import axios from 'axios';
import {
    listUsers,
    listUsersSuccess,
    listUsersFail,
    blockUserRequest,
    blockUserSuccess,
    blockUserFail,
    approveKYCRequest,
    approveKYCSuccess,
    approveKYCFail,
    userTransactionRequest,
    userTransactionSuccess,
    userTransactionFail,
    unBlockUserRequest,
    unBlockUserSuccess,
    unBlockUserFail,
} from './action';
import {URL} from '../../../Utilities/Constants';
import {
    MANUALLY_BANKING_KYC,
    MANUALLY_BANKING_KYC_SUCCESS,
    MANUALLY_BANKING_KYC_FAIL,
    GET_USER_KYC_DOCS,
    GET_USER_KYC_DOCS_SUCCESS,
    GET_USER_KYC_DOCS_FAIL,
    FETCH_APP_USERS,
    FETCH_APP_USERS_SUCCESS,
    FETCH_APP_USERS_FAIL,
    USER_KYC_MANUAL_ACTION_REQUEST,
    USER_KYC_MANUAL_ACTION_SUCCESS,
    USER_KYC_MANUAL_ACTION_FAILURE,
} from './Constants';
import apis from '../../../apiCalls/apis';
import {SOMETHING_WENT_WRONG, UNKNOWN_ERROR_OCCURRED} from '../../../Utilities/constants/message-constants';

/**
 * user kyc manual action
 * @param {status, comment, docId, docType} data user kyc details
 * @param {string} token user token
 * @param {string} clientId selected client id
 */
export const userKycManualAction = (data, token, clientId) => {
    return async (dispatch) => {
        dispatch({type: USER_KYC_MANUAL_ACTION_REQUEST});
        try {
            const response = await apis.userKycManualAction(data, token, clientId);
            console.log('RESPONSE', response);
            if (response.status === 200) {
                dispatch({
                    type: USER_KYC_MANUAL_ACTION_SUCCESS,
                    payload: response?.data?.data,
                });
            } else {
                dispatch({
                    type: USER_KYC_MANUAL_ACTION_FAILURE,
                    payload: response?.data?.errorData || SOMETHING_WENT_WRONG,
                });
            }
        } catch (e) {
            if (e.response) {
                console.log('ERROR_RESPONSE', e.response);
                dispatch({
                    type: USER_KYC_MANUAL_ACTION_FAILURE,
                    payload: e?.response?.data?.data?.errorReason || SOMETHING_WENT_WRONG,
                });
            } else {
                console.log('ERROR', e);
                dispatch({
                    type: USER_KYC_MANUAL_ACTION_FAILURE,
                    payload: e?.message || UNKNOWN_ERROR_OCCURRED,
                });
            }
        }
    };
};

/**
 * get user list api request
 * @param {*} user page details
 * @param {string} token user token
 */
export function getUsersList(user, token, clientId) {
    return (dispatch) => {
        dispatch(listUsers());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token, clientid: clientId},
            url: `${URL}/user/list`,
            data: user,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(listUsersSuccess(data));
                } else if (data.data.status === 'fail') {
                    dispatch(listUsersFail(data.data.errorData.errorReason));
                } else {
                    dispatch(listUsersFail('Unknown Error Occured'));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(listUsersFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(listUsersFail(err.message || 'Something went wrong'));
                }
            });
    };
}

//block users
/**
 * block user api request
 * @param {*} user user details
 * @param {string} token user token
 */
export function blockUser(user, token, clientId) {
    return (dispatch) => {
        dispatch(blockUserRequest());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token, clientid: clientId},
            url: `${URL}/user/block`,
            data: user,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(blockUserSuccess(data));
                } else if (data.data.status === 'fail') {
                    dispatch(blockUserFail(data.data.errorData.errorReason));
                } else {
                    dispatch(blockUserFail('Unknown Error Occured'));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(blockUserFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(blockUserFail(err.message || 'Something went wrong'));
                }
            });
    };
}

export function unBlockUser(req, token, clientId) {
    return (dispatch) => {
        dispatch(unBlockUserRequest());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token, clientid: clientId},
            url: `${URL}/user/unBlock`,
            data: req,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(unBlockUserSuccess(data));
                } else if (data.data.status === 'fail') {
                    dispatch(unBlockUserFail(data.data.errorData.errorReason));
                } else {
                    dispatch(unBlockUserFail('Unknown Error Occured'));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(unBlockUserFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(unBlockUserFail(err.message || 'Something went wrong'));
                }
            });
    };
}

// approve user kyc
/**
 * approve user kyc api request
 * @param {*} user user details
 * @param {string} token user token
 */
export function approveKYC(user, token, clientId) {
    return (dispatch) => {
        dispatch(approveKYCRequest());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token, clientid: clientId},
            url: `${URL}/user/approveKyc`,
            data: user,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(approveKYCSuccess(data));
                } else if (data.data.status === 'fail') {
                    dispatch(approveKYCFail(data.data.errorData.errorReason));
                } else {
                    dispatch(approveKYCFail('Unknown Error Occured'));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(approveKYCFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(approveKYCFail(err.message || 'Something went wrong'));
                }
            });
    };
}

// user Transaction List
/**
 * user Transaction List  api request
 * @param {*} user user details
 * @param {string} token user token
 */
export function transactionList(user, token, clientId) {
    return (dispatch) => {
        dispatch(userTransactionRequest());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token, clientid: clientId},
            url: `${URL}/user/transactions`,
            data: user,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(userTransactionSuccess(data));
                } else if (data.data.status === 'fail') {
                    dispatch(userTransactionFail(data.data.errorData.errorReason));
                } else {
                    dispatch(userTransactionFail('Unknown Error Occured'));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(userTransactionFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(userTransactionFail(err.message || 'Something went wrong'));
                }
            });
    };
}

// get user kyc image
/**
 * @param {userId} data user Id
 * @param {string} token user token
 */
export const getUserKycImage = (data, token, clientId) => (dispatch) => {
    dispatch({type: GET_USER_KYC_DOCS});
    axios({
        method: 'get',
        headers: {Authorization: 'Bearer ' + token, clientid: clientId},
        url: `${URL}/getUserKycImage/${data.userId}`,
    })
        .then((data) => {
            if (data.data.status === 'success') {
                dispatch({type: GET_USER_KYC_DOCS_SUCCESS, payload: data.data});
            } else if (data.data.status === 'fail') {
                dispatch({type: GET_USER_KYC_DOCS_FAIL, payload: data.data.errorData});
            }
        })
        .catch((err) => {
            if (err.response) {
                const errorMsg = err?.response?.data?.msg || err.message;
                dispatch({type: GET_USER_KYC_DOCS_FAIL, payload: {errorReason: errorMsg || 'Something went wrong'}});
            } else {
                dispatch({type: GET_USER_KYC_DOCS_FAIL, payload: {errorReason: err.message || 'Something went wrong'}});
            }
        });
};

// manually Process Kyc
/**
 * @param {status, comment, docId, docType} data user kyc details
 * @param {string} token user token
 */
// export const manualActionOnKyc = (data, token, clientId) => (dispatch) => {
//     dispatch({type: MANUALLY_PROCESS_KYC});
//     axios({
//         method: 'post',
//         headers: {Authorization: 'Bearer ' + token, clientid: clientId},
//         url: `${URL}/manualActionOnKyc`,
//         data: data,
//     })
//         .then((data) => {
//             if (data.data.status === 'success') {
//                 dispatch({type: MANUALLY_PROCESS_KYC_SUCCESS, payload: data.data});
//             } else if (data.data.status === 'fail') {
//                 dispatch({type: MANUALLY_PROCESS_KYC_FAIL, payload: data.data.errorData});
//             }
//         })
//         .catch((err) => {
//             if (err.response) {
//                 const errorMsg = err?.response?.data?.msg || err.message;
//                 dispatch({type: MANUALLY_PROCESS_KYC_FAIL, payload: {errorReason: errorMsg || 'Something went wrong'}});
//             } else {
//                 dispatch({
//                     type: MANUALLY_PROCESS_KYC_FAIL,
//                     payload: {errorReason: err.message || 'Something went wrong'},
//                 });
//             }
//         });
// };

// manually Banking Kyc
/**
 * @param {actionOn, actionType, bankAccData, vpaData, comment, userId, adminComment} data user kyc details
 * @param {string} token user token
 */
export const manualActionOnBanking = (data, token, clientId) => (dispatch) => {
    dispatch({type: MANUALLY_BANKING_KYC});
    axios({
        method: 'post',
        headers: {Authorization: 'Bearer ' + token, clientid: clientId},
        url: `${URL}/maualActionOnBanking`,
        data: data,
    })
        .then((data) => {
            if (data.data.status === 'success') {
                dispatch({type: MANUALLY_BANKING_KYC_SUCCESS, payload: data.data});
            } else if (data.data.status === 'fail') {
                dispatch({type: MANUALLY_BANKING_KYC_FAIL, payload: data.data.errorData});
            }
        })
        .catch((err) => {
            if (err.response) {
                const errorMsg = err?.response?.data?.msg || err.message;
                dispatch({type: MANUALLY_BANKING_KYC_FAIL, payload: {errorReason: errorMsg || 'Something went wrong'}});
            } else {
                dispatch({
                    type: MANUALLY_BANKING_KYC_FAIL,
                    payload: {errorReason: err.message || 'Something went wrong'},
                });
            }
        });
};

// get all app users with filters and pagination
/**
 * @param {*} data app user details
 * @param {string} token user token
 */
export const getAppUserList = (data, token, clientId) => (dispatch) => {
    dispatch({type: FETCH_APP_USERS});
    axios({
        method: 'post',
        headers: {Authorization: 'Bearer ' + token, clientid: clientId},
        url: `${URL}/appUser/list`,
        data: data,
    })
        .then((data) => {
            if (data.data.status === 'success') {
                dispatch({type: FETCH_APP_USERS_SUCCESS, payload: data.data});
            } else if (data.data.status === 'fail') {
                dispatch({type: FETCH_APP_USERS_FAIL, payload: data.data.errorData});
            }
        })
        .catch((err) => {
            if (err.response) {
                const errorMsg = err?.response?.data?.msg || err.message;
                dispatch({type: FETCH_APP_USERS_FAIL, payload: {errorReason: errorMsg || 'Something went wrong'}});
            } else {
                dispatch({type: FETCH_APP_USERS_FAIL, payload: {errorReason: err.message || 'Something went wrong'}});
            }
        });
};
