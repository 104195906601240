export const SQUAD_LIST = 'SQUAD_LIST';
export const SQUAD_LIST_SUCCESS = 'SQUAD_LIST_SUCCESS';
export const SQUAD_LIST_FAIL = 'SQUAD_LIST_FAIL';

export const ADD_SQUAD = 'ADD_SQUAD';
export const ADD_SQUAD_SUCCESS = 'ADD_SQUAD_SUCCESS';
export const ADD_SQUAD_FAIL = 'ADD_SQUAD_FAIL';

export const EDIT_SQUAD = 'EDIT_SQUAD';

export const UPDATE_SQUAD = 'UPDATE_SQUAD';
export const UPDATE_SQUAD_SUCCESS = 'UPDATE_SQUAD_SUCCESS';
export const UPDATE_SQUAD_FAIL = 'UPDATE_SQUAD_FAIL';

export const DELETE_SQUAD = 'DELETE_SQUAD';
export const DELETE_SQUAD_SUCCESS = 'DELETE_SQUAD_SUCCESS';
export const DELETE_SQUAD_FAIL = 'DELETE_SQUAD_FAIL';

export const GET_SQUAD = 'GET_SQUAD';
export const GET_SQUAD_SUCCESS = 'GET_SQUAD_SUCCESS';
export const GET_SQUAD_FAIL = 'GET_SQUAD_FAIL';

export const GET_SQUAD_BY_ID = 'GET_SQUAD_BY_ID';
export const GET_SQUAD_BY_ID_SUCCESS = 'GET_SQUAD_BY_ID_SUCCESS';
export const GET_SQUAD_BY_ID_FAIL = 'GET_SQUAD_BY_ID_FAIL';
