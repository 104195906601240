import React, {useEffect, useState} from 'react';
import Button from '../../../Components/Button/Button';
import Modal from '../../../Components/Modal/Modal';
import {MuiPickersUtilsProvider, DateTimePicker} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Close from '../../../Images/close_yellow.png';
import {NAVBAR_GREY} from '../../../Utilities/Constants.js';
import {connect} from 'react-redux';
import Dropdown from '../../../Components/Dropdown/Dropdown';

const FiltersModal = (props) => {
    const [filterStartTime, setFilterStartTime] = useState(null);
    const [filterEndTime, setFilterEndTime] = useState(null);
    const [gameOptions, setGameOptions] = useState([]);
    const [selectedGame, setSelectedGame] = useState(null);

    const {onCloseModal, onApplyFilters, onResetFilters, modalVisible, resetFilters, toGame} = props;

    useEffect(() => {
        if (resetFilters) {
            resetFilterStates();
        }
    }, [resetFilters]);

    useEffect(() => {
        if (toGame.TOGameListSuccess) {
            setGameOptions(
                toGame.TOGameList.data.data.map((item) => {
                    return {text: item.gameName, value: item.id};
                }),
            );
        }
    }, [toGame.TOGameListSuccess]);

    const getFilters = () => {
        let filters = {};
        filters.filterStartTime = filterStartTime;
        filters.filterEndTime = filterEndTime;
        filters.gameId = selectedGame;

        return filters;
    };

    const closeModal = () => {
        onCloseModal();
    };

    const onReset = () => {
        resetFilterStates();
        onResetFilters();
    };

    const resetFilterStates = () => {
        setFilterStartTime(null);
        setFilterEndTime(null);
        setSelectedGame(null);
    };

    // console.log("modal FiltersModal ", resetFilters);
    return (
        <Modal open={modalVisible}>
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <div style={{flex: 0.5}} />
                <div style={Styles.filterModalRoot}>
                    {/* headers */}
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <div style={{flex: 0.9}} />
                        <div>{'Filters'}</div>
                        <div style={{flex: 1}} />
                        <div onClick={closeModal}>
                            <img src={Close} alt="" className="cursor" />
                        </div>
                    </div>

                    {/* start, end time */}
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <div style={{width: 40}} />
                        <div style={{flex: 1}}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DateTimePicker
                                    margin="normal"
                                    id="date-picker-dialog"
                                    label="Start Date Time"
                                    value={filterStartTime}
                                    disabled={false}
                                    invalidDateMessage={''}
                                    onChange={(val) => {
                                        setFilterStartTime(val);
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        <div style={{width: 40}} />
                        <div style={{flex: 1}}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DateTimePicker
                                    margin="normal"
                                    id="date-picker-dialog"
                                    label="End Date Time"
                                    value={filterEndTime}
                                    disabled={false}
                                    invalidDateMessage={''}
                                    onChange={(val) => {
                                        setFilterEndTime(val);
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        <div style={{width: 40}} />
                    </div>

                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <div style={{width: 40}} />
                        <div style={{flex: 1}}>
                            {/* select game */}
                            <Dropdown
                                label="Game"
                                type="text"
                                menuItems={gameOptions}
                                name="game"
                                value={selectedGame}
                                onChange={(val) => {
                                    setSelectedGame(val);
                                }}
                                placeholder="Select Game"
                            />
                        </div>
                        <div style={{flex: 1}} />
                    </div>

                    {/* buttons */}
                    <div style={{display: 'flex', flexDirection: 'row', marginTop: 50}}>
                        <div style={{flex: 1}} />
                        <Button
                            buttonText="Apply"
                            onClick={() => {
                                onApplyFilters(getFilters());
                            }}
                        />
                        <div style={{width: 20}} />
                        <Button buttonText="Reset" onClick={onReset} />
                        <div style={{flex: 1}} />
                    </div>
                </div>
                <div style={{flex: 0.5}} />
            </div>
        </Modal>
    );
};

const mapStateToProps = (state) => {
    const {toGame} = state;
    return {toGame};
};

const Styles = {
    filterModalRoot: {
        flex: 1,
        backgroundColor: NAVBAR_GREY,
        opacity: 0.9,
        marginLeft: 150,
        marginRight: 150,
        marginTop: 100,
        paddingTop: 20,
        paddingBottom: 20,
        borderRadius: 10,
    },
};

export default connect(mapStateToProps)(FiltersModal);
