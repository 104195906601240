import {KycStatusLabels} from './label-constants';

export const FANTASY_TYPES_OPTIONS = [
    {value: 'NORMAL', text: 'NORMAL'},
    {value: 'SWITCH', text: 'SWITCH'},
];

export const CRICKET_FORMAT_OPTIONS = [
    {value: 'oneday', text: 'ONE-DAY'},
    {value: 't20', text: 'T-20'},
];

export const MAP_NAME_OPTIONS = [{value: 'All', text: 'All'}];

export const LINEUP_STATUS_OPTIONS = [
    {text: 'Announced', value: 'ANNOUNCED'},
    {text: 'Expected', value: 'EXPECTED'},
    {text: 'Not-Expected', value: 'NOT_EXPECTED'},
];

export const PLAYER_LINEUP_STATUS_OPTIONS = [
    {value: 'PLAYING', text: 'Playing'},
    {value: 'NOT_PLAYING', text: 'Not Playing'},
    {value: 'SUBSTITUTE', text: 'Substitute'},
    {value: 'UNAVAILABLE', text: 'Unavailable'},
    {value: 'PLAYED_LAST_MATCH', text: 'Played Last Match'},
    {value: 'NO_INFO', text: 'No Info'},
];

export const KYC_STATUS_OPTIONS = [
    // {value: KycStatusLabels.PROCESSING, text: "Processing"},
    // {value: KycStatusLabels.PROCESS_AGAIN, text: "Process Again"},
    {value: KycStatusLabels.REJECTED, text: 'Rejected'},
    // {value: KycStatusLabels.REPROCESS, text: "Reprocess"},
    {value: KycStatusLabels.SUBMITTED, text: 'Submitted'},
    {value: KycStatusLabels.VERIFIED, text: 'Verified'},
];
