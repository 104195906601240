import React, {useRef, useState} from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';
import Snackbar from '../../../Components/Snackbar/Snackbar';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';
import {useDispatch, useSelector} from 'react-redux';
import MuiInputField from '../../../Components/MuiInputField/MuiInputField';
import Button from '../../../Components/Button/Button';
import {withdrawalRequestAction} from './AxioCalls';

const ActionModal = (props) => {
    const {modalData, onClose, open} = props;
    const submitRef = useRef();
    const dispatch = useDispatch();
    const login = useSelector((state) => state.login);
    const client = useSelector((state) => state.client);
    const [state, setState] = useState({});
    const {errorMessage, loading, refId, comment} = state;

    const handleSubmit = (event) => {
        !!event && event.preventDefault();
        const req = {
            withdrawalId: modalData?.withdrawal?.id || '',
        };
        if (modalData.type === 'approve') {
            req.action = 'VERIFIED';
            req.refTxnId = refId;
        } else if (modalData.type === 'reject') {
            req.action = 'REJECTED';
            req.comment = comment;
        }
        dispatch(withdrawalRequestAction(req, login?.userDetails?.data?.accessToken, client?.selectedClient));
    };

    const handleChange = (obj = {}) => {
        setState({...state, ...obj});
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            scroll="paper"
            maxWidth="sm"
            fullWidth
            disableEscapeKeyDown
            disableBackdropClick
        >
            {!!errorMessage && (
                <Snackbar
                    open={!!errorMessage}
                    message={errorMessage}
                    onClose={() => handleChange({errorMessage: ''})}
                />
            )}
            {!!loading && <LoadingModal open={loading} />}
            <DialogTitle>
                <p
                    style={{fontSize: '20px', fontWeight: '600', lineHeight: '24px'}}
                >{`Action: Withdrawal ${modalData.type === 'approve' ? `Approval` : modalData.type === 'reject' ? `Rejection` : ''}`}</p>
            </DialogTitle>
            <DialogContent dividers={true}>
                <form
                    onSubmit={handleSubmit}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                        gap: '8px',
                        padding: '8px',
                    }}
                >
                    <ol
                        type="a"
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            justifyContent: 'flex-start',
                            gap: '4px',
                        }}
                    >
                        <li>
                            <p
                                style={{
                                    color: '#FFFFFF',
                                    fontSize: '12px',
                                    lineHeight: '16px',
                                    fontWeight: '400',
                                }}
                            >
                                Review the withdrawal details sent to the Jade email address -
                                jadefantasysportspayments@jadegaming.com.ph
                            </p>
                        </li>
                        <li>
                            <p
                                style={{
                                    color: '#FFFFFF',
                                    fontSize: '12px',
                                    lineHeight: '16px',
                                    fontWeight: '400',
                                }}
                            >{`User - ${modalData?.withdrawal?.userName || '-'}`}</p>
                        </li>
                        <li>
                            <p
                                style={{
                                    color: '#FFFFFF',
                                    fontSize: '12px',
                                    lineHeight: '16px',
                                    fontWeight: '400',
                                }}
                            >
                                {`${
                                    modalData.type === 'approve'
                                        ? `If the documents are correct and the withdrawal is processed, click on “Withdrawal
                                Processed`
                                        : modalData.type === 'reject'
                                          ? `If the documents are incorrect/missing, click on “Reject Withdrawal”`
                                          : ``
                                }`}
                            </p>
                        </li>
                        <li>
                            <p
                                style={{
                                    color: '#FFFFFF',
                                    fontSize: '12px',
                                    lineHeight: '16px',
                                    fontWeight: '400',
                                }}
                            >
                                {`${modalData.type === 'approve' ? `Provide the reference ID of the transaction for future reference.` : modalData?.type === 'reject' ? `Provide comments for future reference.` : ``}`}
                            </p>
                        </li>
                        <li>
                            <p
                                style={{
                                    color: '#FFFFFF',
                                    fontSize: '12px',
                                    lineHeight: '16px',
                                    fontWeight: '400',
                                }}
                            >
                                Click on "Cancel" if the withdrawal has not been processed yet.
                            </p>
                        </li>
                    </ol>
                    {!!modalData?.type && modalData?.type === 'approve' && (
                        <MuiInputField
                            label="Reference ID"
                            name="refId"
                            onChange={(event) => handleChange({refId: event.target.value})}
                            size="small"
                            value={refId || ''}
                        />
                    )}
                    {!!modalData?.type && modalData?.type === 'reject' && (
                        <MuiInputField
                            label="Comment"
                            multiline={true}
                            name="comment"
                            onChange={(event) => handleChange({comment: event.target.value})}
                            rows={4}
                            size="small"
                            value={comment || ''}
                        />
                    )}
                    <button ref={submitRef} style={{display: 'none'}} type="submit">
                        Submit
                    </button>
                </form>
            </DialogContent>
            <DialogActions>
                <Button buttonText="Cancel" onClick={onClose} />
                <Button
                    type="submit"
                    buttonText={`${modalData?.type === 'approve' ? `Withdrawal Processed` : modalData?.type === 'reject' ? `Reject Withdrawal` : ''}`}
                    onClick={() => submitRef.current.click()}
                />
            </DialogActions>
        </Dialog>
    );
};

export default ActionModal;
