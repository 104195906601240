export default {
    primary: '#000000',
    accent: '#ffc954',
    header: '#1f2435',
    headerBlack: '#222222',
    headerBlack3: '#333333',
    highlight: '#00fcc7',
    primaryFont: '#FFFFFF',
    unknown: '#AEB1BB',
    highlightFont: '#4FF7CA',
    overlay: '#2d344c',
    BUTTON_GREY: '#DDDDDD',
    BLACK: '#000000',
    BLACK_40: 'rgba(0,0,0,0.4)',
    BLACK_30: 'rgba(0,0,0,0.3)',
    BLACK_50: 'rgba(0,0,0,0.5)',
    BLACK_60: 'rgba(0,0,0,0.6)',
    WHITE: '#FFFFFF',
    OFF_WHITE: '#D0D8ED',
    YELLOW: '#FFC954',
    GREY: '#5F6477',
    LIGHT_GREY: '#AEB1BB',
    DARK_BLUE: '#222222',
    DEEP_BLUE: '#222222',
    LIGHT_BLUE: '#415084',
    ERROR_COLOR: '#FA8A85',
    BOTTOM_BAR_BLUE: '#2D344C',
    LIGHT_YELLOW: '#E2A830',
    DARK_YELLOW: '#FED85C',
    GREEN: '#4FF7CA',
    DIVIDER: '#383F56',
    LIGHTER_GREY: '#999999',
    DIVIDER_BLUE: '#4F576C',
    TRANSPARENT: 'rgba(52, 52, 52, 255)',
    RED: '#FF0000',
    CRIMSON: '#DC143C',
};
