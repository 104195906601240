import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import '../../../Utilities/style.css';
import '../Login/login.css';
import {getMatchList} from './AxioCalls';
import {logout} from '../Login/LoginAction';
import {next, prev} from '../../../Utilities/HelperFunctions';

import TableLayout from '../../Layouts/TableLayout/TableLayout';
import Table from '../../../Components/Table/Table';
import Snackbar from '../../../Components/Snackbar/Snackbar';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';
import FilterInput from '../../../Components/FilterInput';
import Button from '../../../Components/Button/Button';
import {ENTER, NEXT} from '../../../Utilities/Constants';
import {useDebounce, usePrevious} from '../../../Utilities/hooks';
import moment from 'moment';
import Countdown from 'react-countdown';
import MatchContestListModal from './MatchContestListModal';

const UpcomingMatches = (props) => {
    const {history} = props;
    const initialFilter = {
        page: 1,
        pageSize: 20,
        listType: 'ALL',
        status: 'UPCOMING',
        immediate: true,
        sortOrder: 'startTime',
        sortOrderBy: 'DESC',
        startDate: moment().subtract(12, 'h').valueOf(),
    };
    const initialMatchesData = {list: [], pageData: {}};
    const dispatch = useDispatch();
    const login = useSelector((state) => state.login);
    const matches = useSelector((state) => state.match);
    const client = useSelector((state) => state.client);
    const prevMatches = usePrevious(matches);
    const prevClient = usePrevious(client);

    const [matchesData, setMatchesData] = useState(initialMatchesData);
    const {list = [], pageData = {}} = matchesData;

    const [filters, setFilters] = useState(initialFilter);
    const debouncedFilters = useDebounce(filters, {immediate: filters.immediate});
    const [state, setState] = useState({});
    const [contestListModal, setContestListModal] = useState(null);
    const {errorMessage, loading} = state;
    const disableNext = filters.page >= pageData.lastPage;

    useEffect(() => {
        if (!login?.userDetails?.data) {
            history.push('/');
            dispatch(logout());
        }
    }, []);

    useEffect(() => {
        if (matches.matchListRequest && !prevMatches.matchListRequest) {
            handleOnStateChange({loading: true});
        }

        if (matches.matchListSuccess && !prevMatches.matchListSuccess) {
            handleOnStateChange({loading: false});
            setMatchesData({list: matches.matchList.data.data, pageData: matches.matchList.data.pageData});
        }

        if (matches.matchListFail && !prevMatches.matchListFail) {
            handleOnStateChange({errorMessage: matches.errorMessage, loading: false});
        }
    }, [matches]);

    useEffect(() => {
        if (login?.userDetails?.data) handleCallMatchList();
    }, [debouncedFilters]);

    useEffect(() => {
        if (client.selectedClient !== prevClient.selectedClient) handleCallMatchList();
    }, [client]);

    const handleCallMatchList = () => {
        const {immediate, ..._filters} = filters;
        dispatch(getMatchList(_filters, login.userDetails.data.accessToken, client.selectedClient));
    };

    const onNextORPrev = (action) => {
        const _filters = action === NEXT ? next(filters, pageData) : prev(filters);
        if (_filters) setFilters({..._filters});
    };

    const onReset = () => {
        setFilters(initialFilter);
    };

    const handleFilter = (field, value, debounce = true) => {
        if (field !== ENTER) filters[field] = value;
        setFilters({...filters, page: 1, immediate: !debounce});
    };

    const handleOnStateChange = (stateObj = {}) => {
        setState((prevState) => ({...prevState, ...stateObj}));
    };

    const rendererTimeLeft = ({days, hours, minutes, seconds, completed}) => {
        return `${!!days ? days + 'd ' : ''}${!!hours ? hours + 'h ' : ''}${minutes}m ${seconds}s`;
    };

    const handleContestList = (_match) => {
        setContestListModal({matchId: _match.id, contestsCount: _match.contestsCount});
    };

    const columns = [
        {
            Header: 'Match ID',
            accessor: 'id',
            width: 200,
            Cell: (props) => <span>{props?.value || '-'}</span>,
        },
        {
            Header: 'Tournament Name',
            accessor: 'tournamentName',
            width: 150,
            Cell: (props) => <span className="number">{props?.value || '-'}</span>,
        },
        {
            Header: 'Match Name',
            accessor: 'matchName',
            width: 200,
            Cell: (props) => <span className="number">{props?.value || '-'}</span>,
        },
        {
            Header: 'Squad Names',
            accessor: 'squadDataList',
            width: 150,
            Cell: (props) => (
                <span className="number">
                    {!!props?.value?.length ? props.value.map((squad) => squad.squadName).join(', ') : '-'}
                </span>
            ),
        },
        {
            Header: 'Time Left',
            accessor: 'startTime',
            width: 150,
            Cell: (props) => (
                <span className="number red-color fs-16">
                    {!props?.value ? '-' : <Countdown date={new Date(props?.value)} renderer={rendererTimeLeft} />}
                </span>
            ),
        },
        {
            Header: 'Contests',
            accessor: 'contestsCount',
            Cell: (props) => (
                <span className="table-clickable" onClick={() => handleContestList(props.original)}>
                    {props?.value || '0'}
                </span>
            ),
        },
        {
            Header: '# squads',
            accessor: 'squads',
            Cell: (props) => <span className="number">{props?.value?.length ? props.value.length : '-'}</span>,
        },
        {
            Header: 'Map Name',
            accessor: 'mapName',
            Cell: (props) => <span className="number">{props?.value || '-'}</span>,
        },
        {
            Header: '# Players',
            accessor: 'players',
            Cell: (props) => <span className="number">{props?.value?.length ? props.value.length : '-'}</span>,
        },
    ];

    return (
        <TableLayout location="Upcoming Matches" history={history} hideSearchBar>
            {!!loading && <LoadingModal open={!!loading} />}
            {!!errorMessage && (
                <Snackbar
                    open={!!errorMessage}
                    message={errorMessage}
                    onClose={() => handleOnStateChange({errorMessage: ''})}
                />
            )}

            {!!contestListModal && (
                <MatchContestListModal
                    open={!!contestListModal}
                    handleClose={() => setContestListModal(null)}
                    matchDetails={contestListModal}
                />
            )}

            <div className="filter-container my-10">
                <FilterInput
                    placeholder="Match Name"
                    value={filters.matchName || ''}
                    onChange={(value) => handleFilter('matchName', value)}
                    onEnter={() => handleFilter(ENTER, '', false)}
                />
                <FilterInput
                    placeholder="Match Id"
                    value={filters.matchId || ''}
                    onChange={(value) => handleFilter('matchId', value)}
                    onEnter={() => handleFilter(ENTER, '', false)}
                />
                <FilterInput
                    placeholder="Tournament Name"
                    value={filters.tournamentName || ''}
                    onChange={(value) => handleFilter('tournamentName', value)}
                    onEnter={() => handleFilter(ENTER, '', false)}
                />
                <FilterInput
                    placeholder="Game Name"
                    value={filters.gameName || ''}
                    onChange={(value) => handleFilter('gameName', value)}
                    onEnter={() => handleFilter(ENTER, '', false)}
                />
                <Button buttonText="Reset" onClick={onReset} />
            </div>
            <Table
                data={list}
                columns={columns}
                page={filters.page}
                onPrevClick={onNextORPrev}
                onNextClick={() => onNextORPrev(NEXT)}
                disableNext={disableNext}
            ></Table>
        </TableLayout>
    );
};

export default UpcomingMatches;
