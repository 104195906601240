import axios from 'axios';
import {
    listAdmin,
    listAdminSuccess,
    listAdminFail,
    createAdmin,
    createAdminSuccess,
    createAdminFail,
    deleteAdmin,
    deleteAdminSuccess,
    deleteAdminFail,
} from './action';
import {URL} from '../../../Utilities/Constants';
import apis from '../../../apiCalls/apis';
import {
    GET_ADMIN,
    GET_ADMIN_FAIL,
    GET_ADMIN_SUCCESS,
    UPDATE_ADMIN,
    UPDATE_ADMIN_FAIL,
    UPDATE_ADMIN_SUCCESS,
} from './Constants';

/**
 * APi call to get admin list
 * @param {*} data admin object
 * @param {string} token user token
 */
export function getAdminList(data, token,client) {
    return (dispatch) => {
        dispatch(listAdmin());
        axios({
            method: 'get',
            headers: {Authorization: 'Bearer ' + token,clientid:client},
            url: `${URL}/adminUser/list`,
            params: data,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(listAdminSuccess(data));
                } else if (data.data.status === 'fail') {
                    dispatch(listAdminFail(data.data.errorData.errorReason));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(listAdminFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(listAdminFail(err.message || 'Something went wrong'));
                }
            });
    };
}

/**
 * API call to create admin
 * @param {*} admin admin object
 * @param {string} token user token
 */
export function addAdmin(admin, token,client) {
    return (dispatch) => {
        dispatch(createAdmin());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token,clientid: client},
            url: `${URL}/adminUser/create`,
            data: admin,
        })
            .then((response) => {
                if (response.status === 200) {
                    dispatch(createAdminSuccess(response.data));
                } else {
                    dispatch(createAdminFail({msg: response?.data?.msg || 'Something went wrong'}));
                }
            })
            .catch((e) => {
                if (e.response) {
                    const errorMsg = e?.response?.data?.msg || e.message;
                    dispatch(createAdminFail({msg: errorMsg || 'Something went wrong'}));
                } else {
                    dispatch(createAdminFail({msg: e?.message || 'Unknown Error Occured'}));
                }
            });
    };
}

/**
 * delete admin APi call
 * @param {*} admin admin object
 * @param {string} token user token
 */
export function deleteAdmins(admin, token) {
    return (dispatch) => {
        dispatch(deleteAdmin());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token},
            url: `${URL}/adminUser/deactivate`,
            data: admin,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(deleteAdminSuccess(data));
                } else if (data.data.status === 'fail') {
                    dispatch(deleteAdminFail(data.data.errorData.errorReason));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(deleteAdminFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(deleteAdminFail(err.message || 'Something went wrong'));
                }
            });
    };
}

export const fetchAdminUser = (data, token,client) => async (dispatch) => {
    dispatch({type: GET_ADMIN});
    try {
        const response = await apis.getAdminUser(data, token,client);
        if (response.status === 200) {
            dispatch({type: GET_ADMIN_SUCCESS, payload: response.data});
        } else {
            dispatch({type: GET_ADMIN_FAIL, payload: {msg: response?.data?.msg || 'Something went wrong'}});
        }
    } catch (e) {
        if (e.response) {
            const errorMsg = e?.response?.data?.msg || e.message;
            dispatch({
                type: GET_ADMIN_FAIL,
                payload: {msg: errorMsg || 'Something went wrong'},
            });
        } else {
            dispatch({type: GET_ADMIN_FAIL, payload: {msg: e?.message || 'Unknown Error Occured'}});
        }
    }
};

export const updateAdminUser = (data, token,client) => async (dispatch) => {
    dispatch({type: UPDATE_ADMIN});
    try {
        const response = await apis.updateAdminUser(data, token,client);
        if (response.status === 200) {
            dispatch({type: UPDATE_ADMIN_SUCCESS, payload: response.data});
        } else {
            dispatch({type: UPDATE_ADMIN_FAIL, payload: {msg: response?.data?.msg || 'Something went wrong'}});
        }
    } catch (e) {
        if (e.response) {
            const errorMsg = e?.response?.data?.msg || e.message;
            dispatch({
                type: UPDATE_ADMIN_FAIL,
                payload: {msg: errorMsg || 'Something went wrong'},
            });
        } else {
            dispatch({type: UPDATE_ADMIN_FAIL, payload: {msg: e?.message || 'Unknown Error Occured'}});
        }
    }
};
