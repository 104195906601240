import {CREATE_REWARD_USER, CREATE_REWARD_USER_SUCCESS, CREATE_REWARD_USER_FAIL} from './Constants';

const initialState = {
    rewardUser: false,
    rewardUserSuccess: false,
    rewardUserFail: false,
    errorMessage: '',
};

const rewardUserReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_REWARD_USER:
            return {
                ...state,
                rewardUser: true,
                rewardUserSuccess: false,
                rewardUserFail: false,
                errorMessage: '',
            };

        case CREATE_REWARD_USER_SUCCESS:
            return {
                ...state,
                rewardUser: false,
                rewardUserSuccess: true,
                rewardUserFail: false,
                errorMessage: '',
            };

        case CREATE_REWARD_USER_FAIL:
            return {
                ...state,
                rewardUser: false,
                rewardUserSuccess: false,
                rewardUserFail: true,
                errorMessage: action.payload,
            };
        default:
            return state;
    }
};

export default rewardUserReducer;
