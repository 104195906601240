import React from 'react';
import '../../../Utilities/style.css';
import '../Login/login.css';
import {connect} from 'react-redux';
import DetailLayout from '../../Layouts/DetailLayout/DetailLayout';
import Modal from '../../../Components/Modal/Modal';
import Card from '../../../Components/Card/Card';
import Close from '../../../Images/close_yellow.png';
import Input from '../../../Components/Input/Input';
import DropDown from '../../../Components/Dropdown/Dropdown';
import {addDiscount, deleteDiscounts} from './AxioCalls';
import Snackbar from '../../../Components/Snackbar/Snackbar';
import {logout} from '../Login/LoginAction';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';
import Button from '../../../Components/Button/Button';
import {DateTimePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import {DISCOUNT_APPLICABILITY_OPTIONS, DISCOUNT_APPLICABILITY_TYPE} from '../../../Utilities/Constants';

class AddDiscountCode extends React.Component {
    constructor(props) {
        super(props);
        let location = this.props.history.location.pathname.includes('edit');
        this.state = {
            type: '',
            discountCode: '',
            discountValue: '',
            description: '',
            disabled: false,
            loading: false,
            edit: location,
            codeStatus: '',
            open: false,
        };
    }

    submitRef = React.createRef();

    typeOptions = [
        {value: 'AMOUNT', text: 'AMOUNT'},
        {value: 'PERCENT', text: 'PERCENT'},
    ];

    targetAudienceOptions = [
        {value: 'CUSTOM', text: 'CUSTOM'},
        {value: 'INACTIVE', text: 'INACTIVE'},
        {value: 'ACTIVE', text: 'ACTIVE'},
        {value: 'SLEEPING', text: 'SLEEPING'},
        {value: 'CODE', text: 'CODE'},
        {value: 'NEW_USERS', text: 'NEW_USERS'},
        {value: 'ALL', text: 'ALL'},
    ];

    sourceOptions = [
        {value: 'FANTASY', text: 'FANTASY'},
        {value: 'TOURNAMENT', text: 'TOURNAMENT'},
        {value: 'ALL', text: 'ALL'},
    ];

    activationWindowTypeOptions = [
        {value: 'DATE_RANGE', text: 'DATE_RANGE'},
        {value: 'NUMBER_OF_DAYS', text: 'NUMBER_OF_DAYS'},
    ];

    stopGapTypeOptions = [
        {value: 'AMOUNT', text: 'AMOUNT'},
        {value: 'NUMBER_OF_REDEEMS', text: 'NUMBER_OF_REDEEMS'},
    ];

    componentDidMount() {
        const {login, history} = this.props;

        if (!login.userDetails.data) {
            history.push('/');
            this.props.logout();
        } else {
            const {edit} = this.state;
            const {
                discountCode,
                type,
                discountValue,
                title,
                targetAudience,
                source,
                activationWindowType,
                stopGapType,
                maxUsageCount,
                status,
                startDate,
                endDate,
                maxUsageCountPerUser,
                singleGrantCount,
                activationWindowValue,
                maxPercentValue,
                maxDiscountedAmount,
                description,
                minCheckoutAmount,
                applicabilityType,
                validOn,
            } = this.props.discount.discCodeDetails;
            if (edit) {
                this.setState({
                    disabled: true,
                    codeStatus: status,
                    minCheckoutAmount,
                    discountCode,
                    type,
                    discountValue,
                    title,
                    targetAudience,
                    source,
                    activationWindowType,
                    stopGapType,
                    maxUsageCount,
                    maxUsageCountPerUser,
                    singleGrantCount,
                    activationWindowValue,
                    maxPercentValue,
                    maxDiscountedAmount,
                    description,
                    startDate: new Date(startDate),
                    endDate: new Date(endDate),
                    applicabilityType,
                    matchId: validOn?.matchAttributes?.ids?.[0] || '',
                });
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {discount, history} = this.props;

        if (discount.addDiscountSuccess && !prevProps.discount.addDiscountSuccess) {
            history.push('/discountCodes');
        } else if (discount.addDiscountFail && !prevProps.discount.addDiscountFail) {
            this.setState({
                loading: false,
                snackbarOpen: true,
                errorMessage: discount.errorMessage,
            });
        }
        if (discount.deleteDiscountSuccess && !prevProps.discount.deleteDiscountSuccess) {
            this.setState({
                loading: false,
                code: [],
                snackbarOpen: true,
                errorMessage: discount.deleteDiscount.msg,
            });
            history.push('/discountCodes');
        } else if (discount.deleteDiscountFail && !prevProps.discount.deleteDiscountFail) {
            this.setState({
                loading: false,
                snackbarOpen: true,
                errorMessage: discount.errorMessage,
            });
        }
    }

    /**
     * function to save entered data in state
     * @param {string} field field names from the form
     * @param {*} value value in the field
     */
    handleInputChange = (field, value) => {
        this.setState({
            [field]: value,
            snackbarOpen: false,
            errorMessage: '',
        });
    };

    /**
     * API call to add new discount code
     */
    handleSubmit = (e) => {
        !!e && e.preventDefault();

        const {
            discountCode,
            type,
            discountValue,
            title,
            targetAudience,
            source,
            activationWindowType,
            stopGapType,
            maxUsageCount,
            startDate,
            endDate,
            maxUsageCountPerUser,
            singleGrantCount,
            activationWindowValue,
            maxPercentValue,
            maxDiscountedAmount,
            description,
            minCheckoutAmount,
            applicabilityType,
            matchId,
        } = this.state;

        const {login, client} = this.props;

        if (!startDate || !endDate) {
            this.setState({
                snackbarOpen: true,
                errorMessage: 'Please select date range',
            });
            return;
        }

        let discountData = {
            discountCode,
            type,
            targetAudience,
            source,
            description,
            title,
            activationWindowType,
            stopGapType,
            maxUsageCountPerUser: parseInt(maxUsageCountPerUser),
            discountValue: parseInt(discountValue),
            minCheckoutAmount: parseInt(minCheckoutAmount),
            singleGrantCount: parseInt(singleGrantCount),
            startDate: moment(startDate).valueOf(),
            endDate: moment(endDate).valueOf(),
            applicabilityType,
        };

        if (activationWindowType === 'NUMBER_OF_DAYS')
            discountData.activationWindowValue = parseInt(activationWindowValue);

        if (type === 'PERCENT') discountData.maxPercentValue = parseInt(maxPercentValue);

        if (stopGapType === 'AMOUNT') discountData.maxDiscountedAmount = parseInt(maxDiscountedAmount);

        if (stopGapType === 'NUMBER_OF_REDEEMS') discountData.maxUsageCount = parseInt(maxUsageCount);

        if (applicabilityType === DISCOUNT_APPLICABILITY_TYPE.CONTEST_SPECIFIC) discountData.matchId = matchId;

        this.setState({loading: true});
        this.props.addDiscount(discountData, login.userDetails.data.accessToken, client.selectedClient);
    };

    /**
     * api call to delete selected code
     */
    onDeleteCode = () => {
        const {login, client} = this.props;
        const {discountCode} = this.props.discount.discCodeDetails;
        let code = [];
        code.push(this.props.discount.discCodeDetails.id);
        let data = {
            discountIds: JSON.stringify(code),
            discountCode,
        };
        this.props.deleteDiscounts(data, login.userDetails.data.accessToken, client.selectedClient);
        this.setState({open: false, loading: true});
    };

    render() {
        const {
            discountCode,
            disabled,
            type,
            description,
            snackbarOpen,
            errorMessage,
            discountValue,
            loading,
            open,
            edit,
            codeStatus,
            title,
            targetAudience,
            source,
            activationWindowType,
            stopGapType,
            maxUsageCount,
            startDate,
            endDate,
            maxUsageCountPerUser,
            singleGrantCount,
            activationWindowValue,
            maxPercentValue,
            maxDiscountedAmount,
            minCheckoutAmount,
            applicabilityType,
            matchId,
        } = this.state;
        const {discountWrite} = this.props?.login?.permissions || {};

        const {history} = this.props;
        let heading = edit ? 'Discount Code' : 'Add Discount Code';

        return (
            <DetailLayout
                location="discount"
                history={history}
                centerTitle={heading}
                onSave={!!discountWrite ? () => this.submitRef.current.click() : undefined}
                editPage={edit}
                matchStatus={codeStatus}
                onDeleteClick={!!discountWrite ? () => this.setState({open: true}) : undefined}
            >
                <LoadingModal open={loading} />

                <Modal open={open}>
                    <div className="container modal-top-margin">
                        <Card>
                            <div className="disp-flex">
                                <div className="modal-left-header">Delete Discount Code</div>
                                <div onClick={() => this.setState({open: false})}>
                                    <img src={Close} alt="" className="cursor" />
                                </div>
                            </div>
                            <div className="align-center padding-x-20 margin-top-30">
                                <Button buttonText="Delete" onClick={this.onDeleteCode} />
                            </div>
                        </Card>
                    </div>
                </Modal>

                <Snackbar
                    open={snackbarOpen}
                    message={errorMessage}
                    onClose={() => this.setState({snackbarOpen: false})}
                />

                <div className="detail-container margin-top-20">
                    <div className="grid-item" />
                    <div className="grid-item">
                        <Card>
                            <form onSubmit={this.handleSubmit}>
                                <div className="margin-top-20">
                                    <Input
                                        required
                                        label="Title"
                                        type="text"
                                        name="title"
                                        value={title || ''}
                                        onChange={(value) => this.handleInputChange('title', value)}
                                        placeholder="Enter Title"
                                        disabled={disabled}
                                    />
                                </div>
                                <div className="card-grid-container margin-top-20">
                                    <Input
                                        required
                                        label="Create Code"
                                        type="text"
                                        name="discountCode"
                                        value={discountCode || ''}
                                        onChange={(value) => this.handleInputChange('discountCode', value)}
                                        placeholder="Enter Code Eg. TEST123"
                                        disabled={disabled}
                                        inputProps={{
                                            pattern: '[A-Z]{1,}[0-9]{1,}[A-Z0-9]*',
                                            minLength: 4,
                                            maxLength: 10,
                                            title: 'Minimum 4 and Maximum 10 Letters Alphanumeric Code eg. TEST1234',
                                        }}
                                    />

                                    <DropDown
                                        label="Discount Type*"
                                        menuItems={this.typeOptions}
                                        required
                                        name="type"
                                        value={type || ''}
                                        placeholder="Select"
                                        onChange={(value) => this.handleInputChange('type', value)}
                                        disabled={disabled}
                                    />

                                    <div className="margin-top-20">
                                        <Input
                                            required
                                            label="Amount"
                                            type="number"
                                            name="discountValue"
                                            value={discountValue || ''}
                                            onChange={(value) => this.handleInputChange('discountValue', value)}
                                            placeholder="Enter amount"
                                            disabled={disabled}
                                            inputProps={{max: type === 'PERCENT' ? 100 : null}}
                                            suffix={type === 'PERCENT' ? '%' : undefined}
                                        />
                                    </div>

                                    <div className="margin-top-20">
                                        <Input
                                            required
                                            label="Min Amount"
                                            type="number"
                                            name="minCheckoutAmount"
                                            value={minCheckoutAmount || ''}
                                            onChange={(value) => this.handleInputChange('minCheckoutAmount', value)}
                                            placeholder="Enter min amount"
                                            disabled={disabled}
                                            prefix="₹"
                                        />
                                    </div>

                                    {type === 'PERCENT' && (
                                        <div className="margin-top-20">
                                            <Input
                                                required
                                                label="Max Percent Value"
                                                type="number"
                                                name="maxPercentValue"
                                                value={maxPercentValue || ''}
                                                onChange={(value) => this.handleInputChange('maxPercentValue', value)}
                                                placeholder="Enter Max Percent Value"
                                                disabled={disabled}
                                            />
                                        </div>
                                    )}

                                    <DropDown
                                        className="mt-20"
                                        label="Applicability Type*"
                                        menuItems={DISCOUNT_APPLICABILITY_OPTIONS}
                                        required
                                        name="applicabilityType"
                                        value={applicabilityType || ''}
                                        placeholder="Select"
                                        disabled={disabled}
                                        onChange={(value) => this.handleInputChange('applicabilityType', value)}
                                    />

                                    {applicabilityType === DISCOUNT_APPLICABILITY_TYPE.CONTEST_SPECIFIC && (
                                        <Input
                                            className="mt-20"
                                            required
                                            label="Match Id"
                                            type="text"
                                            name="matchId"
                                            placeholder="Enter Match id"
                                            value={matchId || ''}
                                            disabled={disabled}
                                            onChange={(value) => this.handleInputChange('matchId', value)}
                                        />
                                    )}

                                    <div className="margin-top-20">
                                        <DropDown
                                            label="Target Audience*"
                                            menuItems={this.targetAudienceOptions}
                                            required
                                            name="targetAudience"
                                            value={targetAudience || ''}
                                            placeholder="Select"
                                            onChange={(value) => this.handleInputChange('targetAudience', value)}
                                            disabled={disabled}
                                        />
                                    </div>

                                    <div className="margin-top-20">
                                        <DropDown
                                            label="Source*"
                                            menuItems={this.sourceOptions}
                                            required
                                            name="source"
                                            value={source || ''}
                                            placeholder="Select"
                                            onChange={(value) => this.handleInputChange('source', value)}
                                            disabled={disabled}
                                        />
                                    </div>

                                    <div className="margin-top-20">
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <DateTimePicker
                                                margin="normal"
                                                label="Start Date*"
                                                invalidDateMessage={''}
                                                format="dd MMM yyyy HH:mm a"
                                                value={startDate || null}
                                                minDate={disabled ? null : new Date()}
                                                disabled={disabled}
                                                onChange={(value) => this.handleInputChange('startDate', value)}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>

                                    <div className="margin-top-20">
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <DateTimePicker
                                                margin="normal"
                                                label="End Date*"
                                                invalidDateMessage={''}
                                                format="dd MMM yyyy HH:mm a"
                                                value={endDate || null}
                                                minDate={disabled ? null : startDate || new Date()}
                                                disabled={disabled}
                                                onChange={(value) => this.handleInputChange('endDate', value)}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>

                                    <div className="margin-top-20">
                                        <Input
                                            required
                                            label="Max Usage Count Per User"
                                            type="number"
                                            name="maxUsageCountPerUser"
                                            value={maxUsageCountPerUser || ''}
                                            onChange={(value) => this.handleInputChange('maxUsageCountPerUser', value)}
                                            placeholder="Enter Max Usage Count Per User"
                                            disabled={disabled}
                                        />
                                    </div>

                                    <div className="margin-top-20">
                                        <Input
                                            required
                                            label="Single Grant Count"
                                            type="number"
                                            name="singleGrantCount"
                                            value={singleGrantCount || ''}
                                            onChange={(value) => this.handleInputChange('singleGrantCount', value)}
                                            placeholder="Enter Single Grant Count"
                                            disabled={disabled}
                                        />
                                    </div>

                                    <div className="margin-top-20">
                                        <DropDown
                                            label="Activation Window Type*"
                                            menuItems={this.activationWindowTypeOptions}
                                            required
                                            name="activationWindowType"
                                            value={activationWindowType || ''}
                                            placeholder="Select"
                                            onChange={(value) => this.handleInputChange('activationWindowType', value)}
                                            disabled={disabled}
                                        />
                                    </div>

                                    {activationWindowType === 'NUMBER_OF_DAYS' && (
                                        <div className="margin-top-20">
                                            <Input
                                                required
                                                label="Activation Window"
                                                type="number"
                                                name="activationWindowValue"
                                                onChange={(value) =>
                                                    this.handleInputChange('activationWindowValue', value)
                                                }
                                                value={activationWindowValue || ''}
                                                placeholder="Enter Activation Window"
                                                disabled={disabled}
                                            />
                                        </div>
                                    )}

                                    <div className="margin-top-20">
                                        <DropDown
                                            label="Stop Gap Type*"
                                            menuItems={this.stopGapTypeOptions}
                                            required
                                            name="stopGapType"
                                            value={stopGapType || ''}
                                            placeholder="Select"
                                            onChange={(value) => this.handleInputChange('stopGapType', value)}
                                            disabled={disabled}
                                        />
                                    </div>

                                    {stopGapType === 'AMOUNT' && (
                                        <div className="margin-top-20">
                                            <Input
                                                required
                                                label="Max Discounted Amount"
                                                type="number"
                                                name="maxDiscountedAmount"
                                                value={maxDiscountedAmount || ''}
                                                onChange={(value) =>
                                                    this.handleInputChange('maxDiscountedAmount', value)
                                                }
                                                placeholder="Enter Max Discounted Amount"
                                                disabled={disabled}
                                            />
                                        </div>
                                    )}

                                    {stopGapType === 'NUMBER_OF_REDEEMS' && (
                                        <div className="margin-top-20">
                                            <Input
                                                required
                                                label="Max Usage Count"
                                                type="number"
                                                name="maxUsageCount"
                                                value={maxUsageCount || ''}
                                                onChange={(value) => this.handleInputChange('maxUsageCount', value)}
                                                placeholder="Enter Max Usage Count"
                                                disabled={disabled}
                                            />
                                        </div>
                                    )}
                                </div>

                                <div className="margin-top-20">
                                    <Input
                                        required
                                        label="Description"
                                        type="text"
                                        name="description"
                                        placeholder="Enter Description"
                                        onChange={(value) => this.handleInputChange('description', value)}
                                        value={description || ''}
                                        disabled={disabled}
                                    />
                                </div>

                                <div className="margin-top-20 align-center" style={{display: 'none'}}>
                                    <Button buttonRef={this.submitRef} type="submit" buttonText="Submit" />
                                </div>
                            </form>
                        </Card>
                    </div>
                </div>
            </DetailLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        squad: state.squad,
        player: state.player,
        discount: state.discount,
        client: state.client,
    };
};

export default connect(mapStateToProps, {addDiscount, logout, deleteDiscounts})(AddDiscountCode);
