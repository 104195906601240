import axios from 'axios';
import {
    listWithdrawal,
    listWithdrawalSuccess,
    listWithdrawalFail,
    updateWithdrawal,
    updateWithdrawalSuccess,
    updateWithdrawalFail,
} from './action';
import {URL} from '../../../Utilities/Constants';
import {WITHDRAWAL_ACTION_FAILURE, WITHDRAWAL_ACTION_REQUEST, WITHDRAWAL_ACTION_SUCCESS} from './Constants';
import apis from '../../../apiCalls/apis';
import {UNKNOWN_ERROR_OCCURRED} from '../../../Utilities/constants/message-constants';

const UNKNOWN_ERROR = 'Unknown Error Occured';
const SOMETHING_WENT_WRONG = 'Something went wrong';

export const CHECK_WITHDRAWAL_STATUS_INIT = 'CHECK_WITHDRAWAL_STATUS_INIT';
export const CHECK_WITHDRAWAL_STATUS_SUCCESS = 'CHECK_WITHDRAWAL_STATUS_SUCCESS';
export const CHECK_WITHDRAWAL_STATUS_FAILED = 'CHECK_WITHDRAWAL_STATUS_FAILED';

export const CHECK_AND_UPDATE_WITHDRAWAL_INIT = 'CHECK_AND_UPDATE_WITHDRAWAL_INIT';
export const CHECK_AND_UPDATE_WITHDRAWAL_SUCCESS = 'CHECK_AND_UPDATE_WITHDRAWAL_SUCCESS';
export const CHECK_AND_UPDATE_WITHDRAWAL_FAILED = 'CHECK_AND_UPDATE_WITHDRAWAL_FAILED';

export const APPROVE_WITHDRAWL_REQUEST_INIT = 'APPROVE_WITHDRAWL_REQUEST_INIT';
export const APPROVE_WITHDRAWL_REQUEST_SUCCESS = 'APPROVE_WITHDRAWL_REQUEST_SUCCESS';
export const APPROVE_WITHDRAWL_REQUEST_FAILED = 'APPROVE_WITHDRAWL_REQUEST_FAILED';

export const REJECT_WITHDRAWLREQUEST_INIT = 'REJECT_WITHDRAWLREQUEST_INIT';
export const REJECT_WITHDRAWLREQUEST_SUCCESS = 'REJECT_WITHDRAWLREQUEST_SUCCESS';
export const REJECT_WITHDRAWLREQUEST_FAILED = 'REJECT_WITHDRAWLREQUEST_FAILED';

/**
 * get list of all transactions api request
 * @param {*} withdrawal page details
 * @param {string} token user token
 */
export function getWithdrawalList(withdrawal, token, clientId) {
    return (dispatch) => {
        dispatch(listWithdrawal());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token, clientid: clientId},
            url: `${URL}/withdralRequest/list`,
            data: withdrawal,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(listWithdrawalSuccess(data));
                } else if (data.data.status === 'fail') {
                    dispatch(listWithdrawalFail(data.data.errorData.errorReason));
                } else {
                    dispatch(listWithdrawalFail(UNKNOWN_ERROR));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(listWithdrawalFail(errorMsg || SOMETHING_WENT_WRONG));
                } else {
                    dispatch(listWithdrawalFail(err.message || SOMETHING_WENT_WRONG));
                }
            });
    };
}

/**
 * update status of selected transactions api request
 * @param {*} data transaction details
 * @param {string} token user token
 */
export function withdrawalUpdate(data, token, clientId) {
    return (dispatch) => {
        dispatch(updateWithdrawal());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token, clientid: clientId},
            url: `${URL}/withdralRequest/update`,
            data: data,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(updateWithdrawalSuccess(data));
                } else if (data.data.status === 'fail') {
                    dispatch(updateWithdrawalFail(data.data.errorData.errorReason));
                } else {
                    dispatch(updateWithdrawalFail(UNKNOWN_ERROR));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(updateWithdrawalFail(errorMsg || SOMETHING_WENT_WRONG));
                } else {
                    dispatch(updateWithdrawalFail(err.message || SOMETHING_WENT_WRONG));
                }
            });
    };
}

export function checkWithdrawalStatus(withdrawalId, token, clientId) {
    return (dispatch) => {
        dispatch({type: CHECK_WITHDRAWAL_STATUS_INIT});
        axios({
            method: 'GET',
            headers: {Authorization: 'Bearer ' + token, clientid: clientId},
            url: `${URL}/user/checkWithdrawalStatus/${withdrawalId}`,
        })
            .then((response) => {
                if (response.data.status === 'success') {
                    dispatch({
                        type: CHECK_WITHDRAWAL_STATUS_SUCCESS,
                        payload: response.data.data,
                    });
                } else if (response.data.status === 'fail') {
                    dispatch({
                        type: CHECK_WITHDRAWAL_STATUS_FAILED,
                        payload: response.data.errorData.errorReason,
                    });
                } else {
                    dispatch({
                        type: CHECK_WITHDRAWAL_STATUS_FAILED,
                        payload: UNKNOWN_ERROR,
                    });
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch({
                        type: CHECK_WITHDRAWAL_STATUS_FAILED,
                        payload: errorMsg || SOMETHING_WENT_WRONG,
                    });
                } else {
                    dispatch({
                        type: CHECK_WITHDRAWAL_STATUS_FAILED,
                        payload: err.message || SOMETHING_WENT_WRONG,
                    });
                }
            });
    };
}

export function checkAndUpdateWithdrawal(withdrawalId, token, clientId) {
    return (dispatch) => {
        dispatch({type: CHECK_AND_UPDATE_WITHDRAWAL_INIT});
        axios({
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + token,
                clientid: clientId,
            },
            url: `${URL}/user/checkAndUpdateWithdrawal`,
            data: {withdrawalRequestId: withdrawalId},
        })
            .then((response) => {
                if (response.data.status === 'success') {
                    dispatch({
                        type: CHECK_AND_UPDATE_WITHDRAWAL_SUCCESS,
                        payload: response.data.data,
                    });
                } else if (response.data.status === 'fail') {
                    dispatch({
                        type: CHECK_AND_UPDATE_WITHDRAWAL_FAILED,
                        payload: response.data.errorData.errorReason,
                    });
                } else {
                    dispatch({
                        type: CHECK_AND_UPDATE_WITHDRAWAL_FAILED,
                        payload: UNKNOWN_ERROR,
                    });
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch({
                        type: CHECK_AND_UPDATE_WITHDRAWAL_FAILED,
                        payload: errorMsg || SOMETHING_WENT_WRONG,
                    });
                } else {
                    dispatch({
                        type: CHECK_AND_UPDATE_WITHDRAWAL_FAILED,
                        payload: err.message || SOMETHING_WENT_WRONG,
                    });
                }
            });
    };
}

export async function approveWithdrawalRequest(data, token, clientId) {
    const res = await axios({
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + token,
            clientid: clientId,
        },
        url: `${URL}/actionOnWithdrawalRequest`,
        data: data,
    })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            if (err.response) {
                return err.response.data?.msg ?? err.message ?? SOMETHING_WENT_WRONG;
            } else {
                return err.message ?? SOMETHING_WENT_WRONG;
            }
        });
    return res;
}

export async function rejectWithdrawalRequest(data, token, clientId) {
    const res = await axios({
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + token,
            clientid: clientId,
        },
        url: `${URL}/actionOnWithdrawalRequest`,
        data: data,
    })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            if (err.response) {
                return err.response.data?.msg ?? err.message ?? SOMETHING_WENT_WRONG;
            } else {
                return err.message ?? SOMETHING_WENT_WRONG;
            }
        });
    return res;
}

/**
 * withdrawal action api request
 * @param {withdrawalId, refTxnId, comment, action} data withdrawal details
 * @param {string} token user token
 * @param {string} clientId selected client id
 */
export const withdrawalRequestAction = (data, token, clientId) => {
    return async (dispatch) => {
        dispatch({type: WITHDRAWAL_ACTION_REQUEST});
        try {
            const response = await apis.withdrawalRequestAction(data, token, clientId);
            console.log('RESPONSE', response);
            if (response.status === 200) {
                dispatch({
                    type: WITHDRAWAL_ACTION_SUCCESS,
                    payload: response?.data?.msg,
                });
            } else {
                dispatch({
                    type: WITHDRAWAL_ACTION_FAILURE,
                    payload: response?.data?.errorData?.errorReason || SOMETHING_WENT_WRONG,
                });
            }
        } catch (e) {
            if (e.response) {
                console.log('ERROR_RESPONSE', e.response);
                dispatch({
                    type: WITHDRAWAL_ACTION_FAILURE,
                    payload: e?.response?.data?.data?.errorReason || SOMETHING_WENT_WRONG,
                });
            } else {
                console.log('ERROR', e);
                dispatch({
                    type: WITHDRAWAL_ACTION_FAILURE,
                    payload: e?.message || UNKNOWN_ERROR_OCCURRED,
                });
            }
        }
    };
};
