import {
    TOMATCH_LIST,
    TOMATCH_LIST_SUCCESS,
    TOMATCH_LIST_FAIL,
    ADD_TOMATCH,
    ADD_TOMATCH_SUCCESS,
    ADD_TOMATCH_FAIL,
    RESCHEDULE_TO_MATCH,
    RESCHEDULE_TO_MATCH_FAIL,
    RESCHEDULE_TO_MATCH_SUCCESS,
    UPDATE_TOMATCH,
    UPDATE_TOMATCH_SUCCESS,
    UPDATE_TOMATCH_FAIL,
    DELETE_TOMATCH,
    DELETE_TOMATCH_SUCCESS,
    DELETE_TOMATCH_FAIL,
    GET_TOMATCH,
    GET_TOMATCH_SUCCESS,
    GET_TOMATCH_FAIL,
    EDIT_TOMATCH,
} from './Constants';

//lst all TOMATCH
export function listTOMatches() {
    return {
        type: TOMATCH_LIST,
    };
}

export function listTOMatchesSuccess(data) {
    return {
        type: TOMATCH_LIST_SUCCESS,
        payload: data,
    };
}

export function listTOMatchesFail(error) {
    return {
        type: TOMATCH_LIST_FAIL,
        payload: error,
    };
}

// add TOMATCH
export function addTOMatch() {
    return {
        type: ADD_TOMATCH,
    };
}

export function addTOMatchSuccess(data) {
    return {
        type: ADD_TOMATCH_SUCCESS,
        payload: data,
    };
}

export function addTOMatchFail(error) {
    return {
        type: ADD_TOMATCH_FAIL,
        payload: error,
    };
}

/**
 * reschedule  match api
 */
export function rescheduleTOMatchRequest() {
    return {
        type: RESCHEDULE_TO_MATCH,
    };
}

/**
 *handle reschedule match api success
 * @param {*} data data from api
 */
export function rescheduleTOMatchSuccess(data) {
    return {
        type: RESCHEDULE_TO_MATCH_SUCCESS,
        payload: data,
    };
}
/**
 *handle reschedule match api failure
 * @param {*} error error from api
 */
export function rescheduleTOMatchFail(error) {
    return {
        type: RESCHEDULE_TO_MATCH_FAIL,
        payload: error,
    };
}

//update TOMATCH
export function updateTOMatchRequest() {
    return {
        type: UPDATE_TOMATCH,
    };
}

export function updateTOMatchSuccess(data) {
    return {
        type: UPDATE_TOMATCH_SUCCESS,
        payload: data,
    };
}

export function updateTOMatchFail(error) {
    return {
        type: UPDATE_TOMATCH_FAIL,
        payload: error,
    };
}

// delete TOMATCH

export function deleteTOMatchRequest() {
    return {
        type: DELETE_TOMATCH,
    };
}

export function deleteTOMatchSuccess(data) {
    return {
        type: DELETE_TOMATCH_SUCCESS,
        payload: data,
    };
}

export function deleteTOMatchFail(error) {
    return {
        type: DELETE_TOMATCH_FAIL,
        payload: error,
    };
}

//get single TOMATCH
export function getTOMatchRequest() {
    return {
        type: GET_TOMATCH,
    };
}

export function getTOMatchSuccess(data) {
    return {
        type: GET_TOMATCH_SUCCESS,
        payload: data,
    };
}

export function getTOMatchFail(error) {
    return {
        type: GET_TOMATCH_FAIL,
        payload: error,
    };
}

// single match Details
export function editTOMatch(match) {
    return {
        type: EDIT_TOMATCH,
        payload: match,
    };
}
