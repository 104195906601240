import React, {useEffect, useState} from 'react';
import styles from './SwitchConfigurations.module.scss';
import CustomLayout from '../../../../layout/CustomLayout/CustomLayout';
import MuiButton from '../../../../Components/MuiButton/MuiButton';
import {AddCircle} from '@material-ui/icons';
import AddEditSwitchConfiguration from '../../components/AddEditSwitchConfiguration/AddEditSwitchConfiguration';
import {useDispatch, useSelector} from 'react-redux';
import {fetchSwitchConfigsList} from '../../actions';
import {debounce} from '../../../../Utilities/HelperFunctions';
import {logout} from '../../../../View/Modules/Login/LoginAction';
import ReactTable from 'react-table';
import {usePrevious} from '../../../../Utilities/hooks';

const SwitchConfigurations = (props) => {
    const {history} = props;
    const dispatch = useDispatch();
    const login = useSelector((state) => state.login);
    const client = useSelector((state) => state.client);
    const switchConfig = useSelector((state) => state.switchConfig);
    const prevSwitchConfig = usePrevious(switchConfig);
    const [switchConfigs, setSwitchConfigs] = useState({list: [], pageData: {}});
    const [addEditSwitchConfig, setAddEditSwitchConfig] = useState(null);
    const [pageOptions, setPageOptions] = useState({
        page: 1,
        pageSize: 10,
        listType: 'ALL',
    });

    useEffect(() => {
        if (!login?.userDetails?.data) {
            history.push('/');
            dispatch(logout());
        }
        fetchSwitchConfigsListDebounce({immediate: true});
    }, []);

    useEffect(() => {
        console.log('SWITCH_CONFIG', switchConfig);
        if (switchConfig.fetchSwitchConfigsListSuccess && !prevSwitchConfig.fetchSwitchConfigsListSuccess) {
            setSwitchConfigs({
                list: switchConfig?.switchConfigsList,
                pageData: switchConfig?.switchConfigsList?.pageData,
            });
        }
        if (switchConfig.fetchSwitchConfigsListFailure && !prevSwitchConfig.fetchSwitchConfigsListFailure) {
            alert(switchConfig?.message);
        }
    }, [switchConfig]);

    const fetchSwitchConfigsListDebounce = debounce(() => {
        dispatch(fetchSwitchConfigsList(pageOptions, login?.userDetails?.data?.accessToken, client.selectedClient));
    });

    const handleAddSwitchConfigSuccess = () => {
        setAddEditSwitchConfig(null);
    };

    const tableColumns = [
        {
            Header: 'ID',
            accessor: 'id',
            width: 200,
            Cell: (props) => <p>{props?.value || '-'}</p>,
        },
        {
            Header: 'Name',
            accessor: 'name',
            width: 300,
            Cell: (props) => <p>{props?.value || '-'}</p>,
        },
        {
            Header: 'Max Teams Allowed',
            accessor: 'teamsCountLimit',
            width: 200,
            Cell: (props) => <p>{props?.value || '-'}</p>,
        },
        {
            Header: 'Total Switches Allowed',
            accessor: 'totalSubsAllowed',
            width: 200,
            Cell: (props) => <p>{props?.value || '-'}</p>,
        },
        {
            Header: 'Switch Fees',
            accessor: 'subsFee',
            width: 200,
            Cell: (props) => (
                <span>
                    {props?.value?.map((fee, index) => (
                        <p key={`fee-${index + 1}-${fee}`}>{fee}</p>
                    ))}
                </span>
            ),
        },
    ];

    return (
        <CustomLayout history={history} mainClassName={styles.main}>
            {/* add edit switch config modal */}
            {!!addEditSwitchConfig && (
                <AddEditSwitchConfiguration
                    addEditSwitchConfig={addEditSwitchConfig}
                    onClickClose={() => setAddEditSwitchConfig(null)}
                    onSuccess={handleAddSwitchConfigSuccess}
                    open={!!addEditSwitchConfig}
                />
            )}
            {/* button section */}
            <section className={styles.buttonSection}>
                <MuiButton
                    type="button"
                    buttonText="New Switch Configuration"
                    buttonIcon={<AddCircle />}
                    onButtonClick={() => setAddEditSwitchConfig({})}
                />
            </section>

            {/* header section */}
            <section className={styles.headerSection}>
                <h1 className={styles.pageTitle}>{`Switch Configurations`}</h1>
            </section>
            {/* table section */}
            <section className={styles.tableSection}>
                <ReactTable className={styles.table} columns={tableColumns} data={switchConfigs?.list} />
            </section>
        </CustomLayout>
    );
};

export default SwitchConfigurations;
