import axios from 'axios';
import {sendNotificationRequest, sendNotificationSuccess, sendNotificationFail} from './action';
import {URL} from '../../../Utilities/Constants';

/**
 * send notification api request
 * @param {*} data notificatiom data
 * @param {string} token user token
 */
export function sendNotification(data, token, clientId) {
    return (dispatch) => {
        dispatch(sendNotificationRequest());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token, clientid: clientId},
            url: `${URL}/notification/send`,
            data: data,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(sendNotificationSuccess(data));
                } else if (data.data.status === 'fail') {
                    dispatch(sendNotificationFail(data.data.errorData.errorReason));
                } else {
                    dispatch(sendNotificationFail('Unknown Error Occured'));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(sendNotificationFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(sendNotificationFail(err.message || 'Something went wrong'));
                }
            });
    };
}
