import axios from 'axios';
import {
    listReferral,
    listReferralSuccess,
    listReferralFail,
    createReferral,
    createReferralSuccess,
    createReferralFail,
    updateReferral,
    updateReferralSuccess,
    updateReferralFail,
    referredUsers,
    referredUsersSuccess,
    referredUsersFail,
    convertedTnx,
    convertedTnxSuccess,
    convertedTnxFail,
} from './action';
import {URL} from '../../../Utilities/Constants';

/**
 * get ref code list api request
 * @param {*} refCode page details
 * @param {string} token user token
 */
export function getReferralList(refCode, token, clientId) {
    return (dispatch) => {
        dispatch(listReferral());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token, clientid: clientId},
            url: `${URL}/referral/list`,
            data: refCode,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(listReferralSuccess(data));
                } else if (data.data.status === 'fail') {
                    dispatch(listReferralFail(data.data.errorData.errorReason));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(listReferralFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(listReferralFail(err.message || 'Something went wrong'));
                }
            });
    };
}

/**
 * create Referral code api request
 * @param {*} Referral ref code details
 * @param {string} token user token
 */
export function addReferral(Referral, token, clientId) {
    return (dispatch) => {
        dispatch(createReferral());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token, clientid: clientId},
            url: `${URL}/referral/create`,
            data: Referral,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(createReferralSuccess(data));
                } else if (data.data.status === 'fail') {
                    dispatch(createReferralFail(data.data.errorData.errorReason));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(createReferralFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(createReferralFail(err.message || 'Something went wrong'));
                }
            });
    };
}

/**
 * update Referral code api request
 * @param {*} Referral ref code details
 * @param {string} token user token
 */
export function updateReferrals(Referral, token, clientId) {
    return (dispatch) => {
        dispatch(updateReferral());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token, clientid: clientId},
            url: `${URL}/referral/update`,
            data: Referral,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(updateReferralSuccess(data));
                } else if (data.data.status === 'fail') {
                    dispatch(updateReferralFail(data.data.errorData.errorReason));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(updateReferralFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(updateReferralFail(err.message || 'Something went wrong'));
                }
            });
    };
}

/**
 *  user referral code api request
 * @param {*} Referral ref code details
 * @param {string} token user token
 */
export function usersReferred(Referral, token, clientId) {
    return (dispatch) => {
        dispatch(referredUsers());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token, clientid: clientId},
            url: `${URL}/referral/referredUsers`,
            data: Referral,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(referredUsersSuccess(data));
                } else if (data.data.status === 'fail') {
                    dispatch(referredUsersFail(data.data.errorData.errorReason));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(referredUsersFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(referredUsersFail(err.message || 'Something went wrong'));
                }
            });
    };
}

/**
 * CONVERTED TNX api request
 * @param {*} Referral ref code details
 * @param {string} token user token
 */
export function userConvertedTnx(Referral, token, clientId) {
    return (dispatch) => {
        dispatch(convertedTnx());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token, clientid: clientId},
            url: `${URL}/referral/referredUserTxns`,
            data: Referral,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(convertedTnxSuccess(data));
                } else if (data.data.status === 'fail') {
                    dispatch(convertedTnxFail(data.data.errorData.errorReason));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(convertedTnxFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(convertedTnxFail(err.message || 'Something went wrong'));
                }
            });
    };
}
