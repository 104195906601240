import {
    GET_BANNER_LIST,
    GET_BANNER_LIST_SUCCESS,
    GET_BANNER_LIST_FAIL,
    GET_BANNER,
    GET_BANNER_SUCCESS,
    GET_BANNER_FAIL,
    CREATE_BANNER,
    CREATE_BANNER_SUCCESS,
    CREATE_BANNER_FAIL,
    UPDATE_BANNER,
    UPDATE_BANNER_SUCCESS,
    UPDATE_BANNER_FAIL,
} from './constants';

const initialState = {
    bannerList: {},
    isFetchingBanners: false,
    isFetchingBannersSuccess: false,
    isFetchingBannersFail: false,

    bannerDetails: {},
    isFetchingBanner: false,
    isFetchingBannerSuccess: false,
    isFetchingBannerFail: false,

    isCreatingBanner: false,
    isCreatingBannerSuccess: false,
    isCreatingBannerFail: false,

    isUpdatingBanner: false,
    isUpdatingBannerSuccess: false,
    isUpdatingBannerFail: false,

    message: '',
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_BANNER_LIST:
            return {
                ...state,
                isFetchingBanners: true,
                isFetchingBannersSuccess: false,
                isFetchingBannersFail: false,
                bannerList: {},
                message: '',
            };

        case GET_BANNER_LIST_SUCCESS:
            return {
                ...state,
                isFetchingBanners: false,
                isFetchingBannersSuccess: true,
                isFetchingBannersFail: false,
                bannerList: action.payload.data,
                message: '',
            };

        case GET_BANNER_LIST_FAIL:
            return {
                ...state,
                isFetchingBanners: false,
                isFetchingBannersSuccess: false,
                isFetchingBannersFail: true,
                message: action.payload,
            };

        case GET_BANNER:
            return {
                ...state,
                isFetchingBanner: true,
                isFetchingBannerSuccess: false,
                isFetchingBannerFail: false,
                bannerDetails: {},
                message: '',
            };

        case GET_BANNER_SUCCESS:
            return {
                ...state,
                isFetchingBanner: false,
                isFetchingBannerSuccess: true,
                isFetchingBannerFail: false,
                bannerDetails: action.payload.data,
                message: '',
            };

        case GET_BANNER_FAIL:
            return {
                ...state,
                isFetchingBanner: false,
                isFetchingBannerSuccess: false,
                isFetchingBannerFail: true,
                message: action.payload,
            };

        case CREATE_BANNER:
            return {
                ...state,
                isCreatingBanner: true,
                isCreatingBannerSuccess: false,
                isCreatingBannerFail: false,
                message: '',
            };

        case CREATE_BANNER_SUCCESS:
            return {
                ...state,
                isCreatingBanner: false,
                isCreatingBannerSuccess: true,
                isCreatingBannerFail: false,
                message: '',
            };

        case CREATE_BANNER_FAIL:
            return {
                ...state,
                isCreatingBanner: false,
                isCreatingBannerSuccess: false,
                isCreatingBannerFail: true,
                message: action.payload,
            };

        case UPDATE_BANNER:
            return {
                ...state,
                isUpdatingBanner: true,
                isUpdatingBannerSuccess: false,
                isUpdatingBannerFail: false,
                message: '',
            };

        case UPDATE_BANNER_SUCCESS:
            return {
                ...state,
                isUpdatingBanner: false,
                isUpdatingBannerSuccess: true,
                isUpdatingBannerFail: false,
                message: '',
            };

        case UPDATE_BANNER_FAIL:
            return {
                ...state,
                isUpdatingBanner: false,
                isUpdatingBannerSuccess: false,
                isUpdatingBannerFail: true,
                message: action.payload,
            };

        default:
            return state;
    }
};

export default reducer;
