import {
    GET_SWITCH_CONFIGS_LIST_FAILURE,
    GET_SWITCH_CONFIGS_LIST_REQUEST,
    GET_SWITCH_CONFIGS_LIST_SUCCESS,
    POST_SWITCH_CONFIG_FAILURE,
    POST_SWITCH_CONFIG_REQUEST,
    POST_SWITCH_CONFIG_SUCCESS,
} from './action-constants';

const initialState = {
    fetchSwitchConfigsListRequest: false,
    fetchSwitchConfigsListSuccess: false,
    fetchSwitchConfigsListFailure: false,
    switchConfigsList: {},

    addSwitchConfigRequest: false,
    addSwitchConfigSuccess: false,
    addSwitchConfigFailure: false,

    message: '',
};

const switchConfigReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SWITCH_CONFIGS_LIST_REQUEST:
            return {
                ...state,
                fetchSwitchConfigsListRequest: true,
                fetchSwitchConfigsListSuccess: false,
                fetchSwitchConfigsListFailure: false,
            };

        case GET_SWITCH_CONFIGS_LIST_SUCCESS:
            return {
                ...state,
                fetchSwitchConfigsListRequest: false,
                fetchSwitchConfigsListSuccess: true,
                fetchSwitchConfigsListFailure: false,
                switchConfigsList: action.payload,
            };

        case GET_SWITCH_CONFIGS_LIST_FAILURE:
            return {
                ...state,
                fetchSwitchConfigsListRequest: false,
                fetchSwitchConfigsListSuccess: false,
                fetchSwitchConfigsListFailure: true,
                message: action.payload,
            };

        case POST_SWITCH_CONFIG_REQUEST:
            return {
                ...state,
                addSwitchConfigRequest: true,
                addSwitchConfigSuccess: false,
                addSwitchConfigFailure: false,
            };

        case POST_SWITCH_CONFIG_SUCCESS:
            return {
                ...state,
                addSwitchConfigRequest: false,
                addSwitchConfigSuccess: true,
                addSwitchConfigFailure: false,
                message: action.payload,
            };

        case POST_SWITCH_CONFIG_FAILURE:
            return {
                ...state,
                addSwitchConfigRequest: false,
                addSwitchConfigSuccess: false,
                addSwitchConfigFailure: true,
                message: action.payload,
            };

        default:
            return state;
    }
};

export default switchConfigReducer;
