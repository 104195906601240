export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const UPDATE_PERMISSION = 'UPDATE_PERMISSION';

export const SENDEMAIL = 'SENDEMAIL';
export const SENDEMAIL_SUCCESS = 'SENDEMAIL_SUCCESS';
export const SENDEMAIL_FAIL = 'SENDEMAIL_FAIL';

export const LOGGED_IN_DETAILS = 'LOGGED_IN_DETAILS';
export const LOGGED_IN_DETAILS_SUCCESS = 'LOGGED_IN_DETAILS_SUCCESS';
export const LOGGED_IN_DETAILS_FAIL = 'LOGGED_IN_DETAILS_FAIL';
