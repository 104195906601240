export const MATCH_LIST = 'MATCH_LIST';
export const MATCH_LIST_SUCCESS = 'MATCH_LIST_SUCCESS';
export const MATCH_LIST_FAIL = 'MATCH_LIST_FAIL';

export const ADD_MATCH = 'ADD_MATCH';
export const ADD_MATCH_SUCCESS = 'ADD_MATCH_SUCCESS';
export const ADD_MATCH_FAIL = 'ADD_MATCH_FAIL';

export const UPDATE_MATCH = 'UPDATE_MATCH';
export const UPDATE_MATCH_SUCCESS = 'UPDATE_MATCH_SUCCESS';
export const UPDATE_MATCH_FAIL = 'UPDATE_MATCH_FAIL';

export const UPDATE_PLAYERSTATS = 'UPDATE_PLAYERSTATS';
export const UPDATE_PLAYERSTATS_SUCCESS = 'UPDATE_PLAYERSTATS_SUCCESS';
export const UPDATE_PLAYERSTATS_FAIL = 'UPDATE_PLAYERSTATS_FAIL';

export const RESCHEDULE_MATCH = 'RESCHEDULE_MATCH';
export const RESCHEDULE_MATCH_SUCCESS = 'RESCHEDULE_MATCH_SUCCESS';
export const RESCHEDULE_MATCH_FAIL = 'RESCHEDULE_MATCH_FAIL';

export const DELETE_MATCH = 'DELETE_MATCH';
export const DELETE_MATCH_SUCCESS = 'DELETE_MATCH_SUCCESS';
export const DELETE_MATCH_FAIL = 'DELETE_MATCH_FAIl';

export const EDIT_MATCH = 'EDIT_MATCH';
export const CONTEST_MATCH = 'CONTEST_MATCH';

export const GET_MATCH = 'GET_MATCH';
export const GET_MATCH_SUCCESS = 'GET_MATCH_SUCCESS';
export const GET_MATCH_FAIL = 'GET_MATCH_FAIl';

export const PUBLISH_MATCH = 'PUBLISH_MATCH';
export const PUBLISH_MATCH_SUCCESS = 'PUBLISH_MATCH_SUCCESS';
export const PUBLISH_MATCH_FAIL = 'PUBLISH_MATCH_FAIl';

export const UPDATE_STATUS_LIVE_NOW = 'UPDATE_STATUS_LIVE_NOW';
export const UPDATE_STATUS_LIVE_NOW_SUCCESS = 'UPDATE_STATUS_LIVE_NOW_SUCCESS';
export const UPDATE_STATUS_LIVE_NOW_FAIL = 'UPDATE_STATUS_LIVE_NOW_FAIL';

export const UPDATE_MATCH_TAG = 'UPDATE_MATCH_TAG';
export const UPDATE_MATCH_TAG_SUCCESS = 'UPDATE_MATCH_TAG_SUCCESS';
export const UPDATE_MATCH_TAG_FAIL = 'UPDATE_MATCH_TAG_FAIL';

export const COMING_SOON_MATCH = 'COMING_SOON_MATCH';
export const COMING_SOON_MATCH_SUCCESS = 'COMING_SOON_MATCH_SUCCESS';
export const COMING_SOON_MATCH_FAIL = 'COMING_SOON_MATCH_FAIl';

export const UPDATE_MATCH_TYPE = 'UPDATE_MATCH_TYPE';
export const UPDATE_MATCH_TYPE_SUCCESS = 'UPDATE_MATCH_TYPE_SUCCESS';
export const UPDATE_MATCH_TYPE_FAIL = 'UPDATE_MATCH_TYPE_FAIl';

export const SUBSTITUTE_PLAYER = 'SUBSTITUTE_PLAYER';
export const SUBSTITUTE_PLAYER_SUCCESS = 'SUBSTITUTE_PLAYER_SUCCESS';
export const SUBSTITUTE_PLAYER_FAIL = 'SUBSTITUTE_PLAYER_FAIL';

export const MARK_MATCH_FR = 'MARK_MATCH_FR';
export const MARK_MATCH_FR_SUCCESS = 'MARK_MATCH_FR_SUCCESS';
export const MARK_MATCH_FR_FAIL = 'MARK_MATCH_FR_FAIL';

export const MATCH_DRAFT_TO_LIVE = 'MATCH_DRAFT_TO_LIVE';
export const MATCH_DRAFT_TO_LIVE_SUCCESS = 'MATCH_DRAFT_TO_LIVE_SUCCESS';
export const MATCH_DRAFT_TO_LIVE_FAIL = 'MATCH_DRAFT_TO_LIVE_FAIL';

export const PLAYER_FREEZE_UNFREEZE = 'PLAYER_FREEZE_UNFREEZE';
export const PLAYER_FREEZE_UNFREEZE_SUCCESS = 'PLAYER_FREEZE_UNFREEZE_SUCCESS';
export const PLAYER_FREEZE_UNFREEZE_FAIL = 'PLAYER_FREEZE_UNFREEZE_FAIL';
