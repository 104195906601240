import {
    FETCH_TDS_LIST,
    FETCH_TDS_LIST_SUCCESS,
    FETCH_TDS_LIST_FAIL,
    GET_TDS_UPLOAD_URL,
    GET_TDS_UPLOAD_URL_SUCCESS,
    GET_TDS_UPLOAD_URL_FAIL,
    UPLOAD_TDS_CERTIFICATE,
    UPLOAD_TDS_CERTIFICATE_SUCCESS,
    UPLOAD_TDS_CERTIFICATE_FAIL,
    REVIEW_TDS_CERTIFICATE,
    REVIEW_TDS_CERTIFICATE_SUCCESS,
    REVIEW_TDS_CERTIFICATE_FAIL,
} from './Constants';

const initialState = {
    tdsListData: {},
    isFetchingTdsList: false,
    isFetchingTdsListSuccess: false,
    isFetchingTdsListFail: false,

    tdsUploadUrlData: {},
    isFetchingTdsUploadUrl: false,
    isFetchingTdsUploadUrlSuccess: false,
    isFetchingTdsUploadUrlFail: false,

    isUploadingTdsCertificate: false,
    isUploadingTdsCertificateSuccess: false,
    isUploadingTdsCertificateFail: false,

    isReviewingTdsCertificate: false,
    isReviewingTdsCertificateSuccess: false,
    isReviewingTdsCertificateFail: false,

    message: '',
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TDS_LIST:
            return {
                ...state,
                isFetchingTdsList: true,
                isFetchingTdsListSuccess: false,
                isFetchingTdsListFail: false,
                tdsListData: {},
                message: '',
            };
        case FETCH_TDS_LIST_SUCCESS:
            return {
                ...state,
                isFetchingTdsList: false,
                isFetchingTdsListSuccess: true,
                isFetchingTdsListFail: false,
                tdsListData: action?.payload?.data || {},
                message: '',
            };
        case FETCH_TDS_LIST_FAIL:
            return {
                ...state,
                isFetchingTdsList: false,
                isFetchingTdsListSuccess: false,
                isFetchingTdsListFail: true,
                message: action.payload,
            };

        case GET_TDS_UPLOAD_URL:
            return {
                ...state,
                isFetchingTdsUploadUrl: true,
                isFetchingTdsUploadUrlSuccess: false,
                isFetchingTdsUploadUrlFail: false,
                tdsUploadUrlData: {},
                message: '',
            };
        case GET_TDS_UPLOAD_URL_SUCCESS:
            return {
                ...state,
                isFetchingTdsUploadUrl: false,
                isFetchingTdsUploadUrlSuccess: true,
                isFetchingTdsUploadUrlFail: false,
                tdsUploadUrlData: action.payload.data,
                message: '',
            };
        case GET_TDS_UPLOAD_URL_FAIL:
            return {
                ...state,
                isFetchingTdsUploadUrl: false,
                isFetchingTdsUploadUrlSuccess: false,
                isFetchingTdsUploadUrlFail: true,
                message: action.payload,
            };

        case UPLOAD_TDS_CERTIFICATE:
            return {
                ...state,
                isUploadingTdsCertificate: true,
                isUploadingTdsCertificateSuccess: false,
                isUploadingTdsCertificateFail: false,
                message: '',
            };
        case UPLOAD_TDS_CERTIFICATE_SUCCESS:
            return {
                ...state,
                isUploadingTdsCertificate: false,
                isUploadingTdsCertificateSuccess: true,
                isUploadingTdsCertificateFail: false,
                message: action.payload,
            };
        case UPLOAD_TDS_CERTIFICATE_FAIL:
            return {
                ...state,
                isUploadingTdsCertificate: false,
                isUploadingTdsCertificateSuccess: false,
                isUploadingTdsCertificateFail: true,
                message: action.payload,
            };

        case REVIEW_TDS_CERTIFICATE:
            return {
                ...state,
                isReviewingTdsCertificate: true,
                isReviewingTdsCertificateSuccess: false,
                isReviewingTdsCertificateFail: false,
                message: '',
            };
        case REVIEW_TDS_CERTIFICATE_SUCCESS:
            return {
                ...state,
                isReviewingTdsCertificate: false,
                isReviewingTdsCertificateSuccess: true,
                isReviewingTdsCertificateFail: false,
                message: action.payload,
            };
        case REVIEW_TDS_CERTIFICATE_FAIL:
            return {
                ...state,
                isReviewingTdsCertificate: false,
                isReviewingTdsCertificateSuccess: false,
                isReviewingTdsCertificateFail: true,
                message: action.payload,
            };

        default:
            return state;
    }
};
