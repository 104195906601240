import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {logout, updatePermissions} from '../../View/Modules/Login/LoginAction';
import {withRouter} from 'react-router-dom';
import {loggedInDetails} from '../../View/Modules/Login/LoginAxios';
import {usePrevious} from '../../Utilities/hooks';
import {handleAllPermissions} from '../../Utilities/Permission';

const LoginDetails = (props) => {
    const dispatch = useDispatch();
    const login = useSelector((state) => state.login);
    const client=useSelector((state)=>state.client)
    const prevLogin = usePrevious(login);

    useEffect(() => {
        if (
            (login.isFetchingLoggedInDetailsSuccess && !prevLogin.isFetchingLoggedInDetailsSuccess) ||
            (login.loginSuccess && !prevLogin.loginSuccess)
        ) {
            const _permissions = handleAllPermissions(login?.userDetails?.data);
            dispatch(updatePermissions(_permissions));
        }
    }, [login]);

    useEffect(() => {
        const {history} = props;
        if (history?.location?.pathname === '/') return;
        if (!login?.userDetails?.data) {
            history.push('/');
            dispatch(logout());
            return;
        }
        dispatch(loggedInDetails({}, login?.userDetails?.data?.accessToken,client.selectedClient));
    }, []);

    return '';
};

export default withRouter(LoginDetails);
