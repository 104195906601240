import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import FileCopy from '@material-ui/icons/FileCopy';

import {logout} from '../Login/LoginAction';
import {copyText, debounce, next, prev} from '../../../Utilities/HelperFunctions';
import {fetchGlacierSeries, fetchGlacierTournaments} from '../ToCalendar/action';

import TableLayout from '../../Layouts/TableLayout/TableLayout';
import Snackbar from '../../../Components/Snackbar/Snackbar';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';
import Button from '../../../Components/Button/Button';
import {ENTER, NEXT, SERIES_LIFECYCLE_OP, SORT_ORDER_OP, TOURNAMENT_TIERS} from '../../../Utilities/Constants';
import {useDebounce, usePrevious} from '../../../Utilities/hooks';
import DropDown from '../../../Components/Dropdown/Dropdown';
import {getTOGameList} from '../TOGame/AxioCalls';
import DateRangeSelect from '../../../Components/DateRangeSelect';
import Table from '../../../Components/Table/Table';
import AutoCompleteField from '../../../Components/AutoCompleteField/AutoCompleteField';

const SeriesCalendar = (props) => {
    const {history} = props;
    const initialFilter = {offset: 0, limit: 20};
    const dispatch = useDispatch();
    const client = useSelector((state) => state.client);
    const login = useSelector((state) => state.login);
    const toCalendar = useSelector((state) => state.toCalendar);
    const prevToCalendar = usePrevious(toCalendar);
    const toGame = useSelector((state) => state.toGame);
    const prevToGame = usePrevious(toGame);
    const [gameOptions, setGameOptions] = useState({});
    const [tournamentOptions, setTournamentOptions] = useState([]);
    const [filters, setFilters] = useState(initialFilter);
    const {limit, offset, immediate, sorting, ..._filters} = filters;
    const debouncedFilters = useDebounce(filters, {immediate: immediate});
    const [state, setState] = useState({});
    const [series, setSeries] = useState({});

    const page = offset / limit + 1;
    const lastPage = Math.ceil((series?.count || 0) / limit) || 1;

    const {errorMsg} = state;
    const loading = !!toCalendar.isFetchingGlacierSeries || false;

    useEffect(() => {
        if (!login?.userDetails?.data) {
            history.push('/');
            dispatch(logout());
            return;
        }
        dispatch(getTOGameList({listType: 'ALL'}, login?.userDetails?.data?.accessToken, client.selectedClient));
        handleTournamentDebounce();
    }, []);

    useEffect(() => {
        if (login?.userDetails?.data) handleFetchSeries();
    }, [debouncedFilters]);

    useEffect(() => {
        if (toCalendar.isFetchingGlacierSeriesSuccess && !prevToCalendar.isFetchingGlacierSeriesSuccess) {
            setSeries(toCalendar?.glacierSeries || {});
        }

        if (toCalendar.isFetchingGlacierTOSuccess && !prevToCalendar.isFetchingGlacierTOSuccess) {
            const options = toCalendar?.glacierTO?.list?.map((to) => ({title: to.name, value: to.id}));
            setTournamentOptions(options || []);
        }

        if (
            (toCalendar.isFetchingGlacierSeriesFail && !prevToCalendar.isFetchingGlacierSeriesFail) ||
            (toCalendar.isFetchingGlacierTOFail && !prevToCalendar.isFetchingGlacierTOFail)
        ) {
            handleOnStateChange({errorMsg: toCalendar.message.msg});
        }

        if (toGame.TOGameListSuccess && !prevToGame.TOGameListSuccess) {
            const temp = {};
            (toGame?.TOGameList?.data?.data || []).forEach((item) => {
                temp[item.id] = {text: item.gameName, value: item.id};
            });
            setGameOptions(temp);
        }
        if (toGame.TOGameListFail && !prevToGame.TOGameListFail) {
            handleOnStateChange({errorMsg: toGame.errorMessage});
        }
    }, [toCalendar, toGame]);

    const handleFetchSeries = () => {
        const data = {..._filters};
        const query = {};
        if (data.tournamentId) data.tournamentId = data?.tournamentId?.value;
        if (sorting) {
            query.sort = sorting?.split('/')?.[0];
            query.order = sorting?.split('/')?.[1];
        }
        dispatch(fetchGlacierSeries({filters: data, offset, limit, detailed: true, ...query}));
    };

    const handleTournamentDebounce = debounce(({name}) => {
        dispatch(fetchGlacierTournaments({filters: {name}}));
    });

    const handleSearchTournament = (e, name) => {
        if (e?.target?.value && name && e?.target?.value === name) handleTournamentDebounce({name});
    };

    const onReset = () => {
        setFilters({...initialFilter, immediate: true});
    };

    const onNextORPrev = (action) => {
        const pageObj = action === NEXT ? next({page}, {lastPage}) : prev({page});
        if (pageObj) setFilters({...filters, offset: limit * (pageObj?.page - 1), immediate: true});
    };

    const handleFilter = (field, value, debounce = true) => {
        const tempFilter = {};
        if (field === 'dates') {
            tempFilter.startDateRange = {from: value.start, to: value.end};
        } else if (field !== ENTER) tempFilter[field] = value;
        setFilters({...filters, ...tempFilter, offset: 0, immediate: !debounce});
    };

    const handleOnStateChange = (stateObj = {}) => {
        setState((prevState) => ({...prevState, ...stateObj}));
    };

    const handleCopy = (text) => {
        copyText(text, (msg) => handleOnStateChange({errorMsg: msg}));
    };

    const columns = [
        {
            Header: 'Game',
            accessor: 'gameId',
            Cell: (props) => <span>{gameOptions?.[props?.value]?.text || '-'}</span>,
        },
        {
            Header: 'TO Name',
            accessor: 'subStage',
            width: 250,
            Cell: (props) => (
                <span>
                    {props?.value?.tournament?.name || '-'} <br />
                    {!!props?.value?.tournament?.id && (
                        <span className="clickable-icon" onClick={() => handleCopy(props?.value?.tournament?.id)}>
                            {props?.value?.tournament?.id}
                            <FileCopy titleAccess="Copy" className="ml-10" fontSize="small" />
                        </span>
                    )}
                </span>
            ),
        },
        {
            Header: 'Series',
            accessor: 'name',
            width: 250,
            Cell: (props) => (
                <span>
                    {props?.value || '-'} <br />
                    {!!props?.original?.id && (
                        <span className="clickable-icon" onClick={() => handleCopy(props?.original?.id)}>
                            {props?.original?.id}
                            <FileCopy titleAccess="Copy" className="ml-10" fontSize="small" />
                        </span>
                    )}
                </span>
            ),
        },
        {
            Header: 'Tier',
            accessor: 'subStage',
            Cell: (props) => <span>{props?.value?.tournament?.tier}</span>,
        },
        {
            Header: 'Start Date/Time',
            accessor: 'start',
            Cell: (props) => <span>{!!props?.value && moment(props?.value).format('DD/MM/YYYY HH:mm')}</span>,
        },
        {
            Header: 'Status',
            accessor: 'lifecycle',
            Cell: (props) => <span>{props?.value || '-'}</span>,
        },
    ];

    return (
        <TableLayout location="Series Calendar" history={history} hideSearchBar>
            {!!loading && <LoadingModal open={!!loading} />}
            {!!errorMsg && (
                <Snackbar open={!!errorMsg} message={errorMsg} onClose={() => handleOnStateChange({errorMsg: ''})} />
            )}

            <div className="filter-container my-10">
                <AutoCompleteField
                    label="Tournament"
                    options={tournamentOptions}
                    value={filters.tournamentId || null}
                    onChange={(value) => !!value && handleFilter('tournamentId', value, false)}
                    inputChange={handleSearchTournament}
                    disableClearable
                />
                <DateRangeSelect
                    label="Date range"
                    startDate={filters?.startDateRange?.from}
                    endDate={filters?.startDateRange?.to}
                    isClearable={true}
                    onChange={(dates) => handleFilter('dates', dates, false)}
                />
                <DropDown
                    label="Game"
                    value={filters.gameId || ''}
                    menuItems={Object.values(gameOptions || {})}
                    onChange={(value) => handleFilter('gameId', value, false)}
                />
                <DropDown
                    label="Tier"
                    value={filters.tier || ''}
                    menuItems={TOURNAMENT_TIERS}
                    onChange={(value) => handleFilter('tier', value, false)}
                />
                <DropDown
                    label="Status"
                    value={filters.lifecycle || ''}
                    menuItems={SERIES_LIFECYCLE_OP}
                    onChange={(value) => handleFilter('lifecycle', value, false)}
                />
                <DropDown
                    label="Sorting"
                    value={filters.sorting || ''}
                    menuItems={SORT_ORDER_OP}
                    onChange={(value) => handleFilter('sorting', value, false)}
                />
                <Button buttonText="Reset" onClick={onReset} />
            </div>
            <Table
                data={series?.list || []}
                columns={columns}
                page={page}
                onPrevClick={onNextORPrev}
                onNextClick={() => onNextORPrev(NEXT)}
                disableNext={page === lastPage}
            />
        </TableLayout>
    );
};

export default SeriesCalendar;
