export const TOMATCH_LIST = 'TOMATCH_LIST';
export const TOMATCH_LIST_SUCCESS = 'TOMATCH_LIST_SUCCESS';
export const TOMATCH_LIST_FAIL = 'TOMATCH_LIST_FAIL';

export const ADD_TOMATCH = 'ADD_TOMATCH';
export const ADD_TOMATCH_SUCCESS = 'ADD_TOMATCH_SUCCESS';
export const ADD_TOMATCH_FAIL = 'ADD_TOMATCH_FAIL';

export const MODIFY_TOMATCH = 'MODIFY_TOMATCH';
export const MODIFY_TOMATCH_SUCCESS = 'MODIFY_TOMATCH_SUCCESS';
export const MODIFY_TOMATCH_FAIL = 'MODIFY_TOMATCH_FAIL';

export const GET_TOMATCH_PARTICIPATION = 'GET_TOMATCH_PARTICIPATION';
export const GET_TOMATCH_PARTICIPATION_SUCCESS = 'GET_TOMATCH_PARTICIPATION_SUCCESS';
export const GET_TOMATCH_PARTICIPATION_FAIL = 'GET_TOMATCH_PARTICIPATION_FAIL';

export const CANCEL_TOMATCH = 'CANCEL_TOMATCH';
export const CANCEL_TOMATCH_SUCCESS = 'CANCEL_TOMATCH_SUCCESS';
export const CANCEL_TOMATCH_FAIL = 'CANCEL_TOMATCH_FAIL';

export const GET_TOMATCH = 'GET_TOMATCH';
export const GET_TOMATCH_SUCCESS = 'GET_TOMATCH_SUCCESS';
export const GET_TOMATCH_FAIL = 'GET_TOMATCH_FAIL';

export const EDIT_TOMATCH = 'EDIT_TOMATCH';
