import React, {useState, useEffect} from 'react';
import {Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel} from '@material-ui/core';
import Button from '../../../../Components/Button/Button';
import {useDispatch, useSelector} from 'react-redux';
import {usePrevious} from '../../../../Utilities/hooks';
import Snackbar from '../../../../Components/Snackbar/Snackbar';
import LoadingModal from '../../../../Components/LoadingModal/LoadingModal';
import {addAndRemoveFRInMatch} from '../AxioCalls';
import PropTypes from 'prop-types';

const MarkMatchFRModal = (props) => {
    const {details, open, handleClose} = props;
    const {matchId, removeMatchFR} = details;
    const [state, setState] = useState({isGameSuperMatch: true});
    const {message, loading, isGameSuperMatch, confirmation} = state;
    const dispatch = useDispatch();
    const login = useSelector((state) => state.login);
    const match = useSelector((state) => state.match);
    const client = useSelector((state) => state.client);
    const prevMatch = usePrevious(match);

    useEffect(() => {
        if (match.isMatchMarkAsFR && !prevMatch.isMatchMarkAsFR) {
            onChange({loading: true});
        }
        if (
            match.isMatchMarkAsFRSuccess &&
            !prevMatch.isMatchMarkAsFRSuccess &&
            !!match?.martFRDetails?.data?.confirmationRequired
        ) {
            onChange({confirmation: match?.martFRDetails?.msg, message: match?.martFRDetails?.msg, loading: false});
        }
        if (match.isMatchMarkAsFRFail && !prevMatch.isMatchMarkAsFRFail) {
            onChange({message: match?.errorMessage?.msg, loading: false});
        }
    }, [match]);

    const onChange = (obj = {}) => {
        setState({...state, ...obj});
    };

    const handleTextContent = () => {
        if (!!removeMatchFR) {
            return 'Are you sure you want to remove this match as a Fantasy Royal ?';
        } else if (!!confirmation) {
            return confirmation + ' do you wish to continue ?';
        } else {
            return 'Are you sure you want to mark this match as a Fantasy Royal ?';
        }
    };

    const handleSubmit = () => {
        let data = {matchId};
        if (!!isGameSuperMatch) data.isGameSuperMatch = true;
        if (!!removeMatchFR) data.removeMatchFR = true;
        if (!!confirmation) data.isConfirmed = true;
        dispatch(addAndRemoveFRInMatch(data, login?.userDetails?.data?.accessToken, client.selectedClient));
    };

    return (
        <Dialog open={open} onClose={handleClose} scroll="paper" maxWidth="sm" fullWidth>
            {!!message && <Snackbar open={!!message} message={message} onClose={() => onChange({message: ''})} />}
            {!!loading && <LoadingModal open={!!loading} />}
            <DialogTitle>Match Fantasy Royal</DialogTitle>
            <DialogContent dividers={true}>
                <h2>{handleTextContent()}</h2>
                {!removeMatchFR && (
                    <FormControlLabel
                        label="Allow FR on game filter also"
                        control={
                            <Checkbox
                                checked={!!isGameSuperMatch}
                                color="primary"
                                onChange={(e) => onChange({isGameSuperMatch: e.target.checked})}
                            />
                        }
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button buttonText="Cancel" onClick={handleClose} />
                <Button buttonText={!!confirmation ? 'Confirm and Continue' : 'Submit'} onClick={handleSubmit} />
            </DialogActions>
        </Dialog>
    );
};

MarkMatchFRModal.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    details: PropTypes.object,
};

MarkMatchFRModal.defaultProps = {
    handleClose: () => {},
    open: false,
    details: {},
};

export default MarkMatchFRModal;
