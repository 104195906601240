import React from 'react';

import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        color: 'white',
        backgroundColor: '#1F2535',
    }),
    control: () => ({
        width: 200,
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return {...provided, transition, opacity};
    },
};

export default function DropDownMulti(props) {
    const handleChange = (event) => {
        props.onChange(event);
    };
    const {placeholder, options, value} = props;
    let defaultValue = options.filter((o) => value.includes(o.value));

    return (
        <Select
            styles={customStyles}
            value={defaultValue}
            placeholder={placeholder}
            closeMenuOnSelect={false}
            components={animatedComponents}
            isMulti
            options={options}
            onChange={handleChange}
        />
    );
}
