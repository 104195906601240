import React, {useState} from 'react';
import styles from './AddEditSwitchConfiguration.module.scss';
import MuiModal from '../../../../Components/MuiModal/MuiModal';
import {Close, Refresh, Save} from '@material-ui/icons';
import {Box, IconButton, styled} from '@material-ui/core';
import {ColorPalette} from '../../../../Utilities/constants/color-constants';
import MuiInputField from '../../../../Components/MuiInputField/MuiInputField';
import MuiButton from '../../../../Components/MuiButton/MuiButton';
import MuiSelectField from '../../../../Components/MuiSelectField/MuiSelectField';
import {SUBSTITUTION_FEE_OPTIONS} from '../../../../Utilities/Constants';
import {useDispatch, useSelector} from 'react-redux';
import {addSwitchConfig} from '../../actions';

const AddEditSwitchConfiguration = (props) => {
    const {addEditSwitchConfig, onClickClose, onSuccess, open} = props;
    const dispatch = useDispatch();
    const login = useSelector((state) => state.login);
    const client = useSelector((state) => state.client);
    const [formState, setFormState] = useState(addEditSwitchConfig || {});
    const {name, totalSwitchesAllowed, teamsCountLimit, switchFees = []} = formState;

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = {
            name,
            teamsCountLimit: parseInt(teamsCountLimit),
            totalSubsAllowed: parseInt(totalSwitchesAllowed),
            subsFee: switchFees,
        };
        console.log('DATA', data);
        dispatch(addSwitchConfig(data, login?.userDetails?.data?.accessToken, client?.selectedClient));
        onSuccess();
    };

    const handleReset = () => {
        setFormState({});
    };

    const handleFormData = (formObj) => {
        setFormState({...formState, ...formObj});
    };

    const handleOnChangeSwitchFees = (_switchFees, _value, _index) => {
        const tempSwitchFees = _switchFees.slice();
        tempSwitchFees.splice(_index, 1, parseInt(_value));
        handleFormData({switchFees: tempSwitchFees});
    };

    return (
        <MuiModal onClickClose={onClickClose} open={open} className={styles.container}>
            {/* header */}
            <section className={styles.header}>
                <h3>{`${!!addEditSwitchConfig?.id ? 'Edit' : 'Add'} Switch Configuration`}</h3>
                <CustomIconButton onClick={onClickClose} className={styles.closeButton}>
                    <Close className={styles.closeButtonIcon} />
                </CustomIconButton>
            </section>
            {/* divider */}
            <section className={styles.divider} />
            {/* body */}
            <section className={styles.body}>
                <Box component="form" onSubmit={handleSubmit} onReset={handleReset} className={styles.form}>
                    <MuiInputField
                        label="Config Name"
                        name="name"
                        onChange={(event) => handleFormData({name: event.target.value})}
                        required={true}
                        size="small"
                        type="text"
                        value={name || ''}
                    />
                    <MuiInputField
                        label="Maximum Teams Allowed"
                        name="teamsCountLimit"
                        onChange={(event) => handleFormData({teamsCountLimit: event.target.value})}
                        required={true}
                        size="small"
                        type="number"
                        value={teamsCountLimit || ''}
                    />
                    <MuiInputField
                        label="Total Switch Allowed"
                        name="totalSwitchesAllowed"
                        onChange={(event) => handleFormData({totalSwitchesAllowed: event.target.value})}
                        required={true}
                        size="small"
                        type="number"
                        value={totalSwitchesAllowed || ''}
                    />
                    {!!totalSwitchesAllowed && (
                        <div className={styles.switchFeesWrapper}>
                            {Array(parseInt(totalSwitchesAllowed))
                                .fill(null)
                                .map((_, index) => (
                                    <MuiSelectField
                                        key={`switchFees${index + 1}`}
                                        label={`Switch Fees ${index + 1}`}
                                        name={`switchFees${index + 1}`}
                                        fullWidth={true}
                                        onChange={(event) =>
                                            handleOnChangeSwitchFees(switchFees, event.target.value, index)
                                        }
                                        options={SUBSTITUTION_FEE_OPTIONS}
                                        required={true}
                                        size="small"
                                        value={switchFees[index]}
                                    />
                                ))}
                        </div>
                    )}
                    <div className={styles.formButtonGroup}>
                        <MuiButton
                            className={styles.resetButton}
                            type="reset"
                            buttonText="Reset"
                            buttonIcon={<Refresh />}
                        />
                        <MuiButton
                            className={styles.submitButton}
                            type="submit"
                            buttonText="Submit"
                            buttonIcon={<Save />}
                        />
                    </div>
                </Box>
            </section>
        </MuiModal>
    );
};

export default AddEditSwitchConfiguration;

const CustomIconButton = styled(IconButton)({
    width: '28px',
    height: '28px',

    '&:hover': {
        backgroundColor: ColorPalette.NIGHT_RIDER,
    },
});
