import React, {useCallback, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';
import FileCopy from '@material-ui/icons/FileCopy';
import Button from '../../Components/Button/Button';

import Snackbar from '../../Components/Snackbar/Snackbar';
import LoadingModal from '../../Components/LoadingModal/LoadingModal';
import ImageInput from '../../Components/ImageInput/ImageInput';
import {copyText, errorReasonHandler} from '../../Utilities/HelperFunctions';
import apis from '../../apiCalls/apis';

const UploadImageOnS3Modal = (props) => {
    const {handleClose, open} = props;
    let submitRef = useRef();
    const login = useSelector((state) => state.login);
    const [state, setState] = useState({});
    let {errorMessage, image, loading = false, uploadedData = {}} = state;

    const handleChangeState = (key, value) => {
        setState({...state, [key]: value});
    };

    const handleUploadImage = useCallback(
        async (data, token) => {
            try {
                const response = await apis.commonUploadImage(data, token);
                if (response.data.status === 'success') {
                    setState({...state, uploadedData: response.data.data, loading: false});
                } else if (response.data.status === 'fail') {
                    setState({
                        ...state,
                        loading: false,
                        errorMessage: errorReasonHandler(response.data.errorData.errorReason),
                    });
                }
            } catch (e) {
                setState({...state, errorMessage: e.message, loading: false});
            }
        },
        [state],
    );

    const handleSubmit = (e) => {
        !!e && e.preventDefault();
        const data = new FormData();
        data.append('image', image);
        handleUploadImage(data, login.userDetails.data.accessToken);
        handleChangeState('loading', true);
    };

    const handleCopy = (text) => {
        copyText(
            text,
            (msg) => {
                setState({...state, errorMessage: msg});
            },
            (err) => {
                setState({...state, errorMessage: err});
            },
        );
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            scroll="paper"
            maxWidth="sm"
            fullWidth
            disableEscapeKeyDown
            disableBackdropClick
        >
            {!!errorMessage && (
                <Snackbar
                    open={!!errorMessage}
                    message={errorMessage}
                    onClose={() => handleChangeState('errorMessage', '')}
                />
            )}
            {!!loading && <LoadingModal open={loading} />}
            <DialogTitle>Upload Image</DialogTitle>
            <DialogContent dividers={true}>
                <form onSubmit={handleSubmit}>
                    <ImageInput
                        label="Upload Image"
                        type="file"
                        name="file"
                        required
                        onChange={(value) => handleChangeState('image', value)}
                    />
                    <Button className="d-none" buttonText="Submit" type="submit" buttonRef={submitRef} />
                </form>
                {!!uploadedData.s3Url && (
                    <div className="margin-top-30">
                        <h3>
                            Copy CDN Url:
                            <FileCopy
                                titleAccess="Copy"
                                className="clickable-icon ml-15"
                                fontSize="small"
                                onClick={() => handleCopy(uploadedData.image)}
                            />
                        </h3>
                        <p>{uploadedData.image}</p>
                        <hr />
                        <h3>
                            Copy S3 Url:
                            <FileCopy
                                titleAccess="Copy"
                                className="clickable-icon ml-15"
                                fontSize="small"
                                onClick={() => handleCopy(uploadedData.s3Url)}
                            />
                        </h3>
                        <p className="grid-8-4-container">{uploadedData.s3Url}</p>
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button buttonText="Cancel" onClick={handleClose} />
                <Button buttonText="Submit" onClick={() => submitRef.current.click()} />
            </DialogActions>
        </Dialog>
    );
};

UploadImageOnS3Modal.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
};

UploadImageOnS3Modal.defaultProps = {
    handleClose: () => {},
    open: false,
};

export default UploadImageOnS3Modal;
