import {
    SQUAD_LIST,
    SQUAD_LIST_SUCCESS,
    SQUAD_LIST_FAIL,
    ADD_SQUAD,
    ADD_SQUAD_SUCCESS,
    ADD_SQUAD_FAIL,
    UPDATE_SQUAD,
    UPDATE_SQUAD_SUCCESS,
    UPDATE_SQUAD_FAIL,
    DELETE_SQUAD,
    DELETE_SQUAD_SUCCESS,
    DELETE_SQUAD_FAIL,
    EDIT_SQUAD,
    GET_SQUAD,
    GET_SQUAD_SUCCESS,
    GET_SQUAD_FAIL,
} from './SquadConstants';

/**
 * list squad api
 */
export function listSquad() {
    return {
        type: SQUAD_LIST,
    };
}
/**
 *handle list squad api success
 * @param {*} data data from api
 */
export function listSquadSuccess(data) {
    return {
        type: SQUAD_LIST_SUCCESS,
        payload: data,
    };
}
/**
 * handle list squad api failure
 * @param {*} error error from api
 */
export function listSquadFail(error) {
    return {
        type: SQUAD_LIST_FAIL,
        payload: error,
    };
}

//  create squad
/**
 * create squad api
 */
export function addSquadRequest() {
    return {
        type: ADD_SQUAD,
    };
}
/**
 *handle create squad api success
 * @param {*} data data from api
 */
export function addSquadSuccess(data) {
    return {
        type: ADD_SQUAD_SUCCESS,
        payload: data,
    };
}
/**
 *handle create squad api failure
 * @param {*} error error from api
 */
export function addSquadFail(error) {
    return {
        type: ADD_SQUAD_FAIL,
        payload: error,
    };
}

/**
 * store single squad details
 * @param {*} squad squad details
 */
export function editSquad(squad) {
    return {
        type: EDIT_SQUAD,
        payload: squad,
    };
}

// edit
/**
 * edit squad api
 */
export function updateSquads() {
    return {
        type: UPDATE_SQUAD,
    };
}
/**
 *handle edit squad api success
 * @param {*} data data from api
 */
export function updateSquadsSuccess(data) {
    return {
        type: UPDATE_SQUAD_SUCCESS,
        payload: data,
    };
}
/**
 *handle edit squad api failure
 * @param {*} error error from api
 */
export function updateSquadsFail(error) {
    return {
        type: UPDATE_SQUAD_FAIL,
        payload: error,
    };
}

// delete
/**
 * delete squad api
 */
export function deleteSquads() {
    return {
        type: DELETE_SQUAD,
    };
}
/**
 *handle delete squad api success
 * @param {*} data data from api
 */
export function deleteSquadsSuccess(data) {
    return {
        type: DELETE_SQUAD_SUCCESS,
        payload: data,
    };
}
/**
 *handle delete squad api failure
 * @param {*} error error from api
 */
export function deleteSquadsFail(error) {
    return {
        type: DELETE_SQUAD_FAIL,
        payload: error,
    };
}

//GET SQUAD
/**
 * get squad api
 */
export function getSquadRequest(data) {
    return {
        type: GET_SQUAD,
        payload: data,
    };
}
/**
 *handle get squad api success
 * @param {*} data data from api
 */
export function getSquadSuccess(data) {
    return {
        type: GET_SQUAD_SUCCESS,
        payload: data,
    };
}
/**
 *handle get squad api failure
 * @param {*} error error from api
 */
export function getSquadFail(data) {
    return {
        type: GET_SQUAD_FAIL,
        payload: data,
    };
}
