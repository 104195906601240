import React from 'react';
import PropTypes from 'prop-types';
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';
import Button from '../../../../Components/Button/Button';

import Snackbar from '../../../../Components/Snackbar/Snackbar';
import {connect} from 'react-redux';
import LoadingModal from '../../../../Components/LoadingModal/LoadingModal';
import Input from '../../../../Components/Input/Input';
import {addContestInfluencer, uploadInfluencerImage} from '../AxioCalls';
import ImageInput from '../../../../Components/ImageInput/ImageInput';

class AddContestInfluencerModal extends React.Component {
    static propTypes = {
        open: PropTypes.bool,
        handleClose: PropTypes.func,
        addInfluencer: PropTypes.object,
    };

    static defaultProps = {
        handleClose: () => {},
        open: false,
        addInfluencer: {},
    };

    submitRef = React.createRef();

    constructor(props) {
        super(props);
        let {addInfluencer} = props;
        this.state = {
            errorMessage: '',
            influencerName: addInfluencer.displayName,
            influencerImage: addInfluencer.icon,
        };
    }

    componentDidMount() {}

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {
            contest,
            contest: {
                isUploadingInfluencerImageSuccess,
                isAddContestInfluencerFail,
                isUploadingInfluencerImageFail,
                errorMessage,
            },
        } = this.props;

        if (isUploadingInfluencerImageSuccess && !prevProps.contest.isUploadingInfluencerImageSuccess) {
            this.setState({influencerImage: contest.influencerImage.data.imageurl});
        }

        if (
            (isAddContestInfluencerFail && !prevProps.contest.isAddContestInfluencerFail) ||
            (isUploadingInfluencerImageFail && !prevProps.contest.isUploadingInfluencerImageFail)
        ) {
            this.setState({errorMessage: errorMessage.errorReason});
        }
    }

    handleOnChange = (key, value) => {
        this.setState({[key]: value});
    };

    handleUploadImage = (file) => {
        let {login, client} = this.props;
        if (file) {
            const data = new FormData();
            data.append('influencerImage', file);
            this.props.uploadInfluencerImage(data, login.userDetails.data.accessToken, client.selectedClient);
        }
    };

    handleSubmit = (e) => {
        !!e && e.preventDefault();
        let {login, addInfluencer, client} = this.props;
        let {influencerName, influencerImage} = this.state;
        let data = {
            contestId: addInfluencer.contestId,
            influencerImage,
            influencerName,
        };
        this.props.addContestInfluencer(data, login.userDetails.data.accessToken, client.selectedClient);
    };

    render() {
        let {handleClose, open, contest} = this.props;
        let {errorMessage, influencerImage, influencerName} = this.state;

        return (
            <Dialog
                open={open}
                onClose={handleClose}
                scroll="paper"
                maxWidth="sm"
                fullWidth
                disableEscapeKeyDown
                disableBackdropClick
            >
                <Snackbar
                    open={!!errorMessage}
                    message={errorMessage}
                    onClose={() => this.setState({errorMessage: ''})}
                />
                <LoadingModal open={!!contest.isUploadingInfluencerImage || !!contest.isAddContestInfluencer} />
                <DialogTitle>Add Contest Influencer</DialogTitle>
                <DialogContent dividers={true}>
                    <form onSubmit={this.handleSubmit}>
                        <Input
                            className="mb-15"
                            label="Influencer Name"
                            type="text"
                            name="influencerName"
                            required
                            value={influencerName || ''}
                            placeholder="Enter Influencer Name"
                            onChange={(value) => this.handleOnChange('influencerName', value)}
                        />

                        <ImageInput
                            label="Upload Influencer Image"
                            type="file"
                            name="file"
                            onChange={this.handleUploadImage}
                        />

                        {!!influencerImage && (
                            <div className="mb-15">
                                <Input
                                    label="Influencer Image"
                                    type="text"
                                    name="image"
                                    value={influencerImage || ''}
                                    placeholder="Influencer Image"
                                    disabled={true}
                                />
                                <img src={influencerImage} style={{height: 150}} alt="influencerImage" />
                            </div>
                        )}

                        <Button className="d-none" buttonText="Submit" type="submit" buttonRef={this.submitRef} />
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button buttonText="Cancel" onClick={handleClose} />
                    <Button buttonText="Submit" onClick={() => this.submitRef.current.click()} />
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        contest: state.contest,
        client: state.client,
    };
};

export default connect(mapStateToProps, {uploadInfluencerImage, addContestInfluencer})(AddContestInfluencerModal);
