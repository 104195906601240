import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';
import Button from '../../../../Components/Button/Button';

import Snackbar from '../../../../Components/Snackbar/Snackbar';
import LoadingModal from '../../../../Components/LoadingModal/LoadingModal';
import Input from '../../../../Components/Input/Input';
import DropDown from '../../../../Components/Dropdown/Dropdown';
import {
    BOOSTER_CONDITIONS_OPTIONS,
    BOOSTER_PENALTY_REWARD_OPTIONS,
    COMMON_STATUS,
} from '../../../../Utilities/Constants';
import {useDispatch, useSelector} from 'react-redux';
import {usePrevious} from '../../../../Utilities/hooks';
import {getTOGameList} from '../../TOGame/AxioCalls';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
// import DeleteIcon from "@material-ui/icons/Delete";
import {createBoosters, fetchBooster} from '../action';
import ImageInput from '../../../../Components/ImageInput/ImageInput';

const CreateEditBoosterModal = (props) => {
    const {open, onClose, boosterData, localBoosterData, getUpdatedBoosterData} = props;
    const submitRef = useRef(null);
    const dispatch = useDispatch();
    const client = useSelector((state) => state.client);
    const login = useSelector((state) => state.login);
    const boosters = useSelector((state) => state.boosters);
    const prevBoosters = usePrevious(boosters);
    const toGame = useSelector((state) => state.toGame);
    const prevToGame = usePrevious(toGame);
    const [gameOptions, setGameOptions] = useState([]);
    // const [gameAttributes, setGameAttributes] = useState([]);

    const [state, setState] = useState({});
    const [formState, setFormState] = useState({conditions: [{}], ...localBoosterData});
    const {errorMessage} = state;
    const disabled = !!boosterData.id;
    const loading = !!boosters.isFetchingBooster || !!boosters.isCreatingBooster || !!toGame.TOGameListRequest || false;

    useEffect(() => {
        if (boosterData.id) {
            dispatch(fetchBooster({id: boosterData.id}, login?.userDetails?.data?.accessToken));
        }
        dispatch(getTOGameList({listType: 'ALL'}, login?.userDetails?.data?.accessToken, client.selectedClient));
    }, []);

    useEffect(() => {
        if (boosters.isFetchingBoosterSuccess && !prevBoosters.isFetchingBoosterSuccess) {
            setFormState({...(boosters.boosterDetail || {})});
        }

        if (
            (boosters.isFetchingBoosterFail && !prevBoosters.isFetchingBoosterFail) ||
            (boosters.isCreatingBoosterFail && !prevBoosters.isCreatingBoosterFail)
        ) {
            handleOnStateChange({errorMessage: boosters.message.msg});
        }

        if (toGame.TOGameListSuccess && !prevToGame.TOGameListSuccess) {
            const temp = toGame?.TOGameList?.data?.data?.map((item) => ({text: item.gameName, value: item.id}));
            setGameOptions(temp);
        }
        if (toGame.TOGameListFail && !prevToGame.TOGameListFail) {
            handleOnStateChange({errorMessage: toGame.errorMessage});
        }
    }, [boosters, toGame]);

    useEffect(() => {
        handleFormStateChange({imageUrl: handleImageUrl(formState.image)});
    }, [formState.image]);

    // useEffect(() => {
    //     if (formState.game) handleGameAttributes(formState.game);
    // }, [formState.game, gameOptions]);

    const handleOnStateChange = (stateObj = {}) => {
        setState((prevState) => ({...prevState, ...stateObj}));
    };

    const handleFormStateChange = (_formState = {}) => {
        setFormState((prevState) => ({...prevState, ..._formState}));
    };

    // const handleGameAttributes = (_gameId) => {
    //     let tempAttributes = toGame?.TOGameList?.data?.data?.filter(item => item.id === _gameId)[0]?.validPlayerStats;
    //     tempAttributes = tempAttributes?.map(attr => ({text: attr.attributeName, value: attr.attributeName}));
    //     setGameAttributes([...(tempAttributes || [])]);
    // }

    const handleChangeCondition = (_condition, index) => {
        const conditions = [...(formState.conditions || [])];
        if (!_condition) conditions.push({});
        else conditions[index] = _condition;
        handleFormStateChange({conditions});
    };

    const handleRemoveCondition = (index) => {
        const conditions = [...(formState.conditions || [])];
        conditions.splice(index, 1);
        handleFormStateChange({conditions});
    };

    const handleSubmit = (e) => {
        !!e && e.preventDefault();
        const {name, displayName, status, game, penalty, reward, conditions, desc, image} = formState;

        if (localBoosterData?.id) {
            getUpdatedBoosterData({
                ...localBoosterData,
                name,
                displayName,
                status,
                game,
                penalty,
                reward,
                conditions,
                desc,
                image,
            });
            return;
        }

        const data = {
            name,
            displayName,
            status,
            game,
            desc,
            penalty: JSON.stringify(penalty),
            reward: JSON.stringify(reward),
            conditions: JSON.stringify(conditions),
            image, //keep file in last because of sails form data
        };

        dispatch(createBoosters(data, login?.userDetails?.data?.accessToken));
    };

    const handleImageUrl = (image) => {
        if (!image) return false;
        if (typeof image === 'string') return image;
        return URL.createObjectURL(image);
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            scroll="paper"
            maxWidth="sm"
            fullWidth
            disableEscapeKeyDown
            disableBackdropClick
        >
            {!!loading && <LoadingModal open={!!loading} />}
            {!!errorMessage && (
                <Snackbar
                    open={!!errorMessage}
                    message={errorMessage}
                    onClose={() => handleOnStateChange({errorMessage: ''})}
                />
            )}

            <DialogTitle>Booster</DialogTitle>
            <DialogContent dividers={true}>
                <form onSubmit={handleSubmit}>
                    <div className="card-grid-container">
                        <Input
                            className="mb-15"
                            label="Name"
                            type="text"
                            name="name"
                            required
                            value={formState.name || ''}
                            placeholder="Enter Name"
                            onChange={(value) => handleFormStateChange({name: value})}
                            disabled={disabled || !!localBoosterData.id}
                        />

                        <Input
                            className="mb-15"
                            label="Display Name"
                            type="text"
                            name="displayName"
                            required
                            value={formState.displayName || ''}
                            placeholder="Enter display Name"
                            onChange={(value) => handleFormStateChange({displayName: value})}
                            disabled={disabled}
                        />

                        <DropDown
                            className="mb-15"
                            label="Status*"
                            menuItems={COMMON_STATUS}
                            required
                            name="status"
                            value={formState.status || ''}
                            placeholder="Select"
                            onChange={(value) => handleFormStateChange({status: value})}
                            disabled={disabled || !!localBoosterData.id}
                        />

                        <DropDown
                            className="mb-15"
                            label="Game*"
                            menuItems={gameOptions || []}
                            required
                            name="status"
                            value={formState.game || ''}
                            placeholder="Select"
                            onChange={(value) => handleFormStateChange({game: value})}
                            disabled={disabled || !!localBoosterData.id}
                        />

                        <DropDown
                            className="mb-15"
                            label="Penalty*"
                            menuItems={BOOSTER_PENALTY_REWARD_OPTIONS}
                            required
                            name="status"
                            value={formState?.penalty?.type || ''}
                            placeholder="Select"
                            onChange={(value) => handleFormStateChange({penalty: {type: value}})}
                            disabled={disabled}
                        />

                        {!!formState?.penalty?.type && (
                            <Input
                                className="mb-15"
                                label="Penalty value"
                                type="number"
                                name="penaltyValue"
                                required
                                value={formState?.penalty?.value || ''}
                                placeholder="Enter penalty value"
                                onChange={(value) =>
                                    handleFormStateChange({
                                        penalty: {...formState?.penalty, value: parseInt(value)},
                                    })
                                }
                                disabled={disabled}
                            />
                        )}

                        <DropDown
                            className="mb-15"
                            label="Reward*"
                            menuItems={BOOSTER_PENALTY_REWARD_OPTIONS}
                            required
                            name="status"
                            value={formState?.reward?.type || ''}
                            placeholder="Select"
                            onChange={(value) => handleFormStateChange({reward: {type: value}})}
                            disabled={disabled}
                        />

                        {!!formState?.reward?.type && (
                            <Input
                                className="mb-15"
                                label="Reward value"
                                type="number"
                                name="rewardValue"
                                required
                                value={formState?.reward?.value || ''}
                                placeholder="Enter reward value"
                                onChange={(value) =>
                                    handleFormStateChange({
                                        reward: {...formState?.reward, value: parseInt(value)},
                                    })
                                }
                                disabled={disabled}
                            />
                        )}
                    </div>
                    <div className="card-grid-container mb-15">
                        <h3 className="my-0">Conditions:</h3>
                        {/*{!disabled && <Button size="sm" buttonText="Add Condition" onClick={handleChangeCondition} />}*/}
                    </div>
                    {formState?.conditions?.map((_condition, index) => (
                        <div className="grid-3-container-and-delete" key={`condition_${index}`}>
                            {/*<DropDown className="mb-15" label="Game Attributes*" menuItems={gameAttributes || []}
                                required
                                name="attribute" value={_condition.attribute || ''} placeholder="Select"
                                onChange={(value) => handleChangeCondition({..._condition, attribute: value}, index)}
                                disabled={disabled} />*/}

                            <Input
                                className="mb-15"
                                label="Game Attribute"
                                type="text"
                                name="attribute"
                                required
                                value={_condition.attribute || ''}
                                placeholder="Enter game attribute name"
                                onChange={(value) => handleChangeCondition({..._condition, attribute: value}, index)}
                                disabled={disabled}
                            />

                            <DropDown
                                className="mb-15"
                                label="Condition*"
                                menuItems={BOOSTER_CONDITIONS_OPTIONS}
                                required
                                name="condition"
                                value={_condition.condition || ''}
                                placeholder="Select"
                                onChange={(value) => handleChangeCondition({..._condition, condition: value}, index)}
                                disabled={disabled}
                            />

                            {!(_condition.condition === 'max' || _condition.condition === 'min') && (
                                <Input
                                    className="mb-15"
                                    label="Value"
                                    type="number"
                                    name="value"
                                    required
                                    value={_condition.value || ''}
                                    placeholder="Enter value"
                                    onChange={(value) =>
                                        handleChangeCondition(
                                            {
                                                ..._condition,
                                                value: parseInt(value),
                                            },
                                            index,
                                        )
                                    }
                                    disabled={disabled}
                                />
                            )}
                            {/*{!disabled &&
                                <DeleteIcon titleAccess="Delete" className="clickable-icon red-color" fontSize="small"
                                    onClick={() => handleRemoveCondition(index)} />}*/}
                        </div>
                    ))}
                    <TextareaAutosize
                        rowsMin={2}
                        className="mb-15 text-area"
                        value={formState.desc || ''}
                        placeholder="Description"
                        required
                        onChange={(e) => handleFormStateChange({desc: e.target.value})}
                        disabled={disabled}
                    />

                    {!!formState.imageUrl && (
                        <div className="mb-15">
                            <p>{formState.imageUrl}</p>
                            <img src={formState.imageUrl} style={{height: 150, maxWidth: '100%'}} alt="booster image" />
                        </div>
                    )}

                    {!disabled && !localBoosterData.id && (
                        <ImageInput
                            label="Upload Image"
                            type="file"
                            name="image"
                            onChange={(value) => handleFormStateChange({image: value})}
                        />
                    )}

                    <Button className="d-none" buttonText="Submit" type="submit" buttonRef={submitRef} />
                </form>
            </DialogContent>
            <DialogActions>
                <Button buttonText="Cancel" onClick={onClose} />
                {!disabled && <Button buttonText="Submit" onClick={() => submitRef.current.click()} />}
            </DialogActions>
        </Dialog>
    );
};

CreateEditBoosterModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    boosterData: PropTypes.object,
    localBoosterData: PropTypes.object,
    getUpdatedBoosterData: PropTypes.func,
};

CreateEditBoosterModal.defaultProps = {
    onClose: () => {},
    getUpdatedBoosterData: () => {},
    open: false,
    boosterData: {},
    localBoosterData: {},
};

export default CreateEditBoosterModal;
