import React from 'react';
import {timeFormat} from '../../../Utilities/Helpers';

import '../../../Utilities/style.css';
import '../Login/login.css';
import TableLayout from '../../Layouts/TableLayout/TableLayout';
import Table from '../../../Components/Table/Table';

import {connect} from 'react-redux';
import {next, prev, reset} from '../../../Utilities/HelperFunctions';

import {getTOMatchAttributeList, deleteTOMatchAttribute} from './AxioCalls';
import {editTOMatchAttribute} from './action';
import {logout} from '../Login/LoginAction';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';
import Snackbar from '../../../Components/Snackbar/Snackbar';

class TOMatchAttributeList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            TOMatchAttribute: [],
            selectAll: false,
            list: [],
            open: false,
            adminpswd: '',
            pageData: {},
            loading: true,
            pageOptions: {
                page: 1,
                pageSize: 50,
                searchString: null,
                sortOrder: null,
                listType: 'Limited',
            },
            sortOptions: [
                {
                    value: 'id',
                    text: 'Match Attribute Id',
                },
                {
                    value: 'name',
                    text: 'Attribute Name',
                },
                {
                    value: 'attribType',
                    text: 'Attrib Type',
                },
                {
                    value: 'attribIcon',
                    text: 'Attrib Icon',
                },
                {
                    value: 'attribImage',
                    text: 'Attrib Image',
                },
                {
                    value: 'attribText',
                    text: 'Attrib Text',
                },
                {
                    value: 'updatedAt',
                    text: 'Last Updated',
                },
            ],
        };
    }

    componentWillMount() {
        const {login, history} = this.props;

        if (!login.userDetails.data) {
            history.push('/');
            this.props.logout();
        } else if (login.userDetails.data.role === 'ORGANIZER') {
            history.push('/');
        } else {
            const {pageOptions} = this.state;
            console.log('Fetching the attribute list');
            this.props.getTOMatchAttributeList(pageOptions, login.userDetails.data.accessToken);
        }
    }

    componentDidUpdate(prevProps) {
        const {pageOptions} = this.state;
        const {login, toMatchAttribute} = this.props;

        if (toMatchAttribute.TOMatchAttributeListSuccess && !prevProps.toMatchAttribute.TOMatchAttributeListSuccess) {
            this.setState({
                list: toMatchAttribute.TOMatchAttributeList.data.data,
                loading: false,
                pageData: toMatchAttribute.TOMatchAttributeList.data.pageData,
            });
        } else if (toMatchAttribute.TOMatchAttributeListFail && !prevProps.toMatchAttribute.TOMatchAttributeListFail) {
            this.setState({
                errorMessage: toMatchAttribute.errorMessage,
                loading: false,
            });
        }
        if (
            toMatchAttribute.deleteTOMatchAttributeSuccess &&
            !prevProps.toMatchAttribute.deleteTOMatchAttributeSuccess
        ) {
            this.setState({
                snackbarOpen: true,
                errorMessage: toMatchAttribute.deleteTOMatchAttribute.msg,
                TOMatchAttribute: [],
                loading: false,
            });
            this.props.getTOMatchAttributeList(pageOptions, login.userDetails.data.accessToken);
        } else if (
            toMatchAttribute.deleteTOMatchAttributeFail &&
            !prevProps.toMatchAttribute.deleteTOMatchAttributeFail
        ) {
            this.setState({
                snackbarOpen: true,
                errorMessage: toMatchAttribute.errorMessage,
                loading: false,
            });
        }
    }

    //store single contest details selected for editing
    onEditTOMatchAttribute(id) {
        const {toMatchAttribute, history} = this.props;

        let TOMatchAttributes = toMatchAttribute.TOMatchAttributeList.data.data;
        const editTOMatchAttribute = TOMatchAttributes.filter((attribute) => attribute.id === id);
        this.props.editTOMatchAttribute(editTOMatchAttribute[0]);
        history.push('/editTOMatchAttribute/' + id);
    }

    //APi call for search and sort
    onSearchandSort(field, value) {
        const {pageOptions} = this.state;
        const {login} = this.props;
        let options = pageOptions;
        options[field] = value;
        this.setState({pageOptions: options});

        if (field === 'searchString' && value.length > 2) {
            this.props.getTOMatchAttributeList(options, login.userDetails.data.accessToken);
        } else if (field === 'sortOrder') {
            console.log('Sort has been triggered!');
            this.props.getTOMatchAttributeList(options, login.userDetails.data.accessToken);
        }
    }

    //on click of next in table
    onNext() {
        const {pageData, pageOptions} = this.state;
        const {login} = this.props;
        let options = next(pageOptions, pageData);

        if (options) {
            this.props.getTOMatchAttributeList(options, login.userDetails.data.accessToken);
            this.setState({pageOptions: options, loading: true});
        }
    }

    //onclick of prev in table
    onPrev() {
        const {pageOptions} = this.state;
        const {login} = this.props;
        let options = prev(pageOptions);
        if (options) {
            this.props.getTOMatchAttributeList(options, login.userDetails.data.accessToken);
            this.setState({pageOptions: options, loading: true});
        }
    }

    onReset() {
        const {pageOptions} = this.state;
        const {login} = this.props;
        let options = reset(pageOptions);
        this.setState({
            pageOptions: options,
        });
        this.props.getTOMatchAttributeList(options, login.userDetails.data.accessToken);
    }

    render() {
        const {list, sortOptions, loading, pageData, TOMatchAttribute, errorMessage, snackbarOpen} = this.state;
        const data = list;
        const {searchString, sortOrder, page} = this.state.pageOptions;
        let disableNext = page === pageData.lastPage;

        const columns = [
            {
                Header: 'MatchAttribute ID',
                accessor: 'id',
                width: 200,
                Cell: (props) => (
                    <span
                        className="table-clickable"
                        onClick={
                            Object.keys(props.value).length >= 1
                                ? this.onEditTOMatchAttribute.bind(this, props.value)
                                : null
                        }
                    >
                        {props.value === undefined || props.value === '' || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Name',
                accessor: 'name',
                width: 200,
                Cell: (props) => (
                    <span>
                        {props.value === undefined || props.value === '' || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Type',
                accessor: 'attribType',

                Cell: (props) => <span>{props.value === undefined || props.value === '' ? '-' : props.value}</span>,
            },
            {
                Header: 'Icon',
                accessor: 'attribIcon',

                Cell: (props) => <span>{props.value === undefined || props.value === '' ? '-' : props.value}</span>,
            },
            {
                Header: 'Image',
                accessor: 'attribImage',

                Cell: (props) => {
                    if (props.value === undefined || props.value === '') {
                        return <span>-</span>;
                    } else {
                        return (
                            <span>
                                <img src={props.value} />
                            </span>
                        );
                    }
                },
            },
            {
                Header: 'Text',
                accessor: 'attribText',

                Cell: (props) => <span>{props.value === undefined || props.value === '' ? '-' : props.value}</span>,
            },
            {
                Header: 'Game',
                accessor: 'game.gameName',

                Cell: (props) => <span>{props.value === undefined || props.value === '' ? '-' : props.value}</span>,
            },
            {
                Header: 'Last Updated',
                accessor: 'updatedAt',

                Cell: (props) => (
                    <span className="date-space">
                        {props.value === undefined || props.value === '' ? '-' : timeFormat(props.value)}
                    </span>
                ),
            },
        ];
        return (
            <TableLayout
                location="TOMatchAttribute"
                history={this.props.history}
                searchbar="Search MatchAttribute Name"
                dispDelete={TOMatchAttribute.length > 0 ? true : false}
                onsearchChange={this.onSearchandSort.bind(this)}
                sortOptions={sortOptions}
                onReset={this.onReset.bind(this)}
                searchString={searchString}
                sortOrder={sortOrder}
            >
                <Snackbar
                    open={snackbarOpen}
                    message={errorMessage}
                    onClose={() => this.setState({snackbarOpen: false})}
                />
                <LoadingModal open={loading} />

                <Table
                    data={data}
                    columns={columns}
                    page={page}
                    onPrevClick={this.onPrev.bind(this)}
                    onNextClick={this.onNext.bind(this)}
                    disableNext={disableNext}
                ></Table>
            </TableLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        toMatchAttribute: state.toMatchAttribute,
    };
};

export default connect(mapStateToProps, {
    getTOMatchAttributeList,
    editTOMatchAttribute,
    deleteTOMatchAttribute,
    logout,
})(TOMatchAttributeList);
