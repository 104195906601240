import React from 'react';
import PropTypes from 'prop-types';
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';
import Button from '../../../../Components/Button/Button';

import Snackbar from '../../../../Components/Snackbar/Snackbar';
import {connect} from 'react-redux';
import LoadingModal from '../../../../Components/LoadingModal/LoadingModal';
import Input from '../../../../Components/Input/Input';
import {bindActionCreators} from 'redux';
import {createDynamicPrizePool, updateDynamicPrizePool} from '../action';
import DropDown from '../../../../Components/Dropdown/Dropdown';
import {COMMON_STATUS} from '../../../../Utilities/Constants';
import FileInput from '../../../../Components/FileInput/FileInput';

class CreateEditDynamicPrizePoolModal extends React.Component {
    static propTypes = {
        open: PropTypes.bool,
        handleClose: PropTypes.func,
        dynamicPrizePoolDetail: PropTypes.object,
    };

    static defaultProps = {
        handleClose: () => {},
        open: false,
        dynamicPrizePoolDetail: {},
    };
    submitRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            platForms: [],
            ...props.dynamicPrizePoolDetail,
        };
    }

    componentDidMount() {}

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {dynamicPrizePools} = this.props;

        if (
            (dynamicPrizePools.isCreatingDynamicPrizePool && !prevProps.dynamicPrizePools.isCreatingDynamicPrizePool) ||
            (dynamicPrizePools.isUpdatingDynamicPrizePool && !prevProps.dynamicPrizePools.isUpdatingDynamicPrizePool)
        ) {
            this.setState({loading: true});
        }

        if (
            (dynamicPrizePools.isCreatingDynamicPrizePoolSuccess &&
                !prevProps.dynamicPrizePools.isCreatingDynamicPrizePoolSuccess) ||
            (dynamicPrizePools.isUpdatingDynamicPrizePoolSuccess &&
                !prevProps.dynamicPrizePools.isUpdatingDynamicPrizePoolSuccess)
        ) {
            this.setState({loading: false});
        }

        if (
            (dynamicPrizePools.isCreatingDynamicPrizePoolFail &&
                !prevProps.dynamicPrizePools.isCreatingDynamicPrizePoolFail) ||
            (dynamicPrizePools.isUpdatingDynamicPrizePoolFail &&
                !prevProps.dynamicPrizePools.isUpdatingDynamicPrizePoolFail)
        ) {
            this.setState({message: dynamicPrizePools.message.msg, loading: false});
        }
    }

    handleOnChange = (stateObj = {}) => {
        this.setState(stateObj);
    };

    handleSubmit = (e) => {
        !!e && e.preventDefault();
        const {login, client} = this.props;

        const {status, id, templateName, winRate, rake, totalSlots, DPPfile} = this.state;
        let data = {status, templateName};
        if (!id) {
            data = {...data, winRate, rake, totalSlots, DPPfile};
            this.props.createDynamicPrizePool(data, login.userDetails.data.accessToken, client.selectedClient);
        } else {
            data.templateId = id;
            this.props.updateDynamicPrizePool(data, login.userDetails.data.accessToken, client.selectedClient);
        }
    };

    handleClickSubmit = () => {
        this.submitRef.current.click();
    };

    handleDownloadSampleFile = () => {
        window.open(
            'https://fc-fantasy-files.s3.ap-south-1.amazonaws.com/dynamicPrizePoolTemplate/1660808256259.csv',
            '_blank',
        );
    };

    render() {
        const {handleClose, open, login} = this.props;
        const {message, status, loading, id, templateName, winRate, rake, totalSlots} = this.state;
        const {dynamicPrizePoolWrite} = login?.permissions || {};

        return (
            <Dialog
                open={open}
                onClose={handleClose}
                scroll="paper"
                maxWidth="sm"
                fullWidth
                disableEscapeKeyDown
                disableBackdropClick
            >
                {!!message && (
                    <Snackbar open={!!message} message={message} onClose={() => this.setState({message: ''})} />
                )}
                {!!loading && <LoadingModal open={!!loading} />}
                <DialogTitle>{id ? 'Edit' : 'Create'} Dynamic Prize Pool</DialogTitle>
                <DialogContent dividers={true}>
                    <form onSubmit={this.handleSubmit}>
                        <Input
                            className="mb-15"
                            label="Template Name"
                            type="text"
                            name="templateName"
                            value={templateName || ''}
                            placeholder="Enter Template Name"
                            required
                            onChange={(value) => this.handleOnChange({templateName: value})}
                        />

                        <DropDown
                            className="mb-15"
                            label="Status*"
                            menuItems={COMMON_STATUS}
                            required
                            name="status"
                            value={status || ''}
                            placeholder="Select"
                            onChange={(value) => this.handleOnChange({status: value})}
                        />

                        <Input
                            className="mb-15"
                            label="Total Slots"
                            type="number"
                            name="totalSlots"
                            value={totalSlots || ''}
                            placeholder="Enter Total Slots"
                            required
                            disabled={!!id}
                            onChange={(value) => this.handleOnChange({totalSlots: value})}
                        />

                        <Input
                            className="mb-15"
                            label="Win Rate"
                            type="number"
                            name="winRate"
                            value={winRate || ''}
                            placeholder="Enter Win Rate"
                            required
                            disabled={!!id}
                            onChange={(value) => this.handleOnChange({winRate: value})}
                        />

                        <Input
                            className="mb-15"
                            label="Rake"
                            type="number"
                            name="rake"
                            value={rake || ''}
                            placeholder="Enter Rake"
                            required
                            disabled={!!id}
                            onChange={(value) => this.handleOnChange({rake: value})}
                        />

                        {!id && (
                            <FileInput
                                className="mb-15"
                                label={'Upload csv file for template'}
                                name="DPPfile"
                                accept=".csv"
                                required
                                allowSampleFile
                                onClickSampleFile={this.handleDownloadSampleFile}
                                onChange={(value) => this.handleOnChange({DPPfile: value})}
                            />
                        )}

                        {!!dynamicPrizePoolWrite && (
                            <Button className="d-none" buttonText="Submit" type="submit" buttonRef={this.submitRef} />
                        )}
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button buttonText="Cancel" onClick={handleClose} />
                    {!!dynamicPrizePoolWrite && <Button buttonText="Submit" onClick={this.handleClickSubmit} />}
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        dynamicPrizePools: state.dynamicPrizePools,
        client: state.client,
    };
};

const matchDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            createDynamicPrizePool,
            updateDynamicPrizePool,
        },
        dispatch,
    );

export default connect(mapStateToProps, matchDispatchToProps)(CreateEditDynamicPrizePoolModal);
