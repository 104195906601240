import {
    TOMATCHATTRIBUTE_LIST,
    TOMATCHATTRIBUTE_LIST_SUCCESS,
    TOMATCHATTRIBUTE_LIST_FAIL,
    ADD_TOMATCHATTRIBUTE,
    ADD_TOMATCHATTRIBUTE_SUCCESS,
    ADD_TOMATCHATTRIBUTE_FAIL,
    UPDATE_TOMATCHATTRIBUTE,
    UPDATE_TOMATCHATTRIBUTE_SUCCESS,
    UPDATE_TOMATCHATTRIBUTE_FAIL,
    DELETE_TOMATCHATTRIBUTE,
    DELETE_TOMATCHATTRIBUTE_SUCCESS,
    DELETE_TOMATCHATTRIBUTE_FAIL,
    GET_TOMATCHATTRIBUTE,
    GET_TOMATCHATTRIBUTE_SUCCESS,
    GET_TOMATCHATTRIBUTE_FAIL,
    EDIT_TOMATCHATTRIBUTE,
} from './Constants';

//lst all TOMATCHATTRIBUTE
export function listTOMatchAttributes() {
    return {
        type: TOMATCHATTRIBUTE_LIST,
    };
}

export function listTOMatchAttributesSuccess(data) {
    return {
        type: TOMATCHATTRIBUTE_LIST_SUCCESS,
        payload: data,
    };
}

export function listTOMatchAttributesFail(error) {
    return {
        type: TOMATCHATTRIBUTE_LIST_FAIL,
        payload: error,
    };
}

// add TOMATCHATTRIBUTE
export function addTOMatchAttribute() {
    return {
        type: ADD_TOMATCHATTRIBUTE,
    };
}

export function addTOMatchAttributeSuccess(data) {
    return {
        type: ADD_TOMATCHATTRIBUTE_SUCCESS,
        payload: data,
    };
}

export function addTOMatchAttributeFail(error) {
    return {
        type: ADD_TOMATCHATTRIBUTE_FAIL,
        payload: error,
    };
}

//update TOMATCHATTRIBUTE
export function updateTOMatchAttributeRequest() {
    return {
        type: UPDATE_TOMATCHATTRIBUTE,
    };
}

export function updateTOMatchAttributeSuccess(data) {
    return {
        type: UPDATE_TOMATCHATTRIBUTE_SUCCESS,
        payload: data,
    };
}

export function updateTOMatchAttributeFail(error) {
    return {
        type: UPDATE_TOMATCHATTRIBUTE_FAIL,
        payload: error,
    };
}

// delete TOMATCHATTRIBUTE

export function deleteTOMatchAttributeRequest() {
    return {
        type: DELETE_TOMATCHATTRIBUTE,
    };
}

export function deleteTOMatchAttributeSuccess(data) {
    return {
        type: DELETE_TOMATCHATTRIBUTE_SUCCESS,
        payload: data,
    };
}

export function deleteTOMatchAttributeFail(error) {
    return {
        type: DELETE_TOMATCHATTRIBUTE_FAIL,
        payload: error,
    };
}

//get single TOMATCHATTRIBUTE
export function getTOMatchAttributeRequest() {
    return {
        type: GET_TOMATCHATTRIBUTE,
    };
}

export function getTOMatchAttributeSuccess(data) {
    return {
        type: GET_TOMATCHATTRIBUTE_SUCCESS,
        payload: data,
    };
}

export function getTOMatchAttributeFail(error) {
    return {
        type: GET_TOMATCHATTRIBUTE_FAIL,
        payload: error,
    };
}

// single matchAttribute Details
export function editTOMatchAttribute(matchAttribute) {
    return {
        type: EDIT_TOMATCHATTRIBUTE,
        payload: matchAttribute,
    };
}
