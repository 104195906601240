import React from 'react';
import Modal from '@material-ui/core/Modal';
import './Modal.css';
import PropTypes from 'prop-types';

export default function SimpleModal(props) {
    return (
        <div>
            <Modal
                id="modal"
                open={props.open}
                onClose={props.onClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {props.children}
            </Modal>
        </div>
    );
}

SimpleModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
};

SimpleModal.defaultProps = {
    onClose: () => {},
    open: false,
};
