import axios from 'axios';
import {
    listTOMatches,
    listTOMatchesSuccess,
    listTOMatchesFail,
    modifyTOMatchRequest,
    modifyTOMatchSuccess,
    modifyTOMatchFail,
    getTOMatchParticipationRequest,
    getTOMatchParticipationSuccess,
    getTOMatchParticipationFail,
    cancelTOMatchRequest,
    cancelTOMatchSuccess,
    cancelTOMatchFail,
    getTOMatchRequest,
    getTOMatchSuccess,
    getTOMatchFail,
} from './action';
import {TOURL, URL} from '../../../Utilities/Constants';

export const GET_MATCH_DETAIL = 'GET_MATCH_DETAIL';
export const GET_MATCH_DETAIL_FULFILLED = 'GET_MATCH_DETAIL_FULFILLED';
export const GET_MATCH_DETAIL_FAILED = 'GET_MATCH_DETAIL_FAILED';

export function getTOMatchList(pageOptions, token) {
    // listing all TOMatches
    return (dispatch) => {
        dispatch(listTOMatches());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token},
            url: `${TOURL}/match/list`,
            data: pageOptions,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(listTOMatchesSuccess(data));
                } else {
                    dispatch(listTOMatchesFail(data.data.errorData.errorReason));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(listTOMatchesFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(listTOMatchesFail(err.message || 'Something went wrong'));
                }
            });
    };
}

export function getTOOrganizerMatchList(pageOptions, token) {
    // listing all TOMatches
    return (dispatch) => {
        dispatch(listTOMatches());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token},
            url: `${TOURL}/match/organizerList`,
            data: pageOptions,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(listTOMatchesSuccess(data));
                } else {
                    dispatch(listTOMatchesFail(data.data.errorData.errorReason));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(listTOMatchesFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(listTOMatchesFail(err.message || 'Something went wrong'));
                }
            });
    };
}

//TOMatch modify
export function modifyTOMatch(TOMatch, token) {
    return (dispatch) => {
        dispatch(modifyTOMatchRequest());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token},
            url: `${TOURL}/match/modify`,
            data: TOMatch,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(modifyTOMatchSuccess(data));
                } else {
                    dispatch(modifyTOMatchFail(data.data.errorData.errorReason));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(modifyTOMatchFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(modifyTOMatchFail(err.message || 'Something went wrong'));
                }
            });
    };
}

export function getTOMatchPartipation(TOMatch, token) {
    return (dispatch) => {
        dispatch(getTOMatchParticipationRequest());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token},
            url: `${TOURL}/match/getParticipation`,
            data: TOMatch,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(getTOMatchParticipationSuccess(data));
                } else {
                    dispatch(getTOMatchParticipationFail(data.data.errorData.errorReason));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(getTOMatchParticipationFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(getTOMatchParticipationFail(err.message || 'Something went wrong'));
                }
            });
    };
}

//TOMatch cancel
export function cancelTOMatch(TOMatch, token) {
    return (dispatch) => {
        dispatch(cancelTOMatchRequest());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token},
            url: `${TOURL}/match/cancel`,
            data: TOMatch,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(cancelTOMatchSuccess(data));
                } else {
                    dispatch(cancelTOMatchFail(data.data.errorData.errorReason));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(cancelTOMatchFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(cancelTOMatchFail(err.message || 'Something went wrong'));
                }
            });
    };
}

// get single TOMatch
export function getTOMatch(id, token) {
    return (dispatch) => {
        dispatch(getTOMatchRequest());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token},
            url: `${TOURL}/match/get`,
            data: {
                matchId: id,
            },
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(getTOMatchSuccess(data));
                } else {
                    dispatch(getTOMatchFail(data.data.errorData.errorReason));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(getTOMatchFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(getTOMatchFail(err.message || 'Something went wrong'));
                }
            });
    };
}

export const getMatchDetail = (token, userId, matchId) => {
    return (dispatch) => {
        dispatch({type: GET_MATCH_DETAIL});
        axios({
            method: 'post',
            headers: {
                Authorization: 'Bearer ' + token,
            },
            url: `${TOURL}/getMatchDetail`,
            data: {userId: userId, matchId: matchId},
        })
            .then((response) => {
                dispatch({type: GET_MATCH_DETAIL_FULFILLED, payload: response.data.data});
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch({type: GET_MATCH_DETAIL_FAILED, payload: errorMsg || 'Something went wrong'});
                } else {
                    dispatch({type: GET_MATCH_DETAIL_FAILED, payload: err.message || 'Something went wrong'});
                }
            });
    };
};

export const fetchS3FileFromServer = async (s3FileUrl, token) => {
    // console.log("calling fetchS3FileFromServer", s3FileUrl);
    let response = await axios({
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + token,
        },
        url: `${URL}/fetchS3File?s3FileUrl=${s3FileUrl}`,
        responseType: 'arraybuffer',
    });

    return response;
};
