import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiAlert from '@material-ui/lab/Alert';
import './Snackbar.css';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function SimpleSnackbar(props) {
    const {open, children, onClose, message} = props;

    return (
        <div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={open}
                autoHideDuration={4000}
                onClose={props.onClose}
            >
                <Alert
                    severity="warning"
                    action={
                        <React.Fragment>
                            {children}
                            <IconButton size="small" aria-label="close" color="inherit" onClick={onClose}>
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </React.Fragment>
                    }
                >
                    {message}
                </Alert>
            </Snackbar>
        </div>
    );
}
