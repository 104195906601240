import {combineReducers} from 'redux';
import LoginReducer from './View/Modules/Login/LoginReducer';
import squadReducer from './View/Modules/Squads/squadReducer';
import playerReducer from './View/Modules/Players/playerReducer';
import matchReducer from './View/Modules/Matches/reducer';
import contestTypeReducer from './View/Modules/ContestType/reducer';
import contestReducer from './View/Modules/Contests/reducer';
import teamReducer from './View/Modules/Teams/reducer';
import recordMatchReducer from './View/Modules/RecordMatch/reducer';
import fantasyConfigReducer from './View/Modules/FantasyConfig/fantasyReducer';
import userReducer from './View/Modules/Users/reducer';
import adminReducer from './View/Modules/Admin/reducer';
import discountReducer from './View/Modules/DiscountCodes/reducer';
import referralCodeReducer from './View/Modules/ReferalCode/reducer';
import withdrawalReducer from './View/Modules/Withdrawals/reducer';
import changePasswordReducer from './View/Modules/ChangePassword/reducer';
import notificationReducer from './View/Modules/Notifications/reducer';
import reportsReducer from './View/Modules/Reports/reducer';
import tournamentReducer from './View/Modules/FantasyTournament/FantasyTournamentReducer';
import rewardUserReducer from './View/Modules/RewardUser/reducer';

import toGameReducer from './View/Modules/TOGame/reducer';
import toMatchModeReducer from './View/Modules/TOMatchMode/reducer';
import toMatchAttributeReducer from './View/Modules/TOMatchAttribute/reducer';
import toMatchType from './View/Modules/TOMatchType/reducer';
import toMatch from './View/Modules/TOMatch/reducer';
import toPublishedMatch from './View/Modules/TOPublishedMatch/reducer';
import toCompletedMatch from './View/Modules/TOCompletedMatch/reducer';
import toOrganizer from './View/Modules/TOOrganizer/reducer';
import inshortsReducer from './View/Modules/Inshorts/InshortsReducer';
import recommendedTeamReducer from './View/Modules/RecommendedTeams/reducer';
import bannerReducer from './View/Modules/Banners/reducer';
import boosters from './View/Modules/Boosters/reducer';
import tdsCertificates from './View/Modules/TDSCertificates/reducer';
import userKyc from './View/Modules/UserKyc/reducer';
import toCalendar from './View/Modules/ToCalendar/reducer';
import dynamicPrizePools from './View/Modules/DynamicPrizePool/reducer';
import clientReducer from './View/Modules/Client/reducer';

// matches
import commonMatchReducer from './containers/Matches/CommonMatches/reducer';
import switchConfigReducer from './containers/Switch/reducer';

const rootReducer = combineReducers({
    login: LoginReducer,
    squad: squadReducer,
    player: playerReducer,
    inshorts: inshortsReducer,
    match: matchReducer,
    contestType: contestTypeReducer,
    contest: contestReducer,
    team: teamReducer,
    recordMatch: recordMatchReducer,
    fantasyConfig: fantasyConfigReducer,
    user: userReducer,
    admin: adminReducer,
    discount: discountReducer,
    referralCode: referralCodeReducer,
    withdrawal: withdrawalReducer,
    changePassword: changePasswordReducer,
    notification: notificationReducer,
    rewardUser: rewardUserReducer,
    tournament: tournamentReducer,
    reports: reportsReducer,

    toGame: toGameReducer,
    toMatchMode: toMatchModeReducer,
    toMatchAttribute: toMatchAttributeReducer,
    toMatchType: toMatchType,
    toMatch: toMatch,
    toPublishedMatch: toPublishedMatch,
    toCompletedMatch: toCompletedMatch,
    toOrganizer: toOrganizer,

    recommendedTeams: recommendedTeamReducer,
    banners: bannerReducer,
    boosters,
    tdsCertificates,
    userKyc,
    toCalendar,
    dynamicPrizePools,
    client: clientReducer,

    // matches
    commonMatch: commonMatchReducer,

    // switch
    switchConfig: switchConfigReducer,
});

export default rootReducer;
