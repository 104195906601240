import {
    FANTASY_SCORING_LIST,
    FANTASY_SCORING_LIST_SUCCESS,
    FANTASY_SCORING_LIST_FAIL,
    ADD_FANTASY_SCORING,
    ADD_FANTASY_SCORING_SUCCESS,
    ADD_FANTASY_SCORING_FAIL,
    UPDATE_FANTASY_SCORING,
    UPDATE_FANTASY_SCORING_SUCCESS,
    UPDATE_FANTASY_SCORING_FAIL,
    DELETE_FANTASY_SCORING,
    DELETE_FANTASY_SCORING_SUCCESS,
    DELETE_FANTASY_SCORING_FAIL,
    GET_FANTASY_SCORING,
    GET_FANTASY_SCORING_SUCCESS,
    GET_FANTASY_SCORING_FAIL,
    EDIT_FANTASY_SCORING_MODEL,
} from './FantasyConfigConstants';

const initialState = {
    scoringListRequest: false,
    scoringListSuccess: false,
    scoringListFail: false,
    scoringList: [],
    errorMessage: '',
    addScoringRequest: false,
    addScoringSuccess: false,
    addScoringFail: false,
    addScoring: [],
    editScoring: [],
    updateScoringRequest: false,
    updateScoringSuccess: false,
    updateScoringFail: false,
    updateScoring: [],
    deleteScoringRequest: false,
    deleteScoringSuccess: false,
    deleteScoringFail: false,
    deleteScoring: [],
    getScoringRequest: false,
    getScoringSuccess: false,
    getScoringFail: false,
    getScoring: [],
};

const fantasyReducer = (state = initialState, action) => {
    switch (action.type) {
        case FANTASY_SCORING_LIST:
            return {
                ...state,
                scoringListRequest: true,
                scoringListSuccess: false,
                scoringListFail: false,
                scoringList: [],
                errorMessage: '',
            };

        case FANTASY_SCORING_LIST_SUCCESS:
            return {
                ...state,
                scoringListRequest: false,
                scoringListSuccess: true,
                scoringListFail: false,
                scoringList: action.payload.data,
                errorMessage: '',
            };

        case FANTASY_SCORING_LIST_FAIL:
            return {
                ...state,
                scoringListRequest: false,
                scoringListSuccess: false,
                scoringListFail: true,
                errorMessage: action.payload,
            };

        case ADD_FANTASY_SCORING:
            return {
                ...state,
                addScoringRequest: true,
                addScoringSuccess: false,
                addScoringFail: false,
                addScoring: [],
                errorMessage: '',
            };

        case ADD_FANTASY_SCORING_SUCCESS:
            return {
                ...state,
                addScoringRequest: false,
                addScoringSuccess: true,
                addScoringFail: false,
                addScoring: action.payload.data,
                errorMessage: '',
            };

        case ADD_FANTASY_SCORING_FAIL:
            return {
                ...state,
                addScoringRequest: false,
                addScoringSuccess: false,
                addScoringFail: true,
                errorMessage: action.payload,
            };

        case EDIT_FANTASY_SCORING_MODEL:
            return {
                ...state,
                editScoring: action.payload,
            };

        case UPDATE_FANTASY_SCORING:
            return {
                ...state,
                updateScoringRequest: true,
                updateScoringSuccess: false,
                updateScoringFail: false,
                updateScoring: [],
                errorMessage: '',
            };

        case UPDATE_FANTASY_SCORING_SUCCESS:
            return {
                ...state,
                updateScoringRequest: false,
                updateScoringSuccess: true,
                updateScoringFail: false,
                updateScoring: action.payload.data,
            };

        case UPDATE_FANTASY_SCORING_FAIL:
            return {
                ...state,
                updateScoringRequest: false,
                updateScoringSuccess: false,
                updateScoringFail: true,
                errorMessage: action.payload,
            };

        case DELETE_FANTASY_SCORING:
            return {
                ...state,
                deleteScoringRequest: true,
                deleteScoringSuccess: false,
                deleteScoringFail: false,
                deleteScoring: [],
                errorMessage: '',
            };

        case DELETE_FANTASY_SCORING_SUCCESS:
            return {
                ...state,
                deleteScoringRequest: false,
                deleteScoringSuccess: true,
                deleteScoringFail: false,
                deleteScoring: action.payload.data,
            };

        case DELETE_FANTASY_SCORING_FAIL:
            return {
                ...state,
                deleteScoringRequest: false,
                deleteScoringSuccess: false,
                deleteScoringFail: true,
                errorMessage: action.payload,
            };

        case GET_FANTASY_SCORING:
            return {
                ...state,
                getScoringRequest: false,
                getScoringSuccess: false,
                getScoringFail: false,
                getScoring: [],
                errorMessage: '',
            };

        case GET_FANTASY_SCORING_SUCCESS:
            return {
                ...state,
                getScoringRequest: false,
                getScoringSuccess: true,
                getScoringFail: false,
                getScoring: action.payload.data,
                errorMessage: '',
            };

        case GET_FANTASY_SCORING_FAIL:
            return {
                ...state,
                getScoringRequest: false,
                getScoringSuccess: false,
                getScoringFail: true,
                errorMessage: action.payload,
            };

        default:
            return state;
    }
};

export default fantasyReducer;
