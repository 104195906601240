import React from 'react';
import '../../../Utilities/style.css';
import '../Login/login.css';
import {connect} from 'react-redux';
import Input from '../../../Components/Input/Input';
import DropDown from '../../../Components/Dropdown/Dropdown';
import {addTournament} from '../FantasyTournament/FantasyTournamentAxios';
import moment from 'moment';
import {getTOGameList} from '../TOGame/AxioCalls';
import {TOURL} from '../../../Utilities/Constants';
import Snackbar from '../../../Components/Snackbar/Snackbar';
import axios from 'axios';
import DetailLayout from '../../Layouts/DetailLayout/DetailLayout';
import ImageInput from '../../../Components/ImageInput/ImageInput';
import {MuiPickersUtilsProvider, DateTimePicker} from '@material-ui/pickers';
import LoadminModal from '../../../Components/LoadingModal/LoadingModal';
import DateFnsUtils from '@date-io/date-fns';
import {logout} from '../Login/LoginAction';
import Card from '../../../Components/Card/Card';

class AddTournamentScreen extends React.Component {
    constructor(props) {
        super(props);
        let location = this.props.history.location.pathname.includes('edit');
        this.state = {
            menuItems: [
                {
                    value: 'Active',
                    text: 'YES',
                },
                {
                    value: 'InActive',
                    text: 'NO',
                },
            ],
            loading: false,
            matchStatus: '',

            tierOptions: [
                {text: 'Tier 1', value: 'tier1'},
                {text: 'Tier 2', value: 'tier2'},
                {text: 'Tier 3', value: 'tier3'},
                {text: 'Tier 4', value: 'tier4'},
                {text: 'Tier 5', value: 'tier5'},
                {text: 'Tier 6', value: 'tier6'},
            ],
            tier: null,
            zoneOptions: [
                {
                    text: 'India',
                    value: 'india',
                },
                {
                    text: 'International',
                    value: 'international',
                },
            ],
            zone: null,
            internationalZone: null,
            regionOptions: [
                {text: 'Global', value: 'global'},
                {text: 'SEA(South-East Asia)', value: 'sea'},
                {text: 'SA(South Asia)', value: 'southAsia'},
                {text: 'EA(East Asia)', value: 'eastAsia'},
                {text: 'NA(North America)', value: 'northAmerica'},
                {text: 'EU(Europe)', value: 'europe'},
                {text: 'SA(South America)', value: 'southAmerica'},
                {text: 'MENA(Middle East & North Africa)', value: 'mena'},
                {text: 'Oceania(Australia)', value: 'australia'},
                {text: 'Other', value: 'other'},
            ],
            region: null,
            otherRegion: null,
            page: 1,
            pageSize: 30,
            activeTab: 0,
            modalName: '',
            disabled: false,
            status: null,
            selectedSquads: [],
            tournamentName: null,
            tournamentStatus: null,
            startTime: null,
            date: null,
            markLiveModalVisible: false,
            game: null,
            gameOptions: [],
            tournamentList: [],
            selectedTournament: '',
        };
    }

    /**
     * function to save entered data in state
     * @param {string} field field names from the form
     * @param {*} value value in the field
     */

    componentDidMount() {
        const {login, history, client} = this.props;
        if (!login.userDetails.data) {
            history.push('/');
            this.props.logout();
        } else {
            let listAll = {
                listType: 'ALL',
            };
            this.props.getTOGameList(listAll, login.userDetails.data.accessToken, client.selectedClient);
        }
    }

    componentDidUpdate(prevProps) {
        const {toGame, tournament} = this.props;
        const {game, gameOptions} = this.state;
        if (toGame.TOGameListSuccess && !prevProps.toGame.TOGameListSuccess) {
            this.setState({
                gameOptions: toGame.TOGameList.data.data.map((item) => {
                    return {text: item.gameName, value: item.id};
                }),
                loading: false,
            });
        } else if (toGame.TOGameListFail && !prevProps.toGame.TOGameListFail) {
            this.setState({
                errorMessage: toGame.errorMessage,
                loading: false,
            });
        }
        if (tournament.addTournamentRequest && !prevProps.tournament.addTournamentRequest) {
            this.setState({
                loading: true,
            });
        } else if (tournament.addTournamentSuccess && !prevProps.tournament.addTournamentSuccess) {
            this.setState({loading: false, errorMessage: 'Tournament Added Successfully!'});
        } else if (tournament.addTournamentFail && !prevProps.tournament.addTournamentFail) {
            this.setState({loading: false, errorMessage: tournament.errorMessage});
        }
    }

    onSave() {
        const {
            tournamentName,
            startTime,
            game,
            tournamentImage,
            s3Url,
            tier,
            zone,
            region,
            internationalZone,
            otherRegion,
            tournamentHomeIcon,
        } = this.state;
        const {login, match} = this.props;

        if (
            tournamentName === null ||
            tournamentName === undefined ||
            tournamentName === '' ||
            tournamentName.length < 5
        ) {
            this.setState({errorMessage: 'Enter valid Tournament name with minimum 5 characters'});
        } else if (game === null || game === undefined || game === '') {
            this.setState({errorMessage: 'please select valid game'});
            // } else if (tournamentImage === null || tournamentImage === undefined || tournamentImage === "") {
            // this.setState({errorMessage: "please select valid tournament Image"})
            // } else if (!tournamentHomeIcon) {
            // this.setState({errorMessage: "please upload valid tournament home icon"})
        } else if (startTime === null || startTime === undefined || startTime === '') {
            this.setState({errorMessage: 'please select valid startTime'});
        } else if (tier === null) {
            this.setState({errorMessage: 'please select a tier'});
        } else if (zone === null) {
            this.setState({errorMessage: 'please select valid zone'});
        } else if (region === null) {
            this.setState({errorMessage: 'please select valid Region'});
        } else {
            let timestamp = moment(startTime).valueOf();
            let data = {
                tournamentName: this.state.tournamentName,
                game: this.state.game,
                tournamentImage: this.state.tournamentImage,
                tournamentHomeIcon: tournamentHomeIcon,
                s3Url,
                startTime: timestamp,
                tier: tier,
                zone: zone,
                region: region,
                internationalZone: internationalZone,
                otherRegion: otherRegion,
            };
            this.props.addTournament(data, this.props.login.userDetails.data.accessToken);
        }
    }

    onBackClick() {
        const {history} = this.props;

        history.push('/matches');
    }

    handleInputChange(field, value) {
        this.setState({[field]: value, errorMessage: ''});

        if (field === 'uploadImage') {
            this.setState({loading: true});
            const data = new FormData();
            data.append('gameImage', value);
            axios
                .post(`${TOURL}/game/imageupload`, data)
                .then((res) => {
                    this.setState({loading: false});
                    if (res.data.data)
                        this.setState({tournamentImage: res.data.data.image, s3Url: res.data.data.s3Url});
                })
                .catch((err) => {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    this.setState({loading: false, errorMessage: errorMsg});
                });
        } else if (field === 'tournamentHomeIcon') {
            this.setState({loading: true});
            const data = new FormData();
            data.append('gameImage', value);
            axios
                .post(`${TOURL}/game/imageupload`, data)
                .then((res) => {
                    this.setState({loading: false});
                    if (res.data.data) this.setState({tournamentHomeIcon: res.data.data.image});
                })
                .catch((err) => {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    this.setState({loading: false, errorMessage: errorMsg});
                });
        }
    }

    render() {
        const {
            tournamentName,
            startTime,
            game,
            gameOptions,
            tournamentImage,
            loading,
            errorMessage,
            tierOptions,
            tier,
            zoneOptions,
            zone,
            internationalZone,
            region,
            regionOptions,
            otherRegion,
            tournamentHomeIcon,
        } = this.state;
        const {history} = this.props;
        return (
            <DetailLayout
                location="addTournamentScreen"
                history={history}
                centerTitle="Add Tournament"
                onSave={this.onSave.bind(this)}
            >
                {!!loading && <LoadminModal open={loading} />}

                {!!errorMessage && (
                    <Snackbar
                        open={!!errorMessage}
                        message={errorMessage}
                        onClose={() => this.setState({errorMessage: ''})}
                    />
                )}

                <div className="detail-container margin-top-20">
                    <div className="full-grid" />
                    <div className="full-grid">
                        <Card>
                            <table>
                                <tr>
                                    <th>Fan Tier</th>
                                    <th>CS/Valorant/COD/PUBG/BGMI</th>
                                    <th>LOL/FreeFire</th>
                                    <th>Dota</th>
                                    <th>Rainbow Six</th>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>Tier S</td>
                                    <td>Premier</td>
                                    <td>Tier 1</td>
                                    <td>Tier S</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>Tier A</td>
                                    <td>Major</td>
                                    <td>Tier 2</td>
                                    <td>Tier A</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>Tier B</td>
                                    <td>Minor</td>
                                    <td>Tier 3</td>
                                    <td>Tier B</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>Tier C</td>
                                    <td>Monthly</td>
                                    <td>Tier 4</td>
                                    <td>Tier C</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>Qualifiers</td>
                                    <td>-</td>
                                    <td>Qualifiers</td>
                                    <td>Tier D</td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td>Monthly</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>Qualifiers/Monthly</td>
                                </tr>
                                <tr>
                                    <td>Unknown</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                </tr>
                            </table>
                            <div className="card-grid-container nopadding margin-top-20">
                                <div className="grid-item ">
                                    <Input
                                        label="Tournament Name"
                                        type="text"
                                        name="tournamentName"
                                        value={tournamentName}
                                        onChange={this.handleInputChange.bind(this, 'tournamentName')}
                                        placeholder="Enter"
                                    />
                                </div>
                            </div>
                            <div className="card-grid-container nopadding margin-top-20">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DateTimePicker
                                        margin="normal"
                                        id="date-picker-dialog"
                                        label="Date"
                                        value={startTime}
                                        minDate={new Date()}
                                        invalidDateMessage={''}
                                        onChange={this.handleInputChange.bind(this, 'startTime')}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>

                            <div className="card-grid-container nopadding margin-top-20">
                                <div className="grid-item  margin-top-20">
                                    <DropDown
                                        label="Game"
                                        type="text"
                                        menuItems={gameOptions}
                                        name="game"
                                        value={game}
                                        onChange={this.handleInputChange.bind(this, 'game')}
                                        placeholder="Select Game"
                                    />
                                </div>
                            </div>
                            <div className="card-grid-container nopadding margin-top-20">
                                <div className="grid-item  margin-top-20">
                                    <DropDown
                                        label="Select Fan Tier"
                                        type="text"
                                        menuItems={tierOptions}
                                        name="tier"
                                        value={tier}
                                        onChange={this.handleInputChange.bind(this, 'tier')}
                                        placeholder="Select Fan Tier"
                                    />
                                </div>
                            </div>
                            <div className="card-grid-container nopadding margin-top-20">
                                <div className="grid-item  margin-top-20">
                                    <DropDown
                                        label="Select Zone"
                                        type="text"
                                        menuItems={zoneOptions}
                                        name="zone"
                                        value={zone}
                                        onChange={this.handleInputChange.bind(this, 'zone')}
                                        placeholder="Select Zone"
                                    />
                                </div>
                            </div>
                            {zone === 'international' ? (
                                <div className="card-grid-container nopadding margin-top-20">
                                    <div className="grid-item ">
                                        <Input
                                            label="Enter Zone Area if Possible"
                                            type="text"
                                            name="internationalZone"
                                            value={internationalZone}
                                            onChange={this.handleInputChange.bind(this, 'internationalZone')}
                                            placeholder="Enter"
                                        />
                                    </div>
                                </div>
                            ) : null}
                            <div className="card-grid-container nopadding margin-top-20">
                                <div className="grid-item  margin-top-20">
                                    <DropDown
                                        label="Select Region"
                                        type="text"
                                        menuItems={regionOptions}
                                        name="region"
                                        value={region}
                                        onChange={this.handleInputChange.bind(this, 'region')}
                                        placeholder="Select Region"
                                    />
                                </div>
                            </div>
                            {region === 'other' ? (
                                <div className="card-grid-container nopadding margin-top-20">
                                    <div className="grid-item ">
                                        <Input
                                            label="Enter Region if possible"
                                            type="text"
                                            name="otherRegion"
                                            value={otherRegion}
                                            onChange={this.handleInputChange.bind(this, 'otherRegion')}
                                            placeholder="Enter"
                                        />
                                    </div>
                                </div>
                            ) : null}
                            <div className="grid-item  margin-top-20">
                                <Input
                                    label="Tournament Image"
                                    type="text"
                                    name="tournamentImage"
                                    value={tournamentImage}
                                    onChange={this.handleInputChange.bind(this, 'tournamentImage')}
                                    placeholder="Enter tournament Image if Any"
                                    disabled={true}
                                />
                            </div>
                            {tournamentImage !== undefined && tournamentImage !== null && tournamentImage !== '' ? (
                                <div className="grid-item  margin-top-20">
                                    <img src={tournamentImage} style={{height: 100}} />
                                </div>
                            ) : (
                                <div className="grid-item  margin-top-20"></div>
                            )}

                            <div className="grid-item  margin-top-20">
                                <ImageInput
                                    label="Upload Your File"
                                    type="file"
                                    name="file"
                                    onChange={this.handleInputChange.bind(this, 'uploadImage')}
                                />
                            </div>

                            <div className="grid-item margin-top-20">
                                <Input
                                    label="Tournament Home Icon"
                                    type="text"
                                    name="tournamentHomeIcon"
                                    value={tournamentHomeIcon}
                                    placeholder="Tournament Home Icon"
                                    disabled={true}
                                />
                            </div>
                            {!!tournamentHomeIcon && (
                                <div className="grid-item margin-top-20">
                                    <img src={tournamentHomeIcon} style={{height: 100}} />
                                </div>
                            )}

                            <div className="grid-item margin-top-20">
                                <ImageInput
                                    label="Upload Tournament Home Icon"
                                    type="file"
                                    onChange={this.handleInputChange.bind(this, 'tournamentHomeIcon')}
                                />
                            </div>
                        </Card>
                    </div>
                </div>
            </DetailLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        tournament: state.tournament,
        match: state.match,
        toGame: state.toGame,
        client: state.client,
    };
};

export default connect(mapStateToProps, {addTournament, getTOGameList})(AddTournamentScreen);
