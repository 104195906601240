import React from 'react';
import {IconButton, styled} from '@material-ui/core';
import {Cancel, CheckCircle, Close} from '@material-ui/icons';
import MuiModal from '../../../../../Components/MuiModal/MuiModal';
import {ColorPalette} from '../../../../../Utilities/constants/color-constants';
import styles from './ConfirmRemoveSquadModal.module.scss';
import MuiButton from '../../../../../Components/MuiButton/MuiButton';

const ConfirmRemoveSquadModal = (props) => {
    const {onClickClose, onClickConfirm, open, removeSquadData} = props;

    return (
        <MuiModal onClickClose={onClickClose} open={open} className={styles.container}>
            <div className={styles.header}>
                <h3>Remove Squad</h3>
                <CustomIconButton onClick={onClickClose} className={styles.closeButton}>
                    <Close className={styles.closeButtonIcon} />
                </CustomIconButton>
            </div>
            <div className={styles.divider} />
            <div className={styles.body}>
                <div className={styles.confirmationTextWrapper}>
                    <p>Are you sure you want to remove this squad?</p>
                </div>
                <div className={styles.buttonGroup}>
                    <MuiButton type="button" buttonText="Cancel" buttonIcon={<Cancel />} onButtonClick={onClickClose} />
                    <MuiButton
                        type="button"
                        buttonText="Confirm"
                        buttonIcon={<CheckCircle />}
                        onButtonClick={() => onClickConfirm(removeSquadData?._id)}
                    />
                </div>
            </div>
        </MuiModal>
    );
};

const CustomIconButton = styled(IconButton)({
    width: '28px',
    height: '28px',

    '&:hover': {
        backgroundColor: ColorPalette.NIGHT_RIDER,
    },
});

export default ConfirmRemoveSquadModal;
