import axios from 'axios';
import {URL} from '../../../Utilities/Constants';
import {logout} from '../Login/LoginAction';
import {
    GET_RECOMMENDED_TEAMS,
    GET_RECOMMENDED_TEAMS_SUCCESS,
    GET_RECOMMENDED_TEAMS_FAIL,
    CREATE_RECOMMENDED_TEAM,
    CREATE_RECOMMENDED_TEAM_SUCCESS,
    CREATE_RECOMMENDED_TEAM_FAIL,
    UPDATE_RECOMMENDED_TEAM,
    UPDATE_RECOMMENDED_TEAM_SUCCESS,
    UPDATE_RECOMMENDED_TEAM_FAIL,
    DELETE_RECOMMENDED_TEAM,
    DELETE_RECOMMENDED_TEAM_SUCCESS,
    DELETE_RECOMMENDED_TEAM_FAIL,
    AUTO_CREATE_RECOMMENDED_TEAM,
    AUTO_CREATE_RECOMMENDED_TEAM_SUCCESS,
    AUTO_CREATE_RECOMMENDED_TEAM_FAIL,
} from './Constants';

export function getRecommendedTeamsForMatch(data, token) {
    return (dispatch) => {
        dispatch({type: GET_RECOMMENDED_TEAMS});
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token},
            url: `${URL}/match/getRecommendedTeamsForMatch`,
            data: data,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch({type: GET_RECOMMENDED_TEAMS_SUCCESS, payload: data});
                } else if (data.data.status === 'fail') {
                    dispatch({type: GET_RECOMMENDED_TEAMS_FAIL, payload: data.data.errorData.errorReason});
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch({type: GET_RECOMMENDED_TEAMS_FAIL, payload: errorMsg || 'Something went wrong'});
                } else {
                    dispatch({type: GET_RECOMMENDED_TEAMS_FAIL, payload: err.message || 'Something went wrong'});
                }
            });
    };
}

export function createRecommendedTeam(data, token) {
    return (dispatch) => {
        dispatch({type: CREATE_RECOMMENDED_TEAM});
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token},
            url: `${URL}/match/createRecommendedTeam`,
            data: data,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch({type: CREATE_RECOMMENDED_TEAM_SUCCESS, payload: data});
                } else if (data.data.status === 'fail') {
                    dispatch({type: CREATE_RECOMMENDED_TEAM_FAIL, payload: data.data.errorData.errorReason});
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch({type: CREATE_RECOMMENDED_TEAM_FAIL, payload: errorMsg || 'Something went wrong'});
                } else {
                    dispatch({type: CREATE_RECOMMENDED_TEAM_FAIL, payload: err.message || 'Something went wrong'});
                }
            });
    };
}

export function updateRecommendedTeam(data, token) {
    return (dispatch) => {
        dispatch({type: UPDATE_RECOMMENDED_TEAM});
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token},
            url: `${URL}/match/updateRecommendedTeam`,
            data: data,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch({type: UPDATE_RECOMMENDED_TEAM_SUCCESS, payload: data});
                } else if (data.data.status === 'fail') {
                    dispatch({type: UPDATE_RECOMMENDED_TEAM_FAIL, payload: data.data.errorData.errorReason});
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch({type: UPDATE_RECOMMENDED_TEAM_FAIL, payload: errorMsg || 'Something went wrong'});
                } else {
                    dispatch({type: UPDATE_RECOMMENDED_TEAM_FAIL, payload: err.message || 'Something went wrong'});
                }
            });
    };
}

export function deleteRecommendedTeam(data, token) {
    return (dispatch) => {
        dispatch({type: DELETE_RECOMMENDED_TEAM});
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token},
            url: `${URL}/match/deleteRecommendedTeam`,
            data: data,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch({type: DELETE_RECOMMENDED_TEAM_SUCCESS, payload: data});
                } else if (data.data.status === 'fail') {
                    dispatch({type: DELETE_RECOMMENDED_TEAM_FAIL, payload: data.data.errorData.errorReason});
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch({type: DELETE_RECOMMENDED_TEAM_FAIL, payload: errorMsg || 'Something went wrong'});
                } else {
                    dispatch({type: DELETE_RECOMMENDED_TEAM_FAIL, payload: err.message || 'Something went wrong'});
                }
            });
    };
}

export function autoCreateRecommendedTeam(data, token) {
    return (dispatch) => {
        dispatch({type: AUTO_CREATE_RECOMMENDED_TEAM});
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token},
            url: `${URL}/match/createAutoRecommendedTeams`,
            data: data,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch({type: AUTO_CREATE_RECOMMENDED_TEAM_SUCCESS, payload: data});
                } else if (data.data.status === 'fail') {
                    dispatch({type: AUTO_CREATE_RECOMMENDED_TEAM_FAIL, payload: data.data.errorData.errorReason});
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch({type: AUTO_CREATE_RECOMMENDED_TEAM_FAIL, payload: errorMsg || 'Something went wrong'});
                } else {
                    dispatch({type: AUTO_CREATE_RECOMMENDED_TEAM_FAIL, payload: err.message || 'Something went wrong'});
                }
            });
    };
}
