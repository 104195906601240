import {
    EVENT_LIST,
    EVENT_LIST_SUCCESS,
    EVENT_LIST_FAIL,
    RECORD_EVENT,
    RECORD_EVENT_SUCCESS,
    RECORD_EVENT_FAIL,
    VERIFY_EVENT,
    VERIFY_EVENT_SUCCESS,
    VERIFY_EVENT_FAIL,
    RECORD_MATCH,
    VERIFY_MATCH,
    COMPLETE_EVENT,
    COMPLETE_EVENT_SUCCESS,
    COMPLETE_EVENT_FAIL,
    DELETE_EVENT,
    DELETE_EVENT_SUCCESS,
    DELETE_EVENT_FAIL,
    UPDATE_EVENT,
    UPDATE_EVENT_SUCCESS,
    UPDATE_EVENT_FAIL,
    PLAYERS_MATCH,
    PLAYERS_MATCH_SUCCESS,
    PLAYERS_MATCH_FAIL,
    COMPILE_EVENT,
    COMPILE_EVENT_SUCCESS,
    COMPILE_EVENT_FAIL,
    COMPLETE_MATCH_V2,
    COMPLETE_MATCH_V2_SUCCESS,
    COMPLETE_MATCH_V2_FAIL,
    WINNING_DISBURSAL_DETAILS,
    WINNING_DISBURSAL_DETAILS_SUCCESS,
    WINNING_DISBURSAL_DETAILS_FAIL,
    COMPILE_MATCH_V2,
    COMPILE_MATCH_V2_SUCCESS,
    COMPILE_MATCH_V2_FAIL,
    PUBLISH_MATCH_SUMMARY,
    PUBLISH_MATCH_SUMMARY_SUCCESS,
    PUBLISH_MATCH_SUMMARY_FAIL,
    VERIFY_SWITCH_LEADERBOARD_POINTS,
    VERIFY_SWITCH_LEADERBOARD_POINTS_SUCCESS,
    VERIFY_SWITCH_LEADERBOARD_POINTS_FAIL,
} from './Constants';

const initialState = {
    eventListRequest: false,
    eventListSuccess: false,
    eventListFail: false,
    eventList: [],
    errorMessage: '',
    recordEventRequest: false,
    recordEventSuccess: false,
    recordEventFail: false,
    recordEvent: [],
    verifyEventRequest: false,
    verifyEventSuccess: false,
    verifyEventFail: false,
    verifyEvent: [],
    recordingMatch: [],
    verifyingMatch: [],
    completeMatchRequest: false,
    completeMatchSuccess: false,
    completeMatchFail: false,
    completeMatch: [],
    deleteEventRequest: false,
    deleteEventSuccess: false,
    deleteEventFail: false,
    deleteEvent: [],
    updateEventRequest: false,
    updateEventSuccess: false,
    updateEventFail: false,
    updateEvent: [],

    playersMatchRequest: true,
    playersMatchSuccess: false,
    playersMatchFail: false,
    playersMatch: [],
    compileMatchRequest: false,
    compileMatchSuccess: false,
    compileMatchFail: false,
    compileMatch: [],

    isMatchCompletingV2: false,
    isMatchCompletingV2Success: false,
    isMatchCompletingV2Fail: false,

    isMatchCompilingV2: false,
    isMatchCompilingV2Success: false,
    isMatchCompilingV2Fail: false,

    isWinningDisbursalDetails: false,
    isWinningDisbursalDetailsSuccess: false,
    isWinningDisbursalDetailsFail: false,
    winningDisbursalDetails: {},

    isPublishMatchSummary: false,
    isPublishMatchSummarySuccess: false,
    isPublishMatchSummaryFail: false,

    verifySwitchLeaderboardPoints: false,
    verifySwitchLeaderboardPointsSuccess: false,
    verifySwitchLeaderboardPointsFail: false,
    verifySwitchLeaderboardPointsDetails: {},
};

const recordMatchReducer = (state = initialState, action) => {
    switch (action.type) {
        //save  event
        case EVENT_LIST:
            return {
                ...state,
                eventListRequest: true,
                eventListSuccess: false,
                eventListFail: false,
                eventList: [],
                errorMessage: '',
            };

        case EVENT_LIST_SUCCESS:
            return {
                ...state,
                eventListRequest: false,
                eventListSuccess: true,
                eventListFail: false,
                eventList: action.payload.data,
                errorMessage: '',
            };

        case EVENT_LIST_FAIL:
            return {
                ...state,
                eventListRequest: false,
                eventListSuccess: false,
                eventListFail: true,
                errorMessage: action.payload,
            };

        //record event

        case RECORD_EVENT:
            return {
                ...state,
                recordEventRequest: true,
                recordEventSuccess: false,
                recordEventFail: false,
                recordEvent: [],
                errorMessage: '',
            };

        case RECORD_EVENT_SUCCESS:
            return {
                ...state,
                recordEventRequest: false,
                recordEventSuccess: true,
                recordEventFail: false,
                recordEvent: action.payload.data,
                errorMessage: '',
            };

        case RECORD_EVENT_FAIL:
            return {
                ...state,
                recordEventRequest: false,
                recordEventSuccess: false,
                recordEventFail: true,
                errorMessage: action.payload,
            };

        //verify

        case VERIFY_EVENT:
            return {
                ...state,
                verifyEventRequest: true,
                verifyEventSuccess: false,
                verifyEventFail: false,
                verifyEvent: [],
                errorMessage: '',
            };

        case VERIFY_EVENT_SUCCESS:
            return {
                ...state,
                verifyEventRequest: false,
                verifyEventSuccess: true,
                verifyEventFail: false,
                verifyEvent: action.payload.data,
                errorMessage: '',
            };

        case VERIFY_EVENT_FAIL:
            return {
                ...state,
                verifyEventRequest: false,
                verifyEventSuccess: false,
                verifyEventFail: true,
                errorMessage: action.payload,
            };
        //record single event
        case RECORD_MATCH:
            return {
                ...state,
                recordingMatch: action.payload,
            };
        //VERIFY SINGLE EVENT
        case VERIFY_MATCH:
            return {
                ...state,
                verifyingMatch: action.payload,
            };
        //mark match complete
        case COMPLETE_EVENT:
            return {
                ...state,
                completeMatchRequest: true,
                completeMatchSuccess: false,
                completeMatchFail: false,
                completeMatch: [],
                errorMessage: '',
            };

        case COMPLETE_EVENT_SUCCESS:
            return {
                ...state,
                completeMatchRequest: false,
                completeMatchSuccess: true,
                completeMatchFail: false,
                completeMatch: action.payload.data,
                errorMessage: '',
            };

        case COMPLETE_EVENT_FAIL:
            return {
                ...state,
                completeMatchRequest: false,
                completeMatchSuccess: false,
                completeMatchFail: true,
                errorMessage: action.payload,
            };

        case COMPLETE_MATCH_V2:
            return {
                ...state,
                isMatchCompletingV2: true,
                isMatchCompletingV2Success: false,
                isMatchCompletingV2Fail: false,
                errorMessage: '',
            };

        case COMPLETE_MATCH_V2_SUCCESS:
            return {
                ...state,
                isMatchCompletingV2: false,
                isMatchCompletingV2Success: true,
                isMatchCompletingV2Fail: false,
                errorMessage: action.payload,
            };

        case COMPLETE_MATCH_V2_FAIL:
            return {
                ...state,
                isMatchCompletingV2: false,
                isMatchCompletingV2Success: false,
                isMatchCompletingV2Fail: true,
                errorMessage: action?.payload,
            };

        case COMPILE_MATCH_V2:
            return {
                ...state,
                isMatchCompilingV2: true,
                isMatchCompilingV2Success: false,
                isMatchCompilingV2Fail: false,
                errorMessage: '',
            };

        case COMPILE_MATCH_V2_SUCCESS:
            return {
                ...state,
                isMatchCompilingV2: false,
                isMatchCompilingV2Success: true,
                isMatchCompilingV2Fail: false,
                errorMessage: action.payload,
            };

        case COMPILE_MATCH_V2_FAIL:
            return {
                ...state,
                isMatchCompilingV2: false,
                isMatchCompilingV2Success: false,
                isMatchCompilingV2Fail: true,
                errorMessage: action?.payload,
            };

        case WINNING_DISBURSAL_DETAILS:
            return {
                ...state,
                isWinningDisbursalDetails: true,
                isWinningDisbursalDetailsSuccess: false,
                isWinningDisbursalDetailsFail: false,
                winningDisbursalDetails: {},
                errorMessage: '',
            };

        case WINNING_DISBURSAL_DETAILS_SUCCESS:
            return {
                ...state,
                isWinningDisbursalDetails: false,
                isWinningDisbursalDetailsSuccess: true,
                isWinningDisbursalDetailsFail: false,
                winningDisbursalDetails: action.payload,
                errorMessage: '',
            };

        case WINNING_DISBURSAL_DETAILS_FAIL:
            return {
                ...state,
                isWinningDisbursalDetails: false,
                isWinningDisbursalDetailsSuccess: false,
                isWinningDisbursalDetailsFail: true,
                errorMessage: action?.payload,
            };

        // event update
        case UPDATE_EVENT:
            return {
                ...state,
                updateEventRequest: true,
                updateEventSuccess: false,
                updateEventFail: false,
                updateEvent: [],
                errorMessage: '',
            };

        case UPDATE_EVENT_SUCCESS:
            return {
                ...state,
                updateEventRequest: false,
                updateEventSuccess: true,
                updateEventFail: false,
                updateEvent: action.payload.data,
                errorMessage: '',
            };

        case UPDATE_EVENT_FAIL:
            return {
                ...state,
                updateEventRequest: false,
                updateEventSuccess: false,
                updateEventFail: true,
                updateEvent: [],
                errorMessage: action.payload,
            };

        //event delete
        case DELETE_EVENT:
            return {
                ...state,
                deleteEventRequest: true,
                deleteEventSuccess: false,
                deleteEventFail: false,
                deleteEvent: [],
                errorMessage: '',
            };

        case DELETE_EVENT_SUCCESS:
            return {
                ...state,
                deleteEventRequest: false,
                deleteEventSuccess: true,
                deleteEventFail: false,
                deleteEvent: action.payload.data,
                errorMessage: '',
            };

        case DELETE_EVENT_FAIL:
            return {
                ...state,
                deleteEventRequest: false,
                deleteEventSuccess: false,
                deleteEventFail: true,
                deleteEvent: [],
                errorMessage: action.payload,
            };

        // PLAYER LIST FOR MATCH
        case PLAYERS_MATCH:
            return {
                ...state,
                playersMatchRequest: true,
                playersMatchSuccess: false,
                playersMatchFail: false,
                playersMatch: [],
                errorMessage: '',
            };

        case PLAYERS_MATCH_SUCCESS:
            return {
                ...state,
                playersMatchRequest: false,
                playersMatchSuccess: true,
                playersMatchFail: false,
                playersMatch: action.payload.data,
                errorMessage: '',
            };

        case PLAYERS_MATCH_FAIL:
            return {
                ...state,
                playersMatchRequest: false,
                playersMatchSuccess: false,
                playersMatchFail: true,
                errorMessage: action.payload,
            };

        // compile the match
        //mark match complete
        case COMPILE_EVENT:
            return {
                ...state,
                compileMatchRequest: true,
                compileMatchSuccess: false,
                compileMatchFail: false,
                compileMatch: [],
                errorMessage: '',
            };

        case COMPILE_EVENT_SUCCESS:
            return {
                ...state,
                compileMatchRequest: false,
                compileMatchSuccess: true,
                compileMatchFail: false,
                compileMatch: action.payload.data,
                errorMessage: '',
            };

        case COMPILE_EVENT_FAIL:
            return {
                ...state,
                compileMatchRequest: false,
                compileMatchSuccess: false,
                compileMatchFail: true,
                errorMessage: action.payload,
            };

        case PUBLISH_MATCH_SUMMARY:
            return {
                ...state,
                isPublishMatchSummary: true,
                isPublishMatchSummarySuccess: false,
                isPublishMatchSummaryFail: false,
                errorMessage: '',
            };

        case PUBLISH_MATCH_SUMMARY_SUCCESS:
            return {
                ...state,
                isPublishMatchSummary: false,
                isPublishMatchSummarySuccess: true,
                isPublishMatchSummaryFail: false,
                errorMessage: action.payload,
            };

        case PUBLISH_MATCH_SUMMARY_FAIL:
            return {
                ...state,
                isPublishMatchSummary: false,
                isPublishMatchSummarySuccess: false,
                isPublishMatchSummaryFail: true,
                errorMessage: action.payload,
            };

        case VERIFY_SWITCH_LEADERBOARD_POINTS:
            return {
                ...state,
                verifySwitchLeaderboardPoints: true,
                verifySwitchLeaderboardPointsSuccess: false,
                verifySwitchLeaderboardPointsFail: false,
                verifySwitchLeaderboardPointsDetails: {},
                errorMessage: '',
            };

        case VERIFY_SWITCH_LEADERBOARD_POINTS_SUCCESS:
            return {
                ...state,
                verifySwitchLeaderboardPoints: false,
                verifySwitchLeaderboardPointsSuccess: true,
                verifySwitchLeaderboardPointsFail: false,
                verifySwitchLeaderboardPointsDetails: action?.payload,
                errorMessage: '',
            };

        case VERIFY_SWITCH_LEADERBOARD_POINTS_FAIL:
            return {
                ...state,
                verifySwitchLeaderboardPoints: false,
                verifySwitchLeaderboardPointsSuccess: false,
                verifySwitchLeaderboardPointsFail: true,
                errorMessage: action?.payload,
            };

        default:
            return state;
    }
};

export default recordMatchReducer;
