import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import '../../../Utilities/style.css';
import '../Login/login.css';
import {getBannerList, getBanner} from './action';
import {logout} from '../Login/LoginAction';
import {debounce, next, prev} from '../../../Utilities/HelperFunctions';
import {timeFormat} from '../../../Utilities/Helpers';

import TableLayout from '../../Layouts/TableLayout/TableLayout';
import Table from '../../../Components/Table/Table';
import Snackbar from '../../../Components/Snackbar/Snackbar';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';
import FilterInput from '../../../Components/FilterInput';
import DropDown from '../../../Components/Dropdown/Dropdown';
import Button from '../../../Components/Button/Button';
import {BANNER_TYPE_OPTIONS, COMMON_STATUS, ENTER} from '../../../Utilities/Constants';
import CreateEditBannerModal from './CreateEditBannerModal';

class Banners extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageOptions: {
                page: 1,
                pageSize: 20,
            },
        };
    }

    componentDidMount() {
        const {login, history} = this.props;
        if (!login.userDetails.data) {
            history.push('/');
            this.props.logout();
        } else {
            this.getBannerListDebounce({immediate: true});
        }
    }

    componentDidUpdate(prevProps) {
        const {banners, login} = this.props;

        if (
            (banners.isFetchingBanners && !prevProps.banners.isFetchingBanners) ||
            (banners.isFetchingBanner && !prevProps.banners.isFetchingBanner)
        ) {
            this.setState({loading: true});
        }

        if (banners.isFetchingBannersSuccess && !prevProps.banners.isFetchingBannersSuccess) {
            this.setState({loading: false});
        }

        if (banners.isFetchingBannerSuccess && !prevProps.banners.isFetchingBannerSuccess) {
            this.setState({loading: false, bannerData: banners.bannerDetails});
        }

        if (banners.isCreatingBannerSuccess && !prevProps.banners.isCreatingBannerSuccess) {
            this.setState({message: 'Successfully Uploaded Banner', bannerData: undefined});
            this.getBannerListDebounce({immediate: true});
        }

        if (banners.isUpdatingBannerSuccess && !prevProps.banners.isUpdatingBannerSuccess) {
            this.setState({message: 'Successfully Updated', bannerData: undefined});
            this.getBannerListDebounce({immediate: true});
        }

        if (
            (banners.isFetchingBannersFail && !prevProps.banners.isFetchingBannersFail) ||
            (banners.isFetchingBannerFail && !prevProps.banners.isFetchingBannerFail)
        ) {
            this.setState({message: banners.message.errorReason, loading: false});
        }
    }

    /**
     * api call on click of next
     */
    onNext = () => {
        const {pageOptions} = this.state;
        const {
            banners: {
                bannerList: {pageData = {}},
            },
        } = this.props;
        let options = next(pageOptions, pageData);
        if (options) {
            this.setState({pageOptions: options}, () => this.getBannerListDebounce({immediate: true}));
        }
    };

    /**
     * api call on click of prev
     */
    onPrev = () => {
        const {pageOptions} = this.state;
        let options = prev(pageOptions);
        if (options) {
            this.setState({pageOptions: options}, () => this.getBannerListDebounce({immediate: true}));
        }
    };

    /**
     * reset banner filters
     */
    onReset = () => {
        this.setState({pageOptions: {page: 1, pageSize: 20}}, () => {
            this.getBannerListDebounce({immediate: true});
        });
    };

    /**
     * navigate to selected banner page with details
     * @param {string} id id of banner selected
     */
    handleClickOnBannerId = (bannerId) => {
        const {login, client} = this.props;
        this.props.getBanner({bannerId}, login.userDetails.data.accessToken, client.selectedClient);
    };

    handleCreateBanner = () => {
        this.setState({bannerData: {}});
    };

    getBannerListDebounce = debounce(() => {
        const {login} = this.props;
        this.props.getBannerList(
            this.state.pageOptions,
            login.userDetails.data.accessToken,
            this.props.client.selectedClient,
        );
    });

    handleFilter = (field, value, debounce = true) => {
        const {pageOptions} = this.state;
        if (field === ENTER) {
            this.getBannerListDebounce({immediate: true});
            return;
        }
        pageOptions[field] = value;
        this.setState({pageOptions: {...pageOptions, page: 1}}, () => {
            this.getBannerListDebounce(debounce ? {} : {immediate: true});
        });
    };

    render() {
        const {message, loading, bannerData} = this.state;
        const {bannerType, page, status, matchId, bannerId} = this.state.pageOptions;
        const {
            history,
            banners: {
                bannerList: {data = [], pageData = {}},
            },
            login,
        } = this.props;
        let disableNext = page >= pageData.lastPage;

        const {bannerWrite} = login?.permissions || {};

        const columns = [
            {
                Header: 'Banner ID',
                accessor: 'id',
                width: 200,
                Cell: (props) => (
                    <span className="table-clickable cursor" onClick={() => this.handleClickOnBannerId(props.value)}>
                        {props.value ? props.value : '-'}
                    </span>
                ),
            },
            {
                Header: 'Status',
                accessor: 'status',
                width: 100,
                Cell: (props) => <span>{props.value ? props.value : '-'}</span>,
            },
            {
                Header: 'Banner Size',
                accessor: 'bannerSize',
                width: 100,
                Cell: (props) => <span>{props.value ? props.value : '-'}</span>,
            },
            {
                Header: 'Banner Order',
                accessor: 'bannerOrder',
                width: 100,
                Cell: (props) => <span>{props.value ? props.value : '-'}</span>,
            },
            {
                Header: 'Target Audience',
                accessor: 'targetAudience',
                width: 100,
                Cell: (props) => <span>{props.value ? props.value : '-'}</span>,
            },
            {
                Header: 'Banner Type',
                accessor: 'bannerType',
                width: 100,
                Cell: (props) => <span>{props.value ? props.value : '-'}</span>,
            },
            {
                Header: 'Match ID',
                accessor: 'matchId',
                width: 200,
                Cell: (props) => <span>{props.value ? props.value : '-'}</span>,
            },
            {
                Header: 'Navigate',
                accessor: 'navigate',
                width: 200,
                Cell: (props) => <span>{props.value ? props.value : '-'}</span>,
            },
            {
                Header: 'Source',
                accessor: 'source',
                width: 200,
                Cell: (props) => <span>{props.value ? props.value : '-'}</span>,
            },
            {
                Header: 'Expiry At',
                accessor: 'expiryAt',
                width: 100,
                Cell: (props) => (
                    <span className="number date-space">{props.value ? timeFormat(props.value) : '-'}</span>
                ),
            },
            {
                Header: 'CreatedAt',
                accessor: 'createdAt',
                width: 100,
                Cell: (props) => (
                    <span className="number date-space">{props.value ? timeFormat(props.value) : '-'}</span>
                ),
            },
            {
                Header: 'UpdatedAt',
                accessor: 'updatedAt',
                width: 100,
                Cell: (props) => (
                    <span className="number date-space">{props.value ? timeFormat(props.value) : '-'}</span>
                ),
            },
        ];
        return (
            <TableLayout location="Banners" history={history} hideSearchBar>
                <LoadingModal open={!!loading} />
                <Snackbar open={!!message} message={message} onClose={() => this.setState({message: ''})} />

                {!!bannerData && (
                    <CreateEditBannerModal
                        open={!!bannerData}
                        bannerData={bannerData}
                        handleClose={() => this.setState({bannerData: undefined})}
                    />
                )}

                <div className="filter-container my-10">
                    <FilterInput
                        placeholder="Banner Id"
                        value={bannerId || ''}
                        onChange={(value) => this.handleFilter('bannerId', value)}
                        onEnter={() => this.handleFilter(ENTER)}
                    />
                    <FilterInput
                        placeholder="Match Id"
                        value={matchId || ''}
                        onChange={(value) => this.handleFilter('matchId', value)}
                        onEnter={() => this.handleFilter(ENTER)}
                    />
                    <DropDown
                        label="Status"
                        value={status || ''}
                        menuItems={COMMON_STATUS}
                        onChange={(value) => this.handleFilter('status', value, false)}
                    />
                    <DropDown
                        label="Banner type"
                        value={bannerType || ''}
                        menuItems={BANNER_TYPE_OPTIONS}
                        onChange={(value) => this.handleFilter('bannerType', value, false)}
                    />
                    <Button buttonText="Reset" onClick={this.onReset} />
                </div>
                <div className="filter-container margin-bottom-10">
                    {!!bannerWrite && <Button buttonText="Create Banner" onClick={this.handleCreateBanner} />}
                </div>

                <Table
                    data={data}
                    columns={columns}
                    page={page}
                    onPrevClick={this.onPrev}
                    onNextClick={this.onNext}
                    disableNext={disableNext}
                ></Table>
            </TableLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        banners: state.banners,
        client: state.client,
    };
};

const matchDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getBannerList,
            getBanner,
            logout,
        },
        dispatch,
    );

export default connect(mapStateToProps, matchDispatchToProps)(Banners);
