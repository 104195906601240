import React from 'react';
import '../../../Utilities/style.css';
import '../Login/login.css';
import DetailLayout from '../../Layouts/DetailLayout/DetailLayout';
import Table from '../../../Components/Table/Table';
import {next, prev, reset} from '../../../Utilities/HelperFunctions';

import {connect} from 'react-redux';
import Modal from '../../../Components/Modal/Modal';
import Card from '../../../Components/Card/Card';
import Close from '../../../Images/close_yellow.png';
import Button from '../../../Components/Button/Button';
import {transactionList} from '../Users/AxioCalls';
import moment from 'moment';
import {logout} from '../Login/LoginAction';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';
import Snackbar from '../../../Components/Snackbar/Snackbar';

class DiscountCodeTransactions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: [],
            selectAll: false,
            list: [],
            open: false,
            adminpswd: '',
            pageData: {},
            loading: true,
            errorMessage: '',
            snackbarOpen: false,
            pageOptions: {
                page: 1,
                pageSize: 10,
                searchString: null,
                sortOrder: null,
                discountId: this.props.discount.currentDiscountCode.id,
            },
            sortOptions: [
                {
                    value: 'txnType',
                    text: 'Type',
                },
                {
                    value: 'amount',
                    text: 'Amount',
                },
                {
                    value: 'coinClosingBalance',
                    text: 'Closing Balance',
                },
            ],
        };
    }

    /**
     * open discount code delete modal on delete click
     */
    onDeleteClick() {
        this.setState({open: true});
    }

    /**
     * function to save entered data in state
     * @param {string} field field names from the form
     * @param {*} value value in the field
     */
    handleInputChange(field, value) {
        this.setState({
            [field]: value,
            snackbarOpen: false,
            errorMessage: '',
        });
    }

    componentWillMount() {
        const {login, history, client} = this.props;
        if (!login.userDetails.data) {
            history.push('/');
            this.props.logout();
        } else {
            const {pageOptions} = this.state;

            this.props.transactionList(pageOptions, login.userDetails.data.accessToken, client.selectedClient);
        }
    }

    componentDidUpdate(prevProps) {
        const {user} = this.props;
        if (user.userTransactionSuccess && !prevProps.user.userTransactionSuccess) {
            this.setState({
                loading: false,
                list: user.userTransaction.data.data,
            });
        } else if (user.userTransactionFail && !prevProps.user.userTransactionFail) {
            this.setState({
                loading: false,
                snackbarOpen: true,
                errorMessage: user.errorMessage,
            });
        }
    }

    /**
     * APi call with search and sort strings
     * @param {string} field field names of input
     * @param {string} value value in the field
     */
    onSearchandSort(field, value) {
        const {pageOptions} = this.state;
        const {login, client} = this.props;
        let options = pageOptions;
        options[field] = value;
        this.setState({pageOptions: options});
        if (field === 'searchString' && value.length > 2) {
            this.props.transactionList(options, login.userDetails.data.accessToken, client.selectedClient);
        } else if (field === 'sortOrder') {
            this.props.transactionList(options, login.userDetails.data.accessToken, client.selectedClient);
        }
    }

    /**
     * pagination and api call on next click
     */
    onNext() {
        const {pageData, pageOptions} = this.state;
        const {login, client} = this.props;
        let options = next(pageOptions, pageData);

        if (options) {
            this.props.transactionList(options, login.userDetails.data.accessToken, client.selectedClient);
            this.setState({pageOptions: options, loading: true});
        }
    }

    /**
     * pagination and api call on prev click
     */
    onPrev() {
        const {pageOptions} = this.state;
        const {login, client} = this.props;
        let options = prev(pageOptions);
        if (options) {
            this.props.transactionList(options, login.userDetails.data.accessToken, client.selectedClient);
            this.setState({pageOptions: options, loading: true});
        }
    }

    /**
     * reseting search and sort inputs
     */
    onReset() {
        const {pageOptions} = this.state;
        const {login, client} = this.props;
        let options = reset(pageOptions);
        this.setState({
            pageOptions: options,
        });

        this.props.transactionList(options, login.userDetails.data.accessToken, client.selectedClient);
    }

    render() {
        const {list, open, loading, snackbarOpen, errorMessage, sortOptions, pageData} = this.state;
        const {searchString, sortOrder, page} = this.state.pageOptions;

        let disableNext = page === pageData.lastPage ? true : false;
        const data = list;
        const {history, discount} = this.props;

        const columns = [
            {
                Header: 'DiscountId',
                accessor: 'user',
                width: 200,
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Transaction ID',
                accessor: 'col2',
                width: 200,

                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },

            {
                Header: 'Match ID',
                accessor: 'match', // accessor is the "key" in the data
                width: 200,

                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Contest ID',
                accessor: 'contest',
                width: 200,
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Team ID',
                accessor: 'teams', // accessor is the "key" in the data
                width: 200,

                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value.length}
                    </span>
                ),
            },
            {
                Header: '# of Teams',
                accessor: 'teams',

                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value.length}
                    </span>
                ),
            },
            {
                Header: 'Match Start Time',
                accessor: 'pubgName',

                Cell: (props) => (
                    <span className="date-space">{moment(props.value).format('hh:mm A [\n] DD-MM-YYYY')}</span>
                ),
            },
            {
                Header: 'Paid On',
                accessor: 'pubgName',

                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Payment Via',
                accessor: 'pubgName',

                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Initial',
                accessor: 'pubgName',

                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Discount',
                accessor: 'pubgName',

                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Disc Type',
                accessor: 'pubgName',

                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Final Paid',
                accessor: 'pubgName',

                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Contest Status',
                accessor: 'status',

                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
        ];
        return (
            <DetailLayout
                location="discountCode"
                history={history}
                searchbar="Search DiscountId / Match ID / Contest ID / Transaction ID "
                onDeleteClick={this.onDeleteClick.bind(this)}
                search={true}
                centerTitle="Discount Code Transactions"
                onsearchChange={this.onSearchandSort.bind(this)}
                sortOptions={sortOptions}
                id={discount.currentDiscountCode.discountCode}
                onReset={this.onReset.bind(this)}
                searchString={searchString}
                sortOrder={sortOrder}
            >
                <LoadingModal open={loading} />
                <Snackbar
                    open={snackbarOpen}
                    message={errorMessage}
                    onClose={() => this.setState({snackbarOpen: false})}
                />
                <Modal open={open}>
                    <div className="container modal-top-margin">
                        <Card>
                            <div className="disp-flex">
                                <div className="modal-left-header">Delete Code</div>
                                <div onClick={() => this.setState({open: false})}>
                                    <img src={Close} alt="" className="cursor" />
                                </div>
                            </div>
                            <div className="align-center equalcontainer-grids">
                                <div />
                                <div>
                                    <div className="disp-flex align-center margin-top-30">
                                        <div className="margin-right-16">
                                            <Button buttonText="Delete" onClick={this.handleInputChange.bind(this)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                </Modal>
                <Table
                    data={data}
                    columns={columns}
                    pageSize={10}
                    // removePagination={true}
                    page={page}
                    onPrevClick={this.onPrev.bind(this)}
                    onNextClick={this.onNext.bind(this)}
                    disableNext={disableNext}
                ></Table>
            </DetailLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        squad: state.squad,
        player: state.player,
        discount: state.discount,
        user: state.user,
        client: state.client,
    };
};

export default connect(mapStateToProps, {transactionList, logout})(DiscountCodeTransactions);
