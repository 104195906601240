import React from 'react';
import './FileInput.css';
import PropTypes from 'prop-types';

class FileInput extends React.Component {
    static propTypes = {
        required: PropTypes.bool,
        onClickSampleFile: PropTypes.func,
        onChange: PropTypes.func,
        accept: PropTypes.string,
        type: PropTypes.string,
        label: PropTypes.string,
        name: PropTypes.string,
        className: PropTypes.string,
    };

    static defaultProps = {
        onChange: () => {},
        required: false,
        accept: '',
        type: 'file',
        label: '',
        name: '',
        className: '',
    };

    constructor(props) {
        super(props);
        this.state = {};
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(a) {
        let file = a.target.files[0];
        if (!file) return;
        this.props.onChange(file);
    }

    handleClear = (event) => {
        event.target.value = null;
    };

    render() {
        let {className, required, accept, label, type, name, onClickSampleFile} = this.props;
        return (
            <div className="files">
                <label>
                    {label}
                    {!!onClickSampleFile && (
                        <span className="ml-15 clickable-icon text-yellow" onClick={onClickSampleFile}>
                            (Click here for sample file)
                        </span>
                    )}
                </label>
                <input
                    className={className}
                    type={type}
                    required={required}
                    accept={accept}
                    name={name}
                    onChange={this.handleInputChange}
                    onDrop={this.handleClear}
                    onClick={this.handleClear}
                />
            </div>
        );
    }
}

export default FileInput;
