import React from 'react';
import '../../../Utilities/style.css';
import '../Login/login.css';
import {connect} from 'react-redux';
import DetailLayout from '../../Layouts/DetailLayout/DetailLayout';
import Modal from '../../../Components/Modal/Modal';
import Card from '../../../Components/Card/Card';
import Close from '../../../Images/close_yellow.png';
import Input from '../../../Components/Input/Input';
import {TOURL} from '../../../Utilities/Constants';
import ImageInput from '../../../Components/ImageInput/ImageInput';
import DropDown from '../../../Components/Dropdown/Dropdown';
import {createTOGame, updateTOGame, deleteTOGame} from './AxioCalls';
import Snackbar from '../../../Components/Snackbar/Snackbar';
import {logout} from '../Login/LoginAction';
import axios from 'axios';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';
import Button from '../../../Components/Button/Button';
import {isValidNumber} from '../../../Utilities/Helpers';

class AddTOGame extends React.Component {
    constructor(props) {
        super(props);
        let location = this.props.history.location.pathname.includes('edit');
        this.state = {
            activeOptions: [
                {value: 'ACTIVE', text: 'ACTIVE'},
                {value: 'INACTIVE', text: 'INACTIVE'},
            ],
            gameName: null,
            gameImage: null,
            s3Url: null,
            uploadImage: null,
            prizePool: null,
            gameStatus: 'ACTIVE',
            loading: false,
            open: false,
            edit: location,
        };
    }

    handleInputChange(field, value) {
        this.setState({[field]: value, errorMessage: ''});
        if (field === 'uploadImage') {
            this.setState({loading: true});
            const data = new FormData();
            data.append('gameImage', value);
            axios
                .post(`${TOURL}/game/imageupload`, data)
                .then((res) => {
                    this.setState({loading: false});
                    if (res.data.data) this.setState({gameImage: res.data.data.image, s3Url: res.data.data.s3Url});
                })
                .catch((err) => {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    this.setState({loading: false, errorMessage: errorMsg});
                });
        }
    }

    onSave() {
        const {gameName, gameImage, s3Url, gameStatus, sortOrder, edit} = this.state;
        const {login, client} = this.props;

        if (gameName === null || gameName === '' || gameName === undefined) {
            this.setState({errorMessage: 'please enter game name'});
        } else if (gameImage === null || gameImage === undefined || gameImage === '') {
            this.setState({errorMessage: 'please enter valid game Image'});
        } else {
            let game = {
                gameName: gameName,
                gameImage: gameImage,
                s3Url,
                status: gameStatus,
            };
            if (isValidNumber(sortOrder)) game.sortOrder = parseInt(sortOrder);
            this.setState({loading: true});
            if (edit) {
                game.id = this.props.toGame.editTOGame.id;
                this.props.updateTOGame(game, login.userDetails.data.accessToken, client.selectedClient);
            } else {
                this.props.createTOGame(game, login.userDetails.data.accessToken, client.selectedClient);
            }
        }
    }

    componentDidMount() {
        const {login, toGame, history} = this.props;
        if (!login.userDetails.data) {
            history.push('/');
            this.props.logout();
        } else {
            const {edit} = this.state;
            if (edit) {
                const {gameName, gameImage, s3Url, totalPool, status, sortOrder} = toGame.editTOGame;
                this.setState({
                    gameName: gameName,
                    gameImage: gameImage,
                    s3Url,
                    sortOrder,
                    prizePool: totalPool,
                    gameStatus: status,
                });
            }
        }
    }

    componentDidUpdate(prevProps) {
        const {toGame, history} = this.props;

        if (toGame.addTOGameSuccess && !prevProps.toGame.addTOGameSuccess) {
            history.push('/toGames');
        } else if (toGame.addTOGameFail && !prevProps.toGame.addTOGameFail) {
            this.setState({loading: false, errorMessage: toGame.errorMessage});
        }
        if (toGame.updateTOGameSuccess && !prevProps.toGame.updateTOGameSuccess) {
            history.push('/toGames');
        } else if (toGame.updateTOGameFail && !prevProps.toGame.updateTOGameFail) {
            this.setState({loading: false, errorMessage: toGame.errorMessage});
        }
        if (toGame.deleteTOGameSuccess && !prevProps.toGame.deleteTOGameSuccess) {
            this.setState({loading: false, code: [], errorMessage: toGame.deleteTOGame.msg});
            history.push('/toGames');
        } else if (toGame.deleteTOGameFail && !prevProps.toGame.deleteTOGameFail) {
            this.setState({loading: false, errorMessage: toGame.errorMessage});
        }
    }

    //open modal on delete click
    onDeleteClick() {
        this.setState({open: true});
    }

    //api call to delete selcted code
    onDeleteTOGame() {
        const {login} = this.props;
        let gameId = this.props.toGame.editTOGame.id;
        this.props.deleteTOGame(gameId, login.userDetails.data.accessToken);
        this.setState({open: false, loading: true});
    }

    render() {
        const {
            gameName,
            gameImage,
            prizePool,
            gameStatus,
            errorMessage,
            loading,
            open,
            edit,
            activeOptions,
            sortOrder,
        } = this.state;
        const {history, toGame, login} = this.props;
        let title = edit ? 'Edit Game' : 'Add  Game';
        const {toGameWrite} = login?.permissions || {};

        return (
            <DetailLayout
                location="AddTOGame"
                history={history}
                centerTitle={title}
                onSave={!!toGameWrite ? this.onSave.bind(this) : undefined}
                editPage={edit}
                id={edit ? toGame.editTOGame.id : null}
                onDeleteClick={!!toGameWrite ? this.onDeleteClick.bind(this) : undefined}
            >
                {loading && <LoadingModal open={loading} />}

                <Modal open={open}>
                    <div className="container modal-top-margin">
                        <Card>
                            <div className="disp-flex">
                                <div className="modal-left-header">Delete Game</div>
                                <div onClick={() => this.setState({open: false})}>
                                    <img src={Close} alt="" className="cursor" />
                                </div>
                            </div>
                            <div className="align-center equalcontainer-grids">
                                <div />
                                <div>
                                    <div className="disp-flex align-center margin-top-30">
                                        <div className="margin-right-16">
                                            <Button buttonText="Delete" onClick={this.onDeleteTOGame.bind(this)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                </Modal>

                {!!errorMessage && (
                    <Snackbar
                        open={!!errorMessage}
                        message={errorMessage}
                        onClose={() => this.setState({errorMessage: ''})}
                    />
                )}

                <div className="detail-container margin-top-20">
                    <div className="grid-item" />
                    <div className="grid-item">
                        <Card>
                            <div>
                                <div className="card-grid-container">
                                    {/* row 1 */}
                                    <div className="grid-item  margin-top-20">
                                        <Input
                                            label="Game Name"
                                            type="text"
                                            name="gameName"
                                            value={gameName}
                                            onChange={this.handleInputChange.bind(this, 'gameName')}
                                            placeholder="Enter Game Name"
                                            disabled={false}
                                        />
                                    </div>

                                    <div className="grid-item margin-top-20 align-right"></div>

                                    {/* row 2 */}
                                    <div className="grid-item  margin-top-20">
                                        <Input
                                            label="Game Image"
                                            type="text"
                                            name="gameImage"
                                            value={gameImage}
                                            onChange={this.handleInputChange.bind(this, 'gameImage')}
                                            placeholder="Enter Game Image"
                                            disabled={true}
                                        />
                                        {/* <DropDown
                                            label="Discount Type"
                                            type="text"
                                            menuItems={discountTypeOptions}
                                            name="discountType"
                                            value={discountType}
                                            onChange={this.handleInputChange.bind(this, "discountType")}
                                            placeholder="Select"
                                            disabled={disabled} /> */}
                                    </div>

                                    {gameImage !== undefined && gameImage !== null && gameImage !== '' ? (
                                        <div className="grid-item  margin-top-20">
                                            <img src={gameImage} style={{height: 100}} />
                                        </div>
                                    ) : (
                                        <div className="grid-item  margin-top-20"></div>
                                    )}

                                    {!!toGameWrite && (
                                        <div className="grid-item  margin-top-20">
                                            <ImageInput
                                                label="Upload Your File"
                                                type="file"
                                                name="file"
                                                onChange={this.handleInputChange.bind(this, 'uploadImage')}
                                            />
                                        </div>
                                    )}

                                    <div className="grid-item  margin-top-20"></div>

                                    {/* row 3 */}
                                    <div className="grid-item  margin-top-20">
                                        <Input
                                            label="Prize Pool"
                                            type="text"
                                            name="prizePool"
                                            value={prizePool}
                                            onChange={this.handleInputChange.bind(this, 'prizePool')}
                                            placeholder="Prize Pool"
                                            // suffix={discountType === "PERCENT" ? "%" : undefined}
                                            disabled={true}
                                        />
                                    </div>

                                    <Input
                                        className="mb-15"
                                        label="Sort Order"
                                        type="number"
                                        name="sortOrder"
                                        value={sortOrder || ''}
                                        placeholder="Enter sort order"
                                        onChange={this.handleInputChange.bind(this, 'sortOrder')}
                                    />

                                    {/* row 4 */}
                                    <div className="grid-item margin-top-20">
                                        <DropDown
                                            label="Active Status"
                                            type="text"
                                            menuItems={activeOptions}
                                            name="gameStatus"
                                            value={gameStatus}
                                            onChange={this.handleInputChange.bind(this, 'gameStatus')}
                                            placeholder="Select"
                                            disabled={false}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className="grid-item"></div>
                </div>
            </DetailLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        toGame: state.toGame,
        client: state.client,
    };
};

export default connect(mapStateToProps, {createTOGame, logout, deleteTOGame, updateTOGame})(AddTOGame);
