import axios from 'axios';
import {URL} from '../../../Utilities/Constants';

import {createRewardUserRequest, createRewardUserSuccess, createRewardUserFail} from './action';

/*
 * create refund api request
 * @param {*} data Refund data
 * @param {string} token user token
 */
export const createRewardUser = (data, token, clientId) => (dispatch) => {
    dispatch(createRewardUserRequest());
    axios({
        method: 'post',
        headers: {Authorization: 'Bearer ' + token, clientid: clientId},
        url: `${URL}/rewardUser`,
        data: data,
    })
        .then((data) => {
            if (data.data.status === 'success') {
                dispatch(createRewardUserSuccess(data));
            } else if (data.data.status === 'fail') {
                dispatch(createRewardUserFail(data.data.errorData.errorReason));
            } else {
                dispatch(createRewardUserFail('Unknown Error Occured'));
            }
        })
        .catch((err) => {
            if (err.response) {
                const errorMsg = err?.response?.data?.msg || err.message;
                dispatch(createRewardUserFail(errorMsg || 'Something went wrong'));
            } else {
                dispatch(createRewardUserFail(err.message || 'Something went wrong'));
            }
        });
};
