import React from 'react';
import SearchInputField from '../../Components/SearchInputField/SearchInputField';
import '../../Utilities/style.css';
import '../MainNavbar/MainNavbar.css';
import './SearchBar.css';
import DropDown from '../../Components/Dropdown/Dropdown';
import Button from '../../Components/Button/Button';
import Delete from '../../Images/delete.png';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import MuiButton from '../MuiButton/MuiButton';

class SearchBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menuItems: [
                {value: 'Active', text: 'Active'},
                {value: 'Inactive', text: 'Inactive'},
            ],
            filter: 'none',
        };
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    /**
     * send field and events to parent component (on select of dowpdown)
     * @param {*} event name of field
     * @param {*} value value in field
     */
    handleInputChange(field, value) {
        this.setState({[field]: value});
    }

    /*onEnterPressed(val) {
        this.props.onEnterPressed(val);
    }
    onEnterPressed2(val) {
        this.props.onEnterPressed2(val);
    }*/

    render() {
        const {
            location,
            history,
            onDeleteClick,
            searchbar,
            userEntry,
            dispDelete,
            onReset,
            sortOptions,
            sortOrder,
            searchString,
            filterOptions,
            permissions = {},
        } = this.props;
        const {filter} = this.state;
        const {date} = this.state;
        let className = location === 'verify' ? 'grid-item disp-flex min-width' : 'grid-item disp-flex';
        const {
            playerWrite,
            matchWrite,
            matchAdvanceWrite,
            fcTournamentWrite,
            fantasyScoringWrite,
            toGameWrite,
            toMatchModeWrite,
            toMatchWrite,
            toOrganizerWrite,
            matchesCreateMatchesButton
        } = permissions || {};

        return (
            <div>
                <div className="searchbar marin-top-10 ">
                    <div className="searchBar-grid-container ">
                        <div className={className}>
                            {location === 'record' ? (
                                ''
                            ) : (
                                <ul>
                                    {searchbar && (
                                        <li className="width-100 ">
                                            <SearchInputField
                                                placeholder={searchbar}
                                                value={searchString}
                                                onChange={this.props.onsearchChange.bind(this, 'searchString')}
                                            />
                                        </li>
                                    )}
                                    {this.props.searchbar2 ? (
                                        <li className="width-100 ">
                                            <SearchInputField
                                                placeholder={this.props.searchbar2}
                                                value={this.props.searchString2}
                                                onChange={this.props.onsearchChange2.bind(this, 'searchString2')}
                                            />
                                        </li>
                                    ) : null}
                                </ul>
                            )}
                        </div>

                        <div className="grid-item disp-flex ">
                            {location === 'record'
                                ? ''
                                : sortOptions &&
                                  sortOptions.length && (
                                      <ul className="margin-0">
                                          <li>
                                              <DropDown
                                                  id="sort"
                                                  label="Sort By"
                                                  value={sortOrder}
                                                  onChange={this.props.onsearchChange.bind(this, 'sortOrder')}
                                                  placeholder="Select"
                                                  menuItems={sortOptions}
                                              />
                                          </li>
                                      </ul>
                                  )}
                        </div>

                        <div className="grid-item disp-flex ">
                            {filterOptions == null || filterOptions.length === 0 ? (
                                ''
                            ) : location === 'record' ? (
                                ''
                            ) : (
                                <ul className="margin-0">
                                    <li>
                                        <DropDown
                                            id="filter"
                                            label="Filter"
                                            value={filter}
                                            onChange={(val) => {
                                                this.props.onsearchChange('filters', val);
                                                this.setState({filter: val});
                                            }}
                                            placeholder="Select"
                                            menuItems={filterOptions}
                                        />
                                    </li>
                                </ul>
                            )}
                        </div>

                        <div className="grid-item  align-center">
                            {location === 'record' ? (
                                <div className="searchbar-center-header">Open Live Match to Record</div>
                            ) : location === 'refCode' ? (
                                <ul className="margin-0">
                                    <li className="margin-right-16">
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                margin="normal"
                                                id="date-picker-dialog"
                                                label="Start Date"
                                                format="MM/dd/yyyy"
                                                value={date}
                                                onChange={this.props.onsearchChange.bind(this, 'startDate')}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </li>
                                    <li className="margin-right-16">
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                margin="normal"
                                                id="date-picker-dialog"
                                                label="End Date"
                                                format="MM/dd/yyyy"
                                                value={date}
                                                onChange={this.props.onsearchChange.bind(this, 'endDate')}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </li>

                                    <li>{onReset && <Button buttonText="Reset" onClick={onReset} />}</li>
                                </ul>
                            ) : (
                                <ul className="margin-0 align-left">
                                    <li>{onReset && <Button buttonText="Reset" onClick={onReset} />}</li>
                                </ul>
                            )}
                        </div>
                        {location === 'TOGame' ? (
                            <div className="grid-item disp-flex align-right">
                                {!!toGameWrite && (
                                    <Button buttonText="Add Game" onClick={() => history.push('/addToGame')} />
                                )}
                            </div>
                        ) : location === 'TOMatchMode' ? (
                            <div className="grid-item disp-flex align-right">
                                {!!toMatchModeWrite && (
                                    <Button
                                        buttonText="Add MatchMode"
                                        onClick={() => history.push('/addToMatchMode')}
                                    />
                                )}
                            </div>
                        ) : location === 'TOMatchAttribute' ? (
                            <div className="grid-item disp-flex align-right">
                                {!!toMatchWrite && (
                                    <Button
                                        buttonText="Add MatchAttribute"
                                        onClick={() => history.push('/addToMatchAttribute')}
                                    />
                                )}
                            </div>
                        ) : location === 'TOMatchType' ? (
                            <div className="grid-item disp-flex align-right">
                                {!!toMatchWrite && (
                                    <Button
                                        buttonText="Add MatchType"
                                        onClick={() => history.push('/addToMatchType')}
                                    />
                                )}
                            </div>
                        ) : location === 'TOMatch' ? (
                            <div className="grid-item disp-flex align-right">
                                {!!toMatchWrite && (
                                    <Button buttonText="Add Match" onClick={() => history.push('/addToMatch')} />
                                )}
                                {!!toMatchWrite && (
                                    <Button
                                        buttonText="Reschedule Match"
                                        onClick={() => history.push('/rescheduleTOMatch')}
                                    />
                                )}
                            </div>
                        ) : location === 'TOOrganizer' ? (
                            <div className="grid-item disp-flex align-right">
                                {!!toOrganizerWrite && (
                                    <Button
                                        buttonText="Add Organizer"
                                        onClick={() => history.push('/addToOrganizer')}
                                    />
                                )}
                            </div>
                        ) : location === 'FantasyScoringModels' ? (
                            <div className="grid-item disp-flex align-right">
                                {!!fantasyScoringWrite && (
                                    <Button
                                        buttonText="Add Model"
                                        onClick={() => history.push('/addFantasyScoringModel')}
                                    />
                                )}
                            </div>
                        ) : !!playerWrite && location === 'Players' ? (
                            <div className="grid-item disp-flex align-right">
                                <Button buttonText="Add Player" onClick={() => history.push('/addPlayer')} />
                                <Button buttonText="Upload Players" onClick={() => history.push('/uploadPlayers')} />
                            </div>
                        ) : location === 'Matches' ? (
                            <div className="grid-item disp-flex align-right">
                                { !!matchWrite && !!matchesCreateMatchesButton && (
                                    <MuiButton
                                        buttonText="Create Match"
                                        onButtonClick={() => history.push('/matches/create')}
                                    />
                                )}
                                {/* {!!matchWrite && <Button buttonText="Add Match"
                                                                        onClick={() => history.push("/addMatch")} />} */}
                                {!!fcTournamentWrite && (
                                    <Button
                                        buttonText="Add Tournament"
                                        onClick={() => history.push('/addTournamentScreen')}
                                    />
                                )}
                                {!!matchAdvanceWrite && (
                                    <Button
                                        buttonText="Reschedule Match"
                                        onClick={() => history.push('/rescheduleMatch')}
                                    />
                                )}
                                {!!matchAdvanceWrite && (
                                    <Button
                                        buttonText="Cancel and refund Match"
                                        onClick={() => history.push('/cancelMatch')}
                                    />
                                )}
                                {!!matchAdvanceWrite && (
                                    <Button
                                        buttonText="Set/Reset Match of The Day"
                                        onClick={() => history.push('/matchOfTheDay')}
                                    />
                                )}
                            </div>
                        ) : location === 'ContestTypes' ? (
                            <div className="grid-item disp-flex align-right">
                                <Button
                                    buttonText="Add Contest Type"
                                    onClick={() => this.props.history.push('/addContestType')}
                                />
                            </div>
                        ) : location === 'discount' ? (
                            <div className="grid-item disp-flex align-right">
                                <Button
                                    buttonText="Add Disc Code"
                                    onClick={() => this.props.history.push('/addDiscountCode')}
                                />
                            </div>
                        ) : userEntry ? (
                            <div className="grid-item disp-flex align-right"></div>
                        ) : location === 'admins' ? (
                            <div className="grid-item disp-flex align-right">
                                {dispDelete ? (
                                    <div className="margin-right-16 cursor disp-flex">
                                        <img alt="" src={Delete} onClick={onDeleteClick} />
                                    </div>
                                ) : null}
                                <Button buttonText="Add Admin" onClick={() => this.props.history.push('/addAdmin')} />
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }
}

export default SearchBar;
