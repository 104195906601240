import React from 'react';
import PropTypes from 'prop-types';
import {Checkbox, Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';
import Button from '../../../../Components/Button/Button';
import Input from '../../../../Components/Input/Input';

import './style.css';
import Snackbar from '../../../../Components/Snackbar/Snackbar';
import DropDown from '../../../../Components/Dropdown/Dropdown';
import AutoCompleteField from '../../../../Components/AutoCompleteField/AutoCompleteField';
import {connect} from 'react-redux';
import {createRecommendedTeam, updateRecommendedTeam} from '../action';

class CreateEditExpertTeamModal extends React.Component {
    static propTypes = {
        open: PropTypes.bool,
        handleClose: PropTypes.func,
        createExpertTeam: PropTypes.object,
    };

    static defaultProps = {
        handleClose: () => {},
        open: false,
        createExpertTeam: {},
    };

    submitRef = React.createRef();

    constructor(props) {
        super(props);
        let {
            maxPlayersPerSquad,
            maxTeamSize,
            numberOfCredits,
            squads,
            captain,
            viceCaptain,
            expertName,
            displayTitle,
            ranking,
        } = props.createExpertTeam;

        maxPlayersPerSquad = maxPlayersPerSquad && maxPlayersPerSquad > 0 ? maxPlayersPerSquad : 2;
        maxTeamSize = maxTeamSize && maxTeamSize > 0 ? maxTeamSize : 6;
        numberOfCredits = numberOfCredits && numberOfCredits > 0 ? numberOfCredits : 50;

        let data = {};
        if (props.createExpertTeam.id) data = this.handleGetStateData(numberOfCredits);

        this.state = {
            squads,
            maxPlayersPerSquad,
            maxTeamSize,
            captain,
            viceCaptain,
            expertName,
            displayTitle,
            ranking,
            findSquad: {},
            selectedPlayers: data.selectedPlayers || {},
            numberOfCredits: data.numberOfCredits || numberOfCredits,
            errorMessage: '',
        };
    }

    componentDidMount() {}

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {
            recommendedTeams,
            recommendedTeams: {isCreatingTeamFail, isUpdatingTeamFail},
        } = this.props;

        if (
            (isCreatingTeamFail && !prevProps.recommendedTeams.isCreatingTeamFail) ||
            (isUpdatingTeamFail && !prevProps.recommendedTeams.isUpdatingTeamFail)
        ) {
            this.setState({errorMessage: recommendedTeams.errorMessage});
        }
    }

    handleGetStateData = (numberOfCredits) => {
        let {players, squads} = this.props.createExpertTeam;
        let selectedPlayers = {};
        for (let squad of squads) {
            if (squad.players && squad.players.length) {
                squad.players
                    .filter((p) => p.status === 'ACTIVE')
                    .map((p) => {
                        if (players.indexOf(p.id) > -1) {
                            numberOfCredits = parseFloat((numberOfCredits - p.credits).toFixed(1));
                            selectedPlayers[p.id] = {...p, squadId: squad.id};
                        }
                    });
            }
        }
        return {numberOfCredits, selectedPlayers};
    };

    handleOnChange = (key, value) => {
        if (key === 'findSquad') {
            this.setState({findSquad: value ? value : {}});
        } else {
            this.setState({[key]: value});
        }
    };

    handleSelectPlayer = (player) => {
        let {selectedPlayers, numberOfCredits, captain, viceCaptain} = this.state;
        if (!selectedPlayers[player.id]) {
            let error = this.handleValidation(player);
            if (error) {
                this.setState({errorMessage: error});
                return;
            }

            numberOfCredits = parseFloat((numberOfCredits - player.credits).toFixed(1));
            selectedPlayers[player.id] = player;
        } else {
            numberOfCredits = parseFloat((numberOfCredits + player.credits).toFixed(1));
            if (captain === player.id) captain = '';
            if (viceCaptain === player.id) viceCaptain = '';
            delete selectedPlayers[player.id];
        }
        this.setState({selectedPlayers, numberOfCredits, viceCaptain, captain});
    };

    handleValidation = (player) => {
        let {selectedPlayers, maxTeamSize, maxPlayersPerSquad, numberOfCredits} = this.state;
        if (maxTeamSize === Object.keys(selectedPlayers).length)
            return 'Max players selected. Proceed to select Captain';
        if (numberOfCredits < player.credits) return 'Not enough Credits';

        let playersInSquad = Object.values(selectedPlayers).filter((pl) => pl.squadId === player.squadId);
        if (playersInSquad.length >= maxPlayersPerSquad) return 'Max players from Squad selected';

        return false;
    };

    handleSubmit = (e) => {
        !!e && e.preventDefault();
        let {login} = this.props;
        let {selectedPlayers, maxTeamSize, captain, viceCaptain, expertName, displayTitle, ranking} = this.state;
        let {matchId, id} = this.props.createExpertTeam;
        let selectedLength = Object.keys(selectedPlayers).length;
        if (maxTeamSize !== selectedLength) {
            let text =
                maxTeamSize < selectedLength
                    ? `You have selected ${selectedLength} Players and the limit is ${maxTeamSize}`
                    : 'Please Select Players';
            this.setState({errorMessage: text});
            return;
        }

        let data = {
            playerIds: Object.keys(selectedPlayers),
            rank: ranking ? parseInt(ranking) : '',
            captain,
            viceCaptain,
            expertName,
            matchId,
        };

        if (id) {
            data.recommendedTeamId = id;
            data.displayTitle = displayTitle;
            this.props.updateRecommendedTeam(data, login.userDetails.data.accessToken);
        } else {
            this.props.createRecommendedTeam(data, login.userDetails.data.accessToken);
        }
    };

    render() {
        let {
            handleClose,
            open,
            createExpertTeam: {isNewRoleActivated, id},
        } = this.props;
        let {
            expertName,
            displayTitle,
            ranking,
            findSquad,
            squads,
            maxPlayersPerSquad,
            maxTeamSize,
            numberOfCredits,
            selectedPlayers,
            errorMessage,
            captain,
            viceCaptain,
        } = this.state;

        let captainOptions = Object.values(selectedPlayers)
            .filter((c) => c.id !== viceCaptain)
            .map((c) => ({
                value: c.id,
                text: c.pubgName,
            }));
        let videCaptainOptions = Object.values(selectedPlayers)
            .filter((v) => v.id !== captain)
            .map((v) => ({
                value: v.id,
                text: v.pubgName,
            }));
        let squadsOptions = Object.values(squads).map((s) => ({title: s.squadName, value: s.id}));

        return (
            <Dialog
                open={open}
                onClose={handleClose}
                scroll="paper"
                maxWidth="sm"
                className="create-expert-modal"
                fullWidth
                disableEscapeKeyDown
                disableBackdropClick
            >
                <Snackbar
                    open={!!errorMessage}
                    message={errorMessage}
                    onClose={() => this.setState({errorMessage: ''})}
                />
                <DialogTitle>
                    Create/Edit Expert Team
                    <br />
                    <p className="text-yellow heading-desc">
                        Total Credits: <b>{numberOfCredits}</b>, &nbsp; Max Players Per Squad:{' '}
                        <b>{maxPlayersPerSquad}</b>, &nbsp; Max Team Size: <b>{maxTeamSize}</b>
                    </p>
                </DialogTitle>
                <DialogContent dividers={true}>
                    <form onSubmit={this.handleSubmit}>
                        <div className="card-grid-container">
                            <Input
                                label="Expert Team Name"
                                type="text"
                                name="expertName"
                                required={!id}
                                value={expertName || ''}
                                placeholder="Enter Expert Team Name"
                                onChange={(value) => this.handleOnChange('expertName', value)}
                            />

                            {!!id && (
                                <Input
                                    label="Expert Display Title"
                                    type="text"
                                    name="displayTitle"
                                    value={displayTitle || ''}
                                    placeholder="Enter Display Title"
                                    onChange={(value) => this.handleOnChange('displayTitle', value)}
                                />
                            )}

                            <Input
                                label="Expert Ranking"
                                type="number"
                                name="ranking"
                                value={ranking || ''}
                                placeholder="Enter Ranking"
                                onChange={(value) => this.handleOnChange('ranking', value)}
                            />

                            {maxTeamSize === Object.keys(selectedPlayers).length && (
                                <DropDown
                                    menuItems={captainOptions}
                                    label="Select Captain"
                                    value={captain || ''}
                                    placeholder="Select Captain"
                                    required
                                    onChange={(val) => this.handleOnChange('captain', val)}
                                />
                            )}

                            {maxTeamSize === Object.keys(selectedPlayers).length && !!isNewRoleActivated && (
                                <DropDown
                                    menuItems={videCaptainOptions}
                                    label="Select Vice Captain"
                                    value={viceCaptain || ''}
                                    placeholder="Select Vice Captain"
                                    required
                                    onChange={(val) => this.handleOnChange('viceCaptain', val)}
                                />
                            )}

                            <AutoCompleteField
                                label="Filter Squad"
                                options={squadsOptions}
                                value={findSquad || ''}
                                onChange={(val) => this.handleOnChange('findSquad', val)}
                            />
                        </div>

                        <ul className="list">
                            {(squads || [])
                                .filter((s) => (findSquad.value ? s.id === findSquad.value : true))
                                .map((squad) => (
                                    <li key={squad.id}>
                                        <h4>{squad.squadName}</h4>
                                        <ul className="list">
                                            {squad.players
                                                .filter((p) => p.status === 'ACTIVE')
                                                .map((player) => (
                                                    <li key={player.id} className="list-item">
                                                        {player.pubgName} &nbsp; ({player.credits} Cr.)
                                                        <Checkbox
                                                            checked={!!selectedPlayers[player.id]}
                                                            onChange={() =>
                                                                this.handleSelectPlayer({
                                                                    ...player,
                                                                    squadId: squad.id,
                                                                })
                                                            }
                                                        />
                                                    </li>
                                                ))}
                                        </ul>
                                    </li>
                                ))}
                        </ul>
                        <Button className="d-none" buttonText="Submit" type="submit" buttonRef={this.submitRef} />
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button buttonText="Cancel" onClick={handleClose} />
                    <Button buttonText="Submit" onClick={() => this.submitRef.current.click()} />
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        recommendedTeams: state.recommendedTeams,
    };
};

export default connect(mapStateToProps, {createRecommendedTeam, updateRecommendedTeam})(CreateEditExpertTeamModal);
