import React from 'react';
import '../../../Utilities/style.css';
import '../Login/login.css';
import {connect} from 'react-redux';
import DetailLayout from '../../Layouts/DetailLayout/DetailLayout';
import Card from '../../../Components/Card/Card';
import Input from '../../../Components/Input/Input';
import {sendNotification} from './AxioCalls';
import {TOURL} from '../../../Utilities/Constants';
import axios from 'axios';
import ImageInput from '../../../Components/ImageInput/ImageInput';
import Snackbar from '../../../Components/Snackbar/Snackbar';
import LoadminModal from '../../../Components/LoadingModal/LoadingModal';
import DropDown from '../../../Components/Dropdown/Dropdown';
import AutoCompleteField from '../../../Components/AutoCompleteField/AutoCompleteField';
import {getMatchList} from '../Matches/AxioCalls';
import {getTOGameList} from '../TOGame/AxioCalls';
import {getTOMatchList} from '../TOPublishedMatch/AxioCalls';
import {getContestList} from '../Contests/AxioCalls';
import {debounce} from '../../../Utilities/HelperFunctions';

class AddNotification extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: false,
            title: '',
            body: '',
            url: '',
            notificationImage: null,
            s3Url: null,
            uploadImage: null,
            snackbarOpen: false,
            loading: false,
            errorMessage: '',
            product: [
                {
                    text: 'Tournament',
                    value: 'tournament',
                },
                {
                    text: 'Fantasy',
                    value: 'fantasy',
                },
            ],
            userBaseFT: [
                {
                    text: 'All Users',
                    value: 'allUsers',
                },
                {
                    text: 'Inactive for 3 or more Months',
                    value: 'inactive',
                },
                {
                    text: 'Won Atleast Once',
                    value: 'wonAtleastOnce',
                },
                {
                    text: 'Paying Users',
                    value: 'payingUsers',
                },
                {
                    text: 'Active Users (last visit is more than a month)',
                    value: 'active',
                },
                {
                    text: 'Users Played Atleast 7 Matches(high Repeat Users)',
                    value: 'highRepeatUsers',
                },
                {
                    text: 'Send Notification to participants of a match(MatchID Compulsory)',
                    value: 'MatchParticipants',
                },
            ],
            userBaseTO: [
                {
                    text: 'All Users',
                    value: 'allUsers',
                },
                {
                    text: 'Inactive for 3 or more Months',
                    value: 'inactive',
                },
                {
                    text: 'Paying Users',
                    value: 'payingUsers',
                },
                {
                    text: 'Send Notification to participants of a match(MatchID Compulsory)',
                    value: 'MatchParticipants',
                },
            ],
            screenList: [
                {
                    text: 'All Matches List',
                    value: 'matches',
                },
                {
                    text: 'Match Detail',
                    value: 'matchDetail',
                },
                {
                    text: 'Contest Detail',
                    value: 'contestDetail',
                },
            ],
            screenListTO: [
                {
                    text: 'All Matches List',
                    value: 'matches',
                },
                {
                    text: 'Match Detail',
                    value: 'matchDetail',
                },
            ],
            selectedUserBase: '',
            selectedProduct: '',
            selectedGame: '',
            selectedScreen: '',
            selectedMatch: '',
            selectedContest: '',
            toGameList: [],
            matchList: [],
            matchTOList: [],
            contestList: [],
            titleError: false,
            messageError: false,
            titleErrorMessage: '',
            bodyErrorMessage: '',
            screenError: false,
        };
        this.onSendNotification = this.onSendNotification.bind(this);
    }

    /**
     * API call to send notification
     */
    onSendNotification() {
        const {title, body, selectedUserBase, selectedProduct, selectedScreen, selectedGame} = this.state;

        if (title === '' || title === null) {
            this.setState({
                titleError: true,
                titleErrorMessage: 'Please enter a title for the notification!',
            });
            return false;
        } else if (body === '' || body === null) {
            this.setState({
                messageError: true,
                bodyErrorMessage: 'Please enter a message body for the notification!',
            });
            return false;
        } else if (selectedProduct === '' || selectedProduct === null) {
            this.setState({
                loading: false,
                snackbarOpen: true,
                errorMessage: 'Please select a Product!',
            });
            return false;
        } else if (selectedProduct === 'tournament') {
            if (selectedGame === '' || selectedGame === null) {
                this.setState({
                    loading: false,
                    snackbarOpen: true,
                    errorMessage: 'Please select a Game!',
                });
                return false;
            } else if (selectedUserBase === '' || selectedUserBase === null) {
                this.setState({
                    loading: false,
                    snackbarOpen: true,
                    errorMessage: 'Please select a userBase!',
                });
                return false;
            }
        } else if (selectedScreen === '' || selectedScreen === null) {
            this.setState({
                loading: false,
                snackbarOpen: true,
                errorMessage: 'Please select a screen!',
            });
            return false;
        }
        if (selectedScreen === 'matchDetail') {
            if (this.state.selectedMatch === undefined || this.state.selectedMatch === '') {
                this.setState({
                    loading: false,
                    snackbarOpen: true,
                    errorMessage: 'Please select a match!',
                });
                return false;
            }
        }
        if (selectedScreen === 'contestDetail') {
            if (this.state.selectedMatch === undefined || this.state.selectedMatch === '') {
                this.setState({
                    loading: false,
                    snackbarOpen: true,
                    errorMessage: 'Please select a match!',
                });
                return false;
            }

            if (this.state.selectedContest === undefined || this.state.selectedContest === '') {
                this.setState({
                    loading: false,
                    snackbarOpen: true,
                    errorMessage: 'Please select a contest!',
                });
                return false;
            }
        }

        this.setState({
            loading: true,
        });

        let data = {
            title: this.state.title,
            gameId: this.state.selectedGame.value,
            message: this.state.body,
            userBase: this.state.selectedUserBase,
            contestId: this.state.selectedContest.value,
            matchId: this.state.selectedMatch.value,
            source: this.state.selectedProduct,
            image: this.state.notificationImage,
            s3Url: this.state.s3Url,
        };

        this.props.sendNotification(
            data,
            this.props.login.userDetails.data.accessToken,
            this.props.client.selectedClient,
        );
    }

    componentDidMount() {
        const {login, history, client} = this.props;

        if (!login.userDetails.data) {
            history.push('/');
            this.props.logout();
        } else {
            let match = {page: 1, pageSize: 30, listType: 'ALL'};
            let toGame = {page: 1, pageSize: 10, searchString: null, sortOrder: null, listType: 'Limited'};
            this.props.getMatchList(match, login.userDetails.data.accessToken, client.selectedClient);
            this.props.getTOGameList(toGame, login.userDetails.data.accessToken, client.selectedClient);
        }
    }

    componentDidUpdate(prevProps) {
        let {notification, match, toPublishedMatch, contest, toGame} = this.props;
        if (notification.sendNotificationSuccess && !prevProps.notification.sendNotificationSuccess) {
            this.setState({
                loading: false,
                snackbarOpen: true,
                errorMessage: 'Notification Sent Successfully!',
            });
        }
        if (notification.sendNotificationFail && !prevProps.notification.sendNotificationFail) {
            this.setState({
                loading: false,
                snackbarOpen: true,
                errorMessage: notification.errorMessage,
            });
        }
        if (match.matchListRequest && !prevProps.match.matchListRequest) {
            this.setState({loading: true});
        }
        if (match.matchListSuccess && !prevProps.match.matchListSuccess) {
            let list = match.matchList.data.data;
            let matchList = [];
            list.map((match) => {
                matchList.push({title: match.id + ' ' + match.matchName, value: match.id});
            });
            this.setState({loading: false, matchList});
        } else if (match.matchListFail && !prevProps.match.matchListFail) {
            this.setState({
                loading: false,
                snackbarOpen: true,
                errorMessage: match.errorMessage,
            });
        }
        if (contest.contestListSuccess && !prevProps.contest.contestListSuccess) {
            let list = contest.contestList.data.data;
            let contestList = [];
            list.map((contest) => {
                contestList.push({title: contest.contestTypeName, value: contest.id});
            });
            this.setState({loading: false, contestList});
        } else if (contest.contestListFail && !prevProps.contest.contestListFail) {
            this.setState({
                errorMessage: contest.errorMessage,
                loading: false,
                snackbarOpen: true,
            });
        }
        if (toGame.TOGameListSuccess && !prevProps.toGame.TOGameListSuccess) {
            let list = toGame.TOGameList.data.data;
            let toGameList = [];
            list.map((toGame) => {
                toGameList.push({title: toGame.gameName, value: toGame.id});
            });
            this.setState({loading: false, toGameList});
        } else if (toGame.TOGameListFail && !prevProps.toGame.TOGameListFail) {
            this.setState({
                loading: false,
                snackbarOpen: true,
                errorMessage: toGame.TOGameList.errorMessage,
            });
        }
        if (toPublishedMatch.TOMatchListSuccess && !prevProps.toPublishedMatch.TOMatchListSuccess) {
            let list = toPublishedMatch.TOMatchList.data.data;
            let matchTOList = [];

            list.map((toPublishedMatch) => {
                matchTOList.push({
                    title: toPublishedMatch.id + '  ' + toPublishedMatch.name,
                    value: toPublishedMatch.id,
                });
            });

            this.setState({loading: false, matchTOList});
        } else if (toPublishedMatch.TOMatchListFail && !prevProps.toPublishedMatch.TOMatchListFail) {
            this.setState({
                loading: false,
                snackbarOpen: true,
                errorMessage: toPublishedMatch.TOMatchList.errorMessage,
            });
        }
    }

    /**
     * function to save entered data in state
     * @param {string} field field names from the form
     * @param {*} value value in the field
     */
    handleInputChange(field, value) {
        let {match, toGame, client} = this.props;
        this.setState({
            [field]: value,
            titleError: false,
            titleErrorMessage: '',
            messageError: false,
            bodyErrorMessage: '',
        });

        if (field === 'uploadImage') {
            //console.log('Image upload function is triggered');
            this.setState({loading: true});
            const data = new FormData();
            data.append('gameImage', value);
            axios
                .post(`${TOURL}/game/imageupload`, data)
                .then((res) => {
                    this.setState({loading: false});
                    if (res.data.data)
                        this.setState({notificationImage: res.data.data.image, s3Url: res.data.data.s3Url});
                })
                .catch((err) => {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    this.setState({loading: false, snackbarOpen: true, errorMessage: errorMsg});
                });
        }
        if (field === 'selectedProduct') {
            this.setState({
                selectedScreen: '',
                selectedMatch: '',
                selectedContest: '',
                selectedGame: '',
            });
        }
        if (field === 'selectedGame') {
            this.setState({
                selectedScreen: '',
                selectedMatch: '',
                selectedContest: '',
            });
        }
        if (field === 'selectedScreen') {
            this.setState({
                selectedMatch: '',
                selectedContest: '',
            });
        }

        if (value === 'fantasy' && field === 'selectedProduct') {
            let list = match.matchList.data.data;
            let matchList = [];

            list.map((match) => {
                matchList.push({
                    title: match.id + ' ' + match.matchName,
                    value: match.id,
                });
            });

            this.setState({
                loading: false,
                matchList,
            });
        } else if (value === 'tournament' && field === 'selectedProduct') {
            let gameList = toGame.TOGameList.data.data;
            let toGameList = [];
            gameList.map((toGame) => {
                toGameList.push({
                    title: toGame.gameName,
                    value: toGame.id,
                });
            });
            this.setState({
                loading: false,
                toGameList,
            });
        }
        if (field === 'selectedScreen' && this.state.selectedProduct === 'tournament') {
            let toPublishedMatch = {
                page: 1,
                pageSize: 30,
                sortOrder: 'startTime',
                listType: 'ALL',
                searchString: null,
                filters: {gameId: this.state.selectedGame.value},
            };
            this.props.getTOMatchList(toPublishedMatch, this.props.login.userDetails.data.accessToken);
        }

        if (this.state.selectedScreen === 'contestDetail' && field === 'selectedMatch') {
            this.setState({
                selectedContest: '',
            });

            let contest = {
                page: 1,
                pageSize: 20,
                searchString: value.value,
                listType: 'ALL',
            };

            this.props.getContestList(contest, this.props.login.userDetails.data.accessToken, client.selectedClient);
        }
    }

    /**
     * on input change handler for autosuggest
     * @param {string} field field name from the form
     * @param {string} event value in the field
     */

    onInputChange(field, event) {
        const {login, client} = this.props;
        if (
            event !== undefined &&
            event !== '' &&
            event !== null &&
            event.target.value !== undefined &&
            event.target.value !== '' &&
            event.target.value !== null
        ) {
            let string = event.target.value;

            if (string.length > 2) {
                let match = {page: 1, pageSize: 20, listType: 'ALL'};
                let toGame = {page: 1, pageSize: 10, searchString: null, sortOrder: null, listType: 'Limited'};
                let toPublishedMatch = {
                    page: 1,
                    pageSize: 30,
                    sortOrder: 'startTime',
                    listType: 'ALL',
                    searchString: null,
                    filters: {gameId: this.state.selectedGame.value},
                };
                if (string.length > 0) {
                    match.matchId = string;
                    toGame[field] = string;
                    toPublishedMatch[field] = string;
                }
                if (this.state.selectedProduct === 'fantasy') {
                    this.getMatchListDebounce({pageOption: match});
                } else if (this.state.selectedProduct === 'tournament') {
                    if (!this.state.selectedGame) {
                        this.props.getTOGameList(toGame, login.userDetails.data.accessToken, client.selectedClient);
                    } else if (this.state.selectedGame) {
                        this.props.getTOMatchList(toPublishedMatch, this.props.login.userDetails.data.accessToken);
                    }
                }
            }
        }
    }

    getMatchListDebounce = debounce(({pageOption}) => {
        const {login, client} = this.props;
        this.props.getMatchList(pageOption, login.userDetails.data.accessToken, client.selectedClient);
    });

    render() {
        const {
            title,
            body,
            snackbarOpen,
            errorMessage,
            loading,
            disabled,
            screenList,
            screenListTO,
            userBaseFT,
            notificationImage,
            userBaseTO,
            product,
            selectedProduct,
            selectedGame,
            selectedScreen,
            selectedUserBase,
            selectedMatch,
            selectedContest,
            toGameList,
            matchList,
            matchTOList,
            contestList,
            titleError,
            messageError,
            titleErrorMessage,
            bodyErrorMessage,
            screenError,
        } = this.state;
        const {history} = this.props;
        return (
            <DetailLayout
                location="notification"
                history={history}
                centerTitle="Send Notification"
                onSave={this.onSendNotification}
            >
                <LoadminModal open={loading} />

                <Snackbar
                    open={snackbarOpen}
                    message={errorMessage}
                    onClose={() => this.setState({snackbarOpen: false})}
                />
                <div className="detail-container margin-top-20">
                    <div className="full-grid"></div>

                    <div className="full-grid">
                        <Card>
                            <div>
                                <div className="card-fullgrid-container">
                                    {/* row 1 */}
                                    <div className="full-grid margin-top-20">
                                        <Input
                                            label="Notification Title"
                                            type="text"
                                            name="title"
                                            value={title}
                                            onChange={this.handleInputChange.bind(this, 'title')}
                                            placeholder="Enter Notification Title"
                                            error={titleError}
                                            disabled={disabled}
                                        />
                                        <div className="error-text">{titleErrorMessage}</div>
                                    </div>

                                    {/* row 2 */}
                                    <div className="full-grid  margin-top-20 notification-textarea">
                                        <Input
                                            label="Notification Message"
                                            multiline
                                            rows={4}
                                            type="text"
                                            name="body"
                                            value={body}
                                            error={messageError}
                                            onChange={this.handleInputChange.bind(this, 'body')}
                                            placeholder="Enter Notification Message"
                                            disabled={disabled}
                                        />
                                        <div className="error-text">{bodyErrorMessage}</div>
                                    </div>
                                    {/* row 3 */}
                                    <div className="grid-item  margin-top-20">
                                        <Input
                                            label="Notification Image"
                                            type="text"
                                            name="notificationImage"
                                            value={notificationImage}
                                            onChange={this.handleInputChange.bind(this, 'notificationImage')}
                                            placeholder="Enter Notification Image if Any"
                                            disabled={true}
                                        />
                                    </div>

                                    {notificationImage !== undefined &&
                                    notificationImage !== null &&
                                    notificationImage !== '' ? (
                                        <div className="grid-item  margin-top-20">
                                            <img src={notificationImage} style={{height: 100}} />
                                        </div>
                                    ) : (
                                        <div className="grid-item  margin-top-20"></div>
                                    )}

                                    <div className="grid-item  margin-top-20">
                                        <ImageInput
                                            label="Upload Your File"
                                            type="file"
                                            name="file"
                                            onChange={this.handleInputChange.bind(this, 'uploadImage')}
                                        />
                                    </div>

                                    <div className="grid-item  margin-top-20"></div>
                                    {/* row 4 */}
                                    <div className="grid-item  margin-top-20">
                                        <DropDown
                                            label="Select Product"
                                            type="text"
                                            menuItems={product}
                                            name="selectedProduct"
                                            value={selectedProduct}
                                            onChange={this.handleInputChange.bind(this, 'selectedProduct')}
                                            placeholder="Select Product"
                                            error={screenError}
                                            disabled={disabled}
                                        />
                                    </div>
                                    {selectedProduct === 'fantasy' ? (
                                        <div className="grid-item  margin-top-20">
                                            <DropDown
                                                label="Select UserBase"
                                                type="text"
                                                menuItems={userBaseFT}
                                                name="selectedUserBase"
                                                value={selectedUserBase}
                                                onChange={this.handleInputChange.bind(this, 'selectedUserBase')}
                                                placeholder="Select UserBase"
                                                error={screenError}
                                                disabled={disabled}
                                            />
                                        </div>
                                    ) : null}
                                    {selectedProduct === 'tournament' ? (
                                        <>
                                            <div className="grid-item  margin-top-20">
                                                <DropDown
                                                    label="Select UserBase"
                                                    type="text"
                                                    menuItems={userBaseTO}
                                                    name="selectedUserBase"
                                                    value={selectedUserBase}
                                                    onChange={this.handleInputChange.bind(this, 'selectedUserBase')}
                                                    placeholder="Select UserBase"
                                                    error={screenError}
                                                    disabled={disabled}
                                                />
                                            </div>
                                            <div className="grid-item  margin-top-20">
                                                <AutoCompleteField
                                                    label="Game"
                                                    onChange={this.handleInputChange.bind(this, 'selectedGame')}
                                                    options={toGameList}
                                                    value={selectedGame}
                                                    inputChange={this.onInputChange.bind(this, 'searchString')}
                                                />
                                            </div>
                                            {selectedGame ? (
                                                <div className="grid-item  margin-top-20">
                                                    <DropDown
                                                        label="Select Screen"
                                                        type="text"
                                                        menuItems={screenListTO}
                                                        name="selectedScreen"
                                                        value={selectedScreen}
                                                        onChange={this.handleInputChange.bind(this, 'selectedScreen')}
                                                        placeholder="Select Screen"
                                                        error={screenError}
                                                        disabled={disabled}
                                                    />
                                                </div>
                                            ) : null}
                                        </>
                                    ) : null}
                                    {selectedProduct === 'fantasy' ? (
                                        <div className="grid-item  margin-top-20">
                                            <DropDown
                                                label="Select Screen"
                                                type="text"
                                                menuItems={screenList}
                                                name="selectedScreen"
                                                value={selectedScreen}
                                                onChange={this.handleInputChange.bind(this, 'selectedScreen')}
                                                placeholder="Select Screen"
                                                error={screenError}
                                                disabled={disabled}
                                            />
                                        </div>
                                    ) : null}
                                    {selectedScreen === 'matchDetail' && selectedProduct === 'fantasy' ? (
                                        <div className="grid-item  margin-top-20">
                                            <AutoCompleteField
                                                label="Match Id"
                                                onChange={this.handleInputChange.bind(this, 'selectedMatch')}
                                                options={matchList}
                                                value={selectedMatch}
                                                inputChange={this.onInputChange.bind(this, 'searchString')}
                                            />
                                        </div>
                                    ) : null}
                                    {selectedScreen === 'matchDetail' && selectedProduct === 'tournament' ? (
                                        <div className="grid-item  margin-top-20">
                                            <AutoCompleteField
                                                label="TOMatch"
                                                onChange={this.handleInputChange.bind(this, 'selectedMatch')}
                                                options={matchTOList}
                                                value={selectedMatch}
                                                inputChange={this.onInputChange.bind(this, 'searchString')}
                                            />
                                        </div>
                                    ) : null}
                                    {selectedScreen === 'contestDetail' ? (
                                        <>
                                            <div className="grid-item  margin-top-20">
                                                <AutoCompleteField
                                                    label="Match Id"
                                                    onChange={this.handleInputChange.bind(this, 'selectedMatch')}
                                                    options={matchList}
                                                    value={selectedMatch}
                                                    inputChange={this.onInputChange.bind(this, 'searchString')}
                                                />
                                            </div>

                                            <div className="grid-item  margin-top-20">
                                                <AutoCompleteField
                                                    label="Contest"
                                                    onChange={this.handleInputChange.bind(this, 'selectedContest')}
                                                    options={contestList}
                                                    value={selectedContest}
                                                    inputChange={this.onInputChange.bind(this, 'searchString')}
                                                />
                                            </div>
                                        </>
                                    ) : null}
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className="grid-item"></div>
                </div>
            </DetailLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        notification: state.notification,
        match: state.match,
        toGame: state.toGame,
        toPublishedMatch: state.toPublishedMatch,
        contest: state.contest,
        client: state.client,
    };
};

export default connect(mapStateToProps, {
    sendNotification,
    getMatchList,
    getTOMatchList,
    getTOGameList,
    getContestList,
})(AddNotification);
