export const CONTEST_LIST = 'CONTEST_LIST';
export const CONTEST_LIST_SUCCESS = 'CONTEST_LIST_SUCCESS';
export const CONTEST_LIST_FAIL = 'CONTEST_LIST_FAIL';

export const ADD_CONTEST = 'ADD_CONTEST';
export const ADD_CONTEST_SUCCESS = 'ADD_CONTEST_SUCCESS';
export const ADD_CONTEST_FAIL = 'ADD_CONTEST_FAIL';

export const UPDATE_CONTEST = 'UPDATE_CONTEST';
export const UPDATE_CONTEST_SUCCESS = 'UPDATE_CONTEST_SUCCESS';
export const UPDATE_CONTEST_FAIL = 'UPDATE_CONTEST_FAIL';

export const DELETE_CONTEST = 'DELETE_CONTEST';
export const DELETE_CONTEST_SUCCESS = 'DELETE_CONTEST_SUCCESS';
export const DELETE_CONTEST_FAIL = 'DELETE_CONTEST_FAIL';

export const GET_CONTEST = 'GET_CONTEST';
export const GET_CONTEST_SUCCESS = 'GET_CONTEST_SUCCESS';
export const GET_CONTEST_FAIL = 'GET_CONTEST_FAIL';

export const EDIT_CONTEST = 'EDIT_CONTEST';

export const COPY_CONTEST_FROM_MATCH = 'COPY_CONTEST_FROM_MATCH';
export const COPY_CONTEST_FROM_MATCH_SUCCESS = 'COPY_CONTEST_FROM_MATCH_SUCCESS';
export const COPY_CONTEST_FROM_MATCH_FAIL = 'COPY_CONTEST_FROM_MATCH_FAIL';

export const UPLOAD_INFLUENCER_IMAGE = 'UPLOAD_INFLUENCER_IMAGE';
export const UPLOAD_INFLUENCER_IMAGE_SUCCESS = 'UPLOAD_INFLUENCER_IMAGE_SUCCESS';
export const UPLOAD_INFLUENCER_IMAGE_FAIL = 'UPLOAD_INFLUENCER_IMAGE_FAIL';

export const ADD_CONTEST_INFLUENCER = 'ADD_CONTEST_INFLUENCER';
export const ADD_CONTEST_INFLUENCER_SUCCESS = 'ADD_CONTEST_INFLUENCER_SUCCESS';
export const ADD_CONTEST_INFLUENCER_FAIL = 'ADD_CONTEST_INFLUENCER_FAIL';
