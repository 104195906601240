import React from 'react';
import moment from 'moment';
import CloudUpload from '@material-ui/icons/CloudUpload';

import '../../../Utilities/style.css';
import '../Login/login.css';

import TableLayout from '../../Layouts/TableLayout/TableLayout';
import Table from '../../../Components/Table/Table';

import {connect} from 'react-redux';
import {editdiscountCode} from './action';
import {getDiscountList} from './AxioCalls';
import {timeFormat} from '../../../Utilities/Helpers';
import {currentCode} from './action';
import {logout} from '../Login/LoginAction';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';
import Snackbar from '../../../Components/Snackbar/Snackbar';
import {
    debounce,
    downloadXLSXFromJson,
    next,
    prev,
    setFiltersSessionStorageItem,
    getFiltersSessionStorageItem,
} from '../../../Utilities/HelperFunctions';
import GrantDiscountCodeModal from './GrantDiscountCodeModal';
import FilterInput from '../../../Components/FilterInput';
import {ENTER, SCREEN_NAMES} from '../../../Utilities/Constants';
import DropDown from '../../../Components/Dropdown/Dropdown';
import Button from '../../../Components/Button/Button';

class DiscountCodeList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            list: [],
            pageData: {},
            loading: true,
            errorMessage: '',
            pageOptions: {
                page: 1,
                pageSize: 20,
                ...getFiltersSessionStorageItem(SCREEN_NAMES.DISCOUNT_CODE),
            },
            sortOptions: [
                {value: 'discountCode', text: 'Discount Code'},
                {value: 'createdAt', text: 'Created on'},
                {value: 'usageCount', text: '# of times used'},
                {value: 'totalDiscountedAmount', text: 'Total Discounts from Code'},
            ],
        };
    }

    componentWillMount() {
        const {login, history} = this.props;

        if (!login.userDetails.data) {
            history.push('/');
            this.props.logout();
        } else {
            this.getDiscountListDebounce({immediate: true});
        }
    }

    componentDidUpdate(prevProps) {
        const {discount} = this.props;

        if (discount.discountListRequest && !prevProps.discount.discountListRequest) {
            this.setState({loading: true});
        }

        if (discount.discountListSuccess && !prevProps.discount.discountListSuccess) {
            this.setState({
                loading: false,
                list: discount.discountList.data.data,
                pageData: discount.discountList.data.pageData,
            });
        } else if (discount.discountListFail && !prevProps.discount.discountListFail) {
            this.setState({loading: false, errorMessage: discount.errorMessage});
        }
        if (discount.isGrantingDiscountCodeSuccess && !prevProps.discount.isGrantingDiscountCodeSuccess) {
            this.setState({errorMessage: discount.discountCodeData.msg, grantDiscountCode: undefined});
            this.handleErrorUserIdsFile(discount.discountCodeData.data);
            this.getDiscountListDebounce({immediate: true});
        }
    }

    handleErrorUserIdsFile = (data) => {
        if (data && data.length) {
            downloadXLSXFromJson('discount-code', data);
        }
    };

    /**
     * reseting search and sort
     */
    onReset = () => {
        this.setState({pageOptions: {page: 1, pageSize: 20}}, () => {
            this.getDiscountListDebounce({immediate: true});
        });
    };

    /**
     * pagination and api call on next click
     */
    onNext = () => {
        const {pageData, pageOptions} = this.state;
        let options = next(pageOptions, pageData);
        if (options) {
            this.setState({pageOptions: options}, () => this.getDiscountListDebounce({immediate: true}));
        }
    };

    /**
     * pagination and api call on prev click
     */
    onPrev = () => {
        const {pageOptions} = this.state;
        let options = prev(pageOptions);
        if (options) {
            this.setState({pageOptions: options}, () => this.getDiscountListDebounce({immediate: true}));
        }
    };

    /**
     * navigate to discount code transaction of discount clicked based on header
     * @param {*} data entire list as data
     */
    onTransactionDetails(data) {
        const {history, discount} = this.props;
        //get the discount code that is clicked
        let code = discount.discountList.data.data;
        let index = data.index;
        let header = data.column.Header;
        this.props.currentCode(code[index]);
        if (header === 'Total Discounts from Code') {
            history.push('/discountCodeTransactions');
        }
    }

    /**
     * filter out the row with this discount code
     * @param {string} discountCode clicked discount code
     */
    onEditCode(discountCode) {
        let editCode = this.props.discount.discountList.data.data.filter((code) => code.discountCode === discountCode);
        this.props.editdiscountCode(editCode[0]);
        this.props.history.push('/editDiscountCode/' + editCode[0].id);
    }

    getDiscountListDebounce = debounce(() => {
        const {login, client} = this.props;
        setFiltersSessionStorageItem(SCREEN_NAMES.DISCOUNT_CODE, this.state.pageOptions);
        this.props.getDiscountList(this.state.pageOptions, login.userDetails.data.accessToken, client.selectedClient);
    });

    handleFilter = (field, value, debounce = true) => {
        const {pageOptions} = this.state;
        if (field === ENTER) {
            this.getDiscountListDebounce({immediate: true});
            return;
        }
        pageOptions[field] = value;
        this.setState({pageOptions: {...pageOptions, page: 1}}, () => {
            this.getDiscountListDebounce(debounce ? {} : {immediate: true});
        });
    };

    render() {
        const {history, login} = this.props;
        const {list, loading, errorMessage, sortOptions, pageData, grantDiscountCode} = this.state;
        const {discountCode, matchId, sortOrder, page} = this.state.pageOptions;
        const {discountWrite} = login?.permissions || {};

        let disableNext = page === pageData.lastPage;

        const data = list;
        const columns = [
            {
                Header: 'Discount Code',
                accessor: 'discountCode',
                Cell: (props) => (
                    <span onClick={this.onEditCode.bind(this, props.value)} className="table-clickable">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Discount Value',
                accessor: 'discountValue',
                Cell: (props) => <span>{props.value ? props.value : '-'}</span>,
            },
            {
                Header: 'Match Id',
                accessor: 'validOn',
                width: 180,
                Cell: (props) => <span>{props?.value?.matchAttributes?.ids?.join(' ') || '-'}</span>,
            },
            {
                Header: 'Applicability Type',
                accessor: 'applicabilityType',
                width: 120,
                Cell: (props) => <span>{props.value ? props.value : '-'}</span>,
            },
            {
                Header: 'Grant Discount Code',
                accessor: 'discountCode',
                show: discountWrite,
                Cell: (props) => (
                    <span>
                        {props.original.status === 'ACTIVE' && (
                            <CloudUpload
                                titleAccess="Grant Discount Code To Users"
                                className="clickable-icon mr-5"
                                fontSize="small"
                                onClick={() =>
                                    this.setState({
                                        grantDiscountCode: {
                                            code: props.value,
                                            applicabilityType: props.original.applicabilityType,
                                            defaultNoOfCouponsPerUser:
                                                props.original.singleGrantCount ||
                                                props.original.maxUsageCountPerUser ||
                                                1,
                                        },
                                    })
                                }
                            />
                        )}
                    </span>
                ),
            },
            {
                Header: '# of Users of Code',
                accessor: 'usageCount',
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Max Usage Count Per User',
                accessor: 'maxUsageCountPerUser',
                Cell: (props) => <span>{props.value ? props.value : '-'}</span>,
            },
            {
                Header: 'Discount Type',
                accessor: 'type',
                Cell: (props) => <span>{props.value ? props.value : '-'}</span>,
            },
            {
                Header: 'Title',
                accessor: 'title',
                width: 200,
                Cell: (props) => <span>{props.value ? props.value : '-'}</span>,
            },
            {
                Header: 'Description',
                accessor: 'description',
                width: 200,
                Cell: (props) => <span>{props.value ? props.value : '-'}</span>,
            },
            {
                Header: 'Min Amount',
                accessor: 'minCheckoutAmount',
                Cell: (props) => <span>{props.value ? props.value : 0}</span>,
            },
            {
                Header: 'Start Date',
                accessor: 'startDate',
                width: 150,
                Cell: (props) => <span>{props.value ? moment(props.value).format('dd MMM yyy HH:mm a') : '-'}</span>,
            },
            {
                Header: 'End Date',
                accessor: 'endDate',
                width: 150,
                Cell: (props) => <span>{props.value ? moment(props.value).format('dd MMM yyy HH:mm a') : '-'}</span>,
            },
            {
                Header: 'Target Audience',
                accessor: 'targetAudience',
                Cell: (props) => <span>{props.value ? props.value : '-'}</span>,
            },
            {
                Header: 'Source',
                accessor: 'source',
                Cell: (props) => <span>{props.value ? props.value : '-'}</span>,
            },
            {
                Header: 'Single Grant Count',
                accessor: 'singleGrantCount',
                Cell: (props) => <span>{props.value ? props.value : '-'}</span>,
            },
            {
                Header: 'Activation Window Type',
                accessor: 'activationWindowType',
                width: 150,
                Cell: (props) => <span>{props.value ? props.value : '-'}</span>,
            },
            {
                Header: 'Activation Window Value',
                accessor: 'activationWindowValue',
                Cell: (props) => <span>{props.value ? props.value : '-'}</span>,
            },
            {
                Header: 'Stop Gap Type',
                accessor: 'stopGapType',
                width: 150,
                Cell: (props) => <span>{props.value ? props.value : '-'}</span>,
            },
            {
                Header: 'Created on',
                accessor: 'createdAt', // accessor is the "key" in the data
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : timeFormat(props.value)}
                    </span>
                ),
            },
            {
                Header: 'Total Discounts from Code',
                accessor: 'totalDiscountedAmount', // accessor is the "key" in the data
                Cell: (props) => (
                    <span className="table-clickable" onClick={this.onTransactionDetails.bind(this, props)}>
                        {props.value === undefined || props.value === '' ? '-' : '₹ ' + props.value}
                    </span>
                ),
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Fill rate threshold min / max',
                accessor: 'validOn',
                Cell: (props) => (
                    <span>{props?.value?.contestAttributes?.targetAndCurrFillRateDiff?.join(' / ') || '-'}</span>
                ),
            },
        ];
        return (
            <TableLayout location="discount" history={history} hideSearchBar>
                {!!loading && <LoadingModal open={loading} />}
                {!!errorMessage && (
                    <Snackbar
                        open={!!errorMessage}
                        message={errorMessage}
                        onClose={() => this.setState({errorMessage: ''})}
                    />
                )}

                {!!grantDiscountCode && (
                    <GrantDiscountCodeModal
                        open={!!grantDiscountCode}
                        discountCode={grantDiscountCode}
                        handleClose={() => this.setState({grantDiscountCode: undefined})}
                    />
                )}

                <div className="filter-container my-10">
                    <FilterInput
                        placeholder="Discount Code"
                        value={discountCode || ''}
                        onChange={(value) => this.handleFilter('discountCode', value)}
                        onEnter={() => this.handleFilter(ENTER)}
                    />
                    <FilterInput
                        placeholder="Match Id"
                        value={matchId || ''}
                        onChange={(value) => this.handleFilter('matchId', value)}
                        onEnter={() => this.handleFilter(ENTER)}
                    />
                    <DropDown
                        label="Sort Order"
                        value={sortOrder || ''}
                        menuItems={sortOptions}
                        onChange={(value) => this.handleFilter('sortOrder', value, false)}
                    />
                    <Button buttonText="Reset" onClick={this.onReset} />
                </div>
                <div className="filter-container margin-bottom-10">
                    {!!discountWrite && (
                        <Button
                            buttonText="Add Disc Code"
                            onClick={() => this.props.history.push('/addDiscountCode')}
                        />
                    )}
                </div>
                <Table
                    data={data}
                    columns={columns}
                    page={page}
                    onPrevClick={this.onPrev}
                    onNextClick={this.onNext}
                    disableNext={disableNext}
                ></Table>
            </TableLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        discount: state.discount,
        client: state.client,
    };
};

export default connect(mapStateToProps, {
    getDiscountList,
    editdiscountCode,
    logout,
    currentCode,
})(DiscountCodeList);
