import React from 'react';
import '../../../Utilities/style.css';
import '../Login/login.css';
import TableLayout from '../../Layouts/TableLayout/TableLayout';
import Table from '../../../Components/Table/Table';
import {connect} from 'react-redux';
import Modal from '../../../Components/Modal/Modal';
import Card from '../../../Components/Card/Card';
import Close from '../../../Images/close_yellow.png';
import Button from '../../../Components/Button/Button';
import Dropdown from '../../../Components/Dropdown/Dropdown';
import Input from '../../../Components/Input/Input';
import {getUsersList, blockUser, unBlockUser, getUserKycImage, userKycManualAction} from './AxioCalls';
import Snackbar from '../../../Components/Snackbar/Snackbar';
import {currentUser} from './action';
import {logout} from '../Login/LoginAction';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';
import {timeFormat} from '../../../Utilities/Helpers';
import {
    debounce,
    getFiltersSessionStorageItem,
    next,
    prev,
    setFiltersSessionStorageItem,
} from '../../../Utilities/HelperFunctions';
import {KYC_STATUS, ENTER, NAVBAR_GREY, KYC_REJECTION_OPTIONS, SCREEN_NAMES} from '../../../Utilities/Constants';
import FilterInput from '../../../Components/FilterInput';
import DropDown from '../../../Components/Dropdown/Dropdown';
import moment from 'moment';
import TextModal from '../../../Components/TextModal';
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import UserBankAndUPIKyc from '../UserKyc/UserBankAndUPIKyc';

class UsersList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user: [],
            selectAll: false,
            list: [],
            adminpswd: '',
            errorMessage: '',
            loading: false,
            pageOptions: {
                page: 1,
                pageSize: 50,
                filters: null,
                ...getFiltersSessionStorageItem(SCREEN_NAMES.USERS),
            },
            pageData: {},
            sortOptions: [
                {value: 'id', text: 'User Id'},
                {value: 'userName', text: 'UserName'},
                {value: 'matchesCount', text: 'Matches'},
                {value: 'contestsCount', text: 'Contests'},
                {value: 'contestsWonCount', text: 'Won'},
                {text: 'Lost', value: 'contestsLostCount'},
                {value: 'totalWinnings', text: 'Winnings'},
                {value: 'totalWithdrawls', text: 'Withdrawals'},
                {value: 'fcCoins', text: 'FC Coins'},
                {value: 'userStatus', text: 'Status'},
            ],
            filterOptions: [
                {value: null, text: 'None'},
                {value: [{field: 'userKycStatus', values: ['SUBMITTED']}], text: 'kycSubmitted'},
                {value: [{field: 'userKycStatus', values: ['VERIFIED']}], text: 'kycVerified'},
                {value: [{field: 'userKycStatus', values: ['PROCESSING']}], text: 'kycProcessing'},
                {value: [{field: 'userKycStatus', values: ['REJECTED']}], text: 'kycRejected'},
                {value: [{field: 'bankAccStatus', values: ['SUBMITTED']}], text: 'bankSubmitted'},
                {value: [{field: 'bankAccStatus', values: ['VERIFIED']}], text: 'bankVerified'},
                {value: [{field: 'bankAccStatus', values: ['PROCESSING']}], text: 'bankProcessing'},
                {value: [{field: 'bankAccStatus', values: ['REJECTED']}], text: 'bankRejected'},
                {value: [{field: 'linkUpiStatus', values: ['VERIFIED']}], text: 'UpiVerified'},
                {value: [{field: 'linkUpiStatus', values: ['PROCESSING']}], text: 'UpiProcessing'},
                {value: [{field: 'linkUpiStatus', values: ['REJECTED']}], text: 'UpiRejected'},
            ],
            selectedUserId: '',
            selectedUserStatus: '',
            phoneNumber: '',
            userActionModalVisible: false,
            comment: '',
            videoLink: '',
        };
        this.dispBalance = this.dispBalance.bind(this);
    }
    /**
     * function to save entered data in state
     * @param {string} field field names from the form
     * @param {*} value value in the field
     */
    handleInputChange(field, value) {
        this.setState({[field]: value, errorMessage: ''});
    }

    /**
     * api call to block user
     */
    onBanUser() {
        let data = {};
        const {login, client} = this.props;

        const {selectedUserId, comment, videoLink} = this.state;
        data.appUserIds = [selectedUserId];
        data.banReason = comment;
        data.videoLink = videoLink;
        // console.log("selected user", data);
        this.props.blockUser(data, login.userDetails.data.accessToken, client.selectedClient);
        this.setState({comment: '', videoLink: ''});
    }

    onUnBanUser() {
        let data = {};
        const {login, client} = this.props;

        const {selectedUserId, comment} = this.state;
        data.appUserIds = [selectedUserId];
        data.unbanReason = comment;

        // console.log("selected user for unban ", data);
        this.props.unBlockUser(data, login.userDetails.data.accessToken, client.selectedClient);
        this.setState({comment: ''});
    }

    componentDidMount() {
        const {login, history} = this.props;

        if (!login.userDetails.data) {
            history.push('/');
            this.props.logout();
        } else {
            this.getUsersListDebounce({immediate: true});
        }
    }

    componentDidUpdate(prevProps) {
        const {user} = this.props;

        if (
            (user.userListRequest && !prevProps.user.userListRequest) ||
            (user.isGettingUserKycDocs && !prevProps.user.isGettingUserKycDocs) ||
            (user.userKycManualActionRequest && !prevProps.user.userKycManualActionRequest)
        ) {
            this.setState({loading: true});
        }

        if (user.userListSuccess && !prevProps.user.userListSuccess) {
            this.setState({
                list: user.userList.data.data,
                pageData: user.userList.data.pageData,
                loading: false,
            });
        } else if (user.userListFail && !prevProps.user.userListFail) {
            this.setState({loading: false, errorMessage: user.errorMessage});
        }

        if (user.blockUserRequest && !prevProps.user.blockUserRequest) {
            this.setState({loading: true});
        } else if (user.blockUserSuccess && !prevProps.user.blockUserSuccess) {
            this.setState({
                selectedUserId: '',
                loading: false,
                errorMessage: 'User banned!',
                userActionModalVisible: false,
            });
            this.getUsersListDebounce({immediate: true});
        } else if (user.blockUserFail && !prevProps.user.blockUserFail) {
            this.setState({loading: false, errorMessage: user.errorMessage});
        }

        if (user.unBlockUserRequest && !prevProps.user.unBlockUserRequest) {
            this.setState({loading: true});
        } else if (user.unBlockUserSuccess && !prevProps.user.unBlockUserSuccess) {
            this.setState({
                selectedUserId: '',
                loading: false,
                errorMessage: 'User un-banned!',
                userActionModalVisible: false,
            });
            this.getUsersListDebounce({immediate: true});
        } else if (user.unBlockUserFail && !prevProps.user.unBlockUserFail) {
            this.setState({loading: false, errorMessage: user.errorMessage});
        }

        if (user.isGettingUserKycDocsSuccess && !prevProps.user.isGettingUserKycDocsSuccess) {
            this.handleUserKeyDocs(user.userKycDocs.data);
        } else if (user.isGettingUserKycDocsFail && !prevProps.user.isGettingUserKycDocsFail) {
            this.setState({loading: false, errorMessage: user.errorMessage.errorReason});
        }

        if (
            (user.userKycManualActionSuccess && !prevProps.user.userKycManualActionSuccess) ||
            (user.isManuallyBankingKycSuccess && !prevProps.user.isManuallyBankingKycSuccess)
        ) {
            this.setState({
                userKycDocs: undefined,
                loading: false,
                errorMessage: 'Successfully Updated',
                kycActionStatus: '',
                bankUpiKycData: null,
            });
            this.getUsersListDebounce({immediate: true});
        } else if (user.userKycManualActionFailure && !prevProps.user.userKycManualActionFailure) {
            this.setState({loading: false, errorMessage: user.errorMessage.errorReason});
        }
    }

    /**
     * reset table data
     */
    onReset = () => {
        let options = {page: 1, pageSize: 50, filters: null};
        this.setState({pageOptions: options}, () => {
            this.getUsersListDebounce({immediate: true});
        });
    };

    handleFilter = (field, value, debounce = true) => {
        const {pageOptions} = this.state;
        if (field === ENTER) {
            this.getUsersListDebounce({immediate: true});
            return;
        }
        pageOptions[field] = value;
        this.setState({pageOptions: {...pageOptions, page: 1}}, () => {
            this.getUsersListDebounce(debounce ? {} : {immediate: true});
        });
    };

    getUsersListDebounce = debounce(() => {
        const {login, client} = this.props;
        setFiltersSessionStorageItem(SCREEN_NAMES.USERS, this.state.pageOptions);
        this.props.getUsersList(this.state.pageOptions, login.userDetails.data.accessToken, client.selectedClient);
    });

    /**
     * pagination and api call on next click
     */
    onNext() {
        const {pageData, pageOptions} = this.state;
        let options = next(pageOptions, pageData);
        if (options) {
            this.setState({pageOptions: options}, () => this.getUsersListDebounce({immediate: true}));
        }
    }

    /**
     * pagination and api call on  prev click
     */
    onPrev() {
        const {pageOptions} = this.state;
        let options = prev(pageOptions);
        if (options) {
            this.setState({pageOptions: options}, () => this.getUsersListDebounce({immediate: true}));
        }
    }

    /**
     * calculate and display balance
     * @param {*} data row details as data
     * @param {*} cash cashbalance of the user
     */
    dispBalance(data, cash) {
        let users = this.state.list;
        let index = data.index;

        if (!users || index >= users.length) {
            return;
        }

        let winnings = users[index].totalWinnings;
        return (winnings + cash)?.toFixed(2);
    }

    /**
     * navigate to user details page based on header
     * @param {*} data row data of selected user
     */
    selectedUserData(data) {
        let users = this.props.user.userList.data.data;
        const {history} = this.props;
        let index = data.index;
        let header = data.column.Header;
        this.props.currentUser(users[index]);
        if (header === 'FC Coins') {
            history.push('/userFcCoins');
        } else if (header === 'Winnings') {
            history.push('/userWinnings');
        } else if (header === 'Total Entry Fee') {
            history.push('/userEntryFees');
        } else if (header === 'Matches') {
            history.push('/userMatchDetails');
        } else if (header === 'Contests') {
            history.push('/userContestDetails');
        } else if (header === 'Won') {
            history.push('/userContestWon');
        } else if (header === 'Lost') {
            history.push('/userContestLost');
        } else if (header === 'Withdrawals') {
            history.push('/userWidthdrawals');
        } else if (header === 'Cash Balance') {
            history.push('/userBalance');
        }
    }

    handleAfterComment = (value) => {
        const {kycActionStatus, selectDoc, userKycDocs} = this.state;
        const token = this.props.login.userDetails.data.accessToken;
        const selectedClient = this.props.client.selectedClient;
        if (!userKycDocs.dateOfBirth && kycActionStatus === KYC_STATUS.VERIFIED) {
            this.setState({errorMessage: 'Please Select Date Of Birth First'});
            return;
        }
        if (!userKycDocs.fullName && kycActionStatus === KYC_STATUS.VERIFIED) {
            this.setState({errorMessage: 'Please Enter Full Name First'});
            return;
        }
        const data = {
            comment: value || '',
            finalStatus: kycActionStatus || KYC_STATUS.REPROCESS,
            docId: selectDoc.docId,
            docType: selectDoc.docType,
            userId: userKycDocs.userId,
            dateOfBirth: userKycDocs.dateOfBirth ? moment(userKycDocs.dateOfBirth).valueOf() : '',
            fullName: userKycDocs.fullName ? userKycDocs.fullName : '',
        };
        this.props.userKycManualAction(data, token, selectedClient);
    };

    onKycClick(user) {
        const token = this.props.login.userDetails.data.accessToken;
        const selectedClient = this.props.client.selectedClient;
        const data = {userId: user.user};
        this.props.getUserKycImage(data, token, selectedClient);
    }

    handleUserKeyDocs = (userKycDocs) => {
        let selectDoc = '',
            docOptions = [];
        if (userKycDocs.documentList && userKycDocs.documentList.length) {
            selectDoc = userKycDocs.documentList[0];
            docOptions = userKycDocs.documentList.map((doc) => ({value: doc, text: `${doc.docType}-${doc.docId}`}));
        }
        this.setState({
            userKycDocs: {
                ...userKycDocs,
                dateOfBirth: userKycDocs.dateOfBirth ? new Date(userKycDocs.dateOfBirth) : '',
            },
            selectDoc,
            docOptions,
            loading: false,
        });
    };

    handleOpenBankingKyc = () => {
        const {upiObject = [], bankObject = [], userId, fullName} = this.state.userKycDocs;
        this.setState({bankUpiKycData: {upiObject, bankObject, userId, fullName}});
    };

    onUserIdClick(userId, data) {
        this.setState({
            userActionModalVisible: true,
            selectedUserId: userId,
            selectedUserStatus: data.original.userStatus,
            phoneNumber: data.original.phoneNumber,
        });
    }

    UserActionModal = () => {
        const { selectedUserId, selectedUserStatus, phoneNumber, comment, videoLink } = this.state;
        const { userUserModalPhoneNumber, userUserModalVideoLink } = this.props.login.permissions || {}
        return (
            <Modal open={this.state.userActionModalVisible}>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <div style={{flex: 0.5}} />
                    <div style={Styles.filterModalRoot}>
                        {/* headers */}
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <div style={{flex: 0.9}} />
                            <div>{'User Actions'}</div>
                            <div style={{flex: 1}} />
                            <div
                                onClick={() => {
                                    this.setState({userActionModalVisible: false, comment: '', videoLink: ''});
                                }}
                            >
                                <img src={Close} alt="" className="cursor" />
                            </div>
                        </div>

                        {/* start, end time */}
                        <div style={{display: 'flex', flexDirection: 'row', paddingRight: 40}}>
                            <div style={{width: 40}} />
                            <div style={{flex: 1}}>
                                <div>{'Ban/Unban User ' + selectedUserId}</div>
                            </div>
                            <div style={{width: 40}} />
                            {!!userUserModalPhoneNumber && <div>{'Phone Number ' + phoneNumber}</div>}
                        </div>
                        <div style={{paddingLeft: 20, paddingRight: 20, marginTop: 16}}>
                            <Input
                                label="Comments"
                                type="text"
                                name="comment"
                                value={comment}
                                onChange={this.handleInputChange.bind(this, 'comment')}
                                placeholder="Reason"
                            />
                            {selectedUserStatus !== 'BANNED' && !!userUserModalVideoLink && (
                                <Input
                                    label="Video link"
                                    type="text"
                                    name="videoLink"
                                    value={videoLink}
                                    onChange={this.handleInputChange.bind(this, 'videoLink')}
                                    placeholder="Enter video link"
                                />
                            )}
                            <div style={{paddingTop: 16}}>
                                {selectedUserStatus === 'BANNED' ? (
                                    <Button buttonText="UnBan User" onClick={this.onUnBanUser.bind(this)} />
                                ) : (
                                    <Button buttonText="Ban User" onClick={this.onBanUser.bind(this)} />
                                )}
                            </div>
                        </div>
                    </div>
                    <div style={{flex: 0.5}} />
                </div>
            </Modal>
        );
    };

    render() {
        const {history, user, login} = this.props;
        const {
            sortOptions,
            loading,
            pageData,
            kycActionStatus,
            list,
            errorMessage,
            filterOptions,
            pageOptions,
            selectDoc,
            docOptions,
            userKycDocs,
            bankUpiKycData,
        } = this.state;
        const {userId, phoneNumber, sortOrder, filters, page} = this.state.pageOptions;
        const {userWrite, userKycReadOnly, userKycWrite, userWithdrawal, userCashBalance, userTotalBalance, userFcCoin, userKyc, userRefCode, userVideoLink,userUserModalPhoneNumber} = login?.permissions || {};

        const data = list;
        let disableNext = page === pageData.lastPage;

        const columns = [
            {
                Header: 'User ID',
                accessor: 'user',
                width: 200,
                Cell: (props) =>
                    userWrite ? (
                        <span className="table-clickable" onClick={this.onUserIdClick.bind(this, props.value, props)}>
                            {props.value || '-'}
                        </span>
                    ) : (
                        <span>{props.value || '-'}</span>
                    ),
            },
            {
                Header: 'Username',
                accessor: 'userName',
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            } /*
            {
                Header: 'Phone Number',
                accessor: 'phoneNumber',

                Cell: props => <span className="number">{props.value === undefined || props.value === "" ? "-" : props.value}</span>
            },*/,
            {
                Header: 'Last Login',
                accessor: 'lastLogin',
                Cell: (props) => <span className="number date-space">{timeFormat(props.value) || '-'}</span>,
            },
            {
                Header: 'Last  Match',
                accessor: 'lastMatchStartTime',
                Cell: (props) => <span className="number date-space">{timeFormat(props.value) || '-'}</span>,
            },
            {
                Header: 'Matches',
                accessor: 'matchesCount',
                Cell: (props) => (
                    <span className="table-clickable" onClick={this.selectedUserData.bind(this, props)}>
                        view
                    </span>
                ),
            },
            {
                Header: 'Contests',
                accessor: 'contestsCount',
                Cell: (props) => (
                    <span className="table-clickable" onClick={this.selectedUserData.bind(this, props)}>
                         view
                    </span>
                ),
            },
            {
                Header: 'Won',
                accessor: 'contestsWonCount',
                Cell: (props) => (
                    <span className="table-clickable" onClick={this.selectedUserData.bind(this, props)}>
                        view
                    </span>
                ),
            },
            {
                Header: 'Lost',
                accessor: 'contestsLostCount',
                Cell: (props) => (
                    <span className="table-clickable" onClick={this.selectedUserData.bind(this, props)}>
                        view
                    </span>
                ),
            },
            {
                Header: 'Total Entry Fee',
                accessor: 'totalEntryFeePaid',
                Cell: (props) => (
                    <span className="table-clickable" onClick={this.selectedUserData.bind(this, props)}>
                        view
                    </span>
                ),
            },
            {
                Header: 'Winnings',
                accessor: 'totalWinnings',
                Cell: (props) => (
                    <span className="table-clickable" onClick={this.selectedUserData.bind(this, props)}>
                        view
                    </span>
                ),
            },
            {
                Header: 'Withdrawals',
                accessor: 'totalWithdrawls',
                show: !!userWithdrawal,
                Cell: (props) => (
                    <span className="table-clickable" onClick={this.selectedUserData.bind(this, props)}>
                        view
                    </span>
                ),
            },
             {
                Header: 'Cash Balance',
                accessor: 'cashBalance',
                show: !!userCashBalance,
                Cell: (props) => (
                    <span className="table-clickable" onClick={this.selectedUserData.bind(this, props)}>
                        view
                    </span>
                ),
            },
             {
                Header: 'Total Balance',
                accessor: 'cashBalance',
                show: !!userTotalBalance,
                Cell: (props) => (
                    <span>{props.value === undefined ? '-' : '₹ ' + this.dispBalance(props, props.value)}</span>
                ),
            },

             {
                Header: 'FC Coins',
                accessor: 'fcCoins',
                show: !!userFcCoin,
                Cell: (props) => (
                    <span className="table-clickable" onClick={this.selectedUserData.bind(this, props)}>
                        view
                    </span>
                ),
            },
            {
                Header: 'KYC',
                accessor: 'kycDetails',
                show: !!userKyc,
                Cell: (props) => (
                    <span
                        className="table-clickable"
                        onClick={props?.value?.id ? this.onKycClick.bind(this, props.original) : null}
                    >
                        {props?.value?.id ? 'Documents' : ''}
                    </span>
                ),
            },
            {
                Header: 'Used Ref Code',
                accessor: 'referralCode',
                show: !!userRefCode,
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Status',
                accessor: 'userStatus',
                Cell: (props) => <span className="number">{props.value ? props.value : '-'}</span>,
            },
            {
                Header: 'Ban Reason',
                accessor: 'banInfo',
                Cell: (props) => (
                    <span className="number">{props.value && props.value.reason ? props.value.reason : '-'}</span>
                ),
            },
            {
                Header: 'Video Link',
                accessor: 'banInfo',
                show: !!userVideoLink,
                Cell: (props) => (
                    <a href={props.value && props.value.videoLink ? props.value.videoLink : ''} className="number">
                        {props.value && props.value.videoLink ? props.value.videoLink : '-'}
                    </a>
                ),
            },
            {
                Header: 'Unban Reason',
                accessor: 'banInfo',
                Cell: (props) => (
                    <span className="number">
                        {props.value && props.value.unbanReason ? props.value.unbanReason : '-'}
                    </span>
                ),
            },
        ];

        return (
            <TableLayout hideSearchBar location="Users" history={history}>
                <LoadingModal open={loading} />
                <Snackbar
                    open={!!errorMessage}
                    message={errorMessage}
                    onClose={() => this.setState({errorMessage: ''})}
                />
                {!!kycActionStatus && (
                    <TextModal
                        open={!!kycActionStatus}
                        label="Enter Comment Here"
                        handleClose={() => this.setState({kycActionStatus: ''})}
                        heading={kycActionStatus === KYC_STATUS.VERIFIED ? 'Comment for Verify' : 'Comment for Reject'}
                        buttonText={kycActionStatus === KYC_STATUS.VERIFIED ? 'Verify' : 'Reject'}
                        textInputType={kycActionStatus === KYC_STATUS.VERIFIED ? 'input' : 'select'}
                        selectOptions={KYC_REJECTION_OPTIONS}
                        handleOnSubmit={this.handleAfterComment}
                    />
                )}

                {!!bankUpiKycData && (
                    <UserBankAndUPIKyc
                        open={!!bankUpiKycData}
                        handleClose={() => this.setState({bankUpiKycData: null})}
                        kycData={bankUpiKycData}
                    />
                )}

                {!!userKycDocs && (
                    <Modal open={!!userKycDocs}>
                        <div className="container modal-top-margin">
                            <Card>
                                <div className="justify">
                                    <div className="modal-left-header">KYC Documents | Set Status</div>
                                    <div>
                                        <ul className="margin-0 modalUl justify">
                                            <li className="margin-right-16">
                                                <Button
                                                    buttonText="Bank / UPI Kyc"
                                                    onClick={this.handleOpenBankingKyc}
                                                />
                                            </li>
                                            <li className="margin-right-16">
                                                <div className="modal-left-header">
                                                    <span className="basic-headers">Status</span>
                                                    <br />
                                                    <b>{userKycDocs.status}</b>
                                                </div>
                                            </li>
                                            <li className="margin-right-40">
                                                {!!userKycWrite &&
                                                    userKycDocs.status === KYC_STATUS.SUBMITTED &&
                                                    !!selectDoc && (
                                                        <Button
                                                            buttonText="Process Again"
                                                            onClick={this.handleAfterComment}
                                                        />
                                                    )}

                                                {!!userKycWrite &&
                                                    userKycDocs.status === KYC_STATUS.PROCESSING &&
                                                    !!selectDoc && (
                                                        <>
                                                            <Button
                                                                className="mr-10"
                                                                buttonText="Verify"
                                                                onClick={() =>
                                                                    this.setState({
                                                                        kycActionStatus: KYC_STATUS.VERIFIED,
                                                                    })
                                                                }
                                                            />

                                                            <Button
                                                                buttonText="Reject"
                                                                onClick={() =>
                                                                    this.setState({
                                                                        kycActionStatus: KYC_STATUS.REJECTED,
                                                                    })
                                                                }
                                                            />
                                                        </>
                                                    )}
                                            </li>
                                            <li onClick={() => this.setState({userKycDocs: undefined})}>
                                                <img src={Close} alt="" className="cursor" />
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <hr />
                                <div className="kyc-grid-2 margin-top-20">
                                    <div className="card-grid-container align-content">
                                        <div>
                                            <div className="basic-headers">Date Of Birth</div>
                                            <div className="basiic-data">
                                                {user?.userKycDocs?.data?.dateOfBirth ? (
                                                    moment(userKycDocs.dateOfBirth).format('DD-MM-YYYY')
                                                ) : userKycDocs.status === KYC_STATUS.PROCESSING ? (
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <DatePicker
                                                            label="Select DOB"
                                                            format="dd MMM yyyy"
                                                            value={userKycDocs.dateOfBirth || null}
                                                            onChange={(value) =>
                                                                this.handleInputChange('userKycDocs', {
                                                                    ...userKycDocs,
                                                                    dateOfBirth: value,
                                                                })
                                                            }
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                ) : (
                                                    '-'
                                                )}
                                            </div>
                                        </div>
                                        <div>
                                            <div className="basic-headers">Full Name</div>
                                            <div className="basiic-data">
                                                {user?.userKycDocs?.data?.fullName ? (
                                                    userKycDocs.fullName
                                                ) : userKycDocs.status === KYC_STATUS.PROCESSING ? (
                                                    <Input
                                                        className="mb-15"
                                                        label="Full Name"
                                                        type="text"
                                                        name="fullName"
                                                        value={userKycDocs.fullName || ''}
                                                        placeholder="Enter Full Name"
                                                        onChange={(value) =>
                                                            this.handleInputChange('userKycDocs', {
                                                                ...userKycDocs,
                                                                fullName: value,
                                                            })
                                                        }
                                                    />
                                                ) : (
                                                    '-'
                                                )}
                                            </div>
                                        </div>
                                        <div className="margin-top-20">
                                            <div className="basic-headers">Extracted Name</div>
                                            <div className="basiic-data">
                                                {selectDoc.extractedName ? selectDoc.extractedName : '-'}
                                            </div>
                                        </div>
                                        <div className="margin-top-20">
                                            <div className="basic-headers">extracted Govt Pan Name</div>
                                            <div className="basiic-data">
                                                {selectDoc.extractedGovtPanName ? selectDoc.extractedGovtPanName : '-'}
                                            </div>
                                        </div>
                                        <div className="margin-top-20">
                                            <div className="basic-headers">Bank Account #</div>
                                            <div className="basiic-data">
                                                {userKycDocs.bankAccNo ? userKycDocs.bankAccNo : '-'}
                                            </div>
                                        </div>
                                        <div className="margin-top-20">
                                            <div className="basic-headers">Mobile Number</div>
                                            <div className="basiic-data">
                                                {userKycDocs.phoneNumber ? userKycDocs.phoneNumber : '-'}
                                            </div>
                                        </div>
                                        <div className="margin-top-20">
                                            <div className="basic-headers">IFSC code</div>
                                            <div className="basiic-data">
                                                {userKycDocs.bankIfscCode ? userKycDocs.bankIfscCode : '-'}
                                            </div>
                                        </div>
                                        <div className="margin-top-20">
                                            <div className="basic-headers">Upi Id - RegisteredName</div>
                                            <div className="basiic-data">
                                                {userKycDocs.upiIdList
                                                    ? userKycDocs.upiIdList.map((itr, index) => (
                                                          <p className="mb-5 mt-0" key={'kycupi' + index}>
                                                              {itr.upi + ' - ' + itr.upiName}
                                                          </p>
                                                      ))
                                                    : '-'}
                                            </div>
                                        </div>
                                        <div className="margin-top-20">
                                            <div className="basic-headers">Failure Reason</div>
                                            <div className="basiic-data">
                                                {userKycDocs.failureReason ? userKycDocs.failureReason : '-'}
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="margin-bottom-20">
                                            <Dropdown
                                                menuItems={docOptions || []}
                                                label="Select Doc"
                                                value={selectDoc || ''}
                                                placeholder="Select Doc"
                                                onChange={this.handleInputChange.bind(this, 'selectDoc')}
                                            />
                                        </div>
                                        <div className="panCardImg">
                                            {selectDoc.docImageUrl ? (
                                                <img className="doc-img" alt="doc" src={selectDoc.docImageUrl} />
                                            ) : null}
                                        </div>
                                    </div>
                                    <div />
                                </div>
                            </Card>
                        </div>
                    </Modal>
                )}
                <div className="filter-container my-10">
                    <FilterInput
                        placeholder="User Name"
                        value={pageOptions.userName || ''}
                        onChange={(value) => this.handleFilter('userName', value)}
                        onEnter={() => this.handleFilter(ENTER)}
                    />
                    <FilterInput
                        placeholder="User Id"
                        value={userId || ''}
                        onChange={(value) => this.handleFilter('userId', value)}
                        onEnter={() => this.handleFilter(ENTER)}
                    />
                    {!!userUserModalPhoneNumber && <FilterInput
                        placeholder="Phone Number"
                        value={phoneNumber || ''}
                        onChange={(value) => this.handleFilter('phoneNumber', value)}
                        onEnter={() => this.handleFilter(ENTER)}
                    />}
                    <DropDown
                        label="Sort By"
                        value={sortOrder || ''}
                        menuItems={sortOptions}
                        onChange={(value) => this.handleFilter('sortOrder', value, false)}
                    />
                    {/* <DropDown label="Filter" value={filters || ''} menuItems={filterOptions}
                        onChange={(val) => this.handleFilter("filters", val, false)} /> */}
                    <Button buttonText="Reset" onClick={this.onReset} />
                </div>
                <Table
                    data={data}
                    columns={columns}
                    pageSize={50}
                    page={page}
                    onPrevClick={this.onPrev.bind(this)}
                    onNextClick={this.onNext.bind(this)}
                    disableNext={disableNext}
                />
                <this.UserActionModal />
            </TableLayout>
        );
    }
}

const Styles = {
    filterModalRoot: {
        flex: 1,
        backgroundColor: NAVBAR_GREY,
        opacity: 0.9,
        marginLeft: 150,
        marginRight: 150,
        marginTop: 100,
        paddingTop: 20,
        paddingBottom: 20,
        borderRadius: 10,
    },
};

const mapStateToProps = (state) => {
    return {
        login: state.login,
        user: state.user,
        player: state.player,
        client: state.client,
    };
};

export default connect(mapStateToProps, {
    getUsersList,
    blockUser,
    currentUser,
    logout,
    unBlockUser,
    getUserKycImage,
    userKycManualAction,
})(UsersList);
