import React from 'react';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider, DateTimePicker} from '@material-ui/pickers';
import './DateTimePickerStyle.css';

const DateTimePickerComponent = (props) => {
    const {className, label, value, minDate, disabled, required} = props;
    return (
        <div className={`date-time-picker ${className}`}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                    label={label}
                    value={value || null}
                    minDate={minDate || null}
                    onChange={props.onChange}
                    disabled={disabled}
                />
            </MuiPickersUtilsProvider>
            <input className="date-time-picker-input" type="text" required={required} defaultValue={value || ''} />
        </div>
    );
};

DateTimePickerComponent.propTypes = {
    className: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.any,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    minDate: PropTypes.any,
};

DateTimePickerComponent.defaultProps = {
    className: '',
    onChange: () => {},
    value: '',
    label: '',
    disabled: false,
    required: false,
    minDate: '',
};

export default DateTimePickerComponent;
