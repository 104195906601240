import {
    TEAM_LIST,
    TEAM_LIST_SUCCESS,
    TEAM_LIST_FAIL,
    TEAM_DATA_LIST,
    TEAM_DATA_LIST_SUCCESS,
    TEAM_DATA_LIST_FAIL,
} from './Constants';

const initialState = {
    teamListRequest: false,
    teamListSuccess: false,
    teamListFail: false,
    teamList: [],
    teamDataListRequest: false,
    teamDataListSuccess: false,
    teamDataListFail: false,
    teamDataList: [],
    errorMessage: '',
};

const teamreducer = (state = initialState, action) => {
    switch (action.type) {
        case TEAM_LIST:
            return {
                ...state,
                teamListRequest: true,
                teamListSuccess: false,
                teamListFail: false,
                teamList: [],
                errorMessage: '',
            };

        case TEAM_LIST_SUCCESS:
            return {
                ...state,
                teamListRequest: false,
                teamListSuccess: true,
                teamListFail: false,
                teamList: action.payload.data,
                errorMessage: '',
            };

        case TEAM_LIST_FAIL:
            return {
                ...state,
                teamListRequest: false,
                teamListSuccess: false,
                teamListFail: true,
                errorMessage: action.payload,
            };

        // team data list

        case TEAM_DATA_LIST:
            return {
                ...state,
                teamDataListRequest: true,
                teamDataListSuccess: false,
                teamDataListFail: false,
                teamDataList: [],
                errorMessage: '',
            };

        case TEAM_DATA_LIST_SUCCESS:
            return {
                ...state,
                teamDataListRequest: false,
                teamDataListSuccess: true,
                teamDataListFail: false,
                teamDataList: action.payload.data,
                errorMessage: '',
            };

        case TEAM_DATA_LIST_FAIL:
            return {
                ...state,
                teamDataListRequest: false,
                teamDataListSuccess: false,
                teamDataListFail: true,
                errorMessage: action.payload,
            };

        default:
            return state;
    }
};

export default teamreducer;
