export const EVENT_LIST = 'EVENT_LIST';
export const EVENT_LIST_SUCCESS = 'EVENT_LIST_SUCCESS';
export const EVENT_LIST_FAIL = 'EVENT_LIST_FAIL';

export const RECORD_EVENT = 'RECORD_EVENT';
export const RECORD_EVENT_SUCCESS = 'RECORD_EVENT_SUCCESS';
export const RECORD_EVENT_FAIL = 'RECORD_EVENT_FAIL';

export const VERIFY_EVENT = 'VERIFY_EVENT';
export const VERIFY_EVENT_SUCCESS = 'VERIFY_EVENT_SUCCESS';
export const VERIFY_EVENT_FAIL = 'VERIFY_EVENT_FAIL';

export const RECORD_MATCH = 'RECORD_MATCH';
export const VERIFY_MATCH = 'VERIFY_MATCH';

export const COMPLETE_EVENT = 'COMPLETE_EVENT';
export const COMPLETE_EVENT_SUCCESS = 'COMPLETE_EVENT_SUCCESS';
export const COMPLETE_EVENT_FAIL = 'COMPLETE_EVENT_FAIL';

export const DELETE_EVENT = 'DELETE_EVENT';
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';
export const DELETE_EVENT_FAIL = 'DELETE_EVENT_FAIL';

export const UPDATE_EVENT = 'UPDATE_EVENT';
export const UPDATE_EVENT_SUCCESS = 'UPDATE_EVENT_SUCCESS';
export const UPDATE_EVENT_FAIL = 'UPDATE_EVENT_FAIL';

export const PLAYERS_MATCH = 'PLAYERS_MATCH';
export const PLAYERS_MATCH_SUCCESS = 'PLAYERS_MATCH_SUCCESS';
export const PLAYERS_MATCH_FAIL = 'PLAYERS_MATCH_FAIl';

export const COMPILE_EVENT = 'COMPILE_EVENT';
export const COMPILE_EVENT_SUCCESS = 'COMPILE_EVENT_SUCCESS';
export const COMPILE_EVENT_FAIL = 'COMPILE_EVENT_FAIL';

export const COMPLETE_MATCH_V2 = 'COMPLETE_MATCH_V2';
export const COMPLETE_MATCH_V2_SUCCESS = 'COMPLETE_MATCH_V2_SUCCESS';
export const COMPLETE_MATCH_V2_FAIL = 'COMPLETE_MATCH_V2_FAIL';

export const WINNING_DISBURSAL_DETAILS = 'WINNING_DISBURSAL_DETAILS';
export const WINNING_DISBURSAL_DETAILS_SUCCESS = 'WINNING_DISBURSAL_DETAILS_SUCCESS';
export const WINNING_DISBURSAL_DETAILS_FAIL = 'WINNING_DISBURSAL_DETAILS_FAIL';

export const COMPILE_MATCH_V2 = 'COMPILE_MATCH_V2';
export const COMPILE_MATCH_V2_SUCCESS = 'COMPILE_MATCH_V2_SUCCESS';
export const COMPILE_MATCH_V2_FAIL = 'COMPILE_MATCH_V2_FAIL';

export const PUBLISH_MATCH_SUMMARY = 'PUBLISH_MATCH_SUMMARY';
export const PUBLISH_MATCH_SUMMARY_SUCCESS = 'PUBLISH_MATCH_SUMMARY_SUCCESS';
export const PUBLISH_MATCH_SUMMARY_FAIL = 'PUBLISH_MATCH_SUMMARY_FAIL';

export const VERIFY_SWITCH_LEADERBOARD_POINTS = 'VERIFY_SWITCH_LEADERBOARD_POINTS';
export const VERIFY_SWITCH_LEADERBOARD_POINTS_SUCCESS = 'VERIFY_SWITCH_LEADERBOARD_POINTS_SUCCESS';
export const VERIFY_SWITCH_LEADERBOARD_POINTS_FAIL = 'VERIFY_SWITCH_LEADERBOARD_POINTS_FAIL';
