import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import CalendarToday from '@material-ui/icons/CalendarToday';
import {useSelector} from 'react-redux';

import Card from '../../../../Components/Card/Card';
import FileCopy from '@material-ui/icons/FileCopy';
import EmojiEvents from '@material-ui/icons/EmojiEvents';
import {copyText} from '../../../../Utilities/HelperFunctions';
import Snackbar from '../../../../Components/Snackbar/Snackbar';
import {GAME_UNIQUE_CODE} from '../../../../Utilities/Constants';
import ToCalendarSeriesTable from '../ToCalendarSeriesTable';
import ToCalendarMatchesTable from '../ToCalendarMatchesTable';

const ToCalendarCard = (props) => {
    const {tournament, dateRange} = props;
    const toGame = useSelector((state) => state.toGame);
    const [state, setState] = useState({});
    const {errorMsg, game, gameType} = state;

    useEffect(() => {
        const _game = toGame?.TOGameList?.data?.data?.filter((g) => g.id === tournament.gameId)?.[0] || {};
        handleOnStateChange({game: _game});
    }, [tournament?.id, toGame]);

    useEffect(() => {
        if (!!gameType) return handleOnStateChange({gameType: ''});
    }, [dateRange]);

    const handleOnStateChange = (stateObj = {}) => {
        setState((prevState) => ({...prevState, ...stateObj}));
    };

    const handleCopy = (e, text) => {
        !!e && e.stopPropagation();
        copyText(text, (msg) => handleOnStateChange({errorMsg: msg}));
    };

    const handleClickOnCard = () => {
        if (!!gameType) return handleOnStateChange({gameType: ''});
        const games5V5 = [
            GAME_UNIQUE_CODE.csgo,
            GAME_UNIQUE_CODE.val,
            GAME_UNIQUE_CODE.dota,
            GAME_UNIQUE_CODE.pokemonUnite,
            GAME_UNIQUE_CODE.codm,
            GAME_UNIQUE_CODE.codpc,
        ];
        if (games5V5.includes(game?.gameUniqueCode)) {
            handleOnStateChange({gameType: '5V5'});
        } else {
            handleOnStateChange({gameType: 'SQUAD'});
        }
    };

    return (
        <Card className="margin-bottom-20">
            {!!errorMsg && (
                <Snackbar open={!!errorMsg} message={errorMsg} onClose={() => handleOnStateChange({errorMsg: ''})} />
            )}

            <div className="card-grid-container">
                <div>
                    <h3 className="mt-0">
                        <span className="table-clickable text-yellow" onClick={handleClickOnCard}>
                            {tournament?.name}
                        </span>
                    </h3>
                    <div>
                        {tournament?.country?.name || '-'} | {tournament?.region?.name || '-'} | Tier:{' '}
                        {tournament?.tier}
                    </div>
                </div>
                <div className="align-right">
                    <div className="disp-flex justify-content-end mb-15">
                        <CalendarToday className="mr-10" fontSize="small" />
                        {!!tournament?.start &&
                            !!tournament?.end &&
                            moment(tournament?.start).format('Do MMM YYYY')} -{' '}
                        {moment(tournament?.end).format('Do MMM YYYY')}
                    </div>
                    <span>{tournament?.status}</span>
                </div>
            </div>
            <hr className="my-15" />
            <div className="grid-3-container text-center">
                <div className="inline-d-flex align-center-justify-center">
                    <span
                        className="inline-d-flex align-center-justify-center clickable-icon text-yellow"
                        onClick={(e) => handleCopy(e, tournament?.id)}
                    >
                        ID: {tournament?.id}
                        <FileCopy titleAccess="Copy" className="ml-10 MuiSvgIcon-colorPrimary" fontSize="small" />
                    </span>
                </div>
                <div className="inline-d-flex align-center-justify-center">
                    <EmojiEvents titleAccess="Copy" className="mr-10" /> {tournament?.prizePool?.currency}{' '}
                    {tournament?.prizePool?.value}
                </div>
                <div className="inline-d-flex align-center-justify-center">
                    <img className="mr-10" width="35px" alt="Game icon" src={game?.gameIconImage} />
                    {game?.gameName}
                </div>
            </div>

            {gameType === '5V5' && <ToCalendarSeriesTable tournamentId={tournament?.id} dateRange={dateRange} />}
            {gameType === 'SQUAD' && <ToCalendarMatchesTable tournamentId={tournament?.id} />}
        </Card>
    );
};

ToCalendarCard.propTypes = {
    tournament: PropTypes.object,
    dateRange: PropTypes.any,
};

ToCalendarCard.defaultProps = {
    tournament: {},
    dateRange: null,
};

export default ToCalendarCard;
