import React from 'react';
import {timeFormat} from '../../../Utilities/Helpers';

import '../../../Utilities/style.css';
import '../Login/login.css';
import TableLayout from '../../Layouts/TableLayout/TableLayout';
import Table from '../../../Components/Table/Table';

import {connect} from 'react-redux';
import {next, prev} from '../../../Utilities/HelperFunctions';

import {getTOMatchList, getTOOrganizerMatchList} from './AxioCalls';
import {editTOMatch} from './action';
import {logout} from '../Login/LoginAction';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';
import Snackbar from '../../../Components/Snackbar/Snackbar';
import FiltersModal from '../TOPublishedMatch/FiltersModal';
import Button from '../../../Components/Button/Button';
import moment from 'moment';

class TOCompletedMatchList extends React.Component {
    initialPageOptions = {
        page: 1,
        pageSize: 10,
        searchString: null,
        sortOrder: null,
        listType: 'COMPLETED',
    };

    constructor(props) {
        super(props);
        this.state = {
            TOMatch: [],
            selectAll: false,
            list: [],
            open: false,
            adminpswd: '',
            pageData: {},
            loading: false,
            pageOptions: Object.assign({}, this.initialPageOptions),
            sortOptions: [
                {
                    value: 'id',
                    text: 'Match Id',
                },
                {
                    value: 'name',
                    text: 'Match Name',
                },
                {
                    value: 'startTime',
                    text: 'Start Time',
                },
                {
                    value: 'status',
                    text: 'Status',
                },
            ],
            filterModalVisible: false,
            doResetFilters: false,
        };
    }

    componentWillMount() {
        const {login, history} = this.props;

        if (!login.userDetails.data) {
            history.push('/');
            this.props.logout();
        }
        // else if (login.userDetails.data.role === 'ORGANIZER') {
        //     history.push('/');
        // }
        else {
            const {pageOptions} = this.state;
            // console.log("Fetching the matches list");
            if (login.userDetails.data.role === 'ORGANIZER') {
                this.props.getTOOrganizerMatchList(pageOptions, login.userDetails.data.accessToken);
            } else {
                this.props.getTOMatchList(pageOptions, login.userDetails.data.accessToken);
            }
        }
    }

    componentDidUpdate(prevProps) {
        const {pageOptions} = this.state;
        const {login, toCompletedMatch} = this.props;

        if (toCompletedMatch.TOMatchListRequest && !prevProps.toCompletedMatch.TOMatchListRequest) {
            this.setState({
                loading: true,
            });
        } else if (toCompletedMatch.TOMatchListSuccess && !prevProps.toCompletedMatch.TOMatchListSuccess) {
            this.setState({
                list: toCompletedMatch.TOMatchList.data.data,
                loading: false,
                pageData: toCompletedMatch.TOMatchList.data.pageData,
            });
        } else if (toCompletedMatch.TOMatchListFail && !prevProps.toCompletedMatch.TOMatchListFail) {
            this.setState({
                errorMessage: toCompletedMatch.errorMessage,
                loading: false,
            });
        }
    }

    //store single match details selected for editing
    onEditTOMatch(id) {
        const {toCompletedMatch, history} = this.props;

        let TOMatches = toCompletedMatch.TOMatchList.data.data;
        const editTOMatch = TOMatches.filter((match) => match.id === id);
        this.props.editTOMatch(editTOMatch[0]);
        history.push('/editTOCompletedMatch/' + id);
    }

    //APi call for search and sort
    onSearchandSort(field, value) {
        const {pageOptions} = this.state;
        const {login} = this.props;
        let options = pageOptions;
        options[field] = value;
        this.setState({pageOptions: options});

        if (field === 'searchString' && value.length > 2) {
            if (login.userDetails.data.role === 'ORGANIZER') {
                this.props.getTOOrganizerMatchList(options, login.userDetails.data.accessToken);
            } else {
                this.props.getTOMatchList(options, login.userDetails.data.accessToken);
            }
        } else if (field === 'sortOrder') {
            console.log('Sort has been triggered!');
            if (login.userDetails.data.role === 'ORGANIZER') {
                this.props.getTOOrganizerMatchList(options, login.userDetails.data.accessToken);
            } else {
                this.props.getTOMatchList(options, login.userDetails.data.accessToken);
            }
        }
    }

    onApplyFilters(appliedFilters) {
        // const { pageOptions, filterStartTime, filterEndTime } = this.state;
        const {pageOptions} = this.state;
        const {filterStartTime, filterEndTime, gameId} = appliedFilters;
        const {login} = this.props;

        if (!filterStartTime && !filterEndTime && !gameId) {
            this.getMatchList(login, pageOptions);
            this.setState({filterModalVisible: false});
            return;
        }

        let filters = {
            filterStartTime: moment(filterStartTime).valueOf(),
            filterEndTime: moment(filterEndTime).valueOf(),
            gameId: gameId,
        };

        let options = pageOptions;
        options.filters = filters;
        this.setState({pageOptions: options, filterModalVisible: false});

        this.getMatchList(login, options);
    }

    getMatchList(login, options) {
        if (login.userDetails.data.role === 'ORGANIZER') {
            this.props.getTOOrganizerMatchList(options, login.userDetails.data.accessToken);
        } else {
            this.props.getTOMatchList(options, login.userDetails.data.accessToken);
        }
    }

    onResetFilters() {
        const {pageOptions} = this.state;

        let options = pageOptions;
        options.filters = null;
        this.setState({
            pageOptions: options,
        });
    }

    onCloseFilterModal() {
        this.setState({
            filterModalVisible: false,
        });
    }

    //on click of next in table
    onNext() {
        const {pageData, pageOptions} = this.state;
        const {login} = this.props;
        let options = next(pageOptions, pageData);

        if (options) {
            if (login.userDetails.data.role === 'ORGANIZER') {
                this.props.getTOOrganizerMatchList(options, login.userDetails.data.accessToken);
            } else {
                this.props.getTOMatchList(options, login.userDetails.data.accessToken);
            }
            this.setState({pageOptions: options, loading: true});
        }
    }

    //onclick of prev in table
    onPrev() {
        const {pageOptions} = this.state;
        const {login} = this.props;
        let options = prev(pageOptions);
        if (options) {
            if (login.userDetails.data.role === 'ORGANIZER') {
                this.props.getTOOrganizerMatchList(options, login.userDetails.data.accessToken);
            } else {
                this.props.getTOMatchList(options, login.userDetails.data.accessToken);
            }
            this.setState({pageOptions: options, loading: true});
        }
    }

    onReset() {
        const {login} = this.props;
        let options = this.initialPageOptions;
        this.setState({
            pageOptions: options,
            doResetFilters: true,
        });
        if (login.userDetails.data.role === 'ORGANIZER') {
            this.props.getTOOrganizerMatchList(options, login.userDetails.data.accessToken);
        } else {
            this.props.getTOMatchList(options, login.userDetails.data.accessToken);
        }
    }

    render() {
        const {
            list,
            sortOptions,
            loading,
            pageData,
            TOMatch,
            errorMessage,
            snackbarOpen,
            filterModalVisible,
            doResetFilters,
        } = this.state;
        const data = list;
        const {searchString, sortOrder, page} = this.state.pageOptions;

        let disableNext = page === pageData.lastPage ? true : false;

        const columns = [
            {
                Header: 'Match ID',
                accessor: 'id',
                width: 200,
                Cell: (props) => (
                    <span className="table-clickable" onClick={this.onEditTOMatch.bind(this, props.value)}>
                        {props.value === undefined || props.value === '' || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Name',
                accessor: 'name',
                width: 200,
                Cell: (props) => (
                    <span>
                        {props.value === undefined || props.value === '' || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: (props) => (
                    <span>
                        {props.value === undefined || props.value === '' || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Match Access',
                accessor: 'matchAccess',
                Cell: (props) => (
                    <span>
                        {props.value === undefined || props.value === '' || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Start Time',
                accessor: 'startTime',
                Cell: (props) => (
                    <span className="date-space">
                        {props.value === undefined || props.value === '' || props.value === ''
                            ? '-'
                            : timeFormat(props.value)}
                    </span>
                ),
            },
            {
                Header: 'Total Slots',
                accessor: 'totalSlots',
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Created By',
                accessor: 'createdByClient',
                Cell: (props) => (
                    <span>
                        {props.value === undefined || props.value === '' || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Entry Type',
                accessor: 'entryFeeType',
                Cell: (props) => (
                    <span>
                        {props.value === undefined || props.value === '' || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Fee',
                accessor: 'entryFeePerSlot',
                Cell: (props) => (
                    <span>
                        {props.value === undefined || props.value === '' || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Min Rule',
                accessor: 'minParticipationRequired',
                Cell: (props) => (
                    <span>
                        {props.value === undefined || props.value === '' || props.value === ''
                            ? '-'
                            : props.value.toString()}
                    </span>
                ),
            },
            {
                Header: 'Min Slots',
                accessor: 'minFillSlots',
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Pool Type',
                accessor: 'winPoolType',
                Cell: (props) => (
                    <span>
                        {props.value === undefined || props.value === '' || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Fixed Pool',
                accessor: 'winFixedPoolSize',
                Cell: (props) => (
                    <span>
                        {props.value === undefined || props.value === '' || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: '% Pool',
                accessor: 'winPercentPoolSize',
                Cell: (props) => (
                    <span>
                        {props.value === undefined || props.value === '' || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Attribute1',
                accessor: 'attribute1Val',
                Cell: (props) => (
                    <span>
                        {props.value === undefined || props.value === '' || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Attribute2',
                accessor: 'attribute2Val',
                Cell: (props) => (
                    <span>
                        {props.value === undefined || props.value === '' || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Match Mode',
                accessor: 'matchMode.modeName',
                Cell: (props) => (
                    <span>
                        {props.value === undefined || props.value === '' || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Game',
                accessor: 'game.gameName',

                Cell: (props) => <span>{props.value === undefined || props.value === '' ? '-' : props.value}</span>,
            },
        ];
        return (
            <div>
                <TableLayout
                    location="TOCompletedMatch"
                    history={this.props.history}
                    searchbar="Search Match Name"
                    dispDelete={TOMatch.length > 0 ? true : false}
                    onsearchChange={this.onSearchandSort.bind(this)}
                    sortOptions={sortOptions}
                    onReset={this.onReset.bind(this)}
                    searchString={searchString}
                    sortOrder={sortOrder}
                />
                <div style={{marginLeft: 40, marginBottom: 10}}>
                    <Button
                        buttonText="Filters"
                        onClick={() => {
                            this.setState({filterModalVisible: true});
                        }}
                    />
                </div>
                <Table
                    data={data}
                    columns={columns}
                    page={page}
                    onPrevClick={this.onPrev.bind(this)}
                    onNextClick={this.onNext.bind(this)}
                    disableNext={disableNext}
                />
                <Snackbar
                    open={snackbarOpen}
                    message={errorMessage}
                    onClose={() => this.setState({snackbarOpen: false})}
                />
                <LoadingModal open={loading} />
                <FiltersModal
                    modalVisible={filterModalVisible}
                    onApplyFilters={this.onApplyFilters.bind(this)}
                    onResetFilters={this.onResetFilters.bind(this)}
                    onCloseModal={this.onCloseFilterModal.bind(this)}
                    resetFilters={doResetFilters}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        toCompletedMatch: state.toCompletedMatch,
    };
};

export default connect(mapStateToProps, {getTOMatchList, getTOOrganizerMatchList, editTOMatch, logout})(
    TOCompletedMatchList,
);
