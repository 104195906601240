import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {logout} from '../../View/Modules/Login/LoginAction';
import {ChevronLeft} from '@material-ui/icons';
import {IconButton, styled} from '@material-ui/core';
import {ColorPalette} from '../../Utilities/constants/color-constants';
import styles from './AddEditLayout.module.scss';
import classNames from 'classnames';
import MuiSelectField from '../../Components/MuiSelectField/MuiSelectField';
import {selectClient} from '../../View/Modules/Client/action';

const AddEditLayout = (props) => {
    const {children, history, mainClassName, onClickBack} = props;
    const dispatch = useDispatch();
    const login = useSelector((state) => state.login);
    const clientId = useSelector((state) => state.client.selectedClient);

    useEffect(() => {
        if (!login.userDetails.data) {
            history.push('/');
            dispatch(logout());
        }
    }, []);

    const handleChangeClient = (event) => {
        dispatch(selectClient(event.target.value));
        setTimeout(() => { window.location.reload()},0)
    };

    return (
        <section className={styles.layout}>
            <header className={styles.header}>
                <nav className={styles.nav}>
                    <CustomIconButton onClick={onClickBack} className={styles.backButton}>
                        <ChevronLeft className={styles.backButtonIcon} />
                    </CustomIconButton>
                    <MuiSelectField
                        className={styles.clientSelectField}
                        label="Client"
                        name="clientId"
                        onChange={(event) => handleChangeClient(event)}
                        options={login?.userDetails?.data?.clientIds?.map(client => {
                            const [prefix, name] = client.split('_');
                            const capitalized = name.charAt(0).toUpperCase() + name.slice(1);
                        
                            return {
                                value: client,
                                text: capitalized
                            };
                        })}
                        value={clientId}
                        size="small"
                    />
                </nav>
            </header>
            <main className={classNames(mainClassName, styles.main)}>{children}</main>
        </section>
    );
};

const CustomIconButton = styled(IconButton)({
    width: '28px',
    height: '28px',

    '&:hover': {
        backgroundColor: ColorPalette.NIGHT_RIDER,
    },
});

export default AddEditLayout;
