import React from 'react';
import '../../../Utilities/style.css';
import '../Login/login.css';
import {connect} from 'react-redux';
import DetailLayout from '../../Layouts/DetailLayout/DetailLayout';
import Card from '../../../Components/Card/Card';
import Input from '../../../Components/Input/Input';
import DropDown from '../../../Components/Dropdown/Dropdown';
import {getPlayerList} from '../Players/PlayerAxios';
import axios from 'axios';
import {URL} from '../../../Utilities/Constants';
import ImageInput from '../../../Components/ImageInput/ImageInput';
import {addSquad, getSquad, updateSquad, deleteSquad} from './SquadAxios';
import Snackbar from '../../../Components/Snackbar/Snackbar';
import Modal from '../../../Components/Modal/Modal';
import Button from '../../../Components/Button/Button';
import Close from '../../../Images/close_yellow.png';
import {logout} from '../Login/LoginAction';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';
import {timeFormat} from '../../../Utilities/Helpers';

class CreateSquad extends React.Component {
    constructor(props) {
        super(props);
        let location = this.props.history.location.pathname.includes('edit');
        this.state = {
            menuItems: [
                {value: 'ACTIVE', text: 'ACTIVE'},
                {value: 'INACTIVE', text: 'INACTIVE'},
            ],
            squadName: null,
            squadStatus: null,
            squadImage: null,
            s3Url: null,
            disabled: false,
            pageSize: 10,
            page: 1,
            playerOptions: [],
            apiError: null,
            edit: location,
            open: false,
            players: [],
            player1: {
                player1Status: '',
                player1name: '',
                player1Position: '',
                player1ID: '',
            },
            player2: {
                player2Status: '',
                player2name: '',
                player2Position: '',
                player2ID: '',
            },
            player3: {
                player3Status: '',
                player3name: '',
                player3Position: '',
                player3ID: '',
            },
            player4: {
                player4Status: '',
                player4name: '',
                player4Position: '',
                player4ID: '',
            },
            player5: {
                player5Status: '',
                player5name: '',
                player5Position: '',
                player5ID: '',
            },
            loading: false,
            tier: null,
            rank: null,
            popularity: null,
        };
    }

    /**
     * function to save entered data in state
     * @param {string} field field names from the form
     * @param {*} value value in the field
     */
    handleInputChange(field, value) {
        let {player1, player3, player2, player4, player5} = this.state;
        if (field === 'uploadImage') {
            console.log('Squad upload function is triggered');
            this.setState({loading: true});
            const data = new FormData();
            data.append('squadImage', value);
            axios({
                method: 'post',
                headers: {
                    Authorization: 'Bearer ' + this.props.login.userDetails.data.accessToken,
                },
                url: `${URL}/squad/imageupload`,
                data: data,
            })
                .then((res) => {
                    this.setState({loading: false});
                    if (res.data.data) this.setState({squadImage: res.data.data.image, s3Url: res.data.data.s3Url});
                })
                .catch((err) => {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    this.setState({loading: false, errorMessage: errorMsg});
                });
        }
        if (field.includes('player') && field.includes('Status') && value === 'INACTIVE') {
            player1.player1Status =
                'player1Status' === field
                    ? value
                    : player1.player1Status === 'INACTIVE'
                      ? 'ACTIVE'
                      : player1.player1Status;
            player2.player2Status =
                'player2Status' === field
                    ? value
                    : player2.player2Status === 'INACTIVE'
                      ? 'ACTIVE'
                      : player2.player2Status;
            player3.player3Status =
                'player3Status' === field
                    ? value
                    : player3.player3Status === 'INACTIVE'
                      ? 'ACTIVE'
                      : player3.player3Status;
            player4.player4Status =
                'player4Status' === field
                    ? value
                    : player4.player4Status === 'INACTIVE'
                      ? 'ACTIVE'
                      : player4.player4Status;
            player5.player5Status =
                'player5Status' === field
                    ? value
                    : player5.player5Status === 'INACTIVE'
                      ? 'ACTIVE'
                      : player5.player5Status;

            this.setState({
                player1,
                player3,
                player2,
                player4,
                player5,
                [field]: value,
                errorMessage: '',
            });
        } else {
            this.setState({[field]: value, errorMessage: ''});
        }
    }

    /**
     * check number of active players in squad and call api to update squad
     */
    onSave() {
        const {squadName, squadStatus, squadImage, s3Url, tier, popularity, rank} = this.state;
        const {player1ID, player1Status} = this.state.player1;
        const {player2ID, player2Status} = this.state.player2;
        const {player4ID, player4Status} = this.state.player4;
        const {player3ID, player3Status} = this.state.player3;
        const {player5ID, player5Status} = this.state.player5;

        const {squad, login} = this.props;

        if (
            squadName !== null &&
            squadName !== undefined &&
            squadName !== '' &&
            squadName.length > 1 &&
            squadName.length < 30
        ) {
            let newSquad = {
                squadId: squad.editSquad.id,
                squadName: squadName,
                squadImage: squadImage,
                s3Url,
                status: squadStatus,
                tier: tier,
                rank: rank,
                popularity: popularity,
            };
            this.props.updateSquad(newSquad, login.userDetails.data.accessToken);
        } else {
            this.setState({errorMessage: 'Please enter valid squad name'});
        }
    }

    componentDidMount() {
        const {login, history, squad} = this.props;
        if (!login.userDetails.data) {
            history.push('/');
            this.props.logout();
        } else {
            const {edit} = this.state;
            if (edit) {
                let newSquad = {id: squad.editSquad.id};
                this.props.getSquad(newSquad, '', login.userDetails.data.accessToken);
            }
        }
    }

    componentDidUpdate(prevProps) {
        const {history, squad, player, login} = this.props;

        if (player.playerListSuccess && !prevProps.player.playerListSuccess) {
            this.setState({
                loading: false,
                playerOptions: player.playerList.data.data.map((player) => {
                    return {title: player.pubgName, value: player.id};
                }),
            });
        } else if (player.playerListFail && !prevProps.player.playerListFail) {
            this.setState({errorMessage: player.errorMessage, loading: false});
        }

        if (squad.addSquadSuccess && !prevProps.squad.addSquadSuccess) {
            history.goBack();
        } else if (squad.addSquadFail && !prevProps.squad.addSquadFail) {
            this.setState({errorMessage: squad.errorMessage, loading: false});
        }

        if (squad.deleteSquadsuccess && !prevProps.squad.deleteSquadsuccess) {
            let revert = squad.deleteSquad.msg === 'Squad Deleted.';
            if (revert) {
                history.push('/squad');
            } else {
                this.setState({errorMessage: squad.deleteSquad.msg, loading: false});
            }
        } else if (squad.deleteSquadFail && !prevProps.squad.deleteSquadFail) {
            this.setState({errorMessage: squad.errorMessage, loading: false});
        }

        if (squad.updateSquadSuccess && !prevProps.squad.updateSquadSuccess) {
            history.push('/squad');
            this.setState({errorMessage: 'Squad(s) updated', loading: false});
        } else if (squad.updateSquadFail && !prevProps.squad.updateSquadFail) {
            this.setState({errorMessage: squad.errorMessage, loading: false});
        }

        if (squad.getSquadSuccess && !prevProps.squad.getSquadSuccess && this.state.edit) {
            const {squadName, status, players, squadImage, s3Url, tier, rank, popularity} = squad.getSquad.data;
            this.setState({
                squadName: squadName,
                squadStatus: status,
                squadImage: squadImage ? squadImage : null,
                s3Url,
                players: players,
                player1: {
                    player1ID: players[0] && players[0].id ? players[0].id : undefined,
                    player1name: players[0] && players[0].pubgName ? players[0].pubgName : undefined,
                    player1Position: players[0] && players[0].positionInSquad ? players[0].positionInSquad : undefined,
                    player1Status: players[0] && players[0].status ? players[0].status : undefined,
                },
                player2: {
                    player2ID: players[1] && players[1].id ? players[1].id : undefined,
                    player2name: players[1] && players[1].pubgName ? players[1].pubgName : undefined,
                    player2Position: players[1] && players[1].positionInSquad ? players[1].positionInSquad : undefined,
                    player2Status: players[1] && players[1].status ? players[1].status : undefined,
                },
                player3: {
                    player3ID: players[2] && players[2].id ? players[2].id : undefined,
                    player3name: players[2] && players[2].pubgName ? players[2].pubgName : undefined,
                    player3Position: players[2] && players[2].positionInSquad ? players[2].positionInSquad : undefined,
                    player3Status: players[2] && players[2].status ? players[2].status : undefined,
                },
                player4: {
                    player4ID: players[3] && players[3].id ? players[3].id : undefined,
                    player4name: players[3] && players[3].pubgName ? players[3].pubgName : undefined,
                    player4Position: players[3] && players[3].positionInSquad ? players[3].positionInSquad : undefined,
                    player4Status: players[3] && players[3].status ? players[3].status : undefined,
                },
                player5: {
                    player5ID: players[4] && players[4].id ? players[4].id : undefined,
                    player5name: players[4] && players[4].pubgName ? players[4].pubgName : undefined,
                    player5Position: players[4] && players[4].positionInSquad ? players[4].positionInSquad : undefined,
                    player5Status: players[4] && players[4].status ? players[4].status : undefined,
                },

                loading: false,
                playersLength: players.length,
                tier: tier ? tier : null,
                rank: rank ? rank : null,
                popularity: popularity ? popularity : null,
            });
        } else if (squad.getSquadFail && !prevProps.squad.getSquadFail) {
            this.setState({errorMessage: 'Something went Wrong', loading: false});
        }
    }

    /**
     * api call to delete the squad
     */
    onDeleteSquad() {
        const {squad, login} = this.props;

        let data = {
            squadId: squad.editSquad.id,
        };
        this.props.deleteSquad(data, login.userDetails.data.accessToken);
        this.setState({
            open: false,
            loading: true,
        });
    }

    displayPlayers() {
        const {players} = this.state;
        if (players.length > 0) {
            return this.state.players.map((players) => {
                return (
                    <div className="four-column-grid ">
                        <div className="grid-item  margin-top-20">
                            <div className="margin-top-20">
                                <div className="basic-headers">Player ID</div>
                                <div className="basiic-data">{players.id === '' ? '-' : players.id}</div>
                            </div>
                        </div>
                        <div className="grid-item  margin-top-20">
                            <div className="margin-top-20">
                                <div className="basic-headers">Player Name</div>
                                <div className="basiic-data">{players.pubgName === '' ? '-' : players.pubgName}</div>
                            </div>
                        </div>
                        <div className="grid-item  margin-top-20">
                            <div className="margin-top-20">
                                <div className="basic-headers">Position In Squad</div>
                                <div className="basiic-data">
                                    {players.positionInSquad === '' ? '-' : players.positionInSquad}
                                </div>
                            </div>
                        </div>
                        <div className="grid-item  margin-top-20">
                            <div className="margin-top-20">
                                <div className="basic-headers">Player Status</div>
                                <div className="basiic-data">{players.status === '' ? '-' : players.status}</div>
                            </div>
                        </div>
                    </div>
                );
            });
        }
    }

    render() {
        const {
            menuItems,
            squadName,
            squadStatus,
            disabled,
            playerOptions,
            errorMessage,
            open,
            loading,
            edit,
            squadImage,
            tier,
            popularity,
            rank,
        } = this.state;

        let title = edit ? ' Edit Squad' : 'Add Squad';
        const {history, squad, login} = this.props;

        const {squadWrite} = login?.permissions || {};
        return (
            <DetailLayout
                location="Squads"
                history={history}
                centerTitle={title}
                onSave={squadWrite ? this.onSave.bind(this) : undefined}
                onDeleteClick={squadWrite ? () => this.setState({open: true}) : undefined}
                editPage={edit}
                id={edit ? squad.editSquad.id : null}
            >
                {!!errorMessage && (
                    <Snackbar
                        open={!!errorMessage}
                        message={errorMessage}
                        onClose={() => this.setState({errorMessage: ''})}
                    />
                )}
                {!!loading && <LoadingModal open={loading} />}

                <Modal open={open}>
                    <div className="container modal-top-margin">
                        <Card>
                            <div className="disp-flex">
                                <div className="modal-left-header">Delete Squad</div>
                                <div onClick={() => this.setState({open: false})}>
                                    <img src={Close} alt="" className="cursor" />
                                </div>
                            </div>
                            <div className="align-center equalcontainer-grids">
                                <div />
                                <div>
                                    <div className="align-center margin-top-30">
                                        <Button buttonText="Delete" onClick={this.onDeleteSquad.bind(this)} />
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                </Modal>
                <div className="detail-container margin-top-20">
                    <div className="grid-item">
                        <div className="text-grey">Add / Edit Players before populating Squads</div>
                    </div>
                    <div className="grid-item">
                        <Card>
                            <div>
                                <div className="card-grid-container">
                                    {/* row 1 */}
                                    <div className="grid-item  margin-top-20">
                                        <Input
                                            label="Squad Name"
                                            type="text"
                                            name="squadName"
                                            value={squadName}
                                            onChange={this.handleInputChange.bind(this, 'squadName')}
                                            placeholder="Enter Squad Name"
                                        />
                                    </div>
                                    <div className="grid-item margin-top-20">
                                        <DropDown
                                            menuItems={menuItems}
                                            label="Status"
                                            value={squadStatus}
                                            placeholder="select"
                                            onChange={this.handleInputChange.bind(this, 'squadStatus')}
                                        />
                                    </div>
                                </div>
                                <div className="grid-item  margin-top-20">
                                    <div className="grid-item margin-top-20">
                                        <Input
                                            label="Tier"
                                            type="text"
                                            name="tier"
                                            value={tier}
                                            onChange={this.handleInputChange.bind(this, 'tier')}
                                            placeholder="Enter"
                                        />
                                    </div>
                                    <div className="grid-item margin-top-20">
                                        <Input
                                            label="Rank"
                                            type="number"
                                            name="rank"
                                            value={rank}
                                            onChange={this.handleInputChange.bind(this, 'rank')}
                                            placeholder="Enter a number "
                                        />
                                    </div>
                                    <div className="grid-item margin-top-20">
                                        <Input
                                            label="Popularity"
                                            type="text"
                                            name="popularity"
                                            value={popularity}
                                            onChange={this.handleInputChange.bind(this, 'popularity')}
                                            placeholder="Enter yes or no"
                                        />
                                    </div>
                                    <Input
                                        label="Squad Image"
                                        type="text"
                                        name="squadImage"
                                        value={squadImage}
                                        onChange={this.handleInputChange.bind(this, 'squadImage')}
                                        placeholder="Enter Squad Image if Any"
                                        disabled={true}
                                    />
                                </div>

                                {squadImage !== undefined && squadImage !== null && squadImage !== '' ? (
                                    <div className="grid-item  margin-top-20">
                                        <img src={squadImage} style={{height: 100}} />
                                    </div>
                                ) : (
                                    <div className="grid-item  margin-top-20"></div>
                                )}
                                {!!squadWrite && (
                                    <div className="grid-item  margin-top-20">
                                        <ImageInput
                                            label="Upload Squad Image"
                                            type="file"
                                            name="file"
                                            onChange={this.handleInputChange.bind(this, 'uploadImage')}
                                        />
                                    </div>
                                )}
                                {/* row 2 */}
                                <div className="card-fullgrid-container nopadding">
                                    {edit ? this.displayPlayers() : null}
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className="grid-item">
                        {edit ? (
                            <div>
                                <div className="basic-headers">Last Updated</div>
                                <div className="basiic-data date-space">{timeFormat(squad.editSquad.updatedAt)}</div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </DetailLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        squad: state.squad,
        player: state.player,
    };
};

export default connect(mapStateToProps, {
    getPlayerList,
    logout,
    addSquad,
    getSquad,
    updateSquad,
    deleteSquad,
})(CreateSquad);
