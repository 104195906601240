import {GET_MATCH_DETAIL, GET_MATCH_DETAIL_FULFILLED, GET_MATCH_DETAIL_FAILED} from './AxioCalls';

import {
    TOMATCH_LIST,
    TOMATCH_LIST_SUCCESS,
    TOMATCH_LIST_FAIL,
    ADD_TOMATCH,
    ADD_TOMATCH_SUCCESS,
    ADD_TOMATCH_FAIL,
    MODIFY_TOMATCH,
    MODIFY_TOMATCH_SUCCESS,
    MODIFY_TOMATCH_FAIL,
    GET_TOMATCH_PARTICIPATION,
    GET_TOMATCH_PARTICIPATION_SUCCESS,
    GET_TOMATCH_PARTICIPATION_FAIL,
    CANCEL_TOMATCH,
    CANCEL_TOMATCH_SUCCESS,
    CANCEL_TOMATCH_FAIL,
    GET_TOMATCH,
    GET_TOMATCH_SUCCESS,
    GET_TOMATCH_FAIL,
    EDIT_TOMATCH,
} from './Constants';

const initialState = {
    TOMatchListRequest: false,
    TOMatchListSuccess: false,
    TOMatchListFail: false,
    TOMatchList: [],
    errorMessage: '',
    addTOMatchRequest: false,
    addTOMatchSuccess: false,
    addTOMatchFail: false,
    addTOMatch: [],
    modifyTOMatchRequest: false,
    modifyTOMatchSuccess: false,
    modifyTOMatchFail: false,
    modifyTOMatch: [],
    getTOMatchParticipation: false,
    getTOMatchParticipationSuccess: false,
    getTOMatchParticipationFail: false,
    TOMatchParticipation: {},
    cancelTOMatchRequest: false,
    cancelTOMatchSuccess: false,
    cancelTOMatchFail: false,
    cancelTOMatch: [],
    getTOMatchRequest: false,
    getTOMatchSuccess: false,
    getTOMatchFail: false,
    getTOMatch: [],
    editTOMatch: [],
    getMatchDetail: false,
    getMatchDetailComplete: false,
    getMatchDetailFailed: false,
    matchDetail: {},
};

const toPublishedMatchReducer = (state = initialState, action) => {
    switch (action.type) {
        case TOMATCH_LIST:
            return {
                ...state,
                TOMatchListRequest: true,
                TOMatchListSuccess: false,
                TOMatchListFail: false,
                TOMatchList: [],
                errorMessage: '',
            };

        case TOMATCH_LIST_SUCCESS:
            // console.log('Success case is being triggered');
            return {
                ...state,
                TOMatchListRequest: false,
                TOMatchListSuccess: true,
                TOMatchListFail: false,
                TOMatchList: action.payload.data,
                errorMessage: '',
            };

        case TOMATCH_LIST_FAIL:
            return {
                ...state,
                TOMatchListRequest: false,
                TOMatchListSuccess: false,
                TOMatchListFail: true,
                errorMessage: action.payload,
            };
        // ADD match
        case ADD_TOMATCH:
            return {
                ...state,
                addTOMatchRequest: false,
                addTOMatchSuccess: false,
                addTOMatchFail: false,
                addTOMatch: [],
                errorMessage: '',
            };

        case ADD_TOMATCH_SUCCESS:
            return {
                ...state,
                addTOMatchRequest: false,
                addTOMatchSuccess: true,
                addTOMatchFail: false,
                addTOMatch: action.payload.data,
                errorMessage: '',
            };

        case ADD_TOMATCH_FAIL:
            return {
                ...state,
                addTOMatchRequest: false,
                addTOMatchSuccess: false,
                addTOMatchFail: true,
                errorMessage: action.payload,
            };
        //modify TOMatch
        case MODIFY_TOMATCH:
            return {
                ...state,
                modifyTOMatchRequest: true,
                modifyTOMatchSuccess: false,
                modifyTOMatchFail: false,
                modifyTOMatch: [],
                errorMessage: '',
            };

        case MODIFY_TOMATCH_SUCCESS:
            return {
                ...state,
                modifyTOMatchRequest: false,
                modifyTOMatchSuccess: true,
                modifyTOMatchFail: false,
                modifyTOMatch: action.payload.data,
                errorMessage: '',
            };

        case MODIFY_TOMATCH_FAIL:
            return {
                ...state,
                modifyTOMatchRequest: false,
                modifyTOMatchSuccess: false,
                modifyTOMatchFail: true,
                errorMessage: action.payload,
            };

        // GET TOMATCH PARTICIPATION
        case GET_TOMATCH_PARTICIPATION:
            // console.log('Client side log for fetching match participation');
            return {
                ...state,
                getTOMatchParticipation: true,
                getTOMatchParticipationSuccess: false,
                getTOMatchParticipationFail: false,
                TOMatchParticipation: {},
                errorMessage: '',
            };
        case GET_TOMATCH_PARTICIPATION_SUCCESS:
            // console.log('Match Participation fetched');
            // console.log(action.payload.data.data);
            return {
                ...state,
                getTOMatchParticipation: false,
                getTOMatchParticipationSuccess: true,
                getTOMatchParticipationFail: false,
                TOMatchParticipation: action.payload.data.data,
                errorMessage: '',
            };
        case GET_TOMATCH_PARTICIPATION_FAIL:
            return {
                ...state,
                getTOMatchParticipation: false,
                getTOMatchParticipationSuccess: false,
                getTOMatchParticipationFail: true,
                TOMatchParticipation: {},
                errorMessage: action.payload,
            };

        // CANCEL TOMATCH
        case CANCEL_TOMATCH:
            return {
                ...state,
                cancelTOMatchRequest: true,
                cancelTOMatchSuccess: false,
                cancelTOMatchFail: false,
                cancelTOMatch: [],
                errorMessage: '',
            };

        case CANCEL_TOMATCH_SUCCESS:
            return {
                ...state,
                cancelTOMatchRequest: false,
                cancelTOMatchSuccess: true,
                cancelTOMatchFail: false,
                cancelTOMatch: action.payload.data,
                errorMessage: '',
            };

        case CANCEL_TOMATCH_FAIL:
            return {
                ...state,
                cancelTOMatchRequest: false,
                cancelTOMatchSuccess: false,
                cancelTOMatchFail: true,
                errorMessage: action.payload,
            };

        // get single TOMatch
        case GET_TOMATCH:
            return {
                ...state,
                getTOMatchRequest: true,
                getTOMatchSuccess: false,
                getTOMatchFail: false,
                getTOMatch: [],
                errorMessage: '',
            };

        case GET_TOMATCH_SUCCESS:
            return {
                ...state,
                getTOMatchRequest: false,
                getTOMatchSuccess: true,
                getTOMatchFail: false,
                getTOMatch: action.payload.data,
                errorMessage: '',
            };

        case GET_TOMATCH_FAIL:
            return {
                ...state,
                getTOMatchRequest: false,
                getTOMatchSuccess: false,
                getTOMatchFail: true,
                errorMessage: action.payload,
            };
        // EDIT TOMATCH
        case EDIT_TOMATCH:
            return {
                ...state,
                editTOPublishedMatch: action.payload,
            };
        case GET_MATCH_DETAIL:
            return {
                ...state,
                getMatchDetail: true,
                getMatchDetailComplete: false,
                getMatchDetailFailed: false,
                matchDetail: {},
            };
        case GET_MATCH_DETAIL_FULFILLED:
            return {
                ...state,
                getMatchDetail: false,
                getMatchDetailComplete: true,
                getMatchDetailFailed: false,
                matchDetail: action.payload.matchDetail,
            };
        case GET_MATCH_DETAIL_FAILED:
            return {
                ...state,
                getMatchDetail: false,
                getMatchDetailComplete: false,
                getMatchDetailFailed: true,
                matchDetail: action.payload,
            };

        default:
            return state;
    }
};

export default toPublishedMatchReducer;
