import React from 'react';
import {Button, styled} from '@material-ui/core';
import {ColorPalette} from '../../Utilities/constants/color-constants';

const MuiButton = (props) => {
    const {buttonText, buttonIcon, className, onButtonClick, ref, type} = props;

    return (
        <CustomButton className={className} type={type} startIcon={buttonIcon} onClick={onButtonClick} ref={ref}>
            {buttonText}
        </CustomButton>
    );
};

const CustomButton = styled(Button)({
    color: ColorPalette.BLACK,
    background: ColorPalette.SELECTIVE_YELLOW,
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 600,
    textTransform: 'uppercase',
    letterSpacing: '0.8px',
    border: `1px solid ${ColorPalette.SELECTIVE_YELLOW}`,
    borderRadius: '8px',
    transition: 'all 200ms ease-in-out',
    flex: '1 0 auto',
    paddingInline: '16px',

    '&:hover': {
        background: ColorPalette.SALOME,
        border: `1px solid ${ColorPalette.SALOME}`,
    },
});

export default MuiButton;
