import {
    CONTEST_TYPE_LIST,
    CONTEST_TYPE_LIST_SUCCESS,
    CONTEST_TYPE_LIST_FAIL,
    ADD_CONTEST_TYPE,
    ADD_CONTEST_TYPE_SUCCESS,
    ADD_CONTEST_TYPE_FAIL,
    EDIT_CONEST_TYPE,
    UPDATE_CONTEST_TYPE,
    UPDATE_CONTEST_TYPE_SUCCESS,
    UPDATE_CONTEST_TYPE_FAIL,
    DELETE_CONTEST_TYPE,
    DELETE_CONTEST_TYPE_SUCCESS,
    DELETE_CONTEST_TYPE_FAIL,
    GET_CONTEST_TYPE,
    GET_CONTEST_TYPE_SUCCESS,
    GET_CONTEST_TYPE_FAIL,
    GET_CONTEST_CATEGORY_SUCCESS,
    GET_CONTEST_CATEGORY_FAIL,
    GET_CONTEST_CATEGORY,
} from './Constants';

const initialState = {
    contestTypeListRequest: false,
    contestTypeListSuccess: false,
    contestTypeListFail: false,
    contestTypeList: [],
    errorMessage: '',
    addContestTypeRequest: false,
    addContestTypeSuccess: false,
    addContestTypeFail: false,
    addContestType: [],
    editContestType: [],
    contestCategory: [],
    updateContestRequest: false,
    updateContestSuccess: false,
    updateContestFail: false,
    updateContest: [],

    deleteContestType: false,
    deleteContestSuccess: false,
    deleteContestFail: false,
    deleteContest: [],

    getContestType: false,
    getContestSuccess: false,
    getContestFail: false,
    getContest: [],
};

const contestTypeReducer = (state = initialState, action) => {
    switch (action.type) {
        case CONTEST_TYPE_LIST:
            return {
                ...state,
                contestTypeListRequest: true,
                contestTypeListSuccess: false,
                contestTypeListFail: false,
                contestTypeList: [],
                errorMessage: '',
            };

        case CONTEST_TYPE_LIST_SUCCESS:
            return {
                ...state,
                contestTypeListRequest: false,
                contestTypeListSuccess: true,
                contestTypeListFail: false,
                contestTypeList: action.payload.data,
                errorMessage: '',
            };

        case CONTEST_TYPE_LIST_FAIL:
            return {
                ...state,
                contestTypeListRequest: false,
                contestTypeListSuccess: false,
                contestTypeListFail: true,
                errorMessage: action.payload,
            };
        // ADD
        case ADD_CONTEST_TYPE:
            return {
                ...state,
                addContestTypeRequest: true,
                addContestTypeSuccess: false,
                addContestTypeFail: false,
                addContestType: [],
                errorMessage: '',
            };

        case ADD_CONTEST_TYPE_SUCCESS:
            return {
                ...state,
                addContestTypeRequest: false,
                addContestTypeSuccess: true,
                addContestTypeFail: false,
                addContestType: action.payload.data,
                errorMessage: '',
            };

        case ADD_CONTEST_TYPE_FAIL:
            return {
                ...state,
                addContestTypeRequest: false,
                addContestTypeSuccess: false,
                addContestTypeFail: true,
                errorMessage: action.payload,
            };

        // UPDATE
        case UPDATE_CONTEST_TYPE:
            return {
                ...state,
                updateContestRequest: true,
                updateContestSuccess: false,
                updateContestFail: false,
                updateContest: [],
                errorMessage: '',
            };

        case UPDATE_CONTEST_TYPE_SUCCESS:
            return {
                ...state,
                updateContestRequest: false,
                updateContestSuccess: true,
                updateContestFail: false,
                updateContest: action.payload.data,
            };

        case UPDATE_CONTEST_TYPE_FAIL:
            return {
                ...state,
                updateContestRequest: false,
                updateContestSuccess: false,
                updateContestFail: true,
                errorMessage: action.payload,
            };
        // delete
        case DELETE_CONTEST_TYPE:
            return {
                ...state,
                deleteContestType: true,
                deleteContestSuccess: false,
                deleteContestFail: false,
                deleteContest: [],
                errorMessage: '',
            };

        case DELETE_CONTEST_TYPE_SUCCESS:
            return {
                ...state,
                deleteContestType: false,
                deleteContestSuccess: true,
                deleteContestFail: false,
                deleteContest: action.payload.data,
            };

        case DELETE_CONTEST_TYPE_FAIL:
            return {
                ...state,
                deleteContestType: false,
                deleteContestSuccess: false,
                deleteContestFail: true,
                errorMessage: action.payload,
            };
        // get
        case GET_CONTEST_TYPE:
            return {
                ...state,
                getContestType: true,
                getContestSuccess: false,
                getContestFail: false,
                getContest: [],
                errorMessage: '',
            };

        case GET_CONTEST_TYPE_SUCCESS:
            return {
                ...state,
                getContestType: false,
                getContestSuccess: true,
                getContestFail: false,
                getContest: action.payload.data,
            };

        case GET_CONTEST_TYPE_FAIL:
            return {
                ...state,
                getContestType: false,
                getContestSuccess: false,
                getContestFail: true,
                errorMessage: action.payload,
            };

        case GET_CONTEST_CATEGORY_SUCCESS:
            let categories = action.payload.data.data.categories;
            return {
                ...state,
                getContestCategorySuccess: true,
                contestCategory: categories,
            };

        case GET_CONTEST_CATEGORY:
            return {
                ...state,
                getContestCategorySuccess: false,
            };

        case GET_CONTEST_CATEGORY_FAIL:
            return {
                ...state,
                getContestCategorySuccess: false,
                errorMessage: action.payload,
            };

        case EDIT_CONEST_TYPE:
            return {
                ...state,
                editContestType: action.payload,
            };

        default:
            return state;
    }
};

export default contestTypeReducer;
