export const GET_BANNER_LIST = 'GET_BANNER_LIST';
export const GET_BANNER_LIST_SUCCESS = 'GET_BANNER_LIST_SUCCESS';
export const GET_BANNER_LIST_FAIL = 'GET_BANNER_LIST_FAIL';

export const GET_BANNER = 'GET_BANNER';
export const GET_BANNER_SUCCESS = 'GET_BANNER_SUCCESS';
export const GET_BANNER_FAIL = 'GET_BANNER_FAIL';

export const CREATE_BANNER = 'CREATE_BANNER';
export const CREATE_BANNER_SUCCESS = 'CREATE_BANNER_SUCCESS';
export const CREATE_BANNER_FAIL = 'CREATE_BANNER_FAIL';

export const UPDATE_BANNER = 'UPDATE_BANNER';
export const UPDATE_BANNER_SUCCESS = 'UPDATE_BANNER_SUCCESS';
export const UPDATE_BANNER_FAIL = 'UPDATE_BANNER_FAIL';
