import axios from 'axios';
import {
    listTOMatchAttributes,
    listTOMatchAttributesSuccess,
    listTOMatchAttributesFail,
    addTOMatchAttribute,
    addTOMatchAttributeSuccess,
    addTOMatchAttributeFail,
    updateTOMatchAttributeRequest,
    updateTOMatchAttributeSuccess,
    updateTOMatchAttributeFail,
    deleteTOMatchAttributeRequest,
    deleteTOMatchAttributeSuccess,
    deleteTOMatchAttributeFail,
    getTOMatchAttributeRequest,
    getTOMatchAttributeSuccess,
    getTOMatchAttributeFail,
} from './action';
import {TOURL} from '../../../Utilities/Constants';

export function getTOMatchAttributeList(pageOptions, token) {
    return (dispatch) => {
        dispatch(listTOMatchAttributes());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token},
            url: `${TOURL}/matchAttribute/list`,
            data: pageOptions,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(listTOMatchAttributesSuccess(data));
                } else {
                    dispatch(listTOMatchAttributesFail(data.data.errorData.errorReason));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(listTOMatchAttributesFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(listTOMatchAttributesFail(err.message || 'Something went wrong'));
                }
            });
    };
}

//creating new TOMatchAttribute
export function createTOMatchAttribute(TOMatchAttribute, token) {
    return (dispatch) => {
        dispatch(addTOMatchAttribute());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token},
            url: `${TOURL}/matchAttribute/create`,
            data: TOMatchAttribute,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(addTOMatchAttributeSuccess(data));
                } else {
                    dispatch(addTOMatchAttributeFail(data.data.errorData.errorReason));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(addTOMatchAttributeFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(addTOMatchAttributeFail(err.message || 'Something went wrong'));
                }
            });
    };
}

//TOMatchAttribute update
export function updateTOMatchAttribute(TOMatchAttribute, token) {
    return (dispatch) => {
        dispatch(updateTOMatchAttributeRequest());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token},
            url: `${TOURL}/matchAttribute/update`,
            data: TOMatchAttribute,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(updateTOMatchAttributeSuccess(data));
                } else {
                    dispatch(updateTOMatchAttributeFail(data.data.errorData.errorReason));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(updateTOMatchAttributeFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(updateTOMatchAttributeFail(err.message || 'Something went wrong'));
                }
            });
    };
}

//TOMatchAttribute delete
export function deleteTOMatchAttribute(id, token) {
    return (dispatch) => {
        dispatch(deleteTOMatchAttributeRequest());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token},
            url: `${TOURL}/matchAttribute/delete`,
            data: {matchAttributeId: id},
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(deleteTOMatchAttributeSuccess(data));
                } else {
                    dispatch(deleteTOMatchAttributeFail(data.data.errorData.errorReason));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(deleteTOMatchAttributeFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(deleteTOMatchAttributeFail(err.message || 'Something went wrong'));
                }
            });
    };
}

// get single TOMatchAttribute
export function getTOMatchAttribute(id, token) {
    return (dispatch) => {
        dispatch(getTOMatchAttributeRequest());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token},
            url: `${TOURL}/matchAttribute/get`,
            data: {
                matchAttributeId: id,
            },
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(getTOMatchAttributeSuccess(data));
                } else {
                    dispatch(getTOMatchAttributeFail(data.data.errorData.errorReason));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(getTOMatchAttributeFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(getTOMatchAttributeFail(err.message || 'Something went wrong'));
                }
            });
    };
}
