import React from 'react';
import {FormControl, InputLabel, OutlinedInput, styled} from '@material-ui/core';
import {ColorPalette} from '../../Utilities/constants/color-constants';

const MuiInputField = (props) => {
    const {
        className,
        disabled,
        endAdornment,
        fullWidth,
        inputProps,
        label,
        multiline,
        name,
        onChange,
        onEnter,
        placeholder,
        readOnly,
        required,
        rows,
        size,
        startAdornment,
        type,
        value,
    } = props;

    const handleKeyDown = (event) => {
        if (event.keyCode === 13 && !!onEnter) {
            onEnter();
        }
    };

    return (
        <CustomFormControl
            variant="outlined"
            disabled={disabled}
            size={size}
            fullWidth={fullWidth}
            className={className}
        >
            <InputLabel htmlFor={name}>{label}</InputLabel>
            <OutlinedInput
                autoComplete="off"
                endAdornment={endAdornment}
                id={name}
                inputProps={inputProps}
                label={label}
                multiline={multiline}
                name={name}
                onChange={onChange}
                onKeyDown={handleKeyDown}
                placeholder={placeholder}
                readOnly={readOnly}
                required={required}
                rows={rows}
                startAdornment={startAdornment}
                type={type}
                value={value}
            />
        </CustomFormControl>
    );
};

MuiInputField.defaultProps = {
    disabled: false,
    fullWidth: true,
    size: 'medium',
    readOnly: false,
    required: false,
    type: 'text',
};

export default MuiInputField;

const CustomFormControl = styled(FormControl)({
    '& .MuiInputLabel-root': {
        color: ColorPalette.SALOME,

        '&.Mui-focused': {
            color: ColorPalette.SELECTIVE_YELLOW,
        },
    },

    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: ColorPalette.SALOME,
            borderRadius: '8px',
        },

        '&.Mui-focused fieldset': {
            borderColor: ColorPalette.SELECTIVE_YELLOW,
        },
    },

    '& .InputAdornment-root': {
        '& .MuiIconButton-root': {
            color: ColorPalette.SALOME,
        },
    },
});
