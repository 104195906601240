import axios from 'axios';
import {
    listTOMatchTypes,
    listTOMatchTypesSuccess,
    listTOMatchTypesFail,
    addTOMatchType,
    addTOMatchTypeSuccess,
    addTOMatchTypeFail,
    updateTOMatchTypeRequest,
    updateTOMatchTypeSuccess,
    updateTOMatchTypeFail,
    deleteTOMatchTypeRequest,
    deleteTOMatchTypeSuccess,
    deleteTOMatchTypeFail,
    getTOMatchTypeRequest,
    getTOMatchTypeSuccess,
    getTOMatchTypeFail,
} from './action';
import {TOURL} from '../../../Utilities/Constants';
import {logout} from '../Login/LoginAction';

export function getTOMatchTypeList(pageOptions, token) {
    // listing all TOMatchTypes
    return (dispatch) => {
        dispatch(listTOMatchTypes());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token},
            url: `${TOURL}/matchType/list`,
            data: pageOptions,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(listTOMatchTypesSuccess(data));
                } else {
                    dispatch(listTOMatchTypesFail(data.data.errorData.errorReason));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(listTOMatchTypesFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(listTOMatchTypesFail(err.message || 'Something went wrong'));
                }
            });
    };
}

//creating new TOMatchType
export function createTOMatchType(TOMatchType, token) {
    return (dispatch) => {
        dispatch(addTOMatchType());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token},
            url: `${TOURL}/matchType/create`,
            data: TOMatchType,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(addTOMatchTypeSuccess(data));
                } else {
                    dispatch(addTOMatchTypeFail(data.data.errorData.errorReason));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(addTOMatchTypeFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(addTOMatchTypeFail(err.message || 'Something went wrong'));
                }
            });
    };
}

//TOMatchType update
export function updateTOMatchType(TOMatchType, token) {
    return (dispatch) => {
        dispatch(updateTOMatchTypeRequest());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token},
            url: `${TOURL}/matchType/update`,
            data: TOMatchType,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(updateTOMatchTypeSuccess(data));
                } else {
                    dispatch(updateTOMatchTypeFail(data.data.errorData.errorReason));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(updateTOMatchTypeFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(updateTOMatchTypeFail(err.message || 'Something went wrong'));
                }
            });
    };
}

//TOMatchType delete
export function deleteTOMatchType(id, token) {
    return (dispatch) => {
        dispatch(deleteTOMatchTypeRequest());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token},
            url: `${TOURL}/matchType/delete`,
            data: {matchTypeId: id},
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(deleteTOMatchTypeSuccess(data));
                } else {
                    dispatch(deleteTOMatchTypeFail(data.data.errorData.errorReason));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(deleteTOMatchTypeFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(deleteTOMatchTypeFail(err.message || 'Something went wrong'));
                }
            });
    };
}

// get single TOMatchType
export function getTOMatchType(id, token) {
    return (dispatch) => {
        dispatch(getTOMatchTypeRequest());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token},
            url: `${TOURL}/matchType/get`,
            data: {
                matchTypeId: id,
            },
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(getTOMatchTypeSuccess(data));
                } else {
                    dispatch(getTOMatchTypeFail(data.data.errorData.errorReason));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(getTOMatchTypeFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(getTOMatchTypeFail(err.message || 'Something went wrong'));
                }
            });
    };
}
