import {GENERATE_REPORT, GENERATE_REPORT_SUCCESS, GENERATE_REPORT_FAIL} from './Constants';

const initialState = {
    generateReportsRequest: false,
    generateReportsSuccess: false,
    generateReportsFail: false,
    generateReports: [],
    errorMessage: '',
};

const reportsReducer = (state = initialState, action) => {
    switch (action.type) {
        //list all withdrawals
        case GENERATE_REPORT:
            return {
                ...state,
                generateReportsRequest: true,
                generateReportsSuccess: false,
                generateReportsFail: false,
                generateReports: [],
                errorMessage: '',
            };

        case GENERATE_REPORT_SUCCESS:
            return {
                ...state,
                generateReportsRequest: false,
                generateReportsSuccess: true,
                generateReportsFail: false,
                generateReports: action.payload.data,
                errorMessage: '',
            };

        case GENERATE_REPORT_FAIL:
            return {
                ...state,
                generateReportsRequest: false,
                generateReportsSuccess: false,
                generateReportsFail: true,
                errorMessage: action.payload,
            };

        default:
            return state;
    }
};

export default reportsReducer;
