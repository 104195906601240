import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Redirect, Route, Switch, withRouter} from 'react-router-dom';

import Login from './View/Modules/Login/Login';
// import ResetPassword from "./View/Modules/ChangePassword/CreateNewPassword";
import PlayersList from './View/Modules/Players/PlayersList';
import SquadList from './View/Modules/Squads/SquadList';
import CreateSquad from './View/Modules/Squads/CreateSquad';
import AddPlayer from './View/Modules/Players/AddPlayer';
import SquadPaths from './View/Modules/Squads/UrlConstants';
import PlayerPaths from './View/Modules/Players/UrlConstants';
// import ChangePswdPath from "./View/Modules/ChangePassword/UrlConstants";
import MatchesPath from './View/Modules/Matches/UrlConstants';
import Matches from './View/Modules/Matches/MatchesList';
import UpcomingMatches from './View/Modules/Matches/UpcomingMatches';
import ContestPath from './View/Modules/Contests/UrlConstants';
import Contests from './View/Modules/Contests/ContestList';
import TeamsPath from './View/Modules/Teams/UrlConstants';
import Teams from './View/Modules/Teams/TeamsList';
import Users from './View/Modules/Users/UsersList';
import UserPaths from './View/Modules/Users/UrlConstants';
import Withdrawals from './View/Modules/Withdrawals/WithdrawalsList';
import Withdrawalpath from './View/Modules/Withdrawals/UrlConstants';
import ContestTypes from './View/Modules/ContestType/ContestTypeList';
import ContestTypePath from './View/Modules/ContestType/UrlConstants';
import ReferalCode from './View/Modules/ReferalCode/referalCodeList';
import ReferalCodePath from './View/Modules/ReferalCode/UrlConstants';
import DiscountCode from './View/Modules/DiscountCodes/DiscountCodeList';
import DiscountCodePath from './View/Modules/DiscountCodes/UrlConstants';
import VerifyMatch from './View/Modules/VerifyMatch/VerifyMatchList';
import VerifyMatchPath from './View/Modules/VerifyMatch/UrlConstants';
// import RecordMatchList from "./View/Modules/RecordMatch/RecordmatchList";
// import RecordMatchPath from "./View/Modules/RecordMatch/UrlConstants";
import AddMatch from './View/Modules/Matches/AddMatch';
import AddTournamentScreen from './View/Modules/Matches/AddTournamentScreen';
import RescheduleMatch from './View/Modules/Matches/RescheduleMatch';
import CancelMatch from './View/Modules/Matches/CancelMatch';
import MatchOfTheDay from './View/Modules/Matches/MatchOfTheDay';
import AddContestType from './View/Modules/ContestType/AddContestType';
import AddContest from './View/Modules/Contests/AddContest';
import UserMatchDetails from './View/Modules/Users/UserMatchDetails';
import UserContestDetails from './View/Modules/Users/UserContestDetails';
import UserContestWon from './View/Modules/Users/UserContestWon';
import UserEntryFees from './View/Modules/Users/UserEntryFees';
import UserWinnings from './View/Modules/Users/UserWinnings';
import UserWithdrawals from './View/Modules/Users/UserWithdrawals';
import UserBalance from './View/Modules/Users/UserBalance';
import UserFcCoins from './View/Modules/Users/UserFcCoins';
import TeamDetails from './View/Modules/Teams/TeamDetails';
// import RecordMatch from "./View/Modules/RecordMatch/RecordMatch";
import Verify from './View/Modules/VerifyMatch/VerifyMatch';
import AddRefCode from './View/Modules/ReferalCode/AddRefCode';
import AddDiscountCode from './View/Modules/DiscountCodes/AddDiscountCode';
import RefCodeUser from './View/Modules/ReferalCode/RefCodeNewUsers';
import RefConvertedUsers from './View/Modules/ReferalCode/RefConvertedUsers';
import MoneyConverted from './View/Modules/ReferalCode/MoneyConverted';
import Admin from './View/Modules/Admin/Admin';
import AdminPath from './View/Modules/Admin/UrlConstants';
import AddAdmin from './View/Modules/Admin/AddAdmin';
import UserContestLost from './View/Modules/Users/UserContestLost';
import DiscountCodeTransactions from './View/Modules/DiscountCodes/DiscountCodeTransactions';
// import ChangeUserPassword from "./View/Modules/ChangePassword/CreateNewPasswordForMobile";
import Notification from './View/Modules/Notifications/AddNotification';
import NotificationPath from './View/Modules/Notifications/UrlConstants';
// import Reports from "./View/Modules/Reports/GenerateReports";
// import ReportsPath from "./View/Modules/Reports/UrlConstants";
import RewardUser from './View/Modules/RewardUser';
import RewardUserPath from './View/Modules/RewardUser/UrlConstants';

import TOGamePaths from './View/Modules/TOGame/UrlConstants';
import TOGames from './View/Modules/TOGame/TOGameList';
import CreateTOGame from './View/Modules/TOGame/AddTOGame';

import TOMatchModePaths from './View/Modules/TOMatchMode/UrlConstants';
import TOMatchModes from './View/Modules/TOMatchMode/TOMatchModeList';
import CreateTOMatchMode from './View/Modules/TOMatchMode/AddTOMatchMode';

import TOMatchAttributePaths from './View/Modules/TOMatchAttribute/UrlConstants';
import TOMatchAttributes from './View/Modules/TOMatchAttribute/TOMatchAttributeList';
import CreateTOMatchAttribute from './View/Modules/TOMatchAttribute/AddTOMatchAttribute';

import TOMatchTypePaths from './View/Modules/TOMatchType/UrlConstants';
import TOMatchTypes from './View/Modules/TOMatchType/TOMatchTypeList';
import CreateTOMatchType from './View/Modules/TOMatchType/AddTOMatchType';

import TOMatchPaths from './View/Modules/TOMatch/UrlConstants';
import TOMatches from './View/Modules/TOMatch/TOMatchList';
import CreateTOMatch from './View/Modules/TOMatch/AddTOMatch';
import RescheduleTOMatch from './View/Modules/TOMatch/RescheduleTOMatch';

import TOPublishedMatchPaths from './View/Modules/TOPublishedMatch/UrlConstants';
import TOPublishedMatches from './View/Modules/TOPublishedMatch/TOPublishedMatchList';
import EditTOPublishedMatch from './View/Modules/TOPublishedMatch/EditTOPublishedMatch';

import TOCompletedMatchPaths from './View/Modules/TOCompletedMatch/UrlConstants';
import TOCompletedMatches from './View/Modules/TOCompletedMatch/TOCompletedMatchList';
import EditTOCompletedMatch from './View/Modules/TOCompletedMatch/EditTOCompletedMatch';

import TOOrganizerPaths from './View/Modules/TOOrganizer/UrlConstants';
import TOOrganizers from './View/Modules/TOOrganizer/TOOrganizerList';
import CreateTOOrganizer from './View/Modules/TOOrganizer/AddTOOrganizer';
import UploadPlayers from './View/Modules/Players/UploadPlayers';
import UploadFantasyResults from './View/Modules/VerifyMatch/UploadFantasyResults';

import FantasyConfigPaths from './View/Modules/FantasyConfig/UrlConstants';
import ListFantasyScoringModels from './View/Modules/FantasyConfig/ListFantasyScoringModel';
import AddFantasyScoringModel from './View/Modules/FantasyConfig/AddFantasyScoringModel';

import InshortsPaths from './View/Modules/Inshorts/UrlConstants';
import UploadArticles from './View/Modules/Inshorts/UploadArticles';

// import TournamentPath from "./View/Modules/FantasyTournament/UrlConstants";

import BannersPath from './View/Modules/Banners/UrlConstants';
import Banners from './View/Modules/Banners/Banners';

import BoostersPath from './View/Modules/Boosters/UrlConstants';
import Boosters from './View/Modules/Boosters/Boosters';
import BoosterConfigs from './View/Modules/Boosters/BoosterConfigs';

import TDSPath from './View/Modules/TDSCertificates/UrlConstants';
import UserTDSCertificates from './View/Modules/TDSCertificates/UserTDSCertificates';

import UserKycPath from './View/Modules/UserKyc/UrlConstants';
import UserKyc from './View/Modules/UserKyc/UserKyc';
import UserBankKyc from './View/Modules/UserKyc/UserBankKyc';
import LoginDetails from './Components/LoginDetails';

import ToCalendarPath from './View/Modules/ToCalendar/UrlConstants';
import ToCalendar from './View/Modules/ToCalendar/ToCalendar';
import SeriesCalendar from './View/Modules/SeriesCalendar';

import DynamicPrizePoolPath from './View/Modules/DynamicPrizePool/UrlConstants';
import DynamicPrizePools from './View/Modules/DynamicPrizePool/DynamicPrizePools';
import Skeleton from './pages/Skeleton/Skeleton';
import AddEditMatch from './View/Modules/Matches/AddEditMatch/AddEditMatch';
// matches
import CommonMatches from './containers/Matches/CommonMatches/pages/CommonMatches';
import SwitchConfigurations from './containers/Switch/pages/SwitchConfigurations/SwitchConfigurations';

class View extends Component {
    render() {
        const {permissions} = this.props.login;
        const {
            admin,
            superAdmin,
            userReadOnly,
            userKycReadOnly,
            withdrawalReadOnly,
            referralReadOnly,
            referralWrite,
            discountReadOnly,
            discountWrite,
            notificationWrite,
            tdsReadOnly,
            rewardUserPermission,
            bannerReadOnly,
            verifyMatchReadOnly,
            uploadMatchResultPermission,
            playerReadOnly,
            playerWrite,
            squadReadOnly,
            squadWrite,
            matchReadOnly,
            matchWrite,
            matchAdvanceWrite,
            fcTournamentWrite,
            contestReadOnly,
            contestWrite,
            teamReadOnly,
            fantasyScoringReadOnly,
            fantasyScoringWrite,
            boosterReadOnly,
            articleWrite,
            toGameReadOnly,
            toGameWrite,
            toMatchModeReadOnly,
            toMatchModeWrite,
            toMatchReadOnly,
            toMatchWrite,
            toOrganizerReadOnly,
            toOrganizerWrite,
            dynamicPrizePoolReadOnly,
            navbarCalenderDropdown,
            matchesCreateMatchesButton,
            commonMatchesReadOnly,
            switchConfigReadOnly,
            otherSkeletonReadOnly,
            upcomingMatches
        } = permissions || {};

        return (
            <div className={'body ' + (this.props.location.pathname === '/toPublishedMatches' ? 'pt-138' : '')}>
                <LoginDetails />

                <Switch>
                    <Route path="/" exact component={Login} />
                    {/*<Route path={ChangePswdPath.ROUTES.RESETPASSWORD_PASSWORD} exact component={ResetPassword} />*/}
                    {/*<Route path={ChangePswdPath.ROUTES.RESETPASSWORD_USER_PASSWORD} exact
                        component={ChangeUserPassword} />*/}

                    {/* common matches routes */}
                    {!!commonMatchesReadOnly && <Route path="/matches/common" exact component={CommonMatches} />}

                    {/* matches routes */}
                    {!!matchWrite && !!matchesCreateMatchesButton && <Route path="/matches/create" exact component={AddEditMatch} />}
                    {!!matchWrite && !!matchesCreateMatchesButton && <Route path="/matches/update/:id" exact component={AddEditMatch} />}

                    {!!switchConfigReadOnly && <Route path="/switch-configurations" exact component={SwitchConfigurations} />}

                    {!!playerReadOnly && <Route path={PlayerPaths.ROUTES.LIST_PLAYERS} exact component={PlayersList} />}
                    {!!playerReadOnly && <Route path={PlayerPaths.ROUTES.EDIT_PLAYERS} exact component={AddPlayer} />}
                    {!!playerWrite && <Route path={PlayerPaths.ROUTES.ADD_PLAYERS} exact component={AddPlayer} />}
                    {!!playerWrite && (
                        <Route path={PlayerPaths.ROUTES.UPLOAD_PLAYERS} exact component={UploadPlayers} />
                    )}

                    {!!squadReadOnly && <Route path={SquadPaths.ROUTES.LIST_SQUAD} exact component={SquadList} />}
                    {!!squadReadOnly && <Route path={SquadPaths.ROUTES.EDIT_SQUAD} exact component={CreateSquad} />}
                    {!!squadWrite && <Route path={SquadPaths.ROUTES.ADD_SQUAD} exact component={CreateSquad} />}

                    {!!matchReadOnly && <Route path={MatchesPath.ROUTES.LIST_MATCHES} exact component={Matches} />}
                    {!!upcomingMatches && <Route path={MatchesPath.ROUTES.UPCOMING_MATCHES} exact component={UpcomingMatches} />}
                    {!!matchReadOnly && <Route path={MatchesPath.ROUTES.EDIT_MATCH} exact component={AddMatch} />}
                    {!!matchWrite && <Route path={MatchesPath.ROUTES.ADD_MATCH} exact component={AddMatch} />}
                    {!!fcTournamentWrite && (
                        <Route path={MatchesPath.ROUTES.ADD_TOURNAMENT_SCREEN} exact component={AddTournamentScreen} />
                    )}
                    {!!matchAdvanceWrite && (
                        <Route path={MatchesPath.ROUTES.RESCHEDULE_MATCH} exact component={RescheduleMatch} />
                    )}
                    {!!matchAdvanceWrite && (
                        <Route path={MatchesPath.ROUTES.CANCEL_MATCH} exact component={CancelMatch} />
                    )}
                    {!!matchAdvanceWrite && (
                        <Route path={MatchesPath.ROUTES.MATCH_OF_THE_DAY} exact component={MatchOfTheDay} />
                    )}

                    {!!articleWrite && (
                        <Route path={InshortsPaths.ROUTES.LIST_ARTICLES} exact component={UploadArticles} />
                    )}

                    {/* <Route path={RecordMatchPath.ROUTES.LIST_RECORDEDMATCH} exact component={RecordMatchList} /> */}
                    {/*<Route path={RecordMatchPath.ROUTES.RECORD_MATCH} exact component={RecordMatch} />*/}

                    {!!verifyMatchReadOnly && (
                        <Route path={VerifyMatchPath.ROUTES.LIST_VERIFIEDMATCH} exact component={VerifyMatch} />
                    )}
                    {!!verifyMatchReadOnly && (
                        <Route path={VerifyMatchPath.ROUTES.VERIFY_MATCH} exact component={Verify} />
                    )}
                    {!!uploadMatchResultPermission && (
                        <Route path="/uploadFantasyResults" exact component={UploadFantasyResults} />
                    )}

                    {!!contestReadOnly && (
                        <Route path={ContestTypePath.ROUTES.LIST_CONTEST_TYPES} exact component={ContestTypes} />
                    )}
                    {!!contestReadOnly && (
                        <Route path={ContestTypePath.ROUTES.EDIT_CONTEST_TYPE} exact component={AddContestType} />
                    )}
                    {!!contestWrite && (
                        <Route path={ContestTypePath.ROUTES.ADD_CONTEST_TYPE} exact component={AddContestType} />
                    )}
                    {!!contestReadOnly && <Route path={ContestPath.ROUTES.LIST_CONTESTS} exact component={Contests} />}
                    {!!contestReadOnly && <Route path={ContestPath.ROUTES.EDIT_CONTEST} exact component={AddContest} />}
                    {!!contestWrite && <Route path={ContestPath.ROUTES.ADD_CONTESTS} exact component={AddContest} />}

                    {!!teamReadOnly && <Route path={TeamsPath.ROUTES.LIST_TEAMS} exact component={Teams} />}
                    {!!teamReadOnly && <Route path={TeamsPath.ROUTES.TEAM_DETAILS} exact component={TeamDetails} />}

                    {!!userReadOnly && <Route path={UserPaths.ROUTES.LIST_USERS} exact component={Users} />}
                    {!!userReadOnly && (
                        <Route path={UserPaths.ROUTES.USER_MATCH_DETAILS} exact component={UserMatchDetails} />
                    )}
                    {!!userReadOnly && (
                        <Route path={UserPaths.ROUTES.USER_CONTEST_DETAILS} exact component={UserContestDetails} />
                    )}
                    {!!userReadOnly && (
                        <Route path={UserPaths.ROUTES.CONTEST_WON_DETAILS} exact component={UserContestWon} />
                    )}
                    {!!userReadOnly && (
                        <Route path={UserPaths.ROUTES.CONTEST_LOST_DETAILS} exact component={UserContestLost} />
                    )}
                    {!!userReadOnly && (
                        <Route path={UserPaths.ROUTES.USER_ENTRY_FEES} exact component={UserEntryFees} />
                    )}
                    {!!userReadOnly && <Route path={UserPaths.ROUTES.USER_WINNINGS} exact component={UserWinnings} />}
                    {!!userReadOnly && (
                        <Route path={UserPaths.ROUTES.USER_WITHDRAWALS} exact component={UserWithdrawals} />
                    )}
                    {!!userReadOnly && <Route path={UserPaths.ROUTES.USER_BALANCE} exact component={UserBalance} />}
                    {!!userReadOnly && <Route path={UserPaths.ROUTES.USER_FC_COINS} exact component={UserFcCoins} />}

                    {(!!superAdmin || !!admin) && <Route path={AdminPath.ROUTES.LIST_ADMINS} exact component={Admin} />}
                    {(!!superAdmin || !!admin) && <Route path={AdminPath.ROUTES.ADD_ADMIN} exact component={AddAdmin} />}
                    {(!!superAdmin || !!admin)  && <Route path={AdminPath.ROUTES.EDIT_ADMIN} exact component={AddAdmin} />}

                    {!!discountReadOnly && (
                        <Route path={DiscountCodePath.ROUTES.LIST_DISCOUNTCODES} exact component={DiscountCode} />
                    )}
                    {!!discountWrite && (
                        <Route path={DiscountCodePath.ROUTES.ADD_DISCOUNTCODE} exact component={AddDiscountCode} />
                    )}
                    {!!discountReadOnly && (
                        <Route path={DiscountCodePath.ROUTES.EDIT_DISCOUNTCODE} exact component={AddDiscountCode} />
                    )}
                    {!!discountReadOnly && (
                        <Route
                            path={DiscountCodePath.ROUTES.DISCOUNTCODE_TRANSACTIONS}
                            exact
                            component={DiscountCodeTransactions}
                        />
                    )}

                    {!!withdrawalReadOnly && (
                        <Route path={Withdrawalpath.ROUTES.LIST_WITHDRAWALS} exact component={Withdrawals} />
                    )}

                    {!!referralReadOnly && (
                        <Route path={ReferalCodePath.ROUTES.LIST_REFERALCODE} exact component={ReferalCode} />
                    )}
                    {!!referralReadOnly && (
                        <Route path={ReferalCodePath.ROUTES.EDIT_REFCODE} exact component={AddRefCode} />
                    )}
                    {!!referralWrite && (
                        <Route path={ReferalCodePath.ROUTES.ADD_REFCODE} exact component={AddRefCode} />
                    )}
                    {!!referralReadOnly && (
                        <Route path={ReferalCodePath.ROUTES.REFCODE_NEW_USERS} exact component={RefCodeUser} />
                    )}
                    {!!referralReadOnly && (
                        <Route path={ReferalCodePath.ROUTES.REF_CONVERTED_USERS} exact component={RefConvertedUsers} />
                    )}
                    {!!referralReadOnly && (
                        <Route path={ReferalCodePath.ROUTES.REF_MONEY_CONVERTED} exact component={MoneyConverted} />
                    )}

                    {!!notificationWrite && (
                        <Route path={NotificationPath.ROUTES.SEND_NOTIFICATION} exact component={Notification} />
                    )}

                    {!!rewardUserPermission && (
                        <Route path={RewardUserPath.ROUTES.REWARD_USER} exact component={RewardUser} />
                    )}

                    {!!fantasyScoringReadOnly && (
                        <Route
                            path={FantasyConfigPaths.ROUTES.LIST_FANTASY_SCORING_MODELS}
                            exact
                            component={ListFantasyScoringModels}
                        />
                    )}
                    {!!fantasyScoringWrite && (
                        <Route
                            path={FantasyConfigPaths.ROUTES.ADD_FANTASY_SCORING_MODEL}
                            exact
                            component={AddFantasyScoringModel}
                        />
                    )}
                    {!!fantasyScoringReadOnly && (
                        <Route
                            path={FantasyConfigPaths.ROUTES.EDIT_FANTASY_SCORING_MODEL}
                            exact
                            component={AddFantasyScoringModel}
                        />
                    )}

                    {!!toGameReadOnly && <Route path={TOGamePaths.ROUTES.LIST_GAME} exact component={TOGames} />}
                    {!!toGameReadOnly && <Route path={TOGamePaths.ROUTES.EDIT_GAME} exact component={CreateTOGame} />}
                    {!!toGameWrite && <Route path={TOGamePaths.ROUTES.ADD_GAME} exact component={CreateTOGame} />}

                    {!!toMatchModeReadOnly && (
                        <Route path={TOMatchModePaths.ROUTES.LIST_MATCHMODE} exact component={TOMatchModes} />
                    )}
                    {!!toMatchModeReadOnly && (
                        <Route path={TOMatchModePaths.ROUTES.EDIT_MATCHMODE} exact component={CreateTOMatchMode} />
                    )}
                    {!!toMatchModeWrite && (
                        <Route path={TOMatchModePaths.ROUTES.ADD_MATCHMODE} exact component={CreateTOMatchMode} />
                    )}

                    {!!toMatchReadOnly && (
                        <Route
                            path={TOMatchAttributePaths.ROUTES.LIST_MATCHATTRIBUTE}
                            exact
                            component={TOMatchAttributes}
                        />
                    )}
                    {!!toMatchReadOnly && (
                        <Route
                            path={TOMatchAttributePaths.ROUTES.EDIT_MATCHATTRIBUTE}
                            exact
                            component={CreateTOMatchAttribute}
                        />
                    )}
                    {!!toMatchWrite && (
                        <Route
                            path={TOMatchAttributePaths.ROUTES.ADD_MATCHATTRIBUTE}
                            exact
                            component={CreateTOMatchAttribute}
                        />
                    )}

                    {!!toMatchReadOnly && (
                        <Route path={TOMatchTypePaths.ROUTES.LIST_MATCHTYPE} exact component={TOMatchTypes} />
                    )}
                    {!!toMatchReadOnly && (
                        <Route path={TOMatchTypePaths.ROUTES.EDIT_MATCHTYPE} exact component={CreateTOMatchType} />
                    )}
                    {!!toMatchWrite && (
                        <Route path={TOMatchTypePaths.ROUTES.ADD_MATCHTYPE} exact component={CreateTOMatchType} />
                    )}

                    {!!toMatchReadOnly && <Route path={TOMatchPaths.ROUTES.LIST_MATCH} exact component={TOMatches} />}
                    {!!toMatchReadOnly && (
                        <Route path={TOMatchPaths.ROUTES.EDIT_MATCH} exact component={CreateTOMatch} />
                    )}
                    {!!toMatchWrite && <Route path={TOMatchPaths.ROUTES.ADD_MATCH} exact component={CreateTOMatch} />}
                    {!!toMatchWrite && (
                        <Route path={TOMatchPaths.ROUTES.RESCHEDULE_TO_MATCH} exact component={RescheduleTOMatch} />
                    )}

                    {!!toMatchReadOnly && (
                        <Route path={TOPublishedMatchPaths.ROUTES.LIST_MATCH} exact component={TOPublishedMatches} />
                    )}
                    {!!toMatchReadOnly && (
                        <Route path={TOPublishedMatchPaths.ROUTES.EDIT_MATCH} exact component={EditTOPublishedMatch} />
                    )}

                    {!!toMatchReadOnly && (
                        <Route path={TOCompletedMatchPaths.ROUTES.LIST_MATCH} exact component={TOCompletedMatches} />
                    )}
                    {!!toMatchReadOnly && (
                        <Route path={TOCompletedMatchPaths.ROUTES.EDIT_MATCH} exact component={EditTOCompletedMatch} />
                    )}

                    {!!toOrganizerReadOnly && (
                        <Route path={TOOrganizerPaths.ROUTES.LIST_ORGANIZER} exact component={TOOrganizers} />
                    )}
                    {!!toOrganizerReadOnly && (
                        <Route path={TOOrganizerPaths.ROUTES.EDIT_ORGANIZER} exact component={CreateTOOrganizer} />
                    )}
                    {!!toOrganizerWrite && (
                        <Route path={TOOrganizerPaths.ROUTES.ADD_ORGANIZER} exact component={CreateTOOrganizer} />
                    )}

                    {!!bannerReadOnly && <Route path={BannersPath.ROUTES.LIST_BANNERS} exact component={Banners} />}

                    {!!boosterReadOnly && <Route path={BoostersPath.ROUTES.BOOSTERS} exact component={Boosters} />}
                    {!!boosterReadOnly && (
                        <Route path={BoostersPath.ROUTES.BOOSTER_CONFIGS} exact component={BoosterConfigs} />
                    )}

                    {!!tdsReadOnly && (
                        <Route path={TDSPath.ROUTES.TDS_CERTIFICATES} exact component={UserTDSCertificates} />
                    )}

                    {!!userKycReadOnly && <Route path={UserKycPath.ROUTES.USER_KYC} exact component={UserKyc} />}
                    {!!userKycReadOnly && (
                        <Route path={UserKycPath.ROUTES.USER_BANK_KYC} exact component={UserBankKyc} />
                    )}

                    {!!navbarCalenderDropdown && <Route path={ToCalendarPath.ROUTES.CALENDAR_TO} exact component={ToCalendar} />}
                    {!!navbarCalenderDropdown && <Route path={ToCalendarPath.ROUTES.CALENDAR_SERIES} exact component={SeriesCalendar} />}

                    {!!otherSkeletonReadOnly && <Route path="/skeleton" exact component={Skeleton} />}

                    {!!dynamicPrizePoolReadOnly && (
                        <Route path={DynamicPrizePoolPath.ROUTES.LIST} exact component={DynamicPrizePools} />
                    )}

                    {/*Redirect to default page if router not match*/}
                    <Route render={() => <Redirect to={MatchesPath.ROUTES.LIST_MATCHES} />} />
                </Switch>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
    };
};
export default connect(mapStateToProps, {})(withRouter(View));
