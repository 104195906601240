import {
    GET_DYNAMIC_PRIZE_POOLS,
    GET_DYNAMIC_PRIZE_POOLS_SUCCESS,
    GET_DYNAMIC_PRIZE_POOLS_FAIL,
    GET_DYNAMIC_PRIZE_POOL,
    GET_DYNAMIC_PRIZE_POOL_SUCCESS,
    GET_DYNAMIC_PRIZE_POOL_FAIL,
    CREATE_DYNAMIC_PRIZE_POOL,
    CREATE_DYNAMIC_PRIZE_POOL_SUCCESS,
    CREATE_DYNAMIC_PRIZE_POOL_FAIL,
    UPDATE_DYNAMIC_PRIZE_POOL,
    UPDATE_DYNAMIC_PRIZE_POOL_SUCCESS,
    UPDATE_DYNAMIC_PRIZE_POOL_FAIL,
} from './constants';

const initialState = {
    list: {},
    isFetchingDynamicPrizePools: false,
    isFetchingDynamicPrizePoolsSuccess: false,
    isFetchingDynamicPrizePoolsFail: false,

    details: {},
    isFetchingDynamicPrizePool: false,
    isFetchingDynamicPrizePoolSuccess: false,
    isFetchingDynamicPrizePoolFail: false,

    isCreatingDynamicPrizePool: false,
    isCreatingDynamicPrizePoolSuccess: false,
    isCreatingDynamicPrizePoolFail: false,

    isUpdatingDynamicPrizePool: false,
    isUpdatingDynamicPrizePoolSuccess: false,
    isUpdatingDynamicPrizePoolFail: false,

    message: '',
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_DYNAMIC_PRIZE_POOLS:
            return {
                ...state,
                isFetchingDynamicPrizePools: true,
                isFetchingDynamicPrizePoolsSuccess: false,
                isFetchingDynamicPrizePoolsFail: false,
                list: {},
                message: '',
            };

        case GET_DYNAMIC_PRIZE_POOLS_SUCCESS:
            return {
                ...state,
                isFetchingDynamicPrizePools: false,
                isFetchingDynamicPrizePoolsSuccess: true,
                isFetchingDynamicPrizePoolsFail: false,
                list: action?.payload?.data || {},
                message: action?.payload,
            };

        case GET_DYNAMIC_PRIZE_POOLS_FAIL:
            return {
                ...state,
                isFetchingDynamicPrizePools: false,
                isFetchingDynamicPrizePoolsSuccess: false,
                isFetchingDynamicPrizePoolsFail: true,
                message: action?.payload,
            };

        case GET_DYNAMIC_PRIZE_POOL:
            return {
                ...state,
                isFetchingDynamicPrizePool: true,
                isFetchingDynamicPrizePoolSuccess: false,
                isFetchingDynamicPrizePoolFail: false,
                details: {},
                message: '',
            };

        case GET_DYNAMIC_PRIZE_POOL_SUCCESS:
            return {
                ...state,
                isFetchingDynamicPrizePool: false,
                isFetchingDynamicPrizePoolSuccess: true,
                isFetchingDynamicPrizePoolFail: false,
                details: action?.payload?.data || {},
                message: action?.payload,
            };

        case GET_DYNAMIC_PRIZE_POOL_FAIL:
            return {
                ...state,
                isFetchingDynamicPrizePool: false,
                isFetchingDynamicPrizePoolSuccess: false,
                isFetchingDynamicPrizePoolFail: true,
                message: action?.payload,
            };

        case CREATE_DYNAMIC_PRIZE_POOL:
            return {
                ...state,
                isCreatingDynamicPrizePool: true,
                isCreatingDynamicPrizePoolSuccess: false,
                isCreatingDynamicPrizePoolFail: false,
                message: '',
            };

        case CREATE_DYNAMIC_PRIZE_POOL_SUCCESS:
            return {
                ...state,
                isCreatingDynamicPrizePool: false,
                isCreatingDynamicPrizePoolSuccess: true,
                isCreatingDynamicPrizePoolFail: false,
                message: action?.payload,
            };

        case CREATE_DYNAMIC_PRIZE_POOL_FAIL:
            return {
                ...state,
                isCreatingDynamicPrizePool: false,
                isCreatingDynamicPrizePoolSuccess: false,
                isCreatingDynamicPrizePoolFail: true,
                message: action?.payload,
            };

        case UPDATE_DYNAMIC_PRIZE_POOL:
            return {
                ...state,
                isUpdatingDynamicPrizePool: true,
                isUpdatingDynamicPrizePoolSuccess: false,
                isUpdatingDynamicPrizePoolFail: false,
                message: '',
            };

        case UPDATE_DYNAMIC_PRIZE_POOL_SUCCESS:
            return {
                ...state,
                isUpdatingDynamicPrizePool: false,
                isUpdatingDynamicPrizePoolSuccess: true,
                isUpdatingDynamicPrizePoolFail: false,
                message: action?.payload,
            };

        case UPDATE_DYNAMIC_PRIZE_POOL_FAIL:
            return {
                ...state,
                isUpdatingDynamicPrizePool: false,
                isUpdatingDynamicPrizePoolSuccess: false,
                isUpdatingDynamicPrizePoolFail: true,
                message: action?.payload,
            };

        default:
            return state;
    }
};

export default reducer;
