import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

import DatePicker from 'react-datepicker';
import moment from 'moment';
import Button from '../Button/Button';

const DateButton = ({value, onClick, startDate, endDate, label}) => {
    return (
        <Button onClick={onClick}>
            {!!startDate && !!endDate
                ? moment(startDate).format('DD-MM-YYYY') + ' - ' + moment(endDate).format('DD-MM-YYYY')
                : label}
        </Button>
    );
};

class DateRangeSelect extends PureComponent {
    static propTypes = {
        label: PropTypes.string,
        endDate: PropTypes.any,
        startDate: PropTypes.any,
        onChange: PropTypes.func,
        isClearable: PropTypes.bool,
    };

    static defaultProps = {
        label: 'Select a date range',
        isClearable: false,
    };

    constructor(props) {
        super(props);
        const {startDate, endDate} = props;
        this.state = {
            start: startDate,
            end: endDate,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {startDate, endDate} = this.props;
        let start = moment(startDate).format('DD-MM-YYYY');
        let prevStart = moment(prevProps.startDate).format('DD-MM-YYYY');
        let end = moment(endDate).format('DD-MM-YYYY');
        let prevEnd = moment(prevProps.endDate).format('DD-MM-YYYY');
        if (start !== prevStart || end !== prevEnd) {
            this.setState({start: startDate, end: endDate});
        }
    }

    handleChangeDate = (dates) => {
        let {onChange} = this.props;
        let start = null,
            end = null;

        if (dates) {
            [start, end] = dates;
            start = start ? start.valueOf() : null;
            end = end ? moment(end).set({hour: 23, minute: 59, second: 59}).valueOf() : null;
        }

        this.setState({start, end});
        if ((!!start && !!end) || !dates) {
            onChange({start, end});
        }
    };

    render() {
        let {startDate, endDate, isClearable, label} = this.props;
        let {start, end} = this.state;
        const RenderDateButton = React.forwardRef((props, ref) => <DateButton {...props} innerRef={ref} />);

        return (
            <div>
                <DatePicker
                    showYearDropdown
                    showMonthDropdown
                    className="form-control black"
                    placeholderText="Select Date"
                    selected={start}
                    onChange={this.handleChangeDate}
                    startDate={start}
                    endDate={end}
                    shouldCloseOnSelect={false}
                    selectsRange
                    dateFormat="dd MM yyyy"
                    isClearable={isClearable}
                    customInput={<RenderDateButton startDate={start} endDate={end} label={label} />}
                />
            </div>
        );
    }
}

export default DateRangeSelect;
