import React from 'react';
import '../../../Utilities/style.css';
import '../Login/login.css';
import {connect} from 'react-redux';
import DetailLayout from '../../Layouts/DetailLayout/DetailLayout';
import Card from '../../../Components/Card/Card';
import Input from '../../../Components/Input/Input';
import DropDown from '../../../Components/Dropdown/Dropdown';
import {createTOMatchType, updateTOMatchType, deleteTOMatchType} from './AxioCalls';
import {getTOGameList} from '../TOGame/AxioCalls';
import {getTOMatchModeList} from '../TOMatchMode/AxioCalls';
import {getTOMatchAttributeList} from '../TOMatchAttribute/AxioCalls';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Snackbar from '../../../Components/Snackbar/Snackbar';
import {logout} from '../Login/LoginAction';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';
import {isValidNumber, isNumber} from '../../../Utilities/Helpers';

class AddTOMatchType extends React.Component {
    constructor(props) {
        super(props);
        let location = this.props.history.location.pathname.includes('edit');
        this.state = {
            typeName: null,
            entryFeeTypeOptions: [
                {
                    text: 'Cash Only',
                    value: 'Cash Only',
                },
                {
                    text: 'Free',
                    value: 'Free',
                },
                {
                    text: 'Cash+Coin',
                    value: 'Cash+Coin',
                },
                {
                    text: 'FcCoin',
                    value: 'FcCoin',
                },
            ],
            entryFeeType: null,
            fcCoinDiscountPercent: 0,
            minParticipationRequiredOptions: [
                {
                    text: 'True',
                    value: true,
                },
                {
                    text: 'False',
                    value: false,
                },
            ],
            minParticipationRequired: false,
            winDenomination: null,
            winDenominationOptions: [
                {text: 'Cash', value: 'cash'},
                {text: 'Coin', value: 'fcCoin'},
                {text: 'Cash Balance', value: 'cashBalance'},
            ],
            winType: null,
            winTypeOptions: [
                {
                    text: 'Fixed',
                    value: 'Fixed',
                },
                {
                    text: 'Distribution',
                    value: 'Distribution',
                },
            ],
            numberOfBigWinTeams: 0,
            winSlots: {},
            winningDistribution: {},
            handlingCharge: null,
            winParameter: null,
            winParameterValue: 0,
            attribute1Options: [],
            attribute1: null,
            attribute1Val: null,
            attribute2Options: [],
            attribute2: null,
            attribute2Val: null,
            matchMode: null,
            matchModeOptions: [],
            matchRules: null,
            statusOptions: [
                {
                    text: 'ACTIVE',
                    value: 'ACTIVE',
                },
                {
                    text: 'INACTIVE',
                    value: 'INACTIVE',
                },
            ],
            status: 'ACTIVE',
            gameOptions: [],
            game: null,
            loading: false,
            open: false,
            apiError: '',
            edit: location,
            snackbarOpen: false,
        };
    }

    handleInputChange(field, value) {
        const {login} = this.props;
        this.setState({
            [field]: value,
            snackbarOpen: false,
            errorMessage: '',
        });
        if (field === 'game') {
            this.setState({
                loading: true,
                attribute1: null,
                attribute1Val: null,
                matchMode: null,
                attribute2: null,
                attribute2Val: null,
            });
            this.props.getTOMatchModeList({listType: 'ALL'}, login.userDetails.data.accessToken);
            this.props.getTOMatchAttributeList({listType: 'ALL'}, login.userDetails.data.accessToken);
        }
        if (field === 'entryFeeType') {
            if (value === 'Free' || value === 'FcCoin') {
                this.setState({winDenomination: 'fcCoin'});
            } else if (value === 'Cash+Coin' || value === 'Cash Only') {
                this.setState({winDenomination: 'cash'});
            }
        }
        if (field === 'winType') {
            if (value === 'Fixed') {
                this.setState({
                    winSlots: {handlingCharge: null, ranks: {}, splitRemaining: null, smallWinSlots: null},
                    numberOfBigWinTeams: 0,
                });
                if (this.state.edit) {
                    this.setState({
                        winParameter: this.props.toMatchType.editTOMatchType.winParameter,
                        winParameterValue: this.props.toMatchType.editTOMatchType.winParameterValue,
                    });
                } else {
                    this.setState({
                        winParameter: '',
                        winParameterValue: 0,
                    });
                }
            } else if (value === 'Distribution') {
                this.setState({
                    winParameter: '',
                    winParameterValue: 0,
                });
                if (this.state.edit) {
                    this.setState({
                        winSlots: this.props.toMatchType.editTOMatchType.winSlots || {
                            handlingCharge: null,
                            ranks: {},
                            splitRemaining: null,
                            smallWinSlots: null,
                        },
                        numberOfBigWinTeams: this.props.toMatchType.editTOMatchType.numberOfBigWinTeams,
                    });
                } else {
                    this.setState({
                        winSlots: {handlingCharge: null, ranks: {}, splitRemaining: null, smallWinSlots: null},
                        numberOfBigWinTeams: 0,
                    });
                }
            }
        }
        if (field === 'numberOfBigWinTeams') {
            let ranks = {};
            let mainShare = 0;
            for (let i = 1; i <= value; i++) {
                ranks['rank' + i] = this.state.winSlots.ranks['rank' + i];
                mainShare += parseInt(this.state.winSlots.ranks['rank' + i], 10);
            }
            mainShare += parseInt(this.state.winSlots.handlingCharge, 10);
            this.setState({
                winSlots: {...this.state.winSlots, ranks: ranks, splitRemaining: 100 - mainShare},
            });
        }
        if (field.includes('rank')) {
            let ranks = this.state.winSlots.ranks;
            ranks[field] = value;
            let mainShare = parseInt(this.state.winSlots.handlingCharge, 10) || 0;
            for (let rank in ranks) {
                mainShare += parseInt(ranks[rank], 10) || 0;
            }
            this.setState({
                winSlots: {...this.state.winSlots, ranks: ranks, splitRemaining: 100 - mainShare},
                errorMessage: '',
                snackbarOpen: false,
            });
        }
        if (field === 'handlingCharge') {
            let ranks = this.state.winSlots.ranks;
            let mainShare = 0;
            for (let rank in ranks) {
                mainShare += parseInt(ranks[rank], 10);
            }
            this.setState({
                winSlots: {
                    ...this.state.winSlots,
                    handlingCharge: value,
                    splitRemaining: 100 - mainShare - parseInt(value, 10),
                },
            });
        }
        if (field === 'smallWinSlots') {
            this.setState({
                winSlots: {...this.state.winSlots, smallWinSlots: value},
            });
        }
        if (field === 'matchRules') {
            this.setState({[field]: value.target.value});
        }
    }

    displayPrizes(prizes) {
        const {edit, winType, winSlots} = this.state;
        if (winType === 'Fixed') {
            return null;
        }
        let inputs = [];
        for (let i = 1; i <= prizes; i++) {
            let unit = '';
            if (i === 1) {
                unit = i + 'st';
            } else if (i === 2) {
                unit = i + 'nd';
            } else if (i === 3) {
                unit = i + 'rd';
            } else {
                unit = i + 'th';
            }
            let val = 'rank' + i;

            inputs.push(
                <div className="grid-item margin-top-20">
                    <Input
                        label={unit}
                        type="text"
                        name={val}
                        suffix="%"
                        value={winSlots.ranks[val]}
                        onChange={this.handleInputChange.bind(this, val)}
                        placeholder="Enter"
                        disabled={false}
                    />
                </div>,
            );
        }
        return inputs;
    }

    onSave() {
        const {
            typeName,
            entryFeeType,
            fcCoinDiscountPercent,
            minParticipationRequired,
            numberOfBigWinTeams,
            winDenomination,
            winType,
            winParameter,
            winParameterValue,
            winSlots,
            winningDistribution,
            attribute1,
            attribute1Val,
            attribute2,
            attribute2Val,
            matchRules,
            matchMode,
            status,
            game,
            edit,
        } = this.state;
        const {login, toMatchType} = this.props;
        let error = false;
        if (typeName === null || typeName === '' || typeName === undefined) {
            error = true;
            this.setState({
                snackbarOpen: true,
                errorMessage: 'please enter type name',
            });
        } else if (entryFeeType === null || entryFeeType === undefined || entryFeeType === '') {
            error = true;
            this.setState({
                snackbarOpen: true,
                errorMessage: 'please select valid Entry Fee Type',
            });
        } else if (
            entryFeeType === 'Cash+Coin' &&
            (fcCoinDiscountPercent === null ||
                fcCoinDiscountPercent === undefined ||
                fcCoinDiscountPercent === '' ||
                !isNumber(fcCoinDiscountPercent) ||
                fcCoinDiscountPercent <= 0)
        ) {
            error = true;
            this.setState({
                snackbarOpen: true,
                errorMessage: 'please enter valid Coin Discount',
            });
        } else if (
            minParticipationRequired === null ||
            minParticipationRequired === undefined ||
            minParticipationRequired === ''
        ) {
            error = true;
            this.setState({
                snackbarOpen: true,
                errorMessage: 'please select valid minimum participation requirement',
            });
        } else if (winType === null || winType === undefined || winType === '') {
            error = true;
            this.setState({
                snackbarOpen: true,
                errorMessage: 'please select valid Win Type',
            });
        } else if (
            winType === 'Distribution' &&
            (numberOfBigWinTeams === null ||
                numberOfBigWinTeams === undefined ||
                numberOfBigWinTeams === '' ||
                !isValidNumber(numberOfBigWinTeams) ||
                numberOfBigWinTeams <= 0)
        ) {
            error = true;
            this.setState({
                snackbarOpen: true,
                errorMessage: 'please enter valid number of Big Win Teams',
            });
        } else if (
            winType === 'Distribution' &&
            (winSlots === null ||
                winSlots === undefined ||
                Object.keys(winSlots).length === 0 ||
                !winSlots.hasOwnProperty('handlingCharge') ||
                !winSlots.hasOwnProperty('ranks') ||
                !winSlots.hasOwnProperty('splitRemaining') ||
                !winSlots.hasOwnProperty('smallWinSlots'))
        ) {
            error = true;
            this.setState({
                snackbarOpen: true,
                errorMessage: 'please recheck your win amount distribution',
            });
        } else if (
            winType === 'Fixed' &&
            (winParameter === null || winParameter === undefined || winParameter === '')
        ) {
            error = true;
            this.setState({
                snackbarOpen: true,
                errorMessage: 'please recheck valid win parameter',
            });
        } else if (
            winType === 'Fixed' &&
            (winParameterValue === null ||
                winParameterValue === undefined ||
                winParameterValue === '' ||
                !isNumber(winParameterValue) ||
                winParameterValue <= 0)
        ) {
            error = true;
            this.setState({
                snackbarOpen: true,
                errorMessage: 'please enter reward per win parameter',
            });
        } else if (winDenomination === null || winDenomination === undefined || winDenomination === '') {
            error = true;
            this.setState({
                snackbarOpen: true,
                errorMessage: 'please select valid win reward denomination',
            });
        } else if (attribute1 === null || attribute1 === undefined || attribute1 === '') {
            error = true;
            this.setState({
                snackbarOpen: true,
                errorMessage: 'please select valid attribute 1',
            });
        } else if (attribute1Val === null || attribute1Val === undefined || attribute1Val === '') {
            error = true;
            this.setState({
                snackbarOpen: true,
                errorMessage: 'please enter valid attribute 1 value',
            });
        } else if (attribute2 === null || attribute2 === undefined || attribute2 === '') {
            error = true;
            this.setState({
                snackbarOpen: true,
                errorMessage: 'please select valid attribute 2',
            });
        } else if (attribute2Val === null || attribute2Val === undefined || attribute2Val === '') {
            error = true;
            this.setState({
                snackbarOpen: true,
                errorMessage: 'please enter valid attribute 2 value',
            });
        } else if (matchMode === null || matchMode === undefined || matchMode === '') {
            error = true;
            this.setState({
                snackbarOpen: true,
                errorMessage: 'please select valid Match Mode',
            });
        } else if (matchRules === null || matchRules === undefined || matchRules === '') {
            error = true;
            this.setState({
                snackbarOpen: true,
                errorMessage: 'please enter valid Match Rules',
            });
        } else if (status === null || status === undefined || status === '') {
            error = true;
            this.setState({
                snackbarOpen: true,
                errorMessage: 'please select valid status',
            });
        } else if (game === null || game === undefined || game === '') {
            error = true;
            this.setState({
                snackbarOpen: true,
                errorMessage: 'please select valid game',
            });
        }
        let distribution = {};
        console.log('Error state is ' + error);
        if (error === false) {
            if (winType === 'Distribution') {
                for (var i = 1; i <= numberOfBigWinTeams; i++) {
                    if (
                        winSlots.ranks['rank' + i] === undefined ||
                        winSlots.ranks['rank' + i] === null ||
                        winSlots.ranks['rank' + i] === '' ||
                        !isValidNumber(winSlots.ranks['rank' + i]) ||
                        winSlots.ranks['rank' + i] <= 0 ||
                        winSlots.ranks['rank' + i] > 100
                    ) {
                        error = true;
                        this.setState({
                            snackbarOpen: true,
                            errorMessage: 'Please check Rank ' + i + ' pool distribution',
                        });
                        return;
                    }
                    distribution[i.toString()] = Math.round(winSlots.ranks['rank' + i] * 10) / 10;
                }
                if (
                    winSlots.splitRemaining === undefined ||
                    winSlots.splitRemaining === null ||
                    winSlots.splitRemaining === '' ||
                    !isValidNumber(winSlots.splitRemaining) ||
                    winSlots.splitRemaining < 0 ||
                    winSlots.splitRemaining > 100
                ) {
                    error = true;
                    this.setState({
                        snackbarOpen: true,
                        errorMessage: 'Please check your Pool distribution',
                    });
                    return;
                }
                if (winSlots.smallWinSlots === undefined || winSlots.smallWinSlots === null) {
                    if (winSlots.splitRemaining > 0) {
                        if (
                            winSlots.smallWinSlots === '' ||
                            !isValidNumber(winSlots.smallWinSlots) ||
                            winSlots.smallWinSlots <= 0
                        ) {
                            error = true;
                            this.setState({
                                snackbarOpen: true,
                                errorMessage: 'Invalid number of small win slots',
                            });
                            return;
                        }
                    } else if (winSlots.splitRemaining === 0) {
                        this.setState({winSlots: {...winSlots, smallWinSlots: 0}});
                    }
                }
                for (
                    var i = Number(numberOfBigWinTeams) + 1;
                    i <= Number(numberOfBigWinTeams) + Number(winSlots.smallWinSlots);
                    i++
                ) {
                    distribution[i] = Math.round((winSlots.splitRemaining / winSlots.smallWinSlots) * 10) / 10;
                }
            }
        }
        console.log('Error state is ' + error);
        if (error === false) {
            let matchType = {
                name: typeName,
                entryFeeType: entryFeeType,
                fcCoinDiscountPercent: fcCoinDiscountPercent,
                minParticipationRequired: minParticipationRequired,
                winDenomination: winDenomination,
                winType: winType,
                numberOfBigWinTeams: numberOfBigWinTeams,
                winSlots: winSlots,
                winningDistribution: distribution,
                winParameter: winParameter,
                winParameterValue: winParameterValue,
                attribute1: attribute1,
                attribute1Val: attribute1Val,
                attribute2: attribute2,
                attribute2Val: attribute2Val,
                matchMode: matchMode,
                matchRules: matchRules,
                status: status,
                game: game,
            };
            this.setState({loading: true});
            console.log('Trying to update the data in the server');
            if (edit) {
                matchType.id = toMatchType.editTOMatchType.id;
                this.props.updateTOMatchType(matchType, login.userDetails.data.accessToken);
            } else {
                this.props.createTOMatchType(matchType, login.userDetails.data.accessToken);
            }
        }
    }

    componentDidMount() {
        const {login, toMatchType, history} = this.props;
        let gamePageOptions = {
            listType: 'ALL',
        };
        if (!login.userDetails.data) {
            history.push('/');
            this.props.logout();
        } else {
            this.setState({
                loading: true,
            });
            this.props.getTOGameList(gamePageOptions, login.userDetails.data.accessToken);
            if (this.state.edit) {
                const {
                    name,
                    entryFeeType,
                    fcCoinDiscountPercent,
                    minParticipationRequired,
                    numberOfBigWinTeams,
                    winDenomination,
                    winType,
                    winParameter,
                    winParameterValue,
                    winningDistribution,
                    winSlots,
                    attribute1,
                    attribute1Val,
                    attribute2,
                    attribute2Val,
                    matchRules,
                    matchMode,
                    status,
                    game,
                } = toMatchType.editTOMatchType;
                this.props.getTOMatchModeList({listType: 'ALL'}, login.userDetails.data.accessToken);
                this.props.getTOMatchAttributeList({listType: 'ALL'}, login.userDetails.data.accessToken);
                this.setState({
                    typeName: name,
                    entryFeeType: entryFeeType,
                    fcCoinDiscountPercent: fcCoinDiscountPercent,
                    minParticipationRequired: minParticipationRequired,
                    numberOfBigWinTeams: numberOfBigWinTeams,
                    winDenomination: winDenomination,
                    winType: winType,
                    winParameter: winParameter,
                    winParameterValue: winParameterValue,
                    winningDistribution: winningDistribution,
                    winSlots: winSlots,
                    attribute1: attribute1.id,
                    attribute1Val: attribute1Val,
                    attribute2: attribute2.id,
                    attribute2Val: attribute2Val,
                    matchRules: matchRules,
                    matchMode: matchMode.id,
                    status: status,
                    game: game.id,
                });
            }
        }
    }

    componentDidUpdate(prevProps) {
        const {toMatchType, toGame, toMatchMode, toMatchAttribute, history} = this.props;
        const {game} = this.state;

        if (toGame.TOGameListSuccess && !prevProps.toGame.TOGameListSuccess) {
            this.setState({
                gameOptions: toGame.TOGameList.data.data.map((item) => {
                    return {text: item.gameName, value: item.id};
                }),
                loading: false,
            });
        } else if (toGame.TOGameListFail && !prevProps.toGame.TOGameListFail) {
            this.setState({
                errorMessage: toGame.errorMessage,
                loading: false,
            });
        }

        if (toMatchMode.TOMatchModeListSuccess && !prevProps.toMatchMode.TOMatchModeListSuccess) {
            this.setState({
                matchModeOptions: toMatchMode.TOMatchModeList.data.data
                    .filter((item) => item.game === game)
                    .map((item) => {
                        return {text: item.modeName, value: item.id};
                    }),
            });
            if (this.state.edit) {
                this.setState({
                    matchMode: this.props.toMatchType.editTOMatchType.matchMode.id,
                });
            }
            if (toMatchAttribute.TOMatchAttributeListSuccess) {
                this.setState({loading: false});
            }
        } else if (toMatchMode.TOMatchModeListFail && !prevProps.toMatchMode.TOMatchModeListFail) {
            this.setState({
                errorMessage: toMatchMode.errorMessage,
                loading: false,
            });
        }

        if (toMatchAttribute.TOMatchAttributeListSuccess && !prevProps.toMatchAttribute.TOMatchAttributeListSuccess) {
            this.setState({
                attribute1Options: toMatchAttribute.TOMatchAttributeList.data.data
                    .filter((item) => item.game === game)
                    .map((item) => {
                        return {text: item.name, value: item.id};
                    }),
                attribute2Options: toMatchAttribute.TOMatchAttributeList.data.data
                    .filter((item) => item.game === game)
                    .map((item) => {
                        return {text: item.name, value: item.id};
                    }),
            });
            if (this.state.edit) {
                this.setState({
                    attribute1: this.props.toMatchType.editTOMatchType.attribute1.id,
                    attribute2: this.props.toMatchType.editTOMatchType.attribute2.id,
                });
            }
            if (toMatchMode.TOMatchModeListSuccess) {
                this.setState({loading: false});
            }
        } else if (toMatchAttribute.TOMatchAttributeListFail && !prevProps.toMatchAttribute.TOMatchAttributeListFail) {
            this.setState({
                errorMessage: toMatchAttribute.errorMessage,
                loading: false,
            });
        }

        if (toMatchType.addTOMatchTypeSuccess && !prevProps.toMatchType.addTOMatchTypeSuccess) {
            history.push('/toMatchTypes');
        } else if (toMatchType.addTOMatchTypeFail && !prevProps.toMatchType.addTOMatchTypeFail) {
            this.setState({
                loading: false,
                snackbarOpen: true,
                errorMessage: toMatchType.errorMessage,
            });
        }

        if (toMatchType.updateTOMatchTypeSuccess && !prevProps.toMatchType.updateTOMatchTypeSuccess) {
            history.push('/toMatchTypes');
        } else if (toMatchType.updateTOMatchTypeFail && !prevProps.toMatchType.updateTOMatchTypeFail) {
            this.setState({
                loading: false,
                snackbarOpen: true,
                errorMessage: toMatchType.errorMessage,
            });
        }
    }

    render() {
        const {
            typeName,
            entryFeeType,
            entryFeeTypeOptions,
            fcCoinDiscountPercent,
            minParticipationRequired,
            minParticipationRequiredOptions,
            numberOfBigWinTeams,
            winDenomination,
            winDenominationOptions,
            winType,
            winTypeOptions,
            winParameter,
            winParameterValue,
            winSlots,
            attribute1,
            attribute1Options,
            attribute1Val,
            attribute2,
            attribute2Options,
            attribute2Val,
            matchMode,
            matchModeOptions,
            matchRules,
            status,
            statusOptions,
            game,
            gameOptions,
            handlingCharge,
            errorMessage,
            loading,
            open,
            edit,
            snackbarOpen,
        } = this.state;

        const {history, toMatchType, login} = this.props;
        let title = edit ? 'Edit Match Type' : 'Add Match Type';
        let shrink = undefined;
        const {toMatchWrite} = login?.permissions || {};
        return (
            <DetailLayout
                location="AddTOMatchType"
                history={history}
                centerTitle={title}
                onSave={!!toMatchWrite ? this.onSave.bind(this) : undefined}
                editPage={edit}
                id={edit ? toMatchType.editTOMatchType.id : null}
            >
                <LoadingModal open={loading} />
                <Snackbar
                    open={snackbarOpen}
                    message={errorMessage}
                    onClose={() => this.setState({snackbarOpen: false})}
                />
                <div className="detail-container margin-top-20">
                    <div className="grid-item"></div>

                    <div className="grid-item">
                        <Card>
                            <div>
                                <div className="card-grid-container">
                                    {/* row 1 */}
                                    <div className="grid-item  margin-top-20">
                                        <DropDown
                                            label="Entry Type"
                                            type="text"
                                            menuItems={entryFeeTypeOptions}
                                            name="entryFeeType"
                                            value={entryFeeType}
                                            onChange={this.handleInputChange.bind(this, 'entryFeeType')}
                                            placeholder="Select"
                                            disabled={false}
                                        />
                                    </div>

                                    {entryFeeType === 'Cash+Coin' ? (
                                        <div className="grid-item margin-top-20 align-right">
                                            <Input
                                                label="% FC Coin Discount"
                                                type="text"
                                                name="fcCoinDiscountPercent"
                                                value={fcCoinDiscountPercent}
                                                onChange={this.handleInputChange.bind(this, 'fcCoinDiscountPercent')}
                                                placeholder="% FC Coin Discount"
                                                suffix="%"
                                                disabled={false}
                                            />
                                        </div>
                                    ) : (
                                        <div className="grid-item margin-top-20 align-right"></div>
                                    )}

                                    {/* row 2 */}
                                    <div className="grid-item margin-top-20 align-right">
                                        <Input
                                            label="Match Type Name"
                                            type="text"
                                            name="typeName"
                                            value={typeName}
                                            onChange={this.handleInputChange.bind(this, 'typeName')}
                                            placeholder="Match Type Name"
                                            disabled={false}
                                        />
                                    </div>

                                    <div className="grid-item margin-top-20 align-right">
                                        <DropDown
                                            label="Win Reward Denomination"
                                            type="text"
                                            menuItems={winDenominationOptions}
                                            name="winDenomination"
                                            value={winDenomination}
                                            onChange={this.handleInputChange.bind(this, 'winDenomination')}
                                            placeholder="Select"
                                            disabled={false}
                                        />
                                    </div>

                                    {/* row 3 */}
                                    <div className="grid-item  margin-top-20">
                                        <DropDown
                                            label="Game"
                                            type="text"
                                            menuItems={gameOptions}
                                            name="game"
                                            value={game}
                                            onChange={this.handleInputChange.bind(this, 'game')}
                                            placeholder="Select"
                                            disabled={edit ? true : false}
                                        />
                                    </div>
                                    <div className="grid-item  margin-top-20">
                                        <DropDown
                                            label="Match Mode"
                                            type="text"
                                            menuItems={matchModeOptions}
                                            name="matchMode"
                                            value={matchMode}
                                            onChange={this.handleInputChange.bind(this, 'matchMode')}
                                            placeholder="Select"
                                            disabled={false}
                                        />
                                    </div>

                                    {/* row 4 */}
                                    <div className="grid-item margin-top-20 align-right">
                                        <DropDown
                                            label="Attribute 1"
                                            type="text"
                                            menuItems={attribute1Options}
                                            name="attribute1"
                                            value={attribute1}
                                            onChange={this.handleInputChange.bind(this, 'attribute1')}
                                            placeholder="Select"
                                            disabled={false}
                                        />
                                    </div>

                                    <div className="grid-item  margin-top-20">
                                        <Input
                                            label="Attribute 1 Value"
                                            type="text"
                                            name="attribute1Val"
                                            value={attribute1Val}
                                            onChange={this.handleInputChange.bind(this, 'attribute1Val')}
                                            placeholder="Select"
                                            disabled={false}
                                        />
                                    </div>

                                    {/* row 5 */}
                                    <div className="grid-item margin-top-20 align-right">
                                        <DropDown
                                            label="Attribute 2"
                                            type="text"
                                            menuItems={attribute2Options}
                                            name="attribute2"
                                            value={attribute2}
                                            onChange={this.handleInputChange.bind(this, 'attribute2')}
                                            placeholder="Select"
                                            disabled={false}
                                        />
                                    </div>

                                    <div className="grid-item  margin-top-20">
                                        <Input
                                            label="Attribute 2 Value"
                                            type="text"
                                            name="attribute2Val"
                                            value={attribute2Val}
                                            onChange={this.handleInputChange.bind(this, 'attribute2Val')}
                                            placeholder="Select"
                                            disabled={false}
                                        />
                                    </div>

                                    {/* row 6 */}
                                    <div className="grid-item margin-top-20 align-right">
                                        <DropDown
                                            label="Min Participation Required?"
                                            type="text"
                                            menuItems={minParticipationRequiredOptions}
                                            name="minParticipationRequired"
                                            value={minParticipationRequired}
                                            onChange={this.handleInputChange.bind(this, 'minParticipationRequired')}
                                            placeholder="Select"
                                            disabled={false}
                                        />
                                    </div>
                                    <div className="grid-item  margin-top-20">
                                        <DropDown
                                            label="Reward Win Mode"
                                            type="text"
                                            menuItems={winTypeOptions}
                                            name="winType"
                                            value={winType}
                                            onChange={this.handleInputChange.bind(this, 'winType')}
                                            placeholder="Select"
                                            disabled={false}
                                        />
                                    </div>

                                    {/* row 6 */}
                                    {winType === 'Fixed' ? (
                                        <div className="grid-item  margin-top-20">
                                            <Input
                                                label="Winning Parameter e.g. Per Kill"
                                                type="text"
                                                name="winParameter"
                                                value={winParameter}
                                                onChange={this.handleInputChange.bind(this, 'winParameter')}
                                                placeholder="Winning Parameter"
                                                disabled={false}
                                            />
                                        </div>
                                    ) : null}
                                    {winType === 'Fixed' ? (
                                        <div className="grid-item  margin-top-20">
                                            <Input
                                                label="Reward per parameter e.g. 5"
                                                type="text"
                                                name="winParameterValue"
                                                value={winParameterValue}
                                                onChange={this.handleInputChange.bind(this, 'winParameterValue')}
                                                placeholder="Reward per parameter"
                                                disabled={false}
                                            />
                                        </div>
                                    ) : null}

                                    {winType === 'Distribution' ? (
                                        <div className="grid-item  margin-top-20">Prize Pool Distribution Helper</div>
                                    ) : null}
                                    {winType === 'Distribution' ? (
                                        <div className="grid-item  margin-top-20"></div>
                                    ) : null}

                                    {/* row 7 */}
                                    {winType === 'Distribution' ? (
                                        <div className="grid-item margin-top-20">
                                            <Input
                                                label="Handling Charges "
                                                type="text"
                                                name="handlingCharge"
                                                value={winSlots.handlingCharge}
                                                onChange={this.handleInputChange.bind(this, 'handlingCharge')}
                                                placeholder="Enter Handling Charges %"
                                                disabled={false}
                                                suffix="%"
                                            />
                                        </div>
                                    ) : null}
                                    {winType === 'Distribution' ? (
                                        <div className="grid-item margin-top-20">
                                            <Input
                                                label="# of Big Win Slots"
                                                type="text"
                                                name="numberOfBigWinTeams"
                                                value={numberOfBigWinTeams}
                                                onChange={this.handleInputChange.bind(this, 'numberOfBigWinTeams')}
                                                placeholder="Enter # of Big Win Slots"
                                                disabled={false}
                                            />
                                        </div>
                                    ) : null}
                                </div>

                                {/* row8 */}
                                <div className="card-grid-container nopadding ">
                                    {this.displayPrizes(numberOfBigWinTeams)}
                                </div>

                                {/* row9 */}
                                <div className="grid-item card-grid-container 6">
                                    {winType === 'Distribution' ? (
                                        <React.Fragment>
                                            <div className="grid-item margin-top-20">
                                                <Input
                                                    label="Split Remaining"
                                                    type="text"
                                                    name="splitRemaining"
                                                    value={winSlots.splitRemaining}
                                                    onChange={this.handleInputChange.bind(this, 'splitRemaining')}
                                                    placeholder="Enter Split Remaining"
                                                    disabled={true}
                                                    suffix="%"
                                                />
                                            </div>
                                            <div className="grid-item margin-top-20">
                                                <Input
                                                    label="# of small win slots"
                                                    type="text"
                                                    name="smallWinSlots"
                                                    value={winSlots.smallWinSlots}
                                                    onChange={this.handleInputChange.bind(this, 'smallWinSlots')}
                                                    placeholder="Enter # of small win slots"
                                                    disabled={false}
                                                />
                                            </div>
                                        </React.Fragment>
                                    ) : null}
                                </div>
                                {/* row10 */}
                                <div className="grid-item card-grid-container 6">
                                    <div className="grid-item margin-top-20">
                                        <DropDown
                                            label="Status"
                                            type="text"
                                            menuItems={statusOptions}
                                            name="status"
                                            value={status}
                                            onChange={this.handleInputChange.bind(this, 'status')}
                                            placeholder="Enter # of small win slots"
                                            disabled={false}
                                        />
                                    </div>
                                    <div className="grid-item margin-top-20"></div>
                                </div>
                                {/* row11 */}
                                <div className="card-fullgrid-container margin-top-20">
                                    <label for="matchRules">Match Rules</label>
                                    <TextareaAutosize
                                        className="margin-top-20"
                                        aria-label="maximum height"
                                        value={matchRules}
                                        placeholder="Match Rules"
                                        onChange={this.handleInputChange.bind(this, 'matchRules')}
                                        disabled={false}
                                    />
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className="grid-item"></div>
                </div>
            </DetailLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        toGame: state.toGame,
        toMatchType: state.toMatchType,
        toMatchMode: state.toMatchMode,
        toMatchAttribute: state.toMatchAttribute,
    };
};

export default connect(mapStateToProps, {
    getTOGameList,
    getTOMatchAttributeList,
    getTOMatchModeList,
    createTOMatchType,
    logout,
    deleteTOMatchType,
    updateTOMatchType,
})(AddTOMatchType);
