import React from 'react';
import '../../../Utilities/style.css';
import '../Login/login.css';
import {connect} from 'react-redux';
import DetailLayout from '../../Layouts/DetailLayout/DetailLayout';
import Modal from '../../../Components/Modal/Modal';
import Card from '../../../Components/Card/Card';
import Close from '../../../Images/close_yellow.png';
import Input from '../../../Components/Input/Input';
import {TOURL} from '../../../Utilities/Constants';
import ImageInput from '../../../Components/ImageInput/ImageInput';
import DropDown from '../../../Components/Dropdown/Dropdown';
import {createTOOrganizer, updateTOOrganizer, deleteTOOrganizer} from './AxioCalls';
import Snackbar from '../../../Components/Snackbar/Snackbar';
import {logout} from '../Login/LoginAction';
import axios from 'axios';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';
import Button from '../../../Components/Button/Button';

class AddTOOrganizer extends React.Component {
    constructor(props) {
        super(props);
        let location = this.props.history.location.pathname.includes('edit');
        this.state = {
            activeOptions: [
                {
                    value: 'ACTIVE',
                    text: 'ACTIVE',
                },
                {
                    value: 'INACTIVE',
                    text: 'INACTIVE',
                },
            ],
            orgName: null,
            logo: null,
            uploadImage: null,
            email: null,
            phoneNumber: null,
            password: null,
            contact: null,
            status: 'ACTIVE',
            loading: false,
            open: false,
            apiError: '',
            edit: location,
            snackbarOpen: false,
            appUserId: null,
        };
    }

    handleInputChange(field, value) {
        this.setState({
            [field]: value,
            snackbarOpen: false,
            errorMessage: '',
        });
        if (field === 'uploadImage') {
            console.log('Image upload function is triggered');
            this.setState({loading: true});
            const data = new FormData();
            data.append('logo', value);

            axios({
                method: 'post',
                headers: {
                    Authorization: 'Bearer ' + this.props.login.userDetails.data.accessToken,
                },
                url: `${TOURL}/organizer/imageupload`,
                data: data,
            })
                .then((res) => {
                    this.setState({loading: false});
                    console.log(res.data);
                    this.setState({logo: res.data.msg});
                })
                .catch((err) => {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    this.setState({loading: false, snackbarOpen: true, errorMessage: errorMsg});
                });
        }
    }

    onSave() {
        const {orgName, logo, status, email, password, phoneNumber, contact, edit, appUserId} = this.state;
        const {login} = this.props;

        if (orgName === null || orgName === '' || orgName === undefined) {
            this.setState({
                snackbarOpen: true,
                errorMessage: 'please enter organization name',
            });
        } else if (logo === null || logo === undefined || logo === '') {
            this.setState({
                snackbarOpen: true,
                errorMessage: 'please enter valid org Logo',
            });
        } else if (email === null || email === undefined || email === '') {
            this.setState({
                snackbarOpen: true,
                errorMessage: 'please enter valid email',
            });
        } else if (phoneNumber === null || phoneNumber === undefined || phoneNumber === '') {
            this.setState({
                snackbarOpen: true,
                errorMessage: 'please enter valid phone number',
            });
        } else if (contact === null || contact === undefined || contact === '') {
            this.setState({
                snackbarOpen: true,
                errorMessage: 'please enter valid contact details',
            });
        } else if (!appUserId || appUserId.trim() === '') {
            this.setState({
                snackbarOpen: true,
                errorMessage: 'appUserId is mandatory',
            });
        } else {
            let organizer = {
                orgName: orgName,
                logo: logo,
                email: email,
                phoneNumber: phoneNumber,
                contact: contact,
                status: status,
                user: appUserId,
            };
            if ((password !== null && password !== undefined) || password !== '') {
                organizer['password'] = password;
            }
            this.setState({loading: true});
            if (edit) {
                organizer.id = this.props.toOrganizer.editTOOrganizer.id;
                this.props.updateTOOrganizer(organizer, login.userDetails.data.accessToken);
            } else {
                this.props.createTOOrganizer(organizer, login.userDetails.data.accessToken);
            }
        }
    }

    componentDidMount() {
        const {login, toOrganizer, history} = this.props;
        console.log(this.props);
        if (!login.userDetails.data) {
            history.push('/');
            this.props.logout();
        } else {
            const {edit} = this.state;
            if (edit) {
                const {orgName, logo, email, phoneNumber, contact, status, user} = toOrganizer.editTOOrganizer;
                this.setState({
                    orgName: orgName,
                    logo: logo,
                    email: email,
                    phoneNumber: phoneNumber,
                    contact: contact,
                    status: status,
                    appUserId: user,
                });
            }
        }
    }

    componentDidUpdate(prevProps) {
        const {toOrganizer, history} = this.props;

        if (toOrganizer.addTOOrganizerSuccess && !prevProps.toOrganizer.addTOOrganizerSuccess) {
            history.push('/toOrganizers');
        } else if (toOrganizer.addTOOrganizerFail && !prevProps.toOrganizer.addTOOrganizerFail) {
            this.setState({
                loading: false,
                snackbarOpen: true,
                errorMessage: toOrganizer.errorMessage,
            });
        }
        if (toOrganizer.updateTOOrganizerSuccess && !prevProps.toOrganizer.updateTOOrganizerSuccess) {
            history.push('/toOrganizers');
        } else if (toOrganizer.updateTOOrganizerFail && !prevProps.toOrganizer.updateTOOrganizerFail) {
            this.setState({
                loading: false,
                snackbarOpen: true,
                errorMessage: toOrganizer.errorMessage,
            });
        }
        if (toOrganizer.deleteTOOrganizerSuccess && !prevProps.toOrganizer.deleteTOOrganizerSuccess) {
            this.setState({
                loading: false,
                code: [],
                snackbarOpen: true,
                errorMessage: toOrganizer.deleteTOOrganizer.msg,
            });
            history.push('/toOrganizers');
        }
        if (toOrganizer.deleteTOOrganizerFail && !prevProps.toOrganizer.deleteTOOrganizerFail) {
            this.setState({loading: false, snackbarOpen: true, errorMessage: toOrganizer.errorMessage});
        }
    }

    //open modal on delete click
    onDeleteClick() {
        this.setState({open: true});
    }

    //api call to delete selcted code
    onDeleteTOOrganizer() {
        const {login} = this.props;
        let organizerId = this.props.toOrganizer.editTOOrganizer.id;
        this.props.deleteTOOrganizer(organizerId, login.userDetails.data.accessToken);
        this.setState({open: false, loading: true});
    }

    render() {
        const {history, toOrganizer, login} = this.props;
        const {
            orgName,
            logo,
            email,
            phoneNumber,
            contact,
            password,
            status,
            errorMessage,
            loading,
            open,
            edit,
            snackbarOpen,
            activeOptions,
            appUserId,
        } = this.state;
        const {toOrganizerWrite} = login?.permissions || {};
        let title = edit ? 'Edit Organizer' : 'Add  Organizer';
        let shrink = edit ? true : undefined;
        return (
            <DetailLayout
                location="AddTOOrganizer"
                history={history}
                centerTitle={title}
                onSave={!!toOrganizerWrite ? this.onSave.bind(this) : undefined}
                editPage={edit}
                id={edit ? toOrganizer.editTOOrganizer.id : null}
                onDeleteClick={!!toOrganizerWrite ? this.onDeleteClick.bind(this) : undefined}
            >
                <LoadingModal open={loading} />
                <Modal open={open}>
                    <div className="container modal-top-margin">
                        <Card>
                            <div className="disp-flex">
                                <div className="modal-left-header">Delete Organizer</div>
                                <div onClick={() => this.setState({open: false})}>
                                    <img src={Close} alt="" className="cursor" />
                                </div>
                            </div>
                            <div className="align-center equalcontainer-grids">
                                <div />
                                <div>
                                    <div className="disp-flex align-center margin-top-30">
                                        <div className="margin-right-16">
                                            <Button buttonText="Delete" onClick={this.onDeleteTOOrganizer.bind(this)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                </Modal>
                <Snackbar
                    open={snackbarOpen}
                    message={errorMessage}
                    onClose={() => this.setState({snackbarOpen: false})}
                />
                <div className="detail-container margin-top-20">
                    <div className="grid-item"></div>

                    <div className="grid-item">
                        <Card>
                            <div>
                                <div className="card-grid-container">
                                    {/* row 1 */}
                                    <div className="grid-item  margin-top-20">
                                        <Input
                                            label="Organizer Name"
                                            type="text"
                                            name="orgName"
                                            value={orgName}
                                            onChange={this.handleInputChange.bind(this, 'orgName')}
                                            placeholder="Enter Organizer Name"
                                            disabled={false}
                                        />
                                    </div>

                                    <div className="grid-item margin-top-20 align-right"></div>

                                    {/* row 2 */}
                                    <div className="grid-item  margin-top-20">
                                        <Input
                                            label="Organizer Logo"
                                            type="text"
                                            name="logo"
                                            value={logo}
                                            onChange={this.handleInputChange.bind(this, 'logo')}
                                            placeholder="Enter Organizer Logo"
                                            disabled={true}
                                        />
                                        {/* <DropDown
                                            label="Discount Type"
                                            type="text"
                                            menuItems={discountTypeOptions}
                                            name="discountType"
                                            value={discountType}
                                            onChange={this.handleInputChange.bind(this, "discountType")}
                                            placeholder="Select"
                                            disabled={disabled} /> */}
                                    </div>

                                    {logo !== undefined && logo !== null && logo !== '' ? (
                                        <div className="grid-item  margin-top-20">
                                            <img src={logo} style={{height: 100}} />
                                        </div>
                                    ) : (
                                        <div className="grid-item  margin-top-20"></div>
                                    )}

                                    {!!toOrganizerWrite && (
                                        <div className="grid-item  margin-top-20">
                                            <ImageInput
                                                label="Upload Your File"
                                                type="file"
                                                name="file"
                                                onChange={this.handleInputChange.bind(this, 'uploadImage')}
                                            />
                                        </div>
                                    )}

                                    <div className="grid-item  margin-top-20"></div>

                                    {/* row 3 */}
                                    <div className="grid-item  margin-top-20">
                                        <Input
                                            label="Organizer Email"
                                            type="text"
                                            name="email"
                                            value={email}
                                            onChange={this.handleInputChange.bind(this, 'email')}
                                            placeholder="Prize Pool"
                                            // suffix={discountType === "PERCENT" ? "%" : undefined}
                                            disabled={false}
                                        />
                                    </div>

                                    <div className="grid-item  margin-top-20">
                                        <Input
                                            label="Organizer Phone number"
                                            type="text"
                                            name="phoneNumber"
                                            value={phoneNumber}
                                            onChange={this.handleInputChange.bind(this, 'phoneNumber')}
                                            placeholder="Organizer Phone Number"
                                            // suffix={discountType === "PERCENT" ? "%" : undefined}
                                            disabled={false}
                                        />
                                    </div>

                                    {/* row 4 */}

                                    <div className="grid-item  margin-top-20">
                                        <Input
                                            label="Organizer Contact"
                                            type="text"
                                            name="contact"
                                            value={contact}
                                            onChange={this.handleInputChange.bind(this, 'contact')}
                                            placeholder="Organizer Contact"
                                            // suffix={discountType === "PERCENT" ? "%" : undefined}
                                            disabled={false}
                                        />
                                    </div>
                                    <div className="grid-item  margin-top-20">
                                        <Input
                                            label="Enter user id"
                                            type="text"
                                            name="appUserId"
                                            value={appUserId}
                                            onChange={this.handleInputChange.bind(this, 'appUserId')}
                                            placeholder="Enter user id"
                                            disabled={false}
                                        />
                                    </div>

                                    {/* row 7 */}
                                    <div className="grid-item margin-top-20">
                                        <DropDown
                                            label="Active Status"
                                            type="text"
                                            menuItems={activeOptions}
                                            name="status"
                                            value={status}
                                            onChange={this.handleInputChange.bind(this, 'status')}
                                            placeholder="Select"
                                            disabled={false}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className="grid-item"></div>
                </div>
            </DetailLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        toOrganizer: state.toOrganizer,
    };
};

export default connect(mapStateToProps, {
    createTOOrganizer,
    logout,
    deleteTOOrganizer,
    updateTOOrganizer,
})(AddTOOrganizer);
