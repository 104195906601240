import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Box, IconButton, styled} from '@material-ui/core';
import MuiModal from '../../../../../Components/MuiModal/MuiModal';
import {Close, Refresh, Save} from '@material-ui/icons';
import {ColorPalette} from '../../../../../Utilities/constants/color-constants';
import styles from './AddPlayerInCommonMatchModal.module.scss';
import MuiButton from '../../../../../Components/MuiButton/MuiButton';
import MuiInputField from '../../../../../Components/MuiInputField/MuiInputField';
import {addPlayerInMatch} from '../../../../../View/Modules/Players/PlayerAxios';
import {usePrevious} from '../../../../../Utilities/hooks';

const AddPlayerInCommonMatchModal = (props) => {
    const {addPlayerInCommonMatchModal, onClickClose, open} = props;
    const dispatch = useDispatch();
    const client = useSelector((state) => state.client);
    const login = useSelector((state) => state.login);
    const player = useSelector((state) => state.player);
    const prevPlayer = usePrevious(player);
    const [message, setMessage] = useState('');
    const [formState, setFormState] = useState({});
    const {matchId, squadId, playerId} = formState;

    useEffect(() => {
        handleFormData({
            matchId: addPlayerInCommonMatchModal?.matchId,
            squadId: addPlayerInCommonMatchModal?.squadId,
        });
    }, [addPlayerInCommonMatchModal]);

    useEffect(() => {
        if (player.isAddingPlayerInMatchFail && !prevPlayer.isAddingPlayerInMatchFail) {
            setMessage(player?.errorMessage?.errorReason);
        }
    }, [player]);

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = {
            matchId,
            squadId,
            playerId,
        };
        dispatch(addPlayerInMatch(data, login?.userDetails?.data?.accessToken, client.selectedClient));
    };

    const handleReset = () => {
        setFormState({});
    };

    const handleFormData = (formObj) => {
        setFormState({...formState, ...formObj});
    };

    return (
        <MuiModal onClickClose={onClickClose} open={open} className={styles.container}>
            <div className={styles.header}>
                <h3>Add Player</h3>
                <CustomIconButton onClick={onClickClose} className={styles.closeButton}>
                    <Close className={styles.closeButtonIcon} />
                </CustomIconButton>
            </div>
            <div className={styles.divider} />
            <div className={styles.body}>
                <Box component="form" onSubmit={handleSubmit} onReset={handleReset} className={styles.uploadForm}>
                    <MuiInputField label="Match ID" name="matchId" readOnly={true} size="small" value={matchId || ''} />
                    <MuiInputField label="Squad ID" name="squadId" readOnly={true} size="small" value={squadId || ''} />
                    <MuiInputField
                        label="Player ID"
                        name="playerId"
                        onChange={(event) => handleFormData({playerId: event.target.value})}
                        size="small"
                        required={true}
                        value={playerId || ''}
                    />
                    {!!message && <p className={styles.responseText}>{message}</p>}
                    <div className={styles.formButtonGroup}>
                        <MuiButton type="reset" buttonText="Reset" buttonIcon={<Refresh />} />
                        <MuiButton type="submit" buttonText="Submit" buttonIcon={<Save />} />
                    </div>
                </Box>
            </div>
        </MuiModal>
    );
};

const CustomIconButton = styled(IconButton)({
    width: '28px',
    height: '28px',

    '&:hover': {
        backgroundColor: ColorPalette.NIGHT_RIDER,
    },
});

export default AddPlayerInCommonMatchModal;
