import React from 'react';
import Input from '../../../Components/Input/Input';

const MatchDetailsV2 = (props) => {
    const {match, onUpdateOrganizerId} = props;

    return (
        <div>
            <div style={{display: 'flex', flexDirection: 'row', marginTop: 20, flex: 1}}>
                <Input
                    label="Organizer Id"
                    type="text"
                    name="organizerId"
                    defaultValue={match.organizer ? match.organizer.id : ''}
                    onChange={onUpdateOrganizerId}
                    placeholder="Enter Organizer Id"
                    disabled={match.status ? match.status !== 'DRAFT' && match.status !== 'UPCOMING' : false}
                />
            </div>
            <div style={{display: 'flex', flexDirection: 'row'}}>
                {match.metricPrizeAllocation &&
                match.metricPrizeAllocation.allocation &&
                match.metricPrizeAllocation.allocation.length > 0 ? (
                    <div>
                        <div style={Styles.jsonHeading}>{'Metric Prize Allocation'}</div>
                        <div style={Styles.jsonBlock}>
                            <pre>{JSON.stringify(match.metricPrizeAllocation.allocation, null, 4)}</pre>
                        </div>
                    </div>
                ) : null}
                {match.metricScoring && match.metricScoring.scoring && match.metricScoring.scoring.length ? (
                    <div style={{marginLeft: 40}}>
                        <div style={Styles.jsonHeading}>{'Metric Scoring'}</div>
                        <div style={Styles.jsonBlock}>
                            <pre>{JSON.stringify(match.metricScoring.scoring, null, 4)}</pre>
                        </div>
                    </div>
                ) : null}
            </div>

            <div style={{display: 'flex', flexDirection: 'row'}}>
                {match.rankBasedScoring &&
                match.rankBasedScoring.scoring &&
                match.rankBasedScoring.scoring.length > 0 ? (
                    <div>
                        <div style={Styles.jsonHeading}>{'Rank Based Scoring'}</div>
                        <div style={Styles.jsonBlock}>
                            <pre>{JSON.stringify(match.rankBasedScoring.scoring, null, 4)}</pre>
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

const Styles = {
    jsonBlock: {
        overflow: 'scroll',
        height: 200,
    },
    jsonHeading: {
        marginTop: 20,
        textAlign: 'center',
    },
};

export default MatchDetailsV2;
