import React from 'react';
import {Text} from 'react-native';

import DefaultStyles from './default-styles';

const BaseText = (props) => {
    return (
        // <Text style={{ ...DefaultStyles.baseText, ...props.style }}>{props.children}</Text>
        <Text style={props.style ? props.style : DefaultStyles.baseText}>{props.children}</Text>
    );
};

export default BaseText;
