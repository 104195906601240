import React from 'react';
import '../../../Utilities/style.css';
import './login.css';
import LoginLayout from '../../Layouts/LoginLayout/Loginlayout';
import {userLoginV2} from './LoginAxios';
import {connect} from 'react-redux';
import {GoogleLogin} from './GoogleLogin';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';
import Snackbar from '../../../Components/Snackbar/Snackbar';
import FanclashLogo from '../../../Images/fanclash_logo_big.png';

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    login = (id_token) => {
        this.props.userLoginV2({tokenId: id_token});
    };

    componentWillMount() {
        const {login, history} = this.props;
        if (login?.userDetails?.data) {
            if (login?.userDetails?.data?.role === 'ORGANIZER') {
                history.push('/toMatches');
            } else {
                history.push('/matches/upcoming');
            }
        }
    }

    componentDidUpdate(prevProps) {
        const {login, history} = this.props;

        if (login.loginSuccess && !prevProps.login.loginSuccess) {
            if (login?.userDetails?.data?.role === 'ORGANIZER') {
                history.push('/toMatches');
            } else {
                history.push('/matches/upcoming');
            }
        } else if (login.loginFail && !prevProps.login.loginFail) {
            this.setState({errorMessage: login.errorMessage.msg});
        }
    }

    render() {
        const {errorMessage} = this.state;
        const {login} = this.props;
        return (
            <div className="login-container flex-col align-center-justify-center" style={{height: '100vh'}}>
                {!!login.loginRequest && <LoadingModal open={login.loginRequest} />}
                {!!errorMessage && (
                    <Snackbar
                        open={!!errorMessage}
                        message={errorMessage}
                        onClose={() => this.setState({errorMessage: ''})}
                    />
                )}
                <div className="margin-bottom-30">
                    <img alt="" src={FanclashLogo} />
                    <div className="brand-name">Control Center</div>
                </div>

                <div className="margin-top-30">
                    <GoogleLogin onSuccess={this.login} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
    };
};

export default connect(mapStateToProps, {userLoginV2})(Login);
