import {SEND_NOTIFICATION, SEND_NOTIFICATION_SUCCESS, SEND_NOTIFICATION_FAIL} from './Constants';

const initialState = {
    sendNotification: false,
    sendNotificationSuccess: false,
    sendNotificationFail: false,
    errorMessage: '',
};

const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case SEND_NOTIFICATION:
            return {
                ...state,
                sendNotification: true,
                sendNotificationSuccess: false,
                sendNotificationFail: false,
                errorMessage: '',
            };

        case SEND_NOTIFICATION_SUCCESS:
            return {
                ...state,
                sendNotification: false,
                sendNotificationSuccess: true,
                sendNotificationFail: false,
                errorMessage: '',
            };

        case SEND_NOTIFICATION_FAIL:
            return {
                ...state,
                sendNotification: false,
                sendNotificationSuccess: false,
                sendNotificationFail: true,
                errorMessage: action.payload,
            };
        default:
            return state;
    }
};

export default notificationReducer;
