export const ColorPalette = {
    BLACK: '#040404',
    NERO: '#1F1F1F',
    NIGHT_RIDER: '#303030',
    GAINSBORO: '#E3E3E3',
    SILVER: '#BABABA',
    GREY: '#7C7C7C',
    MORTAR: '#535353',
    LINEN: '#FCF9F3',
    SELECTIVE_YELLOW: '#FFB707',
    SALOME: '#FFD885',

    ELECTRIC_INDIGO: '#7901F1',
    PERSIAN_INDIGO: '#2F0954',
};
