import axios from 'axios';
import {
    listTournament,
    listTournamentSuccess,
    listTournamentFail,
    addTournamentRequest,
    addTournamentSuccess,
    addTournamentFail,
} from './FantasyTournamentAction';
import {HEADER_SOURCE, URL} from '../../../Utilities/Constants';
import apis from '../../../apiCalls/apis';
import {IMPORT_TOURNAMENT, IMPORT_TOURNAMENT_FAIL, IMPORT_TOURNAMENT_SUCCESS} from './FantasyTournamentConstants';

export function listAllTournaments(data, token, clientId) {
    return (dispatch) => {
        dispatch(listTournament());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token, clientid: clientId},
            url: `${URL}/tournament/organizer/listAllTournaments`,
            data: data,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(listTournamentSuccess(data));
                } else if (data.data.status === 'fail') {
                    dispatch(listTournamentFail(data.data.errorData.errorReason));
                } else {
                    dispatch(listTournamentFail('Unknown Error Occured'));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(listTournamentFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(listTournamentFail(err.message || 'Something went wrong'));
                }
            });
    };
}

/**
 * create Tournament
 * @param {string} TournamentName Tournament name
 * @param {string} Tournaments players
 * @param {string} Inactive status
 * @param {string} token user token
 */
export function addTournament(data, token) {
    return (dispatch) => {
        dispatch(addTournamentRequest());
        axios({
            method: 'post',
            headers: {
                'x-source': HEADER_SOURCE,
                Authorization: 'Bearer ' + token,
            },
            url: `${URL}/tournament/organizer/createTournament`,
            data: data,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(addTournamentSuccess(data));
                } else if (data.data.status === 'fail') {
                    dispatch(addTournamentFail(data.data.errorData.errorReason));
                } else {
                    dispatch(addTournamentFail('Unknown Error Occured'));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(addTournamentFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(addTournamentFail(err.message || 'Something went wrong'));
                }
            });
    };
}

export const importTournament = (data, token, clientId) => async (dispatch) => {
    dispatch({type: IMPORT_TOURNAMENT});
    try {
        const response = await apis.importTournament(data, token, clientId);
        if (response.status === 200) {
            dispatch({type: IMPORT_TOURNAMENT_SUCCESS, payload: response.data});
        } else {
            dispatch({type: IMPORT_TOURNAMENT_FAIL, payload: response.data});
        }
    } catch (e) {
        if (e.response) {
            dispatch({
                type: IMPORT_TOURNAMENT_FAIL,
                payload: e?.response?.data?.data?.errorReason || 'Something went wrong',
            });
        } else {
            dispatch({type: IMPORT_TOURNAMENT_FAIL, payload: e?.message || 'Unknown Error Occured'});
        }
    }
};
