import React, {useEffect} from 'react';
import {GOOGLE_CLIENT_ID} from '../../../Utilities/Constants';
import PropTypes from 'prop-types';

export const GoogleLogin = (props) => {
    const {onSuccess} = props;

    useEffect(() => {
        if (window.google) {
            window.google.accounts.id.initialize({
                client_id: GOOGLE_CLIENT_ID,
                callback: (e) => {
                    onSuccess(e.credential);
                },
            });

            window.google.accounts.id.renderButton(document.getElementById('google-login-btn'), {
                text: 'Sign in with Google',
                shape: 'pill',
            });
        }
    }, []);

    return <div className="container" id="google-login-btn" />;
};

GoogleLogin.propTypes = {
    onSuccess: PropTypes.func,
};

GoogleLogin.defaultProps = {
    onSuccess: () => {},
};
