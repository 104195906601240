export const TOORGANIZER_LIST = 'TOORGANIZER_LIST';
export const TOORGANIZER_LIST_SUCCESS = 'TOORGANIZER_LIST_SUCCESS';
export const TOORGANIZER_LIST_FAIL = 'TOORGANIZER_LIST_FAIL';

export const ADD_TOORGANIZER = 'ADD_TOORGANIZER';
export const ADD_TOORGANIZER_SUCCESS = 'ADD_TOORGANIZER_SUCCESS';
export const ADD_TOORGANIZER_FAIL = 'ADD_TOORGANIZER_FAIL';

export const UPDATE_TOORGANIZER = 'UPDATE_TOORGANIZER';
export const UPDATE_TOORGANIZER_SUCCESS = 'UPDATE_TOORGANIZER_SUCCESS';
export const UPDATE_TOORGANIZER_FAIL = 'UPDATE_TOORGANIZER_FAIL';

export const DELETE_TOORGANIZER = 'DELETE_TOORGANIZER';
export const DELETE_TOORGANIZER_SUCCESS = 'DELETE_TOORGANIZER_SUCCESS';
export const DELETE_TOORGANIZER_FAIL = 'DELETE_TOORGANIZER_FAIL';

export const GET_TOORGANIZER = 'GET_TOORGANIZER';
export const GET_TOORGANIZER_SUCCESS = 'GET_TOORGANIZER_SUCCESS';
export const GET_TOORGANIZER_FAIL = 'GET_TOORGANIZER_FAIL';

export const EDIT_TOORGANIZER = 'EDIT_TOORGANIZER';
