import axios from 'axios';
import {
    listTOMatches,
    listTOMatchesSuccess,
    listTOMatchesFail,
    getTOMatchParticipationRequest,
    getTOMatchParticipationSuccess,
    getTOMatchParticipationFail,
    archiveTOMatchRequest,
    archiveTOMatchSuccess,
    archiveTOMatchFail,
    getTOMatchRequest,
    getTOMatchSuccess,
    getTOMatchFail,
} from './action';
import {TOURL} from '../../../Utilities/Constants';

export function getTOMatchList(pageOptions, token) {
    return (dispatch) => {
        dispatch(listTOMatches());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token},
            url: `${TOURL}/match/list`,
            data: pageOptions,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(listTOMatchesSuccess(data));
                } else {
                    dispatch(listTOMatchesFail(data.data.errorData.errorReason));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(listTOMatchesFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(listTOMatchesFail(err.message || 'Something went wrong'));
                }
            });
    };
}

export function getTOOrganizerMatchList(pageOptions, token) {
    return (dispatch) => {
        dispatch(listTOMatches());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token},
            url: `${TOURL}/match/organizerList`,
            data: pageOptions,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(listTOMatchesSuccess(data));
                } else {
                    dispatch(listTOMatchesFail(data.data.errorData.errorReason));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(listTOMatchesFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(listTOMatchesFail(err.message || 'Something went wrong'));
                }
            });
    };
}

export function getTOMatchPartipation(TOMatch, token) {
    return (dispatch) => {
        dispatch(getTOMatchParticipationRequest());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token},
            url: `${TOURL}/match/getParticipation`,
            data: TOMatch,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(getTOMatchParticipationSuccess(data));
                } else {
                    dispatch(getTOMatchParticipationFail(data.data.errorData.errorReason));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(getTOMatchParticipationFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(getTOMatchParticipationFail(err.message || 'Something went wrong'));
                }
            });
    };
}

//TOMatch archive
export function archiveTOMatch(TOMatch, token) {
    return (dispatch) => {
        dispatch(archiveTOMatchRequest());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token},
            url: `${TOURL}/match/archive`,
            data: TOMatch,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(archiveTOMatchSuccess(data));
                } else {
                    dispatch(archiveTOMatchFail(data.data.errorData.errorReason));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(archiveTOMatchFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(archiveTOMatchFail(err.message || 'Something went wrong'));
                }
            });
    };
}

// get single TOMatch
export function getTOMatch(id, token) {
    return (dispatch) => {
        dispatch(getTOMatchRequest());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token},
            url: `${TOURL}/match/get`,
            data: {
                matchId: id,
            },
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(getTOMatchSuccess(data));
                } else {
                    dispatch(getTOMatchFail(data.data.errorData.errorReason));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(getTOMatchFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(getTOMatchFail(err.message || 'Something went wrong'));
                }
            });
    };
}
