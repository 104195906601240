/**
 * Component returning contest details item of a match
 *
 */
import React, {Component} from 'react';
import dimen from './dimens';
import color from './colors';
import fontTheme from './fonts';
import {Text, StyleSheet, Image, View} from 'react-native';

class MatchContestItemDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const {style, imgSrc, hideSubrootMargin, text, type, value, toShowFcCoins} = this.props;

        return (
            <View style={[styles.horizontalRoot, style]}>
                {imgSrc ? <Image source={imgSrc} /> : null}
                <View style={hideSubrootMargin ? styles.emptyMargin : styles.marginStart}>
                    <Text
                        // numberOfLines={1}
                        style={styles.smallGreyText}
                    >
                        {text}
                    </Text>
                    {type === 'credit' ? (
                        <View style={styles.creditsWrapper}>
                            <Text style={styles.credit}>{value}</Text>
                        </View>
                    ) : (
                        <View style={styles.horizontalRoot}>
                            {toShowFcCoins ? (
                                <Image style={styles.endMargin} source={require('../../../Images/fc_coin_small.png')} />
                            ) : null}
                            <Text
                                // numberOfLines={1}
                                style={type === 'text' || type === 'amount' ? styles.largeText : styles.largeGreenText}
                            >
                                {type === 'amount' || type === 'winnings' ? '₹ ' : null}
                                {value}
                            </Text>
                        </View>
                    )}
                </View>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    horizontalRoot: {
        flexDirection: 'row',
        flex: 1,
        alignItems: 'flex-start',
        paddingEnd: dimen.DIMEN_8,
    },
    smallGreyText: {
        fontSize: fontTheme.FONT_12,
        color: color.LIGHT_GREY,
        // fontFamily: fontTheme.FONT_NUNITO_SANS_REGULAR,
    },
    largeText: {
        fontSize: fontTheme.FONT_16,
        color: color.WHITE,
        // fontFamily: fontTheme.FONT_NUNITO_SANS_BOLD,
    },
    largeGreenText: {
        fontSize: fontTheme.FONT_16,
        color: color.GREEN,
        // fontFamily: fontTheme.FONT_NUNITO_SANS_BOLD,
    },
    marginStart: {
        marginStart: dimen.DIMEN_4,
    },
    emptyMargin: {},
    endMargin: {
        marginEnd: dimen.DIMEN_8,
    },
    creditsWrapper: {
        alignSelf: 'baseline',
        backgroundColor: color.LIGHT_BLUE,
        borderRadius: dimen.DIMEN_4,
        paddingStart: dimen.DIMEN_8,
        paddingEnd: dimen.DIMEN_8,
        marginTop: dimen.DIMEN_4,
    },
    credit: {
        // fontFamily: fontTheme.FONT_NUNITO_SANS_BOLD,
        fontSize: fontTheme.FONT_14,
        color: color.WHITE,
    },
});

export default MatchContestItemDetails;
