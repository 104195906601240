module.exports = {
    ROUTES: {
        LIST_MATCHES: '/matches',
        UPCOMING_MATCHES: '/matches/upcoming',
        ADD_MATCH: '/addMatch',
        EDIT_MATCH: '/editMatch/:id',
        RESCHEDULE_MATCH: '/rescheduleMatch',
        CANCEL_MATCH: '/cancelMatch',
        MATCH_OF_THE_DAY: '/matchOfTheDay',
        ADD_TOURNAMENT_SCREEN: '/addTournamentScreen',
    },
};
