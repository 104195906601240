import React, {useState} from 'react';
import CustomLayout from '../../layout/CustomLayout/CustomLayout';
import EngagementCenter from './components/EngagementCenterModal/EngagementCenterModal';
// import MuiButton from "../../Components/MuiButton/MuiButton";
// import { Publish } from "@material-ui/icons";
import styles from './Skeleton.module.scss';

const Skeleton = (props) => {
    const {history} = props;
    const [engagementCenter, setEngagementCenter] = useState(null);

    return (
        <CustomLayout history={history}>
            {!!engagementCenter && (
                <EngagementCenter open={!!engagementCenter} onClickClose={() => setEngagementCenter(null)} />
            )}
            <div className={styles.pageHeaderCard}>
                <div className={styles.pageHeading}>
                    <h1>Skeleton</h1>
                </div>
            </div>
            {/* <div className={styles.buttonGroup}>
                <MuiButton type="button" buttonText="Upload Banner" buttonIcon={<Publish className={styles.uploadBannerIcon}/>} onButtonClick={() => setUploadBannerModal({})}/>
            </div> */}
        </CustomLayout>
    );
};

export default Skeleton;
