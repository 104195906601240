import {
    TOMATCHATTRIBUTE_LIST,
    TOMATCHATTRIBUTE_LIST_SUCCESS,
    TOMATCHATTRIBUTE_LIST_FAIL,
    ADD_TOMATCHATTRIBUTE,
    ADD_TOMATCHATTRIBUTE_SUCCESS,
    ADD_TOMATCHATTRIBUTE_FAIL,
    UPDATE_TOMATCHATTRIBUTE,
    UPDATE_TOMATCHATTRIBUTE_SUCCESS,
    UPDATE_TOMATCHATTRIBUTE_FAIL,
    DELETE_TOMATCHATTRIBUTE,
    DELETE_TOMATCHATTRIBUTE_SUCCESS,
    DELETE_TOMATCHATTRIBUTE_FAIL,
    GET_TOMATCHATTRIBUTE,
    GET_TOMATCHATTRIBUTE_SUCCESS,
    GET_TOMATCHATTRIBUTE_FAIL,
    EDIT_TOMATCHATTRIBUTE,
} from './Constants';

const initialState = {
    TOMatchAttributeListRequest: false,
    TOMatchAttributeListSuccess: false,
    TOMatchAttributeListFail: false,
    TOMatchAttributeList: [],
    errorMessage: '',
    addTOMatchAttributeRequest: false,
    addTOMatchAttributeSuccess: false,
    addTOMatchAttributeFail: false,
    addTOMatchAttribute: [],
    updateTOMatchAttributeRequest: false,
    updateTOMatchAttributeSuccess: false,
    updateTOMatchAttributeFail: false,
    updateTOMatchAttribute: [],
    deleteTOMatchAttributeRequest: false,
    deleteTOMatchAttributeSuccess: false,
    deleteTOMatchAttributeFail: false,
    deleteTOMatchAttribute: [],
    getTOMatchAttributeRequest: false,
    getTOMatchAttributeSuccess: false,
    getTOMatchAttributeFail: false,
    getTOMatchAttribute: [],
    editTOMatchAttribute: [],
};

const toMatchAttributeReducer = (state = initialState, action) => {
    switch (action.type) {
        case TOMATCHATTRIBUTE_LIST:
            return {
                ...state,
                TOMatchAttributeListRequest: true,
                TOMatchAttributeListSuccess: false,
                TOMatchAttributeListFail: false,
                TOMatchAttributeList: [],
                errorMessage: '',
            };

        case TOMATCHATTRIBUTE_LIST_SUCCESS:
            return {
                ...state,
                TOMatchAttributeListRequest: false,
                TOMatchAttributeListSuccess: true,
                TOMatchAttributeListFail: false,
                TOMatchAttributeList: action.payload.data,
                errorMessage: '',
            };

        case TOMATCHATTRIBUTE_LIST_FAIL:
            return {
                ...state,
                TOMatchAttributeListRequest: false,
                TOMatchAttributeListSuccess: false,
                TOMatchAttributeListFail: true,
                errorMessage: action.payload,
            };
        // ADD match
        case ADD_TOMATCHATTRIBUTE:
            return {
                ...state,
                addTOMatchAttributeRequest: false,
                addTOMatchAttributeSuccess: false,
                addTOMatchAttributeFail: false,
                addTOMatchAttribute: [],
                errorMessage: '',
            };

        case ADD_TOMATCHATTRIBUTE_SUCCESS:
            return {
                ...state,
                addTOMatchAttributeRequest: false,
                addTOMatchAttributeSuccess: true,
                addTOMatchAttributeFail: false,
                addTOMatchAttribute: action.payload.data,
                errorMessage: '',
            };

        case ADD_TOMATCHATTRIBUTE_FAIL:
            return {
                ...state,
                addTOMatchAttributeRequest: false,
                addTOMatchAttributeSuccess: false,
                addTOMatchAttributeFail: true,
                errorMessage: action.payload,
            };
        //update TOMatchAttribute
        case UPDATE_TOMATCHATTRIBUTE:
            return {
                ...state,
                updateTOMatchAttributeRequest: true,
                updateTOMatchAttributeSuccess: false,
                updateTOMatchAttributeFail: false,
                updateTOMatchAttribute: [],
                errorMessage: '',
            };

        case UPDATE_TOMATCHATTRIBUTE_SUCCESS:
            return {
                ...state,
                updateTOMatchAttributeRequest: false,
                updateTOMatchAttributeSuccess: true,
                updateTOMatchAttributeFail: false,
                updateTOMatchAttribute: action.payload.data,
                errorMessage: '',
            };

        case UPDATE_TOMATCHATTRIBUTE_FAIL:
            return {
                ...state,
                updateTOMatchAttributeRequest: false,
                updateTOMatchAttributeSuccess: false,
                updateTOMatchAttributeFail: true,
                errorMessage: action.payload,
            };

        // DELETE TOMATCHATTRIBUTE
        case DELETE_TOMATCHATTRIBUTE:
            return {
                ...state,
                deleteTOMatchAttributeRequest: true,
                deleteTOMatchAttributeSuccess: false,
                deleteTOMatchAttributeFail: false,
                deleteTOMatchAttribute: [],
                errorMessage: '',
            };

        case DELETE_TOMATCHATTRIBUTE_SUCCESS:
            return {
                ...state,
                deleteTOMatchAttributeRequest: false,
                deleteTOMatchAttributeSuccess: true,
                deleteTOMatchAttributeFail: false,
                deleteTOMatchAttribute: action.payload.data,
                errorMessage: '',
            };

        case DELETE_TOMATCHATTRIBUTE_FAIL:
            return {
                ...state,
                deleteTOMatchAttributeRequest: false,
                deleteTOMatchAttributeSuccess: false,
                deleteTOMatchAttributeFail: true,
                errorMessage: action.payload,
            };

        // get single TOMatchAttribute
        case GET_TOMATCHATTRIBUTE:
            return {
                ...state,
                getTOMatchAttributeRequest: true,
                getTOMatchAttributeSuccess: false,
                getTOMatchAttributeFail: false,
                getTOMatchAttribute: [],
                errorMessage: '',
            };

        case GET_TOMATCHATTRIBUTE_SUCCESS:
            return {
                ...state,
                getTOMatchAttributeRequest: false,
                getTOMatchAttributeSuccess: true,
                getTOMatchAttributeFail: false,
                getTOMatchAttribute: action.payload.data,
                errorMessage: '',
            };

        case GET_TOMATCHATTRIBUTE_FAIL:
            return {
                ...state,
                getTOMatchAttributeRequest: false,
                getTOMatchAttributeSuccess: false,
                getTOMatchAttributeFail: true,
                errorMessage: action.payload,
            };
        // EDIT TOMATCHATTRIBUTE
        case EDIT_TOMATCHATTRIBUTE:
            return {
                ...state,
                editTOMatchAttribute: action.payload,
            };

        default:
            return state;
    }
};

export default toMatchAttributeReducer;
