import {useEffect, useRef, useState} from 'react';

export const usePrevious = (value) => {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = useRef(value);
    // Store current value in ref
    useEffect(() => {
        ref.current = value;
    }, [value]); // Only re-run if value changes
    // Return previous value (happens before update in useEffect above)
    return ref.current;
};

export const useDebounce = (value, data = {}, delay = 1200) => {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);
        if (data && data.immediate) {
            clearTimeout(handler);
            setDebouncedValue(value);
        }

        return () => {
            clearTimeout(handler);
        };
    }, [value]);
    return debouncedValue;
};
