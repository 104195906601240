import React from 'react';
import '../../../Utilities/style.css';
import '../Login/login.css';
import {getPlayerList, deletePlayers} from './PlayerAxios';
import TableLayout from '../../Layouts/TableLayout/TableLayout';
import Table from '../../../Components/Table/Table';

import {connect} from 'react-redux';
import {debounce, next, prev} from '../../../Utilities/HelperFunctions';
import {editPlayer} from './playerAction';
import Snackbar from '../../../Components/Snackbar/Snackbar';
import {logout} from '../Login/LoginAction';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';
import {timeFormat} from '../../../Utilities/Helpers';
import {ENTER} from '../../../Utilities/Constants';
import FilterInput from '../../../Components/FilterInput';
import DropDown from '../../../Components/Dropdown/Dropdown';
import Button from '../../../Components/Button/Button';
import {getTOGameList} from '../TOGame/AxioCalls';

class PlayerList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            players: [],
            list: [],
            open: false,
            pageData: {},
            pageOptions: {
                page: 1,
                pageSize: 10,
            },
            sortOptions: [
                {value: 'id', text: 'Player Id'},
                {value: 'updatedAt', text: 'Last Update'},
                {value: 'pubgName', text: 'Pubg Username'},
                {text: 'Position in Squad', value: 'positionInSquad'},
                {text: 'Current Credits', value: 'currentCredits'},
                {text: 'FC Rating', value: 'fcRating'},
                {text: 'Status', value: 'status'},
            ],
        };
    }

    componentDidMount() {
        const {login, history, client} = this.props;
        if (!login?.userDetails?.data) {
            history.push('/');
            this.props.logout();
        } else {
            this.props.getTOGameList({listType: 'ALL'}, login?.userDetails?.data?.accessToken, client.selectedClient);
            this.getPlayerListDebounce({immediate: true});
        }
    }

    componentDidUpdate(prevProps) {
        const {player, toGame} = this.props;

        if (player.playerListRequest && !prevProps.player.playerListRequest) {
            this.setState({loading: true});
        }

        if (player.playerListSuccess && !prevProps.player.playerListSuccess) {
            this.setState({
                list: player.playerList.data.data,
                pageData: player.playerList.data.pageData,
                loading: false,
            });
        } else if (player.playerListFail && !prevProps.player.playerListFail) {
            this.setState({errorMessage: player.errorMessage, loading: false});
        }
        if (player.deleteplayersuccess && !prevProps.player.deleteplayersuccess) {
            this.setState({
                errorMessage: player.deletePlayer.msg,
                players: [],
                loading: false,
            });
            this.getPlayerListDebounce({immediate: true});
        } else if (player.deleteplayerFail && !prevProps.player.deleteplayerFail) {
            this.setState({errorMessage: player.errorMessage, loading: false});
        }

        if (toGame.TOGameListSuccess && !prevProps.toGame.TOGameListSuccess) {
            const games = toGame?.TOGameList?.data?.data?.map((item) => ({text: item.gameName, value: item.id}));
            this.setState({gameOptions: games});
        }
    }

    getPlayerListDebounce = debounce(() => {
        const {login, client} = this.props;
        this.props.getPlayerList(this.state.pageOptions, login?.userDetails?.data?.accessToken, client.selectedClient);
    });

    handleFilter = (field, value, debounce = true) => {
        const {pageOptions} = this.state;
        if (field === ENTER) {
            this.getPlayerListDebounce({immediate: true});
            return;
        }
        pageOptions[field] = value;
        this.setState({pageOptions: {...pageOptions, page: 1}}, () => {
            this.getPlayerListDebounce(debounce ? {} : {immediate: true});
        });
    };

    onReset = () => {
        this.setState({pageOptions: {page: 1, pageSize: 10}}, () => {
            this.getPlayerListDebounce({immediate: true});
        });
    };

    /**
     * api call on click of next
     */
    onNext() {
        const {pageData, pageOptions} = this.state;
        let options = next(pageOptions, pageData);
        if (options) {
            this.setState({pageOptions: options}, () => this.getPlayerListDebounce({immediate: true}));
        }
    }

    /**
     * api call on click of prev
     */
    onPrev() {
        const {pageOptions} = this.state;
        let options = prev(pageOptions);
        if (options) {
            this.setState({pageOptions: options}, () => this.getPlayerListDebounce({immediate: true}));
        }
    }

    /**
     * navigate to selected player page with details
     * @param {string} id id of player selected
     */
    editPlayerData(id) {
        const {history, player} = this.props;
        let players = player.playerList.data.data;
        const editPlayer = players.filter((player) => player.id === id);
        this.props.editPlayer(editPlayer[0]);
        history.push('/editPlayer/' + id);
    }

    handleSelectUpcoming = () => {
        const {pageOptions} = this.state;
        let options = pageOptions;
        options.playerWithoutImageInUpcmingMatches = !options.playerWithoutImageInUpcmingMatches;
        this.setState({pageOptions: options}, () => this.getPlayerListDebounce({immediate: true}));
    };

    render() {
        const {list, errorMessage, loading, pageData, gameOptions, sortOptions} = this.state;
        const {pubgName, playerId, gameId, sortOrder, page, playerWithoutImageInUpcmingMatches} =
            this.state.pageOptions;
        const {history, login} = this.props;
        const {playersFcRating} = login.permissions || {};
        const data = list;
        let disableNext = page === pageData.lastPage;

        const columns = [
            {
                Header: 'Last Updated',
                accessor: 'updatedAt',
                width: 100,
                Cell: (props) => (
                    <span className="number date-space">
                        {props.value === undefined || props.value === '' ? '-' : timeFormat(props.value)}
                    </span>
                ),
            },
            {
                Header: 'Last Match',
                accessor: 'lastMatch',
                width: 100,
                Cell: (props) => (
                    <span className="number date-space">{props.value === '' ? '-' : timeFormat(props.value)}</span>
                ),
            },
            {
                Header: 'Next Match',
                accessor: 'nextMatch',
                width: 100,
                Cell: (props) => (
                    <span className="number date-space">{props.value === '' ? '-' : timeFormat(props.value)}</span>
                ),
            },
            {
                Header: 'Player ID',
                accessor: 'id',
                width: 200,
                Cell: (props) => (
                    <span className="table-clickable cursor" onClick={this.editPlayerData.bind(this, props.value)}>
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Player Name',
                accessor: 'pubgName',
                width: 150,
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Display Name',
                accessor: 'displayName',
                width: 150,
                Cell: (props) => <span className="number">{props.value || '-'}</span>,
            },
            {
                Header: 'Squad',
                accessor: 'squadName',
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Game',
                accessor: 'game',
                width: 150,
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Position in Squad',
                accessor: 'positionInSquad',
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Current Credits',
                accessor: 'currentCredits',
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'FC Rating',
                accessor: 'fcRating',
                show: !!playersFcRating,
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
        ];
        return (
            <TableLayout location="Players" history={history}>
                {!!loading && <LoadingModal open={!!loading} />}
                {!!errorMessage && (
                    <Snackbar
                        open={!!errorMessage}
                        message={errorMessage}
                        onClose={() => this.setState({errorMessage: ''})}
                    />
                )}

                <div className="filter-container my-10">
                    <FilterInput
                        placeholder="Player Name"
                        value={pubgName || ''}
                        onChange={(value) => this.handleFilter('pubgName', value)}
                        onEnter={() => this.handleFilter(ENTER)}
                    />
                    <FilterInput
                        placeholder="Player Id"
                        value={playerId || ''}
                        onChange={(value) => this.handleFilter('playerId', value)}
                        onEnter={() => this.handleFilter(ENTER)}
                    />
                    <DropDown
                        label="Game"
                        value={gameId || ''}
                        menuItems={gameOptions || []}
                        onChange={(value) => this.handleFilter('gameId', value, false)}
                    />
                    <DropDown
                        label="Sort By"
                        value={sortOrder || ''}
                        menuItems={sortOptions}
                        onChange={(value) => this.handleFilter('sortOrder', value, false)}
                    />
                    <Button buttonText="Reset" onClick={this.onReset} />
                </div>

                <div>
                    <input
                        type="checkbox"
                        checked={!!playerWithoutImageInUpcmingMatches}
                        onChange={this.handleSelectUpcoming.bind(this)}
                    />
                    Players in upcoming matches without image
                </div>

                <Table
                    data={data}
                    columns={columns}
                    page={page}
                    onPrevClick={this.onPrev.bind(this)}
                    onNextClick={this.onNext.bind(this)}
                    disableNext={disableNext}
                />
            </TableLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        squad: state.squad,
        player: state.player,
        toGame: state.toGame,
        client: state.client,
    };
};

export default connect(mapStateToProps, {getPlayerList, editPlayer, deletePlayers, getTOGameList, logout})(PlayerList);
