import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CloudUpload from '@material-ui/icons/CloudUpload';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import DateFnsUtils from '@date-io/date-fns';
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import moment from 'moment';

import '../../../Utilities/style.css';
import '../Login/login.css';
import {fetchTdsList, reviewTdsCertificate} from './action';
import {logout} from '../Login/LoginAction';
import {next, prev} from '../../../Utilities/HelperFunctions';
import {timeFormat} from '../../../Utilities/Helpers';

import TableLayout from '../../Layouts/TableLayout/TableLayout';
import Table from '../../../Components/Table/Table';
import Snackbar from '../../../Components/Snackbar/Snackbar';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';
import FilterInput from '../../../Components/FilterInput';
import Button from '../../../Components/Button/Button';
import {ENTER, NEXT, TDS_CONSTANTS} from '../../../Utilities/Constants';
import {useDebounce, usePrevious} from '../../../Utilities/hooks';
import DropDown from '../../../Components/Dropdown/Dropdown';
import UploadTdsCertificate from './UploadTdsCertificate';
import TextModal from '../../../Components/TextModal';

const UserTDSCertificates = (props) => {
    const {history} = props;
    const initialFilter = {page: 1, pageSize: 20, immediate: true};
    const dispatch = useDispatch();
    const login = useSelector((state) => state.login);
    const client = useSelector((state) => state.client);
    const tdsCertificates = useSelector((state) => state.tdsCertificates);
    const prevTdsCertificates = usePrevious(tdsCertificates);
    const {
        tdsListData: {data: listData = [], pageData = {}},
        isFetchingTdsList,
        isReviewingTdsCertificate,
    } = tdsCertificates;
    let [filters, setFilters] = useState(initialFilter);
    const debouncedFilters = useDebounce(filters, {immediate: filters.immediate});
    let [state, setState] = useState({});
    let [uploadTdsDetail, setUploadTdsDetails] = useState(null);
    let [reviewActionData, setReviewActionData] = useState(null);
    const {errorMessage, loading, uploadTdsDoc} = state;
    const disableNext = filters.page >= pageData.lastPage;
    const isLoading = isFetchingTdsList || isReviewingTdsCertificate || loading || false;
    const {tdsWrite, tdsAdvanceWrite} = login?.permissions || {};

    useEffect(() => {
        if (!login?.userDetails?.data) {
            history.push('/');
            dispatch(logout());
        }
    }, []);

    useEffect(() => {
        if (tdsCertificates.isFetchingTdsListFail && !prevTdsCertificates.isFetchingTdsListFail) {
            handleOnStateChange({errorMessage: tdsCertificates?.message?.msg});
        }
        if (tdsCertificates.isReviewingTdsCertificateFail && !prevTdsCertificates.isReviewingTdsCertificateFail) {
            handleOnStateChange({errorMessage: tdsCertificates?.message?.msg});
        }

        if (tdsCertificates.isUploadingTdsCertificateSuccess && !prevTdsCertificates.isUploadingTdsCertificateSuccess) {
            handleCallUserTdsList();
            setUploadTdsDetails(null);
            handleOnStateChange({errorMessage: tdsCertificates?.message?.msg});
        }

        if (tdsCertificates.isReviewingTdsCertificateSuccess && !prevTdsCertificates.isReviewingTdsCertificateSuccess) {
            handleCallUserTdsList();
            setReviewActionData(null);
            handleOnStateChange({errorMessage: tdsCertificates?.message?.msg});
        }
    }, [tdsCertificates]);

    useEffect(() => {
        if (login?.userDetails?.data) handleCallUserTdsList();
    }, [debouncedFilters]);

    const handleCallUserTdsList = () => {
        const {immediate, ..._filters} = filters;
        if (_filters.year) _filters.year = parseInt(moment(_filters.year).format('yyyy'));
        dispatch(fetchTdsList(_filters, login?.userDetails?.data?.accessToken, client.selectedClient));
    };

    /* api call on click of next or prev */
    const onNextORPrev = (action) => {
        const updatedFilters = action === NEXT ? next(filters, pageData) : prev(filters);
        if (updatedFilters) setFilters({...updatedFilters});
    };

    /* reset transaction filters */
    const onReset = () => {
        setFilters(initialFilter);
    };

    const handleFilter = (field, value, debounce = true) => {
        if (field === ENTER) debounce = false;
        else filters[field] = value;

        setFilters({...filters, page: 1, immediate: !debounce});
    };

    const handleOnStateChange = (stateObj = {}) => {
        setState((prevState) => ({...prevState, ...stateObj}));
    };

    const handleClickOnUploadCertificate = (tds) => {
        setUploadTdsDetails({userId: tds.userId, quarter: tds.quarter, year: tds.year});
    };

    const handleClickOnReviewCertificate = (tds, status) => {
        setReviewActionData({
            userId: tds.userId,
            quarter: tds.quarter,
            year: tds.year,
            reviewStatus: status,
            certificateKey: tds.certificateKey,
        });
    };

    const handleCallReviewTds = (reviewComment) => {
        const {userId, quarter, year, reviewStatus, certificateKey} = reviewActionData;
        dispatch(
            reviewTdsCertificate(
                {
                    userId,
                    quarter,
                    year,
                    reviewStatus,
                    certificateKey,
                    reviewComment,
                },
                login?.userDetails?.data?.accessToken,
                client.selectedClient,
            ),
        );
    };

    const columns = [
        {
            Header: 'ID',
            accessor: 'id',
            width: 200,
            Cell: (props) => <span>{props?.value || '-'}</span>,
        },
        {
            Header: 'User Id',
            accessor: 'userId',
            width: 200,
            Cell: (props) => <span>{props?.value || '-'}</span>,
        },
        {
            Header: 'Quarter',
            accessor: 'quarter',
            Cell: (props) => <span>{props?.value || '-'}</span>,
        },
        {
            Header: 'Pan No.',
            accessor: 'panId',
            Cell: (props) => <span>{props?.value || '-'}</span>,
        },
        {
            Header: 'Year',
            accessor: 'year',
            Cell: (props) => <span>{props?.value || '-'}</span>,
        },
        {
            Header: 'Net Winnings',
            accessor: 'netWinnings',
            Cell: (props) => <span>{props.value ? props?.value?.toFixed(2) : '-'}</span>,
        },
        {
            Header: 'TDS Deducted',
            accessor: 'tdsDeducted',
            Cell: (props) => <span>{props.value ? props?.value?.toFixed(2) : '-'}</span>,
        },
        {
            Header: 'Total Winnings',
            accessor: 'totalWinnings',
            Cell: (props) => <span>{props.value ? props?.value?.toFixed(2) : '-'}</span>,
        },
        {
            Header: 'Status',
            accessor: 'status',
            Cell: (props) => <span>{props?.value || '-'}</span>,
        },
        {
            Header: 'CreatedAt',
            accessor: 'createdAt',
            width: 100,
            Cell: (props) => <span className="number date-space">{props?.value ? timeFormat(props?.value) : '-'}</span>,
        },
        {
            Header: 'Actions',
            accessor: 'status',
            Cell: (props) => (
                <>
                    {!!props?.original?.tdsCertificate && (
                        <VisibilityIcon
                            titleAccess="View Certificate"
                            className="clickable-icon mr-5"
                            onClick={() => window.open(props?.original?.tdsCertificate, '_blank')}
                        />
                    )}
                    {!!tdsWrite &&
                        !![TDS_CONSTANTS.STATUS.NOT_UPLOADED, TDS_CONSTANTS.STATUS.REJECTED].includes(props?.value) && (
                            <CloudUpload
                                titleAccess="Upload Certificate"
                                className="clickable-icon mr-5"
                                onClick={() => handleClickOnUploadCertificate(props?.original)}
                            />
                        )}
                </>
            ),
        },
        {
            Header: 'Review',
            accessor: 'status',
            show: !!tdsAdvanceWrite,
            Cell: (props) => (
                <>
                    {props?.value === TDS_CONSTANTS.STATUS.UPLOADED && (
                        <CheckIcon
                            titleAccess="Verify Certificate"
                            className="clickable-icon mr-5 green-color"
                            onClick={() =>
                                handleClickOnReviewCertificate(props?.original, TDS_CONSTANTS.STATUS.VERIFIED)
                            }
                        />
                    )}
                    {props?.value === TDS_CONSTANTS.STATUS.UPLOADED && (
                        <CloseIcon
                            titleAccess="Reject Certificate"
                            className="clickable-icon mr-5 red-color"
                            onClick={() =>
                                handleClickOnReviewCertificate(props?.original, TDS_CONSTANTS.STATUS.REJECTED)
                            }
                        />
                    )}
                </>
            ),
        },
    ];

    return (
        <TableLayout location="TDS Certificates" history={history} hideSearchBar>
            {!!isLoading && <LoadingModal open={!!isLoading} />}
            {!!errorMessage && (
                <Snackbar
                    open={!!errorMessage}
                    message={errorMessage}
                    onClose={() => handleOnStateChange({errorMessage: ''})}
                />
            )}

            {!!uploadTdsDetail && (
                <UploadTdsCertificate
                    open={!!uploadTdsDetail}
                    handleClose={() => setUploadTdsDetails(null)}
                    details={uploadTdsDetail}
                />
            )}

            {!!reviewActionData && (
                <TextModal
                    open={!!reviewActionData}
                    heading="Review TDS Certificate"
                    label="Enter Comment Here"
                    handleClose={() => setReviewActionData(null)}
                    handleOnSubmit={(reviewComment) => handleCallReviewTds(reviewComment)}
                />
            )}

            <div className="filter-container my-10">
                <FilterInput
                    placeholder="User Id"
                    value={filters.userId || ''}
                    onChange={(value) => handleFilter('userId', value)}
                    onEnter={() => handleFilter(ENTER)}
                />
                <FilterInput
                    placeholder="Pan No."
                    value={filters.panId || ''}
                    onChange={(value) => handleFilter('panId', value)}
                    onEnter={() => handleFilter(ENTER)}
                />
                <DropDown
                    label="Status"
                    value={filters.status || ''}
                    menuItems={TDS_CONSTANTS.STATUS_OPTIONS}
                    onChange={(value) => handleFilter('status', value, false)}
                />
                <DropDown
                    label="Quarter"
                    value={filters.quarter || ''}
                    menuItems={TDS_CONSTANTS.QUARTER_OPTIONS}
                    onChange={(value) => handleFilter('quarter', value, false)}
                />
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                        label="Year"
                        format="yyyy"
                        views={['year']}
                        value={filters.year || null}
                        minDate={moment('2020')}
                        onChange={(value) => handleFilter('year', value, false)}
                    />
                </MuiPickersUtilsProvider>
                <Button buttonText="Reset" onClick={onReset} />
            </div>

            <Table
                data={listData}
                columns={columns}
                page={filters.page}
                onPrevClick={onNextORPrev}
                onNextClick={() => onNextORPrev(NEXT)}
                disableNext={disableNext}
            ></Table>
        </TableLayout>
    );
};

export default React.memo(UserTDSCertificates);
