import React from 'react';
import '../../../Utilities/style.css';
import '../Login/login.css';
import TableLayout from '../../Layouts/TableLayout/TableLayout';
import Table from '../../../Components/Table/Table';
import {connect} from 'react-redux';
import Modal from '../../../Components/Modal/Modal';
import Card from '../../../Components/Card/Card';
import Close from '../../../Images/close_yellow.png';
import Button from '../../../Components/Button/Button';
import {getTeamList, getTeamData} from './AxioCalls';
import {timeFormat} from '../../../Utilities/Helpers';
import Snackbar from '../../../Components/Snackbar/Snackbar';
import {logout} from '../Login/LoginAction';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';
import {next, prev, reset} from '../../../Utilities/HelperFunctions';

class TeamsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: [],
            selectAll: false,
            list: [],
            open: false,
            teamId: null,
            adminpswd: '',
            pageData: {},
            loading: true,
            pageOptions: {
                page: 1,
                pageSize: 10,
                searchString: null,
                sortOrder: null,
            },
            sortOptions: [
                {
                    value: 'id',
                    text: 'Team Id',
                },

                {
                    value: 'user',
                    text: 'User Id',
                },
                {
                    value: 'match',
                    text: 'Match Id',
                },
                {
                    value: 'updatedAt',
                    text: 'Last Updated',
                },

                {
                    value: 'status',
                    text: 'Status',
                },
            ],
        };
    }

    /**
     * function to save entered data in state
     * @param {string} field field names from the form
     * @param {*} value value in the field
     */
    handleInputChange(field, value) {
        this.setState({
            [field]: value,
            snackbarOpen: false,
            errorMessage: '',
        });
    }

    componentWillMount() {
        const {login, history, client} = this.props;
        if (!login.userDetails.data) {
            history.push('/');
            this.props.logout();
        } else {
            const {pageOptions} = this.state;

            this.props.getTeamList([pageOptions], login.userDetails.data.accessToken, client.selectedClient);
        }
    }

    componentDidUpdate(prevProps) {
        const {history, team} = this.props;

        if (team.teamListSuccess && !prevProps.team.teamListSuccess) {
            this.setState({
                list: team.teamList.data.data,
                pageData: team.teamList.data.pageData,
                loading: false,
            });
        } else if (team.teamListFail && !prevProps.team.teamListFail) {
            this.setState({
                snackbarOpen: true,
                errorMessage: team.errorMessage,
                loading: false,
            });
        }
        if (team.teamDataListSuccess && !prevProps.team.teamDataListSuccess) {
            history.push('/teamDetails/' + this.state.teamId);
        } else if (team.teamDataListFail && !prevProps.team.teamDataListFail) {
            this.setState({
                snackbarOpen: true,
                errorMessage: team.errorMessage,
                loading: false,
            });
        }
    }

    /**
     * api call to get details of selected team ID clicked
     * @param {*} id Id of the team clicked
     */
    // getTeam(id) {
    //     const {login} = this.props;

    //     let data = {
    //         teamId: id,
    //     };
    //     this.props.getTeamData(data, login.userDetails.data.accessToken);
    //     this.setState({
    //         teamId: id,
    //         loading: true,
    //     });
    // }

    /**
     * APi call with search and sort strings
     * @param {string} field field names of input
     * @param {string} value value in the field
     */
    onSearchandSort(field, value) {
        const {pageOptions} = this.state;
        const {login, client} = this.props;
        let options = pageOptions;
        options[field] = value;
        this.setState({pageOptions: options});

        if (field === 'searchString' && value.length > 2) {
            this.props.getTeamList(options, login.userDetails.data.accessToken, client.selectedClient);
        } else if (field === 'sortOrder') {
            this.props.getTeamList(options, login.userDetails.data.accessToken, client.selectedClient);
        }
    }

    /**
     * pagination and api call on next click
     */
    onNext() {
        const {pageData, pageOptions} = this.state;
        const {login, client} = this.props;
        let options = next(pageOptions, pageData);

        if (options) {
            this.props.getTeamList(options, login.userDetails.data.accessToken, client.selectedClient);
            this.setState({pageOptions: options, loading: true});
        }
    }

    /**
     * pagination and api call on  prev click
     */
    onPrev() {
        const {pageOptions} = this.state;
        const {login, client} = this.props;
        let options = prev(pageOptions);
        if (options) {
            this.props.getTeamList(options, login.userDetails.data.accessToken, client.selectedClient);
            this.setState({pageOptions: options, loading: true});
        } else {
            return false;
        }
    }

    /**
     * reset table data
     */
    onReset() {
        const {pageOptions} = this.state;
        const {login, client} = this.props;
        let options = reset(pageOptions);
        this.setState({
            pageOptions: options,
        });
        this.props.getTeamList(options, login.userDetails.data.accessToken, client.selectedClient);
    }

    /**
     * display contest details one below other in table
     * @param {*} contests array of contests
     */
    dispContest(contests) {
        if (contests.length > 0) {
            return contests.map((contest) => {
                return <div>{contest}</div>;
            });
        } else {
            return '-';
        }
    }

    render() {
        const {list, open, loading, snackbarOpen, errorMessage, sortOptions, pageData} = this.state;
        const {searchString, sortOrder, page} = this.state.pageOptions;

        let disableNext = page === pageData.lastPage ? true : false;
        const data = list;
        const {history, login} = this.props;
        const {teamFantasyPoint} = login?.permissions || {};

        const columns = [
            {
                Header: 'Team ID',
                accessor: 'id',
                width: 200,
                Cell: (props) => (
                    <span
                    // className="table-clickable cursor" onClick={this.getTeam.bind(this, props.value)}
                    >
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'User Name',
                width: 200,
                accessor: 'userName',

                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Match ID',
                accessor: 'match',
                width: 200,
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Match Name',
                accessor: 'matchName',
                width: 200,
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Contest IDs',
                accessor: 'contests',
                width: 200,
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : this.dispContest(props.value)}
                    </span>
                ),
            },

            {
                Header: 'Match Start Time',
                accessor: 'matchStartTime',
                Cell: (props) => (
                    <span className="number date-space">
                        {props.value === undefined || props.value === '' ? '-' : timeFormat(props.value)}
                    </span>
                ),
            },
            {
                Header: 'Last Updated',
                accessor: 'updatedAt',
                Cell: (props) => (
                    <span className="number date-space">
                        {props.value === undefined || props.value === '' ? '-' : timeFormat(props.value)}
                    </span>
                ),
            },
            {
                Header: 'Fantasy Points',
                accessor: 'fantasyPoints',
                show: !!teamFantasyPoint,
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },

            {
                Header: 'Status',
                accessor: 'status',
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
        ];
        return (
            <TableLayout
                location="Teams"
                history={history}
                searchbar="Search Team ID, User Name, Match ID"
                onDeleteClick={this.onDeleteClick}
                onsearchChange={this.onSearchandSort.bind(this)}
                sortOptions={sortOptions}
                onReset={this.onReset.bind(this)}
                searchString={searchString}
                sortOrder={sortOrder}
            >
                <LoadingModal open={loading} />
                <Snackbar
                    open={snackbarOpen}
                    message={errorMessage}
                    onClose={() => this.setState({snackbarOpen: false})}
                />
                <Modal open={open}>
                    <div className="container modal-top-margin">
                        <Card>
                            <div className="disp-flex">
                                <div className="modal-left-header">Delete Contest</div>
                                <div onClick={() => this.setState({open: false})}>
                                    <img src={Close} alt="" className="cursor" />
                                </div>
                            </div>
                            <div className="align-center equalcontainer-grids">
                                <div />
                                <div>
                                    <div className="align-center margin-top-30">
                                        <Button buttonText="Delete Contest" onClick={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                </Modal>
                <Table
                    data={data}
                    columns={columns}
                    pageSize={5}
                    page={page}
                    onPrevClick={this.onPrev.bind(this)}
                    onNextClick={this.onNext.bind(this)}
                    disableNext={disableNext}
                ></Table>
            </TableLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        squad: state.squad,
        player: state.player,
        team: state.team,
        client: state.client,
    };
};

export default connect(mapStateToProps, {getTeamList, getTeamData, logout})(TeamsList);
