import React from 'react';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '../../Images/search_big.png';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import PropTypes from 'prop-types';

class FilterInput extends React.Component {
    static propTypes = {
        onChange: PropTypes.func,
        onEnter: PropTypes.func,
        value: PropTypes.string,
        placeholder: PropTypes.string,
        disabled: PropTypes.bool,
    };

    static defaultProps = {
        onChange: () => {},
        onEnter: () => {},
        value: '',
        placeholder: '',
        disabled: false,
    };

    constructor(props) {
        super(props);
    }

    handleInputChange = (e) => {
        this.props.onChange(e.target.value);
    };

    keyPress = (e) => {
        if (e.keyCode === 13) {
            this.props.onEnter();
        }
    };

    render() {
        const {value, placeholder, disabled} = this.props;

        return (
            <TextField
                placeholder={placeholder}
                value={value}
                variant="outlined"
                margin="normal"
                onChange={this.handleInputChange}
                onKeyDown={this.keyPress}
                InputProps={{
                    startAdornment: (
                        <InputAdornment>
                            <IconButton size="small">
                                <img alt="" src={SearchIcon} />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                disabled={disabled}
            />
        );
    }
}

export default FilterInput;
