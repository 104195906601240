import axios from 'axios';
import {
    listEvents,
    listEventsSuccess,
    listEventsFail,
    recordMatch,
    recordMatchSuccess,
    recordMatchFail,
    verifyMatch,
    verifyMatchSuccess,
    verifyMatchFail,
    completeMatch,
    completeMatchSuccess,
    completeMatchFail,
    updateEvent,
    updateEventSuccess,
    updateEventFail,
    deleteEvent,
    deleteEventSuccess,
    deleteEventFail,
    playersForMatchRequest,
    playersForMatchSuccess,
    playersForMatchFail,
    compileMatch,
    compileMatchSuccess,
    compileMatchFail,
} from './action';
import {URL} from '../../../Utilities/Constants';
import {
    COMPLETE_MATCH_V2,
    COMPLETE_MATCH_V2_SUCCESS,
    COMPLETE_MATCH_V2_FAIL,
    WINNING_DISBURSAL_DETAILS,
    WINNING_DISBURSAL_DETAILS_SUCCESS,
    WINNING_DISBURSAL_DETAILS_FAIL,
    COMPILE_MATCH_V2,
    COMPILE_MATCH_V2_SUCCESS,
    COMPILE_MATCH_V2_FAIL,
    PUBLISH_MATCH_SUMMARY,
    PUBLISH_MATCH_SUMMARY_SUCCESS,
    PUBLISH_MATCH_SUMMARY_FAIL,
    VERIFY_SWITCH_LEADERBOARD_POINTS,
    VERIFY_SWITCH_LEADERBOARD_POINTS_SUCCESS,
    VERIFY_SWITCH_LEADERBOARD_POINTS_FAIL,
} from './Constants';
import apis from '../../../apiCalls/apis';

/**
 * get event list api request
 * @param {*} id match id
 * @param {string} token user token
 */
export function getEventsList(id, token, clientId) {
    return (dispatch) => {
        dispatch(listEvents());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token, clientid: clientId},
            url: `${URL}/matchEvent/list`,
            data: {
                match: id,
            },
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(listEventsSuccess(data));
                } else if (data.data.status === 'fail') {
                    dispatch(listEventsFail(data.data.errorData.errorReason));
                } else {
                    dispatch(listEventsFail('Unknown Error Occured'));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(listEventsFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(listEventsFail(err.message || 'Something went wrong'));
                }
            });
    };
}

/**
 * record event api request
 * @param {*} event event details
 * @param {string} token user token
 */
export function recordMatchEvent(event, token, clientId) {
    return (dispatch) => {
        dispatch(recordMatch());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token, clientid: clientId},
            url: `${URL}/matchEvent/record`,
            data: event,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(recordMatchSuccess(data));
                } else if (data.data.status === 'fail') {
                    dispatch(recordMatchFail(data.data.errorData.errorReason));
                } else {
                    dispatch(recordMatchFail('Unknown Error Occured'));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(recordMatchFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(recordMatchFail(err.message || 'Something went wrong'));
                }
            });
    };
}

/**
 * Deprecated: support for all attributes live update to be added whenever this function is revived.
 *  verify match api request
 * @param {*} match match details
 * @param {string} token user token
 */
export function verifyMatchEvent(match, token, clientId) {
    return (dispatch) => {
        dispatch(verifyMatch());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token, clientid: clientId},
            url: `${URL}/matchEvent/verify`,
            data: match,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(verifyMatchSuccess(data));
                } else if (data.data.status === 'fail') {
                    dispatch(verifyMatchFail(data.data.errorData.errorReason));
                } else {
                    dispatch(verifyMatchFail('Unknown Error Occured'));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(verifyMatchFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(verifyMatchFail(err.message || 'Something went wrong'));
                }
            });
    };
}

/**
 * mark match as complete api request
 * @param {*} id match id
 * @param {string} token user token
 */
export function matchComplete(data, token, clientId) {
    return (dispatch) => {
        dispatch(completeMatch());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token, clientid: clientId},
            url: `${URL}/match/complete`,
            data: data,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(completeMatchSuccess(data));
                } else if (data.data.status === 'fail') {
                    dispatch(completeMatchFail(data.data.errorData.errorReason));
                } else {
                    dispatch(completeMatchFail('Unknown Error Occured'));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(completeMatchFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(completeMatchFail(err.message || 'Something went wrong'));
                }
            });
    };
}

export const matchCompleteV2 = (data, token, clientId) => async (dispatch) => {
    dispatch({type: COMPLETE_MATCH_V2});
    try {
        const response = await apis.matchCompleteV2(data, token, clientId);
        if (response?.data?.status === 'success') {
            dispatch({type: COMPLETE_MATCH_V2_SUCCESS, payload: response.data});
        } else if (response?.data?.status === 'fail') {
            dispatch({
                type: COMPLETE_MATCH_V2_FAIL,
                payload: {msg: response?.data?.errorData?.errorReason || 'Something went wrong'},
            });
        }
    } catch (e) {
        if (e.response) {
            const errorMsg = e?.response?.data?.errorData?.errorReason || e?.response?.data?.msg || e.message;
            dispatch({type: COMPLETE_MATCH_V2_FAIL, payload: {msg: errorMsg || 'Something went wrong'}});
        } else {
            dispatch({type: COMPLETE_MATCH_V2_FAIL, payload: {msg: e?.message || 'Unknown Error Occured'}});
        }
    }
};

export const fetchWinningDisbursalDetails = (data, token, clientId) => async (dispatch) => {
    dispatch({type: WINNING_DISBURSAL_DETAILS});
    try {
        const response = await apis.winningDisbursalDetails(data, token, clientId);
        if (response?.data?.status === 'success') {
            dispatch({type: WINNING_DISBURSAL_DETAILS_SUCCESS, payload: response.data.data});
        } else if (response?.data?.status === 'fail') {
            dispatch({
                type: WINNING_DISBURSAL_DETAILS_FAIL,
                payload: {msg: response?.data?.errorData?.errorReason || 'Something went wrong'},
            });
        }
    } catch (e) {
        if (e.response) {
            const errorMsg = e?.response?.data?.errorData?.errorReason || e?.response?.data?.msg || e.message;
            dispatch({type: WINNING_DISBURSAL_DETAILS_FAIL, payload: {msg: errorMsg || 'Something went wrong'}});
        } else {
            dispatch({type: WINNING_DISBURSAL_DETAILS_FAIL, payload: {msg: e?.message || 'Unknown Error Occured'}});
        }
    }
};

export const compileMatchV2 = (data, token, clientId) => async (dispatch) => {
    dispatch({type: COMPILE_MATCH_V2});
    try {
        const response = await apis.matchCompileV2(data, token, clientId);
        if (response?.data?.status === 'success') {
            dispatch({type: COMPILE_MATCH_V2_SUCCESS, payload: response.data});
        } else if (response?.data?.status === 'fail') {
            dispatch({
                type: COMPILE_MATCH_V2_FAIL,
                payload: {msg: response?.data?.errorData?.errorReason || 'Something went wrong'},
            });
        }
    } catch (e) {
        if (e.response) {
            const errorMsg = e?.response?.data?.errorData?.errorReason || e?.response?.data?.msg || e.message;
            dispatch({type: COMPILE_MATCH_V2_FAIL, payload: {msg: errorMsg || 'Something went wrong'}});
        } else {
            dispatch({type: COMPILE_MATCH_V2_FAIL, payload: {msg: e?.message || 'Unknown Error Occured'}});
        }
    }
};

/**
 * update event api request
 * @param {*} match match details
 * @param {string} token user token
 */
export function updateMatchEvent(match, token, clientId) {
    return (dispatch) => {
        dispatch(updateEvent());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token, clientid: clientId},
            url: `${URL}/matchEvent/edit`,
            data: match,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(updateEventSuccess(data));
                } else if (data.data.status === 'fail') {
                    dispatch(updateEventFail(data.data.errorData.errorReason));
                } else {
                    dispatch(updateEventFail('Unknown Error Occured'));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(updateEventFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(updateEventFail(err.message || 'Something went wrong'));
                }
            });
    };
}

/**
 * delete event api request
 * @param {*} id match id
 * @param {string} token user token
 */
export function deleteMatchEvent(id, token, clientId) {
    return (dispatch) => {
        dispatch(deleteEvent());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token, clientid: clientId},
            url: `${URL}/matchEvent/delete`,
            data: {
                eventId: id,
            },
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(deleteEventSuccess(data));
                } else if (data.data.status === 'fail') {
                    dispatch(deleteEventFail(data.data.errorData.errorReason));
                } else {
                    dispatch(deleteEventFail('Unknown Error Occured'));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(deleteEventFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(deleteEventFail(err.message || 'Something went wrong'));
                }
            });
    };
}

/**
 * get players list for this match api request
 * @param {*} id match id
 * @param {string} token user token
 */
export function playersForMatch(id, token, clientId) {
    return (dispatch) => {
        dispatch(playersForMatchRequest());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token, clientid: clientId},
            url: `${URL}/player/listForMatch`,
            data: {
                matchId: id,
            },
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(playersForMatchSuccess(data));
                } else if (data.data.status === 'fail') {
                    dispatch(playersForMatchFail(data.data.errorData.errorReason));
                } else {
                    dispatch(playersForMatchFail('Unknown Error Occured'));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(playersForMatchFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(playersForMatchFail(err.message || 'Something went wrong'));
                }
            });
    };
}

/**
 * compile match details api request
 * @param {*} id match id
 * @param {*} token user token
 */
export function matchCompile(id, token, forfeit, clientId) {
    return (dispatch) => {
        dispatch(compileMatch());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token, clientid: clientId},
            url: `${URL}/match/Compile`,
            data: {
                matchId: id,
                forfeit: forfeit ? forfeit : false,
            },
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(compileMatchSuccess(data));
                } else if (data.data.status === 'fail') {
                    dispatch(compileMatchFail(data.data.errorData.errorReason));
                } else {
                    dispatch(compileMatchFail('Unknown Error Occured'));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(compileMatchFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(compileMatchFail(err.message || 'Something went wrong'));
                }
            });
    };
}

export const publishMatchSummary = (data, token, clientId) => async (dispatch) => {
    dispatch({type: PUBLISH_MATCH_SUMMARY});
    try {
        const response = await apis.publishMatchSummary(data, token, clientId);
        if (response.status === 200) {
            dispatch({type: PUBLISH_MATCH_SUMMARY_SUCCESS, payload: response.data});
        } else {
            dispatch({
                type: PUBLISH_MATCH_SUMMARY_FAIL,
                payload: {msg: response?.data?.msg || 'Something went wrong'},
            });
        }
    } catch (e) {
        const err = e?.response?.data?.msg || e?.message || 'Something went wrong';
        if (e.response) {
            dispatch({
                type: PUBLISH_MATCH_SUMMARY_FAIL,
                payload: {msg: err},
            });
        } else {
            dispatch({type: PUBLISH_MATCH_SUMMARY_FAIL, payload: {msg: err}});
        }
    }
};

export const verifySwitchLeaderboardPoints = (data, token, clientId) => {
    return async (dispatch) => {
        dispatch({type: VERIFY_SWITCH_LEADERBOARD_POINTS});
        try {
            const response = await apis.verifySwitchLeaderboardPoints(data, token, clientId);
            if (response.status === 200) {
                dispatch({type: VERIFY_SWITCH_LEADERBOARD_POINTS_SUCCESS, payload: response.data});
            } else {
                dispatch({
                    type: VERIFY_SWITCH_LEADERBOARD_POINTS_FAIL,
                    payload: {msg: response?.data?.msg} || 'Something went wrong!',
                });
            }
        } catch (e) {
            const err = e?.response?.data?.msg || e?.message || 'Something went wrong!';
            if (e.response) {
                dispatch({type: VERIFY_SWITCH_LEADERBOARD_POINTS_FAIL, payload: {msg: err}});
            } else {
                dispatch({type: VERIFY_SWITCH_LEADERBOARD_POINTS_FAIL, payload: {msg: err}});
            }
        }
    };
};
