import {SOMETHING_WENT_WRONG, UNKNOWN_ERROR_OCCURRED} from '../../Utilities/constants/message-constants';
import apis from '../../apiCalls/apis';
import {
    GET_SWITCH_CONFIGS_LIST_FAILURE,
    GET_SWITCH_CONFIGS_LIST_REQUEST,
    GET_SWITCH_CONFIGS_LIST_SUCCESS,
    POST_SWITCH_CONFIG_FAILURE,
    POST_SWITCH_CONFIG_REQUEST,
    POST_SWITCH_CONFIG_SUCCESS,
} from './action-constants';

/**
 * fetch switch configs list api request
 * @param {string} data page details
 * @param {string} token user token
 * @param {string} clientId selected client id
 */
export const fetchSwitchConfigsList = (data, token, clientId) => {
    return async (dispatch) => {
        dispatch({type: GET_SWITCH_CONFIGS_LIST_REQUEST});
        try {
            const response = await apis.fetchSwitchConfigsList(data, token, clientId);
            // console.log("RESPONSE", response);
            if (response.status === 200) {
                dispatch({
                    type: GET_SWITCH_CONFIGS_LIST_SUCCESS,
                    payload: response?.data?.data,
                });
            } else {
                dispatch({
                    type: GET_SWITCH_CONFIGS_LIST_FAILURE,
                    payload: response?.data?.errorData?.errorReason || SOMETHING_WENT_WRONG,
                });
            }
        } catch (e) {
            if (e.response) {
                // console.log("ERROR_RESPONSE", e.response);
                dispatch({
                    type: GET_SWITCH_CONFIGS_LIST_FAILURE,
                    payload: e?.response?.data || SOMETHING_WENT_WRONG,
                });
            } else {
                // console.log("ERROR", e);
                dispatch({
                    type: GET_SWITCH_CONFIGS_LIST_FAILURE,
                    payload: e?.message || UNKNOWN_ERROR_OCCURRED,
                });
            }
        }
    };
};

/**
 * add switch config api request
 * @param {*} data switch config details
 * @param {string} token user token
 * @param {string} clientId selected client id
 */
export const addSwitchConfig = (data, token, clientId) => {
    return async (dispatch) => {
        dispatch({type: POST_SWITCH_CONFIG_REQUEST});
        try {
            const response = await apis.addSwitchConfig(data, token, clientId);
            console.log('RESPONSE', response);
            if (response.status === 200) {
                dispatch({
                    type: POST_SWITCH_CONFIG_SUCCESS,
                    payload: response?.data?.data,
                });
            } else {
                dispatch({
                    type: POST_SWITCH_CONFIG_FAILURE,
                    payload: response?.data?.errorData?.errorReason || SOMETHING_WENT_WRONG,
                });
            }
        } catch (e) {
            if (e.response) {
                console.log('ERROR_RESPONSE', e.response);
                dispatch({
                    type: POST_SWITCH_CONFIG_FAILURE,
                    payload: e?.response?.data?.msg || SOMETHING_WENT_WRONG,
                });
            } else {
                console.log('ERROR', e);
                dispatch({
                    type: POST_SWITCH_CONFIG_FAILURE,
                    payload: e?.message || UNKNOWN_ERROR_OCCURRED,
                });
            }
        }
    };
};
