export const USER_LIST = 'USER_LIST';
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS';
export const USER_LIST_FAIL = 'USER_LIST_FAIL';

export const BLOCK_USER = 'BLOCK_USER';
export const BLOCK_USER_SUCCESS = 'BLOCK_USER_SUCCESS';
export const BLOCK_USER_FAIL = 'BLOCK_USER_FAIL';
export const UNBLOCK_USER = 'UNBLOCK_USER';
export const UNBLOCK_USER_SUCCESS = 'UNBLOCK_USER_SUCCESS';
export const UNBLOCK_USER_FAIL = 'UNBLOCK_USER_FAIL';

export const APPROVE_KYC = 'APPROVE_KYC';
export const APPROVE_KYC_SUCCESS = 'APPROVE_KYC_SUCCESS';
export const APPROVE_KYC_FAIL = 'APPROVE_KYC_FAIL';

export const USERTRANSACTION = 'USERTRANSACTION';
export const USERTRANSACTION_SUCCESS = 'USERTRANSACTION_SUCCESS';
export const USERTRANSACTION_FAIL = 'USERTRANSACTION_FAIL';

export const FETCH_APP_USERS = 'FETCH_APP_USERS';
export const FETCH_APP_USERS_SUCCESS = 'FETCH_APP_USERS_SUCCESS';
export const FETCH_APP_USERS_FAIL = 'FETCH_APP_USERS_FAIL';

export const CURRENT_USER = 'CURRENT_USER';

export const GET_USER_KYC_DOCS = 'GET_USER_KYC_DOCS';
export const GET_USER_KYC_DOCS_SUCCESS = 'GET_USER_KYC_DOCS_SUCCESS';
export const GET_USER_KYC_DOCS_FAIL = 'GET_USER_KYC_DOCS_FAIL';

export const MANUALLY_BANKING_KYC = 'MANUALLY_BANKING_KYC';
export const MANUALLY_BANKING_KYC_SUCCESS = 'MANUALLY_BANKING_KYC_SUCCESS';
export const MANUALLY_BANKING_KYC_FAIL = 'MANUALLY_BANKING_KYC_FAIL';

export const USER_KYC_MANUAL_ACTION_REQUEST = 'USER_KYC_MANUAL_ACTION_REQUEST';
export const USER_KYC_MANUAL_ACTION_SUCCESS = 'USER_KYC_MANUAL_ACTION_SUCCESS';
export const USER_KYC_MANUAL_ACTION_FAILURE = 'USER_KYC_MANUAL_ACTION_FAILURE';
