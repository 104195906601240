import {
    WITHDRAWAL_LIST,
    WITHDRAWAL_LIST_SUCCESS,
    WITHDRAWAL_LIST_FAIL,
    WITHDRAWAL_UPDATE,
    WITHDRAWAL_UPDATE_SUCCESS,
    WITHDRAWAL_UPDATE_FAIL,
} from './Constants';

/**
 * list all transcations api request
 */
export function listWithdrawal() {
    return {
        type: WITHDRAWAL_LIST,
    };
}
/**
 *handle list all transaction success
 * @param {*} data data from api
 */
export function listWithdrawalSuccess(data) {
    return {
        type: WITHDRAWAL_LIST_SUCCESS,
        payload: data,
    };
}

/**
 *handle list all transaction fail
 * @param {*} error error from api
 */
export function listWithdrawalFail(error) {
    return {
        type: WITHDRAWAL_LIST_FAIL,
        payload: error,
    };
}

// withdrawal update
/**
 * update transcations api request
 */
export function updateWithdrawal() {
    return {
        type: WITHDRAWAL_UPDATE,
    };
}
/**
 *handle update transaction success
 * @param {*} data data from api
 */
export function updateWithdrawalSuccess(data) {
    return {
        type: WITHDRAWAL_UPDATE_SUCCESS,
        payload: data,
    };
}
/**
 *handle update all transaction fail
 * @param {*} error error from api
 */
export function updateWithdrawalFail(error) {
    return {
        type: WITHDRAWAL_UPDATE_FAIL,
        payload: error,
    };
}
