export const GET_DYNAMIC_PRIZE_POOLS = 'GET_DYNAMIC_PRIZE_POOLS';
export const GET_DYNAMIC_PRIZE_POOLS_SUCCESS = 'GET_DYNAMIC_PRIZE_POOLS_SUCCESS';
export const GET_DYNAMIC_PRIZE_POOLS_FAIL = 'GET_DYNAMIC_PRIZE_POOLS_FAIL';

export const GET_DYNAMIC_PRIZE_POOL = 'GET_DYNAMIC_PRIZE_POOL';
export const GET_DYNAMIC_PRIZE_POOL_SUCCESS = 'GET_DYNAMIC_PRIZE_POOL_SUCCESS';
export const GET_DYNAMIC_PRIZE_POOL_FAIL = 'GET_DYNAMIC_PRIZE_POOL_FAIL';

export const CREATE_DYNAMIC_PRIZE_POOL = 'CREATE_DYNAMIC_PRIZE_POOL';
export const CREATE_DYNAMIC_PRIZE_POOL_SUCCESS = 'CREATE_DYNAMIC_PRIZE_POOL_SUCCESS';
export const CREATE_DYNAMIC_PRIZE_POOL_FAIL = 'CREATE_DYNAMIC_PRIZE_POOL_FAIL';

export const UPDATE_DYNAMIC_PRIZE_POOL = 'UPDATE_DYNAMIC_PRIZE_POOL';
export const UPDATE_DYNAMIC_PRIZE_POOL_SUCCESS = 'UPDATE_DYNAMIC_PRIZE_POOL_SUCCESS';
export const UPDATE_DYNAMIC_PRIZE_POOL_FAIL = 'UPDATE_DYNAMIC_PRIZE_POOL_FAIL';
