import React from 'react';
import TextField from '@material-ui/core/TextField';
import './SearchInputField.css';
import SearchIcon from '../../Images/search_big.png';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import PropTypes from 'prop-types';

class SearchInputField extends React.Component {
    enteredValue = '';

    static propTypes = {
        onEnterPressed: PropTypes.func,
        onChange: PropTypes.func,
    };

    static defaultProps = {
        onChange: () => {},
        onEnterPressed: () => {},
    };

    constructor(props) {
        super(props);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    /**
     * send field and events to parent component (on select of dowpdown)
     * @param {*} event name of field
     * @param {*} value value in field
     */
    handleInputChange(a) {
        this.props.onChange(a.target.value);
        this.enteredValue = a.target.value;
    }

    keyPress(e) {
        if (e.keyCode == 13) {
            // this.props.onEnterPressed(true);
            this.props.onChange(this.enteredValue);
        } else {
            // this.props.onEnterPressed(false);
        }
    }

    render() {
        const {value, placeholder, disabled} = this.props;

        return (
            <TextField
                placeholder={placeholder}
                value={value}
                variant="outlined"
                margin="normal"
                onChange={this.handleInputChange}
                onKeyDown={this.keyPress.bind(this)}
                InputProps={{
                    startAdornment: (
                        <InputAdornment>
                            <IconButton>
                                <img alt="" src={SearchIcon} />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                disabled={disabled}
            />
        );
    }
}

export default SearchInputField;
