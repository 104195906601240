import React from 'react';
import '../../../Utilities/style.css';
import './TableLayout.css';
// import MainNavbar from "../../../Components/MainNavbar/MainNavbar";
import Searchbar from '../../../Components/SearchBar/SearchBar';
import {connect} from 'react-redux';
import {logout} from '../../Modules/Login/LoginAction';
import PrimaryNavbar from '../../../layout/PrimaryNavbar/PrimaryNavbar';
import UploadImageOnS3Modal from '../../../Components/UploadImageOnS3Modal';

class TableLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillMount() {
        const {login, history} = this.props;

        if (!login.userDetails.data) {
            history.push('/');
            this.props.logout();
        }
    }

    handleChange = (key, value) => {
        this.setState({[key]: value});
    };

    /*onEnterPressed(val) {
        if(this.props.onEnterPressed) this.props.onEnterPressed(val);
    }

    onEnterPressed2(val) {
        if(this.props.onEnterPressed2) this.props.onEnterPressed2(val);
    }*/

    render() {
        const {
            location,
            history,
            searchbar,
            hideSearchBar,
            onDeleteClick,
            options,
            dispDelete,
            updateStatus,
            onsearchChange,
            onSave,
            sortOptions,
            onReset,
            searchString,
            onsearchChange2,
            sortOrder,
            filterOptions,
            searchString2,
            login,
            clientId,
            onChangeClientId,
        } = this.props;
        const permissions = login?.permissions || {};
        const {imageUploadModal} = this.state;
        return (
            <div>
                {/* <MainNavbar location={location} history={history}/> */}
                {!!imageUploadModal && (
                    <UploadImageOnS3Modal
                        open={!!imageUploadModal}
                        handleClose={() => this.handleChange('imageUploadModal', null)}
                    />
                )}
                <PrimaryNavbar history={history} onClickUploadImage={() => this.handleChange('imageUploadModal', {})} />
                <div className={'container ' + (location == 'TOPublishedMatch' ? 'stickySearchStrip' : '')}>
                    {!hideSearchBar && (
                        <div className="margin-top-10 margin-bottom-10">
                            <Searchbar
                                history={history}
                                onReset={onReset}
                                location={location}
                                options={options}
                                sortOptions={sortOptions}
                                filterOptions={filterOptions}
                                updateStatus={updateStatus}
                                onSave={onSave}
                                onDeleteClick={onDeleteClick}
                                sortOrder={sortOrder}
                                dispDelete={dispDelete}
                                searchString={searchString}
                                searchString2={searchString2}
                                onsearchChange={onsearchChange ? onsearchChange : null}
                                onsearchChange2={onsearchChange2 ? onsearchChange2 : null}
                                searchbar={searchbar}
                                searchbar2={this.props.searchbar2 ? this.props.searchbar2 : null}
                                permissions={permissions}
                            />
                        </div>
                    )}
                    {this.props.children}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
    };
};

export default connect(mapStateToProps, {logout})(TableLayout);
