import React from 'react';
import '../../../Utilities/style.css';
import '../Login/login.css';
import {connect} from 'react-redux';
import xlsx from 'xlsx';
import {saveAs} from 'file-saver';
import DetailLayout from '../../Layouts/DetailLayout/DetailLayout';
import SmallTable from '../../../Components/SmallTable/SmallTable';
import Tabs from '../../../Components/Tabs/Tabs';
import Card from '../../../Components/Card/Card';
import Input from '../../../Components/Input/Input';
import DropDown from '../../../Components/Dropdown/Dropdown';
import {archiveTOMatch, getTOMatchPartipation} from './AxioCalls';
import {getTOGameList} from '../TOGame/AxioCalls';
import {getTOMatchTypeList} from '../TOMatchType/AxioCalls';
import {getTOMatchModeList} from '../TOMatchMode/AxioCalls';
import {getTOMatchAttributeList} from '../TOMatchAttribute/AxioCalls';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Snackbar from '../../../Components/Snackbar/Snackbar';
import {logout} from '../Login/LoginAction';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';
import {MuiPickersUtilsProvider, DateTimePicker} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import './Teams.css';
import UpdateMatchResults from '../TOPublishedMatch/UpdateMatchResults';
import MatchDetailsV2 from '../TOPublishedMatch/MatchDetailsV2';
import {fetchS3FileFromServer} from '../TOPublishedMatch/AxioCalls';

class EditTOCompletedMatch extends React.Component {
    constructor(props) {
        super(props);
        let location = this.props.history.location.pathname.includes('edit');
        this.state = {
            name: null,
            startTime: null,
            participation: null,
            filledSlots: null,
            joinedSlots: null,
            roomCredentials: {cred1Name: '', cred1Val: '', cred2Name: '', cred2Val: ''},
            liveStream: null,
            resultsFile: null,
            resultsBook: null,
            uploadFile: null,
            resultColumns: null,
            resultData: null,
            entryFeePerSlot: null,
            totalSlots: null,
            minFillSlots: 0,
            winPoolType: null,
            winPoolTypeOptions: [
                {
                    text: 'Fixed',
                    value: 'Fixed',
                },
                {
                    text: 'Percentage',
                    value: 'Percentage',
                },
            ],
            winFixedPoolSize: 0,
            winPercentPoolSize: 0,
            matchTypeOptions: [],
            matchType: null,
            entryFeeTypeOptions: [
                {
                    text: 'Cash Only',
                    value: 'Cash Only',
                },
                {
                    text: 'Free',
                    value: 'Free',
                },
                {
                    text: 'Cash+Coin',
                    value: 'Cash+Coin',
                },
                {
                    text: 'FcCoin',
                    value: 'FcCoin',
                },
            ],
            entryFeeType: null,
            fcCoinDiscountPercent: 0,
            minParticipationRequiredOptions: [
                {
                    text: 'True',
                    value: true,
                },
                {
                    text: 'False',
                    value: false,
                },
            ],
            minParticipationRequired: false,
            winDenomination: null,
            winDenominationOptions: [
                {text: 'Cash', value: 'cash'},
                {text: 'Coin', value: 'fcCoin'},
                {text: 'Cash Balance', value: 'cashBalance'},
            ],
            winType: null,
            winTypeOptions: [
                {
                    text: 'Fixed',
                    value: 'Fixed',
                },
                {
                    text: 'Distribution',
                    value: 'Distribution',
                },
            ],
            numberOfBigWinTeams: 0,
            winSlots: {},
            winningDistribution: {},
            handlingCharge: null,
            winParameter: null,
            winParameterValue: 0,
            attribute1Options: [],
            attribute1: null,
            attribute1Val: null,
            attribute2Options: [],
            attribute2: null,
            attribute2Val: null,
            matchMode: null,
            matchModeOptions: [],
            matchRules: null,
            status: 'UPCOMING',
            gameOptions: [],
            game: null,
            loading: false,
            open: false,
            apiError: '',
            edit: location,
            snackbarOpen: false,
            targetUsersFilter_loggedIn: false,
            targetUsersFilter_minJoinDate: new Date().getTime(),
            targetUsersByJoinDate: false,
            targetUsersByLoginStatus: false,
            booleanOptions: [
                {text: 'True', value: true},
                {text: 'False', value: false},
            ],
            targetUsersFilter_loggedInOptions: [
                {text: 'Logged In', value: true},
                {text: 'Logged Out', value: false},
            ],
        };
    }

    handleInputChange(field, value) {
        this.setState({
            [field]: value,
            snackbarOpen: false,
            errorMessage: '',
        });
    }

    displayPrizes(prizes) {
        const {winType, winSlots} = this.state;
        if (winType === 'Fixed') {
            return null;
        }
        let inputs = [];
        for (let i = 1; i <= prizes; i++) {
            let unit = '';
            if (i === 1) {
                unit = i + 'st';
            } else if (i === 2) {
                unit = i + 'nd';
            } else if (i === 3) {
                unit = i + 'rd';
            } else {
                unit = i + 'th';
            }
            let val = 'rank' + i;

            inputs.push(
                <div className="grid-item margin-top-20">
                    <Input
                        label={unit}
                        type="text"
                        name={val}
                        suffix="%"
                        value={winSlots.ranks[val]}
                        onChange={this.handleInputChange.bind(this, val)}
                        placeholder="Enter"
                        disabled={false}
                    />
                </div>,
            );
        }
        return inputs;
    }

    onArchive() {
        const {login, toCompletedMatch} = this.props;
        if (
            toCompletedMatch.editTOCompletedMatch.createdByClient &&
            toCompletedMatch.editTOCompletedMatch.createdByClient !== 'control_center'
        ) {
            this.setState({
                snackbarOpen: true,
                errorMessage:
                    'Updates not allowed for match created from ' + this.props.toMatch.editTOMatch.createdByClient,
            });
            return;
        }

        let match = {};
        this.setState({loading: true});
        console.log('Trying to update the data in the server');
        match.matchId = toCompletedMatch.editTOCompletedMatch.id;
        this.props.archiveTOMatch(match, login.userDetails.data.accessToken);
    }

    componentDidMount() {
        const {login, toCompletedMatch, history} = this.props;
        // console.log(moment(a).format("DD/MM/YYYY H:mm"));
        let gamePageOptions = {
            listType: 'ALL',
        };
        if (!login.userDetails.data) {
            history.push('/');
            this.props.logout();
        } else {
            this.setState({
                loading: true,
            });
            const {
                name,
                startTime,
                entryFeePerSlot,
                totalSlots,
                minFillSlots,
                winPoolType,
                winFixedPoolSize,
                winPercentPoolSize,
                matchType,
                liveStream,
                roomCredentials,
                resultsFile,
                entryFeeType,
                fcCoinDiscountPercent,
                minParticipationRequired,
                numberOfBigWinTeams,
                winDenomination,
                winType,
                winParameter,
                winParameterValue,
                winningDistribution,
                winSlots,
                attribute1,
                attribute1Val,
                attribute2,
                attribute2Val,
                matchRules,
                matchMode,
                status,
                game,
                targetUsers,
                targetUsersFilter,
            } = toCompletedMatch.editTOCompletedMatch;
            let targetUsersByJoinDate = false;
            let targetUsersByLoginStatus = false;
            let targetUsersFilter_loggedIn = false;
            let targetUsersFilter_minJoinDate = new Date().getTime();
            if (targetUsers) {
                if (targetUsersFilter.loggedIn !== undefined) {
                    targetUsersByLoginStatus = true;
                    targetUsersFilter_loggedIn = targetUsersFilter.loggedIn;
                }
                if (targetUsersFilter.minJoinDate !== undefined) {
                    targetUsersByJoinDate = true;
                    targetUsersFilter_minJoinDate = targetUsersFilter.minJoinDate;
                }
            }
            this.props.getTOGameList(gamePageOptions, login.userDetails.data.accessToken);
            this.props.getTOMatchPartipation(
                {matchId: toCompletedMatch.editTOCompletedMatch.id},
                login.userDetails.data.accessToken,
            );
            this.props.getTOMatchTypeList({listType: 'ALL'}, login.userDetails.data.accessToken);
            this.props.getTOMatchModeList({listType: 'ALL'}, login.userDetails.data.accessToken);
            this.props.getTOMatchAttributeList({listType: 'ALL'}, login.userDetails.data.accessToken);
            this.setState({
                name: name,
                startTime: startTime,
                roomCredentials: roomCredentials || {},
                liveStream: liveStream,
                resultsFile: resultsFile,
                entryFeePerSlot: entryFeePerSlot,
                totalSlots: totalSlots,
                minFillSlots: minFillSlots,
                winPoolType: winPoolType,
                winFixedPoolSize: winFixedPoolSize,
                winPercentPoolSize: winPercentPoolSize,
                matchType: matchType,
                entryFeeType: entryFeeType,
                fcCoinDiscountPercent: fcCoinDiscountPercent,
                minParticipationRequired: minParticipationRequired,
                numberOfBigWinTeams: numberOfBigWinTeams,
                winDenomination: winDenomination,
                winType: winType,
                winParameter: winParameter,
                winParameterValue: winParameterValue,
                winningDistribution: winningDistribution,
                winSlots: winSlots,
                attribute1: attribute1.id,
                attribute1Val: attribute1Val,
                attribute2: attribute2.id,
                attribute2Val: attribute2Val,
                matchRules: matchRules,
                matchMode: matchMode.id,
                status: status,
                game: game.id,
                targetUsersByJoinDate,
                targetUsersByLoginStatus,
                targetUsersFilter_loggedIn,
                targetUsersFilter_minJoinDate,
            });
        }
    }

    componentDidUpdate(prevProps) {
        const {toCompletedMatch, toMatchType, toGame, toMatchMode, toMatchAttribute, history} = this.props;
        const {game} = this.state;

        if (toGame.TOGameListSuccess && !prevProps.toGame.TOGameListSuccess) {
            this.setState({
                gameOptions: toGame.TOGameList.data.data.map((item) => {
                    return {text: item.gameName, value: item.id};
                }),
                loading: false,
            });
        } else if (toGame.TOGameListFail && !prevProps.toGame.TOGameListFail) {
            this.setState({
                errorMessage: toGame.errorMessage,
                snackbarOpen: true,
                loading: false,
            });
        }

        if (toMatchMode.TOMatchModeListSuccess && !prevProps.toMatchMode.TOMatchModeListSuccess) {
            this.setState({
                matchModeOptions: toMatchMode.TOMatchModeList.data.data
                    .filter((item) => item.game === game)
                    .map((item) => {
                        return {text: item.modeName, value: item.id};
                    }),
            });
            if (this.state.edit) {
                this.setState({
                    matchMode: this.props.toCompletedMatch.editTOCompletedMatch.matchMode.id,
                });
            }
            if (
                toMatchAttribute.TOMatchAttributeListSuccess &&
                toMatchType.TOMatchTypeListSuccess &&
                toCompletedMatch.getTOMatchPartipationSuccess
            ) {
                this.setState({loading: false});
            }
        } else if (toMatchMode.TOMatchModeListFail && !prevProps.toMatchMode.TOMatchModeListFail) {
            this.setState({
                errorMessage: toMatchMode.errorMessage,
                snackbarOpen: true,
                loading: false,
            });
        }

        if (toMatchAttribute.TOMatchAttributeListSuccess && !prevProps.toMatchAttribute.TOMatchAttributeListSuccess) {
            this.setState({
                attribute1Options: toMatchAttribute.TOMatchAttributeList.data.data
                    .filter((item) => item.game === game)
                    .map((item) => {
                        return {text: item.name, value: item.id};
                    }),
                attribute2Options: toMatchAttribute.TOMatchAttributeList.data.data
                    .filter((item) => item.game === game)
                    .map((item) => {
                        return {text: item.name, value: item.id};
                    }),
            });
            if (this.state.edit) {
                this.setState({
                    attribute1: this.props.toCompletedMatch.editTOCompletedMatch.attribute1.id,
                });
            }
            if (
                toMatchMode.TOMatchModeListSuccess &&
                toMatchType.TOMatchTypeListSuccess &&
                toCompletedMatch.getTOMatchParticipationSuccess
            ) {
                this.setState({loading: false});
            }
        } else if (toMatchAttribute.TOMatchAttributeListFail && !prevProps.toMatchAttribute.TOMatchAttributeListFail) {
            this.setState({
                errorMessage: toMatchAttribute.errorMessage,
                snackbarOpen: true,
                loading: false,
            });
        }

        if (toMatchType.TOMatchTypeListSuccess && !prevProps.toMatchType.TOMatchTypeListSuccess) {
            this.setState({
                matchTypeOptions: toMatchType.TOMatchTypeList.data.data
                    .filter((item) => item.game === game)
                    .map((item) => {
                        return {text: item.name, value: item.id};
                    }),
            });
            if (this.state.edit && this.props.toCompletedMatch.editTOCompletedMatch.matchType) {
                this.setState({
                    matchType: this.props.toCompletedMatch.editTOCompletedMatch.matchType.id,
                });
            }
            if (
                toMatchMode.TOMatchModeListSuccess &&
                toMatchAttribute.TOMatchAttributeListSuccess &&
                toCompletedMatch.getTOMatchParticipationSuccess
            ) {
                this.setState({loading: false});
            }
        } else if (toMatchType.TOMatchTypeListFail && !prevProps.toMatchType.TOMatchTypeListFail) {
            this.setState({
                errorMessage: toMatchType.errorMessage,
                snackbarOpen: true,
                loading: false,
            });
        }

        if (
            toCompletedMatch.getTOMatchParticipationSuccess &&
            !prevProps.toCompletedMatch.getTOMatchParticipationSuccess
        ) {
            this.setState({
                filledSlots: this.props.toCompletedMatch.TOMatchParticipation.filledSlots,
                joinedSlots: this.props.toCompletedMatch.TOMatchParticipation.joinedSlots,
                participation: this.props.toCompletedMatch.TOMatchParticipation.participation,
            });
            if (
                toMatchMode.TOMatchModeListSuccess &&
                toMatchAttribute.TOMatchAttributeListSuccess &&
                toMatchType.TOMatchTypeListSuccess
            ) {
                this.setState({loading: false});
            }
            if (
                this.state.resultsFile !== null &&
                this.state.resultsFile !== undefined &&
                this.state.resultsFile !== ''
            ) {
                this.computeResults(this.state.resultsFile);
            }
        } else if (
            toCompletedMatch.getTOMatchPartipationFail &&
            !prevProps.toCompletedMatch.getTOMatchPartipationFail
        ) {
            this.setState({
                errorMessage: toCompletedMatch.errorMessage,
                snackbarOpen: true,
                loading: false,
            });
        }

        if (toCompletedMatch.modifyTOMatchSuccess && !prevProps.toCompletedMatch.modifyTOMatchSuccess) {
            history.push('/toCompletedMatches');
        } else if (toCompletedMatch.modifyTOMatchFail && !prevProps.toCompletedMatch.modifyTOMatchFail) {
            this.setState({
                loading: false,
                snackbarOpen: true,
                errorMessage: toCompletedMatch.errorMessage,
            });
        }

        if (toCompletedMatch.archiveTOMatchSuccess && !prevProps.toCompletedMatch.archiveTOMatchSuccess) {
            history.push('/toCompletedMatches');
        } else if (toCompletedMatch.archiveTOMatchFail && !prevProps.toCompletedMatch.archiveTOMatchFail) {
            this.setState({
                loading: false,
                snackbarOpen: true,
                errorMessage: toCompletedMatch.errorMessage,
            });
        }
    }

    async downloadResults(s3FileUrl) {
        // console.log("downloadResults", s3FileUrl);
        let res = await fetchS3FileFromServer(s3FileUrl, this.props.login.userDetails.data.accessToken);

        let arr = s3FileUrl.split('/');
        let fileName = arr[arr.length - 1];
        saveAs(new Blob([res.data]), fileName);
    }

    async computeResults(fileLocation) {
        // console.log("fileLocation ", fileLocation);
        this.setState({loading: true});
        let columns = [];
        let tempResultData = [];
        let res = await fetchS3FileFromServer(fileLocation, this.props.login.userDetails.data.accessToken);
        // axios(fileLocation, {responseType:'arraybuffer'})
        // .then(res => {
        /* parse the data when it is received */
        var data = new Uint8Array(res.data);
        var workbook = xlsx.read(data, {type: 'array'});
        /* DO SOMETHING WITH workbook HERE */
        let worksheet = workbook.Sheets[workbook.SheetNames[0]];
        let sheetData = xlsx.utils.sheet_to_json(worksheet, {header: 1});
        let headers = sheetData[0];
        for (let i = 0; i < headers.length; i++) {
            columns.push({
                Header: headers[i],
                accessor: headers[i].replace(/\s/g, ''),
                Cell: (props) => <span>{props.value}</span>,
            });
        }
        if (this.state.winType === 'Distribution') {
            columns.push({
                Header: 'Rank',
                accessor: 'rank',
                Cell: (props) => <span>{props.value}</span>,
            });
            columns.push({
                Header: 'Winnings',
                accessor: 'winnings',
                Cell: (props) => <span>{props.value}</span>,
            });
        } else {
            columns.push({
                Header: 'Winnings',
                accessor: 'winnings',
                Cell: (props) => <span>{props.value}</span>,
            });
        }
        for (let i = 1; i < sheetData.length; i++) {
            let temp_dict = {};
            for (let j = 0; j < headers.length; j++) {
                temp_dict[headers[j].replace(/\s/g, '')] = sheetData[i][j];
            }
            tempResultData.push(temp_dict);
        }
        // console.log(tempResultData);
        let thisParticipation = [...this.state.participation];
        if (this.state.winType === 'Distribution') {
            let curConsideration = tempResultData.filter((item) => item.TeamID !== '');
            // console.log(curConsideration);
            curConsideration.sort((a, b) => b.MixedScore - a.MixedScore);
            let rank = 1;
            for (var i = 0; i < curConsideration.length; i++) {
                if (i > 0 && curConsideration[i].MixedScore < curConsideration[i - 1].MixedScore) {
                    rank++;
                }
                curConsideration[i].rank = rank;
            }
            tempResultData = tempResultData.map((item) => {
                if (item.TeamID === '') {
                    return {...item, rank: ''};
                } else {
                    return {...item, rank: curConsideration.find((item2) => item2.TeamID === item.TeamID).rank};
                }
            });
            // console.log(tempResultData);
            let totalPaid = 0;
            for (var i = 0; i < thisParticipation.length; i++) {
                totalPaid += thisParticipation[i].teamParticipation.filter(
                    (item) => item.paymentStatus === 'paid',
                ).length;
            }
            for (var i = 0; i < tempResultData.length; i++) {
                if (tempResultData[i].TeamID == null) {
                    continue;
                }
                if (tempResultData[i].TeamID && tempResultData[i].TeamID !== '') {
                    let participatingTeam = thisParticipation.find(
                        (item) => item.originalTeam.id === tempResultData[i].TeamID,
                    );
                    if (participatingTeam == undefined) {
                        continue;
                    }
                    participatingTeam.score = tempResultData[i].MixedScore;
                    participatingTeam.rank = tempResultData[i].rank;
                    let totalPool;
                    // console.log(this.state.winPoolType);
                    if (this.state.winPoolType === 'Percentage') {
                        // console.log(totalPaid);
                        // console.log(this.state.entryFeePerSlot);
                        // console.log(this.state.winPercentPoolSize);
                        totalPool = totalPaid * this.state.entryFeePerSlot;
                    } else {
                        totalPool = this.state.winFixedPoolSize;
                    }
                    // console.log(totalPool);
                    if (tempResultData[i].rank in this.state.winningDistribution) {
                        // console.log('Inside the assignment of team winnings');
                        // console.log(this.state.winningDistribution[tempResultData[i].rank]);
                        participatingTeam.winnings =
                            Math.round((totalPool * this.state.winningDistribution[tempResultData[i].rank]) / 10) / 10;
                        tempResultData[i].winnings = participatingTeam.winnings;
                    } else {
                        participatingTeam.winnings = 0;
                        tempResultData[i].winnings = 0;
                    }
                    // console.log(participatingTeam.winnings);
                    let joinedSize = participatingTeam.teamParticipation.map(
                        (item) => item.matchStatus === 'joined',
                    ).length;
                    let individualWinnings = Math.round((participatingTeam.winnings / joinedSize) * 10) / 10;
                    // console.log(individualWinnings);
                    for (var j = 0; j < participatingTeam.teamParticipation.length; j++) {
                        if (participatingTeam.teamParticipation[j].matchStatus === 'joined') {
                            participatingTeam.teamParticipation[j].winnings = individualWinnings;
                        } else {
                            participatingTeam.teamParticipation[j].winnings = individualWinnings;
                        }
                    }
                    let thisIndex = thisParticipation.findIndex(
                        (item) => item.originalTeam.id === tempResultData[i].TeamID,
                    );
                    thisParticipation[thisIndex] = participatingTeam;
                } else {
                    let participatingTeam = thisParticipation.find(
                        (item) =>
                            item.teamParticipation.findIndex((item2) => item2.player === tempResultData[i].Player) > -1,
                    );
                    if (participatingTeam == undefined) {
                        continue;
                    }
                    tempResultData[i].winnings = participatingTeam.teamParticipation.find(
                        (item) => item.player === tempResultData[i].Player,
                    ).winnings;
                }
            }
            for (var i = 0; i < thisParticipation.length; i++) {
                for (var j = 0; j < thisParticipation[i].teamParticipation.length; j++) {
                    if (thisParticipation[i].teamParticipation[j].matchStatus === 0) {
                        thisParticipation[i].teamParticipation[j].score = 0;
                        thisParticipation[i].teamParticipation[j].winnings = 0;
                    }
                }
            }
        } else {
            for (var i = 0; i < tempResultData.length; i++) {
                if (tempResultData[i].Player && tempResultData[i].Player !== '') {
                    // console.log(" --tempResultData[i]", JSON.stringify(tempResultData[i]));
                    let participatingTeam = thisParticipation.find(
                        (item) =>
                            item.teamParticipation.findIndex(
                                (item2) => item2.player === tempResultData[i].Player && item2.matchStatus === 'joined',
                            ) > -1,
                    );
                    let participatingTeamIndex = thisParticipation.findIndex(
                        (item) =>
                            item.teamParticipation.findIndex(
                                (item2) => item2.player === tempResultData[i].Player && item2.matchStatus === 'joined',
                            ) > -1,
                    );
                    let thisMember = participatingTeam.teamParticipation.findIndex(
                        (item) => item.player === tempResultData[i].Player && item.matchStatus === 'joined',
                    );
                    participatingTeam.teamParticipation[thisMember].score =
                        tempResultData[i][('# of' + this.state.winParameter).replace(/\s/g, '')];
                    participatingTeam.teamParticipation[thisMember].winnings =
                        tempResultData[i][('# of' + this.state.winParameter).replace(/\s/g, '')] *
                        this.state.winParameterValue;
                    tempResultData[i].winnings = participatingTeam.teamParticipation[thisMember].winnings;
                    thisParticipation[participatingTeamIndex] = participatingTeam;
                }
            }
            for (var i = 0; i < thisParticipation.length; i++) {
                let temp_score = 0;
                let temp_winnings = 0;
                for (var j = 0; j < thisParticipation[i].teamParticipation.length; j++) {
                    if (thisParticipation[i].teamParticipation[j].matchStatus === 'joined') {
                        temp_score += thisParticipation[i].teamParticipation[j].score;
                        temp_winnings += thisParticipation[i].teamParticipation[j].winnings;
                    } else {
                        thisParticipation[i].teamParticipation[j].score = 0;
                        thisParticipation[i].teamParticipation[j].winnings = 0;
                    }
                }
                let thisTeamIndex = tempResultData.findIndex(
                    (item) => item.TeamID === thisParticipation[i].originalTeam.id,
                );
                tempResultData[thisTeamIndex][('# of' + this.state.winParameter).replace(/\s/g, '')] = temp_score;
                tempResultData[thisTeamIndex].winnings = temp_winnings;
                thisParticipation[i].score = temp_score;
                thisParticipation[i].winnings = temp_winnings;
            }
        }
        // console.log(thisParticipation);
        this.setState({
            resultColumns: columns,
            resultData: tempResultData,
            participation: thisParticipation,
            loading: false,
        });
        // });
    }

    renderTabA() {
        const {
            name,
            startTime,
            entryFeePerSlot,
            totalSlots,
            minFillSlots,
            winPoolType,
            filledSlots,
            joinedSlots,
            participation,
            roomCredentials,
            liveStream,
            resultsFile,
            winPoolTypeOptions,
            matchTypeOptions,
            matchType,
            winFixedPoolSize,
            winPercentPoolSize,
            entryFeeType,
            entryFeeTypeOptions,
            fcCoinDiscountPercent,
            minParticipationRequired,
            minParticipationRequiredOptions,
            numberOfBigWinTeams,
            winDenomination,
            winDenominationOptions,
            winType,
            winTypeOptions,
            winParameter,
            winParameterValue,
            winSlots,
            attribute1,
            attribute1Options,
            attribute1Val,
            attribute2,
            attribute2Options,
            attribute2Val,
            matchMode,
            matchModeOptions,
            matchRules,
            status,
            statusOptions,
            game,
            gameOptions,
            handlingCharge,
            uploadFile,
            errorMessage,
            loading,
            open,
            edit,
            snackbarOpen,
            targetUsersByJoinDate,
            targetUsersByLoginStatus,
            booleanOptions,
            targetUsersFilter_minJoinDate,
            targetUsersFilter_loggedIn,
            targetUsersFilter_loggedInOptions,
        } = this.state;
        const {history, toCompletedMatch, toMatchType} = this.props;
        const {createdByClient, registrationStartTime, registrationEndTime} = toCompletedMatch.editTOCompletedMatch;

        return (
            <div>
                <div className="card-grid-container">
                    <div className="grid-item  margin-top-20">
                        <Input
                            label="Match Name"
                            type="text"
                            name="name"
                            value={name}
                            onChange={this.handleInputChange.bind(this, 'name')}
                            placeholder="Enter the Match Name"
                            disabled={true}
                        />
                    </div>
                    <div className="grid-item  margin-top-20">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DateTimePicker
                                margin="normal"
                                id="date-picker-dialog"
                                label={status === 'LIVE' ? 'Gone Live At' : 'Start Time'}
                                value={startTime}
                                minDate={null}
                                disabled={true}
                                invalidDateMessage={''}
                                onChange={this.handleInputChange.bind(this, 'startTime')}
                            />
                        </MuiPickersUtilsProvider>
                    </div>

                    {/* row */}
                    {registrationStartTime ? (
                        <div className="grid-item  margin-top-20">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DateTimePicker
                                    margin="normal"
                                    id="date-picker-dialog"
                                    label={'Registration Start Time'}
                                    value={registrationStartTime}
                                    disabled={true}
                                    invalidDateMessage={''}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    ) : (
                        <div className="grid-item  margin-top-20" />
                    )}
                    {registrationEndTime ? (
                        <div className="grid-item  margin-top-20">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DateTimePicker
                                    margin="normal"
                                    id="date-picker-dialog"
                                    label={'Registration End Time'}
                                    value={registrationEndTime}
                                    disabled={true}
                                    invalidDateMessage={''}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    ) : (
                        <div className="grid-item  margin-top-20" />
                    )}

                    {/* row */}
                    {createdByClient ? (
                        <div className="grid-item  margin-top-20">
                            <Input
                                label="Created From"
                                type="text"
                                name="name"
                                value={createdByClient}
                                placeholder="Created From"
                                disabled={true}
                            />
                        </div>
                    ) : null}
                    {createdByClient ? <div className="grid-item  margin-top-20" /> : null}

                    <div className="grid-item margin-top-20">
                        <Input
                            label="Filled Slots"
                            type="text"
                            name="filledSlots"
                            value={filledSlots}
                            onChange={this.handleInputChange.bind(this, 'filledSlots')}
                            placeholder="Filled Slots"
                            suffix={minParticipationRequired === true ? '/min ' + minFillSlots : null}
                            disabled={true}
                        />
                    </div>
                    <div className="grid-item margin-top-20">
                        <Input
                            label="Joined Slots"
                            type="text"
                            name="joinedSlots"
                            value={joinedSlots}
                            onChange={this.handleInputChange.bind(this, 'joinedSlots')}
                            placeholder="Joined Slots"
                            suffix={minParticipationRequired === true ? '/min ' + minFillSlots : null}
                            disabled={true}
                        />
                    </div>
                    <React.Fragment>
                        <div className="grid-item margin-top-10">
                            <Input
                                label="Room Credentials 1 name"
                                type="text"
                                name="cred1Name"
                                value={roomCredentials.cred1Name}
                                onChange={this.handleInputChange.bind(this, 'cred1Name')}
                                placeholder="Room Credentials 1 name"
                                disabled={true}
                            />
                        </div>
                        <div className="grid-item margin-top-10">
                            <Input
                                label="Room Credentials 1 value"
                                type="text"
                                name="cred1Val"
                                value={roomCredentials.cred1Val}
                                onChange={this.handleInputChange.bind(this, 'cred1Val')}
                                placeholder="Room Credentials 1 value"
                                disabled={true}
                            />
                        </div>
                        <div className="grid-item margin-top-10">
                            <Input
                                label="Room Credentials 2 name"
                                type="text"
                                name="cred2Name"
                                value={roomCredentials.cred2Name}
                                onChange={this.handleInputChange.bind(this, 'cred2Name')}
                                placeholder="Room Credentials 2 name"
                                disabled={true}
                            />
                        </div>
                        <div className="grid-item margin-top-10">
                            <Input
                                label="Room Credentials 2 value"
                                type="text"
                                name="cred2Val"
                                value={roomCredentials.cred2Val}
                                onChange={this.handleInputChange.bind(this, 'cred2Val')}
                                placeholder="Room Credentials 2 value"
                                disabled={true}
                            />
                        </div>
                    </React.Fragment>
                    <React.Fragment>
                        <div className="grid-item margin-top-10">
                            <Input
                                label="Live Stream Link"
                                type="text"
                                name="liveStream"
                                value={liveStream}
                                onChange={this.handleInputChange.bind(this, 'liveStream')}
                                placeholder="Enter Live Stream Link"
                                disabled={true}
                            />
                        </div>
                    </React.Fragment>
                    {resultsFile !== '' && resultsFile !== undefined && resultsFile !== null ? (
                        <React.Fragment>
                            <div className="grid-item margin-top-10">
                                <button onClick={() => this.downloadResults(resultsFile)}>
                                    {'Download Results File'}
                                </button>
                            </div>
                        </React.Fragment>
                    ) : (
                        <div className="grid-item margin-top-10"></div>
                    )}
                    {/* row2 */}
                    <div className="grid-item  margin-top-20">
                        <DropDown
                            label="Target Users by login status?"
                            type="text"
                            menuItems={booleanOptions}
                            name="targetUsersByLoginStatus"
                            value={targetUsersByLoginStatus}
                            onChange={this.handleInputChange.bind(this, 'targetUsersByLoginStatus')}
                            placeholder="Target Specific Users"
                            disabled={true}
                        />
                    </div>
                    <div className="grid-item  margin-top-20">
                        <DropDown
                            label="Target Users by join date"
                            type="text"
                            menuItems={booleanOptions}
                            name="targetUsersByJoinDate"
                            value={targetUsersByJoinDate}
                            onChange={this.handleInputChange.bind(this, 'targetUsersByJoinDate')}
                            placeholder="Target Specific Users"
                            disabled={true}
                        />
                    </div>
                    {targetUsersByLoginStatus ? (
                        <div className="grid-item  margin-top-20">
                            <DropDown
                                label="Target by Log In Status"
                                type="text"
                                menuItems={targetUsersFilter_loggedInOptions}
                                name="targetUsersFilter_loggedIn"
                                value={targetUsersFilter_loggedIn}
                                onChange={this.handleInputChange.bind(this, 'targetUsersFilter_loggedIn')}
                                placeholder="Target Specific Users"
                                disabled={true}
                            />
                        </div>
                    ) : null}
                    {targetUsersByJoinDate ? (
                        <div className="grid-item  margin-top-20">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DateTimePicker
                                    margin="normal"
                                    id="date-picker-dialog"
                                    label="Target if join date is greater than"
                                    value={new Date(targetUsersFilter_minJoinDate)}
                                    disabled={true}
                                    invalidDateMessage={''}
                                    onChange={this.handleInputChange.bind(this, 'targetUsersFilter_minJoinDate')}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    ) : null}
                    <div className="grid-item  margin-top-20">
                        <Input
                            label="Entry Fee Per Slot"
                            type="text"
                            name="entryFeePerSlot"
                            value={entryFeePerSlot}
                            onChange={this.handleInputChange.bind(this, 'entryFeePerSlot')}
                            placeholder="Please enter the entry Fee"
                            disabled={true}
                        />
                    </div>
                    <div className="grid-item  margin-top-20">
                        <Input
                            label="Total Number of Slots"
                            type="text"
                            name="totalSlots"
                            value={totalSlots}
                            onChange={this.handleInputChange.bind(this, 'totalSlots')}
                            placeholder="Please enter the number of slots"
                            disabled={true}
                        />
                    </div>

                    {/* row3 */}
                    <div className="grid-item  margin-top-20">
                        <DropDown
                            label="Type of Reward Pool"
                            type="text"
                            menuItems={winPoolTypeOptions}
                            name="winPoolType"
                            value={winPoolType}
                            onChange={this.handleInputChange.bind(this, 'winPoolType')}
                            placeholder="Please enter type of reward pool"
                            disabled={true}
                        />
                    </div>
                    {winPoolType === 'Fixed' ? (
                        <div className="grid-item  margin-top-20">
                            <Input
                                label="Enter the Pool Size"
                                type="text"
                                name="winFixedPoolSize"
                                value={winFixedPoolSize}
                                onChange={this.handleInputChange.bind(this, 'winFixedPoolSize')}
                                placeholder="Please enter reward pool size"
                                disabled={true}
                            />
                        </div>
                    ) : winPoolType === 'Percentage' ? (
                        <div className="grid-item  margin-top-20">
                            <Input
                                label="Enter the Pool Size"
                                type="text"
                                name="winPercentPoolSize"
                                value={winPercentPoolSize}
                                onChange={this.handleInputChange.bind(this, 'winPercentPoolSize')}
                                placeholder="Please enter reward pool size"
                                suffix="%"
                                disabled={true}
                            />
                        </div>
                    ) : (
                        <div className="grid-item  margin-top-20"></div>
                    )}

                    {/* row4 */}
                    <div className="grid-item  margin-top-20">
                        <DropDown
                            label="Game"
                            type="text"
                            menuItems={gameOptions}
                            name="game"
                            value={game}
                            onChange={this.handleInputChange.bind(this, 'game')}
                            placeholder="Select Game"
                            disabled={true}
                        />
                    </div>
                    {game !== undefined && game !== null && game !== '' ? (
                        <div className="grid-item  margin-top-20">
                            <DropDown
                                label="Match Type"
                                type="text"
                                menuItems={matchTypeOptions}
                                name="matchType"
                                value={matchType}
                                onChange={this.handleInputChange.bind(this, 'matchType')}
                                placeholder="Select Match Type"
                                disabled={true}
                            />
                        </div>
                    ) : (
                        <div className="grid-item  margin-top-20"></div>
                    )}
                    {/* { matchType !== undefined && matchType !=null && matchType !=="" ? */}
                    <React.Fragment>
                        {/* row 1 */}
                        <div className="grid-item  margin-top-20">
                            <DropDown
                                label="Entry Type"
                                type="text"
                                menuItems={entryFeeTypeOptions}
                                name="entryFeeType"
                                value={entryFeeType}
                                onChange={this.handleInputChange.bind(this, 'entryFeeType')}
                                placeholder="Select"
                                disabled={true}
                            />
                        </div>

                        {entryFeeType === 'Cash+Coin' ? (
                            <div className="grid-item margin-top-20 align-right">
                                <Input
                                    label="% FC Coin Discount"
                                    type="text"
                                    name="fcCoinDiscountPercent"
                                    value={fcCoinDiscountPercent}
                                    onChange={this.handleInputChange.bind(this, 'fcCoinDiscountPercent')}
                                    placeholder="% FC Coin Discount"
                                    suffix="%"
                                    disabled={true}
                                />
                            </div>
                        ) : (
                            <div className="grid-item margin-top-20 align-right"></div>
                        )}

                        {/* row 2 */}
                        <div className="grid-item margin-top-20 align-right">
                            <DropDown
                                label="Min Participation Required"
                                type="text"
                                menuItems={minParticipationRequiredOptions}
                                name="minParticipationRequired"
                                value={minParticipationRequired}
                                onChange={this.handleInputChange.bind(this, 'minParticipationRequired')}
                                placeholder="Select"
                                disabled={true}
                            />
                        </div>
                        {minParticipationRequired === true ? (
                            <div className="grid-item margin-top-20 align-right">
                                <Input
                                    label="Min Slots to be filled"
                                    type="text"
                                    name="minFillSlots"
                                    value={minFillSlots}
                                    onChange={this.handleInputChange.bind(this, 'minFillSlots')}
                                    placeholder="Enter the minimum slots"
                                    disabled={true}
                                />
                            </div>
                        ) : (
                            <div className="grid-item margin-top-20 align-right"></div>
                        )}

                        {/* row 3 */}
                        <div className="grid-item margin-top-20 align-right">
                            <DropDown
                                label="Win Reward Denomination"
                                type="text"
                                menuItems={winDenominationOptions}
                                name="winDenomination"
                                value={winDenomination}
                                onChange={this.handleInputChange.bind(this, 'winDenomination')}
                                placeholder="Select"
                                disabled={true}
                            />
                        </div>
                        <div className="grid-item  margin-top-20">
                            <DropDown
                                label="Match Mode"
                                type="text"
                                menuItems={matchModeOptions}
                                name="matchMode"
                                value={matchMode}
                                onChange={this.handleInputChange.bind(this, 'matchMode')}
                                placeholder="Select"
                                disabled={true}
                            />
                        </div>

                        {/* row 4 */}
                        <div className="grid-item margin-top-20 align-right">
                            <DropDown
                                label="Attribute 1"
                                type="text"
                                menuItems={attribute1Options}
                                name="attribute1"
                                value={attribute1}
                                onChange={this.handleInputChange.bind(this, 'attribute1')}
                                placeholder="Select"
                                disabled={true}
                            />
                        </div>

                        <div className="grid-item  margin-top-20">
                            <Input
                                label="Attribute 1 Value"
                                type="text"
                                name="attribute1Val"
                                value={attribute1Val}
                                onChange={this.handleInputChange.bind(this, 'attribute1Val')}
                                placeholder="Select"
                                disabled={true}
                            />
                        </div>

                        {/* row 5 */}
                        <div className="grid-item margin-top-20 align-right">
                            <DropDown
                                label="Attribute 2"
                                type="text"
                                menuItems={attribute2Options}
                                name="attribute2"
                                value={attribute2}
                                onChange={this.handleInputChange.bind(this, 'attribute2')}
                                placeholder="Select"
                                disabled={true}
                            />
                        </div>

                        <div className="grid-item  margin-top-20">
                            <Input
                                label="Attribute 2 Value"
                                type="text"
                                name="attribute2Val"
                                value={attribute2Val}
                                onChange={this.handleInputChange.bind(this, 'attribute2Val')}
                                placeholder="Select"
                                disabled={true}
                            />
                        </div>

                        {/* row 6 */}
                        <div className="grid-item  margin-top-20">
                            <DropDown
                                label="Reward Win Mode"
                                type="text"
                                menuItems={winTypeOptions}
                                name="winType"
                                value={winType}
                                onChange={this.handleInputChange.bind(this, 'winType')}
                                placeholder="Select"
                                disabled={true}
                            />
                        </div>
                        <div className="grid-item margin-top-20 align-right"></div>

                        {/* row 6 */}
                        {winType === 'Fixed' ? (
                            <div className="grid-item  margin-top-20">
                                <Input
                                    label="Winning Parameter e.g. Per Kill"
                                    type="text"
                                    name="winParameter"
                                    value={winParameter}
                                    onChange={this.handleInputChange.bind(this, 'winParameter')}
                                    placeholder="Winning Parameter"
                                    disabled={true}
                                />
                            </div>
                        ) : null}
                        {winType === 'Fixed' ? (
                            <div className="grid-item  margin-top-20">
                                <Input
                                    label="Reward per parameter e.g. 5"
                                    type="text"
                                    name="winParameterValue"
                                    value={winParameterValue}
                                    onChange={this.handleInputChange.bind(this, 'winParameterValue')}
                                    placeholder="Reward per parameter"
                                    disabled={true}
                                />
                            </div>
                        ) : null}

                        {winType === 'Distribution' ? (
                            <div className="grid-item  margin-top-20">Prize Pool Distribution Helper</div>
                        ) : null}
                        {winType === 'Distribution' ? <div className="grid-item  margin-top-20"></div> : null}

                        {/* row 7 */}
                        {winType === 'Distribution' ? (
                            <div className="grid-item margin-top-20">
                                <Input
                                    label="Handling Charges "
                                    type="text"
                                    name="handlingCharge"
                                    value={winSlots.handlingCharge}
                                    onChange={this.handleInputChange.bind(this, 'handlingCharge')}
                                    placeholder="Enter Handling Charges %"
                                    disabled={true}
                                    suffix="%"
                                />
                            </div>
                        ) : null}
                        {winType === 'Distribution' ? (
                            <div className="grid-item margin-top-20">
                                <Input
                                    label="# of Big Win Slots"
                                    type="text"
                                    name="numberOfBigWinTeams"
                                    value={numberOfBigWinTeams}
                                    onChange={this.handleInputChange.bind(this, 'numberOfBigWinTeams')}
                                    placeholder="Enter # of Big Win Slots"
                                    disabled={true}
                                />
                            </div>
                        ) : null}
                    </React.Fragment>
                    {/* : null} */}
                </div>
                {/* {matchType !== undefined && matchType !== null && matchType !== "" ?  */}
                <React.Fragment>
                    {/* row8 */}
                    <div className="card-grid-container nopadding ">{this.displayPrizes(numberOfBigWinTeams)}</div>

                    {/* row9 */}
                    <div className="grid-item card-grid-container 6">
                        {winType === 'Distribution' ? (
                            <React.Fragment>
                                <div className="grid-item margin-top-20">
                                    <Input
                                        label="Split Remaining"
                                        type="text"
                                        name="splitRemaining"
                                        value={winSlots.splitRemaining}
                                        onChange={this.handleInputChange.bind(this, 'splitRemaining')}
                                        placeholder="Enter Split Remaining"
                                        disabled={true}
                                        suffix="%"
                                    />
                                </div>
                                <div className="grid-item margin-top-20">
                                    <Input
                                        label="# of small win slots"
                                        type="text"
                                        name="smallWinSlots"
                                        value={winSlots.smallWinSlots}
                                        onChange={this.handleInputChange.bind(this, 'smallWinSlots')}
                                        placeholder="Enter # of small win slots"
                                        disabled={true}
                                    />
                                </div>
                            </React.Fragment>
                        ) : null}
                    </div>

                    {/* row10 */}
                    <div className="card-fullgrid-container margin-top-20">
                        <label for="matchRules">Match Rules</label>
                        <TextareaAutosize
                            className="margin-top-20"
                            aria-label="maximum height"
                            value={matchRules}
                            placeholder="Match Rules"
                            onChange={this.handleInputChange.bind(this, 'matchRules')}
                            disabled={true}
                        />
                    </div>

                    <MatchDetailsV2 match={toCompletedMatch.editTOCompletedMatch} />
                </React.Fragment>
                {/* : null } */}
            </div>
        );
    }

    renderTabB() {
        const {participation} = this.state;
        let teams = [];
        const columns = [
            {
                Header: 'User',
                accessor: 'user',
                Cell: (props) => <span>{props.value}</span>,
            },
            {
                Header: 'Player',
                accessor: 'player',
                Cell: (props) => <span>{props.value}</span>,
            },
            {
                Header: 'Level',
                accessor: 'level',
                Cell: (props) => <span>{props.value}</span>,
            },
            {
                Header: 'Game ID',
                accessor: 'gameId',
                Cell: (props) => <span>{props.value}</span>,
            },
            {
                Header: 'Match Status',
                accessor: 'matchStatus',
                Cell: (props) => <span>{props.value}</span>,
            },
            {
                Header: 'Payment Status',
                accessor: 'paymentStatus',
                Cell: (props) => <span>{props.value}</span>,
            },
        ];
        for (var i = 0; i < participation.length; i++) {
            teams.push(
                <React.Fragment>
                    <div className="table-cell">
                        <div className="card-grid-container new">
                            <div className="grid-item margin-top-10">
                                <span>Team Name: {participation[i].originalTeam.teamName}</span>
                            </div>
                            <div className="grid-item margin-top-10">
                                <span>Team ID: {participation[i].originalTeam.id}</span>
                            </div>
                            <div className="grid-item margin-top-10">
                                <span>
                                    Paid Slots:{' '}
                                    {
                                        participation[i].teamParticipation.filter(
                                            (item) => item.paymentStatus === 'paid',
                                        ).length
                                    }
                                </span>
                            </div>
                            <div className="grid-item margin-top-10">
                                <span>
                                    Joined Slots:{' '}
                                    {
                                        participation[i].teamParticipation.filter(
                                            (item) => item.matchStatus === 'joined',
                                        ).length
                                    }
                                </span>
                            </div>
                            <div className="grid-item margin-top-10">
                                <span>Game Slot Number: {participation[i].gameSlotNumber}</span>
                            </div>
                        </div>
                        <SmallTable
                            data={participation[i].teamParticipation}
                            columns={columns}
                            page={1}
                            onPrevClick={() => {}}
                            onNextClick={() => {}}
                            disableNext={false}
                            pageSize={participation[i].teamParticipation.length}
                            removePagination={true}
                        />
                    </div>
                    {i === participation.length - 1 ? <div className="bottom"></div> : null}
                </React.Fragment>,
            );
        }
        return <div>{teams}</div>;
    }

    renderTabC() {
        /* set up an async GET request with axios */
        return (
            <React.Fragment>
                <div className="table-cell">
                    <SmallTable
                        data={this.state.resultData}
                        columns={this.state.resultColumns}
                        page={1}
                        onPrevClick={() => {}}
                        onNextClick={() => {}}
                        disableNext={false}
                        pageSize={this.state.resultData.length}
                        removePagination={true}
                    ></SmallTable>
                </div>
                <div className="bottom"></div>
            </React.Fragment>
        );
    }

    renderSubmittedResultsTab() {
        return (
            <div>
                <UpdateMatchResults />
            </div>
        );
    }

    render() {
        const {resultsFile, status, errorMessage, loading, open, edit, snackbarOpen} = this.state;
        const {history, toCompletedMatch, toMatchType, login} = this.props;
        const {createdByClient} = toCompletedMatch.editTOCompletedMatch;
        const {toMatchWrite} = login?.permissions || {};

        let title = 'Edit Match';
        let shrink = undefined;
        return (
            <DetailLayout
                location="EditTOCompletedMatch"
                history={history}
                centerTitle={title}
                onSave={!!toMatchWrite ? this.onArchive.bind(this) : undefined}
                matchStatus={status}
                editPage={edit}
                id={edit ? toCompletedMatch.editTOCompletedMatch.id : null}
            >
                <LoadingModal open={loading} />
                <Snackbar
                    open={snackbarOpen}
                    message={errorMessage}
                    onClose={() => this.setState({snackbarOpen: false})}
                />
                <div className="detail-container margin-top-20">
                    <div className="grid-item"></div>
                    <div className="grid-item">
                        <Card>
                            <div>
                                <div className="card-single-grid-container">
                                    {createdByClient === 'organizer_app' ? (
                                        <Tabs
                                            tab1Label="Summary"
                                            tab2Label="Teams"
                                            tab3Label="Submitted Results"
                                            panelA={this.renderTabA.bind(this)}
                                            panelB={this.renderTabB.bind(this)}
                                            panelC={this.renderSubmittedResultsTab.bind(this)}
                                            disableTab1={false}
                                            disableTab2={false}
                                            disableTab3={false}
                                        />
                                    ) : resultsFile === '' || resultsFile === null || resultsFile === undefined ? (
                                        <Tabs
                                            tab1Label="Summary"
                                            tab2Label="Teams"
                                            panelA={this.renderTabA.bind(this)}
                                            panelB={this.renderTabB.bind(this)}
                                            disableTab1={false}
                                            disableTab2={false}
                                        />
                                    ) : (
                                        <Tabs
                                            tab1Label="Summary"
                                            tab2Label="Teams"
                                            tab3Label="Results"
                                            panelA={this.renderTabA.bind(this)}
                                            panelB={this.renderTabB.bind(this)}
                                            panelC={this.renderTabC.bind(this)}
                                            disableTab1={false}
                                            disableTab2={false}
                                            disableTab3={false}
                                        />
                                    )}
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
            </DetailLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        toGame: state.toGame,
        toCompletedMatch: state.toCompletedMatch,
        toMatch: state.toMatch,
        toMatchType: state.toMatchType,
        toMatchMode: state.toMatchMode,
        toMatchAttribute: state.toMatchAttribute,
    };
};

export default connect(mapStateToProps, {
    getTOGameList,
    getTOMatchTypeList,
    getTOMatchAttributeList,
    getTOMatchModeList,
    logout,
    archiveTOMatch,
    getTOMatchPartipation,
})(EditTOCompletedMatch);
