export const FANTASY_SCORING_LIST = 'FANTASY_SCORING_LIST';
export const FANTASY_SCORING_LIST_SUCCESS = 'FANTASY_SCORING_LIST_SUCCESS';
export const FANTASY_SCORING_LIST_FAIL = 'FANTASY_SCORING_LIST_FAIL';

export const EDIT_FANTASY_SCORING_MODEL = 'EDIT_FANTASY_SCORING_MODEL';

export const ADD_FANTASY_SCORING = 'ADD_FANTASY_SCORING';
export const ADD_FANTASY_SCORING_SUCCESS = 'ADD_FANTASY_SCORING_SUCCESS';
export const ADD_FANTASY_SCORING_FAIL = 'ADD_FANTASY_SCORING_FAIL';

export const UPDATE_FANTASY_SCORING = 'UPDATE_FANTASY_SCORING';
export const UPDATE_FANTASY_SCORING_SUCCESS = 'UPDATE_FANTASY_SCORING_SUCCESS';
export const UPDATE_FANTASY_SCORING_FAIL = 'UPDATE_FANTASY_SCORING_FAIL';

export const DELETE_FANTASY_SCORING = 'DELETE_FANTASY_SCORING';
export const DELETE_FANTASY_SCORING_SUCCESS = 'DELETE_FANTASY_SCORING_SUCCESS';
export const DELETE_FANTASY_SCORING_FAIL = 'DELETE_FANTASY_SCORING_FAIL';

export const GET_FANTASY_SCORING = 'GET_FANTASY_SCORING';
export const GET_FANTASY_SCORING_SUCCESS = 'GET_FANTASY_SCORING_SUCCESS';
export const GET_FANTASY_SCORING_FAIL = 'GET_FANTASY_SCORING_FAIL';
