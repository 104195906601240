import axios from 'axios';
import {
    listPlayer,
    listPlayerSuccess,
    listPlayerFail,
    addPlayer,
    addPlayerSuccess,
    addPlayerFail,
    updatePlayer,
    updatePlayerFail,
    updatePlayerSuccess,
    deletePlayer,
    deletePlayerFail,
    deletePlayerSuccess,
    getPlayer,
    getPlayerSuccess,
    getPlayerFail,
} from './playerAction';
import {HEADER_SOURCE, URL} from '../../../Utilities/Constants';
import {ADD_PLAYER_IN_MATCH, ADD_PLAYER_IN_MATCH_FAIL, ADD_PLAYER_IN_MATCH_SUCCESS} from './PlayerConstants';

/**
 * get player list
 * @param {*} player page details
 * @param {string} token user token
 */
export function getPlayerList(player, token, client) {
    return (dispatch) => {
        dispatch(listPlayer());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token, clientid: client},
            url: `${URL}/player/list`,
            data: player,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(listPlayerSuccess(data));
                } else if (data.data.status === 'fail') {
                    dispatch(listPlayerFail(data.data.errorData.errorReason));
                } else {
                    dispatch(listPlayerFail('Unknown Error Occured'));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(listPlayerFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(listPlayerFail(err.message || 'Something went wrong'));
                }
            });
    };
}

/**
 * add player
 * @param {*} player player details
 * @param {string} token user token
 */
export function createPlayer(player, token) {
    return (dispatch) => {
        dispatch(addPlayer());
        axios({
            method: 'post',
            headers: {
                'x-source': HEADER_SOURCE,
                Authorization: 'Bearer ' + token,
            },
            url: `${URL}/player/create`,
            data: player,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(addPlayerSuccess(data));
                } else if (data.data.status === 'fail') {
                    dispatch(addPlayerFail(data.data.errorData.errorReason));
                } else {
                    dispatch(addPlayerFail('Unknown Error Occured'));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(addPlayerFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(addPlayerFail(err.message || 'Something went wrong'));
                }
            });
    };
}

/**
 * update player
 * @param {*} player player details
 * @param {string} token user token
 */
export function updatePlayers(player, token) {
    return (dispatch) => {
        dispatch(updatePlayer());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token},
            url: `${URL}/player/update`,
            data: player,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(updatePlayerSuccess(data));
                } else if (data.data.status === 'fail') {
                    dispatch(updatePlayerFail(data.data.errorData.errorReason));
                } else {
                    dispatch(updatePlayerFail('Unknown Error Occured'));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(updatePlayerFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(updatePlayerFail(err.message || 'Something went wrong'));
                }
            });
    };
}

/**
 * delete player
 * @param {*} id player id
 * @param {string} token user token
 */
export function deletePlayers(id, token) {
    return (dispatch) => {
        dispatch(deletePlayer());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token},
            url: `${URL}/player/delete`,
            data: id,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(deletePlayerSuccess(data));
                } else if (data.data.status === 'fail') {
                    dispatch(deletePlayerFail(data.data.errorData.errorReason));
                } else {
                    dispatch(deletePlayerFail('Unknown Error Occured'));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(deletePlayerFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(deletePlayerFail(err.message || 'Something went wrong'));
                }
            });
    };
}

/**
 * get player details
 * @param {*} id player id
 * @param {string} token user token
 */
export function getPlayerRequest(id, token, client) {
    return (dispatch) => {
        dispatch(getPlayer());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token, clientId: client},
            url: `${URL}/player/get`,
            data: {
                id: id,
            },
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(getPlayerSuccess(data));
                } else if (data.data.status === 'fail') {
                    dispatch(getPlayerFail(data.data.errorData.errorReason));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(getPlayerFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(getPlayerFail(err.message || 'Something went wrong'));
                }
            });
    };
}

export const addPlayerInMatch = (data, token, clientId) => (dispatch) => {
    dispatch({type: ADD_PLAYER_IN_MATCH});
    axios({
        method: 'post',
        headers: {Authorization: 'Bearer ' + token, clientid: clientId},
        url: `${URL}/match/addPlayerInMatch`,
        data: data,
    })
        .then((data) => {
            if (data.data.status === 'success') {
                dispatch({type: ADD_PLAYER_IN_MATCH_SUCCESS, payload: data});
            } else if (data.data.status === 'fail') {
                dispatch({type: ADD_PLAYER_IN_MATCH_FAIL, payload: data.data.errorData});
            }
        })
        .catch((err) => {
            if (err.response) {
                const errorMsg = err?.response?.data?.msg || err.message;
                dispatch({type: ADD_PLAYER_IN_MATCH_FAIL, payload: {errorReason: errorMsg || 'Something went wrong'}});
            } else {
                dispatch({
                    type: ADD_PLAYER_IN_MATCH_FAIL,
                    payload: {errorReason: err.message || 'Something went wrong'},
                });
            }
        });
};
