import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import View from './View';
import {createMuiTheme, ThemeProvider} from '@material-ui/core';
import {YELLOW, WHITE, TEXT_GREY, RED} from './Utilities/Constants';

import 'react-datepicker/dist/react-datepicker.css';
import './App.css';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: YELLOW,
        },
        text: {
            primary: WHITE,
            secondary: TEXT_GREY,
        },
        error: {
            main: RED,
        },
        divider: WHITE,
    },
});

function App() {
    return (
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <View />
            </ThemeProvider>
        </BrowserRouter>
    );
}

export default App;
