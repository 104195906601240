import {FormControl, InputLabel, MenuItem, Select, styled} from '@material-ui/core';
import React from 'react';
import {ColorPalette} from '../../Utilities/constants/color-constants';

const MuiSelectField = (props) => {
    const {className, fullWidth, label, name, onChange, options, required, size, value} = props;
    return (
        <CustomFormControl variant="outlined" fullWidth={fullWidth} className={className} size={size}>
            <InputLabel htmlFor={name}>{label}</InputLabel>
            <Select id={name} label={label} name={name} onChange={onChange} required={required} value={value}>
                {options?.map((option, index) => (
                    <MenuItem key={`value-${index + 1}-${option.value}`} value={option.value}>
                        {option.text}
                    </MenuItem>
                ))}
            </Select>
        </CustomFormControl>
    );
};

MuiSelectField.defaultProps = {
    fullWidth: false,
    size: 'medium',
};

const CustomFormControl = styled(FormControl)({
    '& .MuiInputLabel-root': {
        color: ColorPalette.SALOME,

        '&.Mui-focused': {
            color: ColorPalette.SELECTIVE_YELLOW,
        },
    },
    '& .MuiOutlinedInput-root': {
        '& .MuiSvgIcon-root': {
            top: 'unset',
            color: ColorPalette.SALOME,
        },

        '& fieldset': {
            borderColor: ColorPalette.SALOME,
            borderRadius: '8px',
        },

        '&.Mui-focused fieldset': {
            borderColor: ColorPalette.SELECTIVE_YELLOW,
        },
    },
});

export default MuiSelectField;
