import {UPLOAD_FILE, PUT_ARTICLE_FAILURE, PUT_ARTICLE_PROGRESS, PUT_ARTICLE_SUCCESS} from './InshortsConstants';

const initialState = {
    snackbarOpen: false,
    errorMessage: '',
    loading: false,
    uploadArticlesFile: '',
};

const inshortsReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPLOAD_FILE:
            let file = action.payload;
            return {
                ...state,
                snackbarOpen: false,
                errorMessage: '',
                loading: false,
                uploadArticlesFile: file,
            };
        case PUT_ARTICLE_FAILURE:
            let msg = action.payload;
            return {
                ...state,
                snackbarOpen: true,
                errorMessage: msg,
                loading: false,
                uploadArticlesFile: '',
            };
        case PUT_ARTICLE_PROGRESS:
            return {
                ...state,
                loading: true,
            };
        case PUT_ARTICLE_SUCCESS:
            return {
                ...state,
                loading: false,
            };

        default:
            return initialState;
    }
};

export default inshortsReducer;
