import axios from 'axios';
import {REQUEST_METHODS} from '../Utilities/Constants';

const makeRequest = (path, allParams = {}, token, clientId) => {
    let headers = {};

    if (token) {
        headers = {...headers, Authorization: 'Bearer ' + token};
    }

    if (clientId) {
        headers = {...headers, clientid: clientId};
    }

    let config = {
        method: allParams.method,
        headers: headers,
        url: path,
        params: {},
        data: {},
    };

    if (allParams.method === REQUEST_METHODS.GET) config.params = allParams.data;
    else config.data = allParams.data;

    return axios(config);
};

export default makeRequest;
