export const TOURNAMENT_LIST = 'TOURNAMENT_LIST';
export const TOURNAMENT_LIST_SUCCESS = 'TOURNAMENT_LIST_SUCCESS';
export const TOURNAMENT_LIST_FAIL = 'TOURNAMENT_LIST_FAIL';

export const ADD_TOURNAMENT = 'ADD_TOURNAMENT';
export const ADD_TOURNAMENT_SUCCESS = 'ADD_TOURNAMENT_SUCCESS';
export const ADD_TOURNAMENT_FAIL = 'ADD_TOURNAMENT_FAIL';

export const IMPORT_TOURNAMENT = 'IMPORT_TOURNAMENT';
export const IMPORT_TOURNAMENT_SUCCESS = 'IMPORT_TOURNAMENT_SUCCESS';
export const IMPORT_TOURNAMENT_FAIL = 'IMPORT_TOURNAMENT_FAIL';
