import {
    MATCH_LIST,
    MATCH_LIST_SUCCESS,
    MATCH_LIST_FAIL,
    ADD_MATCH,
    ADD_MATCH_SUCCESS,
    ADD_MATCH_FAIL,
    EDIT_MATCH,
    UPDATE_MATCH,
    UPDATE_MATCH_FAIL,
    UPDATE_MATCH_SUCCESS,
    UPDATE_PLAYERSTATS,
    UPDATE_PLAYERSTATS_SUCCESS,
    UPDATE_PLAYERSTATS_FAIL,
    RESCHEDULE_MATCH,
    RESCHEDULE_MATCH_FAIL,
    RESCHEDULE_MATCH_SUCCESS,
    DELETE_MATCH,
    DELETE_MATCH_SUCCESS,
    DELETE_MATCH_FAIL,
    GET_MATCH,
    GET_MATCH_SUCCESS,
    GET_MATCH_FAIL,
    CONTEST_MATCH,
    PUBLISH_MATCH,
    PUBLISH_MATCH_SUCCESS,
    PUBLISH_MATCH_FAIL,
    UPDATE_STATUS_LIVE_NOW,
    UPDATE_STATUS_LIVE_NOW_SUCCESS,
    UPDATE_STATUS_LIVE_NOW_FAIL,
    UPDATE_MATCH_TAG,
    UPDATE_MATCH_TAG_SUCCESS,
    UPDATE_MATCH_TAG_FAIL,
    COMING_SOON_MATCH,
    COMING_SOON_MATCH_SUCCESS,
    COMING_SOON_MATCH_FAIL,
    UPDATE_MATCH_TYPE,
    UPDATE_MATCH_TYPE_SUCCESS,
    UPDATE_MATCH_TYPE_FAIL,
    SUBSTITUTE_PLAYER,
    SUBSTITUTE_PLAYER_SUCCESS,
    SUBSTITUTE_PLAYER_FAIL,
    MARK_MATCH_FR,
    MARK_MATCH_FR_SUCCESS,
    MARK_MATCH_FR_FAIL,
    MATCH_DRAFT_TO_LIVE,
    MATCH_DRAFT_TO_LIVE_SUCCESS,
    MATCH_DRAFT_TO_LIVE_FAIL,
    PLAYER_FREEZE_UNFREEZE,
    PLAYER_FREEZE_UNFREEZE_SUCCESS,
    PLAYER_FREEZE_UNFREEZE_FAIL,
} from './Constants';

import {
    GET_MATCH_DETAIL_FAIL,
    GET_MATCH_DETAIL_INIT,
    GET_MATCH_DETAIL_SUCCESS,
    MARK_LIVE_MATCH_INIT,
    MARK_LIVE_MATCH_SUCCESS,
    MARK_LIVE_MATCH_FAIL,
    MARK_MATCH_CANCEL_INIT,
    MARK_MATCH_CANCEL_SUCCESS,
    MARK_MATCH_CANCEL_FAIL,
    MARK_MATCH_OF_THE_DAY_INIT,
    MARK_MATCH_OF_THE_DAY_SUCCESS,
    MARK_MATCH_OF_THE_DAY_FAIL,
} from './AxioCalls';

const initialState = {
    matchListRequest: false,
    matchListSuccess: false,
    matchListFail: false,
    matchList: {},
    errorMessage: '',
    addmatchRequest: false,
    addmatchSuccess: false,
    addmatchFail: false,
    addmatch: [],
    editMatch: [],
    updateMatchRequest: false,
    updateMatchSuccess: false,
    updateMatchFail: false,
    updateMatch: [],
    updatePlayerStatsRequest: false,
    updatePlayerStatsSuccess: false,
    updatePlayerStatsFail: false,
    updatePlayerStats: [],
    rescheduleMatchRequest: false,
    rescheduleMatchSuccess: false,
    rescheduleMatchFail: false,
    rescheduleMatch: [],
    deleteMatchRequest: false,
    deleteMatchSuccess: false,
    deleteMatchFail: false,
    deleteMatch: [],
    getMatchRequest: false,
    getMatchSuccess: false,
    getMatchFail: false,
    getMatch: [],
    contestMatch: '',
    publishMatchRequest: false,
    publishMatchSuccess: false,
    publishMatchFail: false,
    publishMatch: [],
    getMatchDetailInit: false,
    getMatchDetailSuccess: false,
    getMatchDetailFail: false,
    getMatchDetailResponse: {},
    markLiveMatchInit: false,
    markLiveMatchSuccess: false,
    markLiveMatchFail: false,
    markLiveMatchResponse: {},
    markCancelMatchInit: false,
    markCancelMatchSuccess: false,
    markCancelMatchFail: false,
    markCancelMatchResponse: {},
    markMatchOfTheDayInit: false,
    markMatchOfTheDaySuccess: false,
    markMatchOfTheDayFail: false,
    markMatchOfTheDayResponse: {},

    isUpdatingStatusLive: false,
    isUpdatingStatusLiveSuccess: false,
    isUpdatingStatusLiveFail: false,
    updateStatusLive: {},

    isUpdatingMatchTag: false,
    isUpdatingMatchTagSuccess: false,
    isUpdatingMatchTagFail: false,

    isComingSoonMatch: false,
    isComingSoonMatchSuccess: false,
    isComingSoonMatchFail: false,

    isUpdatingMatchType: false,
    isUpdatingMatchTypeSuccess: false,
    isUpdatingMatchTypeFail: false,

    isSubstitutePlayer: false,
    isSubstitutePlayerSuccess: false,
    isSubstitutePlayerFail: false,

    isPlayerFreezeUnfreeze: false,
    isPlayerFreezeUnfreezeSuccess: false,
    isPlayerFreezeUnfreezeFail: false,

    isMatchMarkAsFR: false,
    isMatchMarkAsFRSuccess: false,
    isMatchMarkAsFRFail: false,
    martFRDetails: {},

    isMatchDraftToLive: false,
    isMatchDraftToLiveSuccess: false,
    isMatchDraftToLiveFail: false,
};

const matchReducer = (state = initialState, action) => {
    switch (action.type) {
        case MATCH_LIST:
            return {
                ...state,
                matchListRequest: true,
                matchListSuccess: false,
                matchListFail: false,
                addmatchRequest: false,
                addmatchSuccess: false,
                addmatchFail: false,
                matchList: {},
                errorMessage: '',
            };

        case MATCH_LIST_SUCCESS:
            return {
                ...state,
                matchListRequest: false,
                matchListSuccess: true,
                matchListFail: false,
                matchList: action.payload.data,
                errorMessage: '',
            };

        case MATCH_LIST_FAIL:
            return {
                ...state,
                matchListRequest: false,
                matchListSuccess: false,
                matchListFail: true,
                errorMessage: action.payload,
            };
        // ADD match
        case ADD_MATCH:
            return {
                ...state,
                addmatchRequest: true,
                addmatchSuccess: false,
                addmatchFail: false,
                addmatch: [],
                errorMessage: '',
            };

        case ADD_MATCH_SUCCESS:
            return {
                ...state,
                addmatchRequest: false,
                addmatchSuccess: true,
                addmatchFail: false,
                addmatch: action.payload.data,
                errorMessage: '',
            };

        case ADD_MATCH_FAIL:
            return {
                ...state,
                addmatchRequest: false,
                addmatchSuccess: false,
                addmatchFail: true,
                errorMessage: action.payload,
            };

        // EDIT
        case EDIT_MATCH:
            return {
                ...state,
                editMatch: action.payload,
            };

        // update
        case UPDATE_MATCH:
            return {
                ...state,
                updateMatchRequest: true,
                updateMatchSuccess: false,
                updateMatchFail: false,
                updateMatch: [],
                errorMessage: '',
            };

        case UPDATE_MATCH_SUCCESS:
            return {
                ...state,
                updateMatchRequest: false,
                updateMatchSuccess: true,
                updateMatchFail: false,
                updateMatch: action.payload.data,
                errorMessage: '',
            };

        case UPDATE_MATCH_FAIL:
            return {
                ...state,
                updateMatchRequest: false,
                updateMatchSuccess: false,
                updateMatchFail: true,
                errorMessage: action.payload,
            };

        case UPDATE_PLAYERSTATS:
            return {
                ...state,
                updatePlayerStatsRequest: true,
                updatePlayerStatsSuccess: false,
                updatePlayerStatsFail: false,
                updatePlayerStats: [],
                errorMessage: '',
            };

        case UPDATE_PLAYERSTATS_SUCCESS:
            return {
                ...state,
                updatePlayerStatsRequest: false,
                updatePlayerStatsSuccess: true,
                updatePlayerStatsFail: false,
                updatePlayerStats: action.payload.data,
                errorMessage: '',
            };

        case UPDATE_PLAYERSTATS_FAIL:
            return {
                ...state,
                updatePlayerStatsRequest: false,
                updatePlayerStatsSuccess: false,
                updatePlayerStatsFail: true,
                errorMessage: action.payload,
            };

        case RESCHEDULE_MATCH:
            return {
                ...state,
                rescheduleMatchRequest: true,
                rescheduleMatchSuccess: false,
                rescheduleMatchFail: false,
                errorMessage: '',
            };

        case RESCHEDULE_MATCH_SUCCESS:
            return {
                ...state,
                rescheduleMatchRequest: false,
                rescheduleMatchSuccess: true,
                rescheduleMatchFail: false,
                rescheduleMatch: action.payload.data,
                errorMessage: '',
            };

        case RESCHEDULE_MATCH_FAIL:
            return {
                ...state,
                rescheduleMatchRequest: false,
                rescheduleMatchSuccess: false,
                rescheduleMatchFail: true,
                errorMessage: action.payload,
            };

        // delete

        case DELETE_MATCH:
            return {
                ...state,
                deleteMatchRequest: true,
                deleteMatchSuccess: false,
                deleteMatchFail: false,
                deleteMatch: [],
                errorMessage: '',
            };

        case DELETE_MATCH_SUCCESS:
            return {
                ...state,
                deleteMatchRequest: false,
                deleteMatchSuccess: true,
                deleteMatchFail: false,
                deleteMatch: action.payload.data,
                errorMessage: '',
            };

        case DELETE_MATCH_FAIL:
            return {
                ...state,
                deleteMatchRequest: false,
                deleteMatchSuccess: false,
                deleteMatchFail: true,
                errorMessage: action.payload,
            };

        // get single match details
        case GET_MATCH:
            return {
                ...state,
                getMatchRequest: true,
                getMatchSuccess: false,
                getMatchFail: false,
                getMatch: [],
                errorMessage: '',
            };

        case GET_MATCH_SUCCESS:
            return {
                ...state,
                getMatchRequest: false,
                getMatchSuccess: true,
                getMatchFail: false,
                getMatch: action.payload.data,
                errorMessage: '',
            };

        case GET_MATCH_FAIL:
            return {
                ...state,
                getMatchRequest: false,
                getMatchSuccess: false,
                getMatchFail: true,
                errorMessage: action.payload,
            };
        case CONTEST_MATCH:
            return {
                ...state,
                contestMatch: action.payload,
            };

        //PUBLISH MATCH

        case PUBLISH_MATCH:
            return {
                ...state,
                publishMatchRequest: true,
                publishMatchSuccess: false,
                publishMatchFail: false,
                publishMatch: [],
                errorMessage: '',
            };

        case PUBLISH_MATCH_SUCCESS:
            return {
                ...state,
                publishMatchRequest: false,
                publishMatchSuccess: true,
                publishMatchFail: false,
                publishMatch: action.payload.data,
                errorMessage: '',
            };

        case PUBLISH_MATCH_FAIL:
            return {
                ...state,
                publishMatchRequest: false,
                publishMatchSuccess: false,
                publishMatchFail: true,
                errorMessage: action.payload,
            };

        case GET_MATCH_DETAIL_INIT:
            return {
                ...state,
                getMatchDetailInit: true,
                getMatchDetailSuccess: false,
                getMatchDetailFail: false,
                getMatchDetailResponse: {},
            };

        case GET_MATCH_DETAIL_SUCCESS:
            return {
                ...state,
                getMatchDetailInit: false,
                getMatchDetailSuccess: true,
                getMatchDetailFail: false,
                getMatchDetailResponse: action.payload,
            };

        case GET_MATCH_DETAIL_FAIL:
            return {
                ...state,
                getMatchDetailInit: false,
                getMatchDetailSuccess: false,
                getMatchDetailFail: true,
                getMatchDetailResponse: action.payload,
            };
        case MARK_LIVE_MATCH_INIT:
            return {
                ...state,
                markLiveMatchInit: true,
                markLiveMatchSuccess: false,
                markLiveMatchFail: false,
                markLiveMatchResponse: {},
                errorMessage: '',
            };
        case MARK_LIVE_MATCH_SUCCESS:
            return {
                ...state,
                markLiveMatchInit: false,
                markLiveMatchSuccess: true,
                markLiveMatchFail: false,
                markLiveMatchResponse: action.payload,
                errorMessage: '',
            };
        case MARK_LIVE_MATCH_FAIL:
            return {
                ...state,
                markLiveMatchInit: false,
                markLiveMatchSuccess: false,
                markLiveMatchFail: true,
                errorMessage: action.payload,
            };
        case MARK_MATCH_CANCEL_INIT:
            return {
                ...state,
                markCancelMatchInit: true,
                markCancelMatchSuccess: false,
                markCancelMatchFail: false,
                markCancelMatchResponse: {},
            };
        case MARK_MATCH_CANCEL_SUCCESS:
            return {
                ...state,
                markCancelMatchInit: false,
                markCancelMatchSuccess: true,
                markCancelMatchFail: false,
                markCancelMatchResponse: action.payload,
            };
        case MARK_MATCH_CANCEL_FAIL:
            return {
                ...state,
                markCancelMatchInit: false,
                markCancelMatchSuccess: false,
                markCancelMatchFail: true,
                markCancelMatchResponse: action.payload,
            };
        case MARK_MATCH_OF_THE_DAY_INIT:
            return {
                ...state,
                markMatchOfTheDayInit: true,
                markMatchOfTheDaySuccess: false,
                markMatchOfTheDayFail: false,
                markMatchOfTheDayResponse: {},
            };
        case MARK_MATCH_OF_THE_DAY_SUCCESS:
            return {
                ...state,
                markMatchOfTheDayInit: false,
                markMatchOfTheDaySuccess: true,
                markMatchOfTheDayFail: false,
                markMatchOfTheDayResponse: action.payload,
            };
        case MARK_MATCH_OF_THE_DAY_FAIL:
            return {
                ...state,
                markMatchOfTheDayInit: false,
                markMatchOfTheDaySuccess: false,
                markMatchOfTheDayFail: true,
                markMatchOfTheDayResponse: action.payload,
            };

        case UPDATE_STATUS_LIVE_NOW:
            return {
                ...state,
                isUpdatingStatusLive: true,
                isUpdatingStatusLiveSuccess: false,
                isUpdatingStatusLiveFail: false,
                errorMessage: '',
            };

        case UPDATE_STATUS_LIVE_NOW_SUCCESS:
            return {
                ...state,
                isUpdatingStatusLive: false,
                isUpdatingStatusLiveSuccess: true,
                isUpdatingStatusLiveFail: false,
                errorMessage: '',
            };

        case UPDATE_STATUS_LIVE_NOW_FAIL:
            return {
                ...state,
                isUpdatingStatusLive: false,
                isUpdatingStatusLiveSuccess: false,
                isUpdatingStatusLiveFail: true,
                errorMessage: action.payload,
            };

        case UPDATE_MATCH_TAG:
            return {
                ...state,
                isUpdatingMatchTag: true,
                isUpdatingMatchTagSuccess: false,
                isUpdatingMatchTagFail: false,
                errorMessage: '',
            };

        case UPDATE_MATCH_TAG_SUCCESS:
            return {
                ...state,
                isUpdatingMatchTag: false,
                isUpdatingMatchTagSuccess: true,
                isUpdatingMatchTagFail: false,
                errorMessage: '',
            };

        case UPDATE_MATCH_TAG_FAIL:
            return {
                ...state,
                isUpdatingMatchTag: false,
                isUpdatingMatchTagSuccess: false,
                isUpdatingMatchTagFail: true,
                errorMessage: action.payload,
            };

        case COMING_SOON_MATCH:
            return {
                ...state,
                isComingSoonMatch: true,
                isComingSoonMatchSuccess: false,
                isComingSoonMatchFail: false,
                errorMessage: '',
            };
        case COMING_SOON_MATCH_SUCCESS:
            return {
                ...state,
                isComingSoonMatch: false,
                isComingSoonMatchSuccess: true,
                isComingSoonMatchFail: false,
                errorMessage: '',
            };
        case COMING_SOON_MATCH_FAIL:
            return {
                ...state,
                isComingSoonMatch: false,
                isComingSoonMatchSuccess: false,
                isComingSoonMatchFail: true,
                errorMessage: action.payload,
            };

        case UPDATE_MATCH_TYPE:
            return {
                ...state,
                isUpdatingMatchType: true,
                isUpdatingMatchTypeSuccess: false,
                isUpdatingMatchTypeFail: false,
                errorMessage: '',
            };
        case UPDATE_MATCH_TYPE_SUCCESS:
            return {
                ...state,
                isUpdatingMatchType: false,
                isUpdatingMatchTypeSuccess: true,
                isUpdatingMatchTypeFail: false,
                errorMessage: action.payload,
            };
        case UPDATE_MATCH_TYPE_FAIL:
            return {
                ...state,
                isUpdatingMatchType: false,
                isUpdatingMatchTypeSuccess: false,
                isUpdatingMatchTypeFail: true,
                errorMessage: action.payload,
            };
        case SUBSTITUTE_PLAYER:
            return {
                ...state,
                isSubstitutePlayer: true,
                isSubstitutePlayerSuccess: false,
                isSubstitutePlayerFail: false,
                errorMessage: '',
            };
        case SUBSTITUTE_PLAYER_SUCCESS:
            return {
                ...state,
                isSubstitutePlayer: false,
                isSubstitutePlayerSuccess: true,
                isSubstitutePlayerFail: false,
                errorMessage: action.payload,
            };
        case SUBSTITUTE_PLAYER_FAIL:
            return {
                ...state,
                isSubstitutePlayer: false,
                isSubstitutePlayerSuccess: false,
                isSubstitutePlayerFail: true,
                errorMessage: action.payload,
            };
        case PLAYER_FREEZE_UNFREEZE:
            return {
                ...state,
                isPlayerFreezeUnfreeze: true,
                isPlayerFreezeUnfreezeSuccess: false,
                isPlayerFreezeUnfreezeFail: false,
                errorMessage: '',
            };
        case PLAYER_FREEZE_UNFREEZE_SUCCESS:
            return {
                ...state,
                isPlayerFreezeUnfreeze: false,
                isPlayerFreezeUnfreezeSuccess: true,
                isPlayerFreezeUnfreezeFail: false,
                errorMessage: action.payload,
            };
        case PLAYER_FREEZE_UNFREEZE_FAIL:
            return {
                ...state,
                isPlayerFreezeUnfreeze: false,
                isPlayerFreezeUnfreezeSuccess: false,
                isPlayerFreezeUnfreezeFail: true,
                errorMessage: action.payload,
            };
        case MARK_MATCH_FR:
            return {
                ...state,
                isMatchMarkAsFR: true,
                isMatchMarkAsFRSuccess: false,
                isMatchMarkAsFRFail: false,
                errorMessage: '',
                martFRDetails: {},
            };
        case MARK_MATCH_FR_SUCCESS:
            return {
                ...state,
                isMatchMarkAsFR: false,
                isMatchMarkAsFRSuccess: true,
                isMatchMarkAsFRFail: false,
                martFRDetails: action.payload,
            };
        case MARK_MATCH_FR_FAIL:
            return {
                ...state,
                isMatchMarkAsFR: false,
                isMatchMarkAsFRSuccess: false,
                isMatchMarkAsFRFail: true,
                errorMessage: action.payload,
            };

        case MATCH_DRAFT_TO_LIVE:
            return {
                ...state,
                isMatchDraftToLive: true,
                isMatchDraftToLiveSuccess: false,
                isMatchDraftToLiveFail: false,
                errorMessage: '',
            };
        case MATCH_DRAFT_TO_LIVE_SUCCESS:
            return {
                ...state,
                isMatchDraftToLive: false,
                isMatchDraftToLiveSuccess: true,
                isMatchDraftToLiveFail: false,
                errorMessage: action.payload,
            };
        case MATCH_DRAFT_TO_LIVE_FAIL:
            return {
                ...state,
                isMatchDraftToLive: false,
                isMatchDraftToLiveSuccess: false,
                isMatchDraftToLiveFail: true,
                errorMessage: action.payload,
            };

        default:
            return state;
    }
};

export default matchReducer;
