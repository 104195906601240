import {
    CHECK_WITHDRAWAL_STATUS_INIT,
    CHECK_WITHDRAWAL_STATUS_SUCCESS,
    CHECK_WITHDRAWAL_STATUS_FAILED,
    CHECK_AND_UPDATE_WITHDRAWAL_INIT,
    CHECK_AND_UPDATE_WITHDRAWAL_SUCCESS,
    CHECK_AND_UPDATE_WITHDRAWAL_FAILED,
} from './AxioCalls';

import {
    WITHDRAWAL_LIST,
    WITHDRAWAL_LIST_SUCCESS,
    WITHDRAWAL_LIST_FAIL,
    WITHDRAWAL_UPDATE,
    WITHDRAWAL_UPDATE_SUCCESS,
    WITHDRAWAL_UPDATE_FAIL,
    WITHDRAWAL_ACTION_REQUEST,
    WITHDRAWAL_ACTION_SUCCESS,
    WITHDRAWAL_ACTION_FAILURE,
} from './Constants';

const initialState = {
    withdrawalListRequest: false,
    withdrawalListSuccess: false,
    withdrawalListFail: false,
    withdrawalList: [],
    errorMessage: '',
    withdrawalUpdateRequest: false,
    withdrawalUpdateSuccess: false,
    withdrawalUpdateFail: false,
    withdrawalUpdate: [],
    checkWithdrawalStatusInit: false,
    checkWithdrawalStatusSuccess: false,
    checkWithdrawalStatusFailed: false,
    checkWithdrawalStatusResponse: {},
    checkAndUpdateWithdrawalInit: false,
    checkAndUpdateWithdrawalSuccess: false,
    checkAndUpdateWithdrawalFailed: false,
    checkAndUpdateWithdrawalResponse: {},
    actionTaken: '',
    withdrawalActionRequest: false,
    withdrawalActionSuccess: false,
    withdrawalActionFail: false,
};

const withdrawalReducer = (state = initialState, action) => {
    switch (action.type) {
        //list all withdrawals
        case WITHDRAWAL_LIST:
            return {
                ...state,
                withdrawalListRequest: true,
                withdrawalListSuccess: false,
                withdrawalListFail: false,
                withdrawalList: [],
                errorMessage: '',
            };

        case WITHDRAWAL_LIST_SUCCESS:
            return {
                ...state,
                withdrawalListRequest: false,
                withdrawalListSuccess: true,
                withdrawalListFail: false,
                withdrawalList: action.payload.data,
                errorMessage: '',
            };

        case WITHDRAWAL_LIST_FAIL:
            return {
                ...state,
                withdrawalListRequest: false,
                withdrawalListSuccess: false,
                withdrawalListFail: true,
                errorMessage: action.payload,
            };

        // WITHDRAWAL UPDATE
        case WITHDRAWAL_UPDATE:
            return {
                ...state,
                withdrawalUpdateRequest: true,
                withdrawalUpdateSuccess: false,
                withdrawalUpdateFail: false,
                withdrawalUpdate: [],
                errorMessage: '',
            };

        case WITHDRAWAL_UPDATE_SUCCESS:
            return {
                ...state,
                withdrawalUpdateRequest: false,
                withdrawalUpdateSuccess: true,
                withdrawalUpdateFail: false,
                withdrawalUpdate: action.payload.data,
                errorMessage: '',
            };

        case WITHDRAWAL_UPDATE_FAIL:
            return {
                ...state,
                withdrawalUpdateRequest: false,
                withdrawalUpdateSuccess: false,
                withdrawalUpdateFail: true,
                errorMessage: action.payload,
            };

        case CHECK_WITHDRAWAL_STATUS_INIT:
            return {
                ...state,
                checkWithdrawalStatusInit: true,
                checkWithdrawalStatusSuccess: false,
                checkWithdrawalStatusFailed: false,
                checkWithdrawalStatusResponse: {},
            };
        case CHECK_WITHDRAWAL_STATUS_SUCCESS:
            return {
                ...state,
                checkWithdrawalStatusInit: false,
                checkWithdrawalStatusSuccess: true,
                checkWithdrawalStatusFailed: false,
                checkWithdrawalStatusResponse: action.payload,
            };
        case CHECK_WITHDRAWAL_STATUS_FAILED:
            return {
                ...state,
                checkWithdrawalStatusInit: false,
                checkWithdrawalStatusSuccess: false,
                checkWithdrawalStatusFailed: true,
                checkWithdrawalStatusResponse: action.payload,
            };

        case CHECK_AND_UPDATE_WITHDRAWAL_INIT:
            return {
                ...state,
                checkAndUpdateWithdrawalInit: true,
                checkAndUpdateWithdrawalSuccess: false,
                checkAndUpdateWithdrawalFailed: false,
                checkAndUpdateWithdrawalResponse: {},
            };
        case CHECK_AND_UPDATE_WITHDRAWAL_SUCCESS:
            return {
                ...state,
                checkAndUpdateWithdrawalInit: false,
                checkAndUpdateWithdrawalSuccess: true,
                checkAndUpdateWithdrawalFailed: false,
                checkAndUpdateWithdrawalResponse: action.payload,
            };
        case CHECK_AND_UPDATE_WITHDRAWAL_FAILED:
            return {
                ...state,
                checkAndUpdateWithdrawalInit: false,
                checkAndUpdateWithdrawalSuccess: false,
                checkAndUpdateWithdrawalFailed: true,
                checkAndUpdateWithdrawalResponse: action.payload,
            };
        case WITHDRAWAL_ACTION_REQUEST:
            return {
                ...state,
                withdrawalActionRequest: true,
                withdrawalActionSuccess: false,
                withdrawalActionFail: false,
            };
        case WITHDRAWAL_ACTION_SUCCESS:
            return {
                ...state,
                withdrawalActionRequest: false,
                withdrawalActionSuccess: true,
                withdrawalActionFail: false,
                errorMessage: action.payload,
            };
        case WITHDRAWAL_ACTION_FAILURE:
            return {
                ...state,
                withdrawalActionRequest: false,
                withdrawalActionSuccess: false,
                withdrawalActionFail: true,
                errorMessage: action.payload,
            };

        default:
            return state;
    }
};

export default withdrawalReducer;
