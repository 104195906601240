import {
    GET_RECOMMENDED_TEAMS,
    GET_RECOMMENDED_TEAMS_SUCCESS,
    GET_RECOMMENDED_TEAMS_FAIL,
    CREATE_RECOMMENDED_TEAM,
    CREATE_RECOMMENDED_TEAM_SUCCESS,
    CREATE_RECOMMENDED_TEAM_FAIL,
    UPDATE_RECOMMENDED_TEAM,
    UPDATE_RECOMMENDED_TEAM_SUCCESS,
    UPDATE_RECOMMENDED_TEAM_FAIL,
    DELETE_RECOMMENDED_TEAM,
    DELETE_RECOMMENDED_TEAM_SUCCESS,
    DELETE_RECOMMENDED_TEAM_FAIL,
    AUTO_CREATE_RECOMMENDED_TEAM,
    AUTO_CREATE_RECOMMENDED_TEAM_SUCCESS,
    AUTO_CREATE_RECOMMENDED_TEAM_FAIL,
} from './Constants';

const initialState = {
    recommendedList: {},

    isFetchingTeams: false,
    isFetchingTeamsSuccess: false,
    isFetchingTeamsFail: false,

    isCreatingTeam: false,
    isCreatingTeamSuccess: false,
    isCreatingTeamFail: false,

    isUpdatingTeam: false,
    isUpdatingTeamSuccess: false,
    isUpdatingTeamFail: false,

    isDeletingTeam: false,
    isDeletingTeamSuccess: false,
    isDeletingTeamFail: false,

    isAutoCreatingTeam: false,
    isAutoCreatingTeamSuccess: false,
    isAutoCreatingTeamFail: false,

    errorMessage: '',
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_RECOMMENDED_TEAMS:
            return {
                ...state,
                isFetchingTeams: true,
                isFetchingTeamsSuccess: false,
                isFetchingTeamsFail: false,
                recommendedList: [],
                errorMessage: '',
            };
        case GET_RECOMMENDED_TEAMS_SUCCESS:
            return {
                ...state,
                isFetchingTeams: false,
                isFetchingTeamsSuccess: true,
                isFetchingTeamsFail: false,
                recommendedList: action.payload.data,
                errorMessage: '',
            };
        case GET_RECOMMENDED_TEAMS_FAIL:
            return {
                ...state,
                isFetchingTeams: false,
                isFetchingTeamsSuccess: false,
                isFetchingTeamsFail: true,
                errorMessage: action.payload,
            };

        case CREATE_RECOMMENDED_TEAM:
            return {
                ...state,
                isCreatingTeam: true,
                isCreatingTeamSuccess: false,
                isCreatingTeamFail: false,
                errorMessage: '',
            };
        case CREATE_RECOMMENDED_TEAM_SUCCESS:
            return {
                ...state,
                isCreatingTeam: false,
                isCreatingTeamSuccess: true,
                isCreatingTeamFail: false,
                errorMessage: '',
            };
        case CREATE_RECOMMENDED_TEAM_FAIL:
            return {
                ...state,
                isCreatingTeam: false,
                isCreatingTeamSuccess: false,
                isCreatingTeamFail: true,
                errorMessage: action.payload,
            };

        case UPDATE_RECOMMENDED_TEAM:
            return {
                ...state,
                isUpdatingTeam: true,
                isUpdatingTeamSuccess: false,
                isUpdatingTeamFail: false,
                errorMessage: '',
            };
        case UPDATE_RECOMMENDED_TEAM_SUCCESS:
            return {
                ...state,
                isUpdatingTeam: false,
                isUpdatingTeamSuccess: true,
                isUpdatingTeamFail: false,
                errorMessage: '',
            };
        case UPDATE_RECOMMENDED_TEAM_FAIL:
            return {
                ...state,
                isUpdatingTeam: false,
                isUpdatingTeamSuccess: false,
                isUpdatingTeamFail: true,
                errorMessage: action.payload,
            };

        case DELETE_RECOMMENDED_TEAM:
            return {
                ...state,
                isDeletingTeam: true,
                isDeletingTeamSuccess: false,
                isDeletingTeamFail: false,
                errorMessage: '',
            };
        case DELETE_RECOMMENDED_TEAM_SUCCESS:
            return {
                ...state,
                isDeletingTeam: false,
                isDeletingTeamSuccess: true,
                isDeletingTeamFail: false,
                errorMessage: '',
            };
        case DELETE_RECOMMENDED_TEAM_FAIL:
            return {
                ...state,
                isDeletingTeam: false,
                isDeletingTeamSuccess: false,
                isDeletingTeamFail: true,
                errorMessage: action.payload,
            };

        case AUTO_CREATE_RECOMMENDED_TEAM:
            return {
                ...state,
                isAutoCreatingTeam: true,
                isAutoCreatingTeamSuccess: false,
                isAutoCreatingTeamFail: false,
                errorMessage: '',
            };
        case AUTO_CREATE_RECOMMENDED_TEAM_SUCCESS:
            return {
                ...state,
                isAutoCreatingTeam: false,
                isAutoCreatingTeamSuccess: true,
                isAutoCreatingTeamFail: false,
                errorMessage: '',
            };
        case AUTO_CREATE_RECOMMENDED_TEAM_FAIL:
            return {
                ...state,
                isAutoCreatingTeam: false,
                isAutoCreatingTeamSuccess: false,
                isAutoCreatingTeamFail: true,
                errorMessage: action.payload,
            };

        default:
            return state;
    }
};
