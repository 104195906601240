import {
    FETCH_USER_KYC_LIST,
    FETCH_USER_KYC_LIST_SUCCESS,
    FETCH_USER_KYC_LIST_FAIL,
    FETCH_USER_BANK_KYC_LIST,
    FETCH_USER_BANK_KYC_LIST_SUCCESS,
    FETCH_USER_BANK_KYC_LIST_FAIL,
} from './Constants';

const initialState = {
    userKycList: {},
    isFetchingUserKycList: false,
    isFetchingUserKycListSuccess: false,
    isFetchingUserKycListFail: false,

    userBankKycList: {},
    isFetchingUserBankKycList: false,
    isFetchingUserBankKycListSuccess: false,
    isFetchingUserBankKycListFail: false,
    kycActionTaken: '',
    message: '',
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_USER_KYC_LIST:
            return {
                ...state,
                isFetchingUserKycList: true,
                isFetchingUserKycListSuccess: false,
                isFetchingUserKycListFail: false,
                userKycList: {},
                message: '',
            };
        case FETCH_USER_KYC_LIST_SUCCESS:
            return {
                ...state,
                isFetchingUserKycList: false,
                isFetchingUserKycListSuccess: true,
                isFetchingUserKycListFail: false,
                userKycList: action?.payload?.data || {},
                message: '',
            };
        case FETCH_USER_KYC_LIST_FAIL:
            return {
                ...state,
                isFetchingUserKycList: false,
                isFetchingUserKycListSuccess: false,
                isFetchingUserKycListFail: true,
                message: action.payload,
            };

        case FETCH_USER_BANK_KYC_LIST:
            return {
                ...state,
                isFetchingUserBankKycList: true,
                isFetchingUserBankKycListSuccess: false,
                isFetchingUserBankKycListFail: false,
                userBankKycList: {},
                message: '',
            };
        case FETCH_USER_BANK_KYC_LIST_SUCCESS:
            return {
                ...state,
                isFetchingUserBankKycList: false,
                isFetchingUserBankKycListSuccess: true,
                isFetchingUserBankKycListFail: false,
                userBankKycList: action?.payload?.data || {},
                message: '',
            };
        case FETCH_USER_BANK_KYC_LIST_FAIL:
            return {
                ...state,
                isFetchingUserBankKycList: false,
                isFetchingUserBankKycListSuccess: false,
                isFetchingUserBankKycListFail: true,
                message: action.payload,
            };

        default:
            return state;
    }
};
