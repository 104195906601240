import React from 'react';
import {connect} from 'react-redux';

import '../../../Utilities/style.css';
import '../Login/login.css';
import {getSquadList, deleteSquad} from './SquadAxios';
import {timeFormat} from '../../../Utilities/Helpers';
import Table from '../../../Components/Table/Table';
import TableLayout from '../../Layouts/TableLayout/TableLayout';
import {editSquad} from './squadAction';
import Snackbar from '../../../Components/Snackbar/Snackbar';
import {logout} from '../Login/LoginAction';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';
import {debounce, next, prev} from '../../../Utilities/HelperFunctions';
import FilterInput from '../../../Components/FilterInput';
import {ENTER} from '../../../Utilities/Constants';
import DropDown from '../../../Components/Dropdown/Dropdown';
import Button from '../../../Components/Button/Button';
import {getTOGameList} from '../TOGame/AxioCalls';

class SquadList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            squads: [],
            list: [],
            open: false,
            pageData: {},
            pageOptions: {
                page: 1,
                pageSize: 10,
            },
            sortOptions: [
                {value: 'id', text: 'Squad Id'},
                {value: 'updatedAt', text: 'Last Update'},
                {value: 'squadName', text: 'Squad Name'},
                {text: 'Status', value: 'status'},
            ],
        };
    }

    componentDidMount() {
        const {login, history, client} = this.props;
        if (!login?.userDetails?.data) {
            history.push('/');
            this.props.logout();
        } else {
            this.props.getTOGameList({listType: 'ALL'}, login?.userDetails?.data?.accessToken, client.selectedClient);
            this.getSquadListDebounce({immediate: true});
        }
    }

    componentDidUpdate(prevProps) {
        const {squad, toGame} = this.props;
        if (squad.squadListRequest && !prevProps.squad.squadListRequest) {
            this.setState({loading: true});
        }
        if (squad.squadListSuccess && !prevProps.squad.squadListSuccess) {
            this.setState({
                list: squad.squadList.data.data,
                pageData: squad.squadList.data.pageData,
                loading: false,
            });
        } else if (squad.squadListFail && !prevProps.squad.squadListFail) {
            this.setState({errorMessage: 'Something went wrong', loading: false});
        }
        if (squad.deleteSquadsuccess && !prevProps.squad.deleteSquadsuccess) {
            this.setState({errorMessage: squad.deleteSquad.msg, squads: [], loading: false});
            this.getSquadListDebounce({immediate: true});
        } else if (squad.deleteSquadFail && !prevProps.squad.deleteSquadFail) {
            this.setState({errorMessage: squad.errorMessage, loading: false});
        }

        if (toGame.TOGameListSuccess && !prevProps.toGame.TOGameListSuccess) {
            const games = toGame?.TOGameList?.data?.data?.map((item) => ({text: item.gameName, value: item.id}));
            this.setState({gameOptions: games});
        }
    }

    getSquadListDebounce = debounce(() => {
        const {login,client} = this.props;
        this.props.getSquadList(this.state.pageOptions, login?.userDetails?.data?.accessToken,client.selectedClient);
    });

    handleFilter = (field, value, debounce = true) => {
        const {pageOptions} = this.state;
        if (field === ENTER) {
            this.getSquadListDebounce({immediate: true});
            return;
        }
        pageOptions[field] = value;
        this.setState({pageOptions: {...pageOptions, page: 1}}, () => {
            this.getSquadListDebounce(debounce ? {} : {immediate: true});
        });
    };

    onReset = () => {
        this.setState({pageOptions: {page: 1, pageSize: 10}}, () => {
            this.getSquadListDebounce({immediate: true});
        });
    };

    /**
     * pagination and api call on next click
     */

    onNext() {
        const {pageData, pageOptions} = this.state;
        let options = next(pageOptions, pageData);
        if (options) {
            this.setState({pageOptions: options}, () => this.getSquadListDebounce({immediate: true}));
        }
    }

    /**
     * pagination  and api call on prev click
     */
    onPrev() {
        const {pageOptions} = this.state;
        let options = prev(pageOptions);
        if (options) {
            this.setState({pageOptions: options}, () => this.getSquadListDebounce({immediate: true}));
        }
    }

    /**
     * get details of the squad clicked
     * @param {*} id id of the squad clicked
     */
    editSquadData(id) {
        const {squad, history} = this.props;

        let squads = squad.squadList.data.data;
        const editSquad = squads.filter((squad) => squad.id === id);

        this.props.editSquad(editSquad[0]);
        history.push('/editSquad/' + id);
    }

    render() {
        const {list, errorMessage, loading, sortOptions, pageData, gameOptions} = this.state;
        const {squadName, squadId, gameId, sortOrder, page} = this.state.pageOptions;

        let disableNext = page === pageData.lastPage;
        const data = list;
        const {history} = this.props;
        const columns = [
            {
                Header: 'Last Updated',
                accessor: 'updatedAt',
                width: 200,
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : timeFormat(props.value)}
                    </span>
                ),
            },
            {
                Header: 'Last Match',
                width: 200,
                accessor: 'lastMatch',
                Cell: (props) => (
                    <span className="number ">
                        {props.value === undefined || props.value === '' ? '-' : timeFormat(props.value)}
                    </span>
                ),
            },
            {
                Header: 'Next Match',
                accessor: 'nextMatch',
                width: 200,
                Cell: (props) => (
                    <span className="number ">
                        {props.value === undefined || props.value === '' ? '-' : timeFormat(props.value)}
                    </span>
                ),
            },
            {
                Header: 'Squad ID',
                accessor: 'id',
                width: 200,
                Cell: (props) => (
                    <span className="table-clickable" onClick={this.editSquadData.bind(this, props.value)}>
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Squad Name',
                accessor: 'squadName',
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Game',
                accessor: 'gameName',
                Cell: (props) => <span className="number">{props.value || '-'}</span>,
            },
            {
                Header: 'Players',
                accessor: 'players',
                Cell: (props) => <span className="number">{props.value.length}</span>,
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
        ];
        return (
            <TableLayout location="squads" history={history} hideSearchBar>
                {!!loading && <LoadingModal open={!!loading} />}

                {!!errorMessage && (
                    <Snackbar
                        open={!!errorMessage}
                        message={errorMessage}
                        onClose={() => this.setState({errorMessage: ''})}
                    />
                )}

                <div className="filter-container my-10">
                    <FilterInput
                        placeholder="Squad Name"
                        value={squadName || ''}
                        onChange={(value) => this.handleFilter('squadName', value)}
                        onEnter={() => this.handleFilter(ENTER)}
                    />
                    <FilterInput
                        placeholder="Squad Id"
                        value={squadId || ''}
                        onChange={(value) => this.handleFilter('squadId', value)}
                        onEnter={() => this.handleFilter(ENTER)}
                    />
                    <DropDown
                        label="Game"
                        value={gameId || ''}
                        menuItems={gameOptions || []}
                        onChange={(value) => this.handleFilter('gameId', value, false)}
                    />
                    <DropDown
                        label="Sort By"
                        value={sortOrder || ''}
                        menuItems={sortOptions}
                        onChange={(value) => this.handleFilter('sortOrder', value, false)}
                    />
                    <Button buttonText="Reset" onClick={this.onReset} />
                </div>

                <Table
                    data={data}
                    columns={columns}
                    page={page}
                    onPrevClick={this.onPrev.bind(this)}
                    onNextClick={this.onNext.bind(this)}
                    disableNext={disableNext}
                />
            </TableLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        squad: state.squad,
        toGame: state.toGame,
        client: state.client,
    };
};

export default connect(mapStateToProps, {
    getSquadList,
    logout,
    editSquad,
    deleteSquad,
    getTOGameList,
})(SquadList);
