export const GET_GLACIER_TOURNAMENTS = 'GET_GLACIER_TOURNAMENTS';
export const GET_GLACIER_TOURNAMENTS_SUCCESS = 'GET_GLACIER_TOURNAMENTS_SUCCESS';
export const GET_GLACIER_TOURNAMENTS_FAIL = 'GET_GLACIER_TOURNAMENTS_FAIL';

export const GET_GLACIER_SERIES = 'GET_GLACIER_SERIES';
export const GET_GLACIER_SERIES_SUCCESS = 'GET_GLACIER_SERIES_SUCCESS';
export const GET_GLACIER_SERIES_FAIL = 'GET_GLACIER_SERIES_FAIL';

export const GET_GLACIER_MATCHES = 'GET_GLACIER_MATCHES';
export const GET_GLACIER_MATCHES_SUCCESS = 'GET_GLACIER_MATCHES_SUCCESS';
export const GET_GLACIER_MATCHES_FAIL = 'GET_GLACIER_MATCHES_FAIL';
