import {
    CONTEST_LIST,
    CONTEST_LIST_SUCCESS,
    CONTEST_LIST_FAIL,
    ADD_CONTEST,
    ADD_CONTEST_SUCCESS,
    ADD_CONTEST_FAIL,
    UPDATE_CONTEST,
    UPDATE_CONTEST_SUCCESS,
    UPDATE_CONTEST_FAIL,
    DELETE_CONTEST,
    DELETE_CONTEST_SUCCESS,
    DELETE_CONTEST_FAIL,
    GET_CONTEST,
    GET_CONTEST_SUCCESS,
    GET_CONTEST_FAIL,
    EDIT_CONTEST,
    COPY_CONTEST_FROM_MATCH,
    COPY_CONTEST_FROM_MATCH_SUCCESS,
    COPY_CONTEST_FROM_MATCH_FAIL,
    UPLOAD_INFLUENCER_IMAGE,
    UPLOAD_INFLUENCER_IMAGE_SUCCESS,
    UPLOAD_INFLUENCER_IMAGE_FAIL,
    ADD_CONTEST_INFLUENCER,
    ADD_CONTEST_INFLUENCER_SUCCESS,
    ADD_CONTEST_INFLUENCER_FAIL,
} from './Constants';

const initialState = {
    contestListRequest: false,
    contestListSuccess: false,
    contestListFail: false,
    contestList: [],
    errorMessage: '',
    addContestRequest: false,
    addContestSuccess: false,
    addContestFail: false,
    addContest: [],
    updateContestRequest: false,
    updateContestSuccess: false,
    updateContestFail: false,
    updateContest: [],
    deleteContestRequest: false,
    deleteContestSuccess: false,
    deleteContestFail: false,
    deleteContest: [],
    getContestRequest: false,
    getContestSuccess: false,
    getContestFail: false,
    getContest: [],
    editContest: [],
    copyContestRequest: false,
    copyContestSuccess: false,
    copyContestFail: false,

    influencerImage: {},
    isUploadingInfluencerImage: false,
    isUploadingInfluencerImageSuccess: false,
    isUploadingInfluencerImageFail: false,

    isAddContestInfluencer: false,
    isAddContestInfluencerSuccess: false,
    isAddContestInfluencerFail: false,
};

const contestReducer = (state = initialState, action) => {
    switch (action.type) {
        case CONTEST_LIST:
            return {
                ...state,
                contestListRequest: true,
                contestListSuccess: false,
                contestListFail: false,
                contestList: [],
                errorMessage: '',
            };

        case CONTEST_LIST_SUCCESS:
            return {
                ...state,
                contestListRequest: false,
                contestListSuccess: true,
                contestListFail: false,
                contestList: action.payload.data,
                errorMessage: '',
            };

        case CONTEST_LIST_FAIL:
            return {
                ...state,
                contestListRequest: false,
                contestListSuccess: false,
                contestListFail: true,
                errorMessage: action.payload,
            };
        // ADD match
        case ADD_CONTEST:
            return {
                ...state,
                addContestRequest: false,
                addContestSuccess: false,
                addContestFail: false,
                addContest: [],
                errorMessage: '',
            };

        case ADD_CONTEST_SUCCESS:
            return {
                ...state,
                addContestRequest: false,
                addContestSuccess: true,
                addContestFail: false,
                addContest: action.payload.data,
                errorMessage: '',
            };

        case ADD_CONTEST_FAIL:
            return {
                ...state,
                addContestRequest: false,
                addContestSuccess: false,
                addContestFail: true,
                errorMessage: action.payload,
            };
        //update contest
        case UPDATE_CONTEST:
            return {
                ...state,
                updateContestRequest: true,
                updateContestSuccess: false,
                updateContestFail: false,
                updateContest: [],
                errorMessage: '',
            };

        case UPDATE_CONTEST_SUCCESS:
            return {
                ...state,
                updateContestRequest: false,
                updateContestSuccess: true,
                updateContestFail: false,
                updateContest: action.payload.data,
                errorMessage: '',
            };

        case UPDATE_CONTEST_FAIL:
            return {
                ...state,
                updateContestRequest: false,
                updateContestSuccess: false,
                updateContestFail: true,
                errorMessage: action.payload,
            };

        // DELETE CONTEST
        case DELETE_CONTEST:
            return {
                ...state,
                deleteContestRequest: true,
                deleteContestSuccess: false,
                deleteContestFail: false,
                deleteContest: [],
                errorMessage: '',
            };

        case DELETE_CONTEST_SUCCESS:
            return {
                ...state,
                deleteContestRequest: false,
                deleteContestSuccess: true,
                deleteContestFail: false,
                deleteContest: action.payload.data,
                errorMessage: '',
            };

        case DELETE_CONTEST_FAIL:
            return {
                ...state,
                deleteContestRequest: false,
                deleteContestSuccess: false,
                deleteContestFail: true,
                errorMessage: action.payload,
            };

        // get single contest
        case GET_CONTEST:
            return {
                ...state,
                getContestRequest: true,
                getContestSuccess: false,
                getContestFail: false,
                getContest: [],
                errorMessage: '',
            };

        case GET_CONTEST_SUCCESS:
            return {
                ...state,
                getContestRequest: false,
                getContestSuccess: true,
                getContestFail: false,
                getContest: action.payload.data,
                errorMessage: '',
            };

        case GET_CONTEST_FAIL:
            return {
                ...state,
                getContestRequest: false,
                getContestSuccess: false,
                getContestFail: true,
                errorMessage: action.payload,
            };
        // EDIT CONTEST
        case EDIT_CONTEST:
            return {
                ...state,
                editContest: action.payload,
            };

        // ADD match
        case COPY_CONTEST_FROM_MATCH:
            return {
                ...state,
                copyContestRequest: true,
                copyContestSuccess: false,
                copyContestFail: false,
                errorMessage: '',
            };

        case COPY_CONTEST_FROM_MATCH_SUCCESS:
            return {
                ...state,
                copyContestRequest: false,
                copyContestSuccess: true,
                copyContestFail: false,
                errorMessage: '',
            };

        case COPY_CONTEST_FROM_MATCH_FAIL:
            return {
                ...state,
                copyContestRequest: false,
                copyContestSuccess: false,
                copyContestFail: true,
                errorMessage: action.payload,
            };

        case UPLOAD_INFLUENCER_IMAGE:
            return {
                ...state,
                isUploadingInfluencerImage: true,
                isUploadingInfluencerImageSuccess: false,
                isUploadingInfluencerImageFail: false,
                errorMessage: '',
            };

        case UPLOAD_INFLUENCER_IMAGE_SUCCESS:
            return {
                ...state,
                isUploadingInfluencerImage: false,
                isUploadingInfluencerImageSuccess: true,
                isUploadingInfluencerImageFail: false,
                influencerImage: action.payload.data,
                errorMessage: '',
            };

        case UPLOAD_INFLUENCER_IMAGE_FAIL:
            return {
                ...state,
                isUploadingInfluencerImage: false,
                isUploadingInfluencerImageSuccess: false,
                isUploadingInfluencerImageFail: true,
                errorMessage: action.payload,
            };

        case ADD_CONTEST_INFLUENCER:
            return {
                ...state,
                isAddContestInfluencer: true,
                isAddContestInfluencerSuccess: false,
                isAddContestInfluencerFail: false,
                errorMessage: '',
            };
        case ADD_CONTEST_INFLUENCER_SUCCESS:
            return {
                ...state,
                isAddContestInfluencer: false,
                isAddContestInfluencerSuccess: true,
                isAddContestInfluencerFail: false,
                errorMessage: '',
            };
        case ADD_CONTEST_INFLUENCER_FAIL:
            return {
                ...state,
                isAddContestInfluencer: false,
                isAddContestInfluencerSuccess: false,
                isAddContestInfluencerFail: true,
                errorMessage: action.payload,
            };

        default:
            return state;
    }
};

export default contestReducer;
