export const TOURNAMENT_STATUS_LABEL = {
    DRAFT: 'DRAFT',
    UPCOMING: 'UPCOMING',
    LIVE: 'LIVE',
    COMPLETED: 'COMPLETED',
};

export const GameUniqueCodeLabels = {
    MLBB: 'MLBB',
    CSGO: 'csgo',
    VALORANT: 'val',
    BGMI: 'bgmi',
    FREEFIRE: 'ff',
    DOTA_2: 'dota',
    PUBG_MOBILE: 'pubgm',
    RAINBOW_SIX: 'rainbow6',
    LEAGUE_OF_LEGENDS: 'lol',
    PUBG_PC: 'pubgpc',
    COD_MOBILE: 'codm',
    COD_PC: 'codpc',
    PUBG_NEW_STATE: 'pubg-NS',
    BRAWL_STARS: 'brawlStars',
    POKEMON_UNITE: 'pokemonUnite',
    CRICKET: 'wwc',
    BASKETBALL: 'basketball',
    FOOTBALL: 'football'
};

export const SquadGamesList = [
    GameUniqueCodeLabels.BGMI,
    GameUniqueCodeLabels.FREEFIRE,
    GameUniqueCodeLabels.PUBG_MOBILE,
    GameUniqueCodeLabels.PUBG_NEW_STATE,
    GameUniqueCodeLabels.PUBG_PC,
];

export const KycStatusLabels = {
    PROCESSING: 'PROCESSING',
    SUBMITTED: 'SUBMITTED',
    VERIFIED: 'VERIFIED',
    REJECTED: 'REJECTED',
    REPROCESS: 'REPROCESS',
    PROCESS_AGAIN: 'PROCESS_AGAIN',
};
