/**
 * Component returning live/completed match team's player item
 *
 */
import React, {Component} from 'react';
import {StyleSheet, View, Text, Image, Dimensions, TouchableHighlight} from 'react-native';
import dimen from './dimens';
import fontTheme from './fonts';
import colors from './colors';
import MatchContestItemDetails from './MatchContestItemDetails';

const width = Dimensions.get('window').width;

class MyLiveTeamPlayerItem extends Component {
    constructor(props) {
        super(props);
    }

    onPlayerPress(details) {
        console.log(details);
        this.props.onPlayerClicked(details);
    }

    roundOff(value) {
        return typeof value === 'number' ? value.toFixed(2) : value;
    }

    render() {
        const {details} = this.props;

        return (
            <TouchableHighlight onPress={this.onPlayerPress.bind(this, details)}>
                <View style={styles.rootFlex}>
                    <View style={styles.divider} />
                    <View style={styles.root}>
                        <View style={[styles.horizontalRoot, styles.marginStartEnd, styles.spreadItems]}>
                            <View style={styles.flex}>
                                <Text numberOfLines={1} ellipsizeMode={'tail'} style={styles.playerName}>
                                    {details.pubgName}
                                </Text>

                                <Text style={styles.leader}>{details.positionInSquad}</Text>
                            </View>

                            <View style={styles.creditsWrapper}>
                                <Text style={styles.credit}>{details.currentCredits}</Text>
                            </View>
                        </View>

                        <View style={[styles.horizontalRoot, styles.marginTop]}>
                            <MatchContestItemDetails
                                text="Fantasy Points"
                                type="text"
                                value={
                                    details.playerInMatch
                                        ? details.playerInMatch.totalPoints
                                        : details.totalPoints
                                          ? this.roundOff(details.totalPoints)
                                          : 0
                                }
                            />
                            {details.playerInMatch && !details.playerInMatch.isAlive ? (
                                <Image source={require('../../../Images/kill_dark_grey.png')} />
                            ) : null}
                        </View>
                    </View>
                </View>
            </TouchableHighlight>
        );
    }
}

const styles = StyleSheet.create({
    root: {
        padding: dimen.DIMEN_16,
        marginHorizontal: 30,
    },
    rootFlex: {
        // flex: 0.5,
        backgroundColor: colors.DARK_BLUE,
        width: (width - 32) / 2 - 1,
    },
    horizontalRoot: {
        flexDirection: 'row',
    },
    margin: {
        margin: dimen.DIMEN_16,
    },
    spreadItems: {
        justifyContent: 'space-between',
    },
    marginTop: {
        marginTop: dimen.DIMEN_8,
    },
    marginStartEnd: {
        marginStart: dimen.DIMEN_4,
        marginEnd: dimen.DIMEN_8,
    },
    padding: {
        padding: dimen.DIMEN_16,
    },
    divider: {
        backgroundColor: colors.BLACK,
        height: dimen.DIMEN_1,
    },
    playerName: {
        flex: 0,
        // fontFamily: fontTheme.FONT_NUNITO_SANS_BOLD,
        fontSize: fontTheme.FONT_14,
        color: colors.YELLOW,
    },
    leader: {
        // fontFamily: fontTheme.FONT_NUNITO_SANS_REGULAR,
        fontSize: fontTheme.FONT_10,
        color: colors.WHITE,
    },
    creditsWrapper: {
        alignSelf: 'baseline',
        marginTop: dimen.DIMEN_4,
        backgroundColor: colors.LIGHT_BLUE,
        borderRadius: dimen.DIMEN_4,
        paddingStart: dimen.DIMEN_8,
        paddingEnd: dimen.DIMEN_8,
    },
    credit: {
        // fontFamily: fontTheme.FONT_NUNITO_SANS_BOLD,
        fontSize: fontTheme.FONT_14,
        color: colors.WHITE,
    },
    labellPos: {
        position: 'absolute',
    },
    flex: {
        flex: 1,
    },
});

export default MyLiveTeamPlayerItem;
