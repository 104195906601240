import {
    View,
    StyleSheet,
    ImageBackground,
    TouchableOpacity,
    Dimensions,
    Image,
    Text,
    ScrollView,
    FlatList,
    TextInput,
} from 'react-native';
import {connect} from 'react-redux';
import React, {useState, useEffect} from 'react';
// import {
//     getMatchDetail, updateMatchV2, resetUpdateMatchV2States
// } from '../../actions/toMatchesAction';
import {getMatchDetail} from './AxioCalls';
import BaseText from '../AppReactNative/BaseText';
import TitleText from '../AppReactNative/TitleText';
import Colors from '../AppReactNative/colors';
import LoadingModal from '../AppReactNative/LoadingModal';
// import ToastComponent from "../AppReactNative/ToastComponent";
import {ERROR, SUCCESS} from '../AppReactNative/constants';
import DefaultStyles from '../AppReactNative/default-styles';
import Fonts from '../AppReactNative/fonts';
import Modal from 'react-native-modal';
import FontAwesome from 'react-native-vector-icons/FontAwesome';

const SCREEN_HEIGHT = Dimensions.get('window').height;

const UpdateMatchResults = (props) => {
    const [loadingVisible, setLoadingVisible] = useState(false);
    const [toastVisible, setToastVisibile] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastType, setToastType] = useState('');
    const [matchStatus, setMatchStatus] = useState('DRAFT');
    const [submittedResults, setSubmittedResults] = useState([]);
    const [rankScoreMap, setRankScoreMap] = useState(new Map());
    const [resultUpdateCount, setResultUpdateCount] = useState(0); //so that flatList re-renders!
    const [searchText, setSearchText] = useState('');
    const [saveAndProceedSelected, setSaveAndProceedSelected] = useState(false);
    const [otherModalVisible, setOtherModalVisible] = useState(false);
    const [otherWinnings, setOtherWinnings] = useState([]);
    const [otherRewardsMap, setOtherRewardsMap] = useState(new Map());

    const {
        matchDetail,
        getMatchDetail,
        getMatchDetailComplete,
        updateMatchV2Init,
        updateMatchV2Fulfilled,
        updateMatchV2Failed,
        updateMatchV2Response,
    } = props.toPublishedMatch;
    const {editTOPublishedMatch} = props.toPublishedMatch;
    const {toMatchWrite} = props?.login?.permissions || {};

    useEffect(() => {
        if (editTOPublishedMatch.id) {
            props.getMatchDetail(props.login.userDetails.data.accessToken, undefined, editTOPublishedMatch.id);
        }
    }, [editTOPublishedMatch]);

    // useEffect(() => {
    //     if (updateMatchV2Init) {
    //         setLoadingVisible(true);
    //     }
    // }, [updateMatchV2Init]);

    // useEffect(() => {
    //     if (updateMatchV2Fulfilled) {
    //         setLoadingVisible(false);
    //         props.resetUpdateMatchV2States();
    //         props.getMatchDetail(props.login.accessToken, props.login.id, editTOPublishedMatch.id);

    //         showSuccessToast('Match successfully updated!');
    //         if (saveAndProceedSelected) {
    //             props.navigation.navigate('CreateUpdateMatchMainScreen');
    //         }
    //     }
    // }, [updateMatchV2Fulfilled]);

    // useEffect(() => {
    //     if (updateMatchV2Failed) {
    //         setLoadingVisible(false);
    //         props.resetUpdateMatchV2States();

    //         showErrorToast(updateMatchV2Response);
    //     }
    // }, [updateMatchV2Failed]);

    useEffect(() => {
        if (getMatchDetail) {
            setLoadingVisible(true);
        } else if (getMatchDetailComplete) {
            // console.log("UpdateMatchResults ", JSON.stringify(props.toPublishedMatch.matchDetail));
            setInputFields(props.toPublishedMatch.matchDetail);
            setMatchStatus(props.toPublishedMatch.matchDetail.match.status);
            setLoadingVisible(false);
        }
    }, [getMatchDetail, getMatchDetailComplete]);

    // const onSave = async () => {
    //     // console.log("onsave bigWinOtherReward ", bigWinOtherReward);
    //     setLoadingVisible(true);
    //     let req = createRequest();
    //     // console.log("update match req ", JSON.stringify(req));

    //     if (toOrganizerCurrMatchId) {//update match details
    //         // console.log("updating match ", toOrganizerCurrMatchId);
    //         if (req) {
    //             props.updateMatchV2(props.login.accessToken, toOrganizerCurrMatchId, req);
    //         } else {
    //             setLoadingVisible(false);
    //         }
    //     } else {
    //         showErrorToast('Unexpected error: current matchId must exist!');
    //     }
    // }

    // const createRequest = () => {
    //     let req = {};
    //     if (submittedResults && submittedResults.length > 0) {
    //         req.submittedResults = submittedResults;
    //     }

    //     return req;
    // }

    const setInputFields = (matchDetail) => {
        let match = matchDetail.match;

        let userDetailsMap = new Map();
        for (let i = 0; i < match.participantStats.length; i++) {
            let value = {
                userName: match.participantStats[i].userName,
            };
            userDetailsMap.set(match.participantStats[i].user.phoneNumber, value);
        }
        // console.log("user details map ", map);

        if (match.rankBasedScoring && match.rankBasedScoring.scoring) {
            let rankScoreMap = new Map();
            for (let i = 0; i < match.rankBasedScoring.scoring.length; i++) {
                let rankPoints = match.rankBasedScoring.scoring[i];
                for (let j = rankPoints.startRank; j <= rankPoints.endRank; j++) {
                    rankScoreMap.set(j, rankPoints.points);
                }
            }
            setRankScoreMap(rankScoreMap);
        }

        if (match.submittedResults && match.submittedResults.results && match.submittedResults.results.length > 0) {
            setSubmittedResults(match.submittedResults.results);
            setResultUpdateCount(resultUpdateCount + 1);
        } else {
            if (matchDetail.participation && matchDetail.participation.length >= 0) {
                let submittedResults = [];
                for (let i = 0; i < matchDetail.participation.length; i++) {
                    //team
                    let teamParticipation = matchDetail.participation[i];
                    let teamResult = {
                        teamParticipationId: teamParticipation.id,
                        teamName: teamParticipation.teamName,
                        gameSlotNumber: teamParticipation.gameSlotNumber,
                    };

                    let memberScores = [];
                    for (let j = 0; j < teamParticipation.team.length; j++) {
                        //player
                        let teamMember = teamParticipation.team[j];
                        if (teamMember.matchStatus.toLowerCase() !== 'joined') {
                            continue;
                        }

                        let member = {
                            phone: teamMember.player,
                            gameId: teamMember.gameId,
                            userName: userDetailsMap.has(teamMember.player)
                                ? userDetailsMap.get(teamMember.player).userName
                                : '-',
                        };
                        let metrics = [];
                        if (match.metricScoring && match.metricScoring.scoring) {
                            //metrics
                            for (let k = 0; k < match.metricScoring.scoring.length; k++) {
                                metrics.push({...match.metricScoring.scoring[k]});
                            }
                        }
                        member.metrics = metrics;
                        memberScores.push(member);
                    }
                    teamResult.memberScores = memberScores;
                    submittedResults.push(teamResult);
                }
                setSubmittedResults(submittedResults);
                setResultUpdateCount(resultUpdateCount + 1);
                // console.log("submittedResults ", JSON.stringify(submittedResults));
            }
        }

        if (match.otherRewards && match.otherRewards.rewards && match.otherRewards.rewards.length > 0) {
            let map = new Map();
            for (let i = 0; i < match.otherRewards.rewards.length; i++) {
                map.set(match.otherRewards.rewards[i].id, match.otherRewards.rewards[i]);
            }
            setOtherRewardsMap(map);
        }
    };

    const showErrorToast = (msg) => {
        setToastVisibile(true);
        setToastMessage(msg);
        setToastType(ERROR);
        setToastTimeout();
    };

    const showSuccessToast = (msg) => {
        setToastVisibile(true);
        setToastMessage(msg);
        setToastType(SUCCESS);
        setToastTimeout();
    };

    const setToastTimeout = () => {
        setTimeout(() => setToastVisibile(false), 3000);
    };

    const refreshTeamScore = (index) => {
        let teamResults = submittedResults[index];
        let totalRankScore = teamResults.inGameRank ? rankScoreMap.get(teamResults.inGameRank) : 0;
        let totalMetricScore = 0;
        for (let i = 0; i < teamResults.memberScores.length; i++) {
            for (let j = 0; j < teamResults.memberScores[i].metrics.length; j++) {
                let metric = teamResults.memberScores[i].metrics[j];
                totalMetricScore += metric.count * metric.perUnitPoints;
            }
        }
        teamResults.totalRankScore = totalRankScore;
        teamResults.totalMetricScore = totalMetricScore;

        let tmp = submittedResults;
        tmp[index] = teamResults;
        setSubmittedResults(tmp);
        setResultUpdateCount(resultUpdateCount + 1);
    };

    const renderOtherWinningItem = ({item}) => {
        let reward = otherRewardsMap.get(item.rewardId);
        item = {...item, ...reward};

        // console.log("renderOtherWinningItem ", item);
        if (item.quantity <= 0) {
            return null;
        }

        return (
            <View style={styles.otherRewardsInputBox}>
                <View style={{flexDirection: 'row'}}>
                    <View style={styles.otherWinningsBox}>
                        <View style={{flexDirection: 'row'}}>
                            <TitleText style={styles.otherWinningsTextHightlighted}>
                                {item.quantity + ' ' + item.description}
                            </TitleText>
                        </View>
                        <Image style={styles.otherRewardImage} source={{uri: item.image}} resizeMode="contain" />
                    </View>
                </View>
            </View>
        );
    };

    const RenderOtherModal = () => {
        // console.log("RenderOtherModal ", otherModalVisible, otherModalIndex, otherRewardsForModal.length);
        return (
            <Modal
                propagateSwipe
                isVisible={otherModalVisible}
                onBackdropPress={() => {
                    setOtherModalVisible(false);
                }}
                onBackButtonPress={() => {
                    setOtherModalVisible(false);
                }}
            >
                <View style={styles.otherModal}>
                    <ScrollView>
                        <FlatList
                            data={otherWinnings}
                            keyExtractor={(item, index) => item.rewardId}
                            renderItem={renderOtherWinningItem}
                            contentContainerStyle={{paddingStart: 0, paddingEnd: 0, paddingBottom: 0}}
                        />
                        {/* Cancel button */}
                        <View style={styles.saveProgressBox}>
                            <View style={{flex: 1}} />
                            <TouchableOpacity
                                activeOpacity={0.7}
                                style={{flex: 1}}
                                onPress={() => {
                                    setOtherModalVisible(false);
                                }}
                            >
                                <TitleText style={DefaultStyles.saveProgressText}>{'Cancel'}</TitleText>
                            </TouchableOpacity>
                            <View style={{flex: 1}} />
                        </View>
                        <View style={{height: 30}} />
                    </ScrollView>
                </View>
            </Modal>
        );
    };

    const renderTeamResultItem = ({item, index}) => {
        // console.log("renderTeamResultItem ", JSON.stringify(item));

        if (!item.memberScores || !item.memberScores.length) {
            return null;
        }

        if (searchText != null && searchText != '') {
            let found = false;
            for (let i = 0; i < item.memberScores.length; i++) {
                let gameId = item.memberScores[i].gameId.toLocaleLowerCase();
                if (gameId.includes(searchText.toLocaleLowerCase())) {
                    found = true;
                }
            }

            if (!found) {
                return null;
            }
        }

        let finalWinnings =
            (item.metricWinnings ? item.metricWinnings : 0) + (item.rankWinnings ? item.rankWinnings : 0);
        let totalScore =
            (item.totalRankScore ? item.totalRankScore : 0) + (item.totalMetricScore ? item.totalMetricScore : 0);

        return (
            <View style={styles.bigWinRewardInputBox}>
                <View style={styles.inputField}>
                    <View style={styles.teamResultRow}>
                        <TitleText style={styles.teamHeaderText}>{item.teamName}</TitleText>
                        <BaseText style={styles.teamHeaderText}>
                            {' | Slot: '}
                            {item.gameSlotNumber}
                        </BaseText>
                        <BaseText style={styles.teamHeaderText}>{' | Position: '}</BaseText>
                        <TextInput
                            editable={false}
                            style={styles.textInput}
                            onChangeText={(val) => {
                                item.inGameRank = val ? parseInt(val) : null;
                                refreshTeamScore(index);
                            }}
                            maxLength={4}
                            defaultValue={item.inGameRank ? '' + item.inGameRank : ''}
                            keyboardType={'numeric'}
                        />
                        <View style={{flex: 1}} />
                        <BaseText style={styles.teamHeaderText}>{'Total Score: '}</BaseText>
                        <BaseText style={styles.teamHeaderText}>{totalScore}</BaseText>
                        <View style={{width: 10}} />
                    </View>

                    <View style={styles.teamMembersSeparator} />

                    <View style={styles.topMargin} />
                    <View style={styles.teamResultRow}>
                        <TitleText style={styles.highlightedText}>{'Final Rank: '}</TitleText>
                        <TitleText style={styles.highlightedText}>{item.finalRank ? item.finalRank : 'N/A'}</TitleText>

                        <View style={{width: 20}} />

                        <TitleText style={styles.highlightedText}>
                            {'Final Winnings: '}
                            {matchDetail && matchDetail.match && matchDetail.match.winDenomination === 'cash' ? (
                                <TitleText style={styles.highlightedText}>{'₹'}</TitleText>
                            ) : (
                                <Image
                                    source={require('../../../Images/fc_coin_medium.png')}
                                    style={{height: 16, width: 16}}
                                    resizeMode="contain"
                                />
                            )}
                        </TitleText>
                        <TitleText style={styles.highlightedText}>{' ' + finalWinnings}</TitleText>
                        {item.otherWinnings && item.otherWinnings.length > 0 ? (
                            <TouchableOpacity
                                activeOpacity={0.7}
                                style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}
                                onPress={() => {
                                    setOtherWinnings(item.otherWinnings);
                                    setOtherModalVisible(true);
                                }}
                            >
                                <TitleText style={styles.otherWinningsButton}>{'Other Winnings'}</TitleText>
                            </TouchableOpacity>
                        ) : null}
                    </View>
                    <View style={styles.topMargin} />

                    {/* separator */}
                    <View style={styles.teamResultsSeparator} />

                    {/* column names */}
                    <View style={styles.topMargin} />
                    <View style={styles.teamResultRow}>
                        <BaseText style={styles.teamColumnText}>{'User-Name'}</BaseText>
                        <View style={{width: 20}} />
                        <BaseText style={styles.teamColumnText}>{'Game-Name'}</BaseText>
                        <View style={{flexDirection: 'row', flex: 1}}>
                            {item.memberScores[0].metrics.map((item, index) => {
                                // console.log("metric", item);
                                return <BaseText style={styles.teamColumnText}>{item.name}</BaseText>;
                            })}
                        </View>
                        {/* <View style={{ flex: 1 }} /> */}
                    </View>
                    <View style={styles.topMargin} />

                    {/* separator */}
                    <View style={styles.teamMembersSeparator} />

                    {/* member rows */}
                    <View>
                        {item.memberScores.map((member) => {
                            return (
                                <View style={styles.inputField}>
                                    <View style={styles.teamMemberRow}>
                                        <BaseText style={styles.teamColumnText}>{member.userName}</BaseText>
                                        <View style={{width: 20}} />
                                        <BaseText style={styles.teamColumnText}>{member.gameId}</BaseText>
                                        {member.metrics.length > 0 ? (
                                            <View style={styles.metricInput}>
                                                {member.metrics.map((metric) => {
                                                    return (
                                                        <TextInput
                                                            editable={false}
                                                            style={styles.textInput}
                                                            onChangeText={(val) => {
                                                                metric.count = val ? parseInt(val) : null;
                                                                refreshTeamScore(index);
                                                            }}
                                                            maxLength={4}
                                                            defaultValue={metric.count ? '' + metric.count : ''}
                                                            keyboardType={'numeric'}
                                                        />
                                                    );
                                                })}
                                            </View>
                                        ) : (
                                            <View style={{flex: 2}} />
                                        )}
                                    </View>
                                    <View style={styles.teamMembersSeparator} />
                                </View>
                            );
                        })}
                    </View>
                </View>
            </View>
        );
    };

    return (
        <View>
            {/* <ImageBackground style={{ flex: 1, alignItems: 'center' }} resizeMode={'cover'} source={require('../../../assets/images/background_01.png')}>
            <Header style={{ backgroundColor: Colors.headerBlack }}
                left={<TouchableOpacity activeOpacity={0.7} onPress={() => { props.navigation.navigate('CreateUpdateMatchMainScreen'); }}>
                    <Image source={require('../../../assets/images/back_yellow.png')} style={{ height: 28, width: 28 }} />
                </TouchableOpacity>}
                headerText={
                    <Text style={{ flexDirection: 'row' }}>
                        <TitleText style={styles.headerText}>{'Update Results'}{"\n"}</TitleText>
                    </Text>}>
            </Header> */}

            <ScrollView style={{flex: 1, height: SCREEN_HEIGHT, width: '100%'}}>
                <View style={styles.searchBox}>
                    <FontAwesome name="search" color={Colors.WHITE} style={{marginStart: 20}} size={24} />
                    <View style={styles.searchContent}>
                        <TextInput
                            editable={true}
                            style={styles.searchTextInput}
                            onChangeText={(val) => {
                                setSearchText(val);
                                setResultUpdateCount(resultUpdateCount + 1);
                            }}
                            maxLength={20}
                        />
                    </View>
                </View>

                <FlatList
                    data={submittedResults}
                    renderItem={renderTeamResultItem}
                    extraData={resultUpdateCount}
                    numColumns={1}
                />

                {/* Save and proceed buttons */}
                {!!toMatchWrite && (
                    <View style={styles.saveProgressBox}>
                        <View style={{width: 15}} />
                        <TouchableOpacity
                            activeOpacity={0.7}
                            style={{flex: 0.5}}
                            onPress={() => {
                                setSaveAndProceedSelected(false);
                                // onSave();
                            }}
                        >
                            <TitleText style={DefaultStyles.saveProgressText}> {'Save'} </TitleText>
                        </TouchableOpacity>
                        <View style={{width: 35}} />
                        <TouchableOpacity
                            activeOpacity={0.7}
                            style={{flex: 1}}
                            onPress={() => {
                                setSaveAndProceedSelected(true);
                                // onSave();
                            }}
                        >
                            <TitleText style={DefaultStyles.saveProgressText}> {'Save & Proceed'} </TitleText>
                        </TouchableOpacity>
                        <View style={{width: 15}} />
                    </View>
                )}

                <View style={{height: 30}} />
            </ScrollView>
            <RenderOtherModal />
            <LoadingModal visible={loadingVisible} />
            {/* <ToastComponent visible={toastVisible} type={toastType} message={toastMessage} /> */}
        </View>
    );
};

const styles = StyleSheet.create({
    headerText: {
        textAlign: 'center',
        fontSize: 16,
    },
    subHeaderText: {
        textAlign: 'center',
        fontSize: 12,
        color: Colors.LIGHT_GREY,
    },
    inputHeading: {
        textAlign: 'left',
        fontSize: 16,
        color: Colors.YELLOW,
    },
    inputBox: {
        flexDirection: 'row',
        marginHorizontal: 5,
        opacity: 0.7,
        alignItems: 'center',
        marginTop: 30,
        borderRadius: 10,
        backgroundColor: Colors.headerBlack,
    },
    inputContent: {
        flex: 1,
        backgroundColor: Colors.headerBlack,
        borderRadius: 10,
    },
    bigWinRewardInputBox: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 20,
        backgroundColor: Colors.headerBlack,
        opacity: 0.8,
    },
    inputField: {
        flex: 1,
        paddingBottom: 10,
    },
    saveProgressBox: {
        flexDirection: 'row',
        marginHorizontal: 40,
        opacity: 0.7,
        alignItems: 'center',
        marginTop: 50,
        borderRadius: 10,
    },
    teamResultTextBold: {
        color: Colors.WHITE,
        fontSize: 14,
        // fontFamily: Fonts.FONT_NUNITO_SANS_BOLD,
        fontWeight: 'bold',
    },
    teamHeaderText: {
        color: Colors.WHITE,
        fontSize: 14,
        // fontFamily: Fonts.FONT_NUNITO_SANS_REGULAR,
    },
    teamColumnText: {
        color: Colors.WHITE,
        fontSize: 14,
        // fontFamily: Fonts.FONT_NUNITO_SANS_REGULAR,
        flex: 1,
        textAlign: 'left',
    },
    teamResultRow: {
        flexDirection: 'row',
        paddingHorizontal: 10,
        paddingBottom: 5,
        alignItems: 'center',
    },
    teamMemberRow: {
        flexDirection: 'row',
        paddingHorizontal: 10,
        paddingBottom: 10,
        alignItems: 'center',
    },
    teamResultsSeparator: {
        height: 3,
        backgroundColor: Colors.BLACK,
        width: '100%',
        opacity: 0.7,
    },
    textInput: {
        width: 40,
        height: 40,
        fontSize: Fonts.FONT_14,
        // fontFamily: Fonts.FONT_NUNITO_SANS_REGULAR,
        color: Colors.WHITE,
        borderBottomColor: Colors.WHITE,
        borderBottomWidth: 1,
        marginEnd: 5,
    },
    metricInput: {
        flexDirection: 'row',
        flex: 1,
        alignItems: 'center',
    },
    teamMembersSeparator: {
        height: 1,
        backgroundColor: Colors.BLACK,
        width: '100%',
        opacity: 0.7,
    },
    highlightedText: {
        color: Colors.YELLOW,
        fontSize: 14,
    },
    topMargin: {
        height: 5,
    },
    searchBox: {
        flexDirection: 'row',
        opacity: 0.7,
        alignItems: 'center',
        marginTop: 30,
        borderRadius: 40,
        marginHorizontal: 20,
        borderColor: Colors.WHITE,
        borderWidth: 1,
    },
    searchContent: {
        flex: 1,
        borderRadius: 10,
    },
    searchTextInput: {
        fontSize: Fonts.FONT_14,
        // fontFamily: Fonts.FONT_NUNITO_SANS_REGULAR,
        color: Colors.WHITE,
        marginHorizontal: 10,
        borderBottomWidth: 0,
    },
    otherWinningsButton: {
        color: Colors.highlight,
        alignSelf: 'center',
        fontSize: 12,
        backgroundColor: Colors.BLACK_60,
        paddingVertical: 7,
        paddingHorizontal: 10,
        borderRadius: 20,
        borderWidth: 0.5,
        borderColor: Colors.highlight,
    },
    otherRewardImage: {
        height: 100,
        width: 100,
        marginTop: 10,
        borderColor: Colors.BLACK,
        borderWidth: 1,
        borderRadius: 20,
    },
    otherRewardsInputBox: {
        flexDirection: 'column',
        marginHorizontal: 20,
        opacity: 0.7,
        marginTop: 20,
        borderRadius: 10,
        backgroundColor: Colors.headerBlack,
        borderWidth: 2,
        borderColor: Colors.BLACK,
    },
    modalTextHeading: {
        textAlign: 'left',
        fontSize: 16,
        color: Colors.WHITE,
    },
    otherWinningsTextHightlighted: {
        textAlign: 'left',
        fontSize: 16,
        color: Colors.YELLOW,
    },
    otherModal: {
        backgroundColor: Colors.headerBlack,
        borderRadius: 20,
        marginHorizontal: 10,
        opacity: 0.9,
        height: SCREEN_HEIGHT - 200,
    },
    otherWinningsBox: {
        flex: 1,
        backgroundColor: Colors.headerBlack,
        borderRadius: 10,
        alignItems: 'center',
        paddingBottom: 5,
    },
});

const mapStateToProps = (state) => {
    return {
        login: state.login,
        toPublishedMatch: state.toPublishedMatch,
    };
};

export default connect(mapStateToProps, {
    getMatchDetail,
})(UpdateMatchResults);
