import React from 'react';
import classNames from 'classnames';
import styles from '../../scss/components/CustomFileUploadField.module.scss';

// interface ICustomFileUploadFieldProps {
//     accept: string;
//     inputClassName?: string;
//     label: string;
//     name?: string;
//     onChangeInput: (_file: any) => void;
//     onClickSampleFileLink: () => void;
//     required?: boolean;
//     sampleFileLinkText?: string;
//     sampleFileLinkVisible?: boolean;
//     type?: string;
// }

const CustomFileUploadField = (props) => {
    const {
        accept,
        className,
        inputClassName,
        label,
        name,
        onChangeInput,
        onClickSampleFileLink,
        required,
        sampleFileLinkText,
        sampleFileLinkVisible,
        type,
    } = props;

    const handleClear = (event) => {
        event.target.value = null;
    };

    const handleInputChange = (event) => {
        let file = event.target.files[0];
        if (!file) return;
        onChangeInput(file);
    };

    return (
        <div className={classNames(styles.wrapper, className)}>
            <label>
                <p>
                    {label}
                    {!!sampleFileLinkVisible && (
                        <span role="link" onClick={onClickSampleFileLink}>
                            {sampleFileLinkText}
                        </span>
                    )}
                </p>

                <input
                    accept={accept}
                    className={inputClassName}
                    name={name}
                    onChange={handleInputChange}
                    onClick={handleClear}
                    onDrop={handleClear}
                    required={required}
                    type={type}
                />
            </label>
        </div>
    );
};

CustomFileUploadField.defaultProps = {
    accept: '',
    className: '',
    inputClassName: '',
    label: '',
    name: '',
    onChangeInput: () => {},
    onClickSampleFileLink: () => {},
    required: false,
    sampleFileLinkText: '(Click here to download sample file)',
    sampleFileLinkVisible: true,
    type: 'file',
};

export default CustomFileUploadField;
