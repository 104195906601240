import React from 'react';
import {Modal} from '@material-ui/core';
import classnames from 'classnames';
import styles from '../../scss/components/MuiModal.module.scss';

const MuiModal = (props) => {
    const {children, className, open, onClickClose} = props;
    return (
        <Modal open={open} onClose={onClickClose}>
            <div className={classnames(className, styles.container)}>{children}</div>
        </Modal>
    );
};

export default MuiModal;
