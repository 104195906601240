import {
    REFERRAL_LIST,
    REFERRAL_LIST_SUCCESS,
    REFERRAL_LIST_FAIL,
    EDIT_REFERRAL_DATA,
    ADD_REFERRAL,
    ADD_REFERRAL_SUCCESS,
    ADD_REFERRAL_FAIL,
    UPDATE_REFERRAL,
    UPDATE_REFERRAL_SUCCESS,
    UPDATE_REFERRAL_FAIL,
    EDIT_REFERRAL_CODE,
    REFERRRED_USERS,
    REFERRRED_USERS_SUCCESS,
    REFERRRED_USERS_FAIL,
    CONVERTED_TNX,
    CONVERTED_TNX_SUCCESS,
    CONVERTED_TNX_FAIL,
} from './Constants';

/**
 * list all ref code api request
 */
export function listReferral() {
    return {
        type: REFERRAL_LIST,
    };
}
/**
 *handle list ref code success
 * @param {*} data data from api
 */
export function listReferralSuccess(data) {
    return {
        type: REFERRAL_LIST_SUCCESS,
        payload: data,
    };
}
/**
 *handle list ref code fail
 * @param {*} error error form api
 */
export function listReferralFail(error) {
    return {
        type: REFERRAL_LIST_FAIL,
        payload: error,
    };
}

/**
 * single Referral info
 * @param {*} Referral ref code details
 */
export function editReferralData(Referral) {
    return {
        type: EDIT_REFERRAL_DATA,
        payload: Referral,
    };
}

//create Referral
/**
 * create ref code api request
 */
export function createReferral() {
    return {
        type: ADD_REFERRAL,
    };
}
/**
 *handle create ref code success
 * @param {*} data data from api
 */
export function createReferralSuccess(data) {
    return {
        type: ADD_REFERRAL_SUCCESS,
        payload: data,
    };
}
/**
 *handle create ref code fail
 * @param {*} error error form api
 */
export function createReferralFail(error) {
    return {
        type: ADD_REFERRAL_FAIL,
        payload: error,
    };
}

// update Referral
/**
 *update ref code api request
 */
export function updateReferral() {
    return {
        type: UPDATE_REFERRAL,
    };
}
/**
 *handle update ref code success
 * @param {*} data data from api
 */
export function updateReferralSuccess(data) {
    return {
        type: UPDATE_REFERRAL_SUCCESS,
        payload: data,
    };
}
/**
 *handle update ref code fail
 * @param {*} error error form api
 */
export function updateReferralFail(error) {
    return {
        type: UPDATE_REFERRAL_FAIL,
        payload: error,
    };
}

/**
 * save details to edit ref code
 * @param {*} code ref code details
 */
export function editReferralCode(code) {
    return {
        type: EDIT_REFERRAL_CODE,
        payload: code,
    };
}

/**
 * user ref code  api request
 */
export function referredUsers() {
    return {
        type: REFERRRED_USERS,
    };
}
/**
 *handle user ref code success
 * @param {*} data data from api
 */
export function referredUsersSuccess(data) {
    return {
        type: REFERRRED_USERS_SUCCESS,
        payload: data,
    };
}
/**
 *handle user ref code fail
 * @param {*} error error form api
 */
export function referredUsersFail(error) {
    return {
        type: REFERRRED_USERS_FAIL,
        payload: error,
    };
}

// converted tnx
/**
 * converted tnx
 */
export function convertedTnx() {
    return {
        type: CONVERTED_TNX,
    };
}
/**
 *handle converted tnx success
 * @param {*} data data from api
 */
export function convertedTnxSuccess(data) {
    return {
        type: CONVERTED_TNX_SUCCESS,
        payload: data,
    };
}
/**
 *handle converted tnx fail
 * @param {*} error error form api
 */
export function convertedTnxFail(error) {
    return {
        type: CONVERTED_TNX_FAIL,
        payload: error,
    };
}
