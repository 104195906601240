import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import MuiModal from '../../../../Components/MuiModal/MuiModal';
import styles from '../../../../scss/Matches/VerifySwitchMatchSummaryModal.module.scss';
import {HighlightOff} from '@material-ui/icons';
import {IconButton} from '@material-ui/core';
import {verifySwitchLeaderboardPoints} from '../../RecordMatch/AxioCalls';
import {usePrevious} from '../../../../Utilities/hooks';

const VerifySwitchMatchSummaryModal = (props) => {
    const {open, onClickClose, verifySwitchMatch} = props;
    const {matchId, playersList, token} = verifySwitchMatch;
    const [state, setState] = useState({});
    const [switchLeaderboardData, setSwitchLeaderboardData] = useState({});
    const dispatch = useDispatch();
    const recordMatch = useSelector((state) => state.recordMatch);
    const client = useSelector((state) => state.client);
    const prevRecordMatch = usePrevious(recordMatch);

    const renderPlayerName = (_playerId) => {
        return playersList.filter((player) => player.id === _playerId)?.[0]?.pubgName;
    };

    useEffect(() => {
        dispatch(verifySwitchLeaderboardPoints({matchId}, token, client.selectedClient));
    }, [dispatch]);

    useEffect(() => {
        if (recordMatch.verifySwitchLeaderboardPoints && !prevRecordMatch.verifySwitchLeaderboardPoints) {
            onChange({loading: true});
        }
        if (recordMatch.verifySwitchLeaderboardPointsSuccess && !prevRecordMatch.verifySwitchLeaderboardPointsSuccess) {
            onChange({loading: false});
            const _fantasySwitchData = recordMatch?.verifySwitchLeaderboardPointsDetails?.data;
            setSwitchLeaderboardData(_fantasySwitchData);
        }
        if (recordMatch.verifySwitchLeaderboardPointsFail && !prevRecordMatch.verifySwitchLeaderboardPointsFail) {
            onChange({errorMessage: recordMatch.errorMessage, loading: false});
        }
    }, [recordMatch]);

    const onChange = (obj = {}) => {
        setState({...state, ...obj});
    };

    return (
        <MuiModal open={open} onClickClose={onClickClose} className={styles.container}>
            <div className={styles.header}>
                <h2 className={styles.modalHeading}>Verify Match Result</h2>
                <IconButton onClick={onClickClose} className={styles.closeButton}>
                    <HighlightOff className={styles.closeIcon} />
                </IconButton>
            </div>
            <div className={styles.body}>
                <div className={styles.aggregrateFields}>
                    <p>{`Total Switches: ${switchLeaderboardData?.fantasySubstitution?.totalSubs || `NA`}`}</p>
                    <p>{`Total Points: ${switchLeaderboardData?.fantasySubstitution?.points || `NA`}`}</p>
                </div>
                <div className={styles.tableContainer}>
                    <table className={styles.resultTable}>
                        <thead className={styles.tableHead}>
                            <tr>
                                <th>Switches</th>
                                <th>Inning</th>
                                <th>Ball</th>
                                <th>Player Out</th>
                                <th>Player In</th>
                                <React.Fragment>
                                    {switchLeaderboardData?.fantasySubstitution?.teamSnapshots?.[0]?.teamPlayers?.map(
                                        (player, index) => (
                                            <th key={`player-${index}-${player?.playerId}-head`}>{`Player ${
                                                index + 1
                                            }`}</th>
                                        ),
                                    )}
                                </React.Fragment>
                                <th>Points</th>
                            </tr>
                        </thead>
                        <tbody className={styles.tableBody}>
                            {switchLeaderboardData?.fantasySubstitution?.teamSnapshots?.map((team, index) => (
                                <tr key={`team-${index + 1}`}>
                                    <td>
                                        <p>{index === 0 ? `Initial Team` : `Switch ${index}`}</p>
                                    </td>
                                    <td>
                                        <p>{team?.inning}</p>
                                    </td>
                                    <td>
                                        <p>{`${team?.startOver?.over}.${team?.startOver?.ball}`}</p>
                                    </td>
                                    <td>
                                        <p>{renderPlayerName(team?.playerOut) || '-'}</p>
                                    </td>
                                    <td>
                                        <p>{renderPlayerName(team?.playerIn) || '-'}</p>
                                    </td>
                                    <React.Fragment>
                                        {team?.teamPlayers?.map((player, index) => (
                                            <td key={`player-${index}-${player?.playerId}`}>
                                                <p>{renderPlayerName(player?.playerId)}</p>
                                                <p>{player?.points}</p>
                                            </td>
                                        ))}
                                    </React.Fragment>
                                    <td>
                                        <p>{team?.points}</p>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </MuiModal>
    );
};

export default VerifySwitchMatchSummaryModal;
