export default {
    DIMEN_0: 0,
    DIMEN_HALF: 0.5,
    DIMEN_POINT_SEVEN: 0.7,
    DIMEN_1: 1,
    DIMEN_2: 2,
    DIMEN_3: 3,
    DIMEN_4: 4,
    DIMEN_6: 6,
    DIMEN_8: 8,
    DIMEN_10: 10,
    DIMEN_12: 12,
    DIMEN_14: 14,
    DIMEN_16: 16,
    DIMEN_18: 18,
    DIMEN_20: 20,
    DIMEN_24: 24,
    DIMEN_28: 28,
    DIMEN_32: 32,
    DIMEN_36: 36,
    DIMEN_38: 38,
    DIMEN_40: 40,
    DIMEN_42: 42,
    DIMEN_48: 48,
    DIMEN_52: 52,
    DIMEN_56: 56,
    DIMEN_60: 60,
    DIMEN_64: 64,
    DIMEN_72: 72,
    DIMEN_84: 84,
    DIMEN_96: 96,
    DIMEN_112: 112,
    DIMEN_150: 150,

    DIMEN_NEG_32: -32,

    // Keyboard types
    KEYBOARD_DEFAULT: 'default',
    KEYBOARD_NUMERIC: 'numeric',
    KEYBOARD_EMAIL: 'email-address',
};
