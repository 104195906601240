import {
    PLAYER_LIST,
    PLAYER_LIST_SUCCESS,
    PLAYER_LIST_FAIL,
    ADD_PLAYER,
    ADD_PLAYER_SUCCESS,
    ADD_PLAYER_FAIL,
    EDIT_PLAYER,
    UPDATE_PLAYER,
    UPDATE_PLAYER_SUCCESS,
    UPDATE_PLAYER_FAIL,
    DELETE_PLAYER,
    DELETE_PLAYER_SUCCESS,
    DELETE_PLAYER_FAIL,
    GET_PLAYER,
    GET_PLAYER_SUCCESS,
    GET_PLAYER_FAIL,
    ADD_PLAYER_IN_MATCH,
    ADD_PLAYER_IN_MATCH_SUCCESS,
    ADD_PLAYER_IN_MATCH_FAIL,
} from './PlayerConstants';

const initialState = {
    playerListRequest: false,
    playerListSuccess: false,
    playerListFail: false,
    playerList: [],
    errorMessage: '',
    addPlayerRequest: false,
    addPlayerSuccess: false,
    addPlayerFail: false,
    addPlayer: [],
    editPlayer: [],
    updatePlayerRequest: false,
    updatePlayerSuccess: false,
    updatePlayerFail: false,
    updatePlayer: [],
    deleteplayerRequest: false,
    deleteplayersuccess: false,
    deletePlayerFail: false,
    deletePlayer: [],
    getPlayerRequest: false,
    getPlayerSuccess: false,
    getPlayerFail: false,
    getPlayer: [],

    isAddingPlayerInMatch: false,
    isAddingPlayerInMatchSuccess: false,
    isAddingPlayerInMatchFail: false,
};

const playerReducer = (state = initialState, action) => {
    switch (action.type) {
        case PLAYER_LIST:
            return {
                ...state,
                playerListRequest: true,
                playerListSuccess: false,
                playerListFail: false,
                playerList: [],
                errorMessage: '',
            };

        case PLAYER_LIST_SUCCESS:
            return {
                ...state,
                playerListRequest: false,
                playerListSuccess: true,
                playerListFail: false,
                playerList: action.payload.data,
                errorMessage: '',
            };

        case PLAYER_LIST_FAIL:
            return {
                ...state,
                playerListRequest: false,
                playerListSuccess: false,
                playerListFail: true,
                errorMessage: action.payload,
            };
        // ADD PLAYER
        case ADD_PLAYER:
            return {
                ...state,
                addPlayerRequest: false,
                addPlayerSuccess: false,
                addPlayerFail: false,
                addPlayer: [],
                errorMessage: '',
            };

        case ADD_PLAYER_SUCCESS:
            return {
                ...state,
                addPlayerRequest: false,
                addPlayerSuccess: true,
                addPlayerFail: false,
                addPlayer: action.payload.data,
                errorMessage: '',
            };

        case ADD_PLAYER_FAIL:
            return {
                ...state,
                addPlayerRequest: false,
                addPlayerSuccess: false,
                addPlayerFail: true,
                errorMessage: action.payload,
            };
        // EDIT PLAYER
        case UPDATE_PLAYER:
            return {
                ...state,
                updatePlayerRequest: true,
                updatePlayerSuccess: false,
                updatePlayerFail: false,
                updatePlayer: [],
                errorMessage: '',
            };

        case UPDATE_PLAYER_SUCCESS:
            return {
                ...state,
                updatePlayerRequest: false,
                updatePlayerSuccess: true,
                updatePlayerFail: false,
                updatePlayer: action.payload.data,
            };

        case UPDATE_PLAYER_FAIL:
            return {
                ...state,
                updatePlayerRequest: false,
                updatePlayerSuccess: false,
                updatePlayerFail: true,
                errorMessage: action.payload,
            };

        case EDIT_PLAYER:
            return {
                ...state,
                editPlayer: action.payload,
            };

        // DELETE PLAYER
        case DELETE_PLAYER:
            return {
                ...state,
                deleteplayerRequest: true,
                deleteplayersuccess: false,
                deletePlayerFail: false,
                deletePlayer: [],
                errorMessage: '',
            };

        case DELETE_PLAYER_SUCCESS:
            return {
                ...state,
                deleteplayerRequest: false,
                deleteplayersuccess: true,
                deletePlayerFail: false,
                deletePlayer: action.payload.data,
            };

        case DELETE_PLAYER_FAIL:
            return {
                ...state,
                deleteplayerRequest: false,
                deleteplayersuccess: false,
                deletePlayerFail: true,
                errorMessage: action.payload,
            };

        // GET PLAYER
        case GET_PLAYER:
            return {
                ...state,
                getPlayerRequest: false,
                getPlayerSuccess: false,
                getPlayerFail: false,
                getPlayer: [],
                errorMessage: '',
            };

        case GET_PLAYER_SUCCESS:
            return {
                ...state,
                getPlayerRequest: false,
                getPlayerSuccess: true,
                getPlayerFail: false,
                getPlayer: action.payload.data,
                errorMessage: '',
            };

        case GET_PLAYER_FAIL:
            return {
                ...state,
                getPlayerRequest: false,
                getPlayerSuccess: false,
                getPlayerFail: true,
                errorMessage: action.payload,
            };

        case ADD_PLAYER_IN_MATCH:
            return {
                ...state,
                isAddingPlayerInMatch: true,
                isAddingPlayerInMatchSuccess: false,
                isAddingPlayerInMatchFail: false,
                errorMessage: '',
            };
        case ADD_PLAYER_IN_MATCH_SUCCESS:
            return {
                ...state,
                isAddingPlayerInMatch: false,
                isAddingPlayerInMatchSuccess: true,
                isAddingPlayerInMatchFail: false,
                errorMessage: '',
            };
        case ADD_PLAYER_IN_MATCH_FAIL:
            return {
                ...state,
                isAddingPlayerInMatch: false,
                isAddingPlayerInMatchSuccess: false,
                isAddingPlayerInMatchFail: true,
                errorMessage: action.payload,
            };

        default:
            return state;
    }
};

export default playerReducer;
