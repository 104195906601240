import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';
import Button from '../Button/Button';
import Input from '../Input/Input';
import DropDown from '../Dropdown/Dropdown';

const TextModal = (props) => {
    let {open, handleClose, heading, buttonText, label, handleOnSubmit, textInputType, selectOptions} = props;
    const [value, setValue] = useState('');
    const buttonRef = useRef(null);

    const handleSubmit = (e) => {
        console.log('SUBMIT', value);
        !!e && e.preventDefault();
        handleOnSubmit({comment: value});
    };

    const handleOnChange = (value) => {
        setValue(value);
    };

    // const handleOnClick = (value) => {
    //     buttonRef.current.click()
    // }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            scroll="paper"
            maxWidth="sm"
            fullWidth
            disableEscapeKeyDown
            disableBackdropClick
        >
            <DialogTitle>{heading}</DialogTitle>
            <DialogContent dividers={true}>
                <form onSubmit={handleSubmit}>
                    <label className="d-block mb-15" htmlFor="textAreaModal">
                        {label}
                    </label>
                    {textInputType === 'input' && (
                        <Input
                            type="text"
                            name="textAreaModal"
                            required
                            inputProps={{minLength: 10}}
                            value={value}
                            placeholder="Enter Text"
                            onChange={handleOnChange}
                        />
                    )}

                    {textInputType === 'select' && (
                        <DropDown
                            label="Select"
                            menuItems={selectOptions}
                            required
                            value={value || ''}
                            placeholder="Select"
                            onChange={handleOnChange}
                        />
                    )}

                    <Button className="d-none" buttonText="Submit" type="submit" buttonRef={buttonRef} />
                </form>
            </DialogContent>
            <DialogActions>
                <Button buttonText="Cancel" onClick={handleClose} />
                <Button buttonText={buttonText} onClick={() => buttonRef.current.click()} />
            </DialogActions>
        </Dialog>
    );
};

TextModal.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    handleOnSubmit: PropTypes.func,
    buttonText: PropTypes.string,
    label: PropTypes.string,
    heading: PropTypes.string,
    textInputType: PropTypes.oneOf(['input', 'select']),
    selectOptions: PropTypes.array,
};

TextModal.defaultProps = {
    handleClose: () => {},
    handleOnSubmit: () => {},
    open: false,
    buttonText: 'Submit',
    label: '',
    heading: 'Comment',
    textInputType: 'input',
    selectOptions: [],
};

export default TextModal;
