import {SEND_NOTIFICATION, SEND_NOTIFICATION_SUCCESS, SEND_NOTIFICATION_FAIL} from './Constants';

/**
 * send notification api request
 */
export function sendNotificationRequest() {
    return {
        type: SEND_NOTIFICATION,
    };
}

/**
 * handle send notification api success
 * @param {*} data data from api
 */
export function sendNotificationSuccess(data) {
    return {
        type: SEND_NOTIFICATION_SUCCESS,
        payload: data,
    };
}

/**
 *handle send notification api failure
 * @param {*} error error from api
 */
export function sendNotificationFail(error) {
    return {
        type: SEND_NOTIFICATION_FAIL,
        payload: error,
    };
}
