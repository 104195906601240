import {
    TOMATCHMODE_LIST,
    TOMATCHMODE_LIST_SUCCESS,
    TOMATCHMODE_LIST_FAIL,
    ADD_TOMATCHMODE,
    ADD_TOMATCHMODE_SUCCESS,
    ADD_TOMATCHMODE_FAIL,
    UPDATE_TOMATCHMODE,
    UPDATE_TOMATCHMODE_SUCCESS,
    UPDATE_TOMATCHMODE_FAIL,
    DELETE_TOMATCHMODE,
    DELETE_TOMATCHMODE_SUCCESS,
    DELETE_TOMATCHMODE_FAIL,
    GET_TOMATCHMODE,
    GET_TOMATCHMODE_SUCCESS,
    GET_TOMATCHMODE_FAIL,
    EDIT_TOMATCHMODE,
} from './Constants';

//lst all TOMATCHMODE
export function listTOMatchModes() {
    return {
        type: TOMATCHMODE_LIST,
    };
}

export function listTOMatchModesSuccess(data) {
    return {
        type: TOMATCHMODE_LIST_SUCCESS,
        payload: data,
    };
}

export function listTOMatchModesFail(error) {
    return {
        type: TOMATCHMODE_LIST_FAIL,
        payload: error,
    };
}

// add TOMATCHMODE
export function addTOMatchMode() {
    return {
        type: ADD_TOMATCHMODE,
    };
}

export function addTOMatchModeSuccess(data) {
    return {
        type: ADD_TOMATCHMODE_SUCCESS,
        payload: data,
    };
}

export function addTOMatchModeFail(error) {
    return {
        type: ADD_TOMATCHMODE_FAIL,
        payload: error,
    };
}

//update TOMATCHMODE
export function updateTOMatchModeRequest() {
    return {
        type: UPDATE_TOMATCHMODE,
    };
}

export function updateTOMatchModeSuccess(data) {
    return {
        type: UPDATE_TOMATCHMODE_SUCCESS,
        payload: data,
    };
}

export function updateTOMatchModeFail(error) {
    return {
        type: UPDATE_TOMATCHMODE_FAIL,
        payload: error,
    };
}

// delete TOMATCHMODE

export function deleteTOMatchModeRequest() {
    return {
        type: DELETE_TOMATCHMODE,
    };
}

export function deleteTOMatchModeSuccess(data) {
    return {
        type: DELETE_TOMATCHMODE_SUCCESS,
        payload: data,
    };
}

export function deleteTOMatchModeFail(error) {
    return {
        type: DELETE_TOMATCHMODE_FAIL,
        payload: error,
    };
}

//get single TOMATCHMODE
export function getTOMatchModeRequest() {
    return {
        type: GET_TOMATCHMODE,
    };
}

export function getTOMatchModeSuccess(data) {
    return {
        type: GET_TOMATCHMODE_SUCCESS,
        payload: data,
    };
}

export function getTOMatchModeFail(error) {
    return {
        type: GET_TOMATCHMODE_FAIL,
        payload: error,
    };
}

// single matchMode Details
export function editTOMatchMode(matchMode) {
    return {
        type: EDIT_TOMATCHMODE,
        payload: matchMode,
    };
}
