export const TOMATCHTYPE_LIST = 'TOMATCHTYPE_LIST';
export const TOMATCHTYPE_LIST_SUCCESS = 'TOMATCHTYPE_LIST_SUCCESS';
export const TOMATCHTYPE_LIST_FAIL = 'TOMATCHTYPE_LIST_FAIL';

export const ADD_TOMATCHTYPE = 'ADD_TOMATCHTYPE';
export const ADD_TOMATCHTYPE_SUCCESS = 'ADD_TOMATCHTYPE_SUCCESS';
export const ADD_TOMATCHTYPE_FAIL = 'ADD_TOMATCHTYPE_FAIL';

export const UPDATE_TOMATCHTYPE = 'UPDATE_TOMATCHTYPE';
export const UPDATE_TOMATCHTYPE_SUCCESS = 'UPDATE_TOMATCHTYPE_SUCCESS';
export const UPDATE_TOMATCHTYPE_FAIL = 'UPDATE_TOMATCHTYPE_FAIL';

export const DELETE_TOMATCHTYPE = 'DELETE_TOMATCHTYPE';
export const DELETE_TOMATCHTYPE_SUCCESS = 'DELETE_TOMATCHTYPE_SUCCESS';
export const DELETE_TOMATCHTYPE_FAIL = 'DELETE_TOMATCHTYPE_FAIL';

export const GET_TOMATCHTYPE = 'GET_TOMATCHTYPE';
export const GET_TOMATCHTYPE_SUCCESS = 'GET_TOMATCHTYPE_SUCCESS';
export const GET_TOMATCHTYPE_FAIL = 'GET_TOMATCHTYPE_FAIL';

export const EDIT_TOMATCHTYPE = 'EDIT_TOMATCHTYPE';
