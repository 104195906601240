import React from 'react';
import PropTypes from 'prop-types';
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';
import Button from '../../../../Components/Button/Button';
import {connect} from 'react-redux';
import LoadingModal from '../../../../Components/LoadingModal/LoadingModal';
import Snackbar from '../../../../Components/Snackbar/Snackbar';
import {getUserKycImage, userKycManualAction} from '../../Users/AxioCalls';
import {KYC_REJECTION_OPTIONS, KYC_STATUS} from '../../../../Utilities/Constants';
import TextModal from '../../../../Components/TextModal';
import moment from 'moment/moment';
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Input from '../../../../Components/Input/Input';
import Dropdown from '../../../../Components/Dropdown/Dropdown';
import UserBankAndUPIKyc from '../UserBankAndUPIKyc';
import MuiButton from '../../../../Components/MuiButton/MuiButton';

class UserKycActionModal extends React.Component {
    static propTypes = {
        open: PropTypes.bool,
        handleClose: PropTypes.func,
        userKycData: PropTypes.object,
    };

    static defaultProps = {
        handleClose: () => {},
        open: false,
        userKycData: {},
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            errorMessage: '',
        };

        this.handleGetUserKycImage();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {user} = this.props;

        if (
            (user.isGettingUserKycDocs && !prevProps.user.isGettingUserKycDocs) ||
            (user.userKycManualActionRequest && !prevProps.user.userKycManualActionRequest)
        ) {
            this.setState({loading: true});
        }

        if (user.isGettingUserKycDocsSuccess && !prevProps.user.isGettingUserKycDocsSuccess) {
            this.handleUserKeyDocs(user.userKycDocs.data);
        } else if (user.isGettingUserKycDocsFail && !prevProps.user.isGettingUserKycDocsFail) {
            this.setState({loading: false, errorMessage: user.errorMessage.errorReason});
        }

        if (user.userKycManualActionFailure && !prevProps.user.userKycManualActionFailure) {
            this.setState({loading: false, errorMessage: user.errorMessage.errorReason});
        }
    }

    handleGetUserKycImage = () => {
        const {login, userKycData, client} = this.props;
        const data = {userId: userKycData.userId};
        this.props.getUserKycImage(data, login?.userDetails?.data?.accessToken, client.selectedClient);
    };

    handleUserKeyDocs = (userKycDocs) => {
        let selectDoc = null,
            docOptions = [];
        if (userKycDocs.documentList && userKycDocs.documentList.length) {
            selectDoc = userKycDocs.documentList[0];
            docOptions = userKycDocs.documentList.map((doc) => ({value: doc, text: `${doc.docType}-${doc.docId}`}));
        }
        this.setState({
            userKycDocs: {
                ...userKycDocs,
                dateOfBirth: userKycDocs.dateOfBirth ? new Date(userKycDocs.dateOfBirth) : '',
            },
            selectDoc,
            docOptions,
            loading: false,
            kycStatus: userKycDocs?.status,
        });
    };

    handleAfterComment = ({comment, status}) => {
        const {kycActionStatus, selectDoc, userKycDocs} = this.state;
        const {login, client} = this.props;

        if (!userKycDocs.dateOfBirth && kycActionStatus === KYC_STATUS.VERIFIED) {
            this.setState({errorMessage: 'Please Select Date Of Birth First'});
            return;
        }
        if (!userKycDocs.fullName && kycActionStatus === KYC_STATUS.VERIFIED) {
            this.setState({errorMessage: 'Please Enter Full Name First'});
            return;
        }
        const data = {
            comment: comment || '',
            finalStatus: kycActionStatus || status,
            docId: selectDoc.docId,
            docType: selectDoc.docType,
            userId: userKycDocs.userId,
            dateOfBirth: userKycDocs.dateOfBirth ? moment(userKycDocs.dateOfBirth).valueOf() : '',
            fullName: userKycDocs.fullName ? userKycDocs.fullName : '',
        };
        console.log('KYC_MANUAL', data);
        this.props.userKycManualAction(data, login.userDetails.data.accessToken, client.selectedClient);
    };

    handleOpenBankingKyc = () => {
        const {upiObject = [], bankObject = [], userId, fullName} = this.state.userKycDocs;
        this.setState({bankUpiKycData: {upiObject, bankObject, userId, fullName}});
    };

    handleInputChange = (field, value) => {
        this.setState({[field]: value, errorMessage: ''});
    };

    render() {
        let {handleClose, open, user, login, client} = this.props;
        let {
            loading,
            errorMessage,
            userKycDocs = {},
            selectDoc,
            docOptions = [],
            kycActionStatus,
            bankUpiKycData,
        } = this.state;

        const {userKycWrite} = login?.permissions || {};

        return (
            <Dialog open={open} onClose={handleClose} scroll="paper" maxWidth="lg" fullWidth>
                {!!loading && <LoadingModal open={loading} />}
                {!!errorMessage && (
                    <Snackbar
                        open={!!errorMessage}
                        message={errorMessage}
                        onClose={() => this.setState({errorMessage: ''})}
                    />
                )}

                {!!bankUpiKycData && (
                    <UserBankAndUPIKyc
                        open={!!bankUpiKycData}
                        handleClose={() => this.setState({bankUpiKycData: null})}
                        kycData={bankUpiKycData}
                    />
                )}

                {!!kycActionStatus && (
                    <TextModal
                        open={!!kycActionStatus}
                        label="Enter Comment Here"
                        handleClose={() => this.setState({kycActionStatus: ''})}
                        heading={kycActionStatus === KYC_STATUS.VERIFIED ? 'Comment for Verify' : 'Comment for Reject'}
                        buttonText={kycActionStatus === KYC_STATUS.VERIFIED ? 'Verify' : 'Reject'}
                        textInputType={kycActionStatus === KYC_STATUS.VERIFIED ? 'input' : 'select'}
                        selectOptions={KYC_REJECTION_OPTIONS}
                        handleOnSubmit={this.handleAfterComment}
                    />
                )}

                <DialogTitle className="dialog-title-with-button">
                    User Kyc Documents - {userKycDocs.status}
                    <span>
                        <Button buttonText="Bank / UPI Kyc" onClick={this.handleOpenBankingKyc} />
                    </span>
                </DialogTitle>
                <DialogContent dividers={true}>
                    <div className="kyc-grid-2 margin-top-20">
                        <div className="card-grid-container align-content">
                            <div>
                                <div className="basic-headers">Date Of Birth</div>
                                <div className="basiic-data">
                                    {user?.userKycDocs?.data?.dateOfBirth ? (
                                        moment(userKycDocs.dateOfBirth).format('DD-MM-YYYY')
                                    ) : userKycDocs.status === KYC_STATUS.PROCESSING ? (
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <DatePicker
                                                label="Select DOB"
                                                format="dd MMM yyyy"
                                                value={userKycDocs.dateOfBirth || null}
                                                onChange={(value) =>
                                                    this.handleInputChange('userKycDocs', {
                                                        ...userKycDocs,
                                                        dateOfBirth: value,
                                                    })
                                                }
                                            />
                                        </MuiPickersUtilsProvider>
                                    ) : (
                                        '-'
                                    )}
                                </div>
                            </div>
                            <div>
                                <div className="basic-headers">Full Name</div>
                                <div className="basiic-data">
                                    {user?.userKycDocs?.data?.fullName ? (
                                        userKycDocs.fullName
                                    ) : userKycDocs.status === KYC_STATUS.PROCESSING ? (
                                        <Input
                                            className="mb-15"
                                            label="Full Name"
                                            type="text"
                                            name="fullName"
                                            value={userKycDocs.fullName || ''}
                                            placeholder="Enter Full Name"
                                            onChange={(value) =>
                                                this.handleInputChange('userKycDocs', {
                                                    ...userKycDocs,
                                                    fullName: value,
                                                })
                                            }
                                        />
                                    ) : (
                                        '-'
                                    )}
                                </div>
                            </div>
                            <div className="margin-top-20">
                                <div className="basic-headers">Extracted Name</div>
                                <div className="basiic-data">
                                    {selectDoc?.extractedName ? selectDoc?.extractedName : '-'}
                                </div>
                            </div>
                            <div className="margin-top-20">
                                <div className="basic-headers">extracted Govt Pan Name</div>
                                <div className="basiic-data">
                                    {selectDoc?.extractedGovtPanName ? selectDoc?.extractedGovtPanName : '-'}
                                </div>
                            </div>
                            <div className="margin-top-20">
                                <div className="basic-headers">Bank Account #</div>
                                <div className="basiic-data">{userKycDocs.bankAccNo ? userKycDocs.bankAccNo : '-'}</div>
                            </div>
                            <div className="margin-top-20">
                                <div className="basic-headers">Mobile Number</div>
                                <div className="basiic-data">
                                    {userKycDocs.phoneNumber ? userKycDocs.phoneNumber : '-'}
                                </div>
                            </div>
                            <div className="margin-top-20">
                                <div className="basic-headers">IFSC code</div>
                                <div className="basiic-data">
                                    {userKycDocs.bankIfscCode ? userKycDocs.bankIfscCode : '-'}
                                </div>
                            </div>
                            <div className="margin-top-20">
                                <div className="basic-headers">Upi Id - RegisteredName</div>
                                <div className="basiic-data">
                                    {userKycDocs.upiIdList
                                        ? userKycDocs.upiIdList.map((itr, index) => (
                                              <p className="mb-5 mt-0" key={'kycupi' + index}>
                                                  {itr.upi + ' - ' + itr.upiName}
                                              </p>
                                          ))
                                        : '-'}
                                </div>
                            </div>
                            <div className="margin-top-20">
                                <div className="basic-headers">Failure Reason</div>
                                <div className="basiic-data">
                                    {userKycDocs.failureReason ? userKycDocs.failureReason : '-'}
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="margin-bottom-20">
                                <Dropdown
                                    menuItems={docOptions || []}
                                    label="Select Doc"
                                    value={selectDoc || ''}
                                    placeholder="Select Doc"
                                    onChange={(value) => this.handleInputChange('selectDoc', value)}
                                />
                            </div>
                            <div className="panCardImg">
                                {selectDoc?.docImageUrl ? (
                                    <img className="doc-img" alt="doc" src={selectDoc?.docImageUrl} />
                                ) : null}
                            </div>
                        </div>
                        <div />
                    </div>
                </DialogContent>
                <DialogActions style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                    <Button className="mr-10" buttonText="Cancel" onClick={handleClose} />

                    {!!userKycWrite && userKycDocs.status === KYC_STATUS.SUBMITTED && !!selectDoc && (
                        <Button
                            buttonText="Process Again"
                            onClick={() => this.handleAfterComment({status: KYC_STATUS.REPROCESS})}
                        />
                    )}

                    {!!userKycWrite && userKycDocs.status === KYC_STATUS.PROCESSING && !!selectDoc && (
                        <>
                            <Button
                                className="mr-10"
                                buttonText="Verify"
                                onClick={() => this.setState({kycActionStatus: KYC_STATUS.VERIFIED})}
                            />

                            <Button
                                buttonText="Reject"
                                onClick={() => this.setState({kycActionStatus: KYC_STATUS.REJECTED})}
                            />
                        </>
                    )}
                    {!!userKycWrite &&
                        userKycDocs.status === KYC_STATUS.SUBMITTED &&
                        !!selectDoc &&
                        client.selectedClient === 'client_jade' && (
                            <MuiButton
                                type="button"
                                buttonText="Verify"
                                onButtonClick={() => this.handleAfterComment({status: KYC_STATUS.VERIFIED})}
                            />
                        )}
                    {!!userKycWrite &&
                        userKycDocs.status === KYC_STATUS.SUBMITTED &&
                        !!selectDoc &&
                        client.selectedClient === 'client_jade' && (
                            <MuiButton
                                type="button"
                                buttonText="Reject"
                                onButtonClick={() => this.handleAfterComment({status: KYC_STATUS.REJECTED})}
                            />
                        )}
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        login: state.login,
        client: state.client,
    };
};

export default connect(mapStateToProps, {
    getUserKycImage,
    userKycManualAction,
})(UserKycActionModal);
