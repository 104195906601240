import {Box, IconButton, styled} from '@material-ui/core';
import {Close, Refresh, Save} from '@material-ui/icons';
import React, {useEffect, useState} from 'react';
import MuiButton from '../../../../Components/MuiButton/MuiButton';
import MuiInputField from '../../../../Components/MuiInputField/MuiInputField';
import MuiModal from '../../../../Components/MuiModal/MuiModal';
import {ColorPalette} from '../../../../Utilities/constants/color-constants';
import styles from './ImportTournamentModal.module.scss';
import {useDispatch, useSelector} from 'react-redux';
import LoadingModal from '../../../../Components/LoadingModal/LoadingModal';
import {usePrevious} from '../../../../Utilities/hooks';
import {importTournament} from '../FantasyTournamentAxios';

const ImportTournamentModal = (props) => {
    const {open, onClickClose, onTournamentImportSuccess} = props;
    const dispatch = useDispatch();
    const [formState, setFormState] = useState({});
    const {tournamentId} = formState;
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const client = useSelector((state) => state.client);
    const login = useSelector((state) => state.login);
    const tournament = useSelector((state) => state.tournament);
    const prevTournament = usePrevious(tournament);

    useEffect(() => {
        console.log('TOURNAMENT', tournament);
        if (tournament.isImportingTournament && !tournament.isImportingTournament) {
            setLoading(true);
        }
        if (tournament.isImportingTournamentSuccess && !prevTournament.isImportingTournamentSuccess) {
            setLoading(false);
            alert(tournament?.message?.data?.success?.message);
            onTournamentImportSuccess();
        }
        if (tournament.isImportingTournamentFail && !prevTournament.isImportingTournamentFail) {
            setLoading(false);
            setMessage(tournament.errorMessage);
        }
    }, [tournament]);

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = {tournamentId};
        dispatch(importTournament(data, login?.userDetails?.data?.accessToken, client.selectedClient));
    };

    const handleReset = () => {
        setFormState({});
    };

    const handleFormData = (formObj) => {
        setFormState({...formState, ...formObj});
    };

    return (
        <MuiModal onClickClose={onClickClose} open={open} className={styles.container}>
            {/* {!!message && <Snackbar open={!!message} message={message} onClose={() => onChange({message: ""})} />} */}
            {!!loading && <LoadingModal open={loading} />}
            <div className={styles.header}>
                <h3>Fetch Tournament</h3>
                <CustomIconButton onClick={onClickClose} className={styles.closeButton}>
                    <Close className={styles.closeButtonIcon} />
                </CustomIconButton>
            </div>
            <div className={styles.divider} />
            <div className={styles.body}>
                <Box component="form" onSubmit={handleSubmit} onReset={handleReset} className={styles.uploadForm}>
                    <MuiInputField
                        label="Tournament ID"
                        name="tournamentId"
                        onChange={(event) => handleFormData({tournamentId: event.target.value})}
                        size="small"
                        required={true}
                        value={tournamentId || ''}
                    />
                    {!!message && <p className={styles.responseText}>{message}</p>}
                    <div className={styles.formButtonGroup}>
                        <MuiButton type="reset" buttonText="Reset" buttonIcon={<Refresh />} />
                        <MuiButton type="submit" buttonText="Submit" buttonIcon={<Save />} />
                    </div>
                </Box>
            </div>
        </MuiModal>
    );
};

const CustomIconButton = styled(IconButton)({
    width: '28px',
    height: '28px',

    '&:hover': {
        backgroundColor: ColorPalette.NIGHT_RIDER,
    },
});

export default ImportTournamentModal;
