import React from 'react';
import '../../../Utilities/style.css';
import '../Login/login.css';
import {connect} from 'react-redux';
import Tabs from '../../../Components/Tabs/Tabs';
import Card from '../../../Components/Card/Card';
import DetailLayout from '../../Layouts/DetailLayout/DetailLayout';
import Snackbar from '../../../Components/Snackbar/Snackbar';
import Button from '../../../Components/Button/Button';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';
import FileInput from '../../../Components/FileInput/FileInput';
import {uploadFile, putArticles, reset} from './InshortsAction';

class UploadArticles extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            snackbarOpen: false,
            errorMessage: '',
            loading: false,
            uploadArticlesFile: '',
        };
    }

    componentDidMount() {
        const {login, history} = this.props;
        if (!login.userDetails.data) {
            history.push('/');
            this.props.logout();
        }
    }

    render() {
        const {snackbarOpen, errorMessage, loading, uploadArticlesFile} = this.props.inshorts;
        const {history} = this.props;
        let title = 'Upload articles Data';

        return (
            <DetailLayout location="Inshorts" history={history} centerTitle={title} id="">
                <LoadingModal open={loading} />
                <Snackbar open={snackbarOpen} message={errorMessage} onClose={() => this.props.reset()} />
                <div className="detail-container margin-top-20">
                    <div className="grid-item"></div>
                    <div className="grid-item">
                        <Card>
                            <div>
                                <div className="card-single-grid-container">
                                    <Tabs
                                        tab1Label="Upload Articles"
                                        panelA={this.renderUploadArticlesTab.bind(this)}
                                    />
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
            </DetailLayout>
        );
    }

    renderUploadArticlesTab() {
        let file = this.props.inshorts.uploadArticlesFile;
        return (
            <div>
                <div className="grid-item margin-top-20" style={{border: '2px solid #ffc954', padding: 15}}>
                    <span style={{color: '#ffc954', fontSize: 14}}>
                        - Mandatory: title, author, shortContent, imageURL, tags(comma separated words)
                        <br />
                        - Optional fields: readMoreURL, source, game
                        <br />
                        - Possbile value of game: 5f087626932a4c490cfeee20(PUBG), 5f27f09f1a358b7fa194c3c4(Garena Free
                        Fire), 5f50a3d333247c2d81d752ca(COD Mobile), 5f51f4c31493307c241eee01(VALORANT),
                        5f757d4d53f55124cedc8baf(CSGO),
                        <br /> 5f7c011ce2cf8340c045ce69(BRAWL Stars), 607adf3c37bf8e27254e77ab(COD PC),
                        607adf600be421491c3d377f(PUBG PC)
                    </span>

                    <div className="grid-item margin-top-20">
                        <FileInput
                            label="Upload articles: Upload File"
                            type="file"
                            name="upload_articles"
                            onChange={(value) => this.props.uploadFile(value)}
                        />
                    </div>
                    <Button
                        onClick={() => this.props.putArticles(file, this.props.login.userDetails.data.accessToken)}
                        buttonText="Submit & Download Results"
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const {inshorts, login} = state;
    return {login, inshorts};
};

export default connect(mapStateToProps, {reset, uploadFile, putArticles})(UploadArticles);
