import React from 'react';
import '../../../Utilities/style.css';
import '../Login/login.css';
import {connect} from 'react-redux';
import Card from '../../../Components/Card/Card';
import DetailLayout from '../../Layouts/DetailLayout/DetailLayout';
import Input from '../../../Components/Input/Input';
import DropDown from '../../../Components/Dropdown/Dropdown';
import {createContestType, updateContestType, getContestCategories} from './AxioCalls';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Snackbar from '../../../Components/Snackbar/Snackbar';
import moment from 'moment';
import Modal from '../../../Components/Modal/Modal';
import {deleteContestType} from './AxioCalls';
import Close from '../../../Images/close_yellow.png';
import Button from '../../../Components/Button/Button';
import {logout} from '../Login/LoginAction';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';
import {isNumber, isValidNumber, timeFormat} from '../../../Utilities/Helpers';
import FileInput from '../../../Components/FileInput/FileInput';
import {convertXlsxToJson} from '../../../Utilities/HelperFunctions';
import ConfirmationModal2 from '../../../Components/ConfirmationModal';
import {getDynamicPrizePools} from '../DynamicPrizePool/action';
import {
    USER_TYPES_OPTIONS,
    COMMON_STATUS_CONST,
    PRIZE_POOL_TYPE,
    PRIZE_POOL_TYPE_OPTIONS,
    SWITCH_FEES_OPTIONS,
    MAX_SUBSTITUION_ALLOWED_OPTIONS,
    SUBSTITUTION_FEE_OPTIONS,
    SUBSTITUTION_FEE_TYPES_OPTIONS,
} from '../../../Utilities/Constants';
import {debounce} from '../../../Utilities/HelperFunctions';
import AutoCompleteField from '../../../Components/AutoCompleteField/AutoCompleteField';
import MuiAutocompleteField from '../../../Components/MuiAutocompleteField/MuiAutoCompleteField';

import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

class AddContestType extends React.Component {
    constructor(props) {
        super(props);
        let location = this.props.history.location.pathname.includes('edit');
        const {addContestTypeEntryTypeOptionCashOnly, addContestTypeUserTypeOptionAll} =
            this.props.login?.permissions || {};
        this.state = {
            menuItems: [
                {value: true, text: 'Yes'},
                {value: false, text: 'No'},
            ],
            disabled: false,
            status: null,
            squadName: null,
            bigWins: null,
            expSlotFillPercent: null,
            noOfSmallWinBuckets: null,
            category: '',
            smallWinBuckets: {},
            loading: false,
            valueType: null, //cashPercent, coinPercent (winnings)
            cashOptions: !!addContestTypeEntryTypeOptionCashOnly
                ? [{value: 'cash', text: 'Cash Only'}]
                : [
                      //entryFeeType
                      {value: 'cash+fc', text: 'Cash + FC Coins'},
                      {value: 'cash+gems', text: 'Cash + Gems'},
                      {value: 'cash', text: 'Cash Only'},
                      {value: 'fcCoins', text: 'FC Coins Only'},
                      {value: 'free', text: 'Free'},
                  ],
            cashType: 'cash+fc',
            errorMessage: '',
            minParticipants: null,
            edit: location,
            entryFeeDisable: false,
            fcOption: [
                {value: 'COINPERCENT', text: 'Coin Percent'},
                {value: 'COINVALUE', text: 'Coin Value'},
            ],
            cashValueOption: [
                {value: 'CASHPERCENT', text: 'Cash Percent'},
                {value: 'CASHVALUE', text: 'Cash Value'},
                {value: 'IGCVALUE', text: 'IGC Value'},
            ],
            freeOptions: [{value: 'COINVALUE', text: 'Coin Value'}],
            suggestedContest: 'false',
        };
    }

    /**
     * function to save entered data in state
     * @param {string} field field names from the form
     * @param {*} value value in the field
     */
    handleInputChange(field, value) {
        console.log('field', field, value);
        if (field === 'rules') {
            this.setState({
                [field]: value.target.value,
                errorMessage: '',
                ctError: false,
                fcDiscountError: false,
                entryFeesError: false,
                teamSlotsError: false,
                minNoTeamError: false,
                maxTeamsError: false,
                bigWinsError: false,
                handlingChargeError: false,
                splitRemainingError: false,
                noOfWinsError: false,
                minFanGemsDiscPercentError: false,
                maxFanGemsDiscPercentError: false,
            });
        } else if (field.includes('rank')) {
            if (this.state.edit) {
                let ranks = this.state.ranks;
                ranks[field] = value;
                this.setState({ranks: ranks, errorMessage: '', [field + 'Error']: false});
            } else {
                this.setState({[field]: value, errorMessage: '', [field + 'Error']: false});
            }
        } else if (field.includes('bucketAmount') || field.includes('numOfSlots')) {
            let smallWinBuckets = this.state.smallWinBuckets;
            let bucketNum = field.split('_')[1];
            let key = 'bucket' + bucketNum;
            if (!smallWinBuckets) {
                smallWinBuckets = {};
            }
            if (!smallWinBuckets[key]) {
                smallWinBuckets[key] = {};
            }
            if (field.includes('bucketAmount')) smallWinBuckets[key].bucketAmount = value;
            else if (field.includes('numOfSlots')) {
                smallWinBuckets[key].numOfSlots = value;
            } else {
                this.setState({errorMessage: 'Unknown field ' + field, [field + 'Error']: true});
                return;
            }
            this.setState({smallWinBuckets: smallWinBuckets, errorMessage: '', [field + 'Error']: false});
        } else if (field === 'cashType' && value !== 'free') {
            this.setState({
                [field]: value,
                errorMessage: '',
                [field + 'Error']: false,
                entryFeeDisable: false,
                valueType: null,
                expSlotFillPercent: null,
            });
        } else if (field === 'cashType' && value === 'free') {
            this.setState({
                [field]: value,
                errorMessage: '',
                [field + 'Error']: false,
                entryFeeDisable: true,
                valueType: null,
                expSlotFillPercent: null,
            });
        } else {
            this.setState({
                [field]: value,
                errorMessage: '',
                ctError: false,
                fcDiscountError: false,
                entryFeesError: false,
                teamSlotsError: false,
                minNoTeamError: false,
                maxTeamsError: false,
                bigWinsError: false,
                handlingChargeError: false,
                splitRemainingError: false,
                noOfWinsError: false,
                smallWinsError: false,
                minFanGemsDiscPercentError: false,
                maxFanGemsDiscPercentError: false,
            });
        }
    }

    /**
     * dynamically display prizes based on number of big win slots
     * @param {*} prizes big wins slots object of the contest type
     */
    displayPrizes(prizes) {
        const {cashType, valueType, edit, disabled, ranks} = this.state;
        let inputs = [];
        for (let i = 1; i <= prizes; i++) {
            let unit = '';
            if (i === 1) {
                unit = i + 'st';
            } else if (i === 2) {
                unit = i + 'nd';
            } else if (i === 3) {
                unit = i + 'rd';
            } else {
                unit = i + 'th';
            }
            let val = 'rank' + i;

            let error = val + 'Error';
            inputs.push(
                <div key={'Prizes' + i} className="grid-item margin-top-20">
                    <Input
                        shrink={edit || cashType === 'free' ? true : undefined}
                        label={unit}
                        type="text"
                        name={val}
                        suffix={valueType === 'CASHPERCENT' || valueType === 'COINPERCENT' ? '%' : undefined}
                        value={edit ? ranks[val] : this.state[val]}
                        onChange={this.handleInputChange.bind(this, val)}
                        placeholder="Enter"
                        error={this.state[error]}
                        disabled={disabled}
                    />
                </div>,
            );
        }
        return inputs;
    }

    /**
     * dynamically display small win buckets based on number of small win buckets
     * @param {*} numberOfSmallWinBuckets
     */
    displaySmallWinPrizes(numberOfSmallWinBuckets) {
        const {valueType, smallWinBuckets, disabled} = this.state;
        let inputs = [];
        for (let i = 1; i <= numberOfSmallWinBuckets; i++) {
            let unit = '';
            if (i === 1) {
                unit = i + 'st';
            } else if (i === 2) {
                unit = i + 'nd';
            } else if (i === 3) {
                unit = i + 'rd';
            } else {
                unit = i + 'th';
            }
            let bucketAmount = 'bucketAmount_' + i;
            let numOfSlots = 'numOfSlots_' + i;
            let errorAmount = bucketAmount + 'Error';
            let errorSlots = numOfSlots + 'Error';

            let key = 'bucket' + i;
            inputs.push(
                <div key={key} className="card-grid-container no padding">
                    <div className="grid-item margin-top-20">
                        <Input
                            shrink={true}
                            label={unit + ' bucket - Amount'}
                            type="text"
                            name={bucketAmount}
                            suffix={valueType === 'CASHPERCENT' || valueType === 'COINPERCENT' ? '%' : undefined}
                            value={smallWinBuckets && smallWinBuckets[key] ? smallWinBuckets[key].bucketAmount : null}
                            onChange={this.handleInputChange.bind(this, bucketAmount)}
                            error={this.state[errorAmount]}
                            disabled={disabled}
                        />
                    </div>
                    <div className="grid-item margin-top-20">
                        <Input
                            shrink={true}
                            label={unit + ' bucket - NumberOfSlots'}
                            type="text"
                            name={numOfSlots}
                            value={smallWinBuckets && smallWinBuckets[key] ? smallWinBuckets[key].numOfSlots : null}
                            onChange={this.handleInputChange.bind(this, numOfSlots)}
                            error={this.state[errorSlots]}
                            placeholder="Enter"
                            disabled={disabled}
                        />
                    </div>
                </div>,
            );
        }
        return inputs;
    }

    componentDidMount() {
        const {login, contestType, history, client} = this.props;
        if (!login.userDetails.data) {
            history.push('/');
            this.props.logout();
        } else {
            this.props.getContestCategories(login.userDetails.data.accessToken, client.selectedClient);
            if (this.state.edit) {
                const {
                    name,
                    fcCoinDiscountPercent,
                    type,
                    entryFeePerTeam,
                    totalSlots,
                    minParticipationRequired,
                    minNumberOfTeams,
                    maxTeamsPerUser,
                    numberOfBigWinSlots,
                    winningDistribution,
                    contestRules,
                    valueType,
                    numberOfSmallWinBuckets,
                    expSlotFillPercent,
                    category,
                    minFanGemsDiscPercent,
                    maxFanGemsDiscPercent,
                    eligibleUsers,
                    prizePoolData = {},
                    substitutionData = {},
                } = contestType.editContestType;

                let ranks = winningDistribution.winners;

                this.setState({
                    ctName: name,
                    category: category ? category.name : null,
                    fcDiscount: fcCoinDiscountPercent,
                    cashType: type,
                    valueType,
                    expSlotFillPercent,
                    entryFees: entryFeePerTeam,
                    teamSlots: totalSlots,
                    minParticipants: minParticipationRequired,
                    minNoTeam: minNumberOfTeams,
                    maxTeams: maxTeamsPerUser,
                    bigWins: numberOfBigWinSlots,
                    rules: contestRules,
                    splitRemaining: winningDistribution.splitRemaining,
                    noOfWins: winningDistribution.smallWinSlots,
                    handlingCharge: winningDistribution.handling,
                    contestTypeId: contestType.editContestType.id,
                    ranks,
                    noOfSmallWinBuckets: numberOfSmallWinBuckets,
                    smallWinBuckets: winningDistribution.smallWinBuckets,
                    minFanGemsDiscPercent,
                    maxFanGemsDiscPercent,
                    eligibleUsers: eligibleUsers || undefined,
                    prizePoolType: prizePoolData?.type || '',
                    dynamicPrizePool: !!prizePoolData?.templateId
                        ? {title: '', value: prizePoolData?.templateId}
                        : null,
                    totalSubsAllowed: substitutionData?.totalSubsAllowed,
                    subsFeeType: substitutionData?.subsFeeType,
                    subsFee: substitutionData?.subsFee,
                });

                if (!!prizePoolData?.templateId) {
                    this.getPrizePoolTemplates({templateId: prizePoolData?.templateId});
                }
            }
        }
    }

    /**
     * Api call to add new contest type
     */
    onSave() {
        const {
            cashType,
            fcDiscount,
            ctName,
            entryFees,
            teamSlots,
            minNoTeam,
            rules,
            valueType,
            ranks,
            minParticipants,
            maxTeams,
            bigWins,
            handlingCharge,
            splitRemaining,
            noOfWins,
            edit,
            noOfSmallWinBuckets,
            smallWinBuckets,
            expSlotFillPercent,
            category,
            maxFanGemsDiscPercent,
            minFanGemsDiscPercent,
            contestTypeCreateConfirm,
            eligibleUsers,
            prizePoolType,
            dynamicPrizePool,
            totalSubsAllowed,
            subsFeeType,
            subsFee,
            suggestedContest,
        } = this.state;

        const {contestType, login, client} = this.props;

        if (cashType === null) {
            this.setState({errorMessage: 'Please Select a Cash Type'});
        } else if (
            cashType === 'cash+fc' &&
            (fcDiscount === null || fcDiscount === undefined || fcDiscount === '' || !isNumber(fcDiscount))
        ) {
            this.setState({fcDiscountError: true, errorMessage: 'Enter valid FC Coin Discount'});
        } else if (
            cashType === 'cash+gems' &&
            (maxFanGemsDiscPercent === null ||
                maxFanGemsDiscPercent === undefined ||
                maxFanGemsDiscPercent === '' ||
                !isNumber(maxFanGemsDiscPercent))
        ) {
            this.setState({
                maxFanGemsDiscPercentError: true,
                errorMessage: 'Enter valid max Fan Gems Disc Percent',
            });
        } else if (
            cashType === 'cash+gems' &&
            (minFanGemsDiscPercent === null ||
                minFanGemsDiscPercent === undefined ||
                minFanGemsDiscPercent === '' ||
                !isNumber(minFanGemsDiscPercent))
        ) {
            this.setState({
                minFanGemsDiscPercentError: true,
                errorMessage: 'Enter valid min Fan Gems Disc Percent',
            });
        } else if (ctName === null || ctName === '' || ctName === undefined) {
            this.setState({
                ctError: true,
                errorMessage: 'Enter valid Contest Type Name',
            });
        } else if (!category) {
            this.setState({errorMessage: 'Please select category'});
        } else if ((entryFees === null || !isNumber(entryFees)) && cashType !== 'free') {
            this.setState({
                entryFeesError: true,
                errorMessage: 'Enter valid Entry Fees',
            });
        } else if (teamSlots === null || !isNumber(teamSlots)) {
            this.setState({
                teamSlotsError: true,
                errorMessage: 'Enter valid Team slots',
            });
        } else if (
            (valueType === 'CASHPERCENT' || valueType === 'COINPERCENT') &&
            (expSlotFillPercent === null || !isNumber(expSlotFillPercent))
        ) {
            this.setState({
                teamSlotsError: true,
                errorMessage: 'Enter valid expected number of slot fill %',
            });
        } else if ((minNoTeam === null || !isNumber(minNoTeam)) && minParticipants) {
            this.setState({
                minNoTeamError: true,
                errorMessage: 'Enter valid Number of Teams',
            });
        } else if (minParticipants === null || minParticipants === undefined) {
            this.setState({
                minParticipantsError: true,
                errorMessage: 'Select minimun number of participants',
            });
        } else if (maxTeams === null || !isNumber(maxTeams)) {
            this.setState({
                maxTeamsError: true,
                errorMessage: 'Enter valid number of max teams',
            });
        } else if (bigWins === null || !isNumber(bigWins)) {
            this.setState({
                bigWinsError: true,
                errorMessage: 'Enter valid number of big wins',
            });
        } else if (noOfSmallWinBuckets === null || !isNumber(noOfSmallWinBuckets)) {
            this.setState({
                smallWinsError: true,
                errorMessage: 'Enter valid number of small win buckets',
            });
        } else if (
            (handlingCharge === null || !isNumber(handlingCharge)) &&
            cashType !== 'free' &&
            cashType !== 'fcCoins'
        ) {
            this.setState({
                handlingChargeError: true,
                errorMessage: 'Enter valid handling charge',
            });
        } else if (teamSlots === null || !isNumber(teamSlots)) {
            /*else if ((splitRemaining === null || !isNumber(splitRemaining)) && cashType !== "free") {
            this.setState({
                splitRemainingError: true,
                errorMessage: "Enter valid split",
            });
        }
        else if ((noOfWins === null || !isNumber(noOfWins)) && cashType !== "free") {
            this.setState({
                noOfWinsError: true,
                errorMessage: "Enter valid No of wins",
            });
        }*/
            this.setState({errorMessage: 'Team slots should be number'});
        } else if (valueType === null || valueType === undefined || valueType === '') {
            this.setState({errorMessage: 'Select valid value type'});
        } else if (!prizePoolType) {
            this.setState({errorMessage: 'Select valid prize pool type'});
        } else if (prizePoolType === PRIZE_POOL_TYPE.DYNAMIC && !dynamicPrizePool?.value) {
            this.setState({errorMessage: 'Select valid prize pool template'});
        } else {
            let winner = {};
            let rankTotal = 0;
            let total;
            for (let i = 1; i <= bigWins; i++) {
                if (!edit && (this.state['rank' + i] === undefined || this.state['rank' + i] === null)) {
                    this.setState({
                        ['rank' + i + 'Error']: true,
                        errorMessage: 'Enter valid amount for ranks',
                    });
                    return false;
                } else if (
                    edit &&
                    ranks !== undefined &&
                    ranks['rank' + i] !== undefined &&
                    ranks['rank' + i] !== null &&
                    isValidNumber(ranks['rank' + i])
                ) {
                    rankTotal += parseFloat(ranks['rank' + i]);
                    winner['rank' + i] = parseFloat(ranks['rank' + i] || 0);
                } else if (
                    !edit &&
                    this.state['rank' + i] !== undefined &&
                    this.state['rank' + i] !== null &&
                    isValidNumber(this.state['rank' + i])
                ) {
                    rankTotal += parseFloat(this.state['rank' + i]);
                    winner['rank' + i] = parseFloat(this.state['rank' + i] || 0);
                } else {
                    this.setState({
                        ['rank' + i + 'Error']: true,
                        errorMessage: 'Enter valid amount for ranks',
                    });
                    return false;
                }
            }
            let smallWinBucketList = {};
            let smallWinTotal = 0;
            for (let i = 1; i <= noOfSmallWinBuckets; i++) {
                let key = 'bucket' + i;
                if (smallWinBuckets && smallWinBuckets[key]) {
                    smallWinBucketList[key] = {};
                    if (isNumber(smallWinBuckets[key].numOfSlots)) {
                        smallWinBucketList[key].numOfSlots = parseInt(smallWinBuckets[key].numOfSlots);
                    } else {
                        this.setState({
                            ['numOfSlots_' + i + 'Error']: true,
                            errorMessage: 'Enter valid number of slots for smallWins bucket' + i,
                        });
                        return false;
                    }
                    if (isValidNumber(smallWinBuckets[key].bucketAmount)) {
                        smallWinBucketList[key].bucketAmount = parseFloat(smallWinBuckets[key].bucketAmount);
                        smallWinTotal += parseFloat(smallWinBuckets[key].bucketAmount);
                    } else {
                        this.setState({
                            ['bucketAmount_' + i + 'Error']: true,
                            errorMessage: 'Enter valid bucket Amount for smallWins bucket' + i,
                        });
                        return false;
                    }
                } else {
                    this.setState({
                        ['noOfSmallWinBuckets' + 'Error']: true,
                        errorMessage: 'Enter valid small win bucket information ' + i,
                    });
                    return false;
                }
            }

            let charges = cashType === 'free' || cashType === 'fcCoins' ? 0 : handlingCharge;
            let winDistribution = {
                handling: charges,
                winners: winner,
                smallWinBuckets: smallWinBucketList,
                splitRemaining: splitRemaining,
                smallWinSlots: noOfWins,
            };
            total = parseInt(charges) + rankTotal + smallWinTotal;
            total += splitRemaining ? parseInt(splitRemaining) : 0;

            let newContest = {
                name: ctName,
                fcCoinDiscountPercent: fcDiscount,
                type: cashType,
                entryFeePerTeam: cashType === 'free' ? 0 : entryFees,
                totalSlots: teamSlots,
                minParticipationRequired: minParticipants,
                minNumberOfTeams: minNoTeam,
                maxTeamsPerUser: maxTeams,
                numberOfBigWinSlots: bigWins,
                numberOfSmallWinBuckets: noOfSmallWinBuckets,
                winningDistribution: JSON.stringify(winDistribution),
                contestTypeId: contestType.editContestType.id,
                valueType: valueType,
                category: category,
                eligibleUsers: eligibleUsers || undefined,
                prizePoolData: {type: prizePoolType},
                substitutionData: {totalSubsAllowed, subsFeeType, subsFee},
                autoCreateContest: suggestedContest === 'true',
            };
            console.log('newContest', newContest);
            console.log(newContest);

            if (prizePoolType === PRIZE_POOL_TYPE.DYNAMIC && !!dynamicPrizePool?.value) {
                newContest.prizePoolData.templateId = dynamicPrizePool?.value;
            }

            if (cashType === 'cash+gems') {
                newContest = {...newContest, minFanGemsDiscPercent, maxFanGemsDiscPercent};
            }

            const onlyPercentValueAllow = ['CASHVALUE', 'IGCVALUE', 'COINVALUE'];
            if (expSlotFillPercent !== null && !onlyPercentValueAllow.includes(valueType)) {
                newContest.expSlotFillPercent = expSlotFillPercent;
            }

            if (
                total > 100 &&
                cashType !== 'free' &&
                !onlyPercentValueAllow.includes(valueType) &&
                prizePoolType !== PRIZE_POOL_TYPE.DYNAMIC
            ) {
                this.setState({errorMessage: 'Please make sure prize pool % does not exceed 100'});
                return false;
            } else if (edit) {
                this.setState({loading: true});
                this.props.updateContestType(newContest, login.userDetails.data.accessToken, client.selectedClient);
            } else {
                this.setState({loading: true});
                newContest.isConfirm = !!contestTypeCreateConfirm;
                this.props.createContestType(newContest, login.userDetails.data.accessToken, client.selectedClient);
            }
        }
    }

    componentDidUpdate(prevProps) {
        const {contestType, history, dynamicPrizePools} = this.props;

        if (
            contestType.addContestTypeSuccess &&
            !prevProps.contestType.addContestTypeSuccess &&
            !!contestType?.addContestType?.data?.confirmationRequired
        ) {
            this.setState({loading: false, contestTypeCreateConfirm: true});
        }

        if (
            contestType.addContestTypeSuccess &&
            !prevProps.contestType.addContestTypeSuccess &&
            !contestType?.addContestType?.data?.confirmationRequired
        ) {
            history.push('/contestTypes');
        } else if (contestType.addContestTypeFail && !prevProps.contestType.addContestTypeFail) {
            this.setState({loading: false, errorMessage: contestType.errorMessage});
        }
        if (contestType.updateContestSuccess && !prevProps.contestType.updateContestSuccess) {
            history.push('/contestTypes');
        } else if (contestType.updateContestFail && !prevProps.contestType.updateContestFail) {
            this.setState({loading: false, errorMessage: contestType.errorMessage});
        }
        if (contestType.deleteContestSuccess && !prevProps.contestType.deleteContestSuccess) {
            this.setState({loading: false, errorMessage: contestType.deleteContest.msg});
            history.push('/contestTypes');
        } else if (contestType.deleteContestFail && !prevProps.contestType.deleteContestFail) {
            this.setState({loading: false, errorMessage: contestType.errorMessage});
        }
        if (dynamicPrizePools.isFetchingDynamicPrizePools && !prevProps.dynamicPrizePools.isFetchingDynamicPrizePools) {
            this.setState({loading: true});
        }
        if (
            dynamicPrizePools.isFetchingDynamicPrizePoolsSuccess &&
            !prevProps.dynamicPrizePools.isFetchingDynamicPrizePoolsSuccess
        ) {
            this.handleDynamicPrizePoolData();
        }
        if (
            dynamicPrizePools.isFetchingDynamicPrizePoolsFail &&
            !prevProps.dynamicPrizePools.isFetchingDynamicPrizePoolsFail
        ) {
            this.setState({errorMessage: dynamicPrizePools?.message?.msg, loading: false});
        }
    }

    handleDynamicPrizePoolData = () => {
        const {
            dynamicPrizePools: {
                list: {data = []},
            },
        } = this.props;
        let {dynamicPrizePool} = this.state;

        const opn = data?.map(({id, templateName, prizeDistribution = [], totalSlots = 0}) => ({
            title: templateName,
            value: id,
            prizeDistribution,
            totalSlots,
        }));

        if (this.state.edit && dynamicPrizePool?.value && !dynamicPrizePool?.title) {
            dynamicPrizePool = opn.filter((o) => o.value === dynamicPrizePool?.value)?.[0];
        }

        this.setState({loading: false, dynamicPrizePoolOptions: opn, dynamicPrizePool});
    };

    /**
     * APi call to delete contest type
     */
    onDeleteContestType() {
        const {contestType, login, client} = this.props;
        let data = {
            contestTypeId: contestType.editContestType.id,
        };
        this.props.deleteContestType(data, login.userDetails.data.accessToken, client.selectedClient);
        this.setState({open: false, loading: true});
    }

    handleDownloadSampleFile = () => {
        window.open('https://d35sk7thg13w2i.cloudfront.net/Sample+Contest+Template.xlsx', '_blank');
    };
    handleOnUploadFile = (file) => {
        const {contestType} = this.props;
        let data = {bigWins: 0, noOfSmallWinBuckets: 0, smallWinBuckets: {}};
        convertXlsxToJson(file, (json) => {
            for (let key in json) {
                key = parseInt(key);
                if (key === 0) {
                    const {cashType, ctName, category, entryFees, teamSlots, maxTeams, handlingCharge} = json[key];
                    data = {
                        ...data,
                        cashType,
                        ctName,
                        category,
                        entryFees,
                        teamSlots,
                        maxTeams,
                        handlingCharge,
                    };
                }

                if (json[key]?.bucketSize === 1) {
                    data['rank' + (key + 1)] = json[key]?.totalBucketAmount;
                    data.bigWins = key + 1;
                }

                if (json[key]?.bucketSize > 1) {
                    ++data.noOfSmallWinBuckets;
                    data.smallWinBuckets = {
                        ...data.smallWinBuckets,
                        ['bucket' + data.noOfSmallWinBuckets]: {
                            bucketAmount: json[key]?.totalBucketAmount,
                            numOfSlots: json[key]?.bucketSize,
                        },
                    };
                }
            }

            if (data.category) {
                data.category = contestType.contestCategory?.filter((c) => c?.displayName === data.category)?.[0]?.name;
            }
            if (!data.category) {
                this.setState({errorMessage: 'Category not found, Please check again or select manually'});
            }
            this.setState({...data});
        });
    };

    getPrizePoolTemplates = debounce(({templateName, templateId}) => {
        const {login, client} = this.props;
        this.props.getDynamicPrizePools(
            {
                templateName,
                templateId,
                status: COMMON_STATUS_CONST.ACTIVE,
            },
            login.userDetails.data.accessToken,
            client.selectedClient,
        );
    });

    handleOnChange = (stateObj = {}) => {
        if (stateObj.prizePoolType === PRIZE_POOL_TYPE.DYNAMIC) {
            stateObj = {...stateObj, valueType: 'CASHPERCENT'};
        }
        this.setState(stateObj);
    };

    handleOnChangeSubsFee = (_switchFees, _value, _index) => {
        const newSwitchFees = _switchFees.slice();
        newSwitchFees.splice(_index, 1, parseInt(_value));
        this.handleOnChange({subsFee: newSwitchFees});
    };

    handleOnChangeDpp = (template = {}) => {
        const {edit} = this.state;
        const winningDistribution =
            template?.prizeDistribution?.[(template?.prizeDistribution?.length || 0) - 1]?.winningDistribution || {};
        this.setState({
            ...(!!edit ? {ranks: winningDistribution?.winners || {}} : winningDistribution?.winners || {}),
            smallWinBuckets: winningDistribution?.smallWinBuckets || {},
            handlingCharge: winningDistribution?.handling,
            noOfSmallWinBuckets: Object.keys(winningDistribution?.smallWinBuckets || {})?.length,
            bigWins: Object.keys(winningDistribution?.winners || {})?.length,
            dynamicPrizePool: template,
            teamSlots: template.totalSlots,
        });
    };

    handleSearchDPP = (e, name) => {
        if (e?.target?.value && name && e?.target?.value === name) {
            this.getPrizePoolTemplates({templateName: name});
        }
    };

    handleSuggestedContestChange = (event) => {
        this.setState({suggestedContest: event.target.value});
    };

    render() {
        const {
            menuItems,
            minParticipants,
            disabled,
            contestTypeId,
            fcDiscount,
            teamSlots,
            ctName,
            freeOptions,
            entryFees,
            fcOption,
            cashValueOption,
            minNoTeam,
            maxTeams,
            bigWins,
            handlingCharge,
            rules,
            errorMessage,
            valueType,
            splitRemaining,
            noOfWins,
            cashType,
            cashOptions,
            ctError,
            fcDiscountError,
            loading,
            entryFeesError,
            teamSlotsError,
            minNoTeamError,
            maxTeamsError,
            bigWinsError,
            handlingChargeError,
            splitRemainingError,
            noOfWinsError,
            edit,
            open,
            noOfSmallWinBuckets,
            expSlotFillPercent,
            category,
            minFanGemsDiscPercent,
            maxFanGemsDiscPercent,
            maxFanGemsDiscPercentError,
            minFanGemsDiscPercentError,
            contestTypeCreateConfirm,
            eligibleUsers,
            prizePoolType,
            dynamicPrizePool,
            dynamicPrizePoolOptions = [],
            totalSubsAllowed,
            subsFeeType,
            subsFee = [],
            suggestedContest = 'false',
        } = this.state;
        const {history, contestType, login} = this.props;
        let title = edit ? 'Edit Contest Type' : 'Add  Contest Type';
        let shrink = edit ? true : undefined;
        const {
            contestWrite,
            addContestTypeUserTypeOptionAll,
            addContestTypeSwitchTypeContest,
            addContestTypeTotalSwitchAllowed,
            addContestTypeSwitchFreeTypes,
        } = login?.permissions || {};

        let categoryOptions = contestType.contestCategory
            ? contestType.contestCategory.map((c) => {
                  return {value: c.name, text: c.displayName};
              })
            : [];

        return (
            <DetailLayout
                location="AddContestType"
                history={history}
                centerTitle={title}
                onSave={!!contestWrite ? this.onSave.bind(this) : undefined}
                obPublish={this.onPublish}
                onDeleteClick={!!contestWrite ? () => this.setState({open: true}) : undefined}
                editPage={edit}
                id={edit ? contestType.editContestType.id : null}
            >
                {!!errorMessage && (
                    <Snackbar
                        open={!!errorMessage}
                        message={errorMessage}
                        onClose={() => this.setState({errorMessage: ''})}
                    />
                )}

                {!!loading && <LoadingModal open={loading} />}

                {!!contestTypeCreateConfirm && (
                    <ConfirmationModal2
                        open={!!contestTypeCreateConfirm}
                        handleClose={() => this.setState({contestTypeCreateConfirm: false})}
                        confirmationText="Same configuration contestType already available. Please confirm to continue if you want to proceed"
                        handleOnConfirm={this.onSave.bind(this)}
                    />
                )}

                <Modal open={open}>
                    <div className="container modal-top-margin">
                        <Card>
                            <div className="disp-flex">
                                <div className="modal-left-header">Delete Contest Type</div>
                                <div onClick={() => this.setState({open: false})}>
                                    <img src={Close} alt="" className="cursor" />
                                </div>
                            </div>
                            <div className="align-center equalcontainer-grids">
                                <div />
                                <div>
                                    <div className="align-center  margin-top-30">
                                        <Button
                                            buttonText="Delete Contest Type"
                                            onClick={this.onDeleteContestType.bind(this)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                </Modal>

                <div className="detail-container margin-top-20">
                    <div className="grid-item " />
                    <div className="grid-item ">
                        <Card>
                            {!edit && (
                                <>
                                    <FileInput
                                        className="mb-15 pt-30"
                                        accept=".xlsx"
                                        label={'Upload xlsx file for auto fill contest type fields'}
                                        onClickSampleFile={this.handleDownloadSampleFile}
                                        onChange={this.handleOnUploadFile}
                                    />
                                    <hr />
                                    <p className="text-center">OR</p>
                                    <hr />
                                </>
                            )}
                            <div>
                                <div className="card-grid-container nopadding ">
                                    {/* row 1 */}
                                    <div className="grid-item margin-top-20">
                                        <DropDown
                                            menuItems={cashOptions}
                                            label="Entry Type"
                                            value={cashType}
                                            placeholder="select"
                                            onChange={this.handleInputChange.bind(this, 'cashType')}
                                        />
                                    </div>
                                    <div className="grid-item margin-top-20">
                                        <DropDown
                                            className="mb-15"
                                            label="Prize Pool Type"
                                            menuItems={PRIZE_POOL_TYPE_OPTIONS}
                                            disabled={!!contestTypeId}
                                            name="prizePoolType"
                                            value={prizePoolType || ''}
                                            placeholder="Select"
                                            onChange={(value) => this.handleOnChange({prizePoolType: value})}
                                        />
                                    </div>
                                    {prizePoolType === PRIZE_POOL_TYPE.DYNAMIC && (
                                        <div className="grid-item margin-top-20">
                                            <AutoCompleteField
                                                label="Prize Pool Template"
                                                onChange={(opn) => this.handleOnChangeDpp(opn)}
                                                options={dynamicPrizePoolOptions}
                                                value={dynamicPrizePool || ''}
                                                inputChange={this.handleSearchDPP}
                                            />
                                        </div>
                                    )}
                                    {cashType === 'cash+fc' && (
                                        <div className="grid-item margin-top-20">
                                            <Input
                                                shrink={shrink}
                                                label="% FC Coin Discount"
                                                type="text"
                                                name="fcDiscount"
                                                value={fcDiscount}
                                                error={fcDiscountError}
                                                onChange={this.handleInputChange.bind(this, 'fcDiscount')}
                                                placeholder="Enter % FC Coin Discount"
                                                disabled={disabled}
                                            />
                                        </div>
                                    )}
                                    {cashType === 'cash+gems' && (
                                        <div className="grid-item margin-top-20">
                                            <Input
                                                shrink={shrink}
                                                label="% Max Fan Gems Discount"
                                                type="text"
                                                name="maxFanGemsDiscPercent"
                                                value={maxFanGemsDiscPercent}
                                                error={maxFanGemsDiscPercentError}
                                                onChange={this.handleInputChange.bind(this, 'maxFanGemsDiscPercent')}
                                                placeholder="Enter % Max Fan Gems Discount"
                                                disabled={disabled}
                                            />
                                        </div>
                                    )}
                                    {cashType === 'cash+gems' && (
                                        <div className="grid-item margin-top-20">
                                            <Input
                                                shrink={shrink}
                                                label="% Min Fan Gems Discount"
                                                type="text"
                                                name="maxFanGemsDiscPercent"
                                                value={minFanGemsDiscPercent}
                                                error={minFanGemsDiscPercentError}
                                                onChange={this.handleInputChange.bind(this, 'minFanGemsDiscPercent')}
                                                placeholder="Enter % Min Fan Gems Discount"
                                                disabled={disabled}
                                            />
                                        </div>
                                    )}
                                    {/* row 2 */}
                                    <div className="grid-item margin-top-20">
                                        <Input
                                            shrink={shrink}
                                            label="Contest Type Name"
                                            type="text"
                                            name="ctName"
                                            value={ctName}
                                            error={ctError}
                                            onChange={this.handleInputChange.bind(this, 'ctName')}
                                            placeholder="Enter Contest Type Name"
                                            disabled={disabled}
                                        />
                                    </div>
                                    <div className="grid-item margin-top-20">
                                        <DropDown
                                            menuItems={
                                                !!addContestTypeUserTypeOptionAll
                                                    ? [{value: 'ALL', text: 'ALL'}]
                                                    : USER_TYPES_OPTIONS
                                            }
                                            label="User Type"
                                            value={eligibleUsers || ''}
                                            placeholder="Select user type"
                                            onChange={this.handleInputChange.bind(this, 'eligibleUsers')}
                                        />
                                    </div>
                                    {cashType !== 'free' && (
                                        <div className="grid-item margin-top-20">
                                            <Input
                                                shrink={shrink}
                                                label="Entry Fee / Team"
                                                type="text"
                                                name="entryFees"
                                                value={entryFees}
                                                error={entryFeesError}
                                                onChange={this.handleInputChange.bind(this, 'entryFees')}
                                                placeholder="Enter Entry Fee / Team"
                                                disabled={disabled}
                                            />
                                        </div>
                                    )}
                                    {/* row 3 */}
                                    <div className="grid-item margin-top-20">
                                        <Input
                                            shrink={shrink}
                                            label="Total Team Slots"
                                            type="text"
                                            name="teamSlots"
                                            value={teamSlots}
                                            error={teamSlotsError}
                                            onChange={this.handleInputChange.bind(this, 'teamSlots')}
                                            placeholder="Enter Total Team Slots"
                                            disabled={disabled || prizePoolType === PRIZE_POOL_TYPE.DYNAMIC}
                                        />
                                    </div>
                                    <div className="grid-item margin-top-20">
                                        {cashType === 'cash' || cashType === 'cash+fc' || cashType === 'cash+gems' ? (
                                            <DropDown
                                                menuItems={cashValueOption}
                                                label="Value type"
                                                disabled={prizePoolType === PRIZE_POOL_TYPE.DYNAMIC}
                                                value={valueType}
                                                placeholder="select"
                                                onChange={this.handleInputChange.bind(this, 'valueType')}
                                            />
                                        ) : (
                                            <DropDown
                                                menuItems={cashType === 'free' ? freeOptions : fcOption}
                                                label="Value type"
                                                disabled={prizePoolType === PRIZE_POOL_TYPE.DYNAMIC}
                                                value={valueType}
                                                placeholder="select"
                                                onChange={this.handleInputChange.bind(this, 'valueType')}
                                            />
                                        )}
                                    </div>
                                    {(valueType === 'CASHPERCENT' || valueType === 'COINPERCENT') && (
                                        <div className="grid-item margin-top-20">
                                            <Input
                                                shrink={shrink}
                                                label="Expected Slot Fill %"
                                                type="text"
                                                name="expSlotFillPercent"
                                                value={expSlotFillPercent}
                                                error={teamSlotsError}
                                                onChange={this.handleInputChange.bind(this, 'expSlotFillPercent')}
                                                placeholder="Enter Expected Slot Fill %"
                                                suffix={'%'}
                                                disabled={false}
                                            />
                                        </div>
                                    )}
                                    {/* row 4 */}
                                    <div className="grid-item margin-top-20">
                                        <DropDown
                                            menuItems={menuItems}
                                            label="Minimum Participation Required"
                                            value={minParticipants}
                                            placeholder="select"
                                            onChange={this.handleInputChange.bind(this, 'minParticipants')}
                                        />
                                    </div>
                                    <div className="grid-item margin-top-20">
                                        <Input
                                            shrink={shrink}
                                            label="Minimum # of Teams"
                                            type="text"
                                            name="minNoTeam"
                                            value={minNoTeam}
                                            error={minNoTeamError}
                                            onChange={this.handleInputChange.bind(this, 'minNoTeam')}
                                            placeholder="Enter Minimum # of Teams"
                                            disabled={!minParticipants}
                                        />
                                    </div>
                                    {/* row5 */}
                                    <div className="grid-item margin-top-20">
                                        <Input
                                            shrink={shrink}
                                            label="Max Teams / User"
                                            type="text"
                                            name="maxTeams"
                                            value={maxTeams}
                                            error={maxTeamsError}
                                            onChange={this.handleInputChange.bind(this, 'maxTeams')}
                                            placeholder="Enter Max Teams / User"
                                            disabled={disabled}
                                        />
                                    </div>
                                    <div className="grid-item margin-top-20">
                                        <Input
                                            shrink={shrink}
                                            label="# of Big Win Slots"
                                            type="text"
                                            name="bigWins"
                                            value={bigWins}
                                            error={bigWinsError}
                                            onChange={this.handleInputChange.bind(this, 'bigWins')}
                                            placeholder="Enter # of Big Win Slots"
                                            disabled={disabled || prizePoolType === PRIZE_POOL_TYPE.DYNAMIC}
                                        />
                                        <Input
                                            shrink={true}
                                            label="Number of Small Win Buckets"
                                            type="text"
                                            name="noOfSmallWinBuckets"
                                            value={noOfSmallWinBuckets}
                                            onChange={this.handleInputChange.bind(this, 'noOfSmallWinBuckets')}
                                            placeholder="Enter"
                                            disabled={disabled || prizePoolType === PRIZE_POOL_TYPE.DYNAMIC}
                                        />
                                    </div>

                                    {/* row 6 */}
                                    <div className="grid-item grid-col-1-snap-2 margin-top-30">
                                        Prize Pool as Winnings
                                    </div>
                                    {/* row 7 */}
                                    {cashType === 'free' || cashType === 'fcCoins' ? null : (
                                        <div className="grid-item margin-top-20">
                                            <Input
                                                shrink={shrink}
                                                label="Handling Charges "
                                                type="text"
                                                name="handlingCharge"
                                                value={handlingCharge}
                                                error={handlingChargeError}
                                                onChange={this.handleInputChange.bind(this, 'handlingCharge')}
                                                placeholder="Enter Handling Charges %"
                                                disabled={disabled || prizePoolType === PRIZE_POOL_TYPE.DYNAMIC}
                                                suffix={
                                                    valueType === 'CASHPERCENT' || valueType === 'COINPERCENT'
                                                        ? '%'
                                                        : undefined
                                                }
                                            />
                                        </div>
                                    )}

                                    <div className="grid-item margin-top-20">
                                        <DropDown
                                            menuItems={categoryOptions}
                                            label="Category"
                                            value={category}
                                            placeholder="Contest Cateogry"
                                            onChange={this.handleInputChange.bind(this, 'category')}
                                        />
                                    </div>
                                    <div className="grid-item margin-top-20" />
                                </div>
                                {/* switch row */}
                                {!!addContestTypeSwitchTypeContest && (
                                    <div className="grid-item grid-col-1-snap-2 margin-top-30">Switch Type Contest</div>
                                )}
                                {!!addContestTypeTotalSwitchAllowed && (
                                    <div className="margin-top-20">
                                        <Input
                                            label="Total Switch Allowed"
                                            type="number"
                                            name="totalSubsAllowed"
                                            value={totalSubsAllowed || ''}
                                            onChange={(value) =>
                                                this.handleOnChange({totalSubsAllowed: parseInt(value)})
                                            }
                                        />
                                    </div>
                                )}
                                {!!addContestTypeSwitchFreeTypes && (
                                    <div className="margin-top-20">
                                        <DropDown
                                            menuItems={SUBSTITUTION_FEE_TYPES_OPTIONS}
                                            label="Switch Fee Types"
                                            value={subsFeeType || ''}
                                            onChange={(value) => this.handleOnChange({subsFeeType: value})}
                                        />
                                    </div>
                                )}
                                {!!totalSubsAllowed &&
                                    Array(totalSubsAllowed)
                                        .fill(null)
                                        .map((_, index) => (
                                            <div className="margin-top-20" key={`filed-${index + 1}`}>
                                                <DropDown
                                                    menuItems={SUBSTITUTION_FEE_OPTIONS}
                                                    label={`Switch Fees ${index + 1}`}
                                                    value={subsFee[index]}
                                                    onChange={(value) =>
                                                        this.handleOnChangeSubsFee(subsFee, value, index)
                                                    }
                                                />
                                            </div>
                                        ))}

                                {/* row 8 */}
                                {prizePoolType !== PRIZE_POOL_TYPE.DYNAMIC && (
                                    <div className="grid-item basic-data margin-top-30">Big Winnings</div>
                                )}
                                {prizePoolType !== PRIZE_POOL_TYPE.DYNAMIC && (
                                    <div className="card-grid-container nopadding ">{this.displayPrizes(bigWins)}</div>
                                )}
                                {prizePoolType !== PRIZE_POOL_TYPE.DYNAMIC && (
                                    <div className="grid-item basic-data margin-top-30">Small Winnings</div>
                                )}
                                {prizePoolType !== PRIZE_POOL_TYPE.DYNAMIC && (
                                    <div>{this.displaySmallWinPrizes(noOfSmallWinBuckets)}</div>
                                )}

                                <div className="grid-item card-grid-container 6">
                                    {/* row 11 */}
                                    {cashType !== 'free' ? (
                                        splitRemaining > 0 ? (
                                            <React.Fragment>
                                                <div className="grid-item margin-top-20">
                                                    <Input
                                                        shrink={shrink}
                                                        label="Split Remaining"
                                                        type="text"
                                                        name="splitRemaining"
                                                        value={splitRemaining}
                                                        onChange={this.handleInputChange.bind(this, 'splitRemaining')}
                                                        placeholder="Enter Split Remaining"
                                                        error={splitRemainingError}
                                                        disabled={disabled}
                                                        suffix={
                                                            valueType === 'CASHPERCENT' || valueType === 'COINPERCENT'
                                                                ? '%'
                                                                : undefined
                                                        }
                                                    />
                                                </div>
                                                <div className="grid-item margin-top-20">
                                                    <Input
                                                        shrink={shrink}
                                                        label="# of small win slots"
                                                        type="text"
                                                        name="noOfWins"
                                                        value={noOfWins}
                                                        error={noOfWinsError}
                                                        onChange={this.handleInputChange.bind(this, 'noOfWins')}
                                                        placeholder="Enter # of small win slots"
                                                        disabled={disabled}
                                                    />
                                                </div>
                                            </React.Fragment>
                                        ) : null
                                    ) : null}
                                </div>
                                {/* <div className="card-fullgrid-container margin-top-20">
                                    <label htmlFor="rules">Contest Rules</label>
                                    <TextareaAutosize
                                        className="margin-top-20"
                                        aria-label="maximum height"
                                        value={rules}
                                        placeholder="Contest Rules"
                                        onChange={this.handleInputChange.bind(this, 'rules')}
                                    />
                                </div> */}
                            </div>
                        </Card>
                    </div>
                    <div className="grid-item margin-top-20">
                        <div>
                            {edit ? (
                                <div>
                                    <div className="basic-headers">Last Updated</div>
                                    <div className="basiic-data date-space">
                                        {/* {moment
                                            .utc(contestType.editContestType.updatedAt)
                                            .format('hh:mm A [\n] DD-MM-YYYY')} */}
                                        {timeFormat(contestType.editContestType.updatedAt)}
                                    </div>
                                </div>
                            ) : null}
                            <div style={{padding: '1rem', width: '100%'}}>
                                <FormControl>
                                    <FormLabel id="demo-controlled-radio-buttons-group">Auto Create Contest</FormLabel>
                                    <RadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        value={suggestedContest}
                                        onChange={this.handleSuggestedContestChange}
                                        row
                                    >
                                        <FormControlLabel value="true" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="false" control={<Radio />} label="No" />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                </div>
            </DetailLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        squad: state.squad,
        player: state.player,
        contestType: state.contestType,
        dynamicPrizePools: state.dynamicPrizePools,
        client: state.client,
    };
};

export default connect(mapStateToProps, {
    createContestType,
    updateContestType,
    getContestCategories,
    logout,
    deleteContestType,
    getDynamicPrizePools,
})(AddContestType);
