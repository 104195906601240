import React from 'react';
import './Input.css';
import {TextField} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';

class Input extends React.Component {
    constructor(props) {
        super(props);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.keyDown = this.keyDown.bind(this);
    }

    /**
     * send value to the parent on chnage
     * @param {*} a value in the field
     */
    handleInputChange(a) {
        this.props.onChange(a.target.value);
    }

    /**
     * send value to parent on back click
     * @param {*} event value in the field
     */
    keyDown(event) {
        if (this.props.onKeyDown) {
            this.props.onKeyDown(event);
        }
    }

    render() {
        const {label, type, name, value, placeholder, disabled, error, helperText, defaultValue, id, suffix, prefix} =
            this.props;
        let shrinkProperty = value !== '' && value !== undefined && value !== null;

        return (
            <TextField
                key={label}
                {...this.props}
                id={id}
                label={label}
                type={type}
                name={name}
                value={value}
                InputLabelProps={shrinkProperty === true ? {shrink: true} : null}
                onChange={this.handleInputChange}
                placeholder={placeholder}
                disabled={disabled}
                error={error}
                helperText={helperText}
                defaultValue={defaultValue}
                InputProps={
                    suffix || prefix
                        ? {
                              endAdornment: <InputAdornment position="end">{suffix || ''}</InputAdornment>,
                              startAdornment: <InputAdornment position="start">{prefix || ''}</InputAdornment>,
                          }
                        : null
                }
                onKeyDown={this.keyDown}
            />
        );
    }
}

export default Input;
