import axios from 'axios';
import {
    addMatch,
    addMatchSuccess,
    addMatchFail,
    updateMatchRequest,
    updateMatchSuccess,
    updateMatchFail,
    deleteMatchRequest,
    deleteMatchSuccess,
    deleteMatchFail,
    getMatchRequest,
    getMatchSuccess,
    getMatchFail,
    publishMatchRequest,
    publishMatchSuccess,
    publishMatchFail,
    rescheduleMatchSuccess,
    rescheduleMatchFail,
    updatePlayerStatsFail,
    updatePlayerStatsSuccess,
    updatePlayerStatsRequest,
    rescheduleMatchRequest,
} from './action';
import {URL, baseUrl, HEADER_SOURCE} from '../../../Utilities/Constants';
import {
    UPDATE_STATUS_LIVE_NOW,
    UPDATE_STATUS_LIVE_NOW_FAIL,
    UPDATE_STATUS_LIVE_NOW_SUCCESS,
    UPDATE_MATCH_TAG,
    UPDATE_MATCH_TAG_SUCCESS,
    UPDATE_MATCH_TAG_FAIL,
    COMING_SOON_MATCH,
    COMING_SOON_MATCH_SUCCESS,
    COMING_SOON_MATCH_FAIL,
    UPDATE_MATCH_TYPE,
    UPDATE_MATCH_TYPE_SUCCESS,
    UPDATE_MATCH_TYPE_FAIL,
    SUBSTITUTE_PLAYER,
    SUBSTITUTE_PLAYER_SUCCESS,
    SUBSTITUTE_PLAYER_FAIL,
    MARK_MATCH_FR,
    MARK_MATCH_FR_SUCCESS,
    MARK_MATCH_FR_FAIL,
    MATCH_DRAFT_TO_LIVE,
    MATCH_DRAFT_TO_LIVE_SUCCESS,
    MATCH_DRAFT_TO_LIVE_FAIL,
    PLAYER_FREEZE_UNFREEZE,
    PLAYER_FREEZE_UNFREEZE_SUCCESS,
    PLAYER_FREEZE_UNFREEZE_FAIL,
    MATCH_LIST,
    MATCH_LIST_SUCCESS,
    MATCH_LIST_FAIL,
    GET_COMMON_MATCH,
    GET_COMMON_MATCH_SUCCESS,
    GET_COMMON_MATCH_FAIL,
} from './Constants';
import apis from '../../../apiCalls/apis';

export const GET_MATCH_DETAIL_INIT = 'GET_MATCH_DETAIL_INIT';
export const GET_MATCH_DETAIL_SUCCESS = 'GET_MATCH_DETAIL_SUCCESS';
export const GET_MATCH_DETAIL_FAIL = 'GET_MATCH_DETAIL_FAIL';
export const MARK_LIVE_MATCH_INIT = 'MARK_LIVE_MATCH_INIT';
export const MARK_LIVE_MATCH_SUCCESS = 'MARK_LIVE_MATCH_SUCCESS';
export const MARK_LIVE_MATCH_FAIL = 'MARK_LIVE_MATCH_FAIL';
export const MARK_MATCH_CANCEL_INIT = 'MARK_MATCH_CANCEL_INIT';
export const MARK_MATCH_CANCEL_SUCCESS = 'MARK_MATCH_CANCEL_SUCCESS';
export const MARK_MATCH_CANCEL_FAIL = 'MARK_MATCH_CANCEL_FAIL';
export const MARK_MATCH_OF_THE_DAY_INIT = 'MARK_MATCH_OF_THE_DAY_INIT';
export const MARK_MATCH_OF_THE_DAY_SUCCESS = 'MARK_MATCH_OF_THE_DAY_SUCCESS';
export const MARK_MATCH_OF_THE_DAY_FAIL = 'MARK_MATCH_OF_THE_DAY_FAIL';

const SOMETHING_WENT_WRONG = 'Something went wrong';

/**
 * get match list api request
 * @param {*} data page details
 * @param {string} token user token
 * @param {string} clientId selected client id
 */
export const getMatchList = (data, token, clientId) => {
    return async (dispatch) => {
        dispatch({type: MATCH_LIST});
        try {
            const response = await apis.getMatchList(data, token, clientId);
            if (response.status === 200) {
                dispatch({type: MATCH_LIST_SUCCESS, payload: response});
            } else {
                dispatch({
                    type: MATCH_LIST_FAIL,
                    payload: response?.data?.errorData?.errorReason || 'Something went wrong!',
                });
            }
        } catch (e) {
            if (e.response) {
                dispatch({type: MATCH_LIST_FAIL, payload: e?.response?.data || 'Something went wrong!'});
            } else {
                dispatch({type: MATCH_LIST_FAIL, payload: e?.message || 'Unknown error occurred!'});
            }
        }
    };
};
// export function getMatchList(match, token, clientId) {
//     return dispatch => {
//         dispatch(listMatch());
//         axios({
//             method: "post",
//             headers: {Authorization: "Bearer " + token, clientid: clientId},
//             url: `${URL}/match/list`,
//             data: match
//         }).then(data => {
//             if (data.data.status === "success") {
//                 dispatch(listMatchSuccess(data));
//             } else if (data.data.status === "fail") {
//                 dispatch(listMatchFail(data.data.errorData.errorReason));
//             } else {
//                 dispatch(listMatchFail('Unknown Error Occured'));
//             }
//         }).catch(err => {
//             if (err.response) {
//                 const errorMsg = err?.response?.data?.msg || err.message;
//                 dispatch(listMatchFail(errorMsg || "Something went wrong"));
//             } else {
//                 dispatch(listMatchFail(err.message || "Something went wrong"));
//             }
//         });
//     };
// }

/**
 * create match api request
 * @param {*} match match details
 * @param {string} token user token
 */
export function createMatch(match, token, clientId) {
    return (dispatch) => {
        dispatch(addMatch());
        axios({
            method: 'post',
            headers: {
                'x-source': HEADER_SOURCE,
                Authorization: 'Bearer ' + token,
                clientid: clientId,
            },
            url: `${URL}/match/create`,
            data: match,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(addMatchSuccess(data));
                } else if (data.data.status === 'fail') {
                    dispatch(addMatchFail(data.data.errorData.errorReason));
                } else {
                    dispatch(addMatchFail('Unknown Error Occured'));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(addMatchFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(addMatchFail(err.message || 'Something went wrong'));
                }
            });
    };
}

/**
 * update match api request
 * @param {*} match match details
 * @param {string} token user token
 */
export function updateMatch(match, token, clientId) {
    return (dispatch) => {
        dispatch(updateMatchRequest());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token, clientid: clientId},
            url: `${URL}/match/update`,
            data: match,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(updateMatchSuccess(data));
                } else if (data.data.status === 'fail') {
                    dispatch(updateMatchFail(data.data.errorData.errorReason));
                } else {
                    dispatch(updateMatchFail('Unknown Error Occured'));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(updateMatchFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(updateMatchFail(err.message || 'Something went wrong'));
                }
            });
    };
}

/**
 * update match api request
 * @param {*} data match details
 * @param {string} token user token
 */
export function rescheduleMatch(data, token, clientId) {
    return (dispatch) => {
        dispatch(rescheduleMatchRequest());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token, clientid: clientId},
            url: `${URL}/match/reschedule`,
            data: data,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(rescheduleMatchSuccess(data));
                } else if (data.data.status === 'fail') {
                    dispatch(rescheduleMatchFail(data.data.errorData.errorReason));
                } else {
                    dispatch(rescheduleMatchFail('Unknown Error Occured'));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(rescheduleMatchFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(rescheduleMatchFail(err.message || 'Something went wrong'));
                }
            });
    };
}

/**
 * delete match api request
 * @param {*} match match details
 * @param {string} token user token
 */
export function deleteMatch(match, token, clientId) {
    return (dispatch) => {
        dispatch(deleteMatchRequest());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token, clientid: clientId},
            url: `${URL}/match/delete`,
            data: match,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(deleteMatchSuccess(data));
                } else if (data.data.status === 'fail') {
                    dispatch(deleteMatchFail(data.data.errorData.errorReason));
                } else {
                    dispatch(deleteMatchFail('Unknown Error Occured'));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(deleteMatchFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(deleteMatchFail(err.message || 'Something went wrong'));
                }
            });
    };
}

/**
 * get single match details api request
 * @param {string} id match id
 * @param {string} token user token
 */
export function getMatch(id, token, clientId) {
    return (dispatch) => {
        dispatch(getMatchRequest());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token, clientid: clientId},
            url: `${URL}/match/get`,
            data: {
                matchId: id,
            },
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(getMatchSuccess(data));
                } else if (data.data.status === 'fail') {
                    dispatch(getMatchFail(data.data.errorData.errorReason));
                } else {
                    dispatch(getMatchFail('Unknown Error Occured'));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(getMatchFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(getMatchFail(err.message || 'Something went wrong'));
                }
            });
    };
}

/**
 * publish match api request
 * @param {string} id match id
 * @param {string} token user token
 */
export function publishMatch(id, token, clientId) {
    return (dispatch) => {
        dispatch(publishMatchRequest());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token, clientid: clientId},
            url: `${URL}/match/publish`,
            data: {
                matchId: id,
            },
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(publishMatchSuccess(data));
                } else if (data.data.status === 'fail') {
                    dispatch(publishMatchFail(data.data.errorData.errorReason));
                } else {
                    dispatch(publishMatchFail('Unknown Error Occured'));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(publishMatchFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(publishMatchFail(err.message || 'Something went wrong'));
                }
            });
    };
}

export function getMatchDetail(id, token, clientId) {
    return (dispatch) => {
        dispatch({type: GET_MATCH_DETAIL_INIT});
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token, clientid: clientId},
            url: `${URL}/getMatch`,
            data: {
                matchId: id,
            },
        })
            .then((response) => {
                if (response.data.status === 'success') {
                    dispatch({type: GET_MATCH_DETAIL_SUCCESS, payload: response.data.data});
                } else if (response.data.status === 'fail') {
                    dispatch({type: GET_MATCH_DETAIL_FAIL, payload: response.data.errorData.errorReason});
                } else {
                    dispatch({type: GET_MATCH_DETAIL_FAIL, payload: SOMETHING_WENT_WRONG});
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch({type: GET_MATCH_DETAIL_FAIL, payload: errorMsg || SOMETHING_WENT_WRONG});
                } else {
                    dispatch({type: GET_MATCH_DETAIL_FAIL, payload: err.message || SOMETHING_WENT_WRONG});
                }
            });
    };
}

export function markMatchLive(matchId, token, clientId) {
    return (dispatch) => {
        dispatch({type: MARK_LIVE_MATCH_INIT});
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token, clientid: clientId},
            url: `${URL}/fantasy/match/markLive/${matchId}`,
        })
            .then((response) => {
                if (response.data.status === 'success') {
                    dispatch({type: MARK_LIVE_MATCH_SUCCESS, payload: response.data.data});
                } else if (response.data.status === 'fail') {
                    dispatch({type: MARK_LIVE_MATCH_FAIL, payload: response.data.errorData.errorReason});
                } else {
                    dispatch({type: MARK_LIVE_MATCH_FAIL, payload: SOMETHING_WENT_WRONG});
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch({type: MARK_LIVE_MATCH_FAIL, payload: errorMsg || SOMETHING_WENT_WRONG});
                } else {
                    dispatch({type: MARK_LIVE_MATCH_FAIL, payload: err.message || SOMETHING_WENT_WRONG});
                }
            });
    };
}

export function cancelMatch(matchId, notification, token, clientId) {
    return (dispatch) => {
        dispatch({type: MARK_MATCH_CANCEL_INIT});
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token, clientid: clientId},
            url: `${baseUrl}/admin/match/cancelLive`,
            data: {
                matchId: matchId,
                reason: notification,
            },
        })
            .then((response) => {
                if (response.data.status === 'success') {
                    dispatch({type: MARK_MATCH_CANCEL_SUCCESS, payload: response.data.data});
                } else if (response.data.status === 'fail') {
                    dispatch({type: MARK_MATCH_CANCEL_FAIL, payload: response.data.errorData.errorReason});
                } else {
                    dispatch({type: MARK_MATCH_CANCEL_FAIL, payload: SOMETHING_WENT_WRONG});
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch({type: MARK_MATCH_CANCEL_FAIL, payload: errorMsg || SOMETHING_WENT_WRONG});
                } else {
                    dispatch({type: MARK_MATCH_CANCEL_FAIL, payload: err.message || SOMETHING_WENT_WRONG});
                }
            });
    };
}

export function matchOfTheDay(data, token, clientId) {
    return (dispatch) => {
        dispatch({type: MARK_MATCH_OF_THE_DAY_INIT});
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token, clientid: clientId},
            url: `${baseUrl}/admin/match/matchOfTheDay`,
            data: data,
        })
            .then((response) => {
                if (response.data.status === 'success') {
                    dispatch({type: MARK_MATCH_OF_THE_DAY_SUCCESS, payload: response.data.data});
                } else if (response.data.status === 'fail') {
                    dispatch({type: MARK_MATCH_OF_THE_DAY_FAIL, payload: response.data.errorData.errorReason});
                } else {
                    dispatch({type: MARK_MATCH_OF_THE_DAY_FAIL, payload: SOMETHING_WENT_WRONG});
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch({type: MARK_MATCH_OF_THE_DAY_FAIL, payload: errorMsg || SOMETHING_WENT_WRONG});
                } else {
                    dispatch({type: MARK_MATCH_OF_THE_DAY_FAIL, payload: err.message || SOMETHING_WENT_WRONG});
                }
            });
    };
}

export const updateMatchStatusLive = (matchId, token, clientId) => (dispatch) => {
    dispatch({type: UPDATE_STATUS_LIVE_NOW});
    axios({
        method: 'post',
        headers: {Authorization: 'Bearer ' + token, clientid: clientId},
        url: `${URL}/fantasy/match/markEndedToLive/${matchId}`,
    })
        .then((response) => {
            if (response.data.status === 'success') {
                dispatch({type: UPDATE_STATUS_LIVE_NOW_SUCCESS, payload: response.data.data});
            } else if (response.data.status === 'fail') {
                dispatch({type: UPDATE_STATUS_LIVE_NOW_FAIL, payload: response.data.errorData.errorReason});
            } else {
                dispatch({type: UPDATE_STATUS_LIVE_NOW_FAIL, payload: SOMETHING_WENT_WRONG});
            }
        })
        .catch((err) => {
            if (err.response) {
                const errorMsg = err?.response?.data?.msg || err.message;
                dispatch({type: UPDATE_STATUS_LIVE_NOW_FAIL, payload: errorMsg || 'Something went wrong'});
            } else {
                dispatch({type: UPDATE_STATUS_LIVE_NOW_FAIL, payload: err.message || 'Something went wrong'});
            }
        });
};

// Update match tag
/**
 * @param {*} data match details
 * @param {string} token user token
 */
export const updateMatchTag = (data, token, clientId) => (dispatch) => {
    dispatch({type: UPDATE_MATCH_TAG});
    axios({
        method: 'post',
        headers: {Authorization: 'Bearer ' + token, clientid: clientId},
        url: `${URL}/match/updateMatchTag`,
        data: data,
    })
        .then((res) => {
            if (res.data.status === 'success') {
                dispatch({type: UPDATE_MATCH_TAG_SUCCESS, payload: res.data});
            } else if (res.data.status === 'fail') {
                dispatch({type: UPDATE_MATCH_TAG_FAIL, payload: res.data.errorData.errorReason});
            }
        })
        .catch((err) => {
            if (err.response) {
                const errorMsg = err?.response?.data?.msg || err.message;
                dispatch({type: UPDATE_MATCH_TAG_FAIL, payload: errorMsg || 'Something went wrong'});
            } else {
                dispatch({type: UPDATE_MATCH_TAG_FAIL, payload: err.message || 'Something went wrong'});
            }
        });
};

export const comingSoonMatch = (data, token, clientId) => (dispatch) => {
    dispatch({type: COMING_SOON_MATCH});
    axios({
        method: 'post',
        headers: {Authorization: 'Bearer ' + token, clientid: clientId},
        url: `${URL}/match/markComingSoon`,
        data: data,
    })
        .then((res) => {
            if (res.data.status === 'success') {
                dispatch({type: COMING_SOON_MATCH_SUCCESS, payload: res.data});
            } else if (res.data.status === 'fail') {
                dispatch({type: COMING_SOON_MATCH_FAIL, payload: res.data.errorData.errorReason});
            }
        })
        .catch((err) => {
            if (err.response) {
                const errorMsg = err?.response?.data?.msg || err.message;
                dispatch({type: COMING_SOON_MATCH_FAIL, payload: errorMsg || 'Something went wrong'});
            } else {
                dispatch({type: COMING_SOON_MATCH_FAIL, payload: err.message || 'Something went wrong'});
            }
        });
};

export const updateMatchType = (data, token, clientId) => async (dispatch) => {
    dispatch({type: UPDATE_MATCH_TYPE});
    try {
        const response = await apis.updateMatchType(data, token, clientId);
        if (response.status === 200) {
            dispatch({type: UPDATE_MATCH_TYPE_SUCCESS, payload: response.data});
        } else {
            dispatch({type: UPDATE_MATCH_TYPE_FAIL, payload: {msg: response?.data?.msg || 'Something went wrong'}});
        }
    } catch (e) {
        if (e.response) {
            dispatch({type: UPDATE_MATCH_TYPE_FAIL, payload: {msg: e?.response?.data?.msg || 'Something went wrong'}});
        } else {
            dispatch({type: UPDATE_MATCH_TYPE_FAIL, payload: {msg: e?.message || 'Unknown Error Occured'}});
        }
    }
};

export const substitutePlayer = (data, token, clientId) => async (dispatch) => {
    dispatch({type: SUBSTITUTE_PLAYER});
    try {
        const response = await apis.substitutePlayer(data, token, clientId);
        if (response.status === 200) {
            dispatch({type: SUBSTITUTE_PLAYER_SUCCESS, payload: response.data});
        } else {
            dispatch({type: SUBSTITUTE_PLAYER_FAIL, payload: {msg: response?.data?.msg || 'Something went wrong'}});
        }
    } catch (e) {
        if (e.response) {
            dispatch({
                type: SUBSTITUTE_PLAYER_FAIL,
                payload: {msg: e?.response?.data?.msg || 'Something went wrong'},
            });
        } else {
            dispatch({type: SUBSTITUTE_PLAYER_FAIL, payload: {msg: e?.message || 'Unknown Error Occured'}});
        }
    }
};

export const freezeUnfreezePlayer = (data, token, clientId) => async (dispatch) => {
    dispatch({type: PLAYER_FREEZE_UNFREEZE});
    try {
        const response = await apis.playerFreezeUnfreeze(data, token, clientId);
        if (response.status === 200) {
            dispatch({type: PLAYER_FREEZE_UNFREEZE_SUCCESS, payload: response.data});
        } else {
            dispatch({
                type: PLAYER_FREEZE_UNFREEZE_FAIL,
                payload: {msg: response?.data?.msg || 'Something went wrong'},
            });
        }
    } catch (e) {
        if (e.response) {
            dispatch({
                type: PLAYER_FREEZE_UNFREEZE_FAIL,
                payload: {msg: e?.response?.data?.data?.msg || 'Something went wrong'},
            });
        } else {
            dispatch({type: PLAYER_FREEZE_UNFREEZE_FAIL, payload: {msg: e?.message || 'Unknown Error Occured'}});
        }
    }
};

export const addAndRemoveFRInMatch = (data, token, clientId) => async (dispatch) => {
    dispatch({type: MARK_MATCH_FR});
    try {
        const response = await apis.addAndRemoveFRInMatch(data, token, clientId);
        if (response.status === 200) {
            dispatch({type: MARK_MATCH_FR_SUCCESS, payload: response.data});
        } else {
            dispatch({type: MARK_MATCH_FR_FAIL, payload: {msg: response?.data?.msg || 'Something went wrong'}});
        }
    } catch (e) {
        if (e.response) {
            dispatch({
                type: MARK_MATCH_FR_FAIL,
                payload: {msg: e?.response?.data?.msg || 'Something went wrong'},
            });
        } else {
            dispatch({type: MARK_MATCH_FR_FAIL, payload: {msg: e?.message || 'Unknown Error Occured'}});
        }
    }
};

export const matchDraftToLive = (data, token, clientId) => async (dispatch) => {
    dispatch({type: MATCH_DRAFT_TO_LIVE});
    try {
        const response = await apis.matchDraftToLive(data, token, clientId);
        if (response.status === 200) {
            dispatch({type: MATCH_DRAFT_TO_LIVE_SUCCESS, payload: response.data});
        } else {
            dispatch({type: MATCH_DRAFT_TO_LIVE_FAIL, payload: {msg: response?.data?.msg || 'Something went wrong'}});
        }
    } catch (e) {
        if (e.response) {
            dispatch({
                type: MATCH_DRAFT_TO_LIVE_FAIL,
                payload: {msg: e?.response?.data?.msg || 'Something went wrong'},
            });
        } else {
            dispatch({type: MATCH_DRAFT_TO_LIVE_FAIL, payload: {msg: e?.message || 'Unknown Error Occured'}});
        }
    }
};

export function fetchPlayerStatsForMatch(_data, token, clientId) {
    return (dispatch) => {
        dispatch(updatePlayerStatsRequest());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token, clientid: clientId},
            url: `${URL}/match/syncPlayerStats`,
            data: _data,
        })
            .then((res) => {
                if (res.data.status === 'success') {
                    dispatch(updatePlayerStatsSuccess(res));
                } else if (res.data.status === 'fail') {
                    dispatch(updatePlayerStatsFail(res.data.errorData.errorReason));
                } else {
                    dispatch(updatePlayerStatsFail('Unknown Error Occured'));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.data?.msg || err.message;
                    dispatch(updatePlayerStatsFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(updatePlayerStatsFail(err.message || 'Something went wrong'));
                }
            });
    };
}
