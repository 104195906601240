import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import '../Input/Input.css';
import './Dropdown.css';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    icon: {
        fill: '#ffffff',
    },
}));

export default function Dropdown(props) {
    const classes = useStyles();

    const handleChange = (event) => {
        props.onChange(event.target.value);
    };

    if (!props.menuItems) {
        return;
    }

    const menuItems = props.menuItems.map((menu, index) => {
        return (
            <MenuItem key={index + 1} value={menu.value}>
                {menu.text}
            </MenuItem>
        );
    });
    const {label, value, placeholder, id, defaultValue, disabled, error, required, className} = props;
    let shrinkProperty = value !== '' && value !== undefined && value !== null;

    return (
        <div className={`margin-0 ${className}`}>
            <FormControl className={classes.formControl} id={id}>
                <InputLabel shrink={shrinkProperty === true ? true : undefined} id="demo-simple-select-label">
                    {label}
                </InputLabel>
                <Select
                    error={error}
                    required={!!required}
                    labelId="demo-simple-select-label"
                    id={id}
                    value={value}
                    disabled={disabled}
                    defaultValue={defaultValue}
                    placeholder={placeholder}
                    onChange={handleChange}
                    inputProps={{
                        classes: {
                            icon: classes.icon,
                        },
                    }}
                >
                    {menuItems}
                </Select>
            </FormControl>
        </div>
    );
}
