import React, {useState, useRef, useEffect} from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';
import Button from '../../../../Components/Button/Button';
import DropDown from '../../../../Components/Dropdown/Dropdown';
import {getSquadById} from '../../Squads/SquadAxios';
import {useDispatch, useSelector} from 'react-redux';
import {usePrevious} from '../../../../Utilities/hooks';
import Snackbar from '../../../../Components/Snackbar/Snackbar';
import LoadingModal from '../../../../Components/LoadingModal/LoadingModal';
import {substitutePlayer} from '../AxioCalls';

const SubstitutePlayerInMatchModal = (props) => {
    const {substitutePlayers, open, handleClose} = props;
    const {matchId, squadId, selectedPlayers} = substitutePlayers;
    const [state, setState] = useState({});
    const {errorMessage, loading, player, replacedWith} = state;
    const [activePlayers, setActivePlayers] = useState([]);
    const [players, setPlayers] = useState([]);
    const submitRef = useRef();
    const dispatch = useDispatch();
    const login = useSelector((state) => state.login);
    const squad = useSelector((state) => state.squad);
    const client = useSelector((state) => state.client);
    const prevSquad = usePrevious(squad);

    useEffect(() => {
        const activePlayersOptions = selectedPlayers.map((player) => ({value: player.id, text: player.pubgName}));
        setActivePlayers(activePlayersOptions);

        dispatch(getSquadById({squad: {id: squadId}}, login?.userDetails?.data?.accessToken));
    }, []);

    useEffect(() => {
        if (squad.isGetSquadById && !prevSquad.isGetSquadById) {
            onChange({loading: true});
        }
        if (squad.isGetSquadByIdSuccess && !prevSquad.isGetSquadByIdSuccess) {
            onChange({loading: false});
            const _players = (squad?.squadDetail?.data?.players || [])
                .filter((player) => player.status === 'ACTIVE')
                .map((player) => ({value: player.id, text: player.pubgName}));
            setPlayers(_players);
        }
        if (squad.isGetSquadByIdFail && !prevSquad.isGetSquadByIdFail) {
            onChange({errorMessage: squad.errorMessage, loading: false});
        }
    }, [squad]);

    const onChange = (obj = {}) => {
        setState({...state, ...obj});
    };

    const handleSubmit = (event) => {
        !!event && event.preventDefault();
        const playerAlreadyInMatch = activePlayers.filter((player) => player.value === replacedWith)[0];
        if (playerAlreadyInMatch) {
            return onChange({errorMessage: 'Player already in match'});
        }
        let data = {substitutedPlayers: [{player, replacedWith}], matchId};
        dispatch(substitutePlayer(data, login?.userDetails?.data?.accessToken, client.selectedClient));
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            scroll="paper"
            maxWidth="sm"
            fullWidth
            disableEscapeKeyDown
            disableBackdropClick
        >
            {!!errorMessage && (
                <Snackbar open={!!errorMessage} message={errorMessage} onClose={() => onChange({errorMessage: ''})} />
            )}
            {!!loading && <LoadingModal open={loading} />}
            <DialogTitle>Substitute players in match</DialogTitle>
            <DialogContent dividers={true}>
                <form onSubmit={handleSubmit}>
                    <DropDown
                        menuItems={activePlayers}
                        label="Replaced Player"
                        value={player || ''}
                        placeholder="select"
                        onChange={(value) => onChange({player: value})}
                    />

                    <DropDown
                        menuItems={players}
                        label="Replaced With Player"
                        value={replacedWith || ''}
                        placeholder="select"
                        className="mt-15"
                        onChange={(value) => onChange({replacedWith: value})}
                    />

                    <Button className="d-none" buttonText="Submit" type="submit" buttonRef={submitRef} />
                </form>
            </DialogContent>
            <DialogActions>
                <Button buttonText="Cancel" onClick={handleClose} />
                <Button buttonText="Submit" onClick={() => submitRef.current.click()} />
            </DialogActions>
        </Dialog>
    );
};

export default SubstitutePlayerInMatchModal;
