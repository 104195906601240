export const TOMATCH_LIST = 'TOMATCH_LIST';
export const TOMATCH_LIST_SUCCESS = 'TOMATCH_LIST_SUCCESS';
export const TOMATCH_LIST_FAIL = 'TOMATCH_LIST_FAIL';

export const ADD_TOMATCH = 'ADD_TOMATCH';
export const ADD_TOMATCH_SUCCESS = 'ADD_TOMATCH_SUCCESS';
export const ADD_TOMATCH_FAIL = 'ADD_TOMATCH_FAIL';

export const RESCHEDULE_TO_MATCH = 'RESCHEDULE_TO_MATCH';
export const RESCHEDULE_TO_MATCH_SUCCESS = 'RESCHEDULE_TO_MATCH_SUCCESS';
export const RESCHEDULE_TO_MATCH_FAIL = 'RESCHEDULE_TO_MATCH_FAIL';

export const UPDATE_TOMATCH = 'UPDATE_TOMATCH';
export const UPDATE_TOMATCH_SUCCESS = 'UPDATE_TOMATCH_SUCCESS';
export const UPDATE_TOMATCH_FAIL = 'UPDATE_TOMATCH_FAIL';

export const DELETE_TOMATCH = 'DELETE_TOMATCH';
export const DELETE_TOMATCH_SUCCESS = 'DELETE_TOMATCH_SUCCESS';
export const DELETE_TOMATCH_FAIL = 'DELETE_TOMATCH_FAIL';

export const GET_TOMATCH = 'GET_TOMATCH';
export const GET_TOMATCH_SUCCESS = 'GET_TOMATCH_SUCCESS';
export const GET_TOMATCH_FAIL = 'GET_TOMATCH_FAIL';

export const EDIT_TOMATCH = 'EDIT_TOMATCH';
