import React from 'react';
import {connect} from 'react-redux';

import '../../../Utilities/style.css';
import '../Login/login.css';
import moment from 'moment';
import Table from '../../../Components/Table/Table';
import TableLayout from '../../Layouts/TableLayout/TableLayout';
import {getMatchList} from '../Matches/AxioCalls';
import {verifyMatchDetails} from '../RecordMatch/action';
import {logout} from '../Login/LoginAction';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';
import Snackbar from '../../../Components/Snackbar/Snackbar';
import {
    debounce,
    getFiltersSessionStorageItem,
    next,
    prev,
    setFiltersSessionStorageItem,
} from '../../../Utilities/HelperFunctions';
import FilterInput from '../../../Components/FilterInput';
import DropDown from '../../../Components/Dropdown/Dropdown';
import Button from '../../../Components/Button/Button';
import DateRangeSelect from '../../../Components/DateRangeSelect';
import {ENTER, MATCH_TYPES_OPTIONS, SCREEN_NAMES} from '../../../Utilities/Constants';
import {Checkbox, FormControlLabel} from '@material-ui/core';
import {timeFormat} from '../../../Utilities/Helpers';

class VerifyMatchList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: [],
            selectAll: false,
            list: [],
            pageSize: 30,
            page: 1,
            open: false,
            adminpswd: '',
            pageData: {},
            loading: false,
            snackbarOpen: false,
            errorMessage: '',
            pageOptions: {
                page: 1,
                pageSize: 25,
                listType: 'LIVE',
                ...getFiltersSessionStorageItem(SCREEN_NAMES.VERIFY_MATCHES),
            },
            sortOptions: [
                {value: 'id', text: 'Match Id'},
                {value: 'tournamentName', text: 'Tournament Name'},
                {value: 'matchName', text: 'Match Name'},
                {value: 'startTime', text: 'Start Time'},
                {value: 'mapName', text: 'Map Name'},
                {text: 'Status', value: 'status'},
            ],
        };
    }

    componentWillMount() {
        const {history, login} = this.props;

        if (!login?.userDetails?.data) {
            history.push('/');
            this.props.logout();
        } else {
            this.getMatchListDebounce({immediate: true});
        }
    }

    /**
     * select a particular match to verify
     * @param {*} id Api call to verify an event
     */
    verifyMatchData(id) {
        const {match, history} = this.props;
        let verifyMatch = match.matchList.data.data.filter((match) => match.id === id);
        this.props.verifyMatchDetails(verifyMatch[0]);
        history.push('/verify');
    }

    componentDidUpdate(prevProps) {
        const {match} = this.props;
        if (match.matchListRequest && !prevProps.match.matchListRequest) {
            this.setState({loading: true});
        } else if (match.matchListSuccess && !prevProps.match.matchListSuccess) {
            this.setState({
                list: match.matchList.data.data,
                pageData: match.matchList.data.pageData,
                loading: false,
            });
        } else if (match.matchListFail && !prevProps.match.matchListFail) {
            this.setState({
                snackbarOpen: true,
                errorMessage: match.errorMessage,
                loading: false,
            });
        }
    }

    /**
     * reseting search and sort
     */
    onReset = () => {
        this.setState({pageOptions: {page: 1, pageSize: 25, listType: 'LIVE'}}, () => {
            this.getMatchListDebounce({immediate: true});
        });
    };

    getMatchListDebounce = debounce(() => {
        const {login, client} = this.props;
        setFiltersSessionStorageItem(SCREEN_NAMES.VERIFY_MATCHES, this.state.pageOptions);
        this.props.getMatchList(this.state.pageOptions, login.userDetails.data.accessToken, client.selectedClient);
    });

    handleFilter = (field, value, debounce = true) => {
        const {pageOptions} = this.state;
        if (field === ENTER) {
            this.getMatchListDebounce({immediate: true});
            return;
        }
        if (field === 'dates') {
            pageOptions.startDate = value.start;
            pageOptions.endDate = value.end;
        } else {
            pageOptions[field] = value;
        }
        pageOptions[field] = value;
        this.setState({pageOptions: {...pageOptions, page: 1}}, () => {
            this.getMatchListDebounce(debounce ? {} : {immediate: true});
        });
    };

    /**
     * pagination and api call on  prev click
     */
    onPrev() {
        const {pageOptions} = this.state;
        let options = prev(pageOptions);
        if (options) {
            this.setState({pageOptions: options}, () => this.getMatchListDebounce({immediate: true}));
        }
    }

    /**
     * pagination and api call on next click
     */
    onNext() {
        const {pageData, pageOptions} = this.state;
        let options = next(pageOptions, pageData);
        if (options) {
            this.setState({pageOptions: options}, () => this.getMatchListDebounce({immediate: true}));
        }
    }

    render() {
        const {list, snackbarOpen, loading, errorMessage, pageData, sortOptions} = this.state;
        const {varifyMatchMapName, verifyMatchCreatedFrom, verifyMatchMatchType} = this.props.login.permissions || {};
        const data = list;
        const {
            matchName,
            matchId,
            tournamentName,
            tournamentId,
            sortOrder,
            page,
            gameName,
            startDate,
            endDate,
            matchType,
            isMatchOfTheDay,
            isAllSuperMatch,
        } = this.state.pageOptions;
        let disableNext = page === pageData.lastPage;

        const columns = [
            {
                Header: 'Match ID',
                accessor: 'id',
                width: 200,
                Cell: (props) => (
                    <span className="table-clickable" onClick={this.verifyMatchData.bind(this, props.value)}>
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Tournament Name',
                accessor: 'tournamentName',
                width: 150,
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Match Name',
                accessor: 'matchName',
                width: 200,
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Squad Names',
                accessor: 'squadDataList',
                width: 200,
                Cell: (props) => (
                    <span className="number">
                        {!!props.value && !!props.value.length
                            ? props.value.map((squad) => squad.squadName).join(', ')
                            : '-'}
                    </span>
                ),
            },
            {
                Header: 'Start Time',
                accessor: 'startTime',
                Cell: (props) => <span className="date-space">{timeFormat(props.value)}</span>,
            },
            {
                Header: 'Created from',
                accessor: 'createdByGlacier',
                show: !!verifyMatchCreatedFrom,
                Cell: (props) => <span>{props.value ? 'Glacier' : 'Control-Center'}</span>,
            },
            {
                Header: 'Map Name',
                accessor: 'mapName',
                show: !!varifyMatchMapName,
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: '# Players',
                accessor: 'players',
                Cell: (props) => (
                    <span className="number">{props.value && props.value.length ? props.value.length : '-'}</span>
                ),
            },
            {
                Header: '# squads',
                accessor: 'squads',
                Cell: (props) => (
                    <span className="number">{props.value && props.value.length ? props.value.length : '-'}</span>
                ),
            },
            {
                Header: 'Teams',
                accessor: 'fcTeamsCount',
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Contests',
                accessor: 'contestsCount',
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Total Pool',
                accessor: 'totalPoolAmount ',
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Match Type',
                accessor: 'matchType',
                show: !!verifyMatchMatchType,
                Cell: (props) => <span>{props.value || ''}</span>,
            },
        ];
        return (
            <TableLayout hideSearchBar location="verify" history={this.props.history}>
                <LoadingModal open={loading} />
                <Snackbar
                    open={snackbarOpen}
                    message={errorMessage}
                    onClose={() => this.setState({snackbarOpen: false})}
                />

                <div className="filter-container my-10">
                    <FilterInput
                        placeholder="Match Name"
                        value={matchName || ''}
                        onChange={(value) => this.handleFilter('matchName', value)}
                        onEnter={() => this.handleFilter(ENTER)}
                    />
                    <FilterInput
                        placeholder="Match Id"
                        value={matchId || ''}
                        onChange={(value) => this.handleFilter('matchId', value)}
                        onEnter={() => this.handleFilter(ENTER)}
                    />
                    <FilterInput
                        placeholder="Tournament Name"
                        value={tournamentName || ''}
                        onChange={(value) => this.handleFilter('tournamentName', value)}
                        onEnter={() => this.handleFilter(ENTER)}
                    />
                    <FilterInput
                        placeholder="Tournament Id"
                        value={tournamentId || ''}
                        onChange={(value) => this.handleFilter('tournamentId', value)}
                        onEnter={() => this.handleFilter(ENTER)}
                    />
                    <FilterInput
                        placeholder="Game Name"
                        value={gameName || ''}
                        onChange={(value) => this.handleFilter('gameName', value)}
                        onEnter={() => this.handleFilter(ENTER)}
                    />
                    <DropDown
                        label="Match Type"
                        value={matchType || ''}
                        menuItems={MATCH_TYPES_OPTIONS}
                        onChange={(value) => this.handleFilter('matchType', value, false)}
                    />
                    <DropDown
                        label="Sort By"
                        value={sortOrder || ''}
                        menuItems={sortOptions}
                        onChange={(value) => this.handleFilter('sortOrder', value, false)}
                    />
                    <DateRangeSelect
                        startDate={startDate}
                        endDate={endDate}
                        isClearable={true}
                        onChange={(dates) => this.handleFilter('dates', dates, false)}
                    />
                    <FormControlLabel
                        label="MOTD Matches"
                        control={
                            <Checkbox
                                checked={!!isMatchOfTheDay}
                                onChange={(e) => this.handleFilter('isMatchOfTheDay', e.target.checked, false)}
                            />
                        }
                    />
                    <FormControlLabel
                        label="FR Matches"
                        control={
                            <Checkbox
                                checked={!!isAllSuperMatch}
                                onChange={(e) => this.handleFilter('isAllSuperMatch', e.target.checked, false)}
                            />
                        }
                    />
                    <Button buttonText="Reset" onClick={this.onReset} />
                </div>

                <Table
                    data={data}
                    columns={columns}
                    page={page}
                    onPrevClick={this.onPrev.bind(this)}
                    onNextClick={this.onNext.bind(this)}
                    disableNext={disableNext}
                ></Table>
            </TableLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        squad: state.squad,
        match: state.match,
        recordMatch: state.recordMatch,
        client: state.client,
    };
};

export default connect(mapStateToProps, {getMatchList, verifyMatchDetails, logout})(VerifyMatchList);
