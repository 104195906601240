import React from 'react';
import './ImageInput.css';

class ImageInput extends React.Component {
    constructor(props) {
        super(props);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(a) {
        let file = a.target.files[0];
        this.props.onChange(file);
    }

    render() {
        const {required = false, label, type, name, uploadType} = this.props;

        if (uploadType === 'button') {
            return (
                <label className="button btn-files">
                    {label}
                    <input
                        type="file"
                        accept="image/*"
                        required={required}
                        name={name}
                        onChange={this.handleInputChange}
                    />
                </label>
            );
        }

        return (
            <div className="files">
                <label>{label}</label>
                <input type={type} accept="image/*" required={required} name={name} onChange={this.handleInputChange} />
            </div>
        );
    }
}

export default ImageInput;
