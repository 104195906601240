import {
    TOMATCH_LIST,
    TOMATCH_LIST_SUCCESS,
    TOMATCH_LIST_FAIL,
    GET_TOMATCH_PARTICIPATION,
    GET_TOMATCH_PARTICIPATION_SUCCESS,
    GET_TOMATCH_PARTICIPATION_FAIL,
    ARCHIVE_TOMATCH,
    ARCHIVE_TOMATCH_SUCCESS,
    ARCHIVE_TOMATCH_FAIL,
    GET_TOMATCH,
    GET_TOMATCH_SUCCESS,
    GET_TOMATCH_FAIL,
    EDIT_TOMATCH,
} from './Constants';

//lst all TOMATCH
export function listTOMatches() {
    return {
        type: TOMATCH_LIST,
    };
}

export function listTOMatchesSuccess(data) {
    return {
        type: TOMATCH_LIST_SUCCESS,
        payload: data,
    };
}

export function listTOMatchesFail(error) {
    return {
        type: TOMATCH_LIST_FAIL,
        payload: error,
    };
}

//get participation for TOMATCH
export function getTOMatchParticipationRequest() {
    return {
        type: GET_TOMATCH_PARTICIPATION,
    };
}

export function getTOMatchParticipationSuccess(data) {
    return {
        type: GET_TOMATCH_PARTICIPATION_SUCCESS,
        payload: data,
    };
}

export function getTOMatchParticipationFail(error) {
    return {
        type: GET_TOMATCH_PARTICIPATION_FAIL,
        payload: error,
    };
}

// archive TOMATCH

export function archiveTOMatchRequest() {
    return {
        type: ARCHIVE_TOMATCH,
    };
}

export function archiveTOMatchSuccess(data) {
    return {
        type: ARCHIVE_TOMATCH_SUCCESS,
        payload: data,
    };
}

export function archiveTOMatchFail(error) {
    return {
        type: ARCHIVE_TOMATCH_FAIL,
        payload: error,
    };
}

//get single TOMATCH
export function getTOMatchRequest() {
    return {
        type: GET_TOMATCH,
    };
}

export function getTOMatchSuccess(data) {
    return {
        type: GET_TOMATCH_SUCCESS,
        payload: data,
    };
}

export function getTOMatchFail(error) {
    return {
        type: GET_TOMATCH_FAIL,
        payload: error,
    };
}

// single match Details
export function editTOMatch(match) {
    return {
        type: EDIT_TOMATCH,
        payload: match,
    };
}
