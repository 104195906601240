import React from 'react';
import '../../../Utilities/style.css';
import '../Login/login.css';
import {connect} from 'react-redux';
import DetailLayout from '../../Layouts/DetailLayout/DetailLayout';
import Card from '../../../Components/Card/Card';
import Input from '../../../Components/Input/Input';
import DropDown from '../../../Components/Dropdown/Dropdown';
import {createTOMatchMode, updateTOMatchMode, deleteTOMatchMode} from './AxioCalls';
import {getTOGameList} from '../TOGame/AxioCalls';
import Snackbar from '../../../Components/Snackbar/Snackbar';
import {logout} from '../Login/LoginAction';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';
import {isValidNumber} from '../../../Utilities/Helpers';

class AddTOMatchMode extends React.Component {
    constructor(props) {
        super(props);
        let location = this.props.history.location.pathname.includes('edit');
        this.state = {
            gameOptions: [],
            modeName: null,
            sizeOfTeam: null,
            inviteSlots: null,
            game: null,
            loading: false,
            open: false,
            apiError: '',
            edit: location,
            snackbarOpen: false,
            maxPlayersPerSquad: 2,
            maxFantasyTeamSize: 6,
            numberOfCredits: 50,
        };
    }

    handleInputChange(field, value) {
        this.setState({
            [field]: value,
            snackbarOpen: false,
            errorMessage: '',
        });
    }

    onSave() {
        const {modeName, sizeOfTeam, inviteSlots, game, numberOfCredits, maxFantasyTeamSize, maxPlayersPerSquad} =
            this.state;
        const {login} = this.props;

        if (modeName === null || modeName === '' || modeName === undefined) {
            this.setState({
                snackbarOpen: true,
                errorMessage: 'please enter mode name',
            });
        } else if (
            sizeOfTeam === null ||
            sizeOfTeam === undefined ||
            sizeOfTeam === '' ||
            sizeOfTeam === 0 ||
            !isValidNumber(sizeOfTeam)
        ) {
            this.setState({
                snackbarOpen: true,
                errorMessage: 'please enter valid Team Size',
            });
        } else if (
            inviteSlots === null ||
            inviteSlots === undefined ||
            inviteSlots === '' ||
            !isValidNumber(inviteSlots)
        ) {
            this.setState({
                snackbarOpen: true,
                errorMessage: 'please enter valid Invite Slots',
            });
        } else if (game === null || game === undefined || game === '') {
            this.setState({
                snackbarOpen: true,
                errorMessage: 'please select valid game',
            });
        } else if (
            numberOfCredits === null ||
            numberOfCredits === undefined ||
            numberOfCredits === '' ||
            !isValidNumber(numberOfCredits)
        ) {
            this.setState({
                snackbarOpen: true,
                errorMessage: 'please select valid numberOfCredits',
            });
        } else if (
            maxPlayersPerSquad === null ||
            maxPlayersPerSquad === undefined ||
            maxPlayersPerSquad === '' ||
            !isValidNumber(maxPlayersPerSquad)
        ) {
            this.setState({
                snackbarOpen: true,
                errorMessage: 'please select valid maxPlayersPerSquad',
            });
        } else if (
            maxFantasyTeamSize === null ||
            maxFantasyTeamSize === undefined ||
            maxFantasyTeamSize === '' ||
            !isValidNumber(maxFantasyTeamSize)
        ) {
            this.setState({
                snackbarOpen: true,
                errorMessage: 'please select valid maxFantasyTeamSize',
            });
        } else {
            let matchMode = {
                modeName: modeName,
                sizeOfTeam: sizeOfTeam,
                inviteSlots: inviteSlots,
                maxPlayersPerSquad: maxPlayersPerSquad,
                maxFantasyTeamSize: maxFantasyTeamSize,
                numberOfCredits: numberOfCredits,
                game: game,
            };
            this.setState({loading: true});
            this.props.createTOMatchMode(matchMode, login.userDetails.data.accessToken);
        }
    }

    componentDidMount() {
        const {login, history, client} = this.props;
        let gamePageOptions = {
            listType: 'ALL',
        };
        console.log(this.props);
        if (!login.userDetails.data) {
            history.push('/');
            this.props.logout();
        } else {
            this.setState({
                loading: true,
            });
            this.props.getTOGameList(gamePageOptions, login.userDetails.data.accessToken, client.selectedClient);
        }
    }

    componentDidUpdate(prevProps) {
        const {toMatchMode, toGame, history} = this.props;

        if (toGame.TOGameListSuccess && !prevProps.toGame.TOGameListSuccess) {
            this.setState({
                gameOptions: toGame.TOGameList.data.data.map((item) => {
                    return {text: item.gameName, value: item.id};
                }),
                loading: false,
            });
        } else if (toGame.TOGameListFail && !prevProps.toGame.TOGameListFail) {
            this.setState({
                errorMessage: toGame.errorMessage,
                loading: false,
            });
        }

        if (toMatchMode.addTOMatchModeSuccess && !prevProps.toMatchMode.addTOMatchModeSuccess) {
            history.push('/toMatchModes');
        } else if (toMatchMode.addTOMatchModeFail && !prevProps.toMatchMode.addTOMatchModeFail) {
            this.setState({
                loading: false,
                snackbarOpen: true,
                errorMessage: toMatchMode.errorMessage,
            });
        }
    }

    render() {
        const {
            modeName,
            sizeOfTeam,
            inviteSlots,
            game,
            errorMessage,
            loading,
            open,
            edit,
            snackbarOpen,
            gameOptions,
            maxFantasyTeamSize,
            maxPlayersPerSquad,
            numberOfCredits,
        } = this.state;
        const {history, toMatchMode, login} = this.props;
        let title = 'Add  MatchMode';
        let shrink = undefined;
        const {toMatchModeWrite} = login?.permissions || {};
        return (
            <DetailLayout
                location="AddTOMatchMode"
                history={history}
                centerTitle={title}
                onSave={!!toMatchModeWrite ? this.onSave.bind(this) : undefined}
                editPage={edit}
                id={edit ? toMatchMode.editTOMatchMode.id : null}
            >
                <LoadingModal open={loading} />
                <Snackbar
                    open={snackbarOpen}
                    message={errorMessage}
                    onClose={() => this.setState({snackbarOpen: false})}
                />
                <div className="detail-container margin-top-20">
                    <div className="grid-item"></div>

                    <div className="grid-item">
                        <Card>
                            <div>
                                <div className="card-grid-container">
                                    {/* row 1 */}
                                    <div className="grid-item  margin-top-20">
                                        <Input
                                            label="Mode Name"
                                            type="text"
                                            name="modeName"
                                            value={modeName}
                                            onChange={this.handleInputChange.bind(this, 'modeName')}
                                            placeholder="Enter Mode Name"
                                            disabled={false}
                                        />
                                    </div>

                                    <div className="grid-item margin-top-20 align-right"></div>

                                    {/* row 2 */}
                                    <div className="grid-item  margin-top-20">
                                        <Input
                                            label="Size Of Team"
                                            type="text"
                                            name="sizeOfTeam"
                                            value={sizeOfTeam}
                                            onChange={this.handleInputChange.bind(this, 'sizeOfTeam')}
                                            placeholder="Enter Size of Team"
                                            disabled={false}
                                        />
                                        {/* <DropDown
                                            label="Discount Type"
                                            type="text"
                                            menuItems={discountTypeOptions}
                                            name="discountType"
                                            value={discountType}
                                            onChange={this.handleInputChange.bind(this, "discountType")}
                                            placeholder="Select"
                                            disabled={disabled} /> */}
                                    </div>

                                    <div className="grid-item  margin-top-20"></div>

                                    {/* row 3 */}
                                    <div className="grid-item  margin-top-20">
                                        <Input
                                            label="# of Invite Slots"
                                            type="text"
                                            name="inviteSlots"
                                            value={inviteSlots}
                                            onChange={this.handleInputChange.bind(this, 'inviteSlots')}
                                            placeholder="Invite Slots"
                                            // suffix={discountType === "PERCENT" ? "%" : undefined}
                                            disabled={false}
                                        />
                                    </div>

                                    <div className="grid-item  margin-top-20"></div>

                                    {/* row 4 */}
                                    <div className="grid-item margin-top-20">
                                        <DropDown
                                            label="Game"
                                            type="text"
                                            menuItems={gameOptions}
                                            name="game"
                                            value={game}
                                            onChange={this.handleInputChange.bind(this, 'game')}
                                            placeholder="Select"
                                            disabled={false}
                                        />
                                    </div>

                                    <div></div>

                                    <div className="grid-item  margin-top-20">
                                        <Input
                                            label="Fantasy Team Size"
                                            type="text"
                                            name="maxFantasyTeamSize"
                                            value={maxFantasyTeamSize}
                                            onChange={this.handleInputChange.bind(this, 'maxFantasyTeamSize')}
                                            placeholder="Fantasy team Size"
                                            // suffix={discountType === "PERCENT" ? "%" : undefined}
                                            disabled={false}
                                        />
                                    </div>

                                    <div className="grid-item  margin-top-20">
                                        <Input
                                            label="Fantasy Total Credits"
                                            type="text"
                                            name="numberOfCredits"
                                            value={numberOfCredits}
                                            onChange={this.handleInputChange.bind(this, 'numberOfCredits')}
                                            placeholder="Number of credits"
                                            // suffix={discountType === "PERCENT" ? "%" : undefined}
                                            disabled={false}
                                        />
                                    </div>

                                    <div className="grid-item  margin-top-20">
                                        <Input
                                            label="Max Players Per Squad"
                                            type="text"
                                            name="maxPlayersPerSquad"
                                            value={maxPlayersPerSquad}
                                            onChange={this.handleInputChange.bind(this, 'maxPlayersPerSquad')}
                                            placeholder="Max Players Per Squad"
                                            // suffix={discountType === "PERCENT" ? "%" : undefined}
                                            disabled={false}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className="grid-item"></div>
                </div>
            </DetailLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        toGame: state.toGame,
        toMatchMode: state.toMatchMode,
        client: state.client,
    };
};

export default connect(mapStateToProps, {
    getTOGameList,
    createTOMatchMode,
    logout,
    deleteTOMatchMode,
    updateTOMatchMode,
})(AddTOMatchMode);
