import {
    TOGAME_LIST,
    TOGAME_LIST_SUCCESS,
    TOGAME_LIST_FAIL,
    ADD_TOGAME,
    ADD_TOGAME_SUCCESS,
    ADD_TOGAME_FAIL,
    UPDATE_TOGAME,
    UPDATE_TOGAME_SUCCESS,
    UPDATE_TOGAME_FAIL,
    DELETE_TOGAME,
    DELETE_TOGAME_SUCCESS,
    DELETE_TOGAME_FAIL,
    GET_TOGAME,
    GET_TOGAME_SUCCESS,
    GET_TOGAME_FAIL,
    EDIT_TOGAME,
} from './Constants';

const initialState = {
    TOGameListRequest: false,
    TOGameListSuccess: false,
    TOGameListFail: false,
    TOGameList: [],
    errorMessage: '',
    addTOGameRequest: false,
    addTOGameSuccess: false,
    addTOGameFail: false,
    addTOGame: [],
    updateTOGameRequest: false,
    updateTOGameSuccess: false,
    updateTOGameFail: false,
    updateTOGame: [],
    deleteTOGameRequest: false,
    deleteTOGameSuccess: false,
    deleteTOGameFail: false,
    deleteTOGame: [],
    getTOGameRequest: false,
    getTOGameSuccess: false,
    getTOGameFail: false,
    getTOGame: [],
    editTOGame: [],
};

const toGameReducer = (state = initialState, action) => {
    switch (action.type) {
        case TOGAME_LIST:
            return {
                ...state,
                TOGameListRequest: true,
                TOGameListSuccess: false,
                TOGameListFail: false,
                TOGameList: [],
                errorMessage: '',
            };

        case TOGAME_LIST_SUCCESS:
            // console.log('Success case is being triggered');
            return {
                ...state,
                TOGameListRequest: false,
                TOGameListSuccess: true,
                TOGameListFail: false,
                TOGameList: action.payload.data,
                errorMessage: '',
            };

        case TOGAME_LIST_FAIL:
            return {
                ...state,
                TOGameListRequest: false,
                TOGameListSuccess: false,
                TOGameListFail: true,
                errorMessage: action.payload,
            };
        // ADD match
        case ADD_TOGAME:
            return {
                ...state,
                addTOGameRequest: true,
                addTOGameSuccess: false,
                addTOGameFail: false,
                addTOGame: [],
                errorMessage: '',
            };

        case ADD_TOGAME_SUCCESS:
            return {
                ...state,
                addTOGameRequest: false,
                addTOGameSuccess: true,
                addTOGameFail: false,
                addTOGame: action.payload.data,
                errorMessage: '',
            };

        case ADD_TOGAME_FAIL:
            return {
                ...state,
                addTOGameRequest: false,
                addTOGameSuccess: false,
                addTOGameFail: true,
                errorMessage: action.payload,
            };
        //update TOGame
        case UPDATE_TOGAME:
            return {
                ...state,
                updateTOGameRequest: true,
                updateTOGameSuccess: false,
                updateTOGameFail: false,
                updateTOGame: [],
                errorMessage: '',
            };

        case UPDATE_TOGAME_SUCCESS:
            return {
                ...state,
                updateTOGameRequest: false,
                updateTOGameSuccess: true,
                updateTOGameFail: false,
                updateTOGame: action.payload.data,
                errorMessage: '',
            };

        case UPDATE_TOGAME_FAIL:
            return {
                ...state,
                updateTOGameRequest: false,
                updateTOGameSuccess: false,
                updateTOGameFail: true,
                errorMessage: action.payload,
            };

        // DELETE TOGAME
        case DELETE_TOGAME:
            return {
                ...state,
                deleteTOGameRequest: true,
                deleteTOGameSuccess: false,
                deleteTOGameFail: false,
                deleteTOGame: [],
                errorMessage: '',
            };

        case DELETE_TOGAME_SUCCESS:
            return {
                ...state,
                deleteTOGameRequest: false,
                deleteTOGameSuccess: true,
                deleteTOGameFail: false,
                deleteTOGame: action.payload.data,
                errorMessage: '',
            };

        case DELETE_TOGAME_FAIL:
            return {
                ...state,
                deleteTOGameRequest: false,
                deleteTOGameSuccess: false,
                deleteTOGameFail: true,
                errorMessage: action.payload,
            };

        // get single TOGame
        case GET_TOGAME:
            return {
                ...state,
                getTOGameRequest: true,
                getTOGameSuccess: false,
                getTOGameFail: false,
                getTOGame: [],
                errorMessage: '',
            };

        case GET_TOGAME_SUCCESS:
            return {
                ...state,
                getTOGameRequest: false,
                getTOGameSuccess: true,
                getTOGameFail: false,
                getTOGame: action.payload.data,
                errorMessage: '',
            };

        case GET_TOGAME_FAIL:
            return {
                ...state,
                getTOGameRequest: false,
                getTOGameSuccess: false,
                getTOGameFail: true,
                errorMessage: action.payload,
            };
        // EDIT TOGAME
        case EDIT_TOGAME:
            return {
                ...state,
                editTOGame: action.payload,
            };

        default:
            return state;
    }
};

export default toGameReducer;
