import apis from '../../../apiCalls/apis';
import {
    GET_BOOSTERS_LIST,
    GET_BOOSTERS_LIST_SUCCESS,
    GET_BOOSTERS_LIST_FAIL,
    GET_BOOSTER,
    GET_BOOSTER_SUCCESS,
    GET_BOOSTER_FAIL,
    CREATE_BOOSTER,
    CREATE_BOOSTER_SUCCESS,
    CREATE_BOOSTER_FAIL,
    GET_BOOSTER_CONFIGS,
    GET_BOOSTER_CONFIGS_SUCCESS,
    GET_BOOSTER_CONFIGS_FAIL,
    GET_MULTIPLIER_CONFIGS,
    GET_MULTIPLIER_CONFIGS_SUCCESS,
    GET_MULTIPLIER_CONFIGS_FAIL,
    GET_BOOSTER_CONFIG,
    GET_BOOSTER_CONFIG_SUCCESS,
    GET_BOOSTER_CONFIG_FAIL,
    CREATE_BOOSTER_CONFIG,
    CREATE_BOOSTER_CONFIG_SUCCESS,
    CREATE_BOOSTER_CONFIG_FAIL,
    UPDATE_BOOSTER_CONFIG,
    UPDATE_BOOSTER_CONFIG_SUCCESS,
    UPDATE_BOOSTER_CONFIG_FAIL,
} from './constants';

export const fetchBoosters = (data, token, clientId) => async (dispatch) => {
    dispatch({type: GET_BOOSTERS_LIST});
    try {
        const response = await apis.getBoosters(data, token, clientId);
        if (response.status === 200) {
            dispatch({type: GET_BOOSTERS_LIST_SUCCESS, payload: response.data});
        } else {
            dispatch({type: GET_BOOSTERS_LIST_FAIL, payload: {msg: response?.data?.msg || 'Something went wrong'}});
        }
    } catch (e) {
        if (e.response) {
            dispatch({type: GET_BOOSTERS_LIST_FAIL, payload: {msg: e?.response?.data?.msg || 'Something went wrong'}});
        } else {
            dispatch({type: GET_BOOSTERS_LIST_FAIL, payload: {msg: e?.message || 'Unknown Error Occured'}});
        }
    }
};

export const fetchBooster = (data, token, clientId) => async (dispatch) => {
    dispatch({type: GET_BOOSTER});
    try {
        const response = await apis.getBooster(data, token, clientId);
        if (response.status === 200) {
            dispatch({type: GET_BOOSTER_SUCCESS, payload: response.data});
        } else {
            dispatch({type: GET_BOOSTER_FAIL, payload: {msg: response?.data?.msg || 'Something went wrong'}});
        }
    } catch (e) {
        if (e.response) {
            dispatch({type: GET_BOOSTER_FAIL, payload: {msg: e?.response?.data?.msg || 'Something went wrong'}});
        } else {
            dispatch({type: GET_BOOSTER_FAIL, payload: {msg: e?.message || 'Unknown Error Occured'}});
        }
    }
};

export const createBoosters = (data, token, clientId) => async (dispatch) => {
    dispatch({type: CREATE_BOOSTER});
    try {
        const response = await apis.createBooster(data, token, clientId);
        if (response.status === 200) {
            dispatch({type: CREATE_BOOSTER_SUCCESS, payload: response.data});
        } else {
            dispatch({type: CREATE_BOOSTER_FAIL, payload: {msg: response?.data?.msg || 'Something went wrong'}});
        }
    } catch (e) {
        if (e.response) {
            dispatch({type: CREATE_BOOSTER_FAIL, payload: {msg: e?.response?.data?.msg || 'Something went wrong'}});
        } else {
            dispatch({type: CREATE_BOOSTER_FAIL, payload: {msg: e?.message || 'Unknown Error Occured'}});
        }
    }
};

export const fetchBoosterConfigs = (data, token, clientId) => async (dispatch) => {
    dispatch({type: GET_BOOSTER_CONFIGS});
    try {
        const response = await apis.getBoosterConfigs(data, token, clientId);
        if (response.status === 200) {
            dispatch({type: GET_BOOSTER_CONFIGS_SUCCESS, payload: response.data});
        } else {
            dispatch({type: GET_BOOSTER_CONFIGS_FAIL, payload: {msg: response?.data?.msg || 'Something went wrong'}});
        }
    } catch (e) {
        if (e.response) {
            dispatch({
                type: GET_BOOSTER_CONFIGS_FAIL,
                payload: {msg: e?.response?.data?.msg || 'Something went wrong'},
            });
        } else {
            dispatch({type: GET_BOOSTER_CONFIGS_FAIL, payload: {msg: e?.message || 'Unknown Error Occured'}});
        }
    }
};

export const fetchMultiplierConfigs = (data, token, clientId) => async (dispatch) => {
    dispatch({type: GET_MULTIPLIER_CONFIGS});
    try {
        const response = await apis.getMultiplierConfigs(data, token, clientId);
        if (response.status === 200) {
            dispatch({type: GET_MULTIPLIER_CONFIGS_SUCCESS, payload: response.data});
        } else {
            dispatch({
                type: GET_MULTIPLIER_CONFIGS_FAIL,
                payload: {msg: response?.data?.msg || 'Something went wrong'},
            });
        }
    } catch (e) {
        if (e.response) {
            dispatch({
                type: GET_MULTIPLIER_CONFIGS_FAIL,
                payload: {msg: e?.response?.data?.msg || 'Something went wrong'},
            });
        } else {
            dispatch({type: GET_MULTIPLIER_CONFIGS_FAIL, payload: {msg: e?.message || 'Unknown Error Occured'}});
        }
    }
};

export const fetchBoosterConfig = (data, token, clientId) => async (dispatch) => {
    dispatch({type: GET_BOOSTER_CONFIG});
    try {
        const response = await apis.getBoosterConfig(data, token, clientId);
        if (response.status === 200) {
            dispatch({type: GET_BOOSTER_CONFIG_SUCCESS, payload: response.data});
        } else {
            dispatch({type: GET_BOOSTER_CONFIG_FAIL, payload: {msg: response?.data?.msg || 'Something went wrong'}});
        }
    } catch (e) {
        if (e.response) {
            dispatch({type: GET_BOOSTER_CONFIG_FAIL, payload: {msg: e?.response?.data?.msg || 'Something went wrong'}});
        } else {
            dispatch({type: GET_BOOSTER_CONFIG_FAIL, payload: {msg: e?.message || 'Unknown Error Occured'}});
        }
    }
};

export const createBoosterConfig = (data, token, clientId) => async (dispatch) => {
    dispatch({type: CREATE_BOOSTER_CONFIG});
    try {
        const response = await apis.createBoosterConfig(data, token, clientId);
        if (response.status === 200) {
            dispatch({type: CREATE_BOOSTER_CONFIG_SUCCESS, payload: response.data});
        } else {
            dispatch({type: CREATE_BOOSTER_CONFIG_FAIL, payload: {msg: response?.data?.msg || 'Something went wrong'}});
        }
    } catch (e) {
        if (e.response) {
            dispatch({
                type: CREATE_BOOSTER_CONFIG_FAIL,
                payload: {msg: e?.response?.data?.msg || 'Something went wrong'},
            });
        } else {
            dispatch({type: CREATE_BOOSTER_CONFIG_FAIL, payload: {msg: e?.message || 'Unknown Error Occured'}});
        }
    }
};

export const updateBoosterConfig = (data, token, clientId) => async (dispatch) => {
    dispatch({type: UPDATE_BOOSTER_CONFIG});
    try {
        const response = await apis.updateBoosterConfig(data, token, clientId);
        if (response.status === 200) {
            dispatch({type: UPDATE_BOOSTER_CONFIG_SUCCESS, payload: response.data});
        } else {
            dispatch({type: UPDATE_BOOSTER_CONFIG_FAIL, payload: {msg: response?.data?.msg || 'Something went wrong'}});
        }
    } catch (e) {
        if (e.response) {
            dispatch({
                type: UPDATE_BOOSTER_CONFIG_FAIL,
                payload: {msg: e?.response?.data?.msg || 'Something went wrong'},
            });
        } else {
            dispatch({type: UPDATE_BOOSTER_CONFIG_FAIL, payload: {msg: e?.message || 'Unknown Error Occured'}});
        }
    }
};
