import React from 'react';
import '../../../Utilities/style.css';
import '../Login/login.css';
import {connect} from 'react-redux';
import DetailLayout from '../../Layouts/DetailLayout/DetailLayout';
import Card from '../../../Components/Card/Card';
import Input from '../../../Components/Input/Input';
import DropDown from '../../../Components/Dropdown/Dropdown';
import {createTOMatchAttribute, updateTOMatchAttribute, deleteTOMatchAttribute} from './AxioCalls';
import {getTOGameList} from '../TOGame/AxioCalls';
import Snackbar from '../../../Components/Snackbar/Snackbar';
import {logout} from '../Login/LoginAction';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';
import Modal from '../../../Components/Modal/Modal';
import Close from '../../../Images/close_yellow.png';
import Button from '../../../Components/Button/Button';

class AddTOMatchAttribute extends React.Component {
    constructor(props) {
        super(props);
        let location = this.props.history.location.pathname.includes('edit');
        this.state = {
            gameOptions: [],
            attributeOptions: [
                {
                    value: 'image',
                    text: 'Image',
                },
                {
                    value: 'icon',
                    text: 'Icon',
                },
                {
                    value: 'text',
                    text: 'Text',
                },
            ],
            attribName: null,
            attribType: null,
            attribIcon: '',
            attribImage: '',
            attribText: '',
            game: null,
            loading: false,
            open: false,
            apiError: '',
            edit: location,
            snackbarOpen: false,
            possibleValues: null,
            deleteAttributeModalVisible: false,
        };
    }

    handleInputChange(field, value) {
        this.setState({
            [field]: value,
            snackbarOpen: false,
            errorMessage: '',
        });
    }

    onSave() {
        const {edit, attribName, attribType, attribIcon, attribImage, attribText, game, possibleValues} = this.state;
        const {login} = this.props;

        if (attribName === null || attribName === '' || attribName === undefined) {
            this.setState({
                snackbarOpen: true,
                errorMessage: 'please enter attribute name',
            });
        } else if (attribType === null || attribType === undefined || attribType === '') {
            this.setState({
                snackbarOpen: true,
                errorMessage: 'please select valid Attribute Type',
            });
        } else if (attribType === 'icon' && (attribIcon === undefined || attribIcon === '' || attribIcon === null)) {
            this.setState({
                snackbarOpen: true,
                errorMessage: 'please enter valid Attribute Icon',
            });
        } else if (
            attribType === 'image' &&
            (attribImage === undefined || attribImage === '' || attribImage === null)
        ) {
            this.setState({
                snackbarOpen: true,
                errorMessage: 'please enter valid Attribute Image',
            });
        } else if (attribType === 'text' && (attribText === undefined || attribText === '' || attribText === null)) {
            this.setState({
                snackbarOpen: true,
                errorMessage: 'please enter valid Attribute Text',
            });
        } else if (game === null || game === undefined || game === '') {
            this.setState({
                snackbarOpen: true,
                errorMessage: 'please select valid game',
            });
        } else if (!possibleValues || possibleValues.trim() === '') {
            this.setState({
                snackbarOpen: true,
                errorMessage: 'possibleValues are mandatory!',
            });
        } else {
            let possibleValuesArr = possibleValues.trim().split(',');
            let attributeValues = [];
            for (let i = 0; i < possibleValuesArr.length; i++) {
                if (possibleValuesArr[i].trim() !== '') {
                    attributeValues.push({name: possibleValuesArr[i].trim()});
                }
            }

            let matchAttribute = {
                name: attribName,
                attribType: attribType,
                attribIcon: attribIcon,
                attribText: attribText,
                attribImage: attribImage,
                game: game,
                possibleValues: attributeValues,
            };
            this.setState({loading: true});
            if (edit) {
                matchAttribute.id = this.props.toMatchAttribute.editTOMatchAttribute.id;
                this.props.updateTOMatchAttribute(matchAttribute, login.userDetails.data.accessToken);
            } else {
                this.props.createTOMatchAttribute(matchAttribute, login.userDetails.data.accessToken);
            }
        }
    }

    componentDidMount() {
        const {login, history} = this.props;
        let gamePageOptions = {
            listType: 'ALL',
        };
        if (!login.userDetails.data) {
            history.push('/');
            this.props.logout();
        } else {
            this.setState({
                loading: true,
            });
            this.props.getTOGameList(gamePageOptions, login.userDetails.data.accessToken);

            const {toMatchAttribute} = this.props;
            if (this.state.edit) {
                const {name, attribType, game, possibleValues, attribIcon, attribImage, attribText} =
                    toMatchAttribute.editTOMatchAttribute;
                this.setState({
                    attribName: name,
                    attribType: attribType,
                    game: game.id,
                });
                if (attribType === 'icon') {
                    this.setState({attribIcon: attribIcon});
                } else if (attribType === 'image') {
                    this.setState({attribImage: attribImage});
                } else if (attribType === 'text') {
                    this.setState({attribText: attribText});
                }

                if (possibleValues) {
                    let possibleValuesComma = '';
                    for (let i = 0; i < possibleValues.length; i++) {
                        possibleValuesComma += possibleValues[i].name + ',';
                    }
                    this.setState({possibleValues: possibleValuesComma});
                }
            }
        }
    }

    componentDidUpdate(prevProps) {
        const {toMatchAttribute, toGame, history} = this.props;

        if (toGame.TOGameListSuccess && !prevProps.toGame.TOGameListSuccess) {
            this.setState({
                gameOptions: toGame.TOGameList.data.data.map((item) => {
                    return {text: item.gameName, value: item.id};
                }),
                loading: false,
            });
        } else if (toGame.TOGameListFail && !prevProps.toGame.TOGameListFail) {
            this.setState({
                errorMessage: toGame.errorMessage,
                loading: false,
            });
        }

        if (toMatchAttribute.addTOMatchAttributeSuccess && !prevProps.toMatchAttribute.addTOMatchAttributeSuccess) {
            history.push('/toMatchAttributes');
        } else if (toMatchAttribute.addTOMatchAttributeFail && !prevProps.toMatchAttribute.addTOMatchAttributeFail) {
            this.setState({
                loading: false,
                snackbarOpen: true,
                errorMessage: toMatchAttribute.errorMessage,
            });
        }

        if (
            toMatchAttribute.updateTOMatchAttributeSuccess &&
            !prevProps.toMatchAttribute.updateTOMatchAttributeSuccess
        ) {
            history.push('/toMatchAttributes');
        } else if (
            toMatchAttribute.updateTOMatchAttributeFail &&
            !prevProps.toMatchAttribute.updateTOMatchAttributeFail
        ) {
            this.setState({
                loading: false,
                snackbarOpen: true,
                errorMessage: toMatchAttribute.errorMessage,
            });
        }

        if (
            toMatchAttribute.deleteTOMatchAttributeSuccess &&
            !prevProps.toMatchAttribute.deleteTOMatchAttributeSuccess
        ) {
            history.push('/toMatchAttributes');
        } else if (
            toMatchAttribute.deleteTOMatchAttributeFail &&
            !prevProps.toMatchAttribute.deleteTOMatchAttributeFail
        ) {
            this.setState({
                loading: false,
                snackbarOpen: true,
                errorMessage: toMatchAttribute.errorMessage,
            });
        }
    }

    onDeleteClick() {
        this.setState({deleteAttributeModalVisible: true});
    }

    onDeleteAttribute() {
        const {login, toMatchAttribute} = this.props;
        let attributeId = toMatchAttribute.editTOMatchAttribute.id;
        this.props.deleteTOMatchAttribute(attributeId, login.userDetails.data.accessToken);
        this.setState({deleteAttributeModalVisible: false, loading: true});
    }

    DeleteAttribModal = () => {
        return (
            <Modal open={this.state.deleteAttributeModalVisible}>
                <div className="container modal-top-margin">
                    <Card>
                        <div className="disp-flex">
                            <div className="modal-left-header">{'Delete Attribute'}</div>
                            <div onClick={() => this.setState({deleteAttributeModalVisible: false})}>
                                <img src={Close} alt="" className="cursor" />
                            </div>
                        </div>
                        <div className="align-center equalcontainer-grids">
                            <div />
                            <div>
                                <div className="disp-flex align-center margin-top-30">
                                    <div className="margin-right-16">
                                        <Button buttonText="Delete" onClick={this.onDeleteAttribute.bind(this)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
            </Modal>
        );
    };

    render() {
        const {
            attribName,
            attribType,
            attribIcon,
            attribImage,
            attribText,
            attributeOptions,
            game,
            errorMessage,
            loading,
            edit,
            snackbarOpen,
            gameOptions,
            possibleValues,
        } = this.state;
        const {history, toMatchAttribute, login} = this.props;
        let title = edit ? 'Edit MatchAttribute: ' + toMatchAttribute.editTOMatchAttribute.id : 'Add MatchAttribute';
        const {toMatchWrite} = login?.permissions || {};

        return (
            <DetailLayout
                location="AddTOMatchAttribute"
                history={history}
                centerTitle={title}
                onSave={!!toMatchWrite ? this.onSave.bind(this) : undefined}
                editPage={edit}
                id={edit ? toMatchAttribute.editTOMatchAttribute.id : null}
                onDeleteClick={!!toMatchWrite ? this.onDeleteClick.bind(this) : undefined}
            >
                <LoadingModal open={loading} />
                <Snackbar
                    open={snackbarOpen}
                    message={errorMessage}
                    onClose={() => this.setState({snackbarOpen: false})}
                />
                <div className="detail-container margin-top-20">
                    <div className="grid-item"></div>

                    <div className="grid-item">
                        <Card>
                            <div>
                                <div className="card-grid-container">
                                    {/* row 1 */}
                                    <div className="grid-item  margin-top-20">
                                        <Input
                                            label="Attribute Name"
                                            type="text"
                                            name="attribName"
                                            value={attribName}
                                            onChange={this.handleInputChange.bind(this, 'attribName')}
                                            placeholder="Enter Attribute Name"
                                            disabled={false}
                                        />
                                    </div>

                                    <div className="grid-item margin-top-20 align-right"></div>

                                    {/* row 2 */}
                                    <div className="grid-item  margin-top-20">
                                        <DropDown
                                            label="Attribute Type"
                                            type="text"
                                            menuItems={attributeOptions}
                                            name="attribType"
                                            value={attribType}
                                            onChange={this.handleInputChange.bind(this, 'attribType')}
                                            placeholder="Select"
                                            disabled={false}
                                        />
                                    </div>

                                    <div className="grid-item  margin-top-20"></div>

                                    {/* row 3 multiple possibilities */}
                                    {attribType === 'icon' ? (
                                        <div className="grid-item  margin-top-20">
                                            <Input
                                                label="Attribute Icon"
                                                type="text"
                                                name="attribIcon"
                                                value={attribIcon}
                                                onChange={this.handleInputChange.bind(this, 'attribIcon')}
                                                placeholder="Attribute Icon"
                                                disabled={false}
                                            />
                                        </div>
                                    ) : attribType === 'image' ? (
                                        <div className="grid-item  margin-top-20">
                                            <Input
                                                label="Attribute Image"
                                                type="text"
                                                name="attribImage"
                                                value={attribImage}
                                                onChange={this.handleInputChange.bind(this, 'attribImage')}
                                                placeholder="Attribute Image"
                                                disabled={false}
                                            />
                                        </div>
                                    ) : attribType === 'text' ? (
                                        <div className="grid-item  margin-top-20">
                                            <Input
                                                label="Attribute Text"
                                                type="text"
                                                name="attribText"
                                                value={attribText}
                                                onChange={this.handleInputChange.bind(this, 'attribText')}
                                                placeholder="Attribute Text"
                                                disabled={false}
                                            />
                                        </div>
                                    ) : null}

                                    {attribType === null || attribType === '' ? null : (
                                        <div className="grid-item  margin-top-20"></div>
                                    )}

                                    {/* row 4 */}
                                    <div className="grid-item margin-top-20">
                                        <DropDown
                                            label="Game"
                                            type="text"
                                            menuItems={gameOptions}
                                            name="game"
                                            value={game}
                                            onChange={this.handleInputChange.bind(this, 'game')}
                                            placeholder="Select"
                                            disabled={false}
                                        />
                                    </div>
                                    <div className="grid-item  margin-top-20"></div>

                                    {/* possible values */}
                                    <div className="grid-item  margin-top-20">
                                        <Input
                                            label="Possible Values"
                                            type="text"
                                            name="possibleValues"
                                            value={possibleValues}
                                            onChange={this.handleInputChange.bind(this, 'possibleValues')}
                                            placeholder="Enter comma separated values"
                                            disabled={false}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className="grid-item"></div>
                </div>
                <this.DeleteAttribModal />
            </DetailLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        toGame: state.toGame,
        toMatchAttribute: state.toMatchAttribute,
    };
};

export default connect(mapStateToProps, {
    getTOGameList,
    createTOMatchAttribute,
    logout,
    deleteTOMatchAttribute,
    updateTOMatchAttribute,
})(AddTOMatchAttribute);
