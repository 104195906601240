import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import '../../../../Utilities/style.css';
import '../../Login/login.css';
import {fetchUserBankKycList} from '../action';
import {logout} from '../../Login/LoginAction';
import {next, prev} from '../../../../Utilities/HelperFunctions';
import {timeFormat} from '../../../../Utilities/Helpers';

import TableLayout from '../../../Layouts/TableLayout/TableLayout';
import Table from '../../../../Components/Table/Table';
import Snackbar from '../../../../Components/Snackbar/Snackbar';
import LoadingModal from '../../../../Components/LoadingModal/LoadingModal';
import FilterInput from '../../../../Components/FilterInput';
import Button from '../../../../Components/Button/Button';
import {ENTER, KYC_STATUS_OPTIONS_FANCLASH, NEXT} from '../../../../Utilities/Constants';
import {useDebounce, usePrevious} from '../../../../Utilities/hooks';
import DropDown from '../../../../Components/Dropdown/Dropdown';
import UserKycActionModal from '../UserKycActionModal';

const UserBankKyc = (props) => {
    const {history} = props;
    const initialFilter = {page: 1, pageSize: 20, immediate: true, sortOrder: 'createdAt', sortOrderBy: 'DESC'};
    const dispatch = useDispatch();
    const login = useSelector((state) => state.login);
    const userKyc = useSelector((state) => state.userKyc);
    const prevUserKyc = usePrevious(userKyc);
    const user = useSelector((state) => state.user);
    const prevUser = usePrevious(user);
    const client = useSelector((state) => state.client);
    const {userBankKycList, isFetchingUserBankKycList} = userKyc;
    const {data: kycListData = [], pageData = {}} = userBankKycList || {};
    let [filters, setFilters] = useState(initialFilter);
    const debouncedFilters = useDebounce(filters, {immediate: filters.immediate});
    let [state, setState] = useState({});
    let [KycModal, setKycModal] = useState(null);
    const {errorMessage, loading} = state;
    const disableNext = filters.page >= pageData.lastPage;
    const isLoading = isFetchingUserBankKycList || loading || false;
    const sortOrderByOption = [
        {value: 'ASC', text: 'New to old'},
        {value: 'DESC', text: 'Old to new'},
    ];

    useEffect(() => {
        if (!login?.userDetails?.data) {
            history.push('/');
            dispatch(logout());
        }
    }, []);

    useEffect(() => {
        if (userKyc.isFetchingUserBankKycListFail && !prevUserKyc.isFetchingUserBankKycListFail) {
            handleOnStateChange({errorMessage: userKyc?.message?.msg});
        }
        if (
            (user.userKycManualActionSuccess && !prevUser.userKycManualActionSuccess) ||
            (user.isManuallyBankingKycSuccess && !prevUser.isManuallyBankingKycSuccess)
        ) {
            setKycModal(null);
            handleOnStateChange({errorMessage: 'Successfully Updated'});
            handleUserBankKycList();
        }
    }, [userKyc, user]);

    useEffect(() => {
        if (login?.userDetails?.data) handleUserBankKycList();
    }, [debouncedFilters]);

    const handleUserBankKycList = () => {
        const {immediate, ..._filters} = filters;
        dispatch(fetchUserBankKycList(_filters, login?.userDetails?.data?.accessToken, client.selectedClient));
    };

    const onNextORPrev = (action) => {
        const updatedFilters = action === NEXT ? next(filters, pageData) : prev(filters);
        if (updatedFilters) setFilters({...updatedFilters});
    };

    const onReset = () => {
        setFilters(initialFilter);
    };

    const handleFilter = (field, value, debounce = true) => {
        if (field === ENTER) debounce = false;
        else filters[field] = value;

        setFilters({...filters, page: 1, immediate: !debounce});
    };

    const handleOnStateChange = (stateObj = {}) => {
        setState((prevState) => ({...prevState, ...stateObj}));
    };

    const columns = [
        {
            Header: 'Id',
            accessor: 'id',
            Cell: (props) => <span>{props?.value || '-'}</span>,
        },
        {
            Header: 'User Id',
            accessor: 'userId',
            Cell: (props) => (
                <span className="table-clickable cursor" onClick={() => setKycModal({userId: props.value})}>
                    {props.value ? props.value : '-'}
                </span>
            ),
        },
        {
            Header: 'Registered Name',
            accessor: 'bankDetails',
            Cell: (props) => <span>{props?.value?.registeredName || '-'}</span>,
        },
        {
            Header: 'Status',
            accessor: 'status',
            Cell: (props) => <span>{props?.value || '-'}</span>,
        },
        {
            Header: 'CreatedAt',
            accessor: 'createdAt',
            width: 100,
            Cell: (props) => <span className="number date-space">{props?.value ? timeFormat(props?.value) : '-'}</span>,
        },
    ];

    return (
        <TableLayout location="User Bank Kyc" history={history} hideSearchBar>
            {!!isLoading && <LoadingModal open={!!isLoading} />}
            {!!errorMessage && (
                <Snackbar
                    open={!!errorMessage}
                    message={errorMessage}
                    onClose={() => handleOnStateChange({errorMessage: ''})}
                />
            )}

            {!!KycModal && (
                <UserKycActionModal open={!!KycModal} userKycData={KycModal} handleClose={() => setKycModal(null)} />
            )}

            <div className="filter-container my-10">
                <FilterInput
                    placeholder="User Id"
                    value={filters.userId || ''}
                    onChange={(value) => handleFilter('userId', value)}
                    onEnter={() => handleFilter(ENTER)}
                />
                <FilterInput
                    placeholder="Phone No."
                    value={filters.phoneNumber || ''}
                    onChange={(value) => handleFilter('phoneNumber', value)}
                    onEnter={() => handleFilter(ENTER)}
                />
                <DropDown
                    label="Bank Status"
                    value={filters.bankStatus || ''}
                    menuItems={KYC_STATUS_OPTIONS_FANCLASH}
                    onChange={(value) => handleFilter('bankStatus', value, false)}
                />
                <DropDown
                    label="Sort Direction"
                    value={filters.sortOrderBy || ''}
                    menuItems={sortOrderByOption}
                    onChange={(value) => handleFilter('sortOrderBy', value, false)}
                />
                <Button buttonText="Reset" onClick={onReset} />
            </div>

            <Table
                data={kycListData}
                columns={columns}
                page={filters.page}
                onPrevClick={onNextORPrev}
                onNextClick={() => onNextORPrev(NEXT)}
                disableNext={disableNext}
            ></Table>
        </TableLayout>
    );
};

export default React.memo(UserBankKyc);
