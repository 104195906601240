export const PLAYER_LIST = 'PLAYER_LIST';
export const PLAYER_LIST_SUCCESS = 'PLAYER_LIST_SUCCESS';
export const PLAYER_LIST_FAIL = 'PLAYER_LIST_FAIL';

export const ADD_PLAYER = 'ADD_PLAYER';
export const ADD_PLAYER_SUCCESS = 'ADD_PLAYER_SUCCESS';
export const ADD_PLAYER_FAIL = 'ADD_PLAYER_FAIL';

export const UPDATE_PLAYER = 'UPDATE_PLAYER';
export const UPDATE_PLAYER_SUCCESS = 'UPDATE_PLAYER_SUCCESS';
export const UPDATE_PLAYER_FAIL = 'UPDATE_PLAYER_FAIL';

export const DELETE_PLAYER = 'DELETE_PLAYER';
export const DELETE_PLAYER_SUCCESS = 'DELETE_PLAYER_SUCCESS';
export const DELETE_PLAYER_FAIL = 'DELETE_PLAYER_FAIL';

export const EDIT_PLAYER = 'EDIT_PLAYER';

export const GET_PLAYER = 'GET_PLAYER';
export const GET_PLAYER_SUCCESS = 'GET_PLAYER_SUCCESS';
export const GET_PLAYER_FAIL = 'GET_PLAYER_FAIL';

export const ADD_PLAYER_IN_MATCH = 'ADD_PLAYER_IN_MATCH';
export const ADD_PLAYER_IN_MATCH_SUCCESS = 'ADD_PLAYER_IN_MATCH_SUCCESS';
export const ADD_PLAYER_IN_MATCH_FAIL = 'ADD_PLAYER_IN_MATCH_FAIL';
