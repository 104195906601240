import React from 'react';
import '../../../Utilities/style.css';
import CommonNavbar from '../../../Components/CommonNavbar/CommonNavbar';
import SearchBar from '../../../Components/SearchBar/SearchBar';
import './DetailLayout.css';

class DetailLayout extends React.Component {
    render() {
        const {
            title,
            location,
            buttonText,
            history,
            centerTitle,
            onSave,
            userEntry,
            matchStatus,
            startTime,
            dispDelete,
            sortOptions,
            hideAll,
            count,
            onReset,
            onBackClick,
            initialTime,
            direction,
            searchbar,
            search,
            onDeleteClick,
            options,
            onPublish,
            editPage,
            onsearchChange,
            upcoming,
            id,
            searchString,
            sortOrder,
            onMarkLive,
            onForfeitSave,
        } = this.props;
        return (
            <div>
                <CommonNavbar
                    title={title}
                    location={location}
                    buttonText={buttonText}
                    history={history}
                    centerTitle={centerTitle}
                    onSave={onSave}
                    onDeleteClick={onDeleteClick}
                    onPublish={onPublish}
                    id={id}
                    editPage={editPage}
                    hideAll={hideAll}
                    onForfeitSave={onForfeitSave}
                    count={count}
                    onBackClick={onBackClick}
                    initialTime={initialTime}
                    direction={direction}
                    matchStatus={matchStatus}
                    startTime={startTime}
                    upcoming={upcoming}
                    onMarkLive={onMarkLive}
                />

                <div className="container">
                    {search ? (
                        <div className="margin-top-10 margin-bottom-10">
                            <SearchBar
                                history={history}
                                location={location}
                                userEntry={userEntry}
                                options={options}
                                onDeleteClick={onDeleteClick}
                                searchbar={searchbar}
                                dispDelete={dispDelete}
                                onsearchChange={onsearchChange}
                                onReset={onReset}
                                searchString={searchString}
                                sortOrder={sortOrder}
                                sortOptions={sortOptions}
                            />
                        </div>
                    ) : null}
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default DetailLayout;
