import React from 'react';
import '../../../Utilities/style.css';
import '../Login/login.css';
import {connect} from 'react-redux';
import Tabs from '../../../Components/Tabs/Tabs';
import Card from '../../../Components/Card/Card';
import DetailLayout from '../../Layouts/DetailLayout/DetailLayout';
import Input from '../../../Components/Input/Input';
import DropDown from '../../../Components/Dropdown/Dropdown';
import Snackbar from '../../../Components/Snackbar/Snackbar';
import moment from 'moment';
import {rescheduleMatch} from './AxioCalls';
import {MuiPickersUtilsProvider, DateTimePicker} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {logout} from '../Login/LoginAction';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';

class RescheduleMatch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            matchStatus: '',
            modalName: '',
            disabled: false,
            matchId: null,
            matchStartTime: null,
            errorMessage: null,
            snackbarOpen: false,
        };
    }

    /**
     * function to save entered data in state
     * @param {string} field field names from the form
     * @param {*} value value in the field
     */
    handleInputChange(field, value) {
        this.setState({
            [field]: value,
            snackbarOpen: false,
            errorMessage: '',
        });
    }

    componentWillMount() {
        const {login, history} = this.props;
        if (!login.userDetails.data) {
            history.push('/');
            this.props.logout();
        }
    }

    componentDidMount() {
        const {login, history} = this.props;
        if (!login.userDetails.data) {
            history.push('/');
            this.props.logout();
        }
    }

    componentDidUpdate(prevProps) {
        const {history, match} = this.props;
        if (match.rescheduleMatchSuccess && !prevProps.match.rescheduleMatchSuccess) {
            history.push('/matches');
        } else if (match.rescheduleMatchFail && !prevProps.match.rescheduleMatchFail) {
            this.setState({
                snackbarOpen: true,
                errorMessage: match.errorMessage,
                loading: false,
            });
        }
    }

    /**
     * on click of save button validate and save match
     */
    onSave() {
        const {matchId, matchStartTime, comment} = this.state;
        const {login, client} = this.props;

        const today = moment();
        const matchdate = moment(matchStartTime);

        if (matchStartTime === null || matchStartTime === undefined || matchdate < today) {
            this.setState({snackbarOpen: true, errorMessage: 'Please select a valid future start time'});
            return;
        } else if (matchId === null || matchId === undefined) {
            this.setState({snackbarOpen: true, errorMessage: 'Please select a matchId'});
            return;
        } else if (!comment || comment.length < 14) {
            this.setState({snackbarOpen: true, errorMessage: 'Comment must contain atleast 15 characters'});
            return;
        }

        let selectedTime = moment(matchStartTime, 'DD/MM/YYY H:mm');
        let timestamp = moment(selectedTime).valueOf();
        this.setState({loading: true});
        this.props.rescheduleMatch(
            {matchId, matchStartTime: timestamp, comment},
            login.userDetails.data.accessToken,
            client.selectedClient,
        );
    }

    /**
     * Ui for tab A
     */
    renderTabA() {
        const {matchId, matchStartTime, comment} = this.state;
        return (
            <div>
                <div className="card-fullgrid-container margin-top-20 ">
                    <Input
                        label="Match Id"
                        type="text"
                        name="matchId"
                        value={matchId}
                        onChange={this.handleInputChange.bind(this, 'matchId')}
                        placeholder="Enter "
                    />
                </div>
                <div className="card-fullgrid-container margin-top-20 ">
                    <Input
                        label="Comment"
                        type="text"
                        name="comment"
                        value={comment}
                        onChange={this.handleInputChange.bind(this, 'comment')}
                        placeholder="Enter "
                    />
                </div>
                <div className="card-grid-container nopadding margin-top-20">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DateTimePicker
                            margin="normal"
                            id="date-picker-dialog"
                            label="Date"
                            value={matchStartTime}
                            minDate={new Date()}
                            invalidDateMessage={''}
                            onChange={this.handleInputChange.bind(this, 'matchStartTime')}
                        />
                    </MuiPickersUtilsProvider>
                </div>
            </div>
        );
    }

    /**
     * on click of back button navigate to match table
     */
    onBackClick() {
        const {history} = this.props;

        history.push('/matches');
    }

    render() {
        const {errorMessage, loading, snackbarOpen} = this.state;
        let title = 'Reschedule Match';
        const {history, match} = this.props;

        return (
            <DetailLayout
                location="addMatch"
                history={history}
                centerTitle={title}
                onSave={this.onSave.bind(this)}
                onBackClick={this.onBackClick.bind(this)}
            >
                <Snackbar
                    open={snackbarOpen}
                    message={errorMessage}
                    onClose={() => this.setState({snackbarOpen: false})}
                />
                <LoadingModal open={loading} />

                <div className="detail-container margin-top-20">
                    <div className="grid-item">
                        <div className="text-grey">Only reschedule Matches in draft/upcoming.</div>
                    </div>
                    <div className="grid-item">
                        <Card>
                            <div>
                                <div className="card-single-grid-container">
                                    <Tabs tab1Label="" panelA={this.renderTabA.bind(this)} />
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className="grid-item"></div>
                </div>
            </DetailLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        match: state.match,
        client: state.client,
    };
};

export default connect(mapStateToProps, {rescheduleMatch})(RescheduleMatch);
