import React from 'react';
import '../../../Utilities/style.css';
import '../Login/login.css';
import TableLayout from '../../Layouts/TableLayout/TableLayout';
import Table from '../../../Components/Table/Table';
import {connect} from 'react-redux';
import Modal from '../../../Components/Modal/Modal';
import Card from '../../../Components/Card/Card';
import Close from '../../../Images/close_yellow.png';
import Button from '../../../Components/Button/Button';
import Dropdown from '../../../Components/Dropdown/Dropdown';
import {getWithdrawalList, withdrawalUpdate, checkWithdrawalStatus, checkAndUpdateWithdrawal} from './AxioCalls';
import Snackbar from '../../../Components/Snackbar/Snackbar';
import {approveKYC} from '../Users/AxioCalls';
import {logout} from '../Login/LoginAction';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';
import {timeFormat} from '../../../Utilities/Helpers';
import {copyText, next, prev, reset} from '../../../Utilities/HelperFunctions';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Input from '../../../Components/Input/Input';
import {FileCopy} from '@material-ui/icons';
import ActionModal from './ActionModal';

class WithDrawalList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            withdrawal: [],
            list: [],
            open: false,
            pageData: {},
            modalType: '',
            loading: true,
            modalId: null,
            pageOptions: {
                page: 1,
                pageSize: 10,
                searchString: null,
                sortOrder: null,
            },
            userName: '',
            transactionDetails: '',
            transactionStatus: '',
            options: [
                {value: 'PROCESSED', text: 'PROCESSED'},
                {value: 'FAILED', text: 'FAILED'},
            ],
            approveOptions: [
                {value: 'SUBMITTED', text: 'SUBMITTED'},
                {value: 'VERIFIED', text: 'VERIFIED'},
                {value: 'REJECTED', text: 'REJECTED'},
            ],
            sortOptions: [
                {value: 'id', text: 'Transaction  Id'},
                {value: 'userName', text: 'Username'},
                {value: 'createdAt', text: 'Requested on'},
                {value: 'processedOn', text: 'Processed on'},
                {text: 'Status', value: 'status'},
            ],
            status: null,
            panCardLink: '',
            pmtGatewayStatus: '',
            pmtGatewayCode: '',
            statusMessage: '',
            withDrawalQueryResponse: null,
        };
    }

    /**
     * API call to search for a squad
     * @param {string} field field name
     * @param {string} value value entered in the field
     */
    handleInputChange(field, value) {
        if (field === 'transactionDetails') {
            this.setState({
                [field]: value.target.value,
                snackbarOpen: false,
                errorMessage: '',
            });
        } else {
            this.setState({
                [field]: value,
                snackbarOpen: false,
                errorMessage: '',
            });
        }
    }

    componentWillMount() {
        const {login, history, client} = this.props;
        if (!login.userDetails.data) {
            history.push('/');
            this.props.logout();
        } else {
            const {pageOptions} = this.state;

            this.props.getWithdrawalList(pageOptions, login.userDetails.data.accessToken, client.selectedClient);
        }
    }

    componentDidUpdate(prevProps) {
        const {pageOptions} = this.state;
        const {withdrawal, login, user, client} = this.props;

        if (withdrawal.withdrawalListSuccess && !prevProps.withdrawal.withdrawalListSuccess) {
            this.setState({
                loading: false,
                list: withdrawal.withdrawalList.data.data,
                pageData: withdrawal.withdrawalList.data.pageData,
                withdrawal: [],
                status: null,
            });
        } else if (withdrawal.withdrawalListFail && !prevProps.withdrawal.withdrawalListFail) {
            this.setState({
                loading: false,
                snackbarOpen: true,
                errorMessage: withdrawal.errorMessage,
            });
        }
        if (withdrawal.withdrawalUpdateSuccess && !prevProps.withdrawal.withdrawalUpdateSuccess) {
            this.setState({open: false, modalType: ''});
            this.props.getWithdrawalList(pageOptions, login.userDetails.data.accessToken, client.selectedClient);
        } else if (withdrawal.withdrawalUpdateFail && !prevProps.withdrawal.withdrawalUpdateFail) {
            this.setState({
                loading: false,
                snackbarOpen: true,
                errorMessage: withdrawal.errorMessage,
                open: false,
            });
        }
        if (user.approveKYCSuccess && !prevProps.user.approveKYCSuccess) {
            this.setState({
                loading: false,
                open: false,
            });
            this.props.getWithdrawalList(pageOptions, login.userDetails.data.accessToken, client.selectedClient);
        } else if (user.approveKYCFail && !prevProps.user.approveKYCFail) {
            this.setState({
                loading: false,
                snackbarOpen: true,
                errorMessage: user.errorMessage,
            });
        }

        if (withdrawal.checkWithdrawalStatusInit && !prevProps.withdrawal.checkWithdrawalStatusInit) {
            this.setState({
                loading: true,
            });
        } else if (withdrawal.checkWithdrawalStatusSuccess && !prevProps.withdrawal.checkWithdrawalStatusSuccess) {
            this.setState({
                loading: false,
                withDrawalQueryResponse: withdrawal.checkWithdrawalStatusResponse,
            });
        } else if (withdrawal.checkWithdrawalStatusFailed && !prevProps.withdrawal.checkWithdrawalStatusFailed) {
            this.setState({
                loading: false,
                snackbarOpen: true,
                errorMessage: withdrawal.checkWithdrawalStatusResponse,
            });
        }

        if (withdrawal.checkAndUpdateWithdrawalInit && !prevProps.withdrawal.checkAndUpdateWithdrawalInit) {
            this.setState({
                loading: true,
            });
        } else if (
            withdrawal.checkAndUpdateWithdrawalSuccess &&
            !prevProps.withdrawal.checkAndUpdateWithdrawalSuccess
        ) {
            this.setState({
                loading: false,
                withDrawalQueryResponse: withdrawal.checkAndUpdateWithdrawalResponse,
            });
        } else if (withdrawal.checkAndUpdateWithdrawalFailed && !prevProps.withdrawal.checkAndUpdateWithdrawalFailed) {
            this.setState({
                loading: false,
                snackbarOpen: true,
                errorMessage: withdrawal.checkAndUpdateWithdrawalResponse,
            });
        }
        if (withdrawal.withdrawalActionRequest && !prevProps.withdrawal.withdrawalActionRequest) {
            this.setState({
                loading: true,
            });
        } else if (withdrawal.withdrawalActionSuccess && !prevProps.withdrawal.withdrawalActionSuccess) {
            this.setState({
                loading: false,
                snackbarOpen: true,
                errorMessage: withdrawal.errorMessage,
                actionModal: null,
            });
            this.props.getWithdrawalList(pageOptions, login.userDetails.data.accessToken, client.selectedClient);
        } else if (withdrawal.withdrawalActionFail && !prevProps.withdrawal.withdrawalActionFail) {
            this.setState({
                loading: false,
                snackbarOpen: true,
                errorMessage: withdrawal.errorMessage,
            });
        }
    }

    /**
     * reset table data
     */
    onReset() {
        const {pageOptions} = this.state;
        const {login, client} = this.props;
        let options = reset(pageOptions);
        this.setState({
            pageOptions: options,
        });
        this.props.getWithdrawalList(options, login.userDetails.data.accessToken, client.selectedClient);
    }

    /**
     * api call to update status of transactions
     */
    onUpdate() {
        const {transactionStatus, transactionDetails, modalId, pmtGatewayCode, pmtGatewayStatus, statusMessage} =
            this.state;
        const {login, client} = this.props;

        if (transactionStatus === null) {
            this.setState({
                snackbarOpen: true,
                errorMessage: 'please select a transaction status',
            });
        } else if (transactionDetails === null || transactionDetails === '' || transactionDetails === undefined) {
            this.setState({
                snackbarOpen: true,
                errorMessage: 'please Enter transaction details',
            });
        } else {
            let data = {
                requestId: modalId.transactionId,
                status: transactionStatus,
                pgStatusCode: pmtGatewayCode,
                pgStatus: pmtGatewayStatus,
                pgStatusMessage: statusMessage,
                comments: transactionDetails,
            };
            this.props.withdrawalUpdate(data, login.userDetails.data.accessToken, client.selectedClient);
        }
    }

    checkWithdrawalStatus() {
        // console.log("this.state.modalId ", this.state.modalId);
        this.props.checkWithdrawalStatus(
            this.state.modalId.withdrawalId,
            this.props.login.userDetails.data.accessToken,
            this.props.client.selectedClient,
        );
    }

    checkAndUpdatePendingWithdrawal() {
        this.props.checkAndUpdateWithdrawal(
            this.state.modalId.withdrawalId,
            this.props.login.userDetails.data.accessToken,
            this.props.client.selectedClient,
        );
    }

    /**
     * validate details in transaction modal
     */
    confirmTransaction() {
        const {transactionStatus, transactionDetails, pmtGatewayCode, pmtGatewayStatus, statusMessage} = this.state;

        if (transactionStatus === null) {
            this.setState({
                snackbarOpen: true,
                errorMessage: 'please select a transaction status',
            });
        } else if (transactionDetails === null || transactionDetails === '' || transactionDetails === undefined) {
            this.setState({
                snackbarOpen: true,
                errorMessage: 'please Enter transaction details',
            });
        } else if (pmtGatewayCode === null || pmtGatewayCode === '' || pmtGatewayCode === undefined) {
            this.setState({
                snackbarOpen: true,
                errorMessage: 'please Enter valid payment gateway code',
            });
        } else if (pmtGatewayStatus === null || pmtGatewayStatus === '' || pmtGatewayStatus === undefined) {
            this.setState({
                snackbarOpen: true,
                errorMessage: 'please Enter valid payment gateway status',
            });
        } else if (statusMessage === null || statusMessage === '' || statusMessage === undefined) {
            this.setState({
                snackbarOpen: true,
                errorMessage: 'please Enter valid status code',
            });
        } else {
            this.setState({modalType: 'saveDetails'});
        }
    }

    /**
     *  api call to approve kyc details
     */
    onApprove() {
        let data = {};
        const {modalId, status} = this.state;
        const {login, client} = this.props;

        if (status === null) {
            this.setState({
                snackbarOpen: true,
                errorMessage: 'please select a status',
            });
        } else {
            data.appUserId = modalId.user;
            data.status = status;
            this.props.approveKYC(data, login.userDetails.data.accessToken, client.selectedClient);
        }
    }

    /**
     * APi call with search and sort strings
     * @param {string} field field names of input
     * @param {string} value value in the field
     */
    onSearchandSort(field, value) {
        const {pageOptions} = this.state;
        const {login, client} = this.props;
        let options = pageOptions;
        options[field] = value;
        this.setState({pageOptions: options});
        this.setState({[field]: value});

        if (field === 'searchString' && value.length > 2) {
            this.props.getWithdrawalList(options, login.userDetails.data.accessToken, client.selectedClient);
        } else if (field === 'sortOrder') {
            this.props.getWithdrawalList(options, login.userDetails.data.accessToken, client.selectedClient);
        }
    }

    /**
     * pagination and api call on next click
     */
    onNext() {
        const {pageData, pageOptions} = this.state;
        const {login, client} = this.props;
        let options = next(pageOptions, pageData);

        if (options) {
            this.props.getWithdrawalList(options, login.userDetails.data.accessToken, client.selectedClient);
            this.setState({
                pageOptions: options,
                loading: true,
            });
        }
    }

    /**
     * pagination and api call on  prev click
     */
    onPrev() {
        const {pageOptions} = this.state;
        const {login, client} = this.props;
        let options = prev(pageOptions);
        if (options) {
            this.props.getWithdrawalList(options, login.userDetails.data.accessToken, client.selectedClient);
            this.setState({pageOptions: options, loading: true});
        }
    }

    /**
     * open transaction modal
     * @param {*} id if of transaction
     */
    transactionModal(id) {
        const {withdrawal} = this.props;
        let transaction = withdrawal.withdrawalList.data.data;
        const currentTransaction = transaction.filter((withdrawals) => withdrawals.id === id);
        let modalDetails = {...currentTransaction[0].kycDetails};
        modalDetails.transactionId = currentTransaction[0].id;
        modalDetails.withdrawalId = id;
        modalDetails = {...modalDetails, ...currentTransaction[0]};
        this.setState({
            modalId: modalDetails,
            modalType: 'transaction',
            open: true,
            withDrawalQueryResponse: null,
            transactionStatus: currentTransaction[0].status,
            userName: currentTransaction[0].userName,
            transactionDetails: currentTransaction[0].comments ? currentTransaction[0].comments : '',
            pmtGatewayCode: currentTransaction[0].pgStatusCode ? currentTransaction[0].pgStatusCode : '',
            pmtGatewayStatus: currentTransaction[0].pgStatus ? currentTransaction[0].pgStatus : '',
            statusMessage: currentTransaction[0].pgStatusMessage ? currentTransaction[0].pgStatusMessage : '',
        });
    }

    /**
     * open modal on click of kyc
     * @param {*} data
     * @param {*} kyc
     */
    onKycClick(data, kyc) {
        let users = this.props.withdrawal.withdrawalList.data.data;
        let index = data.index;
        this.setState({
            userName: users[index].userName,
            modalType: 'kycModal',
            open: true,
            modalId: kyc,
            status: kyc.status,
            transactionDetails: '',
        });
    }

    handleActionClick(record, type) {
        this.setState({
            actionModal: {withdrawal: record.original, type},
        });
    }

    handleCopy = (text) => {
        copyText(text, (msg) => this.setState({snackbarOpen: true, errorMessage: msg}));
    };

    render() {
        const {history, login, client} = this.props;
        const {
            open,
            status,
            options,
            list,
            modalId,
            loading,
            userName,
            pageData,
            snackbarOpen,
            approveOptions,
            transactionDetails,
            pmtGatewayCode,
            pmtGatewayStatus,
            statusMessage,
            sortOptions,
            errorMessage,
            modalType,
            actionModal,
            pageOptions,
        } = this.state;
        const {searchString, sortOrder, page} = pageOptions;
        const {userKycWrite} = login?.permissions || {};

        const data = list;
        let disableNext = page === pageData.lastPage;
        const renderColumns = () => {
            const items = [];

            if (client.selectedClient === 'client_jade') {
                items.splice(
                    1,
                    0,
                    {
                        Header: 'Serial No.',
                        accessor: 'id',
                        width: 100,
                        Cell: (props) => (
                            <span className="number">
                                {pageOptions.pageSize * pageOptions.page - 10 + props.index + 1}
                            </span>
                        ),
                    },
                    {
                        Header: 'Transaction ID',
                        accessor: 'id',
                        width: 200,
                        Cell: (props) => (
                            <span className="number">
                                {props.value === undefined || props.value === '' ? '-' : props.value}
                            </span>
                        ),
                    },
                    {
                        Header: 'User ID',
                        accessor: 'user',
                        width: 200,
                        Cell: (props) => (
                            <span className="number">
                                {props.value === undefined || props.value === '' ? '-' : props.value}
                            </span>
                        ),
                    },
                    {
                        Header: 'Username',
                        accessor: 'userName',
                        width: 200,
                        Cell: (props) => (
                            <span style={{display: 'flex', alignItems: 'center', gap: '4px'}}>
                                {props.value === undefined || props.value === '' ? '-' : props.value}
                                {!!props?.value && (
                                    <span
                                        style={{
                                            cursor: 'pointer',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                        onClick={() => this.handleCopy(props?.value)}
                                    >
                                        <FileCopy titleAccess="Copy" fontSize="small" />
                                    </span>
                                )}
                            </span>
                        ),
                    },
                    {
                        Header: 'Requested on',
                        accessor: 'createdAt',
                        width: 200,
                        Cell: (props) => <span className="number date-space">{timeFormat(props.value) || '-'}</span>,
                    },
                    {
                        Header: 'Withdraw Amount',
                        accessor: 'amount',
                        width: 200,
                        Cell: (props) => (
                            <span>{props.value === undefined || props.value === '' ? '-' : props.value}</span>
                        ),
                    },
                    {
                        Header: 'Status',
                        accessor: 'status',
                        width: 200,
                        Cell: (props) => (
                            <span className="number">
                                {props.value === undefined || props.value === '' ? '-' : props.value}
                            </span>
                        ),
                    },
                    {
                        Header: 'Processed on',
                        accessor: 'processedOn',
                        width: 200,
                        Cell: (props) => (
                            <span className="date-space">
                                {props.value === undefined || props.value === '' ? '-' : timeFormat(props.value)}
                            </span>
                        ),
                    },
                    {
                        Header: 'Actions',
                        accessor: 'id',
                        width: 200,
                        Cell: (props) => {
                            if (props.original.status === 'SUCCESS' || props.original.status === 'FAILED') {
                                return <span className="number">{'Action Taken'}</span>;
                            } else {
                                return (
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'flex-start',
                                            gap: '8px',
                                        }}
                                    >
                                        <span
                                            className="number table-clickable"
                                            onClick={this.handleActionClick.bind(this, props, 'approve')}
                                        >
                                            Approve
                                        </span>
                                        <span
                                            className="number table-clickable"
                                            onClick={this.handleActionClick.bind(this, props, 'reject')}
                                        >
                                            Reject
                                        </span>
                                    </div>
                                );
                            }
                        },
                    },
                );
            }

            if (client.selectedClient === 'client_fanclash') {
                items.splice(
                    1,
                    0,
                    {
                        Header: 'Transaction ID',
                        accessor: 'id',
                        width: 200,
                        Cell: (props) => (
                            <span
                                className="number table-clickable"
                                onClick={this.transactionModal.bind(this, props.value)}
                            >
                                {props.value === undefined || props.value === '' ? '-' : props.value}
                            </span>
                        ),
                    },
                    {
                        Header: 'Username',
                        accessor: 'userName',
                        width: 200,
                        Cell: (props) => (
                            <span className="number">
                                {props.value === undefined || props.value === '' ? '-' : props.value}
                            </span>
                        ),
                    },
                    {
                        Header: 'Requested on',
                        accessor: 'createdAt',
                        width: 200,
                        Cell: (props) => <span className="number date-space">{timeFormat(props.value) || '-'}</span>,
                    },
                    {
                        Header: 'Processed on',
                        accessor: 'processedOn',
                        width: 200,
                        Cell: (props) => (
                            <span className="date-space">
                                {props.value === undefined || props.value === '' ? '-' : timeFormat(props.value)}
                            </span>
                        ),
                    },
                    {
                        Header: 'Withdraw Amount',
                        accessor: 'amount',
                        width: 200,
                        Cell: (props) => (
                            <span>{props.value === undefined || props.value === '' ? '-' : '₹ ' + props.value}</span>
                        ),
                    },
                    {
                        Header: 'Destination Type',
                        accessor: 'destinationType',
                        width: 200,
                        Cell: (props) => <span>{props.value ? props.value : '-'}</span>,
                    },
                    {
                        Header: 'Destination',
                        accessor: 'destination',
                        width: 200,
                        Cell: (props) => <span>{props.value ? props.value : '-'}</span>,
                    },
                    {
                        Header: 'KYC',
                        accessor: 'kycDetails',
                        width: 200,
                        Cell: (props) => (
                            <span
                                className="table-clickable"
                                onClick={
                                    Object.keys(props.value).length >= 1
                                        ? this.onKycClick.bind(this, props, props.value)
                                        : null
                                }
                            >
                                {Object.keys(props.value).length < 1 ? '-' : 'Documents'}
                            </span>
                        ),
                    },
                    {
                        Header: 'Bank A/c Name',
                        accessor: 'kycDetails',
                        width: 200,
                        Cell: (props) => <span className="number">{props.value.fullName || '-'}</span>,
                    },
                    {
                        Header: 'Bank A/c #',
                        accessor: 'kycDetails',
                        width: 200,
                        Cell: (props) => <span className="number">{props.value.bankAccountNumber || '-'}</span>,
                    },
                    {
                        Header: 'IFSC code',
                        accessor: 'kycDetails',
                        width: 200,
                        Cell: (props) => <span className="number">{props.value.bankIfscCode || '-'}</span>,
                    },
                    {
                        Header: 'Status',
                        accessor: 'status',
                        width: 200,
                        Cell: (props) => (
                            <span className="number">
                                {props.value === undefined || props.value === '' ? '-' : props.value}
                            </span>
                        ),
                    },
                );
            }

            return items;
        };

        return (
            <TableLayout
                location="Withdrawals"
                history={history}
                searchbar="Search Username / UserID"
                options={options}
                updateStatus={this.handleInputChange.bind(this)}
                onSave={this.onUpdate.bind(this)}
                onsearchChange={this.onSearchandSort.bind(this)}
                sortOptions={sortOptions}
                onReset={this.onReset.bind(this)}
                searchString={searchString}
                sortOrder={sortOrder}
            >
                <Snackbar
                    open={snackbarOpen}
                    message={errorMessage}
                    onClose={() => this.setState({snackbarOpen: false})}
                />
                <LoadingModal open={loading} />
                {modalId ? (
                    <Modal open={open}>
                        {modalType === 'kycModal' ? (
                            <div className="container modal-top-margin">
                                <Card>
                                    <div className="commonnav-grid-container ">
                                        <div className="modal-left-header">KYC Documents | Set Status</div>
                                        <div className="align-center"></div>
                                        <div>
                                            <ul className="margin-0 modalUl align-right  ">
                                                <li className="margin-right-16">
                                                    <Dropdown
                                                        menuItems={approveOptions}
                                                        label="Set Status"
                                                        value={status}
                                                        placeholder="select"
                                                        onChange={this.handleInputChange.bind(this, 'status')}
                                                    />
                                                </li>
                                                <li className="margin-right-40">
                                                    {!!userKycWrite && (
                                                        <Button buttonText="Save" onClick={this.onApprove.bind(this)} />
                                                    )}
                                                </li>
                                                <li onClick={() => this.setState({open: false})}>
                                                    <img src={Close} alt="" className="cursor" />
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="kyc-grid margin-top-20">
                                        <div />
                                        <div>
                                            <div className="margin-top-20">
                                                <div className="basic-headers">Username</div>

                                                <div className="basiic-data">{userName ? userName : '-'}</div>
                                            </div>
                                            {/* row 2 */}
                                            <div className="disp-flex equal-half-grids align-left margin-top-20">
                                                <div className="margin-right-16">
                                                    <div className="basic-headers">Full Name</div>

                                                    <div className="basiic-data">
                                                        {modalId.fullName ? modalId.fullName : '-'}
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="basic-headers">Bank Account #</div>

                                                    <div className="basiic-data">
                                                        {modalId.bankAccountNumber ? modalId.bankAccountNumber : '-'}
                                                    </div>
                                                </div>
                                            </div>
                                            {/* row3 */}
                                            <div className="disp-flex equal-half-grids align-left margin-top-20">
                                                <div className="margin-right-16">
                                                    <div className="basic-headers">Mobile Number</div>
                                                    <div className="basiic-data">
                                                        {modalId.phoneNumber ? modalId.phoneNumber : '-'}
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="basic-headers">IFSC code</div>
                                                    <div className="basiic-data">
                                                        {modalId.bankIfscCode ? modalId.bankIfscCode : '-'}
                                                    </div>
                                                </div>
                                            </div>
                                            {/* row 4 */}
                                            <div className="margin-top-20">
                                                <div className="basic-headers">Email Address</div>
                                                <div className="basiic-data">{modalId.email ? modalId.email : '-'}</div>
                                            </div>
                                        </div>

                                        <div className="panCardImg">
                                            {modalId.panCardLink ? (
                                                <img className="doc-img" alt="doc" src={modalId.panCardLink} />
                                            ) : null}
                                        </div>
                                        <div />
                                    </div>
                                </Card>
                            </div>
                        ) : modalType === 'saveDetails' ? (
                            <div>
                                <Card>
                                    <div className="disp-flex">
                                        <div className="modal-left-header">
                                            Are you sure the Transaction is Processed ?
                                        </div>
                                        <div onClick={() => this.setState({open: false})}>
                                            <img src={Close} alt="" className="cursor" />
                                        </div>
                                    </div>
                                    <div className="align-center equalcontainer-grids">
                                        <div />
                                        <div>
                                            <div className="align-center disp-flex  margin-top-30">
                                                <div className="margin-right-16">
                                                    <Button
                                                        className="margin-right-16"
                                                        buttonText="No"
                                                        onClick={() => this.setState({modalType: 'transaction'})}
                                                    />
                                                </div>
                                                <Button buttonText="Yes" onClick={this.onUpdate.bind(this)} />
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            </div>
                        ) : modalType === 'transaction' ? (
                            // <div className="container modal-top-margin">
                            <div
                                style={{
                                    marginTop: '5%',
                                    height: window.innerHeight - 100,
                                    marginLeft: 40,
                                    marginRight: 40,
                                    overflow: 'scroll',
                                }}
                            >
                                <Card>
                                    <div className="commonnav-grid-container ">
                                        <div className="modal-left-header">Withdrawal Details</div>
                                        <div className="align-center"></div>
                                        <div>
                                            <ul className="margin-0 modalUl align-right  ">
                                                {/* <li className="margin-right-16">
                                                                    <Dropdown
                                                                        menuItems={options}
                                                                        label="Set Status"
                                                                        value={transactionStatus}
                                                                        placeholder="select"
                                                                        onChange={this.handleInputChange.bind(this, "transactionStatus")}
                                                                    />
                                                                </li>
                                                                <li className="margin-right-40">
                                                                    <Button buttonText="Save" onClick={this.confirmTransaction.bind(this)} />
                                                                </li> */}
                                                <li onClick={() => this.setState({open: false})}>
                                                    <img src={Close} alt="" className="cursor" />
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="kyc-grid margin-top-20">
                                        <div />
                                        <div>
                                            <div className="margin-top-20">
                                                <div className="basic-headers">Username</div>

                                                <div className="basiic-data">{userName ? userName : '-'}</div>
                                            </div>
                                            {/* row 2 */}
                                            <div className="disp-flex equal-half-grids align-left margin-top-20">
                                                <div className="margin-right-16">
                                                    <div className="basic-headers">Full Name</div>

                                                    <div className="basiic-data">
                                                        {modalId.fullName ? modalId.fullName : '-'}
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="basic-headers">Bank Account #</div>

                                                    <div className="basiic-data">
                                                        {modalId.bankAccountNumber ? modalId.bankAccountNumber : '-'}
                                                    </div>
                                                </div>
                                            </div>
                                            {/* row3 */}
                                            <div className="disp-flex equal-half-grids align-left margin-top-20">
                                                <div className="margin-right-16">
                                                    <div className="basic-headers">Mobile Number</div>
                                                    <div className="basiic-data">
                                                        {modalId.phoneNumber ? modalId.phoneNumber : '-'}
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="basic-headers">IFSC code</div>
                                                    <div className="basiic-data">
                                                        {modalId.bankIfscCode ? modalId.bankIfscCode : '-'}
                                                    </div>
                                                </div>
                                            </div>
                                            {/* row 4 */}
                                            <div className="margin-top-20">
                                                <div className="basic-headers">Transaction ID</div>
                                                <div className="basiic-data">{modalId.id ? modalId.id : '-'}</div>
                                            </div>

                                            {/* buttons */}
                                            <div className="margin-top-20">
                                                <Button
                                                    buttonText="Check Withdrawal Status in Paytm"
                                                    onClick={this.checkWithdrawalStatus.bind(this)}
                                                />
                                            </div>
                                            {(this.state.modalId.status === 'INITIATED' ||
                                                this.state.modalId.status === 'PREINITIATED') && (
                                                <div className="margin-top-20">
                                                    <Button
                                                        buttonText="Check and update Pending Withdrawal"
                                                        onClick={this.checkAndUpdatePendingWithdrawal.bind(this)}
                                                    />
                                                </div>
                                            )}
                                            {/* {this.state.modalId.status === 'INITIATED' ?
                                                                <div className="margin-top-20">
                                                                    <Button
                                                                        buttonText="Check and update Pending Withdrawal"
                                                                        onClick={this.checkAndUpdatePendingWithdrawal.bind(this)} />
                                                                </div> : null
                                                            } */}

                                            {this.state.withDrawalQueryResponse ? (
                                                <div id="" style={{overflow: 'scroll', height: 200}}>
                                                    <pre>
                                                        {JSON.stringify(this.state.withDrawalQueryResponse, null, 4)}
                                                    </pre>
                                                </div>
                                            ) : null}
                                        </div>

                                        <div>
                                            <div className="grid-item  margin-top-20 width-80">
                                                <Input
                                                    label="Payment Gateway Status Code"
                                                    type="text"
                                                    value={pmtGatewayCode}
                                                    onChange={this.handleInputChange.bind(this, 'pmtGatewayCode')}
                                                    placeholder="Enter Pubg username "
                                                />
                                            </div>
                                            <div className="grid-item  margin-top-20 width-80">
                                                <Input
                                                    label="Payment Gateway Status"
                                                    type="text"
                                                    value={pmtGatewayStatus}
                                                    onChange={this.handleInputChange.bind(this, 'pmtGatewayStatus')}
                                                    placeholder="Enter Pubg username "
                                                />
                                            </div>
                                            <div className="grid-item  margin-top-20 width-80">
                                                <Input
                                                    label="Payment Gateway Status Message"
                                                    type="text"
                                                    value={statusMessage}
                                                    onChange={this.handleInputChange.bind(this, 'statusMessage')}
                                                    placeholder="Enter Pubg username "
                                                />
                                            </div>
                                            <div className="grid-item  margin-top-20">
                                                <TextareaAutosize
                                                    className="margin-top-20"
                                                    aria-label="maximum height"
                                                    value={transactionDetails}
                                                    placeholder="Comments"
                                                    onChange={this.handleInputChange.bind(this, 'transactionDetails')}
                                                />
                                            </div>
                                        </div>
                                        <div />
                                    </div>
                                </Card>
                            </div>
                        ) : null}
                    </Modal>
                ) : null}

                {!!actionModal && (
                    <ActionModal
                        modalData={actionModal}
                        open={!!actionModal}
                        onClose={() => this.setState({actionModal: null})}
                    />
                )}

                <Table
                    data={data}
                    columns={renderColumns()}
                    page={page}
                    onPrevClick={this.onPrev.bind(this)}
                    onNextClick={this.onNext.bind(this)}
                    disableNext={disableNext}
                ></Table>
            </TableLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        squad: state.squad,
        player: state.player,
        withdrawal: state.withdrawal,
        user: state.user,
        client: state.client,
    };
};

export default connect(mapStateToProps, {
    getWithdrawalList,
    withdrawalUpdate,
    logout,
    approveKYC,
    checkWithdrawalStatus,
    checkAndUpdateWithdrawal,
})(WithDrawalList);
