import axios from 'axios';
import {
    listContestTypes,
    listContestTypesSuccess,
    listContestTypesFail,
    addContestTypes,
    addContestTypesSuccess,
    addContestTypesFail,
    updateContestTypes,
    updateContestTypesSuccess,
    updateContestTypesFail,
    deleteContestTypes,
    deleteContestTypesSuccess,
    deleteContestTypesFail,
    getContestTypes,
    getContestTypesSuccess,
    getContestTypesFail,
    getContestCategorySuccess,
    getContestCategoryFail,
    getContestCategory,
} from './action';
import {URL} from '../../../Utilities/Constants';
import {logout} from '../Login/LoginAction';

/**
 * list all contest types
 * @param {*} contestType page details
 * @param {string} token user token
 */
export function getContestTypeList(contestType, token, clientId) {
    return (dispatch) => {
        dispatch(listContestTypes());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token, clientid: clientId},
            url: `${URL}/contestType/list`,
            data: contestType,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(listContestTypesSuccess(data));
                } else if (data.data.status === 'fail') {
                    dispatch(listContestTypesFail(data.data.errorData.errorReason));
                } else {
                    dispatch(listContestTypesFail('Unknown Error Occured'));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(listContestTypesFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(listContestTypesFail(err.message || 'Something went wrong'));
                }
            });
    };
}

/**
 * create contest type
 * @param {*} contestType contest type details
 * @param {string} token user token
 */
export function createContestType(contestType, token, clientId) {
    return (dispatch) => {
        dispatch(addContestTypes());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token, clientid: clientId},
            url: `${URL}/contestType/create`,
            data: contestType,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(addContestTypesSuccess(data));
                } else if (data.data.status === 'fail') {
                    dispatch(addContestTypesFail(data.data.errorData.errorReason));
                } else {
                    dispatch(addContestTypesFail('Unknown Error Occured'));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(addContestTypesFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(addContestTypesFail(err.message || 'Something went wrong'));
                }
            });
    };
}

/**
 * create contest type
 * @param {*} contestType contest type details
 * @param {string} token user token
 */
export function getContestCategories(token, clientId) {
    return (dispatch) => {
        dispatch(getContestCategory());
        axios({
            method: 'get',
            headers: {Authorization: 'Bearer ' + token, clientid: clientId},
            url: `${URL}/getContestCategories`,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(getContestCategorySuccess(data));
                } else if (data.data.status === 'fail') {
                    dispatch(getContestCategoryFail(data.data.errorData.errorReason));
                } else {
                    dispatch(getContestCategoryFail('Unknown Error Occured'));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(getContestCategoryFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(getContestCategoryFail(err.message || 'Something went wrong'));
                }
            });
    };
}

/**
 * update contest type
 * @param {*} contestType contest type details
 * @param {string} token user token
 */
export function updateContestType(contestType, token, clientId) {
    return (dispatch) => {
        dispatch(updateContestTypes());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token, clientid: clientId},
            url: `${URL}/contestType/update`,
            data: contestType,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(updateContestTypesSuccess(data));
                } else if (data.data.status === 'fail') {
                    dispatch(updateContestTypesFail(data.data.errorData.errorReason));
                } else {
                    dispatch(updateContestTypesFail('Unknown Error Occured'));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(updateContestTypesFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(updateContestTypesFail(err.message || 'Something went wrong'));
                }
            });
    };
}

/**
 * delete contest type
 * @param {*} id contest type id
 * @param {string} token user token
 */
export function deleteContestType(id, token, clientId) {
    return (dispatch) => {
        dispatch(deleteContestTypes());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token, clientid: clientId},
            url: `${URL}/contestType/delete`,
            data: id,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(deleteContestTypesSuccess(data));
                } else if (data.data.status === 'fail') {
                    dispatch(deleteContestTypesFail(data.data.errorData.errorReason));
                } else {
                    dispatch(deleteContestTypesFail('Unknown Error Occured'));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(deleteContestTypesFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(deleteContestTypesFail(err.message || 'Something went wrong'));
                }
            });
    };
}

/**
 * get contest details types
 * @param {*} id contest type id
 * @param {string} token user token
 */
export function getContestType(id, token, clientId) {
    return (dispatch) => {
        dispatch(getContestTypes());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token, clientid: clientId},
            url: `${URL}/contestType/get`,
            data: {
                contestTypeId: id,
            },
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(getContestTypesSuccess(data));
                } else if (data.data.status === 'fail') {
                    dispatch(getContestTypesFail(data.data.errorData.errorReason));
                } else {
                    dispatch(getContestTypesFail('Unknown Error Occured'));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(getContestTypesFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(getContestTypesFail(err.message || 'Something went wrong'));
                }
            });
    };
}
