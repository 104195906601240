import React from 'react';
import '../../../Utilities/style.css';
import '../Login/login.css';
import {connect} from 'react-redux';
import Tabs from '../../../Components/Tabs/Tabs';
import Card from '../../../Components/Card/Card';
import DetailLayout from '../../Layouts/DetailLayout/DetailLayout';
import Input from '../../../Components/Input/Input';
import Snackbar from '../../../Components/Snackbar/Snackbar';
import {cancelMatch} from './AxioCalls';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';

class CancelMatch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            matchStatus: '',
            modalName: '',
            disabled: false,
            matchId: null,
            notification: null,
            errorMessage: null,
            snackbarOpen: false,
        };
    }

    /**
     * function to save entered data in state
     * @param {string} field field names from the form
     * @param {*} value value in the field
     */
    handleInputChange(field, value) {
        this.setState({
            [field]: value,
            snackbarOpen: false,
            errorMessage: '',
        });
    }

    componentWillMount() {
        const {login, history} = this.props;
        if (!login.userDetails.data) {
            history.push('/');
            this.props.logout();
        }
    }

    componentDidMount() {
        const {login, history} = this.props;
        if (!login.userDetails.data) {
            history.push('/');
            this.props.logout();
        }
    }

    componentDidUpdate(prevProps) {
        const {history, match} = this.props;
        if (match.markCancelMatchInit && !prevProps.match.markCancelMatchInit) {
            this.setState({
                loading: true,
            });
        } else if (match.markCancelMatchSuccess && !prevProps.match.markCancelMatchSuccess) {
            this.setState({
                snackbarOpen: true,
                loading: false,
                errorMessage: 'Match Cancelled',
            });
            history.push('/matches');
        } else if (match.markCancelMatchFail && !prevProps.match.markCancelMatchFail) {
            this.setState({
                snackbarOpen: true,
                loading: false,
                errorMessage: match.markCancelMatchResponse,
            });
        }
    }

    /**
     * on click of cancel button validate and cancel match
     */
    onCancel() {
        const {matchId, notification, matchStartTime} = this.state;
        const {login, client} = this.props;

        if (matchId === null || matchId === undefined) {
            this.setState({
                snackbarOpen: true,
                errorMessage: 'Please select a matchId',
            });
        } else if (notification === null || notification === undefined) {
            this.setState({
                snackbarOpen: true,
                errorMessage: 'Please Enter a reason for cancelling match',
            });
        }

        this.setState({loading: true});
        this.props.cancelMatch(matchId, notification, login.userDetails.data.accessToken, client.selectedClient);
    }

    /**
     * Ui for tab A
     */
    renderTabA() {
        const {matchId, notification} = this.state;
        return (
            <div>
                <div className="card-fullgrid-container margin-top-20 ">
                    <Input
                        label="Match Id"
                        type="text"
                        name="matchId"
                        value={matchId}
                        onChange={this.handleInputChange.bind(this, 'matchId')}
                        placeholder="Enter "
                    />
                </div>
                <div className="card-grid-container nopadding margin-top-20">
                    <Input
                        label="Notification"
                        type="text"
                        name="notification"
                        value={notification}
                        onChange={this.handleInputChange.bind(this, 'notification')}
                        placeholder="Enter Notification "
                    />
                </div>
            </div>
        );
    }

    /**
     * on click of back button navigate to match table
     */
    onBackClick() {
        const {history} = this.props;

        history.push('/matches');
    }

    render() {
        const {errorMessage, loading, snackbarOpen} = this.state;
        let title = 'Cancel and refund Match';
        const {history, match} = this.props;
        return (
            <DetailLayout
                location="addMatch"
                history={history}
                upcoming={'cancelMatch'}
                centerTitle={title}
                onSave={this.onCancel.bind(this)}
                onBackClick={this.onBackClick.bind(this)}
            >
                <Snackbar
                    open={snackbarOpen}
                    message={errorMessage}
                    onClose={() => this.setState({snackbarOpen: false})}
                />
                <LoadingModal open={loading} />

                <div className="detail-container margin-top-20">
                    <div className="grid-item">
                        <div className="text-grey">Only Live Matches can be cancelled</div>
                    </div>
                    <div className="grid-item">
                        <Card>
                            <div>
                                <div className="card-single-grid-container">
                                    <Tabs tab1Label="" panelA={this.renderTabA.bind(this)} />
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className="grid-item"></div>
                </div>
            </DetailLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        match: state.match,
        client: state.client,
    };
};

export default connect(mapStateToProps, {cancelMatch})(CancelMatch);
