import Axios from 'axios';

import {URL} from '../../../Utilities/Constants';
import {fetchS3FileFromServer} from '../TOPublishedMatch/AxioCalls';
import xlsx from 'xlsx';
import {saveAs} from 'file-saver';

/**
 * Method to populate articles.
 */
export async function putArticlesCall(file, token) {
    try {
        if (!file) {
            let msg = 'File is required!';
            return {error: true, msg: msg};
        }
        const data = new FormData();
        data.append('uploadArticlesFile', file);
        let axiosConfig = {
            headers: {Authorization: 'Bearer ' + token},
        };
        let res = await Axios.post(`${URL}/article/uploadArticlesFile`, data, axiosConfig);
        if (res.data.msg !== null && res.data.msg !== undefined && res.data.msg !== '') {
            let createArticleRes = await createArticles(res.data.msg, token);
            if (createArticleRes.error) {
                return {error: true, msg: createArticleRes.msg};
            }
        }
        return {error: false};
    } catch (err) {
        const errorMsg = err?.response?.data?.msg || err.message;
        return {error: true, msg: errorMsg || 'Upload Articles error'};
    }
}

/**
 * Excel format:
 *  Mandatory: title, author, shortContent, imageURL, tags(List of words)
 *  Optional fields: readMoreURL, source, game
 */
async function createArticles(fileLocation, token) {
    try {
        let res = await fetchS3FileFromServer(fileLocation, token);
        //parse input excel with teamIds
        var data = new Uint8Array(res.data);
        var workbook = xlsx.read(data, {type: 'array'});
        let worksheet = workbook.Sheets[workbook.SheetNames[0]];
        let sheetData = xlsx.utils.sheet_to_json(worksheet, {header: 1});

        let articleList = [];
        for (let i = 1; i < sheetData.length; i++) {
            // console.log("sheet row length", sheetData[i].length);
            if (sheetData[i].length < 5) {
                continue;
            }

            let article = getArticleObj(
                sheetData[i][0],
                sheetData[i][1],
                sheetData[i][2],
                sheetData[i][3],
                sheetData[i][4],
                sheetData[i][5],
                sheetData[i][6],
                sheetData[i][7],
                sheetData[i][8],
            );
            articleList.push(article);
        }

        //axios
        let axiosConfig = {
            headers: {
                Authorization: 'Bearer ' + token,
            },
        };
        let req = {articles: articleList};
        console.log(req);
        let uploadArticlesResult = await Axios.post(`${URL}/article/createBulk`, req, axiosConfig);
        console.log(uploadArticlesResult);

        if (!uploadArticlesResult.data.data || !uploadArticlesResult.data.data.data) {
            return {error: true, msg: 'Could not get expected response from server!'};
        }

        //download results
        populateAndDownloadUploadArticlesResult(uploadArticlesResult.data.data.data);
        return {error: false};
    } catch (err) {
        const errorMsg = err?.response?.data?.msg || err.message;
        return {error: true, msg: errorMsg || 'Something went wrong'};
    }
}

function populateAndDownloadUploadArticlesResult(data) {
    let wb = xlsx.utils.book_new();
    wb.Props = {
        Title: 'Result for upload articles',
        Subject: 'Result for upload articles',
        Author: 'Fanclash',
        CreatedDate: new Date(),
    };

    wb.SheetNames.push('upload_articles_result');

    let ws_data = [];
    //headers
    ws_data.push(['articleId', 'shortContent', 'title', 'reason', 'status']);

    // console.log("download. upload articles data", data)

    //rows
    for (let i = 0; i < data.length; i++) {
        let row = [];
        row.push(data[i].id);
        row.push(data[i].shortContent);
        row.push(data[i].title);
        row.push(data[i].reason);
        row.push(data[i].status);
        ws_data.push(row);
    }

    // console.log("add articles excel", ws_data);

    //create excel
    let ws = xlsx.utils.aoa_to_sheet(ws_data);
    wb.Sheets['upload_articles_result'] = ws;
    let wbout = xlsx.write(wb, {bookType: 'xlsx', type: 'binary'});

    saveAs(new Blob([s2ab(wbout)], {type: 'application/octet-stream'}), 'upload_articles_result.xlsx');
}

function s2ab(s) {
    let buf = new ArrayBuffer(s.length);
    let view = new Uint8Array(buf);
    for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
}

function getArticleObj(title, author, shortContent, imageURL, readMoreURL, tags, game, source) {
    return {
        title: title,
        shortContent: shortContent,
        author: author,
        readMoreURL: readMoreURL,
        tags: tags,
        imageURL: imageURL,
        game: game,
        source: source,
    };
}
