import axios from 'axios';
import {
    listFantasyScoringModels,
    listFantasyScoringModelsSuccess,
    listFantasyScoringModelsFail,
    addFantasyScoringModel,
    addFantasyScoringModelSuccess,
    addFantasyScoringModelFail,
    updateFantasyScoringModel,
    updateFantasyScoringModelSuccess,
    updateFantasyScoringModelFail,
    deleteFantasyScoringModel,
    deleteFantasyScoringModelSuccess,
    deleteFantasyScoringModelFail,
    getFantasyScoringModel,
    getFantasyScoringModelSuccess,
    getFantasyScoringModelFail,
} from './FantasyConfigAction';
import {URL} from '../../../Utilities/Constants';

/**
 * get fantasy scoring models list
 * @param {*} model page details
 * @param {string} token user token
 */
export function getFantasyScoreModelsList(model, token) {
    return (dispatch) => {
        dispatch(listFantasyScoringModels());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token},
            url: `${URL}/fantasyScoring/list`,
            data: model,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(listFantasyScoringModelsSuccess(data));
                } else if (data.data.status === 'fail') {
                    dispatch(listFantasyScoringModelsFail(data.data.errorData.errorReason));
                } else {
                    dispatch(listFantasyScoringModelsFail('Unknown Error Occured'));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(listFantasyScoringModelsFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(listFantasyScoringModelsFail(err.message || 'Something went wrong'));
                }
            });
    };
}

/**
 * add scoring model
 * @param {*} model score model details
 * @param {string} token user token
 */
export function createFantasyScoreModel(model, token) {
    return (dispatch) => {
        dispatch(addFantasyScoringModel());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token},
            url: `${URL}/fantasyScoring/create`,
            data: model,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(addFantasyScoringModelSuccess(data));
                } else if (data.data.status === 'fail') {
                    dispatch(addFantasyScoringModelFail(data.data.errorData.errorReason));
                } else {
                    dispatch(addFantasyScoringModelFail('Unknown Error Occured'));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(addFantasyScoringModelFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(addFantasyScoringModelFail(err.message || 'Something went wrong'));
                }
            });
    };
}

/**
 * update scoring model
 * @param {*} model details
 * @param {string} token user token
 */
export function updateFantasyScoreModel(model, token) {
    return (dispatch) => {
        dispatch(updateFantasyScoringModel());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token},
            url: `${URL}/fantasyScoring/update`,
            data: model,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(updateFantasyScoringModelSuccess(data));
                } else if (data.data.status === 'fail') {
                    dispatch(updateFantasyScoringModelFail(data.data.errorData.errorReason));
                } else {
                    dispatch(updateFantasyScoringModelFail('Unknown Error Occured'));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(updateFantasyScoringModelFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(updateFantasyScoringModelFail(err.message || 'Something went wrong'));
                }
            });
    };
}

/**
 * delete
 * @param {*} id  id
 * @param {string} token user token
 */
export function deleteFantasyScoreModel(id, token) {
    return (dispatch) => {
        dispatch(deleteFantasyScoringModel());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token},
            url: `${URL}/fantasyScoring/delete`,
            data: {
                id: id,
            },
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(deleteFantasyScoringModelSuccess(data));
                } else if (data.data.status === 'fail') {
                    dispatch(deleteFantasyScoringModelFail(data.data.errorData.errorReason));
                } else {
                    dispatch(deleteFantasyScoringModelFail('Unknown Error Occured'));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(deleteFantasyScoringModelFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(deleteFantasyScoringModelFail(err.message || 'Something went wrong'));
                }
            });
    };
}

/**
 * get
 * @param {*} id  id
 * @param {string} token user token
 */
export function getFantasyScoreModel(params, token) {
    return (dispatch) => {
        dispatch(getFantasyScoringModel());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token},
            url: `${URL}/fantasyScoring/get`,
            data: params,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(getFantasyScoringModelSuccess(data));
                } else if (data.data.status === 'fail') {
                    dispatch(getFantasyScoringModelFail(data.data.errorData.errorReason));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(getFantasyScoringModelFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(getFantasyScoringModelFail(err.message || 'Something went wrong'));
                }
            });
    };
}
