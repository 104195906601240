import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fetchCommonMatchesList, removeCommonMatch} from '../actions';
import {
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    makeStyles,
} from '@material-ui/core';
import {
    debounce,
    getFiltersSessionStorageItem,
    next,
    prev,
    setFiltersSessionStorageItem,
} from '../../../../Utilities/HelperFunctions';
import {SCREEN_NAMES} from '../../../../Utilities/Constants';
import CustomLayout from '../../../../layout/CustomLayout/CustomLayout';
import AddEditCommonMatch from '../components/AddEditCommonMatch/AddEditCommonMatch';
import MuiButton from '../../../../Components/MuiButton/MuiButton';
import {AddCircle, Autorenew, ChevronLeft, ChevronRight, Delete, Edit} from '@material-ui/icons';
import styles from './CommonMatches.module.scss';
import {logout} from '../../../../View/Modules/Login/LoginAction';
import {usePrevious} from '../../../../Utilities/hooks';
import {TIME_FORMAT_ONE} from '../../../../Utilities/constants/time-format-constants';
import classNames from 'classnames';
import dayjs from 'dayjs';
import {ColorPalette} from '../../../../Utilities/constants/color-constants';
import MuiInputField from '../../../../Components/MuiInputField/MuiInputField';

const useStyles = makeStyles({
    iconButtonRoot: {
        color: ColorPalette.GREY,
        padding: 0,
    },
    iconButtonLabel: {
        color: ColorPalette.GREY,

        '&:hover': {
            color: ColorPalette.SELECTIVE_YELLOW,
        },
    },

    paginationButtonRoot: {
        backgroundColor: ColorPalette.SALOME,
        padding: '4px',
        border: 'none',

        '&:hover': {
            backgroundColor: ColorPalette.SELECTIVE_YELLOW,
        },
    },
    paginationButtonLabel: {
        color: ColorPalette.BLACK,
    },
    paginationButtonDisabled: {
        color: `${ColorPalette.LINEN}!important`,
        backgroundColor: `${ColorPalette.SILVER}!important`,
    },
});

const CommonMatches = (props) => {
    const {history} = props;
    const dispatch = useDispatch();
    const login = useSelector((state) => state.login);
    const client = useSelector((state) => state.client);
    const commonMatch = useSelector((state) => state.commonMatch);
    const prevCommonMatch = usePrevious(commonMatch);
    const [pageOptions, setPageOptions] = useState({
        page: 1,
        pageSize: 10,
        listType: 'ALL',
        commonMatchId: '',
        matchName: '',
        glacierClassId: '',
        tournamentId: '',
        tournamentName: '',
        gameName: '',
        ...getFiltersSessionStorageItem(SCREEN_NAMES.COMMON_MATCHES),
    });
    const [commonMatches, setCommonMatches] = useState({list: [], pageData: {}});
    const [addEditCommonMatch, setAddEditCommonMatch] = useState(null);
    const classes = useStyles();

    useEffect(() => {
        if (!login?.userDetails?.data) {
            history.push('/');
            dispatch(logout());
        }
        fetchCommonMatchesListDebounce({immediate: true});
    }, []);

    useEffect(() => {
        // console.log("COMMON_MATCH", commonMatch);
        if (commonMatch.fetchCommonMatchesListSuccess && !prevCommonMatch.fetchCommonMatchesListSuccess) {
            setCommonMatches({
                list: commonMatch?.commonMatchesList?.data,
                pageData: commonMatch?.commonMatchesList?.pageData,
            });
        }
        if (commonMatch.fetchCommonMatchesListFailure && !prevCommonMatch.fetchCommonMatchesListFailure) {
            alert(commonMatch.message);
        }
        if (commonMatch.removeCommonMatchSuccess && !prevCommonMatch.removeCommonMatchSuccess) {
            alert(commonMatch.message);
        }
        if (commonMatch.removeCommonMatchFailure && !prevCommonMatch.removeCommonMatchFailure) {
            alert(commonMatch.message);
        }
    }, [commonMatch]);

    const fetchCommonMatchesListDebounce = debounce(() => {
        setFiltersSessionStorageItem(SCREEN_NAMES.MATCHES, pageOptions);
        dispatch(fetchCommonMatchesList(pageOptions, login?.userDetails?.data?.accessToken, client.selectedClient));
    });

    const handleFilter = (_field, _value, _debounce = true) => {
        if (_field === 'ENTER') {
            fetchCommonMatchesListDebounce({immediate: true});
            return;
        }
        pageOptions[_field] = _value;
        setPageOptions({...pageOptions, page: 1});
        fetchCommonMatchesListDebounce(_debounce ? {} : {immediate: true});
    };

    const handleResetFilter = () => {
        setPageOptions({
            page: 1,
            pageSize: 10,
            listType: 'ALL',
        });
        fetchCommonMatchesListDebounce({immediate: true});
    };

    const handleEdit = (_commonMatch) => {
        const data = {
            id: _commonMatch?._id,
        };
        setAddEditCommonMatch(data);
    };

    const handleDelete = (_commonMatch) => {
        const data = {
            id: _commonMatch?._id,
        };
        dispatch(removeCommonMatch(data, login?.userDetails?.data?.accessToken, client.selectedClient));
    };

    const handlePreviousButtonClick = () => {
        const _prevOptions = prev(pageOptions);
        if (_prevOptions) {
            setPageOptions(_prevOptions);
            fetchCommonMatchesListDebounce({immediate: true});
        }
    };

    const handleNextButtonClick = () => {
        const _nextOptions = next(pageOptions, commonMatches.pageData);
        if (_nextOptions) {
            setPageOptions(_nextOptions);
            fetchCommonMatchesListDebounce({immediate: true});
        }
    };

    const onAddEditSuccess = () => {
        setAddEditCommonMatch(null);
        fetchCommonMatchesListDebounce({immediate: true});
    };

    const renderSquadsField = (_commonMatch) => {
        if (_commonMatch?.squads?.length === 0) {
            return '-';
        }
        if (_commonMatch?.squads?.length > 2) {
            return `${_commonMatch?.squads?.length} squads playing`;
        }
        return _commonMatch?.squadDataList?.map((squad) => squad.squadName).join(', ');
    };

    return (
        <CustomLayout history={history} mainClassName={styles.main}>
            {!!addEditCommonMatch && (
                <AddEditCommonMatch
                    open={!!addEditCommonMatch}
                    addEditCommonMatch={addEditCommonMatch}
                    onClickClose={() => setAddEditCommonMatch(null)}
                    onAddEditSuccess={onAddEditSuccess}
                />
            )}
            {/* button section */}
            <section className={styles.buttonSection}>
                <MuiButton
                    type="button"
                    buttonText="Create Common Match"
                    buttonIcon={<AddCircle />}
                    onButtonClick={() => setAddEditCommonMatch({})}
                />
            </section>
            {/* header section */}
            <section className={styles.headerSection}>
                <h1 className={styles.pageTitle}>{`Common Matches`}</h1>
            </section>
            {/* filters section */}
            <section className={styles.filtersSection}>
                <div className={styles.filtersContainer}>
                    <div className={styles.header}>
                        <span className={styles.title}>
                            <h3>Filters</h3>
                        </span>
                        <MuiButton
                            className={styles.resetButton}
                            buttonIcon={<Autorenew />}
                            buttonText="Reset"
                            onButtonClick={handleResetFilter}
                        />
                    </div>
                    <div className={styles.filterFields}>
                        <MuiInputField
                            label="Common Match Id"
                            name="commonMatchId"
                            onEnter={() => handleFilter('ENTER')}
                            onChange={(event) => handleFilter('commonMatchId', event.target.value)}
                            size="small"
                            value={pageOptions.commonMatchId || ''}
                        />
                        <MuiInputField
                            label="Common Match Name"
                            name="matchName"
                            onEnter={() => handleFilter('ENTER')}
                            onChange={(event) => handleFilter('matchName', event.target.value)}
                            size="small"
                            value={pageOptions.matchName || ''}
                        />
                        <MuiInputField
                            label="Glacier Class Id"
                            name="glacierClassId"
                            onEnter={() => handleFilter('ENTER')}
                            onChange={(event) => handleFilter('glacierClassId', event.target.value)}
                            size="small"
                            value={pageOptions.glacierClassId || ''}
                        />
                        <MuiInputField
                            label="Tournament Id"
                            name="tournamentId"
                            onEnter={() => handleFilter('ENTER')}
                            onChange={(event) => handleFilter('tournamentId', event.target.value)}
                            size="small"
                            value={pageOptions.tournamentId || ''}
                        />
                        <MuiInputField
                            label="Tournament Name"
                            name="tournamentName"
                            onEnter={() => handleFilter('ENTER')}
                            onChange={(event) => handleFilter('tournamentName', event.target.value)}
                            size="small"
                            value={pageOptions.tournamentName || ''}
                        />
                        <MuiInputField
                            label="Game Name"
                            name="gameName"
                            onEnter={() => handleFilter('ENTER')}
                            onChange={(event) => handleFilter('gameName', event.target.value)}
                            size="small"
                            value={pageOptions.gameName || ''}
                        />
                    </div>
                </div>
            </section>
            {/* table sections */}
            <section className={styles.tableSection}>
                {/* pagination container */}
                <section className={styles.tablePaginationContainer}>
                    <div className={styles.paginationWrapper}>
                        <IconButton
                            classes={{
                                root: classes.paginationButtonRoot,
                                label: classes.paginationButtonLabel,
                                disabled: classes.paginationButtonDisabled,
                            }}
                            disabled={pageOptions?.page === 1}
                            onClick={handlePreviousButtonClick}
                        >
                            <ChevronLeft />
                        </IconButton>
                        <span className={styles.pageNumber}>
                            <p>{pageOptions.page}</p>
                        </span>
                        <IconButton
                            classes={{
                                root: classes.paginationButtonRoot,
                                label: classes.paginationButtonLabel,
                                disabled: classes.paginationButtonDisabled,
                            }}
                            disabled={pageOptions?.page === commonMatches?.pageData?.lastPage}
                            onClick={handleNextButtonClick}
                        >
                            <ChevronRight />
                        </IconButton>
                    </div>
                </section>
                <TableContainer className={styles.tableContainer}>
                    <Table className={styles.table}>
                        <TableHead className={styles.tableHead}>
                            <TableRow className={styles.tableHeadRow}>
                                <TableCell className={classNames(styles.tableHeadCell, styles.width300)}>
                                    Common Match ID
                                </TableCell>
                                <TableCell className={classNames(styles.tableHeadCell, styles.width300)}>
                                    Common Match Name
                                </TableCell>
                                <TableCell className={classNames(styles.tableHeadCell, styles.width100)}>
                                    Status
                                </TableCell>
                                <TableCell className={classNames(styles.tableHeadCell, styles.width200)}>
                                    Start Time
                                </TableCell>
                                <TableCell className={classNames(styles.tableHeadCell, styles.width400)}>
                                    Squads
                                </TableCell>
                                <TableCell className={classNames(styles.tableHeadCell, styles.width200)}>
                                    Tournament ID
                                </TableCell>
                                <TableCell className={classNames(styles.tableHeadCell, styles.width300)}>
                                    Tournament Name
                                </TableCell>
                                <TableCell className={classNames(styles.tableHeadCell, styles.width200)}>
                                    Map Name
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody className={styles.tableBody}>
                            {commonMatches?.list?.map((_commonMatch) => (
                                <TableRow className={styles.tableBodyRow} key={_commonMatch?._id}>
                                    <TableCell
                                        className={classNames(styles.tableBodyCell, styles.bodyIdCell, styles.width300)}
                                    >
                                        {_commonMatch?._id}
                                        <div className={styles.actionButtonGroup}>
                                            <IconButton
                                                onClick={() => handleEdit(_commonMatch)}
                                                classes={{root: classes.iconButtonRoot, label: classes.iconButtonLabel}}
                                                className={styles.actionButton}
                                            >
                                                <Edit className={styles.actionButtonIcon} />
                                            </IconButton>
                                            <IconButton
                                                onClick={() => handleDelete(_commonMatch)}
                                                classes={{root: classes.iconButtonRoot, label: classes.iconButtonLabel}}
                                                className={styles.actionButton}
                                            >
                                                <Delete className={styles.actionButtonIcon} />
                                            </IconButton>
                                        </div>
                                    </TableCell>
                                    <TableCell className={classNames(styles.tableBodyCell, styles.width300)}>
                                        {_commonMatch?.matchName}
                                    </TableCell>
                                    <TableCell className={classNames(styles.tableBodyCell, styles.width100)}>
                                        {_commonMatch?.status}
                                    </TableCell>
                                    <TableCell className={classNames(styles.tableBodyCell, styles.width200)}>
                                        {_commonMatch.startTime
                                            ? dayjs(_commonMatch.startTime).format(TIME_FORMAT_ONE)
                                            : ''}
                                    </TableCell>
                                    <TableCell className={classNames(styles.tableBodyCell, styles.width400)}>
                                        {renderSquadsField(_commonMatch)}
                                    </TableCell>
                                    <TableCell className={classNames(styles.tableBodyCell, styles.width200)}>
                                        {_commonMatch?.tournament}
                                    </TableCell>
                                    <TableCell className={classNames(styles.tableBodyCell, styles.width300)}>
                                        {_commonMatch?.tournamentName}
                                    </TableCell>
                                    <TableCell className={classNames(styles.tableBodyCell, styles.width200)}>
                                        {_commonMatch?.mapName}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </section>
        </CustomLayout>
    );
};

export default CommonMatches;
