import {
    CONTEST_TYPE_LIST,
    CONTEST_TYPE_LIST_SUCCESS,
    CONTEST_TYPE_LIST_FAIL,
    ADD_CONTEST_TYPE,
    ADD_CONTEST_TYPE_SUCCESS,
    ADD_CONTEST_TYPE_FAIL,
    UPDATE_CONTEST_TYPE,
    UPDATE_CONTEST_TYPE_SUCCESS,
    UPDATE_CONTEST_TYPE_FAIL,
    EDIT_CONEST_TYPE,
    DELETE_CONTEST_TYPE,
    DELETE_CONTEST_TYPE_SUCCESS,
    DELETE_CONTEST_TYPE_FAIL,
    GET_CONTEST_TYPE,
    GET_CONTEST_TYPE_SUCCESS,
    GET_CONTEST_TYPE_FAIL,
    GET_CONTEST_CATEGORY_SUCCESS,
    GET_CONTEST_CATEGORY_FAIL,
    GET_CONTEST_CATEGORY,
} from './Constants';

/**
 * list contest types
 */
export function listContestTypes() {
    return {
        type: CONTEST_TYPE_LIST,
    };
}

/**
 *handle list contest type api success
 * @param {*} data data form api
 */
export function listContestTypesSuccess(data) {
    return {
        type: CONTEST_TYPE_LIST_SUCCESS,
        payload: data,
    };
}

/**
 * handle list contest type api failure
 * @param {*} error error from api
 */
export function listContestTypesFail(error) {
    return {
        type: CONTEST_TYPE_LIST_FAIL,
        payload: error,
    };
}

/**
 * add contest types
 */
export function addContestTypes() {
    return {
        type: ADD_CONTEST_TYPE,
    };
}
/**
 *handle add contest type api success
 * @param {*} data data form api
 */
export function addContestTypesSuccess(data) {
    return {
        type: ADD_CONTEST_TYPE_SUCCESS,
        payload: data,
    };
}
/**
 *handle add contest type api failure
 * @param {*} error error from api
 */
export function addContestTypesFail(error) {
    return {
        type: ADD_CONTEST_TYPE_FAIL,
        payload: error,
    };
}

/**
 * save contest details
 * @param {*} data contest type details
 */
export function editContestType(data) {
    return {
        type: EDIT_CONEST_TYPE,
        payload: data,
    };
}

/**
 * update contest types
 */
export function updateContestTypes(data) {
    return {
        type: UPDATE_CONTEST_TYPE,
        payload: data,
    };
}
/**
 *handle  update contest type api success
 * @param {*} data data form api
 */
export function updateContestTypesSuccess(error) {
    return {
        type: UPDATE_CONTEST_TYPE_SUCCESS,
        payload: error,
    };
}
/**
 *handle update contest type api failure
 * @param {*} error error from api
 */
export function updateContestTypesFail(data) {
    return {
        type: UPDATE_CONTEST_TYPE_FAIL,
        payload: data,
    };
}

/**
 * delete contest types
 */
export function deleteContestTypes(data) {
    return {
        type: DELETE_CONTEST_TYPE,
        payload: data,
    };
}
/**
 *handle delete contest type api success
 * @param {*} data data form api
 */
export function deleteContestTypesSuccess(error) {
    return {
        type: DELETE_CONTEST_TYPE_SUCCESS,
        payload: error,
    };
}
/**
 *handle delete contest type api failure
 * @param {*} error error from api
 */
export function deleteContestTypesFail(data) {
    return {
        type: DELETE_CONTEST_TYPE_FAIL,
        payload: data,
    };
}

/**
 * get contest types
 */
export function getContestTypes(data) {
    return {
        type: GET_CONTEST_TYPE,
        payload: data,
    };
}
/**
 *handle get contest type api success
 * @param {*} data data form api
 */
export function getContestTypesSuccess(error) {
    return {
        type: GET_CONTEST_TYPE_SUCCESS,
        payload: error,
    };
}
/**
 *handle get contest type api failure
 * @param {*} error error from api
 */
export function getContestTypesFail(data) {
    return {
        type: GET_CONTEST_TYPE_FAIL,
        payload: data,
    };
}

export function getContestCategorySuccess(data) {
    return {
        type: GET_CONTEST_CATEGORY_SUCCESS,
        payload: data,
    };
}

export function getContestCategory(data) {
    return {
        type: GET_CONTEST_CATEGORY,
        payload: data,
    };
}

export function getContestCategoryFail(data) {
    return {
        type: GET_CONTEST_CATEGORY_FAIL,
        payload: data,
    };
}
