import React from 'react';
import '../../../Utilities/style.css';
import '../Login/login.css';
import DetailLayout from '../../Layouts/DetailLayout/DetailLayout';
import Table from '../../../Components/Table/Table';

import {connect} from 'react-redux';
import Modal from '../../../Components/Modal/Modal';
import Card from '../../../Components/Card/Card';
import Close from '../../../Images/close_yellow.png';
import Button from '../../../Components/Button/Button';
import Map from '../../../Images/map.png';
import Controller from '../../../Images/players.png';
import moment from 'moment';
import Snackbar from '../../../Components/Snackbar/Snackbar';
import {logout} from '../Login/LoginAction';
import {timeFormat} from '../../../Utilities/Helpers';

class TeamDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: [],
            selectAll: false,
            list: [],
            pageSize: 10,
            page: 1,
            open: false,
            adminpswd: '',
            initialTime: undefined,
            direction: '',
            dayCount: undefined,
        };
    }

    /**
     * function to save entered data in state
     * @param {string} field field names from the form
     * @param {*} value value in the field
     */
    handleInputChange(field, value) {
        this.setState({
            [field]: value,
            snackbarOpen: false,
            errorMessage: '',
        });
    }

    componentWillMount() {
        const {login, history} = this.props;
        if (!login.userDetails.data) {
            history.push('/');
            this.props.logout();
        }
    }

    componentDidMount() {
        const {login, history} = this.props;
        if (!login.userDetails.data) {
            history.push('/');
            this.props.logout();
        } else {
            const {team} = this.props;
            console.log('tem', team.teamDataList.data);
            let date = new Date();
            let timestamp = moment(date).valueOf();
            let startTime = team.teamDataList.data.matchStartTime;
            if (team.teamDataList.data.status === 'PAID, UPCOMING') {
                let timeLeft = startTime - timestamp;
                this.setState({
                    initialTime: timeLeft,
                    direction: 'backward',
                    dayCount: timeLeft < 0 ? undefined : startTime,
                });
            } else if (team.teamDataList.data.status === 'LIVE') {
                this.setState({
                    initialTime: startTime + timestamp,
                    direction: 'forward',
                    dayCount: startTime,
                });
            } else {
                this.setState({
                    initialTime: undefined,
                });
            }
        }
    }

    render() {
        const {open, snackbarOpen, errorMessage, direction, initialTime, dayCount} = this.state;
        const {team, history} = this.props;
        const data = team.teamDataList.data ? team.teamDataList.data.players : '';
        const {fantasyPoints, isWinner, matchStartTime, updatedAt, match} = team.teamDataList.data;
        const {tournamentName, matchName, matchMap, playerCount, squadCount} = team.teamDataList.data;
        console.log('initial time', initialTime, direction);

        let id = {};
        id.matchId = match;
        id.teamId = team.teamDataList.data.id;
        const columns = [
            {
                Header: 'Last Updated',
                accessor: 'updatedAt',

                Cell: (props) => <span className="date-space">{timeFormat(props.value)}</span>,
            },
            {
                Header: 'Player ID',
                accessor: 'id',
                width: 200,
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },

            {
                Header: 'Pubg Username',
                accessor: 'pubgName',
                width: 200,
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Squad',
                accessor: 'squadName',

                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Position in Squad',
                accessor: 'positionInSquad',
                width: 150,
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Credits',
                accessor: 'currentCredits',

                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'FC Rating',
                accessor: 'fcRating',

                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Selected By',
                accessor: 'selectedBy',
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Multiplier',
                accessor: 'multiplier',
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Fantasy Points',
                accessor: 'fantasyPoints',
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Alive',
                accessor: 'aliveStatus',
                Cell: (props) => <span className="number">{props.value === true ? 'Alive' : 'Dead'}</span>,
            },
            {
                Header: 'Status',
                accessor: 'status',

                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
        ];
        return (
            <DetailLayout
                location="teamDetails"
                history={history}
                searchbar="Search Match ID "
                centerTitle="Team Data"
                id={id}
                initialTime={initialTime}
                direction={direction}
                startTime={dayCount}
                matchStatus={team.teamDataList.data.startTime}
            >
                <Snackbar
                    open={snackbarOpen}
                    message={errorMessage}
                    onClose={() => this.setState({snackbarOpen: false})}
                />
                <Modal open={open}>
                    <div className="container modal-top-margin">
                        <Card>
                            <div className="disp-flex">
                                <div className="modal-left-header">Delete/Block User</div>
                                <div onClick={() => this.setState({open: false})}>
                                    <img src={Close} alt="" className="cursor" />
                                </div>
                            </div>
                            <div className="align-center equalcontainer-grids">
                                <div />
                                <div>
                                    <div className="disp-flex align-center margin-top-30">
                                        <div className="margin-right-16">
                                            <Button buttonText="Delete User" onClick={this.handleInputChange} />
                                        </div>

                                        <Button buttonText="Block User" onClick={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                </Modal>

                <div className="team-details-grid margin-bottom-10 margin-top-10">
                    <div className="grid-item">
                        <div className="basiic-data">{tournamentName}</div>
                        <div className="margin-top-10 text-bold">{matchName}</div>
                        <div className="disp-flex align-left margin-top-10">
                            <div className="disp-flex margin-right-16">
                                <div className="margin-right-5">
                                    <img src={Map} alt="" />
                                </div>
                                <div className="basic-headers">{matchMap}</div>
                            </div>
                            <div className="disp-flex">
                                <div className="margin-right-5">
                                    <img src={Controller} alt="" />
                                </div>
                                <div className="basic-headers">
                                    {playerCount + ' Players ' + squadCount + ' Squads'}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid-item disp-flex">
                        <div>
                            <div className="basic-headers">Match Start</div>
                            <div className="basiic-data">{timeFormat(matchStartTime)}</div>
                        </div>
                        <div>
                            <div className="basic-headers">Winner</div>
                            <div className="basiic-data">{isWinner ? 'Yes' : 'No'}</div>
                        </div>

                        <div>
                            <div className="basic-headers">Total Fantasy Points</div>
                            <div className="basiic-data">{fantasyPoints}</div>
                        </div>
                        <div>
                            <div className="basic-headers">Team Last Updated</div>
                            <div className="basiic-data">{timeFormat(updatedAt)}</div>
                        </div>
                    </div>
                </div>
                <Table data={data} columns={columns} pageSize={10} page={1} removePagination={true}></Table>
            </DetailLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        squad: state.squad,
        player: state.player,
        team: state.team,
    };
};

export default connect(mapStateToProps, {logout})(TeamDetails);
