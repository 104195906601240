import React from 'react';

import '../../../Utilities/style.css';
import '../Login/login.css';
import TableLayout from '../../Layouts/TableLayout/TableLayout';
import Table from '../../../Components/Table/Table';

import {connect} from 'react-redux';
import {next, prev, reset} from '../../../Utilities/HelperFunctions';

import {getTOMatchTypeList, deleteTOMatchType} from './AxioCalls';
import {editTOMatchType} from './action';
import {logout} from '../Login/LoginAction';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';
import Snackbar from '../../../Components/Snackbar/Snackbar';

class TOMatchTypeList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            TOMatchType: [],
            selectAll: false,
            list: [],
            open: false,
            adminpswd: '',
            pageData: {},
            loading: true,
            pageOptions: {
                page: 1,
                pageSize: 10,
                searchString: null,
                sortOrder: null,
                listType: 'Limited',
            },
            sortOptions: [
                {
                    value: 'id',
                    text: 'Match Type Id',
                },
                {
                    value: 'name',
                    text: 'Type Name',
                },
                {
                    value: 'winDenomination',
                    text: 'Win Denomination',
                },
                {
                    value: 'winType',
                    text: 'Win Type',
                },
                {
                    text: 'Entry Fee Type',
                    value: 'entryFeeType',
                },
            ],
        };
    }

    componentWillMount() {
        const {login, history} = this.props;

        if (!login.userDetails.data) {
            history.push('/');
            this.props.logout();
        } else if (login.userDetails.data.role === 'ORGANIZER') {
            history.push('/');
        } else {
            const {pageOptions} = this.state;
            console.log('Fetching the type list');
            this.props.getTOMatchTypeList(pageOptions, login.userDetails.data.accessToken);
        }
    }

    componentDidUpdate(prevProps) {
        const {pageOptions} = this.state;
        const {login, toMatchType} = this.props;

        if (toMatchType.TOMatchTypeListSuccess && !prevProps.toMatchType.TOMatchTypeListSuccess) {
            console.log('Done, Match Type List Fetched');
            this.setState({
                list: toMatchType.TOMatchTypeList.data.data,
                loading: false,
                pageData: toMatchType.TOMatchTypeList.data.pageData,
            });
        } else if (toMatchType.TOMatchTypeListFail && !prevProps.toMatchType.TOMatchTypeListFail) {
            this.setState({
                errorMessage: toMatchType.errorMessage,
                loading: false,
            });
        }
        if (toMatchType.deleteTOMatchTypeSuccess && !prevProps.toMatchType.deleteTOMatchTypeSuccess) {
            this.setState({
                snackbarOpen: true,
                errorMessage: toMatchType.deleteTOMatchType.msg,
                TOMatchType: [],
                loading: false,
            });
            this.props.getTOMatchTypeList(pageOptions, login.userDetails.data.accessToken);
        } else if (toMatchType.deleteTOMatchTypeFail && !prevProps.toMatchType.deleteTOMatchTypeFail) {
            this.setState({
                snackbarOpen: true,
                errorMessage: toMatchType.errorMessage,
                loading: false,
            });
        }
    }

    //store single type details selected for editing
    onEditTOMatchType(id) {
        const {toMatchType, history} = this.props;

        let TOMatchTypes = toMatchType.TOMatchTypeList.data.data;
        const editTOMatchType = TOMatchTypes.filter((type) => type.id === id);
        this.props.editTOMatchType(editTOMatchType[0]);
        history.push('/editTOMatchType/' + id);
    }

    //APi call for search and sort
    onSearchandSort(field, value) {
        const {pageOptions} = this.state;
        const {login} = this.props;
        let options = pageOptions;
        options[field] = value;
        this.setState({pageOptions: options});

        if (field === 'searchString' && value.length > 2) {
            this.props.getTOMatchTypeList(options, login.userDetails.data.accessToken);
        } else if (field === 'sortOrder') {
            console.log('Sort has been triggered!');
            this.props.getTOMatchTypeList(options, login.userDetails.data.accessToken);
        }
    }

    //on click of next in table
    onNext() {
        const {pageData, pageOptions} = this.state;
        const {login} = this.props;
        let options = next(pageOptions, pageData);

        if (options) {
            this.props.getTOMatchTypeList(options, login.userDetails.data.accessToken);
            this.setState({pageOptions: options, loading: true});
        }
    }

    //onclick of prev in table
    onPrev() {
        const {pageOptions} = this.state;
        const {login} = this.props;
        let options = prev(pageOptions);
        if (options) {
            this.props.getTOMatchTypeList(options, login.userDetails.data.accessToken);
            this.setState({pageOptions: options, loading: true});
        }
    }

    onReset() {
        const {pageOptions} = this.state;
        const {login} = this.props;
        let options = reset(pageOptions);
        this.setState({
            pageOptions: options,
        });
        this.props.getTOMatchTypeList(options, login.userDetails.data.accessToken);
    }

    render() {
        const {list, sortOptions, loading, pageData, TOMatchType, errorMessage, snackbarOpen} = this.state;
        const data = list;
        const {searchString, sortOrder, page} = this.state.pageOptions;

        let disableNext = page === pageData.lastPage;

        const columns = [
            {
                Header: 'MatchType ID',
                accessor: 'id',
                width: 200,
                Cell: (props) => (
                    <span className="table-clickable" onClick={this.onEditTOMatchType.bind(this, props.value)}>
                        {props.value === undefined || props.value === '' || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Name',
                accessor: 'name',
                Cell: (props) => (
                    <span>
                        {props.value === undefined || props.value === '' || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Entry Type',
                accessor: 'entryFeeType',
                Cell: (props) => (
                    <span>
                        {props.value === undefined || props.value === '' || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Coin Discount',
                accessor: 'fcCoinDiscountPercent',
                Cell: (props) => (
                    <span>
                        {props.value === undefined || props.value === '' || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Min Participation',
                accessor: 'minParticipationRequired',
                Cell: (props) => (
                    <span>
                        {props.value === undefined || props.value === '' || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Big Wins',
                accessor: 'numberOfBigWinTeams',
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Win In',
                accessor: 'winDenomination',
                Cell: (props) => (
                    <span>
                        {props.value === undefined || props.value === '' || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Win Type',
                accessor: 'winType',
                Cell: (props) => (
                    <span>
                        {props.value === undefined || props.value === '' || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Win Param',
                accessor: 'winParameter',
                Cell: (props) => (
                    <span>
                        {props.value === undefined || props.value === '' || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Win Param Value',
                accessor: 'winParameterValue',
                Cell: (props) => (
                    <span>
                        {props.value === undefined || props.value === '' || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Attrib 1',
                accessor: 'attribute1.name',
                Cell: (props) => (
                    <span>
                        {props.value === undefined || props.value === '' || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'AttribVal 1',
                accessor: 'attribute1Val',
                Cell: (props) => (
                    <span>
                        {props.value === undefined || props.value === '' || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Attrib 2',
                accessor: 'attribute2.name',
                Cell: (props) => (
                    <span>
                        {props.value === undefined || props.value === '' || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'AttribVal 2',
                accessor: 'attribute2Val',
                Cell: (props) => (
                    <span>
                        {props.value === undefined || props.value === '' || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Match Mode',
                accessor: 'matchMode.modeName',
                Cell: (props) => (
                    <span>
                        {props.value === undefined || props.value === '' || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Game',
                accessor: 'game.gameName',

                Cell: (props) => <span>{props.value === undefined || props.value === '' ? '-' : props.value}</span>,
            },
            {
                Header: 'Status',
                accessor: 'status',

                Cell: (props) => <span>{props.value === undefined || props.value === '' ? '-' : props.value}</span>,
            },
        ];
        return (
            <TableLayout
                location="TOMatchType"
                history={this.props.history}
                searchbar="Search MatchType Name"
                dispDelete={TOMatchType.length > 0 ? true : false}
                onsearchChange={this.onSearchandSort.bind(this)}
                sortOptions={sortOptions}
                onReset={this.onReset.bind(this)}
                searchString={searchString}
                sortOrder={sortOrder}
            >
                <Snackbar
                    open={snackbarOpen}
                    message={errorMessage}
                    onClose={() => this.setState({snackbarOpen: false})}
                />
                <LoadingModal open={loading} />

                <Table
                    data={data}
                    columns={columns}
                    page={page}
                    onPrevClick={this.onPrev.bind(this)}
                    onNextClick={this.onNext.bind(this)}
                    disableNext={disableNext}
                ></Table>
            </TableLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        toMatchType: state.toMatchType,
    };
};

export default connect(mapStateToProps, {
    getTOMatchTypeList,
    editTOMatchType,
    deleteTOMatchType,
    logout,
})(TOMatchTypeList);
