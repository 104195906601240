import {
    FETCH_TDS_LIST,
    FETCH_TDS_LIST_SUCCESS,
    FETCH_TDS_LIST_FAIL,
    GET_TDS_UPLOAD_URL,
    GET_TDS_UPLOAD_URL_SUCCESS,
    GET_TDS_UPLOAD_URL_FAIL,
    UPLOAD_TDS_CERTIFICATE,
    UPLOAD_TDS_CERTIFICATE_SUCCESS,
    UPLOAD_TDS_CERTIFICATE_FAIL,
    REVIEW_TDS_CERTIFICATE,
    REVIEW_TDS_CERTIFICATE_SUCCESS,
    REVIEW_TDS_CERTIFICATE_FAIL,
} from './Constants';
import apis from '../../../apiCalls/apis';

export const fetchTdsList = (data, token, clientId) => async (dispatch) => {
    dispatch({type: FETCH_TDS_LIST});
    try {
        const response = await apis.fetchTdsList(data, token, clientId);
        if (response.status === 200) {
            dispatch({type: FETCH_TDS_LIST_SUCCESS, payload: response.data});
        } else {
            dispatch({
                type: FETCH_TDS_LIST_FAIL,
                payload: {msg: response?.data?.msg || 'Something went wrong'},
            });
        }
    } catch (e) {
        if (e.response) {
            dispatch({
                type: FETCH_TDS_LIST_FAIL,
                payload: {msg: e?.response?.data?.msg || 'Something went wrong'},
            });
        } else {
            dispatch({
                type: FETCH_TDS_LIST_FAIL,
                payload: {msg: e?.message || 'Unknown Error Occured'},
            });
        }
    }
};

export const fetchTdsUploadUrl = (data, token, clientId) => async (dispatch) => {
    dispatch({type: GET_TDS_UPLOAD_URL});
    try {
        const response = await apis.fetchTdsUploadUrl(data, token, clientId);
        if (response.status === 200) {
            dispatch({type: GET_TDS_UPLOAD_URL_SUCCESS, payload: response.data});
        } else {
            dispatch({
                type: GET_TDS_UPLOAD_URL_FAIL,
                payload: {msg: response?.data?.msg || 'Something went wrong'},
            });
        }
    } catch (e) {
        if (e.response) {
            dispatch({
                type: GET_TDS_UPLOAD_URL_FAIL,
                payload: {msg: e?.response?.data?.msg || 'Something went wrong'},
            });
        } else {
            dispatch({
                type: GET_TDS_UPLOAD_URL_FAIL,
                payload: {msg: e?.message || 'Unknown Error Occured'},
            });
        }
    }
};

export const uploadTdsCertificate = (data, token, clientId) => async (dispatch) => {
    dispatch({type: UPLOAD_TDS_CERTIFICATE});
    try {
        const response = await apis.uploadTds(data, token, clientId);
        if (response.status === 200) {
            dispatch({type: UPLOAD_TDS_CERTIFICATE_SUCCESS, payload: response.data});
        } else {
            dispatch({
                type: UPLOAD_TDS_CERTIFICATE_FAIL,
                payload: {msg: response?.data?.msg || 'Something went wrong'},
            });
        }
    } catch (e) {
        if (e.response) {
            dispatch({
                type: UPLOAD_TDS_CERTIFICATE_FAIL,
                payload: {msg: e?.response?.data?.msg || 'Something went wrong'},
            });
        } else {
            dispatch({type: UPLOAD_TDS_CERTIFICATE_FAIL, payload: {msg: e?.message || 'Unknown Error Occured'}});
        }
    }
};

export const reviewTdsCertificate = (data, token, clientId) => async (dispatch) => {
    dispatch({type: REVIEW_TDS_CERTIFICATE});
    try {
        const response = await apis.reviewTds(data, token, clientId);
        if (response.status === 200) {
            dispatch({type: REVIEW_TDS_CERTIFICATE_SUCCESS, payload: response.data});
        } else {
            dispatch({
                type: REVIEW_TDS_CERTIFICATE_FAIL,
                payload: {msg: response?.data?.msg || 'Something went wrong'},
            });
        }
    } catch (e) {
        if (e.response) {
            dispatch({
                type: REVIEW_TDS_CERTIFICATE_FAIL,
                payload: {msg: e?.response?.data?.msg || 'Something went wrong'},
            });
        } else {
            dispatch({type: REVIEW_TDS_CERTIFICATE_FAIL, payload: {msg: e?.message || 'Unknown Error Occured'}});
        }
    }
};
