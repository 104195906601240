import React from 'react';
import {timeFormat} from '../../../Utilities/Helpers';

import '../../../Utilities/style.css';
import '../Login/login.css';
import TableLayout from '../../Layouts/TableLayout/TableLayout';
import Table from '../../../Components/Table/Table';

import {connect} from 'react-redux';
import {
    debounce,
    getFiltersSessionStorageItem,
    next,
    prev,
    setFiltersSessionStorageItem,
} from '../../../Utilities/HelperFunctions';

import {getContestList, deleteContest} from './AxioCalls';
import {editContest} from './action';
import {logout} from '../Login/LoginAction';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';
import Snackbar from '../../../Components/Snackbar/Snackbar';
import AddAPhoto from '@material-ui/icons/AddAPhoto';
import AddContestInfluencerModal from './AddContestInfluencerModal';
import FilterInput from '../../../Components/FilterInput';
import {CONTEST_STATUS, ENTER, SCREEN_NAMES} from '../../../Utilities/Constants';
import DropDown from '../../../Components/Dropdown/Dropdown';
import Button from '../../../Components/Button/Button';
import {Checkbox, FormControlLabel} from '@material-ui/core';

class ContestList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contest: [],
            selectAll: false,
            list: [],
            open: false,
            adminpswd: '',
            pageData: {},
            loading: true,
            pageOptions: {
                page: 1,
                pageSize: 40,
                listType: 'ALL',
                ...getFiltersSessionStorageItem(SCREEN_NAMES.CONTESTS),
            },
            sortOptions: [
                {value: 'id', text: 'Contest Id'},
                {value: 'contestType', text: 'Contest Type'},
                {value: 'match', text: 'Match Id'},
                {value: 'updatedAt', text: 'Last Updated'},
                {text: 'Status', value: 'status'},
            ],
        };
        this.poolPrize = this.poolPrize.bind(this);
    }

    componentWillMount() {
        const {login, history} = this.props;
        if (!login.userDetails.data) {
            history.push('/');
            this.props.logout();
        } else {
            this.getContestListDebounce({immediate: true});
        }
    }

    componentDidUpdate(prevProps) {
        const {contest} = this.props;

        if (contest.contestListRequest && !prevProps.contest.contestListRequest) {
            this.setState({loading: true});
        }
        if (contest.contestListSuccess && !prevProps.contest.contestListSuccess) {
            this.setState({
                list: contest.contestList.data.data,
                loading: false,
                pageData: contest.contestList.data.pageData,
            });
        } else if (contest.contestListFail && !prevProps.contest.contestListFail) {
            this.setState({errorMessage: contest.errorMessage, loading: false});
        }
        if (contest.deleteContestSuccess && !prevProps.contest.deleteContestSuccess) {
            this.setState({errorMessage: contest.deleteContest.msg, contest: [], loading: false});
            this.getContestListDebounce({immediate: true});
        } else if (contest.deleteContestFail && !prevProps.contest.deleteContestFail) {
            this.setState({errorMessage: contest.errorMessage, loading: false});
        }

        if (contest.isAddContestInfluencerSuccess && !prevProps.contest.isAddContestInfluencerSuccess) {
            this.setState({addInfluencer: undefined, errorMessage: 'Influencer added successfully'});
            this.getContestListDebounce({immediate: true});
        }
    }

    /**
     * store single contest details selected for editing
     * @param {string} id selected contest id
     */
    onEditContest(id) {
        const {contest, history} = this.props;

        let contests = contest.contestList.data.data;
        const editContest = contests.filter((contest) => contest.id === id);
        this.props.editContest(editContest[0]);
        history.push('/editContest/' + id);
    }

    /**
     * pagination and api call on next click
     */
    onNext() {
        const {pageData, pageOptions} = this.state;
        let options = next(pageOptions, pageData);
        if (options) {
            this.setState({pageOptions: options, loading: true}, () => this.getContestListDebounce({immediate: true}));
        }
    }

    /**
     * pagination and api call on prev click
     */
    onPrev() {
        const {pageOptions} = this.state;
        let options = prev(pageOptions);
        if (options) {
            this.setState({pageOptions: options, loading: true}, () => this.getContestListDebounce({immediate: true}));
        }
    }

    /**
     * reseting search and sort inputs
     */
    onReset = () => {
        this.setState({pageOptions: {page: 1, pageSize: 25, listType: 'ALL'}}, () => {
            this.getContestListDebounce({immediate: true});
        });
    };

    /**
     * calculate pool prize for a contest
     * @param {*} data  row details
     * @param {string} cashValue cash value of contest in row
     */
    poolPrize(data, cashValue) {
        let list = this.state.list;
        let index = data.index;
        let current = list[index];
        let header = data.column.Header;
        let key = '';

        if (header === 'Total Entry Fee Collected') {
            key = current?.totalPoolAmount;
        } else {
            key = current?.entryFeePerTeam;
        }

        if (cashValue === 'COINVALUE' || cashValue === 'COINPERCENT') {
            let pool = key + ' FC';
            return <span>{pool}</span>;
        } else {
            let pool = '' + key;
            return <span>{pool}</span>;
        }
    }

    handleOpenInfluencerModal = (contest) => {
        let data = {contestId: contest.id};
        if (contest.category) {
            data.displayName = contest.category.displayName;
            data.icon = contest.category.icon;
        }
        this.setState({addInfluencer: data});
    };

    getContestListDebounce = debounce(() => {
        const {login, client} = this.props;
        setFiltersSessionStorageItem(SCREEN_NAMES.CONTESTS, this.state.pageOptions);
        this.props.getContestList(this.state.pageOptions, login.userDetails.data.accessToken, client.selectedClient);
    });

    handleFilter = (field, value, debounce = true) => {
        const {pageOptions} = this.state;
        if (field === ENTER) {
            this.getContestListDebounce({immediate: true});
            return;
        }
        pageOptions[field] = value;
        this.setState({pageOptions: {...pageOptions, page: 1}}, () => {
            this.getContestListDebounce(debounce ? {} : {immediate: true});
        });
    };

    render() {
        const {list, loading, pageData, errorMessage, addInfluencer} = this.state;
        const data = list;
        const {
            searchString,
            sortOrder,
            page,
            hasContestSpecificDiscount = false,
            contestStatus,
        } = this.state.pageOptions;

        let disableNext = page === pageData.lastPage;

        const {
            contestWrite,
            contestSlotFillRate,
            contestDiscountCode,
            contestUserType,
            contestAddInfluencer,
            contestContestSpecificDiscount,
        } = this.props?.login?.permissions || {};

        const columns = [
            {
                Header: 'Match ID',
                accessor: 'match',
                width: 200,
                Cell: (props) => (
                    <span>
                        {' '}
                        {props.value === undefined || props.value === '' || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Contest ID',
                accessor: 'id',
                width: 200,
                Cell: (props) => (
                    <span className="table-clickable" onClick={this.onEditContest.bind(this, props.value)}>
                        {props.value === undefined || props.value === '' || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Contest Type',
                accessor: 'contestTypeName',
                width: 200,
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'User Type',
                accessor: 'eligibleUsers',
                show: !!contestUserType,
                Cell: (props) => <span>{props.value || ''}</span>,
            },
            {
                Header: 'prize pool type',
                accessor: 'prizePoolData',
                Cell: (props) => <span>{props?.value?.type || '-'}</span>,
            },
            {
                Header: 'Match Start Time',
                accessor: 'matchStartTime',
                Cell: (props) => (
                    <span className="date-space">
                        {props.value === undefined || props.value === '' ? '-' : timeFormat(props.value)}
                    </span>
                ),
            },
            {
                Header: 'Last Updated',
                accessor: 'updatedAt',
                Cell: (props) => (
                    <span className="date-space">
                        {props.value === undefined || props.value === '' ? '-' : timeFormat(props.value)}
                    </span>
                ),
            },
            {
                Header: 'Teams',
                accessor: 'fcTeamsCount',
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: '# of Slots',
                accessor: 'totalSlots',
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: '# of Winning Slots',
                accessor: 'numberOfBigWinSlots',
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: '# Min Slots',
                accessor: 'minNumberOfTeams',
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Entry Fee / Team',
                accessor: 'entryFeePerTeam',
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : ' ' + props.value}
                    </span>
                ),
            },
            {
                Header: 'Total Entry Fee Collected',
                accessor: 'valueType',
                Cell: (props) => this.poolPrize(props, props.value),
            },
            {
                Header: 'slot fill rate @cron',
                accessor: 'contestSpeDisc',
                show: !!contestSlotFillRate,
                Cell: (props) => <span>{props?.value?.slotFillPercentage || '-'}</span>,
            },
            {
                Header: 'Discount Codes',
                accessor: 'contestSpeDisc',
                show: !!contestDiscountCode,
                Cell: (props) => <span>{props?.value?.discountCodes?.join(', ') || '-'}</span>,
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Add Influencer',
                accessor: 'id',
                show: !!contestAddInfluencer,
                Cell: (props) => (
                    <span>
                        <AddAPhoto
                            titleAccess="Add Contest Influencer"
                            className="clickable-icon mr-5"
                            fontSize="small"
                            onClick={() => this.handleOpenInfluencerModal(props.original)}
                        />
                    </span>
                ),
            },
        ];
        return (
            <TableLayout hideSearchBar location="Contests" history={this.props.history}>
                {!!errorMessage && (
                    <Snackbar
                        open={!!errorMessage}
                        message={errorMessage}
                        onClose={() => this.setState({errorMessage: ''})}
                    />
                )}
                {!!loading && <LoadingModal open={loading} />}

                {!!addInfluencer && (
                    <AddContestInfluencerModal
                        open={true}
                        addInfluencer={addInfluencer}
                        handleClose={() => this.setState({addInfluencer: undefined})}
                    />
                )}

                <div className="filter-container my-10">
                    <FilterInput
                        placeholder="Match ID / Contest ID"
                        value={searchString || ''}
                        onChange={(value) => this.handleFilter('searchString', value)}
                        onEnter={() => this.handleFilter(ENTER)}
                    />
                    <DropDown
                        label="Sort"
                        value={sortOrder || ''}
                        menuItems={this.state.sortOptions}
                        onChange={(value) => this.handleFilter('sortOrder', value, false)}
                    />
                    <DropDown
                        label="Contest Status"
                        value={contestStatus || ''}
                        menuItems={CONTEST_STATUS}
                        onChange={(value) => this.handleFilter('contestStatus', value, false)}
                    />
                    {!!contestContestSpecificDiscount && (
                        <FormControlLabel
                            className="mb-15"
                            label="Contest Specific Discounts"
                            control={
                                <Checkbox
                                    checked={hasContestSpecificDiscount}
                                    onChange={(e) =>
                                        this.handleFilter('hasContestSpecificDiscount', e.target.checked, false)
                                    }
                                />
                            }
                        />
                    )}
                    <Button buttonText="Reset" onClick={this.onReset} />
                </div>
                <Table
                    data={data}
                    columns={columns}
                    page={page}
                    onPrevClick={this.onPrev.bind(this)}
                    onNextClick={this.onNext.bind(this)}
                    disableNext={disableNext}
                ></Table>
            </TableLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        squad: state.squad,
        contest: state.contest,
        client: state.client,
    };
};

export default connect(mapStateToProps, {getContestList, editContest, deleteContest, logout})(ContestList);
