import makeRequest from './makeRequest';
import {GLACIER_AUTH, GLACIER_URL, REQUEST_METHODS, URL} from '../Utilities/Constants';
import {jsonToForm} from '../Utilities/HelperFunctions';

export default {
    // login
    loginV2: (data, clientId) => makeRequest(`${URL}/loginV2`, {method: REQUEST_METHODS.POST, data}, clientId),
    loggedInDetails: (data = {}, token, clientId) =>
        makeRequest(`${URL}/getMyLoggedInDetails`, {method: REQUEST_METHODS.GET, data}, token, clientId),

    // access control
    getAdminUser: ({id, ...data}, token, clientId) =>
        makeRequest(`${URL}/adminUser/${id}`, {method: REQUEST_METHODS.GET, data}, token, clientId),
    updateAdminUser: (data, token, clientId) =>
        makeRequest(`${URL}/adminUser/update`, {method: REQUEST_METHODS.POST, data}, token, clientId),

    // matches
    fetchCommonMatchesList: (data, token, clientId) =>
        makeRequest(`${URL}/match/common/list`, {method: REQUEST_METHODS.POST, data}, token, clientId),
    fetchCommonMatch: (data, token, clientId) =>
        makeRequest(`${URL}/match/common/get`, {method: REQUEST_METHODS.POST, data}, token, clientId),
    addCommonMatch: (data, token, clientId) =>
        makeRequest(`${URL}/match/common/create`, {method: REQUEST_METHODS.POST, data}, token, clientId),
    editCommonMatch: (data, token, clientId) =>
        makeRequest(`${URL}/match/common/update`, {method: REQUEST_METHODS.POST, data}, token, clientId),
    deleteCommonMatch: (data, token, clientId) =>
        makeRequest(`${URL}/match/common/delete`, {method: REQUEST_METHODS.POST, data}, token, clientId),

    // switch
    fetchSwitchConfigsList: (data, token, clientId) =>
        makeRequest(`${URL}/switch/config/get`, {method: REQUEST_METHODS.GET, data}, token, clientId),
    addSwitchConfig: (data, token, clientId) =>
        makeRequest(`${URL}/switch/config/add`, {method: REQUEST_METHODS.POST, data}, token, clientId),

    // users
    userKycManualAction: (data, token, clientId) =>
        makeRequest(`${URL}/manualActionOnKyc`, {method: REQUEST_METHODS.POST, data}, token, clientId),

    // withdrawals
    withdrawalRequestAction: (data, token, clientId) =>
        makeRequest(`${URL}/actionOnWithdrawalRequest`, {method: REQUEST_METHODS.POST, data}, token, clientId),

    // others

    getMatchList: (data, token, clientId) =>
        makeRequest(`${URL}/match/list`, {method: REQUEST_METHODS.POST, data}, token, clientId),

    commonUploadImage: (data, token, clientId) =>
        makeRequest(`${URL}/common/uploadImage`, {method: REQUEST_METHODS.POST, data}, token, clientId),

    getBoosters: (data, token, clientId) =>
        makeRequest(`${URL}/boosters`, {method: REQUEST_METHODS.GET, data}, token, clientId),
    getBooster: ({id, ...data}, token, clientId) =>
        makeRequest(`${URL}/booster/${id}`, {method: REQUEST_METHODS.GET, data}, token, clientId),
    createBooster: (data, token, clientId) =>
        makeRequest(`${URL}/createBooster`, {method: REQUEST_METHODS.POST, data: jsonToForm(data)}, token, clientId),

    getBoosterConfigs: (data, token, clientId) =>
        makeRequest(`${URL}/boosterConfigs`, {method: REQUEST_METHODS.GET, data}, token, clientId),
    getBoosterConfig: ({id, ...data}, token, clientId) =>
        makeRequest(`${URL}/boosterConfig/${id}`, {method: REQUEST_METHODS.GET, data}, token, clientId),
    createBoosterConfig: (data, token, clientId) =>
        makeRequest(`${URL}/createBoosterConfig`, {method: REQUEST_METHODS.POST, data}, token, clientId),
    updateBoosterConfig: (data, token, clientId) =>
        makeRequest(`${URL}/updateBoosterConfig`, {method: REQUEST_METHODS.POST, data}, token, clientId),

    getMultiplierConfigs: (data, token, clientId) =>
        makeRequest(`${URL}/getMultiplierConfigs`, {method: REQUEST_METHODS.GET, data}, token, clientId),

    fetchTdsList: (data, token, clientId) =>
        makeRequest(`${URL}/TDS/list`, {method: REQUEST_METHODS.POST, data}, token, clientId),
    fetchTdsUploadUrl: (data, token, clientId) =>
        makeRequest(`${URL}/getTDSUploadUrl`, {method: REQUEST_METHODS.POST, data}, token, clientId),
    uploadTds: (data, token, clientId) =>
        makeRequest(`${URL}/TDS/upload`, {method: REQUEST_METHODS.POST, data}, token, clientId),
    reviewTds: (data, token, clientId) =>
        makeRequest(`${URL}/TDS/review`, {method: REQUEST_METHODS.POST, data}, token, clientId),

    matchCompleteV2: (data, token, clientId) =>
        makeRequest(`${URL}/match/completeV2`, {method: REQUEST_METHODS.POST, data: data}, token, clientId),
    matchCompileV2: (data, token, clientId) =>
        makeRequest(`${URL}/match/compileV2`, {method: REQUEST_METHODS.POST, data: data}, token, clientId),
    winningDisbursalDetails: ({matchId, ...data}, token, clientId) =>
        makeRequest(
            `${URL}/match/winningDisbursalDetails/${matchId}`,
            {method: REQUEST_METHODS.POST, data: data},
            token,
            clientId,
        ),
    updateMatchType: (data, token, clientId) =>
        makeRequest(`${URL}/match/updateMatchType`, {method: REQUEST_METHODS.POST, data: data}, token, clientId),

    substitutePlayer: (data, token, clientId) =>
        makeRequest(`${URL}/match/substitutePlayers`, {method: REQUEST_METHODS.POST, data}, token, clientId),

    playerFreezeUnfreeze: (data, token, clientId) =>
        makeRequest(`${URL}/player/changeSubStatus`, {method: REQUEST_METHODS.POST, data}, token, clientId),

    addAndRemoveFRInMatch: (data, token, clientId) =>
        makeRequest(`${URL}/match/addAndRemoveFRInMatch`, {method: REQUEST_METHODS.POST, data}, token, clientId),
    matchDraftToLive: ({matchId, ...data}, token, clientId) =>
        makeRequest(`${URL}/match/draftToLive/${matchId}`, {method: REQUEST_METHODS.POST, data}, token, clientId),
    publishMatchSummary: ({matchId, ...data}, token, clientId) =>
        makeRequest(
            `${URL}/match/publishMatchSummary/${matchId}`,
            {method: REQUEST_METHODS.POST, data},
            token,
            clientId,
        ),

    userKycList: (data, token, clientId) =>
        makeRequest(`${URL}/userKyc/list`, {method: REQUEST_METHODS.GET, data}, token, clientId),
    userBankList: (data, token, clientId) =>
        makeRequest(`${URL}/userBank/list`, {method: REQUEST_METHODS.GET, data}, token, clientId),

    getGlacierTournaments: (data, clientId) =>
        makeRequest(`${GLACIER_URL}/tournaments`, {method: REQUEST_METHODS.GET, data}, GLACIER_AUTH, clientId),
    getGlacierSeries: (data, clientId) =>
        makeRequest(`${GLACIER_URL}/series`, {method: REQUEST_METHODS.GET, data}, GLACIER_AUTH, clientId),
    getGlacierMatches: (data, clientId) =>
        makeRequest(`${GLACIER_URL}/matches`, {method: REQUEST_METHODS.GET, data}, GLACIER_AUTH, clientId),

    dynamicPrizePools: (data, token, clientId) =>
        makeRequest(`${URL}/dynamicPrizePools`, {method: REQUEST_METHODS.GET, data}, token, clientId),
    dynamicPrizePool: ({templateId, ...data}, token, clientId) =>
        makeRequest(`${URL}/dynamicPrizePool/${templateId}`, {method: REQUEST_METHODS.GET, data}, token, clientId),
    createDynamicPrizePool: (data, token, clientId) =>
        makeRequest(
            `${URL}/dynamicPrizePool/create`,
            {method: REQUEST_METHODS.POST, data: jsonToForm(data)},
            token,
            clientId,
        ),
    updateDynamicPrizePool: (data, token, clientId) =>
        makeRequest(`${URL}/dynamicPrizePool/update`, {method: REQUEST_METHODS.POST, data}, token, clientId),
    verifySwitchLeaderboardPoints: ({matchId, ...data}, token, clientId) =>
        makeRequest(
            `${URL}/match/verifySwitchLeaderboardPoints/${matchId}`,
            {method: REQUEST_METHODS.GET, data},
            token,
            clientId,
        ),

    importTournament: (data, token, clientId) =>
        makeRequest(
            `${URL}/tournament/organizer/importTournament`,
            {method: REQUEST_METHODS.POST, data},
            token,
            clientId,
        ),
};
