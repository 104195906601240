import axios from 'axios';
import {
    listContests,
    listContestsSuccess,
    listContestsFail,
    addContest,
    addContestSuccess,
    addContestFail,
    updateContestRequest,
    updateContestSuccess,
    updateContestFail,
    deleteContestRequest,
    deleteContestSuccess,
    deleteContestFail,
    getContestRequest,
    getContestSuccess,
    getContestFail,
    copyContest,
    copyContestSuccess,
    copyContestFail,
} from './action';
import {URL} from '../../../Utilities/Constants';

import {
    UPLOAD_INFLUENCER_IMAGE,
    UPLOAD_INFLUENCER_IMAGE_SUCCESS,
    UPLOAD_INFLUENCER_IMAGE_FAIL,
    ADD_CONTEST_INFLUENCER,
    ADD_CONTEST_INFLUENCER_SUCCESS,
    ADD_CONTEST_INFLUENCER_FAIL,
} from './Constants';

/**
 * get contest list api call
 * @param {*} contest page details
 * @param {string} token user token
 */
export function getContestList(contest, token, clientId) {
    // listing all contest
    return (dispatch) => {
        dispatch(listContests());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token, clientid: clientId},
            url: `${URL}/contest/list`,
            data: contest,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(listContestsSuccess(data));
                } else {
                    dispatch(listContestsFail(data.data.errorData.errorReason));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(listContestsFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(listContestsFail(err.message || 'Something went wrong'));
                }
            });
    };
}

/**
 * creating new contest api call
 * @param {*} contest contest details
 * @param {*} token user token
 */
export function createContest(contest, token, clientId) {
    return (dispatch) => {
        dispatch(addContest());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token, clientid: clientId},
            url: `${URL}/contest/create`,
            data: contest,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(addContestSuccess(data));
                } else {
                    dispatch(addContestFail(data.data.errorData.errorReason));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(addContestFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(addContestFail(err.message || 'Something went wrong'));
                }
            });
    };
}

/**
 * contest update api call
 * @param {*} contest contest details
 * @param {*} token user token
 */
export function updateContest(contest, token, clientId) {
    return (dispatch) => {
        dispatch(updateContestRequest());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token, clientid: clientId},
            url: `${URL}/contest/update`,
            data: contest,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(updateContestSuccess(data));
                } else {
                    dispatch(updateContestFail(data.data.errorData.errorReason));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(updateContestFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(updateContestFail(err.message || 'Something went wrong'));
                }
            });
    };
}

//contest delete
/**
 * delete contest api request
 * @param {*} id contest id
 * @param {*} token user token
 */
export function deleteContest(id, token, clientId) {
    return (dispatch) => {
        dispatch(deleteContestRequest());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token, clientid: clientId},
            url: `${URL}/contest/delete`,
            data: id,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(deleteContestSuccess(data));
                } else {
                    dispatch(deleteContestFail(data.data.errorData.errorReason));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(deleteContestFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(deleteContestFail(err.message || 'Something went wrong'));
                }
            });
    };
}

/**
 * api request to get single squad details
 * @param {*} id contest id
 * @param {*} token user token
 */
export function getContest(id, token, clientId) {
    return (dispatch) => {
        dispatch(getContestRequest());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token, clientid: clientId},
            url: `${URL}/contest/get`,
            data: {
                contestId: id,
            },
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(getContestSuccess(data));
                } else {
                    dispatch(getContestFail(data.data.errorData.errorReason));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(getContestFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(getContestFail(err.message || 'Something went wrong'));
                }
            });
    };
}

/**
 * copy all contest from give matchId api call
 * @param {*} data contest details
 * @param {*} token user token
 */
export function copyContestFromMatch(data, token, clientId) {
    return (dispatch) => {
        dispatch(copyContest());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token, clientid: clientId},
            url: `${URL}/contest/copy/${data.existMatchId}`,
            data: data.body,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(copyContestSuccess(data));
                } else {
                    dispatch(copyContestFail(data.data.errorData.errorReason));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(copyContestFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(copyContestFail(err.message || 'Something went wrong'));
                }
            });
    };
}

export const uploadInfluencerImage = (data, token, clientId) => (dispatch) => {
    dispatch({type: UPLOAD_INFLUENCER_IMAGE});
    axios({
        method: 'post',
        headers: {Authorization: 'Bearer ' + token, clientid: clientId},
        url: `${URL}/contest/influencerImageUpload`,
        data: data,
    })
        .then((data) => {
            if (data.data.status === 'success') {
                dispatch({type: UPLOAD_INFLUENCER_IMAGE_SUCCESS, payload: data});
            } else if (data.data.status === 'fail') {
                dispatch({type: UPLOAD_INFLUENCER_IMAGE_FAIL, payload: data.data.errorData});
            }
        })
        .catch((err) => {
            if (err.response) {
                const errorMsg = err?.response?.data?.msg || err.message;
                dispatch({
                    type: UPLOAD_INFLUENCER_IMAGE_FAIL,
                    payload: {errorReason: errorMsg || 'Something went wrong'},
                });
            } else {
                dispatch({
                    type: UPLOAD_INFLUENCER_IMAGE_FAIL,
                    payload: {errorReason: err.message || 'Something went wrong'},
                });
            }
        });
};

export const addContestInfluencer = (data, token, clientId) => (dispatch) => {
    dispatch({type: ADD_CONTEST_INFLUENCER});
    axios({
        method: 'post',
        headers: {Authorization: 'Bearer ' + token, clientid: clientId},
        url: `${URL}/contest/markContestAsInfluencer`,
        data: data,
    })
        .then((data) => {
            if (data.data.status === 'success') {
                dispatch({type: ADD_CONTEST_INFLUENCER_SUCCESS, payload: data});
            } else if (data.data.status === 'fail') {
                dispatch({type: ADD_CONTEST_INFLUENCER_FAIL, payload: data.data.errorData});
            }
        })
        .catch((err) => {
            if (err.response) {
                const errorMsg = err?.response?.data?.msg || err.message;
                dispatch({
                    type: ADD_CONTEST_INFLUENCER_FAIL,
                    payload: {errorReason: errorMsg || 'Something went wrong'},
                });
            } else {
                dispatch({
                    type: ADD_CONTEST_INFLUENCER_FAIL,
                    payload: {errorReason: err.message || 'Something went wrong'},
                });
            }
        });
};
