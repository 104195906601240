import {
    MATCH_LIST,
    MATCH_LIST_SUCCESS,
    MATCH_LIST_FAIL,
    ADD_MATCH,
    ADD_MATCH_SUCCESS,
    ADD_MATCH_FAIL,
    UPDATE_MATCH,
    UPDATE_MATCH_FAIL,
    UPDATE_MATCH_SUCCESS,
    UPDATE_PLAYERSTATS,
    UPDATE_PLAYERSTATS_SUCCESS,
    UPDATE_PLAYERSTATS_FAIL,
    DELETE_MATCH,
    DELETE_MATCH_SUCCESS,
    DELETE_MATCH_FAIL,
    GET_MATCH,
    GET_MATCH_SUCCESS,
    GET_MATCH_FAIL,
    EDIT_MATCH,
    CONTEST_MATCH,
    PUBLISH_MATCH,
    PUBLISH_MATCH_SUCCESS,
    PUBLISH_MATCH_FAIL,
    RESCHEDULE_MATCH,
    RESCHEDULE_MATCH_FAIL,
    RESCHEDULE_MATCH_SUCCESS,
} from './Constants';

/**
 * list  match api
 */
export function listMatch() {
    return {
        type: MATCH_LIST,
    };
}

/**
 *handle list match api success
 * @param {*} data success data from api
 */
export function listMatchSuccess(data) {
    return {
        type: MATCH_LIST_SUCCESS,
        payload: data,
    };
}

/**
 *handle list match api failure
 * @param {*} error error from api
 */
export function listMatchFail(error) {
    return {
        type: MATCH_LIST_FAIL,
        payload: error,
    };
}

/**
 * add  match api
 */
export function addMatch() {
    return {
        type: ADD_MATCH,
    };
}
/**
 *handle add match api success
 * @param {*} data success data from api
 */
export function addMatchSuccess(data) {
    return {
        type: ADD_MATCH_SUCCESS,
        payload: data,
    };
}
/**
 *handle add match api failure
 * @param {*} error error from api
 */
export function addMatchFail(error) {
    return {
        type: ADD_MATCH_FAIL,
        payload: error,
    };
}

/**
 * update  match api
 */
export function updateMatchRequest() {
    return {
        type: UPDATE_MATCH,
    };
}
/**
 *handle update match api success
 * @param {*} data data from api
 */
export function updateMatchSuccess(data) {
    return {
        type: UPDATE_MATCH_SUCCESS,
        payload: data,
    };
}
/**
 *handle update match api failure
 * @param {*} error error from api
 */
export function updateMatchFail(error) {
    return {
        type: UPDATE_MATCH_FAIL,
        payload: error,
    };
}
/**
 * update playerStats api
 */
export function updatePlayerStatsRequest() {
    return {
        type: UPDATE_PLAYERSTATS,
    };
}
/**
 *handle update playerStats api success
 * @param {*} data data from api
 */
export function updatePlayerStatsSuccess(data) {
    return {
        type: UPDATE_PLAYERSTATS_SUCCESS,
        payload: data,
    };
}
/**
 *handle update playerStats api failure
 * @param {*} error error from api
 */
export function updatePlayerStatsFail(error) {
    return {
        type: UPDATE_PLAYERSTATS_FAIL,
        payload: error,
    };
}

/**
 * reschedule  match api
 */
export function rescheduleMatchRequest() {
    return {
        type: RESCHEDULE_MATCH,
    };
}
/**
 *handle reschedule match api success
 * @param {*} data data from api
 */
export function rescheduleMatchSuccess(data) {
    return {
        type: RESCHEDULE_MATCH_SUCCESS,
        payload: data,
    };
}
/**
 *handle reschedule match api failure
 * @param {*} error error from api
 */
export function rescheduleMatchFail(error) {
    return {
        type: RESCHEDULE_MATCH_FAIL,
        payload: error,
    };
}

/**
 * delete  match api
 */
export function deleteMatchRequest() {
    return {
        type: DELETE_MATCH,
    };
}
/**
 *handle delete match api success
 * @param {*} data  data from api
 */
export function deleteMatchSuccess(data) {
    return {
        type: DELETE_MATCH_SUCCESS,
        payload: data,
    };
}
/**
 *handle delete match api failure
 * @param {*} error error from api
 */
export function deleteMatchFail(error) {
    return {
        type: DELETE_MATCH_FAIL,
        payload: error,
    };
}

/**
 * save single match data
 * @param {*} match match data
 */
export function editMatch(match) {
    return {
        type: EDIT_MATCH,
        payload: match,
    };
}

/**
 * match related to a contest
 * @param {*} match match details
 */
export function contestMatch(match) {
    return {
        type: CONTEST_MATCH,
        payload: match,
    };
}

/**
 * get  match api
 */
export function getMatchRequest() {
    return {
        type: GET_MATCH,
    };
}
/**
 *handle get match api success
 * @param {*} data success data from api
 */
export function getMatchSuccess(data) {
    return {
        type: GET_MATCH_SUCCESS,
        payload: data,
    };
}
/**
 *handle get match api failure
 * @param {*} error error from api
 */
export function getMatchFail(error) {
    return {
        type: GET_MATCH_FAIL,
        payload: error,
    };
}

/**
 * publish  match api
 */
export function publishMatchRequest() {
    return {
        type: PUBLISH_MATCH,
    };
}
/**
 *handle publish match api success
 * @param {*} data success data from api
 */
export function publishMatchSuccess(data) {
    return {
        type: PUBLISH_MATCH_SUCCESS,
        payload: data,
    };
}
/**
 *handle publish match api failure
 * @param {*} error error from api
 */
export function publishMatchFail(error) {
    return {
        type: PUBLISH_MATCH_FAIL,
        payload: error,
    };
}
