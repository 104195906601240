import React from 'react';
import '../../../Utilities/style.css';
import '../Login/login.css';
import {connect} from 'react-redux';
import DetailLayout from '../../Layouts/DetailLayout/DetailLayout';
import Modal from '../../../Components/Modal/Modal';
import Card from '../../../Components/Card/Card';
import Close from '../../../Images/close_yellow.png';
import Input from '../../../Components/Input/Input';
import DropDown from '../../../Components/Dropdown/Dropdown';
import {createTOMatch, updateTOMatch, deleteTOMatch} from './AxioCalls';
import {getTOGameList} from '../TOGame/AxioCalls';
import {getTOMatchTypeList} from '../TOMatchType/AxioCalls';
import {getTOMatchModeList} from '../TOMatchMode/AxioCalls';
import {getTOMatchAttributeList} from '../TOMatchAttribute/AxioCalls';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Snackbar from '../../../Components/Snackbar/Snackbar';
import {logout} from '../Login/LoginAction';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';
import {timeFormat, isValidNumber, isNumber, isValidString, isvalidTime} from '../../../Utilities/Helpers';
import {MuiPickersUtilsProvider, DateTimePicker} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import Button from '../../../Components/Button/Button';
import MatchDetailsV2 from '../TOPublishedMatch/MatchDetailsV2';
import {Checkbox, FormControlLabel} from '@material-ui/core';

class AddTOMatch extends React.Component {
    constructor(props) {
        super(props);
        let location = this.props.history.location.pathname.includes('edit');
        this.state = {
            name: null,
            startTime: null,
            entryFeePerSlot: null,
            totalSlots: null,
            minFillSlots: 0,
            winPoolType: null,
            winPoolTypeOptions: [
                {text: 'Fixed', value: 'Fixed'},
                {text: 'Percentage', value: 'Percentage'},
            ],
            matchAccess: 'OPEN_TO_ALL',
            matchAccessOptions: [
                {text: 'OPEN_TO_ALL', value: 'OPEN_TO_ALL'},
                {text: 'PRIVATE', value: 'PRIVATE'},
                {text: 'LINKONLY', value: 'LINKONLY'},
            ],

            winFixedPoolSize: 0,
            winPercentPoolSize: 0,
            matchTypeOptions: [],
            matchType: null,
            entryFeeTypeOptions: [
                {text: 'Cash Only', value: 'Cash Only'},
                {text: 'Free', value: 'Free'},
                {text: 'Cash+Coin', value: 'Cash+Coin'},
                {text: 'FcCoin', value: 'FcCoin'},
            ],
            entryFeeType: null,
            fcCoinDiscountPercent: 0,
            minParticipationRequiredOptions: [
                {text: 'True', value: true},
                {text: 'False', value: false},
            ],
            minParticipationRequired: false,
            allowIncompleteTeams: true,
            winDenomination: null,
            winDenominationOptions: [
                {text: 'Cash', value: 'cash'},
                {text: 'Coin', value: 'fcCoin'},
                {text: 'Cash Balance', value: 'cashBalance'},
            ],
            winType: null,
            winTypeOptions: [
                {text: 'Fixed', value: 'Fixed'},
                {text: 'Distribution', value: 'Distribution'},
            ],
            numberOfBigWinTeams: 0,
            winSlots: {},
            winningDistribution: {},
            handlingCharge: null,
            winParameter: null,
            winParameterValue: 0,
            attribute1Options: [],
            attribute1: null,
            attribute1Val: null,
            attribute2Options: [],
            attribute2: null,
            attribute2Val: null,
            matchMode: null,
            matchModeOptions: [],
            matchRules: null,
            status: 'DRAFT',
            gameOptions: [],
            game: null,
            loading: false,
            open: false,
            apiError: '',
            edit: location,
            rankForSorting: null,
            organizerId: null,
            targetUsersFilter_loggedIn: false,
            targetUsersFilter_minJoinDate: new Date().getTime(),
            targetUsersByJoinDate: false,
            targetUsersByReferralCode: false,
            targetUsersByLoginStatus: false,
            booleanOptions: [
                {text: 'True', value: true},
                {text: 'False', value: false},
            ],
            targetUsersFilter_loggedInOptions: [
                {text: 'Logged In', value: true},
                {text: 'Logged Out', value: false},
            ],
            tournamentTypeOption: [
                {text: 'Daily', value: 'daily'},
                {text: 'Monthly', value: 'monthly'},
            ],
        };
    }

    handleInputChange(field, value) {
        const {login} = this.props;
        this.setState({[field]: value, errorMessage: ''});
        if (field === 'targetUsersFilter_minJoinDate') {
            this.setState({
                targetUsersFilter_minJoinDate: new Date(value).getTime(),
            });
        }

        if (field === 'game') {
            this.setState({
                loading: true,
                matchType: null,
            });
            this.props.getTOMatchTypeList({listType: 'ALL'}, login.userDetails.data.accessToken);
            this.props.getTOMatchModeList({listType: 'ALL'}, login.userDetails.data.accessToken);
            this.props.getTOMatchAttributeList({listType: 'ALL'}, login.userDetails.data.accessToken);
        }
        if (field === 'matchType') {
            const curType = this.props.toMatchType.TOMatchTypeList.data.data.find((item) => item.id === value);
            this.setState({
                entryFeeType: curType.entryFeeType,
                fcCoinDiscountPercent: curType.entryFeeType === 'Cash+Coin' ? curType.fcCoinDiscountPercent : 0,
                minParticipationRequired: curType.minParticipationRequired,
                numberOfBigWinTeams: curType.numberOfBigWinTeams,
                winDenomination: curType.winDenomination,
                winType: curType.winType,
                winParameter: curType.winParameter,
                winParameterValue: curType.winParameterValue,
                winSlots: curType.winSlots,
                winningDistribution: curType.winningDistribution,
                attribute1: curType.attribute1,
                attribute1Val: curType.attribute1Val,
                attribute2: curType.attribute2,
                attribute2Val: curType.attribute2Val,
                matchMode: curType.matchMode,
                matchRules: curType.matchRules,
            });
        }
        if (field === 'entryFeeType') {
            if (value === 'Free' || value === 'FcCoin') {
                this.setState({winDenomination: 'fcCoin'});
            } else if (value === 'Cash+Coin' || value === 'Cash Only') {
                this.setState({winDenomination: 'cash'});
            }
        }
        if (field === 'winType') {
            if (value === 'Fixed') {
                this.setState({
                    winSlots: {handlingCharge: null, ranks: {}, splitRemaining: null, smallWinSlots: null},
                    numberOfBigWinTeams: 0,
                });
                if (this.state.edit) {
                    this.setState({
                        winParameter: this.props.toMatchType.editTOMatch.winParameter,
                        winParameterValue: this.props.toMatchType.editTOMatch.winParameterValue,
                    });
                } else {
                    this.setState({
                        winParameter: '',
                        winParameterValue: 0,
                    });
                }
            } else if (value === 'Distribution') {
                this.setState({
                    winParameter: '',
                    winParameterValue: 0,
                });
                if (this.state.edit) {
                    this.setState({
                        winSlots: this.props.toMatch.editTOMatch.winSlots || {
                            handlingCharge: null,
                            ranks: {},
                            splitRemaining: null,
                            smallWinSlots: null,
                        },
                        numberOfBigWinTeams: this.props.toMatch.editTOMatch.numberOfBigWinTeams,
                    });
                } else {
                    this.setState({
                        winSlots: {handlingCharge: null, ranks: {}, splitRemaining: null, smallWinSlots: null},
                        numberOfBigWinTeams: 0,
                    });
                }
            }
        }
        if (field === 'numberOfBigWinTeams') {
            let ranks = {};
            let mainShare = 0;
            for (let i = 1; i <= value; i++) {
                ranks['rank' + i] = this.state.winSlots.ranks['rank' + i];
                mainShare += parseFloat(this.state.winSlots.ranks['rank' + i], 10);
            }
            mainShare += parseFloat(this.state.winSlots.handlingCharge, 10);
            this.setState({
                winSlots: {...this.state.winSlots, ranks: ranks, splitRemaining: 100 - mainShare},
            });
        }
        if (field !== 'rankForSorting' && field.includes('rank')) {
            let ranks = this.state.winSlots.ranks;
            ranks[field] = value;
            let mainShare = parseFloat(this.state.winSlots.handlingCharge, 10) || 0;
            for (let rank in ranks) {
                mainShare += parseFloat(ranks[rank], 10) || 0;
            }
            this.setState({
                winSlots: {...this.state.winSlots, ranks: ranks, splitRemaining: 100 - mainShare},
                errorMessage: '',
            });
        }
        if (field === 'handlingCharge') {
            let ranks = this.state.winSlots.ranks;
            let mainShare = 0;
            for (let rank in ranks) {
                mainShare += parseFloat(ranks[rank], 10);
            }
            this.setState({
                winSlots: {
                    ...this.state.winSlots,
                    handlingCharge: value,
                    splitRemaining: 100 - mainShare - parseFloat(value, 10),
                },
            });
        }
        if (field === 'smallWinSlots') {
            this.setState({
                winSlots: {...this.state.winSlots, smallWinSlots: value},
            });
        }
        if (field === 'matchRules') {
            this.setState({[field]: value.target.value});
        }
        if (field === 'matchAccess') {
            this.setState({matchAccess: value});
        }
    }

    handleEligibilityCheck = (e) => {
        let {tournament_type} = this.state;
        this.setState({
            eligibilityCheck: e.target.checked,
            eligibilityValue: tournament_type === 'monthly' ? 5 : 1,
        });
    };

    displayPrizes(prizes) {
        const {edit, winType, winSlots} = this.state;
        if (winType === 'Fixed') {
            return null;
        }
        let inputs = [];
        for (let i = 1; i <= prizes; i++) {
            let unit = '';
            if (i === 1) {
                unit = i + 'st';
            } else if (i === 2) {
                unit = i + 'nd';
            } else if (i === 3) {
                unit = i + 'rd';
            } else {
                unit = i + 'th';
            }
            let val = 'rank' + i;

            inputs.push(
                <div className="grid-item margin-top-20">
                    <Input
                        label={unit}
                        type="text"
                        name={val}
                        suffix="%"
                        value={winSlots.ranks[val]}
                        onChange={this.handleInputChange.bind(this, val)}
                        placeholder="Enter"
                        disabled={false}
                    />
                </div>,
            );
        }
        return inputs;
    }

    isCreatedFromCC() {
        if (
            this.props.toMatch.editTOMatch.createdByClient &&
            this.props.toMatch.editTOMatch.createdByClient !== 'control_center'
        ) {
            return false;
        }
        return true;
    }

    onSave(new_status) {
        const {
            name,
            startTime,
            totalSlots,
            minFillSlots,
            winPoolType,
            winFixedPoolSize,
            winPercentPoolSize,
            entryFeePerSlot,
            matchType,
            registrationStartTime,
            registrationEndTime,
            entryFeeType,
            fcCoinDiscountPercent,
            minParticipationRequired,
            numberOfBigWinTeams,
            winDenomination,
            winType,
            winParameter,
            winParameterValue,
            winSlots,
            winningDistribution,
            attribute1,
            attribute1Val,
            attribute2,
            attribute2Val,
            matchRules,
            matchMode,
            status,
            game,
            edit,
            matchAccess,
            allowIncompleteTeams,
            rankForSorting,
            organizerId,
            targetUsersByJoinDate,
            targetUsersByLoginStatus,
            targetUsersFilter_loggedIn,
            targetUsersFilter_minJoinDate,
            targetUsersByReferralCode,
            matchReferralCode,
            tournament_type,
            eligibilityCheck,
            eligibilityValue,
        } = this.state;
        const {login, toMatch, toMatchType} = this.props;

        // if (edit && !this.isCreatedFromCC()) {
        //     this.setState({
        //         errorMessage: "Updates not allowed for match created from "+this.props.toMatch.editTOMatch.createdByClient,
        //     });
        //     return;
        // }

        let error = false;
        if (name === null || name === '' || name === undefined) {
            error = true;
            this.setState({errorMessage: 'Please enter the match Name'});
        } else if (startTime === null || startTime === '' || startTime === undefined) {
            error = true;
            this.setState({errorMessage: 'Please enter the match start time'});
        } else if (
            registrationStartTime === null ||
            registrationStartTime === '' ||
            registrationStartTime === undefined
        ) {
            error = true;
            this.setState({errorMessage: 'Please enter the registration start time'});
        } else if (registrationEndTime === null || registrationEndTime === '' || registrationEndTime === undefined) {
            error = true;
            this.setState({errorMessage: 'Please enter the registration end time'});
        } else if (winPoolType === null || winPoolType === '' || winPoolType === undefined) {
            error = true;
            this.setState({errorMessage: 'Please select a valid winning pool type'});
        } else if (
            winPoolType === 'Fixed' &&
            (winFixedPoolSize === null ||
                winFixedPoolSize === '' ||
                winFixedPoolSize === undefined ||
                !isValidNumber(winFixedPoolSize) ||
                winFixedPoolSize <= 0)
        ) {
            error = true;
            this.setState({errorMessage: 'Please enter a valid fixed pool size'});
        } else if (
            winPoolType === 'Percentage' &&
            (winPercentPoolSize === null ||
                winPercentPoolSize === '' ||
                winPercentPoolSize === undefined ||
                !isValidNumber(winPercentPoolSize) ||
                winPercentPoolSize <= 0 ||
                winPercentPoolSize > 100)
        ) {
            error = true;
            this.setState({errorMessage: 'Please enter a valid percentage pool size'});
        } else if (
            entryFeePerSlot === null ||
            entryFeePerSlot === '' ||
            entryFeePerSlot === undefined ||
            !isValidNumber(entryFeePerSlot)
        ) {
            error = true;
            this.setState({errorMessage: 'Please enter valid entry fee per slot'});
        }
        // else if (matchType === null || matchType === "" || matchType === undefined) {
        //     error = true;
        //     this.setState({
        //         errorMessage: "please enter type name"
        //     })
        // }
        else if (entryFeeType === null || entryFeeType === undefined || entryFeeType === '') {
            error = true;
            this.setState({errorMessage: 'please select valid Entry Fee Type'});
        } else if (entryFeeType !== 'Free' && entryFeePerSlot <= 0) {
            error = true;
            this.setState({errorMessage: 'please enter a positive entry fee'});
        } else if (
            entryFeeType === 'Cash+Coin' &&
            (fcCoinDiscountPercent === null ||
                fcCoinDiscountPercent === undefined ||
                fcCoinDiscountPercent === '' ||
                !isNumber(fcCoinDiscountPercent) ||
                fcCoinDiscountPercent <= 0)
        ) {
            error = true;
            this.setState({errorMessage: 'please enter valid Coin Discount'});
        } else if (
            minParticipationRequired === null ||
            minParticipationRequired === undefined ||
            minParticipationRequired === ''
        ) {
            error = true;
            this.setState({errorMessage: 'please select valid minimum participation requirement'});
        } else if (
            minParticipationRequired === true &&
            (minFillSlots === null ||
                minFillSlots === '' ||
                minFillSlots === undefined ||
                !isValidNumber(minFillSlots) ||
                minFillSlots <= 0)
        ) {
            error = true;
            this.setState({errorMessage: 'Please enter valid number of minimum slots to be filled'});
        } else if (winType === null || winType === undefined || winType === '') {
            error = true;
            this.setState({errorMessage: 'please select valid Win Type'});
        } else if (
            winType === 'Distribution' &&
            (numberOfBigWinTeams === null ||
                numberOfBigWinTeams === undefined ||
                numberOfBigWinTeams === '' ||
                !isValidNumber(numberOfBigWinTeams) ||
                numberOfBigWinTeams <= 0)
        ) {
            error = true;
            this.setState({errorMessage: 'please enter valid number of Big Win Teams'});
        } else if (
            winType === 'Distribution' &&
            (winSlots === null ||
                winSlots === undefined ||
                Object.keys(winSlots).length === 0 ||
                !winSlots.hasOwnProperty('handlingCharge') ||
                !winSlots.hasOwnProperty('ranks') ||
                !winSlots.hasOwnProperty('splitRemaining') ||
                !winSlots.hasOwnProperty('smallWinSlots'))
        ) {
            error = true;
            this.setState({errorMessage: 'please recheck your win amount distribution'});
        } else if (
            winType === 'Fixed' &&
            (winParameter === null || winParameter === undefined || winParameter === '')
        ) {
            error = true;
            this.setState({errorMessage: 'please recheck valid win parameter'});
        } else if (
            winType === 'Fixed' &&
            (winParameterValue === null ||
                winParameterValue === undefined ||
                winParameterValue === '' ||
                !isNumber(winParameterValue) ||
                winParameterValue <= 0)
        ) {
            error = true;
            this.setState({errorMessage: 'please enter reward per win parameter'});
        } else if (winDenomination === null || winDenomination === undefined || winDenomination === '') {
            error = true;
            this.setState({errorMessage: 'please select valid win reward denomination'});
        } else if (attribute1 === null || attribute1 === undefined || attribute1 === '') {
            error = true;
            this.setState({errorMessage: 'please select valid attribute 1'});
        } else if (attribute1Val === null || attribute1Val === undefined || attribute1Val === '') {
            error = true;
            this.setState({errorMessage: 'please enter valid attribute 1 value'});
        } else if (attribute2 === null || attribute2 === undefined || attribute2 === '') {
            error = true;
            this.setState({errorMessage: 'please select valid attribute 2'});
        } else if (attribute2Val === null || attribute2Val === undefined || attribute2Val === '') {
            error = true;
            this.setState({errorMessage: 'please enter valid attribute 2 value'});
        } else if (matchMode === null || matchMode === undefined || matchMode === '') {
            error = true;
            this.setState({errorMessage: 'please select valid Match Mode'});
        } else if (
            totalSlots === null ||
            totalSlots === '' ||
            totalSlots === undefined ||
            !isValidNumber(totalSlots) ||
            totalSlots <= 0 ||
            totalSlots %
                this.props.toMatchMode.TOMatchModeList.data.data.find((item) => item.id === matchMode).sizeOfTeam !==
                0 ||
            (winType === 'Distribution' &&
                Math.round(
                    totalSlots /
                        this.props.toMatchMode.TOMatchModeList.data.data.find((item) => item.id === matchMode)
                            .sizeOfTeam,
                ) <
                    numberOfBigWinTeams + Number(winSlots.smallWinSlots))
        ) {
            error = true;
            this.setState({errorMessage: 'Please enter valid number of total slots'});
        } else if (matchRules === null || matchRules === undefined || matchRules === '') {
            error = true;
            this.setState({errorMessage: 'please enter valid Match Rules'});
        } else if (status === null || status === undefined || status === '') {
            error = true;
            this.setState({errorMessage: 'please select valid status'});
        } else if (game === null || game === undefined || game === '') {
            error = true;
            this.setState({errorMessage: 'please select valid game'});
        }
        let distribution = {};
        console.log('Error state is ' + error);
        if (error === false) {
            if (winType === 'Distribution') {
                for (var i = 1; i <= numberOfBigWinTeams; i++) {
                    if (
                        winSlots.ranks['rank' + i] === undefined ||
                        winSlots.ranks['rank' + i] === null ||
                        winSlots.ranks['rank' + i] === '' ||
                        !isValidNumber(winSlots.ranks['rank' + i]) ||
                        winSlots.ranks['rank' + i] <= 0 ||
                        winSlots.ranks['rank' + i] > 100
                    ) {
                        error = true;
                        this.setState({errorMessage: 'Please check Rank ' + i + ' pool distribution'});
                        return;
                    }
                    distribution[i.toString()] = winSlots.ranks['rank' + i];
                }
                if (
                    winSlots.splitRemaining === undefined ||
                    winSlots.splitRemaining === null ||
                    winSlots.splitRemaining === '' ||
                    winSlots.splitRemaining < 0 ||
                    winSlots.splitRemaining > 100
                ) {
                    error = true;
                    this.setState({errorMessage: 'Please check your Pool distribution'});
                    return;
                }
                if (winSlots.smallWinSlots === undefined || winSlots.smallWinSlots === null) {
                    if (winSlots.splitRemaining > 0) {
                        if (
                            winSlots.smallWinSlots === '' ||
                            !isValidNumber(winSlots.smallWinSlots) ||
                            winSlots.smallWinSlots <= 0
                        ) {
                            error = true;
                            this.setState({errorMessage: 'Invalid number of small win slots'});
                            return;
                        }
                    } else if (winSlots.splitRemaining === 0) {
                        this.setState({winSlots: {...winSlots, smallWinSlots: 0}});
                    }
                }
                for (
                    var i = Number(numberOfBigWinTeams) + 1;
                    i <= Number(numberOfBigWinTeams) + Number(winSlots.smallWinSlots);
                    i++
                ) {
                    distribution[i] = winSlots.splitRemaining / winSlots.smallWinSlots;
                }
            }
        }
        console.log('Error state is ' + error);
        if (error === false) {
            let ts = moment(startTime).valueOf();
            let match = {
                name: name,
                startTime: moment(startTime).format('DD/MM/YYYY H:mm'),
                timestamp: ts,
                registrationStartTime: moment(registrationStartTime).valueOf(),
                registrationEndTime: moment(registrationEndTime).valueOf(),
                matchAccess: matchAccess,
                entryFeePerSlot: parseInt(entryFeePerSlot),
                totalSlots: parseInt(totalSlots),
                minFillSlots: parseInt(minFillSlots),
                winPoolType: winPoolType,
                winFixedPoolSize: parseInt(winFixedPoolSize),
                winPercentPoolSize: parseInt(winPercentPoolSize),
                matchType: matchType,
                entryFeeType: entryFeeType,
                fcCoinDiscountPercent: entryFeeType === 'Cash+Coin' ? parseInt(fcCoinDiscountPercent) : 0,
                minParticipationRequired: minParticipationRequired,
                allowIncompleteTeams: allowIncompleteTeams,
                winDenomination: winDenomination,
                winType: winType,
                numberOfBigWinTeams: parseInt(numberOfBigWinTeams),
                winSlots: winSlots,
                winningDistribution: distribution,
                winParameter: winParameter,
                winParameterValue: parseInt(winParameterValue),
                attribute1: attribute1,
                attribute1Val: attribute1Val,
                attribute2: attribute2,
                attribute2Val: attribute2Val,
                matchMode: matchMode,
                matchRules: matchRules,
                status: new_status === 'UPCOMING' ? 'UPCOMING' : 'DRAFT',
                game: game,
                rankForSorting: rankForSorting === '' ? 0 : parseInt(rankForSorting),
                targetUsers: targetUsersByJoinDate || targetUsersByLoginStatus || !!matchReferralCode,
                targetUsersFilter: {},
                tournament_type: '',
                eligibility: {},
            };
            if (targetUsersByLoginStatus) {
                match.targetUsersFilter.loggedIn = targetUsersFilter_loggedIn;
            }
            if (targetUsersByJoinDate) {
                match.targetUsersFilter.minJoinDate = targetUsersFilter_minJoinDate;
            }
            if (targetUsersByReferralCode && matchReferralCode) {
                match.targetUsersFilter.matchReferralCode = (matchReferralCode || '').split(',');
            }
            if (tournament_type && eligibilityCheck) {
                let eliValue = eligibilityValue ? eligibilityValue : tournament_type === 'monthly' ? 5 : 1;
                match.eligibility = {
                    type: 'MIN_FY_COUNT',
                    value: eliValue,
                    timeFrameInDays: tournament_type === 'monthly' ? '30' : '1',
                };
                match.tournament_type = tournament_type;
            }

            if (organizerId) {
                match.organizer = organizerId;
            }
            this.setState({loading: true});
            console.log('Trying to update the data in the server');
            if (edit) {
                match.id = toMatch.editTOMatch.id;
                this.props.updateTOMatch(match, login.userDetails.data.accessToken);
            } else {
                match.createdByClient = 'control_center';
                this.props.createTOMatch(match, login.userDetails.data.accessToken);
            }
        }
    }

    componentDidMount() {
        const {login, toMatch, history, client} = this.props;
        let gamePageOptions = {
            listType: 'ALL',
        };
        if (!login.userDetails.data) {
            history.push('/');
            this.props.logout();
        } else {
            this.setState({
                loading: true,
            });
            this.props.getTOGameList(gamePageOptions, login.userDetails.data.accessToken, client.selectedClient);
            if (this.state.edit) {
                const {
                    name,
                    startTime,
                    entryFeePerSlot,
                    totalSlots,
                    minFillSlots,
                    winPoolType,
                    winFixedPoolSize,
                    winPercentPoolSize,
                    matchType,
                    entryFeeType,
                    fcCoinDiscountPercent,
                    minParticipationRequired,
                    numberOfBigWinTeams,
                    winDenomination,
                    winType,
                    winParameter,
                    winParameterValue,
                    winningDistribution,
                    winSlots,
                    attribute1,
                    attribute1Val,
                    attribute2,
                    attribute2Val,
                    matchRules,
                    matchMode,
                    status,
                    game,
                    matchAccess,
                    allowIncompleteTeams,
                    rankForSorting,
                    targetUsers,
                    targetUsersFilter,
                    tournament_type,
                    eligibility,
                    registrationStartTime,
                    registrationEndTime,
                } = toMatch.editTOMatch;

                this.props.getTOMatchTypeList({listType: 'ALL'}, login.userDetails.data.accessToken);
                this.props.getTOMatchModeList({listType: 'ALL'}, login.userDetails.data.accessToken);
                this.props.getTOMatchAttributeList({listType: 'ALL'}, login.userDetails.data.accessToken);
                let targetUsersByJoinDate = false;
                let targetUsersByLoginStatus = false;
                let targetUsersByReferralCode = false;
                let targetUsersFilter_loggedIn = false;
                let targetUsersFilter_minJoinDate = new Date().getTime();
                let matchReferralCode = '';
                if (targetUsers) {
                    if (targetUsersFilter.loggedIn != undefined) {
                        targetUsersByLoginStatus = true;
                        targetUsersFilter_loggedIn = targetUsersFilter.loggedIn;
                    }
                    if (targetUsersFilter.minJoinDate != undefined) {
                        targetUsersByJoinDate = true;
                        targetUsersFilter_minJoinDate = targetUsersFilter.minJoinDate;
                    }

                    if (targetUsersFilter.matchReferralCode) {
                        targetUsersByReferralCode = true;
                        matchReferralCode = (targetUsersFilter.matchReferralCode || []).join(',');
                    }
                }
                this.setState({
                    name: name,
                    startTime: startTime,
                    entryFeePerSlot: entryFeePerSlot,
                    totalSlots: totalSlots,
                    matchAccess: matchAccess,
                    minFillSlots: minFillSlots,
                    winPoolType: winPoolType,
                    winFixedPoolSize: winFixedPoolSize,
                    winPercentPoolSize: winPercentPoolSize,
                    matchType: matchType,
                    entryFeeType: entryFeeType,
                    fcCoinDiscountPercent: entryFeeType === 'Cash+Coin' ? parseInt(fcCoinDiscountPercent) : 0,
                    minParticipationRequired: minParticipationRequired,
                    allowIncompleteTeams: allowIncompleteTeams,
                    numberOfBigWinTeams: numberOfBigWinTeams,
                    winDenomination: winDenomination,
                    winType: winType,
                    winParameter: winParameter,
                    winParameterValue: winParameterValue,
                    winningDistribution: winningDistribution,
                    winSlots: winSlots,
                    attribute1: attribute1 ? attribute1.id : null,
                    attribute1Val: attribute1Val,
                    attribute2: attribute2 ? attribute2.id : null,
                    attribute2Val: attribute2Val,
                    matchRules: matchRules,
                    matchMode: matchMode ? matchMode.id : null,
                    status: status,
                    game: game ? game.id : null,
                    rankForSorting: rankForSorting,
                    targetUsersByJoinDate,
                    targetUsersByLoginStatus,
                    targetUsersFilter_loggedIn,
                    targetUsersFilter_minJoinDate,
                    targetUsersByReferralCode,
                    matchReferralCode,
                    tournament_type,
                    eligibilityCheck: !!eligibility && !!eligibility.value,
                    eligibilityValue: !!eligibility ? eligibility.value : '',
                    registrationStartTime,
                    registrationEndTime,
                });
            }
        }
    }

    componentDidUpdate(prevProps) {
        const {toMatch, toMatchType, toGame, toMatchMode, toMatchAttribute, history, login} = this.props;
        const {game} = this.state;

        if (toGame.TOGameListSuccess && !prevProps.toGame.TOGameListSuccess) {
            this.setState({
                gameOptions: toGame.TOGameList.data.data.map((item) => {
                    return {text: item.gameName, value: item.id};
                }),
                loading: false,
            });
        } else if (toGame.TOGameListFail && !prevProps.toGame.TOGameListFail) {
            this.setState({errorMessage: toGame.errorMessage, loading: false});
        }

        if (toMatchMode.TOMatchModeListSuccess && !prevProps.toMatchMode.TOMatchModeListSuccess) {
            this.setState({
                matchModeOptions: toMatchMode.TOMatchModeList.data.data
                    .filter((item) => item.game === game)
                    .map((item) => {
                        return {text: item.modeName, value: item.id};
                    }),
            });
            if (this.state.edit) {
                if (this.props.toMatch.editTOMatch.matchMode) {
                    this.setState({
                        matchMode: this.props.toMatch.editTOMatch.matchMode.id,
                    });
                }
            }
            if (toMatchAttribute.TOMatchAttributeListSuccess && toMatchType.TOMatchTypeListSuccess) {
                this.setState({loading: false});
            }
        } else if (toMatchMode.TOMatchModeListFail && !prevProps.toMatchMode.TOMatchModeListFail) {
            this.setState({errorMessage: toMatchMode.errorMessage, loading: false});
        }

        if (toMatchAttribute.TOMatchAttributeListSuccess && !prevProps.toMatchAttribute.TOMatchAttributeListSuccess) {
            this.setState({
                attribute1Options: toMatchAttribute.TOMatchAttributeList.data.data
                    .filter((item) => item.game === game)
                    .map((item) => {
                        return {text: item.name, value: item.id};
                    }),
                attribute2Options: toMatchAttribute.TOMatchAttributeList.data.data
                    .filter((item) => item.game === game)
                    .map((item) => {
                        return {text: item.name, value: item.id};
                    }),
            });
            if (this.state.edit) {
                if (this.props.toMatch.editTOMatch.attribute1) {
                    this.setState({
                        attribute1: this.props.toMatch.editTOMatch.attribute1.id,
                    });
                }
            }
            if (toMatchMode.TOMatchModeListSuccess && toMatchType.TOMatchTypeListSuccess) {
                this.setState({loading: false});
            }
        } else if (toMatchAttribute.TOMatchAttributeListFail && !prevProps.toMatchAttribute.TOMatchAttributeListFail) {
            this.setState({errorMessage: toMatchAttribute.errorMessage, loading: false});
        }

        if (toMatchType.TOMatchTypeListSuccess && !prevProps.toMatchType.TOMatchTypeListSuccess) {
            this.setState({
                matchTypeOptions: toMatchType.TOMatchTypeList.data.data
                    .filter((item) => item.game === game)
                    .map((item) => {
                        return {text: item.name, value: item.id};
                    }),
            });
            if (this.state.edit) {
                if (this.props.toMatch.editTOMatch.matchType) {
                    this.setState({
                        matchType: this.props.toMatch.editTOMatch.matchType.id,
                    });
                }
            }
            if (toMatchMode.TOMatchModeListSuccess && toMatchAttribute.TOMatchAttributeListSuccess) {
                this.setState({loading: false});
            }
        } else if (toMatchType.TOMatchTypeListFail && !prevProps.toMatchType.TOMatchTypeListFail) {
            this.setState({
                errorMessage: toMatchType.errorMessage,
                loading: false,
            });
        }

        if (toMatch.addTOMatchSuccess && !prevProps.toMatch.addTOMatchSuccess) {
            history.push('/toMatches');
        } else if (toMatch.addTOMatchFail && !prevProps.toMatch.addTOMatchFail) {
            this.setState({loading: false, errorMessage: toMatch.errorMessage});
        }

        if (toMatch.updateTOMatchSuccess && !prevProps.toMatch.updateTOMatchSuccess) {
            history.push('/toMatches');
        } else if (toMatch.updateTOMatchFail && !prevProps.toMatch.updateTOMatchFail) {
            this.setState({loading: false, errorMessage: toMatch.errorMessage});
        }

        if (toMatch.deleteTOMatchSuccess && !prevProps.toMatch.deleteTOMatchSuccess) {
            this.setState({
                loading: false,
                code: [],
                errorMessage: toMatch.deleteTOMatch.msg,
            });
            history.push('/toMatches');
        }

        if (toMatch.deleteTOMatchFail && !prevProps.toMatch.deleteTOMatchFail) {
            this.setState({loading: false, code: [], errorMessage: toMatch.errorMessage});
        }
    }

    //open modal on delete click
    onDeleteClick() {
        this.setState({open: true});
    }

    //api call to delete selcted code
    onDeleteTOMatch() {
        if (!this.isCreatedFromCC()) {
            this.setState({
                errorMessage:
                    'Delete not allowed for match created from ' + this.props.toMatch.editTOMatch.createdByClient,
            });
            return;
        }

        const {login} = this.props;
        let matchId = this.props.toMatch.editTOMatch.id;
        this.props.deleteTOMatch(matchId, login.userDetails.data.accessToken);
        this.setState({open: false, loading: true});
    }

    onUpdateOrganizerId(organizerId) {
        this.setState({organizerId: organizerId});
    }

    render() {
        const {
            name,
            startTime,
            entryFeePerSlot,
            totalSlots,
            minFillSlots,
            winPoolType,
            winPoolTypeOptions,
            matchTypeOptions,
            matchType,
            winFixedPoolSize,
            winPercentPoolSize,
            entryFeeType,
            entryFeeTypeOptions,
            fcCoinDiscountPercent,
            minParticipationRequired,
            minParticipationRequiredOptions,
            numberOfBigWinTeams,
            winDenomination,
            winDenominationOptions,
            winType,
            winTypeOptions,
            winParameter,
            winParameterValue,
            winSlots,
            attribute1,
            attribute1Options,
            attribute1Val,
            attribute2,
            attribute2Options,
            attribute2Val,
            matchMode,
            matchModeOptions,
            matchRules,
            status,
            statusOptions,
            game,
            gameOptions,
            handlingCharge,
            errorMessage,
            loading,
            open,
            edit,
            matchAccess,
            matchAccessOptions,
            allowIncompleteTeams,
            rankForSorting,
            targetUsersByJoinDate,
            targetUsersByLoginStatus,
            booleanOptions,
            targetUsersFilter_minJoinDate,
            targetUsersFilter_loggedIn,
            targetUsersFilter_loggedInOptions,
            targetUsersByReferralCode,
            matchReferralCode,
            tournament_type,
            tournamentTypeOption,
            eligibilityCheck,
            eligibilityValue,
            registrationStartTime,
            registrationEndTime,
        } = this.state;
        const {history, toMatch, toMatchType, login} = this.props;
        const {toMatchWrite} = login?.permissions || {};

        let title = edit ? 'Edit Match' : 'Add Match';

        let createdByClient;
        if (edit) {
            createdByClient = toMatch.editTOMatch.createdByClient;
        }

        let shrink = undefined;
        return (
            <DetailLayout
                location="AddTOMatch"
                history={history}
                centerTitle={title}
                onSave={!!toMatchWrite ? this.onSave.bind(this) : undefined}
                onPublish={!!toMatchWrite ? this.onSave.bind(this, 'UPCOMING') : undefined}
                matchStatus={status}
                editPage={edit}
                id={edit ? toMatch.editTOMatch.id : null}
                onDeleteClick={!!toMatchWrite ? this.onDeleteClick.bind(this) : undefined}
            >
                <LoadingModal open={loading} />
                <Modal open={open}>
                    <div className="container modal-top-margin">
                        <Card>
                            <div className="disp-flex">
                                <div className="modal-left-header">Delete Match</div>
                                <div onClick={() => this.setState({open: false})}>
                                    <img src={Close} alt="" className="cursor" />
                                </div>
                            </div>
                            <div className="align-center equalcontainer-grids">
                                <div />
                                <div>
                                    <div className="disp-flex align-center margin-top-30">
                                        <div className="margin-right-16">
                                            <Button buttonText="Delete" onClick={this.onDeleteTOMatch.bind(this)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                </Modal>
                <Snackbar
                    open={!!errorMessage}
                    message={errorMessage}
                    onClose={() => this.setState({errorMessage: ''})}
                />

                <div className="detail-container margin-top-20">
                    <div className="grid-item"></div>

                    <div className="grid-item">
                        <Card>
                            <div>
                                <div className="card-grid-container">
                                    <div className="grid-item  margin-top-20">
                                        <Input
                                            label="Match Name"
                                            type="text"
                                            name="name"
                                            value={name}
                                            onChange={this.handleInputChange.bind(this, 'name')}
                                            placeholder="Enter the Match Name"
                                            disabled={false}
                                        />
                                    </div>
                                    <div className="grid-item  margin-top-20">
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <DateTimePicker
                                                margin="normal"
                                                id="date-picker-dialog"
                                                label="Date"
                                                value={startTime}
                                                minDate={new Date()}
                                                disabled={false}
                                                invalidDateMessage={''}
                                                onChange={this.handleInputChange.bind(this, 'startTime')}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>

                                    {/* row */}
                                    <div className="grid-item  margin-top-20">
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <DateTimePicker
                                                margin="normal"
                                                id="date-picker-dialog"
                                                label={'Registration Start Time'}
                                                value={registrationStartTime || null}
                                                minDate={new Date()}
                                                disabled={false}
                                                invalidDateMessage={''}
                                                onChange={this.handleInputChange.bind(this, 'registrationStartTime')}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    <div className="grid-item  margin-top-20">
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <DateTimePicker
                                                margin="normal"
                                                id="date-picker-dialog"
                                                label={'Registration End Time'}
                                                value={registrationEndTime || null}
                                                minDate={registrationStartTime || new Date()}
                                                disabled={false}
                                                invalidDateMessage={''}
                                                onChange={this.handleInputChange.bind(this, 'registrationEndTime')}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>

                                    {/* row */}
                                    {createdByClient ? (
                                        <div className="grid-item  margin-top-20">
                                            <Input
                                                label="Created From"
                                                type="text"
                                                name="name"
                                                value={createdByClient}
                                                placeholder="Created From"
                                                disabled={true}
                                            />
                                        </div>
                                    ) : null}
                                    {createdByClient ? <div className="grid-item  margin-top-20" /> : null}

                                    {/* row2 */}
                                    <div className="grid-item  margin-top-20">
                                        <Input
                                            label="Entry Fee Per Slot"
                                            type="text"
                                            name="entryFeePerSlot"
                                            value={entryFeePerSlot}
                                            onChange={this.handleInputChange.bind(this, 'entryFeePerSlot')}
                                            placeholder="Please enter the entry Fee"
                                            disabled={false}
                                        />
                                    </div>
                                    <div className="grid-item  margin-top-20">
                                        <Input
                                            label="Total Number of Slots"
                                            type="text"
                                            name="totalSlots"
                                            value={totalSlots}
                                            onChange={this.handleInputChange.bind(this, 'totalSlots')}
                                            placeholder="Please enter the number of slots"
                                            disabled={false}
                                        />
                                    </div>

                                    {/* row3 */}
                                    <div className="grid-item  margin-top-20">
                                        <DropDown
                                            label="Type of Reward Pool"
                                            type="text"
                                            menuItems={winPoolTypeOptions}
                                            name="winPoolType"
                                            value={winPoolType}
                                            onChange={this.handleInputChange.bind(this, 'winPoolType')}
                                            placeholder="Please enter type of reward pool"
                                            disabled={false}
                                        />
                                    </div>
                                    {winPoolType === 'Fixed' ? (
                                        <div className="grid-item  margin-top-20">
                                            <Input
                                                label="Enter the Pool Size"
                                                type="text"
                                                name="winFixedPoolSize"
                                                value={winFixedPoolSize}
                                                onChange={this.handleInputChange.bind(this, 'winFixedPoolSize')}
                                                placeholder="Please enter reward pool size"
                                                disabled={false}
                                            />
                                        </div>
                                    ) : winPoolType === 'Percentage' ? (
                                        <div className="grid-item  margin-top-20">
                                            <Input
                                                label="Enter the Pool Size"
                                                type="text"
                                                name="winPercentPoolSize"
                                                value={winPercentPoolSize}
                                                onChange={this.handleInputChange.bind(this, 'winPercentPoolSize')}
                                                placeholder="Please enter reward pool size"
                                                suffix="%"
                                                disabled={false}
                                            />
                                        </div>
                                    ) : (
                                        <div className="grid-item  margin-top-20"></div>
                                    )}

                                    {/* row4 */}
                                    <div className="grid-item  margin-top-20">
                                        <DropDown
                                            label="Game"
                                            type="text"
                                            menuItems={gameOptions}
                                            name="game"
                                            value={game}
                                            onChange={this.handleInputChange.bind(this, 'game')}
                                            placeholder="Select Game"
                                            disabled={edit ? true : false}
                                        />
                                    </div>
                                    {game !== undefined && game !== null && game !== '' ? (
                                        <div className="grid-item  margin-top-20">
                                            <DropDown
                                                label="Match Type"
                                                type="text"
                                                menuItems={matchTypeOptions}
                                                name="matchType"
                                                value={matchType}
                                                onChange={this.handleInputChange.bind(this, 'matchType')}
                                                placeholder="Select Match Type"
                                                disabled={edit ? true : false}
                                            />
                                        </div>
                                    ) : (
                                        <div className="grid-item  margin-top-20"></div>
                                    )}

                                    {/* row5 */}
                                    <div className="grid-item  margin-top-20">
                                        <DropDown
                                            label="Match Access"
                                            type="text"
                                            menuItems={matchAccessOptions}
                                            name="matchAccess"
                                            value={matchAccess}
                                            onChange={this.handleInputChange.bind(this, 'matchAccess')}
                                            placeholder="Enter Match Access"
                                            disabled={false}
                                        />
                                    </div>
                                    <div className="grid-item  margin-top-20" />

                                    <div className="grid-item  margin-top-20">
                                        <DropDown
                                            label="Target Users by login status?"
                                            type="text"
                                            menuItems={booleanOptions}
                                            name="targetUsersByLoginStatus"
                                            value={targetUsersByLoginStatus}
                                            onChange={this.handleInputChange.bind(this, 'targetUsersByLoginStatus')}
                                            placeholder="Target Specific Users"
                                            disabled={false}
                                        />
                                    </div>
                                    <div className="grid-item  margin-top-20">
                                        <DropDown
                                            label="Target Users by join date"
                                            type="text"
                                            menuItems={booleanOptions}
                                            name="targetUsersByJoinDate"
                                            value={targetUsersByJoinDate}
                                            onChange={this.handleInputChange.bind(this, 'targetUsersByJoinDate')}
                                            placeholder="Target Specific Users"
                                            disabled={false}
                                        />
                                    </div>
                                    <div className="grid-item  margin-top-20">
                                        <DropDown
                                            label="Target User by Referral code"
                                            type="text"
                                            menuItems={booleanOptions}
                                            name="targetUsersByReferralCode"
                                            value={targetUsersByReferralCode}
                                            onChange={this.handleInputChange.bind(this, 'targetUsersByReferralCode')}
                                            placeholder="Target Specific Users"
                                            disabled={false}
                                        />
                                    </div>
                                    {targetUsersByLoginStatus ? (
                                        <div className="grid-item  margin-top-20">
                                            <DropDown
                                                label="Target by Log In Status"
                                                type="text"
                                                menuItems={targetUsersFilter_loggedInOptions}
                                                name="targetUsersFilter_loggedIn"
                                                value={targetUsersFilter_loggedIn}
                                                onChange={this.handleInputChange.bind(
                                                    this,
                                                    'targetUsersFilter_loggedIn',
                                                )}
                                                placeholder="Target Specific Users"
                                                disabled={false}
                                            />
                                        </div>
                                    ) : null}
                                    {targetUsersByJoinDate ? (
                                        <div className="grid-item  margin-top-20">
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <DateTimePicker
                                                    margin="normal"
                                                    id="date-picker-dialog"
                                                    label="Target if join date is greater than"
                                                    value={new Date(targetUsersFilter_minJoinDate)}
                                                    disabled={false}
                                                    invalidDateMessage={''}
                                                    onChange={this.handleInputChange.bind(
                                                        this,
                                                        'targetUsersFilter_minJoinDate',
                                                    )}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                    ) : null}

                                    {targetUsersByReferralCode && (
                                        <div className="grid-item margin-top-20 align-right">
                                            <Input
                                                label="Match Referral Code (Comma Separated)"
                                                type="text"
                                                name="matchReferralCode"
                                                value={matchReferralCode}
                                                onChange={this.handleInputChange.bind(this, 'matchReferralCode')}
                                                placeholder="Eg. ABCDE,ASD123,KMN11"
                                            />
                                        </div>
                                    )}

                                    <div className="grid-item  margin-top-20">
                                        <DropDown
                                            label="Tournament Type"
                                            type="text"
                                            menuItems={tournamentTypeOption}
                                            name="tournament_type"
                                            value={tournament_type || ''}
                                            onChange={this.handleInputChange.bind(this, 'tournament_type')}
                                            placeholder="tournament type"
                                        />
                                    </div>
                                    {tournament_type && (
                                        <div className="grid-item  margin-top-20">
                                            <FormControlLabel
                                                label={`Should player play  the contest in last ${tournament_type === 'monthly' ? '30 days' : '24 hours'}`}
                                                control={
                                                    <Checkbox
                                                        checked={!!eligibilityCheck}
                                                        color="primary"
                                                        name="eligibilityCheck"
                                                        onChange={this.handleEligibilityCheck}
                                                    />
                                                }
                                            />
                                        </div>
                                    )}

                                    {!!tournament_type && !!eligibilityCheck && (
                                        <div className="grid-item margin-top-20 align-right">
                                            <Input
                                                label="Number of fantasy contest"
                                                type="number"
                                                name="eligibilityValue"
                                                value={eligibilityValue || ''}
                                                placeholder="Enter Number"
                                                onChange={this.handleInputChange.bind(this, 'eligibilityValue')}
                                            />
                                        </div>
                                    )}

                                    {/* { matchType !== undefined && matchType !=null && matchType !=="" ? */}
                                    {game !== undefined && game !== null && game !== '' ? (
                                        <React.Fragment>
                                            {/* row 1 */}
                                            <div className="grid-item  margin-top-20">
                                                <DropDown
                                                    label="Entry Type"
                                                    type="text"
                                                    menuItems={entryFeeTypeOptions}
                                                    name="entryFeeType"
                                                    value={entryFeeType}
                                                    onChange={this.handleInputChange.bind(this, 'entryFeeType')}
                                                    placeholder="Select"
                                                    disabled={false}
                                                />
                                            </div>

                                            {entryFeeType === 'Cash+Coin' ? (
                                                <div className="grid-item margin-top-20 align-right">
                                                    <Input
                                                        label="% FC Coin Discount"
                                                        type="text"
                                                        name="fcCoinDiscountPercent"
                                                        value={fcCoinDiscountPercent}
                                                        onChange={this.handleInputChange.bind(
                                                            this,
                                                            'fcCoinDiscountPercent',
                                                        )}
                                                        placeholder="% FC Coin Discount"
                                                        suffix="%"
                                                        disabled={false}
                                                    />
                                                </div>
                                            ) : (
                                                <div className="grid-item margin-top-20 align-right"></div>
                                            )}

                                            {/* row 2 */}
                                            <div className="grid-item margin-top-20 align-right">
                                                <DropDown
                                                    label="Min Participation Required"
                                                    type="text"
                                                    menuItems={minParticipationRequiredOptions}
                                                    name="minParticipationRequired"
                                                    value={minParticipationRequired}
                                                    onChange={this.handleInputChange.bind(
                                                        this,
                                                        'minParticipationRequired',
                                                    )}
                                                    placeholder="Select"
                                                    disabled={false}
                                                />
                                            </div>
                                            {minParticipationRequired === true ? (
                                                <div className="grid-item margin-top-20 align-right">
                                                    <Input
                                                        label="Min Slots to be filled"
                                                        type="text"
                                                        name="minFillSlots"
                                                        value={minFillSlots}
                                                        onChange={this.handleInputChange.bind(this, 'minFillSlots')}
                                                        placeholder="Enter the minimum slots"
                                                        disabled={false}
                                                    />
                                                </div>
                                            ) : (
                                                <div className="grid-item margin-top-20 align-right"></div>
                                            )}

                                            {/* row */}
                                            <div className="grid-item margin-top-20 align-right">
                                                <DropDown
                                                    label="Allow Incomplete Teams"
                                                    type="text"
                                                    menuItems={minParticipationRequiredOptions}
                                                    name="allowIncompleteTeams"
                                                    value={allowIncompleteTeams}
                                                    onChange={(val) => {
                                                        this.setState({allowIncompleteTeams: val});
                                                    }}
                                                    // onChange={this.handleInputChange.bind(this, "allowIncompleteTeams")}
                                                    placeholder="Select"
                                                    disabled={false}
                                                />
                                            </div>
                                            <div className="grid-item margin-top-20 align-right">
                                                <Input
                                                    label="Rank for sorting"
                                                    type="number"
                                                    name="rankForSorting"
                                                    value={rankForSorting}
                                                    onChange={this.handleInputChange.bind(this, 'rankForSorting')}
                                                    placeholder="Keep it 'empty' or 0 if no ranking is to be done"
                                                    disabled={false}
                                                />
                                            </div>

                                            {/* row 3 */}
                                            <div className="grid-item margin-top-20 align-right">
                                                <DropDown
                                                    label="Win Reward Denomination"
                                                    type="text"
                                                    menuItems={winDenominationOptions}
                                                    name="winDenomination"
                                                    value={winDenomination}
                                                    onChange={this.handleInputChange.bind(this, 'winDenomination')}
                                                    placeholder="Select"
                                                    disabled={false}
                                                />
                                            </div>
                                            <div className="grid-item  margin-top-20">
                                                <DropDown
                                                    label="Match Mode"
                                                    type="text"
                                                    menuItems={matchModeOptions}
                                                    name="matchMode"
                                                    value={matchMode}
                                                    onChange={this.handleInputChange.bind(this, 'matchMode')}
                                                    placeholder="Select"
                                                    disabled={false}
                                                />
                                            </div>

                                            {/* row 4 */}
                                            <div className="grid-item margin-top-20 align-right">
                                                <DropDown
                                                    label="Attribute 1"
                                                    type="text"
                                                    menuItems={attribute1Options}
                                                    name="attribute1"
                                                    value={attribute1}
                                                    onChange={this.handleInputChange.bind(this, 'attribute1')}
                                                    placeholder="Select"
                                                    disabled={false}
                                                />
                                            </div>

                                            <div className="grid-item  margin-top-20">
                                                <Input
                                                    label="Attribute 1 Value"
                                                    type="text"
                                                    name="attribute1Val"
                                                    value={attribute1Val}
                                                    onChange={this.handleInputChange.bind(this, 'attribute1Val')}
                                                    placeholder="Select"
                                                    disabled={false}
                                                />
                                            </div>

                                            {/* row 5 */}
                                            <div className="grid-item margin-top-20 align-right">
                                                <DropDown
                                                    label="Attribute 2"
                                                    type="text"
                                                    menuItems={attribute2Options}
                                                    name="attribute2"
                                                    value={attribute2}
                                                    onChange={this.handleInputChange.bind(this, 'attribute2')}
                                                    placeholder="Select"
                                                    disabled={false}
                                                />
                                            </div>

                                            <div className="grid-item  margin-top-20">
                                                <Input
                                                    label="Attribute 2 Value"
                                                    type="text"
                                                    name="attribute2Val"
                                                    value={attribute2Val}
                                                    onChange={this.handleInputChange.bind(this, 'attribute2Val')}
                                                    placeholder="Select"
                                                    disabled={false}
                                                />
                                            </div>

                                            {/* row 6 */}
                                            <div className="grid-item  margin-top-20">
                                                <DropDown
                                                    label="Reward Win Mode"
                                                    type="text"
                                                    menuItems={winTypeOptions}
                                                    name="winType"
                                                    value={winType}
                                                    onChange={this.handleInputChange.bind(this, 'winType')}
                                                    placeholder="Select"
                                                    disabled={false}
                                                />
                                            </div>
                                            <div className="grid-item margin-top-20 align-right"></div>

                                            {/* row 6 */}
                                            {winType === 'Fixed' ? (
                                                <div className="grid-item  margin-top-20">
                                                    <Input
                                                        label="Winning Parameter e.g. Per Kill"
                                                        type="text"
                                                        name="winParameter"
                                                        value={winParameter}
                                                        onChange={this.handleInputChange.bind(this, 'winParameter')}
                                                        placeholder="Winning Parameter"
                                                        disabled={false}
                                                    />
                                                </div>
                                            ) : null}
                                            {winType === 'Fixed' ? (
                                                <div className="grid-item  margin-top-20">
                                                    <Input
                                                        label="Reward per parameter e.g. 5"
                                                        type="text"
                                                        name="winParameterValue"
                                                        value={winParameterValue}
                                                        onChange={this.handleInputChange.bind(
                                                            this,
                                                            'winParameterValue',
                                                        )}
                                                        placeholder="Reward per parameter"
                                                        disabled={false}
                                                    />
                                                </div>
                                            ) : null}

                                            {winType === 'Distribution' ? (
                                                <div className="grid-item  margin-top-20">
                                                    Prize Pool Distribution Helper
                                                </div>
                                            ) : null}
                                            {winType === 'Distribution' ? (
                                                <div className="grid-item  margin-top-20"></div>
                                            ) : null}

                                            {/* row 7 */}
                                            {winType === 'Distribution' ? (
                                                <div className="grid-item margin-top-20">
                                                    <Input
                                                        label="Handling Charges "
                                                        type="text"
                                                        name="handlingCharge"
                                                        value={winSlots.handlingCharge}
                                                        onChange={this.handleInputChange.bind(this, 'handlingCharge')}
                                                        placeholder="Enter Handling Charges %"
                                                        disabled={false}
                                                        suffix="%"
                                                    />
                                                </div>
                                            ) : null}
                                            {winType === 'Distribution' ? (
                                                <div className="grid-item margin-top-20">
                                                    <Input
                                                        label="# of Big Win Slots"
                                                        type="text"
                                                        name="numberOfBigWinTeams"
                                                        value={numberOfBigWinTeams}
                                                        onChange={this.handleInputChange.bind(
                                                            this,
                                                            'numberOfBigWinTeams',
                                                        )}
                                                        placeholder="Enter # of Big Win Slots"
                                                        disabled={false}
                                                    />
                                                </div>
                                            ) : null}
                                        </React.Fragment>
                                    ) : null}
                                </div>
                                {/* {matchType !== undefined && matchType !== null && matchType !== "" ?  */}
                                {game !== undefined && game !== null && game !== '' ? (
                                    <React.Fragment>
                                        {/* row8 */}
                                        <div className="card-grid-container nopadding ">
                                            {this.displayPrizes(numberOfBigWinTeams)}
                                        </div>

                                        {/* row9 */}
                                        <div className="grid-item card-grid-container 6">
                                            {winType === 'Distribution' ? (
                                                <React.Fragment>
                                                    <div className="grid-item margin-top-20">
                                                        <Input
                                                            label="Split Remaining"
                                                            type="text"
                                                            name="splitRemaining"
                                                            value={winSlots.splitRemaining}
                                                            onChange={this.handleInputChange.bind(
                                                                this,
                                                                'splitRemaining',
                                                            )}
                                                            placeholder="Enter Split Remaining"
                                                            disabled={true}
                                                            suffix="%"
                                                        />
                                                    </div>
                                                    <div className="grid-item margin-top-20">
                                                        <Input
                                                            label="# of small win slots"
                                                            type="text"
                                                            name="smallWinSlots"
                                                            value={winSlots.smallWinSlots}
                                                            onChange={this.handleInputChange.bind(
                                                                this,
                                                                'smallWinSlots',
                                                            )}
                                                            placeholder="Enter # of small win slots"
                                                            disabled={false}
                                                        />
                                                    </div>
                                                </React.Fragment>
                                            ) : null}
                                        </div>

                                        {/* row10 */}
                                        <div className="card-fullgrid-container margin-top-20">
                                            <label for="matchRules">Match Rules</label>
                                            <TextareaAutosize
                                                className="margin-top-20"
                                                aria-label="maximum height"
                                                value={matchRules}
                                                placeholder="Match Rules"
                                                onChange={this.handleInputChange.bind(this, 'matchRules')}
                                                disabled={false}
                                            />
                                        </div>

                                        {edit ? (
                                            <MatchDetailsV2
                                                match={toMatch.editTOMatch}
                                                onUpdateOrganizerId={this.onUpdateOrganizerId.bind(this)}
                                            />
                                        ) : (
                                            <MatchDetailsV2
                                                match={{}}
                                                onUpdateOrganizerId={this.onUpdateOrganizerId.bind(this)}
                                            />
                                        )}
                                    </React.Fragment>
                                ) : null}
                            </div>
                        </Card>
                    </div>

                    <div className="grid-item"></div>
                </div>
            </DetailLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        toGame: state.toGame,
        toMatch: state.toMatch,
        toMatchType: state.toMatchType,
        toMatchMode: state.toMatchMode,
        toMatchAttribute: state.toMatchAttribute,
        client: state.client,
    };
};

export default connect(mapStateToProps, {
    getTOGameList,
    getTOMatchTypeList,
    getTOMatchAttributeList,
    getTOMatchModeList,
    createTOMatch,
    logout,
    deleteTOMatch,
    updateTOMatch,
})(AddTOMatch);
