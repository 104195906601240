export const ADMIN_LIST = 'ADMIN_LIST';
export const ADMIN_LIST_SUCCESS = 'ADMIN_LIST_SUCCESS';
export const ADMIN_LIST_FAIL = 'ADMIN_LIST_FAIL';

export const ADD_ADMIN = 'ADD_ADMIN';
export const ADD_ADMIN_SUCCESS = 'ADD_ADMIN_SUCCESS';
export const ADD_ADMIN_FAIL = 'ADD_ADMIN_FAIL';

export const DELETE_ADMIN = 'DELETE_ADMIN';
export const DELETE_ADMIN_SUCCESS = 'DELETE_ADMIN_SUCCESS';
export const DELETE_ADMIN_FAIL = 'DELETE_ADMIN_FAIL';

export const GET_ADMIN = 'GET_ADMIN';
export const GET_ADMIN_SUCCESS = 'GET_ADMIN_SUCCESS';
export const GET_ADMIN_FAIL = 'GET_ADMIN_FAIL';

export const UPDATE_ADMIN = 'UPDATE_ADMIN';
export const UPDATE_ADMIN_SUCCESS = 'UPDATE_ADMIN_SUCCESS';
export const UPDATE_ADMIN_FAIL = 'UPDATE_ADMIN_FAIL';
