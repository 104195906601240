import {
    TOGAME_LIST,
    TOGAME_LIST_SUCCESS,
    TOGAME_LIST_FAIL,
    ADD_TOGAME,
    ADD_TOGAME_SUCCESS,
    ADD_TOGAME_FAIL,
    UPDATE_TOGAME,
    UPDATE_TOGAME_SUCCESS,
    UPDATE_TOGAME_FAIL,
    DELETE_TOGAME,
    DELETE_TOGAME_SUCCESS,
    DELETE_TOGAME_FAIL,
    GET_TOGAME,
    GET_TOGAME_SUCCESS,
    GET_TOGAME_FAIL,
    EDIT_TOGAME,
} from './Constants';

//lst all TOGAME
export function listTOGames() {
    return {
        type: TOGAME_LIST,
    };
}

export function listTOGamesSuccess(data) {
    return {
        type: TOGAME_LIST_SUCCESS,
        payload: data,
    };
}

export function listTOGamesFail(error) {
    return {
        type: TOGAME_LIST_FAIL,
        payload: error,
    };
}

// add TOGAME
export function addTOGame() {
    return {
        type: ADD_TOGAME,
    };
}

export function addTOGameSuccess(data) {
    return {
        type: ADD_TOGAME_SUCCESS,
        payload: data,
    };
}

export function addTOGameFail(error) {
    return {
        type: ADD_TOGAME_FAIL,
        payload: error,
    };
}

//update TOGAME
export function updateTOGameRequest() {
    return {
        type: UPDATE_TOGAME,
    };
}

export function updateTOGameSuccess(data) {
    return {
        type: UPDATE_TOGAME_SUCCESS,
        payload: data,
    };
}

export function updateTOGameFail(error) {
    return {
        type: UPDATE_TOGAME_FAIL,
        payload: error,
    };
}

// delete TOGAME

export function deleteTOGameRequest() {
    return {
        type: DELETE_TOGAME,
    };
}

export function deleteTOGameSuccess(data) {
    return {
        type: DELETE_TOGAME_SUCCESS,
        payload: data,
    };
}

export function deleteTOGameFail(error) {
    return {
        type: DELETE_TOGAME_FAIL,
        payload: error,
    };
}

//get single TOGAME
export function getTOGameRequest() {
    return {
        type: GET_TOGAME,
    };
}

export function getTOGameSuccess(data) {
    return {
        type: GET_TOGAME_SUCCESS,
        payload: data,
    };
}

export function getTOGameFail(error) {
    return {
        type: GET_TOGAME_FAIL,
        payload: error,
    };
}

// single game Details
export function editTOGame(game) {
    return {
        type: EDIT_TOGAME,
        payload: game,
    };
}
