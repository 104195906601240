import React from 'react';
import {timeFormat} from '../../../Utilities/Helpers';

import '../../../Utilities/style.css';
import '../Login/login.css';
import TableLayout from '../../Layouts/TableLayout/TableLayout';
import Table from '../../../Components/Table/Table';

import {connect} from 'react-redux';
import {next, prev, reset} from '../../../Utilities/HelperFunctions';

import {getTOMatchList, getTOOrganizerMatchList, deleteTOMatch} from './AxioCalls';
import {editTOMatch} from './action';
import {logout} from '../Login/LoginAction';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';
import Snackbar from '../../../Components/Snackbar/Snackbar';

class TOMatchList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            TOMatch: [],
            selectAll: false,
            list: [],
            open: false,
            adminpswd: '',
            pageData: {},
            viewType: 'admin',
            loading: true,
            pageOptions: {
                page: 1,
                pageSize: 10,
                searchString: null,
                sortOrder: null,
                listType: 'DRAFT',
            },
            sortOptions: [
                {
                    value: 'id',
                    text: 'Match Id',
                },
                {
                    value: 'name',
                    text: 'Match Name',
                },
                {
                    value: 'startTime',
                    text: 'Start Time',
                },
                {
                    value: 'status',
                    text: 'Status',
                },
            ],
        };
    }

    componentWillMount() {
        const {login, history} = this.props;

        if (!login.userDetails.data) {
            history.push('/');
            this.props.logout();
        } else {
            const {pageOptions} = this.state;
            // console.log("Fetching the matches list");
            if (login.userDetails.data.role === 'ORGANIZER') {
                this.props.getTOOrganizerMatchList(pageOptions, login.userDetails.data.accessToken);
            } else {
                this.props.getTOMatchList(pageOptions, login.userDetails.data.accessToken);
            }
        }
    }

    componentDidUpdate(prevProps) {
        const {pageOptions} = this.state;
        const {login, toMatch} = this.props;

        if (toMatch.TOMatchListSuccess && !prevProps.toMatch.TOMatchListSuccess) {
            this.setState({
                list: toMatch.TOMatchList.data.data,
                loading: false,
                pageData: toMatch.TOMatchList.data.pageData,
            });
        } else if (toMatch.TOMatchListFail && !prevProps.toMatch.TOMatchListFail) {
            this.setState({
                errorMessage: toMatch.errorMessage,
                loading: false,
            });
        }
        if (toMatch.deleteTOMatchSuccess && !prevProps.toMatch.deleteTOMatchSuccess) {
            this.setState({
                snackbarOpen: true,
                errorMessage: toMatch.deleteTOMatch.msg,
                TOMatch: [],
                loading: false,
            });
            if (login.userDetails.data.role === 'ORGANIZER') {
                this.props.getTOOrganizerMatchList(pageOptions, login.userDetails.data.accessToken);
            } else {
                this.props.getTOMatchList(pageOptions, login.userDetails.data.accessToken);
            }
        } else if (toMatch.deleteTOMatchFail && !prevProps.toMatch.deleteTOMatchFail) {
            this.setState({
                snackbarOpen: true,
                errorMessage: toMatch.errorMessage,
                loading: false,
            });
        }
    }

    //store single match details selected for editing
    onEditTOMatch(id) {
        const {toMatch, history} = this.props;

        let TOMatches = toMatch.TOMatchList.data.data;
        const editTOMatch = TOMatches.filter((match) => match.id === id);
        this.props.editTOMatch(editTOMatch[0]);
        history.push('/editTOMatch/' + id);
    }

    //APi call for search and sort
    onSearchandSort(field, value) {
        const {pageOptions} = this.state;
        const {login} = this.props;
        let options = pageOptions;
        options[field] = value;
        this.setState({pageOptions: options});

        if (field === 'searchString' && value.length > 2) {
            if (login.userDetails.data.role === 'ORGANIZER') {
                this.props.getTOOrganizerMatchList(options, login.userDetails.data.accessToken);
            } else {
                this.props.getTOMatchList(options, login.userDetails.data.accessToken);
            }
        } else if (field === 'sortOrder') {
            console.log('Sort has been triggered!');
            if (login.userDetails.data.role === 'ORGANIZER') {
                this.props.getTOOrganizerMatchList(options, login.userDetails.data.accessToken);
            } else {
                this.props.getTOMatchList(options, login.userDetails.data.accessToken);
            }
        }
    }

    //on click of next in table
    onNext() {
        const {pageData, pageOptions} = this.state;
        const {login} = this.props;
        let options = next(pageOptions, pageData);

        if (options) {
            if (login.userDetails.data.role === 'ORGANIZER') {
                this.props.getTOOrganizerMatchList(options, login.userDetails.data.accessToken);
            } else {
                this.props.getTOMatchList(options, login.userDetails.data.accessToken);
            }
            this.setState({pageOptions: options, loading: true});
        }
    }

    //onclick of prev in table
    onPrev() {
        const {pageOptions} = this.state;
        const {login} = this.props;
        let options = prev(pageOptions);
        if (options) {
            if (login.userDetails.data.role === 'ORGANIZER') {
                this.props.getTOOrganizerMatchList(options, login.userDetails.data.accessToken);
            } else {
                this.props.getTOMatchList(options, login.userDetails.data.accessToken);
            }
            this.setState({pageOptions: options, loading: true});
        }
    }

    onReset() {
        const {pageOptions} = this.state;
        const {login} = this.props;
        let options = reset(pageOptions);
        this.setState({
            pageOptions: options,
        });
        if (login.userDetails.data.role === 'ORGANIZER') {
            this.props.getTOOrganizerMatchList(options, login.userDetails.data.accessToken);
        } else {
            this.props.getTOMatchList(options, login.userDetails.data.accessToken);
        }
    }

    render() {
        const {list, sortOptions, loading, pageData, TOMatch, errorMessage, snackbarOpen} = this.state;
        const data = list;
        const {searchString, sortOrder, page} = this.state.pageOptions;

        let disableNext = page === pageData.lastPage;

        const columns = [
            {
                Header: 'Match ID',
                accessor: 'id',
                width: 200,
                Cell: (props) => (
                    <span className="table-clickable" onClick={this.onEditTOMatch.bind(this, props.value)}>
                        {props.value === undefined || props.value === '' || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Name',
                accessor: 'name',
                width: 200,
                Cell: (props) => (
                    <span>
                        {props.value === undefined || props.value === '' || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Type',
                accessor: 'matchType.name',
                width: 200,
                Cell: (props) => (
                    <span>
                        {props.value === undefined || props.value === '' || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Match Access',
                accessor: 'matchAccess',
                Cell: (props) => (
                    <span>
                        {props.value === undefined || props.value === '' || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Start Time',
                accessor: 'startTime',
                Cell: (props) => (
                    <span className="date-space">
                        {props.value === undefined || props.value === '' || props.value === ''
                            ? '-'
                            : timeFormat(props.value)}
                    </span>
                ),
            },
            {
                Header: 'Created By',
                accessor: 'createdByClient',
                Cell: (props) => (
                    <span>
                        {props.value === undefined || props.value === '' || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Total Slots',
                accessor: 'totalSlots',
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Entry Type',
                accessor: 'entryFeeType',
                Cell: (props) => (
                    <span>
                        {props.value === undefined || props.value === '' || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Fee',
                accessor: 'entryFeePerSlot',
                Cell: (props) => (
                    <span>
                        {props.value === undefined || props.value === '' || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Min Rule',
                accessor: 'minParticipationRequired',
                Cell: (props) => (
                    <span>
                        {props.value === undefined || props.value === '' || props.value === ''
                            ? '-'
                            : props.value.toString()}
                    </span>
                ),
            },
            {
                Header: 'Min Slots',
                accessor: 'minFillSlots',
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Pool Type',
                accessor: 'winPoolType',
                Cell: (props) => (
                    <span>
                        {props.value === undefined || props.value === '' || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Fixed Pool',
                accessor: 'winFixedPoolSize',
                Cell: (props) => (
                    <span>
                        {props.value === undefined || props.value === '' || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: '% Pool',
                accessor: 'winPercentPoolSize',
                Cell: (props) => (
                    <span>
                        {props.value === undefined || props.value === '' || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Attribute1',
                accessor: 'attribute1Val',
                Cell: (props) => (
                    <span>
                        {props.value === undefined || props.value === '' || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Attribute2',
                accessor: 'attribute2Val',
                Cell: (props) => (
                    <span>
                        {props.value === undefined || props.value === '' || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Match Mode',
                accessor: 'matchMode.modeName',
                Cell: (props) => (
                    <span>
                        {props.value === undefined || props.value === '' || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Game',
                accessor: 'game.gameName',

                Cell: (props) => <span>{props.value === undefined || props.value === '' ? '-' : props.value}</span>,
            },
            {
                Header: 'Status',
                accessor: 'status',

                Cell: (props) => <span>{props.value === undefined || props.value === '' ? '-' : props.value}</span>,
            },
        ];
        return (
            <TableLayout
                location="TOMatch"
                history={this.props.history}
                searchbar="Search Match Name"
                dispDelete={TOMatch.length > 0 ? true : false}
                onsearchChange={this.onSearchandSort.bind(this)}
                sortOptions={sortOptions}
                onReset={this.onReset.bind(this)}
                searchString={searchString}
                sortOrder={sortOrder}
            >
                <Snackbar
                    open={snackbarOpen}
                    message={errorMessage}
                    onClose={() => this.setState({snackbarOpen: false})}
                />
                <LoadingModal open={loading} />

                <Table
                    data={data}
                    columns={columns}
                    page={page}
                    onPrevClick={this.onPrev.bind(this)}
                    onNextClick={this.onNext.bind(this)}
                    disableNext={disableNext}
                ></Table>
            </TableLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        toMatch: state.toMatch,
    };
};

export default connect(mapStateToProps, {
    getTOMatchList,
    getTOOrganizerMatchList,
    editTOMatch,
    deleteTOMatch,
    logout,
})(TOMatchList);
