import axios from 'axios';
import {
    listTOGames,
    listTOGamesSuccess,
    listTOGamesFail,
    addTOGame,
    addTOGameSuccess,
    addTOGameFail,
    updateTOGameRequest,
    updateTOGameSuccess,
    updateTOGameFail,
    deleteTOGameRequest,
    deleteTOGameSuccess,
    deleteTOGameFail,
    getTOGameRequest,
    getTOGameSuccess,
    getTOGameFail,
} from './action';
import {TOURL} from '../../../Utilities/Constants';
import {logout} from '../Login/LoginAction';

export function getTOGameList(pageOptions, token, clientId) {
    // listing all TOGames
    return (dispatch) => {
        dispatch(listTOGames());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token, clientid: clientId},
            url: `${TOURL}/game/list`,
            data: pageOptions,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(listTOGamesSuccess(data));
                } else {
                    dispatch(listTOGamesFail(data.data.errorData.errorReason));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(listTOGamesFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(listTOGamesFail(err.message || 'Something went wrong'));
                }
            });
    };
}

//creating new TOGame
export function createTOGame(TOGame, token, clientId) {
    return (dispatch) => {
        dispatch(addTOGame());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token, clientid: clientId},
            url: `${TOURL}/game/create`,
            data: TOGame,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(addTOGameSuccess(data));
                } else {
                    dispatch(addTOGameFail(data.data.errorData.errorReason));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(addTOGameFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(addTOGameFail(err.message || 'Something went wrong'));
                }
            });
    };
}

//TOGame update
export function updateTOGame(TOGame, token, clientId) {
    return (dispatch) => {
        dispatch(updateTOGameRequest());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token, clientid: clientId},
            url: `${TOURL}/game/update`,
            data: TOGame,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(updateTOGameSuccess(data));
                } else {
                    dispatch(updateTOGameFail(data.data.errorData.errorReason));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(updateTOGameFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(updateTOGameFail(err.message || 'Something went wrong'));
                }
            });
    };
}

//TOGame delete
export function deleteTOGame(id, token, clientId) {
    return (dispatch) => {
        dispatch(deleteTOGameRequest());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token, clientid: clientId},
            url: `${TOURL}/game/delete`,
            data: {gameId: id},
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(deleteTOGameSuccess(data));
                } else {
                    dispatch(deleteTOGameFail(data.data.errorData.errorReason));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(deleteTOGameFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(deleteTOGameFail(err.message || 'Something went wrong'));
                }
            });
    };
}

// get single TOGame
export function getTOGame(id, token, clientId) {
    return (dispatch) => {
        dispatch(getTOGameRequest());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token, clientid: clientId},
            url: `${TOURL}/game/get`,
            data: {
                gameId: id,
            },
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(getTOGameSuccess(data));
                } else {
                    dispatch(getTOGameFail(data.data.errorData.errorReason));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(getTOGameFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(getTOGameFail(err.message || 'Something went wrong'));
                }
            });
    };
}
