import {CREATE_REWARD_USER, CREATE_REWARD_USER_SUCCESS, CREATE_REWARD_USER_FAIL} from './Constants';

/**
 * create refund api request
 */
export function createRewardUserRequest() {
    return {
        type: CREATE_REWARD_USER,
    };
}

/**
 * handle create refund api success
 * @param {*} data data from api
 */
export function createRewardUserSuccess(data) {
    return {
        type: CREATE_REWARD_USER_SUCCESS,
        payload: data,
    };
}

/**
 *handle create refund api failure
 * @param {*} error error from api
 */
export function createRewardUserFail(error) {
    return {
        type: CREATE_REWARD_USER_FAIL,
        payload: error,
    };
}
