import React, {useCallback, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';

import Button from '../../../../Components/Button/Button';
import Snackbar from '../../../../Components/Snackbar/Snackbar';
import LoadingModal from '../../../../Components/LoadingModal/LoadingModal';
import {usePrevious} from '../../../../Utilities/hooks';
import FileInput from '../../../../Components/FileInput/FileInput';
import {fetchTdsUploadUrl, uploadTdsCertificate} from '../action';
import makeRequest from '../../../../apiCalls/makeRequest';
import {REQUEST_METHODS} from '../../../../Utilities/Constants';

const UploadTdsCertificate = (props) => {
    const {handleClose, open, details} = props;
    const submitRef = useRef();
    const dispatch = useDispatch();
    const login = useSelector((state) => state.login);
    const client = useSelector((state) => state.client);
    const tdsCertificates = useSelector((state) => state.tdsCertificates);
    const prevTdsCertificates = usePrevious(tdsCertificates);
    const {tdsUploadUrlData, isFetchingTdsUploadUrl} = tdsCertificates;
    const [state, setState] = useState({});
    const {errorMessage, pdf, loading = false} = state;
    const isLoading = isFetchingTdsUploadUrl || loading || false;

    useEffect(() => {
        if (tdsCertificates.isFetchingTdsUploadUrlSuccess && !prevTdsCertificates.isFetchingTdsUploadUrlSuccess) {
            handleUploadImage();
        }
        if (tdsCertificates.isFetchingTdsUploadUrlFail && !prevTdsCertificates.isFetchingTdsUploadUrlFail) {
            handleChangeState({errorMessage: tdsCertificates?.message?.msg});
        }
        if (tdsCertificates.isUploadingTdsCertificateFail && !prevTdsCertificates.isUploadingTdsCertificateFail) {
            handleChangeState({errorMessage: tdsCertificates?.message?.msg});
        }
    }, [tdsCertificates]);

    const handleChangeState = (stateObj = {}) => {
        setState((prevState) => ({...prevState, ...stateObj}));
    };

    const handleUploadImage = useCallback(async () => {
        handleChangeState({loading: true});
        try {
            const formData = new FormData();
            formData.append('data', pdf);
            const response = await makeRequest(tdsUploadUrlData?.preSingedUrl, {
                method: REQUEST_METHODS.PUT,
                data: formData.get('data'),
            });
            handleChangeState({loading: false});
            const data = {
                userId: details.userId,
                quarter: details.quarter,
                year: details.year,
                certificateKey: tdsUploadUrlData.key,
            };
            dispatch(uploadTdsCertificate(data, login?.userDetails?.data?.accessToken, client.selectedClient));
        } catch (e) {
            handleChangeState({errorMessage: e.message, loading: false});
        }
    }, [tdsUploadUrlData, state]);

    const handleSubmit = (e) => {
        !!e && e.preventDefault();
        dispatch(
            fetchTdsUploadUrl({userId: details.userId}, login?.userDetails?.data?.accessToken, client.selectedClient),
        );
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            scroll="paper"
            maxWidth="sm"
            fullWidth
            disableEscapeKeyDown
            disableBackdropClick
        >
            {!!errorMessage && (
                <Snackbar
                    open={!!errorMessage}
                    message={errorMessage}
                    onClose={() => handleChangeState({errorMessage: ''})}
                />
            )}
            {!!isLoading && <LoadingModal open={!!isLoading} />}

            <DialogTitle>Upload TDS Certificate</DialogTitle>
            <DialogContent dividers={true}>
                <form onSubmit={handleSubmit}>
                    <FileInput
                        className="mb-15"
                        label={'Upload certificate in pdf format'}
                        accept=".pdf"
                        required
                        onChange={(file) => handleChangeState({pdf: file})}
                    />
                    <Button className="d-none" buttonText="Submit" type="submit" buttonRef={submitRef} />
                </form>
            </DialogContent>
            <DialogActions>
                <Button buttonText="Cancel" onClick={handleClose} />
                <Button buttonText="Submit" onClick={() => submitRef.current.click()} />
            </DialogActions>
        </Dialog>
    );
};

UploadTdsCertificate.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    details: PropTypes.object,
};

UploadTdsCertificate.defaultProps = {
    handleClose: () => {},
    open: false,
    details: {},
};

export default UploadTdsCertificate;
