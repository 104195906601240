/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import '../../../Utilities/style.css';
import '../Login/login.css';
import {fetchUserKycList} from './action';
import {logout} from '../Login/LoginAction';
import {copyText, next, prev} from '../../../Utilities/HelperFunctions';
import {timeFormat} from '../../../Utilities/Helpers';
import TableLayout from '../../Layouts/TableLayout/TableLayout';
import Table from '../../../Components/Table/Table';
import Snackbar from '../../../Components/Snackbar/Snackbar';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';
import FilterInput from '../../../Components/FilterInput';
import Button from '../../../Components/Button/Button';
import {ENTER, KYC_STATUS_OPTIONS_FANCLASH, KYC_STATUS_OPTIONS_JADE, NEXT} from '../../../Utilities/Constants';
import {useDebounce, usePrevious} from '../../../Utilities/hooks';
import DropDown from '../../../Components/Dropdown/Dropdown';
import UserKycActionModal from './UserKycActionModal';
import {ROUTES} from './UrlConstants';
import {FileCopy} from '@material-ui/icons';
import ActionModal from './ActionModal';
import DateRangeSelect from '../../../Components/DateRangeSelect';

const UserKyc = (props) => {
    const {history} = props;
    const initialFilter = {
        page: 1,
        pageSize: 20,
        immediate: true,
        sortOrder: 'createdAt',
        sortOrderBy: 'DESC',
    };
    const dispatch = useDispatch();
    const login = useSelector((state) => state.login);
    const userKyc = useSelector((state) => state.userKyc);
    const prevUserKyc = usePrevious(userKyc);
    const user = useSelector((state) => state.user);
    const prevUser = usePrevious(user);
    const client = useSelector((state) => state.client);
    const {
        userKycList: {data: kycListData = [], pageData = {}},
        isFetchingUserKycList,
    } = userKyc;
    let [filters, setFilters] = useState(initialFilter);
    const debouncedFilters = useDebounce(filters, {
        immediate: filters.immediate,
    });
    let [state, setState] = useState({});
    let [KycModal, setKycModal] = useState(null);
    const [actionModal, setActionModal] = useState(null);
    const {errorMessage, loading, requestedStartDate, requestedEndDate} = state;
    const disableNext = filters.page >= pageData.lastPage;
    const isLoading = isFetchingUserKycList || loading || false;
    const sortOrderByOption = [
        {value: 'ASC', text: 'New to old'},
        {value: 'DESC', text: 'Old to new'},
    ];

    useEffect(() => {
        if (!login?.userDetails?.data) {
            history.push('/');
            dispatch(logout());
        }
    }, []);

    useEffect(() => {
        if (login?.userDetails?.data) handleCallUserKycList();
    }, [debouncedFilters]);

    useEffect(() => {
        if (userKyc.isFetchingUserKycListFail && !prevUserKyc.isFetchingUserKycListFail) {
            handleStateChange({errorMessage: userKyc?.message?.msg});
        }
        if (user.isManuallyBankingKycSuccess && !prevUser.isManuallyBankingKycSuccess) {
            setKycModal(null);
            handleStateChange({errorMessage: user.errorMessage});
            handleCallUserKycList();
        }
    }, [userKyc, user]);

    useEffect(() => {
        if (user.userKycManualActionRequest && !prevUser.userKycManualActionRequest) {
            handleStateChange({loading: true});
        } else if (user.userKycManualActionSuccess && !prevUser.userKycManualActionSuccess) {
            handleStateChange({loading: false});
            setActionModal(null);
            setKycModal(null);
            handleCallUserKycList();
        } else if (user.userKycManualActionFailure && !prevUser.userKycManualActionFailure) {
            handleStateChange({loading: false, errorMessage: user.errorMessage});
        }
    }, [user]);

    const handleCallUserKycList = () => {
        const {immediate, ..._filters} = filters;
        dispatch(fetchUserKycList(_filters, login?.userDetails?.data?.accessToken, client.selectedClient));
    };

    const onNextORPrev = (action) => {
        const updatedFilters = action === NEXT ? next(filters, pageData) : prev(filters);
        if (updatedFilters) setFilters({...updatedFilters});
    };

    const onReset = () => {
        setFilters(initialFilter);
    };

    const handleFilter = (field, value, debounce = true) => {
        if (field === ENTER) {
            debounce = false;
        } else if (field === 'dates') {
            filters.requestedStartDate = value.start;
            filters.requestedEndDate = value.end;
        } else {
            filters[field] = value;
        }

        setFilters({...filters, page: 1, immediate: !debounce});
    };

    const handleStateChange = (stateObj = {}) => {
        setState((prevState) => ({...prevState, ...stateObj}));
    };

    const handleClickBankKycList = () => {
        history.push(ROUTES.USER_BANK_KYC);
    };

    const handleCopy = (text) => {
        copyText(text, (msg) => handleStateChange({errorMessage: msg}));
    };

    const handleActionClick = (record, type) => {
        setActionModal({user: record.original, type});
    };

    const renderColumns = () => {
        const items = [];
        if (client.selectedClient === 'client_jade') {
            items.splice(
                0,
                0,
                {
                    Header: 'Serial No.',
                    accessor: 'id',
                    width: 100,
                    Cell: (props) => (
                        <span className="number">{pageData.currentPage * pageData.perPage - 20 + props.index + 1}</span>
                    ),
                },
                {
                    Header: 'ID',
                    accessor: 'id',
                    width: 200,
                    Cell: (props) => <span>{props?.value || '-'}</span>,
                },
                {
                    Header: 'User ID',
                    accessor: 'user',
                    width: 200,
                    Cell: (props) => <span>{props.value ? props.value : '-'}</span>,
                },
                {
                    Header: 'Username',
                    accessor: 'userName',
                    width: 200,
                    Cell: (props) => (
                        <span style={{display: 'flex', alignItems: 'center', gap: '4px'}}>
                            {props.value === undefined || props.value === '' ? '-' : props.value}
                            {!!props?.value && (
                                <span
                                    style={{
                                        cursor: 'pointer',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                    onClick={() => handleCopy(props?.value)}
                                >
                                    <FileCopy titleAccess="Copy" fontSize="small" />
                                </span>
                            )}
                        </span>
                    ),
                },
                {
                    Header: 'Requested on',
                    accessor: 'createdAt',
                    width: 200,
                    Cell: (props) => {
                        return <span className="number date-space">{timeFormat(props.value) || '-'}</span>;
                    },
                },
                {
                    Header: 'Status',
                    accessor: 'status',
                    width: 200,
                    Cell: (props) => <span>{props?.value || '-'}</span>,
                },
                {
                    Header: 'Processed on',
                    accessor: 'processedAt',
                    width: 200,
                    Cell: (props) => {
                        return <span className="number date-space">{timeFormat(props.value) || '-'}</span>;
                    },
                },
                {
                    Header: 'Actions',
                    accessor: 'id',
                    width: 200,
                    Cell: (props) => {
                        if (props.original.status === 'VERIFIED' || props.original.status === 'REJECTED') {
                            return <span className="number">{'Action Taken'}</span>;
                        } else {
                            return (
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'flex-start',
                                        gap: '8px',
                                    }}
                                >
                                    <span
                                        className="number table-clickable"
                                        onClick={() => handleActionClick(props, 'verify')}
                                    >
                                        Verify
                                    </span>
                                    <span
                                        className="number table-clickable"
                                        onClick={() => handleActionClick(props, 'reject')}
                                    >
                                        Reject
                                    </span>
                                </div>
                            );
                        }
                    },
                },
            );
        }
        if (client.selectedClient === 'client_fanclash') {
            items.splice(
                0,
                0,
                {
                    Header: 'Serial No.',
                    accessor: 'index',
                    width: 100,
                    Cell: (props) => <span>{`${props.index + 1}` || '-'}</span>,
                },
                {
                    Header: 'Id',
                    accessor: 'id',
                    width: 200,
                    Cell: (props) => <span>{props?.value || '-'}</span>,
                },
                {
                    Header: 'User Id',
                    accessor: 'user',
                    width: 200,
                    Cell: (props) => (
                        <span className="table-clickable cursor" onClick={() => setKycModal({userId: props.value})}>
                            {props.value ? props.value : '-'}
                        </span>
                    ),
                },
                {
                    Header: 'Kyc Full Name',
                    accessor: 'fullName',
                    width: 150,
                    Cell: (props) => <span>{props?.value || '-'}</span>,
                },
                {
                    Header: 'Date Of Birth',
                    accessor: 'dateOfBirth',
                    Cell: (props) => (
                        <span className="number date-space">{props?.value ? timeFormat(props?.value) : '-'}</span>
                    ),
                },
                {
                    Header: 'Phone Number',
                    accessor: 'phoneNumber',
                    Cell: (props) => <span>{props?.value || '-'}</span>,
                },
                {
                    Header: 'Kyc Status',
                    accessor: 'status',
                    Cell: (props) => <span>{props?.value || '-'}</span>,
                },
                {
                    Header: 'CreatedAt',
                    accessor: 'createdAt',
                    width: 100,
                    Cell: (props) => (
                        <span className="number date-space">{props?.value ? timeFormat(props?.value) : '-'}</span>
                    ),
                },
            );
        }
        return items;
    };

    return (
        <TableLayout location="User Kyc" history={history} hideSearchBar>
            {!!isLoading && <LoadingModal open={!!isLoading} />}
            {!!errorMessage && (
                <Snackbar
                    open={!!errorMessage}
                    message={errorMessage}
                    onClose={() => handleStateChange({errorMessage: ''})}
                />
            )}

            {!!KycModal && (
                <UserKycActionModal open={!!KycModal} userKycData={KycModal} handleClose={() => setKycModal(null)} />
            )}
            {!!actionModal && (
                <ActionModal modalData={actionModal} open={!!actionModal} onClose={() => setActionModal(null)} />
            )}

            <div className="filter-container my-10">
                <FilterInput
                    placeholder="User Name"
                    value={filters.userName || ''}
                    onChange={(value) => handleFilter('userName', value)}
                    onEnter={() => handleFilter(ENTER)}
                />
                <FilterInput
                    placeholder="User Id"
                    value={filters.userId || ''}
                    onChange={(value) => handleFilter('userId', value)}
                    onEnter={() => handleFilter(ENTER)}
                />
                {client.selectedClient === 'client_fanclash' && (
                    <FilterInput
                        placeholder="Phone No."
                        value={filters.phoneNumber || ''}
                        onChange={(value) => handleFilter('phoneNumber', value)}
                        onEnter={() => handleFilter(ENTER)}
                    />
                )}
                <DropDown
                    label="KYC Status"
                    value={filters.status || ''}
                    menuItems={
                        client.selectedClient === 'client_fanclash'
                            ? KYC_STATUS_OPTIONS_FANCLASH
                            : client.selectedClient === 'client_jade'
                              ? KYC_STATUS_OPTIONS_JADE
                              : []
                    }
                    onChange={(value) => handleFilter('status', value, false)}
                />
                {client.selectedClient === 'client_fanclash' && (
                    <DropDown
                        label="VPA Status"
                        value={filters.vpaStatus || ''}
                        menuItems={KYC_STATUS_OPTIONS_FANCLASH}
                        onChange={(value) => handleFilter('vpaStatus', value, false)}
                    />
                )}
                {client.selectedClient === 'client_fanclash' && (
                    <DropDown
                        label="Sort Direction"
                        value={filters.sortOrderBy || ''}
                        menuItems={sortOrderByOption}
                        onChange={(value) => handleFilter('sortOrderBy', value, false)}
                    />
                )}
                {client.selectedClient === 'client_jade' && (
                    <DateRangeSelect
                        startDate={requestedStartDate}
                        endDate={requestedEndDate}
                        isClearable={true}
                        onChange={(dates) => handleFilter('dates', dates, false)}
                    />
                )}
                <Button buttonText="Reset" onClick={onReset} />
            </div>
            {client.selectedClient === 'client_fanclash' && (
                <div className="filter-container my-10">
                    <Button buttonText="User Bank List" onClick={handleClickBankKycList} />
                </div>
            )}

            <Table
                data={kycListData}
                columns={renderColumns()}
                page={filters.page}
                onPrevClick={onNextORPrev}
                onNextClick={() => onNextORPrev(NEXT)}
                disableNext={disableNext}
            ></Table>
        </TableLayout>
    );
};

export default React.memo(UserKyc);
