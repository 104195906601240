export const WITHDRAWAL_LIST = 'WITHDRAWAL_LIST';
export const WITHDRAWAL_LIST_SUCCESS = 'WITHDRAWAL_LIST_SUCCESS';
export const WITHDRAWAL_LIST_FAIL = 'WITHDRAWAL_LIST_FAIL';

export const WITHDRAWAL_UPDATE = 'WITHDRAWAL_UPDATE';
export const WITHDRAWAL_UPDATE_SUCCESS = 'WITHDRAWAL_UPDATE_SUCCESS';
export const WITHDRAWAL_UPDATE_FAIL = 'WITHDRAWAL_UPDATE_FAIL';

export const WITHDRAWAL_ACTION_REQUEST = 'WITHDRAWAL_ACTION_REQUEST';
export const WITHDRAWAL_ACTION_SUCCESS = 'WITHDRAWAL_ACTION_SUCCESS';
export const WITHDRAWAL_ACTION_FAILURE = 'WITHDRAWAL_ACTION_FAILURE';
