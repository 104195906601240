import {
    GET_DYNAMIC_PRIZE_POOLS,
    GET_DYNAMIC_PRIZE_POOLS_SUCCESS,
    GET_DYNAMIC_PRIZE_POOLS_FAIL,
    GET_DYNAMIC_PRIZE_POOL,
    GET_DYNAMIC_PRIZE_POOL_SUCCESS,
    GET_DYNAMIC_PRIZE_POOL_FAIL,
    CREATE_DYNAMIC_PRIZE_POOL,
    CREATE_DYNAMIC_PRIZE_POOL_SUCCESS,
    CREATE_DYNAMIC_PRIZE_POOL_FAIL,
    UPDATE_DYNAMIC_PRIZE_POOL,
    UPDATE_DYNAMIC_PRIZE_POOL_SUCCESS,
    UPDATE_DYNAMIC_PRIZE_POOL_FAIL,
} from './constants';
import apis from '../../../apiCalls/apis';

// get DynamicPrizePools with pagination
/**
 * @param {*} data DynamicPrizePools details
 * @param {string} token user token
 */
export const getDynamicPrizePools = (data, token, client) => async (dispatch) => {
    dispatch({type: GET_DYNAMIC_PRIZE_POOLS});
    try {
        const response = await apis.dynamicPrizePools(data, token, client);
        if (response.status === 200) {
            dispatch({type: GET_DYNAMIC_PRIZE_POOLS_SUCCESS, payload: response?.data});
        } else {
            dispatch({
                type: GET_DYNAMIC_PRIZE_POOLS_FAIL,
                payload: {msg: response?.data?.msg || 'Something went wrong'},
            });
        }
    } catch (e) {
        if (e.response) {
            dispatch({
                type: GET_DYNAMIC_PRIZE_POOLS_FAIL,
                payload: {msg: e?.response?.data?.msg || e?.message || 'Something went wrong'},
            });
        } else {
            dispatch({
                type: GET_DYNAMIC_PRIZE_POOLS_FAIL,
                payload: {msg: e?.message || 'Unknown Error Occured'},
            });
        }
    }
};

// get dynamicPrizePool using templateId
/**
 * @param {templateId} data templateId
 * @param {string} token user token
 */
export const getDynamicPrizePool = (data, token, client) => async (dispatch) => {
    dispatch({type: GET_DYNAMIC_PRIZE_POOL});
    try {
        const response = await apis.dynamicPrizePool(data, token, client);
        if (response.status === 200) {
            dispatch({type: GET_DYNAMIC_PRIZE_POOL_SUCCESS, payload: response?.data});
        } else {
            dispatch({
                type: GET_DYNAMIC_PRIZE_POOL_FAIL,
                payload: {msg: response?.data?.msg || 'Something went wrong'},
            });
        }
    } catch (e) {
        if (e.response) {
            dispatch({
                type: GET_DYNAMIC_PRIZE_POOL_FAIL,
                payload: {msg: e?.response?.data?.msg || e?.message || 'Something went wrong'},
            });
        } else {
            dispatch({
                type: GET_DYNAMIC_PRIZE_POOL_FAIL,
                payload: {msg: e?.message || 'Unknown Error Occured'},
            });
        }
    }
};

// create DynamicPrizePool
/**
 * @param {*} data dynamicPrizePool details
 * @param {string} token user token
 */
export const createDynamicPrizePool = (data, token, client) => async (dispatch) => {
    dispatch({type: CREATE_DYNAMIC_PRIZE_POOL});
    try {
        const response = await apis.createDynamicPrizePool(data, token, client);
        if (response.status === 200) {
            dispatch({type: CREATE_DYNAMIC_PRIZE_POOL_SUCCESS, payload: response?.data});
        } else {
            dispatch({
                type: CREATE_DYNAMIC_PRIZE_POOL_FAIL,
                payload: {msg: response?.data?.msg || 'Something went wrong'},
            });
        }
    } catch (e) {
        if (e.response) {
            dispatch({
                type: CREATE_DYNAMIC_PRIZE_POOL_FAIL,
                payload: {msg: e?.response?.data?.msg || e?.message || 'Something went wrong'},
            });
        } else {
            dispatch({
                type: CREATE_DYNAMIC_PRIZE_POOL_FAIL,
                payload: {msg: e?.message || 'Unknown Error Occured'},
            });
        }
    }
};

// update dynamicPrizePool
/**
 * @param {*} data dynamicPrizePool details
 * @param {string} token user token
 */
export const updateDynamicPrizePool = (data, token, client) => async (dispatch) => {
    dispatch({type: UPDATE_DYNAMIC_PRIZE_POOL});
    try {
        const response = await apis.updateDynamicPrizePool(data, token, client);
        if (response.status === 200) {
            dispatch({type: UPDATE_DYNAMIC_PRIZE_POOL_SUCCESS, payload: response?.data});
        } else {
            dispatch({
                type: UPDATE_DYNAMIC_PRIZE_POOL_FAIL,
                payload: {msg: response?.data?.msg || 'Something went wrong'},
            });
        }
    } catch (e) {
        if (e.response) {
            dispatch({
                type: UPDATE_DYNAMIC_PRIZE_POOL_FAIL,
                payload: {msg: e?.response?.data?.msg || e?.message || 'Something went wrong'},
            });
        } else {
            dispatch({
                type: UPDATE_DYNAMIC_PRIZE_POOL_FAIL,
                payload: {msg: e?.message || 'Unknown Error Occured'},
            });
        }
    }
};
