import axios from 'axios';
import {
    listDiscount,
    listDiscountSuccess,
    listDiscountFail,
    createDiscount,
    createDiscountSuccess,
    createDiscountFail,
    deleteDiscount,
    deleteDiscountSuccess,
    deleteDiscountFail,
} from './action';
import {URL} from '../../../Utilities/Constants';
import {GRANT_DISCOUNT_CODE, GRANT_DISCOUNT_CODE_FAIL, GRANT_DISCOUNT_CODE_SUCCESS} from './Constants';

/**
 * get discount list code api request
 * @param {*} discount page details
 * @param {string} token user token
 */
export function getDiscountList(discount, token, clientId) {
    return (dispatch) => {
        dispatch(listDiscount());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token, clientid: clientId},
            url: `${URL}/discount/list`,
            data: discount,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(listDiscountSuccess(data));
                } else if (data.data.status === 'fail') {
                    dispatch(listDiscountFail(data.data.errorData.errorReason));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(listDiscountFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(listDiscountFail(err.message || 'Something went wrong'));
                }
            });
    };
}

/**
 * create Discount code api request
 * @param {*} Discount discount code details
 * @param {string} token user token
 */
export function addDiscount(Discount, token, clientId) {
    return (dispatch) => {
        dispatch(createDiscount());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token, clientid: clientId},
            url: `${URL}/discount/create`,
            data: Discount,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(createDiscountSuccess(data));
                } else if (data.data.status === 'fail') {
                    dispatch(createDiscountFail(data.data.errorData.errorReason));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(createDiscountFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(createDiscountFail(err.message || 'Something went wrong'));
                }
            });
    };
}

/**
 * delete Discounts code api request
 * @param {*} Discount disocunt code details
 * @param {string} token user token
 */
export function deleteDiscounts(Discount, token, clientId) {
    return (dispatch) => {
        dispatch(deleteDiscount());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token, clientid: clientId},
            url: `${URL}/discount/deactivate`,
            data: Discount,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(deleteDiscountSuccess(data));
                } else if (data.data.status === 'fail') {
                    dispatch(deleteDiscountFail(data.data.errorData.errorReason));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(deleteDiscountFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(deleteDiscountFail(err.message || 'Something went wrong'));
                }
            });
    };
}

export const grantDiscountCodeToUsers = (data, token, clientId) => (dispatch) => {
    dispatch({type: GRANT_DISCOUNT_CODE});
    axios({
        method: 'post',
        headers: {Authorization: 'Bearer ' + token, clientid: clientId},
        url: `${URL}/discount/discountToCustomUsers`,
        data: data,
    })
        .then((data) => {
            if (data.data.status === 'success') {
                dispatch({type: GRANT_DISCOUNT_CODE_SUCCESS, payload: data.data});
            } else if (data.data.status === 'fail') {
                dispatch({type: GRANT_DISCOUNT_CODE_FAIL, payload: data.data.errorData});
            }
        })
        .catch((err) => {
            if (err.response) {
                const errorMsg = err?.response?.data?.msg || err.message;
                dispatch({type: GRANT_DISCOUNT_CODE_FAIL, payload: {errorReason: errorMsg || 'Something went wrong'}});
            } else {
                dispatch({
                    type: GRANT_DISCOUNT_CODE_FAIL,
                    payload: {errorReason: err.message || 'Something went wrong'},
                });
            }
        });
};
