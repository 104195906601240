import {
    REFERRAL_LIST,
    REFERRAL_LIST_SUCCESS,
    REFERRAL_LIST_FAIL,
    EDIT_REFERRAL_DATA,
    ADD_REFERRAL,
    ADD_REFERRAL_SUCCESS,
    ADD_REFERRAL_FAIL,
    UPDATE_REFERRAL,
    UPDATE_REFERRAL_SUCCESS,
    UPDATE_REFERRAL_FAIL,
    EDIT_REFERRAL_CODE,
    REFERRRED_USERS,
    REFERRRED_USERS_SUCCESS,
    REFERRRED_USERS_FAIL,
    CONVERTED_TNX,
    CONVERTED_TNX_SUCCESS,
    CONVERTED_TNX_FAIL,
} from './Constants';

const initialState = {
    referralListRequest: false,
    referralListSuccess: false,
    referralListFail: false,
    referralList: [],
    errorMessage: '',
    addReferralRequest: false,
    addReferralSuccess: false,
    addReferralFail: false,
    addReferral: [],
    updateReferralRequest: false,
    updateReferralSuccess: false,
    updateReferralFail: false,
    updateReferral: [],
    editReferralCode: [],
    referredUserRequest: false,
    referredUserSuccess: false,
    referredUserFail: false,
    referredUser: [],
    convertedTnxRequest: false,
    convertedTnxSuccess: false,
    convertedTnxFail: false,
    convertedTnx: [],
};

const referralCodeReducer = (state = initialState, action) => {
    switch (action.type) {
        //save  event
        case REFERRAL_LIST:
            return {
                ...state,
                referralListRequest: true,
                referralListSuccess: false,
                referralListFail: false,
                referralList: [],
                errorMessage: '',
            };

        case REFERRAL_LIST_SUCCESS:
            return {
                ...state,
                referralListRequest: false,
                referralListSuccess: true,
                referralListFail: false,
                referralList: action.payload.data,
                errorMessage: '',
            };

        case REFERRAL_LIST_FAIL:
            return {
                ...state,
                referralListRequest: false,
                referralListSuccess: false,
                referralListFail: true,
                errorMessage: action.payload,
            };

        //edit single referral
        case EDIT_REFERRAL_DATA:
            return {
                ...state,
                editreferralData: action.payload,
            };
        //add Referral
        case ADD_REFERRAL:
            return {
                ...state,
                addReferralRequest: true,
                addReferralSuccess: false,
                addReferralFail: false,
                addReferral: [],
                errorMessage: '',
            };

        case ADD_REFERRAL_SUCCESS:
            return {
                ...state,
                addReferralRequest: false,
                addReferralSuccess: true,
                addReferralFail: false,
                addReferral: action.payload.data,
                errorMessage: '',
            };

        case ADD_REFERRAL_FAIL:
            return {
                ...state,
                addReferralRequest: false,
                addReferralSuccess: false,
                addReferralFail: true,
                errorMessage: action.payload,
            };

        //update
        case UPDATE_REFERRAL:
            return {
                ...state,
                updateReferralRequest: true,
                updateReferralSuccess: false,
                updateReferralFail: false,
                updateReferral: [],
                errorMessage: '',
            };

        case UPDATE_REFERRAL_SUCCESS:
            return {
                ...state,
                updateReferralRequest: false,
                updateReferralSuccess: true,
                updateReferralFail: false,
                updateReferral: action.payload.data,
                errorMessage: '',
            };

        case UPDATE_REFERRAL_FAIL:
            return {
                ...state,
                updateReferralRequest: false,
                updateReferralSuccess: false,
                updateReferralFail: true,
                errorMessage: action.payload,
            };

        //single refcodde info
        case EDIT_REFERRAL_CODE:
            return {
                ...state,
                editReferralCode: action.payload,
            };

        //referred user details
        case REFERRRED_USERS:
            return {
                ...state,
                referredUserRequest: true,
                referredUserSuccess: false,
                referredUserFail: false,
                referredUser: [],
                errorMessage: '',
            };

        case REFERRRED_USERS_SUCCESS:
            return {
                ...state,
                referredUserRequest: false,
                referredUserSuccess: true,
                referredUserFail: false,
                referredUser: action.payload.data,
                errorMessage: '',
            };

        case REFERRRED_USERS_FAIL:
            return {
                ...state,
                referredUserRequest: false,
                referredUserSuccess: false,
                referredUserFail: true,
                errorMessage: action.payload,
            };
        //converted user details
        case CONVERTED_TNX:
            return {
                ...state,
                convertedTnxRequest: true,
                convertedTnxSuccess: false,
                convertedTnxFail: false,
                convertedTnx: [],
                errorMessage: '',
            };

        case CONVERTED_TNX_SUCCESS:
            return {
                ...state,
                convertedTnxRequest: false,
                convertedTnxSuccess: true,
                convertedTnxFail: false,
                convertedTnx: action.payload.data,
                errorMessage: '',
            };

        case CONVERTED_TNX_FAIL:
            return {
                ...state,
                convertedTnxRequest: false,
                convertedTnxSuccess: false,
                convertedTnxFail: true,
                errorMessage: action.payload,
            };

        default:
            return state;
    }
};

export default referralCodeReducer;
