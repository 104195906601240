import React from 'react';
import '../../../Utilities/style.css';
import '../Login/login.css';
import DetailLayout from '../../Layouts/DetailLayout/DetailLayout';
import Table from '../../../Components/Table/Table';
import {connect} from 'react-redux';
import {getContestList} from '../Contests/AxioCalls';
import {logout} from '../Login/LoginAction';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';
import Snackbar from '../../../Components/Snackbar/Snackbar';
import {timeFormat} from '../../../Utilities/Helpers';
import {next, prev, reset} from '../../../Utilities/HelperFunctions';

class UserContestWon extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: [],
            selectAll: false,
            list: [],
            open: false,
            adminpswd: '',
            loading: true,
            errorMessage: '',
            snackbarOpen: false,
            pageData: {},
            pageOptions: {
                page: 1,
                pageSize: 10,
                searchString: null,
                sortOrder: null,
                listType: 'USERCONTESTWON',
                userId: this.props.user.currentUser.user,
            },
            sortOptions: [
                {
                    value: 'id',
                    text: 'Contest Id',
                },
                {
                    value: 'contestType',
                    text: 'Contest Type',
                },
                {
                    value: 'match',
                    text: 'Match Id',
                },
                {
                    value: 'updatedAt',
                    text: 'Last Updated',
                },

                {
                    text: 'Status',
                    value: 'status',
                },
            ],
        };
    }

    componentWillMount() {
        const {history, login} = this.props;
        if (!login.userDetails.data) {
            history.push('/');
            this.props.logout();
        } else {
            const {pageOptions} = this.state;

            this.props.getContestList(pageOptions, login.userDetails.data.accessToken);
        }
    }

    componentDidUpdate(prevProps) {
        const {contest} = this.props;
        if (contest.contestListSuccess && !prevProps.contest.contestListSuccess) {
            this.setState({
                loading: false,
                list: contest.contestList.data.data,
                pageData: contest.contestList.data.pageData,
            });
        } else if (contest.contestListFail && !prevProps.contest.contestListFail) {
            this.setState({
                snackbarOpen: true,
                errorMessage: contest.errorMessage,
            });
        }
    }

    /**
     * reset table data
     */
    onReset() {
        const {pageOptions} = this.state;
        const {login} = this.props;
        let options = reset(pageOptions);
        this.setState({
            pageOptions: options,
        });
        this.props.getContestList(options, login.userDetails.data.accessToken);
    }

    /**
     * APi call with search and sort strings
     * @param {string} field field names of input
     * @param {string} value value in the field
     */
    onSearchandSort(field, value) {
        const {pageOptions} = this.state;
        const {login} = this.props;
        let options = pageOptions;
        options[field] = value;
        this.setState({pageOptions: options});

        if (field === 'searchString' && value.length > 2) {
            this.props.getContestList(options, login.userDetails.data.accessToken);
        } else if (field === 'sortOrder') {
            this.props.getContestList(options, login.userDetails.data.accessToken);
        }
    }

    /**
     * pagination  and api call on  next click
     */
    onNext() {
        const {pageData, pageOptions} = this.state;
        const {login} = this.props;
        let options = next(pageOptions, pageData);

        if (options) {
            this.props.getContestList(options, login.userDetails.data.accessToken);
            this.setState({pageOptions: options, loading: true});
        }
    }

    /**
     * pagination  and api call on  prev click
     */
    onPrev() {
        const {pageOptions} = this.state;
        const {login} = this.props;
        let options = prev(pageOptions);
        if (options) {
            this.props.getContestList(options, login.userDetails.data.accessToken);
            this.setState({pageOptions: options, loading: true});
        }
    }

    /**
     * display pool prize with either by ₹ or FC
     * @param {*} data row data
     * @param {*} cashValue cash value in that row
     */
    poolPrize(data, cashValue) {
        let list = this.state.list;
        let index = data.index;
        let current = list[index];
        let header = data.column.Header;
        let key = '';

        if (header === 'Total Paid') {
            key = current.userTotalPaid;
        } else if (header === 'Winnings') {
            key = current.userTotalWinning;
        } else {
            key = current.entryFeePerTeam;
        }

        if (cashValue === 'COINVALUE' || cashValue === 'COINPERCENT') {
            let pool = key + ' FC';
            return <span>{pool}</span>;
        } else {
            let pool = '₹ ' + key;
            return <span>{pool}</span>;
        }
    }

    render() {
        const {list, sortOptions, pageData, snackbarOpen, errorMessage, loading} = this.state;
        const {searchString, sortOrder, page} = this.state.pageOptions;

        const data = list;
        const {history, user} = this.props;

        let userDetails = {};
        userDetails.name = user.currentUser.userName;
        userDetails.id = user.currentUser.user;
        let disableNext = page === pageData.lastPage ? true : false;
        const columns = [
            {
                Header: 'Match ID',
                accessor: 'match',
                width: 200,
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Contest ID',
                accessor: 'id', // accessor is the "key" in the data
                width: 200,
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Contest Type',
                accessor: 'contestTypeName',

                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Match Start Time',
                accessor: 'matchStartTime', // accessor is the "key" in the data

                Cell: (props) => (
                    <span className="date-space">{props.value === '' ? '-' : timeFormat(props.value)}</span>
                ),
            },
            {
                Header: 'Last Updatede',
                accessor: 'updatedAt',

                Cell: (props) => (
                    <span className="date-space">{props.value === '' ? '-' : timeFormat(props.value)}</span>
                ),
            },
            {
                Header: 'User Teams',
                accessor: 'userTeamCount', // accessor is the "key" in the data
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Winning User Teams',
                accessor: 'userWinningTeamCount',
                width: 150,
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Entry Fee / Team',
                accessor: 'valueType',
                Cell: (props) => this.poolPrize(props, props.value),
            },
            {
                Header: 'Total Paid',
                accessor: 'valueType',
                Cell: (props) => this.poolPrize(props, props.value),
            },
            {
                Header: 'Winnings',
                accessor: 'valueType',
                Cell: (props) => this.poolPrize(props, props.value),
            },

            {
                Header: 'Status',
                accessor: 'status',
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
        ];
        return (
            <DetailLayout
                location="userDetails"
                history={history}
                searchbar="Search Match ID / Contest ID"
                search={true}
                centerTitle="User - Contests | Won"
                id={userDetails}
                onsearchChange={this.onSearchandSort.bind(this)}
                sortOptions={sortOptions}
                count={pageData.total}
                onReset={this.onReset.bind(this)}
                searchString={searchString}
                sortOrder={sortOrder}
            >
                <LoadingModal open={loading} />
                <Snackbar
                    open={snackbarOpen}
                    message={errorMessage}
                    onClose={() => this.setState({snackbarOpen: false})}
                />

                <Table
                    data={data}
                    columns={columns}
                    page={page}
                    onPrevClick={this.onPrev.bind(this)}
                    onNextClick={this.onNext.bind(this)}
                    disableNext={disableNext}
                ></Table>
            </DetailLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        squad: state.squad,
        player: state.player,
        user: state.user,
        contest: state.contest,
    };
};

export default connect(mapStateToProps, {getContestList, logout})(UserContestWon);
