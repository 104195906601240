import axios from 'axios';
import {
    listSquad,
    listSquadSuccess,
    listSquadFail,
    addSquadRequest,
    addSquadSuccess,
    addSquadFail,
    updateSquads,
    updateSquadsFail,
    updateSquadsSuccess,
    deleteSquads,
    deleteSquadsFail,
    deleteSquadsSuccess,
    getSquadRequest,
    getSquadSuccess,
    getSquadFail,
} from './squadAction';
import {HEADER_SOURCE, URL} from '../../../Utilities/Constants';
import {GET_SQUAD_BY_ID, GET_SQUAD_BY_ID_FAIL, GET_SQUAD_BY_ID_SUCCESS} from './SquadConstants';

export const MATCH_ID_UPLOAD_FANTASY_RESULTS = 'MATCH_ID_UPLOAD_FANTASY_RESULTS';

/**
 * squad list api
 * @param {*} squad page details
 * @param {string} token user token
 */
export function getSquadList(squad, token,client) {
    return (dispatch) => {
        dispatch(listSquad());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token,clientid: client},
            url: `${URL}/squad/list`,
            data: squad,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(listSquadSuccess(data));
                } else if (data.data.status === 'fail') {
                    dispatch(listSquadFail(data.data.errorData.errorReason));
                } else {
                    dispatch(listSquadFail('Unknown Error Occured'));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(listSquadFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(listSquadFail(err.message || 'Something went wrong'));
                }
            });
    };
}

/**
 * create squad
 * @param {string} squadName squad name
 * @param {string} Squads players
 * @param {string} Inactive status
 * @param {string} token user token
 */
export function addSquad(squadName, Squads, Inactive, token) {
    return (dispatch) => {
        dispatch(addSquadRequest());
        axios({
            method: 'post',
            headers: {
                'x-source': HEADER_SOURCE,
                Authorization: 'Bearer ' + token,
            },
            url: `${URL}/squad/create`,
            data: {
                squadName: squadName,
                players: JSON.stringify(Squads),
                inactivePlayer: Inactive,
            },
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(addSquadSuccess(data));
                } else if (data.data.status === 'fail') {
                    dispatch(addSquadFail(data.data.errorData.errorReason));
                } else {
                    dispatch(addSquadFail('Unknown Error Occured'));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(addSquadFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(addSquadFail(err.message || 'Something went wrong'));
                }
            });
    };
}

/**
 * edit squad
 * @param {*} squad squad details
 * @param {*} token user token
 */
export function updateSquad(squad, token) {
    return (dispatch) => {
        dispatch(updateSquads());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token},
            url: `${URL}/squad/update`,
            data: squad,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(updateSquadsSuccess(data));
                } else if (data.data.status === 'fail') {
                    dispatch(updateSquadsFail(data.data.errorData.errorReason));
                } else {
                    dispatch(updateSquadsFail('Unknown Error Occured'));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(updateSquadsFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(updateSquadsFail(err.message || 'Something went wrong'));
                }
            });
    };
}

/**
 * delete squad
 * @param {*} id squad id
 * @param {*} token user token
 */
export function deleteSquad(id, token) {
    return (dispatch) => {
        dispatch(deleteSquads());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token},
            url: `${URL}/squad/delete`,
            data: id,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(deleteSquadsSuccess(data));
                } else if (data.data.status === 'fail') {
                    dispatch(deleteSquadsFail(data.data.errorData.errorReason));
                } else {
                    dispatch(deleteSquadsFail('Unknown Error Occured'));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(deleteSquadsFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(deleteSquadsFail(err.message || 'Something went wrong'));
                }
            });
    };
}

/**
 * get squad
 * @param {*} squad squad details
 * @param {*} token user token
 */
export function getSquad(squad, game, token, clientId) {
    return (dispatch) => {
        dispatch(getSquadRequest());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token, clientid: clientId},
            url: `${URL}/squad/get`,
            data: {
                squad: squad,
                game: game,
            },
        })
            .then((data) => {
                console.log('GET_SQUAD', data);
                if (data.data.status === 'success') {
                    dispatch(getSquadSuccess(data));
                } else if (data.data.status === 'fail') {
                    dispatch(getSquadFail(data.data.errorData.errorReason));
                } else {
                    dispatch(getSquadFail('Unknown Error Occured'));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(getSquadFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(getSquadFail(err.message || 'Something went wrong'));
                }
            });
    };
}

export function getSquadById(data, token) {
    return (dispatch) => {
        dispatch({type: GET_SQUAD_BY_ID});
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token},
            url: `${URL}/squad/get`,
            data,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch({type: GET_SQUAD_BY_ID_SUCCESS, payload: data.data});
                } else if (data.data.status === 'fail') {
                    dispatch({type: GET_SQUAD_BY_ID_FAIL, payload: data.data.errorData.errorReason});
                } else {
                    dispatch({type: GET_SQUAD_BY_ID_FAIL, payload: 'Something went wrong!'});
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch({type: GET_SQUAD_BY_ID_FAIL, payload: errorMsg || 'Something went wrong!'});
                } else {
                    dispatch({type: GET_SQUAD_BY_ID_FAIL, payload: err.message || 'Something went wrong!'});
                }
            });
    };
}

//not yet used
export function setMatchIdForUploadFantasyResults(matchId) {
    return (dispatch) => {
        dispatch({type: MATCH_ID_UPLOAD_FANTASY_RESULTS, payload: matchId});
    };
}
