import axios from 'axios';
import {
    listTOOrganizers,
    listTOOrganizersSuccess,
    listTOOrganizersFail,
    addTOOrganizer,
    addTOOrganizerSuccess,
    addTOOrganizerFail,
    updateTOOrganizerRequest,
    updateTOOrganizerSuccess,
    updateTOOrganizerFail,
    deleteTOOrganizerRequest,
    deleteTOOrganizerSuccess,
    deleteTOOrganizerFail,
    getTOOrganizerRequest,
    getTOOrganizerSuccess,
    getTOOrganizerFail,
} from './action';
import {TOURL} from '../../../Utilities/Constants';

export function getTOOrganizerList(pageOptions, token) {
    // listing all TOOrganizers
    return (dispatch) => {
        dispatch(listTOOrganizers());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token},
            url: `${TOURL}/organizer/list`,
            data: pageOptions,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(listTOOrganizersSuccess(data));
                } else {
                    dispatch(listTOOrganizersFail(data.data.errorData.errorReason));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(listTOOrganizersFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(listTOOrganizersFail(err.message || 'Something went wrong'));
                }
            });
    };
}

//creating new TOOrganizer
export function createTOOrganizer(TOOrganizer, token) {
    return (dispatch) => {
        dispatch(addTOOrganizer());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token},
            url: `${TOURL}/organizer/create`,
            data: TOOrganizer,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(addTOOrganizerSuccess(data));
                } else {
                    dispatch(addTOOrganizerFail(data.data.errorData.errorReason));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(addTOOrganizerFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(addTOOrganizerFail(err.message || 'Something went wrong'));
                }
            });
    };
}

//TOOrganizer update
export function updateTOOrganizer(TOOrganizer, token) {
    return (dispatch) => {
        dispatch(updateTOOrganizerRequest());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token},
            url: `${TOURL}/organizer/update`,
            data: TOOrganizer,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(updateTOOrganizerSuccess(data));
                } else {
                    dispatch(updateTOOrganizerFail(data.data.errorData.errorReason));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(updateTOOrganizerFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(updateTOOrganizerFail(err.message || 'Something went wrong'));
                }
            });
    };
}

//TOOrganizer delete
export function deleteTOOrganizer(id, token) {
    return (dispatch) => {
        dispatch(deleteTOOrganizerRequest());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token},
            url: `${TOURL}/organizer/delete`,
            data: {organizerId: id},
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(deleteTOOrganizerSuccess(data));
                } else {
                    dispatch(deleteTOOrganizerFail(data.data.errorData.errorReason));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(deleteTOOrganizerFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(deleteTOOrganizerFail(err.message || 'Something went wrong'));
                }
            });
    };
}

// get single TOOrganizer
export function getTOOrganizer(id, token) {
    return (dispatch) => {
        dispatch(getTOOrganizerRequest());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token},
            url: `${TOURL}/organizer/get`,
            data: {
                organizerId: id,
            },
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(getTOOrganizerSuccess(data));
                } else {
                    dispatch(getTOOrganizerFail(data.data.errorData.errorReason));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(getTOOrganizerFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(getTOOrganizerFail(err.message || 'Something went wrong'));
                }
            });
    };
}
