import {
    GET_GLACIER_TOURNAMENTS,
    GET_GLACIER_TOURNAMENTS_SUCCESS,
    GET_GLACIER_TOURNAMENTS_FAIL,
    GET_GLACIER_SERIES,
    GET_GLACIER_SERIES_SUCCESS,
    GET_GLACIER_SERIES_FAIL,
    GET_GLACIER_MATCHES,
    GET_GLACIER_MATCHES_SUCCESS,
    GET_GLACIER_MATCHES_FAIL,
} from './constants';

const initialState = {
    glacierTO: {},
    isFetchingGlacierTO: false,
    isFetchingGlacierTOSuccess: false,
    isFetchingGlacierTOFail: false,

    glacierSeries: {},
    isFetchingGlacierSeries: false,
    isFetchingGlacierSeriesSuccess: false,
    isFetchingGlacierSeriesFail: false,

    glacierMatches: {},
    isFetchingGlacierMatches: false,
    isFetchingGlacierMatchesSuccess: false,
    isFetchingGlacierMatchesFail: false,

    message: '',
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_GLACIER_TOURNAMENTS:
            return {
                ...state,
                isFetchingGlacierTO: true,
                isFetchingGlacierTOSuccess: false,
                isFetchingGlacierTOFail: false,
                glacierTO: {},
                message: '',
            };
        case GET_GLACIER_TOURNAMENTS_SUCCESS:
            return {
                ...state,
                isFetchingGlacierTO: false,
                isFetchingGlacierTOSuccess: true,
                isFetchingGlacierTOFail: false,
                glacierTO: action?.payload || {},
                message: '',
            };
        case GET_GLACIER_TOURNAMENTS_FAIL:
            return {
                ...state,
                isFetchingGlacierTO: false,
                isFetchingGlacierTOSuccess: false,
                isFetchingGlacierTOFail: true,
                message: action.payload,
            };

        case GET_GLACIER_SERIES:
            return {
                ...state,
                isFetchingGlacierSeries: true,
                isFetchingGlacierSeriesSuccess: false,
                isFetchingGlacierSeriesFail: false,
                glacierSeries: {},
                message: '',
            };
        case GET_GLACIER_SERIES_SUCCESS:
            return {
                ...state,
                isFetchingGlacierSeries: false,
                isFetchingGlacierSeriesSuccess: true,
                isFetchingGlacierSeriesFail: false,
                glacierSeries: action?.payload || {},
                message: '',
            };
        case GET_GLACIER_SERIES_FAIL:
            return {
                ...state,
                isFetchingGlacierSeries: false,
                isFetchingGlacierSeriesSuccess: false,
                isFetchingGlacierSeriesFail: true,
                message: action.payload,
            };

        case GET_GLACIER_MATCHES:
            return {
                ...state,
                isFetchingGlacierMatches: true,
                isFetchingGlacierMatchesSuccess: false,
                isFetchingGlacierMatchesFail: false,
                glacierMatches: {},
                message: '',
            };
        case GET_GLACIER_MATCHES_SUCCESS:
            return {
                ...state,
                isFetchingGlacierMatches: false,
                isFetchingGlacierMatchesSuccess: true,
                isFetchingGlacierMatchesFail: false,
                glacierMatches: action?.payload || {},
                message: '',
            };
        case GET_GLACIER_MATCHES_FAIL:
            return {
                ...state,
                isFetchingGlacierMatches: false,
                isFetchingGlacierMatchesSuccess: false,
                isFetchingGlacierMatchesFail: true,
                message: action.payload,
            };

        default:
            return state;
    }
};

export default reducer;
