export const CONTEST_TYPE_LIST = 'CONTEST_TYPE_LIST';
export const CONTEST_TYPE_LIST_SUCCESS = 'CONTEST_TYPE_LIST_SUCCESS';
export const CONTEST_TYPE_LIST_FAIL = 'CONTEST_TYPE_LIST_FAIL';

export const ADD_CONTEST_TYPE = 'ADD_CONTEST_TYPE';
export const ADD_CONTEST_TYPE_SUCCESS = 'ADD_CONTEST_TYPE_SUCCESS';
export const ADD_CONTEST_TYPE_FAIL = 'ADD_CONTEST_TYPE_FAIL';

export const UPDATE_CONTEST_TYPE = 'UPDATE_CONTEST_TYPE';
export const UPDATE_CONTEST_TYPE_SUCCESS = 'UPDATE_CONTEST_TYPE_SUCCESS';
export const UPDATE_CONTEST_TYPE_FAIL = 'UPDATE_CONTEST_TYPE_FAIL';

export const DELETE_CONTEST_TYPE = 'DELETE_CONTEST_TYPE';
export const DELETE_CONTEST_TYPE_SUCCESS = 'DELETE_CONTEST_TYPE_SUCCESS';
export const DELETE_CONTEST_TYPE_FAIL = 'DELETE_CONTEST_TYPE_FAIL';

export const EDIT_CONEST_TYPE = 'EDIT_CONEST_TYPE';

export const GET_CONTEST_TYPE = 'GET_CONTEST_TYPE';
export const GET_CONTEST_TYPE_SUCCESS = 'GET_CONTEST_TYPE_SUCCESS';
export const GET_CONTEST_TYPE_FAIL = 'GET_CONTEST_TYPE_FAIL';

export const GET_CONTEST_CATEGORY = 'GET_CONTEST_CATEGORY';
export const GET_CONTEST_CATEGORY_SUCCESS = 'GET_CONTEST_CATEGORY_SUCCESS';
export const GET_CONTEST_CATEGORY_FAIL = 'GET_CONTEST_CATEGORY_FAIL';
