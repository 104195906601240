import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import '../../../Utilities/style.css';
import '../Login/login.css';
import {getDynamicPrizePools, getDynamicPrizePool} from './action';
import {logout} from '../Login/LoginAction';
import {debounce, next, prev} from '../../../Utilities/HelperFunctions';
import {timeFormat} from '../../../Utilities/Helpers';

import TableLayout from '../../Layouts/TableLayout/TableLayout';
import Table from '../../../Components/Table/Table';
import Snackbar from '../../../Components/Snackbar/Snackbar';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';
import FilterInput from '../../../Components/FilterInput';
import DropDown from '../../../Components/Dropdown/Dropdown';
import Button from '../../../Components/Button/Button';
import {COMMON_STATUS, ENTER} from '../../../Utilities/Constants';
import CreateEditDynamicPrizePoolModal from './CreateEditDynamicPrizePoolModal';
import CloudDownload from '@material-ui/icons/CloudDownload';

class DynamicPrizePools extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageOptions: {
                page: 1,
                pageSize: 20,
            },
        };
    }

    componentDidMount() {
        const {login, history} = this.props;
        if (!login?.userDetails?.data) {
            history.push('/');
            this.props.logout();
        } else {
            this.getDynamicPrizePoolsDebounce({immediate: true});
        }
    }

    componentDidUpdate(prevProps) {
        const {dynamicPrizePools} = this.props;
        if (
            (dynamicPrizePools.isFetchingDynamicPrizePools &&
                !prevProps.dynamicPrizePools.isFetchingDynamicPrizePools) ||
            (dynamicPrizePools.isFetchingDynamicPrizePool && !prevProps.dynamicPrizePools.isFetchingDynamicPrizePool)
        ) {
            this.setState({loading: true});
        }

        if (
            dynamicPrizePools.isFetchingDynamicPrizePoolsSuccess &&
            !prevProps.dynamicPrizePools.isFetchingDynamicPrizePoolsSuccess
        ) {
            this.setState({loading: false});
        }

        if (
            dynamicPrizePools.isFetchingDynamicPrizePoolSuccess &&
            !prevProps.dynamicPrizePools.isFetchingDynamicPrizePoolSuccess
        ) {
            this.setState({loading: false, dynamicPrizePoolData: dynamicPrizePools.details});
        }

        if (
            dynamicPrizePools.isCreatingDynamicPrizePoolSuccess &&
            !prevProps.dynamicPrizePools.isCreatingDynamicPrizePoolSuccess
        ) {
            this.setState({message: dynamicPrizePools?.message?.msg, dynamicPrizePoolData: undefined});
            this.getDynamicPrizePoolsDebounce({immediate: true});
        }

        if (
            dynamicPrizePools.isUpdatingDynamicPrizePoolSuccess &&
            !prevProps.dynamicPrizePools.isUpdatingDynamicPrizePoolSuccess
        ) {
            this.setState({message: dynamicPrizePools?.message?.msg, dynamicPrizePoolData: undefined});
            this.getDynamicPrizePoolsDebounce({immediate: true});
        }

        if (
            (dynamicPrizePools.isFetchingDynamicPrizePoolsFail &&
                !prevProps.dynamicPrizePools.isFetchingDynamicPrizePoolsFail) ||
            (dynamicPrizePools.isFetchingDynamicPrizePoolFail &&
                !prevProps.dynamicPrizePools.isFetchingDynamicPrizePoolFail)
        ) {
            this.setState({message: dynamicPrizePools.message.msg, loading: false});
        }
    }

    /**
     * api call on click of next
     */
    onNext = () => {
        const {pageOptions} = this.state;
        const {
            dynamicPrizePools: {
                list: {pageData = {}},
            },
        } = this.props;
        let options = next(pageOptions, pageData);
        if (options) {
            this.setState({pageOptions: options}, () => this.getDynamicPrizePoolsDebounce({immediate: true}));
        }
    };

    /**
     * api call on click of prev
     */
    onPrev = () => {
        const {pageOptions} = this.state;
        let options = prev(pageOptions);
        if (options) {
            this.setState({pageOptions: options}, () => this.getDynamicPrizePoolsDebounce({immediate: true}));
        }
    };

    /**
     * reset dynamicPrizePool filters
     */
    onReset = () => {
        this.setState({pageOptions: {page: 1, pageSize: 20}}, () => {
            this.getDynamicPrizePoolsDebounce({immediate: true});
        });
    };

    /**
     * navigate to selected dynamicPrizePool page with details
     * @param {string} templateId id of dynamicPrizePool selected
     */
    handleClickOnTemplateId = (templateId) => {
        const {login, client} = this.props;
        this.props.getDynamicPrizePool({templateId}, login.userDetails.data.accessToken, client.selectedClient);
    };

    handleCreateTemplate = () => {
        this.setState({dynamicPrizePoolData: {}});
    };

    getDynamicPrizePoolsDebounce = debounce(() => {
        const {login, client} = this.props;
        this.props.getDynamicPrizePools(
            this.state.pageOptions,
            login.userDetails.data.accessToken,
            client.selectedClient,
        );
    });

    handleFilter = (field, value, debounce = true) => {
        const {pageOptions} = this.state;
        if (field === ENTER) {
            this.getDynamicPrizePoolsDebounce({immediate: true});
            return;
        }
        pageOptions[field] = value;
        this.setState({pageOptions: {...pageOptions, page: 1}}, () => {
            this.getDynamicPrizePoolsDebounce(debounce ? {} : {immediate: true});
        });
    };

    handleDownloadFile = (url = '') => {
        window.open(url, '_blank');
    };

    render() {
        const {message, loading, dynamicPrizePoolData} = this.state;
        const {page, status, templateName, templateId} = this.state.pageOptions;
        const {
            history,
            dynamicPrizePools: {
                list: {data: dppList = [], pageData = {}},
            },
            login,
        } = this.props;
        let disableNext = page >= pageData.lastPage;
        const {dynamicPrizePoolWrite} = login?.permissions || {};

        const columns = [
            {
                Header: 'Template ID',
                accessor: 'id',
                width: 200,
                Cell: (props) => (
                    <span className="table-clickable cursor" onClick={() => this.handleClickOnTemplateId(props.value)}>
                        {props.value || '-'}
                    </span>
                ),
            },
            {
                Header: 'Template Name',
                accessor: 'templateName',
                Cell: (props) => <span>{props.value || '-'}</span>,
            },
            {
                Header: 'Win Rate',
                accessor: 'winRate',
                Cell: (props) => <span>{props.value || '-'}</span>,
            },
            {
                Header: 'Rake',
                accessor: 'rake',
                Cell: (props) => <span>{props.value || '-'}</span>,
            },
            {
                Header: 'Total Slots',
                accessor: 'totalSlots',
                Cell: (props) => <span>{props.value || '-'}</span>,
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: (props) => <span>{props.value || '-'}</span>,
            },
            {
                Header: 'CreatedAt',
                accessor: 'createdAt',
                width: 100,
                Cell: (props) => (
                    <span className="number date-space">{props.value ? timeFormat(props.value) : '-'}</span>
                ),
            },
            {
                Header: 'UpdatedAt',
                accessor: 'updatedAt',
                width: 100,
                Cell: (props) => (
                    <span className="number date-space">{props.value ? timeFormat(props.value) : '-'}</span>
                ),
            },
            {
                Header: 'Download CSV',
                accessor: 'templateUrl',
                Cell: (props) =>
                    !!props.value && (
                        <CloudDownload
                            titleAccess="Download CSV"
                            className="clickable-icon mr-5"
                            fontSize="small"
                            onClick={() => this.handleDownloadFile(props.value)}
                        />
                    ),
            },
        ];
        return (
            <TableLayout location="Dynamic Prize Pool" history={history} hideSearchBar>
                {!!loading && <LoadingModal open={!!loading} />}
                {!!message && (
                    <Snackbar open={!!message} message={message} onClose={() => this.setState({message: ''})} />
                )}

                {!!dynamicPrizePoolData && (
                    <CreateEditDynamicPrizePoolModal
                        open={!!dynamicPrizePoolData}
                        dynamicPrizePoolDetail={dynamicPrizePoolData}
                        handleClose={() => this.setState({dynamicPrizePoolData: undefined})}
                    />
                )}

                <div className="filter-container my-10">
                    <FilterInput
                        placeholder="Template Id"
                        value={templateId || ''}
                        onChange={(value) => this.handleFilter('templateId', value)}
                        onEnter={() => this.handleFilter(ENTER)}
                    />
                    <FilterInput
                        placeholder="Template Name"
                        value={templateName || ''}
                        onChange={(value) => this.handleFilter('templateName', value)}
                        onEnter={() => this.handleFilter(ENTER)}
                    />
                    <DropDown
                        label="Status"
                        value={status || ''}
                        menuItems={COMMON_STATUS}
                        onChange={(value) => this.handleFilter('status', value, false)}
                    />
                    <Button buttonText="Reset" onClick={this.onReset} />
                </div>
                <div className="filter-container margin-bottom-10">
                    {!!dynamicPrizePoolWrite && (
                        <Button buttonText="Create Template" onClick={this.handleCreateTemplate} />
                    )}
                </div>

                <Table
                    data={dppList}
                    columns={columns}
                    page={page}
                    onPrevClick={this.onPrev}
                    onNextClick={this.onNext}
                    disableNext={disableNext}
                ></Table>
            </TableLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        dynamicPrizePools: state.dynamicPrizePools,
        client: state.client,
    };
};

const matchDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getDynamicPrizePools,
            getDynamicPrizePool,
            logout,
        },
        dispatch,
    );

export default connect(mapStateToProps, matchDispatchToProps)(DynamicPrizePools);
