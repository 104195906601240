import React from 'react';
import '../../../Utilities/style.css';
import '../Login/login.css';
import {connect} from 'react-redux';
import Tabs from '../../../Components/Tabs/Tabs';
import Card from '../../../Components/Card/Card';
import DetailLayout from '../../Layouts/DetailLayout/DetailLayout';
import Input from '../../../Components/Input/Input';
import DropDown from '../../../Components/Dropdown/Dropdown';
import {createPlayer, updatePlayers, deletePlayers, getPlayerRequest} from './PlayerAxios';
import Snackbar from '../../../Components/Snackbar/Snackbar';
import Modal from '../../../Components/Modal/Modal';
import Button from '../../../Components/Button/Button';
import Close from '../../../Images/close_yellow.png';
import axios from 'axios';
import {URL} from '../../../Utilities/Constants';
import ImageInput from '../../../Components/ImageInput/ImageInput';
import {getSquadList, getSquad} from '../Squads/SquadAxios';
import {getTOGameList} from '../TOGame/AxioCalls';
import AutoCompleteField from '../../../Components/AutoCompleteField/AutoCompleteField';
import {logout} from '../Login/LoginAction';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';
import {timeFormat, isValidNumber, isNumber, isValidString} from '../../../Utilities/Helpers';

class AddPlayer extends React.Component {
    constructor(props) {
        super(props);
        let location = this.props.history.location.pathname.includes('edit');
        this.state = {
            menuItems: [
                {value: 'ACTIVE', text: 'ACTIVE'},
                {value: 'INACTIVE', text: 'INACTIVE'},
            ],
            // positionOptions: [
            //     {value: "PLAYER", text: "PLAYER"},
            //     {value: "LEADER", text: "LEADER"},
            //     {value: "THE SNIPER", text: "THE SNIPER"},
            //     {value: "THE FRAGGER", text: "THE FRAGGER"},
            //     {value: "THE SUPPORT", text: "THE SUPPORT"},
            //     {value: "BATSMAN", text: "BATSMAN"},
            //     {value: "BOWLER", text: "BOWLER"},
            //     {value: "ALL ROUNDER", text: "ALL ROUNDER"},
            //     {value: "WICKET KEEPER", text: "WICKET KEEPER"},
            // ],
            positionOptions: [],
            disabled: false,
            status: null,
            squadName: null,
            image: null,
            s3Url: null,
            uploadImage: null,
            pubgName: null,
            currentCredits: null,
            fcRating: null,
            fullName: null,
            nationality: null,
            squadPosition: null,
            selectedGame: null,
            matchesPlayed: null,
            fantasyStats: [],
            squadList: [],
            tournaments: null,
            gameOptions: [],
            apiError: '',
            edit: location,
            open: false,
            newSquad: false,
            loading: false,
            stats: {},
            squadOptions: [
                {
                    title: 'Create New Squad',
                    value: 'newSquad',
                },
            ],
        };
    }

    handleInputChangeForMultipleSquads(field, index, value) {
        const {edit, squadList} = this.state;
        if (edit && field.includes('squadList')) {
            this.setState({[field]: value, errorMessage: ''});
            let temp = squadList;
            temp[index].status = value;
            this.setState({squadList: temp});
        }
    }

    /**
     * function to save entered data in state
     * @param {string} field field names from the form
     * @param {*} value value in the field
     */
    handleInputChange(field, value) {
        const {squadOptions, edit, stats} = this.state;
        const {toGame} = this.props;

        if (field.includes('squadName')) {
            if (value !== null && value.value === 'newSquad') {
                this.setState({newSquad: true, squadName: null, errorMessage: ''});
                return;
            } else if (value !== null) {
                let squads = squadOptions;
                let index = squads.findIndex((x) => x.value === value.value);
                if (index !== -1) {
                    squads.splice(index, 1);
                }
                this.setState({[field]: value, squadOptions: squads, errorMessage: ''});
            } else {
                let value = this.state[field];
                let squads = squadOptions;
                if (value) {
                    squads.push({title: value.title, value: value});
                }
                this.setState({
                    [field]: null,
                    squadOptions: squads,
                });
            }
        } else {
            let newState = {errorMessage: ''};
            newState[[field]] = value;
            this.setState(newState);
        }

        if (field === 'selectedGame') {
            let selectedGame = toGame.TOGameList.data.data.filter((item) => item.id == value)[0];
            const availableRoles = toGame?.TOGameList?.data?.data?.filter((item) => item.id === value)?.[0]
                ?.availableRoles;
            let validStats = selectedGame.validPlayerStats;
            let playerStatsModel = selectedGame?.statsModel?.playerStats?.external;
            let initialStats = {};
            if (playerStatsModel) {
                for (let i in playerStatsModel) {
                    let statDescription = playerStatsModel[i];
                    if (statDescription.statType === 'BASE') {
                        let item = {};
                        item['displayName'] = statDescription.displayName;
                        item['value'] = statDescription.defaultValue;
                        initialStats[statDescription.name] = item;
                    }
                }
            } else {
                for (let attribute of validStats) {
                    let item = {};
                    item['displayName'] = attribute.displayName;
                    item['value'] = 0;

                    initialStats[attribute.attributeName] = item;
                }
            }
            this.setState({
                loading: false,
                stats: initialStats,
                positionOptions: availableRoles?.map((item) => ({value: item, text: item})),
                squadName: '',
                squadOptions: [
                    {
                        title: 'Create New Squad',
                        value: 'newSquad',
                    },
                ],
            });
        }
        if (field === 'uploadImage') {
            this.setState({loading: true});
            const data = new FormData();
            data.append('image', value);
            axios({
                method: 'post',
                headers: {
                    Authorization: 'Bearer ' + this.props.login.userDetails.data.accessToken,
                },
                url: `${URL}/player/imageupload`,
                data: data,
            })
                .then((res) => {
                    this.setState({loading: false});
                    if (res.data.data) this.setState({image: res.data.data.image, s3Url: res.data.data.s3Url});
                })
                .catch((err) => {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    this.setState({loading: false, errorMessage: errorMsg});
                });
        }
    }

    handleChangeState = (stateObj = {}) => {
        this.setState({...stateObj});
    };

    /**
     * on input change handler for autosuggest
     * @param {string} field field name from the form
     * @param {string} event value in the field
     */
    onInputChange(field, event) {
        const {login} = this.props;
        if (
            event !== undefined &&
            event !== '' &&
            event !== null &&
            event.target.value !== undefined &&
            event.target.value !== '' &&
            event.target.value !== null
        ) {
            let string = event.target.value;

            if (string?.length >= 2) {
                let squad = {searchString: string};
                if (this.state.selectedGame) {
                    this.props.getSquad(squad, this.state.selectedGame, login.userDetails.data.accessToken);
                }
            }
        }
    }

    /**
     * validate inputs and call api on save
     */
    onSave() {
        const {
            pubgName,
            currentCredits,
            squadName,
            newSquad,
            fcRating,
            fullName,
            displayName,
            nationality,
            squadPosition,
            edit,
            playerId,
            selectedGame,
            squadList,
            image,
            s3Url,
        } = this.state;
        let squadname;
        let squadId;
        const {login} = this.props;

        const {matchesPlayed, tournaments} = this.state;
        const stats = this.state.stats;

        let statsRequest = {};
        for (let i in stats) {
            if (!isValidNumber(stats[i].value)) {
                this.setState({errorMessage: `Please Enter Valid ${i}`});
                return;
            }
            statsRequest[i] = stats[i].value;
        }

        if (
            pubgName === null ||
            pubgName === ' ' ||
            pubgName === undefined ||
            pubgName.length < 1 ||
            pubgName.length > 30
        ) {
            this.setState({errorMessage: 'Please Enter Valid Player Name'});
        } else if (selectedGame === '' || selectedGame === undefined || selectedGame === null) {
            this.setState({loading: false, errorMessage: 'Please select a valid Game!'});
            return false;
        } else if (
            currentCredits === null ||
            currentCredits === '' ||
            currentCredits === undefined ||
            !isValidNumber(currentCredits) ||
            currentCredits > 15
        ) {
            this.setState({errorMessage: 'Please Enter valid credits and must be in range of 1-15'});
        } else if (fcRating === null || fcRating === '' || fcRating === undefined || !isValidNumber(fcRating)) {
            this.setState({errorMessage: 'Please Enter valid F C Rating'});
        } else if (
            fullName === null ||
            fullName === '' ||
            fullName === undefined ||
            fullName.length < 1 ||
            fullName.length > 30
        ) {
            this.setState({errorMessage: 'Please Enter valid Full Name'});
        } else if (squadName === null || squadName === '' || squadName === undefined) {
            this.setState({errorMessage: 'Please select squad name'});
        } else if (
            !edit &&
            !squadName.title &&
            (squadName === null || squadName === '' || squadName === undefined || squadName < 3 || squadName > 30)
        ) {
            this.setState({errorMessage: 'Please enter valid squad name'});
        } else if (
            nationality === null ||
            nationality === '' ||
            nationality === undefined ||
            !isValidString(nationality)
        ) {
            this.setState({errorMessage: 'Please Enter valid Country Name'});
        } else if (squadPosition === null || squadPosition === undefined) {
            this.setState({errorMessage: 'Please select valid squad position'});
        } else if (matchesPlayed === null || !isNumber(matchesPlayed) || matchesPlayed === undefined) {
            this.setState({errorMessage: 'Please select valid matches played'});
        } else if (tournaments === null || tournaments === '' || !isNumber(tournaments) || tournaments === undefined) {
            this.setState({errorMessage: 'Please enter valid tournaments'});
        } else {
            if (newSquad) {
                squadname = squadName;
                squadId = undefined;
            } else {
                squadId = squadName.value;
                squadname = undefined;
            }
            let newPlayer = {
                fullName: fullName,
                pubgName: pubgName,
                game: selectedGame,
                nationality: nationality,
                fcRating: fcRating,
                currentCredits: currentCredits,
                matchesPlayed: matchesPlayed,
                tournaments: tournaments,
                playerId: playerId,
                squadName: squadname,
                squadId: squadId,
                positionInSquad: squadPosition,
                image: image,
                s3Url,
                displayName,
                ...statsRequest,
            };

            this.setState({loading: true});
            if (edit) {
                newPlayer.squadList = squadList;
                this.props.updatePlayers(newPlayer, login.userDetails.data.accessToken);
            } else {
                this.props.createPlayer(newPlayer, login.userDetails.data.accessToken);
            }
        }
    }

    componentWillMount() {
        const {login, history} = this.props;
        if (!login.userDetails.data) {
            history.push('/');
            this.props.logout();
        }
    }

    componentDidMount() {
        const {login, history, player, client} = this.props;
        if (!login.userDetails.data) {
            history.push('/');
            this.props.logout();
        } else {
            const {edit, pageSize, page} = this.state;
            let toGame = {page: 1, pageSize: 20, searchString: null, sortOrder: null, listType: 'Limited'};
            this.props.getTOGameList(toGame, login.userDetails.data.accessToken, client.selectedClient);

            if (edit) {
                this.props.getPlayerRequest(
                    player.editPlayer.id,
                    login.userDetails.data.accessToken,
                    client.selectedClient,
                );
            }
        }
    }

    componentDidUpdate(prevProps) {
        const {history, player, squad, toGame} = this.props;
        const {selectedGame} = this.state;

        if (!prevProps.player.addPlayerSuccess && player.addPlayerSuccess) {
            this.setState({loading: false});
            history.goBack();
        } else if (!prevProps.player.addPlayerFail && player.addPlayerFail) {
            this.setState({errorMessage: player.errorMessage, loading: false});
        }
        if (!prevProps.player.updatePlayerSuccess && player.updatePlayerSuccess) {
            history.goBack();
        } else if (!prevProps.player.updatePlayerFail && player.updatePlayerFail) {
            this.setState({errorMessage: player.errorMessage, loading: false});
        }
        if (toGame.TOGameListSuccess && !prevProps.toGame.TOGameListSuccess) {
            this.setState({
                gameOptions: toGame.TOGameList.data.data.map((item) => {
                    return {text: item.gameName, value: item.id};
                }),
                loading: false,
            });
        } else if (toGame.TOGameListFail && !prevProps.toGame.TOGameListFail) {
            this.setState({
                errorMessage: toGame.errorMessage,
                loading: false,
            });
        }
        if (player.deleteplayersuccess && !prevProps.player.deleteplayersuccess) {
            if (player.deletePlayer.msg === 'Player Deleted') {
                history.push('/players');
                this.setState({errorMessage: player.deletePlayer.msg, loading: false});
            } else {
                this.setState({errorMessage: player.deletePlayer.msg, loading: false});
            }
        } else if (player.deletePlayerFail && !prevProps.player.deletePlayerFail) {
            this.setState({errorMessage: player.errorMessage, loading: false});
        }

        if (squad.getSquadSuccess && !prevProps.squad.getSquadSuccess) {
            let squads = [
                {
                    title: 'Create New Squad',
                    value: 'newSquad',
                },
            ];
            squad.getSquad.data.map((squad) => {
                squads.push({title: squad.squadName, value: squad.id});
            });
            if (squad.getSquad.data.length > 0) {
                this.setState({
                    newSquad: false,
                    squadOptions: squads,
                });
            }
        } else if (squad.getSquadFail && !prevProps.squad.getSquadFail) {
            this.setState({errorMessage: squad.errorMessage});
        }

        if (player.getPlayerSuccess && !prevProps.player.getPlayerSuccess) {
            const {
                fullName,
                displayName,
                pubgName,
                nationality,
                fcRating,
                currentCredits,
                matchesPlayed,
                tournaments,
                squad,
                fantasyStats,
                squadList,
                id,
                positionInSquad,
                nextMatch,
                game,
                image,
                s3Url,
            } = player.getPlayer.data;

            const stats = player.getPlayer.data.stats;
            let playerStatsModel = player.getPlayer.data.game?.statsModel?.playerStats?.external;
            let playerStats = player.getPlayer.data.playerStats || {};
            let initialStats = {};
            if (playerStatsModel) {
                for (let i in playerStatsModel) {
                    let statDescription = playerStatsModel[i];
                    if (statDescription.statType === 'BASE') {
                        let item = {};
                        item['displayName'] = statDescription.displayName;
                        item['value'] = playerStats[statDescription.name]?.value
                            ? playerStats[statDescription.name].value
                            : statDescription.defaultValue;
                        initialStats[statDescription.name] = item;
                    }
                }
            } else {
                initialStats = stats;
            }

            this.setState({
                fullName: fullName,
                pubgName: pubgName,
                nationality: nationality,
                fcRating: fcRating,
                currentCredits: currentCredits,
                matchesPlayed: matchesPlayed,
                tournaments: tournaments,
                playerId: id,
                squadPosition: positionInSquad,
                selectedGame: game.id,
                squadName: {title: squad.squadName, value: squad.id},
                fantasyStats: fantasyStats,
                squadList: squadList,
                disabled: nextMatch !== '' ? true : false,
                stats: initialStats,
                image: image,
                s3Url,
                displayName,
            });
        } else if (player.getPlayerFail && !prevProps.player.getPlayerFail) {
            this.setState({errorMessage: player.errorMessage});
        } else if (selectedGame && toGame.TOGameListSuccess && this.state?.positionOptions?.length == 0) {
            const availableRoles = toGame?.TOGameList?.data?.data?.filter((item) => item.id === selectedGame)?.[0]
                ?.availableRoles;
            this.setState({
                positionOptions: availableRoles?.map((item) => ({value: item, text: item})),
            });
        }
    }

    displaySquads() {
        const {squadList, menuItems, disabled} = this.state;
        if (squadList.length > 0) {
            return this.state.squadList.map((squad, index) => {
                return (
                    <div className="card-grid-container">
                        <div className="grid-item  ">
                            <div className="margin-top-20">
                                <div className="basic-headers">SQUAD NAME</div>
                                <div className="basiic-data">{squad.name === '' ? '-' : squad.name}</div>
                            </div>
                        </div>
                        <div className="grid-item  ">
                            <div className="grid-item   margin-top-20">
                                <DropDown
                                    menuItems={menuItems}
                                    label="STATUS"
                                    value={squadList[index].status}
                                    // disabled={disabled}
                                    placeholder="select"
                                    onChange={this.handleInputChangeForMultipleSquads.bind(
                                        this,
                                        'squadList[index].status',
                                        index,
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                );
            });
        }
    }

    /**
     * map and display fantasy stat details
     */
    displayFantasyStats() {
        const {fantasyStats} = this.state;

        if (fantasyStats.length > 0) {
            return this.state.fantasyStats.map((fantasy) => {
                return (
                    <div className="card-grid-container">
                        <div className="grid-item  ">
                            <div className="margin-top-20">
                                <div className="basic-headers">Match ID</div>
                                <div className="basiic-data">{fantasy.id === '' ? '-' : fantasy.id}</div>
                            </div>
                            <div className="margin-top-20">
                                <div className="basic-headers">FC Rating</div>
                                <div className="basiic-data">
                                    {fantasy.matchFcRating === '' ? '-' : fantasy.matchFcRating}
                                </div>
                            </div>
                            <div className="margin-top-20">
                                <div className="basic-headers">Player Cost Credits</div>
                                <div className="basiic-data">
                                    {fantasy.matchPlayerCostCredits === '' ? '-' : fantasy.matchPlayerCostCredits}
                                </div>
                            </div>
                        </div>
                        <div className="grid-item ">
                            <div className="margin-top-20">
                                <div className="basic-headers">Match Date</div>
                                <div className="basiic-data">
                                    {fantasy.matchDate === '' ? '-' : timeFormat(fantasy.matchDate)}
                                </div>
                            </div>
                            <div className="margin-top-20">
                                <div className="basic-headers">Selected by</div>
                                <div className="basiic-data">
                                    {fantasy.matchSelectedBy === '' ? '-' : fantasy.matchSelectedBy}
                                </div>
                            </div>
                            <div className="margin-top-20">
                                <div className="basic-headers">Fantasy Points</div>
                                <div className="basiic-data">
                                    {fantasy.matchFantasyPoints === '' ? '-' : fantasy.matchFantasyPoints}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            });
        } else {
            return (
                <div className="margin-top-30 text-grey">
                    Fantasy stats will be available when player has played atleast 1 game
                </div>
            );
        }
    }

    /**
     * UI for tab A
     */
    renderTabA() {
        const {
            menuItems,
            status,
            squadName,
            disabled,
            pubgName,
            currentCredits,
            positionOptions,
            fcRating,
            fullName,
            displayName,
            nationality,
            squadPosition,
            newSquad,
            gameOptions,
            squadOptions,
            selectedGame,
            edit,
            squadList,
            image,
        } = this.state;
        const {playerWrite, editPlayerFcRating} = this.props?.login?.permissions || {};
        return (
            <div>
                <div className="card-grid-container nopadding">
                    {/* row 1 */}
                    <div className="grid-item  margin-top-20">
                        <DropDown
                            label="Game"
                            type="text"
                            menuItems={gameOptions}
                            name="selectedGame"
                            value={selectedGame}
                            disabled={edit}
                            onChange={this.handleInputChange.bind(this, 'selectedGame')}
                            placeholder="Select Game"
                        />
                    </div>
                    {selectedGame ? (
                        <div className="grid-item  margin-top-20">
                            <Input
                                label="Player Name"
                                type="text"
                                name="pubgName"
                                value={pubgName}
                                onChange={this.handleInputChange.bind(this, 'pubgName')}
                                placeholder="Enter Player Name"
                            />
                        </div>
                    ) : null}
                    {selectedGame ? (
                        <div className="grid-item   margin-top-20">
                            {newSquad ? (
                                <Input
                                    label="Squad Name"
                                    type="text"
                                    name="squadName"
                                    value={squadName}
                                    placeholder="Enter Squad Name"
                                    disabled={disabled}
                                    onChange={this.handleInputChange.bind(this, 'squadName')}
                                />
                            ) : (
                                <AutoCompleteField
                                    label="Squad Name"
                                    onChange={this.handleInputChange.bind(this, 'squadName')}
                                    options={squadOptions}
                                    value={squadName}
                                    disabled={disabled}
                                    inputChange={this.onInputChange.bind(this, 'searchSquad')}
                                />
                            )}
                        </div>
                    ) : null}

                    <div className="grid-item    margin-top-20">
                        <Input
                            label="Current Credits"
                            type="text"
                            name="currentCredits"
                            value={currentCredits}
                            onChange={this.handleInputChange.bind(this, 'currentCredits')}
                            placeholder="Enter Current Credits"
                            disabled={disabled}
                        />
                    </div>
                    {!!editPlayerFcRating && (
                        <div className="grid-item   margin-top-20  ">
                            <Input
                                label="FC Rating"
                                type="text"
                                name="fcRating"
                                value={fcRating}
                                onChange={this.handleInputChange.bind(this, 'fcRating')}
                                placeholder="Enter FC Rating"
                                disabled={disabled}
                            />
                        </div>
                    )}
                    <div className="grid-item   margin-top-20">
                        <Input
                            label="Display Name"
                            type="text"
                            name="displayName"
                            value={displayName || ''}
                            onChange={this.handleInputChange.bind(this, 'displayName')}
                            placeholder="Enter Display Name"
                        />
                    </div>
                    <div className="grid-item   margin-top-20">
                        <Input
                            label="Real Name"
                            type="text"
                            name="fullName"
                            value={fullName}
                            onChange={this.handleInputChange.bind(this, 'fullName')}
                            placeholder="Enter Full Name"
                            disabled={disabled}
                        />
                    </div>
                    <div className="grid-item   margin-top-20">
                        <Input
                            label="Nationality"
                            type="text"
                            name="nationality"
                            value={nationality}
                            onChange={this.handleInputChange.bind(this, 'nationality')}
                            placeholder="Enter Nationailty"
                            disabled={disabled}
                        />
                    </div>
                    <div className="grid-item    margin-top-20">
                        <DropDown
                            menuItems={positionOptions}
                            label="Position in Squad"
                            value={squadPosition}
                            placeholder="Enter squad position"
                            disabled={disabled}
                            id={'squadPosition'}
                            onChange={this.handleInputChange.bind(this, 'squadPosition')}
                        />
                    </div>
                    <div className="grid-item    margin-top-20">{this.state.edit ? this.displaySquads() : null}</div>
                    <div className="grid-item  margin-top-20">
                        <Input
                            label="Player Image"
                            type="text"
                            name="image"
                            value={image}
                            onChange={this.handleInputChange.bind(this, 'image')}
                            placeholder="Enter Player Image if Any"
                            disabled={true}
                        />
                    </div>

                    {image !== undefined && image !== null && image !== '' ? (
                        <div className="grid-item  margin-top-20">
                            <img src={image} style={{height: 150}} />
                        </div>
                    ) : (
                        <div className="grid-item  margin-top-20"></div>
                    )}
                    <div className="grid-item  margin-top-20">
                        {!!playerWrite && (
                            <ImageInput
                                label="Upload Player Image"
                                type="file"
                                name="file"
                                onChange={this.handleInputChange.bind(this, 'uploadImage')}
                            />
                        )}
                    </div>
                    <div className="grid-item margin-top-20"></div>
                    <div className="grid-item margin-top-20">
                        {!!playerWrite && (
                            <Button buttonText="Upload Image Only" onClick={this.uploadImageOnly.bind(this)} />
                        )}
                    </div>
                </div>
            </div>
        );
    }

    displayDynammicStats(stats) {
        let out = [],
            count = 0;
        for (let i in stats) {
            if (count != 0 && count % 2 == 0) {
                out.push({
                    /* ROW*/
                });
            }
            out.push(
                <div className="grid-item   margin-top-20">
                    <Input
                        label={stats[i].displayName}
                        type="text"
                        name={i}
                        value={stats[i].value}
                        placeholder={stats[i].displayName}
                        onChange={(value) =>
                            this.handleChangeState({
                                stats: {...stats, [i]: {...stats[i], value}},
                            })
                        }
                    />
                </div>,
            );
        }
        return out;
    }

    /**
     * UI for tab B
     */
    renderTabB() {
        const {matchesPlayed, tournaments} = this.state;
        const stats = this.state.stats;

        return (
            <div>
                <div className="card-grid-container nopadding">
                    <div className="grid-item margin-top-20">
                        <Input
                            label="Matches Played"
                            type="text"
                            name="matchesPlayed"
                            value={matchesPlayed}
                            onChange={this.handleInputChange.bind(this, 'matchesPlayed')}
                            placeholder="Enter Matches Played"
                        />
                    </div>

                    <div className="grid-item margin-top-20">
                        <Input
                            label="Tournaments"
                            type="text"
                            name="tournaments"
                            value={tournaments}
                            onChange={this.handleInputChange.bind(this, 'tournaments')}
                            placeholder="Enter Tournaments Count"
                        />
                    </div>

                    {/* ROW*/}

                    {this.displayDynammicStats(stats)}
                </div>
            </div>
        );
    }

    /**
     * UI for tab C
     */
    renderTabC() {
        return (
            <div className="card-fullgrid-container nopadding">
                {this.state.edit ? this.displayFantasyStats() : null}
            </div>
        );
    }

    /**
     * delete current player
     */
    onDeletePlayer() {
        const {login, player} = this.props;

        let data = {
            playerId: player.editPlayer.id,
            game: this.state.selectedGame,
        };
        this.props.deletePlayers(data, login.userDetails.data.accessToken);
        this.setState({open: false, loading: true});
    }

    uploadImageOnly() {
        let {edit, image, playerId, s3Url} = this.state;
        const {login} = this.props;
        let editPlayer = {image: image, s3Url, playerId: playerId, uploadImageOnly: true};
        if (edit) {
            this.props.updatePlayers(editPlayer, login.userDetails.data.accessToken);
        }
    }

    render() {
        const {errorMessage, edit, open, loading} = this.state;
        const {history, player, login} = this.props;
        let title = edit ? 'Edit Player Data | ' + player.editPlayer.pubgName : 'Add Player Data';

        const {id, updatedAt, nextMatch, lastMatch} = player.editPlayer;

        const {playerWrite} = login?.permissions || {};

        return (
            <DetailLayout
                location="Players"
                history={history}
                centerTitle={title}
                onSave={playerWrite ? this.onSave.bind(this) : undefined}
                editPage={edit}
                onDeleteClick={playerWrite ? () => this.setState({open: true}) : undefined}
                id={edit ? id : ''}
            >
                {!!loading && <LoadingModal open={loading} />}
                {!!errorMessage && (
                    <Snackbar
                        open={!!errorMessage}
                        message={errorMessage}
                        onClose={() => this.setState({errorMessage: ''})}
                    />
                )}

                <Modal open={open}>
                    <div className="container modal-top-margin">
                        <Card>
                            <div className="disp-flex">
                                <div className="modal-left-header">Delete Player</div>
                                <div onClick={() => this.setState({open: false})}>
                                    <img src={Close} alt="" className="cursor" />
                                </div>
                            </div>
                            <div className="align-center equalcontainer-grids">
                                <div />
                                <div>
                                    <div className=" disp-flex align-center margin-top-30">
                                        <div className="margin-right-16">
                                            <Button
                                                buttonText="Delete Player"
                                                onClick={this.onDeletePlayer.bind(this)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                </Modal>

                <div className="detail-container margin-top-20">
                    <div className="grid-item">
                        <div className="text-grey">
                            {edit ? 'Edit Players before populating Squads' : 'Add Players before populating Squads'}
                        </div>
                    </div>
                    <div className="grid-item">
                        <Card>
                            <div>
                                <div className="card-single-grid-container">
                                    <Tabs
                                        tab1Label="Summary"
                                        tab2Label="Game Stats"
                                        tab3Label="Fantasy Stats"
                                        panelA={this.renderTabA.bind(this)}
                                        panelB={this.renderTabB.bind(this)}
                                        panelC={this.renderTabC.bind(this)}
                                        disableTab3={!edit}
                                    />
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className="grid-item">
                        <div className="equalcontainer-grids">
                            {edit ? (
                                <React.Fragment>
                                    <div>
                                        <div className="basic-headers">Last Updated</div>
                                        <div className="basiic-data date-space">
                                            {updatedAt !== '' ? timeFormat(updatedAt) : '-'}
                                        </div>
                                    </div>
                                    <div>
                                        <div className="basic-headers">Last Match</div>
                                        <div className="basiic-data date-space">
                                            {lastMatch !== '' ? timeFormat(lastMatch) : '-'}
                                        </div>
                                    </div>
                                    <div>
                                        <div className="basic-headers">Next Match</div>
                                        <div className="basiic-data date-space">
                                            {nextMatch !== '' ? timeFormat(nextMatch) : '-'}
                                        </div>
                                    </div>
                                </React.Fragment>
                            ) : null}
                        </div>
                    </div>
                </div>
            </DetailLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        squad: state.squad,
        player: state.player,
        toGame: state.toGame,
        client: state.client,
    };
};

export default connect(mapStateToProps, {
    createPlayer,
    updatePlayers,
    getSquad,
    logout,
    getPlayerRequest,
    deletePlayers,
    getSquadList,
    getTOGameList,
})(AddPlayer);
