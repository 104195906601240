import apis from '../../../apiCalls/apis';
import {
    DELETE_COMMON_MATCH_FAILURE,
    DELETE_COMMON_MATCH_REQUEST,
    DELETE_COMMON_MATCH_SUCCESS,
    GET_COMMON_MATCHES_LIST_FAILURE,
    GET_COMMON_MATCHES_LIST_REQUEST,
    GET_COMMON_MATCHES_LIST_SUCCESS,
    GET_COMMON_MATCH_FAILURE,
    GET_COMMON_MATCH_REQUEST,
    GET_COMMON_MATCH_SUCCESS,
    POST_COMMON_MATCH_FAILURE,
    POST_COMMON_MATCH_REQUEST,
    POST_COMMON_MATCH_SUCCESS,
    PUT_COMMON_MATCH_FAILURE,
    PUT_COMMON_MATCH_REQUEST,
    PUT_COMMON_MATCH_SUCCESS,
} from './action-constants';
import {SOMETHING_WENT_WRONG, UNKNOWN_ERROR_OCCURRED} from '../../../Utilities/constants/message-constants';

/**
 * fetch common matches list api request
 * @param {string} data page details
 * @param {string} token user token
 * @param {string} clientId selected client id
 */
export const fetchCommonMatchesList = (data, token, clientId) => {
    return async (dispatch) => {
        dispatch({type: GET_COMMON_MATCHES_LIST_REQUEST});
        try {
            const response = await apis.fetchCommonMatchesList(data, token, clientId);
            // console.log("RESPONSE", response);
            if (response.status === 200) {
                dispatch({
                    type: GET_COMMON_MATCHES_LIST_SUCCESS,
                    payload: response?.data?.data,
                });
            } else {
                dispatch({
                    type: GET_COMMON_MATCHES_LIST_FAILURE,
                    payload: response?.data?.errorData?.errorReason || SOMETHING_WENT_WRONG,
                });
            }
        } catch (e) {
            if (e.response) {
                // console.log("ERROR_RESPONSE", e.response);
                dispatch({
                    type: GET_COMMON_MATCHES_LIST_FAILURE,
                    payload: e?.response?.data || SOMETHING_WENT_WRONG,
                });
            } else {
                // console.log("ERROR", e);
                dispatch({
                    type: GET_COMMON_MATCHES_LIST_FAILURE,
                    payload: e?.message || UNKNOWN_ERROR_OCCURRED,
                });
            }
        }
    };
};

/**
 * fetch common match api request
 * @param {string} data common match id
 * @param {string} token user token
 * @param {string} clientId selected client id
 */
export const fetchCommonMatch = (data, token, clientId) => {
    return async (dispatch) => {
        dispatch({type: GET_COMMON_MATCH_REQUEST});
        try {
            const response = await apis.fetchCommonMatch(data, token, clientId);
            console.log('RESPONSE', response);
            if (response.status === 200) {
                dispatch({
                    type: GET_COMMON_MATCH_SUCCESS,
                    payload: response?.data?.data,
                });
            } else {
                dispatch({
                    type: GET_COMMON_MATCH_FAILURE,
                    payload: response?.data?.errorData?.errorReason || SOMETHING_WENT_WRONG,
                });
            }
        } catch (e) {
            if (e.response) {
                console.log('ERROR_RESPONSE', e.response);
                dispatch({
                    type: GET_COMMON_MATCH_FAILURE,
                    payload: e?.response?.data?.msg || SOMETHING_WENT_WRONG,
                });
            } else {
                console.log('ERROR', e);
                dispatch({
                    type: GET_COMMON_MATCH_FAILURE,
                    payload: e?.message || UNKNOWN_ERROR_OCCURRED,
                });
            }
        }
    };
};

/**
 * add common match api request
 * @param {*} data match details
 * @param {string} token user token
 * @param {string} clientId selected client id
 */
export const addCommonMatch = (data, token, clientId) => {
    return async (dispatch) => {
        dispatch({type: POST_COMMON_MATCH_REQUEST});
        try {
            const response = await apis.addCommonMatch(data, token, clientId);
            console.log('RESPONSE', response);
            if (response.status === 200) {
                dispatch({
                    type: POST_COMMON_MATCH_SUCCESS,
                    payload: response?.data?.data,
                });
            } else {
                dispatch({
                    type: POST_COMMON_MATCH_FAILURE,
                    payload: response?.data?.errorData?.errorReason || SOMETHING_WENT_WRONG,
                });
            }
        } catch (e) {
            if (e.response) {
                console.log('ERROR_RESPONSE', e.response);
                dispatch({
                    type: POST_COMMON_MATCH_FAILURE,
                    payload: e?.response?.data?.msg || SOMETHING_WENT_WRONG,
                });
            } else {
                console.log('ERROR', e);
                dispatch({
                    type: POST_COMMON_MATCH_FAILURE,
                    payload: e?.message || UNKNOWN_ERROR_OCCURRED,
                });
            }
        }
    };
};

/**
 * edit common match api request
 * @param {*} data match details
 * @param {string} token user token
 * @param {string} clientId selected client id
 */
export const editCommonMatch = (data, token, clientId) => {
    return async (dispatch) => {
        dispatch({type: PUT_COMMON_MATCH_REQUEST});
        try {
            const response = await apis.editCommonMatch(data, token, clientId);
            // console.log("RESPONSE", response);
            if (response.status === 200) {
                dispatch({
                    type: PUT_COMMON_MATCH_SUCCESS,
                    payload: response?.data?.data,
                });
            } else {
                dispatch({
                    type: PUT_COMMON_MATCH_FAILURE,
                    payload: response?.data?.errorData?.errorReason || SOMETHING_WENT_WRONG,
                });
            }
        } catch (e) {
            if (e.response) {
                // console.log("ERROR_RESPONSE", e.response);
                dispatch({
                    type: PUT_COMMON_MATCH_FAILURE,
                    payload: e?.response?.data?.msg || SOMETHING_WENT_WRONG,
                });
            } else {
                // console.log("ERROR", e);
                dispatch({
                    type: PUT_COMMON_MATCH_FAILURE,
                    payload: e?.message || UNKNOWN_ERROR_OCCURRED,
                });
            }
        }
    };
};

/**
 * remove common match api request
 * @param {*} data match details
 * @param {string} token user token
 * @param {string} clientId selected client id
 */
export const removeCommonMatch = (data, token, clientId) => {
    return async (dispatch) => {
        dispatch({type: DELETE_COMMON_MATCH_REQUEST});
        try {
            const response = await apis.deleteCommonMatch(data, token, clientId);
            console.log('RESPONSE', response);
            if (response.status === 200) {
                dispatch({
                    type: DELETE_COMMON_MATCH_SUCCESS,
                    payload: response?.data?.data,
                });
            } else {
                dispatch({
                    type: DELETE_COMMON_MATCH_FAILURE,
                    payload: response?.data?.errorData?.errorReason || SOMETHING_WENT_WRONG,
                });
            }
        } catch (e) {
            if (e.response) {
                console.log('ERROR_RESPONSE', e.response.data);
                dispatch({
                    type: DELETE_COMMON_MATCH_FAILURE,
                    payload: e?.response?.data || SOMETHING_WENT_WRONG,
                });
            } else {
                console.log('ERROR', e);
                dispatch({
                    type: DELETE_COMMON_MATCH_FAILURE,
                    payload: e?.message || UNKNOWN_ERROR_OCCURRED,
                });
            }
        }
    };
};
