export const GET_COMMON_MATCHES_LIST_REQUEST = 'GET_COMMON_MATCHES_LIST_REQUEST';
export const GET_COMMON_MATCHES_LIST_SUCCESS = 'GET_COMMON_MATCHES_LIST_SUCCESS';
export const GET_COMMON_MATCHES_LIST_FAILURE = 'GET_COMMON_MATCHES_LIST_FAILURE';

export const GET_COMMON_MATCH_REQUEST = 'GET_COMMON_MATCH_REQUEST';
export const GET_COMMON_MATCH_SUCCESS = 'GET_COMMON_MATCH_SUCCESS';
export const GET_COMMON_MATCH_FAILURE = 'GET_COMMON_MATCH_FAILURE';

export const POST_COMMON_MATCH_REQUEST = 'POST_COMMON_MATCH_REQUEST';
export const POST_COMMON_MATCH_SUCCESS = 'POST_COMMON_MATCH_SUCCESS';
export const POST_COMMON_MATCH_FAILURE = 'POST_COMMON_MATCH_FAILURE';

export const PUT_COMMON_MATCH_REQUEST = 'PUT_COMMON_MATCH_REQUEST';
export const PUT_COMMON_MATCH_SUCCESS = 'PUT_COMMON_MATCH_SUCCESS';
export const PUT_COMMON_MATCH_FAILURE = 'PUT_COMMON_MATCH_FAILURE';

export const DELETE_COMMON_MATCH_REQUEST = 'DELETE_COMMON_MATCH_REQUEST';
export const DELETE_COMMON_MATCH_SUCCESS = 'DELETE_COMMON_MATCH_SUCCESS';
export const DELETE_COMMON_MATCH_FAILURE = 'DELETE_COMMON_MATCH_FAILURE';
