import {
    TOMATCH_LIST,
    TOMATCH_LIST_SUCCESS,
    TOMATCH_LIST_FAIL,
    GET_TOMATCH_PARTICIPATION,
    GET_TOMATCH_PARTICIPATION_SUCCESS,
    GET_TOMATCH_PARTICIPATION_FAIL,
    ARCHIVE_TOMATCH,
    ARCHIVE_TOMATCH_SUCCESS,
    ARCHIVE_TOMATCH_FAIL,
    GET_TOMATCH,
    GET_TOMATCH_SUCCESS,
    GET_TOMATCH_FAIL,
    EDIT_TOMATCH,
} from './Constants';

const initialState = {
    TOMatchListRequest: false,
    TOMatchListSuccess: false,
    TOMatchListFail: false,
    TOMatchList: [],
    errorMessage: '',
    addTOMatchRequest: false,
    addTOMatchSuccess: false,
    addTOMatchFail: false,
    addTOMatch: [],
    modifyTOMatchRequest: false,
    modifyTOMatchSuccess: false,
    modifyTOMatchFail: false,
    modifyTOMatch: [],
    getTOMatchParticipation: false,
    getTOMatchParticipationSuccess: false,
    getTOMatchParticipationFail: false,
    TOMatchParticipation: {},
    archiveTOMatchRequest: false,
    archiveTOMatchSuccess: false,
    archiveTOMatchFail: false,
    archiveTOMatch: [],
    getTOMatchRequest: false,
    getTOMatchSuccess: false,
    getTOMatchFail: false,
    getTOMatch: [],
    editTOMatch: [],
};

const toCompletedMatchReducer = (state = initialState, action) => {
    switch (action.type) {
        case TOMATCH_LIST:
            return {
                ...state,
                TOMatchListRequest: true,
                TOMatchListSuccess: false,
                TOMatchListFail: false,
                TOMatchList: [],
                errorMessage: '',
            };

        case TOMATCH_LIST_SUCCESS:
            // console.log('Success case is being triggered');
            return {
                ...state,
                TOMatchListRequest: false,
                TOMatchListSuccess: true,
                TOMatchListFail: false,
                TOMatchList: action.payload.data,
                errorMessage: '',
            };

        case TOMATCH_LIST_FAIL:
            return {
                ...state,
                TOMatchListRequest: false,
                TOMatchListSuccess: false,
                TOMatchListFail: true,
                errorMessage: action.payload,
            };

        // GET TOMATCH PARTICIPATION
        case GET_TOMATCH_PARTICIPATION:
            // console.log('Client side log for fetching match participation');
            return {
                ...state,
                getTOMatchParticipation: true,
                getTOMatchParticipationSuccess: false,
                getTOMatchParticipationFail: false,
                TOMatchParticipation: {},
                errorMessage: '',
            };
        case GET_TOMATCH_PARTICIPATION_SUCCESS:
            // console.log('Match Participation fetched');
            // console.log(action.payload.data.data);
            return {
                ...state,
                getTOMatchParticipation: false,
                getTOMatchParticipationSuccess: true,
                getTOMatchParticipationFail: false,
                TOMatchParticipation: action.payload.data.data,
                errorMessage: '',
            };
        case GET_TOMATCH_PARTICIPATION_FAIL:
            return {
                ...state,
                getTOMatchParticipation: false,
                getTOMatchParticipationSuccess: false,
                getTOMatchParticipationFail: true,
                TOMatchParticipation: {},
                errorMessage: '',
            };

        // ARCHIVE TOMATCH
        case ARCHIVE_TOMATCH:
            return {
                ...state,
                archiveTOMatchRequest: true,
                archiveTOMatchSuccess: false,
                archiveTOMatchFail: false,
                archiveTOMatch: [],
                errorMessage: '',
            };

        case ARCHIVE_TOMATCH_SUCCESS:
            return {
                ...state,
                archiveTOMatchRequest: false,
                archiveTOMatchSuccess: true,
                archiveTOMatchFail: false,
                archiveTOMatch: action.payload.data,
                errorMessage: '',
            };

        case ARCHIVE_TOMATCH_FAIL:
            return {
                ...state,
                archiveTOMatchRequest: false,
                archiveTOMatchSuccess: false,
                archiveTOMatchFail: true,
                errorMessage: action.payload,
            };

        // get single TOMatch
        case GET_TOMATCH:
            return {
                ...state,
                getTOMatchRequest: true,
                getTOMatchSuccess: false,
                getTOMatchFail: false,
                getTOMatch: [],
                errorMessage: '',
            };

        case GET_TOMATCH_SUCCESS:
            return {
                ...state,
                getTOMatchRequest: false,
                getTOMatchSuccess: true,
                getTOMatchFail: false,
                getTOMatch: action.payload.data,
                errorMessage: '',
            };

        case GET_TOMATCH_FAIL:
            return {
                ...state,
                getTOMatchRequest: false,
                getTOMatchSuccess: false,
                getTOMatchFail: true,
                errorMessage: action.payload,
            };
        // EDIT TOMATCH
        case EDIT_TOMATCH:
            return {
                ...state,
                editTOCompletedMatch: action.payload,
            };

        default:
            return state;
    }
};

export default toCompletedMatchReducer;
