import {
    TOMATCHMODE_LIST,
    TOMATCHMODE_LIST_SUCCESS,
    TOMATCHMODE_LIST_FAIL,
    ADD_TOMATCHMODE,
    ADD_TOMATCHMODE_SUCCESS,
    ADD_TOMATCHMODE_FAIL,
    UPDATE_TOMATCHMODE,
    UPDATE_TOMATCHMODE_SUCCESS,
    UPDATE_TOMATCHMODE_FAIL,
    DELETE_TOMATCHMODE,
    DELETE_TOMATCHMODE_SUCCESS,
    DELETE_TOMATCHMODE_FAIL,
    GET_TOMATCHMODE,
    GET_TOMATCHMODE_SUCCESS,
    GET_TOMATCHMODE_FAIL,
    EDIT_TOMATCHMODE,
} from './Constants';

const initialState = {
    TOMatchModeListRequest: false,
    TOMatchModeListSuccess: false,
    TOMatchModeListFail: false,
    TOMatchModeList: [],
    errorMessage: '',
    addTOMatchModeRequest: false,
    addTOMatchModeSuccess: false,
    addTOMatchModeFail: false,
    addTOMatchMode: [],
    updateTOMatchModeRequest: false,
    updateTOMatchModeSuccess: false,
    updateTOMatchModeFail: false,
    updateTOMatchMode: [],
    deleteTOMatchModeRequest: false,
    deleteTOMatchModeSuccess: false,
    deleteTOMatchModeFail: false,
    deleteTOMatchMode: [],
    getTOMatchModeRequest: false,
    getTOMatchModeSuccess: false,
    getTOMatchModeFail: false,
    getTOMatchMode: [],
    editTOMatchMode: [],
};

const toMatchModeReducer = (state = initialState, action) => {
    switch (action.type) {
        case TOMATCHMODE_LIST:
            return {
                ...state,
                TOMatchModeListRequest: true,
                TOMatchModeListSuccess: false,
                TOMatchModeListFail: false,
                TOMatchModeList: [],
                errorMessage: '',
            };

        case TOMATCHMODE_LIST_SUCCESS:
            return {
                ...state,
                TOMatchModeListRequest: false,
                TOMatchModeListSuccess: true,
                TOMatchModeListFail: false,
                TOMatchModeList: action.payload.data,
                errorMessage: '',
            };

        case TOMATCHMODE_LIST_FAIL:
            return {
                ...state,
                TOMatchModeListRequest: false,
                TOMatchModeListSuccess: false,
                TOMatchModeListFail: true,
                errorMessage: action.payload,
            };
        // ADD match
        case ADD_TOMATCHMODE:
            return {
                ...state,
                addTOMatchModeRequest: false,
                addTOMatchModeSuccess: false,
                addTOMatchModeFail: false,
                addTOMatchMode: [],
                errorMessage: '',
            };

        case ADD_TOMATCHMODE_SUCCESS:
            return {
                ...state,
                addTOMatchModeRequest: false,
                addTOMatchModeSuccess: true,
                addTOMatchModeFail: false,
                addTOMatchMode: action.payload.data,
                errorMessage: '',
            };

        case ADD_TOMATCHMODE_FAIL:
            return {
                ...state,
                addTOMatchModeRequest: false,
                addTOMatchModeSuccess: false,
                addTOMatchModeFail: true,
                errorMessage: action.payload,
            };
        //update TOMatchMode
        case UPDATE_TOMATCHMODE:
            return {
                ...state,
                updateTOMatchModeRequest: true,
                updateTOMatchModeSuccess: false,
                updateTOMatchModeFail: false,
                updateTOMatchMode: [],
                errorMessage: '',
            };

        case UPDATE_TOMATCHMODE_SUCCESS:
            return {
                ...state,
                updateTOMatchModeRequest: false,
                updateTOMatchModeSuccess: true,
                updateTOMatchModeFail: false,
                updateTOMatchMode: action.payload.data,
                errorMessage: '',
            };

        case UPDATE_TOMATCHMODE_FAIL:
            return {
                ...state,
                updateTOMatchModeRequest: false,
                updateTOMatchModeSuccess: false,
                updateTOMatchModeFail: true,
                errorMessage: action.payload,
            };

        // DELETE TOMATCHMODE
        case DELETE_TOMATCHMODE:
            return {
                ...state,
                deleteTOMatchModeRequest: true,
                deleteTOMatchModeSuccess: false,
                deleteTOMatchModeFail: false,
                deleteTOMatchMode: [],
                errorMessage: '',
            };

        case DELETE_TOMATCHMODE_SUCCESS:
            return {
                ...state,
                deleteTOMatchModeRequest: false,
                deleteTOMatchModeSuccess: true,
                deleteTOMatchModeFail: false,
                deleteTOMatchMode: action.payload.data,
                errorMessage: '',
            };

        case DELETE_TOMATCHMODE_FAIL:
            return {
                ...state,
                deleteTOMatchModeRequest: false,
                deleteTOMatchModeSuccess: false,
                deleteTOMatchModeFail: true,
                errorMessage: action.payload,
            };

        // get single TOMatchMode
        case GET_TOMATCHMODE:
            return {
                ...state,
                getTOMatchModeRequest: true,
                getTOMatchModeSuccess: false,
                getTOMatchModeFail: false,
                getTOMatchMode: [],
                errorMessage: '',
            };

        case GET_TOMATCHMODE_SUCCESS:
            return {
                ...state,
                getTOMatchModeRequest: false,
                getTOMatchModeSuccess: true,
                getTOMatchModeFail: false,
                getTOMatchMode: action.payload.data,
                errorMessage: '',
            };

        case GET_TOMATCHMODE_FAIL:
            return {
                ...state,
                getTOMatchModeRequest: false,
                getTOMatchModeSuccess: false,
                getTOMatchModeFail: true,
                errorMessage: action.payload,
            };
        // EDIT TOMATCHMODE
        case EDIT_TOMATCHMODE:
            return {
                ...state,
                editTOMatchMode: action.payload,
            };

        default:
            return state;
    }
};

export default toMatchModeReducer;
