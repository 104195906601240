import React from 'react';
import '../../../Utilities/style.css';
import '../Login/login.css';
import {connect} from 'react-redux';
import xlsx from 'xlsx';
import {saveAs} from 'file-saver';
import axios from 'axios';
import DetailLayout from '../../Layouts/DetailLayout/DetailLayout';
import Modal from '../../../Components/Modal/Modal';
import {TOURL} from '../../../Utilities/Constants';
import SmallTable from '../../../Components/SmallTable/SmallTable';
import Tabs from '../../../Components/Tabs/Tabs';
import Card from '../../../Components/Card/Card';
import Close from '../../../Images/close_yellow.png';
import Input from '../../../Components/Input/Input';
import FileInput from '../../../Components/FileInput/FileInput';
import DropDown from '../../../Components/Dropdown/Dropdown';
import {modifyTOMatch, cancelTOMatch, getTOMatchPartipation, fetchS3FileFromServer} from './AxioCalls';
import {getTOGameList} from '../TOGame/AxioCalls';
import {getTOMatchTypeList} from '../TOMatchType/AxioCalls';
import {getTOMatchModeList} from '../TOMatchMode/AxioCalls';
import {getTOMatchAttributeList} from '../TOMatchAttribute/AxioCalls';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Snackbar from '../../../Components/Snackbar/Snackbar';
import {logout} from '../Login/LoginAction';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';
import {MuiPickersUtilsProvider, DateTimePicker} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import Button from '../../../Components/Button/Button';
import './Teams.css';
import UpdateMatchResults from './UpdateMatchResults';
import MatchDetailsV2 from './MatchDetailsV2';

class EditTOPublishedMatch extends React.Component {
    constructor(props) {
        super(props);
        let location = this.props.history.location.pathname.includes('edit');
        let organizer = this.props.login.userDetails.data.role === 'ORGANIZER';
        this.state = {
            name: null,
            startTime: null,
            participation: [],
            filledSlots: null,
            joinedSlots: null,
            addTeamsFile: '',
            removeTeamsFile: '',
            addTeamOldMatchId: '',
            createAndAddTeamsFile: '',
            roomCredentials: {cred1Name: '', cred1Val: '', cred2Name: '', cred2Val: ''},
            liveStream: null,
            resultsFile: null,
            resultsBook: null,
            uploadFile: null,
            resultColumns: null,
            resultData: null,
            entryFeePerSlot: null,
            totalSlots: null,
            minFillSlots: 0,
            winPoolType: null,
            winPoolTypeOptions: [
                {
                    text: 'Fixed',
                    value: 'Fixed',
                },
                {
                    text: 'Percentage',
                    value: 'Percentage',
                },
            ],
            matchAccess: 'OPEN_TO_ALL',
            matchAccessOptions: [
                {
                    text: 'OPEN_TO_ALL',
                    value: 'OPEN_TO_ALL',
                },
                {
                    text: 'PRIVATE',
                    value: 'PRIVATE',
                },
                {
                    text: 'LINKONLY',
                    value: 'LINKONLY',
                },
            ],
            winFixedPoolSize: 0,
            winPercentPoolSize: 0,
            matchTypeOptions: [],
            matchType: null,
            entryFeeTypeOptions: [
                {
                    text: 'Cash Only',
                    value: 'Cash Only',
                },
                {
                    text: 'Free',
                    value: 'Free',
                },
                {
                    text: 'Cash+Coin',
                    value: 'Cash+Coin',
                },
                {
                    text: 'FcCoin',
                    value: 'FcCoin',
                },
            ],
            entryFeeType: null,
            fcCoinDiscountPercent: 0,
            minParticipationRequiredOptions: [
                {
                    text: 'True',
                    value: true,
                },
                {
                    text: 'False',
                    value: false,
                },
            ],
            minParticipationRequired: false,
            allowIncompleteTeams: true,
            winDenomination: null,
            winDenominationOptions: [
                {text: 'Cash', value: 'cash'},
                {text: 'Coin', value: 'fcCoin'},
                {text: 'Cash Balance', value: 'cashBalance'},
            ],
            winType: null,
            winTypeOptions: [
                {
                    text: 'Fixed',
                    value: 'Fixed',
                },
                {
                    text: 'Distribution',
                    value: 'Distribution',
                },
            ],
            numberOfBigWinTeams: 0,
            winSlots: {},
            winningDistribution: {},
            handlingCharge: null,
            winParameter: null,
            winParameterValue: 0,
            attribute1Options: [],
            attribute1: null,
            attribute1Val: null,
            attribute2Options: [],
            attribute2: null,
            attribute2Val: null,
            matchMode: null,
            matchModeOptions: [],
            matchRules: null,
            status: 'UPCOMING',
            gameOptions: [],
            game: null,
            loading: false,
            open: false,
            apiError: '',
            edit: location,
            organizer: organizer,
            snackbarOpen: false,
            openRemoveTeamMemberModal: false,
            playerToRemove: '',
            teamIdToRemovePlayerFrom: '',
            rankForSorting: null,
            organizerId: null,
            targetUsersFilter_loggedIn: false,
            targetUsersFilter_minJoinDate: new Date().getTime(),
            targetUsersByJoinDate: false,
            targetUsersByLoginStatus: false,
            booleanOptions: [
                {text: 'True', value: true},
                {text: 'False', value: false},
            ],
            targetUsersFilter_loggedInOptions: [
                {text: 'Logged In', value: true},
                {text: 'Logged Out', value: false},
            ],
        };
    }

    handleInputChange(field, value) {
        const {login} = this.props;
        this.setState({
            [field]: value,
            snackbarOpen: false,
            errorMessage: '',
        });
        if (field.includes('cred')) {
            let temp_cred = {...this.state.roomCredentials};
            temp_cred[field] = value;
            this.setState({
                roomCredentials: temp_cred,
            });
        }
        if (field === 'uploadFile') {
            console.log('File upload function is triggered');
            this.setState({loading: true});
            const data = new FormData();
            data.append('resultsFile', value);
            // axios.post(`${TOURL}/match/uploadResults`,data)
            axios({
                method: 'post',
                headers: {
                    Authorization: 'Bearer ' + login.userDetails.data.accessToken,
                },
                url: `${TOURL}/match/uploadResults`,
                data: data,
            })
                .then((res) => {
                    this.setState({resultsFile: res.data.msg});
                    if (res.data.msg !== null && res.data.msg !== undefined && res.data.msg !== '') {
                        this.computeResults(res.data.msg);
                    }
                })
                .catch((err) => {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    this.setState({loading: false, snackbarOpen: true, errorMessage: errorMsg});
                });
        }
        if (field === 'matchAccess') {
            this.setState({matchAccess: value});
        }
    }

    uploadAndCallAddTeamsToMatch() {
        try {
            let file = this.state.addTeamsFile;
            let oldMatchId = this.state.addTeamOldMatchId;
            let newMatchId = this.props.toPublishedMatch.editTOPublishedMatch.id;

            if (!file || !oldMatchId || !newMatchId) {
                let msg = 'File and oldMatchId are required!';
                console.log(msg);
                this.setState({
                    snackbarOpen: true,
                    errorMessage: msg,
                    loading: false,
                });
                return;
            }

            this.setState({loading: true});

            const data = new FormData();
            data.append('addTeamsFile', file);
            data.append('matchId', newMatchId);

            let axiosConfig = {
                headers: {
                    Authorization: 'Bearer ' + this.props.login.userDetails.data.accessToken,
                },
            };

            axios
                .post(`${TOURL}/match/uploadAddTeamsFile`, data, axiosConfig)
                .then((res) => {
                    // console.log(">>>uploadAndCallAddTeamsToMatch ", res.data);
                    if (res.data.msg !== null && res.data.msg !== undefined && res.data.msg !== '') {
                        this.addTeamsToMatch(res.data.msg);
                    }
                })
                .catch((err) => {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    this.setState({loading: false, snackbarOpen: true, errorMessage: errorMsg});
                });
        } catch (err) {
            console.error('uploadAndCallAddTeamsToMatch err: ', err);
            this.setState({
                snackbarOpen: true,
                errorMessage: 'Something went wrong!',
                loading: false,
            });
        }
    }

    uploadAndCallRemoveTeamsFromMatch() {
        try {
            let file = this.state.removeTeamsFile;
            let matchId = this.props.toPublishedMatch.editTOPublishedMatch.id;

            if (!file || !matchId) {
                let msg = 'File is required!';
                console.log(msg);
                this.setState({
                    snackbarOpen: true,
                    errorMessage: msg,
                    loading: false,
                });
                return;
            }

            this.setState({loading: true});

            const data = new FormData();
            data.append('removeTeamsFile', file);
            data.append('matchId', matchId);

            let axiosConfig = {
                headers: {
                    Authorization: 'Bearer ' + this.props.login.userDetails.data.accessToken,
                },
            };

            axios
                .post(`${TOURL}/match/uploadRemoveTeamsFile`, data, axiosConfig)
                .then((res) => {
                    // console.log(">>>uploadAndCallRemoveTeamsFromMatch ", res.data);
                    if (res.data.msg !== null && res.data.msg !== undefined && res.data.msg !== '') {
                        this.removeTeamsFromMatch(res.data.msg);
                    }
                })
                .catch((err) => {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    this.setState({loading: false, snackbarOpen: true, errorMessage: errorMsg});
                });
        } catch (err) {
            console.error('uploadAndCallRemoveTeamsToMatch err: ', err);
            this.setState({
                snackbarOpen: true,
                errorMessage: 'Something went wrong!',
                loading: false,
            });
        }
    }

    uploadAndCallCreateAndAddTeamsToMatch() {
        try {
            let file = this.state.createAndAddTeamsFile;
            let matchId = this.props.toPublishedMatch.editTOPublishedMatch.id;

            if (!file || !matchId) {
                let msg = 'File is required!';
                console.log(msg);
                this.setState({
                    snackbarOpen: true,
                    errorMessage: msg,
                    loading: false,
                });
                return;
            }

            this.setState({loading: true});

            const data = new FormData();
            data.append('createAndAddTeamsFile', file);
            data.append('matchId', matchId);

            let axiosConfig = {
                headers: {
                    Authorization: 'Bearer ' + this.props.login.userDetails.data.accessToken,
                },
            };

            axios
                .post(`${TOURL}/match/uploadcreateAndAddTeamsFile`, data, axiosConfig)
                .then((res) => {
                    console.log('>>>uploadAndCallRemoveTeamsFromMatch ', res.data);
                    if (res.data.msg !== null && res.data.msg !== undefined && res.data.msg !== '') {
                        this.createAndAddTeamsToMatch(res.data.msg);
                    }
                })
                .catch((err) => {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    this.setState({loading: false, snackbarOpen: true, errorMessage: errorMsg});
                });
        } catch (err) {
            console.error('uploadAndCallRemoveTeamsToMatch err: ', err);
            this.setState({
                snackbarOpen: true,
                errorMessage: 'Something went wrong!',
                loading: false,
            });
        }
    }

    displayPrizes(prizes) {
        const {edit, winType, winSlots} = this.state;
        if (winType === 'Fixed') {
            return null;
        }
        let inputs = [];
        for (let i = 1; i <= prizes; i++) {
            let unit = '';
            if (i === 1) {
                unit = i + 'st';
            } else if (i === 2) {
                unit = i + 'nd';
            } else if (i === 3) {
                unit = i + 'rd';
            } else {
                unit = i + 'th';
            }
            let val = 'rank' + i;

            inputs.push(
                <div className="grid-item margin-top-20">
                    <Input
                        label={unit}
                        type="text"
                        name={val}
                        suffix="%"
                        value={winSlots.ranks[val] ? winSlots.ranks[val] : winSlots.ranks[i]}
                        onChange={this.handleInputChange.bind(this, val)}
                        placeholder="Enter"
                        disabled={false}
                    />
                </div>,
            );
        }
        return inputs;
    }

    isCreatedFromCC() {
        if (
            this.props.toPublishedMatch.editTOPublishedMatch.createdByClient &&
            this.props.toPublishedMatch.editTOPublishedMatch.createdByClient !== 'control_center'
        ) {
            return false;
        }
        return true;
    }

    onSave(new_status) {
        const {
            name,
            startTime,
            totalSlots,
            minFillSlots,
            winPoolType,
            winFixedPoolSize,
            winPercentPoolSize,
            entryFeePerSlot,
            matchType,
            liveStream,
            roomCredentials,
            resultsFile,
            filledSlots,
            participation,
            joinedSlots,
            entryFeeType,
            fcCoinDiscountPercent,
            minParticipationRequired,
            numberOfBigWinTeams,
            winDenomination,
            winType,
            winParameter,
            winParameterValue,
            winSlots,
            winningDistribution,
            attribute1,
            attribute1Val,
            attribute2,
            attribute2Val,
            matchRules,
            matchMode,
            status,
            game,
            edit,
            matchAccess,
            allowIncompleteTeams,
            rankForSorting,
            organizerId,
            targetUsersByJoinDate,
            targetUsersByLoginStatus,
            targetUsersFilter_loggedIn,
            targetUsersFilter_minJoinDate,
        } = this.state;
        const {login, toPublishedMatch, toMatchType} = this.props;

        // if (!this.isCreatedFromCC()) {
        //     this.setState({
        //         snackbarOpen: true,
        //         errorMessage: "Updates not allowed for match created from "+this.props.toMatch.editTOMatch.createdByClient,
        //     });
        //     return;
        // }
        let error = false;
        if (new_status === 'UPCOMING' && login.userDetails.data.role === 'ORGANIZER') {
            error = true;
            this.setState({
                snackbarOpen: true,
                errorMessage: 'Organizer cannot make changes to a published match',
            });
        } else if (new_status === 'COMPLETED' && login.userDetails.data.role === 'ORGANIZER') {
            error = true;
            this.setState({
                snackbarOpen: true,
                errorMessage: 'Organizer cannot upload the match results. Please contact FanClash',
            });
        } else if (name === null || name === '' || name === undefined) {
            error = true;
            this.setState({
                snackbarOpen: true,
                errorMessage: 'Please enter the match Name',
            });
        } else if (startTime === null || startTime === '' || startTime === undefined) {
            error = true;
            this.setState({
                snackbarOpen: true,
                errorMessage: 'Please enter the match start time',
            });
        } else if (
            new_status === 'LIVE' &&
            minParticipationRequired === true &&
            (filledSlots < minFillSlots || joinedSlots < minFillSlots)
        ) {
            error = true;
            this.setState({
                snackbarOpen: true,
                errorMessage: "The match has not yet reached it's minimum participation level",
            });
        } else if (
            new_status === 'LIVE' &&
            (roomCredentials === null ||
                roomCredentials === undefined ||
                roomCredentials['cred1Name'] === null ||
                roomCredentials['cred1Val'] === null ||
                roomCredentials['cred1Name'] === '' ||
                roomCredentials['cred1Val'] === '')
        ) {
            error = true;
            this.setState({
                snackbarOpen: true,
                errorMessage: 'Please enter valid room credentials',
            });
        } else if (
            new_status === 'COMPLETED' &&
            (resultsFile === null || resultsFile === undefined || resultsFile === '')
        ) {
            error = true;
            this.setState({
                snackbarOpen: true,
                errorMessage: 'Please enter a valid results file',
            });
        }
        console.log('Error state is ' + error);
        if (error === false) {
            let match;
            if (new_status === 'LIVE') {
                let ts = moment(new Date()).valueOf();
                match = {
                    startTime: moment(new Date()).format('DD/MM/YYYY H:mm'),
                    timestamp: ts,
                    roomCredentials: roomCredentials,
                    liveStream: liveStream,
                    status: 'LIVE',
                };
            } else if (new_status === 'COMPLETED') {
                match = {
                    resultsFile: resultsFile,
                    participation: participation,
                    status: 'COMPLETED',
                };
            } else {
                let ts = moment(startTime).valueOf();
                match = {
                    name: name,
                    startTime: moment(startTime).format('DD/MM/YYYY H:mm'),
                    timestamp: ts,
                    roomCredentials: roomCredentials,
                    liveStream: liveStream,
                    status: status,
                    matchAccess: matchAccess,
                    allowIncompleteTeams: allowIncompleteTeams,
                    rankForSorting: rankForSorting === '' ? 0 : parseInt(rankForSorting),
                };
                if (organizerId) {
                    match.organizer = organizerId;
                }
            }
            this.setState({loading: true});
            console.log('Trying to update the data in the server');
            match.id = toPublishedMatch.editTOPublishedMatch.id;
            this.props.modifyTOMatch(match, login.userDetails.data.accessToken);
        }
    }

    componentDidMount() {
        const {login, toPublishedMatch, toMatchType, history} = this.props;
        let a = new Date();
        console.log(moment(a).format('DD/MM/YYYY H:mm'));
        let gamePageOptions = {
            listType: 'ALL',
        };
        if (!login.userDetails.data) {
            history.push('/');
            this.props.logout();
        } else {
            this.setState({
                loading: true,
            });
            const {
                name,
                startTime,
                entryFeePerSlot,
                totalSlots,
                minFillSlots,
                winPoolType,
                winFixedPoolSize,
                winPercentPoolSize,
                matchType,
                liveStream,
                roomCredentials,
                resultsFile,
                entryFeeType,
                fcCoinDiscountPercent,
                minParticipationRequired,
                numberOfBigWinTeams,
                winDenomination,
                winType,
                winParameter,
                winParameterValue,
                winningDistribution,
                winSlots,
                attribute1,
                attribute1Val,
                attribute2,
                attribute2Val,
                matchRules,
                matchMode,
                status,
                game,
                matchAccess,
                allowIncompleteTeams,
                rankForSorting,
                targetUsers,
                targetUsersFilter,
            } = toPublishedMatch.editTOPublishedMatch;
            this.props.getTOGameList(gamePageOptions, login.userDetails.data.accessToken);
            this.props.getTOMatchPartipation(
                {matchId: toPublishedMatch.editTOPublishedMatch.id},
                login.userDetails.data.accessToken,
            );
            this.props.getTOMatchTypeList({listType: 'ALL'}, login.userDetails.data.accessToken);
            this.props.getTOMatchModeList({listType: 'ALL'}, login.userDetails.data.accessToken);
            this.props.getTOMatchAttributeList({listType: 'ALL'}, login.userDetails.data.accessToken);
            let targetUsersByJoinDate = false;
            let targetUsersByLoginStatus = false;
            let targetUsersFilter_loggedIn = false;
            let targetUsersFilter_minJoinDate = new Date().getTime();
            if (targetUsers) {
                if (targetUsersFilter.loggedIn != undefined) {
                    targetUsersByLoginStatus = true;
                    targetUsersFilter_loggedIn = targetUsersFilter.loggedIn;
                }
                if (targetUsersFilter.minJoinDate != undefined) {
                    targetUsersByJoinDate = true;
                    targetUsersFilter_minJoinDate = targetUsersFilter.minJoinDate;
                }
            }
            this.setState({
                name: name,
                startTime: startTime,
                liveStream: liveStream,
                resultsFile: resultsFile,
                entryFeePerSlot: parseInt(entryFeePerSlot),
                totalSlots: parseInt(totalSlots),
                matchAccess: matchAccess,
                minFillSlots: parseInt(minFillSlots),
                winPoolType: winPoolType,
                winFixedPoolSize: parseInt(winFixedPoolSize),
                winPercentPoolSize: parseInt(winPercentPoolSize),
                matchType: matchType,
                entryFeeType: entryFeeType,
                fcCoinDiscountPercent: parseInt(fcCoinDiscountPercent),
                minParticipationRequired: minParticipationRequired,
                allowIncompleteTeams: allowIncompleteTeams,
                numberOfBigWinTeams: parseInt(numberOfBigWinTeams),
                winDenomination: winDenomination,
                winType: winType,
                winParameter: winParameter,
                winParameterValue: parseInt(winParameterValue),
                winningDistribution: winningDistribution,
                winSlots: winSlots,
                attribute1: attribute1.id,
                attribute1Val: attribute1Val,
                attribute2: attribute2.id,
                attribute2Val: attribute2Val,
                matchRules: matchRules,
                matchMode: matchMode.id,
                status: status,
                game: game.id,
                rankForSorting: rankForSorting === '' ? 0 : parseInt(rankForSorting),
                targetUsersByJoinDate,
                targetUsersByLoginStatus,
                targetUsersFilter_loggedIn,
                targetUsersFilter_minJoinDate,
            });
            if (roomCredentials) {
                this.setState({roomCredentials: roomCredentials});
            }
            if (resultsFile !== null && resultsFile !== undefined && resultsFile !== '') {
                this.computeResults(resultsFile);
            }
        }
    }

    componentDidUpdate(prevProps) {
        const {toPublishedMatch, toMatchType, toGame, toMatchMode, toMatchAttribute, history} = this.props;
        const {game} = this.state;

        if (toGame.TOGameListSuccess && !prevProps.toGame.TOGameListSuccess) {
            this.setState({
                gameOptions: toGame.TOGameList.data.data.map((item) => {
                    return {text: item.gameName, value: item.id};
                }),
                loading: false,
            });
        } else if (toGame.TOGameListFail && !prevProps.toGame.TOGameListFail) {
            this.setState({
                errorMessage: toGame.errorMessage,
                snackbarOpen: true,
                loading: false,
            });
        }

        if (toMatchMode.TOMatchModeListSuccess && !prevProps.toMatchMode.TOMatchModeListSuccess) {
            this.setState({
                matchModeOptions: toMatchMode.TOMatchModeList.data.data
                    .filter((item) => item.game === game)
                    .map((item) => {
                        return {text: item.modeName, value: item.id};
                    }),
            });
            if (this.state.edit) {
                if (this.props.toPublishedMatch.editTOPublishedMatch.matchMode) {
                    this.setState({
                        matchMode: this.props.toPublishedMatch.editTOPublishedMatch.matchMode.id,
                    });
                }
            }
            if (
                toMatchAttribute.TOMatchAttributeListSuccess &&
                toMatchType.TOMatchTypeListSuccess &&
                toPublishedMatch.getTOMatchPartipationSuccess
            ) {
                this.setState({loading: false});
            }
        } else if (toMatchMode.TOMatchModeListFail && !prevProps.toMatchMode.TOMatchModeListFail) {
            this.setState({
                errorMessage: toMatchMode.errorMessage,
                snackbarOpen: true,
                loading: false,
            });
        }

        if (toMatchAttribute.TOMatchAttributeListSuccess && !prevProps.toMatchAttribute.TOMatchAttributeListSuccess) {
            this.setState({
                attribute1Options: toMatchAttribute.TOMatchAttributeList.data.data
                    .filter((item) => item.game === game)
                    .map((item) => {
                        return {text: item.name, value: item.id};
                    }),
                attribute2Options: toMatchAttribute.TOMatchAttributeList.data.data
                    .filter((item) => item.game === game)
                    .map((item) => {
                        return {text: item.name, value: item.id};
                    }),
            });
            if (this.state.edit) {
                if (this.props.toPublishedMatch.editTOPublishedMatch.attribute1) {
                    this.setState({
                        attribute1: this.props.toPublishedMatch.editTOPublishedMatch.attribute1.id,
                    });
                }
            }
            if (
                toMatchMode.TOMatchModeListSuccess &&
                toMatchType.TOMatchTypeListSuccess &&
                toPublishedMatch.getTOMatchParticipationSuccess
            ) {
                this.setState({loading: false});
                this.populateExcel();
            }
        } else if (toMatchAttribute.TOMatchAttributeListFail && !prevProps.toMatchAttribute.TOMatchAttributeListFail) {
            this.setState({
                errorMessage: toMatchAttribute.errorMessage,
                snackbarOpen: true,
                loading: false,
            });
        }

        if (toMatchType.TOMatchTypeListSuccess && !prevProps.toMatchType.TOMatchTypeListSuccess) {
            this.setState({
                matchTypeOptions: toMatchType.TOMatchTypeList.data.data
                    .filter((item) => item.game === game)
                    .map((item) => {
                        return {text: item.name, value: item.id};
                    }),
            });
            if (this.state.edit) {
                if (this.props.toPublishedMatch.editTOPublishedMatch.matchType) {
                    this.setState({
                        matchType: this.props.toPublishedMatch.editTOPublishedMatch.matchType.id,
                    });
                }
            }
            if (
                toMatchMode.TOMatchModeListSuccess &&
                toMatchAttribute.TOMatchAttributeListSuccess &&
                toPublishedMatch.getTOMatchParticipationSuccess
            ) {
                this.setState({loading: false});
                this.populateExcel();
            }
        } else if (toMatchType.TOMatchTypeListFail && !prevProps.toMatchType.TOMatchTypeListFail) {
            this.setState({
                errorMessage: toMatchType.errorMessage,
                snackbarOpen: true,
                loading: false,
            });
        }

        if (
            toPublishedMatch.getTOMatchParticipationSuccess &&
            !prevProps.toPublishedMatch.getTOMatchParticipationSuccess
        ) {
            // console.log(this.props.toPublishedMatch.TOMatchParticipation.participation);
            this.setState({
                filledSlots: this.props.toPublishedMatch.TOMatchParticipation.filledSlots,
                joinedSlots: this.props.toPublishedMatch.TOMatchParticipation.joinedSlots,
                participation: this.props.toPublishedMatch.TOMatchParticipation.participation,
            });
            if (
                toMatchMode.TOMatchModeListSuccess &&
                toMatchAttribute.TOMatchAttributeListSuccess &&
                toMatchType.TOMatchTypeListSuccess
            ) {
                this.setState({loading: false});
                this.populateExcel();
            }
        } else if (
            toPublishedMatch.getTOMatchParticipationFail &&
            !prevProps.toPublishedMatch.getTOMatchParticipationFail
        ) {
            this.setState({
                errorMessage: toPublishedMatch.errorMessage,
                snackbarOpen: true,
                loading: false,
            });
        }

        if (toPublishedMatch.modifyTOMatchSuccess && !prevProps.toPublishedMatch.modifyTOMatchSuccess) {
            history.push('/toPublishedMatches');
        } else if (toPublishedMatch.modifyTOMatchFail && !prevProps.toPublishedMatch.modifyTOMatchFail) {
            this.setState({
                loading: false,
                snackbarOpen: true,
                errorMessage: toPublishedMatch.errorMessage,
            });
        }

        if (toPublishedMatch.cancelTOMatchSuccess && !prevProps.toPublishedMatch.cancelTOMatchSuccess) {
            history.push('/toPublishedMatches');
        } else if (toPublishedMatch.cancelTOMatchFail && !prevProps.toPublishedMatch.cancelTOMatchFail) {
            this.setState({
                loading: false,
                snackbarOpen: true,
                errorMessage: toPublishedMatch.errorMessage,
            });
        }
    }

    s2ab(s) {
        let buf = new ArrayBuffer(s.length);
        let view = new Uint8Array(buf);
        for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
    }

    populateExcel() {
        let wb = xlsx.utils.book_new();
        wb.Props = {
            Title: 'Template for Match' + this.props.toPublishedMatch.editTOPublishedMatch.id,
            Subject: 'Match Win Template',
            Author: 'Aravind Maddireddy',
            CreatedDate: new Date(),
        };
        wb.SheetNames.push(this.props.toPublishedMatch.editTOPublishedMatch.id);
        let ws_data = [];
        if (this.state.winType === 'Fixed') {
            ws_data.push([
                'Team Name',
                'Team ID',
                'Player',
                'user',
                'Game ID',
                'Status',
                '# of ' + this.state.winParameter,
            ]);
        } else {
            ws_data.push(['Team Name', 'Team ID', 'Player', 'user', 'Game ID', 'Status', 'Mixed Score']);
        }
        for (var i = 0; i < this.props.toPublishedMatch.TOMatchParticipation.participation.length; i++) {
            let thisTeam = this.props.toPublishedMatch.TOMatchParticipation.participation[i];
            ws_data.push([thisTeam.originalTeam.teamName, thisTeam.originalTeam.id, '', '', '', '']);
            for (var j = 0; j < thisTeam.teamParticipation.length; j++) {
                let teamMember = thisTeam.teamParticipation[j];
                if (teamMember.matchStatus === 'joined') {
                    ws_data.push([
                        '',
                        '',
                        teamMember.player,
                        teamMember.user,
                        teamMember.gameId,
                        teamMember.matchStatus,
                    ]);
                }
            }
        }
        let ws = xlsx.utils.aoa_to_sheet(ws_data);
        wb.Sheets[this.props.toPublishedMatch.editTOPublishedMatch.id] = ws;
        let wbout = xlsx.write(wb, {bookType: 'xlsx', type: 'binary'});
        this.setState({resultsBook: wbout});
    }

    populateAndDownloadAddTeamResultExcel(teamIdsFromInput, data) {
        let matchId = this.props.toPublishedMatch.editTOPublishedMatch.id;
        let wb = xlsx.utils.book_new();
        wb.Props = {
            Title: 'Result for add team ' + matchId,
            Subject: 'Result for add team',
            Author: 'Fanclash',
            CreatedDate: new Date(),
        };

        wb.SheetNames.push(matchId);

        let ws_data = [];
        //headers
        ws_data.push(['TeamId', 'TeamName', 'Status', 'Reason']);

        // console.log("download. add teams data", data)

        //rows
        for (let i = 0; i < teamIdsFromInput.length; i++) {
            let teamId = teamIdsFromInput[i];
            if (data[teamId]) {
                if (data[teamId].status === 'success') {
                    ws_data.push([teamId, data[teamId].teamName, data[teamId].status, '']);
                } else {
                    ws_data.push([teamId, data[teamId].teamName, data[teamId].status, data[teamId].reason]);
                }
            } else {
                ws_data.push([teamId, '', '', '']);
            }
        }

        // console.log("addTeamResult excel", ws_data);

        //create excel
        let ws = xlsx.utils.aoa_to_sheet(ws_data);
        wb.Sheets[matchId] = ws;
        let wbout = xlsx.write(wb, {bookType: 'xlsx', type: 'binary'});

        saveAs(
            new Blob([this.s2ab(wbout)], {type: 'application/octet-stream'}),
            'add_teams_result_' + matchId + '.xlsx',
        );

        //refresh teams' list
        this.props.getTOMatchPartipation(
            {matchId: this.props.toPublishedMatch.editTOPublishedMatch.id},
            this.props.login.userDetails.data.accessToken,
        );
        this.setState({loading: false});
    }

    populateAndDownloadCreateAndAddTeamResultExcel(userDetails, data) {
        let matchId = this.props.toPublishedMatch.editTOPublishedMatch.id;
        let wb = xlsx.utils.book_new();
        wb.Props = {
            Title: 'Result for create and add teams ' + matchId,
            Subject: 'Result for create and add teams',
            Author: 'Fanclash',
            CreatedDate: new Date(),
        };

        wb.SheetNames.push(matchId);

        let ws_data = [];
        //headers
        ws_data.push([
            'TeamName',
            'PhoneNumber',
            'InGameName',
            'FullName',
            'Email',
            'status',
            'message',
            'teamId',
            'userId',
            'isOldUser',
            'createdTeamName',
            'matchParticipationId',
        ]);

        // console.log("download. add teams data", data)

        //rows
        for (let i = 0; i < userDetails.length; i++) {
            let userDetail = userDetails[i];
            let rowId = userDetail.rowId;
            let teamName = userDetail.teamName;
            let fullName = userDetail.fullName;
            let phoneNumber = userDetail.phoneNumber;
            let email = userDetail.email;
            let inGameName = userDetail.inGameName;
            let paramsRow = [teamName, phoneNumber, inGameName, fullName, email];
            let statusRow = ['n/a', '', '', '', '', ''];
            let result = data[rowId];
            if (result) {
                statusRow = [
                    result.status,
                    result.message,
                    result.teamId,
                    result.userId,
                    result.isOldUser,
                    result.createdTeamName,
                    result.participationId,
                ];
            }
            ws_data.push([...paramsRow, ...statusRow]);
        }

        // console.log("addTeamResult excel", ws_data);

        //create excel
        let ws = xlsx.utils.aoa_to_sheet(ws_data);
        wb.Sheets[matchId] = ws;
        let wbout = xlsx.write(wb, {bookType: 'xlsx', type: 'binary'});

        saveAs(
            new Blob([this.s2ab(wbout)], {type: 'application/octet-stream'}),
            'create_and_add_teams_result_' + matchId + '.xlsx',
        );

        //refresh teams' list
        this.props.getTOMatchPartipation(
            {matchId: this.props.toPublishedMatch.editTOPublishedMatch.id},
            this.props.login.userDetails.data.accessToken,
        );
        this.setState({loading: false});
    }

    populateAndDownloadRemoveTeamResultExcel(teamIdsFromInput, data) {
        let matchId = this.props.toPublishedMatch.editTOPublishedMatch.id;
        let wb = xlsx.utils.book_new();
        wb.Props = {
            Title: 'Result for remove teams ' + matchId,
            Subject: 'Result for remove teams',
            Author: 'Fanclash',
            CreatedDate: new Date(),
        };

        wb.SheetNames.push(matchId);

        let ws_data = [];
        //headers
        ws_data.push(['TeamId', 'TeamName', 'Status', 'Reason']);

        // console.log("download. remove teams data", data)

        //rows
        for (let i = 0; i < teamIdsFromInput.length; i++) {
            let teamId = teamIdsFromInput[i];
            if (data[teamId]) {
                if (data[teamId].status === 'success') {
                    ws_data.push([teamId, data[teamId].teamName, data[teamId].status, '']);
                } else {
                    ws_data.push([teamId, data[teamId].teamName, data[teamId].status, data[teamId].reason]);
                }
            } else {
                ws_data.push([teamId, '', '', '']);
            }
        }

        // console.log("addTeamResult excel", ws_data);

        //create excel
        let ws = xlsx.utils.aoa_to_sheet(ws_data);
        wb.Sheets[matchId] = ws;
        let wbout = xlsx.write(wb, {bookType: 'xlsx', type: 'binary'});

        saveAs(
            new Blob([this.s2ab(wbout)], {type: 'application/octet-stream'}),
            'remove_teams_result_' + matchId + '.xlsx',
        );

        //refresh teams' list
        this.props.getTOMatchPartipation({matchId: matchId}, this.props.login.userDetails.data.accessToken);
        this.setState({loading: false});
    }

    downloadMatchDetails() {
        try {
            this.setState({loading: true});
            let matchId = this.props.toPublishedMatch.editTOPublishedMatch.id;
            const {participation} = this.state;
            let matchMode = this.props.toPublishedMatch.editTOPublishedMatch.matchMode.modeName;

            if (participation.length === 0) {
                this.setState({
                    snackbarOpen: true,
                    errorMessage: 'No participation yet!',
                    loading: false,
                });
                return;
            }

            let wb = xlsx.utils.book_new();
            wb.Props = {
                Title: 'Match Details ' + matchId,
                Subject: 'Match Details',
                Author: 'Fanclash',
                CreatedDate: new Date(),
            };

            wb.SheetNames.push(matchId);

            let ws_data = [];

            //add headers
            let playersCount = 1;
            if (matchMode === 'Duo') {
                playersCount = 2;
            } else if (matchMode === 'Squad') {
                playersCount = 4;
            }

            let headers = ['TeamId', 'TeamName'];
            for (let i = 0; i < playersCount; i++) {
                headers.push(`P${i + 1}_Phone`, `P${i + 1}_Level`, `P${i + 1}_Game_ID`, `P${i + 1}_Match_Status`);
            }
            ws_data.push(headers);

            //rows
            for (let i = 0; i < participation.length; i++) {
                let row = [];

                row.push(participation[i].originalTeam.id);
                row.push(participation[i].originalTeam.teamName);

                for (let j = 0; j < participation[i].teamParticipation.length; j++) {
                    row.push(participation[i].teamParticipation[j].player);
                    row.push(participation[i].teamParticipation[j].user);
                    row.push(participation[i].teamParticipation[j].level);
                    row.push(participation[i].teamParticipation[j].gameId);
                    row.push(participation[i].teamParticipation[j].matchStatus);
                }

                ws_data.push(row);
            }

            //create excel
            let ws = xlsx.utils.aoa_to_sheet(ws_data);
            wb.Sheets[matchId] = ws;
            let wbout = xlsx.write(wb, {bookType: 'xlsx', type: 'binary'});

            saveAs(
                new Blob([this.s2ab(wbout)], {type: 'application/octet-stream'}),
                'match_details_' + matchId + '.xlsx',
            );
            this.setState({loading: false});
        } catch (err) {
            console.error('downloadMatchDetails err: ', err);
            this.setState({
                snackbarOpen: true,
                errorMessage: 'Something went wrong!',
                loading: false,
            });
        }
    }

    downloadExcel() {
        saveAs(
            new Blob([this.s2ab(this.state.resultsBook)], {type: 'application/octet-stream'}),
            'template_' + this.props.toPublishedMatch.editTOPublishedMatch.id + '.xlsx',
        );
    }

    async computeResults(fileLocation) {
        let self = this;
        this.setState({loading: true});
        let columns = [];
        let tempResultData = [];
        let res = await fetchS3FileFromServer(fileLocation, this.props.login.userDetails.data.accessToken);
        // axios(fileLocation, {responseType:'arraybuffer'})
        // .then(res => {
        /* parse the data when it is received */
        var data = new Uint8Array(res.data);
        var workbook = xlsx.read(data, {type: 'array'});
        /* DO SOMETHING WITH workbook HERE */
        let worksheet = workbook.Sheets[workbook.SheetNames[0]];
        let sheetData = xlsx.utils.sheet_to_json(worksheet, {header: 1});
        let headers = sheetData[0];
        for (let i = 0; i < headers.length; i++) {
            columns.push({
                Header: headers[i],
                accessor: headers[i].replace(/\s/g, ''),
                Cell: (props) => <span>{props.value}</span>,
            });
        }
        if (this.state.winType === 'Distribution') {
            columns.push({
                Header: 'Rank',
                accessor: 'rank',
                Cell: (props) => <span>{props.value}</span>,
            });
            columns.push({
                Header: 'Winnings',
                accessor: 'winnings',
                Cell: (props) => <span>{props.value}</span>,
            });
        } else {
            columns.push({
                Header: 'Winnings',
                accessor: 'winnings',
                Cell: (props) => <span>{props.value}</span>,
            });
        }
        for (let i = 1; i < sheetData.length; i++) {
            let temp_dict = {};
            for (let j = 0; j < headers.length; j++) {
                temp_dict[headers[j].replace(/\s/g, '')] = sheetData[i][j];
            }
            tempResultData.push(temp_dict);
        }
        // console.log(tempResultData);
        let thisParticipation = [...this.state.participation];
        if (this.state.winType === 'Distribution') {
            let curConsideration = tempResultData.filter((item) => item.TeamID !== '');
            // console.log(curConsideration);
            curConsideration.sort((a, b) => b.MixedScore - a.MixedScore);
            let rank = 1;
            for (var i = 0; i < curConsideration.length; i++) {
                if (i > 0 && curConsideration[i].MixedScore < curConsideration[i - 1].MixedScore) {
                    rank++;
                }
                curConsideration[i].rank = rank;
            }
            tempResultData = tempResultData.map((item) => {
                if (item.TeamID === '') {
                    return {...item, rank: ''};
                } else {
                    return {...item, rank: curConsideration.find((item2) => item2.TeamID === item.TeamID).rank};
                }
            });
            // console.log(tempResultData);
            let totalPaid = 0;
            for (var i = 0; i < thisParticipation.length; i++) {
                totalPaid += thisParticipation[i].teamParticipation.filter(
                    (item) => item.paymentStatus === 'paid',
                ).length;
            }
            for (var i = 0; i < tempResultData.length; i++) {
                if (tempResultData[i].TeamID == null) {
                    continue;
                }

                if (tempResultData[i].TeamID && tempResultData[i].TeamID !== '') {
                    let participatingTeam = thisParticipation.find(
                        (item) => item.originalTeam.id === tempResultData[i].TeamID,
                    );
                    participatingTeam.score = tempResultData[i].MixedScore;
                    participatingTeam.rank = tempResultData[i].rank;
                    let totalPool;
                    // console.log(this.state.winPoolType);
                    if (this.state.winPoolType === 'Percentage') {
                        // console.log(totalPaid);
                        // console.log(this.state.entryFeePerSlot);
                        // console.log(this.state.winPercentPoolSize);
                        totalPool = totalPaid * this.state.entryFeePerSlot;
                    } else {
                        totalPool = this.state.winFixedPoolSize;
                    }
                    // console.log(totalPool);
                    if (tempResultData[i].rank in this.state.winningDistribution) {
                        // console.log('Inside the assignment of team winnings');
                        // console.log(this.state.winningDistribution[tempResultData[i].rank]);
                        participatingTeam.winnings =
                            Math.round((totalPool * this.state.winningDistribution[tempResultData[i].rank]) / 10) / 10;
                        tempResultData[i].winnings = participatingTeam.winnings;
                    } else {
                        participatingTeam.winnings = 0;
                        tempResultData[i].winnings = 0;
                    }
                    // console.log(participatingTeam.winnings);
                    let joinedSize = participatingTeam.teamParticipation.filter(
                        (item) => item.matchStatus === 'joined',
                    ).length;
                    let individualWinnings = Math.round((participatingTeam.winnings / joinedSize) * 10) / 10;
                    // console.log(individualWinnings);
                    for (var j = 0; j < participatingTeam.teamParticipation.length; j++) {
                        if (participatingTeam.teamParticipation[j].matchStatus === 'joined') {
                            participatingTeam.teamParticipation[j].winnings = individualWinnings;
                        } else {
                            // participatingTeam.teamParticipation[j].winnings = individualWinnings;//bug?
                        }
                    }
                    let thisIndex = thisParticipation.findIndex(
                        (item) => item.originalTeam.id === tempResultData[i].TeamID,
                    );
                    thisParticipation[thisIndex] = participatingTeam;
                } else {
                    if (tempResultData[i].Player && tempResultData[i].Player !== '') {
                        let participatingTeam = thisParticipation.find(
                            (item) =>
                                item.teamParticipation.findIndex((item2) => item2.player === tempResultData[i].Player) >
                                -1,
                        );
                        tempResultData[i].winnings = participatingTeam.teamParticipation.find(
                            (item) => item.player === tempResultData[i].Player,
                        ).winnings;
                    } else if (tempResultData[i].user && tempResultData[i].user !== '') {
                        let participatingTeam = thisParticipation.find(
                            (item) =>
                                item.teamParticipation.findIndex((item2) => item2.user === tempResultData[i].user) > -1,
                        );
                        tempResultData[i].winnings = participatingTeam.teamParticipation.find(
                            (item) => item.user === tempResultData[i].user,
                        ).winnings;
                    } else {
                        //unexpected, throw error
                    }
                }
            }
            for (var i = 0; i < thisParticipation.length; i++) {
                for (var j = 0; j < thisParticipation[i].teamParticipation.length; j++) {
                    if (thisParticipation[i].teamParticipation[j].matchStatus === 0) {
                        thisParticipation[i].teamParticipation[j].score = 0;
                        thisParticipation[i].teamParticipation[j].winnings = 0;
                    }
                }
            }
        } else {
            for (var i = 0; i < tempResultData.length; i++) {
                if (tempResultData[i].Player && tempResultData[i].Player !== '') {
                    let participatingTeam = thisParticipation.find(
                        (item) =>
                            item.teamParticipation.findIndex(
                                (item2) => item2.player === tempResultData[i].Player && item2.matchStatus === 'joined',
                            ) > -1,
                    );
                    let participatingTeamIndex = thisParticipation.findIndex(
                        (item) =>
                            item.teamParticipation.findIndex(
                                (item2) => item2.player === tempResultData[i].Player && item2.matchStatus === 'joined',
                            ) > -1,
                    );
                    let thisMember = participatingTeam.teamParticipation.findIndex(
                        (item) => item.player === tempResultData[i].Player && item.matchStatus === 'joined',
                    );
                    participatingTeam.teamParticipation[thisMember].score =
                        tempResultData[i][('# of' + this.state.winParameter).replace(/\s/g, '')];
                    participatingTeam.teamParticipation[thisMember].winnings =
                        tempResultData[i][('# of' + this.state.winParameter).replace(/\s/g, '')] *
                        this.state.winParameterValue;
                    tempResultData[i].winnings = participatingTeam.teamParticipation[thisMember].winnings;
                    thisParticipation[participatingTeamIndex] = participatingTeam;
                } else if (tempResultData[i].user && tempResultData[i].user !== '') {
                    let participatingTeam = thisParticipation.find(
                        (item) =>
                            item.teamParticipation.findIndex(
                                (item2) => item2.user === tempResultData[i].user && item2.matchStatus === 'joined',
                            ) > -1,
                    );
                    let participatingTeamIndex = thisParticipation.findIndex(
                        (item) =>
                            item.teamParticipation.findIndex(
                                (item2) => item2.user === tempResultData[i].user && item2.matchStatus === 'joined',
                            ) > -1,
                    );
                    let thisMember = participatingTeam.teamParticipation.findIndex(
                        (item) => item.user === tempResultData[i].user && item.matchStatus === 'joined',
                    );
                    participatingTeam.teamParticipation[thisMember].score =
                        tempResultData[i][('# of' + this.state.winParameter).replace(/\s/g, '')];
                    participatingTeam.teamParticipation[thisMember].winnings =
                        tempResultData[i][('# of' + this.state.winParameter).replace(/\s/g, '')] *
                        this.state.winParameterValue;
                    tempResultData[i].winnings = participatingTeam.teamParticipation[thisMember].winnings;
                    thisParticipation[participatingTeamIndex] = participatingTeam;
                }
            }
            for (var i = 0; i < thisParticipation.length; i++) {
                let temp_score = 0;
                let temp_winnings = 0;
                for (var j = 0; j < thisParticipation[i].teamParticipation.length; j++) {
                    if (thisParticipation[i].teamParticipation[j].matchStatus === 'joined') {
                        temp_score += thisParticipation[i].teamParticipation[j].score;
                        temp_winnings += thisParticipation[i].teamParticipation[j].winnings;
                    } else {
                        thisParticipation[i].teamParticipation[j].score = 0;
                        thisParticipation[i].teamParticipation[j].winnings = 0;
                    }
                }
                let thisTeamIndex = tempResultData.findIndex(
                    (item) => item.TeamID === thisParticipation[i].originalTeam.id,
                );
                tempResultData[thisTeamIndex][('# of' + this.state.winParameter).replace(/\s/g, '')] = temp_score;
                tempResultData[thisTeamIndex].winnings = temp_winnings;
                thisParticipation[i].score = temp_score;
                thisParticipation[i].winnings = temp_winnings;
            }
        }

        this.setState({
            resultColumns: columns,
            resultData: tempResultData,
            participation: thisParticipation,
            loading: false,
        });
        // });
    }

    async addTeamsToMatch(fileLocation) {
        try {
            // console.log("addTeamsToMatch fileLocation", fileLocation);
            this.setState({loading: true});
            let res = await fetchS3FileFromServer(fileLocation, this.props.login.userDetails.data.accessToken);
            //parse input excel with teamIds
            var data = new Uint8Array(res.data);
            var workbook = xlsx.read(data, {type: 'array'});
            let worksheet = workbook.Sheets[workbook.SheetNames[0]];
            let sheetData = xlsx.utils.sheet_to_json(worksheet, {header: 1});

            let teamIds = [];
            for (let i = 1; i < sheetData.length; i++) {
                if (sheetData[i][0] && sheetData[i][0].trim() != '') {
                    teamIds.push(sheetData[i][0]);
                }
            }

            // call addTeams API
            let oldMatchId = this.state.addTeamOldMatchId;
            let newMatchId = this.props.toPublishedMatch.editTOPublishedMatch.id;
            // console.log("addTeamsToMatch: teamIds, oldMatchId, newMatchId", teamIds, oldMatchId, newMatchId);

            //axios
            let axiosConfig = {
                headers: {
                    Authorization: 'Bearer ' + this.props.login.userDetails.data.accessToken,
                },
            };
            let req = {teamIds: teamIds, matchId: newMatchId, oldMatchId: oldMatchId};
            // console.log("addTeamsToMatch req", req);
            let addTeamsRes = await axios.post(`${TOURL}/match/addTeam`, req, axiosConfig);

            // console.log("addTeamsToMatch: response ", addTeamsRes);
            if (!addTeamsRes.data.data || !addTeamsRes.data.data.data) {
                this.setState({
                    snackbarOpen: true,
                    errorMessage: 'Could not get expected response from server!',
                });
            }

            //download results
            this.populateAndDownloadAddTeamResultExcel(teamIds, addTeamsRes.data.data.data);
        } catch (err) {
            console.error('addTeamsToMatch err: ', err);
            const errorMsg = err?.response?.data?.msg || err.message;
            this.setState({
                snackbarOpen: true,
                errorMessage: errorMsg || 'Something went wrong',
                loading: false,
            });
        }
    }

    async createAndAddTeamsToMatch(fileLocation) {
        try {
            // console.log("createAndAddTeamsToMatch fileLocation", fileLocation);
            this.setState({loading: true});
            let res = await fetchS3FileFromServer(fileLocation, this.props.login.userDetails.data.accessToken);
            //parse input excel with teamIds
            var data = new Uint8Array(res.data);
            var workbook = xlsx.read(data, {type: 'array'});
            let worksheet = workbook.Sheets[workbook.SheetNames[0]];
            let sheetData = xlsx.utils.sheet_to_json(worksheet, {header: 1});

            let userDetails = [];
            //Team Name     phone Number    in game Name	Full Name	email Id
            for (let i = 1; i < sheetData.length; i++) {
                let teamName = ('' + sheetData[i][0]).trim();
                let phoneNumber = ('' + sheetData[i][1]).trim();
                let inGameName = ('' + sheetData[i][2]).trim();
                let fullName = ('' + sheetData[i][3]).trim();
                let email = ('' + sheetData[i][4]).trim();
                userDetails.push({teamName, phoneNumber, inGameName, fullName, email, rowId: i});
            }

            let matchId = this.props.toPublishedMatch.editTOPublishedMatch.id;
            let oldMatchId = this.state.addTeamOldMatchId;

            //axios
            let axiosConfig = {
                headers: {
                    Authorization: 'Bearer ' + this.props.login.userDetails.data.accessToken,
                },
            };
            let req = {userDetails, matchId, oldMatchId};
            // console.log("create and addTeam req", req);
            let createAndAddTeamsRes = await axios.post(`${TOURL}/match/createAndAddTeams`, req, axiosConfig);

            // console.log("addTeamsToMatch: response ", addTeamsRes);
            if (!createAndAddTeamsRes.data.data || !createAndAddTeamsRes.data.data.data) {
                this.setState({
                    snackbarOpen: true,
                    errorMessage: 'Could not get expected response from server!',
                });
            }

            //download results
            this.populateAndDownloadCreateAndAddTeamResultExcel(userDetails, createAndAddTeamsRes.data.data.data);
        } catch (err) {
            console.error('createAndAddTeamsToMatch err: ', err);
            const errorMsg = err?.response?.data?.msg || err.message;
            this.setState({
                snackbarOpen: true,
                errorMessage: errorMsg || 'Something went wrong',
                loading: false,
            });
        }
    }

    async removeTeamsFromMatch(fileLocation) {
        try {
            this.setState({loading: true});
            let res = await fetchS3FileFromServer(fileLocation, this.props.login.userDetails.data.accessToken);
            //parse input excel with teamIds
            var data = new Uint8Array(res.data);
            var workbook = xlsx.read(data, {type: 'array'});
            let worksheet = workbook.Sheets[workbook.SheetNames[0]];
            let sheetData = xlsx.utils.sheet_to_json(worksheet, {header: 1});

            let teamIds = [];
            for (let i = 1; i < sheetData.length; i++) {
                if (sheetData[i][0] && sheetData[i][0].trim() != '') {
                    teamIds.push(sheetData[i][0]);
                }
            }

            let matchId = this.props.toPublishedMatch.editTOPublishedMatch.id;
            // console.log("addTeamsToMatch: teamIds, oldMatchId, newMatchId", teamIds, oldMatchId, newMatchId);

            //axios
            let axiosConfig = {
                headers: {
                    Authorization: 'Bearer ' + this.props.login.userDetails.data.accessToken,
                },
            };
            let req = {teamIds: teamIds, matchId: matchId};
            // console.log("removeTeamsFromMatch req", req);
            let removeTeamsRes = await axios.post(`${TOURL}/match/removeTeam`, req, axiosConfig);

            // console.log("addTeamsToMatch: response ", addTeamsRes);
            if (!removeTeamsRes.data.data || !removeTeamsRes.data.data.data) {
                this.setState({
                    snackbarOpen: true,
                    errorMessage: 'Could not get expected response from server!',
                });
            }

            //download results
            this.populateAndDownloadRemoveTeamResultExcel(teamIds, removeTeamsRes.data.data.data);
        } catch (err) {
            console.error('addTeamsToMatch err: ', err);
            const errorMsg = err?.response?.data?.msg || err.message;
            this.setState({
                snackbarOpen: true,
                errorMessage: errorMsg || 'Something went wrong',
                loading: false,
            });
        }
    }

    onCancel() {
        this.setState({open: true});
    }

    //api call to cancel selcted match
    onCancelTOPublishedMatch() {
        // if (!this.isCreatedFromCC()) {
        //     this.setState({
        //         snackbarOpen: true,
        //         errorMessage: "Cancel not allowed for match created from "+this.props.toMatch.editTOMatch.createdByClient,
        //     });
        //     return;
        // }

        const {login} = this.props;
        let matchId = this.props.toPublishedMatch.editTOPublishedMatch.id;
        this.props.cancelTOMatch({matchId: matchId}, login.userDetails.data.accessToken);
        this.setState({open: false, loading: true});
    }

    onUpdateOrganizerId(organizerId) {
        this.setState({organizerId: organizerId});
    }

    renderTabA() {
        const {
            name,
            startTime,
            entryFeePerSlot,
            totalSlots,
            minFillSlots,
            winPoolType,
            filledSlots,
            joinedSlots,
            participation,
            roomCredentials,
            liveStream,
            resultsFile,
            winPoolTypeOptions,
            matchTypeOptions,
            matchType,
            winFixedPoolSize,
            winPercentPoolSize,
            entryFeeType,
            entryFeeTypeOptions,
            fcCoinDiscountPercent,
            minParticipationRequired,
            minParticipationRequiredOptions,
            numberOfBigWinTeams,
            winDenomination,
            winDenominationOptions,
            winType,
            winTypeOptions,
            winParameter,
            winParameterValue,
            winSlots,
            attribute1,
            attribute1Options,
            attribute1Val,
            attribute2,
            attribute2Options,
            attribute2Val,
            matchMode,
            matchModeOptions,
            matchRules,
            status,
            statusOptions,
            game,
            gameOptions,
            handlingCharge,
            uploadFile,
            organizer,
            errorMessage,
            loading,
            open,
            edit,
            snackbarOpen,
            matchAccess,
            matchAccessOptions,
            allowIncompleteTeams,
            rankForSorting,
            targetUsersByJoinDate,
            targetUsersByLoginStatus,
            booleanOptions,
            targetUsersFilter_minJoinDate,
            targetUsersFilter_loggedIn,
            targetUsersFilter_loggedInOptions,
        } = this.state;
        const {history, toPublishedMatch, toMatchType} = this.props;
        const {createdByClient, registrationStartTime, registrationEndTime} = toPublishedMatch.editTOPublishedMatch;

        return (
            <div>
                <div className="card-grid-container">
                    <div className="grid-item  margin-top-20">
                        <Input
                            label="Match Name"
                            type="text"
                            name="name"
                            value={name}
                            onChange={this.handleInputChange.bind(this, 'name')}
                            placeholder="Enter the Match Name"
                            disabled={status === 'LIVE' ? true : false || organizer}
                        />
                    </div>
                    <div className="grid-item  margin-top-20">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DateTimePicker
                                margin="normal"
                                id="date-picker-dialog"
                                label={status === 'LIVE' ? 'Gone Live At' : 'Start Time'}
                                value={startTime}
                                minDate={status === 'LIVE' ? null : new Date()}
                                disabled={status === 'LIVE' ? true : false || organizer}
                                invalidDateMessage={''}
                                onChange={this.handleInputChange.bind(this, 'startTime')}
                            />
                        </MuiPickersUtilsProvider>
                    </div>

                    {/* row */}
                    {registrationStartTime ? (
                        <div className="grid-item  margin-top-20">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DateTimePicker
                                    margin="normal"
                                    id="date-picker-dialog"
                                    label={'Registration Start Time'}
                                    value={registrationStartTime}
                                    disabled={true}
                                    invalidDateMessage={''}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    ) : (
                        <div className="grid-item  margin-top-20" />
                    )}
                    {registrationEndTime ? (
                        <div className="grid-item  margin-top-20">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DateTimePicker
                                    margin="normal"
                                    id="date-picker-dialog"
                                    label={'Registration End Time'}
                                    value={registrationEndTime}
                                    disabled={true}
                                    invalidDateMessage={''}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    ) : (
                        <div className="grid-item  margin-top-20" />
                    )}

                    {/* row */}
                    {createdByClient ? (
                        <div className="grid-item  margin-top-20">
                            <Input
                                label="Created From"
                                type="text"
                                name="name"
                                value={createdByClient}
                                placeholder="Created From"
                                disabled={true}
                            />
                        </div>
                    ) : null}
                    {createdByClient ? <div className="grid-item  margin-top-20" /> : null}

                    {/* row */}
                    <div className="grid-item margin-top-20">
                        <Input
                            label="Filled Slots"
                            type="text"
                            name="filledSlots"
                            value={filledSlots}
                            onChange={this.handleInputChange.bind(this, 'filledSlots')}
                            placeholder="Filled Slots"
                            suffix={minParticipationRequired === true ? '/min ' + minFillSlots : null}
                            disabled={true}
                        />
                    </div>
                    <div className="grid-item margin-top-20">
                        <Input
                            label="Joined Slots"
                            type="text"
                            name="joinedSlots"
                            value={joinedSlots}
                            onChange={this.handleInputChange.bind(this, 'joinedSlots')}
                            placeholder="Joined Slots"
                            suffix={minParticipationRequired === true ? '/min ' + minFillSlots : null}
                            disabled={true}
                        />
                    </div>

                    {/* row3 */}
                    <div className="grid-item  margin-top-20">
                        <DropDown
                            label="Match Access"
                            type="text"
                            menuItems={matchAccessOptions}
                            name="matchAccess"
                            value={matchAccess}
                            onChange={this.handleInputChange.bind(this, 'matchAccess')}
                            placeholder="Enter Match Access"
                            disabled={false}
                        />
                    </div>
                    <div className="grid-item margin-top-20 align-right">
                        <DropDown
                            label="Allow Incomplete Teams"
                            type="text"
                            menuItems={minParticipationRequiredOptions}
                            name="allowIncompleteTeams"
                            value={allowIncompleteTeams}
                            onChange={(val) => {
                                this.setState({allowIncompleteTeams: val});
                            }}
                            // onChange={this.handleInputChange.bind(this, "allowIncompleteTeams")}
                            placeholder="Select"
                            disabled={false}
                        />
                    </div>

                    {/* row */}
                    <div className="grid-item  margin-top-20">
                        <Input
                            label="Rank for sorting"
                            type="number"
                            name="rankForSorting"
                            value={rankForSorting}
                            onChange={this.handleInputChange.bind(this, 'rankForSorting')}
                            placeholder="Keep it 'empty' or 0 if no ranking is to be done"
                            disabled={false}
                        />
                    </div>
                    <div className="grid-item margin-top-20 align-right" />

                    <React.Fragment>
                        <div className="grid-item margin-top-10">
                            <Input
                                label="Room Credentials 1 name"
                                type="text"
                                name="cred1Name"
                                value={roomCredentials.cred1Name}
                                onChange={this.handleInputChange.bind(this, 'cred1Name')}
                                placeholder="Room Credentials 1 name"
                                disabled={false}
                            />
                        </div>
                        <div className="grid-item margin-top-10">
                            <Input
                                label="Room Credentials 1 value"
                                type="text"
                                name="cred1Val"
                                value={roomCredentials.cred1Val}
                                onChange={this.handleInputChange.bind(this, 'cred1Val')}
                                placeholder="Room Credentials 1 value"
                                disabled={false}
                            />
                        </div>
                        <div className="grid-item margin-top-10">
                            <Input
                                label="Room Credentials 2 name"
                                type="text"
                                name="cred2Name"
                                value={roomCredentials.cred2Name}
                                onChange={this.handleInputChange.bind(this, 'cred2Name')}
                                placeholder="Room Credentials 2 name"
                                disabled={false}
                            />
                        </div>
                        <div className="grid-item margin-top-10">
                            <Input
                                label="Room Credentials 2 value"
                                type="text"
                                name="cred2Val"
                                value={roomCredentials.cred2Val}
                                onChange={this.handleInputChange.bind(this, 'cred2Val')}
                                placeholder="Room Credentials 2 value"
                                disabled={false}
                            />
                        </div>
                    </React.Fragment>
                    {status === 'UPCOMING' || status === 'LIVE' ? (
                        <React.Fragment>
                            <div className="grid-item margin-top-10">
                                <Input
                                    label="Live Stream Link"
                                    type="text"
                                    name="liveStream"
                                    value={liveStream}
                                    onChange={this.handleInputChange.bind(this, 'liveStream')}
                                    placeholder="Enter Live Stream Link"
                                    disabled={false}
                                />
                            </div>
                        </React.Fragment>
                    ) : null}
                    {status === 'LIVE' && createdByClient === 'control_center' ? (
                        <React.Fragment>
                            <div className="grid-item margin-top-10">
                                <Input
                                    label="Results File Link"
                                    type="text"
                                    name="resultsFile"
                                    value={resultsFile}
                                    onChange={this.handleInputChange.bind(this, 'resultsFile')}
                                    placeholder="Results File Link"
                                    disabled={true}
                                />
                            </div>
                            <div className="grid-item margin-top-10">
                                <div>
                                    <span>Download the template for results file</span>
                                </div>
                                {/* <div style={{flex:1, textAlign:'center'}}>
                            <img src="https://image.flaticon.com/icons/png/128/109/109612.png" style={{height:'24px', marginTop:'10px'}} />
                        </div> */}
                                <div style={{flex: 1, textAlign: 'center', marginTop: '30px'}}>
                                    <Button onClick={this.downloadExcel.bind(this)} buttonText="Download" />
                                </div>
                            </div>
                            <div className="grid-item margin-top-10">
                                <FileInput
                                    label="Upload Your File"
                                    type="file"
                                    name="file"
                                    onChange={this.handleInputChange.bind(this, 'uploadFile')}
                                />
                            </div>
                        </React.Fragment>
                    ) : (
                        <div className="grid-item margin-top-10"></div>
                    )}

                    {/* rowheading */}
                    <div className="grid-item margin-top-20">
                        <span style={{color: '#ffc954'}}>Other Uneditable match details</span>
                    </div>
                    <div className="grid-item margin-top-20"></div>
                    {/* row2 */}
                    <div className="grid-item  margin-top-20">
                        <DropDown
                            label="Target Users by login status?"
                            type="text"
                            menuItems={booleanOptions}
                            name="targetUsersByLoginStatus"
                            value={targetUsersByLoginStatus}
                            onChange={this.handleInputChange.bind(this, 'targetUsersByLoginStatus')}
                            placeholder="Target Specific Users"
                            disabled={true}
                        />
                    </div>
                    <div className="grid-item  margin-top-20">
                        <DropDown
                            label="Target Users by join date"
                            type="text"
                            menuItems={booleanOptions}
                            name="targetUsersByJoinDate"
                            value={targetUsersByJoinDate}
                            onChange={this.handleInputChange.bind(this, 'targetUsersByJoinDate')}
                            placeholder="Target Specific Users"
                            disabled={true}
                        />
                    </div>
                    {targetUsersByLoginStatus ? (
                        <div className="grid-item  margin-top-20">
                            <DropDown
                                label="Target by Log In Status"
                                type="text"
                                menuItems={targetUsersFilter_loggedInOptions}
                                name="targetUsersFilter_loggedIn"
                                value={targetUsersFilter_loggedIn}
                                onChange={this.handleInputChange.bind(this, 'targetUsersFilter_loggedIn')}
                                placeholder="Target Specific Users"
                                disabled={true}
                            />
                        </div>
                    ) : null}
                    {targetUsersByJoinDate ? (
                        <div className="grid-item  margin-top-20">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DateTimePicker
                                    margin="normal"
                                    id="date-picker-dialog"
                                    label="Target if join date is greater than"
                                    value={new Date(targetUsersFilter_minJoinDate)}
                                    disabled={true}
                                    invalidDateMessage={''}
                                    onChange={this.handleInputChange.bind(this, 'targetUsersFilter_minJoinDate')}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    ) : null}
                    <div className="grid-item  margin-top-20">
                        <Input
                            label="Entry Fee Per Slot"
                            type="text"
                            name="entryFeePerSlot"
                            value={entryFeePerSlot}
                            onChange={this.handleInputChange.bind(this, 'entryFeePerSlot')}
                            placeholder="Please enter the entry Fee"
                            disabled={true}
                        />
                    </div>
                    <div className="grid-item  margin-top-20">
                        <Input
                            label="Total Number of Slots"
                            type="text"
                            name="totalSlots"
                            value={totalSlots}
                            onChange={this.handleInputChange.bind(this, 'totalSlots')}
                            placeholder="Please enter the number of slots"
                            disabled={true}
                        />
                    </div>

                    {/* row3 */}
                    <div className="grid-item  margin-top-20">
                        <DropDown
                            label="Type of Reward Pool"
                            type="text"
                            menuItems={winPoolTypeOptions}
                            name="winPoolType"
                            value={winPoolType}
                            onChange={this.handleInputChange.bind(this, 'winPoolType')}
                            placeholder="Please enter type of reward pool"
                            disabled={true}
                        />
                    </div>
                    {winPoolType === 'Fixed' ? (
                        <div className="grid-item  margin-top-20">
                            <Input
                                label="Enter the Pool Size"
                                type="text"
                                name="winFixedPoolSize"
                                value={winFixedPoolSize}
                                onChange={this.handleInputChange.bind(this, 'winFixedPoolSize')}
                                placeholder="Please enter reward pool size"
                                disabled={true}
                            />
                        </div>
                    ) : winPoolType === 'Percentage' ? (
                        <div className="grid-item  margin-top-20">
                            <Input
                                label="Enter the Pool Size"
                                type="text"
                                name="winPercentPoolSize"
                                value={winPercentPoolSize}
                                onChange={this.handleInputChange.bind(this, 'winPercentPoolSize')}
                                placeholder="Please enter reward pool size"
                                suffix="%"
                                disabled={true}
                            />
                        </div>
                    ) : (
                        <div className="grid-item  margin-top-20"></div>
                    )}

                    {/* row4 */}
                    <div className="grid-item  margin-top-20">
                        <DropDown
                            label="Game"
                            type="text"
                            menuItems={gameOptions}
                            name="game"
                            value={game}
                            onChange={this.handleInputChange.bind(this, 'game')}
                            placeholder="Select Game"
                            disabled={true}
                        />
                    </div>
                    {game !== undefined && game !== null && game !== '' ? (
                        <div className="grid-item  margin-top-20">
                            <DropDown
                                label="Match Type"
                                type="text"
                                menuItems={matchTypeOptions}
                                name="matchType"
                                value={matchType}
                                onChange={this.handleInputChange.bind(this, 'matchType')}
                                placeholder="Select Match Type"
                                disabled={true}
                            />
                        </div>
                    ) : (
                        <div className="grid-item  margin-top-20"></div>
                    )}
                    {/* { matchType !== undefined && matchType !=null && matchType !=="" ? */}
                    <React.Fragment>
                        {/* row 1 */}
                        <div className="grid-item  margin-top-20">
                            <DropDown
                                label="Entry Type"
                                type="text"
                                menuItems={entryFeeTypeOptions}
                                name="entryFeeType"
                                value={entryFeeType}
                                onChange={this.handleInputChange.bind(this, 'entryFeeType')}
                                placeholder="Select"
                                disabled={true}
                            />
                        </div>

                        {entryFeeType === 'Cash+Coin' ? (
                            <div className="grid-item margin-top-20 align-right">
                                <Input
                                    label="% FC Coin Discount"
                                    type="text"
                                    name="fcCoinDiscountPercent"
                                    value={fcCoinDiscountPercent}
                                    onChange={this.handleInputChange.bind(this, 'fcCoinDiscountPercent')}
                                    placeholder="% FC Coin Discount"
                                    suffix="%"
                                    disabled={true}
                                />
                            </div>
                        ) : (
                            <div className="grid-item margin-top-20 align-right"></div>
                        )}

                        {/* row 2 */}
                        <div className="grid-item margin-top-20 align-right">
                            <DropDown
                                label="Min Participation Required"
                                type="text"
                                menuItems={minParticipationRequiredOptions}
                                name="minParticipationRequired"
                                value={minParticipationRequired}
                                onChange={this.handleInputChange.bind(this, 'minParticipationRequired')}
                                placeholder="Select"
                                disabled={true}
                            />
                        </div>
                        {minParticipationRequired === true ? (
                            <div className="grid-item margin-top-20 align-right">
                                <Input
                                    label="Min Slots to be filled"
                                    type="text"
                                    name="minFillSlots"
                                    value={minFillSlots}
                                    onChange={this.handleInputChange.bind(this, 'minFillSlots')}
                                    placeholder="Enter the minimum slots"
                                    disabled={true}
                                />
                            </div>
                        ) : (
                            <div className="grid-item margin-top-20 align-right"></div>
                        )}

                        {/* row 3 */}
                        <div className="grid-item margin-top-20 align-right">
                            <DropDown
                                label="Win Reward Denomination"
                                type="text"
                                menuItems={winDenominationOptions}
                                name="winDenomination"
                                value={winDenomination}
                                onChange={this.handleInputChange.bind(this, 'winDenomination')}
                                placeholder="Select"
                                disabled={true}
                            />
                        </div>
                        <div className="grid-item  margin-top-20">
                            <DropDown
                                label="Match Mode"
                                type="text"
                                menuItems={matchModeOptions}
                                name="matchMode"
                                value={matchMode}
                                onChange={this.handleInputChange.bind(this, 'matchMode')}
                                placeholder="Select"
                                disabled={true}
                            />
                        </div>

                        {/* row 4 */}
                        <div className="grid-item margin-top-20 align-right">
                            <DropDown
                                label="Attribute 1"
                                type="text"
                                menuItems={attribute1Options}
                                name="attribute1"
                                value={attribute1}
                                onChange={this.handleInputChange.bind(this, 'attribute1')}
                                placeholder="Select"
                                disabled={true}
                            />
                        </div>

                        <div className="grid-item  margin-top-20">
                            <Input
                                label="Attribute 1 Value"
                                type="text"
                                name="attribute1Val"
                                value={attribute1Val}
                                onChange={this.handleInputChange.bind(this, 'attribute1Val')}
                                placeholder="Select"
                                disabled={true}
                            />
                        </div>

                        {/* row 5 */}
                        <div className="grid-item margin-top-20 align-right">
                            <DropDown
                                label="Attribute 2"
                                type="text"
                                menuItems={attribute2Options}
                                name="attribute2"
                                value={attribute2}
                                onChange={this.handleInputChange.bind(this, 'attribute2')}
                                placeholder="Select"
                                disabled={true}
                            />
                        </div>

                        <div className="grid-item  margin-top-20">
                            <Input
                                label="Attribute 2 Value"
                                type="text"
                                name="attribute2Val"
                                value={attribute2Val}
                                onChange={this.handleInputChange.bind(this, 'attribute2Val')}
                                placeholder="Select"
                                disabled={true}
                            />
                        </div>

                        {/* row 6 */}
                        <div className="grid-item  margin-top-20">
                            <DropDown
                                label="Reward Win Mode"
                                type="text"
                                menuItems={winTypeOptions}
                                name="winType"
                                value={winType}
                                onChange={this.handleInputChange.bind(this, 'winType')}
                                placeholder="Select"
                                disabled={true}
                            />
                        </div>
                        <div className="grid-item margin-top-20 align-right"></div>

                        {/* row 6 */}
                        {winType === 'Fixed' ? (
                            <div className="grid-item  margin-top-20">
                                <Input
                                    label="Winning Parameter e.g. Per Kill"
                                    type="text"
                                    name="winParameter"
                                    value={winParameter}
                                    onChange={this.handleInputChange.bind(this, 'winParameter')}
                                    placeholder="Winning Parameter"
                                    disabled={true}
                                />
                            </div>
                        ) : null}
                        {winType === 'Fixed' ? (
                            <div className="grid-item  margin-top-20">
                                <Input
                                    label="Reward per parameter e.g. 5"
                                    type="text"
                                    name="winParameterValue"
                                    value={winParameterValue}
                                    onChange={this.handleInputChange.bind(this, 'winParameterValue')}
                                    placeholder="Reward per parameter"
                                    disabled={true}
                                />
                            </div>
                        ) : null}

                        {winType === 'Distribution' ? (
                            <div className="grid-item  margin-top-20">Prize Pool Distribution Helper</div>
                        ) : null}
                        {winType === 'Distribution' ? <div className="grid-item  margin-top-20"></div> : null}

                        {/* row 7 */}
                        {winType === 'Distribution' ? (
                            <div className="grid-item margin-top-20">
                                <Input
                                    label="Handling Charges "
                                    type="text"
                                    name="handlingCharge"
                                    value={winSlots.handlingCharge}
                                    onChange={this.handleInputChange.bind(this, 'handlingCharge')}
                                    placeholder="Enter Handling Charges %"
                                    disabled={true}
                                    suffix="%"
                                />
                            </div>
                        ) : null}
                        {winType === 'Distribution' ? (
                            <div className="grid-item margin-top-20">
                                <Input
                                    label="# of Big Win Slots"
                                    type="text"
                                    name="numberOfBigWinTeams"
                                    value={numberOfBigWinTeams}
                                    onChange={this.handleInputChange.bind(this, 'numberOfBigWinTeams')}
                                    placeholder="Enter # of Big Win Slots"
                                    disabled={true}
                                />
                            </div>
                        ) : null}
                    </React.Fragment>
                    {/* : null} */}
                </div>
                {/* {matchType !== undefined && matchType !== null && matchType !== "" ?  */}
                <React.Fragment>
                    {/* row8 */}
                    <div className="card-grid-container nopadding ">{this.displayPrizes(numberOfBigWinTeams)}</div>

                    {/* row9 */}
                    <div className="grid-item card-grid-container 6">
                        {winType === 'Distribution' ? (
                            <React.Fragment>
                                <div className="grid-item margin-top-20">
                                    <Input
                                        label="Split Remaining"
                                        type="text"
                                        name="splitRemaining"
                                        value={winSlots.splitRemaining}
                                        onChange={this.handleInputChange.bind(this, 'splitRemaining')}
                                        placeholder="Enter Split Remaining"
                                        disabled={true}
                                        suffix="%"
                                    />
                                </div>
                                <div className="grid-item margin-top-20">
                                    <Input
                                        label="# of small win slots"
                                        type="text"
                                        name="smallWinSlots"
                                        value={winSlots.smallWinSlots}
                                        onChange={this.handleInputChange.bind(this, 'smallWinSlots')}
                                        placeholder="Enter # of small win slots"
                                        disabled={true}
                                    />
                                </div>
                            </React.Fragment>
                        ) : null}
                    </div>

                    {/* row10 */}
                    <div className="card-fullgrid-container margin-top-20">
                        <label for="matchRules">Match Rules</label>
                        <TextareaAutosize
                            className="margin-top-20"
                            aria-label="maximum height"
                            value={matchRules}
                            placeholder="Match Rules"
                            onChange={this.handleInputChange.bind(this, 'matchRules')}
                            disabled={true}
                        />
                    </div>

                    <span style={{color: '#ffc954', marginTop: 20}}>{'New match details'}</span>

                    <MatchDetailsV2
                        match={toPublishedMatch.editTOPublishedMatch}
                        onUpdateOrganizerId={this.onUpdateOrganizerId.bind(this)}
                    />
                </React.Fragment>
                {/* : null } */}
            </div>
        );
    }

    renderTabB() {
        const {participation} = this.state;
        const {toMatchWrite} = this.props?.login?.permissions || {};
        let teams = [];
        const columns = [
            {
                Header: 'User',
                accessor: 'user',
                Cell: (props) => <span>{props.value}</span>,
            },
            {
                Header: 'Player',
                accessor: 'player',
                Cell: (props) => <span>{props.value}</span>,
            },
            {
                Header: 'Level',
                accessor: 'level',
                Cell: (props) => <span>{props.value}</span>,
            },
            {
                Header: 'Game ID',
                accessor: 'gameId',
                Cell: (props) => <span>{props.value}</span>,
            },
            {
                Header: 'Match Status',
                accessor: 'matchStatus',
                Cell: (props) => <span>{props.value}</span>,
            },
            {
                Header: 'Payment Status',
                accessor: 'paymentStatus',
                Cell: (props) => <span>{props.value}</span>,
            },
            {
                Header: 'Remove',
                accessor: 'player',
                show: !!toMatchWrite,
                Cell: (props) => (
                    <span>
                        {props.row.level === 'player' ? (
                            <Button
                                onClick={() => {
                                    this.setState({
                                        openRemoveTeamMemberModal: true,
                                        playerToRemove: props.value,
                                    });
                                }}
                                buttonText="Remove"
                            />
                        ) : null}
                    </span>
                ),
            },
        ];
        for (var i = 0; i < participation.length; i++) {
            teams.push(
                <React.Fragment>
                    <div className="table-cell" style={{marginTop: 10}}>
                        <div className="card-grid-container new">
                            <div className="grid-item margin-top-10">
                                <span>Team Name: {participation[i].originalTeam.teamName}</span>
                            </div>
                            <div className="grid-item margin-top-10">
                                <span>Team ID: {participation[i].originalTeam.id}</span>
                            </div>
                            <div className="grid-item margin-top-10">
                                <span>
                                    Paid Slots:{' '}
                                    {
                                        participation[i].teamParticipation.filter(
                                            (item) => item.paymentStatus === 'paid',
                                        ).length
                                    }
                                </span>
                            </div>
                            <div className="grid-item margin-top-10">
                                <span>
                                    Joined Slots:{' '}
                                    {
                                        participation[i].teamParticipation.filter(
                                            (item) => item.matchStatus === 'joined',
                                        ).length
                                    }
                                </span>
                            </div>
                            <div className="grid-item margin-top-10">
                                <span>Game Slot Number: {participation[i].gameSlotNumber}</span>
                            </div>
                        </div>
                        <SmallTable
                            data={participation[i].teamParticipation}
                            columns={columns}
                            page={1}
                            onPrevClick={() => {}}
                            onNextClick={() => {}}
                            disableNext={false}
                            pageSize={participation[i].teamParticipation.length}
                            removePagination={true}
                        ></SmallTable>
                    </div>
                    {i === participation.length - 1 ? <div className="bottom"></div> : null}
                </React.Fragment>,
            );
        }

        return (
            <div>
                {this.props.login.userDetails.data.role === 'SUPER_ADMIN' && (
                    <Button onClick={this.downloadMatchDetails.bind(this)} buttonText="Download Match Details" />
                )}
                {teams}
                <Modal open={this.state.openRemoveTeamMemberModal}>
                    <div style={{flex: 1, marginTop: 100, width: 500, marginLeft: 400}}>
                        <Card>
                            <div className="disp-flex">
                                <div />
                                <div onClick={() => this.setState({openRemoveTeamMemberModal: false})}>
                                    <img src={Close} alt="" className="cursor" />
                                </div>
                            </div>
                            <div className="align-center">
                                <div />
                                <div className="modal-center-header">Enter TeamId to confirm</div>
                                <div style={{width: 300, marginLeft: 100}}>
                                    <Input
                                        label="Team Id"
                                        type="text"
                                        name="name"
                                        value={this.state.teamIdToRemovePlayerFrom}
                                        onChange={(val) => this.setState({teamIdToRemovePlayerFrom: val})}
                                        placeholder="Team Id"
                                        disabled={false}
                                    />
                                </div>
                                <div>
                                    <div className="disp-flex align-center margin-top-30">
                                        <div className="margin-right-16">
                                            <Button
                                                buttonText="Remove Member"
                                                onClick={this.onRemoveMember.bind(this)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                </Modal>
            </div>
        );
    }

    async onRemoveMember() {
        try {
            let teamId = this.state.teamIdToRemovePlayerFrom;
            let playerPhone = this.state.playerToRemove;
            let matchId = this.props.toPublishedMatch.editTOPublishedMatch.id;

            if (!teamId) {
                let msg = 'TeamId is mandatory!';
                console.log(msg);
                this.setState({
                    snackbarOpen: true,
                    errorMessage: msg,
                    loading: false,
                });
                return;
            }

            if (!playerPhone || !matchId) {
                let msg = 'Unexpected error: playerPhone, matchId not found!';
                console.log(msg);
                this.setState({
                    snackbarOpen: true,
                    errorMessage: msg,
                    loading: false,
                });
                return;
            }

            //axios
            this.setState({loading: true});
            let axiosConfig = {
                headers: {
                    Authorization: 'Bearer ' + this.props.login.userDetails.data.accessToken,
                },
            };
            let req = {originalTeamId: teamId.trim(), matchId: matchId, phoneNumber: playerPhone};
            // console.log("removeTeamMember req ", JSON.stringify(req));

            let response = await axios.post(`${TOURL}/match/removeTeamMember`, req, axiosConfig);

            console.log('removeTeamMember response ', JSON.stringify(response));
            if (!response.data || response.data.status !== 'success') {
                if (response.data.status === 'fail') {
                    this.setState({
                        snackbarOpen: true,
                        errorMessage: 'Request failed on server!',
                        openRemoveTeamMemberModal: false,
                    });
                } else {
                    this.setState({
                        snackbarOpen: true,
                        errorMessage: 'Could not get expected response from server!',
                        openRemoveTeamMemberModal: false,
                    });
                }
            }

            //refresh teams' list
            this.props.getTOMatchPartipation({matchId: matchId}, this.props.login.userDetails.data.accessToken);
            this.setState({loading: false, openRemoveTeamMemberModal: false});
        } catch (err) {
            console.error('removeTeamMember err: ', err);
            const errorMsg = err?.response?.data?.msg || err.message;
            this.setState({
                snackbarOpen: true,
                errorMessage: errorMsg || 'Something went wrong',
                loading: false,
            });
        }
    }

    renderTabC() {
        /* set up an async GET request with axios */
        return (
            <React.Fragment>
                <div className="table-cell">
                    <SmallTable
                        data={this.state.resultData}
                        columns={this.state.resultColumns}
                        page={1}
                        onPrevClick={() => {}}
                        onNextClick={() => {}}
                        disableNext={false}
                        pageSize={this.state.resultData.length}
                        removePagination={true}
                    ></SmallTable>
                </div>
                <div className="bottom"></div>
            </React.Fragment>
        );
    }

    renderEditTeamTab() {
        const {status, matchAccess, addTeamOldMatchId} = this.state;
        const {toMatchWrite} = this.props?.login?.permissions || {};

        if (status != 'DRAFT' && status != 'UPCOMING') {
            return (
                <div className="grid-item margin-top-20">
                    <span style={{color: '#ffc954'}}>Match status must be DRAFT or UPCOMING!</span>
                </div>
            );
        }

        if (matchAccess != 'PRIVATE') {
            return (
                //createAndAddTeams
                <div
                    className="grid-item margin-top-20"
                    style={{
                        flex: 1,
                        alignContent: 'center',
                        marginTop: '100px',
                        border: '2px solid #ffc954',
                        padding: 15,
                    }}
                >
                    <span style={{color: '#ffc954', fontSize: 20, marginLeft: 50}}>Create Teams and Add to Match</span>

                    <div className="grid-item margin-top-20">
                        <FileInput
                            label="Create Teams and Add to Match: Upload File"
                            type="file"
                            name="create_and_add_teams"
                            onChange={(value) => this.setState({createAndAddTeamsFile: value})}
                        />
                    </div>
                    {!!toMatchWrite && (
                        <Button
                            onClick={this.uploadAndCallCreateAndAddTeamsToMatch.bind(this)}
                            buttonText="Submit & Download Results"
                        />
                    )}
                </div>
            );
        }

        return (
            <div>
                {!!toMatchWrite && (
                    <Button onClick={this.downloadMatchDetails.bind(this)} buttonText="Download Match Details" />
                )}
                {/*add team */}
                <div className="grid-item margin-top-20" style={{border: '2px solid #ffc954', padding: 15}}>
                    <span style={{color: '#ffc954', fontSize: 20, marginLeft: 50}}>Add Teams</span>
                    <Input
                        label="Enter Old Match Id"
                        type="text"
                        name="cred1Name"
                        value={addTeamOldMatchId}
                        onChange={(value) => this.setState({addTeamOldMatchId: value})}
                        placeholder="Old Team Id"
                        disabled={false}
                    />
                    <div className="grid-item margin-top-20">
                        <FileInput
                            label="Add Teams: Upload File"
                            type="file"
                            name="add_teams"
                            onChange={(value) => this.setState({addTeamsFile: value})}
                        />
                    </div>
                    {!!toMatchWrite && (
                        <Button
                            onClick={this.uploadAndCallAddTeamsToMatch.bind(this)}
                            buttonText="Submit & Download Results"
                        />
                    )}
                </div>

                {/*remove team */}
                <div
                    className="grid-item margin-top-20"
                    style={{
                        flex: 1,
                        alignContent: 'center',
                        marginTop: '100px',
                        border: '2px solid #ffc954',
                        padding: 15,
                    }}
                >
                    <span style={{color: '#ffc954', fontSize: 20, marginLeft: 50}}>Remove Teams</span>

                    <div className="grid-item margin-top-20">
                        <FileInput
                            label="Remove Teams: Upload File"
                            type="file"
                            name="remove_teams"
                            onChange={(value) => this.setState({removeTeamsFile: value})}
                        />
                    </div>
                    {!!toMatchWrite && (
                        <Button
                            onClick={this.uploadAndCallRemoveTeamsFromMatch.bind(this)}
                            buttonText="Submit & Download Results"
                        />
                    )}
                </div>
            </div>
        );
    }

    renderSubmittedResultsTab() {
        return (
            <div>
                <UpdateMatchResults />
            </div>
        );
    }

    render() {
        // console.log("toPublishedMatch ", JSON.stringify(this.props.toPublishedMatch));
        const {
            name,
            startTime,
            entryFeePerSlot,
            totalSlots,
            minFillSlots,
            winPoolType,
            winPoolTypeOptions,
            matchTypeOptions,
            matchType,
            winFixedPoolSize,
            winPercentPoolSize,
            entryFeeType,
            entryFeeTypeOptions,
            fcCoinDiscountPercent,
            minParticipationRequired,
            minParticipationRequiredOptions,
            numberOfBigWinTeams,
            winDenomination,
            winDenominationOptions,
            winType,
            winTypeOptions,
            winParameter,
            winParameterValue,
            winSlots,
            attribute1,
            attribute1Options,
            attribute1Val,
            attribute2,
            attribute2Options,
            attribute2Val,
            matchMode,
            matchModeOptions,
            matchRules,
            status,
            statusOptions,
            game,
            gameOptions,
            handlingCharge,
            resultsFile,
            errorMessage,
            loading,
            open,
            edit,
            snackbarOpen,
        } = this.state;
        const {history, toPublishedMatch, toMatchType, login} = this.props;
        const {createdByClient} = toPublishedMatch.editTOPublishedMatch;
        const {toMatchWrite} = login?.permissions || {};
        let title = 'Edit Match';
        let shrink = undefined;
        return (
            <DetailLayout
                location="EditTOPublishedMatch"
                history={history}
                centerTitle={title}
                onSave={!!toMatchWrite ? this.onSave.bind(this) : undefined}
                onPublish={
                    !!toMatchWrite
                        ? status === 'UPCOMING'
                            ? this.onSave.bind(this, 'LIVE')
                            : this.onSave.bind(this, 'COMPLETED')
                        : undefined
                }
                matchStatus={status}
                editPage={edit}
                id={edit ? toPublishedMatch.editTOPublishedMatch.id : null}
                onDeleteClick={!!toMatchWrite ? this.onCancel.bind(this) : undefined}
            >
                <LoadingModal open={loading} />
                <Modal open={open}>
                    <div className="container modal-top-margin">
                        <Card>
                            <div className="disp-flex">
                                <div className="modal-left-header">Cancel Match</div>
                                <div onClick={() => this.setState({open: false})}>
                                    <img src={Close} alt="" className="cursor" />
                                </div>
                            </div>
                            <div className="align-center equalcontainer-grids">
                                <div />
                                <div>
                                    <div className="disp-flex align-center margin-top-30">
                                        <div className="margin-right-16">
                                            <Button
                                                buttonText="Cancel"
                                                onClick={this.onCancelTOPublishedMatch.bind(this)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                </Modal>

                <Snackbar
                    open={snackbarOpen}
                    message={errorMessage}
                    onClose={() => this.setState({snackbarOpen: false})}
                />
                <div className="detail-container margin-top-20">
                    <div className="grid-item"></div>
                    <div className="grid-item">
                        <Card>
                            <div>
                                <div className="card-single-grid-container">
                                    {createdByClient === 'organizer_app' ? (
                                        <Tabs
                                            tab1Label="Summary"
                                            tab2Label="Teams"
                                            tab3Label="Submitted Results"
                                            tab4Label="Edit Teams"
                                            panelA={this.renderTabA.bind(this)}
                                            panelB={this.renderTabB.bind(this)}
                                            panelC={this.renderSubmittedResultsTab.bind(this)}
                                            panelD={this.renderEditTeamTab.bind(this)}
                                            disableTab1={false}
                                            disableTab2={false}
                                            disableTab3={false}
                                            disableTab4={false}
                                        />
                                    ) : resultsFile === '' || resultsFile === null || resultsFile === undefined ? (
                                        <Tabs
                                            tab1Label="Summary"
                                            tab2Label="Teams"
                                            tab3Label="Edit Teams"
                                            panelA={this.renderTabA.bind(this)}
                                            panelB={this.renderTabB.bind(this)}
                                            panelC={this.renderEditTeamTab.bind(this)}
                                            disableTab1={false}
                                            disableTab2={false}
                                            disableTab3={false}
                                        />
                                    ) : (
                                        <Tabs
                                            tab1Label="Summary"
                                            tab2Label="Teams"
                                            tab3Label="Results"
                                            panelA={this.renderTabA.bind(this)}
                                            panelB={this.renderTabB.bind(this)}
                                            panelC={this.renderTabC.bind(this)}
                                            disableTab1={false}
                                            disableTab2={false}
                                            disableTab3={false}
                                        />
                                    )}
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
            </DetailLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        toGame: state.toGame,
        toPublishedMatch: state.toPublishedMatch,
        toMatch: state.toMatch,
        toMatchType: state.toMatchType,
        toMatchMode: state.toMatchMode,
        toMatchAttribute: state.toMatchAttribute,
    };
};

export default connect(mapStateToProps, {
    getTOGameList,
    getTOMatchTypeList,
    getTOMatchAttributeList,
    getTOMatchModeList,
    modifyTOMatch,
    logout,
    cancelTOMatch,
    getTOMatchPartipation,
})(EditTOPublishedMatch);
