import React from 'react';
import {FormControl, styled, TextField} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';
import {Cancel} from '@material-ui/icons';
import {ColorPalette} from '../../Utilities/constants/color-constants';

const MuiAutocompleteField = (props) => {
    const {className, disabled, fullWidth, id, label, onChangeField, onInputChange, options, required, size, value} =
        props;

    return (
        <CustomFormControl className={className} fullWidth={fullWidth} required={required} disabled={disabled}>
            <Autocomplete
                closeIcon={<Cancel />}
                getOptionLabel={(option) => option.label || ''}
                id={id}
                onChange={(_, value) => onChangeField(value)}
                onInputChange={(event, value) => onInputChange(event, value)}
                options={options}
                renderInput={(params) => <TextField {...params} variant="outlined" label={label} size={size} />}
                // value={options.find((option) => option.value === value) || null}
                value={value}
            />
        </CustomFormControl>
    );
};

MuiAutocompleteField.defaultProps = {
    disabled: false,
    fullWidth: true,
    required: true,
    size: 'medium',
};

export default MuiAutocompleteField;

const CustomFormControl = styled(FormControl)({
    '& .MuiAutocomplete-root': {
        '& .MuiFormControl-root': {
            '& .MuiFormLabel-root': {
                color: ColorPalette.SALOME,

                '&.Mui-focused': {
                    color: ColorPalette.SELECTIVE_YELLOW,
                },
            },

            '& .MuiOutlinedInput-root': {
                '& .MuiAutocomplete-endAdornment': {
                    top: 'unset',
                },

                '& fieldset': {
                    borderColor: ColorPalette.SALOME,
                    borderRadius: '8px',
                },

                '&.Mui-focused fieldset': {
                    borderColor: ColorPalette.SELECTIVE_YELLOW,
                },
            },
        },
    },
});
