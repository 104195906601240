import {
    FETCH_USER_KYC_LIST,
    FETCH_USER_KYC_LIST_SUCCESS,
    FETCH_USER_KYC_LIST_FAIL,
    FETCH_USER_BANK_KYC_LIST,
    FETCH_USER_BANK_KYC_LIST_SUCCESS,
    FETCH_USER_BANK_KYC_LIST_FAIL,
} from './Constants';
import apis from '../../../apiCalls/apis';

export const fetchUserKycList = (data, token, clientId) => async (dispatch) => {
    dispatch({type: FETCH_USER_KYC_LIST});
    try {
        const response = await apis.userKycList(data, token, clientId);
        if (response.status === 200) {
            dispatch({type: FETCH_USER_KYC_LIST_SUCCESS, payload: response.data});
        } else {
            dispatch({
                type: FETCH_USER_KYC_LIST_FAIL,
                payload: {msg: response?.data?.msg || 'Something went wrong'},
            });
        }
    } catch (e) {
        if (e.response) {
            dispatch({
                type: FETCH_USER_KYC_LIST_FAIL,
                payload: {msg: e?.response?.data?.msg || 'Something went wrong'},
            });
        } else {
            dispatch({
                type: FETCH_USER_KYC_LIST_FAIL,
                payload: {msg: e?.message || 'Unknown Error Occured'},
            });
        }
    }
};

export const fetchUserBankKycList = (data, token, clientId) => async (dispatch) => {
    dispatch({type: FETCH_USER_BANK_KYC_LIST});
    try {
        const response = await apis.userBankList(data, token, clientId);
        if (response.status === 200) {
            dispatch({
                type: FETCH_USER_BANK_KYC_LIST_SUCCESS,
                payload: response.data,
            });
        } else {
            dispatch({
                type: FETCH_USER_BANK_KYC_LIST_FAIL,
                payload: {msg: response?.data?.msg || 'Something went wrong'},
            });
        }
    } catch (e) {
        if (e.response) {
            dispatch({
                type: FETCH_USER_BANK_KYC_LIST_FAIL,
                payload: {msg: e?.response?.data?.msg || 'Something went wrong'},
            });
        } else {
            dispatch({
                type: FETCH_USER_BANK_KYC_LIST_FAIL,
                payload: {msg: e?.message || 'Unknown Error Occured'},
            });
        }
    }
};
