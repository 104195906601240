import {SELECT_CLIENT} from './constants';

const initialState = {
    selectedClient: 'client_unicorn',
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SELECT_CLIENT:
            return {
                ...state,
                selectedClient: action.payload,
            };
        default:
            return state;
    }
};

export default reducer;
