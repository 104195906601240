import React from 'react';
import '../../../Utilities/style.css';
import '../Login/login.css';
import TableLayout from '../../Layouts/TableLayout/TableLayout';
import Table from '../../../Components/Table/Table';

import {connect} from 'react-redux';
import {next, prev, reset} from '../../../Utilities/HelperFunctions';
import Snackbar from '../../../Components/Snackbar/Snackbar';
import {logout} from '../Login/LoginAction';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';
import {timeFormat} from '../../../Utilities/Helpers';
import {getFantasyScoreModelsList, deleteFantasyScoreModel} from './FantasyAxios';
import {editScoring} from './FantasyConfigAction';

class ListFantasyScoringModel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            models: [],
            selectAll: false,
            list: [],
            open: false,
            adminpswd: '',
            disableNext: false,
            pageData: {},
            loading: true,
            pageOptions: {
                page: 1,
                pageSize: 10,
                searchString: null,
                sortOrder: null,
            },
            sortOptions: [
                {value: 'id', text: 'model Id'},
                {value: 'name', text: 'model name'},
            ],
        };
    }

    componentWillMount() {
        const {login, history} = this.props;
        if (!login.userDetails.data) {
            history.push('/');
            this.props.logout();
        } else {
            const {pageOptions} = this.state;
            this.props.getFantasyScoreModelsList(pageOptions, login.userDetails.data.accessToken);
        }
    }

    componentDidUpdate(prevProps) {
        const {pageSize, page} = this.state;
        const {login, fantasyConfig} = this.props;

        let paginationOptions = {
            page: page,
            pageSize: pageSize,
        };

        if (fantasyConfig.scoringListSuccess && !prevProps.fantasyConfig.scoringListSuccess) {
            this.setState({
                list: fantasyConfig.scoringList.data.data,
                pageData: fantasyConfig.scoringList.data.pageData,
                loading: false,
            });
        } else if (fantasyConfig.scoringListFail && !prevProps.fantasyConfig.scoringListFail) {
            this.setState({errorMessage: fantasyConfig.errorMessage, loading: false});
        }

        if (fantasyConfig.deleteScoringSuccess && !prevProps.fantasyConfig.deleteScoringSuccess) {
            this.props.getFantasyScoreModelsList(paginationOptions, login.userDetails.data.accessToken);
            this.setState({
                errorMessage: fantasyConfig.deleteScoring.msg,
                models: [],
                loading: false,
            });
        } else if (fantasyConfig.deleteScoringFail && !prevProps.fantasyConfig.deleteScoringFail) {
            this.setState({errorMessage: fantasyConfig.errorMessage, loading: false});
        }
    }

    /**
     * api call on click of next
     */
    onNext() {
        const {pageData, pageOptions} = this.state;
        const {login} = this.props;
        let options = next(pageOptions, pageData);

        if (options) {
            this.props.getFantasyScoreModelsList(options, login.userDetails.data.accessToken);
            this.setState({
                loading: true,
                pageOptions: options,
            });
        }
    }

    /**
     * api call on click of prev
     */
    onPrev() {
        const {pageOptions} = this.state;
        const {login} = this.props;
        let options = prev(pageOptions);
        if (options) {
            this.props.getFantasyScoreModelsList(options, login.userDetails.data.accessToken);
            this.setState({
                loading: true,
                pageOptions: options,
            });
        }
    }

    /**
     * reseting search and sort
     */
    onReset() {
        const {pageOptions} = this.state;
        const {login} = this.props;
        let options = reset(pageOptions);
        this.setState({
            pageOptions: options,
        });

        this.props.getFantasyScoreModelsList(options, login.userDetails.data.accessToken);
    }

    /**
     * navigate to selected score model page with details
     * @param {string} id id of model selected
     */
    editModelData(id) {
        const {history, fantasyConfig} = this.props;
        let models = fantasyConfig?.scoringList?.data?.data;
        const editModel = models.filter((model) => model.id === id);
        this.props.editScoring(editModel[0]);
        history.push('/editFantasyScoringModel/' + id);
    }

    /**
     * delete selected model
     */
    onDeleteModel() {
        const {login} = this.props;
        this.props.deleteFantasyScoreModel(this.state.models[0].id, login.userDetails.data.accessToken);
        this.setState({
            open: false,
            loading: true,
        });
    }

    /**
     * APi call with search and sort strings
     * @param {string} field field names of input
     * @param {string} value value in the field
     */
    onSearchandSort(field, value) {
        const {pageOptions} = this.state;
        const {login} = this.props;
        let options = pageOptions;
        options[field] = value;
        this.setState({pageOptions: options});

        if (field === 'searchString' && value.length > 2) {
            this.props.getFantasyScoreModelsList(options, login.userDetails.data.accessToken);
        } else if (field === 'sortOrder') {
            this.props.getFantasyScoreModelsList(options, login.userDetails.data.accessToken);
        }
    }

    render() {
        const {list, errorMessage, loading, pageData, models, sortOptions} = this.state;
        const {searchString, sortOrder, page} = this.state.pageOptions;
        const {history} = this.props;
        const data = list;
        let disableNext = page === pageData.lastPage;

        const columns = [
            {
                Header: 'Last Updated',
                accessor: 'updatedAt',
                width: 100,
                Cell: (props) => (
                    <span className="number date-space">
                        {props.value === undefined || props.value === '' ? '-' : timeFormat(props.value)}
                    </span>
                ),
            },
            {
                Header: 'Model ID',
                accessor: 'id',
                width: 200,
                Cell: (props) => (
                    <span className="table-clickable cursor" onClick={this.editModelData.bind(this, props.value)}>
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Name',
                accessor: 'name',
                width: 150,
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'game',
                accessor: 'game.gameName',
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'matchMode',
                accessor: 'matchMode.modeName',
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'status',
                accessor: 'status',
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
        ];
        return (
            <TableLayout
                location="FantasyScoringModels"
                history={history}
                searchbar="Search model name"
                dispDelete={models.length === 1 ? true : false}
                onDeleteClick={this.onDeleteModel.bind(this)}
                onsearchChange={this.onSearchandSort.bind(this)}
                sortOptions={sortOptions}
                onReset={this.onReset.bind(this)}
                searchString={searchString}
                sortOrder={sortOrder}
            >
                {!!loading && <LoadingModal open={loading} />}
                {!!errorMessage && (
                    <Snackbar
                        open={!!errorMessage}
                        message={errorMessage}
                        onClose={() => this.setState({errorMessage: ''})}
                    />
                )}

                <Table
                    data={data}
                    columns={columns}
                    page={page}
                    onPrevClick={this.onPrev.bind(this)}
                    onNextClick={this.onNext.bind(this)}
                    disableNext={disableNext}
                ></Table>
            </TableLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        fantasyConfig: state.fantasyConfig,
    };
};

export default connect(mapStateToProps, {
    getFantasyScoreModelsList,
    editScoring,
    deleteFantasyScoreModel,
    logout,
})(ListFantasyScoringModel);
