import moment from 'moment';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import {EXCEL_EXTENSION, EXCEL_TYPE, ROLES} from './Constants';

export function isUserAllowedAccess(userData = {}, permission = '') {
    // let userDetails = JSON.parse(localStorage.getItem('persist:root') || "{}");
    // userDetails = userDetails?.login?.userDetails?.data || {}
    const accessLevelSet = new Set(userData?.accessLevel || []);
    if (userData.role === ROLES.ADMIN) {
        if (permission === "ADMIN")
        {
            return true;
        }
        return accessLevelSet.has(permission)
    }else{
        return true
    }
}

export function next(pageOptions, pageData) {
    let lastPage = pageData.lastPage;
    if (pageOptions.page === lastPage || lastPage === 0) {
        return false;
    } else {
        pageOptions.page = pageOptions.page + 1;
        return pageOptions;
    }
}

export function prev(pageOptions) {
    if (pageOptions.page > 1) {
        pageOptions.page = pageOptions.page - 1;
        return pageOptions;
    } else {
        return false;
    }
}

export function reset(pageOptions) {
    pageOptions.page = 1;
    pageOptions.sortOrder = '';
    pageOptions.searchString = '';
    return pageOptions;
}

export function timeLeft(time) {
    const today = moment();
    const matchdate = moment(time);
    const totalHours = matchdate.diff(today, 'hours');
    const totalminutes = matchdate.diff(today, 'minutes');

    const days = Math.floor(totalHours / 24);
    const hours = totalHours % 24;
    const mins = totalminutes % 60;
    return days + ' days ' + hours + ' hrs ' + mins + ' minutes';
}

export const convertXlsxToJson = (file, callBackFun) => {
    if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, {type: 'array'});
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const json = XLSX.utils.sheet_to_json(worksheet);
            callBackFun(json);
        };
        reader.readAsArrayBuffer(file);
    }
};

export const downloadXLSXFromJson = (filename = '', json = []) => {
    // convert JSON to XLSX
    const worksheet = XLSX.utils.json_to_sheet(json);
    const workbook = {
        Sheets: {data: worksheet},
        SheetNames: ['data'],
    };
    const excelBuffer = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
    });

    const data = new Blob([excelBuffer], {type: EXCEL_TYPE});
    FileSaver.saveAs(data, filename + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
};

export const debounce = (func, wait = 1200) => {
    let timeout;
    return (args = {}) => {
        const timeOutCallBack = () => {
            clearTimeout(timeout);
            func(args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(timeOutCallBack, wait);
        if (args && args.immediate) timeOutCallBack();
    };
};

export const getQueryString = (params) => {
    let query = Object.keys(params).filter(
        (key) => params[key] !== undefined && params[key] !== null && params[key] !== '',
    );
    return query.map((key) => key + '=' + params[key]).join('&');
};

export const jsonToForm = (data) => {
    const form = new FormData();
    const keys = Object.keys(data);
    const values = Object.values(data);

    // eslint-disable-next-line array-callback-return
    keys.map((key, i) => {
        if (data[key] !== undefined && data[key] !== null && data[key] !== '') {
            form.append(key, values[i]);
        }
    });
    return form;
};

export const copyText = (text, onSuccess, onError) => {
    navigator.clipboard
        .writeText(text)
        .then(() => {
            onSuccess(`Copied: ${text}`);
        })
        .catch((err) => {
            onError(err.toString());
        });
};

export const errorReasonHandler = (errorReason) => {
    if (typeof errorReason === 'object') return errorReason.code || 'Something went wrong';
    return errorReason;
};

export const getMessagesFromApi = (data = {}, err) => {
    let msg = err?.message || 'Something Went Wrong';
    if (data?.error) msg = data?.error;
    else if (data?.fieldErrors && data?.fieldErrors?.length) {
        const temp = data?.fieldErrors?.map(
            (err) => `${err?.error}${err?.field ? `, ${err?.field}` : ''}${err?.value ? `, ${err?.value}` : ''}`,
        );
        msg = temp?.join('; ');
    }
    return msg;
};

export const commaSeparatedStringToArray = (str = '') => {
    const stringList = str.split(',');
    return stringList.map((_str) => _str.replaceAll(' ', ''));
};

export const getSessionStorageItem = (key, defaultValue = '{}') => {
    try {
        return JSON.parse(sessionStorage.getItem(key) || defaultValue);
    } catch (e) {
        return JSON.parse(defaultValue);
    }
};

export const setSessionStorageItem = (key, value = {}) => {
    try {
        return sessionStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
        return null;
    }
};

export const getFiltersSessionStorageItem = (key) => {
    const storageItem = getSessionStorageItem('SCREEN_FILTERS') || {};
    return storageItem[key] || {};
};

export const setFiltersSessionStorageItem = (key, value = {}) => {
    if (typeof value === 'object' && !Array.isArray(value)) {
        const storageItem = getSessionStorageItem('SCREEN_FILTERS') || {};
        storageItem[key] = value;
        return setSessionStorageItem('SCREEN_FILTERS', storageItem);
    }
};
