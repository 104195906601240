import React from 'react';
import moment from 'moment';
import {timeFormat} from '../../../Utilities/Helpers';
import '../../../Utilities/style.css';
import '../Login/login.css';
import TableLayout from '../../Layouts/TableLayout/TableLayout';
import Table from '../../../Components/Table/Table';
import {
    debounce,
    getFiltersSessionStorageItem,
    next,
    prev,
    setFiltersSessionStorageItem,
    timeLeft,
} from '../../../Utilities/HelperFunctions';

import {connect} from 'react-redux';

import {getMatchList, deleteMatch} from './AxioCalls';
import {editMatch} from './action';
import Snackbar from '../../../Components/Snackbar/Snackbar';
import {logout} from '../Login/LoginAction';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';
import FilterInput from '../../../Components/FilterInput';
import DropDown from '../../../Components/Dropdown/Dropdown';
import Button from '../../../Components/Button/Button';
import DateRangeSelect from '../../../Components/DateRangeSelect';
import {ENTER, MATCH_STATUS, MATCH_TYPES_OPTIONS, SCREEN_NAMES} from '../../../Utilities/Constants';
import {Checkbox, FormControlLabel} from '@material-ui/core';

class MatchList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            match: [],
            selectAll: false,
            list: [],
            open: false,
            adminpswd: '',
            pageData: {},
            loading: false,
            pageOptions: {
                page: 1,
                pageSize: 25,
                listType: 'ALL',
                ...getFiltersSessionStorageItem(SCREEN_NAMES.MATCHES),
            },
            sortOptions: [
                {value: 'id', text: 'Match Id'},
                {value: 'tournamentName', text: 'Tournament Name'},
                {value: 'matchName', text: 'Match Name'},
                {value: 'startTime', text: 'Start Time'},
                {value: 'mapName', text: 'Map Name'},
                {text: 'Status', value: 'status'},
            ],
        };
    }

    componentWillMount() {
        const {login, history} = this.props;
        if (!login?.userDetails?.data) {
            history.push('/');
            this.props.logout();
        } else {
            this.getMatchListDebounce({immediate: true});
        }
    }

    componentDidUpdate(prevProps) {
        const {match} = this.props;

        if (match.matchListRequest && !prevProps.match.matchListRequest) {
            this.setState({loading: true});
        } else if (match.matchListSuccess && !prevProps.match.matchListSuccess) {
            this.setState({
                loading: false,
                list: match?.matchList?.data?.data,
                pageData: match?.matchList?.data?.pageData,
            });
        } else if (match.matchListFail && !prevProps.match.matchListFail) {
            this.setState({loading: false, errorMessage: match.errorMessage});
        }
        if (match.deleteMatchSuccess && !prevProps.match.deleteMatchSuccess) {
            this.setState({
                loading: false,
                match: [],
                errorMessage: match.deleteMatch.msg,
            });
            this.getMatchListDebounce({immediate: true});
        } else if (match.deleteMatchFail && !prevProps.match.deleteMatchFail) {
            this.setState({loading: false, errorMessage: match.matchList.errorMessage});
        }

        // if (client.selectedClient !== prevProps.client.selectedClient) {
        //     this.getMatchListDebounce({immediate: true});
        // }
    }

    /**
     * save details of match selected and navigate to add match screen
     * @param {string} id Id of match clicked on
     */
    editMatchData(id) {
        const {match, history} = this.props;
        let matches = match.matchList.data.data;
        const editmatch = matches.filter((match) => match.id === id);
        this.props.editMatch(editmatch[0]);
        history.push('/editMatch/' + id);
    }

    /**
     * API call to delete match
     */
    onDeleteMatch() {
        const {login, client} = this.props;
        let data = {
            matchIds: JSON.stringify(this.state.match),
        };
        this.props.deleteMatch(data, login.userDetails.data.accessToken, client.selectedClient);
        this.setState({open: false, loading: true});
    }

    /**
     * reseting search and sort
     */
    onReset = () => {
        this.setState({pageOptions: {page: 1, pageSize: 25, listType: 'ALL'}}, () => {
            this.getMatchListDebounce({immediate: true});
        });
    };

    getMatchListDebounce = debounce(() => {
        const {login, client} = this.props;
        setFiltersSessionStorageItem(SCREEN_NAMES.MATCHES, this.state.pageOptions);
        this.props.getMatchList(this.state.pageOptions, login.userDetails.data.accessToken, client.selectedClient);
    });

    handleFilter = (field, value, debounce = true) => {
        const {pageOptions} = this.state;
        if (field === ENTER) {
            this.getMatchListDebounce({immediate: true});
            return;
        }
        if (field === 'dates') {
            pageOptions.startDate = value.start;
            pageOptions.endDate = value.end;
        } else {
            pageOptions[field] = value;
        }
        this.setState(
            {
                pageOptions: {...pageOptions, page: 1},
            },
            () => {
                this.getMatchListDebounce(debounce ? {} : {immediate: true});
            },
        );
    };

    /**
     * pagination and api call on next click
     */
    onNext() {
        const {pageData, pageOptions} = this.state;
        let options = next(pageOptions, pageData);
        if (options) {
            this.setState({pageOptions: options}, () => this.getMatchListDebounce({immediate: true}));
        }
    }

    /**
     * pagination and api call on  prev click
     */
    onPrev() {
        const {pageOptions} = this.state;
        let options = prev(pageOptions);
        if (options) {
            this.setState({pageOptions: options}, () => this.getMatchListDebounce({immediate: true}));
        }
    }

    handleChange = (key, value) => {
        this.setState({[key]: value});
    };

    render() {
        const {list, loading, sortOptions, errorMessage, pageData} = this.state;
        const {
            matchName,
            matchId,
            tournamentName,
            tournamentId,
            sortOrder,
            page,
            gameName,
            startDate,
            endDate,
            status,
            matchType,
            isAllSuperMatch,
            isMatchOfTheDay,
        } = this.state.pageOptions;

        let disableNext = page === pageData?.lastPage;
        let date = new Date();
        let timestamp = moment(date).valueOf();

        const { history, login } = this.props;
        const { matchesMapName, matchesMatchType,matchesCreatedFrom } = login?.permissions;

        const data = list;
        const columns = [
            {
                Header: 'Match ID',
                accessor: 'id',
                width: 200,
                Cell: (props) => (
                    <span className="table-clickable" onClick={this.editMatchData.bind(this, props.value)}>
                        {props.value === undefined ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Tournament ID',
                accessor: 'tournament',
                width: 200,
                Cell: (props) => <span>{props.value || '-'}</span>,
            },
            {
                Header: 'Squad Names',
                accessor: 'squadDataList',
                width: 200,
                Cell: (props) => (
                    <span className="number">
                        {!!props.value && !!props.value.length
                            ? props.value.map((squad) => squad.squadName).join(', ')
                            : '-'}
                    </span>
                ),
            },
            {
                Header: 'Tournament Name',
                accessor: 'tournamentName',
                width: 150,
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Match Name',
                accessor: 'matchName',
                width: 200,
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Start Time',
                accessor: 'startTime',
                Cell: (props) => <span className="date-space">{timeFormat(props.value)}</span>,
            },
            {
                Header: 'Created from',
                accessor: 'createdByGlacier',
                show: !!matchesCreatedFrom,
                Cell: (props) => <span>{props.value ? 'Glacier' : 'Control-Center'}</span>,
            },
            { 
                Header: 'Map Name',
                accessor: 'mapName',
                show: !!matchesMapName,
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: '# Players',
                accessor: 'players',
                Cell: (props) => (
                    <span className="number">{props.value && props.value.length ? props.value.length : '-'}</span>
                ),
            },
            {
                Header: '# squads',
                accessor: 'squads',
                Cell: (props) => (
                    <span className="number">{props.value && props.value.length ? props.value.length : '-'}</span>
                ),
            },
            {
                Header: 'Teams',
                accessor: 'fcTeamsCount',
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Contests',
                accessor: 'contestsCount',
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Total Pool',
                accessor: 'totalPoolAmount',
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : '₹ ' + props.value}
                    </span>
                ),
            },
            {
                Header: 'Time Left',
                accessor: 'startTime',
                width: 200,
                Cell: (props) => (
                    <span className="number">
                        {props.value === '' ? '-' : timestamp > props.value ? '-' : timeLeft(props.value)}
                    </span>
                ),
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Match Type',
                accessor: 'matchType',
                show: !!matchesMatchType,
                Cell: (props) => <span>{props.value || ''}</span>,
            },
        ];
        return (
            <TableLayout location="Matches" history={history}>
                {!!loading && <LoadingModal open={loading} />}
                {!!errorMessage && (
                    <Snackbar
                        open={!!errorMessage}
                        message={errorMessage}
                        onClose={() => this.setState({errorMessage: ''})}
                    />
                )}
                <div className="filter-container my-10">
                    <FilterInput
                        placeholder="Match Name"
                        value={matchName || ''}
                        onChange={(value) => this.handleFilter('matchName', value)}
                        onEnter={() => this.handleFilter(ENTER)}
                    />
                    <FilterInput
                        placeholder="Match Id"
                        value={matchId || ''}
                        onChange={(value) => this.handleFilter('matchId', value)}
                        onEnter={() => this.handleFilter(ENTER)}
                    />
                    <FilterInput
                        placeholder="Tournament Name"
                        value={tournamentName || ''}
                        onChange={(value) => this.handleFilter('tournamentName', value)}
                        onEnter={() => this.handleFilter(ENTER)}
                    />
                    <FilterInput
                        placeholder="Tournament Id"
                        value={tournamentId || ''}
                        onChange={(value) => this.handleFilter('tournamentId', value)}
                        onEnter={() => this.handleFilter(ENTER)}
                    />
                    <FilterInput
                        placeholder="Game Name"
                        value={gameName || ''}
                        onChange={(value) => this.handleFilter('gameName', value)}
                        onEnter={() => this.handleFilter(ENTER)}
                    />
                    <DropDown
                        label="Status"
                        value={status || ''}
                        menuItems={MATCH_STATUS}
                        onChange={(value) => this.handleFilter('status', value, false)}
                    />
                    {!!matchesMatchType && <DropDown
                        label="Match Type"
                        value={matchType || ''}
                        menuItems={MATCH_TYPES_OPTIONS}
                        onChange={(value) => this.handleFilter('matchType', value, false)}
                    />}
                    <DropDown
                        label="Sort By"
                        value={sortOrder || ''}
                        menuItems={sortOptions}
                        onChange={(value) => this.handleFilter('sortOrder', value, false)}
                    />
                    <DateRangeSelect
                        startDate={startDate}
                        endDate={endDate}
                        isClearable={true}
                        onChange={(dates) => this.handleFilter('dates', dates, false)}
                    />
                    <FormControlLabel
                        label="MOTD Matches"
                        control={
                            <Checkbox
                                checked={!!isMatchOfTheDay}
                                onChange={(e) => this.handleFilter('isMatchOfTheDay', e.target.checked, false)}
                            />
                        }
                    />
                    <FormControlLabel
                        label="FR Matches"
                        control={
                            <Checkbox
                                checked={!!isAllSuperMatch}
                                onChange={(e) => this.handleFilter('isAllSuperMatch', e.target.checked, false)}
                            />
                        }
                    />
                    <Button buttonText="Reset" onClick={this.onReset} />
                </div>
                <Table
                    data={data}
                    columns={columns}
                    page={page}
                    onPrevClick={this.onPrev.bind(this)}
                    onNextClick={this.onNext.bind(this)}
                    disableNext={disableNext}
                />
            </TableLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        squad: state.squad,
        player: state.player,
        match: state.match,
        client: state.client,
    };
};

export default connect(mapStateToProps, {getMatchList, editMatch, deleteMatch, logout})(MatchList);
