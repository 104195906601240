import {
    DISCOUNT_LIST,
    DISCOUNT_LIST_SUCCESS,
    DISCOUNT_LIST_FAIL,
    EDIT_DISCOUNT_DATA,
    ADD_DISCOUNT,
    ADD_DISCOUNT_SUCCESS,
    ADD_DISCOUNT_FAIL,
    DELETE_DISCOUNT,
    DELETE_DISCOUNT_SUCCESS,
    DELETE_DISCOUNT_FAIL,
    CURRENT_CODE,
    DISCOUNT_CODE_DETAILS,
} from './Constants';

/**
 * list all discount codes
 */
export function listDiscount() {
    return {
        type: DISCOUNT_LIST,
    };
}

/**
 *handle list discount code api success
 * @param {*} data data from api
 */
export function listDiscountSuccess(data) {
    return {
        type: DISCOUNT_LIST_SUCCESS,
        payload: data,
    };
}

/**
 * handle list discount code api failure
 * @param {*} error error from api
 */
export function listDiscountFail(error) {
    return {
        type: DISCOUNT_LIST_FAIL,
        payload: error,
    };
}

/**
 * single Discount code info
 */
export function editDiscountData(Discount) {
    return {
        type: EDIT_DISCOUNT_DATA,
        payload: Discount,
    };
}

/**
 * create discount code api request
 */
export function createDiscount() {
    return {
        type: ADD_DISCOUNT,
    };
}
/**
 * handle create discount code api request success
 * @param {*} data data from api
 */
export function createDiscountSuccess(data) {
    return {
        type: ADD_DISCOUNT_SUCCESS,
        payload: data,
    };
}
/**
 *handle create discount code api request failure
 * @param {*} error error from api
 */
export function createDiscountFail(error) {
    return {
        type: ADD_DISCOUNT_FAIL,
        payload: error,
    };
}

/**
 *delete discount code api request
 */
export function deleteDiscount() {
    return {
        type: DELETE_DISCOUNT,
    };
}
/**
 *handle delete discount code api request success
 * @param {*} data data from api
 */
export function deleteDiscountSuccess(data) {
    return {
        type: DELETE_DISCOUNT_SUCCESS,
        payload: data,
    };
}
/**
 * handle delete discount code failure
 * @param {*} error error from api
 */
export function deleteDiscountFail(error) {
    return {
        type: DELETE_DISCOUNT_FAIL,
        payload: error,
    };
}

/**
 * get single discount code details
 * @param {*} data disocunt code details
 */
export function currentCode(data) {
    return {
        type: CURRENT_CODE,
        payload: data,
    };
}

/**
 * disocunt code details to be edited
 * @param {*} data disocunt code details
 */
export function editdiscountCode(data) {
    return {
        type: DISCOUNT_CODE_DETAILS,
        payload: data,
    };
}
