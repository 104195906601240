import {
    TEAM_LIST,
    TEAM_LIST_SUCCESS,
    TEAM_LIST_FAIL,
    TEAM_DATA_LIST,
    TEAM_DATA_LIST_SUCCESS,
    TEAM_DATA_LIST_FAIL,
} from './Constants';

/**
 * list all teams
 */
export function listTeams() {
    return {
        type: TEAM_LIST,
    };
}
/**
 * handle list team success
 * @param {*} data data from api
 */
export function listTeamsSuccess(data) {
    return {
        type: TEAM_LIST_SUCCESS,
        payload: data,
    };
}
/**
 *handle list team fail
 * @param {*} error error from api
 */
export function listTeamsFail(error) {
    return {
        type: TEAM_LIST_FAIL,
        payload: error,
    };
}

// team data
/**
 * get team data
 */
export function listTeamData() {
    return {
        type: TEAM_DATA_LIST,
    };
}
/**
 *handle get team success
 * @param {*} data data from api
 */
export function listTeamDataSuccess(data) {
    return {
        type: TEAM_DATA_LIST_SUCCESS,
        payload: data,
    };
}
/**
 *handle get team fail
 * @param {*} error error from api
 */
export function listTeamDataFail(error) {
    return {
        type: TEAM_DATA_LIST_FAIL,
        payload: error,
    };
}
