import React from 'react';
import ReactTable from 'react-table';
import './SmallTable.css';
import Prev from '../../Images/left yellow.png';
import Next from '../../Images/right yellow.png';
import DisPrev from '../../Images/left disabled.png';
import DisNext from '../../Images/right disabled.png';

class SmallTable extends React.Component {
    render() {
        const {data, columns, onFetchData, removePagination, onPrevClick, page, disableNext, onNextClick, pageSize} =
            this.props;

        return (
            <div className="table-div">
                <ReactTable
                    manual
                    data={data}
                    columns={columns}
                    defaultPageSize={pageSize}
                    className="hl-card"
                    loading={false}
                    onFetchData={onFetchData}
                    showPagination={false}
                />

                {removePagination ? null : (
                    <div className="disp-flex align-center pagination">
                        <div className="margin-right-16 cursor" onClick={onPrevClick}>
                            {page === 1 ? <img src={DisPrev} alt="" /> : <img src={Prev} alt="" />}
                        </div>
                        <div className="disp-flex">
                            <div>Page</div>
                            <div className="pageNumber">{page}</div>
                        </div>
                        <div className="cursor" onClick={onNextClick}>
                            {disableNext ? <img src={DisNext} alt="" /> : <img src={Next} alt="" />}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default SmallTable;
