export const TOMATCHATTRIBUTE_LIST = 'TOMATCHATTRIBUTE_LIST';
export const TOMATCHATTRIBUTE_LIST_SUCCESS = 'TOMATCHATTRIBUTE_LIST_SUCCESS';
export const TOMATCHATTRIBUTE_LIST_FAIL = 'TOMATCHATTRIBUTE_LIST_FAIL';

export const ADD_TOMATCHATTRIBUTE = 'ADD_TOMATCHATTRIBUTE';
export const ADD_TOMATCHATTRIBUTE_SUCCESS = 'ADD_TOMATCHATTRIBUTE_SUCCESS';
export const ADD_TOMATCHATTRIBUTE_FAIL = 'ADD_TOMATCHATTRIBUTE_FAIL';

export const UPDATE_TOMATCHATTRIBUTE = 'UPDATE_TOMATCHATTRIBUTE';
export const UPDATE_TOMATCHATTRIBUTE_SUCCESS = 'UPDATE_TOMATCHATTRIBUTE_SUCCESS';
export const UPDATE_TOMATCHATTRIBUTE_FAIL = 'UPDATE_TOMATCHATTRIBUTE_FAIL';

export const DELETE_TOMATCHATTRIBUTE = 'DELETE_TOMATCHATTRIBUTE';
export const DELETE_TOMATCHATTRIBUTE_SUCCESS = 'DELETE_TOMATCHATTRIBUTE_SUCCESS';
export const DELETE_TOMATCHATTRIBUTE_FAIL = 'DELETE_TOMATCHATTRIBUTE_FAIL';

export const GET_TOMATCHATTRIBUTE = 'GET_TOMATCHATTRIBUTE';
export const GET_TOMATCHATTRIBUTE_SUCCESS = 'GET_TOMATCHATTRIBUTE_SUCCESS';
export const GET_TOMATCHATTRIBUTE_FAIL = 'GET_TOMATCHATTRIBUTE_FAIL';

export const EDIT_TOMATCHATTRIBUTE = 'EDIT_TOMATCHATTRIBUTE';
