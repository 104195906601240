module.exports = {
    ROUTES: {
        LIST_REFERALCODE: '/referralCodes',
        ADD_REFCODE: '/addRefCode',
        EDIT_REFCODE: '/editRefCode/:id',
        REFCODE_NEW_USERS: '/refNewUsers',
        REF_CONVERTED_USERS: '/refConvertedUsers',
        REF_MONEY_CONVERTED: '/moneyConverted',
    },
};
