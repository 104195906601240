import React from 'react';
import '../../../Utilities/style.css';

import FanclashLogo from '../../../Images/fanclash_logo_big.png';

class LoginLayout extends React.Component {
    render() {
        let loginHeight = {
            height: window.innerHeight,
        };
        return (
            <div className="login-container" style={loginHeight}>
                <div className="container login-container">
                    <div className="pt-15 login-page">
                        <div className="grid-container ">
                            <div className="grid-item login-logo-grid align-center">
                                <div />
                                <div>
                                    <img alt="" src={FanclashLogo} />

                                    <div className="brand-name">Control Center</div>
                                </div>
                            </div>

                            <div className="grid-item login-card-grid">{this.props.children}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LoginLayout;
