import {
    PLAYER_LIST,
    PLAYER_LIST_SUCCESS,
    PLAYER_LIST_FAIL,
    ADD_PLAYER,
    ADD_PLAYER_SUCCESS,
    ADD_PLAYER_FAIL,
    UPDATE_PLAYER,
    UPDATE_PLAYER_SUCCESS,
    UPDATE_PLAYER_FAIL,
    DELETE_PLAYER,
    DELETE_PLAYER_SUCCESS,
    DELETE_PLAYER_FAIL,
    EDIT_PLAYER,
    GET_PLAYER,
    GET_PLAYER_SUCCESS,
    GET_PLAYER_FAIL,
} from './PlayerConstants';

/**
 * list players api
 */
export function listPlayer() {
    return {
        type: PLAYER_LIST,
    };
}
/**
 *handle list player api success
 * @param {*} data data from api
 */
export function listPlayerSuccess(data) {
    return {
        type: PLAYER_LIST_SUCCESS,
        payload: data,
    };
}
/**
 *handle list player failure
 * @param {*} error error from api
 */
export function listPlayerFail(error) {
    return {
        type: PLAYER_LIST_FAIL,
        payload: error,
    };
}

// add player
/**
 * add players api
 */
export function addPlayer() {
    return {
        type: ADD_PLAYER,
    };
}
/**
 *handle add player api success
 * @param {*} data data from api
 */
export function addPlayerSuccess(data) {
    return {
        type: ADD_PLAYER_SUCCESS,
        payload: data,
    };
}
/**
 *handle add player failure
 * @param {*} error error from api
 */
export function addPlayerFail(error) {
    return {
        type: ADD_PLAYER_FAIL,
        payload: error,
    };
}
/**
 * update players api
 */
export function updatePlayer() {
    return {
        type: UPDATE_PLAYER,
    };
}

/**
 *handle update player api success
 * @param {*} data data from api
 */
export function updatePlayerSuccess(data) {
    return {
        type: UPDATE_PLAYER_SUCCESS,
        payload: data,
    };
}
/**
 *handle update player failure
 * @param {*} error error from api
 */
export function updatePlayerFail(error) {
    return {
        type: UPDATE_PLAYER_FAIL,
        payload: error,
    };
}

/**
 * single player details
 * @param {*} player player details
 */
export function editPlayer(player) {
    return {
        type: EDIT_PLAYER,
        payload: player,
    };
}

// delete player
/**
 * delete players api
 */
export function deletePlayer() {
    return {
        type: DELETE_PLAYER,
    };
}
/**
 *handle delete player api success
 * @param {*} data data from api
 */
export function deletePlayerSuccess(data) {
    return {
        type: DELETE_PLAYER_SUCCESS,
        payload: data,
    };
}
/**
 *handle delete player failure
 * @param {*} error error from api
 */
export function deletePlayerFail(error) {
    return {
        type: DELETE_PLAYER_FAIL,
        payload: error,
    };
}

// get single player data
/**
 * get players api
 */
export function getPlayer() {
    return {
        type: GET_PLAYER,
    };
}
/**
 *handle get player api success
 * @param {*} data data from api
 */
export function getPlayerSuccess(data) {
    return {
        type: GET_PLAYER_SUCCESS,
        payload: data,
    };
}
/**
 *handle get player failure
 * @param {*} error error from api
 */
export function getPlayerFail(error) {
    return {
        type: GET_PLAYER_FAIL,
        payload: error,
    };
}
