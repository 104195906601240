import React from 'react';
import '../../Utilities/style.css';
import './Button.css';
import PropTypes from 'prop-types';

class Button extends React.Component {
    static propTypes = {
        onClick: PropTypes.func,
        onDoubleClick: PropTypes.func,
        type: PropTypes.oneOf(['button', 'submit']),
        size: PropTypes.oneOf(['default', 'sm']),
        buttonRef: PropTypes.any,
        disabled: PropTypes.bool,
        className: PropTypes.string,
    };

    static defaultProps = {
        onClick: () => {},
        onDoubleClick: () => {},
        type: 'button',
        buttonRef: null,
        disabled: false,
        className: '',
        size: 'default',
    };

    constructor(props) {
        super(props);
        this.onButtonClick = this.onButtonClick.bind(this);
    }

    /**
     * connect to onclick of props
     */
    onButtonClick() {
        this.props.onClick();
    }

    render() {
        const {buttonText, cancel, type, buttonRef, disabled, className, size, onDoubleClick} = this.props;
        return (
            <button
                ref={buttonRef}
                type={type}
                className={`${className} ${cancel ? 'cancel-button' : 'button'} button_${size}`}
                onClick={this.onButtonClick}
                disabled={disabled}
                onDoubleClick={onDoubleClick}
            >
                {buttonText || this.props.children}
            </button>
        );
    }
}

export default Button;
