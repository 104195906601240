import React from 'react';
import PropTypes from 'prop-types';
import {Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel} from '@material-ui/core';
import Button from '../../../../Components/Button/Button';

import Snackbar from '../../../../Components/Snackbar/Snackbar';
import {connect} from 'react-redux';
import LoadingModal from '../../../../Components/LoadingModal/LoadingModal';
import Input from '../../../../Components/Input/Input';
import {bindActionCreators} from 'redux';
import {createBanner, updateBanner} from '../action';
import DropDown from '../../../../Components/Dropdown/Dropdown';
import {
    BANNER_SIZE_OPTIONS,
    BANNER_TARGET_AUDIENCE_OPTIONS,
    BANNER_TYPE,
    BANNER_TYPE_OPTIONS,
    COMMON_STATUS,
    PLATFORMS,
} from '../../../../Utilities/Constants';
import ImageInput from '../../../../Components/ImageInput/ImageInput';
import DateTimePickerComponent from '../../../../Components/DateTimePickerComponent';

class CreateEditBannerModal extends React.Component {
    static propTypes = {
        open: PropTypes.bool,
        handleClose: PropTypes.func,
        bannerData: PropTypes.object,
    };

    static defaultProps = {
        handleClose: () => {},
        open: false,
        bannerData: {},
    };
    showExternalLink = [
        BANNER_TYPE.discord,
        BANNER_TYPE.squadFantasy,
        BANNER_TYPE.dailyStreak,
        BANNER_TYPE.apk,
        BANNER_TYPE.custom,
    ];
    submitRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            platForms: [],
            ...props.bannerData,
            bannerType: BANNER_TYPE.custom,
        };
    }

    componentDidMount() {}

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {banners} = this.props;

        if (
            (banners.isCreatingBanner && !prevProps.banners.isCreatingBanner) ||
            (banners.isUpdatingBanner && !prevProps.banners.isUpdatingBanner)
        ) {
            this.setState({loading: true});
        }

        if (
            (banners.isCreatingBannerSuccess && !prevProps.banners.isCreatingBannerSuccess) ||
            (banners.isUpdatingBannerSuccess && !prevProps.banners.isUpdatingBannerSuccess)
        ) {
            this.setState({loading: false});
        }

        if (
            (banners.isCreatingBannerFail && !prevProps.banners.isCreatingBannerFail) ||
            (banners.isUpdatingBannerFail && !prevProps.banners.isUpdatingBannerFail)
        ) {
            this.setState({message: banners.message.errorReason, loading: false});
        }
    }

    handleOnChange = (key, value) => {
        this.setState({[key]: value});
    };

    handleChangeCheckbox = (value) => {
        let {platForms} = this.state;
        if (platForms.includes(value)) platForms = platForms.filter((p) => p !== value);
        else platForms.push(value);
        this.handleOnChange('platForms', platForms);
    };

    handleSubmit = (e) => {
        !!e && e.preventDefault();
        const {login, client} = this.props;
        const {
            id,
            bannerType,
            image,
            status,
            externalLink,
            deeplink,
            bannerName,
            matchId,
            platForms,
            expiryAt,
            targetAudience,
            bannerOrder,
            bannerSize,
        } = this.state;
        let data = {bannerType, status, targetAudience, bannerOrder, bannerSize};
        if (bannerType === BANNER_TYPE.estar) data.matchId = matchId;
        if (bannerType === BANNER_TYPE.custom) data = {...data, deeplink, bannerName, platForms};
        if (this.showExternalLink.includes(bannerType)) data.externalLink = externalLink;
        if (expiryAt) data.expiryAt = new Date(expiryAt).valueOf();
        if (id) data.id = id;
        data.image = image; //keep file in last because of sails form data
        if (!id) {
            this.props.createBanner(data, login.userDetails.data.accessToken, client.selectedClient);
        } else {
            this.props.updateBanner(data, login.userDetails.data.accessToken, client.selectedClient);
        }
    };

    handleClickSubmit = () => {
        this.submitRef.current.click();
    };

    handleImageUrl = (image) => {
        if (!image) return false;
        if (typeof image === 'string') return image;
        return URL.createObjectURL(image);
    };

    render() {
        const {handleClose, open, login} = this.props;
        const {
            message,
            bannerType,
            image,
            status,
            externalLink,
            deeplink,
            matchId,
            loading,
            id,
            bannerName,
            platForms,
            expiryAt,
            targetAudience,
            bannerOrder,
            bannerSize,
        } = this.state;
        const imageUrl = this.handleImageUrl(image);

        const {bannerWrite} = login?.permissions || {};

        return (
            <Dialog
                open={open}
                onClose={handleClose}
                scroll="paper"
                maxWidth="sm"
                fullWidth
                disableEscapeKeyDown
                disableBackdropClick
            >
                <Snackbar open={!!message} message={message} onClose={() => this.setState({message: ''})} />
                <LoadingModal open={!!loading} />
                <DialogTitle>{id ? 'Edit ' : 'Create '}Banner</DialogTitle>
                <DialogContent dividers={true}>
                    <form onSubmit={this.handleSubmit}>
                        <div className="card-grid-container">
                            <DropDown
                                className="mb-15"
                                label="Banner Type*"
                                menuItems={BANNER_TYPE_OPTIONS}
                                required
                                name="bannerType"
                                value={bannerType || ''}
                                placeholder="Select"
                                disabled
                                onChange={(value) => this.handleOnChange('bannerType', value)}
                            />

                            <DropDown
                                className="mb-15"
                                label="Status*"
                                menuItems={COMMON_STATUS}
                                required
                                name="status"
                                value={status || ''}
                                placeholder="Select"
                                onChange={(value) => this.handleOnChange('status', value)}
                            />
                        </div>
                        <div className="card-grid-container">
                            <DropDown
                                className="mb-15"
                                label="Target Audience"
                                menuItems={BANNER_TARGET_AUDIENCE_OPTIONS}
                                name="targetAudience"
                                value={targetAudience || ''}
                                placeholder="Select"
                                required
                                onChange={(value) => this.handleOnChange('targetAudience', value)}
                            />

                            <Input
                                className="mb-15"
                                label="Banner Order"
                                type="number"
                                name="bannerOrder"
                                value={bannerOrder || ''}
                                placeholder="Enter banner order"
                                onChange={(value) => this.handleOnChange('bannerOrder', value)}
                            />
                        </div>

                        <div className="card-grid-container">
                            <DropDown
                                className="mb-15"
                                label="Banner Size"
                                menuItems={BANNER_SIZE_OPTIONS}
                                name="bannerSize"
                                value={bannerSize || ''}
                                placeholder="Select"
                                required={!id}
                                onChange={(value) => this.handleOnChange('bannerSize', value)}
                            />

                            {bannerType === BANNER_TYPE.estar && (
                                <Input
                                    className="mb-15"
                                    label="Match Id"
                                    type="text"
                                    name="matchId"
                                    required
                                    value={matchId || ''}
                                    placeholder="Enter Match Id"
                                    onChange={(value) => this.handleOnChange('matchId', value)}
                                />
                            )}

                            {this.showExternalLink.includes(bannerType) && (
                                <Input
                                    className="mb-15"
                                    label="External Link"
                                    type="text"
                                    name="externalLink"
                                    required={bannerType !== BANNER_TYPE.custom}
                                    value={externalLink || ''}
                                    placeholder="Enter External Link"
                                    onChange={(value) => this.handleOnChange('externalLink', value)}
                                />
                            )}

                            {bannerType === BANNER_TYPE.custom && (
                                <Input
                                    className="mb-15"
                                    label="Deep Link"
                                    type="text"
                                    name="deeplink"
                                    value={deeplink || ''}
                                    placeholder="Enter Deep Link"
                                    onChange={(value) => this.handleOnChange('deeplink', value)}
                                />
                            )}

                            {bannerType === BANNER_TYPE.custom && (
                                <Input
                                    className="mb-15"
                                    label="Banner Name"
                                    type="text"
                                    name="bannerName"
                                    value={bannerName || ''}
                                    placeholder="Enter Banner Name"
                                    onChange={(value) => this.handleOnChange('bannerName', value)}
                                />
                            )}

                            <DateTimePickerComponent
                                className="mb-15"
                                label="Expiry At"
                                value={expiryAt || null}
                                minDate={Date.now()}
                                onChange={(value) => this.handleOnChange('expiryAt', value)}
                            />
                        </div>

                        {bannerType === BANNER_TYPE.custom && (
                            <div className="mb-15 grid-3-container">
                                <div>
                                    <FormControlLabel
                                        label="Android"
                                        control={
                                            <Checkbox
                                                checked={platForms.includes(PLATFORMS.ANDROID)}
                                                color="primary"
                                                onChange={() => this.handleChangeCheckbox(PLATFORMS.ANDROID)}
                                            />
                                        }
                                    />
                                </div>
                                <div>
                                    <FormControlLabel
                                        label="IOS"
                                        control={
                                            <Checkbox
                                                checked={platForms.includes(PLATFORMS.IOS)}
                                                color="primary"
                                                onChange={() => this.handleChangeCheckbox(PLATFORMS.IOS)}
                                            />
                                        }
                                    />
                                </div>
                                <div>
                                    <FormControlLabel
                                        label="PWA"
                                        control={
                                            <Checkbox
                                                checked={platForms.includes(PLATFORMS.PWA)}
                                                color="primary"
                                                onChange={() => this.handleChangeCheckbox(PLATFORMS.PWA)}
                                            />
                                        }
                                    />
                                </div>
                            </div>
                        )}

                        {!!imageUrl && (
                            <div className="mb-15">
                                <p>{imageUrl}</p>
                                <img src={imageUrl} style={{height: 150, maxWidth: '100%'}} alt="banner image" />
                            </div>
                        )}

                        <ImageInput
                            label="Upload Image"
                            type="file"
                            name="image"
                            onChange={(value) => this.handleOnChange('image', value)}
                        />

                        <Button className="d-none" buttonText="Submit" type="submit" buttonRef={this.submitRef} />
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button buttonText="Cancel" onClick={handleClose} />
                    {!!bannerWrite && <Button buttonText="Submit" onClick={this.handleClickSubmit} />}
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        banners: state.banners,
        client: state.client,
    };
};

const matchDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            createBanner,
            updateBanner,
        },
        dispatch,
    );

export default connect(mapStateToProps, matchDispatchToProps)(CreateEditBannerModal);
