import React from 'react';
import '../../../Utilities/style.css';
import '../Login/login.css';
import TableLayout from '../../Layouts/TableLayout/TableLayout';
import Table from '../../../Components/Table/Table';

import {connect} from 'react-redux';
import {
    debounce,
    getFiltersSessionStorageItem,
    next,
    prev,
    setFiltersSessionStorageItem,
} from '../../../Utilities/HelperFunctions';

import {getContestTypeList, deleteContestType} from './AxioCalls';
import {editContestType} from './action';
import Snackbar from '../../../Components/Snackbar/Snackbar';
import {logout} from '../Login/LoginAction';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';
import {ENTER, SCREEN_NAMES} from '../../../Utilities/Constants';
import FilterInput from '../../../Components/FilterInput';
import DropDown from '../../../Components/Dropdown/Dropdown';
import Button from '../../../Components/Button/Button';

class ContestTypeList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contestType: [],
            list: [],
            open: false,
            pageData: {},
            loading: true,
            pageOptions: {
                page: 1,
                pageSize: 20,
                ...getFiltersSessionStorageItem(SCREEN_NAMES.CONTESTS_TYPE),
            },
            sortOptions: [
                {value: 'id', text: 'Contest Type Id'},
                {value: 'name', text: 'Contest Type'},
                {text: 'Status', value: 'status'},
            ],
        };
    }

    componentDidMount() {
        const {login, history} = this.props;
        if (!login?.userDetails?.data) {
            history.push('/');
            this.props.logout();
        } else {
            this.getContestTypeListDebounce({immediate: true});
        }
    }

    componentDidUpdate(prevProps) {
        const {contestType} = this.props;

        if (contestType.contestTypeListRequest && !prevProps.contestType.contestTypeListRequest) {
            this.setState({loading: true});
        }

        if (contestType.contestTypeListSuccess && !prevProps.contestType.contestTypeListSuccess) {
            this.setState({
                loading: false,
                list: contestType.contestTypeList.data.data,
                pageData: contestType.contestTypeList.data.pageData,
            });
        } else if (contestType.contestTypeListFail && !prevProps.contestType.contestTypeListFail) {
            this.setState({loading: false, errorMessage: contestType.errorMessage});
        }
        if (contestType.deleteContestSuccess && !prevProps.contestType.deleteContestSuccess) {
            this.setState({
                loading: false,
                errorMessage: contestType.deleteContest.msg,
                contestType: [],
            });
            this.getContestTypeListDebounce({immediate: true});
        } else if (contestType.deleteContestFail && !prevProps.contestType.deleteContestFail) {
            this.setState({loading: false, errorMessage: ' Failed to delete Contest Type'});
        }
    }

    getContestTypeListDebounce = debounce(() => {
        const {login, client} = this.props;
        setFiltersSessionStorageItem(SCREEN_NAMES.CONTESTS_TYPE, this.state.pageOptions);
        this.props.getContestTypeList(
            this.state.pageOptions,
            login.userDetails.data.accessToken,
            client.selectedClient,
        );
    });

    /**
     * get contest Id of the string to be edited
     * @param {string} id get the Id of the contest clicked on
     */
    onEditContestType(id) {
        const {contestType, history} = this.props;
        let contests = contestType.contestTypeList.data.data;
        const editContest = contests.filter((contest) => contest.id === id);
        this.props.editContestType(editContest[0]);
        history.push('/editContestType/' + id);
    }

    handleFilter = (field, value, debounce = true) => {
        const {pageOptions} = this.state;
        if (field === ENTER) {
            this.getContestTypeListDebounce({immediate: true});
            return;
        }
        pageOptions[field] = value;
        this.setState({pageOptions: {...pageOptions, page: 1}}, () => {
            this.getContestTypeListDebounce(debounce ? {} : {immediate: true});
        });
    };

    /**
     * pagination and api call on next click
     */
    onNext = () => {
        const {pageData, pageOptions} = this.state;
        let options = next(pageOptions, pageData);
        if (options) {
            this.setState({pageOptions: options}, () => this.getContestTypeListDebounce({immediate: true}));
        }
    };

    /**
     * pagination and api call on  prev click
     */
    onPrev = () => {
        const {pageOptions} = this.state;
        let options = prev(pageOptions);
        if (options) {
            this.setState({pageOptions: options}, () => this.getContestTypeListDebounce({immediate: true}));
        }
    };

    /**
     * display winning prizes
     * @param {*} pool wining distrbution on contest type
     */
    calculatePool(pool) {
        let ranks = pool.winners;
        let pools = Object.keys(ranks);

        return Object.keys(ranks).map((rank, index) => {
            if (index === pools.length - 1) {
                return ranks[rank];
            } else {
                return ranks[rank] + ' , ';
            }
        });
    }

    /**
     * reseting search and sort
     */
    onReset = () => {
        this.setState({pageOptions: {page: 1, pageSize: 20}}, () => {
            this.getContestTypeListDebounce({immediate: true});
        });
    };

    render() {
        const {history, login} = this.props;
        const {list, sortOptions, loading, errorMessage, contestType, pageData} = this.state;
        const {searchString, sortOrder, page} = this.state.pageOptions;
        const data = list;
        let disableNext = page === pageData.lastPage;

        const {contestWrite} = login?.permissions || {};

        const columns = [
            {
                Header: 'Contest Type ID',
                accessor: 'id',
                width: 200,
                Cell: (props) => (
                    <span className="table-clickable" onClick={this.onEditContestType.bind(this, props.value)}>
                        {props.value === undefined ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Contest Type ',
                accessor: 'name',
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'User Type',
                accessor: 'eligibleUsers',
                Cell: (props) => <span>{props.value || ''}</span>,
            },
            {
                Header: 'prize pool type',
                accessor: 'prizePoolData',
                Cell: (props) => <span>{props?.value?.type || '-'}</span>,
            },
            {
                Header: '# Total of Slots',
                accessor: 'totalSlots',
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: '# Min Slots to be filled',
                accessor: 'minNumberOfTeams',
                width: 150,
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: '# of Winning Slots',
                accessor: 'numberOfBigWinSlots',
                Cell: (props) => <span>{props.value === undefined ? '-' : props.value}</span>,
            },
            {
                Header: 'Entry Fee / Team',
                accessor: 'entryFeePerTeam',
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : '' + props.value}
                    </span>
                ),
            },
            {
                Header: 'Max Teams / User',
                accessor: 'maxTeamsPerUser',
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Winnings % of prizes',
                accessor: 'winningDistribution',
                width: 150,
                Cell: (props) => (
                    <span className="number">{props.value === undefined ? '-' : this.calculatePool(props.value)}</span>
                ),
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Auto Create Contest',
                accessor: 'autoCreateContest',
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value === true ? 'Yes' : 'No'}
                    </span>
                ),
            },
        ];
        return (
            <TableLayout location="ContestTypes" history={history} hideSearchBar>
                {!!loading && <LoadingModal open={!!loading} />}
                {!!errorMessage && (
                    <Snackbar
                        open={!!errorMessage}
                        message={errorMessage}
                        onClose={() => this.setState({errorMessage: ''})}
                    />
                )}

                <div className="filter-container my-10">
                    <FilterInput
                        placeholder="Search Type Name"
                        value={searchString || ''}
                        onChange={(value) => this.handleFilter('searchString', value)}
                        onEnter={() => this.handleFilter(ENTER)}
                    />
                    <DropDown
                        label="Sort"
                        value={sortOrder || ''}
                        menuItems={sortOptions}
                        onChange={(value) => this.handleFilter('sortOrder', value, false)}
                    />
                    <Button buttonText="Reset" onClick={this.onReset} />
                </div>
                <div className="filter-container margin-bottom-10">
                    {!!contestWrite && (
                        <Button
                            buttonText="Add Contest Type"
                            onClick={() => this.props.history.push('/addContestType')}
                        />
                    )}
                </div>

                <Table
                    data={data}
                    columns={columns}
                    page={page}
                    onPrevClick={this.onPrev}
                    onNextClick={this.onNext}
                    disableNext={disableNext}
                ></Table>
            </TableLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        squad: state.squad,
        contestType: state.contestType,
        client: state.client,
    };
};

export default connect(mapStateToProps, {
    getContestTypeList,
    logout,
    editContestType,
    deleteContestType,
})(ContestTypeList);
