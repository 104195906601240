import axios from 'axios';

import {
    GET_BANNER_LIST,
    GET_BANNER_LIST_SUCCESS,
    GET_BANNER_LIST_FAIL,
    GET_BANNER,
    GET_BANNER_SUCCESS,
    GET_BANNER_FAIL,
    CREATE_BANNER,
    CREATE_BANNER_SUCCESS,
    CREATE_BANNER_FAIL,
    UPDATE_BANNER,
    UPDATE_BANNER_SUCCESS,
    UPDATE_BANNER_FAIL,
} from './constants';
import {getQueryString, jsonToForm} from '../../../Utilities/HelperFunctions';
import {URL} from '../../../Utilities/Constants';

// get banners with pagination
/**
 * @param {*} data banner details
 * @param {string} token user token
 */
export const getBannerList = (data, token, clientId) => (dispatch) => {
    dispatch({type: GET_BANNER_LIST});
    axios({
        method: 'get',
        headers: {Authorization: 'Bearer ' + token, clientid: clientId},
        url: `${URL}/banners?${getQueryString(data)}`,
    })
        .then((data) => {
            if (data.data.status === 'success') {
                dispatch({type: GET_BANNER_LIST_SUCCESS, payload: data.data});
            } else if (data.data.status === 'fail') {
                dispatch({type: GET_BANNER_LIST_FAIL, payload: data.data.errorData});
            }
        })
        .catch((err) => {
            if (err.response) {
                const errorMsg = err?.response?.data?.msg || err.message;
                dispatch({type: GET_BANNER_LIST_FAIL, payload: {errorReason: errorMsg || 'Something went wrong'}});
            } else {
                dispatch({type: GET_BANNER_LIST_FAIL, payload: {errorReason: err.message || 'Something went wrong'}});
            }
        });
};

// get banner using bannerId
/**
 * @param {bannerId} data banner Id
 * @param {string} token user token
 */
export const getBanner = (data, token, clientId) => (dispatch) => {
    dispatch({type: GET_BANNER});
    axios({
        method: 'get',
        headers: {Authorization: 'Bearer ' + token, clientid: clientId},
        url: `${URL}/banner/${data.bannerId}`,
    })
        .then((data) => {
            if (data.data.status === 'success') {
                dispatch({type: GET_BANNER_SUCCESS, payload: data.data});
            } else if (data.data.status === 'fail') {
                dispatch({type: GET_BANNER_FAIL, payload: data.data.errorData});
            }
        })
        .catch((err) => {
            if (err.response) {
                const errorMsg = err?.response?.data?.msg || err.message;
                dispatch({type: GET_BANNER_FAIL, payload: {errorReason: errorMsg || 'Something went wrong'}});
            } else {
                dispatch({type: GET_BANNER_FAIL, payload: {errorReason: err.message || 'Something went wrong'}});
            }
        });
};

// create banner
/**
 * @param {*} data banner details
 * @param {string} token user token
 */
export const createBanner = (data, token, clientId) => (dispatch) => {
    dispatch({type: CREATE_BANNER});
    axios({
        method: 'post',
        headers: {Authorization: 'Bearer ' + token, clientid: clientId},
        url: `${URL}/banner/create`,
        data: jsonToForm(data),
    })
        .then((data) => {
            if (data.data.status === 'success') {
                dispatch({type: CREATE_BANNER_SUCCESS, payload: data});
            } else if (data.data.status === 'fail') {
                dispatch({type: CREATE_BANNER_FAIL, payload: data.data.errorData});
            }
        })
        .catch((err) => {
            if (err.response) {
                const errorMsg = err?.response?.data?.msg || err.message;
                dispatch({type: CREATE_BANNER_FAIL, payload: {errorReason: errorMsg || 'Something went wrong'}});
            } else {
                dispatch({type: CREATE_BANNER_FAIL, payload: {errorReason: err.message || 'Something went wrong'}});
            }
        });
};

// update banner
/**
 * @param {*} data banner details
 * @param {string} token user token
 */
export const updateBanner = (data, token, clientId) => (dispatch) => {
    dispatch({type: UPDATE_BANNER});
    axios({
        method: 'post',
        headers: {Authorization: 'Bearer ' + token, clientid: clientId},
        url: `${URL}/banner/update`,
        data: jsonToForm(data),
    })
        .then((data) => {
            if (data.data.status === 'success') {
                dispatch({type: UPDATE_BANNER_SUCCESS, payload: data});
            } else if (data.data.status === 'fail') {
                dispatch({type: UPDATE_BANNER_FAIL, payload: data.data.errorData});
            }
        })
        .catch((err) => {
            if (err.response) {
                const errorMsg = err?.response?.data?.msg || err.message;
                dispatch({type: UPDATE_BANNER_FAIL, payload: {errorReason: errorMsg || 'Something went wrong'}});
            } else {
                dispatch({type: UPDATE_BANNER_FAIL, payload: {errorReason: err.message || 'Something went wrong'}});
            }
        });
};
