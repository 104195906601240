import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core';
import FileCopy from '@material-ui/icons/FileCopy';
import moment from 'moment';

import {copyText, next, prev} from '../../../../Utilities/HelperFunctions';
import Snackbar from '../../../../Components/Snackbar/Snackbar';
import {fetchGlacierMatches} from '../action';
import {useDebounce, usePrevious} from '../../../../Utilities/hooks';
import {ENTER, NEXT, SORT_ORDER_OP} from '../../../../Utilities/Constants';
import Pagination from '../../../../Components/Pagination';
import LoadingModal from '../../../../Components/LoadingModal/LoadingModal';
import SquadListModal from '../SquadListModal';
import DropDown from '../../../../Components/Dropdown/Dropdown';
import Button from '../../../../Components/Button/Button';

const ToCalendarMatchesTable = (props) => {
    const {tournamentId} = props;
    const initialFilter = {offset: 0, limit: 10};
    const dispatch = useDispatch();
    const elementRef = useRef(null);
    const login = useSelector((state) => state.login);
    const toCalendar = useSelector((state) => state.toCalendar);
    const prevToCalendar = usePrevious(toCalendar);
    const [filters, setFilters] = useState(initialFilter);
    const {limit, offset, immediate, sorting, ..._filters} = filters;
    const debouncedFilters = useDebounce(filters, {immediate: immediate});
    const [state, setState] = useState({});
    const [matches, setMatches] = useState({});
    const [participants, setParticipants] = useState(null);

    const {errorMsg, isFetchingStart, loading} = state;

    const page = offset / limit + 1;
    const lastPage = Math.ceil((matches?.count || 0) / limit) || 1;

    useEffect(() => {
        if (toCalendar.isFetchingGlacierMatches && !prevToCalendar.isFetchingGlacierMatches && !!isFetchingStart) {
            handleOnStateChange({loading: true});
        }

        if (
            toCalendar.isFetchingGlacierMatchesSuccess &&
            !prevToCalendar.isFetchingGlacierMatchesSuccess &&
            !!isFetchingStart
        ) {
            setMatches(toCalendar?.glacierMatches || {});
            handleOnStateChange({isFetchingStart: false, loading: false});
            elementRef.current.scrollIntoView({behavior: 'smooth', block: 'start'});
        }

        if (
            toCalendar.isFetchingGlacierMatchesFail &&
            !prevToCalendar.isFetchingGlacierMatchesFail &&
            !!isFetchingStart
        ) {
            handleOnStateChange({errorMsg: toCalendar.message.msg, isFetchingStart: false, loading: false});
        }
    }, [toCalendar]);

    useEffect(() => {
        if (login?.userDetails?.data) handleFetchGlacierMatches();
    }, [debouncedFilters]);

    const handleFetchGlacierMatches = () => {
        const query = {};
        if (sorting) {
            query.sort = sorting?.split('/')?.[0];
            query.order = sorting?.split('/')?.[1];
        }
        handleOnStateChange({isFetchingStart: true});
        dispatch(fetchGlacierMatches({filters: {..._filters, tournamentId}, offset, limit, detailed: true, ...query}));
    };

    const handleOnStateChange = (stateObj = {}) => {
        setState((prevState) => ({...prevState, ...stateObj}));
    };

    const handleFilter = (field, value, debounce = true) => {
        const tempFilter = {};
        if (field !== ENTER) tempFilter[field] = value;
        setFilters({...filters, ...tempFilter, offset: 0, immediate: !debounce});
    };

    const onReset = () => {
        setFilters({...initialFilter, immediate: true});
    };

    const onNextORPrev = (action) => {
        const pageObj = action === NEXT ? next({page}, {lastPage}) : prev({page});
        if (pageObj) setFilters({...filters, offset: limit * (pageObj?.page - 1), immediate: true});
    };

    const handleCopy = (text) => {
        copyText(text, (msg) => handleOnStateChange({errorMsg: msg}));
    };

    return (
        <div className="mt-15" ref={elementRef}>
            {!!loading && <LoadingModal open={!!loading} />}
            {!!errorMsg && (
                <Snackbar open={!!errorMsg} message={errorMsg} onClose={() => handleOnStateChange({errorMsg: ''})} />
            )}

            {!!participants && (
                <SquadListModal
                    open={!!participants}
                    participants={participants}
                    handleClose={() => setParticipants(null)}
                />
            )}

            <div className="filter-container my-10">
                <DropDown
                    label="Sorting"
                    value={filters.sorting || ''}
                    menuItems={SORT_ORDER_OP}
                    onChange={(value) => handleFilter('sorting', value, false)}
                />
                <Button buttonText="Reset" onClick={onReset} />
            </div>

            <TableContainer>
                <Table size="small" border={1}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Match Name</TableCell>
                            <TableCell>Series Name</TableCell>
                            <TableCell>No of squads playing</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Time</TableCell>
                            <TableCell>Map</TableCell>
                            <TableCell>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(matches?.list || []).map((m) => (
                            <TableRow key={m?.id}>
                                <TableCell>
                                    {m?.name} <br />
                                    <span className="clickable-icon" onClick={() => handleCopy(m?.id)}>
                                        {m?.id}
                                        <FileCopy titleAccess="Copy" className="ml-10" fontSize="small" />
                                    </span>
                                </TableCell>
                                <TableCell>
                                    {m.series?.name} <br />
                                    <span className="clickable-icon" onClick={() => handleCopy(m.series?.id)}>
                                        {m.series?.id}
                                        <FileCopy titleAccess="Copy" className="ml-10" fontSize="small" />
                                    </span>
                                </TableCell>
                                <TableCell>
                                    {!!m?.participants?.length ? (
                                        <span
                                            className="table-clickable"
                                            onClick={() => setParticipants(m?.participants)}
                                        >
                                            {m?.participants?.length}
                                        </span>
                                    ) : (
                                        m?.participants?.length
                                    )}
                                </TableCell>
                                <TableCell>{!!m?.start && moment(m?.start).format('DD/MM/YYYY')}</TableCell>
                                <TableCell>{!!m?.start && moment(m?.start).format('HH:mm')}</TableCell>
                                <TableCell>{m?.map}</TableCell>
                                <TableCell>{m?.lifecycle}</TableCell>
                            </TableRow>
                        ))}
                        {!matches?.list?.length && (
                            <TableRow>
                                <TableCell className="text-center" colSpan="10">
                                    No Data Available
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <br />
            <Pagination
                page={page}
                disableNext={page === lastPage}
                onNextClick={() => onNextORPrev(NEXT)}
                onPrevClick={onNextORPrev}
            />
        </div>
    );
};

ToCalendarMatchesTable.propTypes = {
    tournamentId: PropTypes.string,
};

ToCalendarMatchesTable.defaultProps = {
    tournamentId: '',
};

export default ToCalendarMatchesTable;
