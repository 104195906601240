import {
    LOGIN,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    UPDATE_PERMISSION,
    SENDEMAIL,
    SENDEMAIL_SUCCESS,
    SENDEMAIL_FAIL,
    LOGGED_IN_DETAILS,
    LOGGED_IN_DETAILS_SUCCESS,
    LOGGED_IN_DETAILS_FAIL,
} from './LoginConstants';

const initialState = {
    permissions: {},
    loginRequest: false,
    loginSuccess: false,
    loginFail: false,
    userDetails: {},
    errorMessage: '',
    sendEmailRequest: false,
    sendEmailSuccess: false,
    sendEmailFail: false,
    sendEmail: [],

    isFetchingLoggedInDetails: false,
    isFetchingLoggedInDetailsSuccess: false,
    isFetchingLoggedInDetailsFail: false,
};

const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                loginRequest: true,
                loginSuccess: false,
                loginFail: false,
                userDetails: {},
                errorMessage: '',
            };

        case LOGIN_SUCCESS:
            return {
                ...state,
                loginRequest: false,
                loginSuccess: true,
                loginFail: false,
                userDetails: action.payload.data,
                loggedOut: false,
            };

        case LOGIN_FAIL:
            return {
                ...state,
                loginRequest: false,
                loginSuccess: false,
                loginFail: true,
                errorMessage: action.payload,
            };

        case LOGOUT:
            return initialState;

        case UPDATE_PERMISSION:
            return {...state, permissions: action.payload};

        // send email request
        case SENDEMAIL:
            return {
                ...state,
                sendEmailRequest: true,
                sendEmailSuccess: false,
                sendEmailFail: false,
                sendEmail: [],
                errorMessage: '',
            };

        case SENDEMAIL_SUCCESS:
            return {
                ...state,
                sendEmailRequest: false,
                sendEmailSuccess: true,
                sendEmailFail: false,
                sendEmail: action.payload.data,
                loggedOut: false,
            };

        case SENDEMAIL_FAIL:
            return {
                ...state,
                sendEmailRequest: false,
                sendEmailSuccess: false,
                sendEmailFail: true,
                errorMessage: action.payload,
            };

        case LOGGED_IN_DETAILS:
            return {
                ...state,
                isFetchingLoggedInDetails: true,
                isFetchingLoggedInDetailsSuccess: false,
                isFetchingLoggedInDetailsFail: false,
                errorMessage: '',
            };

        case LOGGED_IN_DETAILS_SUCCESS:
            return {
                ...state,
                isFetchingLoggedInDetails: false,
                isFetchingLoggedInDetailsSuccess: true,
                isFetchingLoggedInDetailsFail: false,
                userDetails: {...state?.userDetails, data: {...state?.userDetails?.data, ...action?.payload?.data}},
            };

        case LOGGED_IN_DETAILS_FAIL:
            return {
                ...state,
                isFetchingLoggedInDetails: false,
                isFetchingLoggedInDetailsSuccess: false,
                isFetchingLoggedInDetailsFail: true,
                errorMessage: action.payload,
            };

        default:
            return state;
    }
};

export default loginReducer;
