import {
    TOURNAMENT_LIST,
    TOURNAMENT_LIST_SUCCESS,
    TOURNAMENT_LIST_FAIL,
    ADD_TOURNAMENT,
    ADD_TOURNAMENT_SUCCESS,
    ADD_TOURNAMENT_FAIL,
    IMPORT_TOURNAMENT,
    IMPORT_TOURNAMENT_SUCCESS,
    IMPORT_TOURNAMENT_FAIL,
} from './FantasyTournamentConstants';

const initialState = {
    tournamentListRequest: false,
    tournamentListSuccess: false,
    tournamentListFail: false,
    tournamentList: [],
    errorMessage: '',

    addTournamentRequest: false,
    addTournamentSuccess: false,
    addTournamentFail: false,
    addTournament: [],

    isImportingTournament: false,
    isImportingTournamentSuccess: false,
    isImportingTournamentFail: false,
    message: '',
};

const FantasyTournamentReducer = (state = initialState, action) => {
    switch (action.type) {
        case TOURNAMENT_LIST:
            return {
                ...state,
                tournamentListRequest: true,
                tournamentListSuccess: false,
                tournamentListFail: false,
                tournamentList: [],
                errorMessage: '',
                getTournamentRequest: false,
                getTournamentSuccess: false,
                getTournamentFail: false,
            };

        case TOURNAMENT_LIST_SUCCESS:
            return {
                ...state,
                tournamentListRequest: false,
                tournamentListSuccess: true,
                tournamentListFail: false,
                tournamentList: action.payload.data,
                errorMessage: '',
            };

        case TOURNAMENT_LIST_FAIL:
            return {
                ...state,
                tournamentListRequest: false,
                tournamentListSuccess: false,
                tournamentListFail: true,
                errorMessage: action.payload,
            };

        case ADD_TOURNAMENT:
            return {
                ...state,
                addTournamentRequest: true,
                addTournamentSuccess: false,
                addTournamentFail: false,
                addTournament: [],
                errorMessage: '',
            };

        case ADD_TOURNAMENT_SUCCESS:
            return {
                ...state,
                addTournamentRequest: false,
                addTournamentSuccess: true,
                addTournamentFail: false,
                addTournament: action.payload.data,
                errorMessage: '',
            };

        case ADD_TOURNAMENT_FAIL:
            return {
                ...state,
                addTournamentRequest: false,
                addTournamentSuccess: false,
                addTournamentFail: true,
                errorMessage: action.payload,
            };
        case IMPORT_TOURNAMENT:
            return {
                ...state,
                isImportingTournament: true,
                isImportingTournamentSuccess: false,
                isImportingTournamentFail: false,
            };
        case IMPORT_TOURNAMENT_SUCCESS:
            return {
                ...state,
                isImportingTournament: false,
                isImportingTournamentSuccess: true,
                isImportingTournamentFail: false,
                message: action.payload,
            };
        case IMPORT_TOURNAMENT_FAIL:
            return {
                ...state,
                isImportingTournament: false,
                isImportingTournamentSuccess: false,
                isImportingTournamentFail: true,
                errorMessage: action.payload,
            };
        default:
            return state;
    }
};

export default FantasyTournamentReducer;
