export const GET_BOOSTERS_LIST = 'GET_BOOSTERS_LIST';
export const GET_BOOSTERS_LIST_SUCCESS = 'GET_BOOSTERS_LIST_SUCCESS';
export const GET_BOOSTERS_LIST_FAIL = 'GET_BOOSTERS_LIST_FAIL';

export const GET_BOOSTER = 'GET_BOOSTER';
export const GET_BOOSTER_SUCCESS = 'GET_BOOSTER_SUCCESS';
export const GET_BOOSTER_FAIL = 'GET_BOOSTER_FAIL';

export const CREATE_BOOSTER = 'CREATE_BOOSTER';
export const CREATE_BOOSTER_SUCCESS = 'CREATE_BOOSTER_SUCCESS';
export const CREATE_BOOSTER_FAIL = 'CREATE_BOOSTER_FAIL';

export const GET_BOOSTER_CONFIGS = 'GET_BOOSTER_CONFIGS';
export const GET_BOOSTER_CONFIGS_SUCCESS = 'GET_BOOSTER_CONFIGS_SUCCESS';
export const GET_BOOSTER_CONFIGS_FAIL = 'GET_BOOSTER_CONFIGS_FAIL';

export const GET_MULTIPLIER_CONFIGS = 'GET_MULTIPLIER_CONFIGS';
export const GET_MULTIPLIER_CONFIGS_SUCCESS = 'GET_MULTIPLIER_CONFIGS_SUCCESS';
export const GET_MULTIPLIER_CONFIGS_FAIL = 'GET_MULTIPLIER_CONFIGS_FAIL';

export const GET_BOOSTER_CONFIG = 'GET_BOOSTER_CONFIG';
export const GET_BOOSTER_CONFIG_SUCCESS = 'GET_BOOSTER_CONFIG_SUCCESS';
export const GET_BOOSTER_CONFIG_FAIL = 'GET_BOOSTER_CONFIG_FAIL';

export const CREATE_BOOSTER_CONFIG = 'CREATE_BOOSTER_CONFIG';
export const CREATE_BOOSTER_CONFIG_SUCCESS = 'CREATE_BOOSTER_CONFIG_SUCCESS';
export const CREATE_BOOSTER_CONFIG_FAIL = 'CREATE_BOOSTER_CONFIG_FAIL';

export const UPDATE_BOOSTER_CONFIG = 'UPDATE_BOOSTER_CONFIG';
export const UPDATE_BOOSTER_CONFIG_SUCCESS = 'UPDATE_BOOSTER_CONFIG_SUCCESS';
export const UPDATE_BOOSTER_CONFIG_FAIL = 'UPDATE_BOOSTER_CONFIG_FAIL';
