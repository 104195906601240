export const TOMATCHMODE_LIST = 'TOMATCHMODE_LIST';
export const TOMATCHMODE_LIST_SUCCESS = 'TOMATCHMODE_LIST_SUCCESS';
export const TOMATCHMODE_LIST_FAIL = 'TOMATCHMODE_LIST_FAIL';

export const ADD_TOMATCHMODE = 'ADD_TOMATCHMODE';
export const ADD_TOMATCHMODE_SUCCESS = 'ADD_TOMATCHMODE_SUCCESS';
export const ADD_TOMATCHMODE_FAIL = 'ADD_TOMATCHMODE_FAIL';

export const UPDATE_TOMATCHMODE = 'UPDATE_TOMATCHMODE';
export const UPDATE_TOMATCHMODE_SUCCESS = 'UPDATE_TOMATCHMODE_SUCCESS';
export const UPDATE_TOMATCHMODE_FAIL = 'UPDATE_TOMATCHMODE_FAIL';

export const DELETE_TOMATCHMODE = 'DELETE_TOMATCHMODE';
export const DELETE_TOMATCHMODE_SUCCESS = 'DELETE_TOMATCHMODE_SUCCESS';
export const DELETE_TOMATCHMODE_FAIL = 'DELETE_TOMATCHMODE_FAIL';

export const GET_TOMATCHMODE = 'GET_TOMATCHMODE';
export const GET_TOMATCHMODE_SUCCESS = 'GET_TOMATCHMODE_SUCCESS';
export const GET_TOMATCHMODE_FAIL = 'GET_TOMATCHMODE_FAIL';

export const EDIT_TOMATCHMODE = 'EDIT_TOMATCHMODE';
