import {
    FANTASY_SCORING_LIST,
    FANTASY_SCORING_LIST_FAIL,
    FANTASY_SCORING_LIST_SUCCESS,
    ADD_FANTASY_SCORING,
    ADD_FANTASY_SCORING_FAIL,
    ADD_FANTASY_SCORING_SUCCESS,
    UPDATE_FANTASY_SCORING,
    UPDATE_FANTASY_SCORING_FAIL,
    UPDATE_FANTASY_SCORING_SUCCESS,
    DELETE_FANTASY_SCORING,
    DELETE_FANTASY_SCORING_FAIL,
    DELETE_FANTASY_SCORING_SUCCESS,
    GET_FANTASY_SCORING,
    GET_FANTASY_SCORING_FAIL,
    GET_FANTASY_SCORING_SUCCESS,
    EDIT_FANTASY_SCORING_MODEL,
} from './FantasyConfigConstants';

/**
 * list fantasy scoring models
 */
export function listFantasyScoringModels() {
    return {
        type: FANTASY_SCORING_LIST,
    };
}

/**
 *handle list scoring model api success
 * @param {*} data data form api
 */
export function listFantasyScoringModelsSuccess(data) {
    return {
        type: FANTASY_SCORING_LIST_SUCCESS,
        payload: data,
    };
}

/**
 * handle list fantasy scoring models api failure
 * @param {*} error error from api
 */
export function listFantasyScoringModelsFail(error) {
    return {
        type: FANTASY_SCORING_LIST_FAIL,
        payload: error,
    };
}

/**
 * addFantasyScoringModel
 */
export function addFantasyScoringModel() {
    return {
        type: ADD_FANTASY_SCORING,
    };
}

/**
 * @param {*} data data form api
 */
export function addFantasyScoringModelSuccess(data) {
    return {
        type: ADD_FANTASY_SCORING_SUCCESS,
        payload: data,
    };
}

/**
 * @param {*} error error from api
 */
export function addFantasyScoringModelFail(error) {
    return {
        type: ADD_FANTASY_SCORING_FAIL,
        payload: error,
    };
}

/**
 * update
 */
export function updateFantasyScoringModel(data) {
    return {
        type: UPDATE_FANTASY_SCORING,
        payload: data,
    };
}

/**
 * @param {*} data data form api
 */
export function updateFantasyScoringModelSuccess(error) {
    return {
        type: UPDATE_FANTASY_SCORING_SUCCESS,
        payload: error,
    };
}

/**
 * @param {*} error error from api
 */
export function updateFantasyScoringModelFail(data) {
    return {
        type: UPDATE_FANTASY_SCORING_FAIL,
        payload: data,
    };
}

/**
 * delete
 */
export function deleteFantasyScoringModel(data) {
    return {
        type: DELETE_FANTASY_SCORING,
        payload: data,
    };
}

/**
 * @param {*} data data form api
 */
export function deleteFantasyScoringModelSuccess(error) {
    return {
        type: DELETE_FANTASY_SCORING_SUCCESS,
        payload: error,
    };
}
/**
 * @param {*} error error from api
 */
export function deleteFantasyScoringModelFail(data) {
    return {
        type: DELETE_FANTASY_SCORING_FAIL,
        payload: data,
    };
}

/**
 * get scoring models
 */
export function getFantasyScoringModel(data) {
    return {
        type: GET_FANTASY_SCORING,
        payload: data,
    };
}
/**
 *handle get api success
 * @param {*} data data form api
 */
export function getFantasyScoringModelSuccess(error) {
    return {
        type: GET_FANTASY_SCORING_SUCCESS,
        payload: error,
    };
}
/**
 *handle get api failure
 * @param {*} error error from api
 */
export function getFantasyScoringModelFail(data) {
    return {
        type: GET_FANTASY_SCORING_FAIL,
        payload: data,
    };
}

/**
 * single model details
 * @param {*} model model details
 */
export function editScoring(model) {
    return {
        type: EDIT_FANTASY_SCORING_MODEL,
        payload: model,
    };
}
