export const DISCOUNT_LIST = 'DISCOUNT_LIST';
export const DISCOUNT_LIST_SUCCESS = 'DISCOUNT_LIST_SUCCESS';
export const DISCOUNT_LIST_FAIL = 'DISCOUNT_LIST_FAIL';
export const EDIT_DISCOUNT_DATA = 'EDIT_DISCOUNT_DATA';

export const ADD_DISCOUNT = 'ADD_DISCOUNT';
export const ADD_DISCOUNT_SUCCESS = 'ADD_DISCOUNT_SUCCESS';
export const ADD_DISCOUNT_FAIL = 'ADD_DISCOUNT_FAIL';

export const DELETE_DISCOUNT = 'DELETE_DISCOUNT';
export const DELETE_DISCOUNT_SUCCESS = 'DELETE_DISCOUNT_SUCCESS';
export const DELETE_DISCOUNT_FAIL = 'DELETE_DISCOUNT_FAIL';

export const CURRENT_CODE = 'CURRENT_CODE';

export const DISCOUNT_CODE_DETAILS = 'DISCOUNT_CODE_DETAILS';

export const GRANT_DISCOUNT_CODE = 'GRANT_DISCOUNT_CODE';
export const GRANT_DISCOUNT_CODE_SUCCESS = 'GRANT_DISCOUNT_CODE_SUCCESS';
export const GRANT_DISCOUNT_CODE_FAIL = 'GRANT_DISCOUNT_CODE_FAIL';
