import React from 'react';
import '../../../Utilities/style.css';
import '../Login/login.css';
import {connect} from 'react-redux';
import Tabs from '../../../Components/Tabs/Tabs';
import Card from '../../../Components/Card/Card';
import DetailLayout from '../../Layouts/DetailLayout/DetailLayout';
import Snackbar from '../../../Components/Snackbar/Snackbar';
import Button from '../../../Components/Button/Button';
import {getSquadList, getSquad} from '../Squads/SquadAxios';
import {logout} from '../Login/LoginAction';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';
import FileInput from '../../../Components/FileInput/FileInput';
import axios from 'axios';
import xlsx from 'xlsx';
import {URL} from '../../../Utilities/Constants';
import {saveAs} from 'file-saver';
import {getMatch} from '../Matches/AxioCalls';
import {fetchS3FileFromServer} from '../TOPublishedMatch/AxioCalls';
import {convertXlsxToJson} from '../../../Utilities/HelperFunctions';
import {isValidNumber} from '../../../Utilities/Helpers';
import ConfirmationModal from '../../../Components/ConfirmationModal';

/**
 * This is similar to UploadPlayers.js (copied and modified)
 */
class UploadFantasyResults extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: '',
            loading: true,
            uploadAnyFile: '',
            matchId: null,
            squads: [],
            players: [],
            scoringModel: null,
        };
    }

    componentWillMount() {
        const {login, history} = this.props;
        if (!login.userDetails.data) {
            history.push('/');
            this.props.logout();
        }
    }

    componentDidMount() {
        const {login, history, location, client} = this.props;
        if (!login.userDetails.data) {
            history.push('/');
            this.props.logout();
        }

        this.setState({matchId: location?.state?.matchId});
        this.props.getMatch(location?.state?.matchId, login.userDetails.data.accessToken);
    }

    componentDidUpdate(prevProps) {
        const {match} = this.props;

        if (match.getMatchSuccess && !prevProps.match.getMatchSuccess) {
            this.setState({
                squads: match.getMatch.data.squads,
                players: match.getMatch.data.players,
                scoringModel: match.getMatch.data.fantasyScoring,
                statsModel: match.getMatch.data.statsModel,
                loading: false,
            });
        } else if (match.getMatchFail && !prevProps.match.getMatchFail) {
            this.setState({errorMessage: match.errorMessage, loading: false});
        }
    }

    handleUploadResultsValidation = () => {
        const file = this.state.uploadAnyFile;
        if (!file) {
            this.setState({errorMessage: 'File is required!', loading: false});
            return;
        }

        convertXlsxToJson(file, (playerResult) => {
            const totalColumnData = {};
            for (const result of playerResult) {
                for (const key in result) {
                    if (isValidNumber(result[key])) totalColumnData[key] = (totalColumnData[key] || 0) + result[key];
                }
            }

            let attributeKeysString = '';
            for (const key in totalColumnData) {
                if (totalColumnData[key] === 0)
                    attributeKeysString = attributeKeysString ? `${attributeKeysString}, ${key}` : key;
            }
            if (attributeKeysString) {
                this.setState({
                    submitResultConfirmation: `Every player has zero score for this attribute (${attributeKeysString}), please verify!`,
                });
            } else {
                this.uploadResults();
            }
        });
    };

    uploadResults = () => {
        try {
            let file = this.state.uploadAnyFile;
            const {matchId} = this.state;

            let matchIdInfileName = (file.name || '').split('_');
            matchIdInfileName = (matchIdInfileName[matchIdInfileName.length - 1] || '').split('.')[0];

            if (matchIdInfileName !== matchId) {
                this.setState({loading: false, errorMessage: 'Match Id is not matching with file name.'});
                return;
            }

            this.setState({loading: true, submitResultConfirmation: ''});

            let request = new FormData();
            request.append('uploadFantasyResultsFile', file);
            request.append('matchId', matchId);

            let axiosConfig = {
                headers: {
                    Authorization: 'Bearer ' + this.props.login.userDetails.data.accessToken,
                },
            };

            axios
                .post(`${URL}/player/uploadFantasyResultsFile`, request, axiosConfig)
                .then((res) => {
                    if (res.data.status === 'fail') {
                        this.setState({errorMessage: res.data.errorData.errorReason, loading: false});
                        return;
                    }

                    if (res.data.msg !== null && res.data.msg !== undefined && res.data.msg !== '') {
                        //process uploaded file
                        this.submitFinalResults(res.data.msg);
                    } else {
                        this.setState({errorMessage: 'Something went wrong!', loading: false});
                        return;
                    }
                })
                .catch((err) => {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    this.setState({loading: false, errorMessage: errorMsg});
                });
        } catch (err) {
            console.error('uploadPlayers err: ', err);
            this.setState({errorMessage: 'Something went wrong!', loading: false});
        }
    };

    /**
     * Excel format:
     */
    async submitFinalResults(fileLocation) {
        const {match} = this.props;
        try {
            const {scoringModel} = this.state;
            let res = await fetchS3FileFromServer(fileLocation, this.props.login.userDetails.data.accessToken);
            //parse input excel with teamIds
            let data = new Uint8Array(res.data);
            let workbook = xlsx.read(data, {type: 'array'});
            let worksheet = workbook.Sheets[workbook.SheetNames[0]];
            //extracting sheet data as json
            let sheetData = xlsx.utils.sheet_to_json(worksheet, {raw: true});
            let results = [];
            for (let i = 0; i < sheetData.length; i++) {
                let playerResult = {};

                if (!scoringModel) {
                    //backwards compatibility
                    let isAlive = false;
                    if (sheetData[i].IsAlive && sheetData[i].IsAlive.trim().toLowerCase() === 'yes') {
                        isAlive = true;
                    }
                    playerResult = {
                        playerId: sheetData[i].playerId,
                        kills: sheetData[i].kills,
                        isAlive: isAlive,
                    };
                } else {
                    playerResult = sheetData[i];
                }
                results.push(playerResult);
            }
            let squadSheetName = workbook.SheetNames[1];
            let squadSheet = squadSheetName ? workbook.Sheets[squadSheetName] : false;
            let squadResults = squadSheet ? xlsx.utils.sheet_to_json(squadSheet, {raw: true}) : false;

            //axios
            let axiosConfig = {
                headers: {
                    Authorization: 'Bearer ' + this.props.login.userDetails.data.accessToken,
                },
            };
            let apiUrl = `${URL}/match/submitFinalResults`;
            if (match?.getMatch?.data?.isNewDisbursalFlowActivated) apiUrl = `${URL}/match/submitFinalResultsV2`;
            let req = {results, matchId: this.state.matchId, squadResults, fantasyResultFile: fileLocation};
            let uploadPlayersRes = await axios.post(apiUrl, req, axiosConfig);

            if (!uploadPlayersRes.data || uploadPlayersRes.data.status !== 'success') {
                this.setState({errorMessage: uploadPlayersRes.data.errorData.errorReason});
            } else {
                this.setState({errorMessage: 'Results submitted!'});
            }
        } catch (err) {
            const errorMsg = err?.response?.data?.msg || err.message;
            this.setState({loading: false, errorMessage: errorMsg});
        } finally {
            this.setState({
                loading: false,
            });
        }
    }

    s2ab(s) {
        let buf = new ArrayBuffer(s.length);
        let view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
    }

    downloadResultsTemplate() {
        try {
            this.setState({loading: true});
            const {squads, matchId, players, scoringModel, statsModel} = this.state;

            if (players && players.length === 0) {
                this.setState({errorMessage: 'No players found!', loading: false});
                return false;
            }

            let wb = xlsx.utils.book_new();
            wb.Props = {
                Title: 'Player Results ' + matchId,
                Subject: 'Player Results',
                Author: 'Fanclash',
                CreatedDate: new Date(),
            };

            wb.SheetNames.push('playerResult');

            let ws_data = [];

            //add headers
            let headers = ['squadName', 'playerId', 'ign'];
            if (statsModel) {
                headers.push('placement');
                let resultStatsModel = statsModel.playerStats.fantasyResult;
                for (let i in resultStatsModel) {
                    let statDescription = resultStatsModel[i];
                    if (statDescription.statType === 'BASE' && !statDescription.noUpload) {
                        headers.push(statDescription.name);
                    }
                }
                headers.push('notPlayed');
            } else if (scoringModel) {
                let values = Object.values(scoringModel.attributeList);
                headers.push('placement');
                values.forEach((attribute) => headers.push(attribute.attributeName));
                headers.push('notPlayed');
            } else {
                //backwards compatibility
                headers.push('kills');
                headers.push('IsAlive');
            }
            ws_data.push(headers);

            //rows
            /*for (let i = 0; i < squads.length; i++) {
                for (let j = 0; j < squads[i].players.length; j++) {
                    let players = squads[i].players[j];

                    let row = [];
                    row.push(squads[i].squadName);
                    row.push(players.id);
                    row.push(players.pubgName);
                    row.push(0);
                    row.push('NO');
                    ws_data.push(row);
                }
            }*/

            for (let i = 0; i < players.length; i++) {
                let player = players[i];
                let squadName = '';
                for (let j = 0; j < squads.length; j++) {
                    let isPresent = squads[j].players.filter((p) => p.id === player.id);
                    if (isPresent.length > 0) {
                        squadName = squads[j].squadName;
                    }
                }
                let row = [];
                row.push(squadName);
                row.push(player.id);
                row.push(player.pubgName);
                if (!scoringModel) {
                    //backwards compatibility
                    row.push(0);
                    row.push('NO');
                } else {
                    for (let k = 0; k < headers.length - 3; k++) {
                        row.push(0);
                    }
                }
                ws_data.push(row);
            }

            //create excel
            let ws = xlsx.utils.aoa_to_sheet(ws_data);
            wb.Sheets['playerResult'] = ws;

            //squad results sheet
            wb.SheetNames.push('squadResult');
            let squadWS = [];
            squadWS.push(['squadName', 'squadId', 'roundsPlayed', 'roundsWon', 'roundsLost', 'rank']);
            for (let i = 0; i < squads.length; i++) {
                squadWS.push([squads[i].squadName, squads[i].id]);
            }
            wb.Sheets['squadResult'] = xlsx.utils.aoa_to_sheet(squadWS);
            let wbout = xlsx.write(wb, {bookType: 'xlsx', type: 'binary'});

            saveAs(
                new Blob([this.s2ab(wbout)], {type: 'application/octet-stream'}),
                'player_results_' + matchId + '.xlsx',
            );
            this.setState({loading: false});
        } catch (err) {
            console.error('downloadMatchDetails err: ', err);
            this.setState({errorMessage: 'Something went wrong!', loading: false});
        }
    }

    downloadExcel() {
        saveAs(
            new Blob([this.s2ab(this.state.resultsBook)], {type: 'application/octet-stream'}),
            'template_' + this.state.matchId + '.xlsx',
        );
    }

    renderUploadPlayersTab() {
        return (
            <div>
                <div className="grid-item margin-top-20" style={{border: '2px solid #ffc954', padding: 15}}>
                    <span style={{color: '#ffc954', fontSize: 14}}>
                        NOTE:
                        <br />
                        - All players must be present in sheet. <br />
                        - 'Placement' points are awarded only after marking match ENDED. <br />
                        <br />
                        To upload results:
                        <br />
                        - Download template sheet.
                        <br />
                        - Update attributes, 1 for true, 0 for false for fields with yes/no as options.
                        <br />
                        - placement 1 gets highest points, 0 is considered unranked, kills and deaths are multiplied as
                        per the multiplier in scoring config. . <br />- Submit.
                    </span>

                    <div style={{marginTop: 20}}>
                        <Button
                            onClick={this.downloadResultsTemplate.bind(this)}
                            buttonText="Download results template"
                        />
                    </div>

                    <div className="grid-item margin-top-20">
                        <FileInput
                            label="Upload Results"
                            type="file"
                            name="upload_players"
                            onChange={(value) => this.setState({uploadAnyFile: value})}
                        />
                    </div>
                    <Button onClick={this.handleUploadResultsValidation} buttonText="Submit Results" />
                </div>
            </div>
        );
    }

    render() {
        const {errorMessage, loading, submitResultConfirmation} = this.state;
        const {history} = this.props;
        let title = 'Upload Players Data';

        return (
            <DetailLayout location="Players" history={history} centerTitle={title} id="">
                {!!loading && <LoadingModal open={loading} />}
                {!!errorMessage && (
                    <Snackbar
                        open={!!errorMessage}
                        message={errorMessage}
                        onClose={() => this.setState({errorMessage: ''})}
                    />
                )}

                {!!submitResultConfirmation && (
                    <ConfirmationModal
                        open={!!submitResultConfirmation}
                        confirmButtonText="Submit and continue"
                        handleClose={() => this.setState({submitResultConfirmation: ''})}
                        confirmationText={submitResultConfirmation}
                        handleOnConfirm={this.uploadResults}
                    />
                )}

                <div className="detail-container margin-top-20">
                    <div className="grid-item"></div>
                    <div className="grid-item">
                        <Card>
                            <div>
                                <div className="card-single-grid-container">
                                    <Tabs tab1Label="Upload Players" panelA={this.renderUploadPlayersTab.bind(this)} />
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
            </DetailLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        squad: state.squad,
        player: state.player,
        match: state.match,
        client: state.client,
    };
};

export default connect(mapStateToProps, {
    getSquad,
    logout,
    getSquadList,
    getMatch,
})(UploadFantasyResults);
