import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core';
import FileCopy from '@material-ui/icons/FileCopy';
import moment from 'moment';

import {copyText, next, prev} from '../../../../Utilities/HelperFunctions';
import Snackbar from '../../../../Components/Snackbar/Snackbar';
import {fetchGlacierSeries} from '../action';
import {useDebounce, usePrevious} from '../../../../Utilities/hooks';
import {ENTER, NEXT, SORT_ORDER_OP} from '../../../../Utilities/Constants';
import Pagination from '../../../../Components/Pagination';
import LoadingModal from '../../../../Components/LoadingModal/LoadingModal';
import DropDown from '../../../../Components/Dropdown/Dropdown';
import Button from '../../../../Components/Button/Button';

const ToCalendarSeriesTable = (props) => {
    const {tournamentId, dateRange} = props;
    const initialFilter = {offset: 0, limit: 10};
    const dispatch = useDispatch();
    const elementRef = useRef(null);
    const login = useSelector((state) => state.login);
    const toCalendar = useSelector((state) => state.toCalendar);
    const prevToCalendar = usePrevious(toCalendar);
    const [filters, setFilters] = useState(initialFilter);
    const {limit, offset, immediate, sorting, ..._filters} = filters;
    const debouncedFilters = useDebounce(filters, {immediate: immediate});
    const [state, setState] = useState({});
    const [series, setSeries] = useState({});

    const {errorMsg, isFetchingStart, loading} = state;

    const page = offset / limit + 1;
    const lastPage = Math.ceil((series?.count || 0) / limit) || 1;

    useEffect(() => {
        if (toCalendar.isFetchingGlacierSeries && !prevToCalendar.isFetchingGlacierSeries && !!isFetchingStart) {
            handleOnStateChange({loading: true});
        }

        if (
            toCalendar.isFetchingGlacierSeriesSuccess &&
            !prevToCalendar.isFetchingGlacierSeriesSuccess &&
            !!isFetchingStart
        ) {
            setSeries(toCalendar?.glacierSeries || {});
            handleOnStateChange({isFetchingStart: false, loading: false});
            elementRef.current.scrollIntoView({behavior: 'smooth', block: 'start'});
        }

        if (
            toCalendar.isFetchingGlacierSeriesFail &&
            !prevToCalendar.isFetchingGlacierSeriesFail &&
            !!isFetchingStart
        ) {
            handleOnStateChange({errorMsg: toCalendar.message.msg, isFetchingStart: false, loading: false});
        }
    }, [toCalendar]);

    useEffect(() => {
        if (login?.userDetails?.data) handleFetchGlacierSeries();
    }, [debouncedFilters]);

    const handleFetchGlacierSeries = () => {
        const query = {},
            data = {..._filters};
        if (sorting) {
            query.sort = sorting?.split('/')?.[0];
            query.order = sorting?.split('/')?.[1];
        }
        if (dateRange?.start && dateRange?.end) {
            data.startDateRange = {
                from: moment(dateRange.start).startOf('day').valueOf(),
                to: moment(dateRange.end).endOf('day').valueOf(),
            };
        }
        handleOnStateChange({isFetchingStart: true});
        dispatch(fetchGlacierSeries({filters: {...data, tournamentId}, offset, limit, detailed: true, ...query}));
    };

    const handleOnStateChange = (stateObj = {}) => {
        setState((prevState) => ({...prevState, ...stateObj}));
    };

    const handleFilter = (field, value, debounce = true) => {
        const tempFilter = {};
        if (field !== ENTER) tempFilter[field] = value;
        setFilters({...filters, ...tempFilter, offset: 0, immediate: !debounce});
    };

    const onReset = () => {
        setFilters({...initialFilter, immediate: true});
    };

    const onNextORPrev = (action) => {
        const pageObj = action === NEXT ? next({page}, {lastPage}) : prev({page});
        if (pageObj) setFilters({...filters, offset: limit * (pageObj?.page - 1), immediate: true});
    };

    const handleCopy = (text) => {
        copyText(text, (msg) => handleOnStateChange({errorMsg: msg}));
    };

    return (
        <div className="mt-15" ref={elementRef}>
            {!!loading && <LoadingModal open={!!loading} />}
            {!!errorMsg && (
                <Snackbar open={!!errorMsg} message={errorMsg} onClose={() => handleOnStateChange({errorMsg: ''})} />
            )}

            <div className="filter-container my-10">
                <DropDown
                    label="Sorting"
                    value={filters.sorting || ''}
                    menuItems={SORT_ORDER_OP}
                    onChange={(value) => handleFilter('sorting', value, false)}
                />
                <Button buttonText="Reset" onClick={onReset} />
            </div>

            <TableContainer>
                <Table size="small" border={1}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Series Name</TableCell>
                            <TableCell>Start Date/Time</TableCell>
                            <TableCell>Team A vs Team B</TableCell>
                            <TableCell>No of Matches</TableCell>
                            <TableCell>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(series?.list || []).map((s) => (
                            <TableRow key={s?.id}>
                                <TableCell>
                                    {s?.name} <br />
                                    <span className="clickable-icon" onClick={() => handleCopy(s?.id)}>
                                        {s?.id}
                                        <FileCopy titleAccess="Copy" className="ml-10" fontSize="small" />
                                    </span>
                                </TableCell>
                                <TableCell>{!!s?.start && moment(s?.start).format('DD/MM/YYYY HH:mm')}</TableCell>
                                <TableCell>
                                    {s?.participants?.[0]?.roster?.team?.name || '-'} vs{' '}
                                    {s?.participants?.[1]?.roster?.team?.name || '-'}
                                </TableCell>
                                <TableCell>{s?.matchCount}</TableCell>
                                <TableCell>{s?.lifecycle}</TableCell>
                            </TableRow>
                        ))}
                        {!series?.list?.length && (
                            <TableRow>
                                <TableCell className="text-center" colSpan="10">
                                    No Data Available
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <br />
            <Pagination
                page={page}
                disableNext={page === lastPage}
                onNextClick={() => onNextORPrev(NEXT)}
                onPrevClick={onNextORPrev}
            />
        </div>
    );
};

ToCalendarSeriesTable.propTypes = {
    tournamentId: PropTypes.string,
    dateRange: PropTypes.any,
};

ToCalendarSeriesTable.defaultProps = {
    tournamentId: '',
    dateRange: null,
};

export default ToCalendarSeriesTable;
