import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import './Card.css';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
    root: {
        minWidth: 'auto',
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

function SimpleCard(props) {
    const classes = useStyles();
    const {className, onClick} = props;
    return (
        <Card className={`${classes.root} ${className}`} onClick={onClick}>
            <CardContent>{props.children}</CardContent>
        </Card>
    );
}

SimpleCard.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
};

SimpleCard.defaultProps = {
    className: '',
    onClick: () => {},
};

export default SimpleCard;
