import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';

import {fetchGlacierTournaments} from './action';
import {logout} from '../Login/LoginAction';
import {next, prev} from '../../../Utilities/HelperFunctions';

import TableLayout from '../../Layouts/TableLayout/TableLayout';
import Snackbar from '../../../Components/Snackbar/Snackbar';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';
import FilterInput from '../../../Components/FilterInput';
import Button from '../../../Components/Button/Button';
import {ENTER, NEXT, SORT_ORDER_OP, TOURNAMENT_STATUS_OP, TOURNAMENT_TIERS} from '../../../Utilities/Constants';
import {useDebounce, usePrevious} from '../../../Utilities/hooks';
import DropDown from '../../../Components/Dropdown/Dropdown';
import {getTOGameList} from '../TOGame/AxioCalls';
import Pagination from '../../../Components/Pagination';
import ToCalendarCard from './ToCalendarCard';
import DateRangeSelect from '../../../Components/DateRangeSelect';
import MuiButton from '../../../Components/MuiButton/MuiButton';
import ImportTournamentModal from '../FantasyTournament/ImportTournamentModal/ImportTournamentModal';

const ToCalendar = (props) => {
    const {history} = props;
    const initialFilter = {offset: 0, limit: 20};
    const dispatch = useDispatch();
    const client = useSelector((state) => state.client);
    const login = useSelector((state) => state.login);
    const toCalendar = useSelector((state) => state.toCalendar);
    const prevToCalendar = usePrevious(toCalendar);
    const toGame = useSelector((state) => state.toGame);
    const prevToGame = usePrevious(toGame);
    const [gameOptions, setGameOptions] = useState([]);
    const [filters, setFilters] = useState(initialFilter);
    const {limit, offset, immediate, sorting, ..._filters} = filters;
    const debouncedFilters = useDebounce(filters, {immediate: immediate});
    const [state, setState] = useState({});
    const [tournaments, setTournaments] = useState({});
    const [importTournamentModal, setImportTournamentModal] = useState(null);

    const page = offset / limit + 1;
    const lastPage = Math.ceil((tournaments?.count || 0) / limit) || 1;

    const {errorMsg} = state;
    const loading = !!toCalendar.isFetchingGlacierTO || false;

    useEffect(() => {
        if (!login?.userDetails?.data) {
            history.push('/');
            dispatch(logout());
            return;
        }
        dispatch(getTOGameList({listType: 'ALL'}, login?.userDetails?.data?.accessToken, client.selectedClient));
    }, []);

    useEffect(() => {
        if (login?.userDetails?.data) handleFetchGlacierTo();
    }, [debouncedFilters]);

    useEffect(() => {
        if (toCalendar.isFetchingGlacierTOSuccess && !prevToCalendar.isFetchingGlacierTOSuccess) {
            setTournaments(toCalendar?.glacierTO || {});
        }

        if (toCalendar.isFetchingGlacierTOFail && !prevToCalendar.isFetchingGlacierTOFail) {
            handleOnStateChange({errorMsg: toCalendar.message.msg});
        }

        if (toGame.TOGameListSuccess && !prevToGame.TOGameListSuccess) {
            const temp = toGame?.TOGameList?.data?.data?.map((item) => ({text: item.gameName, value: item.id}));
            setGameOptions(temp);
        }
        if (toGame.TOGameListFail && !prevToGame.TOGameListFail) {
            handleOnStateChange({errorMsg: toGame.errorMessage});
        }
    }, [toCalendar, toGame]);

    const handleFetchGlacierTo = () => {
        const query = {};
        if (sorting) {
            query.sort = sorting?.split('/')?.[0];
            query.order = sorting?.split('/')?.[1];
        }
        dispatch(fetchGlacierTournaments({filters: _filters, offset, limit, detailed: true, ...query}));
    };

    const onReset = () => {
        setFilters({...initialFilter, immediate: true});
    };

    const onNextORPrev = (action) => {
        const pageObj = action === NEXT ? next({page}, {lastPage}) : prev({page});
        if (pageObj) setFilters({...filters, offset: limit * (pageObj?.page - 1), immediate: true});
    };

    const handleFilter = (field, value, debounce = true) => {
        const tempFilter = {};
        if (field === 'dates') {
            tempFilter.start = value.start ? moment(value.start).endOf('day').valueOf() : null;
            tempFilter.end = value.end ? moment(value.end).startOf('day').valueOf() : null;
        } else if (field !== ENTER) tempFilter[field] = value;
        setFilters({...filters, ...tempFilter, offset: 0, immediate: !debounce});
    };

    const handleOnStateChange = (stateObj = {}) => {
        setState((prevState) => ({...prevState, ...stateObj}));
    };

    const onTournamentImportSuccess = () => {
        setImportTournamentModal(null);
    };

    return (
        <TableLayout location="TO Calendar" history={history} hideSearchBar>
            {!!loading && <LoadingModal open={!!loading} />}
            {!!errorMsg && (
                <Snackbar open={!!errorMsg} message={errorMsg} onClose={() => handleOnStateChange({errorMsg: ''})} />
            )}
            {!!importTournamentModal && (
                <ImportTournamentModal
                    open={!!importTournamentModal}
                    onClickClose={() => setImportTournamentModal(null)}
                    onTournamentImportSuccess={onTournamentImportSuccess}
                />
            )}

            <div className="filter-container my-10">
                <FilterInput
                    placeholder="Name"
                    value={filters.name || ''}
                    onChange={(value) => handleFilter('name', value)}
                    onEnter={() => handleFilter(ENTER, '', false)}
                />
                <DateRangeSelect
                    label="Date range"
                    startDate={filters?.start}
                    endDate={filters?.end}
                    isClearable={true}
                    onChange={(dates) => handleFilter('dates', dates, false)}
                />
                <DropDown
                    label="Game"
                    value={filters.gameId || ''}
                    menuItems={gameOptions || []}
                    onChange={(value) => handleFilter('gameId', value, false)}
                />
                <DropDown
                    label="Tier"
                    value={filters.tier || ''}
                    menuItems={TOURNAMENT_TIERS}
                    onChange={(value) => handleFilter('tier', value, false)}
                />
                <DropDown
                    label="Status"
                    value={filters.status || ''}
                    menuItems={TOURNAMENT_STATUS_OP}
                    onChange={(value) => handleFilter('status', value, false)}
                />
                <DropDown
                    label="Sorting"
                    value={filters.sorting || ''}
                    menuItems={SORT_ORDER_OP}
                    onChange={(value) => handleFilter('sorting', value, false)}
                />
                <Button buttonText="Reset" onClick={onReset} />
            </div>
            <div className="my-10">
                <MuiButton buttonText="Import Tournament" onButtonClick={() => setImportTournamentModal({})} />
            </div>
            {(tournaments?.list || []).map((to) => (
                <ToCalendarCard key={to?.id} tournament={to} dateRange={filters} />
            ))}
            <br />
            <Pagination
                page={page}
                disableNext={page === lastPage}
                onNextClick={() => onNextORPrev(NEXT)}
                onPrevClick={onNextORPrev}
            />
        </TableLayout>
    );
};

export default ToCalendar;
