import React from 'react';
import '../../../Utilities/style.css';
import '../Login/login.css';
import {connect} from 'react-redux';
import Tabs from '../../../Components/Tabs/Tabs';
import Card from '../../../Components/Card/Card';
import DetailLayout from '../../Layouts/DetailLayout/DetailLayout';
import Input from '../../../Components/Input/Input';
import Snackbar from '../../../Components/Snackbar/Snackbar';
import {matchOfTheDay} from './AxioCalls';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';
import {Checkbox, FormControlLabel} from '@material-ui/core';

class MatchOfTheDay extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            matchStatus: '',
            modalName: '',
            disabled: false,
            matchId: null,
            notification: null,
            errorMessage: null,
            snackbarOpen: false,
        };
    }

    /**
     * function to save entered data in state
     * @param {string} field field names from the form
     * @param {*} value value in the field
     */
    handleInputChange(field, value) {
        this.setState({
            [field]: value,
            snackbarOpen: false,
            errorMessage: '',
        });
    }

    handleCheckBox = (e) => {
        this.setState({
            removeMatchOfTheDay: e.target.checked,
            snackbarOpen: false,
            errorMessage: '',
        });
    };

    componentWillMount() {
        const {login, history} = this.props;
        if (!login.userDetails.data) {
            history.push('/');
            this.props.logout();
        }
    }

    componentDidMount() {
        const {login, history} = this.props;
        if (!login.userDetails.data) {
            history.push('/');
            this.props.logout();
        }
    }

    componentDidUpdate(prevProps) {
        const {history, match} = this.props;
        if (match.markMatchOfTheDayInit && !prevProps.match.markMatchOfTheDayInit) {
            this.setState({loading: true});
        } else if (match.markMatchOfTheDaySuccess && !prevProps.match.markMatchOfTheDaySuccess) {
            this.setState({
                snackbarOpen: true,
                loading: false,
                errorMessage: 'Match Set as Match of the Day',
            });
            history.push('/matches');
        } else if (match.markMatchOfTheDayFail && !prevProps.match.markMatchOfTheDayFail) {
            this.setState({
                snackbarOpen: true,
                loading: false,
                errorMessage: match.markMatchOfTheDayResponse,
            });
        }
    }

    /**
     * on click of save button validate match ID and set match of the day
     */
    setMatchOfTheDay() {
        const {matchId, removeMatchOfTheDay} = this.state;
        const {login, client} = this.props;

        if (!matchId) {
            this.setState({snackbarOpen: true, errorMessage: 'Please select a matchId'});
            return;
        }

        let data = {matchId};
        if (removeMatchOfTheDay) data.removeMatchOfTheDay = removeMatchOfTheDay;
        this.props.matchOfTheDay(data, login.userDetails.data.accessToken, client.selectedClient);
    }

    /**
     * Ui for tab A
     */
    renderTabA() {
        const {matchId, removeMatchOfTheDay} = this.state;
        return (
            <div>
                <div className="card-fullgrid-container margin-top-20 ">
                    <Input
                        className="mb-15"
                        label="Match Id"
                        type="text"
                        name="matchId"
                        value={matchId}
                        onChange={this.handleInputChange.bind(this, 'matchId')}
                        placeholder="Enter "
                    />
                    <br />
                    <br />
                    <FormControlLabel
                        label="Enable If you want to remove match of the day"
                        control={
                            <Checkbox
                                checked={!!removeMatchOfTheDay}
                                color="primary"
                                name="removeMatchOfTheDay"
                                onChange={this.handleCheckBox}
                            />
                        }
                    />
                </div>
            </div>
        );
    }

    /**
     * on click of back button navigate to match table
     */
    onBackClick() {
        const {history} = this.props;
        history.push('/matches');
    }

    render() {
        const {errorMessage, loading, snackbarOpen} = this.state;
        let title = 'Set Match Of The Day';
        const {history} = this.props;
        return (
            <DetailLayout
                location="addMatch"
                history={history}
                upcoming={'cancelMatch'}
                centerTitle={title}
                onSave={this.setMatchOfTheDay.bind(this)}
                onBackClick={this.onBackClick.bind(this)}
            >
                <Snackbar
                    open={snackbarOpen}
                    message={errorMessage}
                    onClose={() => this.setState({snackbarOpen: false})}
                />
                <LoadingModal open={loading} />

                <div className="detail-container margin-top-20">
                    <div className="grid-item">
                        <div className="text-grey">Only Upcoming Matches can be set as Match of the day.</div>
                    </div>
                    <div className="grid-item">
                        <Card>
                            <div>
                                <div className="card-single-grid-container">
                                    <Tabs tab1Label="" panelA={this.renderTabA.bind(this)} />
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
            </DetailLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        match: state.match,
        client: state.client,
    };
};

export default connect(mapStateToProps, {matchOfTheDay})(MatchOfTheDay);
