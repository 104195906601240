import React from 'react';
import '../../../Utilities/style.css';
import '../Login/login.css';
import {connect} from 'react-redux';
import Tabs from '../../../Components/Tabs/Tabs';
import Card from '../../../Components/Card/Card';
import DetailLayout from '../../Layouts/DetailLayout/DetailLayout';
import Input from '../../../Components/Input/Input';
import Snackbar from '../../../Components/Snackbar/Snackbar';
import moment from 'moment';
import {rescheduleTOMatch} from './AxioCalls';
import {MuiPickersUtilsProvider, DateTimePicker} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';

class RescheduleTOMatch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            matchStatus: '',
            modalName: '',
            disabled: false,
            matchId: null,
            matchStartTime: null,
            errorMessage: null,
            snackbarOpen: false,
        };
    }

    /**
     * function to save entered data in state
     * @param {string} field field names from the form
     * @param {*} value value in the field
     */
    handleInputChange(field, value) {
        this.setState({
            [field]: value,
            snackbarOpen: false,
            errorMessage: '',
        });
    }

    componentWillMount() {
        const {login, history} = this.props;
        if (!login.userDetails.data) {
            history.push('/');
            this.props.logout();
        }
    }

    componentDidMount() {
        const {login, history} = this.props;
        if (!login.userDetails.data) {
            history.push('/');
            this.props.logout();
        }
    }

    componentDidUpdate(prevProps) {
        const {history, match} = this.props;
        if (match.rescheduleTOMatchSuccess && !prevProps.match.rescheduleTOMatchSuccess) {
            history.push('/toMatches');
        } else if (match.rescheduleTOMatchFail && !prevProps.match.rescheduleTOMatchFail) {
            this.setState({
                snackbarOpen: true,
                errorMessage: match.errorMessage,
                loading: false,
            });
        }
    }

    /**
     * on click of save button validate and save match
     */
    onSave() {
        const {matchId, matchStartTime} = this.state;
        const {login} = this.props;

        const today = moment();
        const matchdate = moment(matchStartTime);

        if (matchStartTime === null || matchStartTime === undefined || matchdate < today) {
            this.setState({
                snackbarOpen: true,
                errorMessage: 'Please select a valid future start time',
            });
        } else if (matchId === null || matchId === undefined) {
            this.setState({
                snackbarOpen: true,
                errorMessage: 'Please select a matchId',
            });
        }

        let selectedTime = moment(matchStartTime, 'DD/MM/YYY H:mm');
        let timestamp = moment(selectedTime).valueOf();
        this.setState({loading: true});
        this.props.rescheduleTOMatch(matchId, timestamp, login.userDetails.data.accessToken);
    }

    /**
     * Ui for tab A
     */
    renderTabA() {
        const {matchId, matchStartTime} = this.state;
        return (
            <div>
                <div className="card-fullgrid-container margin-top-20 ">
                    <Input
                        label="Match Id"
                        type="text"
                        name="matchId"
                        value={matchId}
                        onChange={this.handleInputChange.bind(this, 'matchId')}
                        placeholder="Enter "
                    />
                </div>
                <div className="card-grid-container nopadding margin-top-20">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DateTimePicker
                            margin="normal"
                            id="date-picker-dialog"
                            label="Date"
                            value={matchStartTime}
                            minDate={new Date()}
                            invalidDateMessage={''}
                            onChange={this.handleInputChange.bind(this, 'matchStartTime')}
                        />
                    </MuiPickersUtilsProvider>
                </div>
            </div>
        );
    }

    /**
     * on click of back button navigate to match table
     */
    onBackClick() {
        const {history} = this.props;
        history.push('/TOMatches');
    }

    render() {
        const {errorMessage, loading, snackbarOpen} = this.state;
        let title = 'Reschedule Match';
        const {history} = this.props;

        return (
            <DetailLayout
                location="addMatch"
                history={history}
                centerTitle={title}
                onSave={this.onSave.bind(this)}
                onBackClick={this.onBackClick.bind(this)}
            >
                <Snackbar
                    open={snackbarOpen}
                    message={errorMessage}
                    onClose={() => this.setState({snackbarOpen: false})}
                />
                <LoadingModal open={loading} />

                <div className="detail-container margin-top-20">
                    <div className="grid-item">
                        <div className="text-grey">Only reschedule Matches in draft/upcoming.</div>
                    </div>
                    <div className="grid-item">
                        <Card>
                            <div>
                                <div className="card-single-grid-container">
                                    <Tabs tab1Label="" panelA={this.renderTabA.bind(this)} />
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className="grid-item"></div>
                </div>
            </DetailLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        match: state.toMatch,
    };
};

export default connect(mapStateToProps, {rescheduleTOMatch})(RescheduleTOMatch);
