import {
    CONTEST_LIST,
    CONTEST_LIST_SUCCESS,
    CONTEST_LIST_FAIL,
    ADD_CONTEST,
    ADD_CONTEST_SUCCESS,
    ADD_CONTEST_FAIL,
    UPDATE_CONTEST,
    UPDATE_CONTEST_SUCCESS,
    UPDATE_CONTEST_FAIL,
    DELETE_CONTEST,
    DELETE_CONTEST_SUCCESS,
    DELETE_CONTEST_FAIL,
    GET_CONTEST,
    GET_CONTEST_SUCCESS,
    GET_CONTEST_FAIL,
    EDIT_CONTEST,
    COPY_CONTEST_FROM_MATCH,
    COPY_CONTEST_FROM_MATCH_SUCCESS,
    COPY_CONTEST_FROM_MATCH_FAIL,
} from './Constants';

/**
 * list all contest
 */
export function listContests() {
    return {
        type: CONTEST_LIST,
    };
}

/**
 * handle list contest api success
 * @param {*} data data from api
 */
export function listContestsSuccess(data) {
    return {
        type: CONTEST_LIST_SUCCESS,
        payload: data,
    };
}

/**
 * handle list contest api failure
 * @param {*} error error from api
 */
export function listContestsFail(error) {
    return {
        type: CONTEST_LIST_FAIL,
        payload: error,
    };
}

/**
 * add contest
 */
export function addContest() {
    return {
        type: ADD_CONTEST,
    };
}

/**
 * handle add contest api success
 * @param {*} data data from api
 */
export function addContestSuccess(data) {
    return {
        type: ADD_CONTEST_SUCCESS,
        payload: data,
    };
}

/**
 * handle add contest api failure
 * @param {*} error error from api
 */
export function addContestFail(error) {
    return {
        type: ADD_CONTEST_FAIL,
        payload: error,
    };
}

/**
 * update contest
 */
export function updateContestRequest() {
    return {
        type: UPDATE_CONTEST,
    };
}

/**
 *handle update contest api success
 * @param {*} data data from api
 */
export function updateContestSuccess(data) {
    return {
        type: UPDATE_CONTEST_SUCCESS,
        payload: data,
    };
}

/**
 *handle update contest api failure
 * @param {*} error error from api
 */
export function updateContestFail(error) {
    return {
        type: UPDATE_CONTEST_FAIL,
        payload: error,
    };
}

/**
 * delete contest
 */
export function deleteContestRequest() {
    return {
        type: DELETE_CONTEST,
    };
}

/**
 *handle delete contest api success
 * @param {*} data data from api
 */
export function deleteContestSuccess(data) {
    return {
        type: DELETE_CONTEST_SUCCESS,
        payload: data,
    };
}

/**
 *handle delete contest api failure
 * @param {*} error error from api
 */
export function deleteContestFail(error) {
    return {
        type: DELETE_CONTEST_FAIL,
        payload: error,
    };
}

/**
 * get single contest
 */
export function getContestRequest() {
    return {
        type: GET_CONTEST,
    };
}

/**
 *handle get single contest api success
 * @param {*} data data from api
 */
export function getContestSuccess(data) {
    return {
        type: GET_CONTEST_SUCCESS,
        payload: data,
    };
}

/**
 *handle get single contest api failure
 * @param {*} error error from api
 */
export function getContestFail(error) {
    return {
        type: GET_CONTEST_FAIL,
        payload: error,
    };
}

/**
 * single cotest Details
 * @param {*} contest contest details
 */
export function editContest(contest) {
    return {
        type: EDIT_CONTEST,
        payload: contest,
    };
}

export function copyContest() {
    return {type: COPY_CONTEST_FROM_MATCH};
}

export function copyContestSuccess(data) {
    return {type: COPY_CONTEST_FROM_MATCH_SUCCESS, payload: data};
}

export function copyContestFail(error) {
    return {type: COPY_CONTEST_FROM_MATCH_FAIL, payload: error};
}
