import {
    TOMATCH_LIST,
    TOMATCH_LIST_SUCCESS,
    TOMATCH_LIST_FAIL,
    ADD_TOMATCH,
    ADD_TOMATCH_SUCCESS,
    ADD_TOMATCH_FAIL,
    RESCHEDULE_TO_MATCH,
    RESCHEDULE_TO_MATCH_SUCCESS,
    RESCHEDULE_TO_MATCH_FAIL,
    UPDATE_TOMATCH,
    UPDATE_TOMATCH_SUCCESS,
    UPDATE_TOMATCH_FAIL,
    DELETE_TOMATCH,
    DELETE_TOMATCH_SUCCESS,
    DELETE_TOMATCH_FAIL,
    GET_TOMATCH,
    GET_TOMATCH_SUCCESS,
    GET_TOMATCH_FAIL,
    EDIT_TOMATCH,
} from './Constants';

const initialState = {
    TOMatchListRequest: false,
    TOMatchListSuccess: false,
    TOMatchListFail: false,
    TOMatchList: [],
    errorMessage: '',
    addTOMatchRequest: false,
    addTOMatchSuccess: false,
    addTOMatchFail: false,
    addTOMatch: [],
    rescheduleTOMatchRequest: false,
    rescheduleTOMatchSuccess: false,
    rescheduleTOMatchFail: false,
    rescheduleTOMatch: [],
    updateTOMatchRequest: false,
    updateTOMatchSuccess: false,
    updateTOMatchFail: false,
    updateTOMatch: [],
    deleteTOMatchRequest: false,
    deleteTOMatchSuccess: false,
    deleteTOMatchFail: false,
    deleteTOMatch: [],
    getTOMatchRequest: false,
    getTOMatchSuccess: false,
    getTOMatchFail: false,
    getTOMatch: [],
    editTOMatch: [],
};

const toMatchReducer = (state = initialState, action) => {
    switch (action.type) {
        case TOMATCH_LIST:
            return {
                ...state,
                TOMatchListRequest: true,
                TOMatchListSuccess: false,
                TOMatchListFail: false,
                TOMatchList: [],
                errorMessage: '',
            };

        case TOMATCH_LIST_SUCCESS:
            // console.log('Success case is being triggered');
            return {
                ...state,
                TOMatchListRequest: false,
                TOMatchListSuccess: true,
                TOMatchListFail: false,
                TOMatchList: action.payload.data,
                errorMessage: '',
            };

        case TOMATCH_LIST_FAIL:
            return {
                ...state,
                TOMatchListRequest: false,
                TOMatchListSuccess: false,
                TOMatchListFail: true,
                errorMessage: action.payload,
            };
        // ADD match
        case ADD_TOMATCH:
            return {
                ...state,
                addTOMatchRequest: false,
                addTOMatchSuccess: false,
                addTOMatchFail: false,
                addTOMatch: [],
                errorMessage: '',
            };

        case ADD_TOMATCH_SUCCESS:
            return {
                ...state,
                addTOMatchRequest: false,
                addTOMatchSuccess: true,
                addTOMatchFail: false,
                addTOMatch: action.payload.data,
                errorMessage: '',
            };

        case ADD_TOMATCH_FAIL:
            return {
                ...state,
                addTOMatchRequest: false,
                addTOMatchSuccess: false,
                addTOMatchFail: true,
                errorMessage: action.payload,
            };

        // update TOMatch
        case RESCHEDULE_TO_MATCH:
            return {
                ...state,
                rescheduleTOMatchRequest: true,
                rescheduleTOMatchSuccess: false,
                rescheduleTOMatchFail: false,
                errorMessage: '',
            };

        case RESCHEDULE_TO_MATCH_SUCCESS:
            return {
                ...state,
                rescheduleTOMatchRequest: false,
                rescheduleTOMatchSuccess: true,
                rescheduleTOMatchFail: false,
                rescheduleTOMatch: action.payload.data,
                errorMessage: '',
            };

        case RESCHEDULE_TO_MATCH_FAIL:
            return {
                ...state,
                rescheduleTOMatchRequest: false,
                rescheduleTOMatchSuccess: false,
                rescheduleTOMatchFail: true,
                errorMessage: action.payload,
            };

        //update TOMatch
        case UPDATE_TOMATCH:
            return {
                ...state,
                updateTOMatchRequest: true,
                updateTOMatchSuccess: false,
                updateTOMatchFail: false,
                updateTOMatch: [],
                errorMessage: '',
            };

        case UPDATE_TOMATCH_SUCCESS:
            return {
                ...state,
                updateTOMatchRequest: false,
                updateTOMatchSuccess: true,
                updateTOMatchFail: false,
                updateTOMatch: action.payload.data,
                errorMessage: '',
            };

        case UPDATE_TOMATCH_FAIL:
            return {
                ...state,
                updateTOMatchRequest: false,
                updateTOMatchSuccess: false,
                updateTOMatchFail: true,
                errorMessage: action.payload,
            };

        // DELETE TOMATCH
        case DELETE_TOMATCH:
            return {
                ...state,
                deleteTOMatchRequest: true,
                deleteTOMatchSuccess: false,
                deleteTOMatchFail: false,
                deleteTOMatch: [],
                errorMessage: '',
            };

        case DELETE_TOMATCH_SUCCESS:
            return {
                ...state,
                deleteTOMatchRequest: false,
                deleteTOMatchSuccess: true,
                deleteTOMatchFail: false,
                deleteTOMatch: action.payload.data,
                errorMessage: '',
            };

        case DELETE_TOMATCH_FAIL:
            return {
                ...state,
                deleteTOMatchRequest: false,
                deleteTOMatchSuccess: false,
                deleteTOMatchFail: true,
                errorMessage: action.payload,
            };

        // get single TOMatch
        case GET_TOMATCH:
            return {
                ...state,
                getTOMatchRequest: true,
                getTOMatchSuccess: false,
                getTOMatchFail: false,
                getTOMatch: [],
                errorMessage: '',
            };

        case GET_TOMATCH_SUCCESS:
            return {
                ...state,
                getTOMatchRequest: false,
                getTOMatchSuccess: true,
                getTOMatchFail: false,
                getTOMatch: action.payload.data,
                errorMessage: '',
            };

        case GET_TOMATCH_FAIL:
            return {
                ...state,
                getTOMatchRequest: false,
                getTOMatchSuccess: false,
                getTOMatchFail: true,
                errorMessage: action.payload,
            };
        // EDIT TOMATCH
        case EDIT_TOMATCH:
            return {
                ...state,
                editTOMatch: action.payload,
            };

        default:
            return state;
    }
};

export default toMatchReducer;
