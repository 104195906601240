import React from 'react';
import '../../../Utilities/style.css';
import '../Login/login.css';
import DetailLayout from '../../Layouts/DetailLayout/DetailLayout';
import Table from '../../../Components/Table/Table';
import RowSelected from '../../../Images/row_selected.png';
import SelectAllRows from '../../../Images/some_rows_selected.png';
import {connect} from 'react-redux';
import Modal from '../../../Components/Modal/Modal';
import Card from '../../../Components/Card/Card';
import Close from '../../../Images/close_yellow.png';
import Button from '../../../Components/Button/Button';
import {getAdminList, deleteAdmins} from './AxioCalls';
import Snackbar from '../../../Components/Snackbar/Snackbar';
import {logout} from '../Login/LoginAction';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';
import {timeFormat} from '../../../Utilities/Helpers';
import {
    debounce,
    getFiltersSessionStorageItem,
    next,
    prev,
    setFiltersSessionStorageItem,
} from '../../../Utilities/HelperFunctions';
import {COMMON_STATUS, ENTER, ROLES, SCREEN_NAMES} from '../../../Utilities/Constants';
import FilterInput from '../../../Components/FilterInput';
import DropDown from '../../../Components/Dropdown/Dropdown';
import Delete from '../../../Images/delete.png';

class AdminUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: [],
            selectAll: false,
            list: [],
            open: false,
            pageData: {},
            loading: true,
            pageOptions: {
                page: 1,
                pageSize: 20,
                ...getFiltersSessionStorageItem(SCREEN_NAMES.ADMINS),
            },
            roleOptions: [
                {value: ROLES.ADMIN, text: ROLES.ADMIN},
                {value: ROLES.SUPER_ADMIN, text: ROLES.SUPER_ADMIN},
            ],
        };
    }

    /**
     * opening delete admin modal
     */
    onDeleteClick() {
        this.setState({open: true});
    }

    /**
     * selecting a single users ID
     * @param {string} id string
     */
    selectedUser(id) {
        const {user} = this.state;
        if (user.indexOf(id) === -1) {
            this.setState((prevState) => ({
                user: [...prevState.user, id],
            }));
        } else {
            let index = user.indexOf(id);
            let newList = user;
            newList.splice(index, 1);
            this.setState({user: newList});
        }
    }

    /**
     * selecting all admins at once
     */
    selectAlluser() {
        const {admin} = this.props;
        let userIds = [];
        const {selectAll} = this.state;
        if (!selectAll) {
            userIds = admin.adminList.data.data.map((admin) => {
                return admin.id;
            });
        }
        this.setState({
            user: userIds,
            selectAll: !selectAll,
        });
    }

    componentDidMount() {
        const {history, login} = this.props;
        if (!login?.userDetails?.data) {
            history.push('/');
            this.props.logout();
        } else {
            this.getAdminListDebounce({immediate: true});
        }
    }

    componentDidUpdate(prevProps) {
        const {admin} = this.props;
        if (admin.adminListRequest && !prevProps.admin.adminListRequest) {
            this.setState({loading: true});
        }
        if (admin.adminListSuccess && !prevProps.admin.adminListSuccess) {
            this.setState({
                loading: false,
                list: admin.adminList.data.data,
                pageData: admin.adminList.data.pageData,
            });
        } else if (admin.adminListFail && !prevProps.admin.adminListFail) {
            this.setState({loading: false, errorMessage: admin.errorMessage});
        }

        if (admin.deleteAdminSuccess && !prevProps.admin.deleteAdminSuccess) {
            this.setState({loading: false, user: [], errorMessage: admin.deleteAdmin.msg});
            this.getAdminListDebounce({immediate: true});
        } else if (admin.deleteAdminFail && !prevProps.admin.deleteAdminFail) {
            this.setState({loading: false, errorMessage: admin.errorMessage});
        }
    }

    getAdminListDebounce = debounce(() => {
        const { login, client} = this.props;
        setFiltersSessionStorageItem(SCREEN_NAMES.ADMINS, this.state.pageOptions);
        this.props.getAdminList(this.state.pageOptions, login?.userDetails?.data?.accessToken,client.selectedClient);
    });

    handleFilter = (field, value, debounce = true) => {
        const {pageOptions} = this.state;
        if (field === ENTER) {
            this.getAdminListDebounce({immediate: true});
            return;
        }
        pageOptions[field] = value;
        this.setState({pageOptions: {...pageOptions, page: 1}}, () => {
            this.getAdminListDebounce(debounce ? {} : {immediate: true});
        });
    };

    /**
     * api call t0 delete selected admins
     */
    onDeleteAdmins() {
        const {user} = this.state;
        const {login} = this.props;
        let data = {
            adminUserIds: JSON.stringify(user),
        };
        this.props.deleteAdmins(data, login.userDetails.data.accessToken);
        this.setState({open: false, loading: true});
    }

    onReset = () => {
        this.setState({pageOptions: {page: 1, pageSize: 20}}, () => {
            this.getAdminListDebounce({immediate: true});
        });
    };

    /**
     * pagination and api call next click
     */
    onNext() {
        const {pageOptions, pageData} = this.state;
        let options = next(pageOptions, pageData);
        if (options) {
            this.setState({pageOptions: options}, () => this.getAdminListDebounce({immediate: true}));
        }
    }

    /**
     * pagination and api call prev click
     */
    onPrev() {
        const {pageOptions} = this.state;
        let options = prev(pageOptions);
        if (options) {
            this.setState({pageOptions: options}, () => this.getAdminListDebounce({immediate: true}));
        }
    }

    render() {
        const {open, loading, list, pageData, errorMessage, user, selectAll, roleOptions} = this.state;
        const {fullName, email, status, role, page} = this.state.pageOptions;

        let selecteduser = user;
        const data = list;
        const {history} = this.props;
        let disableNext = page === pageData.lastPage ? true : false;

        const columns = [
            {
                Header: (
                    <div
                        className={selectAll ? 'selected-row align-center' : 'select-row align-center'}
                        onClick={this.selectAlluser.bind(this)}
                    >
                        {selectAll ? (
                            <img alt="" src={RowSelected} />
                        ) : user.length > 0 ? (
                            <img alt="" src={SelectAllRows} />
                        ) : (
                            ''
                        )}
                    </div>
                ),
                width: 50,
                accessor: 'id',
                Cell: (props) => (
                    <div
                        className={
                            selecteduser.indexOf(props.value) === -1
                                ? 'select-row align-center'
                                : 'selected-row align-center'
                        }
                        onClick={this.selectedUser.bind(this, props.value)}
                    >
                        {selecteduser.indexOf(props.value) === -1 ? '' : <img alt="" src={RowSelected} />}
                    </div>
                ),
            },
            {
                Header: 'Last Login',
                accessor: 'lastLogin',
                Cell: (props) => (
                    <span className="date-space">
                        {props.value === undefined || props.value === '' ? '-' : timeFormat(props.value)}
                    </span>
                ),
            },

            {
                Header: 'Admin ID',
                accessor: 'id',
                width: 200,
                Cell: (props) => (
                    <span className="table-clickable" onClick={() => history.push(`/editAdmin/${props.value}`)}>
                        {props.value || ''}
                    </span>
                ),
            },
            {
                Header: 'Full Name',
                accessor: 'fullName',

                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Email Address',
                accessor: 'email',
                width: 200,
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Matches Published',
                accessor: 'matchesPublished',

                Cell: (props) => <span>{props.value === undefined || props.value === '' ? '-' : props.value}</span>,
            },

            {
                Header: 'Player Updated',
                accessor: 'playersUpdated',
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },

            {
                Header: 'Matches Verified',
                accessor: 'matchesVerified',
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
        ];
        return (
            <DetailLayout location="admins" history={history} count={pageData.total} centerTitle="Admins">
                {!!errorMessage && (
                    <Snackbar
                        open={!!errorMessage}
                        message={errorMessage}
                        onClose={() => this.setState({errorMessage: ''})}
                    />
                )}
                {loading && <LoadingModal open={loading} />}

                <Modal open={open}>
                    <div className="container modal-top-margin">
                        <Card>
                            <div className="disp-flex">
                                <div className="modal-left-header">Delete Admin</div>
                                <div onClick={() => this.setState({open: false})}>
                                    <img src={Close} alt="" className="cursor" />
                                </div>
                            </div>
                            <div className="align-center equalcontainer-grids">
                                <div />
                                <div>
                                    <div className="disp-flex align-center margin-top-30">
                                        <div className="margin-right-16">
                                            <Button
                                                buttonText="Delete Admin"
                                                onClick={this.onDeleteAdmins.bind(this)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                </Modal>

                <div className="filter-container my-10">
                    <FilterInput
                        placeholder="Full Name"
                        value={fullName || ''}
                        onChange={(value) => this.handleFilter('fullName', value)}
                        onEnter={() => this.handleFilter(ENTER)}
                    />
                    <FilterInput
                        placeholder="Email"
                        value={email || ''}
                        onChange={(value) => this.handleFilter('email', (value || '').trim())}
                        onEnter={() => this.handleFilter(ENTER)}
                    />
                    <DropDown
                        label="Status"
                        value={status || ''}
                        menuItems={COMMON_STATUS}
                        onChange={(value) => this.handleFilter('status', value, false)}
                    />
                    <DropDown
                        label="Role"
                        value={role || ''}
                        menuItems={roleOptions}
                        onChange={(value) => this.handleFilter('role', value, false)}
                    />
                    <Button buttonText="Reset" onClick={this.onReset} />
                    {!!user.length && (
                        <div className="margin-right-16 cursor disp-flex">
                            <img alt="" src={Delete} onClick={this.onDeleteClick.bind(this)} />
                        </div>
                    )}
                </div>
                <div className="filter-container margin-bottom-10">
                    <Button buttonText={this.props.login.permissions.superAdmin?"Add Admin":"Add User"} onClick={() => this.props.history.push('/addAdmin')} />
                </div>

                <Table
                    data={data}
                    columns={columns}
                    page={page}
                    onPrevClick={this.onPrev.bind(this)}
                    onNextClick={this.onNext.bind(this)}
                    disableNext={disableNext}
                ></Table>
            </DetailLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        squad: state.squad,
        player: state.player,
        admin: state.admin,
        client: state.client,
    };
};

export default connect(mapStateToProps, {getAdminList, deleteAdmins, logout})(AdminUser);
