import {StyleSheet} from 'react-native';

import Colors from './colors';
import Fonts from './fonts';
import Dimen from './dimens';

export default StyleSheet.create({
    screen: {
        flex: 1,
    },
    screenContainer: {
        flex: 1,
        backgroundColor: Colors.primary,
    },
    fullCard: {
        width: '90%',
    },
    header: {
        backgroundColor: Colors.header,
        height: 80,
        justifyContent: 'flex-end',
        alignItems: 'center',
        paddingBottom: 15,
    },
    headerText: {
        color: Colors.primaryFont,
        fontFamily: Fonts.FONT_NUNITO_SANS_BOLD,
    },
    accentButtonText: {
        color: Colors.accent,
    },
    baseText: {
        color: Colors.primaryFont,
        fontFamily: Fonts.FONT_NUNITO_SANS_REGULAR,
    },
    titleText: {
        color: Colors.primaryFont,
        fontFamily: Fonts.FONT_NUNITO_SANS_BOLD,
    },
    emptyText: {
        fontFamily: Fonts.FONT_NUNITO_SANS_BOLD,
        fontSize: Fonts.FONT_14,
        color: Colors.YELLOW,
        marginTop: Dimen.DIMEN_48,
        lineHeight: Dimen.DIMEN_28,
        alignSelf: 'center',
        textAlign: 'center',
    },
    subRoot: {
        flex: 1,
        alignItems: 'center',
        alignSelf: 'center',
        padding: Dimen.DIMEN_32,
    },
    nextButton: {
        alignSelf: 'center',
        padding: Dimen.DIMEN_10,
    },
    boldLightYellow: {
        textAlign: 'center',
        color: Colors.LIGHT_YELLOW,
        fontFamily: Fonts.FONT_NUNITO_SANS_BOLD,
        fontSize: 16,
    },
    regularLightYellow: {
        textAlign: 'center',
        color: Colors.LIGHT_YELLOW,
        fontFamily: Fonts.FONT_NUNITO_SANS_REGULAR,
        fontSize: 14,
    },
    transparentHeader: {
        width: '100%',
        flexDirection: 'row',
        height: 60,
        paddingTop: 0,
        paddingLeft: 25,
        paddingRight: 10,
        marginTop: 0,
        alignItems: 'center',
    },
    saveProgressText: {
        backgroundColor: Colors.headerBlack,
        paddingHorizontal: 1,
        paddingTop: 7,
        paddingBottom: 5,
        textAlign: 'center',
        borderRadius: 20,
        fontSize: 16,
        color: Colors.LIGHT_YELLOW,
        borderWidth: 2,
        borderColor: Colors.LIGHT_YELLOW,
    },
    crossText: {
        borderColor: Colors.CRIMSON,
        borderWidth: 2,
        marginStart: 10,
        paddingHorizontal: 4,
        paddingTop: 4,
        paddingBottom: 2,
        textAlign: 'center',
        borderRadius: 20,
        color: Colors.CRIMSON,
    },
});
