export const TOGAME_LIST = 'TOGAME_LIST';
export const TOGAME_LIST_SUCCESS = 'TOGAME_LIST_SUCCESS';
export const TOGAME_LIST_FAIL = 'TOGAME_LIST_FAIL';

export const ADD_TOGAME = 'ADD_TOGAME';
export const ADD_TOGAME_SUCCESS = 'ADD_TOGAME_SUCCESS';
export const ADD_TOGAME_FAIL = 'ADD_TOGAME_FAIL';

export const UPDATE_TOGAME = 'UPDATE_TOGAME';
export const UPDATE_TOGAME_SUCCESS = 'UPDATE_TOGAME_SUCCESS';
export const UPDATE_TOGAME_FAIL = 'UPDATE_TOGAME_FAIL';

export const DELETE_TOGAME = 'DELETE_TOGAME';
export const DELETE_TOGAME_SUCCESS = 'DELETE_TOGAME_SUCCESS';
export const DELETE_TOGAME_FAIL = 'DELETE_TOGAME_FAIL';

export const GET_TOGAME = 'GET_TOGAME';
export const GET_TOGAME_SUCCESS = 'GET_TOGAME_SUCCESS';
export const GET_TOGAME_FAIL = 'GET_TOGAME_FAIL';

export const EDIT_TOGAME = 'EDIT_TOGAME';
