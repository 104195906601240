import {
    TOMATCH_LIST,
    TOMATCH_LIST_SUCCESS,
    TOMATCH_LIST_FAIL,
    ADD_TOMATCH,
    ADD_TOMATCH_SUCCESS,
    ADD_TOMATCH_FAIL,
    MODIFY_TOMATCH,
    MODIFY_TOMATCH_SUCCESS,
    MODIFY_TOMATCH_FAIL,
    GET_TOMATCH_PARTICIPATION,
    GET_TOMATCH_PARTICIPATION_SUCCESS,
    GET_TOMATCH_PARTICIPATION_FAIL,
    CANCEL_TOMATCH,
    CANCEL_TOMATCH_SUCCESS,
    CANCEL_TOMATCH_FAIL,
    GET_TOMATCH,
    GET_TOMATCH_SUCCESS,
    GET_TOMATCH_FAIL,
    EDIT_TOMATCH,
} from './Constants';

//lst all TOMATCH
export function listTOMatches() {
    return {
        type: TOMATCH_LIST,
    };
}

export function listTOMatchesSuccess(data) {
    return {
        type: TOMATCH_LIST_SUCCESS,
        payload: data,
    };
}

export function listTOMatchesFail(error) {
    return {
        type: TOMATCH_LIST_FAIL,
        payload: error,
    };
}

// add TOMATCH
export function addTOMatch() {
    return {
        type: ADD_TOMATCH,
    };
}

export function addTOMatchSuccess(data) {
    return {
        type: ADD_TOMATCH_SUCCESS,
        payload: data,
    };
}

export function addTOMatchFail(error) {
    return {
        type: ADD_TOMATCH_FAIL,
        payload: error,
    };
}

//modify TOMATCH
export function modifyTOMatchRequest() {
    return {
        type: MODIFY_TOMATCH,
    };
}

export function modifyTOMatchSuccess(data) {
    return {
        type: MODIFY_TOMATCH_SUCCESS,
        payload: data,
    };
}

export function modifyTOMatchFail(error) {
    return {
        type: MODIFY_TOMATCH_FAIL,
        payload: error,
    };
}

//get participation for TOMATCH
export function getTOMatchParticipationRequest() {
    return {
        type: GET_TOMATCH_PARTICIPATION,
    };
}

export function getTOMatchParticipationSuccess(data) {
    return {
        type: GET_TOMATCH_PARTICIPATION_SUCCESS,
        payload: data,
    };
}

export function getTOMatchParticipationFail(error) {
    return {
        type: GET_TOMATCH_PARTICIPATION_FAIL,
        payload: error,
    };
}

// cancel TOMATCH

export function cancelTOMatchRequest() {
    return {
        type: CANCEL_TOMATCH,
    };
}

export function cancelTOMatchSuccess(data) {
    return {
        type: CANCEL_TOMATCH_SUCCESS,
        payload: data,
    };
}

export function cancelTOMatchFail(error) {
    return {
        type: CANCEL_TOMATCH_FAIL,
        payload: error,
    };
}

//get single TOMATCH
export function getTOMatchRequest() {
    return {
        type: GET_TOMATCH,
    };
}

export function getTOMatchSuccess(data) {
    return {
        type: GET_TOMATCH_SUCCESS,
        payload: data,
    };
}

export function getTOMatchFail(error) {
    return {
        type: GET_TOMATCH_FAIL,
        payload: error,
    };
}

// single match Details
export function editTOMatch(match) {
    return {
        type: EDIT_TOMATCH,
        payload: match,
    };
}
