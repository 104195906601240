import {
    LOGIN,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    UPDATE_PERMISSION,
    SENDEMAIL,
    SENDEMAIL_SUCCESS,
    SENDEMAIL_FAIL,
} from './LoginConstants';

/**
 * login api request
 */
export function login() {
    return {
        type: LOGIN,
    };
}

/**
 *handle login api success
 * @param {*} data data from api
 */
export function loginSuccess(data) {
    return {
        type: LOGIN_SUCCESS,
        payload: data,
    };
}

/**
 *handle login api failure
 * @param {*} error error from api
 */
export function loginFail(error) {
    return {
        type: LOGIN_FAIL,
        payload: error,
    };
}

/**
 * logout
 */
export function logout() {
    return {
        type: LOGOUT,
    };
}

export function updatePermissions(data) {
    return {
        type: UPDATE_PERMISSION,
        payload: data,
    };
}

/**
 * send email api request
 */
export function sendEmail() {
    return {
        type: SENDEMAIL,
    };
}

/**
 * handle send email api success
 * @param {*} data data from api
 */
export function sendEmailSuccess(data) {
    return {
        type: SENDEMAIL_SUCCESS,
        payload: data,
    };
}
/**
 *handle  send email api failure
 * @param {*} error error from api
 */
export function sendEmailFail(error) {
    return {
        type: SENDEMAIL_FAIL,
        payload: error,
    };
}
