import React from 'react';
import '../../../Utilities/style.css';
import '../Login/login.css';
import DetailLayout from '../../Layouts/DetailLayout/DetailLayout';
import Table from '../../../Components/Table/Table';
import {connect} from 'react-redux';
import Modal from '../../../Components/Modal/Modal';
import Card from '../../../Components/Card/Card';
import Close from '../../../Images/close_yellow.png';
import Button from '../../../Components/Button/Button';
import moment from 'moment';
import {userConvertedTnx} from './AxioCalls';
import {logout} from '../Login/LoginAction';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';
import Snackbar from '../../../Components/Snackbar/Snackbar';
import {timeFormat} from '../../../Utilities/Helpers';

class MoneyConverted extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: [],
            selectAll: false,
            list: [],
            pageSize: 10,
            page: 1,
            open: false,
            adminpswd: '',
            loading: true,
            errorMessage: '',
            snackbarOpen: false,
            pageData: {},
            sortOptions: [
                {
                    value: 'id',
                    text: 'Contest Id',
                },

                {
                    value: 'match',
                    text: 'Match Id',
                },

                {
                    text: 'Status',
                    value: 'status',
                },
            ],
        };
    }

    /**
     * function to save entered data in state
     * @param {string} field field names from the form
     * @param {*} value value in the field
     */
    handleInputChange(field, value) {
        this.setState({
            [field]: value,
            snackbarOpen: false,
            errorMessage: '',
        });
    }

    componentDidMount() {
        const {login, history, referralCode, client} = this.props;
        if (login.userDetails.length === 0) {
            history.push('/');
            this.props.logout();
        } else {
            const {page, pageSize} = this.state;
            let refId = referralCode.editReferralCode.id;
            let referral = {
                page: page,
                pageSize: pageSize,
                referralId: refId,
            };

            this.props.userConvertedTnx(referral, login.userDetails.data.accessToken, client.selectedClient);
        }
    }

    /**
     * reset table data
     */
    onReset() {
        const {page, pageSize} = this.state;
        const {login, referralCode, client} = this.props;

        let refId = referralCode.editReferralCode.id;
        let referral = {
            page: page,
            pageSize: pageSize,
            referralId: refId,
        };

        this.props.userConvertedTnx(referral, login.userDetails.data.accessToken, client.selectedClient);
    }

    componentDidUpdate(prevProps) {
        const {referralCode} = this.props;

        if (referralCode.convertedTnxSuccess && !prevProps.referralCode.convertedTnxSuccess) {
            this.setState({
                loading: false,
                list: referralCode.convertedTnx.data.data,
                pageData: referralCode.convertedTnx.data.pageData,
            });
        } else if (referralCode.convertedTnxFail && !prevProps.referralCode.convertedTnxFail) {
            this.setState({
                loading: false,
                snackbarOpen: true,
                errorMessage: 'Something went wrong',
            });
        }
    }

    /**
     * APi call with search and sort stings
     * @param {string} field field names of input
     * @param {string} value value in the field
     */
    onSearchandSort(field, value) {
        const {pageSize, page} = this.state;
        const {login, referralCode, client} = this.props;

        let refId = referralCode.editReferralCode.id;

        let referral = {
            page: page,
            referralId: refId,
            pageSize: pageSize,
        };
        if (field === 'endDate' || field === 'startDate') {
            let selectedTime = moment(value, 'DD/MM/YYY H:mm');
            let timestamp = moment(selectedTime).valueOf();
            referral[field] = timestamp;
        } else {
            if (value.length > 0) {
                referral[field] = value;
            }
        }
        this.props.userConvertedTnx(referral, login.userDetails.data.accessToken, client.selectedClient);
    }

    /**
     * pagination and api call on next click
     */
    onNext() {
        const {pageSize, page, searchString, sortOrder, pageData} = this.state;
        const {login, referralCode, client} = this.props;

        let lastPage = pageData.lastPage;
        let refId = referralCode.editReferralCode.id;

        if (page === lastPage) {
            return false;
        } else if (page < lastPage) {
            let referral = {
                page: page + 1,
                pageSize: pageSize,
                searchString: searchString,
                sortOrder: sortOrder,
                referralId: refId,
            };
            this.props.userConvertedTnx(referral, login.userDetails.data.accessToken, client.selectedClient);
            this.setState({page: page + 1, loading: true});
        }
    }

    /**
     * pagination  and api call on  prev click
     */
    onPrev() {
        const {pageSize, page, searchString, sortOrder} = this.state;
        const {login, referralCode, client} = this.props;

        if (page > 1) {
            let refId = referralCode.editReferralCode.id;

            let referral = {
                page: page - 1,
                pageSize: pageSize,
                searchString: searchString,
                sortOrder: sortOrder,
                referralId: refId,
            };
            this.props.userConvertedTnx(referral, login.userDetails.data.accessToken, client.selectedClient);
            this.setState({page: page - 1, loading: true});
        } else {
            return false;
        }
    }

    /**
     * display team ids one below another
     * @param {*} teams array of team IDs
     */
    dispTeams(teams) {
        return teams.map((team) => {
            return <div>{team}</div>;
        });
    }

    render() {
        const {list, open, loading, errorMessage, snackbarOpen, page, sortOptions, pageData} = this.state;
        const data = list;
        const {history, referralCode} = this.props;

        let disableNext = page === pageData.lastPage ? true : false;
        let id = {
            id: referralCode.editReferralCode.referralCode,
            amount: '123456',
        };
        const columns = [
            {
                Header: 'Transaction ID',
                accessor: 'id',
                width: 200,
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },

            {
                Header: 'UserID',
                accessor: 'user',
                width: 200,
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Match ID',
                accessor: 'match',
                width: 200,
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Contest ID',
                accessor: 'contest',
                width: 200,
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Team ID',
                accessor: 'teams',
                width: 200,
                Cell: (props) => (props.value.length === 0 ? '-' : this.dispTeams(props.value)),
            },
            {
                Header: '# of Teams',
                accessor: 'teams',

                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value.length}
                    </span>
                ),
            },
            {
                Header: 'Match Start Time',
                accessor: 'matchStartTime',

                Cell: (props) => <span className="date-space">{timeFormat(props.value) || '-'}</span>,
            },
            {
                Header: 'Paid On',
                accessor: 'updatedAt',

                Cell: (props) => <span className="date-space">{timeFormat(props.value) || '-'}</span>,
            },
            {
                Header: 'Payment Via',
                accessor: 'balanceUsed',

                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Final Paid',
                accessor: 'totalAmount',

                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Contest Status',
                accessor: 'contestStatus',

                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
        ];
        return (
            <DetailLayout
                location="refCode"
                history={history}
                searchbar="Search UserID / Match ID / Contest ID / Transaction ID "
                search={true}
                centerTitle="Referral Code - Money Converted"
                onsearchChange={this.onSearchandSort.bind(this)}
                sortOptions={sortOptions}
                id={id}
                count={pageData.total}
                onReset={this.onReset.bind(this)}
            >
                <LoadingModal open={loading} />
                <Snackbar
                    open={snackbarOpen}
                    message={errorMessage}
                    onClose={() => this.setState({snackbarOpen: false})}
                />
                <Modal open={open}>
                    <div className="container modal-top-margin">
                        <Card>
                            <div className="disp-flex">
                                <div className="modal-left-header">Delete</div>
                                <div onClick={() => this.setState({open: false})}>
                                    <img src={Close} alt="" className="cursor" />
                                </div>
                            </div>
                            <div className="align-center equalcontainer-grids">
                                <div />
                                <div>
                                    <div className="disp-flex align-center margin-top-30">
                                        <div className="margin-right-16">
                                            <Button buttonText="Delete User" onClick={this.handleInputChange} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                </Modal>
                <Table
                    data={data}
                    columns={columns}
                    pageSize={10}
                    page={page}
                    onPrevClick={this.onPrev.bind(this)}
                    onNextClick={this.onNext.bind(this)}
                    disableNext={disableNext}
                ></Table>
            </DetailLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        squad: state.squad,
        player: state.player,
        referralCode: state.referralCode,
        client: state.client,
    };
};

export default connect(mapStateToProps, {userConvertedTnx, logout})(MoneyConverted);
