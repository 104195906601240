import React from 'react';
import PropTypes from 'prop-types';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';
import Button from '../../../../Components/Button/Button';
import CreateEditExpertTeamModal from '../CreateEditExpertTeamModal';
import {connect} from 'react-redux';
import {autoCreateRecommendedTeam, deleteRecommendedTeam, getRecommendedTeamsForMatch} from '../action';
import LoadingModal from '../../../../Components/LoadingModal/LoadingModal';
import Snackbar from '../../../../Components/Snackbar/Snackbar';
import ConfirmationModal from '../../../../Components/ConfirmationModal';

class ExpertTeamListModal extends React.Component {
    static propTypes = {
        open: PropTypes.bool,
        handleClose: PropTypes.func,
    };

    static defaultProps = {
        handleClose: () => {},
        open: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            createExpertTeam: undefined,
            loading: false,
            errorMessage: '',
        };
    }

    componentDidMount() {
        this.handleFetchRecommendedTeams();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {
            recommendedTeams,
            recommendedTeams: {
                isFetchingTeamsSuccess,
                isFetchingTeamsFail,
                isCreatingTeamSuccess,
                isUpdatingTeamSuccess,
                isDeletingTeamSuccess,
                isDeletingTeamFail,
                isAutoCreatingTeamSuccess,
                isAutoCreatingTeamFail,
            },
        } = this.props;

        if (isFetchingTeamsSuccess && !prevProps.recommendedTeams.isFetchingTeamsSuccess) {
            this.setState({loading: false});
        }
        if (isFetchingTeamsFail && !prevProps.recommendedTeams.isFetchingTeamsFail) {
            this.setState({loading: false, errorMessage: recommendedTeams.errorMessage});
        }

        if (
            (isCreatingTeamSuccess && !prevProps.recommendedTeams.isCreatingTeamSuccess) ||
            (isUpdatingTeamSuccess && !prevProps.recommendedTeams.isUpdatingTeamSuccess) ||
            (isDeletingTeamSuccess && !prevProps.recommendedTeams.isDeletingTeamSuccess) ||
            (isAutoCreatingTeamSuccess && !prevProps.recommendedTeams.isAutoCreatingTeamSuccess)
        ) {
            this.setState({createExpertTeam: undefined, openConfirmModal: false});
            this.handleFetchRecommendedTeams();
        }
        if (
            (isDeletingTeamFail && !prevProps.recommendedTeams.isDeletingTeamFail) ||
            (isAutoCreatingTeamFail && !prevProps.recommendedTeams.isAutoCreatingTeamFail)
        ) {
            this.setState({errorMessage: recommendedTeams.errorMessage});
        }
    }

    handleFetchRecommendedTeams = () => {
        const {id} = this.props.match.getMatch.data;
        let {login} = this.props;
        this.setState({loading: true});
        this.props.getRecommendedTeamsForMatch({matchId: id}, login.userDetails.data.accessToken);
    };

    handleOpenExpertTeamModal = (team) => {
        const {maxPlayersPerSquad, maxTeamSize, numberOfCredits, squads, isNewRoleActivated, id} =
            this.props.match.getMatch.data;
        let createExpertTeam = {
            maxPlayersPerSquad,
            maxTeamSize,
            numberOfCredits,
            squads,
            isNewRoleActivated,
            matchId: id,
        };
        if (team) createExpertTeam = {...createExpertTeam, ...team};
        this.setState({createExpertTeam});
    };

    handleAutoCreateTeam = () => {
        const {id} = this.props.match.getMatch.data;
        let {login} = this.props;
        this.props.autoCreateRecommendedTeam({matchId: id}, login.userDetails.data.accessToken);
    };

    handleOpenConfirmModalOrCreateTeam = () => {
        let {
            recommendedTeams: {recommendedList},
        } = this.props;
        let isAllExpert =
            recommendedList.data && (recommendedList.data.teams || []).every((team) => team.type === 'Expert');
        if (!isAllExpert) {
            this.setState({openConfirmModal: true});
            return;
        }
        this.handleAutoCreateTeam();
    };

    handleDeleteExpertTeam = (team) => {
        const {id} = this.props.match.getMatch.data;
        let {login} = this.props;
        this.props.deleteRecommendedTeam({matchId: id, recommendedTeamId: team.id}, login.userDetails.data.accessToken);
    };

    render() {
        let {
            handleClose,
            open,
            recommendedTeams: {recommendedList, isCreatingTeam, isUpdatingTeam, isDeletingTeam, isAutoCreatingTeam},
        } = this.props;
        let {createExpertTeam, loading, errorMessage, openConfirmModal} = this.state;
        let loader = loading || isCreatingTeam || isUpdatingTeam || isDeletingTeam || isAutoCreatingTeam;

        return (
            <Dialog
                open={open}
                onClose={handleClose}
                scroll="paper"
                maxWidth="md"
                fullWidth
                disableEscapeKeyDown
                disableBackdropClick
            >
                {!!createExpertTeam && (
                    <CreateEditExpertTeamModal
                        open={!!createExpertTeam}
                        createExpertTeam={createExpertTeam}
                        handleClose={() => this.setState({createExpertTeam: undefined})}
                    />
                )}

                {openConfirmModal && (
                    <ConfirmationModal
                        open={openConfirmModal}
                        handleClose={() => this.setState({openConfirmModal: false})}
                        confirmationText="Auto created team already exist. Do you want to override the auto created teams?"
                        handleOnConfirm={this.handleAutoCreateTeam}
                    />
                )}

                <LoadingModal open={loader} />
                <Snackbar
                    open={!!errorMessage}
                    message={errorMessage}
                    onClose={() => this.setState({errorMessage: ''})}
                />

                <DialogTitle className="dialog-title-with-button">
                    Expert Team List
                    <span>
                        <Button
                            className="mr-10"
                            buttonText="Auto Create"
                            onClick={this.handleOpenConfirmModalOrCreateTeam}
                        />
                        <Button buttonText="Create New Team" onClick={this.handleOpenExpertTeamModal} />
                    </span>
                </DialogTitle>
                <DialogContent className="px-0" dividers={true}>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Display Title</TableCell>
                                    <TableCell>Expert Team Name</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell>players</TableCell>
                                    <TableCell>captain</TableCell>
                                    <TableCell>viceCaptain</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {recommendedList.data &&
                                    (recommendedList.data.teams || []).map((team) => (
                                        <TableRow key={team.id}>
                                            <TableCell>{team.displayTitle}</TableCell>
                                            <TableCell>{team.expertName}</TableCell>
                                            <TableCell>{team.type}</TableCell>
                                            <TableCell>{(!!team.players && team.players.length) || 0}</TableCell>
                                            <TableCell>{team.captain ? 'Yes' : 'No'}</TableCell>
                                            <TableCell>{team.viceCaptain ? 'Yes' : 'No'}</TableCell>
                                            <TableCell>
                                                <EditIcon
                                                    titleAccess="Edit"
                                                    className="clickable-icon mr-5"
                                                    fontSize="small"
                                                    onClick={() => this.handleOpenExpertTeamModal(team)}
                                                />
                                                <DeleteIcon
                                                    titleAccess="Delete"
                                                    className="clickable-icon"
                                                    fontSize="small"
                                                    onClick={() => this.handleDeleteExpertTeam(team)}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}

                                {recommendedList.data && !recommendedList.data.teams.length && (
                                    <TableRow>
                                        <TableCell className="text-center" colSpan="10">
                                            No Data Available
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button buttonText="Cancel" onClick={handleClose} />
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        recommendedTeams: state.recommendedTeams,
        match: state.match,
        login: state.login,
    };
};

export default connect(mapStateToProps, {
    getRecommendedTeamsForMatch,
    deleteRecommendedTeam,
    autoCreateRecommendedTeam,
})(ExpertTeamListModal);
