/**
 * Component returning live/completed match's my team card
 *
 */
import React, {Component} from 'react';
import {StyleSheet, View, FlatList} from 'react-native';
import dimen from './dimens';
import colors from './colors';
import MyLiveTeamPlayerItem from './MyLiveTeamPlayerItem';

import MatchContestItemDetails from './MatchContestItemDetails';
import PlayerStatsResultModal from '../../../Components/PlayerStatsResultModal';

class LiveMyTeamCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alertVisible: false,
        };
    }

    _renderItemComponent = ({item, index}) => {
        return (
            <View style={[{backgroundColor: colors.BLACK, flex: 1}, index % 2 == 0 ? {} : {marginLeft: 1}]}>
                <MyLiveTeamPlayerItem
                    details={item}
                    teamId={this.props.details.id}
                    onPlayerClicked={this.handleOpenPlayerStatsModal}
                    captain={this.props.details.captain}
                    firstOfficer={this.props.details.firstOfficer}
                />
            </View>
        );
    };

    handleOpenPlayerStatsModal = (player) => {
        this.setState({playerStatsModal: player});
    };

    render() {
        const {details} = this.props;
        const {playerStatsModal} = this.state;
        return (
            <View style={styles.root}>
                {!!playerStatsModal && (
                    <PlayerStatsResultModal
                        open={!!playerStatsModal}
                        handleClose={() => this.setState({playerStatsModal: null})}
                        playerDetails={playerStatsModal}
                    />
                )}
                {details.id ? (
                    <View style={styles.horizontalRoot}>
                        <MatchContestItemDetails
                            imgSrc={require('../../../Images/fc_teams_dark_grey.png')}
                            text="Team ID"
                            type="text"
                            value={details.id}
                            style={styles.margin}
                        />
                    </View>
                ) : null}
                <View style={styles.blackBackground}>
                    <FlatList
                        data={details.players}
                        columnWrapperStyle={styles.flatlist}
                        extraData={this.state}
                        renderItem={this._renderItemComponent}
                        numColumns={2}
                    />
                </View>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    root: {
        backgroundColor: colors.DARK_BLUE,
        borderRadius: dimen.DIMEN_4,
        // flex: 1,
        // width: '100%'
    },
    flatlist: {
        flex: 1,
        justifyContent: 'space-around',
        backgroundColor: colors.DARK_BLUE,
    },
    horizontalRoot: {
        flexDirection: 'row',
    },
    blackBackground: {
        backgroundColor: colors.BLACK,
    },
    margin: {
        margin: dimen.DIMEN_16,
    },
});

export default LiveMyTeamCard;
