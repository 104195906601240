import {
    TOORGANIZER_LIST,
    TOORGANIZER_LIST_SUCCESS,
    TOORGANIZER_LIST_FAIL,
    ADD_TOORGANIZER,
    ADD_TOORGANIZER_SUCCESS,
    ADD_TOORGANIZER_FAIL,
    UPDATE_TOORGANIZER,
    UPDATE_TOORGANIZER_SUCCESS,
    UPDATE_TOORGANIZER_FAIL,
    DELETE_TOORGANIZER,
    DELETE_TOORGANIZER_SUCCESS,
    DELETE_TOORGANIZER_FAIL,
    GET_TOORGANIZER,
    GET_TOORGANIZER_SUCCESS,
    GET_TOORGANIZER_FAIL,
    EDIT_TOORGANIZER,
} from './Constants';

//lst all TOORGANIZER
export function listTOOrganizers() {
    return {
        type: TOORGANIZER_LIST,
    };
}

export function listTOOrganizersSuccess(data) {
    console.log('listTOrganizersSuccess action is being triggered');
    return {
        type: TOORGANIZER_LIST_SUCCESS,
        payload: data,
    };
}

export function listTOOrganizersFail(error) {
    return {
        type: TOORGANIZER_LIST_FAIL,
        payload: error,
    };
}

// add TOORGANIZER
export function addTOOrganizer() {
    return {
        type: ADD_TOORGANIZER,
    };
}

export function addTOOrganizerSuccess(data) {
    return {
        type: ADD_TOORGANIZER_SUCCESS,
        payload: data,
    };
}

export function addTOOrganizerFail(error) {
    return {
        type: ADD_TOORGANIZER_FAIL,
        payload: error,
    };
}

//update TOORGANIZER
export function updateTOOrganizerRequest() {
    return {
        type: UPDATE_TOORGANIZER,
    };
}

export function updateTOOrganizerSuccess(data) {
    return {
        type: UPDATE_TOORGANIZER_SUCCESS,
        payload: data,
    };
}

export function updateTOOrganizerFail(error) {
    return {
        type: UPDATE_TOORGANIZER_FAIL,
        payload: error,
    };
}

// delete TOORGANIZER

export function deleteTOOrganizerRequest() {
    return {
        type: DELETE_TOORGANIZER,
    };
}

export function deleteTOOrganizerSuccess(data) {
    return {
        type: DELETE_TOORGANIZER_SUCCESS,
        payload: data,
    };
}

export function deleteTOOrganizerFail(error) {
    return {
        type: DELETE_TOORGANIZER_FAIL,
        payload: error,
    };
}

//get single TOORGANIZER
export function getTOOrganizerRequest() {
    return {
        type: GET_TOORGANIZER,
    };
}

export function getTOOrganizerSuccess(data) {
    return {
        type: GET_TOORGANIZER_SUCCESS,
        payload: data,
    };
}

export function getTOOrganizerFail(error) {
    return {
        type: GET_TOORGANIZER_FAIL,
        payload: error,
    };
}

// single organizer Details
export function editTOOrganizer(organizer) {
    return {
        type: EDIT_TOORGANIZER,
        payload: organizer,
    };
}
