import {
    TOMATCHTYPE_LIST,
    TOMATCHTYPE_LIST_SUCCESS,
    TOMATCHTYPE_LIST_FAIL,
    ADD_TOMATCHTYPE,
    ADD_TOMATCHTYPE_SUCCESS,
    ADD_TOMATCHTYPE_FAIL,
    UPDATE_TOMATCHTYPE,
    UPDATE_TOMATCHTYPE_SUCCESS,
    UPDATE_TOMATCHTYPE_FAIL,
    DELETE_TOMATCHTYPE,
    DELETE_TOMATCHTYPE_SUCCESS,
    DELETE_TOMATCHTYPE_FAIL,
    GET_TOMATCHTYPE,
    GET_TOMATCHTYPE_SUCCESS,
    GET_TOMATCHTYPE_FAIL,
    EDIT_TOMATCHTYPE,
} from './Constants';

//lst all TOMATCHTYPE
export function listTOMatchTypes() {
    return {
        type: TOMATCHTYPE_LIST,
    };
}

export function listTOMatchTypesSuccess(data) {
    return {
        type: TOMATCHTYPE_LIST_SUCCESS,
        payload: data,
    };
}

export function listTOMatchTypesFail(error) {
    return {
        type: TOMATCHTYPE_LIST_FAIL,
        payload: error,
    };
}

// add TOMATCHTYPE
export function addTOMatchType() {
    return {
        type: ADD_TOMATCHTYPE,
    };
}

export function addTOMatchTypeSuccess(data) {
    return {
        type: ADD_TOMATCHTYPE_SUCCESS,
        payload: data,
    };
}

export function addTOMatchTypeFail(error) {
    return {
        type: ADD_TOMATCHTYPE_FAIL,
        payload: error,
    };
}

//update TOMATCHTYPE
export function updateTOMatchTypeRequest() {
    return {
        type: UPDATE_TOMATCHTYPE,
    };
}

export function updateTOMatchTypeSuccess(data) {
    return {
        type: UPDATE_TOMATCHTYPE_SUCCESS,
        payload: data,
    };
}

export function updateTOMatchTypeFail(error) {
    return {
        type: UPDATE_TOMATCHTYPE_FAIL,
        payload: error,
    };
}

// delete TOMATCHTYPE

export function deleteTOMatchTypeRequest() {
    return {
        type: DELETE_TOMATCHTYPE,
    };
}

export function deleteTOMatchTypeSuccess(data) {
    return {
        type: DELETE_TOMATCHTYPE_SUCCESS,
        payload: data,
    };
}

export function deleteTOMatchTypeFail(error) {
    return {
        type: DELETE_TOMATCHTYPE_FAIL,
        payload: error,
    };
}

//get single TOMATCHTYPE
export function getTOMatchTypeRequest() {
    return {
        type: GET_TOMATCHTYPE,
    };
}

export function getTOMatchTypeSuccess(data) {
    return {
        type: GET_TOMATCHTYPE_SUCCESS,
        payload: data,
    };
}

export function getTOMatchTypeFail(error) {
    return {
        type: GET_TOMATCHTYPE_FAIL,
        payload: error,
    };
}

// single matchType Details
export function editTOMatchType(matchType) {
    return {
        type: EDIT_TOMATCHTYPE,
        payload: matchType,
    };
}
