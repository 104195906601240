module.exports = {
    ROUTES: {
        LIST_USERS: '/users',
        USER_MATCH_DETAILS: '/userMatchDetails',
        USER_CONTEST_DETAILS: '/userContestDetails',
        CONTEST_WON_DETAILS: '/userContestWon',
        CONTEST_LOST_DETAILS: '/userContestLost',
        USER_ENTRY_FEES: '/userEntryFees',
        USER_WINNINGS: '/userWinnings',
        USER_WITHDRAWALS: '/userWidthdrawals',
        USER_BALANCE: '/userBalance',
        USER_FC_COINS: '/userFcCoins',
    },
};
