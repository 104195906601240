import {MATCH_ID_UPLOAD_FANTASY_RESULTS} from './SquadAxios';
import {
    SQUAD_LIST,
    SQUAD_LIST_SUCCESS,
    SQUAD_LIST_FAIL,
    ADD_SQUAD,
    ADD_SQUAD_SUCCESS,
    ADD_SQUAD_FAIL,
    EDIT_SQUAD,
    UPDATE_SQUAD,
    UPDATE_SQUAD_SUCCESS,
    UPDATE_SQUAD_FAIL,
    DELETE_SQUAD,
    DELETE_SQUAD_SUCCESS,
    DELETE_SQUAD_FAIL,
    GET_SQUAD,
    GET_SQUAD_SUCCESS,
    GET_SQUAD_FAIL,
    GET_SQUAD_BY_ID,
    GET_SQUAD_BY_ID_SUCCESS,
    GET_SQUAD_BY_ID_FAIL,
} from './SquadConstants';

const initialState = {
    squadListRequest: false,
    squadListSuccess: false,
    squadListFail: false,
    squadList: [],
    errorMessage: '',

    addSquadRequest: false,
    addSquadSuccess: false,
    addSquadFail: false,
    addSquad: [],
    editSquad: [],

    updateSquadRequest: true,
    updateSquadSuccess: false,
    updateSquadFail: false,
    updateSquad: [],

    deleteSquadRequest: true,
    deleteSquadsuccess: false,
    deleteSquadFail: false,
    deleteSquad: [],

    getSquadRequest: false,
    getSquadSuccess: false,
    getSquadFail: false,
    getSquad: [],

    isGetSquadById: false,
    isGetSquadByIdSuccess: false,
    isGetSquadByIdFail: false,
    squadDetail: {},

    matchIdUploadFantasyResults: null,
};

const squadReducer = (state = initialState, action) => {
    switch (action.type) {
        case SQUAD_LIST:
            return {
                ...state,
                squadListRequest: true,
                squadListSuccess: false,
                squadListFail: false,
                squadList: [],
                errorMessage: '',
                getSquadRequest: false,
                getSquadSuccess: false,
                getSquadFail: false,
            };

        case SQUAD_LIST_SUCCESS:
            return {
                ...state,
                squadListRequest: false,
                squadListSuccess: true,
                squadListFail: false,
                squadList: action.payload.data,
                errorMessage: '',
            };

        case SQUAD_LIST_FAIL:
            return {
                ...state,
                squadListRequest: false,
                squadListSuccess: false,
                squadListFail: true,
                errorMessage: action.payload,
            };

        case ADD_SQUAD:
            return {
                ...state,
                addSquadRequest: true,
                addSquadSuccess: false,
                addSquadFail: false,
                addSquad: [],
                errorMessage: '',
            };

        case ADD_SQUAD_SUCCESS:
            return {
                ...state,
                addSquadRequest: false,
                addSquadSuccess: true,
                addSquadFail: false,
                addSquad: action.payload.data,
                errorMessage: '',
            };

        case ADD_SQUAD_FAIL:
            return {
                ...state,
                addSquadRequest: false,
                addSquadSuccess: false,
                addSquadFail: true,
                errorMessage: action.payload,
            };
        case EDIT_SQUAD:
            return {
                ...state,
                editSquad: action.payload,
            };

        // EDIT SQUAD
        case UPDATE_SQUAD:
            return {
                ...state,
                updateSquadRequest: true,
                updateSquadSuccess: false,
                updateSquadFail: false,
                updateSquad: [],
                errorMessage: '',
            };

        case UPDATE_SQUAD_SUCCESS:
            return {
                ...state,
                updateSquadRequest: false,
                updateSquadSuccess: true,
                updateSquadFail: false,
                updateSquad: action.payload.data,
            };

        case UPDATE_SQUAD_FAIL:
            return {
                ...state,
                updateSquadRequest: false,
                updateSquadSuccess: false,
                updateSquadFail: true,
                errorMessage: action.payload,
            };

        // DELETE
        case DELETE_SQUAD:
            return {
                ...state,
                deleteSquadRequest: true,
                deleteSquadsuccess: false,
                deleteSquadFail: false,
                deleteSquad: [],
                errorMessage: '',
            };

        case DELETE_SQUAD_SUCCESS:
            return {
                ...state,
                deleteSquadRequest: false,
                deleteSquadsuccess: true,
                deleteSquadFail: false,
                deleteSquad: action.payload.data,
            };

        case DELETE_SQUAD_FAIL:
            return {
                ...state,
                deleteSquadRequest: false,
                deleteSquadsuccess: false,
                deleteSquadFail: true,
                errorMessage: action.payload,
            };
        // get squad

        case GET_SQUAD:
            return {
                ...state,
                getSquadRequest: true,
                getSquadSuccess: false,
                getSquadFail: false,
                getSquad: [],
                errorMessage: '',
            };

        case GET_SQUAD_SUCCESS:
            return {
                ...state,
                getSquadRequest: false,
                getSquadSuccess: true,
                getSquadFail: false,
                getSquad: action.payload.data,
            };

        case GET_SQUAD_FAIL:
            return {
                ...state,
                getSquadRequest: false,
                getSquadSuccess: false,
                getSquadFail: true,
                errorMessage: action.payload,
            };

        case GET_SQUAD_BY_ID:
            return {
                ...state,
                isGetSquadById: true,
                isGetSquadByIdSuccess: false,
                isGetSquadByIdFail: false,
                squadDetail: {},
                errorMessage: '',
            };
        case GET_SQUAD_BY_ID_SUCCESS:
            return {
                ...state,
                isGetSquadById: false,
                isGetSquadByIdSuccess: true,
                isGetSquadByIdFail: false,
                squadDetail: action.payload,
            };
        case GET_SQUAD_BY_ID_FAIL:
            return {
                ...state,
                isGetSquadById: false,
                isGetSquadByIdSuccess: false,
                isGetSquadByIdFail: true,
                errorMessage: action.payload,
            };

        case MATCH_ID_UPLOAD_FANTASY_RESULTS:
            return {
                ...state,
                matchIdUploadFantasyResults: action.payload,
            };

        default:
            return state;
    }
};

export default squadReducer;
