import React from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import '../../../Utilities/style.css';
import '../Login/login.css';
import DetailLayout from '../../Layouts/DetailLayout/DetailLayout';
import Card from '../../../Components/Card/Card';
import Input from '../../../Components/Input/Input';
import DropDown from '../../../Components/Dropdown/Dropdown';
import Button from '../../../Components/Button/Button';
import {addReferral, updateReferrals} from './AxioCalls';
import Snackbar from '../../../Components/Snackbar/Snackbar';
import {logout} from '../Login/LoginAction';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';
import {timeFormat} from '../../../Utilities/Helpers';
import {PHONE_PATTERN, REFERRAL_CAMPAIGN_OPTIONS, URL_PATTERN, REFERRAL_CAMPAIGNS} from '../../../Utilities/Constants';
import DateTimePickerComponent from '../../../Components/DateTimePickerComponent';

class AddRefCode extends React.Component {
    constructor(props) {
        super(props);
        let location = this.props.history.location.pathname.includes('edit');
        this.state = {
            loading: false,
            edit: location,
        };
    }

    /**
     * function to save entered data in state
     * @param {string} field field names from the form
     * @param {*} value value in the field
     */
    handleChange = (field, value) => {
        let tempState = {};
        tempState[field] = value;
        if (field === 'campaignCategory') {
            tempState.campaignSubCategory = '';
            tempState.email = '';
        }
        if (field === 'budget') tempState.totalSpend = '';
        this.setState(tempState);
    };

    /**
     * Add/Update referral code and send invite via valid referral code
     */
    handleSubmit = (event) => {
        !!event && event.preventDefault();
        const {
            ownerName,
            campaignURL,
            status,
            campaignCategory,
            campaignSubCategory,
            budget,
            totalSpend,
            phoneNumber,
            edit,
            refCode,
            email,
            startDate,
        } = this.state;
        const {referralCode, login, client} = this.props;

        let newRefrralCode = {
            referralCode: refCode,
            startDate: moment(startDate).valueOf(),
            email,
            phoneNumber,
            ownerName,
            status,
            campaignURL,
            campaignCategory,
            campaignSubCategory,
            budget,
            totalSpend,
        };
        if (edit) {
            newRefrralCode.referralCodeId = referralCode.editReferralCode.id;
            this.props.updateReferrals(newRefrralCode, login.userDetails.data.accessToken, client.selectedClient);
        } else {
            this.props.addReferral(newRefrralCode, login.userDetails.data.accessToken, client.selectedClient);
        }
    };

    componentDidMount() {
        const {history, login} = this.props;

        if (!login.userDetails.data) {
            history.push('/');
            this.props.logout();
            return;
        }

        const {
            ownerName,
            campaignURL,
            status,
            campaignCategory,
            campaignSubCategory,
            budget,
            totalSpend,
            phoneNumber,
            referralCode,
            email,
            startDate,
        } = this.props.referralCode.editReferralCode;

        const {edit} = this.state;

        if (edit) {
            this.setState({
                refCode: referralCode,
                ownerName,
                campaignURL,
                status,
                campaignCategory,
                campaignSubCategory,
                budget,
                totalSpend,
                phoneNumber,
                email,
                startDate,
            });
        }
    }

    componentDidUpdate(prevProps) {
        const {history, referralCode} = this.props;

        if (
            (referralCode.addReferralRequest && !prevProps.referralCode.addReferralRequest) ||
            (referralCode.updateReferralRequest && !prevProps.referralCode.updateReferralRequest)
        ) {
            this.setState({loading: true});
        }
        if (referralCode.addReferralSuccess && !prevProps.referralCode.addReferralSuccess) {
            history.push('/referralCodes');
        } else if (referralCode.addReferralFail && !prevProps.referralCode.addReferralFail) {
            this.setState({loading: false, errorMessage: referralCode.errorMessage});
        }
        if (referralCode.updateReferralSuccess && !prevProps.referralCode.updateReferralSuccess) {
            history.push('/referralCodes');
        } else if (referralCode.updateReferralFail && !prevProps.referralCode.updateReferralFail) {
            this.setState({loading: false, errorMessage: referralCode.errorMessage});
        }
    }

    render() {
        const {
            ownerName,
            campaignURL,
            status,
            campaignCategory,
            campaignSubCategory,
            budget,
            totalSpend,
            phoneNumber,
            edit,
            loading,
            errorMessage,
            refCode,
            email,
            startDate,
        } = this.state;
        const {history, login} = this.props;
        const {referralWrite} = login?.permissions || {};

        const {newUsersGained, createdAt, updatedAt, usersConverted, fcCoinsGained, user} =
            this.props.referralCode.editReferralCode;
        let title = edit ? 'Edit Ref Code | External (Non App User)' : 'Add Ref Code';

        return (
            <DetailLayout location="addRefCode" history={history} centerTitle={title}>
                <LoadingModal open={loading} />

                <Snackbar
                    open={!!errorMessage}
                    message={errorMessage}
                    onClose={() => this.setState({errorMessage: ''})}
                />
                <div className="detail-container margin-top-20">
                    <div className="grid-item" />
                    <div className="grid-item">
                        <Card>
                            <form onSubmit={this.handleSubmit}>
                                <div className="card-grid-container">
                                    <div className="grid-item  margin-top-20">
                                        <Input
                                            label="Owner Full Name"
                                            type="text"
                                            name="ownerName"
                                            value={ownerName || ''}
                                            placeholder="Enter Owner Name"
                                            required
                                            onChange={(value) => this.handleChange('ownerName', value)}
                                        />
                                    </div>
                                    <div className="grid-item margin-top-20 align-right">
                                        {!!referralWrite && (
                                            <Button
                                                buttonText={edit ? 'Update Referral Code' : 'Create Referral Code'}
                                                type="submit"
                                            />
                                        )}
                                    </div>
                                    <div className="grid-item  margin-top-20">
                                        <Input
                                            label="Referral Code"
                                            type="text"
                                            name="refCode"
                                            value={refCode || ''}
                                            onChange={(value) => this.handleChange('refCode', value)}
                                            placeholder="Enter Referral Code"
                                            disabled={edit}
                                            required
                                            inputProps={{
                                                pattern: '[A-Z]{1,4}[0-9]{1,4}',
                                                minLength: 8,
                                                maxLength: 8,
                                                title: 'Please enter valid Referral code having 8 uppercase alphanumeric characters! eg. XXXXMMYY, TEST1212',
                                            }}
                                        />
                                    </div>
                                    <div className="grid-item  margin-top-20">
                                        <DateTimePickerComponent
                                            label="Start Date"
                                            value={startDate || null}
                                            minDate={new Date()}
                                            required
                                            onChange={(value) => this.handleChange('startDate', value)}
                                        />
                                    </div>
                                    <div className="grid-item  margin-top-20">
                                        <Input
                                            label="Phone Number"
                                            type="text"
                                            name="phoneNumber"
                                            value={phoneNumber || ''}
                                            placeholder="Enter Phone Number"
                                            onChange={(value) => this.handleChange('phoneNumber', value)}
                                            required={!!phoneNumber}
                                            inputProps={{
                                                pattern: PHONE_PATTERN,
                                                title: 'Please enter valid phone number',
                                            }}
                                        />
                                    </div>
                                    <div className="grid-item margin-top-20">
                                        <Input
                                            label="Campaign URL"
                                            type="text"
                                            name="url"
                                            value={campaignURL || ''}
                                            onChange={(value) => this.handleChange('campaignURL', value)}
                                            placeholder="Enter campaign url"
                                            required
                                            inputProps={{
                                                pattern: URL_PATTERN,
                                                title: 'Please enter valid URL eg. https://fanclash.in',
                                            }}
                                        />
                                    </div>
                                    <div className="grid-item margin-top-20">
                                        <DropDown
                                            menuItems={REFERRAL_CAMPAIGN_OPTIONS.STATUS || []}
                                            required
                                            label="Status"
                                            value={status || ''}
                                            placeholder="select"
                                            onChange={(value) => this.handleChange('status', value)}
                                        />
                                    </div>
                                    <div className="grid-item margin-top-20">
                                        <DropDown
                                            menuItems={REFERRAL_CAMPAIGN_OPTIONS.CAMPAIGN_CATEGORY || []}
                                            label="Campaign Category"
                                            value={campaignCategory || ''}
                                            placeholder="select"
                                            required
                                            disabled={edit}
                                            onChange={(value) => this.handleChange('campaignCategory', value)}
                                        />
                                    </div>
                                    {!!REFERRAL_CAMPAIGN_OPTIONS[campaignCategory] && (
                                        <div className="grid-item margin-top-20">
                                            <DropDown
                                                menuItems={REFERRAL_CAMPAIGN_OPTIONS[campaignCategory] || []}
                                                label="Campaign Sub Category"
                                                value={campaignSubCategory || ''}
                                                placeholder="select"
                                                required
                                                disabled={edit}
                                                onChange={(value) => this.handleChange('campaignSubCategory', value)}
                                            />
                                        </div>
                                    )}
                                    <div className="grid-item margin-top-20">
                                        <DropDown
                                            menuItems={REFERRAL_CAMPAIGN_OPTIONS.BUDGET || []}
                                            label="Budget"
                                            value={budget || ''}
                                            placeholder="select"
                                            required
                                            disabled={edit}
                                            onChange={(value) => this.handleChange('budget', value)}
                                        />
                                    </div>
                                    {(campaignCategory === REFERRAL_CAMPAIGNS.CAMPAIGN_CATEGORY.INFLUENCER ||
                                        campaignCategory === REFERRAL_CAMPAIGNS.CAMPAIGN_CATEGORY.AFFILIATES) && (
                                        <div className="grid-item  margin-top-20">
                                            <Input
                                                label="Email Address"
                                                type="email"
                                                name="email"
                                                value={email || ''}
                                                onChange={(value) => this.handleChange('email', value)}
                                                placeholder="Enter email"
                                                required
                                            />
                                        </div>
                                    )}
                                    {budget === REFERRAL_CAMPAIGNS.BUDGET.FIXED && (
                                        <div className="grid-item  margin-top-20">
                                            <Input
                                                label="Total Spend"
                                                type="number"
                                                name="totalSpend"
                                                value={totalSpend || ''}
                                                placeholder="Enter Total Spend"
                                                onChange={(value) => this.handleChange('totalSpend', value)}
                                                required
                                            />
                                        </div>
                                    )}
                                </div>
                            </form>
                        </Card>
                    </div>
                    <div className="grid-item">
                        {edit && (
                            <React.Fragment>
                                <div className="margin-top-20">
                                    <div className="basic-headers">Last Updated</div>
                                    <div className="basiic-data">{timeFormat(updatedAt)}</div>
                                </div>
                                <div className="margin-top-20">
                                    <div className="basic-headers">Active Since</div>
                                    <div className="basiic-data">{timeFormat(createdAt)}</div>
                                </div>
                                <div className="disp-flex align-left margin-top-20">
                                    <div className="margin-right-16">
                                        <div className="basic-headers"># of new Users</div>
                                        <div className="basiic-data">{newUsersGained}</div>
                                    </div>
                                    <div>
                                        <div className="basic-headers"># of Converted Users</div>
                                        <div className="basiic-data">{usersConverted}</div>
                                    </div>
                                </div>
                                <div className="margin-top-20">
                                    <div className="basic-headers">Total FC Coins gained</div>
                                    <div className="basiic-data">{fcCoinsGained}</div>
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </DetailLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        referralCode: state.referralCode,
        client: state.client,
    };
};

export default connect(mapStateToProps, {addReferral, updateReferrals, logout})(AddRefCode);
