import {
    USER_LIST,
    USER_LIST_SUCCESS,
    USER_LIST_FAIL,
    BLOCK_USER,
    BLOCK_USER_SUCCESS,
    BLOCK_USER_FAIL,
    APPROVE_KYC,
    APPROVE_KYC_SUCCESS,
    APPROVE_KYC_FAIL,
    USERTRANSACTION,
    USERTRANSACTION_SUCCESS,
    USERTRANSACTION_FAIL,
    CURRENT_USER,
    UNBLOCK_USER,
    UNBLOCK_USER_SUCCESS,
    UNBLOCK_USER_FAIL,
    MANUALLY_BANKING_KYC,
    MANUALLY_BANKING_KYC_SUCCESS,
    MANUALLY_BANKING_KYC_FAIL,
    GET_USER_KYC_DOCS,
    GET_USER_KYC_DOCS_SUCCESS,
    GET_USER_KYC_DOCS_FAIL,
    FETCH_APP_USERS,
    FETCH_APP_USERS_SUCCESS,
    FETCH_APP_USERS_FAIL,
    USER_KYC_MANUAL_ACTION_REQUEST,
    USER_KYC_MANUAL_ACTION_SUCCESS,
    USER_KYC_MANUAL_ACTION_FAILURE,
} from './Constants';

const initialState = {
    userListRequest: false,
    userListSuccess: false,
    userListFail: false,
    userList: [],
    errorMessage: '',
    blockUserRequest: false,
    blockUserSuccess: false,
    blockUserFail: false,
    blockUser: [],
    approveKYCRequest: false,
    approveKYCSuccess: false,
    approveKYCFail: false,
    approveKYC: [],
    userTransactionRequest: false,
    userTransactionSuccess: false,
    userTransactionFail: false,
    userTransaction: [],
    currentUser: [],
    unBlockUserRequest: false,
    unBlockUserSuccess: false,
    unBlockUserFail: false,
    userKycDocs: {},
    isGettingUserKycDocs: false,
    isGettingUserKycDocsSuccess: false,
    isGettingUserKycDocsFail: false,
    // isManuallyProcessKyc: false,
    // isManuallyProcessKycSuccess: false,
    // isManuallyProcessKycFail: false,

    isManuallyBankingKyc: false,
    isManuallyBankingKycSuccess: false,
    isManuallyBankingKycFail: false,

    appUsersList: {},
    isFetchingAppUsers: false,
    isFetchingAppUsersSuccess: false,
    isFetchingAppUsersFail: false,

    userKycManualActionRequest: false,
    userKycManualActionSuccess: false,
    userKycManualActionFailure: false,
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        //list all users
        case USER_LIST:
            return {
                ...state,
                userListRequest: true,
                userListSuccess: false,
                userListFail: false,
                userList: [],
                errorMessage: '',
            };

        case USER_LIST_SUCCESS:
            return {
                ...state,
                userListRequest: false,
                userListSuccess: true,
                userListFail: false,
                userList: action.payload.data,
                errorMessage: '',
            };

        case USER_LIST_FAIL:
            return {
                ...state,
                userListRequest: false,
                userListSuccess: false,
                userListFail: true,
                errorMessage: action.payload,
            };

        //block users

        case BLOCK_USER:
            return {
                ...state,
                blockUserRequest: true,
                blockUserSuccess: false,
                blockUserFail: false,
                blockUser: [],
                errorMessage: '',
            };

        case BLOCK_USER_SUCCESS:
            return {
                ...state,
                blockUserRequest: false,
                blockUserSuccess: true,
                blockUserFail: false,
                blockUser: action.payload.data,
                errorMessage: '',
            };

        case BLOCK_USER_FAIL:
            return {
                ...state,
                blockUserRequest: false,
                blockUserSuccess: false,
                blockUserFail: true,
                errorMessage: action.payload,
            };

        //unblock users
        case UNBLOCK_USER:
            return {
                ...state,
                unBlockUserRequest: true,
                unBlockUserSuccess: false,
                unBlockUserFail: false,
                errorMessage: '',
            };

        case UNBLOCK_USER_SUCCESS:
            return {
                ...state,
                unBlockUserRequest: false,
                unBlockUserSuccess: true,
                unBlockUserFail: false,
                errorMessage: '',
            };

        case UNBLOCK_USER_FAIL:
            return {
                ...state,
                unBlockUserRequest: false,
                unBlockUserSuccess: false,
                unBlockUserFail: true,
                errorMessage: action.payload,
            };

        //APPROVE KYC
        case APPROVE_KYC:
            return {
                ...state,
                approveKYCRequest: true,
                approveKYCSuccess: false,
                approveKYCFail: false,
                approveKYC: [],
                errorMessage: '',
            };

        case APPROVE_KYC_SUCCESS:
            return {
                ...state,
                approveKYCRequest: false,
                approveKYCSuccess: true,
                approveKYCFail: false,
                approveKYC: action.payload.data,
                errorMessage: '',
            };

        case APPROVE_KYC_FAIL:
            return {
                ...state,
                approveKYCRequest: false,
                approveKYCSuccess: false,
                approveKYCFail: true,
                errorMessage: action.payload,
            };

        // transactions list
        case USERTRANSACTION:
            return {
                ...state,
                userTransactionRequest: true,
                userTransactionSuccess: false,
                userTransactionFail: false,
                userTransaction: [],
                errorMessage: '',
            };

        case USERTRANSACTION_SUCCESS:
            return {
                ...state,
                userTransactionRequest: false,
                userTransactionSuccess: true,
                userTransactionFail: false,
                userTransaction: action.payload.data,
                errorMessage: '',
            };

        case USERTRANSACTION_FAIL:
            return {
                ...state,
                userTransactionRequest: false,
                userTransactionSuccess: false,
                userTransactionFail: true,
                errorMessage: action.payload,
            };
        // current user data
        case CURRENT_USER:
            return {
                ...state,
                currentUser: action.payload,
            };

        //User kyc Docs
        case GET_USER_KYC_DOCS:
            return {
                ...state,
                isGettingUserKycDocs: true,
                isGettingUserKycDocsSuccess: false,
                isGettingUserKycDocsFail: false,
                userKycDocs: {},
                errorMessage: '',
            };

        case GET_USER_KYC_DOCS_SUCCESS:
            return {
                ...state,
                isGettingUserKycDocs: false,
                isGettingUserKycDocsSuccess: true,
                isGettingUserKycDocsFail: false,
                userKycDocs: action.payload,
                errorMessage: '',
            };

        case GET_USER_KYC_DOCS_FAIL:
            return {
                ...state,
                isGettingUserKycDocs: false,
                isGettingUserKycDocsSuccess: false,
                isGettingUserKycDocsFail: true,
                errorMessage: action.payload,
            };

        //Manually Process Kyc
        // case MANUALLY_PROCESS_KYC:
        //     return {
        //         ...state,
        //         isManuallyProcessKyc: true,
        //         isManuallyProcessKycSuccess: false,
        //         isManuallyProcessKycFail: false,
        //         errorMessage: '',
        //     };

        // case MANUALLY_PROCESS_KYC_SUCCESS:
        //     return {
        //         ...state,
        //         isManuallyProcessKyc: false,
        //         isManuallyProcessKycSuccess: true,
        //         isManuallyProcessKycFail: false,
        //         errorMessage: '',
        //     };

        // case MANUALLY_PROCESS_KYC_FAIL:
        //     return {
        //         ...state,
        //         isManuallyProcessKyc: false,
        //         isManuallyProcessKycSuccess: false,
        //         isManuallyProcessKycFail: true,
        //         errorMessage: action.payload,
        //     };

        //Manually Banking Kyc
        case MANUALLY_BANKING_KYC:
            return {
                ...state,
                isManuallyBankingKyc: true,
                isManuallyBankingKycSuccess: false,
                isManuallyBankingKycFail: false,
                errorMessage: '',
            };

        case MANUALLY_BANKING_KYC_SUCCESS:
            return {
                ...state,
                isManuallyBankingKyc: false,
                isManuallyBankingKycSuccess: true,
                isManuallyBankingKycFail: false,
                errorMessage: '',
            };

        case MANUALLY_BANKING_KYC_FAIL:
            return {
                ...state,
                isManuallyBankingKyc: false,
                isManuallyBankingKycSuccess: false,
                isManuallyBankingKycFail: true,
                errorMessage: action.payload,
            };

        case FETCH_APP_USERS:
            return {
                ...state,
                isFetchingAppUsers: true,
                isFetchingAppUsersSuccess: false,
                isFetchingAppUsersFail: false,
                appUsersList: {},
                errorMessage: '',
            };

        case FETCH_APP_USERS_SUCCESS:
            return {
                ...state,
                isFetchingAppUsers: false,
                isFetchingAppUsersSuccess: true,
                isFetchingAppUsersFail: false,
                appUsersList: action.payload.data,
                errorMessage: '',
            };

        case FETCH_APP_USERS_FAIL:
            return {
                ...state,
                isFetchingAppUsers: false,
                isFetchingAppUsersSuccess: false,
                isFetchingAppUsersFail: true,
                errorMessage: action.payload,
            };
        case USER_KYC_MANUAL_ACTION_REQUEST:
            return {
                ...state,
                userKycManualActionRequest: true,
                userKycManualActionSuccess: false,
                userKycManualActionFailure: false,
            };
        case USER_KYC_MANUAL_ACTION_SUCCESS:
            return {
                ...state,
                userKycManualActionRequest: false,
                userKycManualActionSuccess: true,
                userKycManualActionFailure: false,
                errorMessage: action.payload,
            };
        case USER_KYC_MANUAL_ACTION_FAILURE:
            return {
                ...state,
                userKycManualActionRequest: false,
                userKycManualActionSuccess: false,
                userKycManualActionFailure: true,
                errorMessage: action.payload,
            };

        default:
            return state;
    }
};

export default userReducer;
