import React from 'react';

import './Timer.css';
import Timer from 'react-compound-timer';
import moment from 'moment';

class TimerTrack extends React.Component {
    render() {
        const {initialTime, direction, startTime} = this.props;
        const today = moment();
        const matchdate = moment(startTime);
        const totalHours = matchdate.diff(today, 'hours');
        const d = Math.ceil(totalHours / 24);
        const h = totalHours % 24;
        const days = d < 0 ? d * -1 : d;
        const hours = h < 0 ? h * -1 : h;

        return (
            <React.Fragment>
                {initialTime === undefined ? null : (
                    <div className="timer">
                        <Timer initialTime={initialTime} direction={direction}>
                            <Timer.Days formatValue={(value) => `${days} d : `} />
                            <Timer.Hours formatValue={(value) => `${hours < 10 ? '0' + hours : hours} h : `} />
                            <Timer.Minutes formatValue={(value) => `${value < 10 ? `0${value}` : value} m : `} />
                            <Timer.Seconds formatValue={(value) => `${value < 10 ? `0${value}` : value} s`} />
                        </Timer>
                    </div>
                )}
            </React.Fragment>
        );
    }
}

export default TimerTrack;
