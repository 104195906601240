import React from 'react';
import PropTypes from 'prop-types';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';
import Button from '../../../../Components/Button/Button';
import {connect} from 'react-redux';
import LoadingModal from '../../../../Components/LoadingModal/LoadingModal';
import Snackbar from '../../../../Components/Snackbar/Snackbar';
import {getContestList} from '../../Contests/AxioCalls';

class MatchContestListModal extends React.Component {
    static propTypes = {
        open: PropTypes.bool,
        matchDetails: PropTypes.object,
        handleClose: PropTypes.func,
    };

    static defaultProps = {
        matchDetails: {},
        handleClose: () => {},
        open: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            list: [],
            loading: false,
            errorMessage: '',
        };
    }

    componentDidMount() {
        this.handleFetchContests();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {contest} = this.props;

        if (contest.contestListRequest && !prevProps.contest.contestListRequest) {
            this.setState({loading: true});
        }
        if (contest.contestListSuccess && !prevProps.contest.contestListSuccess) {
            this.setState({
                list: contest.contestList.data.data,
                loading: false,
            });
        }
        if (contest.contestListFail && !prevProps.contest.contestListFail) {
            this.setState({errorMessage: contest.errorMessage, loading: false});
        }
    }

    handleFetchContests = () => {
        let {
            login,
            matchDetails: {matchId, contestsCount},
        } = this.props;
        const {client} = this.props;
        const data = {page: 1, listType: 'ALL', searchString: matchId, pageSize: contestsCount || 20};
        this.props.getContestList(data, login.userDetails.data.accessToken, client.selectedClient);
    };

    render() {
        let {handleClose, open} = this.props;
        let {list = []} = this.state;
        let {loading, errorMessage} = this.state;

        return (
            <Dialog
                open={open}
                onClose={handleClose}
                scroll="paper"
                maxWidth="md"
                fullWidth
                disableEscapeKeyDown
                disableBackdropClick
            >
                {!!loading && <LoadingModal open={loading} />}
                {!!errorMessage && (
                    <Snackbar
                        open={!!errorMessage}
                        message={errorMessage}
                        onClose={() => this.setState({errorMessage: ''})}
                    />
                )}

                <DialogTitle>Contests</DialogTitle>
                <DialogContent className="px-0" dividers={true}>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell>Id</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Fill Rate</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(list || []).map((contest, index) => (
                                    <TableRow key={`${contest.id}_${index}`}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{contest.id}</TableCell>
                                        <TableCell>{contest.name}</TableCell>
                                        <TableCell>
                                            {contest.totalSlotsEntered} / {contest.totalSlots}
                                        </TableCell>
                                    </TableRow>
                                ))}

                                {!list.length && (
                                    <TableRow>
                                        <TableCell className="text-center" colSpan="10">
                                            No Contest Available
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button buttonText="Cancel" onClick={handleClose} />
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        contest: state.contest,
        login: state.login,
        client: state.client,
    };
};

export default connect(mapStateToProps, {getContestList})(MatchContestListModal);
