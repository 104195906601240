import React from 'react';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import './Tabs.css';
import {makeStyles} from '@material-ui/core/styles';

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
const useStyles = makeStyles((theme) => ({
    demo1: {
        '&:focus': {
            color: '#FFC954',
            fontWeight: 'bold',
        },
    },
}));

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}
export default function TabWithPanel(props) {
    const classes = useStyles();
    const {
        tab1Label,
        tab2Label,
        tab3Label,
        tab4Label,
        panelA,
        panelB,
        panelC,
        panelD,
        activeTab,
        disableTab1,
        disableTab2,
        disableTab3,
        disableTab4,
    } = props;
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    let currentTab = activeTab ? activeTab : value;
    return (
        <div className={classes.demo1}>
            <Paper id="tabs" square>
                <Tabs className={classes.demo1} id="tabs" value={currentTab} onChange={handleChange}>
                    <Tab label={tab1Label} {...a11yProps(0)} disabled={disableTab1} />
                    <Tab label={tab2Label} {...a11yProps(1)} disabled={disableTab2} />
                    <Tab label={tab3Label} {...a11yProps(2)} disabled={disableTab3} />
                    {tab4Label ? <Tab label={tab4Label} {...a11yProps(3)} disabled={disableTab4} /> : null}
                </Tabs>
                <TabPanel value={currentTab} index={0}>
                    {panelA}
                </TabPanel>
                <TabPanel value={currentTab} index={1}>
                    {panelB}
                </TabPanel>
                <TabPanel value={currentTab} index={2}>
                    {panelC}
                </TabPanel>
                {tab4Label ? (
                    <TabPanel value={currentTab} index={3}>
                        {panelD}
                    </TabPanel>
                ) : null}
            </Paper>
        </div>
    );
}
