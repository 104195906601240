import React from 'react';
import '../../../Utilities/style.css';
import '../Login/login.css';
import TableLayout from '../../Layouts/TableLayout/TableLayout';
import Table from '../../../Components/Table/Table';
import {connect} from 'react-redux';
import Modal from '../../../Components/Modal/Modal';
import Card from '../../../Components/Card/Card';
import Close from '../../../Images/close_yellow.png';
import Button from '../../../Components/Button/Button';
import {getReferralList} from './AxioCalls';
import {editReferralCode} from './action';
import {logout} from '../Login/LoginAction';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';
import Snackbar from '../../../Components/Snackbar/Snackbar';
import {timeFormat} from '../../../Utilities/Helpers';
import {
    debounce,
    getFiltersSessionStorageItem,
    next,
    prev,
    setFiltersSessionStorageItem,
} from '../../../Utilities/HelperFunctions';
import FilterInput from '../../../Components/FilterInput';
import DropDown from '../../../Components/Dropdown/Dropdown';
import {REFERRAL_CAMPAIGN_OPTIONS, SCREEN_NAMES} from '../../../Utilities/Constants';
import DateRangeSelect from '../../../Components/DateRangeSelect';

class ReferralCodeList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            open: false,
            loading: false,
            errorMessage: '',
            pageData: {},
            pageOptions: {
                page: 1,
                pageSize: 20,
                ...getFiltersSessionStorageItem(SCREEN_NAMES.REFERRAL_CODES),
            },
            sortOptions: [
                {value: 'referralCode', text: 'Ref Code'},
                {value: 'ownerName', text: 'Owner'},
                {value: 'phoneNumber', text: 'Phone Number'},
                {value: 'email', text: 'Email Address'},
                {text: 'User', value: 'user'},
                {text: 'Active Since', value: 'createdAt'},
                {text: 'Total FC Coins gained', value: 'fcCoinsGained'},
                {text: '# of new Users', value: 'newUsersGained'},
                {text: '# of Converted Users', value: 'usersConverted'},
                {text: 'Money Converted', value: 'moneyConverted'},
                {text: 'Status', value: 'status'},
            ],
        };
    }

    /**
     * function to save entered data in state
     * @param {string} field field names from the form
     * @param {*} value value in the field
     */
    handleInputChange(field, value) {
        this.setState({[field]: value});
    }

    componentWillMount() {
        const {history, login} = this.props;
        if (!login.userDetails.data) {
            history.push('/');
            this.props.logout();
        } else {
            this.getReferralListDebounce({immediate: true});
        }
    }

    componentDidUpdate(prevProps) {
        const {referralCode} = this.props;

        if (referralCode.referralListRequest && !prevProps.referralCode.referralListRequest) {
            this.setState({loading: true});
        }
        if (referralCode.referralListSuccess && !prevProps.referralCode.referralListSuccess) {
            this.setState({
                loading: false,
                list: referralCode.referralList.data.data,
                pageData: referralCode.referralList.data.pageData,
            });
        } else if (referralCode.referralListFail && !prevProps.referralCode.referralListFail) {
            this.setState({loading: false, errorMessage: referralCode.errorMessage});
        }
    }

    /**
     * details of the refrerral code to be edited
     * @param {*} referral referral clicked on
     */
    onEditCode(referral) {
        this.props.editReferralCode(referral);
        this.props.history.push('/editRefCode/' + referral.id);
    }

    handleAddReferral = () => {
        this.props.history.push('/addRefCode');
    };

    /**
     * reset table data
     */
    onReset = () => {
        this.setState({pageOptions: {page: 1, pageSize: 20}}, () => {
            this.getReferralListDebounce({immediate: true});
        });
    };

    /**
     * pagination  and api call on next click
     */
    onNext = () => {
        const {pageData, pageOptions} = this.state;
        let options = next(pageOptions, pageData);
        if (options) {
            this.setState({pageOptions: options}, () => this.getReferralListDebounce({immediate: true}));
        }
    };

    /**
     * pagination  and api call on  prev click
     */
    onPrev = () => {
        const {pageOptions} = this.state;
        let options = prev(pageOptions);
        if (options) {
            this.setState({pageOptions: options}, () => this.getReferralListDebounce({immediate: true}));
        }
    };

    /**
     * navigate to page ref code details based on column header
     * @param {*} data row details of selected ref code
     */
    SelectedRefCode(data) {
        let header = data.column.Header;
        this.props.editReferralCode(data.original);
        if (header === '# of new Users') {
            this.props.history.push('/refNewUsers');
        } else if (header === '# of Converted Users') {
            this.props.history.push('/refConvertedUsers');
        } else if ('Money Converted') {
            this.props.history.push('/moneyConverted');
        }
    }

    getReferralListDebounce = debounce(() => {
        const {login, client} = this.props;
        setFiltersSessionStorageItem(SCREEN_NAMES.REFERRAL_CODES, this.state.pageOptions);
        this.props.getReferralList(this.state.pageOptions, login.userDetails.data.accessToken, client.selectedClient);
    });

    handleFilter = (field, value, debounce = true) => {
        const {pageOptions} = this.state;
        if (field === 'Enter') {
            this.getReferralListDebounce({immediate: true});
            return;
        }
        if (field === 'dates') {
            pageOptions.startDate = value.start;
            pageOptions.endDate = value.end;
        } else {
            pageOptions[field] = value;
        }
        this.setState({pageOptions: {...pageOptions, page: 1}}, () => {
            this.getReferralListDebounce(debounce ? {} : {immediate: true});
        });
    };

    render() {
        const {open, list: data, loading, errorMessage, sortOptions, pageData} = this.state;
        const {searchString, sortOrder, page, referralCode, campaignCategory, ownerName, startDate, endDate} =
            this.state.pageOptions;

        const {referralWrite} = this.props?.login?.permissions || {};

        let disableNext = page === pageData.lastPage;

        const columns = [
            {
                Header: 'Ref Code',
                accessor: 'referralCode',
                Cell: (props) => (
                    <span className="table-clickable cursor" onClick={this.onEditCode.bind(this, props.original)}>
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Owner',
                accessor: 'ownerName', // accessor is the "key" in the data
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'FC user ID',
                accessor: 'user', // accessor is the "key" in the data
                width: 200,
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Category',
                accessor: 'campaignCategory',
                Cell: (props) => <span className="number date-space">{props.value ? props.value : '-'}</span>,
            },
            {
                Header: 'Sub Category',
                accessor: 'campaignSubCategory',
                Cell: (props) => <span className="number date-space">{props.value ? props.value : '-'}</span>,
            },
            {
                Header: 'Budget',
                accessor: 'budget',
                Cell: (props) => <span className="number date-space">{props.value ? props.value : '-'}</span>,
            },
            {
                Header: 'Total Spend',
                accessor: 'totalSpend',
                Cell: (props) => <span className="number date-space">{props.value ? props.value : '-'}</span>,
            },
            {
                Header: 'Active Since',
                accessor: 'createdAt',
                Cell: (props) => (
                    <span className="number date-space">
                        {props.value === undefined || props.value === '' ? '-' : timeFormat(props.value)}
                    </span>
                ),
            },
            {
                Header: 'Start Date',
                accessor: 'startDate',
                Cell: (props) => (
                    <span className="number date-space">
                        {props.value === undefined || props.value === '' ? '-' : timeFormat(props.value)}
                    </span>
                ),
            },
            {
                Header: 'Total FC Coins gained',
                accessor: 'fcCoinsGained', // accessor is the "key" in the data
                width: 150,
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: '# of new Users',
                accessor: 'newUsersGained',
                width: 150,
                Cell: (props) => (
                    <span className="table-clickable cursor number" onClick={this.SelectedRefCode.bind(this, props)}>
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: '# of Converted Users',
                accessor: 'usersConverted',
                width: 150,
                Cell: (props) => (
                    <span className="table-clickable cursor number" onClick={this.SelectedRefCode.bind(this, props)}>
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Money Converted',
                accessor: 'moneyConverted',
                Cell: (props) => (
                    <span className="table-clickable cursor number" onClick={this.SelectedRefCode.bind(this, props)}>
                        {props.value === undefined || props.value === '' ? '-' : '₹ ' + props.value}
                    </span>
                ),
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Campagin URL',
                accessor: 'campaignURL',
                width: 300,
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
        ];
        return (
            <TableLayout location="referralCodes" history={this.props.history} hideSearchBar>
                <LoadingModal open={loading} />
                <Snackbar
                    open={!!errorMessage}
                    message={errorMessage}
                    onClose={() => this.setState({errorMessage: ''})}
                />

                <Modal open={open}>
                    <div className="container modal-top-margin">
                        <Card>
                            <div className="disp-flex">
                                <div className="modal-left-header">Delete / Block Ref Code</div>
                                <div onClick={() => this.setState({open: false})}>
                                    <img src={Close} alt="" className="cursor" />
                                </div>
                            </div>
                            <div className="align-center equalcontainer-grids">
                                <div />
                                <div>
                                    <div className="disp-flex align-center margin-top-30">
                                        <div className="margin-right-16">
                                            <Button buttonText="Delete" onClick={this.handleInputChange} />
                                        </div>
                                        <Button buttonText="Block" onClick={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                </Modal>

                <div className="filter-container my-10">
                    <FilterInput
                        placeholder="Referral Code"
                        value={referralCode || ''}
                        onChange={(value) => this.handleFilter('referralCode', value)}
                        onEnter={() => this.handleFilter('Enter')}
                    />
                    <FilterInput
                        placeholder="Owner Name"
                        value={ownerName || ''}
                        onChange={(value) => this.handleFilter('ownerName', value)}
                        onEnter={() => this.handleFilter('Enter')}
                    />
                    <DropDown
                        label="Campaign Category"
                        value={campaignCategory || ''}
                        menuItems={REFERRAL_CAMPAIGN_OPTIONS.CAMPAIGN_CATEGORY || []}
                        onChange={(value) => this.handleFilter('campaignCategory', value, false)}
                    />
                    <DropDown
                        label="Sort By"
                        value={sortOrder || ''}
                        menuItems={sortOptions}
                        onChange={(value) => this.handleFilter('sortOrder', value, false)}
                    />
                    <DateRangeSelect
                        startDate={startDate}
                        endDate={endDate}
                        isClearable={true}
                        onChange={(dates) => this.handleFilter('dates', dates, false)}
                    />
                    <Button buttonText="Reset" onClick={this.onReset} />

                    {!!referralWrite && (
                        <Button className="mt-15" buttonText="Add Ref Code" onClick={this.handleAddReferral} />
                    )}
                </div>

                <Table
                    data={data}
                    columns={columns}
                    page={page}
                    onPrevClick={this.onPrev}
                    onNextClick={this.onNext}
                    disableNext={disableNext}
                ></Table>
            </TableLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        squad: state.squad,
        referralCode: state.referralCode,
        client: state.client,
    };
};

export default connect(mapStateToProps, {getReferralList, editReferralCode, logout})(ReferralCodeList);
