import {
    ADMIN_LIST,
    ADMIN_LIST_SUCCESS,
    ADMIN_LIST_FAIL,
    EDIT_ADMIN_DATA,
    ADD_ADMIN,
    ADD_ADMIN_SUCCESS,
    ADD_ADMIN_FAIL,
    DELETE_ADMIN,
    DELETE_ADMIN_SUCCESS,
    DELETE_ADMIN_FAIL,
} from './Constants';

/**
 * list all admin api request
 */
export function listAdmin() {
    return {
        type: ADMIN_LIST,
    };
}

/**
 *handle list all admins api success
 * @param {*} data data from api
 */
export function listAdminSuccess(data) {
    return {
        type: ADMIN_LIST_SUCCESS,
        payload: data,
    };
}

/**
 *handle list all admins api failure
 * @param {*} error error from api
 */
export function listAdminFail(error) {
    return {
        type: ADMIN_LIST_FAIL,
        payload: error,
    };
}

/**
 * create admin api resquest
 */
export function createAdmin() {
    return {
        type: ADD_ADMIN,
    };
}

/**
 *handle create admin api success
 * @param {*} data data from api
 */
export function createAdminSuccess(data) {
    return {
        type: ADD_ADMIN_SUCCESS,
        payload: data,
    };
}

/**
 *handle create admin api fail
 * @param {*} error error from api
 */
export function createAdminFail(error) {
    return {
        type: ADD_ADMIN_FAIL,
        payload: error,
    };
}

/**
 *delete admin api request
 */
export function deleteAdmin() {
    return {
        type: DELETE_ADMIN,
    };
}

/**
 *handle delete admin api success
 * @param {*} data data from api
 */
export function deleteAdminSuccess(data) {
    return {
        type: DELETE_ADMIN_SUCCESS,
        payload: data,
    };
}

/**
 *handle delete admin api fail
 * @param {*} error error from api
 */
export function deleteAdminFail(error) {
    return {
        type: DELETE_ADMIN_FAIL,
        payload: error,
    };
}
