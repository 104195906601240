import React from 'react';
import PropTypes from 'prop-types';
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';
import Button from '../Button/Button';

const ConfirmationModal = (props) => {
    let {open, handleClose, confirmationHeading, confirmButtonText, confirmationText, handleOnConfirm} = props;

    const handleConfirm = () => {
        handleOnConfirm();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            scroll="paper"
            maxWidth="sm"
            fullWidth
            disableEscapeKeyDown
            disableBackdropClick
        >
            <DialogTitle>{confirmationHeading}</DialogTitle>
            <DialogContent dividers={true}>
                <h3>{confirmationText}</h3>
            </DialogContent>
            <DialogActions>
                <Button buttonText="Cancel" onClick={handleClose} />
                <Button buttonText={confirmButtonText} onClick={handleConfirm} />
            </DialogActions>
        </Dialog>
    );
};

ConfirmationModal.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    handleOnConfirm: PropTypes.func,
    confirmButtonText: PropTypes.string,
    confirmationText: PropTypes.string,
    confirmationHeading: PropTypes.string,
};

ConfirmationModal.defaultProps = {
    handleClose: () => {},
    handleOnConfirm: () => {},
    open: false,
    confirmButtonText: 'Confirm',
    confirmationText: '',
    confirmationHeading: 'Confirmation',
};

export default ConfirmationModal;
