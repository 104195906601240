import Axios from 'axios';
import {UPLOAD_FILE, PUT_ARTICLE_FAILURE, PUT_ARTICLE_PROGRESS, PUT_ARTICLE_SUCCESS} from './InshortsConstants';
import {putArticlesCall} from './InshortsAxios';

export function uploadFile(file) {
    return {
        type: UPLOAD_FILE,
        payload: file,
    };
}

export function putArticles(file, token) {
    return async (dispatch) => {
        let res = putArticlesCall(file, token);
        dispatch({type: PUT_ARTICLE_PROGRESS});
        res = await res;
        if (res.error) {
            return dispatch({
                type: PUT_ARTICLE_FAILURE,
                payload: res.msg,
            });
        }
        return dispatch({type: PUT_ARTICLE_SUCCESS});
    };
}

export function reset() {
    return async (dispatch) => {
        return dispatch({
            type: 'RESET',
        });
    };
}
