import {
    EVENT_LIST,
    EVENT_LIST_SUCCESS,
    EVENT_LIST_FAIL,
    RECORD_EVENT,
    RECORD_EVENT_SUCCESS,
    RECORD_EVENT_FAIL,
    VERIFY_EVENT,
    VERIFY_EVENT_SUCCESS,
    VERIFY_EVENT_FAIL,
    RECORD_MATCH,
    VERIFY_MATCH,
    COMPLETE_EVENT,
    COMPLETE_EVENT_SUCCESS,
    COMPLETE_EVENT_FAIL,
    DELETE_EVENT,
    DELETE_EVENT_SUCCESS,
    DELETE_EVENT_FAIL,
    UPDATE_EVENT,
    UPDATE_EVENT_SUCCESS,
    UPDATE_EVENT_FAIL,
    PLAYERS_MATCH,
    PLAYERS_MATCH_SUCCESS,
    PLAYERS_MATCH_FAIL,
    COMPILE_EVENT,
    COMPILE_EVENT_SUCCESS,
    COMPILE_EVENT_FAIL,
} from './Constants';

/**
 * list events api request
 */
export function listEvents() {
    return {
        type: EVENT_LIST,
    };
}

/**
 *handle list event api success
 * @param {*} data api data
 */
export function listEventsSuccess(data) {
    return {
        type: EVENT_LIST_SUCCESS,
        payload: data,
    };
}

/**
 * handle list event api fail
 * @param {*} error error from api
 */
export function listEventsFail(error) {
    return {
        type: EVENT_LIST_FAIL,
        payload: error,
    };
}

//RECORD MATCH
/**
 * record events api request
 */
export function recordMatch() {
    return {
        type: RECORD_EVENT,
    };
}
/**
 *handle record event api success
 * @param {*} data api data
 */
export function recordMatchSuccess(data) {
    return {
        type: RECORD_EVENT_SUCCESS,
        payload: data,
    };
}
/**
 *handle record event api fail
 * @param {*} error error from api
 */
export function recordMatchFail(error) {
    return {
        type: RECORD_EVENT_FAIL,
        payload: error,
    };
}

// VERIFY MATCH
/**
 * verify events
 */
export function verifyMatch() {
    return {
        type: VERIFY_EVENT,
    };
}
/**
 *handle verify event api success
 * @param {*} data api data
 */
export function verifyMatchSuccess(data) {
    return {
        type: VERIFY_EVENT_SUCCESS,
        payload: data,
    };
}
/**
 *handle verify event api fail
 * @param {*} error error from api
 */
export function verifyMatchFail(error) {
    return {
        type: VERIFY_EVENT_FAIL,
        payload: error,
    };
}

//match beign recoded
/**
 * match being recorded
 * @param {*} match match details
 */
export function recordMatchId(match) {
    return {
        type: RECORD_MATCH,
        payload: match,
    };
}

//match being verified
/**
 * match being verified
 * @param {*} match
 */
export function verifyMatchDetails(match) {
    return {
        type: VERIFY_MATCH,
        payload: match,
    };
}

// complete match
/**
 * complete events api request
 */
export function completeMatch() {
    return {
        type: COMPLETE_EVENT,
    };
}
/**
 *handle complete event api success
 * @param {*} data api data
 */
export function completeMatchSuccess(data) {
    return {
        type: COMPLETE_EVENT_SUCCESS,
        payload: data,
    };
}
/**
 *handle complete event api fail
 * @param {*} error error from api
 */
export function completeMatchFail(error) {
    return {
        type: COMPLETE_EVENT_FAIL,
        payload: error,
    };
}

//edit event
/**
 * edit events api request
 */
export function updateEvent() {
    return {
        type: UPDATE_EVENT,
    };
}
/**
 *handle edit event api success
 * @param {*} data api data
 */
export function updateEventSuccess(data) {
    return {
        type: UPDATE_EVENT_SUCCESS,
        payload: data,
    };
}
/**
 *handle edit event api fail
 * @param {*} error error from api
 */
export function updateEventFail(error) {
    return {
        type: UPDATE_EVENT_FAIL,
        payload: error,
    };
}

//delete event
/**
 * delete events
 */
export function deleteEvent() {
    return {
        type: DELETE_EVENT,
    };
}
/**
 *handle delete event api success
 * @param {*} data api data
 */
export function deleteEventSuccess(data) {
    return {
        type: DELETE_EVENT_SUCCESS,
        payload: data,
    };
}
/**
 *handle delete event api fail
 * @param {*} error error from api
 */
export function deleteEventFail(error) {
    return {
        type: DELETE_EVENT_FAIL,
        payload: error,
    };
}

//players for this match
/**
 *players for this match
 */
export function playersForMatchRequest() {
    return {
        type: PLAYERS_MATCH,
    };
}
/**
 * handle players for this match api success
 * @param {*} data api data
 */
export function playersForMatchSuccess(data) {
    return {
        type: PLAYERS_MATCH_SUCCESS,
        payload: data,
    };
}
/**
 *handle players for this match api fail
 * @param {*} error error from api
 */
export function playersForMatchFail(error) {
    return {
        type: PLAYERS_MATCH_FAIL,
        payload: error,
    };
}

// compile match
/**
 *  compile match
 */
export function compileMatch() {
    return {
        type: COMPILE_EVENT,
    };
}
/**
 *handle compile match api success
 * @param {*} data api data
 */
export function compileMatchSuccess(data) {
    return {
        type: COMPILE_EVENT_SUCCESS,
        payload: data,
    };
}
/**
 *handle compile match api fail
 * @param {*} error error from api
 */
export function compileMatchFail(error) {
    return {
        type: COMPILE_EVENT_FAIL,
        payload: error,
    };
}
