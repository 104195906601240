import React from 'react';
import PropTypes from 'prop-types';
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';
import Button from '../../../../Components/Button/Button';

import Snackbar from '../../../../Components/Snackbar/Snackbar';
import {connect} from 'react-redux';
import LoadingModal from '../../../../Components/LoadingModal/LoadingModal';
import Input from '../../../../Components/Input/Input';
import {addPlayerInMatch} from '../PlayerAxios';

class AddPlayerInMatchModal extends React.Component {
    static propTypes = {
        open: PropTypes.bool,
        handleClose: PropTypes.func,
        addPlayer: PropTypes.object,
    };

    static defaultProps = {
        handleClose: () => {},
        open: false,
        addPlayer: {},
    };

    submitRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            errorMessage: '',
            squadId: props.addPlayer.squadId || '',
        };
    }

    componentDidMount() {}

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {
            player: {isAddingPlayerInMatchFail, errorMessage},
        } = this.props;

        if (isAddingPlayerInMatchFail && !prevProps.player.isAddingPlayerInMatchFail) {
            this.setState({errorMessage: errorMessage.errorReason});
        }
    }

    handleOnChange = (key, value) => {
        this.setState({[key]: value});
    };

    handleSubmit = (e) => {
        !!e && e.preventDefault();
        let {login, addPlayer} = this.props;
        let {playerId, squadId} = this.state;
        let data = {
            matchId: addPlayer.matchId,
            squadId,
            playerId,
        };
        this.props.addPlayerInMatch(data, login.userDetails.data.accessToken);
    };

    render() {
        let {handleClose, open, player, addPlayer} = this.props;
        let {errorMessage, playerId, squadId} = this.state;

        return (
            <Dialog
                open={open}
                onClose={handleClose}
                scroll="paper"
                maxWidth="sm"
                fullWidth
                disableEscapeKeyDown
                disableBackdropClick
            >
                <Snackbar
                    open={!!errorMessage}
                    message={errorMessage}
                    onClose={() => this.setState({errorMessage: ''})}
                />
                <LoadingModal open={player.isAddingPlayerInMatch} />
                <DialogTitle>Add squad and player in match</DialogTitle>
                <DialogContent dividers={true}>
                    <form onSubmit={this.handleSubmit}>
                        <Input
                            className="mb-15"
                            label="Squad Id"
                            type="text"
                            name="squadId"
                            required
                            value={squadId || ''}
                            placeholder="Enter Squad Id"
                            disabled={addPlayer.squadId}
                            onChange={(value) => this.handleOnChange('squadId', value)}
                        />

                        <Input
                            className="mb-15"
                            label="Player Id"
                            type="text"
                            name="playerId"
                            required
                            value={playerId || ''}
                            placeholder="Enter Player Id"
                            onChange={(value) => this.handleOnChange('playerId', value)}
                        />

                        <Button className="d-none" buttonText="Submit" type="submit" buttonRef={this.submitRef} />
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button buttonText="Cancel" onClick={handleClose} />
                    <Button buttonText="Submit" onClick={() => this.submitRef.current.click()} />
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        player: state.player,
    };
};

export default connect(mapStateToProps, {addPlayerInMatch})(AddPlayerInMatchModal);
