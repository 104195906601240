/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import './AutoCompleteField.css';
import PropTypes from 'prop-types';

export default function AutoCompleteField(props) {
    const {options, label, disabled, disableClearable} = props;
    const defaultProps = {
        disableClearable,
        options: options,
        getOptionLabel: (option) => option.title,
    };

    /**
     * send field and events to parent component (on select of dowpdown)
     * @param {*} event name of field
     * @param {*} value value in field
     */
    const handleChange = (event, value) => {
        props.onChange(value);
    };

    /**
     * send field and events to parent component  (on inputchange)
     * @param {*} event name of field
     * @param {*} string value in field
     */
    const handleInputChange = (event, string) => {
        props.inputChange(event, string);
    };

    return (
        <div>
            <Autocomplete
                id={disabled ? 'disabled' : null}
                disabled={disabled}
                {...defaultProps}
                value={props.value || null}
                onInputChange={(event, value) => handleInputChange(event, value)}
                onChange={(event, value) => handleChange(event, value)}
                renderInput={(params) => <TextField {...params} label={label} />}
            />
        </div>
    );
}

AutoCompleteField.propTypes = {
    inputChange: PropTypes.func,
};

AutoCompleteField.defaultProps = {
    inputChange: () => {},
};
