export const GET_RECOMMENDED_TEAMS = 'GET_RECOMMENDED_TEAMS';
export const GET_RECOMMENDED_TEAMS_SUCCESS = 'GET_RECOMMENDED_TEAMS_SUCCESS';
export const GET_RECOMMENDED_TEAMS_FAIL = 'GET_RECOMMENDED_TEAMS_FAIL';

export const CREATE_RECOMMENDED_TEAM = 'CREATE_RECOMMENDED_TEAM';
export const CREATE_RECOMMENDED_TEAM_SUCCESS = 'CREATE_RECOMMENDED_TEAM_SUCCESS';
export const CREATE_RECOMMENDED_TEAM_FAIL = 'CREATE_RECOMMENDED_TEAM_FAIL';

export const UPDATE_RECOMMENDED_TEAM = 'UPDATE_RECOMMENDED_TEAM';
export const UPDATE_RECOMMENDED_TEAM_SUCCESS = 'UPDATE_RECOMMENDED_TEAM_SUCCESS';
export const UPDATE_RECOMMENDED_TEAM_FAIL = 'UPDATE_RECOMMENDED_TEAM_FAIL';

export const DELETE_RECOMMENDED_TEAM = 'DELETE_RECOMMENDED_TEAM';
export const DELETE_RECOMMENDED_TEAM_SUCCESS = 'DELETE_RECOMMENDED_TEAM_SUCCESS';
export const DELETE_RECOMMENDED_TEAM_FAIL = 'DELETE_RECOMMENDED_TEAM_FAIL';

export const AUTO_CREATE_RECOMMENDED_TEAM = 'AUTO_CREATE_RECOMMENDED_TEAM';
export const AUTO_CREATE_RECOMMENDED_TEAM_SUCCESS = 'AUTO_CREATE_RECOMMENDED_TEAM_SUCCESS';
export const AUTO_CREATE_RECOMMENDED_TEAM_FAIL = 'AUTO_CREATE_RECOMMENDED_TEAM_FAIL';
