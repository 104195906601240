import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';
import Button from '../../../../Components/Button/Button';

import Snackbar from '../../../../Components/Snackbar/Snackbar';
import LoadingModal from '../../../../Components/LoadingModal/LoadingModal';
import Input from '../../../../Components/Input/Input';
import DropDown from '../../../../Components/Dropdown/Dropdown';
import {COMMON_STATUS} from '../../../../Utilities/Constants';
import {useDispatch, useSelector} from 'react-redux';
import {usePrevious} from '../../../../Utilities/hooks';
import {getTOGameList} from '../../TOGame/AxioCalls';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import DeleteIcon from '@material-ui/icons/Delete';
import {createBoosterConfig, fetchBoosterConfig, fetchBoosters, updateBoosterConfig} from '../action';
import EditIcon from '@material-ui/icons/Edit';
import {debounce} from '../../../../Utilities/HelperFunctions';
import AutoCompleteField from '../../../../Components/AutoCompleteField/AutoCompleteField';
import CreateEditBoosterModal from '../CreateEditBoosterModal';

const CreateEditBoosterConfigModal = (props) => {
    const {open, onClose, boosterConfigData} = props;
    const submitRef = useRef(null);
    let handleBoostersDebounce = useRef(() => {});
    const dispatch = useDispatch();
    const client = useSelector((state) => state.client);
    const login = useSelector((state) => state.login);
    const boosters = useSelector((state) => state.boosters);
    const prevBoosters = usePrevious(boosters);
    const toGame = useSelector((state) => state.toGame);
    const prevToGame = usePrevious(toGame);

    const [gameOptions, setGameOptions] = useState([]);
    const [boosterOptions, setBoosterOptions] = useState([]);
    const [state, setState] = useState({});
    const [formState, setFormState] = useState({});
    const [localBoosterData, setLocalBoosterData] = useState(null);
    const {errorMessage} = state;
    const loading =
        !!boosters.isFetchingBoosters ||
        !!boosters.isFetchingBoosterConfig ||
        !!boosters.isCreatingBoosterConfig ||
        !!boosters.isUpdatingBoosterConfig ||
        !!toGame.TOGameListRequest ||
        false;
    const {boosterWrite} = login?.permissions || {};

    useEffect(() => {
        if (boosterConfigData.id) {
            dispatch(fetchBoosterConfig({id: boosterConfigData.id}, login?.userDetails?.data?.accessToken));
        }
        dispatch(getTOGameList({listType: 'ALL'}, login?.userDetails?.data?.accessToken, client.selectedClient));
    }, []);

    useEffect(() => {
        handleBoostersDebounce.current = debounce(({name, gameId}) => {
            dispatch(fetchBoosters({name, gameId}, login?.userDetails?.data?.accessToken));
        });
    }, []);

    useEffect(() => {
        if (boosters.isFetchingBoosterConfigSuccess && !prevBoosters.isFetchingBoosterConfigSuccess) {
            setFormState({...(boosters.boosterConfigDetail || {})});
        }

        if (boosters.isFetchingBoostersSuccess && !prevBoosters.isFetchingBoostersSuccess) {
            const tempList = boosters?.boostersList?.data?.map((boost) => ({title: boost.name, value: boost})) || [];
            setBoosterOptions([...tempList]);
        }

        if (
            (boosters.isFetchingBoosterConfigFail && !prevBoosters.isFetchingBoosterConfigFail) ||
            (boosters.isCreatingBoosterConfigFail && !prevBoosters.isCreatingBoosterConfigFail) ||
            (boosters.isUpdatingBoosterConfigFail && !prevBoosters.isUpdatingBoosterConfigFail) ||
            (boosters.isFetchingBoostersFail && !prevBoosters.isFetchingBoostersFail)
        ) {
            handleOnStateChange({errorMessage: boosters.message.msg});
        }

        if (toGame.TOGameListSuccess && !prevToGame.TOGameListSuccess) {
            const temp = toGame?.TOGameList?.data?.data?.map((item) => ({text: item.gameName, value: item.id}));
            setGameOptions(temp);
        }
        if (toGame.TOGameListFail && !prevToGame.TOGameListFail) {
            handleOnStateChange({errorMessage: toGame.errorMessage});
        }
    }, [boosters, toGame]);

    const handleOnStateChange = (stateObj = {}) => {
        setState((prevState) => ({...prevState, ...stateObj}));
    };

    const handleFormStateChange = (_formState = {}) => {
        if (_formState.game) setBoosterOptions([]);
        setFormState((prevState) => ({...prevState, ..._formState}));
    };

    const handleSubmit = (e) => {
        !!e && e.preventDefault();
        const {name, status, game, desc, boosters, maxBoosterApplicable} = formState;

        const data = {
            name,
            maxBoosterApplicable,
            status,
            game,
            desc,
            boosters,
        };

        if (boosterConfigData.id) {
            data.id = boosterConfigData.id;
            dispatch(updateBoosterConfig(data, login?.userDetails?.data?.accessToken));
        } else {
            dispatch(createBoosterConfig(data, login?.userDetails?.data?.accessToken));
        }
    };

    const handleSearchBoosters = (e, name) => {
        if (e?.target?.value && name) handleBoostersDebounce.current({name, gameId: formState.game});
    };

    const handleSelectBooster = (booster, index) => {
        const tempBoosters = [...(formState.boosters || [])];
        if (index > -1) tempBoosters.splice(index, 1);
        else {
            const {conditions, desc, displayName, id, image, name, penalty, reward, game, status} = booster || {};
            tempBoosters.unshift({conditions, desc, displayName, id, image, name, penalty, reward, game, status});
        }
        handleFormStateChange({boosters: tempBoosters});
    };

    const handleGetUpdatedBoosterData = (booster) => {
        const {editIndex, conditions, desc, displayName, id, image, name, penalty, reward, game, status} =
            booster || {};
        const tempBoosters = [...(formState.boosters || [])];
        tempBoosters[editIndex || 0] = {conditions, desc, displayName, id, image, name, penalty, reward, game, status};
        handleFormStateChange({boosters: tempBoosters});
        setLocalBoosterData(null);
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            scroll="paper"
            maxWidth="md"
            fullWidth
            disableEscapeKeyDown
            disableBackdropClick
        >
            {!!loading && <LoadingModal open={!!loading} />}
            {!!errorMessage && (
                <Snackbar
                    open={!!errorMessage}
                    message={errorMessage}
                    onClose={() => handleOnStateChange({errorMessage: ''})}
                />
            )}

            {!!localBoosterData && (
                <CreateEditBoosterModal
                    open={!!localBoosterData}
                    onClose={() => setLocalBoosterData(null)}
                    localBoosterData={localBoosterData}
                    getUpdatedBoosterData={handleGetUpdatedBoosterData}
                />
            )}

            <DialogTitle>Booster Config</DialogTitle>
            <DialogContent dividers={true}>
                <form onSubmit={handleSubmit}>
                    <div className="card-grid-container">
                        <Input
                            className="mb-15"
                            label="Name"
                            type="text"
                            name="name"
                            required
                            value={formState.name || ''}
                            placeholder="Enter Name"
                            onChange={(value) => handleFormStateChange({name: value})}
                        />

                        <Input
                            className="mb-15"
                            label="Max booster applicable"
                            type="number"
                            name="name"
                            required
                            value={formState.maxBoosterApplicable || ''}
                            placeholder="Enter number"
                            onChange={(value) => handleFormStateChange({maxBoosterApplicable: parseInt(value)})}
                        />

                        <DropDown
                            className="mb-15"
                            label="Status*"
                            menuItems={COMMON_STATUS}
                            required
                            name="status"
                            value={formState.status || ''}
                            placeholder="Select"
                            onChange={(value) => handleFormStateChange({status: value})}
                        />

                        <DropDown
                            className="mb-15"
                            label="Game*"
                            menuItems={gameOptions || []}
                            required
                            name="status"
                            value={formState.game || ''}
                            placeholder="Select"
                            disabled={!!boosterConfigData.id}
                            onChange={(value) => handleFormStateChange({game: value, boosters: []})}
                        />
                    </div>
                    <TextareaAutosize
                        rowsMin={2}
                        className="mb-15 text-area"
                        value={formState.desc || ''}
                        placeholder="Description"
                        required
                        onChange={(e) => handleFormStateChange({desc: e.target.value})}
                    />

                    <div className="grid-3-container mb-15">
                        <h3 className="my-0">Boosters:</h3>
                        {!!boosterWrite && !!formState.game && (
                            <AutoCompleteField
                                label="Search and select booster"
                                options={boosterOptions}
                                onChange={(value) => !!value && handleSelectBooster(value.value)}
                                inputChange={handleSearchBoosters}
                            />
                        )}
                    </div>
                    {!!formState?.boosters?.length && (
                        <table className="mb-15">
                            <thead>
                                <tr>
                                    <th className="basic-headers">Name</th>
                                    <th className="basic-headers">Display Name</th>
                                    <th className="basic-headers">Penalty</th>
                                    <th className="basic-headers">Reward</th>
                                    {!!boosterWrite && <th>Action</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {formState?.boosters?.map((_booster, index) => (
                                    <tr key={`${_booster?.id}_${index}`}>
                                        <td>{_booster?.name}</td>
                                        <td>{_booster?.displayName}</td>
                                        <td>
                                            {_booster?.penalty?.type} : {_booster?.penalty?.value}
                                        </td>
                                        <td>
                                            {_booster?.reward?.type} : {_booster?.reward?.value}
                                        </td>
                                        {!!boosterWrite && (
                                            <td>
                                                <EditIcon
                                                    titleAccess="Edit"
                                                    className="clickable-icon mr-5"
                                                    fontSize="small"
                                                    onClick={() => setLocalBoosterData({..._booster, editIndex: index})}
                                                />
                                                <DeleteIcon
                                                    titleAccess="Delete"
                                                    className="clickable-icon red-color"
                                                    fontSize="small"
                                                    onClick={() => handleSelectBooster(_booster, index)}
                                                />
                                            </td>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}

                    <Button className="d-none" buttonText="Submit" type="submit" buttonRef={submitRef} />
                </form>
            </DialogContent>
            <DialogActions>
                <Button buttonText="Cancel" onClick={onClose} />
                {!!boosterWrite && <Button buttonText="Submit" onClick={() => submitRef.current.click()} />}
            </DialogActions>
        </Dialog>
    );
};

CreateEditBoosterConfigModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    boosterConfigData: PropTypes.object,
};

CreateEditBoosterConfigModal.defaultProps = {
    onClose: () => {},
    open: false,
    boosterConfigData: {},
};

export default CreateEditBoosterConfigModal;
