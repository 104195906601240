export const TOMATCH_LIST = 'TOMATCH_LIST';
export const TOMATCH_LIST_SUCCESS = 'TOMATCH_LIST_SUCCESS';
export const TOMATCH_LIST_FAIL = 'TOMATCH_LIST_FAIL';

export const GET_TOMATCH_PARTICIPATION = 'GET_TOMATCH_PARTICIPATION';
export const GET_TOMATCH_PARTICIPATION_SUCCESS = 'GET_TOMATCH_PARTICIPATION_SUCCESS';
export const GET_TOMATCH_PARTICIPATION_FAIL = 'GET_TOMATCH_PARTICIPATION_FAIL';

export const ARCHIVE_TOMATCH = 'ARCHIVE_TOMATCH';
export const ARCHIVE_TOMATCH_SUCCESS = 'ARCHIVE_TOMATCH_SUCCESS';
export const ARCHIVE_TOMATCH_FAIL = 'ARCHIVE_TOMATCH_FAIL';

export const GET_TOMATCH = 'GET_TOMATCH';
export const GET_TOMATCH_SUCCESS = 'GET_TOMATCH_SUCCESS';
export const GET_TOMATCH_FAIL = 'GET_TOMATCH_FAIL';

export const EDIT_TOMATCH = 'EDIT_TOMATCH';
