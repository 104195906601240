import React from 'react';
import '../../../Utilities/style.css';
import '../Login/login.css';
import DetailLayout from '../../Layouts/DetailLayout/DetailLayout';
import Table from '../../../Components/Table/Table';

import {connect} from 'react-redux';
import {next, prev, reset} from '../../../Utilities/HelperFunctions';

import {transactionList} from './AxioCalls';
import Snackbar from '../../../Components/Snackbar/Snackbar';
import {logout} from '../Login/LoginAction';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';
import {timeFormat} from '../../../Utilities/Helpers';

class UserEntryFees extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: [],
            selectAll: false,
            list: [],
            open: false,
            adminpswd: '',
            snackbarOpen: false,
            loading: true,
            errorMessage: '',
            lading: true,
            pageData: {},
            pageOptions: {
                page: 1,
                pageSize: 10,
                searchString: null,
                sortOrder: null,
                txnType: 'PURCHASE',
                userId: this.props.user.currentUser.user,
            },
            sortOptions: [
                {
                    value: 'amount',
                    text: 'Amount',
                },
                {
                    value: 'discount',
                    text: 'Discount',
                },
                {
                    value: 'totalAmount',
                    text: 'Final Paid',
                },
            ],
        };
    }

    /**
     * display teams one below other
     * @param {*} teams array of teams
     */

    dispTeams(teams) {
        return teams.map((team) => {
            return <div>{team}</div>;
        });
    }

    componentWillMount() {
        const {history, login, client} = this.props;

        if (!login.userDetails.data) {
            history.push('/');
            this.props.logout();
        } else {
            const {pageOptions} = this.state;

            this.props.transactionList(pageOptions, login.userDetails.data.accessToken, client.selectedClient);
        }
    }

    componentDidUpdate(prevProps) {
        const {user} = this.props;

        if (user.userTransactionSuccess && !prevProps.user.userTransactionSuccess) {
            this.setState({
                loading: false,
                list: user.userTransaction.data.data,
                pageData: user.userTransaction.data.pageData,
            });
        } else if (user.userTransactionFail && !prevProps.user.userTransactionFail) {
            this.setState({loading: false, snackbarOpen: true, errorMessage: user.errorMessage});
        }
    }

    /**
     * reset table data
     */
    onReset() {
        const {pageOptions} = this.state;
        const {login, client} = this.props;
        let options = reset(pageOptions);
        this.setState({
            pageOptions: options,
        });
        this.props.transactionList(options, login.userDetails.data.accessToken, client.selectedClient);
    }

    /**
     * pagination   and api call on next click
     */
    onNext() {
        const {pageData, pageOptions} = this.state;
        const {login, client} = this.props;
        let options = next(pageOptions, pageData);

        if (options) {
            this.props.transactionList(options, login.userDetails.data.accessToken, client.selectedClient);
            this.setState({pageOptions: options, loading: true});
        }
    }

    /**
     * pagination  and api call on  prev click
     */
    onPrev() {
        const {pageOptions} = this.state;
        const {login, client} = this.props;
        let options = prev(pageOptions);
        if (options) {
            this.props.transactionList(options, login.userDetails.data.accessToken, client.selectedClient);
            this.setState({pageOptions: options, loading: true});
        }
    }
    /**
     * APi call with search and sort strings
     * @param {string} field field names of input
     * @param {string} value value in the field
     */
    onSearchandSort(field, value) {
        const {pageOptions} = this.state;
        const {login, client} = this.props;
        let options = pageOptions;
        options[field] = value;
        this.setState({pageOptions: options});

        if (field === 'searchString' && value.length > 2) {
            this.props.transactionList(options, login.userDetails.data.accessToken, client.selectedClient);
        } else if (field === 'sortOrder') {
            this.props.transactionList(options, login.userDetails.data.accessToken, client.selectedClient);
        }
    }

    render() {
        const {list, loading, snackbarOpen, errorMessage, pageData, sortOptions} = this.state;
        const {searchString, sortOrder, page} = this.state.pageOptions;
        const {userEntryFeesPaidVia,userEntryFeesInitial} = this.props.login.permissions || {}
        const data = list;
        let disableNext = page === pageData.lastPage ? true : false;
        const {user, history} = this.props;
        let userDetails = {};
        userDetails.name = user.currentUser.userName;
        userDetails.id = user.currentUser.user;
        const columns = [
            {
                Header: 'Transaction ID',
                accessor: 'id',
                width: 200,
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },

            {
                Header: 'Match ID',
                accessor: 'match',
                width: 200,
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Contest ID',
                accessor: 'contest',
                width: 200,
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Team ID',
                accessor: 'teams',
                width: 200,
                Cell: (props) => (props.value.length === 0 ? '-' : this.dispTeams(props.value)),
            },
            {
                Header: '# of Teams',
                accessor: 'teams',
                Cell: (props) => <span className="number">{props.value.length || '-'}</span>,
            },
            {
                Header: 'Match Start Time',
                accessor: 'matchStartTime',

                Cell: (props) => <span className="date-space">{timeFormat(props.value) || '-'}</span>,
            },
            {
                Header: 'Paid On',
                accessor: 'updatedAt',
                Cell: (props) => <span className="date-space">{timeFormat(props.value) || '-'}</span>,
            },
            {
                Header: 'Payment Via',
                accessor: 'balanceUsed',
                show:!!userEntryFeesPaidVia,
                width: 150,
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Initial',
                accessor: 'amount',
                show:!!userEntryFeesInitial,
                show:false,
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : '₹ ' + props.value}
                    </span>
                ),
            },

            {
                Header: 'Final Paid',
                accessor: 'totalAmount',
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : '₹ ' + props.value}
                    </span>
                ),
            },
            {
                Header: 'Contest Status',
                accessor: 'status',
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
        ];
        return (
            <DetailLayout
                location="userDetails"
                userEntry={true}
                history={history}
                searchbar="Search Match ID / Contest ID / Team ID"
                search={true}
                centerTitle="User - Entry Fee"
                id={userDetails}
                onsearchChange={this.onSearchandSort.bind(this)}
                sortOptions={sortOptions}
                count={pageData.total}
                onReset={this.onReset.bind(this)}
                searchString={searchString}
                sortOrder={sortOrder}
            >
                <LoadingModal open={loading} />
                <Snackbar
                    open={snackbarOpen}
                    message={errorMessage}
                    onClose={() => this.setState({snackbarOpen: false})}
                />

                <Table
                    data={data}
                    columns={columns}
                    pageSize={10}
                    page={page}
                    onPrevClick={this.onPrev.bind(this)}
                    onNextClick={this.onNext.bind(this)}
                    disableNext={disableNext}
                ></Table>
            </DetailLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        squad: state.squad,
        player: state.player,
        user: state.user,
        client: state.client,
    };
};

export default connect(mapStateToProps, {transactionList, logout})(UserEntryFees);
