import React from 'react';
import PropTypes from 'prop-types';
import DisPrev from '../../Images/left disabled.png';
import Prev from '../../Images/left yellow.png';
import DisNext from '../../Images/right disabled.png';
import Next from '../../Images/right yellow.png';

const Pagination = (props) => {
    let {onPrevClick, page, disableNext, onNextClick} = props;

    return (
        <div className="disp-flex align-center pagination">
            <div className="margin-right-16 cursor">
                {page === 1 ? <img src={DisPrev} alt="" /> : <img src={Prev} alt="" onClick={onPrevClick} />}
            </div>
            <div className="disp-flex">
                <div>Page</div>
                <div className="pageNumber">{page}</div>
            </div>
            <div className="cursor">
                {disableNext ? <img src={DisNext} alt="" /> : <img src={Next} alt="" onClick={onNextClick} />}
            </div>
        </div>
    );
};

Pagination.propTypes = {
    onPrevClick: PropTypes.func,
    onNextClick: PropTypes.func,
    page: PropTypes.number,
    disableNext: PropTypes.bool,
};

Pagination.defaultProps = {
    onPrevClick: () => {},
    onNextClick: () => {},
    disableNext: false,
    page: 1,
};

export default Pagination;
