import React, {useEffect, useState} from 'react';
import {Avatar, Box, IconButton, Tab, Tabs, makeStyles, styled} from '@material-ui/core';
import MuiModal from '../../../../../Components/MuiModal/MuiModal';
import {Close, Refresh, Save} from '@material-ui/icons';
import {ColorPalette} from '../../../../../Utilities/constants/color-constants';
import styles from './AddEditCommonMatch.module.scss';
import MuiButton from '../../../../../Components/MuiButton/MuiButton';
import {useDispatch, useSelector} from 'react-redux';
import {usePrevious} from '../../../../../Utilities/hooks';
import {getTOGameList} from '../../../../../View/Modules/TOGame/AxioCalls';
import MuiSelectField from '../../../../../Components/MuiSelectField/MuiSelectField';
import {SquadGamesList, TOURNAMENT_STATUS_LABEL} from '../../../../../Utilities/constants/label-constants';
import {listAllTournaments} from '../../../../../View/Modules/FantasyTournament/FantasyTournamentAxios';
import MuiAutocompleteField from '../../../../../Components/MuiAutocompleteField/MuiAutoCompleteField';
import {debounce} from '../../../../../Utilities/HelperFunctions';
import MuiInputField from '../../../../../Components/MuiInputField/MuiInputField';
import {
    LINEUP_STATUS_OPTIONS,
    MAP_NAME_OPTIONS,
    PLAYER_LINEUP_STATUS_OPTIONS,
} from '../../../../../Utilities/constants/options-constants';
import {getSquad} from '../../../../../View/Modules/Squads/SquadAxios';
import {addCommonMatch, editCommonMatch, fetchCommonMatch} from '../../actions';
import AddPlayerInCommonMatchModal from '../AddPlayerInCommonMatchModal/AddPlayerInCommonMatchModal';
import {DateTimePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import {TIME_FORMAT_ONE} from '../../../../../Utilities/constants/time-format-constants';
import ConfirmRemoveSquadModal from '../ConfirmRemoveSquadModal/ConfirmRemoveSquadModal';

const tabStyles = makeStyles({
    root: {
        color: `${ColorPalette.LINEN}!important`,
    },
    selected: {
        color: `${ColorPalette.SELECTIVE_YELLOW}!important`,
    },
});

const tabsStyles = makeStyles({
    indicator: {
        background: ColorPalette.SELECTIVE_YELLOW,
    },
});

const AddEditCommonMatch = (props) => {
    const {addEditCommonMatch, onClickClose, open, onAddEditSuccess} = props;
    const dispatch = useDispatch();
    const login = useSelector((state) => state.login);
    const client = useSelector((state) => state.client);
    const commonMatch = useSelector((state) => state.commonMatch);
    const prevCommonMatch = usePrevious(commonMatch);
    const toGame = useSelector((state) => state.toGame);
    const prevToGame = usePrevious(toGame);
    const tournament = useSelector((state) => state.tournament);
    const prevTournament = usePrevious(tournament);
    const squad = useSelector((state) => state.squad);
    const prevSquad = usePrevious(squad);
    const [tab, setTab] = useState('match-summary');
    const [gameOptions, setGameOptions] = useState([]);
    const [tournamentOptions, setTournamentOptions] = useState([]);
    const [squadOptions, setSquadOptions] = useState([]);
    const [isAddSquadButtonClicked, setIsAddSquadButtonClicked] = useState(false);
    const [addPlayerInCommonMatchModal, setAddPlayerInCommonMatchModal] = useState(null);
    const [confirmRemoveSquadModal, setConfirmRemoveSquadModal] = useState(null);
    const [formState, setFormState] = useState(addEditCommonMatch || {});
    const {
        gameId = '',
        selectedTournament = null,
        matchName = '',
        selectedStartTime,
        mapName = '',
        lineupStatus = 'NOT_EXPECTED',
        glacierClassId,
        glacierClassType,
        addSquadId = null,
        selectedSquads = [],
        matchStatus,
        playerLineupStatus = {},
    } = formState;
    const tabClasses = tabStyles();
    const tabsClasses = tabsStyles();

    useEffect(() => {
        dispatch(getTOGameList({listType: 'ALL'}, login.userDetails.data.accessToken, client.selectedClient));
    }, []);

    useEffect(() => {
        if (!!addEditCommonMatch.id) {
            const commonMatchData = {
                matchId: addEditCommonMatch.id,
            };
            dispatch(fetchCommonMatch(commonMatchData, login?.userDetails?.data?.accessToken, client.selectedClient));
        }
    }, [addEditCommonMatch.id]);

    useEffect(() => {
        // console.log("COMMON_MATCH", commonMatch);
        if (commonMatch.fetchCommonMatchSuccess && !prevCommonMatch.fetchCommonMatchSuccess) {
            const {
                game,
                tournament,
                tournamentName,
                glacierClassId,
                glacierClassType,
                matchName,
                mapName,
                squads,
                startTime,
                status,
                playerLineupStatus,
            } = commonMatch?.commonMatch;
            handleFormData({
                gameId: game?.id,
                selectedTournament: {label: tournamentName, value: tournament?.id},
                glacierClassId: glacierClassId,
                glacierClassType: glacierClassType,
                matchName: matchName,
                mapName: mapName,
                selectedSquads: squads,
                matchStatus: status,
                selectedStartTime: startTime,
                playerLineupStatus: playerLineupStatus,
            });
        }
        if (commonMatch.fetchCommonMatchFailure && !prevCommonMatch.fetchCommonMatchFailure) {
            alert('COMMON_MATCH_FAILURE', commonMatch.message);
        }
        if (commonMatch.addCommonMatchSuccess && !prevCommonMatch.addCommonMatchSuccess) {
            alert('Common Match Saved');
            onAddEditSuccess();
        }
        if (commonMatch.addCommonMatchFailure && !prevCommonMatch.addCommonMatchFailure) {
            alert(commonMatch?.message);
        }
        if (commonMatch.editCommonMatchSuccess && !prevCommonMatch.editCommonMatchSuccess) {
            alert('Common Match Updated');
            onAddEditSuccess();
        }
        if (commonMatch.editCommonMatchFailure && !prevCommonMatch.editCommonMatchFailure) {
            alert(commonMatch?.message);
        }
    }, [commonMatch]);

    useEffect(() => {
        if (toGame.TOGameListSuccess && !prevToGame.TOGameListSuccess) {
            const _squadGames = toGame?.TOGameList?.data?.data?.filter((item) =>
                SquadGamesList.includes(item.gameUniqueCode),
            );
            const _gameOptions = _squadGames.map((item) => ({text: item.gameName, value: item.id})) || [];
            setGameOptions(_gameOptions);
        }
        if (toGame.TOGameListFail && !prevToGame.TOGameListFail) {
            alert('TO_GAME', toGame.errorMessage);
        }
    }, [toGame]);

    useEffect(() => {
        if (tournament.tournamentListSuccess && !prevTournament.tournamentListSuccess) {
            const _tournamentOptions =
                tournament?.tournamentList?.data?.data?.map((item) => ({label: item.tournamentName, value: item.id})) ||
                [];
            setTournamentOptions(_tournamentOptions);
        }
        if (tournament.tournamentListFail && !prevTournament.tournamentListFail) {
            alert('TOURNAMENT', tournament.errorMessage);
        }
    }, [tournament]);

    useEffect(() => {
        if (squad.getSquadSuccess && !prevSquad.getSquadSuccess) {
            if (!isAddSquadButtonClicked) {
                let _squadOptions = [];
                const squadData = squad?.getSquad?.data;
                for (const _squad of squadData) {
                    _squadOptions.push({label: _squad.squadName, value: _squad.id});
                }
                setSquadOptions(_squadOptions || []);
            } else if (!!isAddSquadButtonClicked) {
                let tempSquadList = selectedSquads;
                tempSquadList.push(squad?.getSquad?.data);
                handleFormData({selectedSquads: tempSquadList, addSquadId: null});
            }
        }
        if (squad.getSquadFail && !prevSquad.getSquadFail) {
            alert('SQUAD', squad.errorMessage);
        }
    }, [squad, isAddSquadButtonClicked]);

    const fetchPlayerIds = (_squads) => {
        const playerIds = [];

        for (const _squad of _squads) {
            const activePlayers = _squad?.players?.filter((player) => player.status === 'ACTIVE');
            for (const _player of activePlayers) {
                playerIds.push(_player.id);
            }
        }

        return JSON.stringify(playerIds);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const squadIds = selectedSquads?.map((squad) => squad.id);
        const data = {
            gameId,
            tournamentId: selectedTournament?.value,
            glacierClassId,
            glacierClassType,
            matchName,
            mapName,
            lineupStatus,
            squads: JSON.stringify(squadIds),
            players: fetchPlayerIds(selectedSquads),
            startTime: selectedStartTime ? moment(selectedStartTime).valueOf() : null,
            playerLineupStatus,
        };
        if (!addEditCommonMatch?.id) {
            // console.log("CREATE");
            dispatch(addCommonMatch(data, login?.userDetails?.data?.accessToken, client?.selectedClient));
        } else {
            // console.log("EDIT", data, formState);
            const _tempMatchId = addEditCommonMatch.id || '';
            data.id = _tempMatchId;
            dispatch(editCommonMatch(data, login?.userDetails?.data?.accessToken, client?.selectedClient));
        }
    };

    const handleReset = () => {};

    const handleFormData = (formObj) => {
        setFormState({...formState, ...formObj});
    };

    const handleChangeTab = (event, _value) => {
        event.preventDefault();
        setTab(_value);
    };

    const handleInputChange = (_field, _value) => {
        // for any other case
        handleFormData({[_field]: _value});
    };

    const handleTournamentInputChange = (_, value) => {
        if (value) handleFetchTournaments(value);
    };

    const handleFetchTournaments = debounce((_name) => {
        const data = {
            pageNo: 1,
            pageSize: 30,
            gameId,
            status: [TOURNAMENT_STATUS_LABEL.DRAFT, TOURNAMENT_STATUS_LABEL.UPCOMING, TOURNAMENT_STATUS_LABEL.LIVE],
            searchString: _name,
        };
        dispatch(listAllTournaments(data, login?.userDetails?.data?.accessToken, client?.selectedClient));
    });

    const renderMatchSummaryTab = () => {
        return (
            <section className={styles.matchSummarySection}>
                <MuiSelectField
                    label="Game"
                    name="gameId"
                    fullWidth={true}
                    onChange={(event) => handleInputChange('gameId', event.target.value)}
                    options={gameOptions}
                    required={true}
                    value={gameId}
                    size="small"
                />
                <MuiAutocompleteField
                    id="selectedTournament"
                    label="Tournament"
                    onInputChange={handleTournamentInputChange}
                    onChangeField={(selectedOption) => handleInputChange('selectedTournament', selectedOption)}
                    options={tournamentOptions}
                    size="small"
                    value={selectedTournament || null}
                />
                <MuiInputField
                    label="Glacier Class ID"
                    name="glacierClassId"
                    onChange={(event) => handleInputChange('glacierClassId', event.target.value)}
                    size="small"
                    value={glacierClassId || ''}
                />
                <MuiInputField
                    label="Glacier Class Type"
                    name="glacierClassType"
                    onChange={(event) => handleInputChange('glacierClassType', event.target.value)}
                    size="small"
                    value={glacierClassType || ''}
                />
                <MuiInputField
                    label="Match Name"
                    name="matchName"
                    required={true}
                    onChange={(event) => handleInputChange('matchName', event.target.value)}
                    size="small"
                    value={matchName || ''}
                />
                <MuiSelectField
                    label="Map Name"
                    name="mapName"
                    fullWidth={true}
                    onChange={(event) => handleInputChange('mapName', event.target.value)}
                    options={MAP_NAME_OPTIONS}
                    required={true}
                    value={mapName}
                    size="small"
                />
                <MuiSelectField
                    label="Lineup Status"
                    name="lineupStatus"
                    fullWidth={true}
                    onChange={(event) => handleInputChange('lineupStatus', event.target.value)}
                    options={LINEUP_STATUS_OPTIONS}
                    required={true}
                    value={lineupStatus}
                    size="small"
                />
                {!!addEditCommonMatch?.id && (
                    <MuiInputField
                        label="Match Status"
                        name="matchStatus"
                        readOnly={true}
                        size="small"
                        value={matchStatus || ''}
                    />
                )}
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                        margin="normal"
                        id="date-picker-dialog"
                        label="Start Time"
                        format="dd/MM/yyyy HH:mm:ss"
                        value={selectedStartTime}
                        disabled={false}
                        invalidDateMessage={''}
                        onChange={(date) => handleInputChange('selectedStartTime', date)}
                    />
                </MuiPickersUtilsProvider>
            </section>
        );
    };

    const handleAddSquadIdInputChange = (_, value) => {
        setIsAddSquadButtonClicked(false);
        if (value) handleFetchSquads(value);
    };

    const handleFetchSquads = debounce((_name) => {
        const squadData = {
            searchString: _name,
        };
        dispatch(getSquad(squadData, gameId, login?.userDetails?.data?.accessToken, client?.selectedClient));
    });

    const handleAddSquadClick = () => {
        setIsAddSquadButtonClicked(true);
        const squadData = {
            id: addSquadId?.value,
        };
        dispatch(getSquad(squadData, gameId, login?.userDetails?.data?.accessToken, client?.selectedClient));
    };

    const handleAddPlayerInCommonMatch = (_squad) => {
        const data = {
            squadId: _squad?._id,
            squadName: _squad?.squadName,
            matchId: addEditCommonMatch?.id,
        };
        setAddPlayerInCommonMatchModal(data);
    };

    const handleRemoveSquad = (_squadId) => {
        const tempSelectedSquads = selectedSquads;
        const removeSquadIndex = tempSelectedSquads.findIndex((squad) => squad._id === _squadId);
        if (removeSquadIndex !== -1) {
            tempSelectedSquads.splice(removeSquadIndex, 1);
        }
        handleFormData({selectedSquads: tempSelectedSquads});
        setConfirmRemoveSquadModal(null);
    };

    const handlePlayerLineupStatusChange = (event, player) => {
        handleFormData({playerLineupStatus: {...playerLineupStatus, [player.id]: event.target.value}});
    };

    const renderSquadsTab = () => {
        return (
            <section className={styles.squadsSection}>
                <div className={styles.addSquadWrapper}>
                    <div className={styles.addSquadId}>
                        <MuiAutocompleteField
                            id="addSquadId"
                            label="Add Squad"
                            onInputChange={handleAddSquadIdInputChange}
                            onChangeField={(selectedOption) => handleInputChange('addSquadId', selectedOption)}
                            options={squadOptions}
                            size="small"
                            value={addSquadId || null}
                        />
                        <MuiButton buttonText="Add Squad" onButtonClick={handleAddSquadClick} />
                    </div>
                    <div className={styles.buttonGroup}></div>
                </div>
                <div className={styles.squadsList}>
                    {selectedSquads?.length > 0 &&
                        selectedSquads.map((squad, index) => (
                            <div key={`squad-${index + 1}-${squad._id}`} className={styles.oneVsOneWrapper}>
                                <div className={styles.squadHeader}>
                                    <span className={styles.squadDetails}>
                                        <h3 className={styles.squadName}>{squad?.squadName}</h3>
                                    </span>
                                    <span className={styles.squadButtonGroup}>
                                        {/* <MuiButton
                                            buttonText="Add Player"
                                            onButtonClick={() => handleAddPlayerInCommonMatch(squad)}
                                        /> */}
                                        <MuiButton
                                            buttonText="Remove Squad"
                                            onButtonClick={() => setConfirmRemoveSquadModal(squad)}
                                        />
                                    </span>
                                </div>
                                <div className={styles.playersList}>
                                    {/* filter(player => player.status === "ACTIVE") */}
                                    {squad?.players?.length > 0 &&
                                        squad.players
                                            .filter((player) => player.status === 'ACTIVE')
                                            .map((player, index) => (
                                                <div
                                                    key={`player-${index + 1}-${player._id}`}
                                                    className={styles.playerItem}
                                                >
                                                    <div className={styles.playerDetails}>
                                                        <Avatar className={styles.playerImage} />
                                                        <span className={styles.playerName}>
                                                            <p
                                                                className={styles.playerNameKey}
                                                            >{`Player ${index + 1}`}</p>
                                                            <p className={styles.playerNameValue}>{player?.pubgName}</p>
                                                        </span>
                                                    </div>
                                                    <div className={styles.playerActions}>
                                                        {!!playerLineupStatus?.[player?.id] && (
                                                            <MuiSelectField
                                                                className={styles.playerLineupStatus}
                                                                label="Lineup Status"
                                                                name="playerLineupStatus"
                                                                fullWidth={true}
                                                                onChange={(event) =>
                                                                    handlePlayerLineupStatusChange(event, player)
                                                                }
                                                                options={PLAYER_LINEUP_STATUS_OPTIONS}
                                                                value={playerLineupStatus?.[player?.id]}
                                                                size="small"
                                                            />
                                                        )}
                                                    </div>
                                                    <div className={styles.playerAttributes}>
                                                        {!!player.position && (
                                                            <div className={styles.playerAttribute}>
                                                                <p className={styles.playerAttributeKey}>Position</p>
                                                                <p className={styles.playerAttributeValue}>
                                                                    {player?.position}
                                                                </p>
                                                            </div>
                                                        )}
                                                        {!!player.freezeStatus && (
                                                            <div className={styles.playerAttribute}>
                                                                <p className={styles.playerAttributeKey}>
                                                                    Freeze Status
                                                                </p>
                                                                <p className={styles.playerAttributeValue}>
                                                                    {player?.freezeStatus}
                                                                </p>
                                                            </div>
                                                        )}
                                                        {!!player.credits && (
                                                            <div className={styles.playerAttribute}>
                                                                <p className={styles.playerAttributeKey}>Credits</p>
                                                                <p className={styles.playerAttributeValue}>
                                                                    {player?.credits}
                                                                </p>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                </div>
                            </div>
                        ))}
                </div>
            </section>
        );
    };

    return (
        <MuiModal onClickClose={onClickClose} open={open} className={styles.container}>
            {!!addPlayerInCommonMatchModal && (
                <AddPlayerInCommonMatchModal
                    addPlayerInCommonMatchModal={addPlayerInCommonMatchModal}
                    open={!!addPlayerInCommonMatchModal}
                    onClickClose={() => setAddPlayerInCommonMatchModal(null)}
                />
            )}
            {!!confirmRemoveSquadModal && (
                <ConfirmRemoveSquadModal
                    removeSquadData={confirmRemoveSquadModal}
                    open={!!confirmRemoveSquadModal}
                    onClickClose={() => setConfirmRemoveSquadModal(null)}
                    onClickConfirm={handleRemoveSquad}
                />
            )}
            <section className={styles.header}>
                <h3>{`${!!addEditCommonMatch?.id ? 'Edit' : 'Add'} Common Match`}</h3>
                <CustomIconButton onClick={onClickClose} className={styles.closeButton}>
                    <Close className={styles.closeButtonIcon} />
                </CustomIconButton>
            </section>
            <section className={styles.divider} />
            <section className={styles.body}>
                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    onReset={handleReset}
                    className={styles.addEditCommonMatchForm}
                >
                    <div className={styles.tabsContainer}>
                        <Tabs
                            classes={{indicator: tabsClasses.indicator}}
                            value={tab}
                            onChange={handleChangeTab}
                            aria-label="add-edit-common-match-tabs"
                        >
                            <Tab
                                classes={{root: tabClasses.root, selected: tabClasses.selected}}
                                label="Match Summary"
                                value="match-summary"
                            />
                            <Tab
                                classes={{root: tabClasses.root, selected: tabClasses.selected}}
                                label="Squads"
                                value="squads"
                            />
                        </Tabs>
                    </div>
                    {tab === 'match-summary' && renderMatchSummaryTab()}
                    {tab === 'squads' && renderSquadsTab()}
                    <div className={styles.formButtonGroup}>
                        <MuiButton
                            className={styles.resetButton}
                            type="reset"
                            buttonText="Reset"
                            buttonIcon={<Refresh />}
                        />
                        <MuiButton
                            className={styles.submitButton}
                            type="submit"
                            buttonText="Submit"
                            buttonIcon={<Save />}
                        />
                    </div>
                </Box>
            </section>
        </MuiModal>
    );
};

const CustomIconButton = styled(IconButton)({
    width: '28px',
    height: '28px',

    '&:hover': {
        backgroundColor: ColorPalette.NIGHT_RIDER,
    },
});

export default AddEditCommonMatch;
