import apis from '../../../apiCalls/apis';
import {
    GET_GLACIER_TOURNAMENTS,
    GET_GLACIER_TOURNAMENTS_SUCCESS,
    GET_GLACIER_TOURNAMENTS_FAIL,
    GET_GLACIER_SERIES,
    GET_GLACIER_SERIES_SUCCESS,
    GET_GLACIER_SERIES_FAIL,
    GET_GLACIER_MATCHES,
    GET_GLACIER_MATCHES_SUCCESS,
    GET_GLACIER_MATCHES_FAIL,
} from './constants';
import {getMessagesFromApi} from '../../../Utilities/HelperFunctions';

export const fetchGlacierTournaments = (data) => async (dispatch) => {
    dispatch({type: GET_GLACIER_TOURNAMENTS});
    try {
        const response = await apis.getGlacierTournaments(data);
        if (response.status === 200) {
            dispatch({type: GET_GLACIER_TOURNAMENTS_SUCCESS, payload: response.data});
        } else {
            dispatch({type: GET_GLACIER_TOURNAMENTS_FAIL, payload: {msg: getMessagesFromApi(response.data)}});
        }
    } catch (e) {
        if (e.response) {
            dispatch({type: GET_GLACIER_TOURNAMENTS_FAIL, payload: {msg: getMessagesFromApi(e?.response?.data, e)}});
        } else {
            dispatch({type: GET_GLACIER_TOURNAMENTS_FAIL, payload: {msg: e?.message || 'Unknown Error Occured'}});
        }
    }
};

export const fetchGlacierSeries = (data) => async (dispatch) => {
    dispatch({type: GET_GLACIER_SERIES});
    try {
        const response = await apis.getGlacierSeries(data);
        if (response.status === 200) {
            dispatch({type: GET_GLACIER_SERIES_SUCCESS, payload: response.data});
        } else {
            dispatch({type: GET_GLACIER_SERIES_FAIL, payload: {msg: getMessagesFromApi(response.data)}});
        }
    } catch (e) {
        if (e.response) {
            dispatch({type: GET_GLACIER_SERIES_FAIL, payload: {msg: getMessagesFromApi(e?.response?.data, e)}});
        } else {
            dispatch({type: GET_GLACIER_SERIES_FAIL, payload: {msg: e?.message || 'Unknown Error Occured'}});
        }
    }
};

export const fetchGlacierMatches = (data) => async (dispatch) => {
    dispatch({type: GET_GLACIER_MATCHES});
    try {
        const response = await apis.getGlacierMatches(data);
        if (response.status === 200) {
            dispatch({type: GET_GLACIER_MATCHES_SUCCESS, payload: response.data});
        } else {
            dispatch({type: GET_GLACIER_MATCHES_FAIL, payload: {msg: getMessagesFromApi(response.data)}});
        }
    } catch (e) {
        if (e.response) {
            dispatch({type: GET_GLACIER_MATCHES_FAIL, payload: {msg: getMessagesFromApi(e?.response?.data, e)}});
        } else {
            dispatch({type: GET_GLACIER_MATCHES_FAIL, payload: {msg: e?.message || 'Unknown Error Occured'}});
        }
    }
};
