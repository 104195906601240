import React from 'react';
import PropTypes from 'prop-types';
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';
import Button from '../../../../Components/Button/Button';

const PlayerStatsModal = (props) => {
    let {open, handleClose, playerDetails} = props;
    const stats = playerDetails?.playerStats || playerDetails?.stats || {};

    return (
        <Dialog open={open} onClose={handleClose} scroll="paper" maxWidth="sm" fullWidth>
            <DialogTitle>{playerDetails?.pubgName}'s Stats Details</DialogTitle>
            <DialogContent dividers={true}>
                <p className="grid-3-container">
                    <span>matchesPlayed</span>
                    <span>{playerDetails?.matchesPlayed}</span>
                </p>
                {Object.keys(stats)?.map((statKey, index) => (
                    <p className="grid-3-container" key={`${statKey}`}>
                        <span>{statKey}</span>
                        <span>{stats?.[statKey]?.value}</span>
                    </p>
                ))}
            </DialogContent>
            <DialogActions>
                <Button buttonText="Cancel" onClick={handleClose} />
            </DialogActions>
        </Dialog>
    );
};

PlayerStatsModal.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    playerDetails: PropTypes.object,
};

PlayerStatsModal.defaultProps = {
    handleClose: () => {},
    open: false,
    playerDetails: {},
};

export default PlayerStatsModal;
