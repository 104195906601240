import React, {useEffect, useRef, useState} from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';
import Button from '../../../../Components/Button/Button';
import Snackbar from '../../../../Components/Snackbar/Snackbar';
import LoadingModal from '../../../../Components/LoadingModal/LoadingModal';
import Input from '../../../../Components/Input/Input';
import {freezeUnfreezePlayer} from '../AxioCalls';
import {
    MATCH_BALL_OPTIONS,
    MATCH_INNING_OPTIONS,
    MATCH_OVER_OPTIONS,
    SWITCH_STATUS_OPTIONS,
} from '../../../../Utilities/Constants';
import {useDispatch, useSelector} from 'react-redux';
import MuiAutocompleteField from '../../../../Components/MuiAutocompleteField/MuiAutoCompleteField';

const PlayerFreezeUnfreezeModal = (props) => {
    const {playerFreezeUnfreeze, handleClose, open} = props;
    const {selectedPlayers, matchId, squadName} = playerFreezeUnfreeze;
    const [state, setState] = useState({});
    const {errorMessage, loading, playerId = '', switchStatus = '', inning = '', over = '', ball = ''} = state;
    const submitRef = useRef();
    const dispatch = useDispatch();
    const login = useSelector((state) => state.login);
    const client = useSelector((state) => state.client);
    const [activePlayers, setActivePlayers] = useState([]);

    useEffect(() => {
        const activePlayersOptions = selectedPlayers.map((player) => ({value: player.id, label: player?.pubgName}));
        setActivePlayers(activePlayersOptions);
    }, []);

    const handleSubmit = (event) => {
        !!event && event.preventDefault();
        let data = {matchId, playerId, switchStatus, inning, over, ball};
        dispatch(freezeUnfreezePlayer(data, login?.userDetails?.data?.accessToken, client.selectedClient));
    };

    const handleFormData = (formObj = {}) => {
        setState({...state, ...formObj});
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            scroll="paper"
            maxWidth="sm"
            fullWidth
            disableEscapeKeyDown
            disableBackdropClick
        >
            {!!loading && <LoadingModal open={loading} />}
            {!!errorMessage && (
                <Snackbar
                    open={!!errorMessage}
                    message={errorMessage}
                    onClose={() => handleFormData({errorMessage: ''})}
                />
            )}
            <DialogTitle>Player Freeze Unfreeze in Match</DialogTitle>
            <DialogContent dividers={true}>
                <form onSubmit={handleSubmit}>
                    {/* squad */}
                    <Input label="Squad" value={squadName} disabled={true} />

                    {/* player */}
                    <MuiAutocompleteField
                        className="mt-15"
                        id="playerId"
                        label="Player"
                        onChangeField={(selectedOption) => handleFormData({playerId: selectedOption?.value})}
                        options={activePlayers}
                        value={playerId || ''}
                    />

                    {/* switch status */}
                    <MuiAutocompleteField
                        className="mt-15"
                        id="switchStatus"
                        label="Switch Status"
                        onChangeField={(selectedOption) => handleFormData({switchStatus: selectedOption?.value})}
                        options={SWITCH_STATUS_OPTIONS}
                        value={switchStatus || ''}
                    />

                    {/* inning */}
                    <MuiAutocompleteField
                        className="mt-15"
                        id="inning"
                        label="Inning"
                        onChangeField={(selectedOption) => handleFormData({inning: selectedOption?.value})}
                        options={MATCH_INNING_OPTIONS}
                        value={inning || ''}
                    />

                    {/* over */}
                    <MuiAutocompleteField
                        className="mt-15"
                        id="over"
                        label="Over"
                        onChangeField={(selectedOption) => handleFormData({over: selectedOption?.value})}
                        options={MATCH_OVER_OPTIONS}
                        value={over || ''}
                    />

                    {/* ball */}
                    <MuiAutocompleteField
                        className="mt-15"
                        id="ball"
                        label="Ball"
                        onChangeField={(selectedOption) => handleFormData({ball: selectedOption?.value})}
                        options={MATCH_BALL_OPTIONS}
                        value={ball || ''}
                    />

                    {/* submit */}
                    <Button className="d-none" buttonText="Submit" type="submit" buttonRef={submitRef} />
                </form>
            </DialogContent>
            <DialogActions>
                <Button buttonText="Cancel" onClick={handleClose} />
                <Button buttonText="Submit" onClick={() => submitRef.current.click()} />
            </DialogActions>
        </Dialog>
    );
};

export default PlayerFreezeUnfreezeModal;
