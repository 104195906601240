import React from 'react';
import '../../../Utilities/style.css';
import '../Login/login.css';
import {connect} from 'react-redux';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import Tabs from '../../../Components/Tabs/Tabs';
import Card from '../../../Components/Card/Card';
import DetailLayout from '../../Layouts/DetailLayout/DetailLayout';
import Input from '../../../Components/Input/Input';
import DropDown from '../../../Components/Dropdown/Dropdown';
import Button from '../../../Components/Button/Button';
import Remove from '../../../Images/remove.png';
import Close from '../../../Images/close_yellow.png';
import Modal from '../../../Components/Modal/Modal';
import AutoCompleteField from '../../../Components/AutoCompleteField/AutoCompleteField';
import {getSquad, getSquadList} from '../Squads/SquadAxios';
import {getFantasyScoreModel} from '../FantasyConfig/FantasyAxios';
import {listAllTournaments} from '../FantasyTournament/FantasyTournamentAxios';
import Snackbar from '../../../Components/Snackbar/Snackbar';
import moment from 'moment';
import {getTOGameList} from '../TOGame/AxioCalls';
import {timeFormat} from '../../../Utilities/Helpers';
import {
    createMatch,
    updateMatch,
    deleteMatch,
    getMatch,
    publishMatch,
    markMatchLive,
    fetchPlayerStatsForMatch,
    updateMatchStatusLive,
    updateMatchTag,
    comingSoonMatch,
    updateMatchType,
    matchDraftToLive,
    freezeUnfreezePlayer,
} from './AxioCalls';
import {MuiPickersUtilsProvider, DateTimePicker} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {contestMatch} from './action';
import {copyContestFromMatch, deleteContest} from '../Contests/AxioCalls';
import {logout} from '../Login/LoginAction';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';
import ConfirmationModal from '../AppReactNative/ConfirmationModal';
import {getTOMatchModeList} from '../TOMatchMode/AxioCalls';
import {getTOMatchAttributeList} from '../TOMatchAttribute/AxioCalls';
import {Checkbox, FormControlLabel, IconButton} from '@material-ui/core';
import ExpertTeamListModal from '../RecommendedTeams/ExpertTeamListModal';
import AddPlayerInMatchModal from '../Players/AddPlayerInMatchModal';
import ConfirmationModal2 from '../../../Components/ConfirmationModal';
import {
    APP_USER_TYPE,
    CONFIRMATION,
    MATCH_STATUS_CONST,
    MATCH_SUBSTITUTES_OPTIONS,
    MATCH_PLAYER_BEFORE_LINEUP_ANNOUNCED_OPTIONS,
    MATCH_PLAYER_AFTER_LINEUP_ANNOUNCED_OPTIONS,
    MATCH_TYPE_CONST,
    REMOVE,
    ADD,
    TOURNAMENT_CONST,
    GAME_UNIQUE_CODE,
    FANTASY_TYPES_OPTIONS,
    CRICKET_FORMAT_OPTIONS,
} from '../../../Utilities/Constants';
import {debounce, errorReasonHandler} from '../../../Utilities/HelperFunctions';
import {fetchBoosterConfigs, fetchMultiplierConfigs} from '../Boosters/action';
import {AcUnit, CompareArrowsOutlined, RemoveCircle} from '@material-ui/icons';
import SubstitutePlayerInMatchModal from './SubstitutePlayerInMatchModal';
import PlayerStatsModal from './PlayerStatsModal';
import MarkMatchFRModal from './MarkMatchFRModal';
import ImageInput from '../../../Components/ImageInput/ImageInput';
import apis from '../../../apiCalls/apis';
import PlayerFreezeUnfreezeModal from './PlayerFreezeUnfreezeModal';
import styles from '../../../scss/Matches/AddMatch.module.scss';
import MuiInputField from '../../../Components/MuiInputField/MuiInputField';
import {GameUniqueCodeLabels} from '../../../Utilities/constants/label-constants';

class AddMatch extends React.Component {
    allowEditBeforePublish = [MATCH_STATUS_CONST.DRAFT, MATCH_STATUS_CONST.COMING_SOON];
    allowSquadOdds = ['val', 'csgo'];

    constructor(props) {
        super(props);
        let location = this.props.history.location.pathname.includes('edit');

        this.state = {
            menuItems: [
                {value: 'Active', text: 'YES'},
                {value: 'InActive', text: 'NO'},
            ],
            loading: false,
            matchStatus: '',
            statusOptions: [
                {value: 'DRAFT', text: 'DRAFT'},
                {value: 'UPCOMING', text: 'UPCOMING'},
                {value: 'LOCKED', text: 'LOCKED'},
                {value: 'LIVE NOW', text: 'LIVE NOW'},
                {value: 'COMPLETED', text: 'COMPLETED'},
            ],
            page: 1,
            pageSize: 10,
            activeTab: 0,
            modalName: '',
            mapOptions: [
                {value: 'Any', text: 'Any'},
                {value: 'All', text: 'All'},
                {value: 'Bermuda', text: 'Bermuda'},
                {value: 'Purgatory', text: 'Purgatory'},
                {value: 'Kalahari', text: 'Kalahari'},
                {value: 'Erangel', text: 'Erangel'},
                {value: 'Miramar', text: 'Miramar'},
                {value: 'Vikendi', text: 'Vikendi'},
                {value: 'Sanhok', text: 'Sanhok'},
                {value: 'SearchAndDestroy', text: 'Search And Destroy'},
                {value: 'HardPoint', text: 'Hard Point'},
                {value: 'Domination', text: 'Domination'},
                {value: 'Karakin', text: 'Karakin'},
                {value: 'set1', text: 'Set 1'},
                {value: 'set2', text: 'Set 2'},
                {value: 'set3', text: 'Set 3'},
                {value: 'set4', text: 'Set 4'},
                {value: 'set5', text: 'Set 5'},
                {value: 'set6', text: 'Set 6'},
                {value: 'set7', text: 'Set 7'},
                {value: 'set8', text: 'Set 8'},
                {value: 'set9', text: 'Set 9'},
            ],
            disabled: false,
            status: null,
            squadName: null,
            squad: null,
            disableTab1: false,
            disableTab2: false,
            disableTab3: !location,
            edit: location,
            selectedSquads: [],
            tournamentName: null,
            streamLink: null,
            expertAnalysisId: null,
            maxTeamSize: null,
            maxPlayersPerSquad: null,
            numberOfCredits: null,
            tournamentStatus: null,
            matchName: null,
            startTime: null,
            date: null,
            mapName: null,
            squadOptions: [],
            contests: [],
            noOfPlayer: null,
            noOfSquads: null,
            noOfContests: null,
            nextClick: false,
            markLiveModalVisible: false,
            isNewRoleActivated: true,
            rankForSorting: 99,
            game: null,
            matchMode: null,
            matchModeOptions: [],
            lineupsOptions: [
                {text: 'Lineup Announced', value: 'ANNOUNCED'},
                {text: 'Lineup Expected', value: 'EXPECTED'},
                {text: 'Lineup Not Expected', value: 'NOT_EXPECTED'},
            ],
            lineupStatus: 'EXPECTED',
            gameOptions: [],
            attribute1Options: [],
            attribute1: null,
            attribute1Val: null,
            attribute2Options: [],
            attribute2: null,
            attribute2Val: null,
            fantasyScoring: null,
            tournamentList: [],
            selectedTournament: '',
            // freezeUnfreezePlayer: null,
            fantasyType: 'NORMAL',
            cricketFormat: null,
            commonMatchId: '',
        };
    }

    /**
     * function to save entered data in state
     * @param {string} field field names from the form
     * @param {*} value value in the field
     */
    handleInputChange(field, value) {
        let {tournament, login, toGame} = this.props;
        this.setState({
            [field]: value,
            errorMessage: '',
        });
        if (field === 'fantasyType') {
            this.setState({
                [field]: value,
                liveCoverage: true,
            });
        }
        if (field === 'selectedTournament') {
            let list = tournament.tournamentList.data.data;
            let tournamentList = [];
            list.map((tournament) => {
                tournamentList.push({
                    title: tournament.tournamentName,
                    value: tournament.id,
                });
            });
            this.setState({
                tournamentList,
            });
        }

        if (field === 'matchMode') {
            const curMatchMode = this.props.toMatchMode.TOMatchModeList.data.data.find((item) => item.id === value);
            this.setState({
                maxPlayersPerSquad: curMatchMode.maxPlayersPerSquad ? curMatchMode.maxPlayersPerSquad : '',
                numberOfCredits: curMatchMode.numberOfCredits ? curMatchMode.numberOfCredits : '',
                maxTeamSize: curMatchMode.maxFantasyTeamSize ? curMatchMode.maxFantasyTeamSize : '',
                fantasyScoring: '',
            });
            this.props.getFantasyScoreModel(
                {
                    game: this.state.game?.id,
                    matchMode: value,
                },
                this.props.login.userDetails.data.accessToken,
            );
        }

        if (field === 'game') {
            //let attributeOptions = this.props.toMatchAttribute.TOMatchAttributeListSuccess ? this.props.toMatchAttribute.TOMatchAttributeList.data.data.filter(item => item.game === value).map(item => { return { text: item.name, value: item.id } }) : null;
            this.setState({
                game: toGame.TOGameList.data.data.filter((item) => item.id === value)?.[0],
                matchMode: null,
                matchModeOptions: this.props.toMatchMode.TOMatchModeListSuccess
                    ? this.props.toMatchMode.TOMatchModeList.data.data
                          .filter((item) => item.game === value)
                          .map((item) => {
                              return {text: item.modeName, value: item.id};
                          })
                    : null,
                /*attribute1Options: attributeOptions,
                attribute1: null,
                attribute1Val: "",
                attribute2Options: attributeOptions,
                attribute2: null,
                attribute2Val: "",*/
                fantasyScoring: '',
                maxPlayersPerSquad: '',
                numberOfCredits: '',
                maxTeamSize: '',
                selectedSquads: [],
                tournamentName: '',
                tournamentList: [],
                selectedTournament: '',
                selectedBoosterConfig: '',
                boosterConfigOptions: [],
                selectedMultiplierConfig: '',
                multiplierConfigOptions: [],
            });
            const data = {
                pageNo: 1,
                pageSize: 30,
                gameId: value,
                status: [TOURNAMENT_CONST.DRAFT, TOURNAMENT_CONST.LIVE, TOURNAMENT_CONST.UPCOMING],
            };
            this.props.listAllTournaments(data, login.userDetails.data.accessToken);
        }
    }

    handleUploadMatchImage = async (image) => {
        if (!image) return;
        let {login} = this.props;
        const data = new FormData();
        data.append('image', image);
        this.setState({loading: true});
        try {
            const response = await apis.commonUploadImage(data, login?.userDetails?.data?.accessToken);
            if (response?.data?.status === 'success') {
                this.setState({matchImage: response?.data?.data?.image, loading: false});
            } else if (response?.data?.status === 'fail') {
                this.setState({
                    loading: false,
                    errorMessage: errorReasonHandler(response.data.errorData.errorReason),
                });
            }
        } catch (e) {
            this.setState({errorMessage: e.message, loading: false});
        }
    };

    handleChangeState = (obj = {}) => {
        this.setState({...obj, errorMessage: ''});
    };

    /**
     * API call to search for a squad
     * @param {string} field field name
     * @param {string} value value entered in the field
     */
    onSearchSquad(field, value) {
        const {login} = this.props;
        let squad = {};
        squad.searchString = value;
        this.setState({[field]: value});
        this.props.getSquad(squad, this.state.game?.id, login.userDetails.data.accessToken);
    }

    componentWillMount() {
        const {login, history} = this.props;
        if (!login.userDetails.data) {
            history.push('/');
            this.props.logout();
        }
    }

    componentDidMount() {
        const {login, history, match, client} = this.props;
        if (!login.userDetails.data) {
            history.push('/');
            this.props.logout();
        } else {
            const {pageSize, page, edit} = this.state;
            let listAll = {
                listType: 'ALL',
            };
            this.props.getTOGameList(listAll, login.userDetails.data.accessToken, client.selectedClient);
            this.props.getTOMatchModeList(listAll, login.userDetails.data.accessToken);
            //this.props.listAllTournaments(login.userDetails.data.accessToken,1,30);
            //this.props.getTOMatchAttributeList(listAll, this.props.login.userDetails.data.accessToken);
            if (edit) {
                let newSquad = {
                    pageSize: pageSize,
                    page: page,
                    matchId: match.editMatch.id,
                };
                this.props.getSquadList(newSquad, login.userDetails.data.accessToken);
                this.props.getMatch(match.editMatch.id, login.userDetails.data.accessToken, client.selectedClient);
            } else {
                let newSquad = {
                    pageSize: pageSize,
                    page: page,
                };
                this.props.getSquadList(newSquad, login.userDetails.data.accessToken);
            }
        }
    }

    componentDidUpdate(prevProps) {
        const {edit, activeTab, nextClick, selectedSquads, game} = this.state;
        const {
            login,
            match,
            history,
            contest,
            squad,
            toGame,
            toMatchMode,
            toMatchAttribute,
            fantasyConfig,
            tournament,
            player,
            boosters,
            client,
        } = this.props;

        if (tournament.tournamentListSuccess && !prevProps.tournament.tournamentListSuccess) {
            let list = tournament.tournamentList.data.data;
            let tournamentList = [];
            list.map((tournament) => {
                tournamentList.push({
                    title: tournament.tournamentName,
                    value: tournament.id,
                });
            });
            this.setState({
                tournamentList,
            });
        } else if (tournament.tournamentListFail && !prevProps.tournament.tournamentListFail) {
            this.setState({
                errorMessage: tournament.errorMessage,
            });
        }
        if (squad.getSquadRequest && !prevProps.squad.getSquadRequest) {
            this.setState({loading: true});
        } else if (squad.getSquadSuccess && !prevProps.squad.getSquadSuccess) {
            if (nextClick) {
                let selectSquad = selectedSquads;
                selectSquad.push(squad.getSquad.data);
                this.setState({selectedSquads: selectSquad, loading: false});
            } else if (!nextClick) {
                let squads = [];
                squad.getSquad.data.map((squad) => {
                    squads.push({title: squad.squadName, value: squad.id});
                });
                if (squad.getSquad.data.length > 0) {
                    this.setState({squadOptions: squads, loading: false});
                } else {
                    this.setState({loading: false});
                }
            }
        } else if (squad.getSquadFail && !prevProps.squad.getSquadFail) {
            this.setState({
                errorMessage: squad.errorMessage,
                loading: false,
            });
        }

        if (toGame.TOGameListSuccess && !prevProps.toGame.TOGameListSuccess) {
            this.setState({
                gameOptions: toGame.TOGameList.data.data.map((item) => {
                    return {text: item.gameName, value: item.id};
                }),
                loading: false,
            });
        } else if (toGame.TOGameListFail && !prevProps.toGame.TOGameListFail) {
            this.setState({
                errorMessage: toGame.errorMessage,
                loading: false,
            });
        }

        if (toMatchMode.TOMatchModeListSuccess && !prevProps.toMatchMode.TOMatchModeListSuccess) {
            this.setState({
                matchModeOptions: toMatchMode.TOMatchModeList.data.data
                    .filter((item) => item.game === game?.id)
                    .map((item) => {
                        return {text: item.modeName, value: item.id};
                    }),
            });
        } else if (toMatchMode.TOMatchModeListFail && !prevProps.toMatchMode.TOMatchModeListFail) {
            this.setState({
                errorMessage: toMatchMode.errorMessage,
                loading: false,
            });
        }

        if (toMatchAttribute.TOMatchAttributeListSuccess && !prevProps.toMatchAttribute.TOMatchAttributeListSuccess) {
            let attributeOptions = toMatchAttribute.TOMatchAttributeList.data.data
                .filter((item) => item.game === game?.id)
                .map((item) => {
                    return {text: item.name, value: item.id};
                });
            this.setState({
                attribute1Options: attributeOptions,
                attribute2Options: attributeOptions,
            });
            /*if (this.state.edit) {
                if (this.props.match.attribute1) {
                    this.setState({
                        attribute1: this.props.match.attribute1.id
                    })
                }
                if (this.props.match.attribute2) {
                    this.setState({
                        attribute2: this.props.match.attribute2.id
                    })
                }
            }*/
        } else if (toMatchAttribute.TOMatchAttributeListFail && !prevProps.toMatchAttribute.TOMatchAttributeListFail) {
            this.setState({
                errorMessage: toMatchAttribute.errorMessage,
                loading: false,
            });
        }

        if (match.addmatchSuccess && !prevProps.match.addmatchSuccess) {
            if (activeTab === 0 && !edit) {
                this.setState({
                    disableTab3: false,
                    errorMessage: 'Match Saved',
                    loading: false,
                });
            }
        } else if (match.addmatchFail && !prevProps.match.addmatchFail) {
            this.setState({
                errorMessage: match.errorMessage,
                loading: false,
            });
        }

        if (match.updateMatchSuccess && !prevProps.match.updateMatchSuccess) {
            if (!edit) {
                this.setState({
                    disableTab3: false,
                    disableTab1: false,
                    disableTab2: false,
                    loading: false,
                    errorMessage: 'Match Updated',
                });
            } else {
                history.push('/matches');
            }
        } else if (match.updateMatchFail && !prevProps.match.updateMatchFail) {
            this.setState({
                errorMessage: match.errorMessage,
                loading: false,
            });
        }

        if (match.updatePlayerStatsRequest && !prevProps.match.updatePlayerStatsRequest) {
            this.setState({
                loading: true,
            });
        } else if (match.updatePlayerStatsFail && !prevProps.match.updatePlayerStatsFail) {
            this.setState({
                errorMessage: match.errorMessage,
                loading: false,
            });
        } else if (match.updatePlayerStatsSuccess && !prevProps.match.updatePlayerStatsSuccess) {
            this.setState({
                loading: false,
                errorMessage: 'Player Stats Successfully Fetched',
            });
        }

        if (match.getMatchSuccess && !prevProps.match.getMatchSuccess) {
            const {
                matchName,
                mapName,
                tournamentName,
                tournament,
                streamLink,
                expertAnalysisId,
                startTime,
                squads,
                status,
                contests,
                players,
                maxPlayersPerSquad,
                maxTeamSize,
                numberOfCredits,
                rankForSorting,
                isNewRoleActivated,
                game,
                matchMode,
                attribute1,
                attribute1Val,
                attribute2,
                attribute2Val,
                fantasyScoring,
                matchTags,
                boosterConfig,
                squadOdds,
                liveCoverage,
                matchType,
                glacierId,
                substitution,
                createdByGlacier,
                isAllSuperMatch,
                matchImage,
                fantasyResultFile,
                isGlacierSeries,
                lineupStatus,
                playersPlayingInfo,
                fantasyType,
                multiplierConfig,
                playersBattingLineup,
                cricketFormat,
                matchCommon,
                substitutionData,
                glacierClassId,
            } = match.getMatch.data;
            let squadList = squads !== null ? squads : [];
            // let time = moment(startTime);
            // let timestamp = time.format('M/D/YYYY H:mm');
            let timestamp = timeFormat(startTime, 'M/D/YYYY H:mm');
            const selectedBoosterConfig = {title: boosterConfig?.name, value: boosterConfig?.id};
            const selectedMultiplierConfig = {title: multiplierConfig?.name, value: multiplierConfig?.id};
            this.setState({
                selectedSquads: squadList,
                isNewRoleActivated,
                liveCoverage,
                isGlacierSeries,
                fantasyType,
                selectedTournament: {title: tournamentName, value: tournament ? tournament.id : null},
                tournamentName: tournamentName,
                streamLink: streamLink,
                expertAnalysisId: expertAnalysisId,
                tournamentStatus: status,
                matchName: matchName,
                startTime: timestamp,
                mapName: mapName,
                contests: contests,
                maxTeamSize: maxTeamSize,
                maxPlayersPerSquad: maxPlayersPerSquad,
                numberOfCredits: numberOfCredits,
                matchMode: matchMode ? matchMode.id : null,
                lineupStatus: lineupStatus ? lineupStatus : null,
                fantasyType: fantasyType ? fantasyType : null,
                cricketFormat: cricketFormat ? cricketFormat : null,
                playerLineupStatus: playersPlayingInfo,
                lineupOrder: playersBattingLineup,
                noOfPlayer: players?.length,
                noOfSquads: squads?.length,
                loading: false,
                noOfContests: contests.length,
                matchStatus: status,
                disabled: !this.allowEditBeforePublish.includes(status) && status !== MATCH_STATUS_CONST.UPCOMING,
                rankForSorting: rankForSorting,
                game: game ? game : null,
                attribute1: attribute1,
                attribute1Val: attribute1Val,
                attribute2: attribute2,
                attribute2Val: attribute2Val,
                fantasyScoring: fantasyScoring ? {text: fantasyScoring.name, value: fantasyScoring.id} : null,
                matchTags,
                squadOdds,
                matchType,
                glacierId,
                createdByGlacier,
                selectedBoosterConfig: !!boosterConfig?.id ? selectedBoosterConfig : '',
                boosterConfigOptions: !!boosterConfig?.id ? [selectedBoosterConfig] : [],
                selectedMultiplierConfig: !!multiplierConfig?.id ? selectedMultiplierConfig : '',
                multiplierConfigOptions: !!multiplierConfig?.id ? [selectedMultiplierConfig] : [],
                substitution: substitution?.type || undefined,
                isAllSuperMatch,
                matchImage,
                fantasyResultFile,
                commonMatchId: matchCommon,
                substitutionData,
                glacierClassId,
            });
            this.props.getTOMatchModeList({listType: 'ALL'}, login.userDetails.data.accessToken);
            //this.props.getTOMatchAttributeList({listType:'ALL'}, login.userDetails.data.accessToken);
        } else if (match.getMatchFail && !prevProps.match.getMatchFail) {
            this.setState({
                loading: false,
                errorMessage: match.errorMessage,
            });
        } else if (match.publishMatchSuccess && !prevProps.match.publishMatchSuccess) {
            history.push('/matches');
        } else if (match.publishMatchFail && !prevProps.match.publishMatchFail) {
            this.setState({
                loading: false,
                errorMessage: match.errorMessage,
            });
        } else if (contest.deleteContestSuccess && !prevProps.contest.deleteContestSuccess) {
            const {pageSize, page, edit} = this.state;
            let squad = {};
            squad.pageSize = pageSize;
            squad.page = page;

            this.props.getSquadList(squad, login.userDetails.data.accessToken);
            if (edit) {
                let squad = {};
                squad.pageSize = pageSize;
                squad.page = page;
                squad.matchId = match.editMatch.id;
                this.props.getSquadList(squad, login.userDetails.data.accessToken);
                this.props.getMatch(match.editMatch.id, login.userDetails.data.accessToken, client.selectedClient);
            }
            this.setState({
                loading: false,
                errorMessage: contest.deleteContest.msg,
            });
        } else if (contest.deleteContestFail && !prevProps.contest.deleteContestFail) {
            this.setState({
                loading: false,
                errorMessage: contest.errorMessage,
            });
        } else if (match.deleteMatchSuccess && !prevProps.match.deleteMatchSuccess) {
            this.setState({
                loading: false,
                match: [],
                errorMessage: match.deleteMatch.msg,
            });
            history.push('/matches');
        } else if (match.deleteMatchFail && !prevProps.match.deleteMatchFail) {
            this.setState({loading: false, errorMessage: match.errorMessage});
        }

        if (
            (match.markLiveMatchInit && !prevProps.match.markLiveMatchInit) ||
            (match.isUpdatingStatusLive && !prevProps.match.isUpdatingStatusLive) ||
            (match.isUpdatingMatchTag && !prevProps.match.isUpdatingMatchTag) ||
            (match.isComingSoonMatch && !prevProps.match.isComingSoonMatch) ||
            (match.isUpdatingMatchType && !prevProps.match.isUpdatingMatchType) ||
            (boosters.isFetchingBoosterConfigs && !prevProps.boosters.isFetchingBoosterConfigs) ||
            (match.isSubstitutePlayer && !prevProps.match.isSubstitutePlayer) ||
            (match.isMatchDraftToLive && !prevProps.match.isMatchDraftToLive)
        ) {
            this.setState({loading: true});
        }
        if (
            (match.markLiveMatchSuccess && !prevProps.match.markLiveMatchSuccess) ||
            (match.isUpdatingStatusLiveSuccess && !prevProps.match.isUpdatingStatusLiveSuccess)
        ) {
            this.setState({loading: false, errorMessage: 'Match marked live'});
            this.props.getMatch(match.editMatch.id, login.userDetails.data.accessToken, client.selectedClient);
        }

        if (match.isUpdatingMatchTagSuccess && !prevProps.match.isUpdatingMatchTagSuccess) {
            this.setState({loading: false, errorMessage: 'Match Successfully updated'});
            this.props.getMatch(match.editMatch.id, login.userDetails.data.accessToken, client.selectedClient);
        }

        if (match.isComingSoonMatchSuccess && !prevProps.match.isComingSoonMatchSuccess) {
            this.setState({loading: false, errorMessage: 'Match Successfully updated'});
            this.handleOnCloseEstarModal();
            this.props.getMatch(match.editMatch.id, login.userDetails.data.accessToken, client.selectedClient);
        }

        if (
            (match.markLiveMatchFail && !prevProps.match.markLiveMatchFail) ||
            (match.isUpdatingStatusLiveFail && !prevProps.match.isUpdatingStatusLiveFail) ||
            (match.isUpdatingMatchTagFail && !prevProps.match.isUpdatingMatchTagFail) ||
            (match.isComingSoonMatchFail && !prevProps.match.isComingSoonMatchFail)
        ) {
            this.setState({loading: false, errorMessage: match.errorMessage});
        }

        if (match.isUpdatingMatchTypeSuccess && !prevProps.match.isUpdatingMatchTypeSuccess) {
            this.setState({loading: false, errorMessage: match.errorMessage.msg});
            this.props.getMatch(match.editMatch.id, login.userDetails.data.accessToken, client.selectedClient);
        }

        if (match.isSubstitutePlayerSuccess && !prevProps.match.isSubstitutePlayerSuccess) {
            this.setState({loading: false, errorMessage: match.errorMessage.msg, substitutePlayers: null});
            this.props.getMatch(match.editMatch.id, login.userDetails.data.accessToken, client.selectedClient);
        }

        if (match.isPlayerFreezeUnfreezeSuccess && !prevProps.match.isPlayerFreezeUnfreezeSuccess) {
            this.setState({loading: false, errorMessage: match.errorMessage.msg});
            this.props.getMatch(match.editMatch.id, login.userDetails.data.accessToken, client.selectedClient);
        }

        if (match.isMatchDraftToLiveSuccess && !prevProps.match.isMatchDraftToLiveSuccess) {
            this.setState({loading: false, errorMessage: match.errorMessage.msg});
            this.props.getMatch(match.editMatch.id, login.userDetails.data.accessToken, client.selectedClient);
        }

        if (
            match.isMatchMarkAsFRSuccess &&
            !prevProps.match.isMatchMarkAsFRSuccess &&
            !match?.martFRDetails?.data?.confirmationRequired
        ) {
            this.setState({errorMessage: match?.martFRDetails?.msg, markFrMatch: null});
            this.props.getMatch(match.editMatch.id, login.userDetails.data.accessToken, client.selectedClient);
        }

        if (
            (match.isSubstitutePlayerFail && !prevProps.match.isSubstitutePlayerFail) ||
            (match.isUpdatingMatchTypeFail && !prevProps.match.isUpdatingMatchTypeFail) ||
            (match.isMatchDraftToLiveFail && !prevProps.match.isMatchDraftToLiveFail) ||
            (match.isPlayerFreezeUnfreezeFail && !prevProps.match.isPlayerFreezeUnfreezeFail)
        ) {
            this.setState({loading: false, errorMessage: match.errorMessage.msg});
        }

        if (fantasyConfig.getScoringSuccess && !prevProps.fantasyConfig.getScoringSuccess) {
            this.setState({
                fantasyScoring: {text: fantasyConfig.getScoring.data.name, value: fantasyConfig.getScoring.data.id},
            });
        } else if (fantasyConfig.getScoringFail && !prevProps.fantasyConfig.getScoringFail) {
            this.setState({errorMessage: fantasyConfig.errorMessage});
        }

        if (contest.copyContestSuccess && !prevProps.contest.copyContestSuccess) {
            this.setState({
                loading: false,
                existMatchId: '',
                errorMessage: 'Contests successfully created',
            });
            this.props.getMatch(match.editMatch.id, login.userDetails.data.accessToken, client.selectedClient);
        }

        if (contest.copyContestFail && !prevProps.contest.copyContestFail) {
            this.setState({loading: false, errorMessage: contest.errorMessage});
        }

        if (player.isAddingPlayerInMatchSuccess && !prevProps.player.isAddingPlayerInMatchSuccess) {
            this.setState({errorMessage: 'Player Successfully Added.', addPlayer: undefined});
            this.props.getMatch(match.editMatch.id, login.userDetails.data.accessToken, client.selectedClient);
        }

        if (boosters.isFetchingBoosterConfigsSuccess && !prevProps.boosters.isFetchingBoosterConfigsSuccess) {
            const {boosterConfig} = match?.getMatch?.data || {};
            const tempList =
                boosters?.boosterConfigsList?.data
                    ?.filter((config) => config.id !== boosterConfig?.id)
                    .map((config) => ({
                        title: config.name,
                        value: config.id,
                    })) || [];
            if (boosterConfig?.id) tempList.unshift({title: boosterConfig?.name, value: boosterConfig?.id});
            this.setState({loading: false, boosterConfigOptions: [...tempList]});
        }
        if (boosters.isFetchingBoosterConfigsFail && !prevProps.boosters.isFetchingBoosterConfigsFail) {
            this.setState({loading: false, errorMessage: boosters.message.msg});
        }

        if (boosters.isFetchingMultiplierConfigsSuccess && !prevProps.boosters.isFetchingMultiplierConfigsSuccess) {
            const {multiplierConfig} = match?.getMatch?.data || {};
            const tempList =
                boosters?.multiplierConfigsList
                    ?.filter((config) => config.id !== multiplierConfig?.id)
                    .map((config) => ({
                        title: config.name,
                        value: config.id,
                    })) || [];
            if (multiplierConfig?.id) tempList.unshift({title: multiplierConfig?.name, value: multiplierConfig?.id});
            this.setState({loading: false, multiplierConfigOptions: [...tempList]});
        }
        if (boosters.isFetchingMultiplierConfigsFail && !prevProps.boosters.isFetchingMultiplierConfigsFail) {
            this.setState({loading: false, errorMessage: boosters.message.msg});
        }
    }

    /**
     * on click of save button validate and save match
     */
    onSave() {
        const {
            edit,
            selectedTournament,
            streamLink,
            expertAnalysisId,
            tournamentStatus,
            matchName,
            startTime,
            mapName,
            selectedSquads,
            maxTeamSize,
            maxPlayersPerSquad,
            rankForSorting,
            game,
            numberOfCredits,
            matchMode,
            attribute1,
            attribute1Val,
            attribute2,
            lineupStatus,
            lineupOrder,
            attribute2Val,
            fantasyScoring,
            isNewRoleActivated,
            selectedBoosterConfig,
            squadOdds,
            fantasyType,
            cricketFormat,
            liveCoverage,
            glacierId,
            substitution,
            matchImage,
            isGlacierSeries,
            playerLineupStatus,
            selectedMultiplierConfig,
            commonMatchId,
            substitutionData,
            glacierClassId,
        } = this.state;
        const {login, match, client} = this.props;

        const today = moment();
        const matchdate = moment(startTime);

        let finalTeamSize = maxTeamSize ? parseInt(maxTeamSize) : 6;
        let finalPlayersPerSquad = maxPlayersPerSquad ? parseInt(maxPlayersPerSquad) : 2;
        let finalCredits = numberOfCredits ? parseInt(numberOfCredits) : 50;
        let minSquadlength = Math.ceil(finalTeamSize / finalPlayersPerSquad);
        if (selectedTournament.value === null || selectedTournament.value === undefined) {
            this.setState({errorMessage: 'Please select a Tournament to proceed'});
        }
        if (matchName === null || matchName === undefined || matchName === '' || matchName.length < 5) {
            this.setState({errorMessage: 'Enter valid Match name with minimum 5 characters'});
        } else if (!edit && (startTime === null || startTime === undefined || matchdate < today)) {
            this.setState({errorMessage: 'Please select a valid future start time'});
        } else if (mapName === null || mapName === undefined) {
            this.setState({errorMessage: 'Please select a Map name'});
        } else if (game?.id === null || game?.id === undefined || game?.id === '') {
            /*else if (selectedSquads.length < minSquadlength) {
            this.setState({errorMessage: "Please select minimum " + minSquadlength + " squads"})
        }*/
            this.setState({errorMessage: 'please select valid game'});
        } else if (matchMode === null || matchMode === undefined || matchMode === '') {
            this.setState({errorMessage: 'please select valid match mode'});
        } else if (fantasyScoring === null || fantasyScoring === undefined || fantasyScoring === '') {
            this.setState({errorMessage: 'please select valid fantasyScoring'});
        } else if (lineupStatus === null || lineupStatus === undefined || lineupStatus === '') {
            this.setState({errorMessage: 'please select valid lineup Status'});
        } else if (fantasyType === null || fantasyType === undefined || fantasyType === '') {
            this.setState({errorMessage: 'Please Select a Valid Fantasy Type'});
        } else {
            /*else if (attribute1 === null || attribute1 === undefined || attribute1 === "") {
            this.setState({
                errorMessage: "please select valid attribute 1"
            })
        }
        else if (attribute1Val === null || attribute1Val === undefined || attribute1Val === "") {
            this.setState({
                errorMessage: "please enter valid attribute 1 value"
            })
        }
        else if (attribute2 === null || attribute2 === undefined || attribute2 === "") {
            this.setState({
                errorMessage: "please select valid attribute 2"
            })
        }
        else if (attribute2Val === null || attribute2Val === undefined || attribute2Val === "") {
            this.setState({
                errorMessage: "please enter valid attribute 2 value"
            })
        }*/
            if (edit) {
                let timestamp = moment(startTime).valueOf();
                const {selectedSquads} = this.state;
                let squadIds = [];
                let play = [];

                squadIds = selectedSquads.map((squad) => {
                    return squad.id;
                });

                selectedSquads.map((squad) => {
                    let activePlayers = squad.players.filter((player) => {
                        return player.status === 'ACTIVE';
                    });

                    activePlayers.map((player) => {
                        play.push(player.id);
                    });
                });

                this.setState({loading: true});
                let newMatch = {
                    tournamentId: selectedTournament.value,
                    boosterConfigId: selectedBoosterConfig?.value,
                    multiplierConfigId: selectedMultiplierConfig?.value,
                    streamLink: streamLink ? streamLink : null,
                    matchImage: matchImage ? matchImage : null,
                    expertAnalysisId: expertAnalysisId ? expertAnalysisId : null,
                    matchName: matchName,
                    startTime: timestamp,
                    mapName: mapName,
                    tournamentStatus: tournamentStatus,
                    squads: JSON.stringify(squadIds),
                    players: JSON.stringify(play),
                    matchId: match.editMatch.id,
                    maxTeamSize: finalTeamSize,
                    maxPlayersPerSquad: finalPlayersPerSquad,
                    numberOfCredits: finalCredits,
                    rankForSorting: !rankForSorting || rankForSorting === '' ? 99 : parseInt(rankForSorting),
                    game: game?.id,
                    matchMode: matchMode,
                    lineupStatus: lineupStatus,
                    playersPlayingInfo: playerLineupStatus,
                    lineupOrder: lineupOrder,
                    fantasyScoring: fantasyScoring.value,
                    isNewRoleActivated: !!isNewRoleActivated,
                    liveCoverage: !!liveCoverage,
                    isGlacierSeries: !!isGlacierSeries,
                    fantasyType: fantasyType,
                    cricketFormat,
                    squadOdds,
                    glacierId: glacierId ? glacierId : undefined,
                    substitution: substitution || undefined,
                    commonMatchId,
                    substitutionData,
                    glacierClassId,
                    /* attribute1: attribute1,
                     attribute1Val: attribute1Val,
                     attribute2: attribute2,
                     attribute2Val: attribute2Val*/
                };
                this.props.updateMatch(newMatch, login.userDetails.data.accessToken, client.selectedClient);
            } else {
                let selectedTime = moment(startTime, 'DD/MM/YYY H:mm');
                let timestamp = moment(selectedTime).valueOf();
                const {selectedSquads} = this.state;
                let squadIds = [];
                let play = [];

                squadIds = selectedSquads.map((squad) => {
                    return squad.id;
                });

                selectedSquads.map((squad) => {
                    let activePlayers = squad.players.filter((player) => {
                        return player.status === 'ACTIVE';
                    });

                    activePlayers.map((player) => {
                        play.push(player.id);
                    });
                });

                let newMatch = {
                    tournamentId: selectedTournament.value,
                    boosterConfigId: selectedBoosterConfig?.value,
                    multiplierConfigId: selectedMultiplierConfig?.value,
                    streamLink: streamLink ? streamLink : null,
                    matchImage: matchImage ? matchImage : null,
                    expertAnalysisId: expertAnalysisId ? expertAnalysisId : null,
                    matchName: matchName,
                    startTime: timestamp,
                    mapName: mapName,
                    tournamentStatus: tournamentStatus,
                    maxTeamSize: finalTeamSize,
                    maxPlayersPerSquad: finalPlayersPerSquad,
                    numberOfCredits: finalCredits,
                    squads: JSON.stringify(squadIds),
                    players: JSON.stringify(play),
                    rankForSorting: !rankForSorting || rankForSorting === '' ? 99 : parseInt(rankForSorting),
                    matchMode: matchMode,
                    lineupStatus: lineupStatus,
                    playersPlayingInfo: playerLineupStatus,
                    lineupOrder: lineupOrder,
                    /*attribute1: attribute1,
                    attribute1Val: attribute1Val,
                    attribute2: attribute2,
                    attribute2Val: attribute2Val,*/
                    fantasyScoring: fantasyScoring.value,
                    isNewRoleActivated: !!isNewRoleActivated,
                    liveCoverage: !!liveCoverage,
                    isGlacierSeries: !!isGlacierSeries,
                    fantasyType: fantasyType,
                    cricketFormat,
                    squadOdds,
                    glacierId: glacierId ? glacierId : undefined,
                    substitution: substitution || undefined,
                    substitutionData,
                };
                this.setState({loading: true});
                this.props.createMatch(newMatch, login.userDetails.data.accessToken, client.selectedClient);
            }
        }
    }

    /**
     * navigate to contest create page with contest id
     */
    addNewContest() {
        const {match, history} = this.props;

        if (this.state.edit) {
            this.props.contestMatch(match.editMatch.id);
            history.push('/addContests');
        } else {
            this.props.contestMatch(match.addmatch.data.id);
            history.push('/addContests');
        }
    }

    handleSubmitCopyContests = (e) => {
        !!e && e.preventDefault();
        const {match, login, client} = this.props;
        const {edit, existMatchId} = this.state;
        let data = {existMatchId, body: {}};
        edit ? (data.body.matchId = match.editMatch.id) : (data.body.matchId = match.addmatch.data.id);
        this.setState({loading: true});
        this.props.copyContestFromMatch(data, login.userDetails.data.accessToken, client.selectedClient);
    };

    handleOpenExpertModal = () => {
        if (!this.state.expertAnalysisId) {
            this.setState({
                errorMessage: 'Please Enter Expert Analysis Youtube Video Id First To Create Team',
            });
            return;
        }
        this.setState({expertTeamModal: true});
    };

    /**
     * Ui for tab A
     */
    renderTabA() {
        const {
            mapName,
            disabled,
            streamLink,
            expertAnalysisId,
            matchName,
            mapOptions,
            edit,
            tournamentList,
            selectedTournament,
            startTime,
            matchStatus,
            maxTeamSize,
            maxPlayersPerSquad,
            rankForSorting,
            gameOptions,
            game,
            numberOfCredits,
            matchMode,
            matchModeOptions,
            fantasyScoring,
            isNewRoleActivated,
            liveCoverage,
            lineupStatus,
            fantasyType,
            cricketFormat,
            boosterConfigOptions,
            selectedBoosterConfig,
            glacierId,
            substitution,
            matchImage,
            isGlacierSeries,
            lineupsOptions,
            selectedMultiplierConfig,
            multiplierConfigOptions,
            commonMatchId,
            substitutionData,
            glacierClassId,
        } = this.state;
        let shrink = edit ? 'true' : 'false';
        const {
            matchWrite,
            addMatchCommonMatchId,
            addMatchGlacierClassId,
            addMatchSearchBoosterConfig,
            addMatchSearchMultiplierConfig,
            addMatchGlacierId,
            addMatchSubstitution,
            addMatchStreamLink,
            addMatchExpertAnalysis,
            addMatchMapName,
            addMatchRankForSorting,
            addMatchGlacierSeries,
            addMatchFantasyType,
            addMatchEnableViceCaptian,
            addMatchUploadImage,
        } = this.props?.login?.permissions || {};
        const {selectedClient} = this.props?.client;

        const handleClientValue = (_selectedClient) => {
            if (_selectedClient === 'client_jade') return 'Jade';
            if (_selectedClient === 'client_unicorn') return 'Unicorn';
            if (_selectedClient === 'client_pie') return 'Pie';
            return 'Fanclash';
        };

        return (
            <div>
                <div className="card-grid-container nopadding margin-top-20">
                    {!!addMatchCommonMatchId && (
                        <div className="grid-item">
                            <MuiInputField
                                label="Common Match ID"
                                type="text"
                                readOnly={true}
                                value={commonMatchId || ''}
                                size="small"
                            />
                        </div>
                    )}

                    {!!addMatchGlacierClassId && (
                        <div className="grid-item">
                            <MuiInputField
                                label="Glacier Class ID"
                                type="text"
                                readOnly={true}
                                value={glacierClassId || ''}
                                size="small"
                            />
                        </div>
                    )}
                    <div className="grid-item margin-top-20">
                        <MuiInputField
                            label="Client"
                            type="text"
                            readOnly={true}
                            value={handleClientValue(selectedClient) || ''}
                            size="small"
                        />
                    </div>
                    {/* row 1 */}
                    <div className="grid-item margin-top-20">
                        <DropDown
                            label="Game"
                            type="text"
                            menuItems={gameOptions}
                            name="game"
                            value={game?.id || null}
                            disabled={edit}
                            onChange={this.handleInputChange.bind(this, 'game')}
                            placeholder="Select Game"
                        />
                    </div>
                    <div className="grid-item ">
                        <AutoCompleteField
                            label="Tournament Name"
                            onChange={this.handleInputChange.bind(this, 'selectedTournament')}
                            options={tournamentList}
                            value={selectedTournament}
                            disabled={edit}
                            inputChange={this.onTOInputChange.bind(this, 'searchString')}
                        />
                    </div>
                    {!!addMatchSearchBoosterConfig && !!this.state.game?.id && (
                        <div className="grid-item mt-15">
                            <AutoCompleteField
                                label="Search Booster Config"
                                options={boosterConfigOptions || []}
                                value={selectedBoosterConfig || null}
                                inputChange={this.handleSearchBoosterConfigs}
                                onChange={this.handleInputChange.bind(this, 'selectedBoosterConfig')}
                                disabled={edit && !this.allowEditBeforePublish.includes(matchStatus)}
                            />
                        </div>
                    )}
                    {!!addMatchSearchMultiplierConfig && !!this.state.game?.id && (
                        <div className="grid-item mt-15">
                            <AutoCompleteField
                                label="Search Multiplier Config"
                                options={multiplierConfigOptions || []}
                                value={selectedMultiplierConfig || null}
                                inputChange={this.handleSearchMultiplierConfigs}
                                onChange={this.handleInputChange.bind(this, 'selectedMultiplierConfig')}
                                disabled={edit && !this.allowEditBeforePublish.includes(matchStatus)}
                            />
                        </div>
                    )}
                    {!!addMatchGlacierId && (
                        <div className="grid-item mt-15">
                            <Input
                                shrink={shrink}
                                label="Glacier id"
                                type="text"
                                name="glacierId"
                                value={glacierId || ''}
                                placeholder="Enter "
                                onChange={this.handleInputChange.bind(this, 'glacierId')}
                            />
                        </div>
                    )}
                    {!!addMatchSubstitution && (
                        <div className="grid-item mt-15">
                            <DropDown
                                menuItems={MATCH_SUBSTITUTES_OPTIONS}
                                label="Substitution"
                                value={substitution || ''}
                                placeholder="select"
                                onChange={this.handleInputChange.bind(this, 'substitution')}
                            />
                        </div>
                    )}
                </div>
                {/* ROW 2 */}
                <div className="card-fullgrid-container margin-top-20 ">
                    <Input
                        shrink={shrink}
                        label="Match Name"
                        type="text"
                        name="matchName"
                        value={matchName || ''}
                        onChange={this.handleInputChange.bind(this, 'matchName')}
                        placeholder="Enter "
                        disabled={disabled}
                    />
                </div>
                {!!addMatchStreamLink && (
                    <div className="grid-item ">
                        <Input
                            label="Stream Link"
                            type="text"
                            name="streamLink"
                            value={streamLink || ''}
                            onChange={this.handleInputChange.bind(this, 'streamLink')}
                            placeholder="Enter"
                        />
                    </div>
                )}
                {!!addMatchExpertAnalysis && (
                    <div className="grid-8-4-container margin-top-15">
                        <Input
                            label="Expert Analysis Youtube Video Id"
                            type="text"
                            name="expertAnalysisId"
                            value={expertAnalysisId || ''}
                            onChange={this.handleInputChange.bind(this, 'expertAnalysisId')}
                            placeholder="Enter"
                        />

                        {!!matchWrite && edit && (
                            <Button buttonText="Expert Team" onClick={this.handleOpenExpertModal} />
                        )}
                    </div>
                )}
                {/* row 3 */}
                {console.log('time', startTime)}
                <div className="card-grid-container nopadding margin-top-20">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DateTimePicker
                            margin="normal"
                            id="date-picker-dialog"
                            label="Date"
                            value={startTime}
                            minDate={edit && !this.allowEditBeforePublish.includes(matchStatus) ? null : new Date()}
                            // disabled={edit && !this.allowEditBeforePublish.includes(matchStatus)}
                            disabled={true}
                            invalidDateMessage={''}
                            onChange={this.handleInputChange.bind(this, 'startTime')}
                        />
                    </MuiPickersUtilsProvider>

                    {!!addMatchMapName && (
                        <div className="grid-item ">
                            <DropDown
                                menuItems={mapOptions}
                                label="Map Name"
                                value={mapName}
                                disabled={disabled}
                                placeholder="select"
                                onChange={this.handleInputChange.bind(this, 'mapName')}
                            />
                        </div>
                    )}
                </div>

                <div className="card-grid-container nopadding margin-top-20">
                    {!!addMatchRankForSorting && (
                        <div className="grid-item margin-top-20">
                            <Input
                                shrink={shrink}
                                label="Rank for Sorting"
                                placeholder="default 99; 1 ranked highest"
                                type="number"
                                name="rankForSorting"
                                value={rankForSorting || ''}
                                onChange={this.handleInputChange.bind(this, 'rankForSorting')}
                                disabled={false}
                            />
                        </div>
                    )}

                    <div className="grid-item  margin-top-20">
                        <DropDown
                            label="Match Mode"
                            type="text"
                            menuItems={matchModeOptions}
                            disabled={edit && !this.allowEditBeforePublish.includes(matchStatus)}
                            name="matchMode"
                            value={matchMode}
                            onChange={this.handleInputChange.bind(this, 'matchMode')}
                            placeholder="Select Match Mode"
                        />
                    </div>
                    <div className="grid-item margin-top-20">
                        <DropDown
                            label="Lineup Status"
                            type="text"
                            menuItems={lineupsOptions}
                            disabled={false}
                            name="lineupStatus"
                            value={lineupStatus}
                            onChange={this.handleInputChange.bind(this, 'lineupStatus')}
                            placeholder="Select Lineup Status"
                        />
                    </div>
                    {!!addMatchFantasyType && (
                        <div className="grid-item margin-top-20">
                            <DropDown
                                label="Fantasy Type"
                                type="text"
                                menuItems={FANTASY_TYPES_OPTIONS}
                                name="fantasyType"
                                value={fantasyType}
                                onChange={this.handleInputChange.bind(this, 'fantasyType')}
                                placeholder="Select Lineup Status"
                            />
                        </div>
                    )}
                    {fantasyType === 'SWITCH' && !!addMatchFantasyType && (
                        <div className="grid-item  margin-top-20">
                            <p>{`Maximum Teams for Switch: ${substitutionData?.teamsCountLimit || '-'}`}</p>
                            <p>{`Maximum Switches Allowed: ${substitutionData?.totalSubsAllowed || '-'}`}</p>
                            <p>{`Switch Fee: ${substitutionData?.subsFee?.join(', ') || '-'}`}</p>
                        </div>
                    )}
                    <div className="grid-item  margin-top-20">
                        <Input
                            label="fantasy scoring"
                            type="text"
                            disabled={true}
                            name="fantasyScoring"
                            value={fantasyScoring ? fantasyScoring.text || '' : ''}
                            shrink={shrink}
                        />
                    </div>
                </div>

                <div className="card-grid-container nopadding margin-top-20">
                    <div className="grid-item">
                        <Input
                            shrink={shrink}
                            label="Number of credits"
                            type="number"
                            name="numberOfCredits"
                            value={numberOfCredits || ''}
                            onChange={this.handleInputChange.bind(this, 'numberOfCredits')}
                            placeholder="defaults to 50 "
                            disabled={edit && !this.allowEditBeforePublish.includes(matchStatus)}
                        />
                    </div>
                    <div className="grid-item">
                        <Input
                            shrink={shrink}
                            label="Max Fantasy Team Size"
                            type="number"
                            name="maxTeamSize"
                            value={maxTeamSize || ''}
                            onChange={this.handleInputChange.bind(this, 'maxTeamSize')}
                            placeholder="defaults to 6 "
                            disabled={edit && !this.allowEditBeforePublish.includes(matchStatus)}
                        />
                    </div>
                    <div className="grid-item">
                        <Input
                            shrink={shrink}
                            label="Max Players Per Squad"
                            type="number"
                            name="maxPlayersPerSquad"
                            value={maxPlayersPerSquad || ''}
                            onChange={this.handleInputChange.bind(this, 'maxPlayersPerSquad')}
                            placeholder="defaults to 2 "
                            disabled={edit && !this.allowEditBeforePublish.includes(matchStatus)}
                        />
                    </div>
                    {!!game?.id && game.gameUniqueCode === GAME_UNIQUE_CODE.cricket && (
                        <div className="grid-item">
                            <DropDown
                                label="Cricket Format"
                                type="text"
                                menuItems={CRICKET_FORMAT_OPTIONS}
                                name="cricketFormat"
                                value={cricketFormat}
                                onChange={this.handleInputChange.bind(this, 'cricketFormat')}
                                placeholder="Select Cricket Format"
                            />
                        </div>
                    )}
                    {!!addMatchEnableViceCaptian && (
                        <div className="grid-item margin-top-20">
                            <FormControlLabel
                                label="Enable Vice Captain"
                                control={
                                    <Checkbox
                                        checked={!!isNewRoleActivated}
                                        color="primary"
                                        name="isNewRoleActivated"
                                        onChange={(e) => this.handleChangeState({isNewRoleActivated: e.target.checked})}
                                    />
                                }
                            />
                        </div>
                    )}
                    <div className="grid-item margin-top-20">
                        <FormControlLabel
                            label="Live Coverage"
                            control={
                                <Checkbox
                                    checked={!!liveCoverage}
                                    color="primary"
                                    name="liveCoverage"
                                    onChange={(e) => this.handleChangeState({liveCoverage: e.target.checked})}
                                />
                            }
                        />
                        {!!addMatchGlacierSeries && (
                            <FormControlLabel
                                label="Glacier Series"
                                control={
                                    <Checkbox
                                        checked={!!isGlacierSeries}
                                        color="primary"
                                        name="isGlacierSeries"
                                        onChange={(e) => this.handleChangeState({isGlacierSeries: e.target.checked})}
                                    />
                                }
                            />
                        )}
                    </div>
                </div>
                {!!addMatchUploadImage && (
                    <div className="grid-8-4-container margin-top-15">
                        <Input
                            label="Match image for home screen"
                            type="text"
                            value={matchImage || ''}
                            placeholder="Enter"
                            disabled={true}
                        />
                        <ImageInput
                            label="Upload image"
                            type="file"
                            uploadType="button"
                            onChange={this.handleUploadMatchImage}
                        />
                    </div>
                )}

                {/*<div className="card-grid-container nopadding margin-top-20">
                    <div className="grid-item align-right">
                        <DropDown
                            label="Attribute 1"
                            type="text"
                            menuItems={attribute1Options}
                            name="attribute1"
                            value={attribute1}
                            onChange={this.handleInputChange.bind(this, "attribute1")}
                            placeholder="Select"
                            disabled={false} />
                    </div>

                    <div className="grid-item">
                        <Input
                            label="Attribute 1 Value"
                            type="text"
                            name="attribute1Val"
                            value={attribute1Val}
                            onChange={this.handleInputChange.bind(this, "attribute1Val")}
                            placeholder="Select"
                            disabled={false} />
                    </div>
                </div>
                <div className="card-grid-container nopadding margin-top-20">
                    <div className="grid-item align-right">
                        <DropDown
                            label="Attribute 2"
                            type="text"
                            menuItems={attribute2Options}
                            name="attribute2"
                            value={attribute2}
                            onChange={this.handleInputChange.bind(this, "attribute2")}
                            placeholder="Select"
                            disabled={false} />
                    </div>

                    <div className="grid-item">
                        <Input
                            label="Attribute 2 Value"
                            type="text"
                            name="attribute2Val"
                            value={attribute2Val}
                            onChange={this.handleInputChange.bind(this, "attribute2Val")}
                            placeholder="Select"
                            disabled={false} />
                    </div>
        </div> */}
            </div>
        );
    }

    /**
     * onInput change handler for autosuggest
     * @param {string} field field name selected
     * @param {*} event value entered in field
     */
    onInputChange(field, event) {
        const {edit, game} = this.state;
        const {login, match} = this.props;
        if (
            event !== undefined &&
            event !== '' &&
            event !== null &&
            event.target.value !== undefined &&
            event.target.value !== '' &&
            event.target.value !== null
        ) {
            let string = event.target.value;
            if (string.length > 0 && !edit) {
                let squad = {searchString: string};
                this.props.getSquad(squad, game?.id, login.userDetails.data.accessToken);
            } else if (string.length > 0 && edit) {
                let squad = {matchId: match.editMatch.id, searchString: string};
                this.props.getSquad(squad, game?.id, login.userDetails.data.accessToken);
            }
            this.setState({nextClick: false});
        }
    }

    onTOInputChange(field, event) {
        const {login} = this.props;
        if (
            event !== undefined &&
            event !== '' &&
            event !== null &&
            event.target.value !== undefined &&
            event.target.value !== '' &&
            event.target.value !== null
        ) {
            let string = event.target.value;

            if (string.length > 0) {
                const data = {
                    pageNo: 1,
                    pageSize: 30,
                    gameId: this.state.game?.id,
                    status: [TOURNAMENT_CONST.DRAFT, TOURNAMENT_CONST.LIVE, TOURNAMENT_CONST.UPCOMING],
                    searchString: string,
                };
                this.props.listAllTournaments(data, login.userDetails.data.accessToken);
            }
        }
    }

    handleSearchBoosterConfigs = (e, name) => {
        if (e?.target?.value && name) this.handleFetchBoosterConfigs({name});
    };

    handleFetchBoosterConfigs = debounce(({name}) => {
        const {login} = this.props;
        this.props.fetchBoosterConfigs(
            {
                name,
                gameId: this.state.game?.id,
                status: 'ACTIVE',
            },
            login?.userDetails?.data?.accessToken,
        );
    });

    handleSearchMultiplierConfigs = (e, name) => {
        if (e?.target?.value && name) this.handleFetchMultiplierConfigs({name});
    };

    handleFetchMultiplierConfigs = debounce(({name}) => {
        const {login} = this.props;
        this.props.fetchMultiplierConfigs(
            {
                name,
                gameId: this.state.game?.id,
                status: 'ACTIVE',
            },
            login?.userDetails?.data?.accessToken,
        );
    });

    handleAddPlayerInMatch = (squad) => {
        const {match} = this.props;
        this.setState({addPlayer: {matchId: match.editMatch.id, squadId: squad.id}});
    };

    handleAddSquadAndPlayer = () => {
        const {match} = this.props;
        this.setState({addPlayer: {matchId: match.editMatch.id}});
    };

    handleSubstitutePlayerInMatch = (squad) => {
        const {match} = this.props;
        const selectedPlayers = squad.players.filter((player) => player.status === 'ACTIVE');
        this.setState({substitutePlayers: {matchId: match.editMatch.id, squadId: squad.id, selectedPlayers}});
    };

    // handlePlayerFreezeUnfreezeInMatch = (squad) => {
    //     const { match } = this.props;
    //     const selectedPlayers = squad.players.filter((player) => player.status === "ACTIVE");
    //     this.setState({ freezeUnfreezePlayer: { matchId: match.editMatch.id, squadId: squad.id, squadName: squad.squadName, selectedPlayers } });
    // };

    /**
     * Ui for tab b
     */
    renderTabB() {
        const {squadOptions, squadName, edit, matchStatus} = this.state;
        const status = matchStatus;

        const {matchWrite, matchAdvanceWrite, addMatchAddSquadBtn, addMatchAddSquadAndPlayerBtn} =
            this.props?.login?.permissions || {};

        return (
            <section className={styles.squadsAndPlayersTab}>
                {/* add squad and player section */}
                <div className={styles.addSquadAndPlayerSection}>
                    {!!matchWrite && (
                        <AutoCompleteField
                            disabled={edit && !this.allowEditBeforePublish.includes(matchStatus)}
                            label="Squad"
                            onChange={this.handleInputChange.bind(this, 'squadName')}
                            options={squadOptions}
                            value={squadName}
                            inputChange={this.onInputChange.bind(this, 'searchSquad')}
                        />
                    )}
                    <div className={styles.addSquadButtonGroup}>
                        {!!matchWrite &&
                            (!!this.allowEditBeforePublish.includes(status) || !edit) &&
                            !!addMatchAddSquadBtn && (
                                <Button
                                    buttonText="Add Squad"
                                    className={styles.addSquadButton}
                                    onClick={this.addSquad.bind(this)}
                                />
                            )}
                        {!!matchAdvanceWrite && !!addMatchAddSquadAndPlayerBtn && (
                            <Button
                                buttonText="Add Squad & Player"
                                className={styles.addSquadAndPlayerButton}
                                onClick={this.handleAddSquadAndPlayer}
                            />
                        )}
                    </div>
                </div>
                {/* display squad and player section */}
                <div className={styles.rosterSection}>{this.renderSelectedSquadsAndPlayers()}</div>
            </section>
            // <div>
            //     <div className="card-fullgrid-container nopadding margin-top-20">
            //         {/* row 1 */}
            //         <div className="card-grid-container">
            //             {!!matchWrite && <div className="grid-item ">
            //                 <AutoCompleteField
            //                     disabled={edit && (!this.allowEditBeforePublish.includes(matchStatus))}
            //                     label="Squad"
            //                     onChange={this.handleInputChange.bind(this, "squadName")}
            //                     options={squadOptions}
            //                     value={squadName}
            //                     inputChange={this.onInputChange.bind(this, "searchSquad")}
            //                 />
            //             </div>}
            //             <div className="grid-item align-right margin-top-20 ">
            //                 {
            //                     !!matchWrite && !!this.allowEditBeforePublish.includes(status) || !edit ?
            //                         <Button className="mr-5" buttonText="Add Squad"
            //                             onClick={this.addSquad.bind(this)} /> : null
            //                 }
            //                 {!!matchAdvanceWrite &&
            //                     <Button buttonText="Add Squad & Player" onClick={this.handleAddSquadAndPlayer} />}
            //             </div>
            //         </div>

            //         {/* big row 2 */}
            //         {this.displaySelectedSquads()}
            //     </div>
            // </div>
        );
    }

    /**
     * Ui for tab C
     */
    renderTabC() {
        const {edit, matchStatus, contests, existMatchId} = this.state;
        const status = matchStatus;
        const {matchWrite, contestWrite} = this.props?.login?.permissions || {};

        return (
            <div className="card-fullgrid-container">
                <div className="card-grid-container margin-top-20">
                    {!!contestWrite &&
                    (!!this.allowEditBeforePublish.includes(status) || status === 'UPCOMING' || !edit) ? (
                        <div className="justify pr-15px">
                            <Button buttonText="Add New Contest" onClick={this.addNewContest.bind(this)} />
                            {(!!this.allowEditBeforePublish.includes(status) || !edit) && <strong>Or</strong>}
                        </div>
                    ) : null}
                    {!!matchWrite && (!!this.allowEditBeforePublish.includes(status) || !edit) && (
                        <form className="card-grid-container" onSubmit={this.handleSubmitCopyContests}>
                            <Input
                                label="Existing Match Id"
                                type="text"
                                name="existMatchId"
                                required
                                value={existMatchId || ''}
                                placeholder="Existing Match Id"
                                onChange={this.handleInputChange.bind(this, 'existMatchId')}
                            />
                            <Button type="submit" buttonText="Add Contests" />
                        </form>
                    )}
                </div>
                <div className="margin-top-20">
                    {edit ? (
                        <table>
                            <thead>
                                {contests.length === 0 ? null : (
                                    <tr>
                                        <th className="basic-headers">Contest ID</th>
                                        <th className="basic-headers">Contest Name</th>
                                        <th className="basic-headers">Prize Pool Type</th>
                                        <th className="basic-headers">Slots</th>
                                        <th className="basic-headers">Entry Fee</th>
                                        <th></th>
                                    </tr>
                                )}
                            </thead>
                            <tbody>{this.displayContests()}</tbody>
                        </table>
                    ) : null}
                </div>
            </div>
        );
    }

    /**
     * display contest details
     */
    displayContests() {
        const status = this.state.matchStatus;
        const {edit, contests} = this.state;
        const {contestWrite} = this.props?.login?.permissions || {};
        return contests.map((contest, index) => {
            return (
                <tr key={'contest' + index}>
                    <td>{contest.id}</td>
                    <td>{contest.contestTypeName}</td>
                    <td>{contest.prizePoolData.type}</td>
                    <td>{contest.totalSlots}</td>
                    <td>{contest.entryFeePerTeam}</td>

                    {!!contestWrite &&
                    (!!this.allowEditBeforePublish.includes(status) || status === 'UPCOMING' || !edit) ? (
                        <td className="align-right cursor " onClick={this.onDeleteContest.bind(this, contest.id)}>
                            <img src={Remove} alt="" />
                        </td>
                    ) : null}
                </tr>
            );
        });
    }

    /**
     * api call to delete contest
     * @param {string} id match Id to be deleted
     */
    onDeleteContest(id) {
        const {login, client} = this.props;
        let contest = {};
        contest.contestId = id;
        this.props.deleteContest(contest, login.userDetails.data.accessToken, client.selectedClient);
    }

    /**
     * add squad to match
     */
    addSquad() {
        const {squadOptions, squadName, game} = this.state;

        const {login} = this.props;

        if (squadName !== null) {
            let squad = {};
            squad.id = squadName.value;
            this.props.getSquad(squad, game?.id, login.userDetails.data.accessToken);
            let value = squadName;

            let squads = squadOptions;
            let index = squads.findIndex((x) => x.value === value.value);
            if (index !== -1) {
                squads.splice(index, 1);
            }

            this.setState({
                squadOptions: squads,
                squadName: null,
                nextClick: true,
            });
        } else {
            this.setState({errorMessage: 'Please Select a Squad'});
        }
    }

    /**
     * remove squad from selected squad list
     * @param {*} name name of squad to be deleted
     * @param {*} id ID of squad to be deleted
     */
    removeSquad(name, id) {
        const {selectedSquads, squadOptions, squadOdds} = this.state;
        let newSquad = {title: name, value: id};
        let squads = squadOptions;
        squads.push(newSquad);

        let squadList = selectedSquads;
        let index = selectedSquads.findIndex((x) => x.id === id);
        if (index !== -1) {
            squadList.splice(index, 1);
        }

        delete squadOdds?.[id];

        this.setState({
            squadOptions: squads,
            selectedSquads: squadList,
        });
    }

    handlePlayerLineupChange = (player, value) => {
        this.setState({
            playerLineupStatus: {...this.state.playerLineupStatus, [player.id]: value},
        });
    };

    handlePlayerFreeze = (_player) => {
        const {match, freezeUnfreezePlayer, login} = this.props;
        const data = {matchId: match?.getMatch?.data?.id, playerId: _player.id, switchStatus: 'freeze'};
        this.setState({loading: true});
        freezeUnfreezePlayer(data, login.userDetails.data.accessToken);
    };

    handlePlayerUnfreeze = (_player) => {
        const {match, freezeUnfreezePlayer, login} = this.props;
        const data = {matchId: match?.getMatch?.data?.id, playerId: _player.id, switchStatus: 'unfreeze'};
        this.setState({loading: true});
        freezeUnfreezePlayer(data, login.userDetails.data.accessToken);
    };

    renderSelectedSquadsAndPlayers() {
        const {matchAdvanceWrite, matchWrite, addMatchAddSquadBtn, addMatchSubstitutePlayer, addMatchAddPlayer} =
            this.props?.login?.permissions || {};
        const {selectedSquads, matchStatus, edit, squadOdds, game, playerLineupStatus, lineupOrder} = this.state;
        const status = matchStatus;
        const switchGamesList = [
            GameUniqueCodeLabels.CRICKET,
            GameUniqueCodeLabels.BASKETBALL,
            GameUniqueCodeLabels.FOOTBALL,
        ];

        if (selectedSquads && selectedSquads.length > 0) {
            return selectedSquads.map((squad, squadIndex) => {
                return (
                    <section key={`${squad?.id}-${squadIndex}`} className={styles.roster}>
                        {/* squad */}
                        <div className={styles.squadWrapper}>
                            <span className={styles.squadDetails}>
                                <p className={styles.header}>Squad</p>
                                <p className={styles.squadName}>{squad?.squadName}</p>
                            </span>
                            {!!game?.id && this.allowSquadOdds.includes(game?.gameUniqueCode) && (
                                <Input
                                    className={styles.squadOddsInputField}
                                    label="Odds Value"
                                    type="number"
                                    value={squadOdds?.[squad?.id] || ''}
                                    disabled={edit && !this.allowEditBeforePublish.includes(matchStatus)}
                                    onChange={(value) =>
                                        this.setState({squadOdds: {...squadOdds, [squad?.id]: parseFloat(value)}})
                                    }
                                />
                            )}
                            <div className={styles.buttonGroup}>
                                {!!matchAdvanceWrite && !!addMatchAddPlayer && (
                                    <IconButton
                                        className={styles.squadIconButton}
                                        onClick={() => this.handleAddPlayerInMatch(squad)}
                                    >
                                        <AddCircleOutline titleAccess="Add Player in Match" className={styles.icon} />
                                    </IconButton>
                                )}
                                {!!matchAdvanceWrite &&
                                    status === MATCH_STATUS_CONST.LIVE_NOW &&
                                    !!addMatchSubstitutePlayer && (
                                        <IconButton
                                            className={styles.squadIconButton}
                                            onClick={() => this.handleSubstitutePlayerInMatch(squad)}
                                        >
                                            <CompareArrowsOutlined
                                                titleAccess="Substitute Player in Match"
                                                className={styles.icon}
                                            />
                                        </IconButton>
                                    )}
                                {/* {!!matchAdvanceWrite && game.gameUniqueCode === GAME_UNIQUE_CODE.cricket && (<IconButton className={styles.squadIconButton} onClick={() => this.handlePlayerFreezeUnfreezeInMatch(squad)}>
                                        <AcUnit titleAccess='Freeze-unfreeze Player in Match' className={styles.icon}/>    
                                    </IconButton>)} */}
                                {!!matchWrite &&
                                    !!addMatchAddSquadBtn &&
                                    (!!this.allowEditBeforePublish.includes(status) || !edit) && (
                                        <IconButton
                                            className={styles.squadIconButton}
                                            onClick={this.removeSquad.bind(this, squad.squadName, squad.id)}
                                        >
                                            <RemoveCircle titleAccess="Remove squad in Match" className={styles.icon} />
                                        </IconButton>
                                    )}
                            </div>
                        </div>
                        <span className={styles.squadLineupDivider}></span>
                        {/* players */}
                        <div className={styles.lineupWrapper}>
                            {squad?.players?.length > 0 &&
                                squad.players
                                    .filter((player) => player.status === 'ACTIVE')
                                    .map((player, index) => (
                                        <div className={styles.player} key={`${player.id}-${index}`}>
                                            <div className={styles.playerDetail}>
                                                <p className={styles.header}>{`Player ${index + 1}`}</p>
                                                <span>
                                                    {player.image && (
                                                        <img
                                                            className={styles.playerImg}
                                                            width="50px"
                                                            src={player.image}
                                                            alt="Player Image"
                                                        />
                                                    )}
                                                    <span className={styles.playerDescription}>
                                                        <p
                                                            className={styles.playerName}
                                                            role="link"
                                                            onClick={() => this.setState({playerStatsDetail: player})}
                                                        >
                                                            {player.pubgName}
                                                        </p>
                                                        <span className={styles.playerDetails}>
                                                            {!!player?.positionInSquad &&
                                                                status !== MATCH_STATUS_CONST.DRAFT && (
                                                                    <p
                                                                        className={styles.playerPosition}
                                                                    >{`Position: ${player?.positionInSquad}`}</p>
                                                                )}
                                                            {!!player?.freezeStatus &&
                                                                status !== MATCH_STATUS_CONST.DRAFT && (
                                                                    <p
                                                                        className={styles.playerFreezeStatus}
                                                                    >{`Freeze Status: ${player?.freezeStatus}`}</p>
                                                                )}
                                                        </span>
                                                    </span>
                                                </span>
                                            </div>
                                            {!!switchGamesList.includes(game.gameUniqueCode) &&
                                                status === MATCH_STATUS_CONST.LIVE_NOW &&
                                                player?.freezeStatus === 'ACTIVE' && (
                                                    <Button
                                                        buttonText="Freeze"
                                                        onClick={() => this.handlePlayerFreeze(player)}
                                                    />
                                                )}
                                            {!!switchGamesList.includes(game.gameUniqueCode) &&
                                                status === MATCH_STATUS_CONST.LIVE_NOW &&
                                                player?.freezeStatus === 'FREEZED' && (
                                                    <Button
                                                        buttonText="Unfreeze"
                                                        onClick={() => this.handlePlayerUnfreeze(player)}
                                                    />
                                                )}
                                            {!!switchGamesList.includes(game.gameUniqueCode) &&
                                                status !== MATCH_STATUS_CONST.DRAFT && (
                                                    <DropDown
                                                        className={styles.lineupDropdownField}
                                                        menuItems={
                                                            this.state.lineupStatus === 'ANNOUNCED'
                                                                ? MATCH_PLAYER_AFTER_LINEUP_ANNOUNCED_OPTIONS
                                                                : MATCH_PLAYER_BEFORE_LINEUP_ANNOUNCED_OPTIONS
                                                        }
                                                        label="Lineup Status"
                                                        value={playerLineupStatus?.[player?.id]}
                                                        placeholder="select"
                                                        onChange={this.handlePlayerLineupChange.bind(this, player)}
                                                    />
                                                )}
                                            {game.gameUniqueCode === GAME_UNIQUE_CODE.cricket &&
                                                status !== MATCH_STATUS_CONST.DRAFT && (
                                                    <Input
                                                        className={styles.lineupOrderInputField}
                                                        label="Lineup Order"
                                                        type="number"
                                                        name="lineupOrder"
                                                        value={lineupOrder?.[player?.id]}
                                                        onChange={(value) =>
                                                            this.setState({
                                                                lineupOrder: {
                                                                    ...lineupOrder,
                                                                    [player?.id]: parseInt(value),
                                                                },
                                                            })
                                                        }
                                                    />
                                                )}
                                            {!!player.credits && (
                                                <div className={styles.playerCredit}>
                                                    <p className={styles.header}>Credits</p>
                                                    <p className={styles.credit}>{player.credits}</p>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                        </div>
                    </section>
                );
            });
        }
    }

    /**
     * map and display selected squads
     */
    // displaySelectedSquads() {
    //     const { selectedSquads, matchStatus, edit, squadOdds, game, playerLineupStatus } = this.state;
    //     const status = matchStatus;

    //     const { matchAdvanceWrite, matchWrite } = this.props?.login?.permissions || {};

    //     if (selectedSquads && selectedSquads.length > 0) {
    //         return selectedSquads.map((squad, squadIndex) => {
    //             let players = squad.players ? squad.players.filter((player) => {
    //                 if (player.status)
    //                     return player.status === "ACTIVE"
    //                 else
    //                     return player
    //             }) : {};

    //             return (
    //                 <div className="card-grid-container" key={squad?.id + squadIndex}>
    //                     {/* row 1 */}
    //                     <div className="grid-item  ">
    //                         <div className="margin-top-20">
    //                             <div className="basic-headers">Squad</div>
    //                             <div className="basiic-data">{squad.squadName}</div>
    //                         </div>
    //                     </div>
    //                     <div className="grid-2-container align-right margin-top-20">
    //                         <div>
    //                             {!!game?.id && this.allowSquadOdds.includes(game?.gameUniqueCode) &&
    //                                 <Input label="Odd Value" type="number" value={squadOdds?.[squad?.id] || ''}
    //                                     placeholder="Enter number"
    //                                     disabled={edit && !this.allowEditBeforePublish.includes(matchStatus)}
    //                                     onChange={(value) => this.setState({
    //                                         squadOdds: { ...squadOdds, [squad?.id]: parseFloat(value) }
    //                                     })} />}
    //                         </div>

    //                         <div className="align-right">
    //                             {!!matchAdvanceWrite && <AddCircleOutline titleAccess="Add player in match"
    //                                 className="clickable-icon mr-5 fs-28px"
    //                                 onClick={() => this.handleAddPlayerInMatch(squad)} color="primary" />}
    //                             {!!matchAdvanceWrite && status === MATCH_STATUS_CONST.LIVE_NOW &&
    //                                 <CompareArrowsOutlined titleAccess='Substitute player in a squad'
    //                                     className='clickable-icon mr-5 fs-28px' color='primary'
    //                                     onClick={() => this.handleSubstitutePlayerInMatch(squad)} />}
    //                             {!!matchAdvanceWrite && game.gameUniqueCode === GAME_UNIQUE_CODE.cricket && (
    //                                 <AcUnit
    //                                     className='clickable-icon mr-5 fs-28px'
    //                                     color='primary'
    //                                     onClick={() => this.handlePlayerFreezeUnfreezeInMatch(squad)}
    //                                     titleAccess='Player free-unfreeze in match'
    //                                 />
    //                             )}
    //                             {
    //                                 !!matchWrite && (!!this.allowEditBeforePublish.includes(status) || !edit) ?
    //                                     <span className="margin-top-20 cursor-pointer d-inline-block"
    //                                         onClick={this.removeSquad.bind(this, squad.squadName, squad.id)}>
    //                                         <img className="cursor" src={Remove} alt="" />
    //                                     </span> : null}
    //                         </div>
    //                     </div>
    //                     {
    //                         players.map((player, index) => {
    //                             return (
    //                                 <React.Fragment key={'player' + index}>
    //                                     <div className="grid-item margin-top-20 ">
    //                                         <div className="basic-headers">
    //                                             Player {index + 1}
    //                                         </div>
    //                                         <div className="basiic-data player-image-name">
    //                                             {player.image &&
    //                                                 <img className="mr-10" width="50px" src={player.image}
    //                                                     alt="Player Image" />}
    //                                             <span className="text-yellow cursor"
    //                                                 onClick={() => this.setState({ playerStatsDetail: player })}>
    //                                                 {player.pubgName}</span>
    //                                         </div>
    //                                     </div>
    //                                     <div className="grid-3-container">

    //                                         <div>
    //                                             <div className="basic-headers">Credits</div>
    //                                             <div className="basiic-data player-image-name">{player.credits}</div>
    //                                         </div>
    //                                         <div>
    //                                             <DropDown
    //                                                 menuItems={this.state.lineupStatus==="ANNOUNCED"?MATCH_PLAYER_AFTER_LINEUP_ANNOUNCED_OPTIONS : MATCH_PLAYER_BEFORE_LINEUP_ANNOUNCED_OPTIONS}
    //                                                 label="playerLineupStatus"
    //                                                 value={playerLineupStatus?.[player?.id]}
    //                                                 placeholder="select"
    //                                                 onChange={this.handlePlayerLineupChange.bind(this, player)}
    //                                             />
    //                                         </div>
    //                                     </div>
    //                                 </React.Fragment>
    //                             )
    //                         })
    //                     }
    //                 </div>
    //             )

    //         })
    //     }

    // }

    /**
     * api call to publish match
     */
    onMatchPublish() {
        const {login, match, client} = this.props;
        this.setState({loading: true});
        this.props.publishMatch(match.editMatch.id, login.userDetails.data.accessToken, client.selectedClient);
    }

    /**
     * open delete match modal
     */
    onDeleteClick() {
        this.setState({open: true, modalName: 'delete'});
    }

    /**
     * api call to delete match
     */
    onDeleteMatch() {
        const {login, match, client} = this.props;

        let delMatch = match.editMatch.id;
        let data = {
            matchId: delMatch,
        };
        this.props.deleteMatch(data, login.userDetails.data.accessToken, client.selectedClient);
        this.setState({open: false, loading: true});
    }

    /**
     * on click of back button navigate to match table
     */
    onBackClick() {
        const {history} = this.props;

        history.push('/matches');
    }

    /**
     * display publish modal if more than 1 contest is present
     */
    publishModal() {
        const {contests} = this.state;
        if (contests.length >= 1) {
            this.setState({open: true});
        } else {
            this.setState({
                errorMessage: 'Please select mimimum 1 contest before publishing',
            });
        }
    }

    onPressMarkLive() {
        const {status} = this.props.match.getMatch.data;

        if (status === 'UPCOMING' || status === 'LOCKED' || status === 'ENDED') {
            this.setState({markLiveModalVisible: true});
        } else {
            this.setState({
                errorMessage: 'match must be UPCOMING, LOCKED or ENDED',
            });
        }
    }

    onMatchMarkLiveConfirm() {
        const {match, client} = this.props;
        // const { id, status } = this.props.match.getMatch.data;
        if (match.getMatch.data.status === 'ENDED') {
            this.props.updateMatchStatusLive(
                match.getMatch.data.id,
                this.props.login.userDetails.data.accessToken,
                client.selectedClient,
            );
        } else {
            this.props.markMatchLive(
                match.getMatch.data.id,
                this.props.login.userDetails.data.accessToken,
                client.selectedClient,
            );
        }
        this.setState({markLiveModalVisible: false});
    }

    handleMarkComingSoon = (action) => {
        const {id} = this.props.match.getMatch.data;
        const {selectedClient} = this.props.client;
        if (action === CONFIRMATION) {
            this.setState({errorMessage: 'Please double click if you want to mark match as a coming soon'});
        } else {
            this.props.comingSoonMatch({matchId: id}, this.props.login.userDetails.data.accessToken, selectedClient);
        }
    };

    handleUpdateMatchTypeLinkOnly = (action) => {
        const {id} = this.props.match.getMatch.data;
        const {selectedClient} = this.props.client;
        if (action === CONFIRMATION) {
            this.setState({errorMessage: 'Please double click if you want to mark match as a LINKONLY'});
        } else {
            this.props.updateMatchType(
                {
                    matchId: id,
                    matchType: MATCH_TYPE_CONST.LINKONLY,
                },
                this.props.login.userDetails.data.accessToken,
                selectedClient,
            );
        }
    };

    handleMatchDraftToLIve = (action) => {
        const {id} = this.props.match.getMatch.data;
        const {selectedClient} = this.props.client;
        if (action === CONFIRMATION) {
            this.setState({errorMessage: 'Please double click if you want to update match status from draft to live'});
        } else {
            this.props.matchDraftToLive({matchId: id}, this.props.login.userDetails.data.accessToken, selectedClient);
        }
    };

    onCloseConfirmationModal() {
        this.setState({markLiveModalVisible: false});
    }

    handleOnCloseEstarModal = () => {
        this.setState({estarModal: undefined});
    };

    handleClickEstarAction = (status) => {
        let estarModal = {
            text: 'Are you sure you want to make this match as a ESTAR match',
            action: status,
        };
        if (status === REMOVE) {
            estarModal.text = 'Are you sure you want to remove this match as a ESTAR match';
        }
        this.setState({estarModal});
    };

    handleConfirmEstar = () => {
        const {id} = this.props.match.getMatch.data;
        const {selectedClient} = this.props.client;
        let {estarModal} = this.state;
        let data = {matchId: id, matchTag: APP_USER_TYPE.ESTAR};
        if (estarModal.action === REMOVE) data.removeMatchTag = true;
        this.props.updateMatchTag(data, this.props.login.userDetails.data.accessToken, selectedClient);
    };

    handleClickMarkMatchFRButton = (action) => {
        const {id} = this.props.match.getMatch.data;
        let markFrMatch = {matchId: id};
        if (action === REMOVE) {
            markFrMatch.removeMatchFR = true;
        }
        this.setState({markFrMatch});
    };

    handleClickFetchPlayerStats = () => {
        const {matchId} = this.props.match.getMatch.data;
        const {selectedClient} = this.props.client;
        this.props.fetchPlayerStatsForMatch(matchId, this.props.login.userDetails.data.accessToken, selectedClient);
    };

    render() {
        const {
            open,
            disabled,
            modalName,
            noOfContests,
            noOfPlayer,
            noOfSquads,
            game,
            loading,
            errorMessage,
            edit,
            disableTab3,
            markLiveModalVisible,
            matchStatus,
            expertTeamModal,
            addPlayer,
            estarModal,
            matchTags,
            matchType,
            substitutePlayers,
            createdByGlacier,
            playerStatsDetail,
            markFrMatch,
            isAllSuperMatch,
            fantasyResultFile,
        } = this.state;
        let title = edit ? 'Edit Match Data' : 'Add Match Data';
        const {history, match} = this.props;

        const {
            matchWrite,
            matchAdvanceWrite,
            addMatchMakeEasterMatch,
            addMatchMarkComingSoon,
            addMatchMarkDraftToLive,
        } = this.props?.login?.permissions || {};

        const isMatchEstar = !!matchTags && matchTags.includes(APP_USER_TYPE.ESTAR);

        return (
            <DetailLayout
                location="addMatch"
                history={history}
                centerTitle={title}
                onSave={matchWrite ? this.onSave.bind(this) : undefined}
                onPublish={matchWrite ? this.publishModal.bind(this) : undefined}
                onMarkLive={matchWrite ? this.onPressMarkLive.bind(this) : undefined}
                editPage={edit}
                id={edit ? match.editMatch.id : null}
                hideAll={disabled}
                onDeleteClick={matchWrite ? this.onDeleteClick.bind(this) : undefined}
                onBackClick={this.onBackClick.bind(this)}
                upcoming={matchStatus}
            >
                <Snackbar
                    open={!!errorMessage}
                    message={errorMessage}
                    onClose={() => this.setState({errorMessage: ''})}
                />

                <LoadingModal open={loading} />

                {!!expertTeamModal && (
                    <ExpertTeamListModal
                        open={!!expertTeamModal}
                        handleClose={() => this.setState({expertTeamModal: false})}
                    />
                )}
                {!!addPlayer && (
                    <AddPlayerInMatchModal
                        open={!!addPlayer}
                        addPlayer={addPlayer}
                        handleClose={() => this.setState({addPlayer: false})}
                    />
                )}
                {!!substitutePlayers && (
                    <SubstitutePlayerInMatchModal
                        open={!!substitutePlayers}
                        substitutePlayers={substitutePlayers}
                        handleClose={() => this.setState({substitutePlayers: null})}
                    />
                )}
                {/* {!!freezeUnfreezePlayer && <PlayerFreezeUnfreezeModal open={!!freezeUnfreezePlayer} playerFreezeUnfreeze={freezeUnfreezePlayer} handleClose={() => this.setState({freezeUnfreezePlayer: null})}/>} */}
                {!!playerStatsDetail && (
                    <PlayerStatsModal
                        open={!!playerStatsDetail}
                        playerDetails={playerStatsDetail}
                        handleClose={() => this.setState({playerStatsDetail: null})}
                    />
                )}

                {!!estarModal && (
                    <ConfirmationModal2
                        open={!!estarModal}
                        handleClose={this.handleOnCloseEstarModal}
                        confirmationText={estarModal.text}
                        handleOnConfirm={this.handleConfirmEstar}
                    />
                )}

                {!!markFrMatch && (
                    <MarkMatchFRModal
                        open={!!markFrMatch}
                        handleClose={() => this.setState({markFrMatch: null})}
                        details={markFrMatch}
                    />
                )}

                <Modal open={open}>
                    {modalName === 'delete' ? (
                        <div className="container modal-top-margin">
                            <Card>
                                <div className="disp-flex">
                                    <div className="modal-left-header">Delete Match</div>
                                    <div onClick={() => this.setState({open: false})}>
                                        <img src={Close} alt="" className="cursor" />
                                    </div>
                                </div>
                                <div className="align-center equalcontainer-grids">
                                    <div />
                                    <div>
                                        <div className="disp-flex align-center margin-top-30">
                                            <div className="margin-right-16">
                                                <Button
                                                    buttonText="Delete Match"
                                                    onClick={this.onDeleteMatch.bind(this)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    ) : (
                        <div className="container modal-top-margin">
                            <Card>
                                <div className="disp-flex">
                                    <div className="modal-left-header">Publish Match</div>
                                    <div onClick={() => this.setState({open: false})}>
                                        <img src={Close} alt="" className="cursor" />
                                    </div>
                                </div>
                                <div className="align-center equalcontainer-grids">
                                    <div className="disp-flex align-left align-top">
                                        <div className="margin-right-16">
                                            <div className="basic-headers">Players</div>
                                            <div className="basiic-data">
                                                <div>{noOfPlayer}</div>
                                            </div>
                                        </div>
                                        <div className="margin-right-16">
                                            <div className="basic-headers">Squads</div>
                                            <div className="basiic-data">
                                                <div>{noOfSquads}</div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="basic-headers">Contests</div>
                                            <div className="basiic-data">
                                                <div>{noOfContests}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="modal-left-header align-left">
                                            Make sure all players, Squads & Contests are Onboarded Before You Publish
                                            This Match
                                        </div>
                                        <div className="margin-top-20" />
                                        <div className="disp-flex align-center margin-top-30">
                                            <Button
                                                buttonText="Publish Match"
                                                onClick={this.onMatchPublish.bind(this)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    )}
                </Modal>

                <div className="detail-container margin-top-20">
                    <div className="grid-item">
                        {edit ? (
                            <div className="text-grey">
                                Make sure Summary, Squads & Players and Contests are populated before publishing the
                                match.
                            </div>
                        ) : (
                            <div className="text-grey">
                                Note: <br />
                                Please save the Match before Adding Contests
                            </div>
                        )}
                    </div>
                    <div className="grid-item">
                        <Card>
                            <div>
                                <div className="card-single-grid-container">
                                    <Tabs
                                        tab1Label="Summary"
                                        tab2Label="Squads and Players"
                                        tab3Label="Contests"
                                        panelA={this.renderTabA.bind(this)}
                                        panelB={this.renderTabB.bind(this)}
                                        panelC={this.renderTabC.bind(this)}
                                        disableTab1={false}
                                        disableTab2={false}
                                        disableTab3={disableTab3}
                                    />
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className="grid-item">
                        <div className="equalcontainer-grids">
                            {edit ? (
                                <div>
                                    <div className="basic-headers">Last Updated</div>
                                    <div className="basiic-data date-space">
                                        {timeFormat(match.editMatch.updatedAt)}
                                    </div>
                                </div>
                            ) : null}
                            <div>
                                <div className="basic-headers">Match Type</div>
                                <strong className="text-yellow">{matchType}</strong>
                            </div>
                            <div>
                                <div className="basic-headers">Created From</div>
                                <strong className="text-yellow">
                                    {createdByGlacier ? 'Glacier' : 'Control-Center'}
                                </strong>
                            </div>
                            <div className="margin-top-10">
                                <div className="basic-headers">Status</div>
                                <strong className="text-yellow">{matchStatus}</strong>
                            </div>
                            {!!isAllSuperMatch && (
                                <div className="margin-top-10">
                                    <strong className="text-yellow">Fantasy Royal</strong>
                                </div>
                            )}
                        </div>
                        <div className="mt-15">
                            {!!matchWrite && !!matchStatus && !!addMatchMakeEasterMatch && (
                                <Button
                                    buttonText={!isMatchEstar ? 'Make estar match' : 'Remove estar match'}
                                    className="mr-10"
                                    onClick={() => this.handleClickEstarAction(!isMatchEstar ? ADD : REMOVE)}
                                />
                            )}
                            {!!matchWrite && matchStatus === MATCH_STATUS_CONST.DRAFT && !!addMatchMarkComingSoon && (
                                <Button
                                    buttonText="Mark Coming Soon"
                                    className="mr-10"
                                    onClick={() => this.handleMarkComingSoon(CONFIRMATION)}
                                    onDoubleClick={this.handleMarkComingSoon}
                                />
                            )}

                            {!!matchWrite &&
                                matchStatus === MATCH_STATUS_CONST.DRAFT &&
                                matchType !== MATCH_TYPE_CONST.LINKONLY && (
                                    <Button
                                        buttonText="Mark match LinkOnly"
                                        className="mr-10 margin-top-10"
                                        onClick={() => this.handleUpdateMatchTypeLinkOnly(CONFIRMATION)}
                                        onDoubleClick={this.handleUpdateMatchTypeLinkOnly}
                                    />
                                )}

                            {!!fantasyResultFile && (
                                <Button
                                    className="margin-top-10"
                                    buttonText="Download uploaded result file"
                                    onClick={() => window.open(fantasyResultFile, '_blank')}
                                />
                            )}

                            {!!matchAdvanceWrite && matchStatus === MATCH_STATUS_CONST.UPCOMING && (
                                <Button
                                    buttonText={`${!isAllSuperMatch ? 'Mark' : 'Remove'} match FR`}
                                    className="mr-5 margin-top-10"
                                    onClick={() => this.handleClickMarkMatchFRButton(!isAllSuperMatch ? ADD : REMOVE)}
                                />
                            )}
                            {!!matchAdvanceWrite &&
                                matchStatus === MATCH_STATUS_CONST.DRAFT &&
                                !!addMatchMarkDraftToLive && (
                                    <Button
                                        buttonText="Mark match draft to live"
                                        className="mr-10 margin-top-10"
                                        onClick={() => this.handleMatchDraftToLIve(CONFIRMATION)}
                                        onDoubleClick={this.handleMatchDraftToLIve}
                                    />
                                )}
                            {!!matchAdvanceWrite &&
                                !!game?.id &&
                                game.gameUniqueCode === GAME_UNIQUE_CODE.cricket &&
                                matchStatus === MATCH_STATUS_CONST.UPCOMING && (
                                    <Button
                                        buttonText={'Fetch player Stats'}
                                        className="mr-5 margin-top-10"
                                        onClick={() => this.handleClickFetchPlayerStats()}
                                    />
                                )}
                        </div>
                    </div>
                    <ConfirmationModal
                        modalVisible={markLiveModalVisible}
                        title={'Mark match LIVE?'}
                        onConfirm={this.onMatchMarkLiveConfirm.bind(this)}
                        onClose={this.onCloseConfirmationModal.bind(this)}
                    />
                </div>
            </DetailLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        squad: state.squad,
        player: state.player,
        match: state.match,
        contest: state.contest,
        toGame: state.toGame,
        toMatchMode: state.toMatchMode,
        toMatchAttribute: state.toMatchAttribute,
        fantasyConfig: state.fantasyConfig,
        tournament: state.tournament,
        boosters: state.boosters,
        client: state.client,
    };
};

export default connect(mapStateToProps, {
    getSquad,
    getSquadList,
    createMatch,
    updateMatch,
    publishMatch,
    deleteContest,
    listAllTournaments,
    deleteMatch,
    getMatch,
    contestMatch,
    logout,
    markMatchLive,
    getTOGameList,
    getTOMatchModeList,
    getTOMatchAttributeList,
    getFantasyScoreModel,
    copyContestFromMatch,
    updateMatchStatusLive,
    updateMatchTag,
    comingSoonMatch,
    fetchBoosterConfigs,
    updateMatchType,
    matchDraftToLive,
    fetchPlayerStatsForMatch,
    fetchMultiplierConfigs,
    freezeUnfreezePlayer,
})(AddMatch);
