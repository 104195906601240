import React from 'react';
import PropTypes from 'prop-types';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';
import Button from '../../../../Components/Button/Button';
import {connect} from 'react-redux';
import LoadingModal from '../../../../Components/LoadingModal/LoadingModal';
import Snackbar from '../../../../Components/Snackbar/Snackbar';
import {manualActionOnBanking} from '../../Users/AxioCalls';
import {KYC_ACTON_ON, KYC_BANK_OPTIONS, KYC_STATUS, KYC_UPI_OPTIONS} from '../../../../Utilities/Constants';
import TextModal from '../../../../Components/TextModal';

class UserBankAndUPIKyc extends React.Component {
    static propTypes = {
        open: PropTypes.bool,
        handleClose: PropTypes.func,
        kycData: PropTypes.exact({
            upiObject: PropTypes.array,
            bankObject: PropTypes.array,
            userId: PropTypes.string,
            fullName: PropTypes.string,
        }),
    };

    static defaultProps = {
        handleClose: () => {},
        open: false,
        kycData: {
            upiObject: [],
            bankObject: [],
        },
    };

    constructor(props) {
        super(props);
        const {kycData = {}} = props;
        this.state = {
            upiObject: kycData.upiObject || [],
            bankObject: kycData.bankObject || [],
            loading: false,
            errorMessage: '',
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {user} = this.props;

        if (
            (user.isGettingUserKycDocs && !prevProps.user.isGettingUserKycDocs) ||
            (user.isManuallyBankingKyc && !prevProps.user.isManuallyBankingKyc)
        ) {
            this.setState({loading: true});
        }
        if (user.isGettingUserKycDocsSuccess && !prevProps.user.isGettingUserKycDocsSuccess) {
            const {upiObject, bankObject} = user.userKycDocs.data;
            this.setState({loading: false, upiObject, bankObject});
        }
        if (user.isManuallyBankingKycSuccess && !prevProps.user.isManuallyBankingKycSuccess) {
            this.setState({loading: false});
        }
        if (
            (user.isGettingUserKycDocsFail && !prevProps.user.isGettingUserKycDocsFail) ||
            (user.isManuallyBankingKycFail && !prevProps.user.isManuallyBankingKycFail)
        ) {
            this.setState({loading: false, errorMessage: user.errorMessage.errorReason});
        }
    }

    handleUpdateKyc = (docData = {}) => {
        const {kycData = {}} = this.props;
        const {actionOn, actionType, bankAccData, vpaData, comment} = docData;
        const token = this.props.login.userDetails.data.accessToken;
        const selectedClient = this.props.client.selectedClient;
        const data = {
            adminComment: comment || '',
            userId: kycData.userId,
            actionOn,
            actionType,
            bankAccData,
            vpaData,
        };
        this.props.manualActionOnBanking(data, token, selectedClient);
    };

    handleClickOnBankDoc = (doc = {}, status) => {
        const data = {
            actionOn: KYC_ACTON_ON.BANK_ACCOUNT,
            actionType: status,
            bankAccData: {bankIfscCode: doc.ifscCode, bankAccountNumber: doc.bankAccountNumber},
        };
        if (status === KYC_STATUS.PROCESS_AGAIN) this.handleUpdateKyc(data);
        else this.setState({kycActionData: data});
    };

    handleClickOnUpiDoc = (doc = {}, status) => {
        const data = {
            actionOn: KYC_ACTON_ON.VPA,
            actionType: status,
            vpaData: {vpa: doc.vpa},
        };
        if (status === KYC_STATUS.PROCESS_AGAIN) this.handleUpdateKyc(data);
        else this.setState({kycActionData: data});
    };

    render() {
        let {handleClose, open, kycData = {}, login} = this.props;
        let {bankObject, upiObject, loading, errorMessage, kycActionData} = this.state;
        const {userKycWrite} = login?.permissions || {};

        return (
            <Dialog open={open} onClose={handleClose} scroll="paper" maxWidth="lg" fullWidth>
                {!!loading && <LoadingModal open={loading} />}
                {!!errorMessage && (
                    <Snackbar
                        open={!!errorMessage}
                        message={errorMessage}
                        onClose={() => this.setState({errorMessage: ''})}
                    />
                )}

                {!!kycActionData && (
                    <TextModal
                        open={!!kycActionData}
                        label="Enter Comment Here"
                        handleClose={() => this.setState({kycActionData: null})}
                        heading={
                            kycActionData.actionType === KYC_STATUS.VERIFIED
                                ? 'Comment for Verify'
                                : 'Comment for Reject'
                        }
                        buttonText={kycActionData.actionType === KYC_STATUS.VERIFIED ? 'Verify' : 'Reject'}
                        textInputType={kycActionData.actionType === KYC_STATUS.VERIFIED ? 'input' : 'select'}
                        selectOptions={
                            kycActionData.actionOn === KYC_ACTON_ON.BANK_ACCOUNT ? KYC_BANK_OPTIONS : KYC_UPI_OPTIONS
                        }
                        handleOnSubmit={(comment) => this.handleUpdateKyc({...kycActionData, comment})}
                    />
                )}

                <DialogTitle>Banking and UPI Kyc</DialogTitle>
                <DialogContent dividers={true}>
                    <h2>Banking Docs</h2>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Bank Name</TableCell>
                                    <TableCell>Pan Name</TableCell>
                                    <TableCell>Account No.</TableCell>
                                    <TableCell>IFSC Code</TableCell>
                                    <TableCell>status</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {bankObject.map((bank, index) => (
                                    <TableRow key={'bank' + index}>
                                        <TableCell>{bank.registeredName}</TableCell>
                                        <TableCell>{kycData.fullName}</TableCell>
                                        <TableCell>{bank.bankAccountNumber}</TableCell>
                                        <TableCell>{bank.ifscCode}</TableCell>
                                        <TableCell>{bank.status}</TableCell>
                                        <TableCell>
                                            {!!userKycWrite && bank.status === KYC_STATUS.SUBMITTED && (
                                                <Button
                                                    buttonText="Process Again"
                                                    size="sm"
                                                    onClick={() =>
                                                        this.handleClickOnBankDoc(bank, KYC_STATUS.PROCESS_AGAIN)
                                                    }
                                                />
                                            )}

                                            {!!userKycWrite && bank.status === KYC_STATUS.PROCESSING && (
                                                <>
                                                    <Button
                                                        className="mr-10"
                                                        buttonText="Verify"
                                                        size="sm"
                                                        onClick={() =>
                                                            this.handleClickOnBankDoc(bank, KYC_STATUS.VERIFIED)
                                                        }
                                                    />
                                                    <Button
                                                        buttonText="Reject"
                                                        size="sm"
                                                        onClick={() =>
                                                            this.handleClickOnBankDoc(bank, KYC_STATUS.REJECTED)
                                                        }
                                                    />
                                                </>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <br />
                    <br />
                    <h2>UPI Docs</h2>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>UPI Name</TableCell>
                                    <TableCell>Pan Name</TableCell>
                                    <TableCell>VPA</TableCell>
                                    <TableCell>status</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {upiObject.map((upi, index) => (
                                    <TableRow key={'upi' + index}>
                                        <TableCell>{upi.registeredName}</TableCell>
                                        <TableCell>{kycData.fullName}</TableCell>
                                        <TableCell>{upi.vpa}</TableCell>
                                        <TableCell>{upi.status}</TableCell>
                                        <TableCell>
                                            {!!userKycWrite && upi.status === KYC_STATUS.SUBMITTED && (
                                                <Button
                                                    buttonText="Process Again"
                                                    size="sm"
                                                    onClick={() =>
                                                        this.handleClickOnUpiDoc(upi, KYC_STATUS.PROCESS_AGAIN)
                                                    }
                                                />
                                            )}

                                            {!!userKycWrite && upi.status === KYC_STATUS.PROCESSING && (
                                                <Button
                                                    className="mr-10"
                                                    buttonText="Verify"
                                                    size="sm"
                                                    onClick={() => this.handleClickOnUpiDoc(upi, KYC_STATUS.VERIFIED)}
                                                />
                                            )}
                                            {!!userKycWrite &&
                                                [KYC_STATUS.PROCESSING, KYC_STATUS.VERIFIED].includes(upi.status) && (
                                                    <Button
                                                        buttonText="Reject"
                                                        size="sm"
                                                        onClick={() =>
                                                            this.handleClickOnUpiDoc(upi, KYC_STATUS.REJECTED)
                                                        }
                                                    />
                                                )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button buttonText="Cancel" onClick={handleClose} />
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        login: state.login,
        client: state.client,
    };
};

export default connect(mapStateToProps, {
    manualActionOnBanking,
})(UserBankAndUPIKyc);
