import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';

import Snackbar from '../../../../Components/Snackbar/Snackbar';
import LoadingModal from '../../../../Components/LoadingModal/LoadingModal';
import Button from '../../../../Components/Button/Button';
import {fetchWinningDisbursalDetails} from '../../RecordMatch/AxioCalls';
import {usePrevious} from '../../../../Utilities/hooks';

const WinningDisbursalDetailsModal = (props) => {
    const {handleClose, open, matchDetails} = props;
    const dispatch = useDispatch();
    const login = useSelector((state) => state.login);
    const recordMatch = useSelector((state) => state.recordMatch);
    const client = useSelector((state) => state.client);
    const prevRecordMatch = usePrevious(recordMatch);
    const [state, setState] = useState({});
    const {errorMessage = ''} = state;

    const loading = recordMatch?.isWinningDisbursalDetails || false;

    useEffect(() => {
        if (matchDetails.matchId)
            dispatch(
                fetchWinningDisbursalDetails(
                    {matchId: matchDetails.matchId},
                    login?.userDetails?.data?.accessToken,
                    client.selectedClient,
                ),
            );
    }, []);

    useEffect(() => {
        if (recordMatch.isWinningDisbursalDetailsFail && !prevRecordMatch.isWinningDisbursalDetailsFail) {
            handleChangeState({errorMessage: recordMatch?.errorMessage?.msg});
        }
    }, [recordMatch]);

    const handleChangeState = (obj = {}) => {
        setState({...state, ...obj});
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            scroll="paper"
            maxWidth="sm"
            fullWidth
            disableEscapeKeyDown
            disableBackdropClick
        >
            {!!errorMessage && (
                <Snackbar
                    open={!!errorMessage}
                    message={errorMessage}
                    onClose={() => handleChangeState({errorMessage: ''})}
                />
            )}
            {!!loading && <LoadingModal open={!!loading} />}
            <DialogTitle>Winning Disbursal Details</DialogTitle>
            <DialogContent className="card-grid-container" dividers={true}>
                {Object.keys(recordMatch?.winningDisbursalDetails || {}).map((key, index) => (
                    <p key={key + index}>
                        {key}:{' '}
                        <strong className="text-yellow">
                            {recordMatch?.winningDisbursalDetails?.[key]?.toString()}
                        </strong>
                    </p>
                ))}
            </DialogContent>
            <DialogActions>
                <Button buttonText="Cancel" onClick={handleClose} />
            </DialogActions>
        </Dialog>
    );
};

WinningDisbursalDetailsModal.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    matchDetails: PropTypes.object,
};

WinningDisbursalDetailsModal.defaultProps = {
    handleClose: () => {},
    open: false,
    matchDetails: {},
};

export default WinningDisbursalDetailsModal;
