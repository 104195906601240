import {
    TOMATCHTYPE_LIST,
    TOMATCHTYPE_LIST_SUCCESS,
    TOMATCHTYPE_LIST_FAIL,
    ADD_TOMATCHTYPE,
    ADD_TOMATCHTYPE_SUCCESS,
    ADD_TOMATCHTYPE_FAIL,
    UPDATE_TOMATCHTYPE,
    UPDATE_TOMATCHTYPE_SUCCESS,
    UPDATE_TOMATCHTYPE_FAIL,
    DELETE_TOMATCHTYPE,
    DELETE_TOMATCHTYPE_SUCCESS,
    DELETE_TOMATCHTYPE_FAIL,
    GET_TOMATCHTYPE,
    GET_TOMATCHTYPE_SUCCESS,
    GET_TOMATCHTYPE_FAIL,
    EDIT_TOMATCHTYPE,
} from './Constants';

const initialState = {
    TOMatchTypeListRequest: false,
    TOMatchTypeListSuccess: false,
    TOMatchTypeListFail: false,
    TOMatchTypeList: [],
    errorMessage: '',
    addTOMatchTypeRequest: false,
    addTOMatchTypeSuccess: false,
    addTOMatchTypeFail: false,
    addTOMatchType: [],
    updateTOMatchTypeRequest: false,
    updateTOMatchTypeSuccess: false,
    updateTOMatchTypeFail: false,
    updateTOMatchType: [],
    deleteTOMatchTypeRequest: false,
    deleteTOMatchTypeSuccess: false,
    deleteTOMatchTypeFail: false,
    deleteTOMatchType: [],
    getTOMatchTypeRequest: false,
    getTOMatchTypeSuccess: false,
    getTOMatchTypeFail: false,
    getTOMatchType: [],
    editTOMatchType: [],
};

const toMatchTypeReducer = (state = initialState, action) => {
    switch (action.type) {
        case TOMATCHTYPE_LIST:
            return {
                ...state,
                TOMatchTypeListRequest: true,
                TOMatchTypeListSuccess: false,
                TOMatchTypeListFail: false,
                TOMatchTypeList: [],
                errorMessage: '',
            };

        case TOMATCHTYPE_LIST_SUCCESS:
            return {
                ...state,
                TOMatchTypeListRequest: false,
                TOMatchTypeListSuccess: true,
                TOMatchTypeListFail: false,
                TOMatchTypeList: action.payload.data,
                errorMessage: '',
            };

        case TOMATCHTYPE_LIST_FAIL:
            return {
                ...state,
                TOMatchTypeListRequest: false,
                TOMatchTypeListSuccess: false,
                TOMatchTypeListFail: true,
                errorMessage: action.payload,
            };
        // ADD match
        case ADD_TOMATCHTYPE:
            return {
                ...state,
                addTOMatchTypeRequest: false,
                addTOMatchTypeSuccess: false,
                addTOMatchTypeFail: false,
                addTOMatchType: [],
                errorMessage: '',
            };

        case ADD_TOMATCHTYPE_SUCCESS:
            return {
                ...state,
                addTOMatchTypeRequest: false,
                addTOMatchTypeSuccess: true,
                addTOMatchTypeFail: false,
                addTOMatchType: action.payload.data,
                errorMessage: '',
            };

        case ADD_TOMATCHTYPE_FAIL:
            return {
                ...state,
                addTOMatchTypeRequest: false,
                addTOMatchTypeSuccess: false,
                addTOMatchTypeFail: true,
                errorMessage: action.payload,
            };
        //update TOMatchType
        case UPDATE_TOMATCHTYPE:
            return {
                ...state,
                updateTOMatchTypeRequest: true,
                updateTOMatchTypeSuccess: false,
                updateTOMatchTypeFail: false,
                updateTOMatchType: [],
                errorMessage: '',
            };

        case UPDATE_TOMATCHTYPE_SUCCESS:
            return {
                ...state,
                updateTOMatchTypeRequest: false,
                updateTOMatchTypeSuccess: true,
                updateTOMatchTypeFail: false,
                updateTOMatchType: action.payload.data,
                errorMessage: '',
            };

        case UPDATE_TOMATCHTYPE_FAIL:
            return {
                ...state,
                updateTOMatchTypeRequest: false,
                updateTOMatchTypeSuccess: false,
                updateTOMatchTypeFail: true,
                errorMessage: action.payload,
            };

        // DELETE TOMATCHTYPE
        case DELETE_TOMATCHTYPE:
            return {
                ...state,
                deleteTOMatchTypeRequest: true,
                deleteTOMatchTypeSuccess: false,
                deleteTOMatchTypeFail: false,
                deleteTOMatchType: [],
                errorMessage: '',
            };

        case DELETE_TOMATCHTYPE_SUCCESS:
            return {
                ...state,
                deleteTOMatchTypeRequest: false,
                deleteTOMatchTypeSuccess: true,
                deleteTOMatchTypeFail: false,
                deleteTOMatchType: action.payload.data,
                errorMessage: '',
            };

        case DELETE_TOMATCHTYPE_FAIL:
            return {
                ...state,
                deleteTOMatchTypeRequest: false,
                deleteTOMatchTypeSuccess: false,
                deleteTOMatchTypeFail: true,
                errorMessage: action.payload,
            };

        // get single TOMatchType
        case GET_TOMATCHTYPE:
            return {
                ...state,
                getTOMatchTypeRequest: true,
                getTOMatchTypeSuccess: false,
                getTOMatchTypeFail: false,
                getTOMatchType: [],
                errorMessage: '',
            };

        case GET_TOMATCHTYPE_SUCCESS:
            return {
                ...state,
                getTOMatchTypeRequest: false,
                getTOMatchTypeSuccess: true,
                getTOMatchTypeFail: false,
                getTOMatchType: action.payload.data,
                errorMessage: '',
            };

        case GET_TOMATCHTYPE_FAIL:
            return {
                ...state,
                getTOMatchTypeRequest: false,
                getTOMatchTypeSuccess: false,
                getTOMatchTypeFail: true,
                errorMessage: action.payload,
            };
        // EDIT TOMATCHTYPE
        case EDIT_TOMATCHTYPE:
            return {
                ...state,
                editTOMatchType: action.payload,
            };

        default:
            return state;
    }
};

export default toMatchTypeReducer;
