import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {logout} from '../../View/Modules/Login/LoginAction';
import PrimaryNavbar from '../PrimaryNavbar/PrimaryNavbar';
import UploadImageOnS3Modal from '../../Components/UploadImageOnS3Modal';
import classnames from 'classnames';
import styles from './CustomLayout.module.scss';

const CustomLayout = (props) => {
    const {children, history, mainClassName} = props;
    const dispatch = useDispatch();
    const login = useSelector((state) => state.login);
    const [imageUploadModal, setImageUploadModal] = useState(null);

    useEffect(() => {
        if (!login.userDetails.data) {
            history.push('/');
            dispatch(logout());
        }
    }, []);

    return (
        <section className={styles.layout}>
            {!!imageUploadModal && (
                <UploadImageOnS3Modal open={!!imageUploadModal} handleClose={() => setImageUploadModal(null)} />
            )}
            <PrimaryNavbar history={history} onClickUploadImage={() => setImageUploadModal({})} />
            <main className={classnames(mainClassName, styles.main)}>{children}</main>
        </section>
    );
};

export default CustomLayout;
