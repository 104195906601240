import React, {useState, useEffect} from 'react';
import {View, StyleSheet, TouchableOpacity, Dimensions} from 'react-native';
import TitleText from './TitleText';
import Colors from './colors';
import Fonts from './fonts';
import DefaultStyles from './default-styles';
import Modal from 'react-native-modal';

const SCREEN_WIDTH = Dimensions.get('window').width;

const ConfirmationModal = (props) => {
    const {modalVisible, title, onConfirm, onClose} = props;

    return (
        <Modal propagateSwipe isVisible={modalVisible} onBackdropPress={onClose} onBackButtonPress={onClose}>
            <View style={styles.otherModal}>
                <View>
                    <TitleText style={styles.modalTitleText}>{title}</TitleText>

                    {/* Save and cancel buttons */}
                    <View style={styles.saveProgressBox}>
                        <View style={{flex: 0.2}} />
                        <TouchableOpacity activeOpacity={0.7} style={{flex: 0.5}} onPress={onClose}>
                            <TitleText style={DefaultStyles.saveProgressText}>{'Cancel'}</TitleText>
                        </TouchableOpacity>
                        <View style={{width: 20}} />
                        <TouchableOpacity activeOpacity={0.7} style={{flex: 0.6}} onPress={onConfirm}>
                            <TitleText style={DefaultStyles.saveProgressText}>{'Confirm'}</TitleText>
                        </TouchableOpacity>
                        <View style={{flex: 0.2}} />
                    </View>
                    <View style={{height: 30}} />
                </View>
            </View>
        </Modal>
    );
};

const styles = StyleSheet.create({
    otherModal: {
        paddingVertical: 20,
        backgroundColor: Colors.headerBlack,
        borderRadius: 20,
        marginHorizontal: 10,
        opacity: 0.8,
        height: 180,
        width: SCREEN_WIDTH / 3,
        alignSelf: 'center',
    },
    saveProgressBox: {
        flexDirection: 'row',
        marginHorizontal: 40,
        opacity: 0.7,
        alignItems: 'center',
        marginTop: 50,
        borderRadius: 10,
    },
    modalTitleText: {
        textAlign: 'center',
        color: Colors.YELLOW,
        fontSize: Fonts.FONT_16,
        marginBottom: 15,
    },
});

export default ConfirmationModal;
