import React from 'react';
import '../../../Utilities/style.css';
import '../Login/login.css';
import DetailLayout from '../../Layouts/DetailLayout/DetailLayout';
import moment from 'moment';
import {connect} from 'react-redux';
import Modal from '../../../Components/Modal/Modal';
import Card from '../../../Components/Card/Card';
import Input from '../../../Components/Input/Input';
import Button from '../../../Components/Button/Button';
import Controller from '../../../Images/players.png';
import ToggleButton from '@material-ui/lab/ToggleButton';
import Snackbar from '../../../Components/Snackbar/Snackbar';
import Kill from '../../../Images/kill_medium.png';
import KillBig from '../../../Images/kill_yellow.png';
import AutoCompleteField from '../../../Components/AutoCompleteField/AutoCompleteField';
import Remove from '../../../Images/remove.png';
import Edit from '../../../Images/edit.png';
import AirPlane from '../../../Images/match_start.png';
import MatchEnds from '../../../Images/match ends.png';
import Timer from '../../../Images/timer.png';
import Verify from '../../../Images/verified.png';
import {
    recordMatchEvent,
    verifyMatchEvent,
    getEventsList,
    updateMatchEvent,
    deleteMatchEvent,
    matchComplete,
    matchCompile,
    matchCompleteV2,
    compileMatchV2,
    publishMatchSummary,
} from '../RecordMatch/./AxioCalls';
import {playersForMatch} from '../RecordMatch/AxioCalls';
import {logout} from '../Login/LoginAction';
import {isvalidTime} from '../../../Utilities/Helpers';
import {isNumber} from '../../../Utilities/Helpers';
import LiveMyTeamCard from '../AppReactNative/LiveMyTeamCard';
import {getMatchDetail} from '../Matches/AxioCalls';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';
import {GAME_UNIQUE_CODE, MATCH_STATUS_CONST} from '../../../Utilities/Constants';
import WinningDisbursalDetailsModal from './WinningDisbursalDetailsModal';
import {Checkbox, FormControlLabel} from '@material-ui/core';
import VerifySwitchMatchSummaryModal from './VerifySwitchMatchSummaryModal/VerifySwitchMatchSummaryModal';

class VerifyMatch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: [],
            selectAll: false,
            list: [],
            pageSize: 10,
            page: 1,
            open: false,
            adminpswd: '',
            player1Options: null,
            player2Options: null,
            headshot: false,
            modalName: '',
            player1: null,
            player2: null,
            eventList: [],
            playersInMatch: {},
            loading: false,
            verifySwitchMatchSummaryOpen: null,
        };
    }

    /**
     * function to save entered data in state
     * @param {string} field field names from the form
     * @param {*} value value in the field
     */
    handleInputChange(field, value) {
        if (field === 'time' && value.length === 2 && isNumber(value)) {
            let tvalue = value + ':';
            this.setState({[field]: tvalue, errorMessage: ''});
        } else if (field === 'time' && value.length > 5) {
            return false;
        } else {
            this.setState({[field]: value, errorMessage: ''});
        }
    }

    /**
     * function to remove ':' on back click
     * @param {string} field field names from the form
     * @param {*} event value in the field
     */
    onKeyDown(field, event) {
        var key = event.keyCode || event.charCode;

        if (field && field.includes(':') && (key === 8 || key === 46)) {
            let str = field.replace(':', '');
            this.setState({time: str});
        }
    }

    componentDidMount() {
        const {login, recordMatch, history, client} = this.props;
        if (!login.userDetails.data) {
            history.push('/');
            logout();
        } else {
            let matchId = recordMatch.verifyingMatch.id;
            this.props.playersForMatch(matchId, login.userDetails.data.accessToken, client.selectedClient);
            // this.props.getEventsList(recordMatch.verifyingMatch.id, login.userDetails.data.accessToken, client.selectedClient);
            this.props.getMatchDetail(matchId, login.userDetails.data.accessToken, client.selectedClient);
        }
    }

    componentDidUpdate(prevProps) {
        const {login, recordMatch, history, match, client} = this.props;

        if (recordMatch.isPublishMatchSummary && !prevProps.recordMatch.isPublishMatchSummary) {
            this.setState({loading: true});
        }

        if (recordMatch.playersMatchSuccess && !prevProps.recordMatch.playersMatchSuccess) {
            let options = recordMatch.playersMatch.data.map((player) => {
                return {title: player.pubgName, value: player.id};
            });
            this.setState({
                player1Options: options,
                player2Options: options,
                open: false,
            });
        } else if (recordMatch.playersMatchFail && !prevProps.recordMatch.playersMatchFail) {
            this.setState({errorMessage: recordMatch.errorMessage, open: false});
        }

        if (recordMatch.recordEventSuccess && !prevProps.recordMatch.recordEventSuccess) {
            this.setState({
                player1: null,
                player2: null,
                time: null,
                headshot: !this.state.headshot,
                open: false,
            });
            this.props.getEventsList(recordMatch.verifyingMatch.id, login.userDetails.data.accessToken);
        } else if (recordMatch.recordEventFail && !prevProps.recordMatch.recordEventFail) {
            this.setState({errorMessage: recordMatch.errorMessage, open: false});
        }
        if (recordMatch.completeMatchSuccess && !prevProps.recordMatch.completeMatchSuccess) {
            this.props.getMatchDetail(
                recordMatch.verifyingMatch.id,
                login.userDetails.data.accessToken,
                client.selectedClient,
            );
            this.setState({
                open: false,
                errorMessage: recordMatch.completeMatch.msg,
                loading: false,
            });
        } else if (recordMatch.completeMatchFail && !prevProps.recordMatch.completeMatchFail) {
            this.setState({errorMessage: recordMatch.errorMessage, open: false, loading: false});
        }
        if (recordMatch.eventListSuccess && !prevProps.recordMatch.eventListSuccess) {
            this.setState({eventList: recordMatch.eventList.data, open: false});
        } else if (recordMatch.eventListFail && !prevProps.recordMatch.eventListFail) {
            this.setState({errorMessage: recordMatch.errorMessage, open: false});
        }
        if (recordMatch.updateEventSuccess && !prevProps.recordMatch.updateEventSuccess) {
            this.props.getEventsList(recordMatch.verifyingMatch.id, login.userDetails.data.accessToken);
            this.setState({
                player1: null,
                player2: null,
                time: '',
                headshot: false,
                open: false,
            });
        } else if (recordMatch.updateEventFail && !prevProps.recordMatch.updateEventFail) {
            this.setState({errorMessage: recordMatch.errorMessage, open: false});
        }

        if (recordMatch.deleteEventSuccess && !prevProps.recordMatch.deleteEventSuccess) {
            this.props.getEventsList(recordMatch.verifyingMatch.id, login.userDetails.data.accessToken);
            this.setState({
                player1: null,
                player2: null,
                time: '',
                headshot: false,
                open: false,
                modalName: '',
            });
        } else if (recordMatch.deleteEventFail && !prevProps.recordMatch.deleteEventFail) {
            this.setState({errorMessage: recordMatch.errorMessage, open: false});
        }

        if (recordMatch.verifyEventSuccess && !prevProps.recordMatch.verifyEventSuccess) {
            this.props.getEventsList(recordMatch.verifyingMatch.id, login.userDetails.data.accessToken);
        } else if (recordMatch.verifyEventFail && !prevProps.recordMatch.verifyEventFail) {
            this.setState({errorMessage: recordMatch.errorMessage, open: false});
        }
        if (recordMatch.compileMatchSuccess && !prevProps.recordMatch.compileMatchSuccess) {
            this.setState({loading: false, open: false});
            history.push('/verifyMatch');
        } else if (recordMatch.compileMatchFail && !prevProps.recordMatch.compileMatchFail) {
            this.setState({errorMessage: recordMatch.errorMessage, open: false, loading: false});
        }

        if (match.getMatchDetailSuccess && !prevProps.match.getMatchDetailSuccess) {
            let playerInMatchStatsMap = new Map();
            let players = match.getMatchDetailResponse.players;
            let playerInMatchStats = match.getMatchDetailResponse.playerInMatchStats;

            let playersInMatch = {players: players};
            if (playerInMatchStats) {
                for (let i = 0; i < playerInMatchStats.length; i++) {
                    playerInMatchStatsMap.set(playerInMatchStats[i].player, playerInMatchStats[i]);
                }

                for (let i = 0; i < players.length; i++) {
                    players[i].totalPoints = playerInMatchStatsMap.has(players[i].id)
                        ? playerInMatchStatsMap.get(players[i].id).totalPoints
                        : 0;
                    players[i].playerInMatchStats = playerInMatchStatsMap.has(players[i].id)
                        ? playerInMatchStatsMap.get(players[i].id)
                        : {};
                    players[i].placements = match?.getMatchDetailResponse?.placements || [];
                }
                playersInMatch = {players: players};
            }

            this.setState({playersInMatch: playersInMatch});
        }

        if (recordMatch.isMatchCompletingV2Success && !prevProps.recordMatch.isMatchCompletingV2Success) {
            this.props.getMatchDetail(
                recordMatch.verifyingMatch.id,
                login.userDetails.data.accessToken,
                client.selectedClient,
            );
            this.setState({
                open: false,
                errorMessage: recordMatch?.errorMessage?.msg,
                loading: false,
            });
        } else if (recordMatch.isMatchCompletingV2Fail && !prevProps.recordMatch.isMatchCompletingV2Fail) {
            this.setState({errorMessage: recordMatch?.errorMessage?.msg, open: false, loading: false});
        }

        if (recordMatch.isMatchCompilingV2Success && !prevProps.recordMatch.isMatchCompilingV2Success) {
            this.props.getMatchDetail(
                recordMatch.verifyingMatch.id,
                login.userDetails.data.accessToken,
                client.selectedClient,
            );
            this.setState({
                open: false,
                errorMessage: recordMatch?.errorMessage?.msg,
                loading: false,
            });
        } else if (recordMatch.isMatchCompilingV2Fail && !prevProps.recordMatch.isMatchCompilingV2Fail) {
            this.setState({errorMessage: recordMatch?.errorMessage?.msg, open: false, loading: false});
        }

        if (recordMatch.isPublishMatchSummarySuccess && !prevProps.recordMatch.isPublishMatchSummarySuccess) {
            this.props.getMatchDetail(
                recordMatch.verifyingMatch.id,
                login.userDetails.data.accessToken,
                client.selectedClient,
            );
            this.setState({errorMessage: recordMatch?.errorMessage?.msg, loading: false});
        } else if (recordMatch.isPublishMatchSummaryFail && !prevProps.recordMatch.isPublishMatchSummaryFail) {
            this.setState({errorMessage: recordMatch?.errorMessage?.msg, loading: false});
        }
    }

    /**
     * Api call to delete an event
     */
    onRemoveEvent() {
        const {login, client} = this.props;

        this.props.deleteMatchEvent(this.state.eventId, login.userDetails.data.accessToken, client.selectedClient);
    }

    /**
     * convert seconds from api to string
     * @param {*} totalSeconds time in seconds
     */
    secondsToString(totalSeconds) {
        let seconds = totalSeconds % 60;
        let minutes = parseInt(totalSeconds / 60);
        let min = minutes;
        let sec = seconds;
        if (minutes.toString().length === 1) {
            min = '0' + minutes;
        }
        if (seconds.toString().length === 1) {
            sec = '0' + seconds;
        }
        return min + ':' + sec;
    }

    /**
     * api call to update an event details
     */
    onDone() {
        const {time, player1, player2, headshot, eventId} = this.state;
        let seconds = moment(time, 'mm:ss: A').diff(moment().startOf('day'), 'seconds');
        const {login, recordMatch, client} = this.props;
        if (player1 === null || player2 === null || player2 === undefined || player1 === undefined) {
            this.setState({errorMessage: 'Please make sure to select both players'});
        }
        // else if (player1.value === player2.value) {
        //     this.setState({
        //         errorMessage: "Please select two different players"
        //     });
        // }
        else if (time === null || time === '' || time === undefined || !isvalidTime(time)) {
            this.setState({errorMessage: 'Please enter valid time in 00:00 format'});
        } else {
            let recordMatchs = {
                match: recordMatch.verifyingMatch.id,
                player1: player1.value,
                player2: player2.value,
                eventTimeStamp: seconds,
                headshot: headshot,
                eventId: eventId,
            };
            this.props.updateMatchEvent(recordMatchs, login.userDetails.data.accessToken, client.selectedClient);
        }
    }

    /**
     * api call to verify an event
     * @param {*} event API call to verify event
     */
    onVerify(event) {
        const {login, recordMatch} = this.props;

        let recordMatchs = {
            match: recordMatch.verifyingMatch.id,
            player1: event.player1.id,
            player2: event.player2.id,
            eventTimeStamp: event.eventTimeStamp,
            headshot: event.headshot,
            eventId: event.id,
            status: 'VERIFIED',
        };
        //this.props.verifyMatchEvent(recordMatchs, login.userDetails.data.accessToken);
    }

    /**
     * api call to mark match complete
     */
    onMatchComplete() {
        const {isLastMatchOfTournament} = this.state;
        const {login, recordMatch, match, client} = this.props;
        this.setState({loading: true, open: false});
        // if (isLastMatchOfTournament) {
        //     if (match?.getMatchDetailResponse?.isNewDisbursalFlowActivated) {
        //         this.props.matchCompleteV2({ matchId: recordMatch.verifyingMatch.id, isLastMatchOfTournament}, login.userDetails.data.accessToken);
        //     } else {
        //         this.props.matchComplete({ matchId: recordMatch.verifyingMatch.id, isLastMatchOfTournament}, login.userDetails.data.accessToken);
        //     }
        // }
        // else {
        this.props.matchCompleteV2(
            {
                matchId: recordMatch.verifyingMatch.id,
                isLastMatchOfTournament,
            },
            login.userDetails.data.accessToken,
            client.selectedClient,
        );

        // }
    }

    /**
     * display edit modal with player details
     * @param {*} event object with event details
     */
    onEdit(event) {
        this.setState({
            open: true,
            modalName: 'editMatch',
            player1: {title: event.player1.pubgName, value: event.player1.id},
            eventId: event.id,
            player2: {title: event.player2.pubgName, value: event.player2.id},
            time: this.secondsToString(event.eventTimeStamp),
            headshot: event.headshot,
        });
    }

    /**
     * display delete event modal with player details
     * @param {*} event object with event details
     */
    onDeleteEvent(event) {
        let players = this.state.player2Options;
        players.push({title: event.player2.pubgName, value: event.player2.id});
        this.setState({
            open: true,
            modalName: 'remove',
            player1: {title: event.player1.pubgName, value: event.player1.id},
            eventId: event.id,
            player2: {title: event.player2.pubgName, value: event.player2.id},
            time: this.secondsToString(event.eventTimeStamp),
            headshot: event.headshot,
            player2Options: players,
        });
    }

    /**
     * to close a modal on click of cancel
     */
    onCancel() {
        this.setState({
            open: false,
            player1: null,
            player2: null,
            time: '',
            modalName: null,
        });
    }

    /**
     * save match and navigate to matches page
     */
    onMatchCompleted() {
        const {login, recordMatch, match, client} = this.props;

        if (match?.getMatchDetailResponse?.status === MATCH_STATUS_CONST.ENDED) {
            this.setState({loading: true, open: false});
            if (match?.getMatchDetailResponse?.isNewDisbursalFlowActivated) {
                this.props.compileMatchV2(
                    {
                        matchId: recordMatch.verifyingMatch.id,
                        forfeit: false,
                    },
                    login.userDetails.data.accessToken,
                    client.selectedClient,
                );
            } else {
                this.props.matchCompile(
                    recordMatch.verifyingMatch.id,
                    login.userDetails.data.accessToken,
                    false,
                    client.selectedClient,
                );
            }
        } else {
            this.setState({
                errorMessage:
                    'Match is not in ENDED status, Please end the match or please wait until leaderboards are generated',
                open: false,
            });
        }
    }

    onMatchForfeit() {
        const {login, recordMatch, match, client} = this.props;

        if (match?.getMatchDetailResponse?.status === MATCH_STATUS_CONST.ENDED) {
            this.setState({loading: true, open: false});
            if (match?.getMatchDetailResponse?.isNewDisbursalFlowActivated) {
                this.props.compileMatchV2(
                    {
                        matchId: recordMatch.verifyingMatch.id,
                        forfeit: true,
                    },
                    login.userDetails.data.accessToken,
                    client.selectedClient,
                );
            } else {
                this.props.matchCompile(
                    recordMatch.verifyingMatch.id,
                    login.userDetails.data.accessToken,
                    true,
                    client.selectedClient,
                );
            }
        } else {
            this.setState({
                errorMessage:
                    'Match is not in ENDED status, Please end the match or please wait until leaderboards are generated',
                open: false,
            });
        }
    }

    /**
     *  map and display event details
     */
    dislayEvents() {
        return this.state.eventList.map((event) => {
            return (
                <tr>
                    <td>{event.status === 'VERIFIED' ? <img src={Verify} alt="" /> : null}</td>
                    <td>{this.secondsToString(event.eventTimeStamp)}</td>
                    <td className="fs-16-bold">{event.player1.pubgName}</td>
                    <td>
                        <img src={Kill} alt="" />
                    </td>
                    <td className="fs-16-bold">{event.player2.pubgName}</td>
                    <td className="disp-flex align-center">
                        {event.headshot ? <div className="headshot">H</div> : <div className="hoverdiv"></div>}
                    </td>
                    {event.status === 'VERIFIED' ? null : (
                        <React.Fragment>
                            <td className="editable" onClick={this.onEdit.bind(this, event)}>
                                <img src={Edit} alt="" />
                            </td>
                            <td className="editable" onClick={this.onDeleteEvent.bind(this, event)}>
                                <img src={Remove} alt="" />
                            </td>
                        </React.Fragment>
                    )}

                    {/*
                        event.status === "VERIFIED" ?
                            null : <td className="editable">
                                <Button buttonText="Verify" onClick={this.onVerify.bind(this, event)} />
                            </td>
                            */}

                    <td className="hoverdiv"></td>
                </tr>
            );
        });
    }

    handleClickWinningDisbursal = () => {
        const {recordMatch} = this.props;
        this.setState({winningDisbursalModal: {matchId: recordMatch.verifyingMatch.id}});
    };

    handleClickPublishMatchSummary = () => {
        const {recordMatch, login, client} = this.props;
        this.props.publishMatchSummary(
            {matchId: recordMatch.verifyingMatch.id},
            login?.userDetails?.data?.accessToken,
            client.selectedClient,
        );
    };

    handleChangeState = (obj = {}) => {
        this.setState({...obj, errorMessage: ''});
    };

    handleVerifySwitchMatchSummary = () => {
        const {recordMatch, login} = this.props;
        this.setState({
            verifySwitchMatchSummaryOpen: {
                matchId: recordMatch?.verifyingMatch?.id,
                playersList: recordMatch?.playersMatch?.data,
                token: login?.userDetails?.data?.accessToken,
            },
        });
    };

    render() {
        const {
            player1Options,
            player2Options,
            open,
            errorMessage,
            modalName,
            time,
            disabled,
            headshot,
            player1,
            player2,
            winningDisbursalModal,
            isLastMatchOfTournament,
            verifySwitchMatchSummaryOpen,
        } = this.state;
        const {history, recordMatch, match, login} = this.props;

        const {tournamentName, matchName, mapName, players, squads} = recordMatch.verifyingMatch;

        const {verifyMatchWrite, uploadMatchResultPermission, verifyMatchUploadResults} = login?.permissions || {};

        let date = new Date();
        let timestamp = moment(date).valueOf();
        let startTime = recordMatch.verifyingMatch.startTime;
        let initialTime = startTime + timestamp;
        let direction = 'forward';
        return (
            <DetailLayout
                location="recordMatch"
                history={history}
                searchbar="Search Match ID "
                centerTitle="Verify Match"
                onSave={verifyMatchWrite ? () => this.setState({open: true, modalName: 'done'}) : undefined}
                onForfeitSave={
                    verifyMatchWrite
                        ? () =>
                              this.setState({
                                  open: true,
                                  modalName: 'doneForfeit',
                              })
                        : undefined
                }
                id={recordMatch.verifyingMatch.id}
                initialTime={initialTime}
                direction={direction}
                startTime={startTime}
                matchStatus={match?.getMatchDetailResponse?.status}
            >
                {!!this.state.loading && <LoadingModal open={this.state.loading} />}

                {!!errorMessage && (
                    <Snackbar
                        open={!!errorMessage}
                        message={errorMessage}
                        onClose={() => this.setState({errorMessage: ''})}
                    />
                )}

                {!!verifySwitchMatchSummaryOpen && (
                    <VerifySwitchMatchSummaryModal
                        open={!!verifySwitchMatchSummaryOpen}
                        verifySwitchMatch={verifySwitchMatchSummaryOpen}
                        onClickClose={() => this.setState({verifySwitchMatchSummaryOpen: null})}
                    />
                )}

                {!!winningDisbursalModal && (
                    <WinningDisbursalDetailsModal
                        open={!!winningDisbursalModal}
                        matchDetails={winningDisbursalModal}
                        handleClose={() => this.setState({winningDisbursalModal: null})}
                    />
                )}

                <Modal open={open}>
                    <div className="container modal-top-margin">
                        <Card>
                            {modalName === 'matchComplete' ? (
                                <React.Fragment>
                                    <div className="recordMatchContainer ">
                                        <Button
                                            buttonText="Cancel"
                                            cancel={true}
                                            onClick={() => this.setState({open: false})}
                                        />
                                    </div>
                                    <div className="margin-bottom-30">
                                        <div className="align-center mb-15">Are You Sure Match Is Over?</div>
                                        {!!isLastMatchOfTournament && (
                                            <h3 className="align-center text-yellow">
                                                Are you sure this is the last match of the tournament?
                                            </h3>
                                        )}
                                    </div>
                                    <div className="disp-flex margin-top-10 align-center">
                                        <Button
                                            buttonText="Yes, Match Over. Calculate final scores"
                                            onClick={this.onMatchComplete.bind(this)}
                                        />
                                    </div>
                                </React.Fragment>
                            ) : modalName === 'editMatch' ? (
                                <React.Fragment>
                                    <div className="disp-flex">
                                        <Button
                                            buttonText="Cancel"
                                            cancel={true}
                                            onClick={() => this.setState({open: false})}
                                        />
                                        <div>Edit Recorded Event</div>
                                        <Button buttonText="Mark Complete" onClick={this.onDone.bind(this)} />
                                    </div>

                                    <div className="modal-big-container">
                                        <div />
                                        <div id="inner-card">
                                            <Card>
                                                <div className="recordMatchContainer m-auto">
                                                    <div className="align-center m-auto">
                                                        <img className="margin-right-16" src={Timer} alt="" />
                                                    </div>
                                                    <div className=" timeContainer align-center">
                                                        <div />
                                                        <div>
                                                            Timer
                                                            <div id="timer" className="margin-top-10">
                                                                <Input
                                                                    id="timer"
                                                                    type="text"
                                                                    value={time}
                                                                    onChange={this.handleInputChange.bind(this, 'time')}
                                                                    placeholder="22:22"
                                                                    disabled={disabled}
                                                                    onKeyDown={this.onKeyDown.bind(this, time)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <ToggleButton
                                                            value="check"
                                                            selected={headshot}
                                                            onChange={() =>
                                                                this.setState({headshot: !this.state.headshot})
                                                            }
                                                        >
                                                            H
                                                        </ToggleButton>
                                                        <div className="margin-top-10">HeadShot</div>
                                                    </div>
                                                </div>
                                            </Card>
                                            <div className="kill-grid margin-top-10">
                                                <Card>
                                                    <AutoCompleteField
                                                        label="Player 1"
                                                        onChange={this.handleInputChange.bind(this, 'player1')}
                                                        options={player1Options}
                                                        value={player1}
                                                        inputChange={() => {}}
                                                    />
                                                </Card>
                                                <div className="align-center">
                                                    <img src={KillBig} alt="" />
                                                    <div className="margin-top-10 text-yellow">Killed</div>
                                                </div>
                                                <Card>
                                                    <AutoCompleteField
                                                        label="Player 2"
                                                        onChange={this.handleInputChange.bind(this, 'player2')}
                                                        options={player2Options}
                                                        value={player2}
                                                        inputChange={() => {}}
                                                    />
                                                </Card>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            ) : modalName === 'remove' ? (
                                <React.Fragment>
                                    <div className="disp-flex">
                                        <Button buttonText="Cancel" cancel={true} onClick={this.onCancel.bind(this)} />
                                        <div>Remove Recorded Event</div>
                                        <Button buttonText="Remove" onClick={this.onRemoveEvent.bind(this)} />
                                    </div>
                                    <div>
                                        <tr className="disp-flex align-center">
                                            <td className="margin-right-16">{time}</td>
                                            <td className=" margin-right-16 fs-16-bold">{player1.title}</td>
                                            <td className="margin-right-16">
                                                <img src={Kill} alt="" />
                                            </td>
                                            <td className="fs-16-bold">{player2.title}</td>
                                        </tr>
                                    </div>
                                </React.Fragment>
                            ) : modalName === 'done' ? (
                                <React.Fragment>
                                    <div className="commonnav-grid-container ">
                                        <div className="modal-left-header">
                                            <Button
                                                buttonText="Cancel"
                                                cancel={true}
                                                onClick={() => this.setState({open: false})}
                                            />
                                        </div>
                                        <div className="align-center">
                                            Mark Complete?, Make Sure match in ENDED Status
                                        </div>
                                        <div className="align-right" onClick={() => this.setState({open: false})}>
                                            <Button buttonText="Exit" onClick={() => history.push('/verifyMatch')} />
                                        </div>
                                    </div>
                                    <div className="align-center">
                                        <Button buttonText="Save" onClick={this.onMatchCompleted.bind(this)} />
                                    </div>
                                </React.Fragment>
                            ) : modalName === 'doneForfeit' ? (
                                <React.Fragment>
                                    <div className="commonnav-grid-container ">
                                        <div className="modal-left-header">
                                            <Button
                                                buttonText="Cancel"
                                                cancel={true}
                                                onClick={() => this.setState({open: false})}
                                            />
                                        </div>
                                        <div className="align-center">
                                            Mark Forfeit?, Make Sure match in ENDED Status
                                        </div>
                                        <div className="align-right" onClick={() => this.setState({open: false})}>
                                            <Button buttonText="Exit" onClick={() => history.push('/verifyMatch')} />
                                        </div>
                                    </div>
                                    <div className="align-center">
                                        <Button buttonText="Save" onClick={this.onMatchForfeit.bind(this)} />
                                    </div>
                                </React.Fragment>
                            ) : null}
                        </Card>
                    </div>
                </Modal>

                <div className="verifyMatch-grid margin-bottom-10 margin-top-10">
                    <div className="grid-item">
                        <div className="basiic-data">{tournamentName}</div>
                        <div className="margin-top-10 text-bold">{matchName}</div>
                        <div className="margin-top-10 text-yellow text-bold">
                            Status: <span>{match?.getMatchDetailResponse?.status}</span>
                        </div>
                        <div className="margin-top-10 text-yellow text-bold">
                            Created From:{' '}
                            <span>
                                {match?.getMatchDetailResponse?.createdByGlacier ? 'Glacier' : 'Control-Center'}
                            </span>
                        </div>
                        <div className="disp-flex align-left margin-top-10">
                            <div className="disp-flex margin-right-16">
                                <div className="margin-right-5">
                                    <img src={'../../../Images/map.png'} alt="" />
                                </div>
                                <div className="basic-headers">{mapName}</div>
                            </div>
                            <div className="disp-flex">
                                <div className="margin-right-5">
                                    <img src={Controller} alt="" />
                                </div>
                                <div className="basic-headers">
                                    {players || squads ? players.length + ' Players ' + squads.length + ' Squads' : ''}
                                </div>
                            </div>
                        </div>
                        <div className="margin-top-10">
                            {!!uploadMatchResultPermission &&
                                match?.getMatchDetailResponse?.status === MATCH_STATUS_CONST.LIVE_NOW && (
                                    <div className="mb-10">
                                        <div className="mb-10">
                                            <FormControlLabel
                                                label="Last Match of the Tournament"
                                                control={
                                                    <Checkbox
                                                        checked={!!isLastMatchOfTournament}
                                                        color="primary"
                                                        name="isLastMatchOfTournament"
                                                        onChange={(e) =>
                                                            this.setState({isLastMatchOfTournament: e.target.checked})
                                                        }
                                                    />
                                                }
                                            />
                                        </div>
                                        <Button
                                            className="mr-10"
                                            buttonText="End Match"
                                            onClick={() => this.setState({modalName: 'matchComplete', open: true})}
                                        />
                                        {!!verifyMatchUploadResults && (
                                            <Button
                                                buttonText="Upload Results"
                                                onClick={() =>
                                                    history.push({
                                                        pathname: '/uploadFantasyResults',
                                                        state: {matchId: recordMatch.verifyingMatch.id},
                                                    })
                                                }
                                            />
                                        )}
                                    </div>
                                )}
                            {match?.getMatchDetailResponse?.status === MATCH_STATUS_CONST.WINNING_INITIATED && (
                                <Button
                                    buttonText="Winning Disbursal Details"
                                    onClick={this.handleClickWinningDisbursal}
                                />
                            )}
                            {GAME_UNIQUE_CODE.csgo === match?.getMatchDetailResponse?.game?.gameUniqueCode &&
                                match?.getMatchDetailResponse?.status === MATCH_STATUS_CONST.LIVE_NOW && (
                                    <Button
                                        className="margin-top-10"
                                        buttonText="Publish Match Summary"
                                        onClick={this.handleClickPublishMatchSummary}
                                    />
                                )}
                            {!!match?.getMatchDetailResponse?.fantasyResultFile && (
                                <Button
                                    className="margin-top-10"
                                    buttonText="Download uploaded result file"
                                    onClick={() =>
                                        window.open(match?.getMatchDetailResponse?.fantasyResultFile, '_blank')
                                    }
                                />
                            )}
                            {match?.getMatchDetailResponse?.game?.gameUniqueCode === GAME_UNIQUE_CODE.cricket &&
                                match?.getMatchDetailResponse?.status === MATCH_STATUS_CONST.ENDED && (
                                    <Button
                                        className="margin-top-10"
                                        buttonText="Verify Switch Match Result"
                                        onClick={this.handleVerifySwitchMatchSummary}
                                    />
                                )}
                        </div>
                    </div>
                    <div>
                        <div className="margin-top-20">
                            <table className="editTable">
                                <tbody>
                                    <tr>
                                        <td>
                                            <img src={Verify} alt="" />
                                        </td>
                                        <td>00:00</td>
                                        <td>
                                            <img src={AirPlane} alt="" />
                                        </td>
                                        <td className="fs-16-bold text-yellow">Match Start</td>
                                    </tr>
                                    {
                                        //this.dislayEvents()
                                    }
                                    {match?.getMatchDetailResponse?.status === MATCH_STATUS_CONST.ENDED ? (
                                        <tr>
                                            <td>
                                                <img src={Verify} alt="" />
                                            </td>
                                            <td>45:00</td>
                                            <td>
                                                <img src={MatchEnds} alt="" />
                                            </td>
                                            <td className="fs-16-bold text-yellow">Match End</td>
                                        </tr>
                                    ) : null}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div style={{marginBottom: 20}}>
                    <LiveMyTeamCard details={this.state.playersInMatch} />
                </div>
            </DetailLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        squad: state.squad,
        player: state.player,
        recordMatch: state.recordMatch,
        match: state.match,
        client: state.client,
    };
};

export default connect(mapStateToProps, {
    recordMatchEvent,
    verifyMatchEvent,
    logout,
    updateMatchEvent,
    deleteMatchEvent,
    matchCompile,
    compileMatchV2,
    matchComplete,
    matchCompleteV2,
    getEventsList,
    playersForMatch,
    getMatchDetail,
    publishMatchSummary,
})(VerifyMatch);
