import React from 'react';
import '../../../Utilities/style.css';
import '../Login/login.css';
import {connect} from 'react-redux';
import Tabs from '../../../Components/Tabs/Tabs';
import Card from '../../../Components/Card/Card';
import DetailLayout from '../../Layouts/DetailLayout/DetailLayout';
import {createPlayer, updatePlayers, deletePlayers, getPlayerRequest} from './PlayerAxios';
import Snackbar from '../../../Components/Snackbar/Snackbar';
import Button from '../../../Components/Button/Button';
import {getSquadList, getSquad} from '../Squads/SquadAxios';
import {logout} from '../Login/LoginAction';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';
import FileInput from '../../../Components/FileInput/FileInput';
import axios from 'axios';
import xlsx from 'xlsx';
import {HEADER_SOURCE, URL} from '../../../Utilities/Constants';
import {saveAs} from 'file-saver';
import Input from '../../../Components/Input/Input';
import {fetchS3FileFromServer} from '../TOPublishedMatch/AxioCalls';
import {getTOGameList} from '../TOGame/AxioCalls';
import DropDown from '../../../Components/Dropdown/Dropdown';

class UploadPlayers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            snackbarOpen: false,
            errorMessage: '',
            loading: false,
            uploadPlayersFile: '',
            isEdit: 'N',
            selectedGame: null,
            gameOptions: [],
        };
    }

    componentWillMount() {
        const {login, history} = this.props;
        if (!login.userDetails.data) {
            history.push('/');
            this.props.logout();
        }
    }

    componentDidMount() {
        const {login, history, client} = this.props;
        if (!login.userDetails.data) {
            history.push('/');
            this.props.logout();
        } else {
            let toGame = {page: 1, pageSize: 100, searchString: null, sortOrder: null, listType: 'Limited'};
            this.props.getTOGameList(toGame, login.userDetails.data.accessToken, client.selectedClient);
        }
    }

    componentDidUpdate(prevProps) {
        const {toGame} = this.props;
        if (toGame.TOGameListSuccess && !prevProps.toGame.TOGameListSuccess) {
            this.setState({
                gameOptions: toGame.TOGameList.data.data.map((item) => {
                    return {text: item.gameName, value: item.id};
                }),
                loading: false,
            });
        } else if (toGame.TOGameListFail && !prevProps.toGame.TOGameListFail) {
            this.setState({
                errorMessage: toGame.errorMessage,
                loading: false,
            });
        }
    }

    uploadPlayers() {
        try {
            let file = this.state.uploadPlayersFile;
            let isUpdate = this.state.isEdit;
            if (!file) {
                let msg = 'File is required!';
                console.log(msg);
                this.setState({
                    snackbarOpen: true,
                    errorMessage: msg,
                    loading: false,
                });
                return;
            }

            this.setState({loading: true});

            const data = new FormData();
            data.append('uploadPlayersFile', file);

            let axiosConfig = {
                headers: {
                    Authorization: 'Bearer ' + this.props.login.userDetails.data.accessToken,
                },
            };

            axios
                .post(`${URL}/player/uploadPlayersFile`, data, axiosConfig)
                .then((res) => {
                    // console.log(">>>uploadPlayers ", res.data);
                    if (res.data.msg !== null && res.data.msg !== undefined && res.data.msg !== '') {
                        if (isUpdate.toLowerCase() === 'y') {
                            console.log(isUpdate);
                            this.updatePlayers(res.data.msg);
                        } else {
                            this.addPlayers(res.data.msg);
                        }
                    }
                })
                .catch((err) => {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    this.setState({loading: false, snackbarOpen: true, errorMessage: errorMsg});
                });
        } catch (err) {
            console.error('uploadPlayers err: ', err);
            this.setState({
                snackbarOpen: true,
                errorMessage: 'Something went wrong!',
                loading: false,
            });
        }
    }

    /**
     * Excel format:
     *  squadName, fullName, displayName, pubgName, nationality, fcRating, currentCredits, matchesPlayed, tournaments, wins, kills, deaths, assists, kdRatio, avgSurvival, avgTravel, winRatio, avgHeals, avgDamage, headshotPercent, accuracyPercent, positionInSquad, game
     */
    async addPlayers(fileLocation) {
        try {
            let res = await fetchS3FileFromServer(fileLocation, this.props.login.userDetails.data.accessToken);
            //parse input excel with teamIds
            var data = new Uint8Array(res.data);
            var workbook = xlsx.read(data, {type: 'array'});
            let worksheet = workbook.Sheets[workbook.SheetNames[0]];
            let sheetData = xlsx.utils.sheet_to_json(worksheet, {raw: true});

            let playersList = [];
            for (let i = 0; i < sheetData.length; i++) {
                playersList.push(sheetData[i]);
            }

            //axios
            let axiosConfig = {
                headers: {
                    'x-source': HEADER_SOURCE,
                    Authorization: 'Bearer ' + this.props.login.userDetails.data.accessToken,
                },
            };
            let req = {players: playersList};
            let uploadPlayersRes = await axios.post(`${URL}/player/createBulk`, req, axiosConfig);

            // console.log("uploadPlayers response: ", uploadPlayersRes);
            if (!uploadPlayersRes.data.data || !uploadPlayersRes.data.data.data) {
                this.setState({
                    snackbarOpen: true,
                    errorMessage: 'Could not get expected response from server!',
                });
            }

            //download results
            this.populateAndDownloadUploadPlayersResult(uploadPlayersRes.data.data.data);
        } catch (err) {
            console.error('addTeamsToMatch err: ', err);
            const errorMsg = err?.response?.data?.msg || err.message;
            this.setState({
                snackbarOpen: true,
                errorMessage: errorMsg || 'Something went wrong',
                loading: false,
            });
        }
    }

    /**
     * Excel format:
     *  squadName, fullName, displayName, pubgName, nationality, fcRating, currentCredits, matchesPlayed, tournaments, wins, kills, deaths, assists, kdRatio avgSurvival avgTravel, winRatio, avgHeals, avgDamage, headshotPercent accuracyPercent positionInSquad status
     */
    async updatePlayers(fileLocation) {
        try {
            let res = await fetchS3FileFromServer(fileLocation, this.props.login.userDetails.data.accessToken);
            //parse input excel with teamIds
            var data = new Uint8Array(res.data);
            var workbook = xlsx.read(data, {type: 'array'});
            let worksheet = workbook.Sheets[workbook.SheetNames[0]];
            let sheetData = xlsx.utils.sheet_to_json(worksheet, {raw: true});

            let playersList = [];
            for (let i = 0; i < sheetData.length; i++) {
                playersList.push(sheetData[i]);
            }

            //axios
            let axiosConfig = {
                headers: {
                    Authorization: 'Bearer ' + this.props.login.userDetails.data.accessToken,
                },
            };
            let req = {players: playersList};
            let uploadPlayersRes = await axios.post(`${URL}/player/updateBulk`, req, axiosConfig);

            if (!uploadPlayersRes.data.data || !uploadPlayersRes.data.data.data) {
                this.setState({
                    snackbarOpen: true,
                    errorMessage: 'Could not get expected response from server!',
                });
            }

            //download results
            this.populateAndDownloadUploadPlayersResult(uploadPlayersRes.data.data.data);
        } catch (err) {
            console.error('addTeamsToMatch err: ', err);
            const errorMsg = err?.response?.data?.msg || err.message;
            this.setState({
                snackbarOpen: true,
                errorMessage: errorMsg || 'Something went wrong',
                loading: false,
            });
        }
    }

    populateAndDownloadUploadPlayersResult(data) {
        let wb = xlsx.utils.book_new();
        wb.Props = {
            Title: 'Result for upload players',
            Subject: 'Result for upload players',
            Author: 'Fanclash',
            CreatedDate: new Date(),
        };

        wb.SheetNames.push('upload_players_result');

        let ws_data = [];
        //headers
        ws_data.push(['playerId', 'squadName', 'fullName', 'displayName', 'pubgName', 'status', 'reason', 'game']);

        // console.log("download. upload players data", data)

        //rows
        for (let i = 0; i < data.length; i++) {
            let row = [];
            row.push(data[i].playerId);
            row.push(data[i].squadName);
            row.push(data[i].fullName);
            row.push(data[i].displayName);
            row.push(data[i].pubgName);
            row.push(data[i].status);
            row.push(data[i].reason);
            row.push(data[i].game);

            ws_data.push(row);
        }

        // console.log("addTeamResult excel", ws_data);

        //create excel
        let ws = xlsx.utils.aoa_to_sheet(ws_data);
        wb.Sheets['upload_players_result'] = ws;
        let wbout = xlsx.write(wb, {bookType: 'xlsx', type: 'binary'});

        saveAs(new Blob([this.s2ab(wbout)], {type: 'application/octet-stream'}), 'upload_players_result.xlsx');

        //refresh teams' list
        this.setState({loading: false});
    }

    s2ab(s) {
        let buf = new ArrayBuffer(s.length);
        let view = new Uint8Array(buf);
        for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
    }

    handleInputChange(field, value) {
        this.setState({
            [field]: value,
        });
    }

    renderUploadPlayersTab() {
        const {gameOptions, selectedGame} = this.state;
        return (
            <div>
                <div className="grid-item margin-top-20" style={{border: '2px solid #ffc954', padding: 15}}>
                    <span style={{color: '#ffc954', fontSize: 14}}>
                        - Possible values for 'positionInSquad': "LEADER", "THE SNIPER", "THE FRAGGER", "THE SUPPORT"
                        <br />- Possible values for 'status': "ACTIVE", "INACTIVE"
                    </span>
                    <div className="grid-item margin-top-20 ">
                        <Input
                            label="Is Bulk Edit ? (Y/N) "
                            type="text"
                            name="isEdit"
                            value={this.state.isEdit}
                            onChange={this.handleInputChange.bind(this, 'isEdit')}
                            placeholder="Is Bulk Update?"
                        />
                    </div>
                    <div className="grid-item  margin-top-20">
                        <DropDown
                            label="Game"
                            type="text"
                            menuItems={gameOptions}
                            name="selectedGame"
                            value={selectedGame}
                            disabled={false}
                            onChange={this.handleInputChange.bind(this, 'selectedGame')}
                            placeholder="Select Game"
                        />
                    </div>

                    <div className="grid-item margin-top-20">
                        <FileInput
                            label="Upload Players: Upload File"
                            type="file"
                            name="upload_players"
                            onChange={(value) => this.setState({uploadPlayersFile: value})}
                        />
                    </div>
                    <div style={{marginTop: 20}}>
                        <Button onClick={this.downloadPlayerExcel.bind(this)} buttonText="Download player template" />
                    </div>
                    <div style={{marginTop: 20}}>
                        <Button onClick={this.uploadPlayers.bind(this)} buttonText="Submit & Download Results" />
                    </div>
                </div>
            </div>
        );
    }

    // getValidPlayerStats() {
    //     this.setState({loading: true});
    //     let axiosConfig = {
    //         headers: {
    //             Authorization: "Bearer " + this.props.login.userDetails.data.accessToken
    //         }
    //     };

    //     axios.post(`${URL}/getValidPlayerStats`, {}, axiosConfig).then(res => {
    //         if (res.data.data !== null) {
    //             this.downloadPlayerExcel(res.data.data);
    //         }
    //     }).catch(err => {
    //         console.log("error");
    //         this.setState({ loading: false });
    //         console.log(err);
    //     })

    //     this.setState({loading: false});
    // }

    downloadPlayerExcel(data) {
        const {selectedGame} = this.state;
        const {toGame} = this.props;
        let selectedGameData = toGame.TOGameList.data.data.filter((item) => item.id == selectedGame)[0];
        if (!selectedGameData) {
            return false;
        }
        let headers = [
            'fullName',
            'displayName',
            'game',
            'pubgName',
            'nationality',
            'currentCredits',
            'squadName',
            'positionInSquad',
            'fcRating',
            'matchesPlayed',
            'tournaments',
        ]; //common fields
        let game = selectedGameData.gameUniqueCode;

        let wb = xlsx.utils.book_new();
        wb.Props = {
            Title: 'Player Stats',
            Subject: 'Player',
            Author: 'Fanclash',
            CreatedDate: new Date(),
        };
        wb.SheetNames.push(game);
        let ws_data = [];
        if (selectedGameData?.statsModel?.playerStats?.external) {
            Object.values(selectedGameData.statsModel.playerStats.external).forEach((x) =>
                x.statType == 'BASE' ? headers.push(x.name) : null,
            );
        } else if (selectedGameData.validPlayerStats) {
            selectedGameData.validPlayerStats.forEach((x) => headers.push(x.attributeName));
        } else {
            return false;
        }
        ws_data.push(headers);
        let ws = xlsx.utils.aoa_to_sheet(ws_data);
        wb.Sheets[game] = ws;

        let wbout = xlsx.write(wb, {bookType: 'xlsx', type: 'binary'});
        saveAs(new Blob([this.s2ab(wbout)], {type: 'application/octet-stream'}), 'player_upload' + '.xlsx');
    }

    render() {
        const {snackbarOpen, errorMessage, loading} = this.state;
        const {history} = this.props;
        let title = 'Upload Players Data';

        return (
            <DetailLayout location="Players" history={history} centerTitle={title} id="">
                <LoadingModal open={loading} />
                <Snackbar
                    open={snackbarOpen}
                    message={errorMessage}
                    onClose={() => this.setState({snackbarOpen: false})}
                />
                <div className="detail-container margin-top-20">
                    <div className="grid-item"></div>
                    <div className="grid-item">
                        <Card>
                            <div>
                                <div className="card-single-grid-container">
                                    <Tabs tab1Label="Upload Players" panelA={this.renderUploadPlayersTab.bind(this)} />
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
            </DetailLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        squad: state.squad,
        player: state.player,
        toGame: state.toGame,
        client: state.client,
    };
};

export default connect(mapStateToProps, {
    createPlayer,
    updatePlayers,
    getSquad,
    logout,
    getPlayerRequest,
    deletePlayers,
    getSquadList,
    getTOGameList,
})(UploadPlayers);
