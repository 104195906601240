import React from 'react';
import '../../../Utilities/style.css';
import '../Login/login.css';
import {connect} from 'react-redux';
import Tabs from '../../../Components/Tabs/Tabs';
import Card from '../../../Components/Card/Card';
import DetailLayout from '../../Layouts/DetailLayout/DetailLayout';
import Input from '../../../Components/Input/Input';
import DropDown from '../../../Components/Dropdown/Dropdown';
import {
    createFantasyScoreModel,
    updateFantasyScoreModel,
    deleteFantasyScoreModel,
    getFantasyScoreModel,
} from './FantasyAxios';
import Snackbar from '../../../Components/Snackbar/Snackbar';
import Modal from '../../../Components/Modal/Modal';
import Button from '../../../Components/Button/Button';
import Close from '../../../Images/close_yellow.png';

import {getTOGameList} from '../TOGame/AxioCalls';
import {getTOMatchModeList} from '../TOMatchMode/AxioCalls';
import {logout} from '../Login/LoginAction';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';
import {timeFormat, isValidNumberWithSign, isValidString} from '../../../Utilities/Helpers';

class AddFantasyScoringModel extends React.Component {
    constructor(props) {
        super(props);
        let location = this.props.history.location.pathname.includes('edit');
        this.state = {
            statusOptions: [
                {value: 'ACTIVE', text: 'ACTIVE'},
                {value: 'INACTIVE', text: 'INACTIVE'},
            ],
            gameOptions: [],
            matchModeOptions: [],
            scoringAttributeOptions: [],
            attributeOptions: [
                {value: 'kills', text: 'kills'},
                {value: 'deaths', text: 'deaths'},
                {value: 'assists', text: 'assists'},
            ],
            typeOptions: [
                {value: 'COUNT', text: 'count'},
                {value: 'AVERAGE', text: 'average'},
                {value: 'PERCENT', text: 'percent'},
            ],
            disabled: false,
            status: null,
            name: null,
            game: null,
            matchMode: null,
            attributeScoreList: {},
            apiError: '',
            edit: location,
            open: false,
            loading: false,
            id: null,
            numberOfAttributes: 0,
            placementNumber: 0,
        };
    }

    /**
     * function to save entered data in state
     * @param {string} field field names from the form
     * @param {*} value value in the field
     */
    handleInputChange(field, value) {
        this.setState({[field]: value, errorMessage: ''});

        if (field === 'game') {
            this.setState({
                matchMode: null,
                scoringAttributeOptions: this.handleScoringAttributeOptions(value),
                matchModeOptions: this.props.toMatchMode.TOMatchModeList.data.data
                    .filter((item) => item.game === value)
                    .map((item) => {
                        return {text: item.modeName, value: item.id};
                    }),
            });
        }

        if (
            field.includes('attributeName') ||
            field.includes('displayName') ||
            field.includes('multiplier') ||
            field.includes('attributeStatus') ||
            field.includes('type')
        ) {
            let attributeScoreList = this.state.attributeScoreList;
            let attributeNum = field.split('_')[1];
            let key = 'attribute_' + attributeNum;
            if (!attributeScoreList) {
                attributeScoreList = {};
            }
            if (!attributeScoreList[key]) {
                attributeScoreList[key] = {};
            }
            if (field.includes('attributeName')) {
                attributeScoreList[key].attributeName = value;
            } else if (field.includes('displayName')) {
                attributeScoreList[key].displayName = value;
            } else if (field.includes('multiplier')) {
                attributeScoreList[key].multiplier = value;
            } else if (field.includes('attributeStatus')) {
                attributeScoreList[key].attributeStatus = value;
            } else if (field.includes('type')) {
                attributeScoreList[key].type = value;
            } else {
                this.setState({
                    errorMessage: 'Unknown field ' + field,
                    [field + 'Error']: true,
                });
                return;
            }
            this.setState({
                attributeScoreList: attributeScoreList,
                errorMessage: '',
                [field + 'Error']: false,
            });
        }
    }

    /**
     * validate inputs and call api on save
     */
    onSave() {
        const {
            status,
            name,
            game,
            matchMode,
            edit,
            id,
            attributeScoreList,
            numberOfAttributes,
            placementNumber,
            placement = {},
        } = this.state;
        const {login} = this.props;

        if (name === null || name === ' ' || name === undefined || name.length < 2 || name.length > 30) {
            this.setState({errorMessage: 'Please Enter Valid model Name'});
            return false;
        } else if (status === null || status === undefined) {
            this.setState({errorMessage: 'Please select status'});
            return false;
        } else if (game === null || game === undefined) {
            this.setState({errorMessage: 'Please select game'});
            return false;
        } else if (matchMode === null || matchMode === undefined) {
            this.setState({errorMessage: 'Please select matchMode'});
            return false;
        } else if (!numberOfAttributes || numberOfAttributes === 0) {
            this.setState({errorMessage: 'Please select attributes'});
            return false;
        } else if (!id && Object.keys(placement).length !== parseInt(placementNumber)) {
            this.setState({
                errorMessage:
                    'Please click on Create points button after change Number of placement points and fill the point fields',
            });
            return false;
        }

        let finalAttributeList = {};
        for (let i = 1; i <= numberOfAttributes; i++) {
            let key = 'attribute_' + i;
            if (attributeScoreList && attributeScoreList[key]) {
                finalAttributeList[key] = {};
                if (isValidNumberWithSign(attributeScoreList[key].multiplier)) {
                    finalAttributeList[key].multiplier = parseFloat(attributeScoreList[key].multiplier);
                } else {
                    this.setState({
                        ['multiplier_' + i + 'Error']: true,
                        errorMessage: 'Enter valid multiplier' + i,
                    });
                    return false;
                }
                if (attributeScoreList[key].attributeName) {
                    finalAttributeList[key].attributeName = attributeScoreList[key].attributeName;
                } else {
                    this.setState({
                        ['attributeName_' + i + 'Error']: true,
                        errorMessage: 'Enter valid attribute name for ' + i,
                    });
                    return false;
                }
                if (isValidString(attributeScoreList[key].type)) {
                    finalAttributeList[key].type = attributeScoreList[key].type;
                }
                if (attributeScoreList[key].displayName) {
                    finalAttributeList[key].displayName = attributeScoreList[key].displayName;
                } else {
                    this.setState({
                        ['displayName_' + i + 'Error']: true,
                        errorMessage: 'Enter valid attribute displayName for ' + i,
                    });
                    return false;
                }
                if (isValidString(attributeScoreList[key].attributeStatus)) {
                    finalAttributeList[key].attributeStatus = attributeScoreList[key].attributeStatus;
                } else {
                    this.setState({
                        ['attributeStatus_' + i + 'Error']: true,
                        errorMessage: 'Enter valid attribute status for ' + i,
                    });
                    return false;
                }
            } else {
                this.setState({errorMessage: 'Enter valid attribute values for  ' + i});
                return false;
            }
        }

        for (const [key, bucket] of Object.entries(placement)) {
            if (!(bucket.startRank > 0) || !(bucket.endRank > 0) || !(bucket.pointsPerRank > 0)) {
                this.setState({errorMessage: `startRank, endRank, pointsPerRank can't be empty or 0 in ${key}`});
                return;
            }
        }

        let newModel = {
            game,
            matchMode,
            status,
            name,
            attributeList: finalAttributeList,
            placement,
        };

        if (edit) {
            newModel.id = id;
            this.props.updateFantasyScoreModel(newModel, login.userDetails.data.accessToken);
        } else {
            this.props.createFantasyScoreModel(newModel, login.userDetails.data.accessToken);
        }
    }

    componentWillMount() {
        const {login, history} = this.props;
        if (!login.userDetails.data) {
            history.push('/');
            this.props.logout();
        }
    }

    componentDidMount() {
        const {login, history, client} = this.props;
        if (!login.userDetails.data) {
            history.push('/');
            this.props.logout();
        } else {
            if (this.state.edit) {
                this.props.getFantasyScoreModel(
                    {id: this.props.model.editScoring.id},
                    login.userDetails.data.accessToken,
                );
            }
            this.props.getTOGameList({listType: 'ALL'}, login.userDetails.data.accessToken, client.selectedClient);
            this.props.getTOMatchModeList({listType: 'ALL'}, this.props.login.userDetails.data.accessToken);
        }
    }

    componentDidUpdate(prevProps) {
        const {history, model, toMatchMode, toGame} = this.props;

        if (
            (model.addScoringRequest && !prevProps.model.addScoringRequest) ||
            (model.updateScoringRequest && !prevProps.model.updateScoringRequest)
        ) {
            this.setState({loading: true});
        }

        if (toGame.TOGameListSuccess && !prevProps.toGame.TOGameListSuccess) {
            this.setState({
                gameOptions: toGame.TOGameList.data.data.map((item) => {
                    return {text: item.gameName, value: item.id};
                }),
                scoringAttributeOptions: this.handleScoringAttributeOptions(this.state.game),
                loading: false,
            });
        } else if (toGame.TOGameListFail && !prevProps.toGame.TOGameListFail) {
            this.setState({errorMessage: toGame.errorMessage, loading: false});
        }

        if (toMatchMode.TOMatchModeListSuccess && !prevProps.toMatchMode.TOMatchModeListSuccess) {
            this.setState({
                matchModeOptions: toMatchMode.TOMatchModeList.data.data
                    .filter((item) => item.game === this.state.game)
                    .map((item) => {
                        return {text: item.modeName, value: item.id};
                    }),
                loading: false,
            });
        } else if (toMatchMode.TOMatchModeListFail && !prevProps.toMatchMode.TOMatchModeListFail) {
            this.setState({errorMessage: toMatchMode.errorMessage, loading: false});
        }

        if (!prevProps.model.addScoringSuccess && model.addScoringSuccess) {
            this.setState({loading: false});
            history.goBack();
        } else if (!prevProps.model.addScoringFail && model.addScoringFail) {
            this.setState({errorMessage: model.errorMessage, loading: false});
        }
        if (!prevProps.model.updateScoringSuccess && model.updateScoringSuccess) {
            this.setState({loading: false});
            history.goBack();
        } else if (!prevProps.model.updateScoringFail && model.updateScoringFail) {
            this.setState({errorMessage: model.errorMessage, loading: false});
        }

        if (model.deleteScoringSuccess && !prevProps.model.deleteScoringSuccess) {
            if (model.deleteScoring.msg === 'Model Deleted') {
                history.push('/fantasyScoringModels');
            }
            this.setState({errorMessage: model.deleteScoring.msg, loading: false});
        } else if (model.deleteScoringFail && !prevProps.model.deleteScoringFail) {
            this.setState({errorMessage: model.errorMessage, loading: false});
        }

        if (model.getScoringSuccess && !prevProps.model.getScoringSuccess) {
            const {name, game, matchMode, status, id, attributeList, placement} = model.getScoring.data;
            this.setState({
                name,
                game: game?.id,
                matchMode: matchMode?.id,
                status,
                id,
                attributeScoreList: attributeList,
                numberOfAttributes: attributeList ? Object.keys(attributeList).length : 0,
                placementNumber: placement ? Object.keys(placement).length : 0,
                placement: placement || {},
                disabled: true,
                scoringAttributeOptions: this.handleScoringAttributeOptions(game),
            });
        } else if (model.getScoringFail && !prevProps.model.getScoringFail) {
            this.setState({errorMessage: model.errorMessage});
        }
    }

    handleScoringAttributeOptions = (gameId = '') => {
        const {toGame} = this.props;
        let _scoringAttributes = [];
        if (gameId) {
            _scoringAttributes =
                toGame?.TOGameList?.data?.data?.filter((g) => g.id === gameId)?.[0]?.validScoringAttribute || [];
            _scoringAttributes = _scoringAttributes.map((item) => ({
                text: item.scoringLegacyName,
                value: item.scoringLegacyName,
            }));
        }
        return _scoringAttributes;
    };

    /**
     * dynamically display number of attributes
     * @param {*} numberOfAttributes
     */
    displayAttributeList(numberOfAttributes) {
        const {attributeScoreList, disabled, typeOptions, statusOptions, scoringAttributeOptions} = this.state;

        let inputs = [];
        for (let i = 1; i <= numberOfAttributes; i++) {
            let unit = '';
            if (i === 1) {
                unit = i + 'st';
            } else if (i === 2) {
                unit = i + 'nd';
            } else if (i === 3) {
                unit = i + 'rd';
            } else {
                unit = i + 'th';
            }

            let name = 'attributeName_' + i;
            let displayName = 'displayName_' + i;
            let multiplier = 'multiplier_' + i;
            let status = 'attributeStatus_' + i;
            let type = 'type_' + i;

            let errorName = name + 'Error';
            let errorDisplayName = displayName + 'Error';
            let errorMultiplier = multiplier + 'Error';
            let errorStatus = status + 'Error';

            let key = 'attribute_' + i;

            inputs.push(
                <div className="four-column-grid">
                    <div className="grid-item margin-top-20">
                        <DropDown
                            menuItems={scoringAttributeOptions}
                            disabled={disabled}
                            placeholder="select"
                            onChange={this.handleInputChange.bind(this, name)}
                            label={unit + ' Attribute-Name'}
                            value={attributeScoreList?.[key]?.attributeName || ''}
                            type="text"
                            name={type}
                            error={this.state[errorName]}
                        />
                        {/*<Input
                            shrink={true}
                            label={unit + " Attribute-Name"}
                            type="text"
                            name={name}
                            value={attributeScoreList && attributeScoreList[key] ? attributeScoreList[key].attributeName : null}
                            onChange={this.handleInputChange.bind(this, name)}
                            error={this.state[errorName]}
                            disabled={disabled} />*/}
                    </div>
                    <div className="grid-item margin-top-20">
                        <Input
                            shrink={true}
                            label={unit + ' Attribute - DisplayName'}
                            type="text"
                            name={displayName}
                            value={
                                attributeScoreList && attributeScoreList[key]
                                    ? attributeScoreList[key].displayName
                                    : null
                            }
                            onChange={this.handleInputChange.bind(this, displayName)}
                            error={this.state[errorDisplayName]}
                            disabled={disabled}
                        />
                    </div>
                    <div className="grid-item margin-top-20">
                        <Input
                            shrink={true}
                            label={unit + ' Attribute - multiplier'}
                            type="text"
                            name={multiplier}
                            value={
                                attributeScoreList && attributeScoreList[key]
                                    ? attributeScoreList[key].multiplier
                                    : null
                            }
                            onChange={this.handleInputChange.bind(this, multiplier)}
                            error={this.state[errorMultiplier]}
                            disabled={disabled}
                        />
                    </div>
                    <div className="grid-item margin-top-20">
                        <DropDown
                            menuItems={typeOptions}
                            disabled={disabled}
                            placeholder="select"
                            onChange={this.handleInputChange.bind(this, type)}
                            label={unit + ' Type'}
                            value={attributeScoreList && attributeScoreList[key] ? attributeScoreList[key].type : null}
                            type="text"
                            name={type}
                            error={this.state[errorName]}
                        />
                    </div>
                    <div className="grid-item margin-top-20">
                        <DropDown
                            shrink={true}
                            menuItems={statusOptions}
                            label={unit + ' Attribute - Status'}
                            type="text"
                            name={status}
                            value={
                                attributeScoreList && attributeScoreList[key]
                                    ? attributeScoreList[key].attributeStatus
                                    : null
                            }
                            onChange={this.handleInputChange.bind(this, status)}
                            error={this.state[errorStatus]}
                            disabled={disabled}
                        />
                    </div>
                </div>,
            );
        }
        return inputs;
    }

    handleCreatePointList = () => {
        const {placement = {}, placementNumber = 0} = this.state;
        let tempPlacement = {};
        for (let i = 1; i <= placementNumber; i++) {
            const tempBucket = placement[`bucket${i}`] || {};
            tempPlacement[`bucket${i}`] = {
                startRank: tempBucket.startRank || '',
                endRank: tempBucket.endRank || '',
                pointsPerRank: tempBucket.pointsPerRank || '',
            };
        }
        this.setState({placement: tempPlacement});
    };

    handleChangePlacement = (bucket, key, value) => {
        this.setState((prevState) => ({
            placement: {
                ...prevState.placement,
                [bucket]: {
                    ...prevState.placement[bucket],
                    [key]: value,
                },
            },
        }));
    };

    /**
     * dynamically display number of placements
     * placement: {bucket1: {startRank, endRank, pointsPerRank}}
     */
    displayPlacementPoints() {
        const {placement = {}, disabled} = this.state;
        return Object.keys(placement).map((bucket, index) => {
            const bucketObj = placement[bucket] || {};

            return (
                <div key={bucket}>
                    <p className="margin-top-30 mb-5">{bucket + ' of placement point'}</p>
                    <div className="four-column-grid">
                        <div className="grid-item">
                            <Input
                                shrink={true}
                                label="startRank"
                                type="number"
                                name="startRank"
                                value={bucketObj.startRank || ''}
                                disabled={disabled}
                                onChange={(value) => this.handleChangePlacement(bucket, 'startRank', value)}
                            />
                        </div>
                        <div className="grid-item">
                            <Input
                                shrink={true}
                                label="endRank"
                                type="number"
                                name="endRank"
                                value={bucketObj.endRank || ''}
                                disabled={disabled}
                                onChange={(value) => this.handleChangePlacement(bucket, 'endRank', value)}
                            />
                        </div>
                        <div className="grid-item">
                            <Input
                                shrink={true}
                                label="Points Per Rank"
                                type="number"
                                name="pointsPerRank"
                                value={bucketObj.pointsPerRank || ''}
                                disabled={disabled}
                                onChange={(value) => this.handleChangePlacement(bucket, 'pointsPerRank', value)}
                            />
                        </div>
                    </div>
                </div>
            );
        });
    }

    /**
     * UI for tab A
     */
    renderTabA() {
        const {
            status,
            statusOptions,
            disabled,
            name,
            gameOptions,
            game,
            matchMode,
            matchModeOptions,
            numberOfAttributes,
            placementNumber,
            placement = {},
        } = this.state;
        return (
            <div>
                <div className="card-grid-container nopadding">
                    {/* row 1 */}
                    <div className="grid-item  margin-top-20">
                        <Input
                            label="Model Name"
                            type="text"
                            name="name"
                            value={name}
                            onChange={this.handleInputChange.bind(this, 'name')}
                            placeholder="Enter model name "
                        />
                    </div>
                    <div className="grid-item   margin-top-20">
                        <DropDown
                            menuItems={statusOptions}
                            label="Status"
                            value={status}
                            placeholder="select"
                            onChange={this.handleInputChange.bind(this, 'status')}
                        />
                    </div>
                </div>
                <div className="card-grid-container nopadding">
                    <div className="grid-item  margin-top-20">
                        <DropDown
                            label="Game"
                            type="text"
                            menuItems={gameOptions}
                            name="game"
                            value={game}
                            onChange={this.handleInputChange.bind(this, 'game')}
                            placeholder="Select Game"
                            disabled={disabled}
                        />
                    </div>

                    <div className="grid-item  margin-top-20">
                        <DropDown
                            label="Match Mode"
                            type="text"
                            menuItems={matchModeOptions}
                            name="matchMode"
                            value={matchMode}
                            onChange={this.handleInputChange.bind(this, 'matchMode')}
                            placeholder="Select Match Mode"
                            disabled={disabled}
                        />
                    </div>

                    <div className="grid-item  margin-top-20">
                        <Input
                            shrink={true}
                            label="Number of attributes"
                            type="text"
                            name="numberOfAttributes"
                            value={numberOfAttributes}
                            onChange={this.handleInputChange.bind(this, 'numberOfAttributes')}
                            placeholder="Enter"
                            disabled={disabled}
                        />
                    </div>
                    <div className="grids-8-4 margin-top-20">
                        <Input
                            shrink={true}
                            label="Number of placement points"
                            type="text"
                            name="placementNumber"
                            value={placementNumber}
                            onChange={this.handleInputChange.bind(this, 'placementNumber')}
                            placeholder="Enter"
                            disabled={disabled}
                        />
                        <Button
                            size="sm"
                            buttonText="Create points"
                            onClick={this.handleCreatePointList}
                            disabled={disabled}
                        />
                    </div>
                </div>
                <div>{this.displayAttributeList(numberOfAttributes)}</div>
                {!!Object.values(placement).length && <div>{this.displayPlacementPoints()}</div>}
            </div>
        );
    }

    /**
     * delete current model
     */
    onDeleteModel() {
        const {login} = this.props;
        this.props.deleteFantasyScoreModel(this.state.id, login.userDetails.data.accessToken);
        this.setState({open: false, loading: true});
    }

    render() {
        const {errorMessage, edit, open, loading, id} = this.state;
        const {history, model, login} = this.props;
        let title = edit ? 'Edit Scoring Model | ' + model.editScoring.name : 'Add Scoring Model';

        const {updatedAt, nextMatch, lastMatch} = model.editScoring;

        const {fantasyScoringWrite} = login?.permissions || {};

        return (
            <DetailLayout
                location="FantasyScoringModels"
                history={history}
                centerTitle={title}
                onSave={!!fantasyScoringWrite ? this.onSave.bind(this) : undefined}
                onDeleteClick={!!fantasyScoringWrite ? () => this.setState({open: true}) : undefined}
                editPage={edit}
                id={edit ? id : ''}
            >
                {!!loading && <LoadingModal open={loading} />}
                {!!errorMessage && (
                    <Snackbar
                        open={!!errorMessage}
                        message={errorMessage}
                        onClose={() => this.setState({errorMessage: ''})}
                    />
                )}

                <Modal open={open}>
                    <div className="container modal-top-margin">
                        <Card>
                            <div className="disp-flex">
                                <div className="modal-left-header">Delete Model</div>
                                <div onClick={() => this.setState({open: false})}>
                                    <img src={Close} alt="" className="cursor" />
                                </div>
                            </div>
                            <div className="align-center equalcontainer-grids">
                                <div />
                                <div>
                                    <div className=" disp-flex align-center margin-top-30">
                                        <div className="margin-right-16">
                                            <Button buttonText="Delete Model" onClick={this.onDeleteModel.bind(this)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                </Modal>

                <div className="detail-container margin-top-20">
                    <div className="grid-item">
                        <div className="basic-headers">
                            Updating existing scoring models can corrupt past matches data. Please create a new one if
                            anything has to be changed.
                        </div>
                    </div>
                    <div className="grid-item">
                        <Card>
                            <div>
                                <div className="card-single-grid-container">
                                    <Tabs tab1Label="Fantasy Scoring Config" panelA={this.renderTabA.bind(this)} />
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className="grid-item">
                        <div className="equalcontainer-grids">
                            {edit && (
                                <React.Fragment>
                                    <div>
                                        <div className="basic-headers">Last Updated</div>
                                        <div className="basiic-data date-space">
                                            {updatedAt !== '' ? timeFormat(updatedAt) : '-'}
                                        </div>
                                    </div>
                                    <div>
                                        <div className="basic-headers">Last Match</div>
                                        <div className="basiic-data date-space">
                                            {lastMatch !== '' ? timeFormat(lastMatch) : '-'}
                                        </div>
                                    </div>
                                    <div>
                                        <div className="basic-headers">Next Match</div>
                                        <div className="basiic-data date-space">
                                            {nextMatch !== '' ? timeFormat(nextMatch) : '-'}
                                        </div>
                                    </div>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </div>
            </DetailLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        model: state.fantasyConfig,
        toGame: state.toGame,
        toMatchMode: state.toMatchMode,
        client: state.client,
    };
};

export default connect(mapStateToProps, {
    logout,
    getTOGameList,
    getTOMatchModeList,
    getFantasyScoreModel,
    createFantasyScoreModel,
    updateFantasyScoreModel,
    deleteFantasyScoreModel,
})(AddFantasyScoringModel);
