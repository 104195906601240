import React from 'react';
import '../../../Utilities/style.css';
import '../Login/login.css';
import DetailLayout from '../../Layouts/DetailLayout/DetailLayout';
import Table from '../../../Components/Table/Table';
import {next, prev, reset} from '../../../Utilities/HelperFunctions';
import {transactionList} from './AxioCalls';
import {connect} from 'react-redux';
import {logout} from '../Login/LoginAction';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';
import Snackbar from '../../../Components/Snackbar/Snackbar';

class UserBalance extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: [],
            selectAll: false,
            list: [],
            pageData: {},
            pageOptions: {
                page: 1,
                pageSize: 10,
                searchString: null,
                sortOrder: null,
                userId: this.props.user.currentUser.user,
                txnType: 'CASHBALANCE',
            },
            loading: true,
            errorMessage: '',
            snackbarOpen: false,
            open: false,
            adminpswd: '',
            sortOptions: [
                {
                    value: 'id',
                    text: 'Contest Id',
                },
                {
                    value: 'contestType',
                    text: 'Contest Type',
                },
                {
                    value: 'match',
                    text: 'Match Id',
                },
                {
                    value: 'updatedAt',
                    text: 'Last Updated',
                },

                {
                    text: 'Status',
                    value: 'status',
                },
            ],
        };
    }

    componentWillMount() {
        const {login, history, client} = this.props;

        if (!login.userDetails.data) {
            history.push('/');
            this.props.logout();
        } else {
            const {pageOptions} = this.state;

            this.props.transactionList(pageOptions, login.userDetails.data.accessToken, client.selectedClient);
        }
    }

    /**
     * reset table data
     */
    onReset() {
        const {pageOptions} = this.state;
        const {login, client} = this.props;
        let options = reset(pageOptions);
        this.setState({
            pageOptions: options,
        });
        this.props.transactionList(options, login.userDetails.data.accessToken, client.selectedClient);
    }

    /**
     * API call to search for a squad
     * @param {string} field field name
     * @param {string} value value entered in the field
     */
    onSearchandSort(field, value) {
        const {pageOptions} = this.state;
        const {login, client} = this.props;
        let options = pageOptions;
        options[field] = value;
        this.setState({pageOptions: options});
        this.setState({[field]: value});

        if (field === 'searchString' && value.length > 2) {
            this.props.transactionList(options, login.userDetails.data.accessToken, client.selectedClient);
        } else if (field === 'sortOrder') {
            this.props.transactionList(options, login.userDetails.data.accessToken, client.selectedClient);
        }
    }

    /**
     * pagination  and api call on next click
     */
    onNext() {
        const {pageData, pageOptions} = this.state;
        const {login, client} = this.props;
        let options = next(pageOptions, pageData);

        if (options) {
            this.props.transactionList(options, login.userDetails.data.accessToken, client.selectedClient);
            this.setState({pageOptions: options, loading: true});
        }
    }

    /**
     * pagination  and api call on  prev click
     */
    onPrev() {
        const {pageOptions} = this.state;
        const {login, client} = this.props;
        let options = prev(pageOptions);
        if (options) {
            this.props.transactionList(options, login.userDetails.data.accessToken, client.selectedClient);
            this.setState({pageOptions: options, loading: true});
        }
    }

    componentDidUpdate(prevProps) {
        const {user} = this.props;

        if (user.userTransactionSuccess && !prevProps.user.userTransactionSuccess) {
            this.setState({
                loading: false,
                list: user.userTransaction.data.data,
                pageData: user.userTransaction.data.pageData,
            });
        } else if (user.userTransactionFail && !prevProps.user.userTransactionFail) {
            this.setState({loading: false, snackbarOpen: true, errorMessage: user.errorMessage});
        }
    }

    /**
     * display team Ids one below the other
     * @param {*} teams array of team IDs
     */
    dispTeams(teams) {
        return teams.map((team) => {
            return <div>{team}</div>;
        });
    }

    render() {
        const {list, pageData, loading, snackbarOpen, sortOptions, errorMessage} = this.state;
        const data = list;
        const {searchString, sortOrder, page} = this.state.pageOptions;

        let userDetails = {};
        const {user} = this.props;
        userDetails.name = user.currentUser.userName;
        userDetails.id = user.currentUser.user;
        let disableNext = page === pageData.lastPage ? true : false;
        const columns = [
            {
                Header: 'Transaction ID',
                accessor: 'id',
                width: 200,
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },

            {
                Header: 'Type',
                accessor: 'txnType',

                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Amount',
                accessor: 'amount', // accessor is the "key" in the data

                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Closing Balance',
                accessor: 'cashBalance',
                width: 150,
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Match ID',
                accessor: 'match', // accessor is the "key" in the data
                width: 200,
                Cell: (props) => <span>{props.value === undefined || props.value === '' ? '-' : props.value}</span>,
            },
            {
                Header: 'Contest ID ',
                accessor: 'contest',
                width: 200,
                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
            {
                Header: 'Team ID',
                accessor: 'teams', // accessor is the "key" in the data
                width: 200,

                Cell: (props) => (props.value.length === 0 ? '-' : this.dispTeams(props.value)),
            },
            {
                Header: 'Status',
                accessor: 'status',

                Cell: (props) => (
                    <span className="number">
                        {props.value === undefined || props.value === '' ? '-' : props.value}
                    </span>
                ),
            },
        ];
        return (
            <DetailLayout
                location="userDetails"
                history={this.props.history}
                searchbar="Search Match ID "
                onDeleteClick={this.onDeleteClick}
                search={true}
                id={userDetails}
                onsearchChange={this.onSearchandSort.bind(this)}
                centerTitle="User - Cash Balance"
                sortOptions={sortOptions}
                onReset={this.onReset.bind(this)}
                searchString={searchString}
                sortOrder={sortOrder}
            >
                <Snackbar
                    open={snackbarOpen}
                    message={errorMessage}
                    onClose={() => this.setState({snackbarOpen: false})}
                />
                <LoadingModal open={loading} />

                <Table
                    data={data}
                    columns={columns}
                    page={page}
                    onPrevClick={this.onPrev.bind(this)}
                    onNextClick={this.onNext.bind(this)}
                    disableNext={disableNext}
                ></Table>
            </DetailLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        squad: state.squad,
        player: state.player,
        withdrawal: state.withdrawal,
        user: state.user,
        client: state.client,
    };
};

export default connect(mapStateToProps, {transactionList, logout})(UserBalance);
