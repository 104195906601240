import axios from 'axios';
import {
    listTOMatchModes,
    listTOMatchModesSuccess,
    listTOMatchModesFail,
    addTOMatchMode,
    addTOMatchModeSuccess,
    addTOMatchModeFail,
    updateTOMatchModeRequest,
    updateTOMatchModeSuccess,
    updateTOMatchModeFail,
    deleteTOMatchModeRequest,
    deleteTOMatchModeSuccess,
    deleteTOMatchModeFail,
    getTOMatchModeRequest,
    getTOMatchModeSuccess,
    getTOMatchModeFail,
} from './action';
import {TOURL} from '../../../Utilities/Constants';

export function getTOMatchModeList(pageOptions, token) {
    return (dispatch) => {
        dispatch(listTOMatchModes());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token},
            url: `${TOURL}/matchMode/list`,
            data: pageOptions,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(listTOMatchModesSuccess(data));
                } else {
                    dispatch(listTOMatchModesFail(data.data.errorData.errorReason));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(listTOMatchModesFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(listTOMatchModesFail(err.message || 'Something went wrong'));
                }
            });
    };
}

//creating new TOMatchMode
export function createTOMatchMode(TOMatchMode, token) {
    return (dispatch) => {
        dispatch(addTOMatchMode());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token},
            url: `${TOURL}/matchMode/create`,
            data: TOMatchMode,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(addTOMatchModeSuccess(data));
                } else {
                    dispatch(addTOMatchModeFail(data.data.errorData.errorReason));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(addTOMatchModeFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(addTOMatchModeFail(err.message || 'Something went wrong'));
                }
            });
    };
}

//TOMatchMode update
export function updateTOMatchMode(TOMatchMode, token) {
    return (dispatch) => {
        dispatch(updateTOMatchModeRequest());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token},
            url: `${TOURL}/matchMode/update`,
            data: TOMatchMode,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(updateTOMatchModeSuccess(data));
                } else {
                    dispatch(updateTOMatchModeFail(data.data.errorData.errorReason));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(updateTOMatchModeFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(updateTOMatchModeFail(err.message || 'Something went wrong'));
                }
            });
    };
}

//TOMatchMode delete
export function deleteTOMatchMode(id, token) {
    return (dispatch) => {
        dispatch(deleteTOMatchModeRequest());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token},
            url: `${TOURL}/matchMode/delete`,
            data: {matchModeId: id},
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(deleteTOMatchModeSuccess(data));
                } else {
                    dispatch(deleteTOMatchModeFail(data.data.errorData.errorReason));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(deleteTOMatchModeFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(deleteTOMatchModeFail(err.message || 'Something went wrong'));
                }
            });
    };
}

// get single TOMatchMode
export function getTOMatchMode(id, token) {
    return (dispatch) => {
        dispatch(getTOMatchModeRequest());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token},
            url: `${TOURL}/matchMode/get`,
            data: {
                matchModeId: id,
            },
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(getTOMatchModeSuccess(data));
                } else {
                    dispatch(getTOMatchModeFail(data.data.errorData.errorReason));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(getTOMatchModeFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(getTOMatchModeFail(err.message || 'Something went wrong'));
                }
            });
    };
}
