import React from 'react';
import PropTypes from 'prop-types';
import {Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel} from '@material-ui/core';
import Button from '../../../../Components/Button/Button';

import Snackbar from '../../../../Components/Snackbar/Snackbar';
import {connect} from 'react-redux';
import {grantDiscountCodeToUsers} from '../AxioCalls';
import FileInput from '../../../../Components/FileInput/FileInput';
import {
    commaSeparatedStringToArray,
    convertXlsxToJson,
    downloadXLSXFromJson,
} from '../../../../Utilities/HelperFunctions';
import LoadingModal from '../../../../Components/LoadingModal/LoadingModal';
import Input from '../../../../Components/Input/Input';

class GrantDiscountCodeModal extends React.Component {
    static propTypes = {
        open: PropTypes.bool,
        handleClose: PropTypes.func,
        discountCode: PropTypes.object,
    };

    static defaultProps = {
        handleClose: () => {},
        open: false,
        discountCode: {},
    };

    submitRef = React.createRef();

    constructor(props) {
        super(props);
        const {defaultNoOfCouponsPerUser} = props.discountCode;
        this.state = {
            errorMessage: '',
            sendNotification: false,
            defaultNoOfCouponsPerUser,
        };
    }

    componentDidMount() {}

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {
            discount,
            discount: {isGrantingDiscountCodeFail, errorMessage},
        } = this.props;

        if (isGrantingDiscountCodeFail && !prevProps.discount.isGrantingDiscountCodeFail) {
            this.setState({errorMessage: errorMessage.errorReason});
            this.handleErrorUserIdsFile(errorMessage.data);
        }
    }

    handleOnUploadFile = (file) => {
        convertXlsxToJson(file, (json) => {
            for (const u of json) {
                if (!u.userId || !u.forceGrant) {
                    this.setState({
                        errorMessage:
                            'userId and forceGrant columns are mandatory and forceGrant must be YES or NO only. Or Please download sample file',
                    });
                    return;
                }
            }
            const listOfUsers = json.map((u) => ({...u, forceGrant: u.forceGrant.toUpperCase()}));
            this.setState({listOfUsers});
        });
    };

    handleOnChange = (key, value) => {
        this.setState({[key]: value});
    };

    handleSubmit = (e) => {
        !!e && e.preventDefault();
        const {login, discountCode, client} = this.props;
        const {
            listOfUsers,
            contestIds = [],
            sendNotification,
            notificationTitle,
            notificationMessage,
            defaultNoOfCouponsPerUser,
        } = this.state;
        let data = {
            discountCode: discountCode.code,
            listOfUsers,
            defaultNoOfCouponsPerUser: parseInt(defaultNoOfCouponsPerUser),
        };
        if (contestIds.length) data.contestIds = commaSeparatedStringToArray(contestIds);
        if (sendNotification) data = {...data, sendNotification, notificationTitle, notificationMessage};

        this.props.grantDiscountCodeToUsers(data, login.userDetails.data.accessToken, client.selectedClient);
    };

    handleDownloadSampleFile = () => {
        const data = [
            {userId: '61fb794b416b351f261ffd12', forceGrant: 'NO', noOfCoupon: ''},
            {userId: '61e71cad86f77d1093f39b11', forceGrant: 'YES', noOfCoupon: ''},
        ];
        downloadXLSXFromJson('userIdFile', data);
    };

    handleErrorUserIdsFile = (data) => {
        if (data && data.length) {
            downloadXLSXFromJson('discount-code', data);
        }
    };

    render() {
        const {
            handleClose,
            open,
            discount,
            discountCode: {applicabilityType},
        } = this.props;
        const {
            errorMessage,
            sendNotification,
            notificationTitle,
            notificationMessage,
            contestIds,
            defaultNoOfCouponsPerUser,
        } = this.state;

        return (
            <Dialog
                open={open}
                onClose={handleClose}
                scroll="paper"
                maxWidth="sm"
                fullWidth
                disableEscapeKeyDown
                disableBackdropClick
            >
                <Snackbar
                    open={!!errorMessage}
                    message={errorMessage}
                    onClose={() => this.setState({errorMessage: ''})}
                />
                <LoadingModal open={discount.isGrantingDiscountCode} />

                <DialogTitle>Grant Discount Code To Users</DialogTitle>
                <DialogContent dividers={true}>
                    <form onSubmit={this.handleSubmit}>
                        <FileInput
                            className="mb-15"
                            label={'Upload xlsx file for user ids'}
                            name="listOfUsers"
                            accept=".xlsx"
                            required
                            onClickSampleFile={this.handleDownloadSampleFile}
                            onChange={this.handleOnUploadFile}
                        />

                        {!!applicabilityType && applicabilityType === 'CONTEST_SPECIFIC' && (
                            <Input
                                className="mb-15"
                                label="Contest ids"
                                type="text"
                                name="contestIds"
                                required
                                value={contestIds || ''}
                                placeholder="Enter comma separated ids"
                                onChange={(value) => this.handleOnChange('contestIds', value)}
                                helperText="Comma separated contest Ids. Ex: 1212gfg23232f,12h1j2h12jh2h3hb"
                            />
                        )}

                        <Input
                            className="mb-15"
                            label="No Of Coupons Per User"
                            type="number"
                            name="defaultNoOfCouponsPerUser"
                            required
                            value={defaultNoOfCouponsPerUser || ''}
                            placeholder="Enter No Of Coupons Per User"
                            onChange={(value) => this.handleOnChange('defaultNoOfCouponsPerUser', value)}
                        />

                        <FormControlLabel
                            className="mb-15"
                            label="Also Send Notification To The User's"
                            control={
                                <Checkbox
                                    checked={sendNotification}
                                    onChange={(e) => this.handleOnChange('sendNotification', e.target.checked)}
                                />
                            }
                        />

                        {sendNotification && (
                            <Input
                                className="mb-15"
                                label="Notification Title"
                                type="text"
                                name="notificationTitle"
                                required
                                value={notificationTitle || ''}
                                placeholder="Enter Notification Title"
                                onChange={(value) => this.handleOnChange('notificationTitle', value)}
                            />
                        )}

                        {sendNotification && (
                            <Input
                                className="mb-15"
                                label="Expert Notification Message"
                                type="text"
                                name="notificationMessage"
                                required
                                value={notificationMessage || ''}
                                placeholder="Enter Notification Message"
                                onChange={(value) => this.handleOnChange('notificationMessage', value)}
                            />
                        )}

                        <Button className="d-none" buttonText="Submit" type="submit" buttonRef={this.submitRef} />
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button buttonText="Cancel" onClick={handleClose} />
                    <Button buttonText="Submit" onClick={() => this.submitRef.current.click()} />
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        discount: state.discount,
        client: state.client,
    };
};

export default connect(mapStateToProps, {grantDiscountCodeToUsers})(GrantDiscountCodeModal);
