import {
    TOORGANIZER_LIST,
    TOORGANIZER_LIST_SUCCESS,
    TOORGANIZER_LIST_FAIL,
    ADD_TOORGANIZER,
    ADD_TOORGANIZER_SUCCESS,
    ADD_TOORGANIZER_FAIL,
    UPDATE_TOORGANIZER,
    UPDATE_TOORGANIZER_SUCCESS,
    UPDATE_TOORGANIZER_FAIL,
    DELETE_TOORGANIZER,
    DELETE_TOORGANIZER_SUCCESS,
    DELETE_TOORGANIZER_FAIL,
    GET_TOORGANIZER,
    GET_TOORGANIZER_SUCCESS,
    GET_TOORGANIZER_FAIL,
    EDIT_TOORGANIZER,
} from './Constants';

const initialState = {
    TOOrganizerListRequest: false,
    TOOrganizerListSuccess: false,
    TOOrganizerListFail: false,
    TOOrganizerList: [],
    errorMessage: '',
    addTOOrganizerRequest: false,
    addTOOrganizerSuccess: false,
    addTOOrganizerFail: false,
    addTOOrganizer: [],
    updateTOOrganizerRequest: false,
    updateTOOrganizerSuccess: false,
    updateTOOrganizerFail: false,
    updateTOOrganizer: [],
    deleteTOOrganizerRequest: false,
    deleteTOOrganizerSuccess: false,
    deleteTOOrganizerFail: false,
    deleteTOOrganizer: [],
    getTOOrganizerRequest: false,
    getTOOrganizerSuccess: false,
    getTOOrganizerFail: false,
    getTOOrganizer: [],
    editTOOrganizer: [],
};

const toOrganizerReducer = (state = initialState, action) => {
    switch (action.type) {
        case TOORGANIZER_LIST:
            return {
                ...state,
                TOOrganizerListRequest: true,
                TOOrganizerListSuccess: false,
                TOOrganizerListFail: false,
                TOOrganizerList: [],
                errorMessage: '',
            };

        case TOORGANIZER_LIST_SUCCESS:
            // console.log('Success case is being triggered');
            return {
                ...state,
                TOOrganizerListRequest: false,
                TOOrganizerListSuccess: true,
                TOOrganizerListFail: false,
                TOOrganizerList: action.payload.data,
                errorMessage: '',
            };

        case TOORGANIZER_LIST_FAIL:
            return {
                ...state,
                TOOrganizerListRequest: false,
                TOOrganizerListSuccess: false,
                TOOrganizerListFail: true,
                errorMessage: action.payload,
            };
        // ADD match
        case ADD_TOORGANIZER:
            return {
                ...state,
                addTOOrganizerRequest: false,
                addTOOrganizerSuccess: false,
                addTOOrganizerFail: false,
                addTOOrganizer: [],
                errorMessage: '',
            };

        case ADD_TOORGANIZER_SUCCESS:
            return {
                ...state,
                addTOOrganizerRequest: false,
                addTOOrganizerSuccess: true,
                addTOOrganizerFail: false,
                addTOOrganizer: action.payload.data,
                errorMessage: '',
            };

        case ADD_TOORGANIZER_FAIL:
            return {
                ...state,
                addTOOrganizerRequest: false,
                addTOOrganizerSuccess: false,
                addTOOrganizerFail: true,
                errorMessage: action.payload,
            };
        //update TOOrganizer
        case UPDATE_TOORGANIZER:
            return {
                ...state,
                updateTOOrganizerRequest: true,
                updateTOOrganizerSuccess: false,
                updateTOOrganizerFail: false,
                updateTOOrganizer: [],
                errorMessage: '',
            };

        case UPDATE_TOORGANIZER_SUCCESS:
            return {
                ...state,
                updateTOOrganizerRequest: false,
                updateTOOrganizerSuccess: true,
                updateTOOrganizerFail: false,
                updateTOOrganizer: action.payload.data,
                errorMessage: '',
            };

        case UPDATE_TOORGANIZER_FAIL:
            return {
                ...state,
                updateTOOrganizerRequest: false,
                updateTOOrganizerSuccess: false,
                updateTOOrganizerFail: true,
                errorMessage: action.payload,
            };

        // DELETE TOORGANIZER
        case DELETE_TOORGANIZER:
            return {
                ...state,
                deleteTOOrganizerRequest: true,
                deleteTOOrganizerSuccess: false,
                deleteTOOrganizerFail: false,
                deleteTOOrganizer: [],
                errorMessage: '',
            };

        case DELETE_TOORGANIZER_SUCCESS:
            return {
                ...state,
                deleteTOOrganizerRequest: false,
                deleteTOOrganizerSuccess: true,
                deleteTOOrganizerFail: false,
                deleteTOOrganizer: action.payload.data,
                errorMessage: '',
            };

        case DELETE_TOORGANIZER_FAIL:
            return {
                ...state,
                deleteTOOrganizerRequest: false,
                deleteTOOrganizerSuccess: false,
                deleteTOOrganizerFail: true,
                errorMessage: action.payload,
            };

        // get single TOOrganizer
        case GET_TOORGANIZER:
            return {
                ...state,
                getTOOrganizerRequest: true,
                getTOOrganizerSuccess: false,
                getTOOrganizerFail: false,
                getTOOrganizer: [],
                errorMessage: '',
            };

        case GET_TOORGANIZER_SUCCESS:
            return {
                ...state,
                getTOOrganizerRequest: false,
                getTOOrganizerSuccess: true,
                getTOOrganizerFail: false,
                getTOOrganizer: action.payload.data,
                errorMessage: '',
            };

        case GET_TOORGANIZER_FAIL:
            return {
                ...state,
                getTOOrganizerRequest: false,
                getTOOrganizerSuccess: false,
                getTOOrganizerFail: true,
                errorMessage: action.payload,
            };
        // EDIT TOORGANIZER
        case EDIT_TOORGANIZER:
            return {
                ...state,
                editTOOrganizer: action.payload,
            };

        default:
            return state;
    }
};

export default toOrganizerReducer;
