/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {Avatar, Box, Checkbox, FormControlLabel, IconButton, styled} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import AddEditLayout from '../../../../layout/AddEditLayout/AddEditLayout';
import MuiButton from '../../../../Components/MuiButton/MuiButton';
import MuiInputField from '../../../../Components/MuiInputField/MuiInputField';
import styles from './AddEditMatch.module.scss';
import {createMatch} from '../AxioCalls';
import Snackbar from '../../../../Components/Snackbar/Snackbar';
import {usePrevious} from '../../../../Utilities/hooks';
import classNames from 'classnames';
import moment from 'moment';
import {ColorPalette} from '../../../../Utilities/constants/color-constants';
import MuiSelectField from '../../../../Components/MuiSelectField/MuiSelectField';
import {getFantasyScoreModel} from '../../FantasyConfig/FantasyAxios';
import MuiAutocompleteField from '../../../../Components/MuiAutocompleteField/MuiAutoCompleteField';
import {CRICKET_FORMAT_OPTIONS, FANTASY_TYPES_OPTIONS} from '../../../../Utilities/constants/options-constants';
import {fetchBoosterConfigs, fetchMultiplierConfigs} from '../../Boosters/action';
import {debounce} from '../../../../Utilities/HelperFunctions';
import CustomFileUploadField from '../../../../Components/CustomFileUploadField/CustomFileUploadField';
import apis from '../../../../apiCalls/apis';
import {fetchCommonMatch} from '../../../../containers/Matches/CommonMatches/actions';
import {getTOMatchModeList} from '../../TOMatchMode/AxioCalls';
import {GameUniqueCodeLabels, SquadGamesList} from '../../../../Utilities/constants/label-constants';
import {fetchSwitchConfigsList} from '../../../../containers/Switch/actions';

const AddEditMatch = (props) => {
    const {history} = props;
    const dispatch = useDispatch();
    const client = useSelector((state) => state.client);
    const login = useSelector((state) => state.login);
    const commonMatchState = useSelector((state) => state.commonMatch);
    const prevCommonMatchState = usePrevious(commonMatchState);
    const match = useSelector((state) => state.match);
    const prevMatch = usePrevious(match);
    const toMatchMode = useSelector((state) => state.toMatchMode);
    const prevToMatchMode = usePrevious(toMatchMode);
    const fantasyConfig = useSelector((state) => state.fantasyConfig);
    const prevFantasyConfig = usePrevious(fantasyConfig);
    const boosters = useSelector((state) => state.boosters);
    const prevBoosters = usePrevious(boosters);
    const switchConfig = useSelector((state) => state.switchConfig);
    const prevSwitchConfig = usePrevious(switchConfig);
    const [errorMessage, setErrorMessage] = useState(null);
    const [commonMatchFormVisible, setCommonMatchFormVisible] = useState(true);
    const [addEditFormVisible, setAddEditFormVisible] = useState(false);
    const [matchModeOptions, setMatchModeOptions] = useState([]);
    const [boosterConfigOptions, setBoosterConfigOptions] = useState([]);
    const [multiplierConfigOptions, setMultiplierConfigOptions] = useState([]);
    const [switchConfigOptions, setSwitchConfigOptions] = useState([]);
    const [commonMatch, setCommonMatch] = useState(null);
    const [formState, setFormState] = useState({});
    const {
        commonMatchId,
        game,
        tournament,
        startTime,
        matchName,
        glacierClassType,
        glacierClassId,
        squads,
        fantasyType = '',
        matchMode = '',
        fantasyScoring,
        maxPlayersPerSquad,
        numberOfCredits,
        maxTeamSize,
        selectedBoosterConfig,
        selectedMultiplierConfig,
        rankForSorting = '99',
        enableViceCaptain = false,
        liveCoverage = false,
        matchImage,
        substitution = 'AUTO',
        streamLink,
        mapName,
        matchStatus,
        lineupStatus,
        cricketFormat = '',
        selectedSwitchConfig,
        maxTeamsAllowedForSwitch,
        switchFees,
        maxSwitchesAllowed,
    } = formState;

    useEffect(() => {
        console.log('FORM_STATE', formState);
    }, [formState]);

    useEffect(() => {
        // console.log("COMMON_MATCH", commonMatchState);
        if (commonMatchState.fetchCommonMatchSuccess && !prevCommonMatchState.fetchCommonMatchSuccess) {
            const {
                id,
                game,
                tournament,
                startTime,
                matchName,
                glacierClassType,
                glacierClassId,
                squads,
                mapName,
                status,
                lineupStatus,
            } = commonMatchState?.commonMatch;
            setFormState({
                ...formState,
                commonMatchId: id,
                game: game,
                tournament: tournament,
                startTime: startTime,
                matchName: matchName,
                glacierClassType: glacierClassType,
                glacierClassId: glacierClassId,
                squads: squads,
                mapName: mapName,
                matchStatus: status,
                lineupStatus: lineupStatus,
            });
            dispatch(
                getTOMatchModeList({listType: 'ALL'}, login?.userDetails?.data?.accessToken, client.selectedClient),
            );
            setCommonMatchFormVisible(false);
            setAddEditFormVisible(true);
        }
        if (commonMatchState.fetchCommonMatchFailure && !prevCommonMatchState.fetchCommonMatchFail) {
            setErrorMessage(commonMatchState.message);
        }
    }, [commonMatchState]);

    useEffect(() => {
        if (toMatchMode.TOMatchModeListSuccess && !prevToMatchMode.TOMatchModeListSuccess) {
            const _matchModeOptions = toMatchMode?.TOMatchModeList?.data?.data
                ?.filter((item) => item.game === game?.id)
                .map((item) => ({text: item.modeName, value: item.id}));
            setMatchModeOptions(_matchModeOptions || []);
        }
        if (toMatchMode.TOMatchModeListFail && !prevToMatchMode.TOMatchModeListFail) {
            setErrorMessage(toMatchMode.errorMessage);
        }
    }, [toMatchMode]);

    useEffect(() => {
        if (match.addmatchSuccess && !prevMatch.addmatchSuccess) {
            setErrorMessage('Match saved!');
            history.push('/matches');
        }
        if (match.addmatchFail && !prevMatch.addmatchFail) {
            setErrorMessage(match.errorMessage);
        }
    }, [match]);

    useEffect(() => {
        // console.log("FANTASY_CONFIG", fantasyConfig);
        if (fantasyConfig.getScoringSuccess && !prevFantasyConfig.getScoringSuccess) {
            handleFormData({
                fantasyScoring: {text: fantasyConfig.getScoring.data.name, value: fantasyConfig.getScoring.data.id},
            });
        }
        if (fantasyConfig.getScoringFail && !prevFantasyConfig.getScoringFail) {
            setErrorMessage(fantasyConfig.errorMessage);
        }
    }, [fantasyConfig]);

    useEffect(() => {
        // console.log("BOOSTERS", boosters);
        if (boosters.isFetchingBoosterConfigsSuccess && !prevBoosters.isFetchingBoosterConfigsSuccess) {
            const tempList =
                boosters?.boosterConfigsList?.data?.map((config) => ({label: config.name, value: config.id})) || [];
            setBoosterConfigOptions(tempList);
        }
        if (boosters.isFetchingBoosterConfigsFail && !prevBoosters.isFetchingBoosterConfigsFail) {
            setErrorMessage(boosters?.message?.msg);
        }
        if (boosters.isFetchingMultiplierConfigsSuccess && !prevBoosters.isFetchingMultiplierConfigsSuccess) {
            const tempList =
                boosters?.multiplierConfigsList?.map((config) => ({label: config.name, value: config.id})) || [];
            setMultiplierConfigOptions(tempList);
        }
        if (boosters.isFetchingMultiplierConfigsFail && !prevBoosters.isFetchingMultiplierConfigsFail) {
            setErrorMessage(boosters?.message?.msg);
        }
    }, [boosters]);

    useEffect(() => {
        // console.log("SWITCH_CONFIG", switchConfig);
        if (switchConfig.fetchSwitchConfigsListSuccess && !prevSwitchConfig.fetchSwitchConfigsListSuccess) {
            const tempList =
                switchConfig?.switchConfigsList?.map((config) => ({text: config.name, value: config.id})) || [];
            setSwitchConfigOptions(tempList);
        }
        if (switchConfig.fetchSwitchConfigsListFailure && !prevSwitchConfig.fetchSwitchConfigsListFailure) {
            setErrorMessage(switchConfig?.message);
        }
    }, [switchConfig]);

    const handleCommonMatchFormSubmit = (_event) => {
        !!_event && _event.preventDefault();
        const data = {matchId: commonMatch};
        dispatch(fetchCommonMatch(data, login?.userDetails?.data?.accessToken, client.selectedClient));
    };

    const fetchPlayerIds = (_squads) => {
        const playerIds = [];

        for (const _squad of _squads) {
            const activePlayers = _squad?.players?.filter((player) => player.status === 'ACTIVE');
            for (const _player of activePlayers) {
                playerIds.push(_player._id);
            }
        }

        return JSON.stringify(playerIds);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const squadIds = squads?.map((squad) => squad._id);

        const data = {
            // common match data
            commonMatchId,
            gameId: game?.id,
            startTime: moment(startTime).valueOf(),
            tournamentId: tournament?.id,
            tournamentStatus: tournament?.status,
            matchName,
            glacierClassType,
            glacierClassId,
            mapName,
            lineupStatus,
            squads: JSON.stringify(squadIds),
            players: fetchPlayerIds(squads),

            // input fantasy details
            fantasyType,
            boosterConfigId: selectedBoosterConfig ? selectedBoosterConfig.value : null,
            multiplierConfigId: selectedMultiplierConfig ? selectedMultiplierConfig.value : null,
            substitution,
            selectedSwitchConfig,
            substitutionData: {
                teamsCountLimit: !!maxTeamsAllowedForSwitch && parseInt(maxTeamsAllowedForSwitch),
                totalSubsAllowed: !!maxSwitchesAllowed && parseInt(maxSwitchesAllowed),
                subsFee: !!switchFees ? switchFees : [],
            },
            streamLink: streamLink ? streamLink : null,
            matchMode,
            fantasyScoring: fantasyScoring?.value,
            numberOfCredits: parseInt(numberOfCredits),
            maxPlayersPerSquad: parseInt(maxPlayersPerSquad),
            maxTeamSize: parseInt(maxTeamSize),
            rankForSorting: parseInt(rankForSorting),
            cricketFormat: cricketFormat ? cricketFormat : null,
            isNewRoleActivated: !!enableViceCaptain,
            liveCoverage: !!liveCoverage,
            matchImage,

            // expertAnalysisId: expertAnalysisId ? expertAnalysisId : null,
            // playersPlayingInfo: playerLineupStatus,
            // lineupOrder: lineupOrder,
            // squadOdds,
        };
        // console.log("FINAL_FORM_STATE", formState);
        // console.log("SUBMIT_DATA", data);
        dispatch(createMatch(data, login?.userDetails?.data?.accessToken, client.selectedClient));
    };
    const handleReset = () => {
        setFormState({});
    };

    const handleFantasyTypeChange = (event) => {
        if (event.target.value === 'SWITCH') {
            dispatch(
                fetchSwitchConfigsList({listType: 'ALL'}, login?.userDetails?.data?.accessToken, client.selectedClient),
            );
        }
        handleFormData({fantasyType: event.target.value, liveCoverage: event.target.value === 'SWITCH' ? true : false});
    };

    const handleSwitchConfigChange = (event) => {
        const currentSwitchConfig = switchConfig?.switchConfigsList?.filter(
            (config) => config.id === event.target.value,
        )?.[0];
        handleFormData({
            selectedSwitchConfig: event.target.value,
            maxTeamsAllowedForSwitch: currentSwitchConfig.teamsCountLimit ? currentSwitchConfig.teamsCountLimit : '',
            maxSwitchesAllowed: currentSwitchConfig.totalSubsAllowed ? currentSwitchConfig.totalSubsAllowed : '',
            switchFees: currentSwitchConfig.subsFee ? currentSwitchConfig.subsFee : [],
        });
    };

    const handleMatchModeChange = (event) => {
        const currentMatchMode = toMatchMode.TOMatchModeList.data.data.filter(
            (item) => item.id === event.target.value,
        )[0];

        handleFormData({
            matchMode: event.target.value,
            maxPlayersPerSquad: currentMatchMode.maxPlayersPerSquad ? currentMatchMode.maxPlayersPerSquad : '',
            numberOfCredits: currentMatchMode.numberOfCredits ? currentMatchMode.numberOfCredits : '',
            maxTeamSize: currentMatchMode.maxFantasyTeamSize ? currentMatchMode.maxFantasyTeamSize : '',
        });
        dispatch(
            getFantasyScoreModel(
                {
                    game: game?.id,
                    matchMode: event.target.value,
                },
                login?.userDetails?.data?.accessToken,
            ),
        );
    };

    const handleFormData = (formObj) => {
        setFormState({...formState, ...formObj});
    };

    const handleBoosterConfigInputChange = (_, value) => {
        if (value) handleFetchBoosterConfigs(value);
    };

    const handleFetchBoosterConfigs = debounce((_name) => {
        const data = {
            name: _name,
            gameId: commonMatchState?.commonMatch?.game?.id,
            status: 'ACTIVE',
        };
        dispatch(fetchBoosterConfigs(data, login?.userDetails?.data?.accessToken, client.selectedClient));
    });

    const handleMultiplierConfigInputChange = (_, value) => {
        if (value) handleFetchMultiplierConfigs(value);
    };

    const handleFetchMultiplierConfigs = debounce((_name) => {
        const data = {
            name: _name,
            gameId: commonMatchState?.commonMatch?.game?.id,
            status: 'ACTIVE',
        };
        dispatch(fetchMultiplierConfigs(data, login?.userDetails?.data?.accessToken, client.selectedClient));
    });

    const handleMatchImageChange = async (file) => {
        if (!file) return;
        const data = new FormData();
        data.append('image', file);
        try {
            const response = await apis.commonUploadImage(data, login?.userDetails?.data?.accessToken);
            if (response?.data?.status === 'success') {
                handleFormData({matchImage: response?.data?.data?.image});
            } else if (response?.data?.status === 'fail') {
                setErrorMessage(response.data.errorData.errorReason);
            }
        } catch (e) {
            setErrorMessage(e.message);
        }
    };

    return (
        <AddEditLayout history={history} onClickBack={() => history.push('/matches')} mainClassName={styles.main}>
            {!!errorMessage && (
                <Snackbar open={!!errorMessage} message={errorMessage} onClose={() => setErrorMessage(null)} />
            )}
            {/* main header section */}
            <section className={styles.mainHeader}>
                <h1 className={styles.pageTitle}>{`Add Match`}</h1>
                {!!commonMatchFormVisible && (
                    <Box component="form" onSubmit={handleCommonMatchFormSubmit} className={styles.commonMatchForm}>
                        <MuiInputField
                            className={styles.commonMatchField}
                            label="Common Match ID"
                            name="commonMatch"
                            onChange={(event) => setCommonMatch(event.target.value)}
                            size="small"
                            required={true}
                            value={commonMatch || ''}
                            onEnter={handleCommonMatchFormSubmit}
                        />
                        <MuiButton type="submit" buttonText="Get Details" />
                    </Box>
                )}
            </section>
            {/* main body section */}
            {!!addEditFormVisible && (
                <section className={styles.mainBody}>
                    <Box component="form" onSubmit={handleSubmit} onReset={handleReset} className={styles.addMatchForm}>
                        {/* add match button group */}
                        <div className={styles.addMatchButtonGroup}>
                            <div className={styles.formButtons}>
                                <MuiButton type="submit" buttonText="Save" />
                                <MuiButton type="reset" buttonText="Reset" />
                            </div>
                        </div>
                        {/* match summary container */}
                        <div className={classNames(styles.container, styles.matchSummaryContainer)}>
                            <MuiInputField
                                label="Common Match ID"
                                name="commonMatchId"
                                readOnly={true}
                                size="small"
                                value={commonMatchId || ''}
                            />
                            <MuiInputField
                                label="Game"
                                name="game"
                                readOnly={true}
                                size="small"
                                value={game ? game.gameName : ''}
                            />
                            <MuiInputField
                                label="Start Time"
                                name="startTime"
                                readOnly={true}
                                size="small"
                                value={startTime ? moment(startTime).format('DD/MM/YYYY HH:mm:ss') : ''}
                            />
                            <MuiInputField
                                label="Match Status"
                                name="matchStatus"
                                readOnly={true}
                                size="small"
                                value={matchStatus || ''}
                            />
                            <MuiInputField
                                label="Tournament"
                                name="tournament"
                                readOnly={true}
                                size="small"
                                value={tournament ? tournament.tournamentName : ''}
                            />
                            <MuiInputField
                                label="Match Name"
                                name="match"
                                readOnly={true}
                                size="small"
                                value={matchName || ''}
                            />
                            <MuiInputField
                                label="Glacier Class Type"
                                name="glacierClassType"
                                readOnly={true}
                                size="small"
                                value={glacierClassType || ''}
                            />
                            <MuiInputField
                                label="Glacier Class ID"
                                name="glacierClassId"
                                readOnly={true}
                                size="small"
                                value={glacierClassId || ''}
                            />
                            <MuiInputField
                                label="Map Name"
                                name="mapName"
                                readOnly={true}
                                size="small"
                                value={mapName || ''}
                            />
                            <MuiInputField
                                label="Lineup Status"
                                name="lineupStatus"
                                readOnly={true}
                                size="small"
                                value={lineupStatus || ''}
                            />
                        </div>
                        {/* squads container */}
                        <div className={classNames(styles.container, styles.squadsContainer)}>
                            <div className={styles.squadsWrapper}>
                                {squads?.length > 0 &&
                                    squads.map((squad) => (
                                        <div key={squad._id} className={styles.oneVsOneWrapper}>
                                            <div className={styles.squadHeader}>
                                                <span className={styles.squadDetails}>
                                                    <h3 className={styles.squadName}>{squad?.squadName}</h3>
                                                </span>
                                                <span className={styles.squadButtonGroup}></span>
                                            </div>
                                            <div className={styles.playersList}>
                                                {squad?.players?.length > 0 &&
                                                    squad.players
                                                        .filter((player) => player.status === 'ACTIVE')
                                                        .map((player, index) => (
                                                            <div key={player._id} className={styles.playerItem}>
                                                                <div className={styles.playerDetails}>
                                                                    <Avatar className={styles.playerImage} />
                                                                    <span className={styles.playerName}>
                                                                        <p className={styles.playerNameKey}>{`Player ${
                                                                            index + 1
                                                                        }`}</p>
                                                                        <p className={styles.playerNameValue}>
                                                                            {player?.pubgName}
                                                                        </p>
                                                                    </span>
                                                                </div>
                                                                {!!player.credits && (
                                                                    <div className={styles.playerCredits}>
                                                                        <p className={styles.playerCreditsKey}>
                                                                            Credits
                                                                        </p>
                                                                        <p className={styles.playerCreditsValue}>
                                                                            {player?.credits}
                                                                        </p>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        ))}
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                        {/* fantasy details container */}
                        <div className={classNames(styles.container, styles.fantasyDetailsContainer)}>
                            <div className={styles.fantasyDetailsForm}>
                                <span className={styles.fantasyTypeWrapper}>
                                    <MuiSelectField
                                        label="Fantasy Type"
                                        name="fantasyType"
                                        onChange={handleFantasyTypeChange}
                                        options={FANTASY_TYPES_OPTIONS}
                                        value={fantasyType}
                                        size="small"
                                    />
                                    {!SquadGamesList.includes(game.gameUniqueCode) && fantasyType === 'NORMAL' && (
                                        <MuiAutocompleteField
                                            id="selectedBoosterConfig"
                                            label="Booster Config"
                                            onInputChange={handleBoosterConfigInputChange}
                                            onChangeField={(selectedOption) =>
                                                handleFormData({selectedBoosterConfig: selectedOption})
                                            }
                                            options={boosterConfigOptions}
                                            size="small"
                                            value={selectedBoosterConfig || null}
                                        />
                                    )}
                                    {SquadGamesList.includes(game.gameUniqueCode) && fantasyType === 'NORMAL' && (
                                        <MuiAutocompleteField
                                            id="selectedMultiplierConfig"
                                            label="Multiplier Config"
                                            onInputChange={handleMultiplierConfigInputChange}
                                            onChangeField={(selectedOption) =>
                                                handleFormData({selectedMultiplierConfig: selectedOption})
                                            }
                                            options={multiplierConfigOptions}
                                            size="small"
                                            value={selectedMultiplierConfig || null}
                                        />
                                    )}
                                    {fantasyType === 'SWITCH' && (
                                        <MuiSelectField
                                            label="Switch Config"
                                            name="selectedSwitchConfig"
                                            onChange={handleSwitchConfigChange}
                                            options={switchConfigOptions}
                                            required={true}
                                            size="small"
                                            value={selectedSwitchConfig || ''}
                                        />
                                    )}
                                    {!!selectedSwitchConfig && (
                                        <span className={styles.switchConfigData}>
                                            <span>
                                                <p>Max Teams Allowed for Switch:</p>
                                                <p>{maxTeamsAllowedForSwitch}</p>
                                            </span>
                                            <span>
                                                <p>Max Switches Allowed:</p>
                                                <p>{maxSwitchesAllowed}</p>
                                            </span>
                                            <span>
                                                <p>Switch Fees:</p>
                                                <p>{switchFees?.join(', ')}</p>
                                            </span>
                                        </span>
                                    )}
                                </span>
                                <MuiInputField
                                    label="Substitution"
                                    name="substitution"
                                    readOnly={true}
                                    size="small"
                                    value={substitution || ''}
                                />
                                <MuiInputField
                                    label="Stream Link"
                                    name="streamLink"
                                    onChange={(event) => handleFormData({streamLink: event.target.value})}
                                    size="small"
                                    value={streamLink || ''}
                                />
                                <MuiSelectField
                                    label="Match Mode"
                                    name="matchMode"
                                    onChange={handleMatchModeChange}
                                    options={matchModeOptions}
                                    required={true}
                                    value={matchMode}
                                    size="small"
                                />
                                <MuiInputField
                                    label="Fantasy Scoring"
                                    name="fantasyScoring"
                                    readOnly={true}
                                    size="small"
                                    value={fantasyScoring ? fantasyScoring.text : ''}
                                />
                                <MuiInputField
                                    label="Total Credits"
                                    name="numberOfCredits"
                                    required={true}
                                    onChange={(event) => handleFormData({numberOfCredits: event.target.value})}
                                    size="small"
                                    value={numberOfCredits || ''}
                                />
                                <MuiInputField
                                    label="Max Players per Squad"
                                    name="maxPlayersPerSquad"
                                    required={true}
                                    onChange={(event) => handleFormData({maxPlayersPerSquad: event.target.value})}
                                    size="small"
                                    value={maxPlayersPerSquad || ''}
                                />
                                <MuiInputField
                                    label="Max Team Size"
                                    name="maxTeamSize"
                                    required={true}
                                    onChange={(event) => handleFormData({maxTeamSize: event.target.value})}
                                    size="small"
                                    value={maxTeamSize || ''}
                                />
                                <MuiInputField
                                    label="Rank for Sorting"
                                    name="rankForSorting"
                                    required={true}
                                    onChange={(event) => handleFormData({rankForSorting: event.target.value})}
                                    size="small"
                                    value={rankForSorting || ''}
                                />
                                {game.gameUniqueCode === GameUniqueCodeLabels.CRICKET && (
                                    <MuiSelectField
                                        label="Cricket Format"
                                        name="cricketFormat"
                                        onChange={(event) => handleFormData({cricketFormat: event.target.value})}
                                        options={CRICKET_FORMAT_OPTIONS}
                                        value={cricketFormat}
                                        size="small"
                                    />
                                )}
                                <span className={styles.checkboxWrapper}>
                                    <FormControlLabel
                                        label="Enable Vice Captain"
                                        control={
                                            <Checkbox
                                                checked={!!enableViceCaptain}
                                                name="enableViceCaptain"
                                                onChange={(event) =>
                                                    handleFormData({enableViceCaptain: event.target.checked})
                                                }
                                                value={!!enableViceCaptain}
                                            />
                                        }
                                    />
                                    <FormControlLabel
                                        label="Live Coverage"
                                        control={
                                            <Checkbox
                                                checked={!!liveCoverage}
                                                name="liveCoverage"
                                                onChange={(event) =>
                                                    handleFormData({liveCoverage: event.target.checked})
                                                }
                                                value={!!liveCoverage}
                                            />
                                        }
                                    />
                                </span>
                                <CustomFileUploadField
                                    className={styles.matchImageUploadField}
                                    accept="image/*"
                                    type="file"
                                    label="Match image for Home Screen"
                                    name="matchImage"
                                    onChangeInput={handleMatchImageChange}
                                    sampleFileLinkVisible={false}
                                    value={matchImage || ''}
                                />
                            </div>
                        </div>
                        {/* contests container */}
                        <div className={classNames(styles.container, styles.contestsContainer)}></div>
                    </Box>
                </section>
            )}
        </AddEditLayout>
    );
};

const CustomIconButton = styled(IconButton)({
    width: '28px',
    height: '28px',

    '&:hover': {
        backgroundColor: ColorPalette.NIGHT_RIDER,
    },
});

export default AddEditMatch;
