export default {
    FONT_8: 8,
    FONT_10: 10,
    FONT_12: 12,
    FONT_14: 14,
    FONT_16: 16,
    FONT_18: 18,
    FONT_20: 20,
    FONT_22: 22,
    FONT_24: 24,
    FONT_28: 28,
    FONT_32: 32,

    // Fonts
    FONT_NUNITO_SANS_BOLD: 'NunitoSans-Bold',
    FONT_NUNITO_SANS_REGULAR: 'NunitoSans_regular',
};
