import {Box, IconButton, styled} from '@material-ui/core';
import {Close, Refresh, Save} from '@material-ui/icons';
import React, {useState} from 'react';
import MuiButton from '../../../../Components/MuiButton/MuiButton';
import MuiInputField from '../../../../Components/MuiInputField/MuiInputField';
import MuiModal from '../../../../Components/MuiModal/MuiModal';
import {ColorPalette} from '../../../../Utilities/constants/color-constants';
import styles from './EngagementCenterModal.module.scss';

const EngagementCenterModal = (props) => {
    const {open, onClickClose} = props;
    const [formState, setFormState] = useState({});
    const {aspectRatioH, aspectRatioW, containerName, engagementCardUrl, matchId, skeletonName} = formState;

    const handleSubmit = (event) => {
        event.preventDefault();
        if (
            aspectRatioH === '' ||
            aspectRatioW === '' ||
            containerName === '' ||
            engagementCardUrl === '' ||
            matchId === '' ||
            skeletonName === ''
        ) {
            console.log('ERROR');
        }
        const data = {
            aspectRatioH: parseInt(aspectRatioH),
            aspectRatioW: parseInt(aspectRatioW),
            containerName,
            engagementCardUrl,
            matchId,
            skeletonName,
        };
        console.log('SUBMIT_REQ', data);
    };

    const handleReset = () => {
        setFormState({});
    };

    const handleFormData = (formObj) => {
        setFormState({...formState, ...formObj});
    };

    return (
        <MuiModal onClickClose={onClickClose} open={open} className={styles.container}>
            <div className={styles.header}>
                <h3>Engagement Center</h3>
                <CustomIconButton onClick={onClickClose} className={styles.closeButton}>
                    <Close className={styles.closeButtonIcon} />
                </CustomIconButton>
            </div>
            <div className={styles.divider} />
            <div className={styles.body}>
                <Box component="form" onSubmit={handleSubmit} onReset={handleReset} className={styles.uploadForm}>
                    <MuiInputField
                        label="Match ID"
                        name="matchId"
                        onChange={(event) => handleFormData({matchId: event.target.value})}
                        required={true}
                        size="small"
                        value={matchId || ''}
                    />
                    <MuiInputField
                        label="Skeleton Name"
                        name="skeletonName"
                        onChange={(event) => handleFormData({skeletonName: event.target.value})}
                        required={true}
                        size="small"
                        value={skeletonName || ''}
                    />
                    <MuiInputField
                        label="Container Name"
                        name="skeletonName"
                        onChange={(event) => handleFormData({containerName: event.target.value})}
                        required={true}
                        size="small"
                        value={containerName || ''}
                    />
                    <span className={styles.formColumn}>
                        <MuiInputField
                            label="Aspect Ratio (H)"
                            name="aspectRatioH"
                            onChange={(event) => handleFormData({aspectRatioH: event.target.value})}
                            required={true}
                            size="small"
                            value={aspectRatioH || ''}
                        />
                        <MuiInputField
                            label="Aspect Ratio (W)"
                            name="aspectRatioW"
                            onChange={(event) => handleFormData({aspectRatioW: event.target.value})}
                            required={true}
                            size="small"
                            value={aspectRatioW || ''}
                        />
                    </span>
                    <MuiInputField
                        label="Card URL"
                        name="engagementCardUrl"
                        onChange={(event) => handleFormData({engagementCardUrl: event.target.value})}
                        required={true}
                        size="small"
                        value={engagementCardUrl || ''}
                    />
                    <div className={styles.formButtonGroup}>
                        <MuiButton type="reset" buttonText="Reset" buttonIcon={<Refresh />} />
                        <MuiButton type="submit" buttonText="Submit" buttonIcon={<Save />} />
                    </div>
                </Box>
            </div>
        </MuiModal>
    );
};

const CustomIconButton = styled(IconButton)({
    width: '28px',
    height: '28px',

    '&:hover': {
        backgroundColor: ColorPalette.NIGHT_RIDER,
    },
});

export default EngagementCenterModal;
