import axios from 'axios';
import {
    listTeams,
    listTeamsSuccess,
    listTeamsFail,
    listTeamData,
    listTeamDataSuccess,
    listTeamDataFail,
} from './action';
import {URL} from '../../../Utilities/Constants';

/**
 * get team list api request
 * @param {*} team page details
 * @param {string} token user token
 */
export function getTeamList(team, token, client) {
    return (dispatch) => {
        dispatch(listTeams());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token,clientid: client},
            url: `${URL}/team/list`,
            data: team,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(listTeamsSuccess(data));
                } else if (data.data.status === 'fail') {
                    dispatch(listTeamsFail(data.data.errorData.errorReason));
                } else {
                    dispatch(listTeamsFail('Unknown Error Occured'));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(listTeamsFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(listTeamsFail(err.message || 'Something went wrong'));
                }
            });
    };
}

/**
 * get team data api request
 * @param {*} id team id
 * @param {string} token user token
 */
export function getTeamData(id, token) {
    return (dispatch) => {
        dispatch(listTeamData());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token},
            url: `${URL}/team/get`,
            data: id,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(listTeamDataSuccess(data));
                } else if (data.data.status === 'fail') {
                    dispatch(listTeamDataFail(data.data.errorData.errorReason));
                } else {
                    dispatch(listTeamDataFail('Unknown Error Occured'));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(listTeamDataFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(listTeamDataFail(err.message || 'Something went wrong'));
                }
            });
    };
}
