/*
 *
 * LoadingModal
 * Component for modal with loading spinner
 *
 */

import React, {Component} from 'react';
import {StyleSheet, ActivityIndicator, View, Modal} from 'react-native';
import colors from './colors';

export default class LoadingModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalVisible: true,
        };
    }

    render() {
        return (
            <Modal
                animationType="fade"
                transparent={true}
                visible={this.props.visible}
                onRequestClose={() => {
                    this.setState({visible: false});
                }}
            >
                <View style={styles.modalContentStyle}>
                    <ActivityIndicator size="large" color={colors.YELLOW} animating={true} />
                </View>
            </Modal>
        );
    }
}

const styles = StyleSheet.create({
    modalContentStyle: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: colors.BLACK_40,
    },
});
