export const FETCH_TDS_LIST = 'FETCH_TDS_LIST';
export const FETCH_TDS_LIST_SUCCESS = 'FETCH_TDS_LIST_SUCCESS';
export const FETCH_TDS_LIST_FAIL = 'FETCH_TDS_LIST_FAIL';

export const GET_TDS_UPLOAD_URL = 'GET_TDS_UPLOAD_URL';
export const GET_TDS_UPLOAD_URL_SUCCESS = 'GET_TDS_UPLOAD_URL_SUCCESS';
export const GET_TDS_UPLOAD_URL_FAIL = 'GET_TDS_UPLOAD_URL_FAIL';

export const UPLOAD_TDS_CERTIFICATE = 'UPLOAD_TDS_CERTIFICATE';
export const UPLOAD_TDS_CERTIFICATE_SUCCESS = 'UPLOAD_TDS_CERTIFICATE_SUCCESS';
export const UPLOAD_TDS_CERTIFICATE_FAIL = 'UPLOAD_TDS_CERTIFICATE_FAIL';

export const REVIEW_TDS_CERTIFICATE = 'REVIEW_TDS_CERTIFICATE';
export const REVIEW_TDS_CERTIFICATE_SUCCESS = 'REVIEW_TDS_CERTIFICATE_SUCCESS';
export const REVIEW_TDS_CERTIFICATE_FAIL = 'REVIEW_TDS_CERTIFICATE_FAIL';
