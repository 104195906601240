import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';
import Button from '../Button/Button';

const PlayerStatsResultModal = (props) => {
    let {open, handleClose, playerDetails} = props;
    const [state, setState] = useState({perf: playerDetails?.playerInMatchStats?.perf || {}});

    const handleSetState = (obj = {}) => {
        setState({...state, ...obj});
    };

    useEffect(() => {
        const placementPoint = playerDetails?.playerInMatchStats?.placement || 0;
        const placement =
            playerDetails?.placements?.filter(
                (rank) => placementPoint >= rank.startRank && placementPoint <= rank.endRank,
            )?.[0] || {};
        handleSetState({
            perf: {
                ...state.perf,
                placementPoints: {
                    displayName: `Placement point (${placementPoint})`,
                    value: placement?.points || 0,
                    multiplier: 1,
                    fantasyPoints: placement?.points || 0,
                },
            },
        });
    }, []);

    return (
        <Dialog open={open} onClose={handleClose} scroll="paper" maxWidth="sm" fullWidth>
            <DialogTitle>{playerDetails?.pubgName}'s Stats Details</DialogTitle>
            <DialogContent dividers={true}>
                {Object.values(state.perf || {})?.map(
                    (performance, index) =>
                        !!performance?.displayName && (
                            <p className="grid-3-container" key={`${performance?.displayName}_${index}`}>
                                <span>{performance?.displayName}</span>
                                <span>
                                    {performance?.value} x {performance?.multiplier}
                                </span>
                                <span>{performance?.fantasyPoints}</span>
                            </p>
                        ),
                )}
                <hr />
                <p className="grid-3-container">
                    <span>Total</span>
                    <span />
                    <span>{playerDetails?.totalPoints ? playerDetails?.totalPoints?.toFixed(2) : 0}</span>
                </p>
            </DialogContent>
            <DialogActions>
                <Button buttonText="Cancel" onClick={handleClose} />
            </DialogActions>
        </Dialog>
    );
};

PlayerStatsResultModal.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    playerDetails: PropTypes.object,
};

PlayerStatsResultModal.defaultProps = {
    handleClose: () => {},
    open: false,
    playerDetails: {},
};

export default PlayerStatsResultModal;
