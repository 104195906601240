import {
    ADMIN_LIST,
    ADMIN_LIST_SUCCESS,
    ADMIN_LIST_FAIL,
    ADD_ADMIN,
    ADD_ADMIN_SUCCESS,
    ADD_ADMIN_FAIL,
    DELETE_ADMIN,
    DELETE_ADMIN_SUCCESS,
    DELETE_ADMIN_FAIL,
    GET_ADMIN,
    GET_ADMIN_SUCCESS,
    GET_ADMIN_FAIL,
    UPDATE_ADMIN,
    UPDATE_ADMIN_SUCCESS,
    UPDATE_ADMIN_FAIL,
} from './Constants';

const initialState = {
    adminListRequest: false,
    adminListSuccess: false,
    adminListFail: false,
    adminList: [],

    addAdminRequest: false,
    addAdminSuccess: false,
    addAdminFail: false,
    addAdmin: {},

    deleteAdminRequest: false,
    deleteAdminSuccess: false,
    deleteAdminFail: false,
    deleteAdmin: [],

    isFetchingAdminUser: false,
    isFetchingAdminUserSuccess: false,
    isFetchingAdminUserFail: false,
    adminUserDetail: {},

    isUpdatingAdminUser: false,
    isUpdatingAdminUserSuccess: false,
    isUpdatingAdminUserFail: false,

    errorMessage: '',
};

const adminReducer = (state = initialState, action) => {
    switch (action.type) {
        //save  event
        case ADMIN_LIST:
            return {
                ...state,
                adminListRequest: true,
                adminListSuccess: false,
                adminListFail: false,
                adminList: [],
                errorMessage: '',
            };

        case ADMIN_LIST_SUCCESS:
            return {
                ...state,
                adminListRequest: false,
                adminListSuccess: true,
                adminListFail: false,
                adminList: action.payload.data,
                errorMessage: '',
            };

        case ADMIN_LIST_FAIL:
            return {
                ...state,
                adminListRequest: false,
                adminListSuccess: false,
                adminListFail: true,
                errorMessage: action.payload,
            };
        //add admin
        case ADD_ADMIN:
            return {
                ...state,
                addAdminRequest: true,
                addAdminSuccess: false,
                addAdminFail: false,
                addAdmin: {},
                errorMessage: '',
            };

        case ADD_ADMIN_SUCCESS:
            return {
                ...state,
                addAdminRequest: false,
                addAdminSuccess: true,
                addAdminFail: false,
                addAdmin: action.payload,
                errorMessage: '',
            };

        case ADD_ADMIN_FAIL:
            return {
                ...state,
                addAdminRequest: false,
                addAdminSuccess: false,
                addAdminFail: true,
                errorMessage: action.payload,
            };
        //delete admin
        case DELETE_ADMIN:
            return {
                ...state,
                deleteAdminRequest: true,
                deleteAdminSuccess: false,
                deleteAdminFail: false,
                deleteAdmin: [],
                errorMessage: '',
            };

        case DELETE_ADMIN_SUCCESS:
            return {
                ...state,
                deleteAdminRequest: false,
                deleteAdminSuccess: true,
                deleteAdminFail: false,
                deleteAdmin: action.payload.data,
                errorMessage: '',
            };

        case DELETE_ADMIN_FAIL:
            return {
                ...state,
                deleteAdminRequest: false,
                deleteAdminSuccess: false,
                deleteAdminFail: true,
                errorMessage: action.payload,
            };

        case UPDATE_ADMIN:
            return {
                ...state,
                isUpdatingAdminUser: true,
                isUpdatingAdminUserSuccess: false,
                isUpdatingAdminUserFail: false,
                errorMessage: '',
            };

        case UPDATE_ADMIN_SUCCESS:
            return {
                ...state,
                isUpdatingAdminUser: false,
                isUpdatingAdminUserSuccess: true,
                isUpdatingAdminUserFail: false,
                errorMessage: action.payload,
            };

        case UPDATE_ADMIN_FAIL:
            return {
                ...state,
                isUpdatingAdminUser: false,
                isUpdatingAdminUserSuccess: false,
                isUpdatingAdminUserFail: true,
                errorMessage: action.payload,
            };

        case GET_ADMIN:
            return {
                ...state,
                isFetchingAdminUser: true,
                isFetchingAdminUserSuccess: false,
                isFetchingAdminUserFail: false,
                errorMessage: '',
            };

        case GET_ADMIN_SUCCESS:
            return {
                ...state,
                isFetchingAdminUser: false,
                isFetchingAdminUserSuccess: true,
                isFetchingAdminUserFail: false,
                adminUserDetail: action?.payload || {},
                errorMessage: '',
            };

        case GET_ADMIN_FAIL:
            return {
                ...state,
                isFetchingAdminUser: false,
                isFetchingAdminUserSuccess: false,
                isFetchingAdminUserFail: true,
                errorMessage: action.payload,
            };

        default:
            return state;
    }
};

export default adminReducer;
