import {
    TOURNAMENT_LIST,
    TOURNAMENT_LIST_SUCCESS,
    TOURNAMENT_LIST_FAIL,
    ADD_TOURNAMENT,
    ADD_TOURNAMENT_SUCCESS,
    ADD_TOURNAMENT_FAIL,
} from './FantasyTournamentConstants';

/**
 * list TOURNAMENT api
 */
export function listTournament() {
    return {
        type: TOURNAMENT_LIST,
    };
}
/**
 *handle list TOURNAMENT api success
 * @param {*} data data from api
 */
export function listTournamentSuccess(data) {
    return {
        type: TOURNAMENT_LIST_SUCCESS,
        payload: data,
    };
}
/**
 * handle list Tournament api failure
 * @param {*} error error from api
 */
export function listTournamentFail(error) {
    return {
        type: TOURNAMENT_LIST_FAIL,
        payload: error,
    };
}

//  create Tournament
/**
 * create Tournament api
 */
export function addTournamentRequest() {
    return {
        type: ADD_TOURNAMENT,
    };
}
/**
 *handle create Tournament api success
 * @param {*} data data from api
 */
export function addTournamentSuccess(data) {
    return {
        type: ADD_TOURNAMENT_SUCCESS,
        payload: data,
    };
}
/**
 *handle create Tournament api failure
 * @param {*} error error from api
 */
export function addTournamentFail(error) {
    return {
        type: ADD_TOURNAMENT_FAIL,
        payload: error,
    };
}
