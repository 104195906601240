import axios from 'axios';
import {
    listTOMatches,
    listTOMatchesSuccess,
    listTOMatchesFail,
    addTOMatch,
    addTOMatchSuccess,
    addTOMatchFail,
    rescheduleTOMatchRequest,
    rescheduleTOMatchSuccess,
    rescheduleTOMatchFail,
    updateTOMatchRequest,
    updateTOMatchSuccess,
    updateTOMatchFail,
    deleteTOMatchRequest,
    deleteTOMatchSuccess,
    deleteTOMatchFail,
    getTOMatchRequest,
    getTOMatchSuccess,
    getTOMatchFail,
} from './action';
import {TOURL} from '../../../Utilities/Constants';

export function getTOMatchList(pageOptions, token) {
    // listing all TOMatches
    return (dispatch) => {
        dispatch(listTOMatches());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token},
            url: `${TOURL}/match/list`,
            data: pageOptions,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(listTOMatchesSuccess(data));
                } else {
                    dispatch(listTOMatchesFail(data.data.errorData.errorReason));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(listTOMatchesFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(listTOMatchesFail(err.message || 'Something went wrong'));
                }
            });
    };
}

/**
 * update match api request
 * @param {*} match match details
 * @param {string} token user token
 */
export function rescheduleTOMatch(id, startTime, token) {
    return (dispatch) => {
        dispatch(rescheduleTOMatchRequest());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token},
            url: `${TOURL}/match/rescheduleTOMatch`,
            data: {
                matchId: id,
                matchStartTime: startTime,
            },
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(rescheduleTOMatchSuccess(data));
                } else if (data.data.status === 'fail') {
                    dispatch(rescheduleTOMatchFail(data.data.errorData.errorReason));
                } else {
                    dispatch(rescheduleTOMatchFail('Unknown Error Occured'));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(rescheduleTOMatchFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(rescheduleTOMatchFail(err.message || 'Something went wrong'));
                }
            });
    };
}

export function getTOOrganizerMatchList(pageOptions, token) {
    // listing all TOMatches
    return (dispatch) => {
        dispatch(listTOMatches());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token},
            url: `${TOURL}/match/organizerList`,
            data: pageOptions,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(listTOMatchesSuccess(data));
                } else {
                    dispatch(listTOMatchesFail(data.data.errorData.errorReason));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(listTOMatchesFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(listTOMatchesFail(err.message || 'Something went wrong'));
                }
            });
    };
}

//creating new TOMatch
export function createTOMatch(TOMatch, token) {
    return (dispatch) => {
        dispatch(addTOMatch());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token},
            url: `${TOURL}/match/create`,
            data: TOMatch,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(addTOMatchSuccess(data));
                } else {
                    dispatch(addTOMatchFail(data.data.errorData.errorReason));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(addTOMatchFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(addTOMatchFail(err.message || 'Something went wrong'));
                }
            });
    };
}

//TOMatch update
export function updateTOMatch(TOMatch, token) {
    return (dispatch) => {
        dispatch(updateTOMatchRequest());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token},
            url: `${TOURL}/match/update`,
            data: TOMatch,
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(updateTOMatchSuccess(data));
                } else {
                    dispatch(updateTOMatchFail(data.data.errorData.errorReason));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(updateTOMatchFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(updateTOMatchFail(err.message || 'Something went wrong'));
                }
            });
    };
}

//TOMatch delete
export function deleteTOMatch(id, token) {
    return (dispatch) => {
        dispatch(deleteTOMatchRequest());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token},
            url: `${TOURL}/match/delete`,
            data: {matchId: id},
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(deleteTOMatchSuccess(data));
                } else {
                    dispatch(deleteTOMatchFail(data.data.errorData.errorReason));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(deleteTOMatchFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(deleteTOMatchFail(err.message || 'Something went wrong'));
                }
            });
    };
}

// get single TOMatch
export function getTOMatch(id, token) {
    return (dispatch) => {
        dispatch(getTOMatchRequest());
        axios({
            method: 'post',
            headers: {Authorization: 'Bearer ' + token},
            url: `${TOURL}/match/get`,
            data: {
                matchId: id,
            },
        })
            .then((data) => {
                if (data.data.status === 'success') {
                    dispatch(getTOMatchSuccess(data));
                } else {
                    dispatch(getTOMatchFail(data.data.errorData.errorReason));
                }
            })
            .catch((err) => {
                if (err.response) {
                    const errorMsg = err?.response?.data?.msg || err.message;
                    dispatch(getTOMatchFail(errorMsg || 'Something went wrong'));
                } else {
                    dispatch(getTOMatchFail(err.message || 'Something went wrong'));
                }
            });
    };
}
