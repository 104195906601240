import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {fetchBoosterConfigs} from './action';
import {getTOGameList} from '../TOGame/AxioCalls';
import {logout} from '../Login/LoginAction';
import {next, prev} from '../../../Utilities/HelperFunctions';

import TableLayout from '../../Layouts/TableLayout/TableLayout';
import Table from '../../../Components/Table/Table';
import Snackbar from '../../../Components/Snackbar/Snackbar';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';
import FilterInput from '../../../Components/FilterInput';
import Button from '../../../Components/Button/Button';
import {COMMON_STATUS, ENTER, NEXT} from '../../../Utilities/Constants';
import {useDebounce, usePrevious} from '../../../Utilities/hooks';
import DropDown from '../../../Components/Dropdown/Dropdown';
import CreateEditBoosterConfigModal from './CreateEditBoosterConfigModal';
import {ROUTES} from './UrlConstants';

const BoosterConfigs = (props) => {
    const {history} = props;
    const initialFilter = {page: 1, pageSize: 20};
    const dispatch = useDispatch();
    const client = useSelector((state) => state.client);
    const login = useSelector((state) => state.login);
    const prevLogin = usePrevious(login);
    const boosters = useSelector((state) => state.boosters);
    const prevBoosters = usePrevious(boosters);
    const toGame = useSelector((state) => state.toGame);
    const prevToGame = usePrevious(toGame);
    const [gameOptions, setGameOptions] = useState([]);
    const [filters, setFilters] = useState(initialFilter);
    const debouncedFilters = useDebounce(filters, {immediate: filters.immediate});
    const [state, setState] = useState({});
    const [createBoosterConfigData, setCreateBoosterConfigData] = useState(null);

    const {data: boostersConfigData, pageData = {}} = boosters?.boosterConfigsList || {};
    const {errorMessage} = state;
    const disableNext = filters.page >= (pageData.lastPage || 1);
    const loading = !!boosters.isFetchingBoosterConfigs || false;
    const {boosterWrite} = login?.permissions || {};

    useEffect(() => {
        if (!login?.userDetails?.data) {
            history.push('/');
            dispatch(logout());
            return;
        }
        dispatch(getTOGameList({listType: 'ALL'}, login?.userDetails?.data?.accessToken, client.selectedClient));
    }, []);

    useEffect(() => {
        if (boosters.isFetchingBoosterConfigsFail && !prevBoosters.isFetchingBoosterConfigsFail) {
            handleOnStateChange({errorMessage: boosters.message.msg});
        }

        if (
            (boosters.isCreatingBoosterConfigSuccess && !prevBoosters.isCreatingBoosterConfigSuccess) ||
            (boosters.isUpdatingBoosterConfigSuccess && !prevBoosters.isUpdatingBoosterConfigSuccess)
        ) {
            setCreateBoosterConfigData(null);
            handleOnStateChange({errorMessage: boosters.message.msg});
            handleCallBoosterConfigs();
        }

        if (toGame.TOGameListSuccess && !prevToGame.TOGameListSuccess) {
            const temp = toGame?.TOGameList?.data?.data?.map((item) => ({text: item.gameName, value: item.id}));
            setGameOptions(temp);
        }
        if (toGame.TOGameListFail && !prevToGame.TOGameListFail) {
            handleOnStateChange({errorMessage: toGame.errorMessage});
        }
    }, [boosters, toGame]);

    useEffect(() => {
        if (login?.userDetails?.data) handleCallBoosterConfigs();
    }, [debouncedFilters]);

    const handleCallBoosterConfigs = () => {
        const {immediate, ..._filters} = filters;
        dispatch(fetchBoosterConfigs(_filters, login?.userDetails?.data?.accessToken));
    };

    const onReset = () => {
        setFilters({...initialFilter, immediate: true});
    };

    const onNextORPrev = (action) => {
        const _filters = action === NEXT ? next(filters, pageData) : prev(filters);
        if (_filters) setFilters({..._filters, immediate: true});
    };

    const handleFilter = (field, value, debounce = true) => {
        if (field !== ENTER) filters[field] = value;
        setFilters({...filters, page: 1, immediate: !debounce});
    };

    const handleOnStateChange = (stateObj = {}) => {
        setState((prevState) => ({...prevState, ...stateObj}));
    };

    const handleClickOnId = (id) => {
        setCreateBoosterConfigData({id});
    };

    const columns = [
        {
            Header: 'ID',
            accessor: 'id',
            width: 200,
            Cell: (props) => (
                <span className="table-clickable" onClick={() => handleClickOnId(props.value)}>
                    {props?.value || '-'}
                </span>
            ),
        },
        {
            Header: 'Name',
            accessor: 'name',
            width: 150,
            Cell: (props) => <span>{props?.value || '-'}</span>,
        },
        {
            Header: 'Status',
            accessor: 'status',
            Cell: (props) => <span>{props?.value || '-'}</span>,
        },
        {
            Header: 'Max booster applicable',
            accessor: 'maxBoosterApplicable',
            Cell: (props) => <span>{props?.value || '-'}</span>,
        },
        {
            Header: '# Boosters',
            accessor: 'boosters',
            Cell: (props) => <span className="number">{props?.value?.length || '0'}</span>,
        },
    ];

    return (
        <TableLayout location="Boosters Config" history={history} hideSearchBar>
            {!!loading && <LoadingModal open={!!loading} />}
            {!!errorMessage && (
                <Snackbar
                    open={!!errorMessage}
                    message={errorMessage}
                    onClose={() => handleOnStateChange({errorMessage: ''})}
                />
            )}

            {!!createBoosterConfigData && (
                <CreateEditBoosterConfigModal
                    open={!!createBoosterConfigData}
                    boosterConfigData={createBoosterConfigData}
                    onClose={() => setCreateBoosterConfigData(null)}
                />
            )}

            <div className="filter-container my-10">
                <FilterInput
                    placeholder="Name"
                    value={filters.name || ''}
                    onChange={(value) => handleFilter('name', value)}
                    onEnter={() => handleFilter(ENTER, '', false)}
                />
                <FilterInput
                    placeholder="Booster config id"
                    value={filters.boosterConfigId || ''}
                    onChange={(value) => handleFilter('boosterConfigId', value)}
                    onEnter={() => handleFilter(ENTER, '', false)}
                />
                <DropDown
                    label="Game"
                    value={filters.gameId || ''}
                    menuItems={gameOptions || []}
                    onChange={(value) => handleFilter('gameId', value, false)}
                />
                <DropDown
                    label="Status"
                    value={filters.status || ''}
                    menuItems={COMMON_STATUS}
                    onChange={(value) => handleFilter('status', value, false)}
                />
                <Button buttonText="Reset" onClick={onReset} />
            </div>
            <div className="filter-container margin-bottom-10">
                {!!boosterWrite && (
                    <Button buttonText="Create Booster Config" onClick={() => setCreateBoosterConfigData({})} />
                )}
                <Button buttonText="Boosters" onClick={() => history.push(ROUTES.BOOSTERS)} />
            </div>
            <Table
                data={boostersConfigData || []}
                columns={columns}
                page={filters.page}
                onPrevClick={onNextORPrev}
                onNextClick={() => onNextORPrev(NEXT)}
                disableNext={disableNext}
            ></Table>
        </TableLayout>
    );
};

export default BoosterConfigs;
