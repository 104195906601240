import {
    DISCOUNT_LIST,
    DISCOUNT_LIST_SUCCESS,
    DISCOUNT_LIST_FAIL,
    EDIT_DISCOUNT_DATA,
    ADD_DISCOUNT,
    ADD_DISCOUNT_SUCCESS,
    ADD_DISCOUNT_FAIL,
    DELETE_DISCOUNT,
    DELETE_DISCOUNT_SUCCESS,
    DELETE_DISCOUNT_FAIL,
    CURRENT_CODE,
    DISCOUNT_CODE_DETAILS,
    GRANT_DISCOUNT_CODE,
    GRANT_DISCOUNT_CODE_FAIL,
    GRANT_DISCOUNT_CODE_SUCCESS,
} from './Constants';

const initialState = {
    discountListRequest: false,
    discountListSuccess: false,
    discountListFail: false,
    discountList: [],
    errorMessage: '',
    addDiscountRequest: false,
    addDiscountSuccess: false,
    addDiscountFail: false,
    addDiscount: [],
    deleteDiscountRequest: false,
    deleteDiscountSuccess: false,
    deleteDiscountFail: false,
    deleteDiscount: [],
    discountTransaction: [],
    currentDiscountCode: [],
    discCodeDetails: [],

    discountCodeData: {},
    isGrantingDiscountCode: false,
    isGrantingDiscountCodeSuccess: false,
    isGrantingDiscountCodeFail: false,
};

const discountCodeReducer = (state = initialState, action) => {
    switch (action.type) {
        //save  event
        case DISCOUNT_LIST:
            return {
                ...state,
                discountListRequest: true,
                discountListSuccess: false,
                discountListFail: false,
                discountList: [],
                errorMessage: '',
            };

        case DISCOUNT_LIST_SUCCESS:
            return {
                ...state,
                discountListRequest: false,
                discountListSuccess: true,
                discountListFail: false,
                discountList: action.payload.data,
                errorMessage: '',
            };

        case DISCOUNT_LIST_FAIL:
            return {
                ...state,
                discountListRequest: false,
                discountListSuccess: false,
                discountListFail: true,
                errorMessage: action.payload,
            };

        //edit single discount
        case EDIT_DISCOUNT_DATA:
            return {
                ...state,
                editdiscountData: action.payload,
            };
        //add Discount
        case ADD_DISCOUNT:
            return {
                ...state,
                addDiscountRequest: true,
                addDiscountSuccess: false,
                addDiscountFail: false,
                addDiscount: [],
                errorMessage: '',
            };

        case ADD_DISCOUNT_SUCCESS:
            return {
                ...state,
                addDiscountRequest: false,
                addDiscountSuccess: true,
                addDiscountFail: false,
                addDiscount: action.payload.data,
                errorMessage: '',
            };

        case ADD_DISCOUNT_FAIL:
            return {
                ...state,
                addDiscountRequest: false,
                addDiscountSuccess: false,
                addDiscountFail: true,
                errorMessage: action.payload,
            };

        case DELETE_DISCOUNT:
            return {
                ...state,
                deleteDiscountRequest: true,
                deleteDiscountSuccess: false,
                deleteDiscountFail: false,
                deleteDiscount: [],
                errorMessage: '',
            };

        case DELETE_DISCOUNT_SUCCESS:
            return {
                ...state,
                deleteDiscountRequest: false,
                deleteDiscountSuccess: true,
                deleteDiscountFail: false,
                deleteDiscount: action.payload.data,
                errorMessage: '',
            };

        case DELETE_DISCOUNT_FAIL:
            return {
                ...state,
                deleteDiscountRequest: false,
                deleteDiscountSuccess: false,
                deleteDiscountFail: true,
                errorMessage: action.payload,
            };

        case CURRENT_CODE:
            return {
                ...state,
                currentDiscountCode: action.payload,
            };
        case DISCOUNT_CODE_DETAILS:
            return {
                ...state,
                discCodeDetails: action.payload,
            };

        case GRANT_DISCOUNT_CODE:
            return {
                ...state,
                isGrantingDiscountCode: true,
                isGrantingDiscountCodeSuccess: false,
                isGrantingDiscountCodeFail: false,
                errorMessage: '',
            };
        case GRANT_DISCOUNT_CODE_SUCCESS:
            return {
                ...state,
                isGrantingDiscountCode: false,
                isGrantingDiscountCodeSuccess: true,
                isGrantingDiscountCodeFail: false,
                discountCodeData: action.payload,
                errorMessage: '',
            };
        case GRANT_DISCOUNT_CODE_FAIL:
            return {
                ...state,
                isGrantingDiscountCode: false,
                isGrantingDiscountCodeSuccess: false,
                isGrantingDiscountCodeFail: true,
                errorMessage: action.payload,
            };

        default:
            return state;
    }
};

export default discountCodeReducer;
