export const REFERRAL_LIST = 'REFERRAL_LIST';
export const REFERRAL_LIST_SUCCESS = 'REFERRAL_LIST_SUCCESS';
export const REFERRAL_LIST_FAIL = 'REFERRAL_LIST_FAIL';
export const EDIT_REFERRAL_DATA = 'EDIT_REFERRAL_DATA';

export const ADD_REFERRAL = 'ADD_REFERRAL';
export const ADD_REFERRAL_SUCCESS = 'ADD_REFERRAL_SUCCESS';
export const ADD_REFERRAL_FAIL = 'ADD_REFERRAL_FAIL';

export const UPDATE_REFERRAL = 'UPDATE_REFERRAL';
export const UPDATE_REFERRAL_SUCCESS = 'UPDATE_REFERRAL_SUCCESS';
export const UPDATE_REFERRAL_FAIL = 'UPDATE_REFERRAL_FAIL';
export const EDIT_REFERRAL_CODE = 'EDIT_REFERRAL_CODE';

export const REFERRRED_USERS = 'REFERRRED_USERS';
export const REFERRRED_USERS_SUCCESS = 'REFERRRED_USERS_SUCCESS';
export const REFERRRED_USERS_FAIL = 'REFERRRED_USERS_FAIL';

export const CONVERTED_TNX = 'CONVERTED_TNX';
export const CONVERTED_TNX_SUCCESS = 'CONVERTED_TNX_SUCCESS';
export const CONVERTED_TNX_FAIL = 'CONVERTED_TNX_FAIL';
