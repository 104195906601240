import {
    RESETPASSWORD,
    RESETPASSWORD_SUCCESS,
    RESETPASSWORD_FAIL,
    USER_RESETPASSWORD,
    USER_RESETPASSWORD_SUCCESS,
    USER_RESETPASSWORD_FAIL,
} from './Constants';

const initialState = {
    resetPasswordRequest: false,
    resetPasswordSuccess: false,
    resetPasswordFail: false,
    userDetails: [],
    errorMessage: '',
    userPasswordRequest: false,
    userPasswordSuccess: false,
    userPasswordFail: false,
    userPassword: [],
};

const resetPasswordReducer = (state = initialState, action) => {
    switch (action.type) {
        // reset web password
        case RESETPASSWORD:
            return {
                ...state,
                resetPasswordRequest: true,
                resetPasswordSuccess: false,
                resetPasswordFail: false,
                userDetails: [],
                errorMessage: '',
            };

        case RESETPASSWORD_SUCCESS:
            return {
                ...state,
                resetPasswordRequest: false,
                resetPasswordSuccess: true,
                resetPasswordFail: false,
                userDetails: action.payload.data,
                loggedOut: false,
            };

        case RESETPASSWORD_FAIL:
            return {
                ...state,
                resetPasswordRequest: false,
                resetPasswordSuccess: false,
                resetPasswordFail: true,
                errorMessage: action.payload,
            };

        // USER RESET PASSWORD

        case USER_RESETPASSWORD:
            return {
                ...state,
                userPasswordRequest: true,
                userPasswordSuccess: false,
                userPasswordFail: false,
                userPassword: [],
                errorMessage: '',
            };

        case USER_RESETPASSWORD_SUCCESS:
            return {
                ...state,
                userPasswordRequest: false,
                userPasswordSuccess: true,
                userPasswordFail: false,
                userPassword: action.payload.data,
                loggedOut: false,
            };

        case USER_RESETPASSWORD_FAIL:
            return {
                ...state,
                userPasswordRequest: false,
                userPasswordSuccess: false,
                userPasswordFail: true,
                errorMessage: action.payload,
            };

        default:
            return state;
    }
};

export default resetPasswordReducer;
