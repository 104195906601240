import {
    DELETE_COMMON_MATCH_FAILURE,
    DELETE_COMMON_MATCH_REQUEST,
    DELETE_COMMON_MATCH_SUCCESS,
    GET_COMMON_MATCHES_LIST_FAILURE,
    GET_COMMON_MATCHES_LIST_REQUEST,
    GET_COMMON_MATCHES_LIST_SUCCESS,
    GET_COMMON_MATCH_FAILURE,
    GET_COMMON_MATCH_REQUEST,
    GET_COMMON_MATCH_SUCCESS,
    POST_COMMON_MATCH_FAILURE,
    POST_COMMON_MATCH_REQUEST,
    POST_COMMON_MATCH_SUCCESS,
    PUT_COMMON_MATCH_FAILURE,
    PUT_COMMON_MATCH_REQUEST,
    PUT_COMMON_MATCH_SUCCESS,
} from './action-constants';

const initialState = {
    fetchCommonMatchesListRequest: false,
    fetchCommonMatchesListSuccess: false,
    fetchCommonMatchesListFailure: false,
    commonMatchesList: {},

    fetchCommonMatchRequest: false,
    fetchCommonMatchSuccess: false,
    fetchCommonMatchFailure: false,
    commonMatch: {},

    addCommonMatchRequest: false,
    addCommonMatchSuccess: false,
    addCommonMatchFailure: false,

    editCommonMatchRequest: false,
    editCommonMatchSuccess: false,
    editCommonMatchFailure: false,
    editCommonMatch: {},

    removeCommonMatchRequest: false,
    removeCommonMatchSuccess: false,
    removeCommonMatchFailure: false,

    message: '',
};

const commonMatchReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_COMMON_MATCHES_LIST_REQUEST:
            return {
                ...state,
                fetchCommonMatchesListRequest: true,
                fetchCommonMatchesListSuccess: false,
                fetchCommonMatchesListFailure: false,
            };

        case GET_COMMON_MATCHES_LIST_SUCCESS:
            return {
                ...state,
                fetchCommonMatchesListRequest: false,
                fetchCommonMatchesListSuccess: true,
                fetchCommonMatchesListFailure: false,
                commonMatchesList: action.payload,
            };

        case GET_COMMON_MATCHES_LIST_FAILURE:
            return {
                ...state,
                fetchCommonMatchesListRequest: false,
                fetchCommonMatchesListSuccess: false,
                fetchCommonMatchesListFailure: true,
                message: action.payload,
            };

        case GET_COMMON_MATCH_REQUEST:
            return {
                ...state,
                fetchCommonMatchRequest: true,
                fetchCommonMatchSuccess: false,
                fetchCommonMatchFailure: false,
            };

        case GET_COMMON_MATCH_SUCCESS:
            return {
                ...state,
                fetchCommonMatchRequest: false,
                fetchCommonMatchSuccess: true,
                fetchCommonMatchFailure: false,
                commonMatch: action.payload,
            };

        case GET_COMMON_MATCH_FAILURE:
            return {
                ...state,
                fetchCommonMatchRequest: false,
                fetchCommonMatchSuccess: false,
                fetchCommonMatchFailure: true,
                message: action.payload,
            };

        case POST_COMMON_MATCH_REQUEST:
            return {
                ...state,
                addCommonMatchRequest: true,
                addCommonMatchSuccess: false,
                addCommonMatchFailure: false,
            };

        case POST_COMMON_MATCH_SUCCESS:
            return {
                ...state,
                addCommonMatchRequest: false,
                addCommonMatchSuccess: true,
                addCommonMatchFailure: false,
                message: action.payload,
            };

        case POST_COMMON_MATCH_FAILURE:
            return {
                ...state,
                addCommonMatchRequest: false,
                addCommonMatchSuccess: false,
                addCommonMatchFailure: true,
                message: action.payload,
            };

        case PUT_COMMON_MATCH_REQUEST:
            return {
                ...state,
                editCommonMatchRequest: true,
                editCommonMatchSuccess: false,
                editCommonMatchFailure: false,
            };

        case PUT_COMMON_MATCH_SUCCESS:
            return {
                ...state,
                editCommonMatchRequest: false,
                editCommonMatchSuccess: true,
                editCommonMatchFailure: false,
                editCommonMatch: action.payload,
            };

        case PUT_COMMON_MATCH_FAILURE:
            return {
                ...state,
                editCommonMatchRequest: false,
                editCommonMatchSuccess: false,
                editCommonMatchFailure: true,
                message: action.payload,
            };

        case DELETE_COMMON_MATCH_REQUEST:
            return {
                ...state,
                removeCommonMatchRequest: true,
                removeCommonMatchSuccess: false,
                removeCommonMatchFailure: false,
            };

        case DELETE_COMMON_MATCH_SUCCESS:
            return {
                ...state,
                removeCommonMatchRequest: false,
                removeCommonMatchSuccess: true,
                removeCommonMatchFailure: false,
                message: action.payload,
            };

        case DELETE_COMMON_MATCH_FAILURE:
            return {
                ...state,
                removeCommonMatchRequest: false,
                removeCommonMatchSuccess: false,
                removeCommonMatchFailure: true,
                message: action.payload,
            };

        default:
            return state;
    }
};

export default commonMatchReducer;
