import React from 'react';
import '../../../Utilities/style.css';
import '../Login/login.css';
import {connect} from 'react-redux';
import DetailLayout from '../../Layouts/DetailLayout/DetailLayout';
import Card from '../../../Components/Card/Card';
import {createRewardUser} from './AxioCalls';
import Snackbar from '../../../Components/Snackbar/Snackbar';
import LoadminModal from '../../../Components/LoadingModal/LoadingModal';
import Input from '../../../Components/Input/Input';
import DropDown from '../../../Components/Dropdown/Dropdown';
import {isNoSpaceStringOnStarting, isNumberOnly} from '../../../Utilities/Helpers';

class RewardUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            snackbarOpen: false,
            loading: false,
            errorMessage: '',
        };
    }

    userIdentifier = [
        {text: 'User Phone Number', value: 'userPhone'},
        {text: 'User Id', value: 'userId'},
    ];

    denominationList = [
        {text: 'FCCOIN', value: 'FCCOIN'},
        {text: 'MONEY', value: 'MONEY'},
    ];

    transactionList = {
        FCCOIN: [
            {text: 'FEEDBACK REWARD', value: 'FEEDBACK_REWARD'},
            {text: 'CC REFUND', value: 'CC_REFUND'},
        ],
        MONEY: [{text: 'CC REFUND', value: 'CC_REFUND'}],
    };

    componentDidMount() {
        const {login, history} = this.props;
        if (!login.userDetails.data) {
            history.push('/');
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {rewardUser, history} = this.props;
        if (rewardUser.rewardUserSuccess && !prevProps.rewardUser.rewardUserSuccess) {
            this.setState({loading: false, snackbarOpen: true, errorMessage: 'Successfully Updated!'});
            history.push('/users');
        }
        if (rewardUser.rewardUserFail && !prevProps.rewardUser.rewardUserFail) {
            this.setState({loading: false, snackbarOpen: true, errorMessage: rewardUser.errorMessage});
        }
    }

    handleCreateRewardUser = () => {
        const {userId, userPhone, userIdentifier, amount, description, denomination, txnType} = this.state;
        if (!!this.handleValidation()) return;

        this.setState({
            loading: true,
        });
        let data = {amount: parseInt(amount), description, denomination, txnType};
        userIdentifier === 'userPhone' ? (data.userPhone = userPhone) : (data.userId = userId);
        this.props.createRewardUser(
            data,
            this.props.login.userDetails.data.accessToken,
            this.props.client.selectedClient,
        );
    };

    handleOnChange = (key, value) => {
        if (key === 'userPhone' && isNumberOnly(value) && value.length <= 10) {
            this.setState({[key]: value, userPhoneError: ''});
        } else if (key === 'amount') {
            this.setState({[key]: value, amountError: ''});
        } else if (key === 'description' && isNoSpaceStringOnStarting(value)) {
            this.setState({[key]: value, descriptionError: ''});
        }
    };

    handleValidation = () => {
        const {userId, userPhone, userIdentifier, amount, description, denomination, txnType} = this.state;

        if (!userIdentifier) {
            this.setState({userIdentifierError: 'Please fill this field'});
            return true;
        }
        if (userIdentifier === 'userPhone' && (userPhone || '').length !== 10) {
            this.setState({userPhoneError: 'Please Enter 10 Digit Mobile Number'});
            return true;
        }
        if (userIdentifier === 'userId' && !userId) {
            this.setState({userIdError: 'Please fill this field'});
            return true;
        }
        if (!amount) {
            this.setState({amountError: 'Please fill this field'});
            return true;
        }
        if (!denomination) {
            this.setState({denominationError: 'Please fill this field'});
            return true;
        }
        if (!txnType) {
            this.setState({txnTypeError: 'Please fill this field'});
            return true;
        }
        if (!description) {
            this.setState({descriptionError: 'Please fill this field'});
            return true;
        }
    };

    render() {
        const {
            snackbarOpen,
            errorMessage,
            loading,
            userId,
            userPhone,
            userPhoneError,
            userIdError,
            userIdentifier,
            userIdentifierError,
            amount,
            amountError,
            description,
            descriptionError,
            denomination,
            txnType,
            txnTypeError,
            denominationError,
        } = this.state;
        const {history} = this.props;

        return (
            <DetailLayout
                location="submit"
                history={history}
                centerTitle="Reward User"
                onSave={this.handleCreateRewardUser}
            >
                <LoadminModal open={loading} />

                <Snackbar
                    open={snackbarOpen}
                    message={errorMessage}
                    onClose={() => this.setState({snackbarOpen: false})}
                />

                <div className="detail-container margin-top-20">
                    <div className="full-grid" />
                    <div className="full-grid">
                        <Card>
                            <div className="grid-item  margin-top-20">
                                <DropDown
                                    label="Select User Identifier"
                                    type="text"
                                    menuItems={this.userIdentifier}
                                    name="selectedScreen"
                                    value={userIdentifier || ''}
                                    onChange={(value) =>
                                        this.setState({
                                            userIdentifier: value,
                                            userIdentifierError: '',
                                        })
                                    }
                                    placeholder="Select User Identifier"
                                    error={!!userIdentifierError}
                                />
                                <div className="error-text">{userIdentifierError}</div>
                            </div>
                            {userIdentifier === 'userPhone' && (
                                <div className="full-grid margin-top-20">
                                    <Input
                                        label="User Phone Number"
                                        type="number"
                                        name="userPhone"
                                        value={userPhone || ''}
                                        placeholder="Enter User Phone Number"
                                        error={!!userPhoneError}
                                        onChange={(value) => this.handleOnChange('userPhone', value)}
                                    />
                                    <div className="error-text">{userPhoneError}</div>
                                </div>
                            )}
                            {userIdentifier === 'userId' && (
                                <div className="full-grid margin-top-20">
                                    <Input
                                        label="User Id"
                                        type="text"
                                        name="userId"
                                        value={userId || ''}
                                        placeholder="Enter User Id"
                                        error={!!userIdError}
                                        onChange={(value) => this.setState({userId: value, userIdError: ''})}
                                    />
                                    <div className="error-text">{userIdError}</div>
                                </div>
                            )}
                            <div className="grid-item  margin-top-20">
                                <DropDown
                                    label="Select Denomination"
                                    type="text"
                                    menuItems={this.denominationList}
                                    name="denomination"
                                    value={denomination || ''}
                                    placeholder="Select Denomination"
                                    onChange={(value) =>
                                        this.setState({
                                            denomination: value,
                                            denominationError: '',
                                            txnType: '',
                                        })
                                    }
                                    error={!!denominationError}
                                />
                                <div className="error-text">{denominationError}</div>
                            </div>
                            <div className="full-grid margin-top-20">
                                <Input
                                    label={`Enter ${denomination === 'MONEY' ? 'Amount' : 'Coin'}`}
                                    type="number"
                                    name="amount"
                                    value={amount || ''}
                                    error={!!amountError}
                                    placeholder={`Enter ${denomination === 'MONEY' ? 'Amount' : 'Coin'}`}
                                    onChange={(value) => this.handleOnChange('amount', value)}
                                />
                                <div className="error-text">{amountError}</div>
                            </div>
                            <div className="grid-item  margin-top-20">
                                <DropDown
                                    label="Select Transaction Type"
                                    type="text"
                                    error={!!txnTypeError}
                                    menuItems={this.transactionList[denomination || 'FCCOIN']}
                                    name="txnType"
                                    value={txnType || ''}
                                    placeholder="Select Transaction Type"
                                    onChange={(value) => this.setState({txnType: value, txnTypeError: ''})}
                                />
                                <div className="error-text">{txnTypeError}</div>
                            </div>
                            <div className="full-grid  margin-top-20 notification-textarea">
                                <Input
                                    label="Reward User Message"
                                    multiline
                                    rows={4}
                                    type="text"
                                    name="description"
                                    onChange={(value) => this.handleOnChange('description', value)}
                                    error={!!descriptionError}
                                    placeholder="Enter Reward User Message"
                                    value={description || ''}
                                />
                                <div className="error-text">{descriptionError}</div>
                            </div>
                        </Card>
                    </div>
                    <div className="grid-item" />
                </div>
            </DetailLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        rewardUser: state.rewardUser,
        client: state.client,
    };
};

export default connect(mapStateToProps, {createRewardUser})(RewardUser);
