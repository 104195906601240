import {
    GET_BOOSTERS_LIST,
    GET_BOOSTERS_LIST_SUCCESS,
    GET_BOOSTERS_LIST_FAIL,
    GET_BOOSTER,
    GET_BOOSTER_SUCCESS,
    GET_BOOSTER_FAIL,
    CREATE_BOOSTER,
    CREATE_BOOSTER_SUCCESS,
    CREATE_BOOSTER_FAIL,
    GET_BOOSTER_CONFIGS,
    GET_BOOSTER_CONFIGS_SUCCESS,
    GET_BOOSTER_CONFIGS_FAIL,
    GET_MULTIPLIER_CONFIGS,
    GET_MULTIPLIER_CONFIGS_SUCCESS,
    GET_MULTIPLIER_CONFIGS_FAIL,
    GET_BOOSTER_CONFIG,
    GET_BOOSTER_CONFIG_SUCCESS,
    GET_BOOSTER_CONFIG_FAIL,
    CREATE_BOOSTER_CONFIG,
    CREATE_BOOSTER_CONFIG_SUCCESS,
    CREATE_BOOSTER_CONFIG_FAIL,
    UPDATE_BOOSTER_CONFIG,
    UPDATE_BOOSTER_CONFIG_SUCCESS,
    UPDATE_BOOSTER_CONFIG_FAIL,
} from './constants';

const initialState = {
    boostersList: {},
    isFetchingBoosters: false,
    isFetchingBoostersSuccess: false,
    isFetchingBoostersFail: false,

    boosterDetail: {},
    isFetchingBooster: false,
    isFetchingBoosterSuccess: false,
    isFetchingBoosterFail: false,

    isCreatingBooster: false,
    isCreatingBoosterSuccess: false,
    isCreatingBoosterFail: false,

    boosterConfigsList: {},
    isFetchingBoosterConfigs: false,
    isFetchingBoosterConfigsSuccess: false,
    isFetchingBoosterConfigsFail: false,

    boosterConfigDetail: {},
    isFetchingBoosterConfig: false,
    isFetchingBoosterConfigSuccess: false,
    isFetchingBoosterConfigFail: false,

    isCreatingBoosterConfig: false,
    isCreatingBoosterConfigSuccess: false,
    isCreatingBoosterConfigFail: false,

    isUpdatingBoosterConfig: false,
    isUpdatingBoosterConfigSuccess: false,
    isUpdatingBoosterConfigFail: false,

    message: '',
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_BOOSTERS_LIST:
            return {
                ...state,
                isFetchingBoosters: true,
                isFetchingBoostersSuccess: false,
                isFetchingBoostersFail: false,
                boostersList: {},
                message: '',
            };

        case GET_BOOSTERS_LIST_SUCCESS:
            return {
                ...state,
                isFetchingBoosters: false,
                isFetchingBoostersSuccess: true,
                isFetchingBoostersFail: false,
                boostersList: action?.payload?.data || {},
                message: '',
            };

        case GET_BOOSTERS_LIST_FAIL:
            return {
                ...state,
                isFetchingBoosters: false,
                isFetchingBoostersSuccess: false,
                isFetchingBoostersFail: true,
                message: action.payload,
            };

        case GET_BOOSTER:
            return {
                ...state,
                isFetchingBooster: true,
                isFetchingBoosterSuccess: false,
                isFetchingBoosterFail: false,
                boosterDetail: {},
                message: '',
            };

        case GET_BOOSTER_SUCCESS:
            return {
                ...state,
                isFetchingBooster: false,
                isFetchingBoosterSuccess: true,
                isFetchingBoosterFail: false,
                boosterDetail: action?.payload?.data || {},
                message: '',
            };

        case GET_BOOSTER_FAIL:
            return {
                ...state,
                isFetchingBooster: false,
                isFetchingBoosterSuccess: false,
                isFetchingBoosterFail: true,
                message: action.payload,
            };

        case CREATE_BOOSTER:
            return {
                ...state,
                isCreatingBooster: true,
                isCreatingBoosterSuccess: false,
                isCreatingBoosterFail: false,
                message: '',
            };

        case CREATE_BOOSTER_SUCCESS:
            return {
                ...state,
                isCreatingBooster: false,
                isCreatingBoosterSuccess: true,
                isCreatingBoosterFail: false,
                message: action.payload,
            };

        case CREATE_BOOSTER_FAIL:
            return {
                ...state,
                isCreatingBooster: false,
                isCreatingBoosterSuccess: false,
                isCreatingBoosterFail: true,
                message: action.payload,
            };

        case GET_BOOSTER_CONFIGS:
            return {
                ...state,
                isFetchingBoosterConfigs: true,
                isFetchingBoosterConfigsSuccess: false,
                isFetchingBoosterConfigsFail: false,
                boosterConfigsList: {},
                message: '',
            };

        case GET_BOOSTER_CONFIGS_SUCCESS:
            return {
                ...state,
                isFetchingBoosterConfigs: false,
                isFetchingBoosterConfigsSuccess: true,
                isFetchingBoosterConfigsFail: false,
                boosterConfigsList: action?.payload?.data || {},
                message: '',
            };

        case GET_BOOSTER_CONFIGS_FAIL:
            return {
                ...state,
                isFetchingBoosterConfigs: false,
                isFetchingBoosterConfigsSuccess: false,
                isFetchingBoosterConfigsFail: true,
                message: action.payload,
            };

        case GET_MULTIPLIER_CONFIGS:
            return {
                ...state,
                isFetchingMultiplierConfigs: true,
                isFetchingMultiplierConfigsSuccess: false,
                isFetchingMultiplierConfigsFail: false,
                multiplierConfigsList: [],
                message: '',
            };

        case GET_MULTIPLIER_CONFIGS_SUCCESS:
            return {
                ...state,
                isFetchingMultiplierConfigs: false,
                isFetchingMultiplierConfigsSuccess: true,
                isFetchingMultiplierConfigsFail: false,
                multiplierConfigsList: action?.payload?.data || [],
                message: '',
            };

        case GET_MULTIPLIER_CONFIGS_FAIL:
            return {
                ...state,
                isFetchingMultiplierConfigs: false,
                isFetchingMultiplierConfigsSuccess: false,
                isFetchingMultiplierConfigsFail: true,
                message: action.payload,
            };

        case GET_BOOSTER_CONFIG:
            return {
                ...state,
                isFetchingBoosterConfig: true,
                isFetchingBoosterConfigSuccess: false,
                isFetchingBoosterConfigFail: false,
                boosterConfigDetail: {},
                message: '',
            };

        case GET_BOOSTER_CONFIG_SUCCESS:
            return {
                ...state,
                isFetchingBoosterConfig: false,
                isFetchingBoosterConfigSuccess: true,
                isFetchingBoosterConfigFail: false,
                boosterConfigDetail: action?.payload?.data || {},
                message: '',
            };

        case GET_BOOSTER_CONFIG_FAIL:
            return {
                ...state,
                isFetchingBoosterConfig: false,
                isFetchingBoosterConfigSuccess: false,
                isFetchingBoosterConfigFail: true,
                message: action.payload,
            };

        case CREATE_BOOSTER_CONFIG:
            return {
                ...state,
                isCreatingBoosterConfig: true,
                isCreatingBoosterConfigSuccess: false,
                isCreatingBoosterConfigFail: false,
                message: '',
            };

        case CREATE_BOOSTER_CONFIG_SUCCESS:
            return {
                ...state,
                isCreatingBoosterConfig: false,
                isCreatingBoosterConfigSuccess: true,
                isCreatingBoosterConfigFail: false,
                message: action.payload,
            };

        case CREATE_BOOSTER_CONFIG_FAIL:
            return {
                ...state,
                isCreatingBoosterConfig: false,
                isCreatingBoosterConfigSuccess: false,
                isCreatingBoosterConfigFail: true,
                message: action.payload,
            };

        case UPDATE_BOOSTER_CONFIG:
            return {
                ...state,
                isUpdatingBoosterConfig: true,
                isUpdatingBoosterConfigSuccess: false,
                isUpdatingBoosterConfigFail: false,
                message: '',
            };

        case UPDATE_BOOSTER_CONFIG_SUCCESS:
            return {
                ...state,
                isUpdatingBoosterConfig: false,
                isUpdatingBoosterConfigSuccess: true,
                isUpdatingBoosterConfigFail: false,
                message: action.payload,
            };

        case UPDATE_BOOSTER_CONFIG_FAIL:
            return {
                ...state,
                isUpdatingBoosterConfig: false,
                isUpdatingBoosterConfigSuccess: false,
                isUpdatingBoosterConfigFail: true,
                message: action.payload,
            };

        default:
            return state;
    }
};

export default reducer;
