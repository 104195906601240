import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';
import Button from '../../../../Components/Button/Button';
import Snackbar from '../../../../Components/Snackbar/Snackbar';
import {copyText} from '../../../../Utilities/HelperFunctions';
import FileCopy from '@material-ui/icons/FileCopy';

const SquadListModal = (props) => {
    let {handleClose, open, participants} = props;
    const [state, setState] = useState({});
    const {errorMsg} = state;

    const handleOnStateChange = (stateObj = {}) => {
        setState((prevState) => ({...prevState, ...stateObj}));
    };
    const handleCopy = (text) => {
        copyText(text, (msg) => handleOnStateChange({errorMsg: msg}));
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            scroll="paper"
            maxWidth="md"
            fullWidth
            disableEscapeKeyDown
            disableBackdropClick
        >
            {!!errorMsg && (
                <Snackbar open={!!errorMsg} message={errorMsg} onClose={() => handleOnStateChange({errorMsg: ''})} />
            )}

            <DialogTitle>Squads</DialogTitle>
            <DialogContent className="px-0" dividers={true}>
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>Id</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>No. of players</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(participants || []).map((participant, index) => (
                                <TableRow key={`${participant?.rosterId}_${index}`}>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>
                                        {participant?.roster?.team?.id && (
                                            <span
                                                className="clickable-icon"
                                                onClick={() => handleCopy(participant?.roster?.team?.id)}
                                            >
                                                {participant?.roster?.team?.id}
                                                <FileCopy titleAccess="Copy" className="ml-10" fontSize="small" />
                                            </span>
                                        )}
                                    </TableCell>
                                    <TableCell>{participant?.roster?.team?.name}</TableCell>
                                    <TableCell>{participant?.roster?.team?.playerIds?.length}</TableCell>
                                </TableRow>
                            ))}

                            {!participants?.length && (
                                <TableRow>
                                    <TableCell className="text-center" colSpan="10">
                                        No Data Available
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button buttonText="Cancel" onClick={handleClose} />
            </DialogActions>
        </Dialog>
    );
};

SquadListModal.propTypes = {
    open: PropTypes.bool,
    participants: PropTypes.array,
    handleClose: PropTypes.func,
};

SquadListModal.defaultProps = {
    participants: [],
    handleClose: () => {},
    open: false,
};

export default SquadListModal;
